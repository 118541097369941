<!--
 * @Author: gest
 * @Date:  2022-10-12 11:09:11
 * @LastEditTime: 2024-06-14 11:15:24
 * @Description: 消息
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <List
      v-model:loading="loading"
      :finished="finished"
      :offset="20"
      @load="loadFunc"
    >
      <div class="same-box" v-for="(item, index) in list_data" :key="index">
        <div class="person-box">
          <div class="person-item">
            <Image
              v-if="item.user_type == 1"
              class="img-head"
              round
              :src="require('@/assets/images/icon_sys.png')"
            ></Image>
            <Image
              v-else
              class="img-head"
              round
              :src="
                item.face_image
                  ? item.face_image.includes('http')
                    ? item.face_image
                    : $host + item.face_image
                  : require('@/assets/images/icon-empty-img.png')
              "
            ></Image>
            <div class="flex">
              <div class="name">{{ item.user_name }}</div>
              <div class="desc">
                {{ item.post_name
                }}{{ item.department_name ? '/' + item.department_name : '' }}
              </div>
            </div>
          </div>
        </div>
        <div class="info_box">
          <div class="info_title">{{ item.title }}</div>
          <ul class="cell-info">
            <li v-for="(val, idx) in item.content" :key="idx">
              <div class="grey">{{ idx }}</div>
              <div class="info" v-if="idx == '合计金额:'">{{ val }}</div>
              <div class="info" v-else>{{ val || '-' }}</div>
            </li>
            <li
              class="btn"
              @click="onClickInfo(item?.next_ids)"
              v-if="item?.next_ids?.jump_type"
            >
              {{
                item?.next_ids?.jump_type === 5 ||
                item?.next_ids?.jump_type === 6 ||
                item?.next_ids?.jump_type === 7
                  ? '查看详情>'
                  : '点此跟进>'
              }}
            </li>
          </ul>
          <span class="info_time">{{ item.add_time }}</span>
        </div>
      </div>
      <template v-if="list_data.length > 0" #finished>
        <Divider class="divider-bottom">我也是有底线的</Divider>
      </template>
    </List>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无消息"
      v-if="!loading && list_data.length === 0"
    />
    <!-- end -->
  </div>
  <!--V1.4.0 菜单 悬浮按钮 -->
  <ol class="fixed-ol-box">
    <li class="icon-box" @click="_DATA.showMenu = true">
      <img
        class="icon-fixed"
        :src="require('@/assets/images/icon/icon_menu.png')"
      />
    </li>
  </ol>
  <sideMenu
    :data="{ showMenu: _DATA.showMenu }"
    @closed="
      () => {
        _DATA.showMenu = false;
      }
    "
  ></sideMenu>
  <!-- end -->
</template>
<script setup>
import { ref, inject, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { Notify, Image, List, Divider, Empty, Loading, Overlay } from 'vant';
import sideMenu from '@/components/sideMenu';
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();

const loading = ref(false);
const finished = ref(false);

const list_data = ref([]);
const _DATA = reactive({});
const page = reactive({
  page: 1,
  pages: 20,
  total: 0,
  count_page: 1,
});

/**
 * 接口调用
 */
const loadFunc = () => {
  loading.value = true;
  $http
    .getMessageApi({
      page: page.page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        if (data.page.page == page.page) {
          list_data.value = list_data.value.concat(data.list);
          Object.assign(page, { ...data.page });
          if (data.page.page < data.page.count_page) {
            page.page++;
          } else {
            finished.value = true;
          }
        }
      } else {
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    });
};
const onClickInfo = (next_ids) => {
  const type = next_ids?.jump_type;
  switch (type) {
    case 1:
      //客户详情
      router.push({
        path: '/sale',
        query: { id: next_ids.customer_id },
      });
      break;
    case 2:
      //线索详情
      router.push({
        path: '/clueDetail',
        query: { id: next_ids.clue_id },
      });
      break;
    case 3:
      //合同详情
      router.push({
        path: '/contractDetail',
        query: { cid: next_ids.contract_id, id: next_ids.customer_id },
      });
      break;
    case 5:
      //分摊业绩-审批详情
      router.push({
        path: '/taskView',
        query: { id: next_ids.task_id, curTab: 2 },
      });
      break;
    case 6:
      //渠道费-审批详情
      router.push({
        path: '/taskView',
        query: { id: next_ids.task_id, curTab: 2 },
      });
      break;
    case 7:
      //优选补货
      Notify({
        type: 'warning',
        message: '请去网页版-红珊瑚操作！',
      });
      break;
    default:
      break;
  }
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;

  .same-box {
    border-top: 1px solid #f5f5f5;
    &:first-child {
      border-top: none;
    }
    .person-box {
      padding: 20px 20px 0 20px;
      .person-item {
        display: flex;
        align-items: center;
        .img-head {
          width: 36px;
          height: 36px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .flex {
          margin-left: 10px;
          display: flex;
          flex-direction: column;

          .name {
            font-size: 15px;
            font-weight: bold;
            color: #1a1a1a;
          }
          .desc {
            font-size: 12px;
            color: #aaaaaa;
          }
        }
      }
    }
    .info_box {
      padding: 10px 10px 10px 66px;
      .info_title {
        font-size: 15px;
        color: #1a1a1a;
        line-height: 20px;
      }
      .cell-info {
        margin-top: 10px;
        padding: 10px;
        background: #f5f5f5;
        border-radius: 4px;
        color: #1a1a1a;
        font-size: 13px;

        li {
          display: flex;
          line-height: 22px;
          .grey {
            color: #767676;
            width: 82px;
            margin-right: 10px;
          }
          .info {
            flex: 1;
          }
        }
        .btn {
          font-size: 12px;
          text-decoration: underline;
          color: #0256ff;
          cursor: pointer;
          margin-bottom: 0;
        }
      }
      .info_time {
        font-size: 12px;
        color: #aaaaaa;
      }
    }
  }
}

:deep(.van-list__loading) {
  display: none !important;
}
</style>
