/*
 * @Author: Jessica
 * @Date: 2024-05-09 16:29:08
 * @LastEditTime: 2024-05-18 16:35:54
 * @Description:对比柱形漏斗图/单个柱形漏斗图-公共方法
 */
//-@对比漏斗图基础配置--begin
import { Chart } from '@antv/g2';

const renderFunnelChart = (
  containerId,
  height,
  padding,
  data,
  color,
  max,
  scale
) => {
  // 销毁已有的图表
  const canvasExists = document.getElementById(containerId);
  if (canvasExists?.firstChild) {
    canvasExists.removeChild(canvasExists.firstChild);
  }
  const chart = new Chart({
    container: containerId,
    forceFit: true,
    height: height,
    padding: padding,
  });
  commonChart(chart, data, color, max, scale);
};

const commonChart = (chart, data, color, max, scale) => {
  chart.coord('rect').transpose().scale(scale, -1);
  chart.axis(false);
  chart.legend(false);
  chart.tooltip(false);
  chart
    .intervalSymmetric()
    .position('name*value')
    .shape('funnel')
    .color('name', color)
    .adjust('symmetric')
    .style({
      lineWidth: 0,
      stroke: 'rgba(255, 255, 255, 0.9)',
    });
  chart.source(data, {
    value: {
      min: 0,
      max: max,
      nice: false,
    },
  });
  chart.render();
};
//-@对比漏斗图基础配置--end
export { renderFunnelChart };
