<!--
 * @Author: Jessica
 * @Date: 2023-04-26 09:35:24
 * @LastEditTime: 2024-06-13 09:27:59
 * @Description: v1.3.6主材报价-工地
 
-->
<template>
  <div class="quotation">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div v-if="!_DATA.loading && _DATA.infoList.length !== 0">
      <Collapse v-model="_DATA.activeNames">
        <CollapseItem
          :name="item.id"
          v-for="item in _DATA.infoList"
          :key="item.id"
        >
          <template #title>
            <div class="title">
              <p class="name">{{ item.name }}</p>
              <p class="tip">
                <!-- 共{{ item.count }}项，待确认{{
                  item.un_confirm_count
                }}项，已确认{{ item.confirm_count }}项 -->
                共{{ item.total_num }}项目，已下单选样{{
                  item.order_num || '0'
                }}项，未下单选样{{ item.un_order_num || '0' }}项，未确认选样{{
                  item.uncheck_num || '0'
                }}项，无此产品{{ item.none_num || '0' }}项
              </p>
            </div>
          </template>
          <div
            class="detail clearfix"
            v-for="(i, index) in item.list"
            :key="index"
          >
            <div class="pull-left">
              <Image
                class="img"
                lazy-load
                fit="cover"
                :src="
                  i.img && i.img.length
                    ? $host + i.img[0].thumbnail_path
                    : require('@/assets/images/empty-img2.png')
                "
              >
                <template v-slot:loading>
                  <Loading type="spinner" size="20"></Loading>
                </template>
              </Image>
            </div>
            <div class="main_content pull-left">
              <div class="m1">
                <p class="info">
                  <span class="name">{{ i.name }}</span>
                  <span
                    class="status_tag"
                    :class="
                      i.status == 0
                        ? 'yellow'
                        : i.status == 1
                        ? 'green'
                        : i.status == 2
                        ? 'red'
                        : 'grey'
                    "
                  >
                    {{ i.status_name }}
                  </span>
                </p>
                <span class="price">
                  ¥{{ i.market_price }}
                  <em>/{{ i.unit }}</em>
                </span>
              </div>
              <div class="m2">
                <p class="info">
                  <span class="t1">{{ i.brand || '-' }}</span>
                  <span class="t1">｜{{ i.model || '-' }}</span>
                  <span class="t1">｜{{ i.norms || '-' }}</span>
                  <span class="t1">｜{{ i.material || '-' }}</span>
                </p>
                <span class="num">数量：{{ i.amount }}</span>
              </div>
              <div class="m3">
                <span class="t2">
                  <em>产品大类:</em>
                  {{ i.category_name }}；
                </span>
                <span class="t2">
                  <em>产品子类:</em>
                  {{ i.category_sub_name }}；
                </span>

                <span class="t2">
                  <em>供应商:</em>
                  {{ i.supplier_full_name + '【' + i.supplier_name + '】' }}；
                </span>
                <span class="t2">
                  <em>产品编号:</em>
                  {{ i.sku }}
                </span>
              </div>
            </div>
          </div>
        </CollapseItem>
      </Collapse>
    </div>
  </div>
  <!-- 空状态 -->
  <Empty
    class="empty-fff"
    description="暂无数据"
    v-if="!loading && _DATA.infoList.length === 0"
  />
  <!-- end -->
</template>

<script setup>
import { inject, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';

import {
  Notify,
  Overlay,
  Loading,
  Empty,
  Collapse,
  CollapseItem,
  Image,
} from 'vant';

const $http = inject('$http');
const router = useRouter();
const $host = inject('$host');

const _DATA = reactive({
  loading: false,
  site_id: router.currentRoute.value.query.gdid, // 工地id
  infoList: [],
  activeNames: [],
});

onMounted(() => {
  // 报价单
  loadInfo();
});

/**
 * 接口调用
 */
// 报价单
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .getConstructionQuotePreviewApi({ construction_id: _DATA.site_id })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.infoList = data.space_details ?? [];
        // _DATA.activeNames = _DATA.infoList.length
        //   ? _DATA.infoList.map((v) => {
        //       return v.id;
        //     })
        //   : [];
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
</script>

<style lang="less" scoped>
.quotation {
  width: 100%;
  :deep(.van-cell) {
    border: 0;
    padding: 20px;
    align-items: center;
  }
  :deep(.van-collapse-item__content) {
    background: #fbfbfb;
    padding: 0 20px;
  }
  .title {
    width: 100%;

    .name {
      font-size: 15px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .tip {
      font-size: 13px;
      color: #767676;
    }
  }
  .detail {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0;
    .img {
      width: 64px;
      height: 64px;
      overflow: hidden;
    }
    .main_content {
      padding-left: 10px;
      width: calc(100% - 64px);
      box-sizing: border-box;
      .m1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        .name {
          font-size: 15px;
          font-weight: bold;
          color: #1a1a1a;
          padding-right: 5px;
        }
        .price {
          font-size: 15px;
          font-weight: bold;
          color: #1a1a1a;
          > em {
            font-size: 12px;

            color: #767676;
          }
        }
      }
      .m2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        .t1 {
          font-size: 13px;
          color: #767676;
        }
        .num {
          min-width: 50px;
          font-size: 12px;
          text-align: right;
          color: #767676;
        }
      }
    }
  }
}
</style>
