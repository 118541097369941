<!--
 * @Author: NanNan
 * @Date: 2022-05-24 10:52:09
 * @LastEditTime: 2024-06-14 11:13:06
 * @Description: 施工 - 施工日志
 * v1.3.2 优化评论
-->
<template>
  <div class="page-box" @load="_DATA.loading">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div>
      <template v-if="_DATA.list_data && _DATA.list_data.length > 0">
        <div
          v-for="(item, index) in _DATA.list_data"
          :key="index"
          class="same-box"
        >
          <div class="title-box">
            <div class="left">
              <p class="time">{{ item.add_time }}</p>
              <p class="title">{{ item.node_name }}</p>
            </div>
            <div class="right">
              <Checkbox
                v-model="item.front_flag"
                shape="square"
                label-position="left"
                style="font-size: 14px"
                icon-size="20"
                class="checkbox-left"
                @change="changeCheck(item.front_flag, item.id)"
                v-if="allOperationAuthority.indexOf('139') > -1"
              >
                客户可见
              </Checkbox>
              <span
                class="sl"
                v-if="
                  allOperationAuthority.indexOf('139') > -1 &&
                  (allOperationAuthority.indexOf('140') > -1 ||
                    allOperationAuthority.indexOf('141') > -1)
                "
              ></span>
              <Icon
                name="ellipsis"
                style="transform: rotate(90deg)"
                @click="handleMore(item)"
                v-if="
                  allOperationAuthority.indexOf('140') > -1 ||
                  allOperationAuthority.indexOf('141') > -1
                "
              />
            </div>
          </div>

          <div class="con-box">
            <dl class="cus-message">
              <dt>
                <lazy-component>
                  <Image
                    v-if="item.face_image"
                    :src="
                      item.face_image.includes('http')
                        ? item.face_image
                        : $host + item.face_image
                    "
                    v-lazy
                    fit="cover"
                    round
                  />
                  <img
                    :src="require('@/assets/images/icon-empty-img.png')"
                    v-else
                  />
                </lazy-component>
              </dt>
              <dd>
                <p class="name">{{ item.user_name }}</p>
                <p class="depart">
                  {{ item.department_name + '/' + item.post_name }}
                </p>
              </dd>
            </dl>

            <dl class="same-flex">
              <dd>{{ item.comment }}</dd>
            </dl>

            <!-- 附件 -->
            <div class="fileList" v-if="item.files && item.files.length > 0">
              <FileGrid :data="item.files"></FileGrid>
            </div>
            <!-- end -->

            <!-- 评论区域 -->
            <div class="review-box">
              <p class="title">
                <span>{{ item.comment_list.length > 0 ? '评论' : '' }}</span>
                <a @click="handleAdd(item.id)">添加评论</a>
              </p>
              <div class="content" v-if="item.comment_list.length > 0">
                <dl
                  class="same-flex"
                  v-for="(val, idx) in item.comment_list"
                  :key="idx"
                >
                  <dt>
                    <lazy-component>
                      <Image
                        width="32px"
                        height="32px"
                        fit="cover"
                        :src="
                          val.face_image.includes('http')
                            ? val.face_image
                            : $host + val.face_image
                        "
                        v-if="val.face_image"
                        round
                      />
                      <Image
                        width="32px"
                        height="32px"
                        :src="require('@/assets/images/icon-empty-img.png')"
                        v-else
                      />
                    </lazy-component>
                  </dt>
                  <dd>
                    <p class="name">{{ val.user_name }}</p>
                    <p class="depart">
                      {{ val.department_name + '/' + val.post_name }}
                    </p>
                    <p class="remark">
                      {{ val.content }}
                    </p>
                    <p class="time">
                      <span>{{ val.add_time }}</span>
                      <a
                        v-if="val.delete_flag === 1"
                        @click="handleDel(val.id)"
                      >
                        删除
                      </a>
                    </p>
                  </dd>
                </dl>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </template>
      <template v-else>
        <div class="no_info">当前没有施工日志~</div>
      </template>

      <!-- 面板 -->
      <Popup v-model:show="_DATA.show" position="bottom">
        <Field
          v-model="_DATA.form.remark"
          center
          clearable
          placeholder="请填写评论"
          @blur="handleBlur"
          ref="inputField"
        >
          <template #button>
            <Button size="small" type="primary" @click="handleSend">
              发送
            </Button>
          </template>
        </Field>
      </Popup>
      <!-- 更多操作 编辑、删除 -->
      <ActionSheet
        v-model:show="_DATA.more_show"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancel"
      >
        <div class="van-action-sheet__content">
          <div v-for="(item, index) in _DATA.more_list" :key="index">
            <Button
              class="van-action-sheet__item"
              type="button"
              @click="handleClick(item.id)"
              v-if="
                (allOperationAuthority.indexOf('140') > -1 && item.id == 1) ||
                (allOperationAuthority.indexOf('141') > -1 && item.id == 2)
              "
            >
              <span class="van-action-sheet__name">{{ item.name }}</span>
            </Button>
          </div>
        </div>
      </ActionSheet>
    </div>
    <!-- 悬浮按钮 -->
    <ol class="fixed-text-box" v-if="allOperationAuthority.indexOf('225') > -1">
      <li class="more" @click="linkTo('/construc/logAdd')">
        <div class="icon-box">
          <Icon name="plus" color="#fff" size="16" />
          添加日志
        </div>
      </li>
    </ol>
    <!-- end -->
  </div>
</template>
<script setup>
import FileGrid from '@/components/common/FileGrid';

import { inject, onMounted, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import {
  Field,
  ActionSheet,
  Notify,
  Button,
  Image,
  Loading,
  Overlay,
  // ImagePreview,
  Checkbox,
  Icon,
  Dialog,
  Popup,
} from 'vant';
import { useStore } from 'vuex';
const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const inputField = ref(null);

const _DATA = reactive({
  list_data: [],
  loading: false,
  show: false,
  more_show: false,
  more_list: [
    { name: '编辑', id: 1 },
    { name: '删除', id: 2 },
  ],
  more_item: '',
  form: {
    log_id: '',
    remark: '',
  },
});

onMounted(() => {
  // $http.getloginApi();
  _DATA.loading = true;
  loadFunc();
});

/**
 * 接口调用
 */
//@客户跟进记录 列表
const loadFunc = () => {
  $http
    .getConstructionLogApi({
      construction_id: router.currentRoute.value.query.gdid,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list_data = data;
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

/**
 * 操作
 */
//-@ 客户是否可见
const changeCheck = (val, id) => {
  _DATA.loading = true;
  $http
    .getSetFrontFlagApi({
      id,
      type: val ? 1 : 2,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        loadFunc();
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//-@ 图片预览
// const previewImage = (arr, index) => {
//   ImagePreview({
//     images: arr.map((v) => $host + v.path), // 预览图片的那个数组
//     startPosition: index, // 指明预览第几张图
//   });
// };

//-@ 击添加评论
const handleAdd = (id) => {
  _DATA.show = true;
  _DATA.form.log_id = id;
  _DATA.form.remark = '';
  setTimeout(() => {
    inputField.value.focus();
  }, 100);
};

//-@ 点击完成
const handleBlur = () => {
  inputField.value.blur();
  // show.value = false;
};

//-@ 点击发送
const handleSend = () => {
  $http.getAddConstructionCommentApi({ ..._DATA.form }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.show = false;
      _DATA.loading = true;
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          loadFunc();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-@ 删除评论
const handleDel = (id) => {
  _DATA.loading = true;
  $http.getDelConstructionCommentApi({ id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          loadFunc();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-@ 更多
const handleMore = (item) => {
  _DATA.more_show = true;
  _DATA.more_item = item;
};
//-@ 取消拉起
const onCancel = () => {
  _DATA.more_show = false;
};
//-@ 编辑、删除
const handleClick = (type_id) => {
  _DATA.more_show = false;
  if (type_id === 1) {
    // 编辑
    router.push({
      path: '/construc/logAdd',
      query: {
        id: router.currentRoute.value.query.id,
        gdid: router.currentRoute.value.query.gdid,
        cid: router.currentRoute.value.query.cid,
        logid: _DATA.more_item.id,
      },
    });
  } else if (type_id === 2) {
    // 删除

    Dialog.confirm({ message: '确定要删除吗？删除后将不可恢复！' }).then(() => {
      _DATA.loading = true;
      $http
        .getDelConstructionLogApi({ id: _DATA.more_item.id })
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            Notify({ type: 'success', message: '操作成功' });
            loadFunc();
          } else {
            _DATA.loading = false;
            Notify({ type: 'warning', message: data });
          }
        })
        .catch(() => {
          _DATA.loading = false;
        });
    });
  }
};
//-@ 跳转
const linkTo = (name) => {
  router.push({
    path: name,
    query: {
      id: router.currentRoute.value.query.id,
      gdid: router.currentRoute.value.query.gdid,
      cid: router.currentRoute.value.query.cid,
    },
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/construction/log.less';
.no_info {
  text-align: center;
  padding-bottom: 20px;
}
</style>
