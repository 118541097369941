<!--
 * @Author: NanNan
 * @Date: 2023-04-25 10:50:00
 * @LastEditTime: 2023-08-23 16:37:31
 * @Description: v1.3.6 见面记录
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="list-box" v-if="!_DATA.loading">
      <ul>
        <li>
          <div class="label">客户编号</div>
          <div class="right">{{ _DATA.info?.customer_no || '-' }}</div>
        </li>
        <li>
          <div class="label">派单状态</div>
          <div class="statusbox">
            <em
              class="tag red"
              v-if="_DATA.info?.many_dis && !_DATA.info?.change_dis"
            >
              {{ _DATA.info?.many_dis }}
            </em>
            <!-- 改 -->
            <em class="tag redin" v-if="_DATA.info?.change_dis">
              {{ _DATA.info?.change_dis }}
            </em>
            <!-- 设计中心 -->
            <em class="text" v-if="_DATA.info?.dep_name">
              {{ _DATA.info?.dep_name }}
            </em>
          </div>
        </li>
        <li>
          <div class="label">见面时间</div>
          <div class="right" v-if="_DATA.info?.already_meet_date">
            {{ _DATA.info?.already_meet_date }}
          </div>
          <div class="right" v-else-if="_DATA.info?.meet_date">
            {{ '预计' + _DATA.info?.meet_date }}
          </div>
          <div class="right" v-else>-</div>
        </li>
        <li>
          <div class="label">签约时间</div>
          <div class="right">
            {{ _DATA.info?.signed_date || '-' }}
          </div>
        </li>
        <li>
          <div class="label">回访客户结果</div>
          <div class="rightbox">
            <p>{{ _DATA.info?.visit_content || '-' }}</p>
          </div>
        </li>

        <li>
          <div class="label">首见看工地</div>
          <div class="rightbox">
            <div class="status-box" v-if="!_DATA.info?.first_construction_time">
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{ green: _DATA.info?.first_construction_status == 2 }"
              ></i>
              <span
                v-if="
                  _DATA.info?.first_construction_status == 1 ||
                  _DATA.info?.first_construction_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.first_construction_day + '天' }}
                <em></em>
                {{ _DATA.info?.first_construction_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">首见有预案</div>
          <div class="rightbox">
            <div class="status-box" v-if="!_DATA.info?.first_plan_time">-</div>
            <div class="status-box" v-else>
              <i :class="{ green: _DATA.info?.first_plan_status == 2 }"></i>
              <span
                v-if="
                  _DATA.info?.first_plan_status == 1 ||
                  _DATA.info?.first_plan_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.first_plan_day + '天' }}
                <em></em>
                {{ _DATA.info?.first_plan_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">首见活动压单</div>
          <div class="rightbox">
            <div
              class="status-box"
              v-if="!_DATA.info?.first_activity_order_time"
            >
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{ green: _DATA.info?.first_activity_order_status == 2 }"
              ></i>
              <span
                v-if="
                  _DATA.info?.first_activity_order_status == 1 ||
                  _DATA.info?.first_activity_order_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.first_activity_order_day + '天' }}
                <em></em>
                {{ _DATA.info?.first_activity_order_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">首见发谈四条</div>
          <div class="rightbox">
            <div class="status-box" v-if="!_DATA.info?.first_four_few_time">
              -
            </div>
            <div class="status-box" v-else>
              <i :class="{ green: _DATA.info?.first_four_few_status == 2 }"></i>
              <span
                v-if="
                  _DATA.info?.first_four_few_status == 1 ||
                  _DATA.info?.first_four_few_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.first_four_few_day + '天' }}
                <em></em>
                {{ _DATA.info?.first_four_few_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">首见签约</div>
          <div class="rightbox">
            <div class="status-box" v-if="!_DATA.info?.first_signing_time">
              -
            </div>
            <div class="status-box" v-else>
              <i :class="{ green: _DATA.info?.first_signing_status == 2 }"></i>
              <span
                v-if="
                  _DATA.info?.first_signing_status == 1 ||
                  _DATA.info?.first_signing_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.first_signing_day + '天' }}
                <em></em>
                {{ _DATA.info?.first_signing_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">2天内量房照片</div>
          <div class="rightbox">
            <div
              class="status-box"
              v-if="!_DATA.info?.three_measure_house_time"
            >
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{ green: _DATA.info?.three_measure_house_status == 2 }"
              ></i>
              <span
                v-if="
                  _DATA.info?.three_measure_house_status == 1 ||
                  _DATA.info?.three_measure_house_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.three_measure_house_day + '天' }}
                <em></em>
                {{ _DATA.info?.three_measure_house_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">
            3天内预案
            <br />
            制作照片
          </div>
          <div class="rightbox">
            <div class="status-box" v-if="!_DATA.info?.three_plan_photo_time">
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{ green: _DATA.info?.three_plan_photo_status == 2 }"
              ></i>
              <span
                v-if="
                  _DATA.info?.three_plan_photo_status == 1 ||
                  _DATA.info?.three_plan_photo_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.three_plan_photo_day + '天' }}
                <em></em>
                {{ _DATA.info?.three_plan_photo_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">
            4天内预演会照片
            <br />
            (400m²以上)
          </div>
          <div class="rightbox">
            <div
              class="status-box"
              v-if="!_DATA.info?.three_rehearsal_meeting_photo_time"
            >
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{
                  green: _DATA.info?.three_rehearsal_meeting_photo_status == 2,
                }"
              ></i>
              <span
                v-if="
                  _DATA.info?.three_rehearsal_meeting_photo_status == 1 ||
                  _DATA.info?.three_rehearsal_meeting_photo_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.three_rehearsal_meeting_photo_day + '天' }}
                <em></em>
                {{ _DATA.info?.three_rehearsal_meeting_photo_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">
            7天内二见
            <br />
            补看工地
          </div>
          <div class="rightbox">
            <div
              class="status-box"
              v-if="!_DATA.info?.seven_second_meeting_construction_time"
            >
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{
                  green:
                    _DATA.info?.seven_second_meeting_construction_status == 2,
                }"
              ></i>
              <span
                v-if="
                  _DATA.info?.seven_second_meeting_construction_status == 1 ||
                  _DATA.info?.seven_second_meeting_construction_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.seven_second_meeting_construction_day + '天' }}
                <em></em>
                {{ _DATA.info?.seven_second_meeting_construction_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">7天内二见</div>
          <div class="rightbox">
            <div
              class="status-box"
              v-if="!_DATA.info?.seven_second_meeting_time"
            >
              -
            </div>
            <div class="status-box" v-else>
              <i
                :class="{ green: _DATA.info?.seven_second_meeting_status == 2 }"
              ></i>
              <span
                v-if="
                  _DATA.info?.seven_second_meeting_construction_status == 1 ||
                  _DATA.info?.seven_second_meeting_construction_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.seven_second_meeting_day + '天' }}
                <em></em>
                {{ _DATA.info?.seven_second_meeting_time }}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div class="label">7天内签约</div>
          <div class="rightbox">
            <div class="status-box" v-if="!_DATA.info?.seven_signing_time">
              -
            </div>
            <div class="status-box" v-else>
              <i :class="{ green: _DATA.info?.seven_signing_status == 2 }"></i>
              <span
                v-if="
                  _DATA.info?.seven_signing_status == 1 ||
                  _DATA.info?.seven_signing_status == ''
                "
                class="gray"
              >
                -
              </span>
              <span v-else>
                {{ _DATA.info?.seven_signing_day + '天' }}
                <em></em>
                {{ _DATA.info?.seven_signing_time }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Overlay, Loading, Notify } from 'vant';

const $http = inject('$http');
const router = useRouter();

const _DATA = reactive({
  loading: false,
  loadingBtn: false,
  info: {},
  parmas: {},
});

onMounted(() => {
  loadFunc();
});

/**
 * api
 */
// -- 详情
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .clueMeetRecordInfo({
      dispatch_id: router.currentRoute?.value?.query?.id,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.info = JSON.parse(JSON.stringify(data));
        _DATA.parmas = JSON.parse(JSON.stringify(data));
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueRecord.less';
</style>
