<!--
 * @Author: Jessica
 * @Date: 2023-03-21 16:02:46
 * @LastEditTime: 2023-03-29 16:01:27
 * @Description: v1.3.4 产品方案预演会表单
-->
<template>
  <div class="formBase">
    <CellGroup>
      <!-- 方案内容 -->
      <div class="content">
        <h3>方案内容</h3>
        <div class="list1">
          <div
            class="item"
            v-for="(item, index) in form.info.basic_info"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup v-model="item.value" direction="horizontal">
                  <Radio
                    :name="1"
                    class="radio_ok"
                    :class="{
                      checked: item.value === 1,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-checkbox-circle-fill"></use>
                      </svg>
                    </template>
                    完成
                  </Radio>
                  <Radio
                    :name="2"
                    class="radio_no"
                    :class="{
                      checked: item.value === 2,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-close-circle-fill"></use>
                      </svg>
                    </template>
                    未完成
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
      </div>
      <!-- 方案质量 -->
      <div class="content">
        <h3>方案质量</h3>
        <div class="list1">
          <div
            class="item"
            v-for="(item, index) in form.info.quality_info"
            :key="index"
          >
            <span>{{ item.name }}</span>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup v-model="item.value" direction="horizontal">
                  <Radio
                    :name="1"
                    class="radio_ok"
                    :class="{
                      checked: item.value === 1,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-fill1"></use>
                      </svg>
                    </template>
                    满足
                  </Radio>
                  <Radio
                    :name="2"
                    class="radio_no"
                    :class="{
                      checked: item.value === 2,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-sad-fill1"></use>
                      </svg>
                    </template>
                    不满足
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
        <!-- 产品配置、配比是否合理 -->
        <div class="list1 mt_15">
          <div class="item">
            <span>产品配置、配比是否合理</span>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.info.product_config_value"
                  direction="horizontal"
                >
                  <Radio
                    name="1"
                    class="radio_ok"
                    :class="{
                      checked: form.info.product_config_value == 1,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-fill1"></use>
                      </svg>
                    </template>
                    满足
                  </Radio>
                  <Radio
                    name="2"
                    class="radio_no"
                    :class="{
                      checked: form.info.product_config_value == 2,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-sad-fill1"></use>
                      </svg>
                    </template>
                    不满足
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div class="item pt_0">
            <!-- 软装 -->
            <p class="field_item">
              <Field
                label="软装"
                v-model="form.info.product_config_soft"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 主材 -->
            <p class="field_item">
              <Field
                label="主材"
                v-model="form.info.product_config_main"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 机电 -->
            <p class="field_item">
              <Field
                label="机电"
                v-model="form.info.product_config_machine"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 园林 -->
            <p class="field_item">
              <Field
                label="园林"
                v-model="form.info.product_config_gard"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 施工 -->
            <p class="field_item">
              <Field
                label="施工"
                v-model="form.info.product_config_construct"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
          </div>
        </div>
        <!-- 产品报价是否超预算 -->
        <div class="list1 mt_15">
          <div class="item">
            <span>产品报价是否超预算</span>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.info.product_offer_value"
                  direction="horizontal"
                >
                  <Radio
                    name="1"
                    class="radio_ok"
                    :class="{
                      checked: form.info.product_offer_value == 1,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-fill1"></use>
                      </svg>
                    </template>
                    满足
                  </Radio>
                  <Radio
                    name="2"
                    class="radio_no"
                    :class="{
                      checked: form.info.product_offer_value == 2,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-sad-fill1"></use>
                      </svg>
                    </template>
                    不满足
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div class="item pt_0">
            <!-- 软装 -->
            <p class="field_item">
              <Field
                label="软装"
                v-model="form.info.product_offer_soft"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 主材 -->
            <p class="field_item">
              <Field
                label="主材"
                v-model="form.info.product_offer_main"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 机电 -->
            <p class="field_item">
              <Field
                label="机电"
                v-model="form.info.product_offer_machine"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
          </div>
        </div>
        <!-- 对这次“方案通过”有信心吗？ -->
        <div class="list1 mt_15">
          <h2>对这次“方案通过”有信心吗？</h2>
          <div class="score_item clearfix">
            <p
              class="pull-left"
              :class="{ checked: i == form.info.pass_confidence }"
              v-for="i in 10"
              :key="i"
              :label="i"
              @click.stop="getScore(i, 'pass_confidence')"
            >
              <span>{{ i + '分' }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 商务洽谈 -->
      <div class="content">
        <h3>商务洽谈</h3>
        <div class="list1">
          <div class="item pt_0">
            <!-- 决策人 -->
            <p class="field_item max">
              <Field
                label="决策人"
                v-model="form.info.policy_man"
                type="text"
                placeholder="输入决策人姓名"
                :rules="[
                  {
                    required: true,
                    message: '请输入决策人姓名',
                  },
                ]"
              ></Field>
            </p>
            <!-- 见面时间 -->
            <p class="field_item max">
              <Field
                label="见面时间"
                v-model="form.info.face_time"
                right-icon="underway-o"
                readonly
                name="picker"
                placeholder="选择见面时间"
                :rules="[
                  {
                    required: true,
                    message: '请选择见面时间',
                  },
                ]"
                @click="openDateType('face_time')"
              />
            </p>
            <!-- 本次预计收款额 -->
            <p class="field_item max">
              <Field
                label="本次预计收款额"
                v-model="form.info.money"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
          </div>
        </div>
        <!-- 对这次的“收款成功”有信心吗？ -->
        <div class="list1 mt_15">
          <h2>对这次的“收款成功”有信心吗？</h2>
          <div class="score_item clearfix">
            <p
              class="pull-left"
              :class="{ checked: i == form.info.success_confidence }"
              v-for="i in 10"
              :key="i"
              :label="i"
              @click.stop="getScore(i, 'success_confidence')"
            >
              <span>{{ i + '分' }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 收款方式 -->
      <div class="content mb_15">
        <div class="list1 mt_15">
          <div class="item pt_0">
            <!-- 收款方式 -->
            <p class="field_item max">
              <Field
                label="收款方式"
                v-model="form.info.money_type_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="选择收款方式"
                :rules="[
                  {
                    required: true,
                    message: '请选择收款方式',
                  },
                ]"
                @click="state.showCashPicker = true"
              />
            </p>
            <!-- 收全款理由 -->
            <p class="field_item max">
              <span class="label-name">收全款理由</span>
              <Field
                v-model="form.info.remark"
                rows="3"
                autosize
                type="textarea"
                maxlength="500"
                placeholder="填写收全款理由..."
                show-word-limit
                :rules="[
                  {
                    required: true,
                    message: '请填写收全款理由',
                  },
                ]"
              ></Field>
            </p>
          </div>
        </div>
      </div>

      <!-- popup -->
      <!-- 时间选择控件 -->
      <Popup v-model:show="state.showTimePicker" position="bottom">
        <DatetimePicker
          v-model="state.currentDate"
          :min-date="state.minDate"
          type="datetime"
          title="选择日期"
          @confirm="(v) => onConfirmDate(v, 'face_time')"
          @cancel="state.showTimePicker = false"
        />
      </Popup>
      <!-- 收款方式选择控件 -->
      <Popup v-model:show="state.showCashPicker" position="bottom">
        <Picker
          :columns="state.cash_list"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(v) => onConfirm(v, 'money_type')"
          @cancel="state.showCashPicker = false"
          :default-index="
            state.cash_list.findIndex((v) => {
              return v.id == form.info.money_type;
            })
          "
        />
      </Popup>
    </CellGroup>
  </div>
</template>

<script setup>
import { defineProps, reactive, inject, onMounted, defineEmits } from 'vue';
import {
  Field,
  CellGroup,
  RadioGroup,
  Radio,
  Popup,
  DatetimePicker,
  Notify,
  Picker,
} from 'vant';
import moment from 'moment';
const emit = defineEmits(['update:data']);
const $http = inject('$http');
const props = defineProps(['data']);
const state = reactive({
  //-时间控件
  minDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
  //-收款方式
  showCashPicker: false,
  cash_list: [],
});
const form = reactive({
  info: {},
  loading: false,
  cash_list: [], //收款方式
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
  loadParams();
});

const loadParams = async () => {
  await $http.getDesignNodeFormEditParams().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      state.cash_list = data.cash_type_list;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};
// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD HH:mm');
};

//-@ picker选择控件
const onConfirm = (value, type) => {
  state.showCashPicker = false;
  form['info'][type] = value.id;
  form['info'][type + '_name'] = value.name;
};

//-@ 分数选择空间
const getScore = (value, type) => {
  if (form['info'][type] == value) {
    form['info'][type] = 0;
  } else {
    form['info'][type] = value;
  }
};
</script>

<style lang="less" scoped>
.formBase {
  .mt_15 {
    margin-top: 15px;
  }

  :deep(.van-cell-group) {
    background-color: #f5f5f5;
  }
  :deep(.van-cell) {
    border: 0;
    border-radius: 0;
    width: auto;
    padding: 0;
  }
  //-单选框样式自定义
  :deep(.van-radio-group--horizontal) {
    display: block;
    width: 100%;
    margin: 15px 0 10px;
  }
  :deep(.van-radio) {
    float: left;
    width: calc(50% - 10px);
    height: 48px;
    border-radius: 5px;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0px;
    }
  }
  :deep(.van-radio__icon) {
    height: auto;
  }
  :deep(.van-radio__label) {
    font-size: 15px;
  }
  .radio_no {
    color: #ea0000;
    :deep(.van-radio__icon) {
      fill: #ea0000;
    }
    :deep(.van-radio__label) {
      color: #ea0000;
    }
    &.checked {
      background: rgba(234, 0, 0, 0.1);
      border: 1px solid #ea0000 !important;
    }
  }
  .radio_ok {
    color: #0256ff;
    :deep(.van-radio__icon) {
      fill: #0256ff;
    }
    :deep(.van-radio__label) {
      color: #0256ff;
    }
    &.checked {
      background: #e0eaff;
      border: 1px solid #0256ff !important;
    }
  }
  //---end-----
  .content {
    h3 {
      color: #939aa3;
      text-indent: 20px;
      font-size: 14px;
      padding: 10px 0px;
    }

    .icon-small {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }

    .list1 {
      padding: 0px 20px;
      background: #fff;

      > h2 {
        font-size: 17px;
        font-weight: bold;
        color: #1a1a1a;
        text-align: center;
        padding: 10px 0px;
      }
      .item {
        padding: 10px 0px;
        border-bottom: 1px solid #efefef;
        > span {
          font-size: 15px;
          color: #1a1a1a;
        }
        &:last-child {
          border-bottom: 0px;
        }
        &.pt_0 {
          padding-top: 0px;
        }
      }
      .field_item {
        padding: 10px 0;
        border-bottom: 1px solid #efefef;
        .label-name {
          color: #1d2129;
          font-size: 15px;
          margin-bottom: 8px;
          display: inline-block;
        }
        &:last-child {
          border-bottom: 0px;
        }
        :deep(.van-field) {
          font-size: 15px;
        }
        :deep(.van-field__value) {
          color: #1d2129;
        }
        :deep(.van-field__label) {
          color: #1d2129;
          width: 60px;
        }
        &.max {
          :deep(.van-field__label) {
            width: 110px;
          }
        }
      }
      .score_item {
        padding: 15px 0;
        > p {
          width: 20%;
          margin-bottom: 10px;
          &.checked {
            > span {
              color: #165dff;
              border: 1px solid #bedaff;
              background: #e8f3ff;
            }
          }
          > span {
            display: block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border: 1px solid #efefef;
            border-radius: 100%;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: #000000;
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
