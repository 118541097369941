<!--
 * @Author: NanNan
 * @Date: 2023-10-10 18:00:16
 * @LastEditTime: 2024-05-31 15:02:15
 * @Description: 线索-完善信息(全新-改版优化)
 * v1.3.2 smart 线索联调增加
 * v1.3.3 smart 线索整改 - 根据是否有申请派单权限做变动，有：生成多派->申请多派，生成客户->申请派单，无权限保持原样
 * v1.3.4 smart 表单项清空问题优化、跟进记录根据 渠道来源===系统同步来增加权限判断
 * v1.4.1 smart 除了“保存线索”，其余操作均需要验证“邀约人”是否填写，前端拦截判断
 * v1.4.2 smart 渠道来源=84工地返单，渠道申请人 必填（搜索列表变更），新增返单工地信息 必填（搜索列表）
 * v1.4.3 smart 线索更改 - 市场到网销的自动派单功能
 * v1.5.0 smart 点击保存线索前置判断，“线索等级”变更一旦变更，前端弹窗提示
 * v1.5.4 smart [2024.1.19]紧急上线：“线索等级”，计算规则 >= 200 更改成 >= 180
 * v1.6.1 smart 客资表联动修改
-->
<template>
  <div class="page-box formBase">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="number">线索编号:{{ form_info.info.clue_no }}</div>
    <!-- 表单填写 -->
    <Form class="form-box" ref="formRef">
      <!-- 项目 -->
      <div class="title_box">项目</div>
      <p class="label-name">
        <em>*</em>
        客户姓名
      </p>
      <Field
        v-model.trim="form_info.info.name"
        placeholder="填写客户姓名"
        maxlength="20"
        name="validator"
        :rules="[
          {
            required: true,
            validator: validatorName,
            message: '请填写正确的客户姓名',
            trigger: 'onChange',
          },
        ]"
      />
      <p class="label-name">
        <em>*</em>
        联系方式
        <span
          class="pull-right record"
          @click="picker.contact_record_show = true"
        >
          <svg class="icon-small" aria-hidden="true">
            <use xlink:href="#icon-file-list-fill"></use>
          </svg>
          历史记录
        </span>
      </p>
      <!--特殊： 非超管，联系方式为空或者为11位数字，不可编辑-->
      <Field
        v-if="isPhoneEdit()"
        readonly
        disabled
        v-model.trim="form_info.info.phone"
        placeholder="请在企微备注客户手机号"
        maxlength="20"
      />
      <!-- 可以编辑  -->
      <Field
        v-else
        v-model.trim="form_info.info.phone"
        placeholder="填写联系方式"
        maxlength="20"
      />
      <p class="label-name">性别</p>
      <Field
        v-model="form_info.info.gender_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择性别"
        @click="picker.gender_show = true"
        class="mb20"
      />
      <div
        v-if="
          1 === 2 &&
          (form_info.info?.source_type == 1 || form_info.info?.source_type == 2)
        "
      >
        <p class="label-name">
          <em>*</em>
          线索等级
          <Icon name="question" color="#FFAE4E" @click.stop="getInstructions" />
        </p>
        <Field
          v-model="form_info.info.level_name"
          right-icon="arrow-down"
          readonly
          placeholder="选择线索等级"
          @click="picker.level_show = true"
          :rules="[
            {
              required: true,
              trigger: 'onSubmit',
              message: '请选择线索等级',
            },
          ]"
        />
      </div>
      <p class="label-name">项目等级</p>
      <Field
        v-model="form_info.info.dispatch_level_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择项目等级"
        @click="picker.dispatch_level_show = true"
      />

      <p class="label-name">楼盘</p>
      <Field
        v-model="form_info.info.floor_name"
        readonly
        placeholder="选择楼盘"
        @click="picker.floor_show = true"
      />
      <p class="label-name">单元栋号</p>
      <Field
        v-model="form_info.info.floor_detail_name"
        readonly
        placeholder="选择单元栋号"
        @click="picker.floor_detail_show = true"
      />
      <p class="label-name-flex">
        <span class="left">区域位置</span>
        <Checkbox
          v-model="form_info.is_city_unknown_type"
          icon-size="16px"
          shape="square"
          style="font-size: 15px"
          @change="changeCityCheck"
        >
          区域未知
        </Checkbox>
      </p>
      <Field
        v-model="form_info.info.city_name"
        readonly
        placeholder="选择区域位置"
        @click="picker.city_show = true"
        :disabled="form_info.is_city_unknown_type"
      />
      <p class="label-name-flex">
        <span class="left">面积</span>
        <Checkbox
          v-model="form_info.is_size_unknown_type"
          icon-size="16px"
          shape="square"
          style="font-size: 15px"
          @change="changeSizeCheck"
        >
          面积未知
        </Checkbox>
      </p>
      <Field
        v-model="form_info.info.size"
        :type="messageHide ? 'text' : 'number'"
        :rules="[
          {
            message: '请填写正确的房屋面积',
            validator: validatorSize,
            trigger: 'onChange',
          },
        ]"
        placeholder="填写房屋面积"
        :disabled="form_info.is_size_unknown_type"
      >
        <template #button>
          <text>㎡</text>
        </template>
      </Field>

      <p class="label-name">详细地址</p>
      <Field
        v-model="form_info.info.address"
        placeholder="填写详细地址"
        maxlength="50"
      />
      <p class="label-name">购房情况</p>
      <Field
        v-model="form_info.info.house_type_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择购房情况"
        @click="picker.house_type_show = true"
      />
      <div
        class="active-box"
        v-if="
          form_info?.info?.house_type_name === '期房' ||
          form_info?.info?.house_type_name === '半年内期房' ||
          form_info?.info?.house_type_name === '未购房'
        "
      >
        时间未知
        <Checkbox
          v-model="form_info.is_time_unknown_type"
          @change="changeCheck"
        />
      </div>
      <!-- 预计交房时间 -->
      <div
        v-if="
          form_info.info.house_type_name === '期房' ||
          form_info.info.house_type_name === '半年内期房'
        "
      >
        <p class="label-name">
          <em v-if="!form_info.is_time_unknown_type">*</em>
          预计交房时间
        </p>
        <Field
          v-model="form_info.info.plan_delivery_day"
          name="picker"
          readonly
          placeholder="选择预计交房时间"
          right-icon="underway-o"
          @click="
            !form_info.is_time_unknown_type
              ? (picker.plan_delivery_day_show = true)
              : (picker.plan_delivery_day_show = false)
          "
          :rules="
            form_info.is_time_unknown_type
              ? [{ required: false }]
              : [
                  {
                    required: true,
                    trigger: 'onSubmit',
                    message: '请选择预计交房时间',
                  },
                ]
          "
          :disabled="form_info.is_time_unknown_type"
        />
      </div>
      <!-- 预计购房时间 -->
      <div v-if="form_info.info.house_type_name === '未购房'">
        <p class="label-name">
          <em v-if="!form_info.is_time_unknown_type">*</em>
          预计购房时间
        </p>
        <Field
          v-model="form_info.info.plan_buy_day"
          name="picker"
          readonly
          placeholder="选择预计购房时间"
          right-icon="underway-o"
          @click="
            !form_info.is_time_unknown_type
              ? (picker.plan_buy_day_show = true)
              : (picker.plan_buy_day_show = false)
          "
          :rules="
            form_info.is_time_unknown_type
              ? [{ required: false }]
              : [
                  {
                    required: true,
                    trigger: 'onSubmit',
                    message: '请选择预计购房时间',
                  },
                ]
          "
          :disabled="form_info.is_time_unknown_type"
        />
      </div>

      <p class="label-name">精装/毛坯</p>
      <Field
        v-model="form_info.info.house_category_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择精装/毛坯"
        @click="picker.house_category_show = true"
      />
      <p class="label-name">房屋结构</p>
      <Field
        v-model="form_info.info.home_type_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择房屋结构"
        @click="picker.home_type_show = true"
        class="mb20"
      />
      <!-- 企微好友添加 -->
      <div class="title_box">企微好友添加</div>
      <!-- 只有全部编辑权限的人（超管）可以编辑 -->
      <p class="label-name">添加状态</p>
      <Field
        v-model="form_info.info.wechat_friend_status_name"
        right-icon="arrow-down"
        readonly
        :disabled="auth_form.wechat_disabled"
        placeholder="选择添加状态"
        @click="
          () => {
            if (auth_form.wechat_disabled) {
              return;
            }
            picker.wechat_friend_status_show = true;
          }
        "
      />
      <p class="label-name">external_userId</p>
      <Field
        v-model="form_info.info.external_userid"
        :disabled="auth_form.wechat_disabled"
        placeholder="填写"
        maxlength="50"
      />

      <p class="label-name">unionId</p>
      <Field
        v-model="form_info.info.wechat_unionid"
        :disabled="auth_form.wechat_disabled"
        placeholder="填写"
        maxlength="50"
      />
      <div class="cell_info">
        <div class="left">企微联系人</div>
        <div class="right">
          {{
            form_info.info.wechat_contact_name
              ? `${form_info.info.wechat_contact_name}[${form_info.info.wechat_contact_company}/${form_info.info.wechat_contact_department}/${form_info.info.wechat_contact_post}]`
              : '-'
          }}
        </div>
      </div>
      <div class="cell_info">
        <div class="left">添加客户时间</div>
        <div class="right">
          {{ form_info.info.wechat_customer_add_time || '-' }}
        </div>
      </div>
      <div class="cell_info">
        <div class="left">好友添加方式</div>
        <div class="right">
          {{ form_info.info.wechat_friend_add_way_name || '-' }}
        </div>
      </div>
      <div class="cell_info mb20">
        <div class="left">首次备注手机号</div>
        <div class="right">{{ form_info.info.first_phone_remark || '-' }}</div>
      </div>
      <!-- 当渠道来源为“系统同步”时，渠道来源、渠道申请人、渠道信息为可编辑状态，超管不受限制 -->
      <!-- 来源 -->
      <div class="title_box">来源</div>
      <p class="label-name">
        <em>*</em>
        渠道来源
      </p>
      <Field
        v-model="form_info.info.channel_source_name"
        right-icon="arrow-down"
        readonly
        :disabled="auth_form.channel_disabled"
        placeholder="选择渠道来源"
        @click="
          () => {
            //不是超管并且不是系统同步
            if (auth_form.channel_disabled) {
              return;
            }
            picker.channel_source_show = true;
          }
        "
        :rules="[
          {
            required: true,
            message: '请选择渠道来源',
            trigger: 'onChange',
          },
        ]"
      />
      <div v-if="form_info.info.channel_source != 84">
        <p class="label-name">申请人</p>
        <Field
          v-model="form_info.channelUser"
          readonly
          :disabled="auth_form.channel_disabled"
          placeholder="选择渠道申请人"
          @click="
            () => {
              //不是超管并且不是系统同步
              if (auth_form.channel_disabled) {
                return;
              }
              picker.channel_user_show = true;
            }
          "
        />
      </div>
      <div v-if="form_info.info.channel_source == 84">
        <p class="label-name">
          <em>*</em>
          申请人
        </p>
        <Field
          v-model="form_info.channelUser"
          readonly
          placeholder="选择渠道申请人"
          @click="
            () => {
              picker.construction_channel_show = true;
            }
          "
          :rules="[
            {
              required: true,
              trigger: 'onSubmit',
              message: '请选择渠道申请人',
            },
          ]"
        />
      </div>

      <p class="label-name">订单号</p>
      <Field
        :disabled="auth_form.channel_disabled"
        v-model="form_info.info.channel_no"
        placeholder="填写渠道订单号"
        maxlength="20"
      />

      <div v-if="form_info.info.channel_source != 84">
        <p class="label-name">渠道信息</p>
        <Field
          :readonly="auth_form.channel_disabled"
          :disabled="auth_form.channel_disabled"
          v-model="form_info.info.channel_info"
          placeholder="填写渠道信息"
          maxlength="1000"
          class="mb20"
          :rows="3"
          autosize
          type="textarea"
          show-word-limit
        />
      </div>

      <p class="label-name" v-if="form_info.info.channel_source == 84">
        <em>*</em>
        返单工地信息
      </p>
      <Field
        v-if="form_info.info.channel_source == 84"
        v-model="form_info.constructionName"
        readonly
        placeholder="选择返单工地信息"
        @click="
          () => {
            picker.construction_id_show = true;
          }
        "
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请选择返单工地信息',
          },
        ]"
      />

      <!-- 系统信息 -->
      <div class="title_box">
        系统信息
        <span
          class="pull-right record"
          @click="picker.distribution_record_show = true"
        >
          <svg class="icon-small" aria-hidden="true">
            <use xlink:href="#icon-file-list-fill"></use>
          </svg>
          分配历史
        </span>
      </div>
      <!-- 分配规则，负责人只有超管能改；有企微联系人时，邀约人不可编辑，超管可以编辑；网销轮派时，负责人邀约人清空并禁用 -->
      <p class="label-name">分配规则</p>
      <Field
        v-model="form_info.info.distribution_rule_name"
        right-icon="arrow-down"
        readonly
        disabled
        placeholder="-"
      />
      <p class="label-name">负责人</p>
      <Field
        v-model="form_info.responsibleUser"
        readonly
        disabled
        placeholder="-"
      />
      <p class="label-name">邀约人</p>
      <Field v-model="form_info.inviteUser" readonly disabled placeholder="-" />
      <div class="cell_info">
        <div class="left">最近分配</div>
        <div class="right">
          <p>
            {{
              form_info.info.distribution_user_name
                ? `${form_info.info.distribution_user_name}[${form_info.info.distribution_user_company}/${form_info.info.distribution_user_department}/${form_info.info.distribution_user_post}]`
                : '-'
            }}
          </p>
          <p>{{ form_info.info.distribution_time || '-' }}</p>
        </div>
      </div>

      <div class="cell_info">
        <div class="left">已转客户编号</div>
        <div class="right">
          {{ form_info.info.clue_turn_customer_no || '-' }}
        </div>
      </div>
      <div class="cell_info">
        <div class="left">最近转客户时间</div>
        <div class="right">{{ form_info.info.turn_customer_time || '-' }}</div>
      </div>
      <div class="cell_info">
        <div class="left">线索创建</div>
        <div class="right" v-if="form_info.info?.add_time">
          <p>
            <span v-if="form_info.info?.add_uid == -1">系统机器人</span>
            <span v-else>
              {{
                form_info.info.add_user_name
                  ? form_info.info.add_user_name
                  : '-'
              }}{{
                form_info.info.add_user_department
                  ? `[${form_info.info.add_user_company}/${form_info.info.add_user_department}/${form_info.info.add_user_post}]`
                  : ''
              }}
            </span>
          </p>
          <p>{{ form_info.info.add_time }}</p>
        </div>
        <div v-else class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">最近修改</div>
        <div class="right" v-if="form_info.info?.update_time">
          <p>
            <span v-if="form_info.info.update_uid == -1">系统机器人</span>
            <span v-else>
              {{
                form_info.info.update_user_name
                  ? form_info.info.update_user_name +
                    '[' +
                    form_info.info.update_user_company +
                    '/' +
                    form_info.info.update_user_department +
                    '/' +
                    form_info.info.update_user_post +
                    ']'
                  : '-'
              }}
            </span>
          </p>
          <p>{{ form_info.info.update_time }}</p>
        </div>
        <div v-else class="right">-</div>
      </div>
    </Form>
    <div class="stick-box">
      <Row gutter="20">
        <Col
          :span="24 / buttonObj.list?.length"
          v-for="(item, index) in buttonObj.list"
          :key="index"
        >
          <Button
            size="large"
            class="button-danger"
            @click="onSubmit(item)"
            :type="item.btn_type"
            :loading="item.loadingBtn"
            :loading-text="item.name"
          >
            {{ item.name }}
          </Button>
        </Col>
      </Row>
    </div>
    <!-- end -->
    <!-- 基础信息 - 性别 -->
    <Popup v-model:show="picker.gender_show" position="bottom">
      <Picker
        :columns="options.gender_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        cancel-button-text="清除"
        @confirm="(value) => changePicker(value, 'gender')"
        @cancel="clearPicker('gender')"
        :default-index="
          options.gender_list.findIndex((v) => {
            return v.id == form_info.info.gender;
          })
        "
      />
    </Popup>
    <!-- 项目信息 - 线索等级 -->
    <Popup v-model:show="picker.level_show" position="bottom" :key="keyNumber">
      <Picker
        :columns="options.clue_level"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'level')"
        @cancel="clearPicker('level')"
        cancel-button-text="清除"
        :default-index="
          options.clue_level.findIndex((v) => {
            return v.id == form_info.info.level;
          })
        "
      />
    </Popup>
    <!-- 项目信息 - 项目等级 -->
    <Popup v-model:show="picker.dispatch_level_show" position="bottom">
      <Picker
        :columns="options.dispatch_level"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'dispatch_level')"
        @cancel="clearPicker('dispatch_level')"
        cancel-button-text="清除"
        :default-index="
          options.dispatch_level.findIndex((v) => {
            return v.id == form_info.info.dispatch_level;
          })
        "
      />
    </Popup>
    <!-- 项目信息 - 楼盘 -->
    <Popup
      v-model:show="picker.floor_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempFloor
        v-if="picker.floor_show"
        @reviewClose="
          () => {
            picker.floor_show = false;
          }
        "
        :kw="form_info.info.floor_name"
        :check="form_info.info.floor_id"
        @reviewOk="reviewOk_floor"
      />
    </Popup>
    <!-- 项目信息 - 单元栋号-->
    <Popup
      v-model:show="picker.floor_detail_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempFloorDetail
        ref="floorDetailRef"
        :data="options.floor_detail_search_list"
        :check="form_info.info.floor_detail_id"
        v-if="picker.floor_detail_show"
        @reviewClose="
          () => {
            picker.floor_detail_show = false;
          }
        "
        @reviewOk="reviewOk_floorDetail"
      />
    </Popup>
    <!-- 项目信息 - 区域位置 -->
    <Popup v-model:show="picker.city_show" position="bottom">
      <Picker
        :columns="options.city"
        :columns-field-names="{
          text: 'name',
          value: 'id',
          children: 'sub',
        }"
        cancel-button-text="清除"
        @confirm="(value) => changePicker(value, 'city')"
        @cancel="clearPicker('city')"
        ref="cityRef"
      />
    </Popup>
    <!-- 项目信息 - 购房情况 -->
    <Popup v-model:show="picker.house_type_show" position="bottom">
      <Picker
        :columns="options.house_type"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'house_type')"
        @cancel="clearPicker('house_type')"
        cancel-button-text="清除"
        :default-index="
          options.house_type.findIndex((v) => {
            return v.id == form_info.info.house_type;
          })
        "
      />
    </Popup>
    <!-- 项目信息 - 预计购房时间 -->
    <Popup v-model:show="picker.plan_buy_day_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="预计购房时间"
        @confirm="(value) => changePicker(value, 'plan_buy_day')"
        @cancel="clearPicker('plan_buy_day')"
        cancel-button-text="清除"
      />
    </Popup>
    <!-- 项目信息 - 预计交房时间 -->
    <Popup v-model:show="picker.plan_delivery_day_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="预计交房时间"
        @confirm="(value) => changePicker(value, 'plan_delivery_day')"
        @cancel="clearPicker('plan_delivery_day')"
        cancel-button-text="清除"
      />
    </Popup>
    <!-- 项目信息 - 精装/毛坯 -->
    <Popup v-model:show="picker.house_category_show" position="bottom">
      <Picker
        :columns="options.house_category"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'house_category')"
        @cancel="clearPicker('house_category')"
        cancel-button-text="清除"
        :default-index="
          options.house_category.findIndex((v) => {
            return v.id == form_info.info.house_category;
          })
        "
      />
    </Popup>
    <!-- 项目信息 - 房屋结构 -->
    <Popup v-model:show="picker.home_type_show" position="bottom">
      <Picker
        :columns="options.home_type"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'home_type')"
        @cancel="clearPicker('home_type')"
        cancel-button-text="清除"
        :default-index="
          options.home_type.findIndex((v) => {
            return v.id == form_info.info.home_type;
          })
        "
      />
    </Popup>
    <!-- 来源信息 - 渠道来源 -->
    <Popup v-model:show="picker.channel_source_show" position="bottom">
      <Picker
        :columns="options.channel_source"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'channel_source')"
        @cancel="clearPicker('channel_source')"
        cancel-button-text="清除"
        :default-index="
          options.channel_source.findIndex((v) => {
            return v.id == form_info.info.channel_source;
          })
        "
      />
    </Popup>
    <!-- 来源信息 - 渠道申请人-->
    <Popup
      v-model:show="picker.channel_user_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempUser
        v-if="picker.channel_user_show"
        :data="'选择渠道申请人'"
        :check="form_info.info.channel_user_id"
        :kw="form_info.channelUser"
        @reviewClose="
          () => {
            picker.channel_user_show = false;
          }
        "
        @reviewOk="(item) => reviewOk_person(item, '渠道申请人')"
      />
    </Popup>
    <!-- 来源信息 - 渠道申请人（针对渠道来源 = 84工地返单） -->
    <Popup
      v-model:show="picker.construction_channel_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempList
        v-if="picker.construction_channel_show"
        :data="'选择渠道申请人'"
        :check="form_info.info.channel_user_id"
        :kw="form_info.channelUser"
        :requestType="2"
        @reviewClose="
          () => {
            picker.construction_channel_show = false;
          }
        "
        @reviewOk="(item) => reviewOk_person(item, '工地-渠道申请人')"
      />
    </Popup>
    <!-- 来源信息 - 返单工地信息（针对渠道来源 = 84工地返单） -->
    <Popup
      v-model:show="picker.construction_id_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempList
        v-if="picker.construction_id_show"
        :data="'选择返单工地信息'"
        :check="form_info.info.construction_id"
        :kw="form_info.constructionName"
        :requestType="1"
        @reviewClose="
          () => {
            picker.construction_id_show = false;
          }
        "
        @reviewOk="(item) => reviewOk_person(item, '返单工地信息')"
      />
    </Popup>

    <!-- 企微好友 - 添加状态 -->
    <Popup v-model:show="picker.wechat_friend_status_show" position="bottom">
      <Picker
        :columns="options.wechat_friend_status"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changePicker(value, 'wechat_friend_status')"
        @cancel="picker.wechat_friend_status_show = false"
        :default-index="
          options.wechat_friend_status.findIndex((v) => {
            return v.id == form_info.info.wechat_friend_status;
          })
        "
      />
    </Popup>
    <!-- 联系方式记录-->
    <Popup
      v-model:show="picker.contact_record_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <clueContactRecord
        v-if="picker.contact_record_show"
        :data="clueID"
        @reviewClose="
          () => {
            picker.contact_record_show = false;
          }
        "
      />
    </Popup>
    <!-- 邀约人分配记录-->
    <Popup
      v-model:show="picker.distribution_record_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <clueDistributionRecord
        v-if="picker.distribution_record_show"
        :data="clueID"
        @reviewClose="
          () => {
            picker.distribution_record_show = false;
          }
        "
      />
    </Popup>
    <!-- 来源信息 - 渠道来源 -->
    <Popup
      v-model:show="picker.channel_source_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempChannelSource
        v-if="picker.channel_source_show"
        :data="'选择渠道来源'"
        :check="form_info.info.channel_source"
        :kw="form_info.info.channel_source_name"
        :auth="1"
        @reviewClose="
          () => {
            picker.channel_source_show = false;
          }
        "
        @reviewOk="(item) => changePicker(item, 'channel_source')"
      />
    </Popup>
  </div>
</template>
<script setup>
import { inject, onMounted, reactive, ref, computed, watch } from 'vue';
import {
  Form,
  Field,
  Button,
  Notify,
  Loading,
  Overlay,
  Row,
  Col,
  Picker,
  Popup,
  Dialog,
  Checkbox,
  DatetimePicker,
  Icon,
} from 'vant';
import moment from 'moment';

import TempFloor from '@/components/clue/TempFloor';
import TempFloorDetail from '@/components/clue/TempFloorDetail';
import TempUser from '@/components/clue/TempUser';
import TempList from '@/components/clue/TempList';
import TempChannelSource from '@/components/clue/TempChannelSource';

import clueContactRecord from '@/components/clue/clueContactRecord';
import clueDistributionRecord from '@/components/clue/clueDistributionRecord';
import patterns from '@/utils/pattern';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);

const $http = inject('$http');
const formRef = ref(null);
const router = useRouter();

const clueID = ref(router.currentRoute.value.query.id);
const messageHide = ref(false);

const form_info = reactive({
  userInfo: {
    name: '', ////登录账号：姓名[苏州/部门/岗位]
    id: '', //登录id
  },
  info: {},
  infoCopy: {},
  old_contact: '',
  channelUser: '', //渠道申请人
  responsibleUser: '', //负责人
  invitePerson: '', //邀约人
  constructionName: '', //返单工地信息
  is_time_unknown_type: false, //时间未知（预计购房时间、预计交房时间）
  is_city_unknown_type: false, //区域未知
  is_size_unknown_type: false, //面积未知
});

const options = reactive({});
const loading = ref(false);
const currentDate = ref(new Date());
const keyNumber = ref(Date.parse(new Date()));

onMounted(() => {
  getPublicParameter();
  getUserFunc();
  messageHide.value =
    allOperationAuthority?.value?.filter((e) => ['241', '391'].includes(e))
      ?.length == 0;
});

/**
 * 接口
 */
// -- 参数
const getPublicParameter = async () => {
  loading.value = true;
  $http
    .getSaleOptionsApi()
    .then((res) => {
      if (res.code === 0) {
        Object.assign(options, res.data.customer);
        options.city = res.data.city;
        loadFunc();
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: res.data });
      }
    })
    .then(() => {
      // 线索等级 格式化
      const clue_level = options.clue_level.map((item) => {
        return { id: item.id, name: item.name, disabled: false };
      });
      options.clue_level = clue_level;
    });
};

// -- 详情
const cityRef = ref(null);

const loadFunc = () => {
  $http.getClueInfo({ id: clueID.value }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      form_info.infoCopy = JSON.parse(JSON.stringify(data));
      form_info.old_contact = data.phone;
      // 渠道申请人
      form_info.channelUser = data.channel_user_name
        ? getStr(
            data.channel_user_name,
            data.channel_user_company,
            data.channel_user_department,
            data.channel_user_post
          )
        : '';
      // 负责人
      form_info.responsibleUser = data.responsible_user_name
        ? getStr(
            data.responsible_user_name,
            data.responsible_user_company,
            data.responsible_user_department,
            data.responsible_user_post
          )
        : '';
      // 邀约人
      form_info.inviteUser = data.invite_user_name
        ? getStr(
            data.invite_user_name,
            data.invite_user_company,
            data.invite_user_department,
            data.invite_user_post
          )
        : '';
      // 返单工地信息
      form_info.constructionName = data.construction_name;
      if (data.floor_id) {
        $http
          .getFloorsApi({
            kw: data.floor_name,
          })
          .then((res2) => {
            if (res2.code === 0) {
              options.floor_detail_search_list =
                res2.data?.floor_detail_list?.filter(
                  (i) => i.floor_id == data.floor_id
                ) || [];
            }
          });
      }
      form_info.is_time_unknown_type = data.is_time_unknown == 1 ? true : false;
      form_info.is_city_unknown_type = data.is_city_unknown == 1 ? true : false;
      form_info.is_size_unknown_type = data.is_size_unknown == 1 ? true : false;
      //- v1.4.3派单初次赋值
      levelOptionsHandle(
        [data.size, data.house_category, data.home_type, data.source_type],
        data.level
      );
      //特殊权限
      initialAuthority(data);
      //- 操作按钮
      btnVisibleFunc(data.status, data.is_meet?.id);
      Object.assign(form_info.info, data);
    } else {
      Notify({ type: 'warning', message: data });
    }
    loading.value = false;
  });
};

// -- 获取登录者信息
const getUserFunc = () => {
  $http.getUserInfo().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      const info = JSON.parse(JSON.stringify(data));
      const userInfo = `${info.name}[${info?.company_name}/${info.department_name}/${info.post_name}]`;
      form_info.userInfo.name = userInfo;
      form_info.userInfo.id = info.id;
      form_info.responsibleUser = userInfo;
    } else {
      form_info.responsibleUser = '';
    }
  });
};

/**
 * 校验方式
 */
const { houseSizeReg, phoneReg } = patterns;

const isPhoneEdit = () => {
  return (
    allOperationAuthority.value.indexOf('241') <= -1 &&
    (!form_info.old_contact || phoneReg.test(form_info.old_contact))
  );
};

const validatorName = (val) => {
  let result = false;
  if (val && val.length >= 2) {
    result = true;
  }
  return result;
};

const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

/**
 * 操作
 */
// -- 权限控制
const auth_form = reactive({
  phone_disabled: true,
  channel_disabled: true,
  wechat_disabled: true,
});
const initialAuthority = (info) => {
  auth_form.wechat_disabled = allOperationAuthority.value.indexOf('241') <= -1;
  if (info.channel_source != 0) {
    //渠道来源 !== 系统同步 不可编辑、禁用
    auth_form.channel_disabled = true;
    return;
  } else {
    if (info.clue_edit == 1) {
      //241-所有字段编辑权限
      auth_form.channel_disabled = false;
    } else {
      auth_form.channel_disabled = true;
    }
  }
};

// -- 字符串拼接
const getStr = (name, ...value) => {
  if (name) {
    var str = name;
    str = str + '[' + value.join('/') + ']';
    return str;
  }
};

// -- 选择器
const picker = reactive({
  gender_show: false, //性别选择器
  dispatch_level_show: false, //项目等级
  floor_show: false, //楼盘名称
  floor_detail_show: false, //单元栋号
  city_show: false, //区域位置
  house_type_show: false, //购房情况
  house_category_show: false, //精装/毛坯
  home_type_show: false, //房屋结构
  plan_delivery_day_show: false, //预计交房时间
  plan_buy_day_show: false, //预计购房时间
  channel_source_show: false, //渠道来源
  channel_user_show: false, //渠道申请人
  wechat_friend_status_show: false, //企微好友 - 添加状态
  contact_record_show: false, //联系记录pop
  distribution_record_show: false, //分配历史pop
  construction_id_show: false, //返单工地信息pop
  construction_channel_show: false, //渠道来源 == 84工地返单，渠道申请人pop
  level_show: false, //线索等级
});

const changePicker = (value, type) => {
  switch (type) {
    case 'gender':
      // 性别
      picker.gender_show = false;
      form_info.info.gender = value.id;
      form_info.info.gender_name = value.name;
      break;
    case 'level':
      // 线索等级
      picker.level_show = false;
      form_info.info.level = value.id;
      form_info.info.level_name = value.name;
      break;
    case 'dispatch_level':
      picker.dispatch_level_show = false;
      form_info.info.dispatch_level = value.id;
      form_info.info.dispatch_level_name = value.name;
      break;
    case 'city':
      picker.city_show = false;
      form_info.info.city_id = value[2].code;
      form_info.info.city_name =
        value[0].name + '/' + value[1].name + '/' + value[2].name;
      break;
    case 'house_type':
      picker.house_type_show = false;
      form_info.info.house_type = value.id;
      form_info.info.house_type_name = value.name;
      break;
    case 'house_category':
      picker.house_category_show = false;
      form_info.info.house_category = value.id;
      form_info.info.house_category_name = value.name;
      break;
    case 'home_type':
      picker.home_type_show = false;
      form_info.info.home_type = value.id;
      form_info.info.home_type_name = value.name;
      break;
    case 'plan_delivery_day':
      picker.plan_delivery_day_show = false;
      form_info.info.plan_delivery_day = moment(value).format('YYYY-MM-DD');
      break;
    case 'plan_buy_day':
      picker.plan_buy_day_show = false;
      form_info.info.plan_buy_day = moment(value).format('YYYY-MM-DD');
      break;
    case 'channel_source':
      // 渠道来源
      picker.channel_source_show = false;
      form_info.info.channel_source = value?.id;
      form_info.info.channel_source_name = value?.name;
      if (value.id == 84) {
        form_info.info.channel_user_id = '';
        form_info.info.channel_user_name = '';
        form_info.channelUser = '';
      }
      // v1.4.3特殊处理 赋值渠道来源对应的渠道类型type1-集团、2-市场、3-网销自主、4-中心自主
      var source_type = value?.id
        ? options.channel_source.filter((item) => item.id == value?.id)[0].type
        : '';
      form_info.info.source_type = source_type;
      if (source_type != 1 && source_type != 2) {
        form_info.info.level = '';
      } else {
        levelOptionsHandle([
          form_info.info?.size,
          form_info.info?.house_category,
          form_info.info?.home_type,
        ]);
      }
      break;
    case 'wechat_friend_status':
      // 企微好友 - 添加状态
      picker.wechat_friend_status_show = false;
      form_info.info.wechat_friend_status = value.id;
      form_info.info.wechat_friend_status_name = value.name;
      break;
    default:
      break;
  }
};

// -- 选择器清除
const clearPicker = (type) => {
  switch (type) {
    case 'gender':
      // 性别
      picker.gender_show = false;
      form_info.info.gender = '';
      form_info.info.gender_name = '';
      break;
    case 'level':
      // 线索等级
      picker.level_show = false;
      form_info.info.level = '';
      form_info.info.level_name = '';
      break;
    case 'dispatch_level':
      picker.dispatch_level_show = false;
      form_info.info.dispatch_level = '';
      form_info.info.dispatch_level_name = '';
      break;
    case 'city':
      picker.city_show = false;
      form_info.info.city_id = '';
      form_info.info.city_name = '';
      break;
    case 'house_type':
      picker.house_type_show = false;
      form_info.info.house_type = '';
      form_info.info.house_type_name = '';
      break;
    case 'house_category':
      picker.house_category_show = false;
      form_info.info.house_category = '';
      form_info.info.house_category_name = '';
      break;
    case 'home_type':
      picker.home_type_show = false;
      form_info.info.home_type = '';
      form_info.info.home_type_name = '';
      break;
    case 'plan_delivery_day':
      picker.plan_delivery_day_show = false;
      form_info.info.plan_delivery_day = '';
      break;
    case 'plan_buy_day':
      picker.plan_buy_day_show = false;
      form_info.info.plan_buy_day = '';
      break;
    case 'channel_source':
      picker.channel_source_show = false;
      form_info.info.channel_source = '';
      form_info.info.channel_source_name = '';
      break;
    default:
      break;
  }
};

// -- v1.4.3 面积、精装/毛坯、房屋结构修改之后引发的“线索等级”筛选项变动
const levelOptionsHandle = (newVal, level) => {
  console.log(newVal);
  keyNumber.value = Date.parse(new Date());
  const size = newVal[0]; //面积
  const hose_id = newVal[1]; //精装/毛坯
  const home_id = newVal[2]; //房屋结构
  // A类可选：面积 >= 180 && 精装/毛坯 == 101毛坯 && 房屋结构 != (488未知 || 111自建)；
  // B类可选：面积 >= 140 && 精装/毛坯 == 102精装 && 房屋结构 != (488未知 || 111自建)；
  // C类可选：新建时，永远禁止选择，disabled状态；编辑时，永远可选
  // D类可选：不符合上述情况为D；
  form_info.info.level = '';
  if (
    size &&
    parseFloat(size) >= 180 &&
    hose_id == 101 &&
    home_id != 488 &&
    home_id != 111
  ) {
    options.clue_level[0].disabled = false;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = true;
    form_info.info.level = level || 1;
    form_info.info.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[0].name;
    console.log('A+C');
    return;
  } else if (
    (size &&
      parseFloat(size) >= 140 &&
      hose_id == 102 &&
      home_id != 488 &&
      home_id != 111) ||
    (size && parseFloat(size) >= 180 && home_id == 111)
  ) {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = false;
    options.clue_level[3].disabled = true;
    form_info.info.level = level || 2;
    form_info.info.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[1].name;
    console.log('B+C');
    return;
  } else {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = false;
    form_info.info.level = level || 4;
    form_info.info.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[3].name;
    console.log('D+C');
  }
};
watch(
  () => [
    form_info.info.size,
    form_info.info.house_category,
    form_info.info.home_type,
    form_info.info.source_type,
    form_info.info.id,
  ],
  (newVal, oldVal) => {
    if (newVal[3] && Number(newVal[3]) > 2) {
      form_info.info.level = '';
      return;
    }
    if (newVal.toString() !== oldVal.toString() && newVal[4] === oldVal[4]) {
      levelOptionsHandle(newVal);
    }
  }
);

// -- 楼盘popup
const reviewOk_floor = (item, list) => {
  form_info.info.floor_id = item?.id || '';
  form_info.info.floor_name = item?.name || '';
  form_info.info.city_name = item?.city_name || '';
  form_info.info.city_id = item?.city_id || '';
  options.floor_detail_search_list = list;
  form_info.info.floor_detail_id = '';
  form_info.info.floor_detail_name = '';
  form_info.info.size = null;
  picker.floor_show = false;
};

// -- 单元栋号popup
const reviewOk_floorDetail = (data) => {
  form_info.info.floor_detail_id = data?.id || '';
  form_info.info.floor_detail_name = data?.detail_name || '';
  form_info.info.size = data?.size || null;
  picker.floor_detail_show = false;
};

// -- 时间未知
const changeCheck = (value) => {
  if (value) {
    form_info.info.plan_buy_day = undefined;
    form_info.info.plan_delivery_day = undefined;
  }
};

// -- 区域未知
const changeCityCheck = (value) => {
  if (value) {
    form_info.info.city_name = '';
    form_info.info.city_id = '';
  }
};

// -- 面积未知
const changeSizeCheck = (value) => {
  if (value) {
    form_info.info.size = '';
  }
};

// -- 渠道申请人
const reviewOk_person = (item, type) => {
  if (type === '返单工地信息') {
    form_info.constructionName = item.name;
    form_info.info.construction_id = item?.id || '';
    picker.construction_id_show = false;
    return;
  }
  const nameStr = item.name
    ? `${item.name}[${item.company_name}/${item.dep_name}/${item.post_name}]`
    : '';
  if (type === '渠道申请人') {
    form_info.channelUser = nameStr;
    form_info.info.channel_user_id = item?.id || '';
    picker.channel_user_show = false;
  } else if (type === '工地-渠道申请人') {
    form_info.channelUser = nameStr;
    form_info.info.channel_user_id = item?.id || '';
    picker.construction_channel_show = false;
  }
};

// -- v1.4.3新增线索等级 文字信息提示
const getInstructions = () => {
  Dialog.confirm({
    title: '',
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#576B95',
    className: 'dialogDescripton',
    message: `<div class="description"><h3>线索等级判定方式</h3><p>1.A类 - 200平米以上毛坯</p><p>2.B类 - 140平米以上精装、200平米以上自建</p><p>3.C类 - 经判定已无法联系或无需求线索</p><p>4.D类 - 非上述范围，或信息不全的线索</p></div>`,
    allowHtml: true,
  });
};

const buttonObj = reactive({
  list: [],
});

// -- 操作按钮 根据权限379申请派单权限、是否已转客户判断展示隐藏
const btnVisibleFunc = (status, is_meet) => {
  const auth_apply = allOperationAuthority.value.indexOf('379') > -1;
  const auth_distribution =
    allOperationAuthority.value.indexOf('541') > -1 ||
    allOperationAuthority.value.indexOf('542') > -1;
  if (status == 1) {
    //待分配线索
    buttonObj.list = auth_distribution
      ? [{ name: '派单分配', loadingBtn: false, btn_type: 'primary' }]
      : [];
  } else if (status == 2) {
    //待完善线索
    buttonObj.list = auth_apply
      ? [
          { name: '保存线索', loadingBtn: false },
          { name: '设计派单', loadingBtn: false, btn_type: 'primary' },
        ]
      : [
          { name: '保存线索', loadingBtn: false },
          { name: '生成客户', loadingBtn: false, btn_type: 'primary' },
        ];
  } else if (status == 3) {
    //已转客户
    if (!auth_apply) {
      buttonObj.list = [{ name: '保存线索', loadingBtn: false }];
    } else {
      buttonObj.list =
        is_meet == 1
          ? [
              { name: '保存线索', loadingBtn: false },
              {
                name: '设计改派',
                loadingBtn: false,
                btn_type: 'primary',
              },
            ]
          : [
              { name: '保存线索', loadingBtn: false },
              {
                name: '设计多派',
                loadingBtn: false,
                btn_type: 'primary',
              },
            ];
    }
  }
};
/**
 * 表单提交
 */
// -- 步骤1: 一系列前提验证
const onSubmit = (btnItem) => {
  formRef.value?.validate().then(() => {
    //1.1: 当渠道来源包含“客户经理渠道、设计师渠道、市拓话单、市拓渠道“，申请人、渠道信息两个字段为必填
    if (
      form_info.info.channel_source == 242 ||
      form_info.info.channel_source == 246 ||
      form_info.info.channel_source == 44 ||
      form_info.info.channel_source == 20
    ) {
      if (!form_info.info.channel_user_id) {
        Notify({ type: 'warning', message: '请选择渠道申请人' });
        return;
      } else if (!form_info.info.channel_info) {
        Notify({ type: 'warning', message: '请填写渠道信息' });
        return;
      }
    }

    //1.2: 手动分配邀约人,邀约人必填
    if (
      form_info.info.distribution_rule_id == 1 &&
      !form_info.info.invite_user_id
    ) {
      Notify({ type: 'warning', message: '请选择邀约人' });
      return;
    }

    //1.3: 联系方式有修改的话校验为11位数字，否则弹提示‘联系方式更新必须为11位手机号！
    if (!phoneReg.test(form_info.info.phone)) {
      Notify({ type: 'warning', message: '联系方式更新必须为11位手机号！' });
      return;
    }

    //1.4: 设计多派、设计派单、申请多派、设计改派二次确认
    if (btnItem.name === '设计多派') {
      Dialog.confirm({
        message: '是否确认对 ' + form_info.info.name + ' 发起多派？',
        confirmButtonText: '确认发起',
        confirmButtonColor: '#0256FF',
      }).then(() => {
        changePhoneFunc(btnItem);
      });
      return;
    } else if (
      btnItem.name === '设计派单' ||
      btnItem.name === '申请多派' ||
      btnItem.name === '设计改派'
    ) {
      Dialog.confirm({
        message:
          '是否确认对 ' + form_info.info.name + ' ' + btnItem.name + '？',
        confirmButtonText: '确认申请',
        confirmButtonColor: '#0256FF',
      }).then(() => {
        changePhoneFunc(btnItem);
      });
      return;
    }
    changePhoneFunc(btnItem);
  });
};

// -- 步骤2: 联系方式是否更改
const changePhoneFunc = (btnItem) => {
  if (form_info.info.phone !== form_info.old_contact) {
    Dialog.confirm({
      message:
        '客户联系方式修改为' +
        form_info.info.phone +
        ',是否确认？此操作无法撤销！',
      confirmButtonColor: '#0256FF',
    }).then(() => {
      validateLevel(btnItem);
      // saveInfo(btnItem);
    });
  } else {
    validateLevel(btnItem);
    // saveInfo(btnItem);
  }
};

// -- 步骤3: 编辑线索接口（v1.5.0前置判断：点击保存线索，前端校验“线索等级”是否发生变更，弹窗提示）
// -- v1.6.0 2024.5.18 去除此弹窗
const validateLevel = (btnItem) => {
  console.log(form_info.infoCopy.level, form_info.info.level);
  saveInfo(btnItem);
  // if (
  //   btnItem.name === '保存线索' &&
  //   form_info.infoCopy.level != form_info.info.level
  // ) {
  //   Dialog.confirm({
  //     message: '信息已调整，请填写跟进记录，否则无法变更线索等级！',
  //     confirmButtonColor: '#0256FF',
  //     cancelButtonText: '回头再说',
  //     confirmButtonText: '立即添加',
  //   })
  //     .then(() => {
  //       // 立即添加：保存线索-成功之后-跳转新增跟进记录
  //       saveInfo(btnItem, '立即添加');
  //     })
  //     .catch(() => {
  //       // 回头再说
  //       saveInfo(btnItem);
  //     });
  // } else {
  //   saveInfo(btnItem);
  // }
};

// -- 步骤3: 编辑线索接口
const saveInfo = (btnItem, type_name) => {
  btnItem.loadingBtn = true;
  // 未知转换
  form_info.info.is_time_unknown = form_info.is_time_unknown_type ? 1 : 2;
  form_info.info.is_city_unknown = form_info.is_city_unknown_type ? 1 : 2;
  form_info.info.is_size_unknown = form_info.is_size_unknown_type ? 1 : 2;
  $http
    .editClue(form_info.info)
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        if (btnItem.name == '保存线索') {
          Notify({
            type: 'success',
            message: '操作成功',
            duration: 1000,
            onClose: () => {
              loading.value = false;
              if (type_name === '立即添加') {
                router.replace({
                  path: '/clueFollowNew',
                  query: { cludID: clueID.value },
                });
              } else {
                router.back();
              }
            },
          });
        } else {
          creatCustomerFunc(btnItem);
        }
      } else {
        btnItem.loadingBtn = false;
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
        });
      }
    })
    .catch(() => {
      btnItem.loadingBtn = false;
    });
};

// -- 步骤4：生成客户
const creatCustomerFunc = (btnItem) => {
  //v1.4.1 除了保存线索，其余操作均需拦截判断邀约人是否填写
  // if (!form_info.info.invite_user_id) {
  //   Notify({ type: 'warning', message: '请选择邀约人！' });
  //   return;
  // }
  $http
    .clueCreateCustomer({ clue_id: clueID.value })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        loading.value = false;
        btnItem.loadingBtn = false;
        if (data.customer_id) {
          //生成客户成功
          applyDispatchFunc(btnItem, data);
        } else {
          //生成客户失败
          router.push({
            path: '/clueCreateCustomer',
            query: { id: clueID.value, info: JSON.stringify(data) },
          });
        }
      } else {
        loading.value = false;
        btnItem.loadingBtn = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      btnItem.loadingBtn = false;
      loading.value = false;
    });
};

// -- 提交表单 步骤5:生成客户、设计多派(没有 379申请设计派单权限) ||设计派单、申请多派、设计改派（有379申请设计派单权限）
const applyDispatchFunc = (btnItem, data) => {
  if (btnItem.name === '生成客户') {
    Dialog.confirm({
      message: `客户已生成 ${data.customer_no},您现在可以查看客户，并操作申请设计派单！`,
      confirmButtonText: '查看客户',
      cancelButtonText: '返回',
      confirmButtonColor: '#0256FF',
    })
      .then(() => {
        router.push({
          path: '/sale',
          query: { id: data.customer_id },
        });
      })
      .catch(() => {
        router.back();
      });
  } else if (
    btnItem.name === '设计派单' ||
    btnItem.name === '申请多派' ||
    btnItem.name === '设计改派' ||
    btnItem.name === '设计多派'
  ) {
    router.push({
      path: '/clueDesignDispatch',
      query: {
        id: data.customer_id,
        NO: data.customer_no,
        clueID: clueID.value,
      },
    });
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueEditInfo.less';
</style>
