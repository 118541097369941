<!--
 * @Author: Jessica
 * @Date: 2023-09-19 16:20:48
 * @LastEditTime: 2024-05-11 16:57:38
 * @Description: 财务大屏-成本
-->
<template>
  <!-- 成本 -->
  <div class="main-data">
    <div class="warpper">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_8.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                成本
                {{ detail.finance_cost?.actual + '万' ?? '-' }}
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 产品 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">产品</p>
                </div>
              </Col>
              <Col class="flex_right" span="17">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.finance_product_cost?.actual + '万' ?? '-' }}
                    </em>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <!-- 施工 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">施工</p>
                </div>
              </Col>
              <Col class="flex_right" span="17">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{
                        detail.finance_construction_cost?.actual + '万' ?? '-'
                      }}
                    </em>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <!-- 整装 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">整装</p>
                </div>
              </Col>
              <Col class="flex_right" span="17">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.finance_fine_cost?.actual + '万' ?? '-' }}
                    </em>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { Skeleton, Row, Col } from 'vant';
const props = defineProps(['data', 'loading']);
const loading = computed(() => props.loading ?? false);
const detail = computed(() => props.data ?? {});
</script>

<style lang="less" scoped>
@import '@/assets/styles/financeData/targetIndex.less';
</style>
