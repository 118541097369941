<!--
 * @Author: NanNan
 * @Date: 2023-10-13 11:21:32
 * @LastEditTime: 2023-10-13 14:11:56
 * @Description: v1.4.7 多选pop公共
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">{{ props?.data?.title }}</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="checkedList noBorder">
      <CheckboxGroup
        ref="checkboxGroup"
        v-model="_DATA.checked"
        v-if="_DATA.list.length"
        :max="props?.data?.max"
      >
        <cell v-for="item in _DATA.list" :key="item.id">
          <div class="member_list">
            <Checkbox :name="item.id">
              <div class="member_item">
                <p class="name">{{ item.name }}</p>
              </div>
            </Checkbox>
          </div>
        </cell>
      </CheckboxGroup>
      <Empty
        v-if="!_DATA.loading && !_DATA.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Button, Cell, Empty, Checkbox, CheckboxGroup } from 'vant';
const props = defineProps(['data']);
const emit = defineEmits(['reviewOk', 'reviewClose']);

const _DATA = reactive({
  loading: false,
  list: props?.data?.list || [],
  checked: props?.data?.checked || [],
  checkedItems: props?.data?.checkedItems || [],
});

onMounted(() => {
  console.log('props：', props);
});

/**
 * 操作
 */

// -- 清除
const clear = () => {
  _DATA.checked = [];
  _DATA.checkedItems = [];
};
// -- 确定
const btnSumbit = () => {
  if (_DATA.checked.length > 0) {
    _DATA.checkedItems = _DATA.list.filter((v) => _DATA.checked.includes(v.id));
  }
  emit('reviewOk', _DATA.checked, _DATA.checkedItems);
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
</style>
