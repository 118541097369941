<!--
 * @Author: gest
 * @Date: 2023-08-10 15:16:14
 * @LastEditTime: 2023-09-22 14:34:50
 * @Description:v1.4.3 跟进记录
-->
<template>
  <div>
    <Tabs class="tabList" v-model:active="_DATA.tabIndex" @click-tab="clickTab">
      <Tab title="跟进记录" :name="1"> </Tab>
      <!-- <Tab title="五步 + 三部曲" :name="2"></Tab> -->
      <Tab title="操作记录" :name="2"></Tab>
    </Tabs>
    <div class="info_box">
      <followList v-if="_DATA.tabIndex == 1" />
      <!-- <fiveRecordInfo
        v-if="_DATA.tabIndex == 2"
        style="padding: 10px 20px; background: #ffffff"
      ></fiveRecordInfo> -->
      <SaleCycleSeen v-if="_DATA.tabIndex == 2"></SaleCycleSeen>
    </div>
  </div>
</template>
<script setup>
import followList from '@/components/sale/followList.vue';
import SaleCycleSeen from '@/components/sale/saleCycleSeen.vue';
// import fiveRecordInfo from '@/components/sale/fiveRecordInfo';
import { reactive } from 'vue';

import { Tabs, Tab } from 'vant';

const _DATA = reactive({
  tabIndex: '',
});

const clickTab = (val) => {
  _DATA.tabIndex = val.name;
};
</script>
<style lang="less" scoped>
.tabList {
  position: fixed;
  top: 46px;
  background: #fff;
  z-index: 99;
  width: 100vw;
  left: 0px;
}
.info_box {
  margin-top: 46px;
  background-color: #f5f5f5;
}
</style>
