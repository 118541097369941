<!--
 * @Author: NanNan
 * @Date: 2024-05-14 18:39:06
 * @LastEditTime: 2024-05-29 10:37:06
 * @Description: v1.6.0新版本 获客-线索：线索质量(循环遍历)
-->
<template>
  <!-- <div
    class="bj-grey"
    :class="{
      bj_gray: props?.padding === 'none' ? true : false,
      border_top: props?.padding === 'none' ? false : true,
      border_bottom: props?.padding === 'none' ? true : false,
    }"
    v-if="
      props?.data?.expanded &&
      (props?.data?.list?.length > 0 || props?.data?.children?.length > 0)
    "
  > -->
  <div
    class="bj-grey"
    :class="{
      bj_gray: props?.padding === 'none' ? true : false,
      border_top: props?.padding === 'none' ? false : true,
    }"
    v-if="
      props?.data?.expanded &&
      (props?.data?.list?.length > 0 || props?.data?.children?.length > 0)
    "
  >
    <div
      v-for="(sub, subIndex) in _DATA.list"
      :key="sub.name"
      @click.stop="handleClick(subIndex)"
    >
      <Row
        style="align-items: center; height: 100%; margin: 0 10px 0 15px"
        class="border_bottom sub_item3"
      >
        <Col span="6">
          <div class="size13" style="display: flex; align-items: center">
            <template v-if="sub?.children?.length > 0">
              <Icon
                name="play"
                :style="
                  sub?.expanded
                    ? 'transform: rotate(90deg)'
                    : 'transform: rotate(0deg)'
                "
                class="arrow"
              />
            </template>
            <p>{{ sub.name }}</p>
          </div>
        </Col>
        <!-- 线索数 -->
        <Col span="5">
          <div>
            <span class="align_center">
              {{ sub.clue_num === '' ? '-' : sub.clue_num }}
            </span>
          </div>
        </Col>
        <!-- 见面数/见面率 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.meet_num === '' ? '-' : sub.meet_num }}
            </span>
          </div>
          <div>
            <span class="grey align_center">
              {{ sub.meet_rate === '' ? '-' : sub.meet_rate + '%' }}
            </span>
          </div>
        </Col>
        <!-- 协议数/成功率 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.sign_num === '' ? '-' : sub.sign_num }}
            </span>
          </div>
          <div>
            <span class="grey align_center">
              {{ sub.sign_rate === '' ? '-' : sub.sign_rate + '%' }}
            </span>
          </div>
        </Col>
        <!-- 线-签率 -->
        <Col span="5">
          <div>
            <span class="grey align_center">
              {{ sub.clue_sign_rate === '' ? '-' : sub.clue_sign_rate + '%' }}
            </span>
          </div>
        </Col>
      </Row>
      <!-- 递归 -->
      <Transition name="thred-fade">
        <TempChannelQuality
          v-if="(sub.list || sub.children) && sub.expanded"
          :data="sub"
          :padding="'none'"
        />
      </Transition>
    </div>
  </div>
</template>
<script setup>
import TempChannelQuality from '@/components/targetData/thredData/collapseItem/TempChannelQuality';
import { defineProps, reactive } from 'vue';
import { Icon, Row, Col } from 'vant';
const props = defineProps(['data', 'padding']);
const _DATA = reactive({
  list: props?.data?.list || props?.data?.children,
});

const handleClick = (subIndex) => {
  if (_DATA.list[subIndex]?.children?.length == 0) {
    return;
  }
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
