<!--
 * @Author: NanNan
 * @Date: 2023-10-17 14:07:27
 * @LastEditTime: 2023-10-31 17:20:36
 * @Description: v1.4.7 客资表：新增跟进 - pop
-->
<template>
  <div class="page-box">
    <div class="title-box">
      <div class="left">
        <p class="title">跟进记录</p>
        <p class="desc">
          <span class="width25">
            {{ props.data.name }}
          </span>
          <em>|</em>
          <span class="width35">
            {{ props.data.floor_name }}
          </span>
          <em class="width25">|</em>
          <span>{{ props.data.size }}</span>
        </p>
      </div>
      <Icon name="cross" :size="16" color="#000" @click.stop="cancel" />
    </div>
    <!-- 表单 -->
    <div class="formBase" style="background: #fff">
      <Form ref="ruleFormRef" @submit="onSubmit" error-message-align="right">
        <div class="content">
          <div class="list_field white_bg">
            <div class="field_item right">
              <div class="flex_between">
                <p>
                  <span class="red_color">*</span>
                  本次跟进时间
                </p>
                <Field
                  v-model="form.now_follow_time"
                  right-icon="arrow"
                  name="picker"
                  placeholder="选择时间"
                  readonly
                  :rules="[
                    {
                      required: true,
                      message: '请选择本次跟进时间',
                    },
                  ]"
                  @click="openDateType()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="flex_between">
                <div>
                  <span class="red_color">*</span>
                  跟进类型
                </div>
                <Field
                  name="radio"
                  label=""
                  :rules="[
                    {
                      required: true,
                      message: '请选择跟进类型',
                    },
                  ]"
                >
                  <template #input>
                    <RadioGroup
                      v-model="form.meet_type"
                      direction="horizontal"
                      checked-color="#008911"
                    >
                      <Radio :name="2">见面</Radio>
                      <Radio :name="3">回访</Radio>
                    </RadioGroup>
                  </template>
                </Field>
              </div>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="label-name">
                <span class="red_color">*</span>
                本次跟进内容
              </div>
              <Field
                v-model="form.follow_remark"
                rows="5"
                autosize
                type="textarea"
                maxlength="2000"
                placeholder="仅做记录，客户不可见"
                show-word-limit
                :rules="[
                  {
                    required: true,
                    message: '请填写本次跟进内容',
                  },
                ]"
              ></Field>
            </div>
          </div>
        </div>
        <div style="margin: 16px">
          <Button
            block
            type="primary"
            native-type="submit"
            :loading="loading_btn"
            loading-text="保存中..."
          >
            保存
          </Button>
        </div>
      </Form>
    </div>
    <!-- end -->
    <!-- 时间组件 -->
    <Popup v-model:show="timePicker" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="datetime"
        title="选择日期"
        :min-date="minDate"
        @confirm="confirmDate"
        cancel-button-text="清除"
        @cancel="cancelTime"
        :formatter="formatterDatetime"
      />
    </Popup>
    <!-- end -->
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  onMounted,
  reactive,
  ref,
  inject,
} from 'vue';
import {
  Icon,
  Form,
  Field,
  RadioGroup,
  Radio,
  Popup,
  DatetimePicker,
  Button,
  Notify,
} from 'vant';
import moment from 'moment';

const props = defineProps(['data']);
const emit = defineEmits(['reviewOk']);
const $http = inject('$http');

onMounted(() => {});

/**
 * api
 */
// -- 保存跟进
const submitApi = () => {
  loading_btn.value = true;
  $http.customerFollowOrder({ ...form }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          console.log('////');
          loading_btn.value = false;
          emit('reviewOk', 'ok', props?.data);
        },
      });
    } else {
      loading_btn.value = false;
      Notify({ type: 'warning', message: data });
    }
  });
};

/**
 * 操作
 */
// -- 时间组件
const timePicker = ref(false);
const minDate = ref(new Date());
const currentDate = ref(new Date());

const openDateType = () => {
  timePicker.value = true;
  minDate.value = undefined;
  currentDate.value = form.now_follow_time
    ? new Date(form.now_follow_time)
    : new Date();
};

const confirmDate = (value) => {
  form.now_follow_time = moment(value).format('YYYY-MM-DD HH:mm');
  timePicker.value = false;
};

const cancelTime = () => {
  timePicker.value = false;
  form.now_follow_time = '';
};

// -- 表单
const form = reactive({
  customer_id: props?.data?.id,
  now_follow_time: '',
  meet_type: '',
  meet_type_name: '',
  follow_remark: '',
});

// -- 表单：提交
const loading_btn = ref(false);
const onSubmit = (values) => {
  console.log('submit', values);
  if (!loading_btn.value) {
    submitApi();
  }
};

// -- 关闭
const cancel = () => {
  emit('reviewOk', 'cancel', props?.data);
};

// -- 时间格式化
const formatterDatetime = (type, val) => {
  if (type === 'year') {
    return `${val}年`;
  }
  if (type === 'month') {
    return `${val}月`;
  }
  if (type === 'day') {
    return `${val}日`;
  }
  if (type === 'hour') {
    return `${val}：`;
  }
  return val;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/comonFormGrey.less';
.page-box {
  padding: 0px;
}
.title-box {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: 24px;
    font-size: 13px;

    .title {
      font-weight: bold;
      font-size: 17px;
      color: #000;
      line-height: 21px;
    }

    .desc {
      height: 21px;
      margin-top: 2px;
      font-size: 15px;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw - 56px);
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 0;
        &.width25 {
          max-width: 25%;
        }
        &.width35 {
          max-width: 35%;
        }
      }

      em {
        font-size: 11px !important;
        width: 9px;
        text-align: center;
        color: #a1a1a1;
      }
    }
  }

  :deep(.van-icon) {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 20px;
  }
}
</style>
