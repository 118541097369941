<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2023-02-21 16:52:53
 * @Description: v0.2.2 设计节点 - 列表 - 标记完成
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <Form class="form-box" ref="formRef">
      <Field
        v-model.trim="form_info.remark"
        rows="10"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加备注..."
        style="border: none; margin-top: 10px"
      ></Field>
      <!-- end -->
      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Form, Field, Overlay, Loading, Button, Notify, Toast } from 'vant';

const $http = inject('$http');
const router = useRouter();

// const design_id = ref(router.currentRoute.value.query.did); //设计节点列表 过来携带 设计id
const design_node_id = ref(router.currentRoute.value.query.nid); //设计节点列表 过来携带 节点id

const form_default = reactive({
  node_id: design_node_id.value, //*设计节点id
  remark: '', //*备注
});
const form_info = reactive({});
const loading = ref(false);

/**
 * 初始化
 */
onMounted(() => {
  Object.assign(form_info, form_default);
});

/**
 * 操作
 */
//-@ 提交
const formRef = ref(null);

const onSubmit = () => {
  if (!form_info.remark) {
    Toast('请填写备注');
    return false;
  }
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http.getDesignOverApi(form_info).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
  });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
}
</style>
