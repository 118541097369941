<!--
 * @Author: NanNan
 * @Date: 2022-06-28 09:13:16
 * @LastEditTime: 2024-01-11 15:19:09
 * @Description: v0.5.0 合同详情模版 根据合同类型更改
-->
<template>
  <div v-if="info && info.basic_into">
    <!-- 51 尚层装饰室内设计协议 52 尚层装饰配饰设计协议 -->
    <div v-if="[51, 52].indexOf(info.basic_into.type) > -1">
      <ul>
        <li>
          <label>合同编号</label>
          <span>{{ info.basic_into.contract_no || '-' }}</span>
        </li>
        <li>
          <label>合同类型</label>
          <span>{{ info.basic_into.name || '-' }}</span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ info.basic_into.signed_up_time || '-' }}</span>
        </li>
        <li>
          <label>签订合同价</label>
          <span>
            {{
              info.basic_into.contract_amount
                ? info.basic_into.contract_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>预计启动时间</label>
          <span>
            {{ info.basic_into.estimated_commencement_time || '-' }}
          </span>
        </li>
        <li>
          <label>预计完成时间</label>
          <span>
            {{ info.basic_into.expected_completion_time || '-' }}
          </span>
        </li>
        <li>
          <label>工期</label>
          <span>
            {{
              info.basic_into.schedule ? info.basic_into.schedule + '天' : '-'
            }}
          </span>
        </li>
        <li>
          <label>贷款金额</label>
          <span>
            {{
              info.basic_into.loan_amount
                ? info.basic_into.loan_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>设计费</label>
          <span>
            {{
              info.basic_into.design_amount
                ? info.basic_into.design_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>优惠活动</label>
          <span>
            {{ info.basic_into.discount_activity_name || '-' }}
          </span>
        </li>
        <li>
          <label>优惠金额</label>
          <span>
            {{
              info.basic_into.discount_amount
                ? info.basic_into.discount_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>合同签署人</label>
          <span>
            {{ info.basic_into.signatory_name || '-' }}
          </span>
        </li>
        <li v-if="info.basic_into.signatory == 2">
          <label>企业名称</label>
          <span>{{ info.basic_into.company_name || '-' }}</span>
        </li>

        <li>
          <label>备注</label>
          <span>{{ info.basic_into.remarks || '-' }}</span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="info.basic_into.file_url?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 53 家居产品委托代购协议 -->
    <div v-if="[53].indexOf(info.basic_into.type) > -1">
      <ul>
        <li>
          <label>合同编号</label>
          <span>{{ info.basic_into.contract_no || '-' }}</span>
        </li>
        <li>
          <label>合同类型</label>
          <span>{{ info.basic_into.name || '-' }}</span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ info.basic_into.signed_up_time || '-' }}</span>
        </li>
        <!-- <li>
          <label>签订合同价</label>
          <span>
            {{
              info.basic_into.contract_amount
                ? info.basic_into.contract_amount + '元'
                : '-'
            }}
          </span>
        </li> -->

        <li>
          <label>优惠活动</label>
          <span>
            {{ info.basic_into.discount_activity_name || '-' }}
          </span>
        </li>
        <li>
          <label>优惠金额</label>
          <span>
            {{
              info.basic_into.discount_amount
                ? info.basic_into.discount_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>合同执行人</label>
          <span>{{ info.basic_into.executor_type_name || '-' }}</span>
        </li>
        <li>
          <label>合同签署人</label>
          <span>
            {{ info.basic_into.signatory_name || '-' }}
          </span>
        </li>
        <li v-if="info.basic_into.signatory == 2">
          <label>企业名称</label>
          <span>{{ info.basic_into.company_name || '-' }}</span>
        </li>

        <li>
          <label>备注</label>
          <span>{{ info.basic_into.remarks || '-' }}</span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="info.basic_into.file_url?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 54 家庭居室装饰装修工程施工合同 -->
    <div v-if="[54].indexOf(info.basic_into.type) > -1">
      <ul class="ul1">
        <li>
          <label>合同编号</label>
          <span>{{ info.basic_into.contract_no || '-' }}</span>
        </li>
        <li>
          <label>合同类型</label>
          <span>{{ info.basic_into.name || '-' }}</span>
        </li>
        <li>
          <label>工程套内建筑面积</label>
          <span>
            {{
              info.basic_into.house_size
                ? info.basic_into.house_size + '㎡'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ info.basic_into.signed_up_time || '-' }}</span>
        </li>
        <li>
          <label>签订合同价</label>
          <span>
            {{
              info.basic_into.contract_amount
                ? info.basic_into.contract_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>合同总额(折前)</label>
          <span>
            {{
              info.basic_into.dis_contract_amount
                ? info.basic_into.dis_contract_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <!-- <li>
          <label>工程造价总额</label>
          <span>
            {{
              info.basic_into.project_cost
                ? info.basic_into.project_cost + '元'
                : '-'
            }}
          </span>
        </li> -->
        <li>
          <label>税金</label>
          <span>
            {{
              info.basic_into.tax_amount
                ? info.basic_into.tax_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>管理费</label>
          <span>
            {{
              info.basic_into.manage_amount
                ? info.basic_into.manage_amount + '元'
                : '-'
            }}
          </span>
        </li>

        <li>
          <label>必报项工程造价</label>
          <span>
            {{
              info.basic_into.required_amount
                ? info.basic_into.required_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>设备电工程造价</label>
          <span>
            {{
              info.basic_into.device_amount
                ? info.basic_into.device_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>升级选项工程造价</label>
          <span>
            {{
              info.basic_into.upgrade_amount
                ? info.basic_into.upgrade_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>远程费</label>
          <span>
            {{
              info.basic_into.remote_fee
                ? info.basic_into.remote_fee + '元'
                : '-'
            }}
          </span>
        </li>

        <li>
          <label>施工折扣</label>
          <span>
            {{ info.basic_into.discount_ratio || '-' }}
          </span>
        </li>
        <li>
          <label>优惠活动</label>
          <span>
            {{ info.basic_into.discount_activity_name || '-' }}
          </span>
        </li>
        <li>
          <label>优惠金额</label>
          <span>
            {{
              info.basic_into.discount_amount
                ? info.basic_into.discount_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>工程约定方式</label>
          <span>
            {{
              info.basic_into.schedule_agreed_method_name
                ? info.basic_into.schedule_agreed_method_name
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>工期</label>
          <span>
            {{
              info.basic_into.schedule ? info.basic_into.schedule + '天' : '-'
            }}
          </span>
        </li>

        <li>
          <label>工程承包方式</label>
          <span>
            {{ info.basic_into.contracting_mode_name || '-' }}
          </span>
        </li>
        <li>
          <label>图纸提供方式</label>
          <span>
            {{ info.basic_into.construction_drawings_mode_name || '-' }}
          </span>
        </li>

        <li>
          <label>开工日期</label>
          <span>
            {{ info.basic_into.interior_decoration_start_time || '-' }}
          </span>
        </li>
        <!-- V1.5.3合同详情去除预计派单时间字段 -->
        <!-- <li>
          <label>预计派单时间</label>
          <span>
            {{ info.basic_into.expected_dispatch_date || '-' }}
          </span>
        </li> -->
        <li>
          <label>交付日期</label>
          <span>
            {{ info.basic_into.interior_decoration_end_time || '-' }}
          </span>
        </li>

        <li>
          <label>合同签署方</label>
          <span>
            {{ info.basic_into.signatory_name || '-' }}
          </span>
        </li>
        <li v-if="info.basic_into.signatory == 2">
          <label>企业名称</label>
          <span>{{ info.basic_into.company_name || '-' }}</span>
        </li>

        <li>
          <label>签署人姓名</label>
          <span>
            {{ info.basic_into.signatory_person_name || '-' }}
          </span>
        </li>
        <li>
          <label>签署人电话</label>
          <span>
            {{ info.basic_into.signatory_phone || '-' }}
          </span>
        </li>
        <li>
          <label>签署人身份证</label>
          <span>
            {{ info.basic_into.signatory_identity || '-' }}
          </span>
        </li>
        <li>
          <label>经办人姓名</label>
          <span>
            {{ info.basic_into.hand_name || '-' }}
          </span>
        </li>
        <li>
          <label>经办人电话</label>
          <span>
            {{ info.basic_into.hand_phone || '-' }}
          </span>
        </li>

        <li>
          <label>备注</label>
          <span>{{ info.basic_into.remarks || '-' }}</span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="info.basic_into.file_url?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 55 优选整装服务销售合同 -->
    <div v-if="[55].indexOf(info.basic_into.type) > -1">
      <ul>
        <li>
          <label>合同编号</label>
          <span>{{ info.basic_into.contract_no || '-' }}</span>
        </li>
        <li>
          <label>合同类型</label>
          <span>{{ info.basic_into.name || '-' }}</span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ info.basic_into.signed_up_time || '-' }}</span>
        </li>
        <li>
          <label>签订合同价</label>
          <span>
            {{
              info.basic_into.contract_amount
                ? info.basic_into.contract_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>整装产品管理费</label>
          <span>
            {{
              info.basic_into.pack_manage_amount
                ? info.basic_into.pack_manage_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>整装产品税金</label>
          <span>
            {{
              info.basic_into.pack_tax_amount
                ? info.basic_into.pack_tax_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>优惠活动</label>
          <span>
            {{ info.basic_into.discount_activity_name || '-' }}
          </span>
        </li>
        <li>
          <label>优惠金额</label>
          <span>
            {{
              info.basic_into.discount_amount
                ? info.basic_into.discount_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>优选整装产品</label>
          <span>{{ info.basic_into.product_name || '-' }}</span>
        </li>
        <li>
          <label>合同执行人</label>
          <span>{{ info.basic_into.executor_type_name || '-' }}</span>
        </li>
        <li>
          <label>合同签署人</label>
          <span>
            {{ info.basic_into.signatory_name || '-' }}
          </span>
        </li>
        <li v-if="info.basic_into.signatory == 2">
          <label>企业名称</label>
          <span>{{ info.basic_into.company_name || '-' }}</span>
        </li>

        <li>
          <label>备注</label>
          <span>{{ info.basic_into.remarks || '-' }}</span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="info.basic_into.file_url?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 56 优选整装工程施工合同 -->
    <div v-if="[56].indexOf(info.basic_into.type) > -1">
      <ul class="ul1">
        <li>
          <label>合同编号</label>
          <span>{{ info.basic_into.contract_no || '-' }}</span>
        </li>
        <li>
          <label>合同类型</label>
          <span>{{ info.basic_into.name || '-' }}</span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ info.basic_into.signed_up_time || '-' }}</span>
        </li>
        <li>
          <label>签订合同总价</label>
          <span>
            {{
              info.header.contract_all_money
                ? info.header.contract_all_money + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>工程造价</label>
          <span>
            {{
              info.basic_into.project_cost
                ? info.basic_into.project_cost + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>税金</label>
          <span>
            {{
              info.basic_into.tax_amount
                ? info.basic_into.tax_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>管理费</label>
          <span>
            {{
              info.basic_into.manage_amount
                ? info.basic_into.manage_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>整装产品报价</label>
          <span>
            {{
              info.basic_into.pack_amount
                ? info.basic_into.pack_amount + '元/㎡'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>服务包金额</label>
          <span>
            {{
              info.basic_into.service_package_amount
                ? info.basic_into.service_package_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>产品升级金额</label>
          <span>
            {{
              info.basic_into.product_upgrade_amount
                ? info.basic_into.product_upgrade_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>产品单独销售金额</label>
          <span>
            {{
              info.basic_into.products_sold_separately
                ? info.basic_into.products_sold_separately + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>整装金额</label>
          <span>
            {{
              info.basic_into.contract_amount
                ? info.basic_into.contract_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>优惠活动</label>
          <span>
            {{ info.basic_into.discount_activity_name || '-' }}
          </span>
        </li>
        <li>
          <label>优惠金额</label>
          <span>
            {{
              info.basic_into.discount_amount
                ? info.basic_into.discount_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>工期</label>
          <span>
            {{
              info.basic_into.schedule ? info.basic_into.schedule + '天' : '-'
            }}
          </span>
        </li>
        <li>
          <label>预计开工时间</label>
          <span>
            {{ info.basic_into.estimated_commencement_time || '-' }}
          </span>
        </li>
        <!-- V1.5.3合同详情去除预计派单时间字段 -->
        <!-- <li>
          <label>预计派单时间</label>
          <span>
            {{ info.basic_into.expected_dispatch_date || '-' }}
          </span>
        </li> -->
        <li>
          <label>预计完工时间</label>
          <span>
            {{ info.basic_into.expected_completion_time || '-' }}
          </span>
        </li>
        <li>
          <label>图纸提供方式</label>
          <span>
            {{ info.basic_into.construction_drawings_mode_name || '-' }}
          </span>
        </li>
        <li>
          <label>合同执行人</label>
          <span>{{ info.basic_into.executor_type_name || '-' }}</span>
        </li>
        <li>
          <label>优选整装产品</label>
          <span>{{ info.basic_into.product_name || '-' }}</span>
        </li>
        <li>
          <label>合同签署人</label>
          <span>
            {{ info.basic_into.signatory_name || '-' }}
          </span>
        </li>
        <li v-if="info.basic_into.signatory == 2">
          <label>企业名称</label>
          <span>{{ info.basic_into.company_name || '-' }}</span>
        </li>

        <li>
          <label>备注</label>
          <span>{{ info.basic_into.remarks || '-' }}</span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="info.basic_into.file_url?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 69 装饰合同工程配套材料补充协议 -->
    <div v-if="[69].indexOf(info.basic_into.type) > -1">
      <ul>
        <li>
          <label>合同编号</label>
          <span>{{ info.basic_into.contract_no || '-' }}</span>
        </li>
        <li>
          <label>合同类型</label>
          <span>{{ info.basic_into.name || '-' }}</span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ info.basic_into.signed_up_time || '-' }}</span>
        </li>
        <!-- V1.5.3合同详情去除预计派单时间字段 -->
        <!-- <li v-if="info.basic_into.expected_dispatch_date">
          <label>预计派单时间</label>
          <span>
            {{ info.basic_into.expected_dispatch_date || '-' }}
          </span>
        </li> -->
        <!-- <li>
          <label>签订合同价</label>
          <span>
            {{
              info.basic_into.contract_amount
                ? info.basic_into.contract_amount + '元'
                : '-'
            }}
          </span>
        </li> -->
        <li>
          <label>贷款金额</label>
          <span>
            {{
              info.basic_into.loan_amount
                ? info.basic_into.loan_amount + '元'
                : '-'
            }}
          </span>
        </li>

        <li>
          <label>优惠活动</label>
          <span>
            {{ info.basic_into.discount_activity_name || '-' }}
          </span>
        </li>
        <li>
          <label>优惠金额</label>
          <span>
            {{
              info.basic_into.discount_amount
                ? info.basic_into.discount_amount + '元'
                : '-'
            }}
          </span>
        </li>
        <li>
          <label>合同签署人</label>
          <span>
            {{ info.basic_into.signatory_name || '-' }}
          </span>
        </li>
        <li v-if="info.basic_into.signatory == 2">
          <label>企业名称</label>
          <span>{{ info.basic_into.company_name || '-' }}</span>
        </li>

        <li>
          <label>备注</label>
          <span>{{ info.basic_into.remarks || '-' }}</span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="info.basic_into.file_url?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 全部附件 -->
    <FileAll
      ref="fileAll"
      :data="info.basic_into.file_url"
      :isShow="showAllFile"
      @closePop="
        () => {
          showAllFile = false;
        }
      "
    ></FileAll>
  </div>
</template>
<script setup>
import FileAll from '@/components/common/FileAll';

import { ref, defineProps } from 'vue';
const props = defineProps(['info']);
const info = ref(props['info']);

/**
 * 操作
 */
//-@ 点击查看全部附件
const showAllFile = ref(false);
const lookFile = () => {
  showAllFile.value = true;
};
</script>
<style lang="less" scoped>
ul {
  padding: 10px 0;

  li {
    padding: 14px 0;
    line-height: 20px;
    display: flex;
    justify-content: space-between;

    label {
      color: #a6a6a6;
      font-size: 14px;
      width: 90px;
      flex-shrink: 0;
    }

    span {
      font-size: 14px;
      flex-grow: 1;
    }
  }
  &.ul1 {
    li {
      label {
        width: 126px;
      }
    }
  }
}
</style>
