<!--
 * @Author: NanNan
 * @Date: 2024-05-28 14:56:06
 * @LastEditTime: 2024-05-28 17:33:26
 * @Description: v1.6.1 smart 派单模块 - 跟进情况模版(多场景应用)
-->
<template>
  <div class="can-chose-designer-box">
    <Collapse v-model="collapse_active" ref="collapse">
      <CollapseItem title="标题1" name="1">
        <template #title>跟进情况</template>
        <div class="inner">
          <label>跟进次数：</label>
          <p v-if="info?.clue_follow_info?.record_count">
            {{ info?.clue_follow_info?.record_count }}次
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>最后跟进时间：</label>
          <p v-if="info?.clue_follow_info?.record_last_follow_time">
            <span>
              {{ info?.clue_follow_info?.record_last_follow_time }}
            </span>
            <em v-if="info?.clue_follow_info?.record_count_day">
              {{ info?.clue_follow_info?.record_count_day }}天
            </em>
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>预计下次跟进：</label>
          <p v-if="info?.clue_follow_info?.next_record_time">
            <span>{{ info?.clue_follow_info?.next_record_time }}</span>
            <em
              v-if="info?.clue_follow_info?.next_record_time_day"
              :class="{ red: info?.clue_follow_info?.is_over_time?.id == 1 }"
            >
              {{ info?.clue_follow_info?.is_over_time?.label_name }}
            </em>
          </p>
          <p v-else>-</p>
        </div>
        <div class="title-common">小程序发送情况</div>
        <div class="inner">
          <label>企微实际添加情况：</label>
          <p v-if="info?.clue_applet_send_info">
            {{
              info?.clue_applet_send_info?.wechat_friend_status == 1
                ? '已添加'
                : info?.clue_applet_send_info?.wechat_friend_status == 2
                ? '未添加'
                : '-'
            }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>发送总数：</label>
          <p v-if="info?.clue_applet_send_info">
            {{ '共' + info?.clue_applet_send_info?.total_count + '次' }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>首页：</label>
          <p v-if="info?.clue_applet_send_info">
            {{ '共' + info?.clue_applet_send_info?.home_count + '次' }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>公司介绍：</label>
          <p v-if="info?.clue_applet_send_info">
            {{ '共' + info?.clue_applet_send_info?.introduce_count + '次' }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>推荐卡片：</label>
          <p v-if="info?.clue_applet_send_info">
            {{
              '共' +
              info?.clue_applet_send_info?.share_card_count +
              '次（客户浏览：' +
              info?.clue_applet_send_info?.card_browse_count +
              '次）'
            }}
          </p>
          <p v-else>-</p>
        </div>
        <div v-if="info?.clue_intention?.applet_intention_id">
          <div class="title-common">
            <em
              :class="{
                red: info.clue_intention?.applet_intention_id == 1,
                green: info.clue_intention?.applet_intention_id == 2,
                gray: info.clue_intention?.applet_intention_id == 3,
              }"
            ></em>
            <span>{{ info.clue_intention?.applet_intention_name }}</span>
          </div>
          <div class="desc">
            {{ info.clue_intention?.applet_intention_content_item || '----' }}
          </div>
        </div>
      </CollapseItem>
    </Collapse>
  </div>
</template>
<script setup>
import { inject, defineProps, onMounted, ref } from 'vue';
import { Toast, Collapse, CollapseItem } from 'vant';

const $http = inject('$http');
const props = defineProps(['customerID', 'taskID']);

const info = ref('');
const collapse_active = ref(['1']);

onMounted(() => {
  getAppointDesignerFollowInfo();
});
/**
 * api
 */
// -- v1.5.1指定设计师信息
const getAppointDesignerFollowInfo = () => {
  $http
    .customerManagement_getAppointDesignerFollowInfo({
      customer_id: props?.customerID,
      task_id: props?.taskID,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        info.value = data;
      } else {
        Toast(data);
      }
    });
};
</script>
<style lang="less" scoped>
// v1.5.1 申请设计派单 - 文本信息 - 可指派设计师中心
.can-chose-designer-box {
  margin-bottom: 15px;
  .title-common {
    font-weight: bold;
    color: #000000;
    margin: 10px 0 4px;
    display: flex;
    justify-content: left;
    align-items: center;
    span {
      font-weight: bold;
    }
    em {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 8px;
      &.green {
        background: #008911;
      }
      &.red {
        background: #ea0000;
      }
      &.gray {
        background: #8d9091;
      }
    }
  }
  .desc {
    color: #000;
  }
  .inner {
    display: flex;
    justify-content: left;
    align-items: start;
    color: #767676 !important;
    line-height: 26px;

    label {
      text-align: right;
      box-sizing: border-box;
      flex-shrink: 0;
      flex-grow: 0;
    }

    p {
      flex-grow: 1;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: left;
      em {
        padding: 0 4px;
        background: #e5eeff;
        border-radius: 2px;
        color: #0256ff;
        font-weight: 500;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-left: 8px;
        &.red {
          background: #fce5e5;
          color: #ea0000;
        }
      }
    }
  }

  :deep(.van-collapse-item__title) {
    padding: 10px !important;
    font-size: 15px;
    font-weight: bold !important;
    background: #f5f5f5;
    border: none !important;
    margin-bottom: 10px;
  }

  :deep(.van-cell__title) {
    font-weight: bold !important;
  }

  :deep(.van-collapse-item__content) {
    padding-top: 0px !important;
  }
}
</style>
