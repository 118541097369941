<!--
 * @Author: Jessica
 * @Date: 2024-06-07 10:25:28
 * @LastEditTime: 2024-06-15 11:30:07
 * @Description: v1.6.1 施工 - 标记完成
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="pb_90 bg_fa">
      <!-- 基础信息 -->
      <div class="base_info">
        <!-- 提示信息 -->
        <div class="tip_title" v-if="detail?.is_set_over == 2">
          <svg class="icon_svg" aria-hidden="true">
            <use xlink:href="#icon-information-fill"></use>
          </svg>
          <p>存在工人未设置完成时间，请联系现场负责人设置</p>
        </div>
        <!-- 详情 -->
        <SiteNodeDateLine :detail="detail" />
      </div>

      <!-- 操作 -->
      <Form class="form-box" ref="formRef">
        <p class="label-name">
          <em>*</em>
          备注：
        </p>
        <Field
          v-model.trim="form_info.remark"
          rows="6"
          autosize
          type="textarea"
          maxlength="1000"
          placeholder="请填写补充说明..."
          style="border: none; padding: 0 16px 10px"
        ></Field>
        <!-- end -->
      </Form>
      <!-- 施工团队 -->
      <div class="team_work" v-if="detail.work_type_name">
        <SiteMembersList
          :work_members="detail.work_members"
          :other_work_members="detail.other_work_members"
        />
      </div>
    </div>
    <!-- 操作 -->
    <div class="stick-box">
      <Button type="primary" size="large" @click="onSubmit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Form, Field, Overlay, Loading, Button, Notify, Toast } from 'vant';
import SiteNodeDateLine from '@/components/construction/SiteNodeDateLine'; //节点信息
import SiteMembersList from '@/components/construction/SiteMembersList'; //施工团队
const $http = inject('$http');
const router = useRouter();
const node_id = ref(router.currentRoute.value.query.nid); //过来携带 节点id

const form_default = reactive({
  node_id: node_id.value, //*节点id
  remark: '', //*备注
});
const form_info = reactive({});
const loading = ref(false);
//-详情
const detail = ref({});
/**
 * 初始化
 */
onMounted(() => {
  Object.assign(form_info, form_default);
  loadFunc();
});

const loadFunc = () => {
  loading.value = true;
  $http
    .construction_nodeInfo({ node_id: node_id.value })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        detail.value = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    })
    .then(() => {
      loading.value = false;
    });
};
/**
 * 操作
 */
//-@ 提交
const formRef = ref(null);

const onSubmit = () => {
  if (!form_info.remark) {
    Toast('请填写备注');
    return false;
  }

  formRef.value?.validate().then(() => {
    loading.value = true;
    $http.getConTagOverApi(form_info).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loading.value = false;
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
  });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
  height: calc(100% - 48px);
  min-height: calc(100vh - 48px);
  background: #fafafa;
  .pb_90 {
    padding-bottom: 90px;
  }
  .bg_fa {
    background: #fafafa;
  }
  .form-box {
    background: #fff;
    margin-bottom: 15px;
    .label-name {
      font-size: 15px;
      line-height: 20px;
      padding: 15px 16px;
      font-weight: 700;
      em {
        color: #ea3d2f;
      }
    }
  }

  //-提示信息
  .tip_title {
    font-size: 14px;
    padding: 14px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff1e9;
    color: rgba(0, 0, 0, 0.9);
    .icon_svg {
      width: 18px;
      height: 18px;
      fill: #f37020;
    }
    p {
      margin-left: 10px;
      color: @black-color;
    }
  }

  //-施工团队
  .team_work {
    width: 100%;
  }
}
</style>
