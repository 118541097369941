<!--
 * @Author: gest
 * @Date: 2023-09-14 17:28:59
 * @LastEditTime: 2023-10-27 11:17:17
 * @Description: v1.4.5 产品安装计划表
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <!-- v-for -->
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="flex1 mr_15">{{ item.name }}</div>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick(item)"
                  v-model="item.value"
                  direction="horizontal"
                  checked-color="#008911"
                >
                  <Radio :name="1">有</Radio>
                  <Radio :name="2">无</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>

        <div
          class="list_field white_bg"
          style="background-color: #fafafa"
          v-if="item.value == 1"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">
                <span class="red_color">*</span>安装时间
              </div>
              <Field
                style="text-align: right; background-color: #fafafa"
                v-model="item.time"
                right-icon="arrow"
                name="picker"
                placeholder="选择"
                readonly
                :rules="[
                  {
                    required: true,
                    message: '请选择安装时间',
                  },
                ]"
                @click="openDateType(item)"
              />
            </div>
          </div>
          <div class="field_item">
            <span class="label-name grey_color">备注说明</span>
            <Field
              style="background-color: transparent"
              v-model="item.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写特殊产品的相关情况......"
              show-word-limit
            ></Field>
          </div>
        </div>
      </div>
      <!-- v-for end-->
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有什么需要补充的..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v)"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Dialog, Popup, DatetimePicker } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);

const props = defineProps(['data']);
const state = reactive({
  //-默认选项
  initData: {},
  //-时间控件
  // minDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

// @-时间控件
const openDateType = (data) => {
  state.showTimePicker = true;
  state.initData = data;
  if (data.time) {
    state.currentDate = new Date(data.time);
  } else {
    state.currentDate = new Date();
  }
};
const onConfirmDate = (value) => {
  state.showTimePicker = false;
  state.initData.time = moment(value).format('YYYY-MM-DD');
};
const radioClick = (item) => {
  //从【有此产品】切换至【无此产品】需二次弹框确认；

  if (item.value == 2) {
    if (item.time || item.remark) {
      Dialog.confirm({
        message: '确认将[有此产品]切换成[无此产品]吗？一旦确认，填写数据将清空',
      })
        .then(() => {
          item.time = '';
          item.remark = '';
        })
        .catch(() => {
          item.value = 1;
        });
    }
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
