<!--
 * @Author: Jessica
 * @Date: 2023-07-05 16:54:52
 * @LastEditTime: 2023-12-02 15:05:03
 * @Description: V1.4.1 【模版】设备/升级项报价编辑
-->
<template>
  <div class="content">
    <!-- 加载状态 -->
    <Overlay
      :show="_DATA.loading && _DATA.pageInfo.page == 1"
      class="overlay-fff"
    >
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="title">
      <h2 v-if="_DATA.list.length">
        {{ props.data.type == 1 ? '选择设备电项目' : '选择升级选报项目' }}
      </h2>
    </div>

    <div class="lists">
      <CheckboxGroup v-model="_DATA.checked" v-if="_DATA.list.length">
        <List
          v-model:loading="_DATA.loading"
          :finished="_DATA.finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <Cell v-for="item in _DATA.list" :key="item.id">
            <Checkbox :name="item.id" @click.stop="getClick(item)">
              <div class="item">
                <p class="t1">{{ item.project_name || '-' }}</p>
                <p class="t2">
                  <span
                    >差价
                    {{
                      item.project_cost ? '+' + item.project_cost : '-'
                    }}</span
                  >
                  <span>
                    | 工程量
                    {{
                      item.num
                        ? item.unit_name
                          ? item.num + item.unit_name
                          : item.num
                        : '-'
                    }}
                  </span>
                </p>
                <p class="t3">合计 {{ item.subtotal || '-' }}</p>
                <div class="btns">
                  <p
                    class="btn mr_10"
                    @click.stop="
                      getContent('造价结算说明', item.project_cost_description)
                    "
                  >
                    造价结算说明
                  </p>
                  <p
                    class="btn"
                    @click.stop="
                      getContent('工艺说明', item.construction_description)
                    "
                  >
                    工艺说明
                  </p>
                </div>
              </div>
            </Checkbox>
          </Cell>
        </List>
      </CheckboxGroup>
      <Empty v-else description="还未配置施工选报项" />
    </div>

    <div class="footer" v-if="_DATA.list.length">
      <div class="left">
        <p class="line1">
          已选{{ _DATA.checked.length }}项，工程造价合计
          <span v-if="_DATA.checked.length">
            <em class="size13">¥</em>
            <em class="size17 font_bold ml_5 blue"
              >{{ _DATA.total_info.total }}
            </em>
          </span>
          <span v-else>0</span>
        </p>
        <p
          class="line2"
          @click.stop="
            () => {
              _DATA.showPicker_content = true;
            }
          "
        >
          查看明细
          <Icon name="arrow-up" size="12" class="ml_5" />
        </p>
      </div>

      <Button
        class="right"
        type="primary"
        @click="btnSumbit()"
        :loading="_DATA.btnLoading"
      >
        提交
      </Button>
    </div>

    <!-- 组件集合 -->
    <div>
      <!-- picker-总价说明 -->
      <Popup
        v-model:show="_DATA.showPicker_content"
        round
        position="bottom"
        :style="{ height: '70%' }"
      >
        <div class="total_sum">
          <div class="top">
            <Icon
              name="cross"
              size="22"
              @click.stop="
                () => {
                  _DATA.showPicker_content = false;
                }
              "
            />
            <span class="name">总价明细</span>
          </div>
          <TempQuotationFlow class="pt-64" :data="_DATA.total_info" />
        </div>
      </Popup>
    </div>
  </div>
</template>
<script setup>
import { defineProps, reactive, inject, onMounted } from 'vue';
import * as _ from 'lodash';
import NP from 'number-precision';
import {
  CheckboxGroup,
  List,
  Cell,
  Checkbox,
  Empty,
  Notify,
  Button,
  Icon,
  Dialog,
  Popup,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
import TempQuotationFlow from '@/components/design/TempQuotationFlow'; //-总价明细
const router = useRouter();
const props = defineProps(['data']);
const $http = inject('$http');
const _DATA = reactive({
  loading: false,
  btnLoading: false,
  //-判断滚动加载
  finished: false,
  //-列表
  list: [],
  //-已选List-ids
  checked: [],
  //-已选Lists
  checkedList: [],
  //-分页
  defaultPage: 1,
  pageInfo: {
    total: 0, //总条数
    page: 1, //当前页
    count_page: 1,
    pages: 10, //每页多少条
  },
  //-picker-总价说明
  showPicker_content: false,
  total_info: {
    tax_points: '', //税点
    manage_points: '', //管理费点
    tax_amount: '', //税金
    manage_amount: '', //管理费
    total_cost: '', //工程造价
    total: '', //总价
  },
});

onMounted(() => {
  paramsFunc();
  onLoad('loadFirst');
});

/**
 * 接口调用
 */
//--滚动加载-列表
const onLoad = (type) => {
  _DATA.loading = true;
  $http
    .getChooseQuoteList({
      customer_id: props.data?.customer_id,
      type: props.data?.type,
      page: _DATA.pageInfo.page ?? _DATA.defaultPage,
      pages: _DATA.pageInfo.pages,
    })
    .then((res) => {
      _DATA.loading = false;
      const { code, data } = res;
      if (code === 0) {
        _DATA.pageInfo = data.page;
        _DATA.list = [..._DATA.list, ...data.list];
        if (_DATA.pageInfo.page < _DATA.pageInfo.count_page) {
          _DATA.pageInfo.page++;
        } else {
          _DATA.finished = true;
        }
        if (type) {
          _DATA.checked = data.choose_list;
          getClick();
        }
      } else {
        _DATA.finished = true;
      }
    });
};

//-参数
const paramsFunc = () => {
  $http.getCustomerQuoteOrderParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.total_info.tax_points = data.quote_point.tax_points;
      _DATA.total_info.manage_points = data.quote_point.manage_points;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};
//-说明展示-begin
const getContent = (title, data) => {
  Dialog.confirm({
    title: '',
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#576B95',
    className: 'dialogDescripton',
    message: `<div class="description"><h3>${title}</h3><p>${
      data ?? ''
    }</p></div>`,
    allowHtml: true,
  }).then(() => {});
};
//---------end---------

//--选择配置项-begin
//--计算方式--
// 1. 工程造价：累计报价勾选所有项小计
// 2. 管理费=工程造价x管理费点
// 3. 税金=工程造价x（1+管理费点）x税点
// 4. 总价=工程造价+管理费+税金
const getClick = () => {
  _DATA.checkedList = _DATA.list.filter((v) => {
    return _DATA.checked.indexOf(v.id) > -1;
  });
  getCountFun(_DATA.checkedList);
};

const getCountFun = (arr) => {
  //工程造价
  _DATA.total_info.total_cost = arr.reduce((prev, next) => {
    return _.floor(NP.plus(prev, next.subtotal ?? 0), 2);
  }, 0);

  //税金
  _DATA.total_info.tax_amount = _.floor(
    NP.times(
      _DATA.total_info.total_cost ?? 0,
      NP.plus(1, _DATA.total_info.manage_points ?? 0),
      _DATA.total_info.tax_points ?? 0
    ),
    2
  );
  //管理费
  _DATA.total_info.manage_amount = _.floor(
    NP.times(
      _DATA.total_info.total_cost ?? 0,
      _DATA.total_info.manage_points ?? 0
    ),
    2
  );

  //总价
  _DATA.total_info.total = _.floor(NP.plus(_DATA.total_info.total_cost), 2);
};
//------------end---------

//-提交操作-begin
const btnSumbit = () => {
  _DATA.btnLoading = true;
  if (_DATA.checked.length > 0) {
    $http
      .getChooseQuoteSave({
        customer_id: props.data?.customer_id,
        type: props.data?.type,
        choose_list: _DATA.checkedList,
      })
      .then((res) => {
        _DATA.btnLoading = false;
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功！',
            duration: 1000,
            onClose: () => {
              router.back();
            },
          });
        } else {
          Notify({
            type: 'warning',
            message: data,
            duration: 2000,
          });
        }
      });
  } else {
    Notify({ type: 'warning', message: '请先选择项目' });
    _DATA.btnLoading = false;
  }
};
//------------end---------
</script>

<style lang="less" scoped>
.content {
  padding: 15px 0px 64px;
  .title {
    background: #ffffff;
    padding-left: 20px;
    > h2 {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #8e8e8e;
      border-bottom: 1px solid #f5f5f5;
    }
  }

  :deep(.van-cell) {
    border: 0;
    border-radius: 0;
    padding: 0px 0px 0px 20px;
    &::after {
      border-bottom: 0;
    }
  }
  :deep(.van-checkbox__label) {
    width: 100%;
  }
  :deep(.van-list__finished-text) {
    background: #ffffff;
  }
  .item {
    padding: 15px 20px;
    border-bottom: 1px solid #f5f5f5;
    .t1,
    .t3 {
      font-size: 15px;
      color: #000000;
      margin-bottom: 8px;
    }
    .t2 {
      font-size: 15px;
      color: #8d9091;
      margin-bottom: 8px;
    }
    .btns {
      display: flex;
      align-items: center;
      .btn {
        font-size: 15px;
        color: #576b95;
        cursor: pointer;
      }
    }
  }
  .mr_10 {
    margin-right: 10px;
  }
  .ml_5 {
    margin-left: 5px;
  }
  .size13 {
    font-size: 13px;
  }
  .size17 {
    font-size: 17px;
  }
  .blue {
    color: #0256ff;
  }
  .footer {
    width: 100%;
    height: 64px;
    padding: 0 20px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -1px 0px rgba(62, 67, 69, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      max-width: calc(100% - 80px);
      > p {
        font-size: 13px;
        color: #8d9091;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .line2 {
        font-size: 13px;
        color: #576b95;
      }
    }
  }
  //-弹窗-描述
  :deep(.van-dialog) {
    border-radius: 8px;
    opacity: 1;
    background: #ffffff;
  }
}
:deep(.van-popup) {
  background: #fbfbfb;
}
.total_sum {
  width: 100%;
  .top {
    width: 100%;
    height: 64px;
    position: fixed;
    line-height: 64px;
    padding: 0 20px;
    background: #ffffff;
    border-radius: var(--van-popup-round-border-radius)
      var(--van-popup-round-border-radius) 0 0;
    .name {
      width: calc(100% - 30px);
      text-align: center;
      display: inline-block;
    }
  }
  .pt-64 {
    padding-top: 64px;
  }
}
</style>
