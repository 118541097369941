/*
 * @Author: NanNan
 * @Date: 2022-04-14 10:03:29
 * @LastEditTime: 2023-12-07 17:59:19
 * @Description: axios 封装请求
 */
import axios from 'axios';
import { Toast } from 'vant';
const host = process.env.NODE_ENV === 'production' ? '' : '/api';
const localStorage = window.localStorage;
const token = localStorage.getItem('ACC_TOKEN');

const service = axios.create({
  baseURL: host, // api的base_url
  timeout: 60 * 1000, // request timeout 60s
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers['acc-token'] = token;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data || {};
    localStorage.setItem('CURRENT_URL', location);
    if (res.code === 5112) {
      if (location.pathname == '/auth' || location.pathname == '/') {
        window.location.href = '/auth';
      } else if (location.pathname == '/authC') {
        window.location.href = '/authC';
      } else {
        window.location.href = '/authA';
      }
    }
    return res;
  },
  (error) => {
    let response = error.response || {};
    let message = '网络异常';
    // 断网情况，会没有statusText
    if (response.statusText) {
      message = response.statusText + ':' + response.status;
    }
    Toast(message ? message : '连接失败，请查看当前网络状态再刷新当前页');
    return Promise.reject(error);
  }
);

export default service;
