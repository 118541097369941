<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2023-12-15 14:46:00
 * @Description:【工程确认单】- V1.4.1 全部默认通知客户
-->
<template>
  <div class="page-waper page-waper1">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <!-- 提示信息 -->
      <div class="tip_info">
        <Icon name="warning" color="#F37020" size="14" />
        <p class="tip_text">
          此表为客户施工变更的最终确认，客户确认后作为施工依据
        </p>
      </div>
      <Form ref="ruleFormRef">
        <!-- 加载状态 -->
        <Loading class="loading-box" color="#0094ff" v-if="state.loading" />
        <!-- 合同头部信息 -->
        <div class="mess-top">
          <p class="label-name white">
            <em>*</em>
            变更合同
          </p>
          <Field
            v-model="form.list.contract_name"
            right-icon="arrow-down"
            readonly
            placeholder="选择变更合同"
            @click="state.showContractPicker = true"
            :rules="[{ required: true, message: '请选择变更合同' }]"
          />
          <p class="contract-id">
            合同编号：{{ form.list.contract_no ? form.list.contract_no : '-' }}
          </p>
        </div>
        <!-- 绑定工程变更意向单 -->
        <div class="part mb_15">
          <!-- 变更阶段 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更阶段
            </label>
            <Field
              v-model="form.list.construction_stage_name"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="state.showTypePicker_stage = true"
            />
          </div>
          <div class="cell_no" v-if="form.list.construction_stage !== ''">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              绑定工程变更意向单
            </label>
            <Field
              v-model="form.list.intend_ids_valid"
              right-icon=""
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="添加"
              @click.stop="getIntendFun"
            >
              <template #input>
                <p class="text"><Icon name="plus" /> 添加</p>
              </template>
            </Field>
          </div>
          <!-- 已绑定工程意向单 -->
          <div
            class="bottom_checked"
            v-if="
              form.list.construction_stage !== '' &&
              form.list.intend_list?.length
            "
          >
            <div class="top">
              <p class="t t1">工程变更意向单号</p>
              <p class="t t2">预估合计金额</p>
            </div>
            <div class="item" v-for="i in form.list.intend_list" :key="i.id">
              <div class="line">
                <p class="n n1">{{ i.no }}</p>
                <p class="n n2">{{ i.amount }}</p>
              </div>
              <p @click.stop="delIntendFun(i.id)">
                <Icon name="cross" class="icon_16" />
              </p>
            </div>
          </div>
        </div>
        <div class="part mb_15">
          <!-- 变更类型 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更类型
            </label>
            <p class="show_text">基装变更</p>
          </div>
          <!-- 变更项是否现场确认 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更项是否现场确认
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.list.scene_confirm"
                  direction="horizontal"
                >
                  <Radio
                    :name="item.id"
                    v-for="(
                      item, index
                    ) in state.contract_change_scene_confirm_labels"
                    :key="index"
                  >
                    {{ item.name }}
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <!-- 变更类别 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更类别
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <CheckboxGroup
                  v-model="form.list.change_types"
                  direction="horizontal"
                >
                  <Checkbox
                    :name="item.id"
                    v-for="(item, index) in state.contract_change_type_labels"
                    :key="index"
                  >
                    {{ item.name }}
                  </Checkbox>
                </CheckboxGroup>
              </template>
            </Field>
          </div>
        </div>
        <div class="part mb_15">
          <!-- 预估工期是否变更 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期是否变更
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.list.is_change_schedule"
                  direction="horizontal"
                  @change="clearFun"
                >
                  <Radio
                    :name="item.id"
                    v-for="(
                      item, index
                    ) in state.contract_change_is_schedule_labels"
                    :key="index"
                  >
                    {{ item.name }}
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <!-- 变更节点 -->
          <div class="cell_no" v-if="form.list.is_change_schedule == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期变更节点
            </label>
            <Field
              v-model="form.list.change_node_name"
              right-icon="arrow"
              readonly
              name="picker"
              autosize
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="state.showPoint = true"
            />
          </div>
          <!-- 变更后工期 -->
          <div class="cell_no" v-if="form.list.is_change_schedule == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期变更天数
            </label>
            <div class="next">
              <span class="line">延期</span>
              <Field
                v-model="form.list.change_schedule"
                type="number"
                placeholder="请填写"
                maxlength="3"
                :rules="[
                  {
                    required: true,
                    message: '请填写',
                    trigger: 'onChange',
                  },
                  {
                    validator: validatorNum,
                    message: '可输入1-90天',
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">天</span>
            </div>
          </div>
        </div>
        <!-- 三大类卡片 -->
        <!-- 基装实际增减金额 -->
        <div class="cell-card" @click="getPopup('base')">
          <div class="do-box">
            <p class="title">基装实际增减金额</p>
          </div>
          <div class="do-box bottom no_padding">
            <ul class="ul-flex">
              <li>
                <p class="text c1">增项合计</p>
                <p class="n">{{ form.list.base_plus_amount }}</p>
              </li>
              <li>
                <p class="text c1">减项合计</p>
                <p class="n">{{ form.list.base_minus_amount }}</p>
              </li>
              <li>
                <p class="text c2">实际增减金额</p>
                <p class="n n1">{{ form.list.base_amount }}</p>
              </li>
            </ul>
          </div>
          <div class="do-box bottom clearfix">
            <label class="label1 pull-left">增减明细:</label>
            <div class="box-list pull-left" v-if="form.list.base_arr.length">
              <p v-for="(i, index) in form.list.base_arr" :key="index">
                {{ i.project_name }}
              </p>
            </div>
            <div class="box-list pull-left" v-else>-</div>
          </div>
          <div class="do-box clearfix">
            <Icon name="plus" class="pull-left icon_plus" />
            <p class="add pull-left">添加明细</p>
          </div>
        </div>
        <!-- 其他实际增减金额 -->
        <div class="cell-card" @click="getPopup('other')">
          <div class="do-box">
            <p class="title">其他实际增减金额</p>
          </div>
          <div class="do-box bottom no_padding">
            <ul class="ul-flex bottom">
              <li>
                <p class="text c1">增项合计</p>
                <p class="n">{{ form.list.other_plus_amount }}</p>
              </li>
              <li>
                <p class="text c1">减项合计</p>
                <p class="n">{{ form.list.other_minus_amount }}</p>
              </li>
              <li>
                <p class="text c2">实际增减金额</p>
                <p class="n n1">{{ form.list.other_amount }}</p>
              </li>
            </ul>
          </div>
          <div class="do-box bottom clearfix">
            <label class="label1 pull-left">增减明细:</label>
            <div class="box-list pull-left" v-if="form.list.other_arr.length">
              <p v-for="(i, index) in form.list.other_arr" :key="index">
                {{ i.project_name }}
              </p>
            </div>
            <div class="box-list pull-left" v-else>-</div>
          </div>
          <div class="do-box clearfix">
            <Icon name="plus" class="pull-left icon_plus" />
            <p class="add pull-left">添加明细</p>
          </div>
        </div>
        <!-- 总计 -->
        <div class="card-total clearfix">
          <label class="pull-left">合计</label>
          <p class="pull-right font_bold font_size_16">
            ¥ {{ form.list.amount }}
          </p>
        </div>
        <!-- 1.5.1新增费用明细 -->
        <div class="part mb_15">
          <div
            class="font_size_14 grey_color"
            style="
              padding: 4vw 0px;
              margin: 0 4vw;
              border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
            "
          >
            <span style="color: #ea3d2f">*</span>费用明细
          </div>

          <div class="cell_no">
            <label class="form_title">
              税金
              <p class="grey_color font_size_13">
                税点：{{ state.quote_point.tax_points || '-' }}%
              </p>
            </label>
            <div class="next_big">
              <span class="line" v-if="form.list.amount < 0">-</span>
              <Field
                v-model="form.list.tax_amount_add"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写正确金额',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">元</span>
            </div>
          </div>
          <div class="cell_no">
            <label class="form_title">
              管理费
              <p class="grey_color font_size_13">
                管理费点：{{ state.quote_point.manage_points || '-' }}%
              </p>
            </label>
            <div class="next_big">
              <span class="line" v-if="form.list.amount < 0">-</span>
              <Field
                v-model="form.list.manage_amount_add"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写正确金额',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">元</span>
            </div>
          </div>
          <div class="cell_no">
            <label class="form_title"> 远程费点 </label>
            <div class="next_big">
              <Field
                v-model="form.list.remote_fee_ratio"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写正确的远程费点',
                    validator: validatorPoint,
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">%</span>
            </div>
          </div>
          <div class="cell_no">
            <label class="form_title"> 远程费 </label>
            <div class="next_big">
              <span class="line" v-if="form.list.amount < 0">-</span>
              <Field
                v-model="form.list.remote_fee_add"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写正确金额',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">元</span>
            </div>
          </div>
          <div class="cell_no">
            <label class="form_title"> 工程造价 </label>
            <div class="next_big">
              <span class="line" v-if="form.list.amount < 0">-</span>
              <Field
                v-model="form.list.project_cost_add"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写正确金额',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">元</span>
            </div>
          </div>
          <div class="cell_no">
            <label class="form_title"> 折扣 </label>
            <div class="next_big">
              <Field
                v-model="form.list.discount_ratio"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写正确的折扣',
                    validator: validatorDiscount,
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">折</span>
            </div>
          </div>
        </div>
        <!-- 1.5.1新增费用明细 end-->
        <div class="part">
          <!-- 收款方式 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              收款方式
            </label>
            <Field
              v-model="form.list.type_str"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择收款方式',
                },
              ]"
              placeholder="请选择收款方式"
              @click="getTypeFun"
            />
          </div>
          <div class="cell" v-if="form.list.type == 1">
            <CheckboxGroup v-model="state.checked">
              <Checkbox
                :name="item.id"
                class="checkBox"
                shape="square"
                v-for="item in state.chargeProportion"
                :key="item.id"
                @click="getClickCharge(item.id)"
              >
                <div class="cell-card2">
                  <div class="do-box no-bottom">
                    <p class="title">
                      <span class="status">{{ item.status_str }}</span>
                      {{ item.payment_period }}
                    </p>
                  </div>
                  <div class="do-box no-bottom">
                    <ul class="ul-flex bottom">
                      <li>
                        <p class="text c1">收款比例</p>
                        <p class="n">{{ item.proportion }}</p>
                      </li>
                      <li>
                        <p class="text c1">应收金额</p>
                        <p class="n n1">{{ item.receivable_money }}</p>
                      </li>
                      <li>
                        <p class="text c1">增减项金额</p>
                        <p class="n">{{ item.adjust_money }}</p>
                      </li>
                      <li>
                        <p class="text c1">本期已收</p>
                        <p class="n">{{ item.received_money }}</p>
                      </li>
                    </ul>
                  </div>
                  <div class="do-box clearfix">
                    <label class="label1 pull-left">款项说明:</label>
                    <div class="box-list pull-left">
                      {{
                        item.payment_description
                          ? item.payment_description
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </Checkbox>
            </CheckboxGroup>
          </div>
          <!-- 通知客户 只有【产品代购合同】和【装饰合同工程配套配套材料补充协议】有权限选择是否通知客户-->
          <div class="cell switch_cell">
            <div class="clearfix">
              <div class="switch pull-left">
                <p class="t1">通知客户</p>
              </div>
              <Switch
                v-model="form.list.notify_customer_flag"
                size="22"
                class="pull-right switchBtn"
                disabled
              />
            </div>

            <p class="grey_color font_size_13 pt_5">
              工程变更确认单待主创设计师确认后，默认通知客户确认。
            </p>
          </div>
          <!-- 备注 -->
          <div class="cell no_border block mt_15">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            />
          </div>
          <!-- 附件 -->
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              附件
              <span style="color: #999999; font-size: 12px"
                >上传施工图纸、施工报价</span
              >
            </label>
            <FileUpload
              :data="form.list.attach"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </div>

        <div class="btnSumbit btnSumbit1">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            提交
          </Button>
          <!-- <p v-if="state.contractType != 53 && state.contractType != 69">
            合同增减项默认通知客户
          </p> -->
        </div>
      </Form>

      <!-- 变更合同组件 -->
      <Popup v-model:show="state.showContractPicker" position="bottom">
        <Picker
          :columns="state.contract_list"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'contract')"
          @cancel="state.showContractPicker = false"
          :default-index="
            state.contract_list.findIndex((v) => {
              return v.id == form.list.contract_id;
            })
          "
        />
      </Popup>
      <!-- 绑定意向单组件 -->
      <Popup
        v-model:show="state.intendOption.show"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '80%', borderRadius: '25px' }"
      >
        <IntendList
          v-if="state.intendOption.show"
          :data="state.intendOption"
          @reviewClose="reviewIntendClose"
          @reviewOk="reviewIntendOk"
        />
      </Popup>
      <!-- 变更阶段组件 -->
      <Popup v-model:show="state.showTypePicker_stage" position="bottom">
        <Picker
          :columns="state.construction_stages"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'stage')"
          @cancel="state.showTypePicker_stage = false"
          :default-index="
            state.construction_stages.findIndex((v) => {
              return v.id == form.list.contract_id;
            })
          "
        />
      </Popup>
      <!-- 变更节点组件 -->
      <Popup v-model:show="state.showPoint" position="bottom">
        <Area
          :value="form.list.area_id"
          :area-list="state.area_list"
          :columns-num="2"
          @confirm="(v) => onConfirm(v, 'point')"
          @cancel="state.showPoint = false"
        ></Area>
      </Popup>
      <!-- 收款方式组件 -->
      <Popup v-model:show="state.showPaymentPicker" position="bottom">
        <Picker
          :columns="state.payment_type_list"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'payment')"
          @cancel="state.showPaymentPicker = false"
        />
      </Popup>
      <!-- 基装\主材\其它组件 -->
      <Popup
        v-model:show="state.cardShow"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <BaseTemp
          v-if="state.cardShow"
          :data="state.cardOptions"
          :unitList="state.unitList"
          @reviewOk="reviewOk"
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, ref, inject, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import BaseTemp from '@/components/change/BaseTemp'; //基装\主材\其它
import IntendList from '@/components/change/IntendList'; //-意向单选择列表
import {
  Form,
  Field,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Button,
  Loading,
  Overlay,
  Switch,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Area,
  Dialog,
} from 'vant';
import patterns from '@/utils/pattern';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loading: false,
  loadingConfirm: false,
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  //-变更单Id
  change_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.gId
    : '',
  //-合同Id
  contract_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cid
    : '',
  contractType: '', //-合同类型
  unitList: [], //-单位方式参数
  //-变更合同参数
  showContractPicker: false,
  contract_list: [],
  //-end
  //-收款方式参数&款期
  showPaymentPicker: false,
  payment_type_list: [],
  payment_type_list_init: [],
  cardTotal: 0,
  chargeProportion: [],
  checked: [], //@-款期是否变化
  //-end
  // 变更节点
  showPoint: false,
  pointList: [],
  area_list: {
    province_list: {},
    city_list: {},
  },
  // end
  //-基装等三大类型编辑参数
  cardOptions: {
    optionType: 'edit',
    type: '',
    base_arr: [],
    main_arr: [],
    other_arr: [],
    total: 0,
    totalPlus: 0,
    totalMinus: 0,
  },
  cardShow: false,
  //-end
  // 变更阶段组件
  showTypePicker_stage: false,
  construction_stages: [],
  //-end
  construction_info: {}, //-工地状态
  // 意向单选择列表
  intendOption: {
    show: false,
    list: [],
    change_item_type: 1, //1 工程 2 产品
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    construction_stage: '',
  },
  //-end
  quote_point: {}, //税点,管理费点
});
//-参数&详情-begin
onMounted(() => {
  getContractPublicParameter();
});
//-获取参数
const getContractPublicParameter = async () => {
  state.loading = true;
  await $http
    .getContractPublicParameter({ customer_id: state.customer_id })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        state.unitList = data.material_type;
        state.construction_stages = data.construction_stages;
        state.quote_point = data.quote_point;
        if (state.customer_id) {
          getContractCustomerParameter();
        }
      } else {
        Toast(data);
      }
    });
};
//-获取客户参数
const getContractCustomerParameter = async () => {
  await $http
    .getContractCustomerParameter({ customer_id: state.customer_id })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        state.contract_change_scene_confirm_labels =
          data.contract_change_scene_confirm_labels;
        state.contract_change_is_schedule_labels =
          data.contract_change_is_schedule_labels;
        state.contract_change_type_labels = data.contract_change_type_labels;
        // 工程：54，56 产品：53，69
        state.contract_list = data.item_contract_list.filter(
          (v) => v.type == 54 || v.type == 56
        );
        state.payment_type_list_init = data.payment_type_list;
        state.construction_info = data.construction_info;
        if (data.construction_info.id) {
          getConstructionNodeList(data.construction_info.id);
        }

        if (state.contract_id) {
          const contract_list = state.contract_list.filter(
            (v) => v.id == state.contract_id
          );
          form.list.contract_id = contract_list.length ? state.contract_id : '';
          form.list.contract_name = contract_list.length
            ? contract_list[0].name
            : '';
          form.list.contract_no = contract_list.length
            ? contract_list[0].contract_no
            : '';
          state.chargeProportion = contract_list.length
            ? contract_list[0]['chargeProportion']
            : [];
        }

        if (state.change_id) {
          getContractInfo();
        }
      } else {
        Toast(data);
      }
    });
};
//获取变更节点参数
const getConstructionNodeList = (id) => {
  form.list.change_node_name = '';
  form.list.area_id = '';
  $http
    .getConstructionNodeList({ construction_id: id })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        state.pointList = data.list;
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .then(() => {
      for (let i in state.pointList) {
        const item = state.pointList[i];
        let key = Number(i) + 1 + '00000';
        let val = item.type_name;
        let isAdd = false;
        for (let n in item.list) {
          var obj = item.list[n];
          var k = Number(i) + 1 + '0' + n;
          var v = obj.node_name_id_name;
          if (
            ((obj.node_type == 3 || obj.node_type == 2) && obj.status !== 5) ||
            (obj.node_type !== 3 && obj.node_type !== 2 && obj.status !== 3)
          ) {
            //节点状态不是已完成/审核通过/验收通过/已收款
            state.area_list.city_list[k] = v;
            if (!isAdd) {
              state.area_list.province_list[key] = val;
              isAdd = true;
            }
          }
          if (form.list.change_node_id == obj.node_id) {
            form.list.change_node_name = val + '-' + obj.node_name_id_name;
            form.list.area_id = Number(i) + 1 + '0' + n;
          }
        }
      }
    });
};

//-获取变更单详情
const getContractInfo = async () => {
  await $http.getContractItemInfo({ id: state.change_id }).then((res) => {
    state.loading = false;
    const { data, code } = res;
    if (code === 0) {
      form.list = data;
      //费用明细
      form.list.discount_ratio = data.quote_arr.discount_ratio;
      form.list.remote_fee_ratio = data.quote_arr.remote_fee_ratio;

      form.list.tax_amount_add = Math.abs(data.quote_arr.tax_amount);
      form.list.manage_amount_add = Math.abs(data.quote_arr.manage_amount);
      form.list.remote_fee_add = Math.abs(data.quote_arr.remote_fee);
      form.list.project_cost_add = Math.abs(data.quote_arr.project_cost);

      form.list.attach = form.list.attach.length ? form.list.attach : [];
      //-兼容【产品代购合同】和【装饰合同工程配套配套材料补充协议】有权限选择是否通知客户,其他类型合同 默认通知客户
      state.contractType = data.contract_type;
      // if (data.contract_type != 53 && data.contract_type != 69) {
      //   form.list.notify_customer_flag = false;
      // }
      //-end
      state.checked = data.proportion_id ? [data.proportion_id] : [];
      nextTick(() => {
        var list = state.contract_list.filter((v) => {
          return v.id == form.list.contract_id;
        });
        state.chargeProportion = list.length ? list[0]['chargeProportion'] : [];
      });
      //-重新生成确认单，清空已选意向单
      form.list.intend_ids = form.list.intend_ids_valid = [];
      form.list.intend_list = [];
    } else {
      Toast(data);
    }
  });
};
//-end

//--表单&验证--------begin
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    change_item_type: 1, //-1工程 2产品
    // 合同
    contract_id: '',
    contract_no: '',
    contract_name: '',
    // 意向单
    intend_ids: [],
    intend_ids_valid: [],
    intend_list: [],
    // 变更的施工阶段
    construction_stage_name: '',
    construction_stage: '',
    //-现场确认
    scene_confirm: '',
    // 变更类别
    change_types: [],
    // 预估工期是否变更
    is_change_schedule: '',
    // 工期变更节点
    area_id: '', //组件展示id
    change_node_id: '',
    change_node_name: '',
    //-变更工期
    change_schedule: '',
    // 收款
    type: '',
    type_str: '',
    proportion_id: '',
    notify_customer_flag: true,
    //-附件
    attach: [],
    //-备注
    remark: '',
    base_arr: [],
    main_arr: [],
    other_arr: [],
    base_plus_amount: '0.00',
    base_minus_amount: '0.00',
    base_amount: '0.00',
    main_plus_amount: '0.00',
    main_minus_amount: '0.00',
    main_amount: '0.00',
    other_plus_amount: '0.00',
    other_minus_amount: '0.00',
    other_amount: '0.00',
    amount: '0.00',
  },
});
//-验证
const { numReg, priceReg } = patterns;
const validatorNum = (val) => {
  if (val) {
    if (parseFloat(val) <= 90 && parseFloat(val) >= 1) {
      if (numReg.test(val)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};
const validatorPoint = (val) =>
  val
    ? parseFloat(val) <= 100 && parseFloat(val) >= 0 && priceReg.test(val)
      ? true
      : false
    : true;
const validatorDiscount = (val) =>
  val
    ? parseFloat(val) <= 10 && parseFloat(val) >= 1 && priceReg.test(val)
      ? true
      : false
    : true;
/**
 * 校验方式
 */
//-单价
const validatorPrice = (val) => (val ? priceReg.test(val) : true);

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
//-end

//-操作&详情--begin

//-picked 操作
const onConfirm = (value, type) => {
  //-变更合同
  if (type === 'contract') {
    if (value) {
      form.list.contract_name = value.name;
      form.list.contract_id = value.id;
      form.list.contract_no = value.contract_no;
      state.chargeProportion = value.chargeProportion;
      state.contractType = value.type;
    }
    state.showContractPicker = false;
  } else if (type === 'payment') {
    //-款期
    if (value) {
      form.list.type_str = value.name;
      form.list.type = value.id;
    }
    state.showPaymentPicker = false;
  } else if (type === 'stage') {
    if (
      form.list.construction_stage !== value.id &&
      form.list.construction_stage !== ''
    ) {
      Dialog.confirm({
        message: '确认将切换变更阶段吗？一旦确认，绑定的意向单数据将清空',
      }).then(() => {
        //-变更阶段
        if (value) {
          form.list.construction_stage_name = value.name;
          form.list.construction_stage = value.id;
        }
        state.showTypePicker_stage = false;
        //-清空已选意向单
        form.list.intend_ids = form.list.intend_ids_valid = [];
        form.list.intend_list = [];
      });
    } else {
      //-变更阶段
      if (value) {
        form.list.construction_stage_name = value.name;
        form.list.construction_stage = value.id;
      }
      state.showTypePicker_stage = false;
    }
  } else if (type === 'point') {
    //-变更节点
    state.showPoint = false;
    // 文本拼接
    form.list.change_node_name = value[0].name + '-' + value[1].name;
    // id获取
    let index = Number(value[0].code[0]) - 1;
    let idx = Number(value[1].code.slice(2, value[1].code.length));
    form.list.change_node_id = state.pointList[index].list[idx].node_id;
  }
};
//-切换是否，清空数据
const clearFun = (v) => {
  if (v == 2) {
    form.list.area_id = '';
    form.list.change_node_id = '';
    form.list.change_node_name = '';
    form.list.change_schedule = '';
  }
};

//-意向单组件方法集合
const getIntendFun = () => {
  state.intendOption.show = true;
  state.intendOption.construction_stage = form.list.construction_stage;
  state.intendOption.list = form.list.intend_list;
};

const reviewIntendClose = () => {
  state.intendOption.show = false;
};
const reviewIntendOk = (data) => {
  state.intendOption.show = false;
  form.list.intend_list = data;
  form.list.intend_ids = data.map((i) => {
    return i.id;
  });
  form.list.intend_ids_valid = JSON.parse(JSON.stringify(form.list.intend_ids));
};
const delIntendFun = (id) => {
  form.list.intend_list = form.list.intend_list.filter((v) => {
    return v.id != id;
  });
  form.list.intend_ids = form.list.intend_list.map((i) => {
    return i.id;
  });
  form.list.intend_ids_valid = JSON.parse(JSON.stringify(form.list.intend_ids));
};
//-end

//-基装等三大卡片类型
const getPopup = (type) => {
  state.cardOptions.type = type;
  state.cardShow = true;
  if (type == 'base') {
    state.cardOptions.base_arr = form.list.base_arr;
    state.cardOptions.total = form.list.base_amount;
    state.cardOptions.totalPlus = form.list.base_plus_amount;
    state.cardOptions.totalMinus = form.list.base_minus_amount;
  }
  if (type == 'main') {
    state.cardOptions.main_arr = form.list.main_arr;
    state.cardOptions.total = form.list.main_amount;
    state.cardOptions.totalPlus = form.list.main_plus_amount;
    state.cardOptions.totalMinus = form.list.main_minus_amount;
  }
  if (type == 'other') {
    state.cardOptions.other_arr = form.list.other_arr;
    state.cardOptions.total = form.list.other_amount;
    state.cardOptions.totalPlus = form.list.other_plus_amount;
    state.cardOptions.totalMinus = form.list.other_minus_amount;
  }
};
//-保存三大类数据
const reviewOk = (total, list) => {
  if (total.type == 'base') {
    form.list.base_arr = list ? list.base_arr : state.cardOptions.base_arr;
    form.list.base_plus_amount = list
      ? total.totalPlus
      : state.cardOptions.totalPlus;
    form.list.base_minus_amount = list
      ? total.totalMinus
      : state.cardOptions.totalMinus;
    form.list.base_amount = list ? total.total : state.cardOptions.total;
  }
  if (total.type == 'main') {
    form.list.main_arr = list ? list.main_arr : state.cardOptions.main_arr;
    form.list.main_plus_amount = list
      ? total.totalPlus
      : state.cardOptions.totalPlus;
    form.list.main_minus_amount = list
      ? total.totalMinus
      : state.cardOptions.totalMinus;
    form.list.main_amount = list ? total.total : state.cardOptions.total;
  }
  if (total.type == 'other') {
    form.list.other_arr = list ? list.other_arr : state.cardOptions.other_arr;
    form.list.other_plus_amount = list
      ? total.totalPlus
      : state.cardOptions.totalPlus;
    form.list.other_minus_amount = list
      ? total.totalMinus
      : state.cardOptions.totalMinus;
    form.list.other_amount = list ? total.total : state.cardOptions.total;
  }
  state.cardShow = false;
  form.list.amount = (
    parseFloat(form.list.base_amount) +
    parseFloat(form.list.main_amount) +
    parseFloat(form.list.other_amount)
  ).toFixed(2);
  if (parseFloat(form.list.amount) < 0) {
    if (form.list.type == 2) {
      form.list.type = '';
      form.list.type_str = '';
      form.list.proportion_id = '';
    }
  } else {
    if (form.list.type == 1) {
      form.list.type = '';
      form.list.type_str = '';
    }
  }
};

//-修改款期类型
const getClickCharge = (v) => {
  if (v != form.list.proportion_id || form.list.proportion_id == '') {
    form.list.proportion_id = v;
    state.checked = [v];
  } else {
    form.list.proportion_id = '';
    state.checked = [];
  }
};
//-款期切换
const getTypeFun = () => {
  if (parseFloat(form.list.amount) < 0) {
    state.payment_type_list = state.payment_type_list_init.filter((v) => {
      return v.id == 1;
    });
  } else {
    state.payment_type_list = state.payment_type_list_init.filter((v) => {
      return v.id == 2;
    });
  }
  state.showPaymentPicker = true;
};

// @附件
const getFileList = (files) => {
  form.list.attach = files;
};

//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (form.list.attach && form.list.attach.length > 0) {
        goSave();
      } else {
        Toast('请上传附件');
      }
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
    });
};
const goSave = () => {
  state.loadingConfirm = true;
  // if (state.contractType != 53 && state.contractType != 69) {
  form.list.notify_customer_flag = true;
  // }
  if (form.list.amount < 0) {
    form.list.tax_amount = 0 - form.list.tax_amount_add;
    form.list.manage_amount = 0 - form.list.manage_amount_add;
    form.list.remote_fee = 0 - form.list.remote_fee_add;
    form.list.project_cost = 0 - form.list.project_cost_add;
  } else {
    form.list.tax_amount = form.list.tax_amount_add;
    form.list.manage_amount = form.list.manage_amount_add;
    form.list.remote_fee = form.list.remote_fee_add;
    form.list.project_cost = form.list.project_cost_add;
  }
  $http
    .getContractAddPlusMinusItem({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--表单end---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/change/change.less';
</style>
