/*
 * @Author: NanNan
 * @Date: 2024-02-21 15:21:06
 * @LastEditTime: 2024-02-22 14:47:50
 * @Description:格式化数据
 */
// -- 姓名[分公司/部门/岗位]
const FormatFullName = (name, ...arr) => {
  if (name) {
    const new_arr = arr.filter((item) => item !== '');
    return new_arr?.length > 0 ? name + '[' + new_arr.join('/') + ']' : name;
  } else {
    return '-';
  }
};

const FormatUserName = (info, arr, empty) => {
  const name = info[arr[0]];
  const arr_label = arr.slice(1);
  if (info && name) {
    const newarr = arr_label.map((value) => info[value]);
    const new_arr = newarr.filter((item) => item !== '');
    return new_arr?.length > 0 ? name + '[' + new_arr.join('/') + ']' : name;
  } else {
    return empty ? '' : '-';
  }
};

export { FormatFullName, FormatUserName };
