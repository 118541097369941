<!--
 * @Author: NanNan
 * @Date: 2023-12-07 09:25:35
 * @LastEditTime: 2023-12-11 18:19:00
 * @Description: v1.5.1 线索首页 - 空：创建新线索，一个线索：跳转详情，多个线索：变更状态
-->
<template>
  <div class="index-page">
    <svg class="icon-small1" aria-hidden="true">
      <use xlink:href="#icon-a-Group63"></use>
    </svg>
    <!-- 空页面  v-if="_DATA.page_type == 3"-->
    <div class="inner-box" v-if="_DATA.page_type == 1">
      <p class="h1">未找到此客户所属线索</p>
      <p class="more-text">
        如已有线索，请将客户标注成线索的手机号，并重新进入此页。
        <span>
          （绑定线索的微信才可追踪客户浏览案例、在线邀约设计师等操作）
        </span>
      </p>
      <p class="des">如为自主线索，请点此创建</p>
      <Button type="primary" size="large" @click="jumpto(1)">创建新线索</Button>
    </div>
    <!-- 切换线索 -->
    <ActionSheet
      v-model:show="_DATA.action_show"
      :closeable="false"
      close-on-click-action
      :close-on-click-overlay="false"
      title="请选择客户关联线索"
      @cancel="() => (_DATA.action_show = false)"
      class="change-action-sheet"
    >
      <template #description>
        <div class="h-description">
          绑定线索的微信才可追踪客户浏览案例、在线邀约设计师等操作
        </div>
      </template>
      <div class="action-inner">
        <div class="list-box">
          <div class="item" v-for="item in _DATA.list" :key="item.id">
            <div class="left">
              <p class="title">
                <span>{{ item.name }}</span>
                -
                <span>
                  {{ item.floor_name }}
                </span>
                -
                <span>{{ item.size }}</span>
              </p>
              <ul>
                <li>详细地址：{{ item.address || '-' }}</li>
                <li>线索编号：{{ item.clue_no || '-' }}</li>
                <li>来源：{{ item.channel_source_name || '-' }}</li>
                <li>
                  分配人：{{
                    item.distribution_user_name + ' ' + item.distribution_time
                  }}
                </li>
              </ul>
            </div>
            <div class="right">
              <Button size="small" class="btn-small" @click="changeType(item)">
                {{ item.type_name }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ActionSheet>
  </div>
</template>
<script setup>
import { inject, reactive, onMounted } from 'vue';
import { Notify, Button, ActionSheet, Dialog } from 'vant';
import { useRouter } from 'vue-router';

const $http = inject('$http');
const router = useRouter();

const _DATA = reactive({
  action_show: false,
  list: [],
  page_type: '', //1-空页面 、2-跳线索详情、3-线索选择绑定弹窗
});

onMounted(() => {
  getList();
});

/**
 * 操作
 */
// -- 变更
const changeType = (item) => {
  console.log(item.type_name);
  var title = item.type === 3 ? '敏感操作' : '提示';
  var message =
    item.type === 3
      ? '线索已有添加客户记录，是否确认变更为当前微信？'
      : '确认选择此线索？';
  Dialog.confirm({
    title,
    message,
    confirmButtonColor: '#0256FF',
  })
    .then(() => {
      // on confirm
      if (item.type == 2) {
        router.push({
          path: '/clueDetail',
          query: { id: item.id, source: 'clueIndex' },
        });
      } else {
        getBind(item);
      }
    })
    .catch(() => {
      // on cancel
      console.log('取消');
    });
};

// -- 跳转
const jumpto = (type) => {
  if (type === 1) {
    //去往新增线索
    router.push('/clueNewInfo?source=clueIndex');
  }
};

/**
 * api
 */
// -- api:线索列表(page_type:1-空页面 、2-跳线索详情、3-线索选择绑定弹窗)
const getList = () => {
  const external_userid = localStorage.getItem('WX_EXTERNAL_ID');
  $http
    .getClueShareDesignCase({
      external_user_id: external_userid,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.list = data.list;
        _DATA.page_type = data.page_type;
        if (data.page_type === 3) {
          _DATA.action_show = true;
        } else if (data.page_type === 2) {
          _DATA.action_show = false;
          window.location.href = '/clueDetail?id=' + data.list[0]?.id;
        } else if (data.page_type === 1) {
          _DATA.action_show = false;
        }
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
// -- api:变更
const getBind = (item) => {
  $http
    .getClueClueBind({
      clue_id: item.id,
      external_userid: localStorage.getItem('WX_EXTERNAL_ID'),
      type: item.type,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        console.log('操作成功');
        window.location.href = '/clueDetail?id=' + item.id;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/welcome.less';
</style>
