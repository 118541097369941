<!--
 * @Author: gest
 * @Date: 2023-09-12 13:51:19
 * @LastEditTime: 2023-10-27 11:19:26
 * @Description: v1.4.5 防水验收记录表详情
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <!-- v-for -->
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="flex1 mr_20">{{ item.name }}</div>
            <div
              :class="
                item.value == 1
                  ? 'blue_color'
                  : item.value == 2
                  ? 'red_color'
                  : ''
              "
            >
              {{ item.value == 1 ? '合格' : item.value == 2 ? '不合格' : '-' }}
            </div>
          </div>
        </div>

        <div
          class="list_field"
          style="background-color: #fafafa"
          v-if="item.value == 2"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">整改时间</div>
              <div>{{ item.time || '-' }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- v-for end-->
    </div>
    <div class="font_size_13 grey_color" style="padding: 0 15px 6px 15px">
      验收分析
    </div>
    <div class="content mb_15">
      <div class="white_bg">
        <div class="radio_box">
          <div
            class="radio_item flex_between align_center"
            style="padding-bottom: 0"
          >
            <div class="name">验收结果</div>
            <div
              class="flex_between"
              :class="
                form.info.check_pass == 1
                  ? 'blue_color'
                  : form.info.check_pass == 2
                  ? 'red_color'
                  : ''
              "
            >
              <!-- <svg class="icon-small mr_5" aria-hidden="true">
                <use xlink:href="#icon-a-emotion-fill1"></use>
              </svg> -->
              <div>
                {{
                  form.info.check_pass == 1
                    ? '通过'
                    : form.info.check_pass == 2
                    ? '不通过'
                    : '-'
                }}
              </div>
            </div>
          </div>
          <div
            class="radio_item flex_between align_center"
            style="padding-bottom: 0"
          >
            <div class="name">是否合格</div>
            <div
              class="flex_between"
              :class="
                form.info.check_result == 1
                  ? 'blue_color'
                  : form.info.check_result == 2
                  ? 'red_color'
                  : ''
              "
            >
              <!-- <svg class="icon-small mr_5" aria-hidden="true">
                <use xlink:href="#icon-a-emotion-sad-fill1"></use>
              </svg> -->
              <div>
                {{
                  form.info.check_result == 1
                    ? '合格'
                    : form.info.check_result == 2
                    ? '不合格'
                    : '-'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>{{ form.info.remark || '-' }}</div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);

const props = defineProps(['data']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
.radio_box {
  padding: 0 15px;
  .radio_item {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
    &:last-child {
      border-bottom: 0px;
    }
    .name {
      padding: 15px 0 10px 0;
    }
    .icon-small {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
    //-单选框样式自定义
    :deep(.van-radio-group--horizontal) {
      display: block;
      width: 100%;
      margin: 0;
    }
    :deep(.van-radio) {
      float: left;
      width: calc(50% - 50px);
      height: 44px;
      border-radius: 5px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-right: 0px;
      }
    }
    :deep(.van-radio__icon) {
      height: auto;
    }
    :deep(.van-radio__label) {
      font-size: 15px;
    }
    .radio_no {
      color: #ea0000;
      :deep(.van-radio__icon) {
        fill: #ea0000;
      }
      :deep(.van-radio__label) {
        color: #ea0000;
      }
      &.checked {
        background: rgba(234, 0, 0, 0.1);
        border: 1px solid #ea0000 !important;
      }
    }
    .radio_ok {
      color: #0256ff;
      :deep(.van-radio__icon) {
        fill: #0256ff;
      }
      :deep(.van-radio__label) {
        color: #0256ff;
      }
      &.checked {
        background: #e0eaff;
        border: 1px solid #0256ff !important;
      }
    }
  }
}
</style>
