<!--
 * @Author: gest
 * @Date: 2023-09-22 10:55:11
 * @LastEditTime: 2023-11-24 17:44:41
 * @Description: v1.4.6 家庭居室/优选整装阶段结算单详情
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">实际开工日期</div>
            <div class="">{{ form.info.start_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">预计完工日期</div>
            <div class="">{{ form.info.plan_end_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div
          class="field_item"
          v-if="
            (form.info.contract_type == 56 && props.sub_type == 22) ||
            props.sub_type != 22
          "
        >
          <div class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">
              <div>
                原工程合同{{
                  props.sub_type == 22
                    ? '隐蔽'
                    : props.sub_type == 23
                    ? '中期'
                    : props.sub_type == 24
                    ? '尾期'
                    : ''
                }}款
              </div>

              <div class="grey_color font_size_13">
                {{ form.info.proportion_name }}
              </div>
            </div>
            <div>
              <span class="blue_color">{{
                form.info.payment_period || form.info.payment_period === 0
                  ? form.info.payment_period
                  : '-'
              }}</span>
              元
            </div>
          </div>
        </div>

        <div
          class="field_item right"
          v-if="form.info.change_type && props.witness_status != 1"
        >
          <div class="flex_between">
            <div class="flex1 mr_15">
              {{
                props.sub_type == 22
                  ? '隐蔽'
                  : props.sub_type == 23
                  ? '中期'
                  : props.sub_type == 24
                  ? '尾期'
                  : ''
              }}变更
            </div>
            <div>
              {{
                form.info.change_type == 1
                  ? '增加'
                  : form.info.change_type == 2
                  ? '减少'
                  : '-'
              }}
            </div>
          </div>
        </div>
        <div class="field_item">
          <div class="flex_between">
            <div>
              {{
                props.sub_type == 22
                  ? '隐蔽'
                  : props.sub_type == 23
                  ? '中期'
                  : props.sub_type == 24
                  ? '尾期'
                  : ''
              }}变更款
            </div>

            <div class="flex_between" v-if="props.witness_status == 1">-</div>
            <div class="flex_between" v-else>
              <div class="mr_5" v-if="form.info.change_type == 1">+</div>
              <div class="mr_5" v-if="form.info.change_type == 2">-</div>
              <div>
                {{
                  form.info.change_amount || form.info.change_amount === 0
                    ? form.info.change_amount
                    : '-'
                }}<span class="ml_5">元</span>
              </div>
            </div>
          </div>
          <div
            class="text_right mt_5"
            v-if="
              form.info.contract_type != 56 &&
              props.sub_type == 22 &&
              (form.info.change_amount || form.info.change_amount === 0)
            "
          >
            {{
              form.info.change_amount || form.info.change_amount === 0
                ? convertCurrency(
                    form.info.change_type == 2
                      ? NP.times(-1, form.info.change_amount)
                      : form.info.change_amount
                  )
                : '-'
            }}
          </div>
        </div>
        <div
          class="field_item right"
          v-if="
            (form.info.contract_type == 56 && props.sub_type == 22) ||
            props.sub_type != 22
          "
        >
          <div class="flex_between">
            <div style="width: 220px">
              预收工程合同{{
                props.sub_type == 22
                  ? '隐蔽'
                  : props.sub_type == 23
                  ? '中期'
                  : props.sub_type == 24
                  ? '尾期'
                  : ''
              }}款
            </div>
            <div>
              {{
                form.info.received_amount || form.info.received_amount === 0
                  ? form.info.received_amount
                  : '-'
              }}<span class="ml_5">元</span>
            </div>
          </div>
        </div>
        <div
          class="field_item"
          v-if="
            (form.info.contract_type == 56 && props.sub_type == 22) ||
            props.sub_type != 22
          "
        >
          <div class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">当前应收工程款</div>
            <div v-if="props.witness_status == 1">
              <span>- </span>
            </div>
            <div v-else>
              <span class="blue_color">{{
                form.info.settled_amount || form.info.settled_amount === 0
                  ? form.info.settled_amount
                  : '-'
              }}</span>
              元
            </div>
          </div>
          <div
            class="text_right mt_5"
            v-if="
              (form.info.settled_amount || form.info.settled_amount === 0) &&
              props.witness_status != 1
            "
          >
            {{
              form.info.settled_amount || form.info.settled_amount === 0
                ? convertCurrency(form.info.settled_amount)
                : '-'
            }}
          </div>
          <div
            class="grey_color font_size_13 mt_15"
            v-if="props.sub_type == 22"
          >
            当前应收工程款=原工程合同隐蔽款+隐蔽变更款-预收工程合同隐蔽款
          </div>
          <div
            class="grey_color font_size_13 mt_15"
            v-if="props.sub_type == 23"
          >
            当前应收工程款=原工程合同中期款+中期变更款-预收工程合同中期款
          </div>

          <div
            class="grey_color font_size_13 mt_15"
            v-if="props.sub_type == 24"
          >
            当前应收工程款=原工程合同尾期款+尾期变更款-预收工程合同尾期款
          </div>
        </div>
        <div class="field_item right">
          <div class="flex_between">
            <div>客户最晚交款时间</div>

            <div>{{ form.info.expect_end_time || '-' }}</div>
          </div>
          <div class="font_size_13 mt_15 grey_color">
            请客户务必于该日期前缴纳款项到乙方银行账户，否则，可能由此影响工程进度。
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="font_size_13 grey_color border_bottom"
      style="padding: 15px 15px 6px 15px; background: #ffffff"
    >
      当前工程预决算总额
    </div> -->
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">当前工程预决算总额</div>
            <div v-if="props.witness_status == 1">
              <span>-</span>
            </div>
            <div v-else>
              <span class="blue_color">{{
                form.info.change_contract_amount ||
                form.info.change_contract_amount === 0
                  ? form.info.change_contract_amount
                  : '-'
              }}</span>
              元
            </div>
          </div>
          <div
            class="text_right mt_5"
            v-if="
              (form.info.change_contract_amount ||
                form.info.change_contract_amount === 0) &&
              props.witness_status != 1
            "
          >
            {{
              form.info.change_contract_amount ||
              form.info.change_contract_amount === 0
                ? convertCurrency(form.info.change_contract_amount)
                : '-'
            }}
          </div>
          <div class="grey_color font_size_13 mt_15">
            工程预决算总额=原合同金额+从签施工合同至{{
              props.sub_type == 22
                ? '隐蔽'
                : props.sub_type == 23
                ? '中期'
                : props.sub_type == 24
                ? '尾期'
                : ''
            }}工程变更确认单金额
          </div>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <div>{{ form.info.remark || '-' }}</div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
import { convertCurrency } from '@/utils/util';
import NP from 'number-precision';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type', 'witness_status']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
  changeMoney();
});
const changeMoney = () => {
  if (form.info.change_type == 1) {
    if (props.sub_type == 22 || form.info.change_amount_total != 0) {
      form.info.change_contract_amount = NP.plus(
        Number(form.info.change_amount_total),
        Number(form.info.change_amount)
      );
    }
    form.info.settled_amount = NP.minus(
      NP.plus(
        Number(form.info.payment_period),
        Number(form.info.change_amount)
      ),
      Number(form.info.received_amount)
    );
  } else if (form.info.change_type == 2) {
    if (props.sub_type == 22 || form.info.change_amount_total != 0) {
      form.info.change_contract_amount = NP.minus(
        Number(form.info.change_amount_total),
        Number(form.info.change_amount)
      );
    }

    form.info.settled_amount = NP.minus(
      NP.minus(
        Number(form.info.payment_period),
        Number(form.info.change_amount)
      ),
      Number(form.info.received_amount)
    );
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
