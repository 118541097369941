<!--
 * @Author: gest
 * @Date: 2023-09-08 15:00:54
 * @LastEditTime: 2023-09-09 11:31:38
 * @Description: 见证件pop模板，包含发起、提交、确认全流程操作权限
-->
<template>
  <NavBar
    safe-area-inset-top
    title="见证件"
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
  />
  <witnessViewDesign
    :data="props.data"
    v-if="props.data.type == 1"
  ></witnessViewDesign>
  <witnessViewConstruction
    :data="props.data"
    v-if="props.data.type == 2"
  ></witnessViewConstruction>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { NavBar } from 'vant';
import witnessViewDesign from '@/views/witness/witnessViewDesign';
import witnessViewConstruction from '@/views/witness/witnessViewDesign';
const props = defineProps(['data']);
const emit = defineEmits(['reviewOk']);

//-关闭popup 返回确认验收
const onClosePop = () => {
  emit('reviewOk');
};
</script>

<style lang="less" scoped></style>
