<!--
 * @Author: gest
 * @Date:2023-07-20 10:53:26
 * @LastEditTime: 2023-08-30 09:58:40
 * @Description: V1.4.2 指派交付中心
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="state.contract_list?.length > 0"
      @click="
        () => {
          state.contract_list?.length > 1
            ? (state.showPicker_contract = true)
            : null;
        }
      "
    >
      <div class="left">
        <h1>{{ state.contract_detail.contract_name }}</h1>
        <p>合同编号：{{ state.contract_detail.contract_no }}</p>
      </div>
      <div class="right" v-if="state.contract_list?.length > 1">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              交付中心
            </label>
            <Field
              v-model="form.list.deliver_center_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择交付中心"
              :rules="[
                {
                  required: true,
                  message: '请选择交付中心',
                },
              ]"
              @click="
                () => {
                  state.showPicker_deliver_center = true;
                }
              "
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>指派确认单</label
            >
            <FileUpload
              :data="form.list.files"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-头部合同切换 --->
    <Popup v-model:show="state.showPicker_contract" position="bottom">
      <Picker
        :columns="state.contract_list"
        :columns-field-names="{
          text: 'contract_name',
          value: 'contract_id',
        }"
        :default-index="
          state.contract_list.findIndex((v) => {
            return v.contract_id == form.list.contract_id;
          })
        "
        @confirm="(v) => onConfirm(v, 'contract')"
        @cancel="state.showPicker_contract = false"
      />
    </Popup>
    <!--picker-交付中心 --->
    <Popup v-model:show="state.showPicker_deliver_center" position="bottom">
      <Picker
        :columns="state.centerList"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          state.centerList.findIndex((v) => {
            return v.id == form.list.deliver_center_id;
          })
        "
        @confirm="(v) => onConfirm(v, 'deliver_center')"
        @cancel="state.showPicker_deliver_center = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-头部合同选择-picker
  showPicker_contract: false,
  contract_list: [],
  contract_detail: {},
  //-交付中心
  showPicker_deliver_center: false,
  centerList: [],
});

const onConfirm = (value, type) => {
  if (value) {
    // 头部合同切换
    if (type == 'contract') {
      state.contract_detail = value;
      form['list']['contract_id'] = value.contract_id;
      form['list']['construction_id'] = value.construction_id;
    } else if (type == 'deliver_center') {
      form['list'][type + '_id'] = value.id;
      form['list'][type + '_name'] = value.name;
    }
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
  getCustomerListParmas();
});

//-@头部合同参数
const getCustomerListParmas = async () => {
  await $http
    .getDesignCustomerListApi({
      customer_id: router.currentRoute.value.query?.id,
      type: 2,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        state.contract_list = data.list;
        if (data.list?.length) {
          const allList = data.list;
          const typeList1 = allList.filter(
            (v) => v.contract_id == form.list.contract_id
          );
          if (typeList1.length) {
            state.contract_detail = typeList1[0];
            form.list.construction_id = typeList1[0].construction_id;
            form.list.contract_id = typeList1[0].contract_id;
          } else {
            state.contract_detail = allList[0];
            form.list.construction_id = allList[0].construction_id;
          }
        }
      } else {
        Toast(res.data);
      }
    });
};

//-获取交付中心
const getPublicParameter = (dep_id) => {
  $http
    .getAssignDeliverCenterParam({
      customer_id: router.currentRoute.value.query?.id,
      dep_id: dep_id ?? '',
      contract_id: form.list.contract_id ?? '',
    })
    .then((res) => {
      if (res.code === 0) {
        state.centerList = res.data.deliver_center;
      } else {
        Toast(res.data);
      }
    });
};

//-获取参数--end

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    construction_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.gdid
      : '',
    contract_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.cid
      : '',
    deliver_center_id: '',
    deliver_center_name: '',
    remark: '',
    files: '',
  },
});
//-@附件
const getFileList = (files) => {
  form.list.files = files;
};
//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .construction_assignDeliverCenter({
          construction_id: form.list.construction_id,
          deliver_center_id: form.list.deliver_center_id,
          remark: form.list.remark,
          files: form.list.files,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
