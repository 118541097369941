<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2023-11-30 16:43:47
 * @Description: v0.3.0 施工 - 通知验收
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="node-name-box">
      <em>节点名称：</em>
      {{ node_name || '-' }}
    </div>
    <Form class="form-box" ref="formRef">
      <div class="person">
        <p class="label-name">验收人</p>
        <div class="cus-name">
          <span>{{ customer_name }}</span>
        </div>
      </div>
      <p class="label-name">
        <em>*</em>
        备注
      </p>
      <Field
        v-model.trim="form_info.remarks"
        rows="10"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加备注..."
        style="border: none; padding: 0"
      ></Field>
      <!-- end -->
      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Form, Field, Overlay, Loading, Button, Notify, Toast } from 'vant';

const $http = inject('$http');
const router = useRouter();
const node_id = ref(router.currentRoute.value.query.nid); //携带 节点id
const node_name = ref(router.currentRoute.value.query.nname); //携带 节点名称
const customer_name = ref(router.currentRoute.value.query.cname || '-');

const form_default = reactive({
  node_id: node_id.value, //*节点id
  remarks: '', //*备注
});
const form_info = reactive({});
const loading = ref(false);

/**
 * 初始化
 */
onMounted(() => {
  Object.assign(form_info, form_default);
});

/**
 * 操作
 */
//-@ 提交
const formRef = ref(null);
const onSubmit = () => {
  if (!form_info.remarks) {
    Toast('请填写备注');
    return false;
  }
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http.getConNoticeInspectedApi(form_info).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loading.value = false;
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
  });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;

  .node-name-box {
    padding: 20px;
    font-size: 16px;
    line-height: 18px;
    background: #f5f5f5;
    text-align: center;

    em {
      color: #999;
    }
  }

  .form-box {
    padding: 0 20px;
    .person {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      align-items: center;
    }

    .label-name {
      font-size: 16px;
      line-height: 20px;
      margin: 15px 0;
      font-weight: 700;

      &.white {
        color: #fff;
      }

      em {
        color: #ea3d2f;
      }
    }

    .cus-name {
      span {
        color: #1a1a1a;
        font-size: 15px;
      }
    }
  }
}
</style>
