/*
 * @Author: gest
 * @Date: 2023-06-17 16:32:05
 * @LastEditTime: 2023-12-22 16:08:01
 * @Description:V1.4.0目标数据-通用方法
 */

const getDelayTime = (pos, index) => {
  let time = 0;
  if (pos) {
    time = Math.abs(pos - index) * 200;
  }

  return time;
};
export { getDelayTime };
