<!--
 * @Author: NanNan
 * @Date: 2024-05-14 15:57:38
 * @LastEditTime: 2024-05-29 14:45:56
 * @Description: v1.6.0新版本 获客-见面:线索-见面时间
-->
<template>
  <div class="data_item extend chart_jd" style="padding-bottom: 0px">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、头部 -->
        <div class="data_card_top border_bottom">
          <div class="title">线索-见面时间</div>
          <!-- 目标/完成 -->
          <Row>
            <Col span="12">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.info?.target === '' ? '-' : _DATA.info?.target + '天'
                  }}
                </span>
              </div>
            </Col>
            <Col span="12">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.actual === '' ? '-' : _DATA.info?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info?.actual < _DATA.info?.target"
              >
                <span class="bold green">{{ _DATA.info?.gap }}天</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info?.actual > _DATA.info?.target"
              >
                <span class="bold orange">{{ _DATA.info?.gap }}天</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
          </Row>
        </div>
        <!-- 二、内容 -->
        <div class="data_card_table_bottom p0">
          <!-- 1标题  -->
          <Row class="mb_15 p_l15_r10">
            <Col span="6">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <Col span="6">
              <div class="grey align_center">目标</div>
            </Col>
            <Col span="6">
              <div class="grey align_center">完成</div>
            </Col>
            <Col span="6">
              <div class="grey align_center">差值</div>
            </Col>
          </Row>
          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(parentItem, parentIndex) in _DATA.list"
            :key="parentIndex"
          >
            <div class="sub_item5">
              <Row
                class="p_l15_r10"
                style="align-items: center; height: 100%"
                @click.stop="toggleAccordion(parentIndex, _DATA.activeIndex)"
              >
                <Col span="6">
                  <div
                    class="size13"
                    style="display: flex; align-items: center"
                  >
                    <template v-if="parentItem?.sub?.length > 0">
                      <Icon
                        name="play"
                        :style="
                          parentItem?.expanded
                            ? 'transform: rotate(90deg)'
                            : 'transform: rotate(0deg)'
                        "
                        class="arrow"
                      />
                    </template>
                    <p>{{ parentItem.short_name }}</p>
                  </div>
                </Col>
                <!-- 目标 -->
                <Col span="6">
                  <div>
                    <span class="lineheight16 align_center">
                      {{
                        parentItem.target === ''
                          ? '-'
                          : parentItem.target + '天'
                      }}
                    </span>
                  </div>
                </Col>
                <!-- 完成 -->
                <Col span="6">
                  <div>
                    <span class="lineheight16 align_center">
                      {{
                        parentItem.actual === ''
                          ? '-'
                          : parentItem.actual + '天'
                      }}
                    </span>
                  </div>
                </Col>
                <!-- 差值 -->
                <Col span="6">
                  <div
                    class="flex_line flex_line_center"
                    v-if="parentItem.actual < parentItem.target"
                  >
                    <span class="green">
                      {{ parentItem.gap + '天' }}
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <div
                    class="flex_line flex_line_center"
                    v-else-if="parentItem.actual > parentItem.target"
                  >
                    <span class="orange">
                      {{ parentItem.gap + '天' }}
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <div class="flex_line flex_line_center" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <Transition name="thred-fade">
              <TempThredMeetTime
                v-if="parentItem.sub && parentItem?.expanded"
                :data="parentItem"
                :index="parentIndex"
              />
            </Transition>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import {
  Row,
  Col,
  // Overlay,
  // Loading,
  Icon,
  Notify,
  Skeleton,
} from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import TempThredMeetTime from '@/components/targetData/thredData/collapseItem/TempThredMeetTime';
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: props.data.depId ? 2 : 7, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 1, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  open: false,
  line_values: [],
  middle_value: '',

  activeIndex: [],
  activeChildIndex: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depClueToMeet;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

/**
 * 操作
 */
// 展开收起-操作
const toggleAccordion = (subIndex) => {
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
  console.log(_DATA.list[subIndex]);
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
