<!--
 * @Author: NanNan
 * @Date: 2024-05-11 09:11:28
 * @LastEditTime: 2024-05-31 21:08:07
 * @Description: v1.6.0新版本 获客-协议：见面-协议时间
-->
<template>
  <div
    class="data_item extend chart_jd"
    style="padding-bottom: 0px; margin-top: 15px"
  >
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、头部 -->
        <div class="data_card_top border_bottom">
          <div class="title">见面-协议时间</div>
          <!-- 协议目标/当年完成/完成率 -->
          <Row>
            <Col span="8">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.info?.target === '' ? '-' : _DATA.info?.target + '天'
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">当年完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.actual === '' ? '-' : _DATA.info?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info?.actual < _DATA.info?.target"
              >
                <span class="bold green">{{ _DATA.info?.gap }}天</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info?.actual > _DATA.info?.target"
              >
                <span class="bold orange">{{ _DATA.info?.gap }}天</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.complete_rate === ''
                      ? '-'
                      : _DATA.info?.complete_rate + '%'
                  }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 二、内容 -->
        <div class="data_card_table_bottom p0">
          <!-- 1标题  -->
          <Row class="mb_15 p_l15_r10">
            <Col span="6">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>

            <!-- 进度图标题 begin-->
            <Col span="12" style="position: relative">
              <div class="chart_pos pr_30 pl_20">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 2,
                    unit: '天',
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
              </div>
            </Col>
            <!-- 进度图标题 end-->

            <Col span="6">
              <div class="grey align_center">完成率</div>
            </Col>
          </Row>
          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(parentItem, parentIndex) in _DATA.list"
            :key="parentIndex"
          >
            <Row
              class="p_l15_r10"
              style="align-items: center; height: 100%"
              @click.stop="toggleAccordion(parentIndex, _DATA.activeIndex)"
            >
              <Col span="6">
                <div class="size13" style="display: flex; align-items: center">
                  <template v-if="parentItem?.sub?.length > 0">
                    <Icon
                      name="play"
                      :style="
                        parentItem?.expanded
                          ? 'transform: rotate(90deg)'
                          : 'transform: rotate(0deg)'
                      "
                      class="arrow"
                    />
                  </template>
                  <p>{{ parentItem.short_name }}</p>
                </div>
              </Col>

              <!-- 进度图内容 begin-->
              <Col span="12" class="chart_item">
                <div style="display: flex" class="pr_30 pl_20">
                  <chartJDZW
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      actual: parentItem.actual,
                      target: parentItem.target,
                    }"
                  ></chartJDZW>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos pr_30 pl_20">
                  <chartCoverLine
                    :data="{
                      type: 2,
                      list: _DATA.line_values,
                      num: _DATA.middle_value,
                    }"
                  ></chartCoverLine>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 进度图内容 end-->

              <!-- 完成率 -->
              <Col span="6">
                <div>
                  <span class="lineheight16 align_center">
                    {{
                      parentItem.complete_rate === ''
                        ? '-'
                        : parentItem.complete_rate + '%'
                    }}
                  </span>
                </div>
              </Col>
            </Row>
            <Transition name="thred-fade">
              <TempMeetAgreementTime
                v-if="parentItem.sub && parentItem?.expanded"
                :data="parentItem"
                :index="parentIndex"
              />
            </Transition>
          </div>
          <!-- 图例 -->
          <div class="p_15 flex_center">
            <p class="box_8 red_bg ml_30"></p>
            <p class="ml_5">达标</p>
            <p class="box_8 green_bg ml_30"></p>
            <p class="ml_5">未达标</p>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import { Row, Col, Icon, Notify, Skeleton } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJDZW from '@/components/targetData/chatTemp/chartJDZW';
import TempMeetAgreementTime from '@/components/targetData/thredData/collapseItem/TempMeetAgreementTime';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: props.data.depId ? 2 : 7, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 1, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  line_values: [],
  middle_value: '',
  activeIndex: [],
  activeChildIndex: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depMeetToSigning;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

/**
 * 操作
 */
// 展开收起-操作
const toggleAccordion = (subIndex) => {
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
  console.log(_DATA.list[subIndex]);
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
