<!--
 * @Author: smart
 * @Date:2022-03-21 11:03:35
 * @LastEditTime: 2023-04-07 10:30:45
 * @Description: 楼盘名称组件 - 优化
 * v1.3.5 搜索框交互调整
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">选择楼盘</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="state.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup v-model="form.checked" v-if="form.list.length">
        <cell v-for="item in form.list" :key="item.id">
          <div class="member_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="member_item">
                <p class="name">{{ item.floor_name }}</p>
              </div>
            </Radio>
          </div>
        </cell>
      </RadioGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, defineEmits, onMounted, defineProps } from 'vue';
import { Button, Search, Cell, Empty, Radio, RadioGroup } from 'vant';

import { Toast } from 'vant';
const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['kw', 'check']);

const $http = inject('$http');

const state = reactive({
  loading: true,
  kw: props?.kw || '',
});
const form = reactive({
  checked: props?.check ? Number(props?.check) : '',
  checkedItem: {},
  list: [],
  floor_detail_list: [],
});
onMounted(() => {
  searchFun();
});
//-搜索
const searchFun = () => {
  form.list = [];
  form.floor_detail_list = [];
  if (state.kw) {
    onLoad();
  }
};

//-获取列表
const onLoad = () => {
  state.loading = true;
  $http
    .getFloorsApi({
      kw: state.kw,
    })
    .then((res) => {
      state.loading = false;
      const { code, data, msg } = res;
      if (code === 0) {
        form.list = data?.floor_list || [];
        form.floor_detail_list = data?.floor_detail_list || [];
        if (props?.check) {
          form.checkedItem = data?.floor_list?.filter(
            (item) => item.id == props?.check
          )?.[0];
        } else {
          form.checkedItem = {};
        }
      } else {
        form.list = [];
        Toast(msg || data);
      }
    });
};

const getClick = (item) => {
  form.checked = item.id;
  form.checkedItem = item;
  form.floor_detail_list = form.floor_detail_list.filter(
    (i) => i.floor_id == item.id
  );
};
//-确认操作
const btnSumbit = () => {
  emit('reviewOk', form.checkedItem, form.floor_detail_list);
};

//-取消
const clear = () => {
  form.checkedItem = {};
  form.floor_detail_list = [];
  emit('reviewOk', form.checkedItem, form.floor_detail_list);
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
</style>
