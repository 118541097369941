<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2022-08-24 14:31:42
 * @Description: 
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <Field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in state.signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <Field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></Field>
          </div>
        </CellGroup>
      </Form>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, ref, defineExpose } from 'vue';
import { Form, Field, CellGroup } from 'vant';
const props = defineProps(['data']);
const state = reactive({
  //-合同签署方
  signatoryList: [
    { id: 1, name: '个体' },
    { id: 2, name: '企业' },
  ],
});

//--表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  signatory: props.data.signatory ? props.data.signatory : '',
  company_name: props.data.company_name ? props.data.company_name : '',
});

defineExpose({ form, ruleFormRef });
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
