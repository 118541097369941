<!--
 * @Author: gest
 * @Date: 2023-08-08 15:53:21
 * @LastEditTime: 2024-06-14 11:16:10
 * @Description: v1.5.2 业绩榜单
-->
<template>
  <div
    class="rank_box"
    :class="{
      black: _DATA.rankId == 2,
      red: _DATA.rankId == 1,
      has_mine: _DATA.myInfo,
    }"
  >
    <div class="img_box">
      <img
        :src="require('@/assets/images/redrank.jpg')"
        style="width: 100%"
        v-show="_DATA.rankId == 1"
      />
      <img
        :src="require('@/assets/images/blackrank.jpg')"
        style="width: 100%"
        v-show="_DATA.rankId == 2"
      />
      <div class="time">红珊瑚数据统计·11月20日更新</div>
    </div>

    <div class="top_box">
      <Icon class="left" name="arrow-left" @click="onClose" />
      <div
        class="right"
        @click="
          () => {
            _DATA.showPicker = true;
          }
        "
      >
        <div class="font_size_17">切换榜单</div>
        <svg class="icon-small" aria-hidden="true">
          <use xlink:href="#icon-rongqi"></use>
        </svg>
      </div>
    </div>

    <div class="search_box" :class="_DATA.rankId == 2 ? 'black' : 'red'">
      <div
        class="left"
        @click="
          () => {
            _DATA.showPicker_time = true;
          }
        "
      >
        <svg class="icon-small" aria-hidden="true">
          <use xlink:href="#icon-history-fill"></use>
        </svg>
        <div class="ml_5">{{ _DATA.yearId }}年度</div>
        <svg class="icon-small small" aria-hidden="true">
          <use xlink:href="#icon-rongqi"></use>
        </svg>
      </div>
      <div
        class="right"
        @click="
          () => {
            _DATA.showPicker_role = true;
          }
        "
      >
        <div>{{ _DATA.roleName }}</div>
        <svg class="icon-small" aria-hidden="true">
          <use xlink:href="#icon-rongqi"></use>
        </svg>
      </div>
    </div>
    <div class="rank_list" :class="_DATA.rankId == 2 ? 'black' : 'red'">
      <div
        class="rank_item"
        v-for="(item, index) in _DATA.rankInfo"
        :key="index"
      >
        <div class="left">
          <div class="rank">
            <img
              v-if="item.rank_num == 1 && _DATA.rankId == 1"
              :src="require('@/assets/images/rank1.png')"
              style="width: 44px"
            />
            <img
              v-if="item.rank_num == 2 && _DATA.rankId == 1"
              :src="require('@/assets/images/rank2.png')"
              style="width: 44px"
            />
            <img
              v-if="item.rank_num == 3 && _DATA.rankId == 1"
              :src="require('@/assets/images/rank3.png')"
              style="width: 44px"
            />
            <span class="num" v-if="item.rank_num >= 4 || _DATA.rankId == 2">{{
              item.rank_num >= 10 ? item.rank_num : '0' + item.rank_num
            }}</span>
          </div>

          <img
            :src="
              item?.face_image
                ? item.face_image.includes('http')
                  ? item.face_image
                  : $host + item?.face_image
                : require('@/assets/images/icon-empty-img.png')
            "
            class="head_img"
            :class="{
              first: _DATA.rankId == 1 && item.rank_num == 1,
              second: _DATA.rankId == 1 && item.rank_num == 2,
              third: _DATA.rankId == 1 && item.rank_num == 3,
            }"
          />

          <div class="ml_20">
            {{ item.name }}
            <p class="desc" v-show="_DATA.rankId == 2">{{ item.short_name }}</p>
          </div>
        </div>
        <div class="right">
          {{ _DATA.rankId == 2 ? item.val + '桶' : item.short_name }}
        </div>
      </div>
    </div>
    <div
      class="self_box"
      :class="_DATA.rankId == 2 ? 'black' : 'red'"
      v-if="_DATA.myInfo"
    >
      <div class="self_item rank_item">
        <div class="left">
          <div class="rank">
            <img
              v-if="_DATA.myInfo.rank_num == 1 && _DATA.rankId == 1"
              :src="require('@/assets/images/rank1.png')"
              style="width: 44px"
            />
            <img
              v-if="_DATA.myInfo.rank_num == 2 && _DATA.rankId == 1"
              :src="require('@/assets/images/rank2.png')"
              style="width: 44px"
            />
            <img
              v-if="_DATA.myInfo.rank_num == 3 && _DATA.rankId == 1"
              :src="require('@/assets/images/rank3.png')"
              style="width: 44px"
            />
            <span
              class="num"
              v-if="_DATA.myInfo.rank_num >= 4 || _DATA.rankId == 2"
              >{{
                _DATA.myInfo.rank_num >= 10
                  ? _DATA.myInfo.rank_num
                  : '0' + _DATA.myInfo.rank_num
              }}</span
            >
          </div>

          <img
            :src="
              _DATA.myInfo?.face_image
                ? _DATA.myInfo.face_image.includes('http')
                  ? _DATA.myInfo.face_image
                  : $host + _DATA.myInfo?.face_image
                : require('@/assets/images/icon-empty-img.png')
            "
            class="head_img"
            :class="{
              first: _DATA.rankId == 1 && _DATA.myInfo.rank_num == 1,
              second: _DATA.rankId == 1 && _DATA.myInfo.rank_num == 2,
              third: _DATA.rankId == 1 && _DATA.myInfo.rank_num == 3,
            }"
          />

          <div class="ml_20">
            {{ _DATA.myInfo.name }}
            <p class="desc" v-show="_DATA.rankId == 2">
              {{ _DATA.myInfo.short_name }}
            </p>
          </div>
        </div>
        <div class="right">
          {{
            _DATA.rankId == 2
              ? _DATA.myInfo.val + '桶'
              : _DATA.myInfo.short_name
          }}
        </div>
      </div>
    </div>
  </div>
  <!--picker --->
  <Popup v-model:show="_DATA.showPicker" position="bottom">
    <Picker
      :columns="_DATA.showPicker_list"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirm(v, '切换榜单')"
      @cancel="_DATA.showPicker = false"
    />
  </Popup>
  <Popup v-model:show="_DATA.showPicker_time" position="bottom">
    <Picker
      :columns="_DATA.showPicker_list_time"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirm(v, '年份')"
      @cancel="_DATA.showPicker_time = false"
    />
  </Popup>
  <Popup v-model:show="_DATA.showPicker_role" position="bottom">
    <Picker
      :columns="_DATA.showPicker_list_role"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirm(v, '角色')"
      @cancel="_DATA.showPicker_role = false"
    />
  </Popup>
</template>
<script setup>
import { reactive, inject, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Toast, Notify, Icon, Popup, Picker } from 'vant';
const $host = inject('$host');
const $http = inject('$http');

const router = useRouter();

const _DATA = reactive({
  id: ref(Number(router.currentRoute.value.query.id)),

  rankInfo: [],
  myInfo: null,
  showPicker: false,
  showPicker_list: [
    { id: 1, name: '红榜' },
    { id: 2, name: '黑榜' },
  ],
  showPicker_time: false,
  showPicker_list_time: [],
  showPicker_role: false,
  showPicker_list_role: [],
  rankId: 1,
  yearId: null,
  roleId: 2, //2设计师
  roleName: '设计师榜单',
});
//-picker-选择项字段--begin
const customFieldName = {
  text: 'name',
  value: 'id',
};
onMounted(() => {
  getParams();
});

/**
 * 接口调用
 */
const getParams = () => {
  $http
    .targetStatistic_getParams({
      id: _DATA.id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.showPicker_list_role = data.rank_user_types;
        let yearList = [];
        data.years.forEach((v) => {
          yearList.push({ id: v, name: v });
        });
        _DATA.showPicker_list_time = yearList;
        let yearArr = data.years.slice(-1);
        _DATA.yearId = yearArr[0];
        loadFunc();
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
const loadFunc = () => {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 0,
  });
  _DATA.rankInfo = [];
  _DATA.myInfo = null;
  $http
    .targetStatistic_performanceRankList({
      type: _DATA.rankId,
      user_type: _DATA.roleId,
      year: _DATA.yearId,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.rankInfo = data.list;
      console.log(_DATA.rankInfo);
      _DATA.myInfo = data.mine.id ? data.mine : null;
      setTimeout(() => {
        Toast.clear();
      }, 600);
      if (code !== 0) {
        Notify({ type: 'warning', message: data });
      }
    });
};
const onConfirm = (value, type) => {
  switch (type) {
    case '切换榜单':
      _DATA.rankId = value.id;
      _DATA.showPicker = false;
      loadFunc();
      break;
    case '年份':
      _DATA.yearId = value.id;
      _DATA.showPicker_time = false;
      loadFunc();
      break;
    case '角色':
      _DATA.roleId = value.id;
      _DATA.roleName = value.name;
      _DATA.showPicker_role = false;
      loadFunc();
      break;
    default:
      break;
  }
};
const onClose = () => {
  router.back();
};
</script>
<style lang="less" scoped>
.rank_box {
  position: relative;

  min-height: 100vh;
  &.has_mine {
    padding-bottom: 87px;
  }
  &.red {
    background: #6e1914;
  }
  &.black {
    background: black;
  }
  .img_box {
    position: relative;
    display: flex;
    .time {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(255, 255, 255, 0.7);
      position: absolute;
      bottom: 50px;
      width: 100%;
    }
  }
  .top_box {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    .left {
      color: white;
      padding: 15px 20px;
    }
    .right {
      display: flex;
      align-items: center;
      color: white;
      .icon-small {
        width: 8px;
        height: 8px;
        vertical-align: -4px;
        fill: currentColor;
        margin-left: 5px;
      }
    }
  }

  .search_box {
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.7);

    display: flex;
    justify-content: space-between;
    font-size: 13px;
    &.red {
      background: #6e1914;
    }
    &.black {
      background: black;
    }
    .left {
      display: flex;
      align-items: center;

      .icon-small {
        width: 14px;
        height: 14px;
        vertical-align: -4px;
        fill: currentColor;
        &.small {
          width: 8px;
          height: 8px;
          margin-left: 5px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .icon-small {
        width: 8px;
        height: 8px;
        vertical-align: -4px;
        fill: currentColor;
        margin-left: 5px;
      }
    }
  }
  .rank_list {
    color: white;
    &.red {
      background: #6e1914;
    }
    &.black {
      background: black;
    }
  }
  .self_box {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
    &.red {
      background: #6e1914;
      .self_item {
        background: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(50px);
      }
    }
    &.black {
      background: black;
      .self_item {
        background: rgba(141, 144, 145, 0.2);
        backdrop-filter: blur(50px);
      }
    }
  }
  .rank_item {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .left {
      display: flex;
      align-items: center;

      .rank {
        width: 80px;
        display: flex;
        .num {
          width: 40px;
          text-align: center;
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .head_img {
        width: 48px;
        height: 48px;

        border-radius: 50%;
        &.first {
          border: 2px solid #fad199;
        }
        &.second {
          border: 2px solid #f4f4f4;
        }
        &.third {
          border: 2px solid #b89587;
        }
      }
      .desc {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
