<!--
 * @Author: gest
 * @Date: 2023-09-27 09:26:05
 * @LastEditTime: 2023-10-05 14:37:07
 * @Description: V1.4.7标记指派
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- <NavBar
      safe-area-inset-top
      left-arrow
      title="标记指派"
      @click-left="onBack"
      :fixed="true"
    /> -->
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              指派说明
            </label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写标记指派原因..."
              :rules="[
                {
                  required: true,
                  message: '请填写指派说明',
                },
              ]"
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.files"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
  </div>
</template>

<script setup>
import FileUpload from '@/components/common/FileUpload';

import { inject, reactive, ref } from 'vue';
import {
  // NavBar,
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';

import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-全部待办类型
  dtype: router.currentRoute.value.query
    ? router.currentRoute.value.query.dtype
    : '',
});
// //-bar返回操作
// const onBack = () => {
//   router.back();
// };

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',

    remark: '',
    files: [],
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .construction_tagAssign({
          ...form.list,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                // 哪里来回哪里去
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-时间控件--end

const getFileList = (files) => {
  form.list.files = files;
};
//--上传附件end----------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
