<!--
 * @Author: Jessica
 * @Date: 2023-12-22 09:38:21
 * @LastEditTime: 2023-12-23 09:56:39
 * @Description:  渠道费-申请
-->
<template>
  <div class="page-waper">
    <Overlay :show="state.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <!--渠道消息 -->
      <div class="customer_info pt_15">
        <ol>
          <li>
            <label>申请人</label>
            <p>{{ options?.user_name || '-' }}</p>
          </li>
          <li>
            <label>申请时间</label>
            <p>{{ options?.apply_date || '-' }}</p>
          </li>
          <li>
            <label class="col1">渠道费金额</label>
            <p class="col2">
              {{
                options?.channel_fee || options.channel_fee === 0
                  ? '¥' + options.channel_fee
                  : '-'
              }}
            </p>
          </li>
          <li>
            <label class="col1">税负</label>
            <p class="col2">
              {{
                options?.channel_tax_fee || options.channel_tax_fee === 0
                  ? '¥' + options.channel_tax_fee
                  : '-'
              }}
            </p>
          </li>
          <li>
            <label class="col1">总计支付金额</label>
            <p class="col2">
              {{
                options?.channel_pay_fee || options.channel_pay_fee === 0
                  ? '¥' + options.channel_pay_fee
                  : '-'
              }}
            </p>
          </li>
        </ol>
      </div>
      <!--项目消息 -->
      <h3 class="top_title">项目信息</h3>
      <div class="customer_info">
        <ol>
          <li>
            <label>客户编码</label>
            <p>{{ options?.no || '-' }}</p>
          </li>
          <li>
            <label>客户姓名</label>
            <p>{{ options?.customer_name || '-' }}</p>
          </li>
          <li>
            <label>项目地址</label>
            <p>{{ options?.estate_str || '-' }}</p>
          </li>
          <li>
            <label>所属中心</label>
            <p>{{ options?.design_center_name || '-' }}</p>
          </li>
          <li>
            <label>客户经理</label>
            <p>{{ options?.customer_manager_name || '-' }}</p>
          </li>
          <li>
            <label>设计师</label>
            <p>{{ options?.designer_name || '-' }}</p>
          </li>
          <li>
            <label>施工阶段</label>
            <p>{{ options?.construction_stage || '-' }}</p>
          </li>
        </ol>
      </div>

      <!--收款人信息 -->
      <h3 class="top_title">收款人信息</h3>
      <Form ref="ruleFormRef">
        <div class="part mb_15">
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              收款人
            </label>
            <Field
              v-model="form.list.collect_user"
              maxlength="15"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              联系方式
            </label>
            <Field
              v-model="form.list.collect_contact"
              maxlength="11"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
                {
                  validator: validatorPhone,
                  message: '请填写正确的联系方式',
                },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              银行账号
            </label>
            <Field
              v-model="form.list.collect_bank_account"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
                {
                  validator: validatorAccount,
                  message: '请填写正确的银行账号',
                },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              身份证号
            </label>
            <Field
              v-model="form.list.collect_card_no"
              maxlength="18"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
                { validator: validatorID, message: '请输入正确的身份证号' },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              银行名称
            </label>
            <Field
              v-model="form.list.collect_bank_name"
              maxlength="15"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              银行支行名称
            </label>
            <Field
              v-model="form.list.collect_bank_branch"
              maxlength="20"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              联行号
            </label>
            <Field
              v-model="form.list.collect_bank_branch_code"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                },
              ]"
              placeholder="请输入"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注说明</label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="有特殊说明请补充..."
              show-word-limit
            ></Field>
          </div>
        </div>

        <div class="btnSumbit btnSumbit1">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            提交
          </Button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Form, Field, Toast, Notify, Button, Loading, Overlay } from 'vant';
import patterns from '@/utils/pattern';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loading: false,
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
});

//-参数&详情-begin
onMounted(() => {
  getInfo();
});

//-获取参数
const options = reactive({});
const getInfo = async () => {
  state.loading = true;
  await $http
    .company_channelFeeInfoDetail({ customer_id: state.customer_id })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        Object.assign(options, data);
        form.list.channel_fee = data.channel_fee;
        form.list.channel_tax_fee = data.channel_tax_fee;
        form.list.channel_pay_fee = data.channel_pay_fee;
      } else {
        Toast(data);
      }
    });
};

//-end

//--表单&验证--------begin
const { accountReg, phoneReg, IDReg } = patterns;
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
const validatorID = (val) => (val ? IDReg.test(val) : true);
const validatorAccount = (val) => (val ? accountReg.test(val) : true);

const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: state.customer_id ?? '', //-客户id
    channel_fee: '', //-渠道费金额
    channel_tax_fee: '', //-税负
    channel_pay_fee: '', //-实际支付金额
    collect_user: '', //-收款人
    collect_contact: '', //-联系方式
    collect_bank_account: '', //-银行账号
    collect_card_no: '', //-身份证号
    collect_bank_name: '', //-银行名称
    collect_bank_branch: '', //-银行支行名称
    collect_bank_branch_code: '', //-联行号
    remark: '', //-备注
  },
});

//-操作&详情--begin

//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goSave();
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
    });
};
const goSave = () => {
  state.loading = true;
  $http
    .company_channelFeeApply({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loading = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--表单end---------------------
</script>

<style lang="less" scoped>
.page-waper {
  width: 100%;
  background: #f8f8f8;
  box-sizing: border-box;
  padding-bottom: 90px;
  min-height: calc(100vh - 90px);
  .top_title {
    color: #8e8e8e;
    font-size: 13px;
    padding: 10px 20px;
  }
  .pt_15 {
    padding-top: 15px;
  }
  //-项目信息
  .customer_info {
    > ol {
      background: #fff;
      padding: 0 15px;
      > li {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(228, 228, 228, 0.6);
        &:last-child {
          border-bottom: 0px;
        }
        .col1 {
          color: #000000;
          font-size: 15px;
        }
        .col2 {
          color: #0256ff;
          font-size: 15px;
          font-weight: 600;
        }
        > label {
          color: #8d9091;
          font-size: 15px;
          min-width: 80px;
        }
        > span {
          color: #000000;
          font-size: 15px;
        }
      }
    }
  }
  //-收款信息
  .part {
    background: #fff;
    margin-bottom: 15px;
    .cell_no {
      padding: 15px 0px;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      &:last-child {
        border-bottom: 0;
      }
      .form_title {
        font-size: 15px;
        min-width: fit-content;
      }
      :deep(.van-cell) {
        padding: 5px 0;
        text-align: right;
        border: 0;
        .van-field__error-message {
          text-align: right;
        }
        .van-field__control {
          text-align: right;
          justify-content: flex-end;
          padding-left: 10px;
          font-size: 15px;
        }
      }
    }
    .cell {
      padding: 15px 0px;
      margin: 0 15px;
      .form_title {
        font-size: 15px;
        min-width: fit-content;
      }
      :deep(.van-cell) {
        padding: 5px 0;
        border: 0;
        .van-field__control {
          font-size: 15px;
        }
      }
    }
  }
  //-提交操作
  .btnSumbit {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 120px;
    background: #fff;
    box-shadow: 0px -1px 4vw rgb(0 0 0 / 10%);
    &.btnSumbit1 {
      height: 90px;
    }
    .btn {
      width: calc(100% - 50px);
      margin: 0 25px;
      position: relative;
      top: 20px;
    }
    > p {
      width: 100%;
      height: 20px;
      position: relative;
      top: 30px;
      font-size: 15px;
      line-height: 20px;
      color: #aaaaaa;
      text-align: center;
    }
  }
}
</style>
