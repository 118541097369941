<!--
 * @Author: Jessica
 * @Date: 2024-01-31 14:08:46
 * @LastEditTime: 2024-05-29 11:31:35
 * @Description:V1.6.0-交付产值
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 总计 -->
        <div class="data_card_top">
          <div class="title flex_line_between">
            <span class="size17 bold">交付产值</span>
            <div
              class="size13 color_999 flex_line_between"
              v-if="props.data?.now_year == props.data?.yearId"
            >
              目标累计至当月值
              <!-- 规则入口 -->
              <div class="icon_info_box_small pl_3" @click.stop="createDanger">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/icon_info.png')"
                  alt="info"
                />
              </div>
            </div>
          </div>
          <!-- 目标/完成/完成率 -->
          <!-- 当年 -->
          <Row v-if="props.data?.now_year == props.data?.yearId">
            <Col span="7">
              <div class="grey align_center">目标（万）</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target === '' ? '-' : _DATA.info?.target }}
                </span>
              </div>
            </Col>
            <Col span="9">
              <div class="grey align_center">完成（万）</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.actual }}
                </span>
              </div>
              <!-- 差值 -->
              <div v-if="_DATA.info?.gap !== ''">
                <div
                  class="flex_line flex_line_center"
                  v-if="_DATA.info.actual > _DATA.info.target"
                >
                  <span class="bold green">{{ _DATA.info.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_line_center"
                  v-else-if="_DATA.info.actual < _DATA.info.target"
                >
                  <span class="bold orange">{{ _DATA.info.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_line_center" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成率</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.rate === '' ? '-' : _DATA.info?.rate + '%' }}
                </span>
              </div>
            </Col>
          </Row>
          <!-- 往年 -->
          <Row v-else>
            <Col span="24">
              <div class="grey align_center">完成（万）</div>
              <div class="align_center size17 bold">
                {{ _DATA.info?.actual }}
              </div>
            </Col>
          </Row>
        </div>
        <!-- 图表 -->
        <!-- 当年 -->
        <div
          class="data_card_table border_top"
          v-if="props.data?.now_year == props.data?.yearId"
        >
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col :span="props.data.depId ? 19 : 15" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->

                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <Col span="4" v-if="!props.data.depId">
              <div class="grey align_center">完成率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col :span="props.data.depId ? 19 : 15" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    v-if="props.data.depId"
                    :data="{
                      actual: item.actual,
                      list: _DATA.line_values,
                      type: 3,
                    }"
                  ></chartJD>
                  <chartJD
                    v-else
                    :data="{
                      actual: item.actual,
                      target: item.target,
                      list: _DATA.line_values,
                      type: 1,
                    }"
                  ></chartJD>
                  <div class="chart_right" v-if="props.data.depId"></div>
                  <div class="chart_right" v-else>
                    {{ item?.target === '' ? '-' : item?.target }}
                  </div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 完成率:二级页展示 -->
              <Col span="4" class="flex_line_center" v-if="!props.data.depId">
                <div>{{ item.rate + '%' }}</div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 往年 -->
        <div class="data_card_table border_top" v-else>
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col span="19" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->

                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="19" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      actual: item.actual,
                      list: _DATA.line_values,
                      type: 3,
                    }"
                  ></chartJD>

                  <div class="chart_right"></div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 -->
        <div
          class="p_15 flex_center"
          v-if="props.data?.now_year == props.data?.yearId && !props.data.depId"
        >
          <p class="box_8 grey_bg"></p>
          <p class="ml_5">目标</p>
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
          <p class="box_8 red2_bg ml_30"></p>
          <p class="ml_5">超额</p>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  inject,
  watch,
  onMounted,
  defineExpose,
} from 'vue';
import _ from 'lodash';
import { Row, Col, Skeleton, Icon, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 1, //处在页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 1, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  //-进度条相关
  line_values: [],
  middle_value: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depConstructPerformance;
  let params = { time_type: 'now', year: props.data.yearId, column_num: 4 };
  if (props.data.depId) {
    url = $http.targetStatistic_personConstructPerformance;
    params = {
      time_type: 'now',
      department_id: props.data.depId,
      year: props.data.yearId,
      column_num: 4,
    };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.info = {};
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */

const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depSiteData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};

//-规则说明--begin
const _rule = reactive({
  htmlTitle: '交付产值_规则',
  htmlContent: {
    yearInfo: '',
    titleInit: [
      { name: '月份' },
      { name: '交付一' },
      { name: '交付二' },
      { name: '交付三' },
      { name: '交付四' },
      { name: '交付五' },
    ],
    contentInit: [
      {
        title: '1月',
        value1: 720,
        value2: 1000,
        value3: 1000,
        value4: 1300,
        value5: 0,
      },
      {
        title: '2月',
        value1: 900,
        value2: 700,
        value3: 500,
        value4: 500,
        value5: 0,
      },
      {
        title: '3月',
        value1: 950,
        value2: 1200,
        value3: 1000,
        value4: 1200,
        value5: 20,
      },
      {
        title: '4月',
        value1: 1500,
        value2: 1200,
        value3: 1000,
        value4: 800,
        value5: 50,
      },
      {
        title: '5月',
        value1: 1080,
        value2: 1500,
        value3: 1000,
        value4: 1000,
        value5: 80,
      },
      {
        title: '6月',
        value1: 1300,
        value2: 1500,
        value3: 1000,
        value4: 1500,
        value5: 100,
      },

      {
        title: '7月',
        value1: 1400,
        value2: 1500,
        value3: 1100,
        value4: 1000,
        value5: 100,
      },
      {
        title: '8月',
        value1: 1500,
        value2: 1500,
        value3: 1200,
        value4: 800,
        value5: 300,
      },
      {
        title: '9月',
        value1: 1100,
        value2: 1300,
        value3: 1200,
        value4: 1000,
        value5: 400,
      },
      {
        title: '10月',
        value1: 1600,
        value2: 1200,
        value3: 1200,
        value4: 1000,
        value5: 400,
      },
      {
        title: '11月',
        value1: 1700,
        value2: 1500,
        value3: 1200,
        value4: 1000,
        value5: 400,
      },
      {
        title: '12月',
        value1: 2000,
        value2: 1650,
        value3: 1200,
        value4: 1500,
        value5: 400,
      },
    ],
    title: [],
    content: [],
  },
});
const createDanger = () => {
  _rule.htmlContent.yearInfo = props.data.yearId + '年';
  //-特殊处理：三级页只展示该交付中心的产值
  if (props.data.depId) {
    let dataTitle = _.cloneDeep(_rule.htmlContent.titleInit);
    let dataContent = _.cloneDeep(_rule.htmlContent.contentInit);
    _rule.htmlContent.title = dataTitle.filter((v) => {
      if (v.name == '月份' || v.name == props.data.depName) {
        return v;
      }
    });
    _rule.htmlContent.content = dataContent.map((v) => {
      let obj = { title: v.title, value: '' };
      if (props.data.depName == '交付一') {
        obj.value = v.value1;
      } else if (props.data.depName == '交付二') {
        obj.value = v.value2;
      } else if (props.data.depName == '交付三') {
        obj.value = v.value3;
      } else if (props.data.depName == '交付四') {
        obj.value = v.value4;
      } else if (props.data.depName == '交付五') {
        obj.value = v.value5;
      }
      return obj;
    });
  } else {
    _rule.htmlContent.title = _.cloneDeep(_rule.htmlContent.titleInit);
    _rule.htmlContent.content = _.cloneDeep(_rule.htmlContent.contentInit);
  }
  emit('createDanger', 'deliveryPerformanceRef');
};
defineExpose({ _rule });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
