<!--
 * @Author: gest
 * @Date: 2023-09-14 15:21:18
 * @LastEditTime: 2023-10-27 11:18:13
 * @Description: v1.4.5 产品二次交底单 
-->
<template>
  <div class="formBase">
    <div
      class="content white_bg mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="p_15 flex_between">
        <div class="flex_column">
          <div class="font_size_17 font_bold">{{ item.name }}</div>
          <div class="grey_color font_size_13">
            共{{ item.children.length }}项<span
              v-show="getTotalFun(item.children, '空值') != 0"
              >，<span class="red_color">{{
                getTotalFun(item.children, '空值')
              }}</span
              >项待确认</span
            >
          </div>
        </div>

        <div
          class="flex_between line_info"
          style="background: #ffffff; padding: 0 0 0 10px"
        >
          <div class="right">
            <div class="r_item">
              <div class="green_color font_size_17">
                {{ getTotalFun(item.children, '已交底') }}
              </div>
              <div class="grey_color font_size_12">已交底</div>
            </div>
            <div class="vline"></div>
            <div class="r_item">
              <div class="red_color font_size_17">
                {{ getTotalFun(item.children, '未交底') }}
              </div>
              <div class="grey_color font_size_12">未交底</div>
            </div>
            <div class="vline"></div>
            <div class="r_item">
              <div class="grey_color font_size_17">
                {{ getTotalFun(item.children, '无此项') }}
              </div>
              <div class="grey_color font_size_12">无此项</div>
            </div>
            <div class="ml_5" @click="arrowClick(item)">
              <Icon name="arrow-up" class="grey_color" v-if="item.arrow == 1" />
              <Icon name="arrow-down" class="grey_color" v-else />
            </div>
          </div>
        </div>
      </div>
      <div v-show="item.arrow == 1">
        <div class="cell_border" v-for="(v, i) in item.children" :key="i">
          <div class="font_size_14 mb_15">
            {{ v.name }}
          </div>

          <Field
            name="radio"
            label=""
            :rules="[
              {
                required: true,
                message: '请选择',
              },
            ]"
          >
            <template #input>
              <RadioGroup
                v-model="v.value"
                direction="horizontal"
                :checked-color="
                  v.value == 1
                    ? '#008911'
                    : v.value == 2
                    ? '#EA0000'
                    : '#8d9091'
                "
              >
                <Radio :name="1">已交底</Radio>
                <Radio :name="2">未交底</Radio>
                <Radio :name="3">无此项</Radio>
              </RadioGroup>
            </template>
          </Field>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div><span class="red_color">*</span>交底时间</div>
            <Field
              style="text-align: right"
              v-model="form.info.accept_time"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              @click="openDateType('accept_time')"
            />
          </div>
        </div>
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有什么需要补充的..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>

    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        :max-date="state.maxDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v, 'accept_time')"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, inject, onMounted, defineEmits } from 'vue';
import {
  Field,
  RadioGroup,
  Radio,
  Popup,
  DatetimePicker,
  Notify,
  Icon,
} from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);
const $http = inject('$http');
const props = defineProps(['data']);
const state = reactive({
  //-时间控件
  maxDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
  loadParams();
});

const loadParams = async () => {
  await $http.getDesignNodeFormEditParams().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      state.cash_list = data.cash_type_list;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// @-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD');
};

//-计算
const getTotalFun = (data, name) => {
  let list = [];
  if (name == '空值') {
    list = data.filter((v) => {
      return !v.value;
    });
  } else if (name == '已交底') {
    list = data.filter((v) => {
      return v.value == 1;
    });
  } else if (name == '未交底') {
    list = data.filter((v) => {
      return v.value == 2;
    });
  } else if (name == '无此项') {
    list = data.filter((v) => {
      return v.value == 3;
    });
  }
  return list.length;
};
const arrowClick = (item) => {
  if (item.arrow == 1) {
    item.arrow = '';
  } else {
    item.arrow = 1;
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
:deep(.van-radio-group--horizontal) {
  justify-content: space-between;
  width: 100%;
}
</style>
