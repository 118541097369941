/*
 * @Author: NanNan
 * @Date: 2022-04-14 10:18:43
 * @LastEditTime: 2023-06-17 15:14:38
 * @Description:
 */
import userAccountApis from './moudle/user/account';
import contractIndexApis from './moudle/contract/index';
import saleIndexApis from './moudle/sale/index';
import collectionApis from './moudle/contract/collection';
import moreOperateIndexApis from './moudle/moreOperate/index';
import taskIndexApis from './moudle/task/index';
import designIndexApis from './moudle/design/index';
import constructionIndexApis from './moudle/construction/index';
import contractChangeApis from './moudle/contract/change';
import clueIndexApis from './moudle/clue/index';
import messageApis from './moudle/message/index';
import witnessApis from './moudle/witness/index';
import productOrderApis from './moudle/productOrder/index';
import orderApis from './moudle/order/index';
import targetDataApis from './moudle/targetData/index';
export default {
  ...userAccountApis,
  ...contractIndexApis,
  ...saleIndexApis,
  ...collectionApis,
  ...moreOperateIndexApis,
  ...taskIndexApis,
  ...designIndexApis,
  ...constructionIndexApis,
  ...contractChangeApis,
  ...clueIndexApis,
  ...messageApis,
  ...witnessApis,
  ...productOrderApis,
  ...orderApis,
  ...targetDataApis,
};
