/*
 * @Author: gest
 * @Date: 2022-09-14 09:23:47
 * @LastEditTime: 2024-05-24 15:58:16
 * @Description: 线索相关
 */
//
import Service from '@/utils/request';

export default {
  //获取登录者信息
  getUserInfo(params) {
    return Service.post(`/rs/user/info`, params);
  },
  //线索列表
  getClueList(params) {
    return Service.post(`/rs/clue/list`, params);
  },
  //线索添加编辑
  editClue(params) {
    return Service.post(`/rs/clue/editClue`, params);
  },
  //线索详情
  getClueInfo(params) {
    return Service.post(`/rs/clue/clueInfo`, params);
  },
  //线索联系方式修改历史
  clueContactChangeList(params) {
    return Service.post(`/rs/clue/clueContactChangeList`, params);
  },
  //线索分配记录
  clueDistributionList(params) {
    return Service.post(`/rs/clue/clueDistributionList`, params);
  },
  //线索批量删除
  clueDel(params) {
    return Service.post(`/rs/clue/clueDel`, params);
  },
  //批量分配
  clueDistribution(params) {
    return Service.post(`/rs/clue/clueDistribution`, params);
  },
  //线索生成客户
  clueCreateCustomer(params) {
    return Service.post(`/rs/clue/createCustomer`, params);
  },
  //线索标注

  clueTagging(params) {
    return Service.post(`/rs/clue/clueTagging`, params);
  },
  //线索标注确认
  clueTaggingConfirm(params) {
    return Service.post(`/rs/clue/clueTaggingConfirm`, params);
  },
  // ----- v1.3.2线索跟进记录 -----
  //线索跟进记录参数
  clueFollowRecordParam() {
    return Service.post(`/rs/public/clueFollowRecordParam`);
  },
  //线索跟进记录列表
  clueFollowRecordList(params) {
    return Service.post(`/rs/clue/clueFollowRecordList`, params);
  },
  //最近一次跟进记录详情
  getClueFollowRecordInfoNew(params) {
    return Service.post(`/rs/clue/getClueFollowRecordInfoNew`, params);
  },
  //添加线索跟进记录
  addClueFollowRecord(params) {
    return Service.post(`/rs/clue/addClueFollowRecord`, params);
  },
  //删除线索跟进记录
  delClueFollowRecord(params) {
    return Service.post(`/rs/clue/delClueFollowRecord`, params);
  },
  //添加线索跟进记录评论
  addClueFollowRecordComment(params) {
    return Service.post(`/rs/clue/addClueFollowRecordComment`, params);
  },
  //删除线索跟进记录评论
  delClueFollowRecordComment(params) {
    return Service.post(`/rs/clue/delClueFollowRecordComment`, params);
  },
  //获取用户成员(根据用户权限所管理的部门)
  publicUserListParameterByAuth(params) {
    return Service.post(`/rs/public/userListParameterByAuth`, params);
  },
  //线索变更分配
  clueChangeDistribution(params) {
    return Service.post(`/rs/clue/clueChangeDistribution`, params);
  },
  //v1.3.5线索撤销删除客户
  companyDelCustomer(params) {
    return Service.post(`/rs/company/delCustomer`, params);
  },
  //v1.3.6线索发起轮派 /rs/clue/copyClue
  clueCopyClue(params) {
    return Service.post(`/rs/clue/copyClue`, params);
  },
  //v1.3.6见面记录详情
  clueMeetRecordInfo(params) {
    return Service.post(`/rs/clue/clueMeetRecordInfo`, params);
  },
  //v1.3.6见面记录编辑
  editClueMeetRecord(params) {
    return Service.post(`/rs/clue/editClueMeetRecord`, params);
  },
  //v1.4.2线索-渠道来源=84工地返单 返单工地信息参数列表
  clueConstructionList(params) {
    return Service.post(`/rs/clue/constructionList`, params);
  },
  //v1.4.2线索-渠道来源=84工地返单 渠道申请人参数列表
  clueChannelApplyPeople(params) {
    return Service.post(`/rs/clue/channelApplyPeople`, params);
  },
  //v1.4.3线索-线索死单
  clue_clueDead(params) {
    return Service.post(`/rs/clue/clueDead`, params);
  },
  //v1.4.3线索-取消死单
  clue_clueDeadRestore(params) {
    return Service.post(`/rs/clue/clueDeadRestore`, params);
  },
  //v1.4.7线索-人员权限接口
  public_servicePersonalList(params) {
    return Service.post(`/rs/public/servicePersonalList`, params);
  },
  //v1.4.7线索-派单分配
  clue_dispatchAllocation(params) {
    return Service.post(`/rs/clue/dispatchAllocation`, params);
  },
  //v1.5.6线索-线索改派
  clue_clueReassignment(params) {
    return Service.post(`/rs/clue/clueReassignment`, params);
  },
  //v1.5.7小程序分享卡片
  clue_designerAppletShareCard(params) {
    return Service.post(`/rs/clue/designerAppletShareCard`, params);
  },
  //v1.5.7分享小程序埋点
  clue_designerAppletShareCardRecord(params) {
    return Service.post(`/rs/clue/designerAppletShareCardRecord`, params);
  },
  //v1.6.1线索列表按角色权限获取服务人员
  customerManagement_clueCustomerServiceRoleParam(params) {
    return Service.post(
      `/rs/customerManagement/clueCustomerServiceRoleParam`,
      params
    );
  },
};
