<!--
 * @Author: gest
 * @Date: 2023-09-12 15:51:51
 * @LastEditTime: 2023-10-27 11:16:50
 * @Description: v1.4.5 场地交接单
-->
<template>
  <div class="formBase">
    <div class="content mb_15 white_bg">
      <div class="cell_border flex_between">
        <div class="flex_column flex1 mr_15">
          <div><span class="red_color">*</span>是否精装</div>
        </div>
        <div
          :class="
            form.info.is_fine_construction
              ? form.info.is_fine_construction == 1
                ? 'blue_color'
                : 'red_color'
              : ''
          "
        >
          {{
            form.info.is_fine_construction
              ? form.info.is_fine_construction == 1
                ? '是'
                : '否'
              : '-'
          }}
        </div>
      </div>
      <div
        class="cell_border flex_between"
        v-if="form.info.is_fine_construction == 1"
      >
        <div class="flex_column flex1 mr_15">
          <div>
            <span class="red_color">*</span>精装是否已按照合同要求进行保护
          </div>
        </div>
        <Field
          name="radio"
          label=""
          :rules="[
            {
              required: true,
              message: '请选择',
            },
          ]"
        >
          <template #input>
            <RadioGroup
              v-model="form.info.fine_protect"
              direction="horizontal"
              checked-color="#008911"
            >
              <Radio :name="1">是</Radio>
              <Radio :name="2">否</Radio>
            </RadioGroup>
          </template>
        </Field>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">土建检查情况</div>
          <Field
            v-model="form.info.content"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="请填写清楚项目相关情况..."
            show-word-limit
            :rules="[
              {
                required: true,
                message: '请填写土建检查情况',
              },
            ]"
          ></Field>
        </div>
      </div>
    </div>
    <!-- 进场时现场财产数量及部位 v-for -->
    <div class="font_size_13 grey_color" style="padding: 0 15px 6px 15px">
      进场时现场财产数量及部位：
    </div>
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="item" v-if="item.name !== '燃气表表数'">
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div>{{ item.name || '-' }}</div>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick(item)"
                  v-model="item.value"
                  direction="horizontal"
                  checked-color="#008911"
                >
                  <Radio :name="1">有</Radio>
                  <Radio :name="2">无</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>

        <div
          class="list_field"
          style="background-color: #fafafa"
          v-if="item.value == 1"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div>数量</div>
              <Field
                style="text-align: right; background-color: #fafafa"
                v-model="item.num"
                type="number"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写1～9999的整数',
                    validator: validatorNum,
                    trigger: 'onChange',
                  },
                ]"
              >
                <template #button>个</template></Field
              >
            </div>
          </div>
        </div>
      </div>

      <div class="item" v-else>
        <div class="list_field white_bg">
          <div class="field_item right">
            <div class="flex_between">
              <p>{{ item.name || '-' }}</p>
              <Field
                style="text-align: right"
                v-model="item.num"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="请填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写燃气表表数',
                  },
                ]"
              >
                <template #button>m³</template></Field
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- 原房屋外观质量内容 v-for-->
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.content_info"
      :key="index"
    >
      <div class="list_field white_bg">
        <div class="field_item">
          <span class="label-name grey_color">{{ item.name || '-' }}</span>
          <Field
            v-model="item.value"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="请填写清楚项目相关情况..."
            show-word-limit
            :rules="[
              {
                required: true,
                message: '请填写',
              },
            ]"
          ></Field>
        </div>
      </div>
    </div>

    <!-- end -->

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <p><span class="red_color">*</span>交接日期</p>
            <Field
              style="text-align: right"
              v-model="form.info.hand_over_time"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              @click="openDateType('hand_over_time')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <span class="label-name grey_color">备注说明</span>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="备注说明验收结果，验收不合格写明具体不合格的事项与整改完成时间..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>

    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        :max-date="state.maxDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v, 'hand_over_time')"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Popup, DatetimePicker, Dialog } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import patterns from '@/utils/pattern';
import moment from 'moment';
const emit = defineEmits(['update:data']);
const props = defineProps(['data']);
const state = reactive({
  //-时间控件
  maxDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

const { numReg } = patterns;
//-数值验证-除燃气表表数
const validatorNum = (val) => {
  if (val) {
    if (parseFloat(val) <= 9999 && parseFloat(val) > 0) {
      if (numReg.test(val)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};
//-数值验证-燃气表表数
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999.99) {
    val = 99999.99;
  } else if (val < 0) {
    val = '';
  }
  return val;
};
// @-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD');
};
//-end

// 切换验证，清空数据
const radioClick = (data) => {
  if (data.value === 2 && data.num) {
    Dialog.confirm({
      message: '确认将【有】切换成【无】吗？一旦确认，填写的数据将清空',
    })
      .then(() => {
        data.num = '';
      })
      .catch(() => {
        data.value = 1;
      });
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
