<!--
 * @Author: gest
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-29 10:19:12
 * @Description:V1.4.0-全部数据-设计
-->
<template>
  <div v-if="props.data.pos">
    <IndexBar ref="indexBarRef" :index-list="_DATA.indexList" :sticky="false">
      <IndexAnchor index="1"></IndexAnchor>
      <transformPerformance
        :data="props.data"
        @loadEnd="loadEnd(1)"
        ref="transformPerformanceRef"
        @createDanger="createDanger"
      />
      <IndexAnchor index="2"></IndexAnchor>
      <transformTemp
        :data="props.data"
        @loadEnd="loadEnd(2)"
        ref="transformTempRef"
        @createDanger="createDanger"
      />
      <IndexAnchor index="3"></IndexAnchor>
      <transformTimeTemp
        :data="props.data"
        @loadEnd="loadEnd(3)"
        ref="transformTimeTempRef"
        @createDanger="createDanger"
      />
      <IndexAnchor index="4"></IndexAnchor>
      <transformPeriod
        :data="props.data"
        @loadEnd="loadEnd(4)"
        ref="transformPeriodRef"
        @createDanger="createDanger"
      />
    </IndexBar>
  </div>
  <!-- 规则说明组件 -->
  <ActionSheet
    class="dangerActionSheet_rule"
    v-model:show="_DATA.dangerOperate"
    @open="onOpenDanger"
  >
    <div class="content">
      <div class="content_item">
        <h4>{{ _DATA.dangerTitle }}</h4>
        <div class="info_rule" v-html="_DATA.dangerHtml"></div>
      </div>
      <div class="content_button_blue">
        <Button
          block
          loading-type="spinner"
          @click="closeDanger"
          loading-text="知道了"
        >
          知道了
        </Button>
      </div>
    </div>
  </ActionSheet>
</template>
<script setup>
import { ref, reactive, defineProps, nextTick, defineExpose } from 'vue';
import { IndexBar, IndexAnchor, ActionSheet, Button } from 'vant';
import transformPerformance from '@/components/targetData/designData/transformPerformance'; //-转化产值
import transformTemp from '@/components/targetData/designData/transformTemp'; //-转化数
import transformTimeTemp from '@/components/targetData/designData/transformTimeTemp'; //-转化时间
import transformPeriod from '@/components/targetData/designData/transformPeriod'; //-设计阶段数据

const props = defineProps(['data']);
const _DATA = reactive({
  indexList: [1, 2, 3, 4],
  //-规则说明
  dangerTitle: '',
  dangerHtml: '',
  dangerOperate: false,
});
const indexBarRef = ref(null);

let loadEndList = [];
const loadEnd = (index) => {
  let pos = Number(props.data.pos);
  if (pos == 1) {
    if (indexBarRef.value) {
      nextTick(() => {
        indexBarRef.value.scrollTo(pos);
      });
    }
  } else if (pos > index) {
    loadEndList.push(index);
    let needLoad = _DATA.indexList.filter((i) => i < pos);
    if (needLoad.sort().toString() == loadEndList.sort().toString()) {
      nextTick(() => {
        if (indexBarRef.value) {
          indexBarRef.value.scrollTo(pos);
        }
      });
    }
  }
};

//-规则说明
const transformPerformanceRef = ref();
const transformTempRef = ref();
const transformTimeTempRef = ref();
const transformPeriodRef = ref();
// 使用nextTick确保DOM已更新 并且回到顶部
const onOpenDanger = () => {
  setTimeout(() => {
    const actionSheet = document.querySelector('.van-action-sheet__content');
    if (actionSheet) {
      actionSheet.scrollTop = 0;
    }
  }, 0);
};
const createDanger = (type) => {
  _DATA.dangerOperate = true;
  if (type == 'transformPerformanceRef') {
    _DATA.dangerTitle = transformPerformanceRef?.value.htmlTitle;
    _DATA.dangerHtml = transformPerformanceRef?.value.htmlContent;
  } else if (type == 'transformTempRef') {
    _DATA.dangerTitle = transformTempRef?.value.htmlTitle;
    _DATA.dangerHtml = transformTempRef?.value.htmlContent;
  } else if (type == 'transformTimeTempRef') {
    _DATA.dangerTitle = transformTimeTempRef?.value.htmlTitle;
    _DATA.dangerHtml = transformTimeTempRef?.value.htmlContent;
  } else if (type == 'transformPeriodRef') {
    _DATA.dangerTitle = transformPeriodRef?.value.htmlTitle;
    _DATA.dangerHtml = transformPeriodRef?.value.htmlContent;
  }
};
const closeDanger = () => {
  _DATA.dangerOperate = false;
};
defineExpose({ createDanger });
//-规则说明 end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/targetData.less';
</style>
