<!--
 * @Author: NanNan
 * @Date: 2023-06-25 14:08:05
 * @LastEditTime: 2024-05-18 17:52:16
 * @Description: v1.4.0 - 部门数据 - 获客
 * v1.4.2 smart 交互数据整改
-->
<template>
  <div class="change_box">
    <span class="name">{{ _DATA.depName || '-' }}</span>
    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker = true"
      v-if="_DATA.depList?.length > 1"
    >
      切换部门
    </Button>
    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker_year = true"
    >
      {{ _DATA.yearName }}
    </Button>
  </div>
  <div class="page-box">
    <IndexBar ref="indexBarRef" :index-list="_DATA.indexList" :sticky="false">
      <IndexAnchor index="1"></IndexAnchor>
      <TempPerMeetData
        :data="{ depId: _DATA.depId, pos: _DATA.pos, yearId: _DATA.yearId }"
        @loadEnd="loadEnd(1)"
      />
      <IndexAnchor index="2"></IndexAnchor>
      <TempPerTimeData
        :data="{ depId: _DATA.depId, pos: _DATA.pos, yearId: _DATA.yearId }"
        @loadEnd="loadEnd(2)"
      />
      <IndexAnchor index="3"></IndexAnchor>
      <TempPerAgreementData
        :data="{ depId: _DATA.depId, pos: _DATA.pos, yearId: _DATA.yearId }"
        @loadEnd="loadEnd(3)"
      />
      <IndexAnchor index="4"></IndexAnchor>
      <TempPerSignData
        :data="{ depId: _DATA.depId, pos: _DATA.pos, yearId: _DATA.yearId }"
        @loadEnd="loadEnd(4)"
      />
    </IndexBar>
    <!--picker 部门-->
    <Popup v-model:show="_DATA.showPicker" position="bottom">
      <Picker
        :columns="_DATA.depList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v)"
        @cancel="_DATA.showPicker = false"
        :default-index="
          _DATA.depList.findIndex((v) => {
            return v.id == _DATA.depId;
          })
        "
      />
    </Popup>
    <!-- end -->
    <!-- 年份 -->
    <Popup v-model:show="_DATA.showPicker_year" position="bottom">
      <Picker
        :columns="_DATA.yearList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'year')"
        @cancel="_DATA.showPicker_year = false"
        :default-index="
          _DATA.yearList.findIndex((v) => {
            return v.id == _DATA.yearId;
          })
        "
      />
    </Popup>
    <!-- end -->
  </div>
  <!--V1.4.0 菜单 悬浮按钮 -->
  <ol class="fixed-ol-box">
    <li class="icon-box" @click="_DATA.showMenu = true">
      <img
        class="icon-fixed"
        :src="require('@/assets/images/icon/icon_menu.png')"
      />
    </li>
  </ol>
  <sideMenu
    :data="{ showMenu: _DATA.showMenu }"
    @closed="
      () => {
        _DATA.showMenu = false;
      }
    "
  ></sideMenu>
  <!-- end -->
</template>
<script setup>
import _ from 'lodash';
import { ref, reactive, inject, onMounted, nextTick } from 'vue';
import { IndexBar, IndexAnchor, Picker, Button, Popup } from 'vant';
import { useRouter } from 'vue-router';

import TempPerTimeData from '@/components/targetData/meetingData/perTimeData.vue';
import TempPerMeetData from '@/components/targetData/meetingData/perMeetData.vue';
import TempPerSignData from '@/components/targetData/meetingData/perSignData.vue';
import TempPerAgreementData from '@/components/targetData/meetingData/perAgreementData.vue';
import sideMenu from '@/components/sideMenu';
const $http = inject('$http');
const router = useRouter();
const customFieldName = {
  text: 'short_name',
  value: 'id',
};
const _DATA = reactive({
  depId: Number(router.currentRoute.value.query.depId) || '0',
  depName: router.currentRoute.value.query.depName || '-',
  pos: Number(router.currentRoute.value.query.pos), // 与全部数据卡片位置顺序一样
  indexList: [1, 2, 3, 4],
  depList: [],
  showPicker: false,
  showPicker_year: false,
  yearId: null,
  yearName: null,
  yearList: [],
});
const indexBarRef = ref(null);
onMounted(() => {
  onLoad();
});
let loadEndList = [];
const loadEnd = (index) => {
  let pos = Number(_DATA.pos);
  if (pos > index) {
    loadEndList.push(index);
    let needLoad = _DATA.indexList.filter((i) => i < pos);
    if (needLoad.sort().toString() == loadEndList.sort().toString()) {
      nextTick(() => {
        indexBarRef.value.scrollTo(pos);
      });
    }
  }
};
//-@ 加载

const onLoad = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.depList = data.network_sale_departments;
      // 年份
      let years = data.years;
      let yearList = [];
      years.forEach((v) => {
        yearList.push({ id: v, short_name: v });
      });
      _DATA.yearList = yearList;
      _DATA.yearId = Number(localStorage.getItem('targetYear'));
      _DATA.yearName = Number(localStorage.getItem('targetYear'));
      if (!_DATA.yearId || !years.includes(_DATA.yearId)) {
        let yearArr = years.slice(-1);
        _DATA.yearId = yearArr[0];
        _DATA.yearName = yearArr[0];
      }
      console.log(_DATA.yearId);
      //end
    } else {
      _DATA.depList = [];
    }
  });
};
//

const onConfirm = (value, type) => {
  if (type == 'year') {
    //切换部门
    localStorage.setItem('targetYear', value.id);
    _DATA.yearId = value.id;
    _DATA.yearName = value.short_name;
    _DATA.showPicker_year = false;
  } else {
    //切换部门
    _DATA.depId = value.id;
    _DATA.depName = value.short_name;
    _DATA.showPicker = false;
    // 动态修改路由参数
    const query = _.cloneDeep(router.currentRoute.value.query);
    query.depId = _DATA.depId;
    query.depName = _DATA.depName;
    router.replace({ query });
    indexBarRef.value.scrollTo(1);
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/targetData.less';
.change_box {
  padding: 10px 15px;
  background: #f5f5f5;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    font-size: 17px;
    font-weight: bold;
    flex: 1;
    margin-right: 20px;
  }
  .year_btn {
    border: none;
    border-radius: 2px;
    font-size: 13px;
    padding: 0 10px;
    margin-left: 10px;
    height: 30px;
  }
}
.page-box {
  padding: 0 0 40px 0;
  background: #f5f5f5;
  height: calc(100% - 48px);
  overflow: auto;
}
</style>
