<!--
 * @Author: gest
 * @Date:2024-01-24 11:33:29
 * @LastEditTime: 2024-05-28 16:24:47
 * @Description: V1.5.4 目标数据-简单转化数据
 * @Description: V1.6.0 目标数据-简单转化数据
-->
<template>
  <!-- 线索数 -->
  <div class="main-data" @click.stop="redictFun">
    <div class="warpper_simple">
      <Skeleton
        title
        avatar
        :row="5"
        :loading="loading || props.loading"
        style="padding: 15px"
      >
        <div class="top top_info">
          <div class="flex">
            <div class="flex_left">
              <div class="icon">
                <img
                  :src="require('@/assets/images/target-data/t_s_2.png')"
                  alt="icon"
                />
              </div>
              <div class="font_size_17 font_bold">转化数据</div>
            </div>
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
        </div>

        <div class="pb_15 flex_evenly align_center font_size_13 grey_color">
          <div class="flex_column align_center simple_item">
            <div>定制产值(万)</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.gross?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.gross.today }}
                </span>
                <div class="icon_box ml_2">
                  <!-- <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> -->
                </div>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.gross?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.gross.today }}
                </span>
                <div class="icon_box ml_2">
                  <!-- <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> -->
                </div>
              </div>
              <div class="icon_box ml_2" v-if="detail.gross?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.gross?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.gross.month }}
                </span>
                <div class="icon_box ml_2">
                  <!-- <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> -->
                </div>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.gross?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.gross.month }}
                </span>
                <div class="icon_box ml_2">
                  <!-- <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> -->
                </div>
              </div>
              <div class="icon_box ml_2" v-if="detail.gross?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div class="font_size_20 black_color font_bold">
              {{ detail.gross?.total }}
            </div>
            <div class="mt_10">
              产：<span class="black_color font_bold">{{
                detail.gross?.product
              }}</span
              >万
            </div>
            <div class="mt_2">
              施：<span class="black_color font_bold">{{
                detail.gross?.constr
              }}</span
              >万
            </div>
          </div>
          <div class="line"></div>
          <div class="flex_column align_center simple_item">
            <div>转化数</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.convert?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.convert.today }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.convert?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.convert.today }}
                </span>
                <!-- <div class="icon_box ml_2">
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>

              <div class="icon_box ml_2" v-if="detail.convert?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.convert?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.convert.month }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.convert?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.convert.month }}
                </span>
                <!-- <div class="icon_box ml_2">
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>

              <div class="icon_box ml_2" v-if="detail.convert?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>

            <div class="font_size_20 black_color font_bold">
              {{ detail.convert?.total }}
            </div>
            <div class="mt_10">转化率</div>
            <div class="black_color font_bold font_size_15 line_h20">
              {{ detail.convert?.rate }}%
            </div>
          </div>
          <div class="line"></div>
          <div class="flex_column align_center simple_item">
            <div>整装产值(万)</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.package?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.package.today }}
                </span>
                <!-- <div class="icon_box ml_2">
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.package?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.package.today }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div class="icon_box ml_2" v-if="detail.package?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.package?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.package.month }}
                </span>
                <!--  <div class="icon_box ml_2">
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.package?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.package.month }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div class="icon_box ml_2" v-if="detail.package?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div class="font_size_20 black_color font_bold">
              {{ detail.package?.total }}
            </div>
            <div class="mt_10">
              草：<span class="black_color font_bold">{{
                detail.package?.initial
              }}</span
              >万
            </div>
            <div class="mt_2">
              实：<span class="black_color font_bold">{{
                detail.package?.actual
              }}</span
              >万
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  inject,
  watch,
  defineProps,
  defineEmits,
  defineExpose,
} from 'vue';
import { Notify, Skeleton } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
const emit = defineEmits(['createDanger']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .targetStatistic_simpleConvert({ year: props.data.yearId })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        detail.value = data;
      } else {
        loading.value = false;
        detail.value = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 2, pos: 1 },
  });
};

//-规则说明--begin
const htmlTitle = '转化数据_规则';
const htmlContent = `<p>1、定制产值：当年非优选项目产值，包含取产品业绩、返款业绩、产品交付业绩、产品其他业绩、施工首期业绩、施工增减项业绩、施工渠道费业绩（扣减）、施工其他业绩；</p>
  <p>2、整装产值：当年优选项目产值，优选施工业绩、 优选增减项业绩、优选其他业绩、优选产品业绩、优选返款业绩；</p>
  <p>3、转化数：累计当年签署施工合同或累计产品收款≥500000项目数量；</p>`;
const createDanger = () => {
  emit('createDanger', 'desginRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
