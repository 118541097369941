<!--
 * @Author: gest
 * @Date: 2023-05-09 11:36:41
 * @LastEditTime: 2024-06-15 11:49:25
 * @Description: 订单详情
  * @Description:V1.5.7 优选订单-订单详情
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="detail_box">
      <div class="top_box">
        <div class="left" v-if="_DATA.info.supplier_logo">
          <Image
            class="avader_img"
            :src="
              _DATA.info.supplier_logo
                ? _DATA.supplier_logo.includes('http')
                  ? _DATA.supplier_logo
                  : $host + _DATA.info.supplier_logo
                : require('@/assets/images/supplier-avder-empty.png')
            "
          />
        </div>
        <div v-else class="left icon_name">
          {{ _DATA.info.supplier_name ? _DATA.info.supplier_name[0] : '' }}
        </div>
        <div class="right">
          <div class="left">
            <div class="name">{{ _DATA.info.supplier_name }}</div>
            <div class="desc">
              {{
                _DATA.info.type_name
                  ? _DATA.info.category_name + ' | ' + _DATA.info.type_name
                  : _DATA.info.category_name
              }}
            </div>
          </div>
          <div class="right">
            <div class="name">{{ _DATA.info.deal_price }}</div>
            <div class="desc">成交金额/元</div>
          </div>
        </div>
      </div>
      <div class="dec_box">
        <div class="dec_item">
          <div class="left">订单属性</div>
          <div
            class="right status_tag"
            :class="_DATA.info.attr_type == 1 ? 'blue' : 'green'"
          >
            {{ _DATA.info.attr_type_name }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">订单状态</div>
          <div
            class="right status_tag"
            :class="
              _DATA.info.status == 3
                ? 'red'
                : _DATA.info.status == 4
                ? 'blue'
                : _DATA.info.status == 5
                ? 'green'
                : _DATA.info.status == 6
                ? 'grey'
                : 'orange'
            "
          >
            {{ _DATA.info.status_name }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">当前进度</div>
          <div class="right">{{ _DATA.info.progress_name || '-' }}</div>
        </div>
        <div class="dec_item">
          <div class="left">供应商全称</div>
          <div class="right">
            {{ _DATA.info.supplier_full_name }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">订单编号</div>
          <div class="right">{{ _DATA.info.order_no }}</div>
        </div>
        <div
          class="dec_item"
          v-if="_DATA.info.attr_type == 2 && _DATA.order_field.indexOf(3) > -1"
        >
          <div class="left">采购合计金额</div>
          <div class="right">
            {{
              _DATA.info.deal_price || _DATA.info.deal_price === 0
                ? _DATA.info.deal_price + '元'
                : '-'
            }}
          </div>
        </div>

        <div class="dec_item" v-if="_DATA.order_field.indexOf(4) > -1">
          <div class="left">集成金额</div>
          <div class="right">
            {{
              _DATA.info.integrate_money || _DATA.info.integrate_money === 0
                ? _DATA.info.integrate_money + '元'
                : '-'
            }}
          </div>
        </div>
        <div class="dec_item" v-if="_DATA.info.attr_type == 1">
          <div class="left">市场成交价合计</div>
          <div class="right price">{{ _DATA.info.total_price }}元</div>
        </div>
        <div class="dec_item" v-if="_DATA.info.attr_type == 1">
          <div class="left">尚层价合计</div>
          <div class="right price">{{ _DATA.info.total_sc_price }}元</div>
        </div>
      </div>
      <div v-show="_DATA.arrow == 1">
        <div class="dec_box">
          <div class="dec_item" v-if="_DATA.info.type == 3">
            <div class="left">原订单编号</div>
            <div
              class="right link"
              @click="
                () => {
                  router.push({
                    path: '/mainOrderDetail',
                    query: { id: _DATA.info.source_order_id },
                  });
                }
              "
            >
              {{ _DATA.info.source_order_no }}
            </div>
          </div>
          <div class="dec_item">
            <div class="left">工地编号</div>
            <div class="right">{{ _DATA.info.construction_no }}</div>
          </div>
          <div class="dec_item">
            <div class="left">产品合同</div>
            <div class="right">{{ _DATA.info.contract_no }}</div>
          </div>
          <div class="dec_item">
            <div class="left">签单折扣</div>
            <div class="right">{{ _DATA.info.order_discount }}</div>
          </div>

          <div class="dec_item">
            <div class="left">完成测量时间</div>
            <div class="right">{{ _DATA.info.measure_time || '-' }}</div>
          </div>
          <div class="dec_item">
            <div class="left">正式下单时间</div>
            <div class="right">{{ _DATA.info.place_order_time || '-' }}</div>
          </div>
          <div class="dec_item">
            <div class="left">完成送货日期</div>
            <div class="right">{{ _DATA.info.delivery_time || '-' }}</div>
          </div>
          <div class="dec_item">
            <div class="left">完成验收日期</div>
            <div class="right">{{ _DATA.info.accept_time || '-' }}</div>
          </div>
        </div>
        <div class="dec_box">
          <div class="dec_title">服务人员</div>
          <div
            class="list_item"
            v-for="(item, index) in _DATA.info.service_staff"
            :key="index"
          >
            <div class="img-box">
              <Image
                class="img-head"
                fit="cover"
                round
                :src="
                  item.face_image
                    ? item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                    : require('@/assets/images/icon-empty-img.png')
                "
              ></Image>
            </div>
            <div class="right">
              <div class="name_box">
                <div class="name">
                  {{ item.name || '-' }}
                </div>
                <div class="desc">
                  {{ item.dep_name }}
                </div>
              </div>
              <div class="btn_phone" @click="telPhone(item.phone)">联系</div>
            </div>
          </div>
        </div>
        <div class="info_item">
          <div class="title">订单标注</div>
          <div
            class="content mark_select"
            v-if="allOperationAuthority.indexOf('427') > -1"
            @click="_DATA.markShow = true"
          >
            <span>{{ _DATA.info.mark_name || '-' }}</span>
            <Icon name="arrow" size="12" class="ml_5" />
          </div>
          <div class="content" v-else>
            {{ _DATA.info.mark_name || '-' }}
          </div>
        </div>
        <div class="info_item">
          <div class="title">下单备注</div>
          <div v-if="allOperationAuthority.indexOf('427') > -1">
            <Field
              @blur="blurFun"
              v-model="_DATA.info.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="30"
              placeholder="请填写"
              show-word-limit
            ></Field>
          </div>
          <div class="content" v-else>
            {{ _DATA.info.remark || '-' }}
          </div>
        </div>
      </div>

      <div class="arrow_box" @click="arrowBtn('1')" v-show="_DATA.arrow == 0">
        <span>详细信息</span>
        <Icon name="arrow-down" class="arrow" />
      </div>
      <div
        class="arrow_box"
        @click="arrowBtn('0')"
        v-show="_DATA.arrow == 1"
        style="padding: 22px 0"
      >
        <span>收起</span>
        <Icon name="arrow-up" class="arrow" />
      </div>
    </div>
    <!-- 粘性布局 -->
    <div ref="container" class="tab-box-bigger">
      <Sticky :container="container">
        <Tabs
          v-model:active="_DATA.tab_active"
          title-active-color="#0256ff"
          title-inactive-color="#000000"
          :ellipsis="false"
          class="tab_box"
        >
          <Tab title="材料明细" :name="1"></Tab>
          <Tab title="订单附件" :name="2"></Tab>
          <Tab title="订单记录" :name="3"></Tab>
          <Tab title="结算付款记录" :name="4"></Tab>
          <Tab title="罚款记录" :name="5"></Tab>
          <Tab title="安装时间表" :name="6"></Tab>
        </Tabs>
      </Sticky>
      <!-- 加载状态 -->
      <Overlay :show="_DATA.loading" class="overlay-fff">
        <Loading color="#1989fa" type="spinner"></Loading>
      </Overlay>
      <!-- end -->

      <!-- 内容区域 -->
      <div class="tab-content-bigger">
        <!-- tab 材料明细 -->
        <OrderDetailLits
          v-if="_DATA.tab_active == 1"
          :id="_DATA.id"
          :attr_type="_DATA.info.attr_type"
        />
        <!-- tab  订单附件-->
        <OrderFiles v-if="_DATA.tab_active == 2" :id="_DATA.id" />
        <!-- tab  订单记录-->
        <OrderRecords v-if="_DATA.tab_active == 3" :id="_DATA.id" />
        <!-- tab  结算付款记录-->
        <OrderPayList
          v-if="_DATA.tab_active == 4"
          :id="_DATA.id"
          :type="_DATA.info.type"
        />
        <!-- tab  罚款记录-->
        <OrderFineList v-if="_DATA.tab_active == 5" :id="_DATA.id" />
        <!-- tab 安装时间表-->
        <installationTable v-if="_DATA.tab_active == 6" :id="_DATA.id" />
      </div>
    </div>
    <!-- 判断权限 -->
    <div class="stick-box" v-if="allOperationAuthority">
      <Button
        type="default"
        size="large"
        v-if="_DATA.more_list.length"
        @click="_DATA.showMoreAction = true"
      >
        更多
      </Button>

      <Button
        type="primary"
        size="large"
        :to="{
          path: '/mainOrderOperate',
          query: { id: _DATA.id, type: 429 },
        }"
        v-if="allOperationAuthority.indexOf('429') > -1"
      >
        跟单
      </Button>
    </div>
    <a href="" v-show="false" ref="telRef"></a>
  </div>
  <!-- 动作面板 - 更多 -->
  <ActionSheet
    v-model:show="_DATA.showMoreAction"
    cancel-text="取消"
    close-on-click-action
    @cancel="
      () => {
        _DATA.showMoreAction = false;
      }
    "
    class="more-box"
  >
    <div class="content">
      <div class="same">
        <div
          v-for="(item, index) in _DATA.more_list"
          :key="index"
          @click="handelFuns(item)"
        >
          <svg class="icon_svg" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </ActionSheet>
  <Popup v-model:show="_DATA.markShow" position="bottom">
    <div class="mark_pop">
      <div class="mark_title">
        <span style="flex: 1; text-align: center">选择标注</span>
        <Icon
          name="cross"
          :size="18"
          style="color: #8d9091"
          @click="
            () => {
              _DATA.markShow = false;
            }
          "
        />
      </div>
      <CheckboxGroup
        v-model="_DATA.markList"
        class="mt_15"
        style="max-height: 500px; overflow: auto; height: 200px"
      >
        <Checkbox
          v-for="val in _DATA.allMarkList"
          :key="val.name"
          class="checkboxNoIcon"
          :name="val.id"
        >
          <template #icon></template>
          <p
            class="menuBox"
            :class="{
              checked: _DATA.markList.indexOf(val.id) > -1,
            }"
          >
            {{ val.name }}
          </p>
        </Checkbox>
      </CheckboxGroup>
      <Button type="primary" size="large" @click="saveMark">保存</Button>
    </div>
  </Popup>
</template>
<script setup>
import { onMounted, reactive, inject, computed, ref, watch } from 'vue';
import OrderDetailLits from '@/components/order/mainOrder/OrderDetailLits';
import OrderFiles from '@/components/order/mainOrder/OrderFiles';
import OrderRecords from '@/components/order/mainOrder/OrderRecords';
import OrderPayList from '@/components/order/mainOrder/OrderPayList';
import OrderFineList from '@/components/order/mainOrder/OrderFineList';
import installationTable from '@/components/order/mainOrder/installationTable';

import { useRouter, useRoute } from 'vue-router';
import {
  Button,
  Overlay,
  Loading,
  Notify,
  Image,
  ActionSheet,
  Tab,
  Tabs,
  Sticky,
  Icon,
  Field,
  Popup,
  CheckboxGroup,
  Checkbox,
} from 'vant';
import { telRef, telPhone } from '@/utils/util';
import { useStore } from 'vuex';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const route = useRoute();
const _DATA = reactive({
  id: router.currentRoute.value.query.id,
  loading: false,
  info: '',
  showMoreAction: false, //更多面板
  more_list: [],
  arrow: 0,
  markShow: false,
  markList: [],
  allMarkList: [],
  order_field: computed(() => {
    return store.state.account.user_auth?.order_sensitive_auth ?? [];
  }), //订单敏感权限
});

const container = ref(null);
onMounted(() => {
  loadFunc();
  console.log(_DATA.order_field, store.state.account);
});
watch(
  () => route.query,
  (cur) => {
    if (cur.id && !cur.gdid) {
      _DATA.id = cur.id;
      loadFunc();
    }
  }
);
/**
 * 获取接口
 */

const loadFunc = () => {
  _DATA.loading = true;
  $http.mainOrder_info({ id: _DATA.id }).then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data;
      _DATA.markList = _DATA.info.mark_list.map((v) => Number(v.id));
      getMoreAuth();
      getParams();
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
const getParams = () => {
  _DATA.loading = true;
  $http.mainOrder_getParams().then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.allMarkList = data.main_order_mark;
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
//备注编辑
const blurFun = () => {
  _DATA.loading = true;
  $http
    .mainOrder_save({ id: _DATA.id, remark: _DATA.info.remark, type: 2 })
    .then((res) => {
      const { data, code } = res;
      _DATA.loading = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '下单备注保存成功',
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//@-更多操作-根据状态展示不同的操作权限
/*
订单状态：未提交——428致电商家
订单状态：未确认——428致电商家、430订单驳回、431确认订单、443作废
订单状态：未正式下单——428致电商家、430订单驳回、433正式下单、432申请下单、443作废
订单状态：正式下单
    1. 状态阶段：商家未接单——428致电商家、434撤回订单、435通知测量、443作废
    2. 状态阶段：商家已接单-未全部安装验收——428致电商家、436申请撤回、435通知测量、437通知送货、438现场到货验收、439通知安装、440安装验收、441开退货单、442开罚款单
订单状态：已验收——致电商家、开退货单、开罚款单
订单状态：作废——致电商家、444删除
*/
const getMoreAuth = () => {
  let opt = [
    {
      name: '致电商家',
      icon: '#icon-phone-line',
      type: '428',
    },
    {
      name: '订单驳回',
      icon: '#icon-anticlockwise-2-line1',
      type: '430',
    },
    {
      name: '确认订单',
      icon: '#icon-layout-bottom-2-line',
      type: '431',
    },
    {
      name: '作废',
      icon: '#icon-close-circle-line',
      type: '443',
    },
    {
      name: '正式下单',
      icon: '#icon-clipboard-line',
      type: '433',
    },
    {
      name: '申请下单',
      icon: '#icon-contacts-book-upload-line',
      type: '432',
    },
    {
      name: '撤回订单',
      icon: '#icon-arrow-go-back-line',
      type: '434',
    },

    {
      name: '通知测量',
      icon: '#icon-discuss-line',
      type: '435',
    },
    {
      name: '申请撤回',
      icon: '#icon-reply-line',
      type: '436',
    },
    {
      name: '通知送货',
      icon: '#icon-truck-line',
      type: '437',
    },
    {
      name: '现场到货验收',
      icon: '#icon-lightbulb-line',
      type: '438',
    },
    {
      name: '通知安装',
      icon: '#icon-chat-settings-line',
      type: '439',
    },
    {
      name: '安装验收',
      icon: '#icon-file-list-line',
      type: '440',
    },
    {
      name: '开退货单',
      icon: '#icon-a-edit-line1',
      type: '441',
    },
    {
      name: '开罚款单',
      icon: '#icon-file-list-line',
      type: '442',
    },
  ];
  opt = opt.filter((k) => {
    return allOperationAuthority.value.indexOf(k.type) > -1;
  });
  if (_DATA.info.status == 1) {
    opt = opt.filter((k) => {
      return k.type == 428;
    });
  } else if (_DATA.info.status == 2) {
    opt = opt.filter((k) => {
      return k.type == 428 || k.type == 430 || k.type == 431 || k.type == 443;
    });
  } else if (_DATA.info.status == 3) {
    opt = opt.filter((k) => {
      return (
        k.type == 428 ||
        k.type == 430 ||
        k.type == 433 ||
        k.type == 432 ||
        k.type == 443
      );
    });
  } else if (_DATA.info.status == 4) {
    if (_DATA.info.order_stage == 1) {
      //商家未接单
      opt = opt.filter((k) => {
        return k.type == 428 || k.type == 434 || k.type == 435 || k.type == 443;
      });
    } else {
      //商家已接单
      //退货单不显示【安装验收】和【开退货单】
      if (_DATA.info.type == 3) {
        opt = opt.filter((k) => {
          return (
            k.type == 428 ||
            k.type == 436 ||
            k.type == 435 ||
            k.type == 437 ||
            k.type == 438 ||
            k.type == 439 ||
            k.type == 442
          );
        });
      } else {
        opt = opt.filter((k) => {
          return (
            k.type == 428 ||
            k.type == 436 ||
            k.type == 435 ||
            k.type == 437 ||
            k.type == 438 ||
            k.type == 439 ||
            k.type == 440 ||
            k.type == 441 ||
            k.type == 442
          );
        });
      }
      opt = opt.filter((k) => {
        return (
          k.type == 428 ||
          k.type == 436 ||
          k.type == 435 ||
          k.type == 437 ||
          k.type == 438 ||
          k.type == 439 ||
          k.type == 440 ||
          k.type == 441 ||
          k.type == 442
        );
      });
    }
  } else if (_DATA.info.status == 5) {
    if (_DATA.info.type == 3) {
      opt = opt.filter((k) => {
        return k.type == 428 || k.type == 442;
      });
    } else {
      opt = opt.filter((k) => {
        return k.type == 428 || k.type == 441 || k.type == 442;
      });
    }
  } else {
    opt = opt.filter((k) => {
      return k.type == 428;
    });
  }
  _DATA.more_list = opt;
};
/**
 * 操作
 */
const arrowBtn = (type) => {
  _DATA.arrow = type;
};

// -- 更多
//-@更多操作
const handelFuns = (item) => {
  if (
    item.type == 429 ||
    item.type == 437 ||
    item.type == 432 ||
    item.type == 435
  ) {
    //429跟单/437通知送货/432申请下单/435通知测量
    router.push({
      path: '/mainOrderOperate',
      query: {
        id: _DATA.id,
        type: item.type,
      },
    });
  } else if (item.type == 438 || item.type == 439 || item.type == 440) {
    //438现场到货验收/439通知安装/440安装验收
    router.push({
      path: '/mainOrderOperateProduct',
      query: {
        id: _DATA.id,
        type: item.type,
        is_show: _DATA.info?.is_install_show ?? '',
      },
    });
  } else if (item.type == '428') {
    //致电商家
    telPhone(_DATA.info.supplier_phone);
  } else if (item.type == '441') {
    //开退货单
    Notify({
      type: 'warning',
      message: '企微版该功能暂未上线，请去网页版-红珊瑚操作！',
    });
  } else if (item.type == '442') {
    //开罚款单
    Notify({
      type: 'warning',
      message: '企微版该功能暂未上线！',
    });
  } else {
    Notify({
      type: 'warning',
      message: '企微版该功能暂未上线，请去网页版-红珊瑚操作！',
    });
  }
};
const saveMark = () => {
  _DATA.loading = true;
  $http
    .mainOrder_save({ id: _DATA.id, mark: _DATA.markList, type: 1 })
    .then((res) => {
      const { data, code } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.markShow = false;
        Notify({
          type: 'success',
          message: '保存成功',
        });
        loadFunc();
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 74px;
  background: @grey_color;
  min-height: calc(100vh - 118px);
  .detail_box {
    background-color: @white-color;
    padding: 0 16px;
    .top_box {
      padding: 14px 0;

      display: flex;
      .left {
        .avader_img {
          width: 40px;
          height: 40px;
          // border: 1px solid @line-color;
        }
      }
      .right {
        flex: 1;
        padding-left: 12px;
        display: flex;

        align-items: center;
        justify-content: flex-end;
        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .name {
            font-size: 17px;
            font-weight: bold;
            line-height: 24px;
          }
          .desc {
            font-size: 14px;
            color: @grey-color;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          .name {
            font-size: 20px;
            line-height: 26px;
            color: @blue-color;
          }
          .desc {
            font-size: 12px;
            line-height: 18px;
            color: @grey-color;
          }
        }
      }
    }
    .dec_box {
      padding: 8px 0;
      border-top: 1px solid @line-color;
      .dec_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        font-size: 14px;
        color: @grey-color;
        line-height: 20px;
        .left {
          min-width: 55px;
        }
        .price {
          text-decoration: line-through;
          color: #cccccc;
        }
        .link {
          color: @blue-color;
          text-decoration: underline;
        }
      }
      .dec_title {
        padding: 10px 0;
        color: @grey-color;
        font-size: 14px;
      }
      .list_item {
        display: flex;
        align-items: center;
        .img-box {
          .img-head {
            width: 40px;
            height: 40px;
          }
        }
        .right {
          flex: 1;
          display: flex;
          border-bottom: 1px solid @line-color;
          justify-content: space-between;
          align-items: center;
          margin-left: 12px;
          padding: 16px 0;
          .name_box {
            display: flex;
            flex-direction: column;
            .name {
              font-size: 17px;
              line-height: 24px;
              color: @black-color;
            }
            .desc {
              font-size: 12px;
              line-height: 17px;
              color: @grey-color;
            }
          }

          .btn_phone {
            font-size: 15px;
            line-height: 21px;
            color: @blue-color;
            padding: 4px 14px;
            background: #f8f8f8;
            border-radius: 3px;
          }
        }
      }
    }
    .info_item {
      .title {
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        color: @black-color;
        padding: 16px 0;
      }
      .content {
        // padding: 10px 20px;
        color: @grey-color;
        font-size: 15px;
        font-weight: normal;
        line-height: 18px;
        // border: 1px solid @line-color;
        border-radius: 5px;
        &.mark_select {
          padding: 10px 20px;

          border: 1px solid @line-color;
          display: flex;

          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .arrow_box {
      color: #576b95;
      line-height: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0 0;
      padding-bottom: 16px;
      .arrow {
        color: #576b95;
        margin-left: 6px;
      }
    }
  }
  .tab-box-bigger {
    margin-bottom: 15px;
    margin-top: 10px;
    .tab_box {
      border-bottom: 1px solid @line-color;
      :deep(.van-tabs__line) {
        display: block;
        width: 20%;
        background: @blue-color;
        height: 1px;
      }
      :deep(.van-tab__text) {
        font-weight: bold;
      }
    }
  }
  .stick-box {
    display: flex;
    justify-content: space-around;
    padding: 12px 16px;
    z-index: 9;

    button {
      margin: 0 8px;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
//@更多操作
.more-box {
  .content {
    padding: 10px;

    .same {
      display: flex;
      justify-content: left;
      align-items: flex-start;
      flex-wrap: wrap;

      div {
        width: 25%;
        text-align: center;
        padding: 10px 5px;
        color: #3e4345;

        .icon_svg {
          width: 24px;
          height: 24px;
          fill: currentColor;
        }
        p {
          font-size: 13px;
          line-height: 20px;
          margin-top: 2px;
          color: #3e4345;
        }
      }
    }
  }
}
.mark_pop {
  padding: 20px;
  .mark_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .menuBox {
    margin: 0 10px 8px 0px;
    padding: 9px 20px;
    background: #ffffff;
    border: 1px solid @line-color;
    // border-radius: 25px;
    &.checked {
      background: @blue-bg-color;
      color: @blue-color;
    }
  }
}
.icon_name {
  background: linear-gradient(135deg, #ace2da 0%, #bdd5ff 100%);

  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 46px;
  width: 46px;
  height: 46px;
}
</style>
