<!--
 * @Author: Jessica
 * @Date: 2023-12-06 17:46:05
 * @LastEditTime: 2023-12-22 15:27:35
 * @Description:  分摊业绩-申请
-->
<template>
  <div class="page-waper">
    <Overlay :show="state.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <!-- 客户消息 -->
      <div class="customer_info">
        <ol>
          <li>
            <label>客户编码</label>
            <p>{{ options?.customer_no || '-' }}</p>
          </li>
          <li>
            <label>客户姓名</label>
            <p>{{ options?.customer_name || '-' }}</p>
          </li>
          <li>
            <label>项目地址</label>
            <p>{{ options?.estate_str || '-' }}</p>
          </li>
          <li>
            <label>所属中心</label>
            <p>{{ options?.design_center_name || '-' }}</p>
          </li>
          <li>
            <label>客户经理</label>
            <p>{{ options?.customer_manager_name || '-' }}</p>
          </li>
          <li>
            <label>主创设计师</label>
            <p>{{ options?.designer || '-' }}</p>
          </li>
          <li>
            <label>产品设计师</label>
            <p>{{ options?.product_design_name || '-' }}</p>
          </li>
        </ol>
      </div>
      <!-- 分摊操作 -->
      <Form ref="ruleFormRef">
        <div class="part mb_15">
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              分摊部门
            </label>
            <Field
              v-model="form.list.split_dep_id_name"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="state.showTypePicker_dep = true"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              协议
            </label>
            <Field
              v-model="form.list.protocol_val_name"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="getRatios('protocol_val', form.list.protocol_val)"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              产品业绩
            </label>
            <Field
              v-model="form.list.product_val_name"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="getRatios('product_val', form.list.product_val)"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工业绩
            </label>
            <Field
              v-model="form.list.construction_val_name"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="getRatios('construction_val', form.list.construction_val)"
            />
          </div>
        </div>

        <div class="btnSumbit btnSumbit1">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            提交
          </Button>
        </div>
      </Form>

      <!-- 分摊部门组件 -->
      <Popup v-model:show="state.showTypePicker_dep" position="bottom">
        <Picker
          :columns="options.design_departments"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'split_dep_id')"
          @cancel="state.showTypePicker_dep = false"
          :default-index="
            options.design_departments.findIndex((v) => {
              return v.id == form.list.split_dep_id;
            })
          "
        />
      </Popup>
      <!-- 分摊比例组件 -->
      <Popup v-model:show="state.showTypePicker_ratios" position="bottom">
        <Picker
          :columns="options.split_ratios"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'ratios')"
          @cancel="state.showTypePicker_ratios = false"
          ref="ratiosRef"
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, inject, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import {
  Form,
  Field,
  Popup,
  Picker,
  Toast,
  Notify,
  Button,
  Loading,
  Overlay,
} from 'vant';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loading: false,
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  // 分摊部门组件
  showTypePicker_dep: false,
  // 分摊比例组件
  ratios_type: '',
  showTypePicker_ratios: false,
});

//-参数&详情-begin
onMounted(() => {
  getParameter();
});

//-获取参数
const options = reactive({});
const getParameter = async () => {
  state.loading = true;
  await $http
    .company_customerInfo({ customer_id: state.customer_id })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        Object.assign(options, data);
      } else {
        Toast(data);
      }
    });
};

//-end

//--表单&验证--------begin
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: state.customer_id,
    split_dep_id: '',
    split_dep_id_name: '',
    protocol_val: '',
    protocol_val_name: '',
    product_val: '',
    product_val_name: '',
    construction_val: '',
    construction_val_name: '',
  },
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
//-end

//-操作&详情--begin

//-picked 操作
const ratiosRef = ref();
const getRatios = (ratios_type, id) => {
  state.ratios_type = ratios_type;
  state.showTypePicker_ratios = true;
  nextTick(() => {
    let column = options.split_ratios.findIndex((v) => {
      return v.id == id;
    });
    ratiosRef.value.setColumnIndex(0, column > -1 ? column : 0);
  });
};

const onConfirm = (value, type) => {
  if (type === 'split_dep_id') {
    //-分摊部门
    if (value) {
      form.list[type + '_name'] = value.name;
      form.list[type] = value.id;
    }
    state.showTypePicker_dep = false;
  } else if (type === 'ratios') {
    //-分摊比例
    if (value) {
      form.list[state.ratios_type + '_name'] = value.name;
      form.list[state.ratios_type] = value.id;
    }
    state.showTypePicker_ratios = false;
  }
};

//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goSave();
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
    });
};
const goSave = () => {
  state.loading = true;
  $http
    .company_applySplit({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loading = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--表单end---------------------
</script>

<style lang="less" scoped>
.page-waper {
  width: 100%;
  background: #f8f8f8;
  box-sizing: border-box;
  padding-bottom: 90px;
  min-height: calc(100vh - 90px);
  //-客户信息
  .customer_info {
    padding: 15px 0;
    > ol {
      background: #fff;
      padding: 0 15px;
      > li {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(228, 228, 228, 0.6);
        &:last-child {
          border-bottom: 0px;
        }
        > label {
          color: #8d9091;
          font-size: 15px;
          min-width: 80px;
        }
        > span {
          color: #000000;
          font-size: 15px;
        }
      }
    }
  }
  //-分摊操作
  .part {
    background: #fff;
    margin-bottom: 15px;
    .cell_no {
      padding: 15px 0px;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      &:last-child {
        border-bottom: 0;
      }
      .form_title {
        font-size: 15px;
        min-width: fit-content;
      }
      :deep(.van-cell) {
        padding: 5px 0;
        text-align: right;
        border: 0;
        .van-field__error-message {
          text-align: right;
        }
        .van-field__control {
          text-align: right;
          justify-content: flex-end;
          padding-left: 10px;
        }
        .van-checkbox-group {
          justify-content: flex-end;
        }
      }
    }
  }
  //-提交操作
  .btnSumbit {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 120px;
    background: #fff;
    box-shadow: 0px -1px 4vw rgb(0 0 0 / 10%);
    &.btnSumbit1 {
      height: 90px;
    }
    .btn {
      width: calc(100% - 50px);
      margin: 0 25px;
      position: relative;
      top: 20px;
    }
    > p {
      width: 100%;
      height: 20px;
      position: relative;
      top: 30px;
      font-size: 15px;
      line-height: 20px;
      color: #aaaaaa;
      text-align: center;
    }
  }
}
</style>
