<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-04-03 13:48:03
 * @Description: 优选整装施工
-->
<template>
  <!-- 合同收款 -->
  <div class="temp">
    <h1>合同收款</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签订合同总价
            </label>
            <van-field
              v-model="form.contract_amount_sum"
              type="number"
              @blur="setContractAmount"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的签订合同总价',
                  validator: validatorAmountSum,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写签订合同总价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              整装产品报价
            </label>
            <van-field
              v-model="form.pack_amount"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的整装产品报价',
                  validator: validatorAmountSum,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写整装产品报价费"
            >
              <template #button>
                <van-text>元/㎡</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              服务包金额
              <em class="tip">(包含服务包金额)</em>
            </label>
            <van-field
              v-model="form.service_package_amount"
              type="number"
              placeholder="0.00"
              @blur="setContractAmount"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的服务包金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              产品升级金额
              <em class="tip">(包含整装内主材报价单、整装内定制报价单金额)</em>
            </label>
            <van-field
              v-model="form.product_upgrade_amount"
              type="number"
              placeholder="0.00"
              @blur="setContractAmount"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的产品升级金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              产品单独销售金额
              <em class="tip">(包含主材单独销售、定制单独销售金额)</em>
            </label>
            <van-field
              v-model="form.products_sold_separately"
              type="number"
              placeholder="0.00"
              @blur="setContractAmount"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的产品单独销售金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              整装金额
            </label>
            <van-field
              v-model="form.contract_amount"
              type="number"
              @blur="getAmountNum"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的整装金额',
                  validator: validatorAmountSum,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写整装金额"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工程造价
            </label>
            <van-field
              v-model="form.project_cost"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的工程造价',
                  validator: validatorAmountSum,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工程造价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              税金
            </label>
            <van-field
              v-model="form.tax_amount"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的税金',
                  validator: validatorAmountSum,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写税金"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              管理费
            </label>
            <van-field
              v-model="form.manage_amount"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的管理费',
                  validator: validatorAmountSum,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写管理费"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">优惠活动</label>
            <van-field
              v-model="form.discount_activity_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择优惠活动"
              @click="showActivePicker = true"
            />
            <van-popup v-model:show="showActivePicker" position="bottom">
              <van-picker
                :columns="activeList"
                :columns-field-names="customFieldName"
                @confirm="onConfirm"
                cancel-button-text="清除"
                @cancel="onRemoveActive"
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">优惠金额</label>
            <van-field
              v-model="form.discount_amount"
              type="number"
              placeholder="0.00"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的优惠金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              收费比例
            </label>
            <van-field
              v-model="form.directory_attr_name"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请选择收费比例',
                },
              ]"
              placeholder="收费比例"
            ></van-field>
          </div>
          <div class="cell2">
            <label class="form_title">收款期数</label>
            <div
              class="collect_list"
              v-for="(item, index) in form.charge_proportion_details"
              :key="index"
            >
              <div class="line1 clearfix">
                <div class="pull-left header">
                  {{ item.payment_period }}
                </div>
                <div class="pull-left">
                  <p class="title">收款比例</p>
                  <p class="num">{{ item.proportion + '%' }}</p>
                </div>
                <div class="pull-left">
                  <p class="title">应收金额</p>
                  <p class="num">{{ item.receivable_money }}</p>
                </div>
              </div>
              <div class="line2">
                <van-field
                  v-model="item.payment_description"
                  placeholder="输入款项说明"
                  maxlength="100"
                />
              </div>
            </div>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
} from 'vant';
import { Toast } from 'vant';
import * as _ from 'lodash';
import NP from 'number-precision';
export default {
  props: [
    'type',
    'contractType',
    'data',
    'parameterList',
    'charge_proportions',
    'contractTypeName',
  ],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      contractType: props.contractType,
      //-活动
      showActivePicker: false,
      activeList: props.parameterList
        ? props.parameterList.contract_activity
        : [],
    });

    //--表单---------------------
    //-贷款/优惠金额/整装产品金额等
    const amountReg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/; //最多输入两位小数
    const validatorAmount = (val) =>
      val
        ? parseFloat(val) <= 99999999.99 &&
          parseFloat(val) >= 0 &&
          amountReg.test(val)
          ? true
          : false
        : true;
    //-整装金额
    const validatorAmountSum = (val) =>
      val
        ? parseFloat(val) > 0 &&
          parseFloat(val) <= 99999999.99 &&
          amountReg.test(val)
          ? true
          : false
        : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      contract_amount_sum: props.data.contract_amount
        ? _.floor(
            NP.plus(
              parseFloat(props.data.contract_amount),
              parseFloat(props.data.service_package_amount),
              parseFloat(props.data.product_upgrade_amount),
              parseFloat(props.data.products_sold_separately)
            ),
            2
          )
        : '',
      contract_amount: props.data.contract_amount
        ? props.data.contract_amount
        : '',
      directory_attr_id: props.data.directory_attr_id
        ? props.data.directory_attr_id
        : props.contractType,
      directory_attr_name: props.data.directory_attr_name
        ? props.data.directory_attr_name
        : props.contractTypeName,
      discount_activity_id: props.data.discount_activity_id
        ? props.data.discount_activity_id
        : '',
      discount_activity_name: props.data.discount_activity_name
        ? props.data.discount_activity_name
        : '',
      discount_amount: props.data.discount_amount
        ? props.data.discount_amount
        : '',
      pack_amount: props.data.pack_amount ? props.data.pack_amount : '',
      service_package_amount: props.data.service_package_amount
        ? props.data.service_package_amount
        : '',
      product_upgrade_amount: props.data.product_upgrade_amount
        ? props.data.product_upgrade_amount
        : '',
      products_sold_separately: props.data.products_sold_separately
        ? props.data.products_sold_separately
        : '',
      charge_proportion_details: props.data.charge_proportion_details
        ? props.data.charge_proportion_details
        : props.charge_proportions
        ? props.charge_proportions
        : [],
      project_cost: props.data.project_cost ? props.data.project_cost : '',
      tax_amount: props.data.tax_amount ? props.data.tax_amount : '',
      manage_amount: props.data.manage_amount ? props.data.manage_amount : '',
    });
    //-picker-活动选择项字段
    const customFieldName = {
      text: 'name',
      value: 'id',
    };
    const onConfirm = (value) => {
      if (value) {
        form.discount_activity_name = value.name;
        form.discount_activity_id = value.id;
      }
      state.showActivePicker = false;
    };
    const onRemoveActive = () => {
      form.discount_activity_name = '';
      form.discount_activity_id = '';
      state.showActivePicker = false;
    };

    //-获取款期
    const floatNum1 = /^[0-9]+([.]{1}[0-9]{1})?$/;
    const floatNum2 = /^[0-9]+([.]{1}[0-9][0-9]{1})?$/;
    const getAmountNum = () => {
      if (form.charge_proportion_details.length) {
        let total = 0;
        return form.charge_proportion_details.map((v, index) => {
          if (
            index == form.charge_proportion_details.length - 1 &&
            form.charge_proportion_details.length > 1
          ) {
            v.receivable_money = (
              parseFloat(form.contract_amount ? form.contract_amount : 0) -
              parseFloat(total)
            ).toFixed(2);
          } else {
            var currentMoney =
              parseFloat(form.contract_amount ? form.contract_amount : 0) *
              (parseFloat(v.proportion) / 100);
            var currentMoneySpecial =
              Math.ceil(
                parseFloat(form.contract_amount ? form.contract_amount : 0) *
                  (parseFloat(v.proportion) / 100) *
                  100
              ) / 100;
            v.receivable_money =
              Number.isInteger(currentMoney) || floatNum1.test(currentMoney)
                ? currentMoney.toFixed(2)
                : floatNum2.test(currentMoney)
                ? currentMoney
                : floatNum1.test(currentMoneySpecial)
                ? currentMoneySpecial.toFixed(2)
                : currentMoneySpecial;

            total += parseFloat(v.receivable_money);
          }

          v.payment_period;
          v.proportion;
          v.payment_period;
        });
      }
    };
    //-自动计算整装装金额
    const setContractAmount = () => {
      if (
        form.contract_amount_sum &&
        form.service_package_amount &&
        form.product_upgrade_amount &&
        form.products_sold_separately
      ) {
        form.contract_amount = _.floor(
          NP.minus(
            parseFloat(form.contract_amount_sum),
            parseFloat(form.service_package_amount),
            parseFloat(form.product_upgrade_amount),
            parseFloat(form.products_sold_separately)
          ),
          2
        );
        getAmountNum();
      }
    };
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          if (
            parseFloat(form.contract_amount_sum) ==
            _.floor(
              NP.plus(
                parseFloat(form.contract_amount),
                parseFloat(form.service_package_amount),
                parseFloat(form.product_upgrade_amount),
                parseFloat(form.products_sold_separately)
              ),
              2
            )
          ) {
            emit('reviewFun', state.type, form);
          } else {
            Toast('请核对签订合同总价！');
          }
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      onConfirm,
      btnSumbit,
      customFieldName,
      getAmountNum,
      validatorAmount,
      onRemoveActive,
      setContractAmount,
      validatorAmountSum,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
