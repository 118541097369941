<!--
 * @Author: gest
 * @Date: 2022-11-09 13:38:42
 * @LastEditTime: 2023-10-27 16:07:15
 * @Description: 见面回单审核操作面板
 * v1.3.5 选择回单，联动回单原因
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <h3 class="title_tip">{{ props.title }}</h3>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell" v-if="props?.data?.checkStaus == 'ok'">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              回单原因
            </label>
            <!-- <Field
              v-model="form.list.receipt_cause"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="选择回单时必填"
              show-word-limit
              :rules="[
                {
                  required: true,
                  message: '请填写回单原因',
                },
              ]"
            /> -->
            <Field
              v-model="form.list.receipt_cause"
              right-icon="arrow-down"
              readonly
              placeholder="选择回单原因"
              :rules="[
                {
                  required: true,
                  message: '请选择回单原因',
                },
              ]"
              @click="() => (receipt_cause_show = true)"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注派单部门可见"
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" style="box-shadow: none">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        确定
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 回单原因 -->
    <Popup v-model:show="receipt_cause_show" position="bottom">
      <Picker
        :columns="props?.data?.receipt_cause_type_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changeReceiptPicker(value)"
        @cancel="clearReceiptPicker()"
        cancel-button-text="清除"
        :default-index="
          props?.data?.receipt_cause_type_list.findIndex((v) => {
            return v.id == form.list.receipt_cause_type;
          })
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, defineProps } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
  Popup,
  Picker,
  // Dialog,
} from 'vant';
import { useRouter } from 'vue-router';
const props = defineProps(['data', 'title', 'id']);
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
});

onBeforeMount(() => {});
console.log(props.data);
//-获取参数--end
//--基本信息表单---------------------
const ruleFormRef = ref(null);

const form = reactive({
  list: {
    receipt_cause: props.data?.receipt_cause || '',
    receipt_cause_type: props.data?.receipt_cause_type || '',
    remark: props.data?.remark || '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      operateFun();
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

// 调用接口
const operateFun = () => {
  goConfirm();
};
const goConfirm = () => {
  let obj = {
    task_id: props.id,
    approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
    receipt_cause: form.list.receipt_cause,
    receipt_cause_type: form.list.receipt_cause_type,
    remark: form.list.remark,
  };

  $http.confirmMeetingForCenterManager(obj).then((res) => {
    const { code, data } = res;
    state.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//--基本信息表单-----end----------------

// -- v1.4.8 回单原因下拉
const receipt_cause_show = ref(false);
const changeReceiptPicker = (value) => {
  form.list.receipt_cause_type = value.id;
  form.list.receipt_cause = value.name;
  receipt_cause_show.value = false;
};
const clearReceiptPicker = () => {
  form.list.receipt_cause_type = '';
  form.list.receipt_cause = '';
  receipt_cause_show.value = false;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
