<!--
 * @Author: Jessica
 * @Date: 2022-10-12 10:48:26
 * @LastEditTime: 2022-10-19 16:50:44
 * @Description: 
-->
<template>
  <!-- 合同变更信息 -->
  <div class="temp temp_first" :class="{ temp_first_top: props.contractType }">
    <h1>合同变更信息</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更合同类型
            </label>
            <Field
              v-model="form.change_contract_type_name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择变更合同类型',
                },
              ]"
              placeholder="请选择变更合同类型"
            />
          </div>
          <!-- 合同基本信息 -->
          <div class="changeInfo">
            <h3>基本信息</h3>
            <div class="changeInfo_item">
              <p>
                <label>合同编号:</label>
                <span>{{ form.contract_no || '-' }}</span>
              </p>
              <p>
                <label>客户姓名:</label>
                <span>{{ form.customer_name || '-' }}</span>
              </p>
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更原因（具体原因）
            </label>
            <Field
              v-model="form.change_specific_reasons"
              rows="5"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="请填写具体变更原因..."
              show-word-limit
              :rules="[
                {
                  required: true,
                  message: '请填写具体变更原因',
                },
              ]"
            />
          </div>
        </CellGroup>
      </Form>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, defineProps, defineExpose } from 'vue';
import { Form, Field, CellGroup } from 'vant';
const props = defineProps(['data', 'contractType']);

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  contract_no: props.data.contract_no,
  customer_name: props.data.customer_name,
  change_contract_type_id: props.data.change_contract_type_id,
  change_contract_type_name: props.data.change_contract_type_name,
  change_specific_reasons: props.data.change_specific_reasons,
});

defineExpose({ form, ruleFormRef });
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
