<!--
 * @Author: Jessica
 * @Date: 2024-06-07 11:00:28
 * @LastEditTime: 2024-06-14 11:07:20
 * @Description: V1.6.1 标记完成+查看施工团队-施工团队模版
-->
<template>
  <div class="content-box">
    <!-- end -->
    <!-- 施工团队成员 -->
    <div class="work_list" v-if="props.work_members?.length">
      <h3>施工团队成员</h3>
      <div
        v-for="(item, index) in props.work_members"
        :key="index"
        class="same-box"
      >
        <div class="con-box">
          <dl class="cus-message">
            <dt>
              <lazy-component>
                <Image
                  class="img"
                  width="48px"
                  height="48px"
                  v-if="item.face_image"
                  :src="
                    item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                  "
                  v-lazy
                  fit="cover"
                  @click="
                    fileDetail(
                      {
                        path: item.face_image,
                        type: 'png',
                      },
                      [{ path: item.face_image, type: 'png' }]
                    )
                  "
                />
                <img
                  class="img"
                  :src="require('@/assets/images/icon-empty-img2.png')"
                  v-else
                />
              </lazy-component>
            </dt>
            <dd>
              <div class="flex_between">
                <div>
                  <p class="name">{{ item.name + ' · ' + item.phone }}</p>
                  <p class="work">
                    {{ item.work_type_names }}
                  </p>
                  <p class="desc">身份证号： {{ item.id_card || '-' }}</p>
                  <p class="desc">
                    实际施工时间：
                    {{ item.start_time ? item.start_time : '未知' }}～{{
                      item.end_time ? item.end_time : '未知'
                    }}
                  </p>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- 空数据 -->
      <div class="empty-box" v-if="props.work_members?.length == 0">
        <p>暂无数据</p>
      </div>
    </div>
    <!-- 其他施工团队成员 -->
    <div class="work_list mt_16" v-if="props.other_work_members?.length">
      <h3>其他施工团队成员</h3>
      <div
        v-for="(item, index) in props.other_work_members"
        :key="index"
        class="same-box"
      >
        <div class="con-box">
          <dl class="cus-message">
            <dt>
              <lazy-component>
                <Image
                  class="img"
                  width="48px"
                  height="48px"
                  v-if="item.face_image"
                  :src="
                    item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                  "
                  v-lazy
                  fit="cover"
                  @click="
                    fileDetail(
                      {
                        path: item.face_image,
                        type: 'png',
                      },
                      [{ path: item.face_image, type: 'png' }]
                    )
                  "
                />
                <img
                  class="img"
                  :src="require('@/assets/images/icon-empty-img2.png')"
                  v-else
                />
              </lazy-component>
            </dt>
            <dd>
              <div class="flex_between">
                <div>
                  <p class="name">{{ item.name + ' · ' + item.phone }}</p>
                  <p class="work">
                    {{ item.work_type_names }}
                  </p>
                  <p class="desc">身份证号： {{ item.id_card || '-' }}</p>
                  <p class="desc">
                    实际施工时间：{{
                      item.start_time && item.end_time
                        ? item.start_time + '~' + item.end_time
                        : '-'
                    }}
                  </p>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- 空数据 -->
      <div class="empty-box" v-if="props.other_work_members?.length == 0">
        <p>暂无数据</p>
      </div>
    </div>

    <!-- 空数据 -->
    <div
      class="empty-box"
      v-if="
        props.work_members?.length == 0 && props.other_work_members?.length == 0
      "
    >
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script setup>
import { defineProps, inject } from 'vue';
const props = defineProps(['work_members', 'other_work_members']);
import { Image } from 'vant';
import { fileDetail } from '@/utils/fileUpload';
const $host = inject('$host');
</script>
<style lang="less" scoped>
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.mt_16 {
  margin-top: 16px;
}
// 施工团队
.work_list {
  > h3 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 16px 5px;
  }
  .same-box {
    background: #fff;
    border-bottom: 1px solid rgba(228, 228, 228, 0.3);
    dl.cus-message {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px 16px;

      dt {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          width: 48px;
          height: 48px;

          & > div {
            width: 48px;
            height: 48px;
          }
        }

        .img {
          width: 48px;
          height: 48px;
          border-radius: 6px;
        }
        :deep(.van-image img) {
          width: 48px;
          height: 48px;
          border-radius: 6px;
        }
      }

      dd {
        flex-grow: 1;
        .name {
          font-size: 15px;
          line-height: 15px;
          margin-bottom: 4px;
        }

        .work {
          font-size: 13px;
          margin-bottom: 2px;
        }
        .desc {
          font-size: 13px;
          color: #999999;
        }
      }
    }
  }
  .empty-box {
    height: 100px;
    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #979797;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      font-weight: 400;
      margin-top: 15px;
    }
  }
}
</style>
