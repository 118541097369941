<!--
 * @Author: NanNan
 * @Date: 2022-05-20 17:13:35
 * @LastEditTime: 2024-05-28 09:49:37
 * @Description: 客户 - 生命周期4\5 - 转化、施工
 * v1.3.0 仅针对施工合同，1去除申请交付派单功能（申请交付派单按钮+待主创设计师申请交付派单文案）
 * v1.5.1 隐藏产品去下单入口以及确认开工入口
-->
<template>
  <div class="page-cycle-construction-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="card-box" v-if="_DATA.contract_list?.length > 0">
      <!-- 所有合同工（设计协议、施工合同） -->
      <!-- 设计协议切换卡片 -->
      <div class="change-box">
        <div class="left">
          <h1>{{ _DATA.info.contract_name }}</h1>
          <p>合同编号：{{ _DATA.info.contract_no }}</p>
        </div>
        <div
          class="right"
          v-if="_DATA.contract_list?.length > 1"
          @click="_DATA.contract_show = true"
        >
          <Icon name="arrow-down" size="16" color="#7C7B7B" />
        </div>
      </div>

      <!-- 设计协议展示 -->
      <div class="content" v-if="options.design.indexOf(_DATA.info.type) > -1">
        <!-- 设计启动（进度展示） -->
        <div class="circle-box" v-if="_DATA.info.design_start == 1">
          <Circle
            v-if="_DATA.info.over_percent >= 0"
            v-model:current-rate="_DATA.currentRate"
            :rate="_DATA.info.over_percent"
            :speed="50"
            :size="160"
            layer-color="#D7E5FD"
            stroke-width="120"
          >
            <template #default>
              <!-- 设计状态 1待启动 2进行中 3已完成 -->
              <div class="circle-text" v-if="_DATA.info.design_status !== 3">
                <p class="day">
                  <span style="font-size: 32px; font-weight: bold">
                    {{ _DATA.info.done_node_num }}
                  </span>
                  <span style="font-size: 15px; font-weight: bold">
                    /{{ _DATA.info.total_node_num }}
                  </span>
                </p>
                <p class="title">已完成节点</p>
              </div>
              <div class="circle-text" v-else>
                <p class="all">已完成</p>
              </div>
            </template>
          </Circle>
          <div class="time-box-page">
            <div class="time-box">
              <div class="left">
                <p class="title">启动日期</p>
                <p class="time">{{ _DATA.info.start_time || '-' }}</p>
              </div>
              <div class="left">
                <p class="title" style="text-align: right">预计完成日期</p>
                <p class="time">{{ _DATA.info.end_time || '-' }}</p>
              </div>
            </div>
          </div>
          <!-- 更多操作 --V1.3.2 紧急版本 添加设计死单：根据设计状态判断，若处于设计死单状态，查看设计节点按钮隐藏！！！！！-->
          <Button
            type="primary"
            size="large"
            :to="
              '/design?id=' +
              _DATA.customer_id +
              '&&did=' +
              _DATA.info.design_id
            "
            class="btn"
            v-if="_DATA.info.design_status !== 6"
          >
            查看设计节点
          </Button>
        </div>
        <!-- 设计未启动->设计启动 -->
        <div class="circle-box" v-else>
          <div class="no-site">
            <div class="f">
              <img
                :src="require('@/assets/images/empty-gd.png')"
                alt=""
                srcset=""
              />
            </div>
            <Button
              type="primary"
              size="large"
              class="btn"
              style="width: 50%"
              @click="handleClick('设计启动')"
              v-if="!_DATA.info.design_start && props?.auth?.indexOf('99') > -1"
            >
              设计启动
            </Button>
            <p
              class="warn-message"
              v-if="
                !_DATA.info.design_start && props?.auth?.indexOf('99') === -1
              "
            >
              待主创设计师设计启动...
            </p>
          </div>
        </div>
      </div>

      <!-- 施工合同展示 -->
      <div
        class="content"
        v-if="options.construction.indexOf(_DATA.info.type) > -1"
      >
        <!-- 1.5.1 99需求隐藏确认开工相关提示 -->
        <!-- <div
          class="font_size_13 flex_between align_center box_tip"
          v-if="_DATA.info.node_order_show == 1"
        >
          <svg aria-hidden="true" class="icon_svg">
            <use xlink:href="#icon-information-fill"></use>
          </svg>
          <span class="flex1"
            >有在开工前需下单的产品未下单，待下单完成后才可操作确认开工！
            <span class="blue_color" @click="dealOrder()">去下单 ></span></span
          >
        </div> -->
        <!-- 有工地、已开工（展示进度） -->
        <div class="circle-box" v-if="_DATA.info.construction_start == 1">
          <!-- 状态：停工中 -->
          <div class="has_site_tag" v-if="_DATA.info.use_schedule">
            已施工{{ _DATA.info.use_schedule }}天
          </div>
          <div class="stop_tag" v-if="_DATA.info.construction_status_val == 5">
            停工中
          </div>
          <Circle
            v-if="_DATA.info.over_percent >= 0"
            v-model:current-rate="_DATA.currentRate"
            :rate="_DATA.info.over_percent"
            :speed="50"
            :size="160"
            layer-color="#D7E5FD"
            stroke-width="120"
            @click="
              linkTo('/constructionPlan', {
                gdid: _DATA.info.construction_id,
                cid: _DATA.info.contract_id,
              })
            "
          >
            <template #default>
              <!-- ——construction_status
施工状态 0 待操作施工开工 1 施工开工审核中 2 待操作交付派单 3 待操作确认开工 4 确认开工审核中 5 已开工 6 已完工 7终止 -->
              <div
                class="circle-text"
                v-if="_DATA.info.construction_status !== 6"
              >
                <p class="day">
                  <span style="font-size: 32px; font-weight: bold">
                    {{ _DATA.info.done_node_num }}
                  </span>
                  <span style="font-size: 15px; font-weight: bold">
                    /{{ _DATA.info.total_node_num }}
                  </span>
                </p>
                <p class="title">已完成节点</p>
                <p
                  class="delay"
                  v-if="
                    _DATA.info.delay_schedule_day > 0 &&
                    _DATA.info.construction_status !== 7 &&
                    _DATA.info.is_delay == 1
                  "
                >
                  <Icon
                    name="warning"
                    color="#EA0000"
                    size="14"
                    style="margin-right: 4px"
                  />
                  延期{{ _DATA.info.delay_schedule_day }}天
                </p>

                <p class="delay" v-if="_DATA.info.construction_status == 7">
                  <Icon
                    name="warning"
                    color="#EA0000"
                    size="14"
                    style="margin-right: 4px"
                  />
                  工地终止
                </p>
              </div>
              <div class="circle-text" v-else>
                <p class="grey_color font_size_15">已完工</p>
                <p
                  class="font_bold font_size_17"
                  v-if="_DATA.info.finish_schedule"
                >
                  工期<span class="blue_color font_bold">{{
                    _DATA.info.finish_schedule
                  }}</span
                  >天
                </p>
                <p
                  class="delay"
                  v-if="
                    _DATA.info.delay_schedule_day > 0 &&
                    _DATA.info.is_delay == 1
                  "
                >
                  <Icon
                    name="warning"
                    color="#EA0000"
                    size="14"
                    style="margin-right: 4px"
                  />
                  延期{{ _DATA.info.delay_schedule_day }}天
                </p>
                <p
                  class="success"
                  v-if="
                    _DATA.info.delay_schedule_day > 0 &&
                    _DATA.info.is_delay == 2
                  "
                >
                  提前{{ _DATA.info.delay_schedule_day }}天
                </p>
              </div>
            </template>
          </Circle>
          <div class="time-box-page">
            <div class="time-box">
              <div class="left">
                <p class="title">开工日期</p>
                <p class="time">{{ _DATA.info.start_time || '-' }}</p>
              </div>
              <div class="left">
                <p class="title" style="text-align: right">
                  {{
                    _DATA.info.construction_status == 6
                      ? '实际完工日期'
                      : '预计完工日期'
                  }}
                </p>
                <p
                  class="time"
                  :class="{
                    'text-right': _DATA.info.construction_status_val == 5,
                  }"
                >
                  {{
                    _DATA.info.construction_status_val == 5
                      ? '-'
                      : _DATA.info.end_time
                      ? _DATA.info.end_time
                      : '-'
                  }}
                </p>
              </div>
            </div>
          </div>
          <ul class="ul-flex">
            <li
              v-for="item in _DATA.tab_list"
              @click="
                linkTo(item.path, {
                  gdid: _DATA.info.construction_id,
                  cid: _DATA.info.contract_id,
                })
              "
              :key="item.name"
            >
              <img :src="item.icon" />
              <p>{{ item.name }}</p>
            </li>
          </ul>
          <!-- 产品订单/报价单/施工报价相关 -->
          <ul class="ul-flex">
            <template v-for="item in _DATA.order_tab_list" :key="item.name">
              <li
                v-if="
                  (item.name == '主材订单' &&
                    allOperationAuthority.indexOf('426') > -1) ||
                  item.name !== '主材订单'
                "
                @click="
                  linkTo(item.path, {
                    gdid: _DATA.info.construction_id,
                  })
                "
              >
                <img :src="item.icon" />
                <p>{{ item.name }}</p>
              </li>
            </template>
          </ul>
          <!-- 安装计划/客户服务相关入口 -->
          <ul class="ul-flex">
            <template v-for="item in _DATA.quoation_tab_list" :key="item.name">
              <li
                @click="
                  linkTo(item.path, {
                    gdid: _DATA.info.construction_id,
                  })
                "
              >
                <img :src="item.icon" />
                <p>{{ item.name }}</p>
              </li>
            </template>
          </ul>
        </div>
        <div class="circle-box" v-else>
          <div class="no-site">
            <div class="f">
              <img
                :src="require('@/assets/images/empty-gd.png')"
                alt=""
                srcset=""
              />
            </div>
            <!-- <Button
              type="primary"
              size="large"
              class="btn"
              style="width: 50%"
              @click="handleClick('申请交付派单')"
              v-if="
                _DATA.info.construction_status === 2 &&
                props?.auth?.indexOf('135') > -1
              "
            >
              申请交付派单
            </Button> 
            <p
              class="warn-message"
              v-if="
                _DATA.info.construction_status === 2 &&
                props?.auth?.indexOf('135') === -1
              "
            >
              待主创设计师申请交付派单...
            </p>-->
            <p
              class="warn-message"
              v-if="_DATA.info.construction_first_payment !== 1"
            >
              待客户经理收施工首期款...
            </p>
            <!-- <Button
              type="primary"
              size="large"
              class="btn"
              style="width: 50%"
              @click="handleClick('确认开工')"
              v-if="_DATA.info.construction_status === 3"
            >
              确认开工
            </Button> -->
            <!-- <Button
              type="primary"
              size="large"
              class="btn"
              v-if="_DATA.info.construction_status === 1"
              disabled
              color="#666"
            >
              申请派单审核中...
            </Button> -->
            <p class="warn-message" v-if="_DATA.info.construction_status === 1">
              交付派单处理中...
            </p>
            <Button
              type="primary"
              size="large"
              class="btn"
              v-if="_DATA.info.construction_status === 4"
              disabled
              color="#666"
            >
              确认开工审核中...
            </Button>

            <Button
              type="primary"
              size="large"
              class="btn"
              style="width: 50%"
              @click="handleClick('完善派单信息')"
              v-if="_DATA.info.show_dispatch_btn == 1"
            >
              完善派单信息
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-box" style="padding: 0 20px" v-else>
      <div class="empty-box">
        <img :src="require('@/assets/images/empty.png')" alt="" />
        <p>暂无待处理合同</p>
        <Button
          type="primary"
          size="large"
          class="btn"
          style="width: 50%"
          :to="'/contractSign?id=' + _DATA.customer_id"
        >
          查看合同列表
        </Button>
      </div>
    </div>
    <!-- end -->
    <Popup v-model:show="_DATA.contract_show" position="bottom">
      <Picker
        :columns="_DATA.contract_list"
        :columns-field-names="{
          text: 'contract_name',
          value: 'design_id',
        }"
        :default-index="
          _DATA.contract_list.findIndex((v) => {
            return v.contract_id == _DATA.contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="_DATA.contract_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import {
  inject,
  onMounted,
  reactive,
  defineEmits,
  defineProps,
  computed,
} from 'vue';
import { useRouter } from 'vue-router';
import {
  Notify,
  Icon,
  Circle,
  Button,
  Popup,
  Picker,
  Loading,
  Overlay,
} from 'vant';
import { useStore } from 'vuex';
const $http = inject('$http');
const router = useRouter();
const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const options = reactive({
  design: [51, 52],
  construction: [54, 56, 53, 69],
});
const _DATA = reactive({
  customer_id: router.currentRoute.value.query.id, //客户id
  contract_id: router.currentRoute.value.query.cid, //合同id 有默认锁定选择合同
  contract_list: [],
  info: {},
  loading: false,
  currentRate: 0,
  //-切换设计协议
  contract_show: false,
  tab_list: [
    {
      name: '项目成员',
      path: '/siteProjectMembers',
      icon: require('@/assets/images/icon-xmcy.png'),
    },
    {
      name: '施工计划',
      path: '/constructionPlan',
      icon: require('@/assets/images/icon-jihua.png'),
    },
    {
      name: '施工日志',
      path: '/construc/log',
      icon: require('@/assets/images/icon-rizhi.png'),
    },

    {
      name: '现场管理',
      path: '/siteManagement',
      icon: require('@/assets/images/icon-guanli.png'),
    },
  ],
  //-产品订单/报价单/施工报价相关
  order_tab_list: [
    {
      name: '施工报价',
      path: '/siteQuotation',
      icon: require('@/assets/images/icon-quoation.png'),
    },
    {
      name: '主材报价',
      path: '/mainQuotation',
      icon: require('@/assets/images/icon-quation.png'),
    },
    {
      name: '主材订单',
      path: '/mainOrder',
      icon: require('@/assets/images/icon-order.png'),
    },
    {
      name: '自购产品',
      path: '/mainSelfOrder',
      icon: require('@/assets/images/icon-product.png'),
    },
  ],
  //安装计划/客户服务相关
  quoation_tab_list: [
    {
      name: '安装计划',
      path: '/installation',
      icon: require('@/assets/images/installation.png'),
    },

    {
      name: '客户服务',
      path: '/customerService',
      icon: require('@/assets/images/icon-fuwu.png'),
    },
  ],
});

const emit = defineEmits(['update:func']);
const props = defineProps(['data', 'auth']);
onMounted(() => {
  loadParmas();
});

/**
 * 接口调用
 */
//-@ 设计节点 获取参数 {type: 类型 -1全部 1设计 2施工}
const loadParmas = () => {
  _DATA.loading = true;
  let type = -1;
  if (props.data.design_show && !props.data.construction_show) {
    type = 1;
  } else if (!props.data.design_show && props.data.construction_show) {
    type = 2;
  }
  $http
    .getDesignCustomerListApi({ customer_id: _DATA.customer_id, type })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        _DATA.contract_list = data.list;
        if (data.list.length > 0) {
          let list = [];
          if (_DATA.contract_id) {
            list = data.list.filter(
              (item) => item.contract_id == _DATA.contract_id
            )[0];
            if (!list) {
              list = data.list[0];
            }
          } else {
            list = data.list[0];
          }
          _DATA.info = list;
          console.log(_DATA.info);
          //传参给 父组件
          emit('update:func', {
            ...list,
            site_id: list.construction_id,
          });
        }
      } else {
        Notify({ type: 'warning', message: data });
      }
      _DATA.loading = false;
    });
};
/**
 * 操作
 */
//-@操作
const handleClick = (type_name) => {
  switch (type_name) {
    case '设计启动':
      router.push({
        path: '/desginOperation',
        query: {
          id: _DATA.customer_id,
          cid: _DATA.info.contract_id,
          did: _DATA.info.design_id,
          startDate: _DATA.info.contract_start_time,
        },
      });
      break;
    // case '申请交付派单':
    //   router.push({
    //     path: '/construcReapplyOpen',
    //     query: {
    //       id: _DATA.customer_id,
    //       cid: _DATA.info.contract_id,
    //       gdid: _DATA.info.construction_id,
    //       startDate: _DATA.info.contract_start_time,
    //       cname: _DATA.info.contract_name,
    //       cno: _DATA.info.contract_no,
    //     },
    //   });
    //   break;
    case '确认开工':
      router.push({
        path: '/construcOperation',
        query: {
          id: _DATA.customer_id,
          cid: _DATA.info.contract_id,
          gdid: _DATA.info.construction_id,
          startDate: _DATA.info.contract_start_time,
          house_size: _DATA.info.house_size,
          type: _DATA.info.type,
        },
      });
      break;
    case '完善派单信息':
      router.push({
        path: '/perfectDispatch',
        query: {
          id: _DATA.info.dispatch_id,
        },
      });
      break;
  }
};
//-@切换合同
const changeContract = (val) => {
  _DATA.contract_show = false;
  _DATA.loading = true;
  setTimeout(() => {
    _DATA.loading = false;
  }, 1000);
  if (val) {
    _DATA.contract_id = val.contract_id;
    router.push({
      path: '/sale',
      query: { id: router.currentRoute.value.query.id, cid: val.contract_id },
    });
  }

  Object.assign(_DATA.info, val);
  //传参给 父组件
  emit('update:func', {
    ...val,
    site_id: val.construction_id,
  });
};
//-@跳转
const linkTo = (name, query) => {
  router.push({ path: name, query: { ...query, id: _DATA.customer_id } });
};

// 1.5.1 99需求隐藏去下单入口
// const dealOrder = () => {
//   router.push({
//     path: '/productOrder',
//     query: { id: _DATA.customer_id, gdid: _DATA.info.construction_id },
//   });
// };
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/saleCycleConstruction.less';
.box_tip {
  background: rgba(243, 112, 32, 0.102);
  padding: 8px 16px;
  color: #f37020;
  .icon_svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
    margin-right: 6px;
  }
}
</style>
