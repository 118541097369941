<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2023-12-12 16:57:33
 * @Description: 
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同执行人
            </label>
            <van-field
              class="noBorder"
              v-model="form.executor_type"
              :rules="[
                {
                  required: true,
                  message: '请选择合同执行人',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in personList"
                    :key="item.id"
                    :class="{ checked: form.executor_type == item.id }"
                    class="pull-left"
                    @click="getChecked(item.id)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="extra_operate" v-if="form.executor_type">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
            <div
              class="extra_item"
              @click.stop="getHandelFun('helper')"
              v-if="form.executor_type == 2"
            >
              <p class="t1">完善委托人信息</p>
              <p class="t2" v-if="form.customer_entrust_num > 0">
                <span>{{ form.customer_entrust_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              关联合同
            </label>
            <van-field
              v-model="form.relation_contract_name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择关联合同',
                },
              ]"
              placeholder="请选择关联合同"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积
            </label>
            <van-field
              v-model="form.house_size"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的房屋面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写房屋面积"
            >
              <template #button>
                <van-text>㎡</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <van-field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <van-field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              整装优选产品
            </label>
            <van-field
              v-model="form.product_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择整装优选产品',
                },
              ]"
              placeholder="请选择整装优选产品"
              @click="showTypePicker1 = true"
            />
            <van-popup v-model:show="showTypePicker1" position="bottom">
              <van-picker
                :columns="preferred_packaged_products"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type1')"
                @cancel="showTypePicker1 = false"
              />
            </van-popup>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
    <!-- 完善客户基础信息/完善执行人信息 -->
    <van-popup
      v-model:show="complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="complete_card.complete_card_Type == 'base'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
      <SaleAddExecutorPage
        v-if="complete_card.complete_card_Type == 'helper'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </van-popup>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  Notify,
} from 'vant';
import { Toast } from 'vant';
import SaleAddMorePage from '@/components/sale/addMore';
import SaleAddExecutorPage from '@/components/sale/addExecutor';
import patterns from '@/utils/pattern';
export default {
  props: [
    'type',
    'data',
    'customer_id',
    'parameterList',
    'parameterListForCustomer',
  ],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Notify.name]: Notify,
    SaleAddMorePage,
    SaleAddExecutorPage,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      personList: [
        { id: 1, name: '本人' },
        { id: 2, name: '委托人' },
      ],
      //-整装优选产品参数
      preferred_packaged_products: props.parameterList
        ? props.parameterList.preferred_packaged_sell_products
        : [],
      showTypePicker1: false,
      //-合同签署方
      signatoryList: [
        { id: 1, name: '个体' },
        { id: 2, name: '企业' },
      ],
      //-完善信息
      complete_card: {
        //-客户Id
        customer_id: props.customer_id ? props.customer_id : '',
        complete_card_Type: '',
      },
      complete_card_show: false,
    });

    onMounted(() => {
      getCustomerParameter(props.customer_id);
      get_product_price();
    });

    //-@重新生成获取整装优选产品单价
    const get_product_price = () => {
      const product_id = props?.data?.product_id ?? '';
      const obj_arr = state.preferred_packaged_products.filter((v) => {
        return v.id == product_id;
      });
      form.product_price = obj_arr?.length ? obj_arr[0]['product_price'] : 0;
    };
    //--表单---------------------
    /**
     * 校验方式
     */
    const { houseSizeReg } = patterns;
    //-房屋面积
    const validatorSize = (val) =>
      val
        ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
          ? true
          : false
        : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      executor_type: props.data.executor_type ? props.data.executor_type : '',
      house_size: props.data.house_size
        ? props.data.house_size
        : props.parameterListForCustomer.house_size
        ? props.parameterListForCustomer.house_size
        : '',
      product_id: props.data.product_id ? props.data.product_id : '',
      product_name: props.data.product_name ? props.data.product_name : '',
      relation_contract_id: props.data.relation_contract_id
        ? props.data.relation_contract_id
        : props.parameterListForCustomer
        ? props.parameterListForCustomer.relation_contract.contract_id
        : '',
      relation_contract_name: props.data.relation_contract_name
        ? props.data.relation_contract_name
        : props.parameterListForCustomer
        ? props.parameterListForCustomer.relation_contract.contract_name +
          '(' +
          props.parameterListForCustomer.relation_contract.contract_no +
          ')'
        : '',
      signatory: props.data.signatory ? props.data.signatory : '',
      company_name: props.data.company_name ? props.data.company_name : '',
      //-待完善条数-本人
      customer_other_num: 0,
      //-待完善条数-委托人
      customer_entrust_num: 0,
      //-整装优选产品单价
      product_price: 0,
    });

    //-@选择合同执行人---begin
    const getChecked = (id) => {
      form.executor_type = id;
    };

    //-完善合同执行人信息
    const getHandelFun = (type) => {
      state.complete_card_show = true;
      state.complete_card.complete_card_Type = type;
    };
    //-完善合同执行人、委托人信息操作
    const reviewOk = () => {
      if (props.customer_id) {
        getCustomerParameter(props.customer_id);
      }
    };
    //-获取客户参数
    const getCustomerParameter = (id) => {
      state.complete_card_show = false;
      state.complete_card.complete_card_Type = '';
      $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
        if (res.code === 0) {
          form.customer_other_num = res.data.customer_other_num;
          form.customer_entrust_num = res.data.customer_entrust_num;
        } else {
          Toast(res.data);
        }
      });
    };
    //-@选择合同执行人---end

    //-picker-类型选择
    const customFieldName = {
      text: 'name',
      value: 'id',
    };

    const onConfirmType = (value, type) => {
      if (type === 'type1') {
        if (value) {
          form.product_name = value.name;
          form.product_id = value.id;
          form.product_price = value.product_price;
        }
        state.showTypePicker1 = false;
      }
    };
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      btnSumbit,
      getChecked,
      getHandelFun,
      customFieldName,
      onConfirmType,
      reviewOk,
      validatorSize,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
