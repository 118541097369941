<!--
 * @Author: Jessica
 * @Date: 2023-06-17 16:36:06
 * @LastEditTime: 2024-05-28 16:25:06
 * @Description: 目标数据-协议数
-->
<template>
  <!-- 协议数 -->
  <div class="main-data" @click.stop="redictFun">
    <div class="warpper">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading || props.loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_3.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                协议数
                <em>{{ detail.total?.actual ?? '-' }}</em>
                <span class="font-size15">
                  {{ ' /' + detail.total?.all ?? '-' }}
                </span>
              </div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>

                  <em class="pl-3">{{
                    detail.total?.target === '' ? '-' : detail.total?.target
                  }}</em>
                  <!-- 规则入口 -->
                  <div
                    class="icon_info_box_small pl-3"
                    @click.stop="createDanger"
                  >
                    <img
                      class="icon_img"
                      :src="
                        require('@/assets/images/target-data/icon_info.png')
                      "
                      alt="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap" v-if="detail.total?.gap !== ''">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail.total?.target ?? 0) <
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c1">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail.total?.target ?? 0) >
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c2">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">完成率</span>
                <span class="font-size12 pl-3">
                  {{
                    detail.total?.done_rate === ''
                      ? '-'
                      : detail.total?.done_rate + '%'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <!-- 当年 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">当年</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.now?.target === '' ? '-' : detail.now?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.now?.actual ?? '-' }}</em>
                    <span>{{ ' /' + detail.now?.all ?? '-' }}</span>
                  </p>
                  <p class="temp3">成功率{{ detail.now?.rate + '%' ?? '-' }}</p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.now?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.now?.target ?? 0) <
                          parseFloat(detail.now?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.now?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.now?.target ?? 0) >
                          parseFloat(detail.now?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.now?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">
                        {{
                          detail.now?.done_rate === ''
                            ? '-'
                            : detail.now?.done_rate + '%'
                        }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 存量 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">存量</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.passed?.target === ''
                          ? '-'
                          : detail.passed?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.passed?.actual ?? '-' }}</em>
                    <span>{{ ' /' + detail.passed?.all ?? '-' }}</span>
                  </p>
                  <p class="temp3">当年完成</p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.passed?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.passed?.target ?? 0) <
                          parseFloat(detail.passed?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.passed?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.passed?.target ?? 0) >
                          parseFloat(detail.passed?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.passed?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">
                        {{
                          detail.passed?.done_rate === ''
                            ? '-'
                            : detail.passed?.done_rate + '%'
                        }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 自主 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">自主</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.diy?.target === '' ? '-' : detail.diy?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.diy?.actual ?? '-' }}</em>
                  </p>
                  <p class="temp3">当年完成</p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.diy?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.diy?.target ?? 0) <
                          parseFloat(detail.diy?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.diy?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.diy?.target ?? 0) >
                          parseFloat(detail.diy?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.diy?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">
                        {{
                          detail.diy?.done_rate === ''
                            ? '-'
                            : detail.diy?.done_rate + '%'
                        }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 见面-协议时间 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">见面-协议</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">
                        {{
                          detail.meet_design?.target === ''
                            ? '-'
                            : detail.meet_design?.target + '天'
                        }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.meet_design?.actual + '天' ?? '-' }}</em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.meet_design?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.meet_design?.target ?? 0) >
                          parseFloat(detail.meet_design?.actual ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.meet_design?.gap + '天' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.meet_design?.target ?? 0) <
                          parseFloat(detail.meet_design?.actual ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.meet_design?.gap + '天' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  defineProps,
  watch,
  defineEmits,
  defineExpose,
} from 'vue';
import { Notify, Skeleton, Row, Col } from 'vant';
import { useRouter } from 'vue-router';
const emit = defineEmits(['createDanger']);
const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .targetStatistic_homeContract({ year: props.data.yearId })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        detail.value = data;
      } else {
        detail.value = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 1, pos: 1 },
  });
};

//-规则说明--begin
const htmlTitle = '协议数_规则';
const htmlContent = `<p>1、【尚层装饰室内设计协议】满足合同签约金额≥15000且财务确认收首款，计数1；</p>
  <p>2、【尚层装饰配饰设计协议】满足合同签约金额≥8000且财务确认收首款，计数0.5；</p>
  <p>3、【尚层装饰室内设计协议】＜15000累计财务确认收产品款金额≥500000，协议数量计数1；</p>
  <p>4、【尚层装饰配饰设计协议】＜8000累计财务确认收产品款金额≥200000，协议数量计数0.5；</p>
  <p>5、当年：当年见面当年签约，存量：非当年见面当年签约；</p>`;
const createDanger = () => {
  emit('createDanger', 'protocolRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
