<!--
 * @Author: gest
 * @Date: 2024-05-09 15:34:52
 * @LastEditTime: 2024-05-23 14:39:43
 * @Description: v1.6.1 进度组件2
 四种颜色
  {
    actual: [20,3,6,8], //四种实际值
    list: [0, 100, 200, 300], //坐标值
  },
-->
<template>
  <div class="chart_box">
    <div
      v-if="props.data.actual[0] > 0"
      class="chart_line color1 stroke1"
      style="z-index: 5"
      :style="{
        width: getDis(props.data.actual[0]) + '%',
      }"
      :data-attr="props.data.actual[0]"
    >
      {{ props.data.actual[0] }}
    </div>
    <div
      v-if="props.data.actual[1] > 0"
      class="chart_line color2"
      style="z-index: 4"
      :style="{
        width: getDis(props.data.actual[1]) + '%',
      }"
    >
      <div class="text_box">
        <div
          style="position: relative"
          class="stroke2"
          :data-attr="props.data.actual[1]"
        >
          {{ props.data.actual[1] }}
        </div>
      </div>
    </div>
    <div
      v-if="props.data.actual[2] > 0"
      style="z-index: 3"
      class="chart_line color3"
      :style="{
        width: getDis(props.data.actual[2]) + '%',
      }"
    >
      <div class="text_box">
        <div
          style="position: relative"
          class="stroke3"
          :data-attr="props.data.actual[2]"
        >
          {{ props.data.actual[2] }}
        </div>
      </div>
    </div>
    <div
      v-if="props.data.actual[3] > 0"
      class="chart_line color4"
      :style="{
        width: getDis(props.data.actual[3]) + '%',
      }"
    >
      <div class="text_box">
        <div
          style="position: relative"
          class="stroke4"
          :data-attr="props.data.actual[3]"
        >
          {{ props.data.actual[3] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import * as _ from 'lodash';
import NP from 'number-precision';

const props = defineProps(['data']);

const getDis = (val) => {
  let result = 0;
  //计算进度条宽度百分比

  //负数
  let total =
    props.data.list?.length == 5
      ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
      : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));

  result = NP.times(_.floor(NP.divide(parseFloat(val), total), 4), 100);

  console.log(result);
  return result;
};
</script>
<style lang="less" scoped>
.chart_box {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  .chart_line {
    text-align: center;
    font-size: 11px;
    line-height: 13px;
    height: 13px;

    white-space: nowrap;
    position: relative;
    color: #ffffff;
    z-index: 2;

    &.color1 {
      background: #0256ff;
    }
    &.color2 {
      background: #722ed1;
    }
    &.color3 {
      background: #14c9c9;
    }

    &.color4 {
      background: #f7ba1e;
    }
  }
  .text_box {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
.stroke1 {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #0256ff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}

.stroke2 {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #722ed1;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
.stroke3 {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #14c9c9;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
.stroke4 {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #f7ba1e;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
</style>
