<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2023-02-18 09:47:40
 * @Description: 申请看工地
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计看工地日期
            </label>
            <Field
              v-model="form.list.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择预计看工地日期"
              :rules="[
                {
                  required: true,
                  message: '请选择预计看工地日期',
                },
              ]"
              @click="openDateType('face_time')"
            />
            <p class="recentDate" @click="getRecentDate" v-if="state.face_time">
              获取最近待见面时间
            </p>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面工地
            </label>
            <div class="sitesLits">
              <div
                class="list_item"
                v-for="item in form.list.siteList"
                :key="item.id"
              >
                <h2>{{ item.estate_str }}</h2>
                <ol class="ol-flex">
                  <li>
                    <p class="t1">项目经理</p>
                    <p class="t2">
                      {{
                        item.project_manager_name
                          ? item.project_manager_name
                          : '-'
                      }}
                    </p>
                    <p class="t3">
                      {{
                        item.project_manager_phone
                          ? item.project_manager_phone
                          : '-'
                      }}
                    </p>
                  </li>
                  <li>
                    <p class="t1">工长</p>
                    <p class="t2">
                      {{
                        item.worker_leader_name ? item.worker_leader_name : '-'
                      }}
                    </p>
                    <p class="t3">
                      {{
                        item.worker_leader_phone
                          ? item.worker_leader_phone
                          : '-'
                      }}
                    </p>
                  </li>
                  <li>
                    <p class="t1">现场负责人</p>
                    <p class="t2">
                      {{
                        item.scene_leader_name ? item.scene_leader_name : '-'
                      }}
                    </p>
                    <p class="t3">
                      {{
                        item.scene_leader_phone ? item.scene_leader_phone : '-'
                      }}
                    </p>
                  </li>
                </ol>
                <p class="removeBtn" @click.stop="deleteSiteFun(item)">
                  <Icon name="minus" />
                  <span>移除</span>
                </p>
              </div>
            </div>
            <Field
              v-model="form.list.sites"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面工地',
                },
              ]"
              @click="state.siteListPopup = true"
            >
              <template #input>
                <p class="siteIds">
                  <Icon name="plus" style="margin-right: 10px" />
                  添加见面工地
                </p>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>

    <!-- 工地列表-->
    <Popup
      v-model:show="state.siteListPopup"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SiteListTemp
        v-if="state.siteListPopup"
        :data="form.list.siteList"
        @reviewClose="
          () => {
            state.siteListPopup = false;
          }
        "
        @reviewOk="reviewOk"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Icon,
  DatetimePicker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
import SiteListTemp from '@/components/moreOperate/SiteListTemp';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-全部待办类型
  dtype: router.currentRoute.value.query
    ? router.currentRoute.value.query.dtype
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  currentDate: new Date(),
  //-最近待见面时间
  face_time: '',
  //-工地列表popup
  siteListPopup: false,
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    face_time: '',
    remark: '',
    sites: '', //-表单验证
    siteList: [],
    site_ids: [],
    attach_arr: [],
  },
});
//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-@获取参数
const getPublicParameter = () => {
  $http
    .customerGetReadyFaceTime({ customer_id: form.list.customer_id })
    .then((res) => {
      if (res.code === 0) {
        state.face_time = res.data.ready_face_time;
      } else {
        state.face_time = '';
      }
    });
};

//-@获取最近待见面时间
const getRecentDate = () => {
  form.list.face_time = state.face_time;
};

//-获取参数--end

//-工地操作-------begin----

//-@保存工地列表popup
const reviewOk = (data) => {
  form.list.siteList = data;
  form.list.site_ids = form.list.sites = form.list.siteList.map((v) => {
    return v.id;
  });
  state.siteListPopup = false;
};

//-@删除工地列表
const deleteSiteFun = (item) => {
  form.list.siteList = form.list.siteList.filter((v) => v.id != item.id);
  form.list.site_ids = form.list.sites = form.list.siteList.map((v) => {
    return v.id;
  });
};

//-工地操作-------end----

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .customerLookSite({
          customer_id: form.list.customer_id,
          face_time: form.list.face_time,
          remark: form.list.remark,
          site_ids: form.list.site_ids,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (state.taskDate) {
                  router.push('/taskDay?date=' + state.taskDate);
                } else {
                  router.push('/sale?id=' + form.list.customer_id);
                }
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
