<!--
 * @Author: gest
 * @Date: 2023-06-17 16:36:06
 * @LastEditTime: 2024-05-08 11:39:11
 * @Description: 数据看板一级页
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    :title="
      sameKey.length == 1
        ? allOperationAuthority.indexOf('629') > -1
          ? '运营数据'
          : '财务数据'
        : '数据看板'
    "
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="tabBar-box" v-show="sameKey.length && sameKey.length > 1">
    <div class="tabBar">
      <Tabs
        v-model:active="_DATA.tabIndex"
        title-inactive-color:="#8d9091"
        title-active-color="#0256FF"
        @change="changeTab"
      >
        <Tab
          title="运营"
          :name="1"
          v-if="allOperationAuthority.indexOf('629') > -1"
        ></Tab>
        <Tab
          title="财务"
          :name="2"
          v-if="allOperationAuthority.indexOf('539') > -1"
        ></Tab>
      </Tabs>
    </div>
  </div>
  <div class="tab_box" :class="{ no_top: sameKey.length == 1 }">
    <!-- 运营 -->
    <div
      style="height: 100%"
      v-if="
        (sameKey.length > 1 && _DATA.tabIndex == 1) ||
        (sameKey.length == 1 && allOperationAuthority.indexOf('629') > -1)
      "
    >
      <totalTargetTemp></totalTargetTemp>
    </div>
    <!-- 财务 -->
    <div
      style="height: 100%"
      v-if="
        (sameKey.length > 1 && _DATA.tabIndex == 2) ||
        (sameKey.length == 1 && allOperationAuthority.indexOf('539') > -1)
      "
    >
      <totalFinanceTemp></totalFinanceTemp>
    </div>
  </div>
  <!--V1.4.0 菜单 悬浮按钮 -->
  <ol class="fixed-ol-box">
    <li class="icon-box" @click="_DATA.showMenu = true">
      <img
        class="icon-fixed"
        :src="require('@/assets/images/icon/icon_menu.png')"
      />
    </li>
  </ol>
  <sideMenu
    :data="{ showMenu: _DATA.showMenu }"
    @closed="
      () => {
        _DATA.showMenu = false;
      }
    "
  ></sideMenu>
  <!-- end -->
</template>

<script setup>
import { reactive, computed } from 'vue';
import { NavBar, Tabs, Tab } from 'vant';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import totalTargetTemp from '@/components/targetData/totalTargetTemp'; // 运营一级
import totalFinanceTemp from '@/components/targetData/totalFinanceTemp';
import sideMenu from '@/components/sideMenu';
const router = useRouter();

const _DATA = reactive({
  tabIndex: Number(router.currentRoute.value.query.type) || 1, // 1运营  2财务
  authorList: ['629', '539'],
});
const store = useStore();
//-权限数据
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const sameKey = computed(() =>
  allOperationAuthority.value.filter((item) =>
    _DATA.authorList.some((e) => e == item)
  )
);

//-@切换tab
const changeTab = (val) => {
  // 动态修改路由参数
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.type = val;
  router.replace({ query });
  _DATA.tabIndex = val;
};
//-@ 返回
const onBack = () => {
  router.back();
};
</script>

<style lang="less" scoped>
.tabBar-box {
  height: 44px;
  z-index: 9999;
}
.tabBar {
  position: fixed;
  top: 45px;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 1;
  border-bottom: 1px solid #efefef;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  :deep(.van-tab__text) {
    font-size: 15px;
    font-weight: bold;
  }

  :deep(.van-tabs__line) {
    display: block;
    width: 8%;
    background: #0256ff;
  }
}
.tab_box {
  background: #f5f5f5;
  height: calc(100% - 90px);
  overflow: hidden;
  &.no_top {
    height: calc(100% - 46px);
  }
}
</style>
