<!--
 * @Author: NanNan
 * @Date: 2022-06-28 09:13:16
 * @LastEditTime: 2023-06-15 09:25:32
 * @Description:V1.3.8 自购详情-材料明细
-->
<template>
  <div>
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="detail_box" v-for="item in _DATA.info" :key="item.id">
      <div class="top_box">
        <div
          class="left"
          @click="fileDetail(item.img[0], item.img)"
          v-if="item.img && item.img.length > 0"
        >
          <Image class="avader_img" :src="$host + item.img[0].path" />
        </div>
        <div v-else class="left icon_name">自购</div>
        <div class="right">
          <div class="name_box">
            <p class="name" style="flex: 1">{{ item.name }}</p>
            <div class="clearfix" v-if="item.acceptance_status_name">
              <div class="status_tag red" style="float: right">
                {{ item.acceptance_status_name }}
              </div>
            </div>
          </div>

          <p class="info">
            <span class="t1">{{ item.space_name || '-' }}</span>
            <span class="t1">｜{{ item.category_sub_name || '-' }}</span>
            <span class="t1">｜{{ item.brand || '-' }}</span>
            <span class="t1">｜{{ item.model || '-' }}</span>
            <span class="t1">｜{{ item.norms || '-' }}</span>
            <span class="t1">｜{{ item.material || '-' }}</span>
            <span class="t1">｜{{ item.colour || '-' }}</span>
          </p>
        </div>
      </div>
      <div class="dec_box">
        <div class="dec_item">
          <div class="left">数量</div>
          <div class="right">
            {{ item.amount || '-' }}{{ item.unit }}
            <!-- <span v-if="item.return_num_name">
              /
              <span class="red">{{ item.return_num_name }}{{ item.unit }}</span>
            </span> -->
          </div>
        </div>
        <div class="dec_item">
          <div class="left">供货周期</div>
          <div class="right">
            {{ item.lead_period ? item.lead_period + '天' : '-' }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">备注</div>
          <div class="right">{{ item.remark || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, inject, onMounted } from 'vue';
import { Notify, Image, Overlay, Loading } from 'vant';
import { fileDetail } from '@/utils/fileUpload';
const $http = inject('$http');
const $host = inject('$host');
const props = defineProps(['id']);
const _DATA = reactive({
  loading: false,
  info: [],
});
onMounted(() => {
  loadInfo();
});
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .mainSelfOrder_materialDetails({
      id: props.id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data.list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      setTimeout(() => {
        _DATA.loading = false;
      }, 500);
    });
};
</script>
<style lang="less" scoped>
.detail_box {
  background-color: @white-color;
  padding: 0 16px;
  margin-bottom: 10px;
  .top_box {
    padding: 14px 0;

    display: flex;
    align-items: flex-start;

    .left {
      .avader_img {
        width: 40px;
        height: 40px;
        // border: 1px solid @line-color;
      }
    }
    .right {
      flex: 1;
      padding-left: 12px;

      .name_box {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        .name {
          font-size: 17px;
          font-weight: bold;
          line-height: 20px;
          padding-right: 5px;
        }
      }
      .status4 {
        display: inline-block;
        border-radius: 2px;
        font-size: 12px;
        line-height: 20px;
        padding: 0 4px;
        background: @red-bg-color;
        color: @red-color;
      }
      // .info {
      //   line-height: 22px;
      //   .t1 {
      //     font-size: 13px;
      //     color: #767676;
      //     line-height: 20px;

      //     padding: 0 5px;
      //     &.blue {
      //       color: #576b95;
      //     }
      //     &:last-child {
      //       border-right: none;
      //     }
      //     &:first-child {
      //       padding-left: 0;
      //     }
      //   }
      // }
      .t1 {
        font-size: 14px;
        color: #888888;
      }
    }
  }
  .dec_box {
    padding: 8px 0;
    border-top: 1px solid @line-color;
    .dec_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px;
      font-size: 14px;
      color: @grey-color;
      line-height: 20px;

      .price {
        text-decoration: line-through;
        color: #cccccc;
      }
      .blue {
        color: @blue-color;
      }
      .red {
        color: @red-color;
      }
    }
  }
}
.icon_name {
  background: rgba(0, 137, 17, 0.1);
  color: rgba(0, 137, 17, 0.4);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
</style>
