<!--
 * @Author: gest
 * @Date: 2022-08-04 15:42:24
 * @LastEditTime: 2024-06-14 11:08:00
 * @Description: 记录详情
-->
<template>
  <Popup
    v-model:show="_DATA.showOverlay"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="wrapper" v-if="_DATA.showOverlay" @click.stop>
      <NavBar
        left-arrow
        title="记录详情"
        @click-left="onCloseOverLay"
        :fixed="true"
      ></NavBar>
      <div class="content">
        <List
          v-model:loading="_DATA.loading"
          :finished="_DATA.finished"
          :immediate-check="false"
          :offset="50"
          @load="loadList"
        >
          <!-- <div> -->
          <div
            class="task-card-same"
            v-for="(val, index) in _DATA.list"
            :key="index"
          >
            <div class="item-top">
              <div class="time">{{ val.add_time }}</div>
              <div class="title">{{ val.type_name }}</div>
            </div>
            <div class="item-main">
              <div class="left"></div>
              <div class="right">
                <div class="person-box">
                  <div class="person-item">
                    <Image
                      v-if="val.user_type == 1"
                      class="img-head"
                      round
                      :src="require('@/assets/images/icon_sys.png')"
                    ></Image>
                    <Image
                      v-else
                      class="img-head"
                      round
                      :src="
                        val.user_image
                          ? val.user_image.includes('http')
                            ? val.user_image
                            : $host + val.user_image
                          : require('@/assets/images/icon-empty-img.png')
                      "
                    ></Image>
                    <div class="flex">
                      <div class="name">{{ val.user_name }}</div>
                      <div class="desc">
                        {{ val.user_description }}
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="cell-info">
                  <li v-for="(item, idx) in val.record" :key="idx">
                    <span class="grey" v-if="item.name">{{ item.name }}：</span>
                    <span>{{ item.value }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <template #finished>
            <Divider class="divider-bottom" v-if="_DATA.list.length > 0">
              我也是有底线的
            </Divider>
          </template>
        </List>
      </div>
      <!-- 空状态 -->
      <Empty
        class="empty-list"
        description="暂无操作记录"
        :image="require('@/assets/images/empty.png')"
        v-if="!_DATA.loading && _DATA.list.length === 0"
      />
      <!-- end -->
    </div>
  </Popup>
</template>
<script setup>
import { watch, inject, reactive, defineProps, defineEmits } from 'vue';
import { Image, Popup, NavBar, Notify, Divider, List, Empty } from 'vant';
const $host = inject('$host');
const $http = inject('$http');
const props = defineProps(['data', 'isShow']);
const emit = defineEmits(['closePop', 'update:func']);
const _DATA = reactive({
  info: {},
  showOverlay: false,
  list: [],
  page: {
    page: 1,
    count_page: 0,
    pages: 10,
  },
});

watch(
  () => props.data,
  (cur) => {
    _DATA.info = cur ? cur : {};
  }
);
watch(
  () => props.isShow,
  (cur) => {
    _DATA.showOverlay = cur ? cur : false;
    if (cur) {
      _DATA.list = [];
      _DATA.loading = false;
      _DATA.finished = false;
      _DATA.page = {
        page: 1,
        count_page: 0,
        pages: 10,
      };
      loadList();
    }
  }
);
const loadList = () => {
  _DATA.loading = true;
  //设计
  if (_DATA.info.type == 1) {
    $http
      .getDesignNodeLogs({
        node_id: _DATA.info.node_id,
        page: _DATA.page.page,
        pages: _DATA.page.pages,
      })
      .then((res) => {
        const { code, data } = res;
        _DATA.loading = false;
        if (code === 0) {
          _DATA.list = _DATA.list.concat(data.list);
          _DATA.page = data.page;
          if (data.page.page < data.page.count_page) {
            _DATA.page.page++;
          } else {
            _DATA.finished = true;
          }
        } else {
          _DATA.finished = true;
          Notify({ type: 'warning', message: res.data });
        }
      })
      .catch(() => {
        _DATA.loading = false;
        _DATA.finished = true;
      });
  } else {
    $http
      .getConstructionNodeLogs({
        node_id: _DATA.info.node_id,
        page: _DATA.page.page,
        pages: _DATA.page.pages,
      })
      .then((res) => {
        const { code, data } = res;
        _DATA.loading = false;
        if (code === 0) {
          _DATA.list = _DATA.list.concat(data.list);
          _DATA.page = data.page;
          if (data.page.page < data.page.count_page) {
            _DATA.page.page++;
          } else {
            _DATA.finished = true;
          }
        } else {
          _DATA.finished = true;
          Notify({ type: 'warning', message: res.data });
        }
      })
      .catch(() => {
        _DATA.loading = false;
        _DATA.finished = true;
      });
  }
};
const onCloseOverLay = () => {
  emit('closePop');
};
</script>
<style lang="less" scoped>
.wrapper {
  height: 100%;
  background-color: @grey_color;
  .content {
    padding-top: 12.3vw;
    background-color: @grey_color;
  }

  .item-top {
    padding: 15px;
    .time {
      font-size: 13px;

      color: #a6a6a6;
    }
    .title {
      font-size: 17px;
      font-weight: bold;

      color: #3e4345;
    }
  }
  .item-main {
    position: relative;
    display: flex;
    background-color: #ffffff;
    color: #151522;
    .left {
      &::after {
        content: '';
        width: 1px;
        border-left: 1px solid #e4e4e4;
        position: absolute;
        top: 0;
        left: 15px;
        height: 100%;
      }
    }
    .right {
      margin-left: 15px;
      flex: 1;
      .person-box {
        padding: 15px;
        .person-item {
          display: flex;
          align-items: center;
          .img-head {
            width: 35px;
            height: 35px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .flex {
            margin-left: 10px;
            display: flex;
            flex-direction: column;

            .name {
              font-weight: bold;
            }
            .desc {
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
      .cell-info {
        padding: 0 15px 15px 15px;
        .grey {
          color: #999999;
        }
      }
    }
  }
  .empty-list {
    background: #ffffff;
    height: calc(100% - 46px);
  }
}
</style>
