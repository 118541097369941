<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2023-12-14 16:58:33
 * @Description: 
-->
<template>
  <div class="page-waper">
    <div class="page-view">
      <!-- 加载状态 -->
      <Loading class="loading-box" color="#0094ff" v-if="state.loading" />
      <div class="page-item">
        <!-- 三大卡片类型 -->
        <div class="card-list">
          <p class="h1">
            {{ state.detail.contract_edit_type_str
            }}{{
              state.detail.construction_stage_name
                ? '(' + state.detail.construction_stage_name + ')'
                : ''
            }}
          </p>
          <!-- 基装实际增减金额 -->
          <div
            class="same-box"
            @click="getPopup('base')"
            v-if="state.detail.base_arr?.length"
          >
            <div class="t1 clearfix">
              <span class="t pull-left">基装预估增减项金额</span>
              <span class="n pull-right">
                合计 {{ state.detail.base_amount }}元
              </span>
            </div>
            <div class="t2">
              <p>
                <span>增项合计</span>
                <span>¥ {{ state.detail.base_plus_amount }}</span>
              </p>
              <p>
                <span>减项合计</span>
                <span>¥ {{ state.detail.base_minus_amount }}</span>
              </p>
            </div>
            <div class="t3">
              查看明细
              <Icon name="arrow" />
            </div>
          </div>
          <!-- 主材实际增减金额 -->
          <div
            class="same-box"
            @click="getPopup('main')"
            v-if="state.detail.main_arr?.length"
          >
            <div class="t1 clearfix">
              <span class="t pull-left">主材预估增减金额</span>
              <span class="n pull-right">
                合计 {{ state.detail.main_amount }}元
              </span>
            </div>
            <div class="t2">
              <p>
                <span>增项合计</span>
                <span>¥ {{ state.detail.main_plus_amount }}</span>
              </p>
              <p>
                <span>减项合计</span>
                <span>¥ {{ state.detail.main_minus_amount }}</span>
              </p>
            </div>
            <div class="t3">
              查看明细
              <Icon name="arrow" />
            </div>
          </div>
          <!-- 其他实际增减金额 -->
          <div
            class="same-box"
            @click="getPopup('other')"
            v-if="state.detail.other_arr?.length"
          >
            <div class="t1 clearfix">
              <span class="t pull-left">其他预估增减金额</span>
              <span class="n pull-right">
                合计 {{ state.detail.other_amount }}元
              </span>
            </div>
            <div class="t2">
              <p>
                <span>增项合计</span>
                <span>¥ {{ state.detail.other_plus_amount }}</span>
              </p>
              <p>
                <span>减项合计</span>
                <span>¥ {{ state.detail.other_minus_amount }}</span>
              </p>
            </div>
            <div class="t3">
              查看明细
              <Icon name="arrow" />
            </div>
          </div>
          <div class="total-box clearfix">
            <span class="t pull-left">预估增减项合计</span>
            <span class="n pull-right">{{ state.detail.amount }} 元</span>
          </div>
        </div>
      </div>
      <div class="page-item">
        <!-- <div class="info-box pt-20">
          <p class="h2">变更阶段</p>
          <p class="t2">{{ state.detail.construction_stage_name }}</p>
        </div>
        <div class="info-box pt-20">
          <p class="h2">变更类型</p>
          <p class="t2">{{ state.detail.contract_edit_type_str }}</p>
        </div> -->
        <div class="info-box pt-20">
          <p class="h2">变更项是否现场确认</p>
          <p class="t2">
            {{ state.detail.scene_confirm == 1 ? '是' : '否' }}
          </p>
        </div>
        <div class="info-box pt-20">
          <p class="h2">变更类别</p>
          <p class="t2">
            {{
              state.detail.change_type_names
                ? state.detail.change_type_names.join(',')
                : '-'
            }}
          </p>
        </div>
        <div class="info-box pt-20">
          <p class="h2">工期是否变更</p>
          <p class="t2">
            {{ state.detail.is_change_schedule == 1 ? '是' : '否' }}
          </p>
        </div>
        <div
          class="info-box pt-20"
          v-if="
            state.detail.is_change_schedule == 1 &&
            state.detail.change_node_name
          "
        >
          <p class="h2">工期变更节点</p>
          <p class="t2">
            {{ state.detail.change_node_name }}
          </p>
        </div>
        <div class="info-box pt-20" v-if="state.detail.change_schedule">
          <p class="h2">工期变更天数</p>
          <p class="t2">顺延{{ state.detail.change_schedule }}天</p>
        </div>

        <div class="info-box pt-20">
          <p class="h2">{{ state.detail.notify_customer_flag_str }}</p>
        </div>
        <div class="info-box pt-20">
          <p class="h2">备注</p>
          <p class="t2">{{ state.detail.remark || '-' }}</p>
        </div>
        <!-- 附件 -->
        <div class="info-box pt-20">
          <p class="h2">附件</p>
          <div
            class="fileList mt_10"
            v-if="state.detail.attach && state.detail.attach.length > 0"
          >
            <FileGrid :data="state.detail.attach"></FileGrid>
          </div>
          <div v-else>-</div>
        </div>
        <!-- end -->
        <div
          class="info-box pt-20"
          style="padding-bottom: 20px"
          v-if="state.detail.intend_list?.length > 0"
        >
          <div class="about">
            已绑定变更确认单：<span
              v-for="(item, index) in state.detail.intend_list"
              :key="index"
            >
              <router-link
                :to="'/contractChangeView?gId=' + item.id"
                class="blue_color underline"
              >
                {{ item.no }} </router-link
              ><span v-if="index + 1 != state.detail.intend_list.length"
                >,</span
              ></span
            >
          </div>
        </div>
      </div>
      <!-- 基装等组件 -->
      <Popup
        v-model:show="state.cardShow"
        :close-on-popstate="true"
        position="bottom"
        round
        :style="{ height: '70%' }"
      >
        <BaseTemp
          v-if="state.cardShow"
          :data="state.cardOptions"
          @reviewClose="
            () => {
              state.cardShow = false;
            }
          "
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import FileGrid from '@/components/common/FileGrid';
import { reactive, inject, onMounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import BaseTemp from '@/components/change/BasePurposeTemp';
import { Icon, Popup, Toast, Loading } from 'vant';
const props = defineProps(['id']);
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  //-变更单Id
  change_id: '',
  loading: false,
  //-详情数据
  detail: {},
  //-卡片popup
  cardShow: false,
  cardOptions: {
    optionType: 'view',
    type: '',
    base_arr: [],
    main_arr: [],
    other_arr: [],
    total: 0,
  },
  //-end
});
//-参数
onMounted(() => {
  state.change_id = props.id ? props.id : router.currentRoute.value.query.gId;
  getContractInfo();
});
//-获取变更单详情
const getContractInfo = async () => {
  state.loading = true;
  await $http
    .contractManagement_intendInfo({ id: state.change_id })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.detail = res.data;
      } else {
        Toast(res.data);
      }
    });
};
//-基装等详情
const getPopup = (type) => {
  state.cardOptions.type = type;
  state.cardShow = true;
  if (type == 'base') {
    state.cardOptions.base_arr = state.detail.base_arr;
    state.cardOptions.total = state.detail.base_amount;
  }
  if (type == 'main') {
    state.cardOptions.main_arr = state.detail.main_arr;
    state.cardOptions.total = state.detail.main_amount;
  }
  if (type == 'other') {
    state.cardOptions.other_arr = state.detail.other_arr;
    state.cardOptions.total = state.detail.other_amount;
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/change/changeView.less';
.about {
  background: rgba(2, 86, 255, 0.05);
  padding: 6px 10px;
  font-size: 13px;
  margin-top: 5px;
}
</style>
