<!--
 * @Author: Jessica
 * @Date: 2022-06-30 09:00:31
 * @LastEditTime: 2023-05-26 10:44:10
 * @Description: 
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <!-- 确认开工 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              开工日期
            </label>
            <Field
              v-model="form.list.start_day"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择开工日期"
              :rules="[
                {
                  required: true,
                  message: '请选择开工日期',
                },
              ]"
              @click="openDateType('start_day')"
            />
          </div>
          <div class="cell" v-if="state.type == 69">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工面积
            </label>
            <Field
              v-model="form.list.house_size"
              type="number"
              :rules="[
                {
                  message: '请填写正确的施工面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写施工面积"
              @blur="changeSize()"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              验收模板
            </label>
            <Field
              v-model="form.list.template_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择验收模板"
              :rules="[
                {
                  required: true,
                  message: '请选择验收模板',
                },
              ]"
              @click="state.showPicker_template = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.describe"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.files"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-模板 --->
    <Popup v-model:show="state.showPicker_template" position="bottom">
      <Picker
        :columns="state.templateList"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(v) => onConfirm(v, 'template')"
        @cancel="state.showPicker_template = false"
      />
    </Popup>
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="date"
        title="选择日期"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.start_day = '';
          }
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import patterns from '@/utils/pattern';
// 参数：客户id-id，合同id-cid，施工id-gdid，开工日期-startDate
import { inject, reactive, ref, onBeforeMount } from 'vue';
import FileUpload from '@/components/common/FileUpload';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Toast,
  Notify,
  Overlay,
  Loading,
  Picker,
  Dialog,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query?.date,
  //-全部待办类型
  dtype: router.currentRoute.value.query?.dtype,
  //-客户id
  customer_id: router.currentRoute.value.query?.id,
  //-待办来源Id
  source_id: router.currentRoute.value.query?.sid,
  //-时间控件
  showTimePicker: false,
  timeType: '',
  currentDate: new Date(),
  //-模板控件
  showPicker_template: false,
  templateList: [],
  type: router.currentRoute.value.query?.type,
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    construction_id: router.currentRoute.value.query?.gdid,
    start_day: '',
    house_size: router.currentRoute.value.query?.house_size,
    describe: '',
    template_id: '',
    template_name: '',
    files: [],
  },
});

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  await $http
    .getConstructionTemplateList({
      contract_id: router.currentRoute.value.query?.cid,
      house_size: form.list.house_size,
    })
    .then((res) => {
      if (res.code === 0) {
        state.templateList = res.data;
      } else {
        state.templateList = [];
        Toast(res.data);
      }
    });
};
//-获取参数--end
const { houseSizeReg } = patterns;
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

const changeSize = () => {
  form.list.template_id = '';
  form.list.template_name = '';

  getPublicParameter();
};
// @附件--begin
const getFileList = (file_list) => {
  form.list.files = file_list;
};
// @附件--end

//-下一步操作
const btnSumbit = () => {
  ruleFormRef.value
    .validate()
    .then(() => {
      Dialog.confirm({
        message:
          '请仔细核对开工日期：【' +
          form.list.start_day +
          '】是否正确,一旦确认，将生成施工计划，且不可更改，是否继续？',
      }).then(() => {
        goConfirm();
      });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};
const goConfirm = () => {
  state.loadingConfirm = true;
  $http
    .getConstructionStart({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;

      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          onClose: () => {
            // 哪里来回哪里去
            state.loadingConfirm = false;
            router.back();
          },
        });
      } else {
        state.loadingConfirm = false;
        Notify({ type: 'warning', message: data });
      }
    });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD');
  state.showTimePicker = false;
};

//-时间控件--end

//-选择模板--begin
const onConfirm = (value, type) => {
  form['list'][type + '_id'] = value.id;
  form['list'][type + '_name'] = value.name;
  state['showPicker_' + type] = false;
};
//-选择模板--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
