<!--
 * @Author: NanNan
 * @Date: 2024-05-11 10:24:10
 * @LastEditTime: 2024-05-29 14:43:52
 * @Description: v1.6.0新版本 获客-见面
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、桑基图 -->
        <div class="data_card_top">
          <div class="title" style="padding-bottom: 0">见面</div>
        </div>
        <div
          class="sangji-total flex-box"
          style="font-size: 13px; line-height: 40px"
        >
          <span
            style="
              flex-grow: 0;
              flex-shrink: 0;
              width: calc(50vw - 52px);
              display: flex;
            "
          >
            <em class="grey">线索:</em>
            {{
              _DATA.header?.total?.clue_total === ''
                ? '-'
                : _DATA.header?.total?.clue_total
            }}
          </span>
          <span
            style="
              flex-grow: 0;
              flex-shrink: 0;
              width: calc(50vw - 52px);
              display: flex;
            "
          >
            <em class="grey">见面:</em>
            {{
              _DATA.header?.total?.meet_total === ''
                ? '-'
                : _DATA.header?.total?.meet_total
            }}
          </span>
          <span style="flex-grow: 1; flex-shrink: 1; display: flex">
            <em class="grey">签约:</em>
            {{
              _DATA.header?.total?.sign_total === ''
                ? '-'
                : _DATA.header?.total?.sign_total
            }}
          </span>
        </div>
        <div
          ref="chartDom1"
          style="width: calc(100vw); height: 200px; overflow: hidden"
        ></div>
        <div class="border_bottom"></div>
        <!-- 二、头部 -->
        <div class="data_card_top border_bottom" v-if="_DATA.open">
          <Row style="margin-bottom: 16px">
            <Col span="8">
              <div class="grey align_center">见面目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target === '' ? '-' : _DATA.info?.target }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.actual === '' ? '-' : _DATA.info?.actual }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info?.actual > _DATA.info?.target"
              >
                <span class="bold green">{{ _DATA.info?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info?.actual < _DATA.info?.target"
              >
                <span class="bold orange">{{ _DATA.info?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.rate === '' ? '-' : _DATA.info?.rate + '%' }}
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span="8">
              <div class="grey align_center">见面率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.meet_rate === ''
                      ? '-'
                      : _DATA.info?.meet_rate + '%'
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">主场率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.court_rate === ''
                      ? '-'
                      : _DATA.info?.court_rate + '%'
                  }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 三、内容 -->
        <div class="data_card_table_bottom p0" v-if="_DATA.open">
          <!-- 1标题 -->
          <Row class="mb_15 p_l15_r10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="11" style="position: relative">
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="4">
              <div class="grey align_center">完成率</div>
            </Col>

            <Col span="4">
              <div class="grey align_center">见面率/</div>
              <div class="grey align_center">主场率</div>
            </Col>
          </Row>
          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(parentItem, parentIndex) in _DATA.list"
            :key="parentIndex"
          >
            <Row
              class="p_l15_r10"
              style="align-items: center; height: 100%"
              @click.stop="toggleAccordion(parentIndex, _DATA.activeIndex)"
            >
              <Col span="5">
                <div class="size13" style="display: flex; align-items: center">
                  <template v-if="parentItem?.sub?.length > 0">
                    <Icon
                      name="play"
                      :style="
                        parentItem?.expanded
                          ? 'transform: rotate(90deg)'
                          : 'transform: rotate(0deg)'
                      "
                      class="arrow"
                    />
                  </template>
                  <p>{{ parentItem.short_name || parentItem.name }}</p>
                </div>
              </Col>
              <Col span="11" class="chart_item">
                <!-- 进度图内容 begin-->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      actual: parentItem.actual,
                      target: parentItem.target
                        ? parentItem.target
                        : parentItem.actual,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ parentItem.target === '' ? '-' : parentItem.target }}
                  </div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      num: _DATA.middle_value,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 进度图内容 end-->
              <!-- 完成率 -->
              <Col span="4">
                <div>
                  <span class="lineheight16 align_center">
                    {{ parentItem.rate === '' ? '-' : parentItem.rate + '%' }}
                  </span>
                </div>
              </Col>
              <!-- 见面率/主场率 -->
              <Col span="4">
                <div>
                  <span class="lineheight16 align_center">
                    {{
                      parentItem.meet_rate === ''
                        ? '-'
                        : parentItem.meet_rate + '%'
                    }}
                  </span>
                </div>
                <div>
                  <span class="lineheight16 align_center">
                    {{
                      parentItem.court_rate === ''
                        ? '-'
                        : parentItem.court_rate + '%'
                    }}
                  </span>
                </div>
              </Col>
            </Row>
            <Transition name="thred-fade">
              <TempMeet
                v-if="parentItem.sub && parentItem?.expanded"
                :data="parentItem"
                :index="parentIndex"
              />
            </Transition>
          </div>
          <!-- 图例 -->
          <div class="p_15 flex_center">
            <p class="box_8 grey_bg"></p>
            <p class="ml_5">目标</p>
            <p class="box_8 red_bg ml_30"></p>
            <p class="ml_5">达标</p>
            <p class="box_8 green_bg ml_30"></p>
            <p class="ml_5">未达标</p>
            <p class="box_8 red2_bg ml_30"></p>
            <p class="ml_5">超额</p>
          </div>
        </div>
      </Skeleton>
    </div>

    <!-- 线索-见面时间 -->
    <div style="margin-top: 15px" v-if="_DATA.open">
      <TempThredMeetTime :data="props.data" />
    </div>

    <!-- 展开收起 -->
    <div
      v-if="!_DATA.loading"
      class="expend-new"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
      :class="_DATA.open ? 'grey' : 'white'"
    >
      <span>{{ _DATA.open ? '收起' : '展开查看详情' }}</span>
      <Icon
        name="play"
        style="transform: rotate(90deg)"
        size="14"
        :style="
          !_DATA.open ? 'transform: rotate(90deg)' : 'transform: rotate(-90deg)'
        "
      />
    </div>
  </div>
</template>
<script setup>
import * as echarts from 'echarts';

import TempThredMeetTime from '@/components/targetData/thredData/thredMeetTime';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  ref,
  nextTick,
} from 'vue';
import { Icon, Notify, Skeleton, Row, Col } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';

import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
import TempMeet from '@/components/targetData/thredData/collapseItem/TempMeet';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  header: {
    list: [],
    total: {},
  },
  line_values: [],
  info: {},
  list: [],
  middle_value: '',
  index: props.data.depId ? 3 : 3, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  open: false,
  collapse_active: [], //锁定
  activeIndex: [],
  activeChildIndex: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);

const chartDom1 = ref();

var option = {
  tooltip: {
    show: false,
    trigger: 'item',
    triggerOn: 'mousemove',
  },

  series: {
    type: 'sankey',
    layout: 'none',
    emphasis: {
      // focus: 'adjacency',// 禁用点击事件的高亮效果
    },
    levels: [
      {
        depth: 0,
        itemStyle: {
          color: '#fbb4ae',
        },
        lineStyle: {
          color: 'source',
          opacity: 0.2,
        },
      },
      {
        depth: 1,
        itemStyle: {
          color: '#b3cde3',
        },
        lineStyle: {
          color: 'source',
          opacity: 0.2,
        },
      },
    ],
    backgroundColor: '#000',
    layoutIterations: 64,
    nodeWidth: 10,
    nodeGap: 30,
    left: 20,
    right: 70,
    bottom: 20,
    edgeLabel: {
      show: true,
      offset: [30, 0], // 向左偏移 10 个像素
      formatter: function (params) {
        return '{black|' + params.data.percent + '%' + '}';
        // return (
        //   '{black|' +
        //   params.data.percent +
        //   '%' +
        //   '}' +
        //   '\n' +
        //   '{gray|' +
        //   '(' +
        //   params.data.day +
        //   ')' +
        //   '}'
        // );
      },
      rich: {
        black: {
          color: '#3D3D3D',
          fontWeight: 'bold',
          fontSize: 11,
          lineHeight: 13,
        },
        gray: {
          color: '#8D9091',
          fontSize: 11,
          lineHeight: 13,
        },
      },
    },
    draggable: false,
    label: {
      show: true,
      offset: [0, 0], // 向左偏移 10 个像素
      fontSize: 11, // 设置字体大小为 16 像素
      textBorderColor: 'transparent', // 设置标签文本的边框颜色为透明
      formatter: function (params) {
        var name = '';
        const nameArr = params.name.split('?');

        if (nameArr.length === 1) {
          name = '{black|' + nameArr[0] + ':' + params.value + '}';
        } else {
          name = '{black|' + nameArr[1] + ':' + params.value + '}';
        }
        return name;
      },
      rich: {
        black: {
          width: 70, // 设置宽度为 50 像素
          color: '#3D3D3D',
          fontWeight: 'bold',
          fontSize: 11,
          lineHeight: 13,
          // backgroundColor: '#eee', // 设置背景色
        },
        gray: {
          color: '#8D9091',
          fontSize: 11,
          lineHeight: 13,
        },
      },
    },
    data: [],
    links: [],
  },
};

onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载
const onLoad = () => {
  let url = $http.targetStatistic_depMeetData;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.header = data.header;
      _DATA.line_values = data.line_values;
      _DATA.info = Object.assign(_DATA.info, data.info);
      _DATA.list = data.list;
      _DATA.middle_value = data.middle_value;

      nextTick(() => {
        if (chartDom1.value) {
          formatData(data.header);
        }
      });
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

//-@ 数据变更
const color = ['#D158B0', '#B7D158', '#58C1D1', '#5874D1', '#D16858'];
const formatData = (data) => {
  const list = data?.list || [];
  var new_data = [];
  var new_link = [];
  list.forEach((element, index) => {
    // 塞入data
    var itemStyle = { color: color[index] };
    var grandfather = {
      name: element.short_name,
      value: element.clue_num,
      itemStyle,
    };
    var father = {
      name: element.short_name + '?见面',
      itemStyle,
    };
    var son = {
      name: element.short_name + '?签约',
    };
    new_data.push(grandfather, father, son);
    // 塞入links
    var father_link = {
      source: element.short_name,
      target: element.short_name + '?见面',
      value: element.meet_num,
      percent: element.meet_rate,
      day: '21天',
    };

    var son_link = {
      source: element.short_name + '?见面',
      target: element.short_name + '?签约',
      value: element.sign_num,
      percent: element.sign_rate,
      day: '8天',
    };
    new_link.push(father_link, son_link);
  });
  renderChart(new_data, new_link);
};

const renderChart = (data1, links1) => {
  if (chartDom1?.value && myChart1?.value && option1) {
    myChart1.value.setOption(option1);
  } else {
    console.log(chartDom1.value);
    var myChart1 = echarts.init(chartDom1.value);
    var option1 = {};
    Object.assign(option1, {
      ...option,
      series: { ...option.series, data: data1, links: links1 },
    });
    myChart1.setOption(option1);
  }
};

// 展开收起-操作
const toggleAccordion = (subIndex) => {
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
  console.log(_DATA.list[subIndex]);
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
