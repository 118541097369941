<!--
 * @Author: Jessica
 * @Date: 2023-06-20 15:34:11
 * @LastEditTime: 2024-05-28 16:28:50
 * @Description:V1.4.0-转化数/转化率
 * @Description:V1.6.0 转化个数
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title flex_line_between">
            转化个数
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!-- 转化目标/当年完成/完成率 -->
          <Row>
            <Col span="8">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.info.total?.target === ''
                      ? '-'
                      : _DATA.info.total?.target
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">转化数</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info.total?.actual }}
                </span>
              </div>
              <div v-if="_DATA.info.total?.gap !== ''">
                <div
                  class="flex_line flex_line_center"
                  v-if="_DATA.info.total?.actual > _DATA.info.total?.target"
                >
                  <span class="bold green"> {{ _DATA.info.total?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_line_center"
                  v-else-if="
                    _DATA.info.total?.actual < _DATA.info.total?.target
                  "
                >
                  <span class="bold orange"> {{ _DATA.info.total?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_line_center" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成率</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.info.total?.done_rate === ''
                      ? '-'
                      : _DATA.info.total?.done_rate + '%'
                  }}
                </span>
              </div>
            </Col> </Row
          ><Row class="pt_20">
            <Col span="8">
              <div class="grey align_center">转化率</div>
              <div>
                <span class="size17 bold align_center">{{
                  _DATA.info.total?.success_rate + '%'
                }}</span>
              </div>
            </Col>
            <Col span="8"> </Col>
            <Col span="8"> </Col>
          </Row>
        </div>
        <div class="data_card_table_bottom border_top">
          <Row class="mb_15">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="11" style="position: relative">
              <div class="chart_pos pl_10">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
                <div class="chart_right"></div>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="4">
              <div class="grey align_center">完成率</div>
            </Col>
            <Col span="4">
              <div class="grey align_center">转化率</div>
            </Col>
          </Row>
          <Collapse
            v-model="_DATA.collapse_active"
            ref="collapseGroupRef"
            :accordion="false"
            :border="false"
          >
            <div
              class="sub_list"
              v-for="(item, index) in _DATA.list"
              :key="index"
            >
              <CollapseItem
                :name="index"
                :is-link="false"
                v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              >
                <template #title>
                  <Row class="mb_20" style="align-items: center; height: 100%">
                    <Col span="5">
                      <div
                        class="size13"
                        style="display: flex; align-items: center"
                      >
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) == -1"
                          style="transform: rotate(0deg)"
                          class="arrow"
                        />
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) > -1"
                          style="transform: rotate(90deg)"
                          class="arrow"
                        />
                        <p>{{ item.short_name }}</p>
                      </div>
                    </Col>
                    <!-- 进度图内容 begin-->
                    <Col span="11" class="chart_item">
                      <div style="display: flex" class="pl_10">
                        <chartJD
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            actual: item.total.actual,
                            target: item.total.target
                              ? item.total.target
                              : item.total.actual,
                          }"
                        ></chartJD>
                        <div class="chart_right">
                          {{
                            item.total?.target === '' ? '-' : item.total?.target
                          }}
                        </div>
                      </div>

                      <!-- 竖线 begin-->
                      <div class="chart_pos pl_10">
                        <chartCoverLine
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            num: _DATA.middle_value,
                          }"
                        ></chartCoverLine>
                        <div class="chart_right"></div>
                      </div>
                      <!-- 竖线 end-->
                    </Col>
                    <!-- 进度图内容 end-->

                    <Col span="4">
                      <div>
                        <span class="lineheight16 align_center">{{
                          item.total?.done_rate === ''
                            ? '-'
                            : item.total?.done_rate + '%'
                        }}</span>
                      </div>
                    </Col>
                    <Col span="4">
                      <div>
                        <span class="lineheight16 align_center">{{
                          item.total.success_rate + '%'
                        }}</span>
                      </div>
                    </Col>
                  </Row>
                </template>
                <Row
                  class="mb_10 mt_10"
                  v-show="item.sub_list && item.sub_list.length > 0"
                >
                  <Col span="5">
                    <div class="grey pr_10">
                      {{ props.data.depId ? '人员' : '部门' }}
                    </div>
                  </Col>

                  <Col span="5">
                    <div class="grey align_center">目标</div>
                  </Col>
                  <Col span="6">
                    <div class="grey align_center">完成/差值</div>
                  </Col>
                  <Col span="4">
                    <div class="grey align_center">完成率</div>
                  </Col>

                  <Col span="4">
                    <div class="grey align_center">转化率</div>
                  </Col>
                </Row>
                <div
                  class="sub_item"
                  v-for="(sub, index) in item.sub_list"
                  :key="index"
                >
                  <Row
                    class="mb_20"
                    style="align-items: center; height: 100%"
                    @click="goDetail(sub)"
                  >
                    <Col span="5">
                      <div class="size13">
                        {{ sub.short_name }}
                      </div>
                    </Col>
                    <Col span="5">
                      <div>
                        <span class="align_center">
                          {{
                            sub.total?.target === '' ? '-' : sub.total?.target
                          }}
                        </span>
                      </div>
                    </Col>
                    <Col span="6">
                      <div>
                        <span class="align_center">
                          {{ sub.total.actual }}
                        </span>
                      </div>
                      <div v-if="sub.total?.gap !== ''">
                        <div
                          class="flex_line flex_line_center"
                          v-if="sub.total.actual > sub.total.target"
                        >
                          <span class="green">{{ sub.total.gap }}</span>
                          <img
                            class="icon_img"
                            :src="require('@/assets/images/target-data/up.png')"
                            alt="down"
                          />
                        </div>
                        <div
                          class="flex_line flex_line_center"
                          v-else-if="sub.total.actual < sub.total.target"
                        >
                          <span class="orange">{{ sub.total.gap }}</span>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/down.png')
                            "
                            alt="down"
                          />
                        </div>
                        <div class="flex_line flex_line_center" v-else>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/equal.png')
                            "
                            alt="down"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span="4">
                      <div>
                        <span class="align_center">
                          {{
                            sub.total?.done_rate === ''
                              ? '-'
                              : sub.total?.done_rate + '%'
                          }}
                        </span>
                      </div>
                    </Col>

                    <Col span="4">
                      <div>
                        <span class="align_center">
                          {{ sub.total.success_rate + '%' }}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CollapseItem>
            </div>
          </Collapse>
        </div>
        <div class="p_15 flex_center">
          <p class="box_8 grey_bg"></p>
          <p class="ml_5">目标</p>
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
          <p class="box_8 red2_bg ml_30"></p>
          <p class="ml_5">超额</p>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  defineExpose,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify, Collapse, CollapseItem } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  //-在全部数据||部门数据不同页面所处的位置
  index: props.data.depId ? 4 : 1,
  depIndex: 5, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  open: false,
  collapse_active: [], //锁定
  line_values: [],
  middle_value: '',
});
//-监听 切换部门-切换路由
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depConvert;
  let params = { year: props.data.yearId, column_num: 3 };
  if (props.data.depId) {
    url = $http.targetStatistic_personConvert;
    params = { department_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.info = {};
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */
const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depDesginData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};

//-规则说明--begin
const htmlTitle = '转化个数_规则';
const htmlContent = `<p>1、【尚层装饰室内设计协议】已签约【家庭居室装饰装修工程施工合同】【优选整装工程施工合同】，【已签约】状态且【财务确认收首款】或产品款+产品预收款+产品返款金额≥500000的项目，转化计数1；</p>
  <p>2、【尚层装饰配饰设计协议】产品款+产品预收款+产品返款金额≥200000，计数0.5；</p>`;
const createDanger = () => {
  emit('createDanger', 'transformTempRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
