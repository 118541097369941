<!--
 * @Author: NanNan
 * @Date: 2022-04-16 09:35:25
 * @LastEditTime: 2023-09-02 17:23:00
 * @Description: 待办事项 - 全部（企微入口）- 详细
-->
<template>
  <NavBar
    safe-area-inset-top
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
    :placeholder="true"
  >
    <template #right>
      <DropdownMenu active-color="#3E4345" class="task-DropdownMenu">
        <DropdownItem
          v-model="_DATA.form.type"
          :options="_DATA.type_list"
          @change="handleChange"
        ></DropdownItem>
      </DropdownMenu>
    </template>
  </NavBar>
  <div class="page-box">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="content">
      <!-- 待办卡片 -->
      <div
        class="task-card-same"
        v-for="(val, idx) in _DATA.list"
        :key="idx"
        @click="
          () => {
            val.status != 1 &&
              val.type == 27 &&
              router.push(
                '/taskNotice?id=' +
                  val.id +
                  '&source_id=' +
                  val.source_id +
                  '&status=' +
                  val.status
              );
          }
        "
      >
        <!-- 状态栏 -->
        <p class="h1">
          <label :class="{ done: val.status === 2, cancel: val.status === 3 }">
            <Icon name="clock-o" v-if="val.status === 1" />
            <Icon name="success" v-if="val.status === 2" />
            <Icon name="cross" v-if="val.status === 3" />
            {{ val.status_str }}
          </label>
          <span class="delay_time" v-if="val.decline_day">
            已延期
            <span class="red">{{ val.decline_day }}</span>
            天
          </span>
        </p>
        <!-- 相关事项 -->
        <div class="do-box">
          <p class="title">{{ val.type_str }}</p>
          <ol class="ol-flex">
            <li v-for="(v, i) in val.content" :key="i">
              <label>{{ i + '：' }}</label>
              <span>{{ v }}</span>
            </li>
          </ol>
        </div>
        <!-- 操作按钮 (只有待处理才展示)-->
        <div class="option-box" v-if="val.status === 1">
          <Row gutter="20">
            <Col span="12">
              <Button
                type="primary"
                size="large"
                class="button-flex"
                @click="
                  routerTo(val.type, val.source_id, {
                    todo_id: val.id,
                    isManager: val.is_customer_manager,
                    design_id: val.design_id,
                    node_id: val.node_id,
                    contract_id: val.contract_id,
                    construction_id: val.construction_id,
                    payment_id: val.payment_id,
                    customer_id: val.customer_id,
                    source_id: val.source_id,
                    node_name: val.node_name,
                    faceType: val.face_type,
                    faceMode: val.face_mode,
                    c_uid_name: val.c_uid_name,
                    status: val.status,
                    c_post_id: val.node_info ? val.node_info.c_post_id : '',
                    type_sort: val.node_info ? val.node_info.type_sort : '',
                    second_check_role_id: val.node_info
                      ? val.node_info.second_check_role_id
                      : '',
                    second_check_user_name: val.node_info
                      ? val.node_info.second_check_user_name
                      : '',
                  })
                "
              >
                <span>去处理</span>
                <em>
                  <Icon name="arrow" />
                </em>
              </Button>
            </Col>
            <Col span="12" v-if="val.type === 1 || val.type === 2">
              <Button
                size="large"
                class="button-flex"
                @click="
                  routerFailTo(val.type, val.source_id, {
                    isManager: val.is_customer_manager,
                    design_id: val.design_id,
                    node_id: val.node_id,
                    contract_id: val.contract_id,
                    payment_id: val.payment_id,
                    source_id: val.source_id,
                    node_name: val.node_name,
                    customer_name: val.customer_name,
                  })
                "
              >
                <span v-if="val.type === 1">取消见面</span>
                <span v-if="val.type === 2">取消见工地</span>
              </Button>
            </Col>
          </Row>
        </div>
        <!--V1.2.3 查看详情 -->
        <div
          class="option-box"
          v-if="val.status != 1 && (val.type == 30 || val.type == 31)"
        >
          <Row gutter="20">
            <Col span="12">
              <Button
                type="primary"
                size="large"
                class="button-flex"
                @click="
                  routerLook(val.type, val.source_id, {
                    todo_id: val.id,
                    isManager: val.is_customer_manager,
                    design_id: val.design_id,
                    node_id: val.node_id,
                    contract_id: val.contract_id,
                    payment_id: val.payment_id,
                    customer_id: val.customer_id,
                    source_id: val.source_id,
                    node_name: val.node_name,
                    faceType: val.face_type,
                    faceMode: val.face_mode,
                    c_uid_name: val.c_uid_name,
                    status: val.status,
                  })
                "
              >
                <span>去查看</span>
                <em>
                  <Icon name="arrow" />
                </em>
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <!-- 空状态 -->
      <Empty
        class="empty-fff"
        description="暂无数据"
        v-if="!loading_list && _DATA.list.length === 0"
      />
    </div>
    <!--  -->
  </div>
  <!-- v1.2.0 BPM -->
  <ActionSheet v-model:show="_DATA.BPM.show" class="dangerActionSheet">
    <BPMAction
      v-if="_DATA.BPM.show"
      :data="_DATA.BPM"
      @changeBPM="changeBPM"
      :id="_DATA.form.customer_id"
    />
  </ActionSheet>
  <!-- BPM end -->
</template>
<script setup>
import { onMounted, reactive, ref, inject } from 'vue';
import { useStore } from 'vuex';
import BPMAction from '@/components/contract/BPMAction';

import {
  DropdownMenu,
  DropdownItem,
  Notify,
  Empty,
  Row,
  Col,
  Button,
  Icon,
  NavBar,
  Overlay,
  Loading,
  ActionSheet,
} from 'vant';
import { useRouter } from 'vue-router';
const store = useStore();

const router = useRouter();
const $http = inject('$http');

const _DATA = reactive({
  form: {
    type: router.currentRoute.value.query.dtype
      ? Number(router.currentRoute.value.query.dtype)
      : 1, //类型：1 线索获取 2 见面 3 设计签约 4 产值转化 5 施工
    date: router.currentRoute.value.query.date, //日期
    customer_id: router.currentRoute.value.query.id || '',
  },
  list: [], //待办卡片 list
  type_list: [], //下拉
  //-BPM--begin
  BPM: {
    show: false,
    query: {},
  },
  //-BPM--end
});

onMounted(() => {
  loading.value = true;
  loadFunc();
});

/**
 * 接口
 */
const loading = ref(false);
const loading_list = ref(false);
const loadFunc = () => {
  loading_list.value = true;
  loading.value = true;
  $http.getTaskTodoList(_DATA.form).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.type_list = data.summary.filter(
        (item) => item.date === _DATA.form.date
      )[0].type_count_list;
      for (let i in _DATA.type_list) {
        let item = _DATA.type_list[i];
        item.text = item.type_name;
        item.value = item.type;
      }

      _DATA.list = data.list;
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
    loading.value = false;
    loading_list.value = false;
  });
};

/**
 * 操作
 */
const handleChange = (value) => {
  _DATA.form.type = value;
  window.scroll(0, 0);
  loadFunc();
};
//-@跳转 type类型 1 见面、2 确认见工地、3 跟进  8 量房 9通知验收 10确认验收 11 合同收款 12图纸设计 14验收回访 15定期回访 16施工节点（施工） 17通知验收（施工） 18确认验收（施工） 19收款（施工） 26 分配服务人员 30获客确认见面 31派单确认见面 32待设计改派
//- 24 分配项目经理 v1.3.0紧急需求
const routerTo = (type, source_id, more) => {
  _DATA.form.customer_id = more.customer_id;
  _DATA.BPM.query = {};
  switch (type) {
    case 1:
      router.push({
        path: '/confirmFace',
        query: {
          id: more.customer_id,
          sid: source_id,
          isManager: more.isManager,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          faceType: more.faceType,
          faceMode: more.faceMode,
          cname: more.customer_name,
        },
      });
      break;
    case 2:
      router.push({
        path: '/confirmMeetSite',
        query: {
          id: more.customer_id,
          sid: source_id,
          isManager: more.isManager,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
        },
      });
      break;
    case 3:
      router.push({
        path: '/documentary',
        query: {
          id: more.customer_id,
          sid: source_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
        },
      });
      break;
    case 8:
      // 量房
      router.push({
        path: '/measureHouse',
        query: {
          id: more.customer_id,
          sid: source_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          authId: 26,
        },
      });
      break;
    case 9:
      // 设计节点 - 通知验收
      router.push({
        path: '/designNotice',
        query: {
          id: more.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
          cname: more.c_uid_name,
          type_sort: more.type_sort,
          c_post_id: more.c_post_id,
          id2: more.second_check_role_id,
          cname2: more.second_check_user_name,
        },
      });
      break;
    case 10:
      // 设计节点 - 确认验收
      router.push({
        path: '/designSure',
        query: {
          id: more.customer_id,
          did: more.design_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
        },
      });
      break;
    case 11:
      // 合同收款（v1.2.0修改）
      Object.assign(_DATA.BPM.query, {
        id: more.customer_id,
        date: _DATA.form.date,
        dtype: _DATA.form.type,
        cid: more.contract_id, //合同id
        pid: more.payment_id, //款期id
        nid: more.node_id, //设计节点id
      });
      getBPM();
      break;
    case 12:
      // 图纸设计、标记完成
      router.push({
        path: '/designOver',
        query: {
          id: more.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
        },
      });
      break;
    case 14:
      // v0.3.0 验收回访
      router.push({
        path: '/construc/visitCheck',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          sid: more.source_id,
          nname: more.node_name,
        },
      });
      break;
    case 15:
      // v0.3.0 定期回访
      router.push({
        path: '/construc/visitRegular',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          sid: more.source_id,
        },
      });
      break;
    case 16:
      // v0.3.0 施工 - 标记完成
      router.push({
        path: '/construc/over',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
        },
      });
      break;
    case 17:
      // v0.3.0 施工 - 通知验收
      router.push({
        path: '/construc/notice',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
          cname: more.c_uid_name,
        },
      });
      break;
    case 18:
      // v0.3.0 施工 - 确认验收
      router.push({
        path: '/construc/sure',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
        },
      });
      break;
    case 19:
      // 合同收款（v1.2.0修改）
      Object.assign(_DATA.BPM.query, {
        id: more.customer_id,
        date: _DATA.form.date,
        dtype: _DATA.form.type,
        cid: more.contract_id, //合同id
        pid: more.payment_id, //款期id
        gdid: more.node_id, //施工节点id
      });
      getBPM();
      break;
    case 23:
      // 合同收款(v1.2.0修改)
      Object.assign(_DATA.BPM.query, {
        id: more.customer_id,
        date: _DATA.form.date,
        dtype: _DATA.form.type,
        cid: more.contract_id, //合同id
        pid: more.payment_id, //款期id
      });
      getBPM();
      break;
    case 26:
      // 设计中心经理分配服务人员
      router.push({
        path: '/designSerciverSend',
        query: {
          id: more.todo_id,
        },
      });
      break;
    case 27:
      // 设计改派通知
      router.push({
        path: '/taskNotice',
        query: {
          id: more.todo_id,
          source_id: more.source_id,
          status: more.status,
        },
      });
      break;
    case 30:
      // 获客确认见面
      router.push({
        path: '/customerMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: '',
        },
      });
      break;
    case 31:
      // 派单确认见面
      router.push({
        path: '/dispatchMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: '',
        },
      });
      break;
    case 32:
      //待设计改派
      router.push({
        path: '/applyDesignReassignment',
        query: {
          todo_id: more.todo_id,
        },
      });
      break;
    case 24:
      // 分配项目经理
      router.push({
        path: '/productManagerSend',
        query: {
          id: more.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          gdid: more.construction_id,
        },
      });
      break;
    default:
      Notify({
        type: 'warning',
        message: '企微版该功能暂未上线，请去网页版-红珊瑚操作！',
      });
      break;
  }
};

//-@跳转 type类型 1见面 2确认见工地
const routerFailTo = (type, source_id, more) => {
  console.log(type, source_id, more);
  switch (type) {
    case 1:
      router.push({
        path: '/commonRemark',
        query: {
          id: _DATA.form.customer_id,
          paramsID: more.source_id,
          title: '取消见面',
        },
      });
      break;
    case 2:
      router.push({
        path: '/commonRemark',
        query: {
          id: _DATA.form.customer_id,
          paramsID: more.source_id,
          title: '取消见工地',
        },
      });
      break;
  }
};
//v1.2.3待办查看详情
const routerLook = (type, source_id, more) => {
  switch (type) {
    case 30:
      //获客确认见面
      // 获客确认见面
      router.push({
        path: '/customerMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: 'look',
        },
      });
      break;
    case 31:
      router.push({
        path: '/dispatchMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: 'look',
        },
      });
      break;
  }
};
//-@v1.2.0 判断BPM
const getBPM = () => {
  store.dispatch('BPM/getBpmAction', _DATA.form.customer_id).then((res) => {
    if (res) {
      changeBPM();
    } else {
      _DATA.BPM.show = true;
    }
  });
};

//-@v1.2.0 BPM成功返回
const changeBPM = () => {
  router.push({
    path: '/Ccollection',
    query: { ..._DATA.BPM.query },
  });
};

//-返回
const onClosePop = () => {
  router.back();
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/task/allDetail.less';
:deep(.van-nav-bar__right) {
  right: calc(50% - 46px);
}
:deep(.van-dropdown-menu__bar--opened) {
  z-index: 0;
}
</style>
