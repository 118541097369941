<!--
 * @Author: Jessica
 * @Date: 2023-09-07 10:21:14
 * @LastEditTime: 2024-06-13 10:24:44
 * @Description: V1.4.4选择供应商
-->
<template>
  <!-- 项目成员组件 -->
  <div class="page-wrap">
    <div class="top">
      <NavBar
        safe-area-inset-top
        title="请选择供应商"
        left-arrow
        @click-left="onClosePop"
      />
      <div class="search_filed noBorder">
        <form action="/">
          <Search
            v-model="state.kw"
            show-action
            placeholder="输入供应商/大类/品牌搜索"
            @search="searchFun()"
            @clear="searchFun()"
          >
            <template #action>
              <div @click="searchFun">搜索</div>
            </template>
          </Search>
        </form>
      </div>
    </div>

    <div class="checkedList noBorder">
      <CheckboxGroup
        v-model="form.checked"
        v-if="form.list.length"
        :max="state.maxLength"
      >
        <List
          v-model:loading="state.loading"
          :finished="state.finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <Cell v-for="item in form.list" :key="item.id">
            <div class="member_list">
              <Checkbox :name="item.id" @click.stop="getClick(item)">
                <div class="member_item clearfix">
                  <!-- 图标 -->
                  <Image
                    v-if="item.logo"
                    class="pull-left imgIcon"
                    :src="host + item.logo"
                  />
                  <span v-else class="pull-left logo">
                    {{ item.name ? item.name.substr(0, 1) : '' }}
                  </span>
                  <!-- end -->

                  <div class="pull-left detail">
                    <p class="name">
                      {{ item.name }}
                    </p>
                    <p class="extra">
                      {{ item.full_name }}
                    </p>
                    <p class="extra">
                      {{ item.category_name_str }}
                    </p>
                    <p class="extra">
                      {{ item.brand_name_str }}
                    </p>
                    <p class="extra">
                      {{
                        item.responsible_name + ' / ' + item.responsible_phone
                      }}
                    </p>
                  </div>
                </div>
              </Checkbox>
            </div>
          </Cell>
        </List>
      </CheckboxGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="footer clearfix">
      <p class="pull-left">
        已选
        <span>{{ form.checked.length }}</span>
        人
      </p>
      <Button
        class="pull-right"
        type="primary"
        size="normal"
        style="width: 115px; margin: 9px 0px; border-radius: 5px"
        @click="btnSumbit()"
      >
        完成
      </Button>
    </div>
  </div>
</template>

<script setup>
import { reactive, inject, onMounted, defineProps, defineEmits } from 'vue';
import {
  Button,
  Image,
  Checkbox,
  CheckboxGroup,
  Search,
  List,
  Cell,
  Empty,
  NavBar,
  Notify,
  Toast,
} from 'vant';

const $http = inject('$http');
const host = inject('$host');
const props = defineProps(['data', 'company_id', 'node_id']);
const emit = defineEmits(['reviewOk', 'reviewClose']);
const state = reactive({
  loading: true,
  finished: false,
  kw: '',
  defaultPage: 1,
  maxLength: 10000,
  pageInfo: {
    total: 0, //总条数
    page: 1, //当前页
    count_page: 1,
    pages: 10, //每页多少条
  },
});
const form = reactive({
  checked: [],
  checkedList: props.data ? JSON.parse(JSON.stringify(props.data)) : [],
  clickChecked: [],
  clickCheckedList: props.data ? JSON.parse(JSON.stringify(props.data)) : [],
  list: [],
});
onMounted(() => {
  form.clickChecked = form.checked = form.checkedList.map((v) => {
    return v.id;
  });
  searchFun();
});
//-搜索
const searchFun = () => {
  form.list = [];
  state.finished = false;
  state.pageInfo.total = 0;
  state.pageInfo.page = 1;
  state.pageInfo.count_page = 1;
  state.pageInfo.pages = 10;
  onLoad();
};

//-获取用户列表
const onLoad = () => {
  state.loading = true;
  $http
    .witness_simpleList({
      kw: state.kw,
      node_id: props.node_id ?? '',
      company_id: props.company_id ?? '',
      page: state.pageInfo.page ? state.pageInfo.page : state.defaultPage,
      pages: state.pageInfo.pages,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.pageInfo = res.data.page;
        form.list = [...form.list, ...res.data.list];
        if (state.pageInfo.page < state.pageInfo.count_page) {
          state.pageInfo.page++;
        } else {
          state.finished = true;
        }
      } else {
        state.finished = true;
        Toast(res.msg || res.data);
      }
    });
};

const getClick = (item) => {
  if (form.clickChecked.indexOf(item.id) > -1) {
    form.clickChecked.splice(form.clickChecked.indexOf(item.id), 1);
    form.clickCheckedList = form.clickCheckedList.filter((v) => {
      return v.id != item.id;
    });
  } else {
    if (form.clickChecked.length < state.maxLength) {
      form.clickChecked.push(item.id);
      form.clickCheckedList.push(item);
    } else {
      Notify({
        type: 'warning',
        message: `您最多只能选择${state.maxLength}位项目成员，目前已超过限制啦`,
      });
    }
  }
};
//-确认操作
const btnSumbit = () => {
  form.checkedList = JSON.parse(JSON.stringify(form.clickCheckedList));
  emit('reviewOk', form.checkedList);
};

//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
.page-wrap {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  .top {
    width: 100%;
    position: fixed;
    z-index: 9;
    .search_filed {
      width: 100%;
      background: #fff;
      padding: 10px 20px 0px;
      box-sizing: border-box;
      :deep(.van-search) {
        border-radius: 20px;
        border: 0px;
        padding: 0 5px 0 15px;
        background: #f5f5f5;
      }
      :deep(.van-search .van-search__content) {
        border: 0;
        padding-left: 0;
        background: #f5f5f5;
      }
      :deep(.van-search .van-search__action) {
        border-radius: 24px;
        background: #fff;
        font-size: 13px;
        text-align: center;
        padding: 0 15px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .footer {
    width: 100%;
    height: 64px;
    position: fixed;
    background: #fff;
    z-index: 9;
    left: 0px;
    bottom: 0;
    box-shadow: 0px -1px 0px rgba(62, 67, 69, 0.08);
    padding: 0px 20px;
    box-sizing: border-box;
    > p {
      line-height: 64px;
      width: calc(100% - 150px);
      > span {
        color: #3471ff;
      }
    }
  }

  //-编辑
  .checkedList {
    position: relative;
    top: 100px;
    padding-bottom: 64px;
    .member_list {
      > div {
        padding: 16px 10px 10px;
        .member_item {
          width: calc(100vw - 56px);
          padding: 0px 0px 0px 10px;
          border-bottom: 0;
          .detail {
            width: calc(100% - 88px);
          }
          .imgIcon {
            width: 40px;
            height: 40px;
            margin-right: 11px;
          }
          .logo {
            width: 40px;
            height: 40px;
            font-size: 15px;
            font-weight: bold;
            margin-right: 11px;
            color: #0256ff;
            background: #e5eeff;
            text-align: center;
            line-height: 40px;
          }
          .nameIcon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 11px;
            line-height: 40px;
            text-align: center;
            background: #ebf2fe;
            color: #3471ff;
          }
          .detail {
            width: calc(100% - 66px);
            .name {
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #3e4345;
            }
            .extra {
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
              color: #a6a6a6;
            }
          }
        }
      }
    }
  }
}
</style>
