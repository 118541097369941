<!--
 * @Author: gest
 * @Date: 2022-08-20 15:11:00
 * @LastEditTime: 2024-05-28 16:33:02
 * @Description: 申请设计改派/网销专员，网销经理重新申请
 * 入口:
 *     1- 客户-更多变更设计团队
 *     2- 待办-待变更团队
 *     3- 我的申请-带变更团队-重新申请（网销专员）
 * --------------- 分割线 ---------------
 * v1.3.5 全局更改 - 变更设计团队
 * v1.3.8 根据回单结果用户可以在待办选择是否继续派单
 * v1.4.1 派单信息
 * v1.4.7plus smart 派单方式=='自动轮派'，展示排除设计中心选项
 * v1.4.8 smart 派单方式 === 492客户制定设计师中心，上传附件必填最少1个最多3个
 * v1.5.1 smart 模块引入-可指派设计师中心
 * v1.6.1 smart 模块引入-跟进情况
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase designReassignment">
      <div class="cellInfo">
        <div class="item">
          <div class="left">客户编号：</div>
          <span class="right">{{ state.customer_info.customer_no }}</span>
        </div>
        <div class="item">
          <div class="left">客户姓名：</div>
          <span class="right">{{ state.customer_info.customer_name }}</span>
        </div>
        <div class="item">
          <div class="left">楼盘信息：</div>
          <span class="right">{{ state.customer_info.floor_name }}</span>
        </div>
        <div class="item">
          <div class="left">原设计中心：</div>
          <span class="right">
            {{ state.customer_info.design_center_name }}
          </span>
        </div>
      </div>
      <Form ref="ruleFormRef" class="form_main">
        <CellGroup class="form_cell">
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更原因
            </label>
            <Field
              v-model="form.list.reassignment_reason_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择变更原因"
              :rules="[
                {
                  required: true,
                  message: '请选择变更原因',
                },
              ]"
              :disabled="state.type == 2 || !!state.todo_id"
              @click="
                () => {
                  if (state.type == 2 || !!state.todo_id) {
                    return;
                  }
                  state.showPicker_reassignment_reason = true;
                }
              "
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="500"
              :placeholder="state.type == 2 ? '' : '此备注审批人可见'"
              :disabled="state.type == 2"
            />
          </div>
        </CellGroup>
        <template
          v-if="state.contract_progress && state.contract_progress.length > 0"
        >
          <div class="design_list">
            <div class="title">变更后设计进度</div>
            <div
              class="list"
              v-for="(item, index) in state.contract_progress"
              :key="index"
            >
              <div class="item">
                <div class="left">合同编号：</div>
                <div class="right">
                  {{ item.contract_no ? item.contract_no : '-' }}
                </div>
              </div>
              <div class="item">
                <div class="left">合同状态：</div>
                <div class="right">
                  {{
                    item.contract_status_name ? item.contract_status_name : '-'
                  }}
                </div>
              </div>
              <div class="item">
                <div class="left">设计编号：</div>
                <div class="right">
                  {{ item.design_no ? item.design_no : '-' }}
                </div>
              </div>
              <div class="item">
                <div class="left">目前进度：</div>
                <div class="right">
                  {{ item.progress_name ? item.progress_name : '-' }}
                </div>
              </div>
              <div v-if="item.design_no">
                <div class="info">变更后阶段调整</div>
                <Field
                  v-model="item.design_reassignment_stage_name"
                  right-icon="arrow-down"
                  readonly
                  name="picker"
                  placeholder="请选择"
                  :disabled="state.type == 2"
                  @click="
                    () => {
                      if (state.type != 2) {
                        selectClick(item, index);
                      }
                    }
                  "
                />
              </div>
            </div>
          </div>
        </template>

        <CellGroup
          style="border-top: 1px solid #eeeeee; padding: 20px 0"
          v-if="form.list?.is_continue_dispatch == 2"
        >
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              是否继续派单
            </label>
            <Field
              v-model="form.list.is_continue_dispatch_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择是否继续派单',
                },
              ]"
              placeholder="请选择是否继续派单"
              @click="state.is_continue_dispatch_showPicker = true"
            />
          </div>
        </CellGroup>

        <CellGroup
          style="border-top: 1px solid #eeeeee; padding: 20px 0"
          v-else
        >
          <div
            class="cell"
            v-if="state.todo_id && form.list.reassignment_reason == 346"
          >
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              是否继续派单
            </label>
            <Field
              v-model="form.list.is_continue_dispatch_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择是否继续派单',
                },
              ]"
              placeholder="请选择是否继续派单"
              @click="state.is_continue_dispatch_showPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计见面时间
            </label>
            <Field
              v-model="form.list.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择预计见面时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计见面时间',
                },
              ]"
              @click="openDateType('face_time')"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面方式
            </label>
            <Field
              v-model="form.list.face_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面方式',
                },
              ]"
              placeholder="请选择见面方式"
              @click="state.showPickerMode = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              派单级别
            </label>
            <Field
              v-model="form.list.dispatch_level_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择派单级别',
                },
              ]"
              placeholder="请选择派单级别"
              @click="state.dispatch_level_showPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">派单备注</label>
            <Field
              v-model="form.list.dispatch_remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注设计中心可见"
              show-word-limit
            />
          </div>
          <!-- v1.6.1 跟进情况 -->
          <TempSendFollow
            v-if="
              !state.loadingConfirm &&
              state.info?.is_show_appoint_designer_info == 1
            "
            :customerID="
              router?.currentRoute?.value?.query?.id ||
              state.customerObj?.customer_id
            "
            :taskID="form?.list?.task_id"
          />
          <!-- v1.5.1 可指派设计师中心 -->
          <TempAppointDesigner
            v-if="
              !state.loadingConfirm &&
              state.info?.is_show_appoint_designer_info == 1
            "
            :customerID="
              router?.currentRoute?.value?.query?.id ||
              state.customerObj?.customer_id
            "
            :taskID="form?.list?.task_id"
          />
          <!-- end -->
          <!-- <div class="cell" v-if="!(state.type == 2 || !!state.todo_id)"> -->
          <div class="cell" v-if="!(state.type == 2 && state.is_reapply)">
            <label class="form_title">派单设计中心</label>
            <Field
              v-model="form.list.design_center_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择派单设计中心"
              @click="state.showPicker_design_select_center_id = true"
            />
          </div>
          <!-- end -->
          <div v-if="state.is_reapply && state.type == 2">
            <div class="cell">
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                派单原因
              </label>
              <Field
                v-model="form.list.redispatch_reason_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                :rules="[
                  {
                    required: true,
                    message: '请选择派单原因',
                  },
                ]"
                placeholder="请选择派单原因"
                @click="state.redispatch_reason_showPicker = true"
              />
            </div>
            <div class="cell">
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                派单方式
              </label>
              <Field
                v-model="form.list.dispatch_mode_id_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                :rules="[
                  {
                    required: true,
                    message: '请选择派单方式',
                  },
                ]"
                placeholder="请选择派单方式"
                @click="state.showPicker_dispatch_mode_id = true"
              />
            </div>
            <!-- v1.4.7plus 派单方式=='自动轮派'，展示排除设计中心选项 -->
            <div
              class="cell"
              v-if="
                form.list.dispatch_mode_id == 336 &&
                state.info?.reject_design_center_switch == 1
              "
            >
              <label class="form_title">排除设计中心</label>
              <Field
                v-model="form.list.reject_design_center_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="请选择排除设计中心，最多选择四个"
                @click="state.reject_design_picker.visible = true"
              />
            </div>
            <!-- end(v1.4.7plus) -->
            <div
              class="cell"
              v-if="[335, 411, 492].indexOf(form.list.dispatch_mode_id) > -1"
            >
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                {{
                  form.list.dispatch_mode_id == 411
                    ? '优先轮派'
                    : '派单设计中心'
                }}
                <Icon
                  name="question"
                  color="#FFC300"
                  size="16"
                  @click.stop="showDialog"
                  v-if="form.list.dispatch_mode_id == 411"
                />
              </label>
              <Field
                v-model="form.list.design_center_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                :rules="[
                  {
                    required: true,
                    message: '请选择设计中心',
                  },
                ]"
                placeholder="请选择设计中心"
                @click="state.showPicker_design_select_center_id = true"
                v-if="form.list.dispatch_mode_id == 492"
              />
              <Field
                v-model="form.list.design_center_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                v-else
                :rules="[
                  {
                    required: true,
                    message: '请选择设计中心',
                  },
                ]"
                placeholder="请选择设计中心"
                @click="state.showPicker_design_center_id = true"
              />
            </div>
          </div>
        </CellGroup>
      </Form>
      <div
        style="padding: 0 24px 0 23px; margin-top: -20px"
        v-if="state.customerObj?.customer_id"
      >
        <TempSendForm
          ref="TempSendFormRef"
          :customerObj="state.customerObj"
          :data="form?.list"
          :cityList="state.city_list"
        />
      </div>
    </div>
    <!-- v1.4.1 派单信息 -->
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-变更原因--->
    <Popup
      v-model:show="state.showPicker_reassignment_reason"
      position="bottom"
    >
      <Picker
        :columns="state.reassignment_reason_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'reassignment_reason')"
        @cancel="state.showPicker_reassignment_reason = false"
      />
    </Popup>

    <!--picker 变更后阶段 --->
    <Popup v-model:show="state.showPicker_designNode" position="bottom">
      <Picker
        :columns="state.pickerItem.design_reassignment_stage"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'designerNode')"
        @cancel="state.showPicker_designNode = false"
      />
    </Popup>
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="state.minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>
    <!--picker -派单级别-->
    <Popup v-model:show="state.dispatch_level_showPicker" position="bottom">
      <Picker
        :columns="state.dispatch_level_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_level')"
        @cancel="state.dispatch_level_showPicker = false"
      />
    </Popup>
    <!--picker -见面方式-->
    <Popup v-model:show="state.showPickerMode" position="bottom">
      <Picker
        :columns="state.face_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'face_mode')"
        @cancel="state.showPickerMode = false"
      />
    </Popup>
    <!--picker -派单原因-->
    <Popup v-model:show="state.redispatch_reason_showPicker" position="bottom">
      <Picker
        :columns="state.redispatch_reason_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'redispatch_reason')"
        @cancel="state.redispatch_reason_showPicker = false"
        :default-index="
          state.design_select_center_list.findIndex((v) => {
            return v.id == form.list.design_id;
          })
        "
      />
    </Popup>
    <!--picker -派单方式-->
    <Popup v-model:show="state.showPicker_dispatch_mode_id" position="bottom">
      <Picker
        :columns="state.dispatch_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_mode_id')"
        @cancel="state.showPicker_dispatch_mode_id = false"
      />
    </Popup>
    <!--picker -派单设计中心-->
    <Popup v-model:show="state.showPicker_design_center_id" position="bottom">
      <Picker
        :columns="state.design_center_id_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'design_center_id')"
        @cancel="state.showPicker_design_center_id = false"
      />
    </Popup>
    <!--picker -派单设计中心2-->
    <Popup
      v-model:show="state.showPicker_design_select_center_id"
      position="bottom"
    >
      <Picker
        cancel-button-text="清除"
        :columns="state.design_select_center_list"
        :columns-field-names="customFieldName2"
        @confirm="(v) => onConfirm(v, 'design_select_center_id')"
        @cancel="onClear('design_select_center_id')"
        :default-index="
          state.design_select_center_list.findIndex((v) => {
            return v.design_id == form.list.design_id;
          })
        "
      />
    </Popup>
    <!-- picker -是否继续派单 -->
    <Popup
      v-model:show="state.is_continue_dispatch_showPicker"
      position="bottom"
    >
      <Picker
        :columns="state.is_continue_dispatch_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'is_continue_dispatch')"
        @cancel="state.is_continue_dispatch_showPicker = false"
      />
    </Popup>
    <!-- picker-排除设计中心 -->
    <Popup
      v-model:show="state.reject_design_picker.visible"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempMultiple
        v-if="state.reject_design_picker.visible"
        :data="state.reject_design_picker"
        @reviewClose="
          () => {
            state.reject_design_picker.visible = false;
          }
        "
        @reviewOk="reviewOk_design"
      />
    </Popup>
    <!-- end -->
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  DatetimePicker,
  Icon,
  Dialog,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
import TempSendForm from '@/components/moreOperate/TempSendForm.vue';
import TempMultiple from '@/components/clue/TempMultiple';
import TempAppointDesigner from '@/components/moreOperate/TempAppointDesigner.vue';
import TempSendFollow from '@/components/moreOperate/TempSendFollow.vue';

const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  todo_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.todo_id
    : null,
  info: {
    is_show_appoint_designer_info: 1, //1 显示 2不显示(针对于跟进情况模版、可指派设计中心模版)
  },
  contract_progress: [],
  customer_info: {},
  design_center: [],
  showPicker_designNode: false,
  pickerItem: '',
  pickerIndex: null,
  is_reapply: router.currentRoute.value.query.is_reapply == 0 ? true : false,
  type: router.currentRoute.value.query.type
    ? router.currentRoute.value.query.type
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  //-picker
  dispatch_level_showPicker: false,
  showPickerMode: false,
  redispatch_reason_showPicker: false,
  dispatch_level_list: [],
  face_mode_list: [],
  redispatch_reason_list: [],
  design_center_id_list: [],
  showPicker_reassignment_reason: false,
  reassignment_reason_list: [], //变更原因
  showPicker_design_center_id: false,
  dispatch_mode_list: [],
  showPicker_dispatch_mode_id: false,
  is_continue_dispatch_list: [], //是否继续派单list
  is_continue_dispatch_showPicker: false, //是否继续派单
  city_list: [], //省市区

  customerObj: {
    customer_id: '',
    customer_name: '',
  },
  //-排除设计中心-picker
  reject_design_picker: {
    visible: false,
    title: '选择排除设计中心',
    max: 4,
    list: [], //排除设计中心list
    checked: [],
    checkedItems: [],
  },
  //v1.5.1 可指派设计师中心
  design_select_center_list: [],
  showPicker_design_select_center_id: false, //v1.5.1 申请设计派单 派单设计中心-
});
//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    task_id: router.currentRoute.value.query.task_id
      ? router.currentRoute.value.query.task_id
      : '',
    reassignment_reason: '',
    reassignment_reason_name: '',
    designs: [],
    face_time: '',
    dispatch_level: '',
    dispatch_level_name: '',
    face_mode: '',
    face_mode_name: '',
    remark: '',
    redispatch_reason: '',
    redispatch_reason_name: '',
    design_id: '', //v1.5.1 设计师id
    design_center_id: '',
    design_center_name: '',
    dispatch_remark: '',
    dispatch_mode_id: '',
    dispatch_mode_id_name: '',
    is_continue_dispatch: 1,
    is_continue_dispatch_name: '是',
    reject_design_center_id: [], //排除设计中心
    reject_design_center_name: '', //排除设计中心，前端拼接字符串
  },
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const customFieldName2 = {
  text: 'text',
  value: 'design_id',
  disabled: 'disabled',
};
//点击选择节点阶段
const selectClick = (item, index) => {
  state.showPicker_designNode = true;
  state.pickerItem = item;
  state.pickerIndex = index;
};
const onConfirm = (value, type) => {
  if (type == 'reassignment_reason') {
    form.list.reassignment_reason = value.id;
    form.list.reassignment_reason_name = value.name;
    state.showPicker_reassignment_reason = false;
  }
  if (type == 'design_center_id') {
    form.list.design_center_id = value.id;
    form.list.design_center_name = value.name;
    state.showPicker_design_center_id = false;
  }
  if (type == 'design_select_center_id') {
    if (!value.disabled) {
      form.list.design_center_id = value?.design_center_id;
      form.list.design_center_name = value?.text;
      form.list.design_id = value?.design_id;
    } else {
      form.list.design_center_id = '';
      form.list.design_center_name = '';
      form.list.design_id = '';
    }
    state.showPicker_design_select_center_id = false;
  }
  if (type == 'is_continue_dispatch') {
    form.list.is_continue_dispatch = value.id;
    form.list.is_continue_dispatch_name = value.name;
    state.is_continue_dispatch_showPicker = false;
  }
  if (type == 'face_mode') {
    form.list.face_mode = value.id;
    form.list.face_mode_name = value.name;
    state.showPickerMode = false;
  }
  if (type == 'dispatch_level') {
    form.list.dispatch_level = value.id;
    form.list.dispatch_level_name = value.name;
    state.dispatch_level_showPicker = false;
  }

  if (type == 'redispatch_reason') {
    form.list.redispatch_reason = value.id;
    form.list.redispatch_reason_name = value.name;
    state.redispatch_reason_showPicker = false;
  }
  if (type == 'designerNode') {
    if (value) {
      state.contract_progress[
        state.pickerIndex
      ].design_reassignment_stage_name = value.name;
      if (form.list.designs[state.pickerIndex]) {
        form.list.designs[state.pickerIndex].design_id =
          state.pickerItem.design_id;
        form.list.designs[state.pickerIndex].design_reassignment_stage =
          value.id;
      }
    }
    state.showPicker_designNode = false;
  }
  if (type == 'dispatch_mode_id') {
    form.list.dispatch_mode_id = value.id;
    form.list.dispatch_mode_id_name = value.name;
    state.showPicker_dispatch_mode_id = false;
    form.list.design_center_id = '';
    form.list.design_center_name = '';
  }
};

const onClear = (type) => {
  if (type === 'design_select_center_id') {
    form.list.design_id = '';
    form.list.design_center_name = '';
    form.list.design_center_id = '';
    state.showPicker_design_select_center_id = false;
  }
};

//-获取参数--begin
onBeforeMount(() => {
  state.loadingConfirm = true;
  getParmarsList();
});

//-获取参数
const getParmarsList = async () => {
  await $http.getDispatchParams().then((res) => {
    if (res.code === 0) {
      state.face_mode_list = res.data.face_mode;
      state.dispatch_level_list = res.data.dispatch_level;
      state.dispatch_mode_list = res.data.dispatch_mode;
      state.is_continue_dispatch_list = res.data.is_continue_dispatch;
      state.city_list = res.data.city;
      state.reject_design_picker.list = res.data.design_center;
      if (!state.is_reapply) {
        state.reassignment_reason_list = res.data.manual_redispatch_reason;
        if (state.todo_id) {
          state.reassignment_reason_list = res.data.system_redispatch_reason;
        }
      }
      getPublicParameter();
    } else {
      state.loadingConfirm = false;
      state.face_mode_list = [];
      state.dispatch_level_list = [];
      state.dispatch_mode_list = [];
    }
  });
};
const getPublicParameter = async () => {
  let paramsInfo = { customer_id: state.customer_id };
  if (state.todo_id) {
    paramsInfo = { todo_id: state.todo_id };
  }
  await $http.designReassignmentParam(paramsInfo).then((res) => {
    if (res.code === 0) {
      state.contract_progress = res.data.contract_progress;
      state.customer_info = res.data.customer_info;
      state.customerObj.customer_id = res.data?.customer_info?.customer_id;
      state.customerObj.customer_name = res.data?.customer_info?.customer_name;
      //变更后阶段调整
      if (state.contract_progress && state.contract_progress.length > 0) {
        let designList = [];
        state.contract_progress.forEach((item) => {
          if (item.design_no) {
            item.design_reassignment_stage_name =
              item.design_reassignment_stage[0].name;
            item.design_reassignment_stage_id =
              item.design_reassignment_stage[0].id;
            designList.push({
              design_id: item.design_id,
              design_reassignment_stage: item.design_reassignment_stage_id,
            });
          }
        });
        form.list.designs = designList;
      }
      //待办
      if (state.todo_id) {
        form.list.face_time = res.data.face_time;
        form.list.face_mode = res.data.face_mode;
        form.list.dispatch_level = res.data.dispatch_level;
        form.list.face_mode_name = res.data.face_mode_name;
        form.list.is_continue_dispatch = res.data?.is_continue_dispatch || 1;
        form.list.is_continue_dispatch_name =
          res.data?.is_continue_dispatch_name || '是';
        form.list.dispatch_level_name = res.data.dispatch_level_name;
        form.list.dispatch_remark = res.data.dispatch_remark;
        form.list.reassignment_reason = res.data.redispatch_reason;
        form.list.reassignment_reason_name = res.data.redispatch_reason_name;
        form.list.remark = res.data.network_remark;
        form.list.dispatch_mode_id = res.data.default_dispatch_model;
        form.list.dispatch_mode_id_name = res.data.default_dispatch_model_name;
        form.list.design_id = res.data.default_design_id || res.data.design_id;
        form.list.design_center_id =
          res.data.default_design_center_id || res.data.design_center_id;
        form.list.design_center_name =
          res.data.default_design_center_name || res.data.design_center_name;
        console.log('代办', res.data);
      }
      //重新申请
      if (state.is_reapply) {
        if (state.type == 2) {
          getDispatchList();
        }
        getDetailInfo();
      }
      state.loadingConfirm = false;
      getSelectDesignParam(res.data?.customer_info?.customer_id);
    } else {
      state.loadingConfirm = false;
      Toast(res.data);
    }
  });
};
//设计中心
const getDispatchList = () => {
  $http.customerAssignDesignCenterParam().then((res) => {
    state.loading = false;
    if (res.code === 0) {
      state.design_center_id_list = res.data.design_center;
    } else {
      state.design_center_id_list = [];
    }
  });
};

// -- v1.5.1 动态派单设计中心
const getSelectDesignParam = async (customer_id, dispatch_mode) => {
  await $http
    .public_assignSelectDesignParam({
      customer_id,
      dispatch_mode,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data.length > 0) {
          const newData = data.map((item) => ({
            ...item,
            disabled: item.status === 2,
          }));
          state.design_select_center_list = newData || [];
        }
      } else {
        Toast(data);
      }
    });
};
//-获取参数--end
//获取审批详情
const getDetailInfo = () => {
  state.loadingConfirm = true;
  $http.getTaskApplyInfo({ id: form.list.task_id }).then((res) => {
    state.loading = false;
    if (res.code === 0) {
      state.info = res.data;
      state.customerObj.customer_id = res.data?.customer_id;
      state.customerObj.customer_name = res.data?.customer_name;

      state.customer_info.design_center_name = res.data.old_design_center_name;
      state.customer_info.floor_name = res.data.floor_name;
      form.list.task_id = res.data.id;
      form.list.reassignment_reason = Number(res.data.redispatch_reason);
      form.list.reassignment_reason_name = res.data.redispatch_reason_name;

      form.list.face_time = res.data.face_time;
      form.list.face_mode = res.data.face_mode;
      form.list.face_mode_name = res.data.face_mode_name;
      form.list.is_continue_dispatch = res.data.is_continue_dispatch || 1;
      form.list.is_continue_dispatch_name =
        res.data.is_continue_dispatch_name || '是';
      form.list.dispatch_level = res.data.dispatch_level;
      form.list.dispatch_level_name = res.data.dispatch_level_name;
      form.list.remark = res.data.operator_remark;
      form.list.dispatch_remark = res.data.dispatch_remark;
      form.list.design_id = res.data.default_design_id;
      form.list.design_center_id = res.data.default_design_center_id;
      form.list.design_center_name = res.data.default_design_center_name;
      form.list.dispatch_mode_id = res.data.default_dispatch_model;
      form.list.dispatch_mode_id_name = res.data.default_dispatch_model_name;
      //变更原因
      state.reassignment_reason_list = res.data.approve_redispatch_reason_list;
      if (state.type == 2) {
        state.redispatch_reason_list = res.data.redispatch_reason_list;
        if (
          state.redispatch_reason_list &&
          state.redispatch_reason_list.length == 1
        ) {
          form.list.redispatch_reason = state.redispatch_reason_list[0].id;
        }
      }
      // v1.4.1
      state.customerObj.customer_id = res.data.customer_id;
      state.customerObj.customer_name = res.data?.customer_name || '';
      if (state.dispatch_mode_list) {
        const filteredData = state.dispatch_mode_list.map((item) => ({
          ...item,
          disabled: res.data.disable_dispatch_model.indexOf(item.id) > -1,
        }));
        state.dispatch_mode_list = filteredData;
      }
    } else {
      Notify({ type: 'warning', message: res.data });
    }
  });
};
//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      //参数
      form.list.redispatch_reason = form.list.reassignment_reason;
      let params = { ...form.list, customer_id: state.customer_id };
      if (state.todo_id) {
        params = { ...form.list, todo_id: state.todo_id };
      }
      let url = $http.getApplyDesignReassignment;
      if (state.type == 15 && state.is_reapply) {
        url = $http.approveReapplyNetwork;
        params = { ...form.list };
      } else if (state.type == 2 && state.is_reapply) {
        url = $http.approveReapply;
        //分配设计中心重新申请
        form.list.remark = form.list.dispatch_remark;
        params = { ...form.list };
      }

      url(params).then((res) => {
        const { code, data } = res;
        state.loadingConfirm = false;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              router.back();
            },
          });
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end

//-优先轮派提示文字
const showDialog = () => {
  Dialog.confirm({
    title: '注意',
    message: `优先轮派仅优先派单一次，超时未接单时记录跳单。`,
    messageAlign: 'left',
    allowHtml: true,
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#006BFF',
  });
};

// -- v1.4.7 设计中心
const reviewOk_design = (checked, checkedItems) => {
  state.reject_design_picker.checked = checked;
  state.reject_design_picker.checkedItems = checkedItems;
  state.reject_design_picker.visible = false;
  form.list.reject_design_center_id = checked;
  const names = checkedItems.map((item) => item.name);
  form.list.reject_design_center_name = names.join(',');
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
@import '@/assets/styles/moreOperate/designReassignment.less';
.page-wrap {
  padding: 0;
}
</style>
