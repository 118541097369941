<!--
 * @Author: gest
 * @Date:2023-09-12 14:24:24
 * @LastEditTime: 2023-10-27 11:24:51
 * @Description: v1.4.5 交底记录单 （预交底记录单，二次交底单）
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div
          class="field_item"
          v-for="(item, index) in form.info.users"
          :key="index"
        >
          <div class="flex_between">
            <div class="grey_color">{{ item.role_name }}</div>
            <div class="">{{ item.name || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">记录单内容</div>
          <div>
            {{ form.info.content || '-' }}
          </div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">交底日期</div>
            <div>{{ form.info.accept_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item" v-if="props.sub_type == 26">
          <div class="flex_between">
            <div class="grey_color">特定项验收项</div>
            <div>
              {{
                form.info.special_item
                  ? form.info.special_item == 1
                    ? '有'
                    : '无'
                  : '-'
              }}
            </div>
          </div>
        </div>
        <div class="field_item" v-if="form.info.special_item == 1">
          <div>
            {{ form.info.special_item_remark || '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);
const props = defineProps(['data', 'sub_type']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
