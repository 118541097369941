<!--
 * @Author: NanNan
 * @Date: 2024-05-23 10:35:12
 * @LastEditTime: 2024-05-29 14:51:33
 * @Description: v1.6.1 smart 客资表：新增跟进 - pop
-->
<template>
  <div class="page-box">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部信息 -->
    <div class="content-flex">
      <div class="title-box">
        <div class="left">
          <p class="title">跟进记录</p>
          <p class="desc">
            <span class="width25">
              {{ clueInfo?.name || '姓名未知' }}
            </span>
            <em>-</em>
            <span class="width35">
              {{ clueInfo?.floor_name || '楼盘未知' }}
            </span>
            <em class="width25">-</em>
            <span>
              {{ clueInfo?.size === '' ? '面积未知' : clueInfo?.size + '㎡' }}
            </span>
          </p>
        </div>
        <Icon name="cross" :size="16" color="#000" @click.stop="cancel" />
      </div>
      <!-- 内容信息 -->
      <div class="formBase" style="background: #fff">
        <Form
          class="form-box"
          ref="formRef"
          @failed="onFailed"
          @submit="onSubmit"
        >
          <p class="label-name">
            <em>*</em>
            本次跟进
          </p>
          <div class="active-box">
            客户主动
            <Checkbox v-model="form.is_customer_active" />
          </div>

          <Field
            v-model="formValue.communication_type_id"
            readonly
            right-icon="arrow-down"
            placeholder="选择联系方式"
            class="mb15"
            @click="action.communication_type_id = true"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请选择联系方式',
              },
            ]"
          />

          <Field
            v-model="form.record_time"
            name="picker"
            readonly
            placeholder="选择跟进时间"
            right-icon="underway-o"
            @click="() => openDateType('record_time')"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请选择跟进时间',
              },
            ]"
          />

          <!-- v1.4.9 新增线索等级 -->
          <div v-if="clueInfo.source_type == 1 || clueInfo.source_type == 2">
            <p class="label-name">
              <em>*</em>
              线索等级
              <Icon
                name="question"
                color="#FFAE4E"
                @click.stop="getInstructions"
              />
            </p>
            <Field
              v-model="formValue.level"
              right-icon="arrow-down"
              readonly
              placeholder="选择线索等级"
              @click="action.level = true"
              :rules="[
                {
                  required: true,
                  trigger: 'onSubmit',
                  message: '请选择线索等级',
                },
              ]"
            />
          </div>
          <!-- v1.4.9end -->
          <p class="label-name">
            <em>*</em>
            本次跟进内容
          </p>
          <Field
            v-model="form.content"
            placeholder="填写本次跟进内容..."
            rows="3"
            autosize
            type="textarea"
            maxlength="300"
            show-word-limit
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请填写本次跟进内容',
              },
            ]"
          />

          <p class="label-name">
            <em>*</em>
            下次跟进
          </p>
          <Field
            v-model="formValue.next_follow_type"
            readonly
            right-icon="arrow-down"
            placeholder="选择跟进方式"
            @click="action.next_follow_type = true"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请选择跟进方式',
              },
            ]"
          />

          <Field
            v-model="form.next_record_time"
            name="picker"
            readonly
            placeholder="选择下次跟进时间"
            right-icon="underway-o"
            @click="() => openDateType('next_record_time')"
            class="mt15"
            :rules="[
              {
                required: true,
                trigger: 'onSubmit',
                message: '请选择下次跟进时间',
              },
            ]"
            v-if="!action.next_record_time_disabled"
          />

          <div class="stick-box">
            <Button
              type="primary"
              size="large"
              native-type="submit"
              :loading="loadingBtn"
            >
              确定
            </Button>
          </div>
        </Form>
      </div>
    </div>
    <!-- 联系方式 -->
    <Popup v-model:show="action.communication_type_id" position="bottom">
      <Picker
        :columns="options.communication_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="(value) => handelChange(value, 'communication_type_id')"
        @cancel="action.communication_type_id = false"
        :default-index="
          options.communication_type.findIndex((v) => {
            return v.id == form.communication_type_id;
          })
        "
      />
    </Popup>
    <!-- 跟进时间 年月日时分-->
    <Popup v-model:show="action.record_time" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="datetime"
        title="跟进时间"
        :min-date="minDate"
        @confirm="(value) => handelChange(value, 'record_time')"
        @cancel="action.record_time = false"
        :formatter="formatterDatetime"
      />
    </Popup>
    <!-- 下次跟进方式 -->
    <Popup v-model:show="action.next_follow_type" position="bottom">
      <Picker
        :columns="options.next_follow_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="(value) => handelChange(value, 'next_follow_type')"
        @cancel="action.next_follow_type = false"
        :default-index="
          options.next_follow_type.findIndex((v) => {
            return v.id == form.next_follow_type;
          })
        "
      />
    </Popup>
    <!-- 下次跟进时间 年月日时分-->
    <Popup v-model:show="action.next_record_time" position="bottom">
      <DatetimePicker
        v-model="currentSevenDate"
        type="datetime"
        title="下次跟进时间"
        :min-date="minDate"
        @confirm="(value) => handelChange(value, 'next_record_time')"
        @cancel="action.next_record_time = false"
        :formatter="formatterDatetime"
      />
    </Popup>
    <!-- v1.4.9线索等级 -->
    <Popup v-model:show="action.level" position="bottom" :key="keyNumber">
      <Picker
        :columns="options.clue_level"
        :columns-field-names="{
          text: 'name',
          value: 'id',
          disabled: 'disabled',
        }"
        @confirm="(value) => handelChange(value, 'level')"
        @cancel="action.level = false"
        :default-index="
          options.clue_level.findIndex((v) => {
            return v.id == form.level;
          })
        "
      />
    </Popup>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  inject,
  onMounted,
  defineProps,
  defineEmits,
} from 'vue';
import {
  Loading,
  Overlay,
  Toast,
  Notify,
  Form,
  Checkbox,
  Popup,
  Picker,
  Field,
  DatetimePicker,
  Button,
  Dialog,
  Icon,
} from 'vant';
import moment from 'moment';
const props = defineProps(['clueID']);
const emit = defineEmits(['reviewOk']);

const loading = ref(false);
const $http = inject('$http');

const formValue = reactive({
  communication_type_id: '', //联系方式str
  next_follow_type: '继续跟进', //下次跟进方式str
  level: '', //v1.4.9线索等级str
});

const form = reactive({
  is_customer_active: false, //是否客户主动：0false 否, 1true 是
  communication_type_id: '', //联系方式
  record_time: undefined, // 本次跟进时间
  content: '', // 本次跟进内容
  next_record_time: undefined, // 下次跟进时间
  next_follow_type: 1, // 下次跟进方式
  level: '', ////v1.4.9线索等级
});

const action = reactive({
  communication_type_id: false, //联系方式
  record_time: false, //本次跟进时间
  next_follow_type: false, //下次跟进方式
  next_record_time: false, //下次跟进时间
  next_record_time_disabled: false, //下次跟进时间是否disabled
  level: false, //v1.4.9线索等级
});

const options = reactive({});

onMounted(() => {
  loadParmas();
});

/**
 * 接口api
 */

// -- 公共参数接口
const loadParmas = () => {
  loading.value = true;
  $http.clueFollowRecordParam().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Object.assign(options, data);
      form.record_time = data.now_day;
      form.next_record_time = data.seven_day;
      loadClueInfo();
    } else {
      Notify({ type: 'warning', message: data });
      loading.value = false;
    }
  });
};

// -- 线索详情
const clueInfo = reactive({});
const loadClueInfo = () => {
  $http.getClueInfo({ id: props?.clueID }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Object.assign(clueInfo, data);
      // formValue.level = data.level_name;
      // form.level = data.level;
      levelOptionsHandle(
        [data.size, data.house_category, data.home_type, data.source_type],
        data.level
      );
    } else {
      Notify({ type: 'warning', message: data });
    }
    loading.value = false;
  });
};

/**
 * 操作
 */

// -- v1.4.9 面积、精装/毛坯、房屋结构修改之后引发的“线索等级”筛选项变动
const keyNumber = ref(Date.parse(new Date()));
const levelOptionsHandle = (newVal, level) => {
  console.log(newVal);
  keyNumber.value = Date.parse(new Date());
  const size = newVal[0]; //面积
  const hose_id = newVal[1]; //精装/毛坯
  const home_id = newVal[2]; //房屋结构
  // A类可选：面积 >= 180 && 精装/毛坯 == 101毛坯 && 房屋结构 != (488未知 || 111自建)；
  // B类可选：面积 >= 140 && 精装/毛坯 == 102精装 && 房屋结构 != (488未知 || 111自建)；
  // C类可选：新建时，永远禁止选择，disabled状态；编辑时，永远可选
  // D类可选：不符合上述情况为D；
  clueInfo.level = '';
  if (
    size &&
    parseFloat(size) >= 180 &&
    hose_id == 101 &&
    home_id != 488 &&
    home_id != 111
  ) {
    options.clue_level[0].disabled = false;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = true;
    clueInfo.level = level || 1;
    clueInfo.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[0].name;
    console.log('A+C');
    return;
  } else if (
    (size &&
      parseFloat(size) >= 140 &&
      hose_id == 102 &&
      home_id != 488 &&
      home_id != 111) ||
    (size && parseFloat(size) >= 180 && home_id == 111)
  ) {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = false;
    options.clue_level[3].disabled = true;
    clueInfo.level = level || 2;
    clueInfo.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[1].name;
    console.log('B+C');
    return;
  } else {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = false;
    clueInfo.level = level || 4;
    clueInfo.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[3].name;
    console.log('D+C');
  }
  console.log(options.clue_level);
};

// -- v1.4.9 新增线索等级 文字信息提示
const getInstructions = () => {
  Dialog.confirm({
    title: '',
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#576B95',
    className: 'dialogDescripton',
    message: `<div class="description"><h3>线索等级判定方式</h3><p>1.A类 - 200平米以上毛坯</p><p>2.B类 - 140平米以上精装、200平米以上自建</p><p>3.C类 - 经判定已无法联系或无需求线索</p><p>4.D类 - 非上述范围，或信息不全的线索</p></div>`,
    allowHtml: true,
  });
};

// -- 时间组件
const minDate = ref(new Date());
const currentDate = ref(new Date());
const currentSevenDate = ref(new Date());
const openDateType = (type_name) => {
  minDate.value = undefined;
  if (type_name === 'record_time') {
    action.record_time = true;
    currentDate.value = form.record_time
      ? new Date(form.record_time)
      : new Date();
  } else if (type_name === 'next_record_time') {
    action.next_record_time = true;
    currentSevenDate.value = form.next_record_time
      ? new Date(form.next_record_time)
      : new Date();
  }
};

// -- 组件操作

const popOptions = reactive({
  time: ['record_time', 'next_record_time'],
  select: ['communication_type_id', 'level'],
});
const handelChange = (value, name) => {
  if (popOptions.time.find((element) => element === name)) {
    //跟进时间、下次跟进时间
    form[name] = moment(value).format('YYYY-MM-DD HH:mm');
    action[name] = false;
  } else if (popOptions.select.find((element) => element === name)) {
    //联系方式、是否有效
    form[name] = value.id;
    formValue[name] = value.name;
    action[name] = false;
  } else if (name === 'next_follow_type') {
    //下次跟进方式
    form[name] = value.id;
    formValue[name] = value.name;
    action[name] = false;
    if (value.name === '不再跟进') {
      action.next_record_time_disabled = true;
      form.next_record_time = undefined;
    } else {
      action.next_record_time_disabled = false;
    }
  }
};

// -- 表单提交
const formRef = ref(null);
const loadingBtn = ref(false);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loadingBtn.value = true;
    loading.value = true;
    submitApi();
  });
};
// -- 提交接口
const submitApi = () => {
  const params = JSON.parse(JSON.stringify(form));
  params.is_customer_active = params.is_customer_active ? 1 : 0;
  params.level =
    clueInfo.source_type == 1 || clueInfo.source_type == 2 ? params.level : '';
  $http
    .addClueFollowRecord({
      clue_id: props?.clueID,
      ...params,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loadingBtn.value = true;
            loading.value = true;
            emit('reviewOk', 'ok');
          },
        });
      } else {
        loadingBtn.value = false;
        loading.value = false;
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
        });
      }
    });
};
// -- 表单报错
const onFailed = (errorInfo) => {
  Toast('请完善信息!', errorInfo);
  return false;
};

// -- 关闭
const cancel = () => {
  emit('reviewOk', 'cancel');
};

// -- 时间格式化
const formatterDatetime = (type, val) => {
  if (type === 'year') {
    return `${val}年`;
  }
  if (type === 'month') {
    return `${val}月`;
  }
  if (type === 'day') {
    return `${val}日`;
  }
  if (type === 'hour') {
    return `${val}：`;
  }
  return val;
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0 !important;
  .formBase {
    padding: 10px 25px 95px;
  }
  .label-name {
    font-size: 16px;
    line-height: 20px;
    margin: 15px 0;
    font-weight: 700;

    &.white {
      color: #fff;
    }

    em {
      color: #ea3d2f;
    }
  }

  .active-box {
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    margin: 15px 0;
    font-size: 15px;
  }

  .mb15 {
    margin-bottom: 15px;
  }

  .mt15 {
    margin-top: 15px;
  }
  .content-flex {
    position: relative;
    .title-box {
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      position: sticky;
      left: 0;
      top: 0;
      background: #f9f9f9;
      z-index: 2;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        margin-left: 24px;
        font-size: 13px;

        .title {
          font-weight: bold;
          font-size: 17px;
          color: #000;
          line-height: 21px;
        }

        .desc {
          height: 21px;
          margin-top: 2px;
          font-size: 15px;
          color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100vw - 56px);
          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex-grow: 0;
            &.width25 {
              max-width: 25%;
            }
            &.width35 {
              max-width: 35%;
            }
          }

          em {
            font-size: 11px !important;
            width: 9px;
            text-align: center;
            color: #a1a1a1;
          }
        }
      }

      :deep(.van-icon) {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 20px;
      }
    }
  }
}
</style>
