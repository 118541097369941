<!--
 * @Author: NanNan
 * @Date: 2022-04-21 16:00:32
 * @LastEditTime: 2023-06-13 16:01:53
 * @Description: 合同外退款
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <Form class="form-box" ref="formRef">
      <!-- 合同头部信息 -->
      <div class="mess-top">
        <p class="label-name white">
          <em>*</em>
          关联合同
        </p>
        <Field
          v-model="form_info.contract_str"
          right-icon="arrow-down"
          readonly
          placeholder="选择退款合同"
          :rules="[{ required: true, message: '请选择退款合同' }]"
          @click="contract_show = true"
        />
        <p class="contract-id" v-if="form_info.contract_no">
          合同编号：{{ form_info.contract_no }}
        </p>
      </div>

      <!-- 预收款展示信息 -->
      <div class="message-top" v-if="form_info.data_type == 2">
        <div>
          <p class="t">预收金额</p>
          <p class="m">
            <em>¥</em>
            {{ form_info.received_money || '0.00' }}
          </p>
        </div>
      </div>
      <!-- end -->
      <!-- 退款展示信息 -->
      <div class="message-top" v-else>
        <div>
          <p class="t">已收金额</p>
          <p class="m">
            <em>¥</em>
            {{
              form_info.contract_id ? form_info.receivable_money || '0.00' : ''
            }}
          </p>
        </div>
        <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
        <div
          class="wait-box"
          v-if="form_info.income_amount_str || form_info.expenditure_amount_str"
        >
          <Icon name="info-o" color="#EA0000" />
          <p>
            {{
              getString([
                form_info.income_amount_str,
                form_info.expenditure_amount_str,
              ])
            }}
          </p>
        </div>
        <!-- end -->
      </div>
      <!-- end -->

      <div class="content-box">
        <p class="label-name">
          <em>*</em>
          实退金额
        </p>
        <Field
          type="number"
          v-model="form_info.amount"
          placeholder="填写实退金额"
          :formatter="formatter"
          format-trigger="onBlur"
          :rules="[
            { required: true, trigger: 'onChange', message: '请填写实退金额' },
          ]"
        >
          <template #button>元</template>
        </Field>

        <p class="label-name">
          <em>*</em>
          退款日期
        </p>
        <Field
          v-model="form_info.collection_time"
          name="picker"
          readonly
          placeholder="选择退款日期"
          right-icon="underway-o"
          @click="picker_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择退款日期' },
          ]"
        />

        <p class="label-name">
          <em>*</em>
          退款方式
        </p>
        <Field
          v-model="form_info.collection_method_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择退款方式"
          @click="collect_show = true"
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请选选择退款方式',
            },
          ]"
        />

        <p class="label-name">退款账号</p>
        <Field
          v-model="form_info.collection_account_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择退款账号"
          @click="collect_account_show = true"
        />

        <p class="label-name">票据编号</p>
        <Field
          v-model="form_info.bill_no"
          maxlength="50"
          placeholder="输入票据编号"
        />

        <p class="label-name">手工收据号</p>
        <Field
          v-model="form_info.handwork_no"
          maxlength="50"
          placeholder="输入手工票据编号"
        />

        <!-- v0.6.0 新增 -->
        <p class="label-name">打款人</p>
        <Field
          v-model="form_info.drawee"
          maxlength="30"
          placeholder="输入打款人"
        />

        <p class="label-name">手续费</p>
        <Field
          v-model="form_info.service_charge"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写手续费"
        >
          <template #button>元</template>
        </Field>

        <!-- <p class="label-name">优惠活动</p>
        <Field
          v-model="form_info.activity_name"
          maxlength="50"
          placeholder="-"
          disabled
        /> -->
        <!-- end -->

        <p class="label-name">备注</p>
        <Field
          v-model="form_info.remarks"
          rows="3"
          autosize
          type="textarea"
          maxlength="1000"
          placeholder="有什么需要补充的吗..."
          show-word-limit
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">附件</p>
        <FileUpload
          :data="form_info.file_urls"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <!-- end -->

        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>
    <!-- 合同组件 -->
    <Popup v-model:show="contract_show" position="bottom">
      <Picker
        :columns="options.payment_contract_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.payment_contract_list.findIndex((v) => {
            return v.id == contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="contract_show = false"
      />
    </Popup>
    <!-- 年月日 组件 -->
    <Popup v-model:show="picker_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择退款日期"
        @confirm="changePicker"
        @cancel="picker_show = false"
      />
    </Popup>
    <!-- 退款方式 -->
    <Popup v-model:show="collect_show" position="bottom">
      <Picker
        :columns="options.cash_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollect"
        @cancel="collect_show = false"
      />
    </Popup>
    <!-- 退款账号 -->
    <Popup v-model:show="collect_account_show" position="bottom">
      <Picker
        :columns="options.collection_account"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollectAccount"
        @cancel="collect_account_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload';

import {
  Form,
  Field,
  Button,
  Notify,
  DatetimePicker,
  Picker,
  Popup,
  Loading,
  Overlay,
  Icon,
} from 'vant';
import moment from 'moment';

const $http = inject('$http');
const router = useRouter();
const customer_id = ref(router.currentRoute.value.query.id);
const contract_id = ref(router.currentRoute.value.query.cid); //携带 合同id
const options = reactive({
  payment_contract_list: [], // 合同list
  cash_type: [], //退款方式
  collection_account: [], //退款账号
});

const form_default = reactive({
  contract_id: '', //*合同id
  contract_str: '', //合同_str
  contract_no: '', //合同 编号
  receivable_money: '', //已收金额
  ready_money: '', //已收金额 - 联动
  amount: '', //*退款金额
  collection_time: undefined, //退款时间
  collection_method_id: '', //退款方式
  collection_method_str: '', //退款方式_str
  collection_account_id: '', //退款账号
  collection_account_str: '', //退款账号_str
  bill_no: '', //票据编号
  handwork_no: '', //手工收据号
  drawee: '', //打款人
  service_charge: '', //手续费
  activity_name: '', //优惠活动str
  activity_id: '', //优惠活动id
  remarks: '', //备注
  file_urls: [], //附件集合

  income_amount_str: '', //合同外收款待审
  expenditure_amount_str: '', //合同外退款待审
  //数据类型 1合同 2预收款
  data_type: '',
});
const form_info = reactive({});

onMounted(() => {
  Object.assign(form_info, form_default);
  loadFunc();
  loadPaymentFunc();
});

/**
 * 接口调用
 */
const loading = ref(false);
const loadFunc = () => {
  loading.value = true;
  $http
    .getContractRefundParameter({ customer_id: customer_id.value })
    .then((res) => {
      const { data, code } = res;
      loading.value = false;
      if (code === 0) {
        options.cash_type = data.cash_type;
        options.collection_account = data.collection_account;
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

//-v1.3.8产品预收款接口
const loadPaymentFunc = () => {
  // 1合同内收款 2合同内退款 3合同外收款 4合同外退款 5转款 7转款 9增减项 11返款 12产品预收款
  $http
    .getContractPaymentParams({ customer_id: customer_id.value, type: 4 })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        options.payment_contract_list = data.payment_contract_list;
        // 数据格式替换 - 开始
        changeData(data.payment_contract_list);
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

// v0.6.0 接口调整 @根据款期，获取待审核金额相关(不包含增减项了)
const loadPaymentInfoFunc = async (id, type) => {
  loading.value = true;
  let obj = { contract_id: id };
  //-预收款
  if (type) {
    obj = { payment_id: id };
  }
  await $http
    .getContractCollectionPaymentInfoApi(obj)
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        loading.value = false;
        form_info.received_money = data.received_money || '';
        form_info.receivable_money = data.receivable_money || '';
        form_info.income_amount_str = data.income_amount_str || '';
        form_info.expenditure_amount_str = data.expenditure_amount_str || '';
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// @数据格式替换
const changeData = (arr) => {
  const objArr = arr.filter((val) => val.id == contract_id.value);
  const obj = objArr.length ? JSON.parse(JSON.stringify(objArr[0])) : '';
  form_info.contract_id = obj.id || '';
  form_info.contract_str = obj.name || '';
  form_info.contract_no = obj.contract_no || '';
  form_info.activity_id = obj.activity_id || '';
  form_info.activity_name = obj.activity_name || '';
  form_info.data_type = obj.data_type || 1;
  //-data_type:1:合同,2：产品预收款
  if (obj.data_type == 2) {
    loadPaymentInfoFunc(obj.id, 'prepayment');
  } else {
    loadPaymentInfoFunc(obj.id || router.currentRoute.value.query.cid);
  }
};

/**
 * 操作集合
 */
// @拼接字符串
const getString = (arr) => {
  let strArr = [];
  arr[0] && strArr.push('待审核收款¥' + arr[0]);
  arr[1] && strArr.push('待审核退款¥' + arr[1]);
  return strArr.join('，');
};

// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @选择合同
const contract_show = ref(false);
const changeContract = (value) => {
  loading.value = true;
  contract_id.value = value.id;
  contract_show.value = false;
  Object.assign(form_info, form_default);
  changeData(options.payment_contract_list);
  changeLoading();
};

// @loading加载
const changeLoading = () => {
  loading.value = true;
  setTimeout(() => {
    // 重置
    formRef.value?.resetValidation();
    loading.value = false;
  }, 500);
};

// @退款日期
const picker_show = ref(false);
const currentDate = ref(new Date());
const changePicker = (value) => {
  picker_show.value = false;
  form_info.collection_time = moment(value).format('YYYY-MM-DD');
};

// @退款方式
const collect_show = ref(false);
const changeCollect = (value) => {
  collect_show.value = false;
  form_info.collection_method_id = value.id;
  form_info.collection_method_str = value.name;
};

// @退款账号
const collect_account_show = ref(false);
const changeCollectAccount = (value) => {
  collect_account_show.value = false;
  form_info.collection_account_id = value.id;
  form_info.collection_account_str = value.name;
};

// @附件
const getFileList = (file_list) => {
  form_info.file_urls = file_list;
};

// @提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    // 格式变化
    const parmars = Object.assign({}, form_info);
    $http
      .getContractOutsideRefundApi({ ...parmars })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              loading.value = false;
              router.push({
                path: '/sale',
                query: { id: customer_id.value },
              });
            },
          });
        } else {
          loading.value = false;
          Notify({ type: 'warning', message: data });
        }
      })
      .catch(() => {
        loading.value = false;
      });
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/outReturn.less';
</style>
