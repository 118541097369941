<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2024-03-07 16:47:18
 * @Description: 整装销售合同收款

-->
<template>
  <!-- 合同收款 -->
  <div class="temp">
    <h1>合同收款</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签订合同价
            </label>
            <Field
              v-model="form.contract_amount"
              type="number"
              @blur="getAmountNum"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的签订合同价',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写签订合同价"
            >
              <template #button>
                <text>元</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              收费比例
            </label>
            <Field
              v-model="form.directory_attr_name"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请选择收费比例',
                },
              ]"
              placeholder="收费比例"
            ></Field>
          </div>
          <div class="cell">
            <label class="form_title">整装优选产品单价</label>
            <Field
              v-model="form.pack_amount"
              type="number"
              disabled
              :rules="[
                {
                  message: '请填写正确的整装优选产品单价',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写整装优选产品单价"
            >
              <template #button>
                <text>元/㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>整装产品管理费</label
            >
            <Field
              v-model="form.pack_manage_amount"
              type="number"
              :rules="[
                {
                  message: '请填写正确的整装产品管理费',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写整装产品管理费"
            >
              <template #button>
                <text>元</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>整装产品税金</label
            >
            <Field
              v-model="form.pack_tax_amount"
              type="number"
              :rules="[
                {
                  message: '请填写正确的整装产品税金',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写整装产品税金"
            >
              <template #button>
                <text>元</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">优惠活动</label>
            <Field
              v-model="form.discount_activity_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择优惠活动"
              @click="state.showActivePicker = true"
            />
            <Popup v-model:show="state.showActivePicker" position="bottom">
              <Picker
                :columns="state.activeList"
                :columns-field-names="customFieldName"
                @confirm="onConfirm"
                cancel-button-text="清除"
                @cancel="onRemoveActive"
                :default-index="
                  state.activeList.findIndex((v) => {
                    return v.id == form.discount_activity_id;
                  })
                "
              />
            </Popup>
          </div>
          <div class="cell">
            <label class="form_title">优惠金额</label>
            <Field
              v-model="form.discount_amount"
              type="number"
              placeholder="0.00"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的优惠金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <text>元</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">贷款金额</label>
            <Field
              v-model="form.loan_amount"
              type="number"
              placeholder="填写贷款金额"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的贷款金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <text>元</text>
              </template>
            </Field>
          </div>
          <div class="cell2">
            <label class="form_title">收款期数</label>
            <div
              class="collect_list"
              v-for="(item, index) in form.charge_proportion_details"
              :key="index"
            >
              <div class="line1 clearfix">
                <div class="pull-left header">
                  {{ item.payment_period }}
                </div>
                <div class="pull-left">
                  <p class="title">收款比例</p>
                  <p class="num">{{ item.proportion + '%' }}</p>
                </div>
                <div class="pull-left">
                  <p class="title">应收金额</p>
                  <p class="num">{{ item.receivable_money }}</p>
                </div>
              </div>
              <div class="line2">
                <Field
                  v-model="item.payment_description"
                  placeholder="输入款项说明"
                  maxlength="100"
                />
              </div>
            </div>
          </div>
        </CellGroup>
      </Form>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, ref, defineExpose, computed } from 'vue';
import { Form, Field, CellGroup, Popup, Picker } from 'vant';
const props = defineProps([
  'contractType',
  'data',
  'parameterList',
  'contractTypeName',
  'priceList',
]);
const state = reactive({
  //-活动
  showActivePicker: false,
  activeList: props.parameterList ? props.parameterList.contract_activity : [],
});

//--表单---------------------
//-贷款/签订合同价/优惠金额
const validatorAmount = (val) =>
  val ? (parseFloat(val) <= 99999999.99 ? true : false) : true;

const ruleFormRef = ref(null);
const form = reactive({
  contract_amount: props.data.contract_amount ? props.data.contract_amount : '',
  directory_attr_id: props.data.directory_attr_id
    ? props.data.directory_attr_id
    : props.contractType,
  directory_attr_name: props.data.directory_attr_name
    ? props.data.directory_attr_name
    : props.contractTypeName,
  discount_activity_id: props.data.discount_activity_id
    ? props.data.discount_activity_id
    : '',
  discount_activity_name: props.data.discount_activity_name
    ? props.data.discount_activity_name
    : '',
  discount_amount: props.data.discount_amount ? props.data.discount_amount : '',
  pack_amount: computed(() => props?.priceList?.product_price ?? ''),
  pack_manage_amount: props.data.pack_manage_amount
    ? props.data.pack_manage_amount
    : '',
  pack_tax_amount: props.data.pack_tax_amount ? props.data.pack_tax_amount : '',
  charge_proportion_details: props.data.charge_proportion_details
    ? props.data.charge_proportion_details
    : props.charge_proportions
    ? props.charge_proportions
    : [],
  loan_amount: props.data.loan_amount ? props.data.loan_amount : '',
});
//-picker-活动选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value) => {
  if (value) {
    form.discount_activity_name = value.name;
    form.discount_activity_id = value.id;
  }
  state.showActivePicker = false;
};

const onRemoveActive = () => {
  form.discount_activity_name = '';
  form.discount_activity_id = '';
  state.showActivePicker = false;
};

//-获取款期
const floatNum1 = /^[0-9]+([.]{1}[0-9]{1})?$/;
const floatNum2 = /^[0-9]+([.]{1}[0-9][0-9]{1})?$/;
const getAmountNum = () => {
  if (form.charge_proportion_details.length) {
    let total = 0;
    return form.charge_proportion_details.map((v, index) => {
      if (
        index == form.charge_proportion_details.length - 1 &&
        form.charge_proportion_details.length > 1
      ) {
        v.receivable_money = (
          parseFloat(form.contract_amount ? form.contract_amount : 0) -
          parseFloat(total)
        ).toFixed(2);
      } else {
        var currentMoney =
          parseFloat(form.contract_amount ? form.contract_amount : 0) *
          (parseFloat(v.proportion) / 100);
        var currentMoneySpecial =
          Math.ceil(
            parseFloat(form.contract_amount ? form.contract_amount : 0) *
              (parseFloat(v.proportion) / 100) *
              100
          ) / 100;
        v.receivable_money =
          Number.isInteger(currentMoney) || floatNum1.test(currentMoney)
            ? currentMoney.toFixed(2)
            : floatNum2.test(currentMoney)
            ? currentMoney
            : floatNum1.test(currentMoneySpecial)
            ? currentMoneySpecial.toFixed(2)
            : currentMoneySpecial;

        total += parseFloat(v.receivable_money);
      }

      v.payment_period;
      v.proportion;
      v.payment_period;
    });
  }
};

defineExpose({ form, ruleFormRef });
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
