<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2023-07-13 18:56:21
 * @Description: 
-->
<template>
  <!-- 基本信息 -->
  <div class="temp">
    <h1>合同基础信息</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同类型
            </label>
            <Field
              v-model="form.name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择合同类型',
                },
              ]"
              placeholder="请选择合同类型"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加合同备注..."
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              v-if="form.type == 54"
              :type="5"
              :data="form.file_url"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
            <FileUpload
              v-else
              :data="form.file_url"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, ref, defineProps, defineExpose } from 'vue';
import { Form, Field, CellGroup } from 'vant';
const props = defineProps(['data']);

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  name: props.data.name ? props.data.name : '',
  type: props.data.type ? props.data.type : '',
  remarks: props.data.remarks ? props.data.remarks : '',
  file_url: props.data.file_url ? props.data.file_url : [],
});

//-@附件
const getFileList = (file_list) => {
  form.file_url = file_list;
};

//--上传附件end----------

defineExpose({ form, ruleFormRef });
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
