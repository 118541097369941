<!--
 * @Author: gest
 * @Date: 2023-09-12 15:51:51
 * @LastEditTime: 2024-03-07 15:52:14
 * @Description: v1.5.6 三级安全教育承诺书 见证件
-->
<template>
  <div class="formBase">
    <div class="content white_bg mb_15 p_15">
      <div class="pb_10 font_size_17 font_bold text_center">安全教育大纲</div>
      <div class="font_bold">现场负责人</div>
      <div>
        1. 本人承诺完成公司三级安全教育制度培训，并完成“4表1题”，上传至附件。
      </div>
      <div>2. 本人承诺在项目施工前的进场劳务人员都完成三级安全教育。</div>
      <div>
        3.
        原则上现场负责人和劳务人员在合同周期内不允许中途更换，特殊情况，提前三天申请报告，并新进劳务人员完成三级安全教育。
      </div>
      <div>
        4. 本人承诺做好项目安全第一责任人，对自身安全负责，对在场劳务人员负责。
      </div>
      <div class="pt_15 font_bold">劳务工人</div>
      <div>
        1. 本人承诺完成公司三级安全教育制度培训，并完成“4表1题”，上传至附件。
      </div>
      <div>2. 本人承诺按照公司要求履行现场安全规章制度，服从公司管理。</div>
      <div>3. 本人承诺做好自身安全第一责任人，对自身安全负责。</div>
    </div>
    <div class="content white_bg mb_15">
      <p class="pt_15 pb_15 ml_15 mr_15 border_bottom grey_color">
        “4表1题”资料
      </p>
      <div v-if="form.info.basic_info && form.info.basic_info.length > 0">
        <div
          class="item"
          v-for="(item, index) in form.info.basic_info"
          :key="index"
        >
          <div class="pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="font_size_17">{{ item.name }} · {{ item.phone }}</div>
            <div class="grey_color font_size_13 pb_10">
              {{ item.work_type_names || '-' }}
            </div>
            <FileGrid
              :data="item.files"
              v-if="item.files && item.files.length > 0"
            ></FileGrid>
            <div v-else class="grey_color pt_10 pb_10 text_center">
              暂未上传
            </div>
          </div>
        </div>
      </div>
      <div v-else class="grey_color p_20 text_center">暂未分配人员</div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>{{ form.info.remark || '-' }}</div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';

import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
