/*
 * @Author: Jessica
 * @Date: 2023-04-26 12:11:52
 * @LastEditTime: 2023-04-26 12:11:52
 * @Description: 产品订单相关
 */
//
import Service from '@/utils/request';

export default {
  /**
   * 施工主材报价
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getConstructionQuotePreviewApi(params) {
    return Service.post(`/rs/construction/quotePreview`, params);
  },
};
