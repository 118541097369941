<!--
 * @Author: gest
 * @Date: 2023-08-08 15:53:21
 * @LastEditTime: 2023-09-21 18:05:33
 * @Description: v1.4.3 客户服务记录
-->
<template>
  <div class="shareInfo">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <!-- <img
      :src="require('@/assets/images/share/sharebig.png')"
      style="width: 100%"
    /> -->
    <div class="p_20 font_size_15">
      <div class="pb_20 border_bottom">
        <div class="font_size_20 font_bold mb_10">本次服务</div>
        <div style="color: #3d3d3d">
          服务时间：{{ _DATA.info.now_follow_time }}
        </div>
        <div class="mt_15">
          <template
            v-for="(item, index) in _DATA.info.five_step_trilogy"
            :key="index"
          >
            <div
              class="flex_between mb_10"
              style="
                background-color: #ffffff;
                padding: 15px;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
              "
              v-if="item.add_time"
            >
              <Image
                :src="
                  require('@/assets/images/share/icon_' +
                    item.follow_type +
                    '_' +
                    item.id +
                    '.png')
                "
                style="width: 20px"
                class="mr_15"
              />

              <div class="flex1">
                <p class="font_bold">{{ item.name }}</p>
              </div>
              <div class="green_color font_size_13">
                <svg class="icon-small" aria-hidden="true">
                  <use xlink:href="#icon-a-checkbox-circle-fill2"></use>
                </svg>
                <span>已完成</span>
              </div>
            </div></template
          >
        </div>
        <div
          v-if="_DATA.info.attach && _DATA.info.attach.length > 0"
          class="mt_20"
        >
          <!-- <div class="font_size_17 font_bold mb_15">本次服务图片</div> -->
          <FileGrid :data="_DATA.info.attach"></FileGrid>
        </div>
      </div>

      <div class="font_size_20 font_bold mt_15 mb_10">下次服务</div>
      <div class="mb_5">服务时间：{{ _DATA.info.next_follow_time || '-' }}</div>
      <div>
        服务内容：{{
          _DATA.info.next_follow_remark ? _DATA.info.next_follow_remark : '-'
        }}
      </div>
      <div class="font_size_13 grey_color text_center" style="padding: 40px 0">
        如有问题，请及时沟通
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import FileGrid from '@/components/common/FileGrid';
import { Loading, Overlay, Notify, Image } from 'vant';

const $http = inject('$http');

const router = useRouter();

const _DATA = reactive({
  id: ref(Number(router.currentRoute.value.query.id)),
  loading: false,
  info: {},
});

onMounted(() => {
  loadFunc();
});

/**
 * 接口调用
 */
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .shareDetail({
      id: _DATA.id,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      _DATA.info = data;
      if (code !== 0) {
        Notify({ type: 'warning', message: data });
      }
    });
};
</script>
<style lang="less" scoped>
.shareInfo {
  background-color: #f8f8f8;
  max-width: 480px;
  margin: 0 auto;

  .p_20 {
    padding: 20px;
  }
  .pb_20 {
    padding-bottom: 20px;
  }

  .mt_15 {
    margin-top: 15px;
  }
  .mt_20 {
    margin-top: 20px;
  }
  .mr_15 {
    margin-right: 15px;
  }

  .mb_5 {
    margin-bottom: 5px;
  }
  .mb_10 {
    margin-bottom: 10px;
  }
  .mb_15 {
    margin-bottom: 15px;
  }

  .icon-small {
    width: 16px;
    height: 16px;

    vertical-align: -4px;
    fill: currentColor;
    margin-right: 5px;
  }
}
</style>
