<!--
 * @Author: NanNan
 * @Date: 2022-12-29 09:30:26
 * @LastEditTime: 2024-06-14 11:15:06
 * @Description: v1.3.1 新增 设计详情页 
-->
<template>
  <div class="page-box" @load="_DATA.loading">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->

    <!-- 设计详情 -->
    <div>
      <!-- 头部信息 -->
      <div class="cell-box">
        <ul class="info">
          <li>
            <label class="title">启动时间</label>
            <span>{{ _DATA.info.start_day || '-' }}</span>
          </li>
          <li>
            <label class="title">工期</label>
            <span>{{ _DATA.info.schedule || '-' }}天</span>
            <span class="desc">
              {{ _DATA.info.is_monday_postponed_str || '' }}
            </span>
          </li>
          <li>
            <label class="title">标准内外控工期</label>
            <span>
              {{
                _DATA.info.inner_schedule === ''
                  ? '-'
                  : _DATA.info.inner_schedule + '天'
              }}
            </span>
          </li>
          <li>
            <label class="title">协议工期</label>
            <span>
              {{
                _DATA.info.contract_schedule === ''
                  ? '-'
                  : _DATA.info.contract_schedule + '天'
              }}
            </span>
          </li>
          <li>
            <label class="title">设计中心</label>
            <span>{{ _DATA.info.customer_manager_dep || '-' }}</span>
          </li>
          <li>
            <label class="title">创建日期</label>
            <span>{{ _DATA.info.add_time || '-' }}</span>
          </li>
          <li>
            <label class="title">验收模板</label>
            <span>{{ _DATA.info.template_name || '-' }}</span>
          </li>
          <li>
            <label class="title">备注</label>
            <span>{{ _DATA.info.describe || '-' }}</span>
          </li>
          <li class="last">
            <label class="title">附件</label>
            <span
              class="arrow-item between"
              @click="lookFile(_DATA.info?.files)"
              v-if="_DATA.info?.files?.length > 0"
            >
              <a style="color: #3471ff">
                全部{{ _DATA.info?.files?.length }}个附件
              </a>
              <Icon name="arrow" />
            </span>
            <span v-else>-</span>
          </li>
        </ul>
      </div>
      <!-- 服务人员 -->
      <div class="action-content">
        <div class="main">
          <div class="title">服务人员</div>
          <div
            class="list-item"
            v-for="(item, index) in _DATA.info.services"
            :key="index"
            :class="{ borderno: index + 1 === _DATA.info?.services?.length }"
          >
            <div class="img-box">
              <Image
                class="img-head"
                fit="cover"
                round
                :src="
                  item.face_image
                    ? item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                    : require('@/assets/images/icon-empty-img.png')
                "
              ></Image>
            </div>
            <div class="right">
              <div class="name">
                {{ item.name || '-' }} · {{ item.phone || '-' }}
              </div>
              <div class="desc">
                {{
                  item.dep_name
                    ? `${item.dep_name}/${item.post_name}`
                    : `${item.post_name}`
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 全部附件 -->
  <FileAll
    ref="fileAll"
    :data="_DATA.files"
    :isShow="_DATA.showAllFile"
    @closePop="
      () => {
        _DATA.showAllFile = false;
      }
    "
  ></FileAll>
</template>

<script setup>
import FileAll from '@/components/common/FileAll';
import { Icon, Loading, Overlay, Notify, Image } from 'vant';
import { inject, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';

const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();

const _DATA = reactive({
  design_id: router.currentRoute.value.query.did, //设计id
  info: {},
  showAllFile: false, //是否显示附件列表
  files: '', //查看附件 公共参数
});

onMounted(() => {
  loadInfo();
});

/**
 * 接口调用
 */
// -- 设计详情
const loadInfo = () => {
  _DATA.loading = true;
  $http.getDesignInfo({ id: _DATA.design_id }).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

/**
 * 操作
 */
// -- 点击查看全部附件
const lookFile = (files) => {
  _DATA.files = files;
  _DATA.showAllFile = true;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/construction/commonDetail.less';
</style>
