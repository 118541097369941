<!--
 * @Author: gest
 * @Date: 2023-03-24 15:39:58
 * @LastEditTime: 2023-03-29 16:54:09
 * @Description: v1.3.4 产品方案预演会表单
-->
<template>
  <div class="formBase">
    <CellGroup>
      <!-- 方案评审 -->
      <div class="content">
        <h3>方案内容</h3>
        <div class="list">
          <div
            class="cell"
            v-for="(item, index) in form.info.basic_info"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div class="right">
              <p>
                {{ item.value ? (item.value == 1 ? '已完成' : '未完成') : '-' }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <h3>方案质量</h3>
        <div class="list">
          <div
            class="cell"
            v-for="(item, index) in form.info.quality_info"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div class="right">
              <template v-if="item.value">
                <div class="right2">
                  <div class="icon_box" :class="item.value == 2 ? 'no' : ''">
                    <svg class="icon-small" aria-hidden="true">
                      <use
                        :xlink:href="
                          item.value == 1
                            ? '#icon-a-emotion-fill1'
                            : '#icon-a-emotion-sad-fill1'
                        "
                      ></use>
                    </svg>
                    <span>{{ item.value == 1 ? '满足' : '不满足' }}</span>
                  </div>
                </div>
              </template>
              <template v-else><p>-</p></template>
            </div>
          </div>
        </div>

        <div class="list mt_15">
          <div class="cell">
            <div>产品配置、配比是否合理</div>
            <div class="right">
              <template v-if="form.info.product_config_value">
                <div class="right2">
                  <div
                    class="icon_box"
                    :class="form.info.product_config_value == 2 ? 'no' : ''"
                  >
                    <svg class="icon-small" aria-hidden="true">
                      <use
                        :xlink:href="
                          form.info.product_config_value == 1
                            ? '#icon-a-emotion-fill1'
                            : '#icon-a-emotion-sad-fill1'
                        "
                      ></use>
                    </svg>
                    <span>
                      {{
                        form.info.product_config_value == 1 ? '满足' : '不满足'
                      }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else><p>-</p></template>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="cell">
            <div class="name_grey">软装</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_config_soft == undefined ||
                  form.info.product_config_soft == ''
                    ? '-'
                    : form.info.product_config_soft
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">主材</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_config_main == undefined ||
                  form.info.product_config_main == ''
                    ? '-'
                    : form.info.product_config_main
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">机电</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_config_machine == undefined ||
                  form.info.product_config_machine == ''
                    ? '-'
                    : form.info.product_config_machine
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">园林</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_config_gard == undefined ||
                  form.info.product_config_gard == ''
                    ? '-'
                    : form.info.product_config_gard
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">施工</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_config_construct == undefined ||
                  form.info.product_config_construct == ''
                    ? '-'
                    : form.info.product_config_construct
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
        </div>
        <div class="list mt_15">
          <div class="cell">
            <div>产品报价是否超预算</div>
            <div class="right">
              <template v-if="form.info.product_offer_value">
                <div class="right2">
                  <div
                    class="icon_box"
                    :class="form.info.product_offer_value == 2 ? 'no' : ''"
                  >
                    <svg class="icon-small" aria-hidden="true">
                      <use
                        :xlink:href="
                          form.info.product_offer_value == 1
                            ? '#icon-a-emotion-fill1'
                            : '#icon-a-emotion-sad-fill1'
                        "
                      ></use>
                    </svg>
                    <span>
                      {{
                        form.info.product_offer_value == 1 ? '满足' : '不满足'
                      }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else><p>-</p></template>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="cell">
            <div class="name_grey">软装</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_offer_soft == undefined ||
                  form.info.product_offer_soft == ''
                    ? '-'
                    : form.info.product_offer_soft
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">主材</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_offer_main == undefined ||
                  form.info.product_offer_main == ''
                    ? '-'
                    : form.info.product_offer_main
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">机电</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.product_offer_machine == undefined ||
                  form.info.product_offer_machine == ''
                    ? '-'
                    : form.info.product_offer_machine
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
        </div>
        <div class="list mt_15">
          <div class="cell">
            <div>对这次“方案通过”有信心吗？</div>
            <div class="right">
              <p>
                <span class="score">
                  {{
                    !form.info.pass_confidence &&
                    form.info.pass_confidence !== 0
                      ? '-'
                      : form.info.pass_confidence
                  }}
                </span>
                <span class="score_desc">分</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 商务洽谈 -->
      <div class="content">
        <h3>商务洽谈</h3>
        <div class="list">
          <div class="cell">
            <div class="name_grey">决策人</div>
            <div class="right">
              <span class="name_black">{{ form.info.policy_man || '-' }}</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">见面时间</div>
            <div class="right">
              <span class="name_black">{{ form.info.face_time || '-' }}</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">本次预计收款额</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.money == undefined || form.info.money == ''
                    ? '-'
                    : form.info.money
                }}
              </span>
              <span class="ml_5">元</span>
            </div>
          </div>
        </div>
        <div class="list mt_15">
          <div class="cell">
            <div>对这次的“收款成功”有信心吗？</div>
            <div class="right">
              <p>
                <span class="score">
                  {{
                    !form.info.success_confidence &&
                    form.info.success_confidence !== 0
                      ? '-'
                      : form.info.success_confidence
                  }}
                </span>
                <span class="score_desc">分</span>
              </p>
            </div>
          </div>
        </div>
        <div class="list mt_15 mb_15">
          <div class="cell">
            <div class="name_grey">收款方式</div>
            <div class="right">
              <span class="name_black">
                {{ form.info.money_type_name || '-' }}
              </span>
            </div>
          </div>
          <div class="cell" style="display: block">
            <div class="name_grey">收全款理由</div>
            <div class="remark mt_10">
              {{ form.info.remark || '-' }}
            </div>
          </div>
        </div>
      </div>
    </CellGroup>
  </div>
</template>

<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { CellGroup } from 'vant';

const props = defineProps(['data']);
const form = reactive({
  info: {},
});
// 参数--调用接口
onMounted(() => {
  form.info = props.data;
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessDetail.less';
</style>
