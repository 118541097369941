<!--
 * @Author: Jessica
 * @Date:2022-12-29 16:49:01
 * @LastEditTime: 2023-12-07 11:02:13
 * @Description: V1.4.1 测算工期
 * @Description: Jessica V1.5.1 测算工期：【签约-开工准备阶段】去除
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="state.contract_list?.length > 0"
      @click="
        () => {
          state.contract_list?.length > 1
            ? (state.showPicker_contract = true)
            : null;
        }
      "
    >
      <div class="left">
        <div class="font_size_15">{{ form.list.contract_name }}</div>
      </div>
      <div class="right" v-if="state.contract_list?.length > 1">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <div class="temp_info">
      <div class="item">合同编号：{{ form.list.contract_no }}</div>
      <div class="item">施工面积：{{ form.list.house_size }}㎡</div>
      <div class="item temp_box">
        <div>验收模板：{{ form.list.template_name }}</div>
        <div
          v-if="state.templateList && state.templateList.length > 1"
          class="btn_temp"
          @click="state.showPicker_template = true"
        >
          切换模板
        </div>
      </div>
    </div>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              开工日期
            </label>
            <Field
              v-model="form.list.start_date"
              name="picker"
              readonly
              placeholder="选择开工时间生成阶段计划时间"
              right-icon="underway-o"
              @click="state.time_show = true"
              :rules="[
                {
                  required: true,
                  trigger: 'onChange',
                  message: '请选择开工时间',
                },
              ]"
            />
          </div>
        </CellGroup>
      </Form>
      <div class="schedule_box">
        <div
          class="schedule_item"
          v-for="[key, value] in Object.entries(state.calcContractList)"
          :key="key"
        >
          <div class="grey_color font_size_15" v-if="key > 1">
            {{ state.scheduleName[key - 1] }}时间：
          </div>
          <div class="font_size_15" v-if="key > 1">{{ value || '-' }}</div>
        </div>
      </div>
      <div class="stick-box">
        <Button
          type="primary"
          size="large"
          @click="
            () => {
              router.back();
            }
          "
          >知道了</Button
        >
      </div>
    </div>

    <!-- popup集合 -->
    <!-- 年月日 组件 -->
    <Popup v-model:show="state.time_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="changePicker"
        @cancel="state.time_show = false"
      />
    </Popup>
    <!--picker-模板 --->
    <Popup v-model:show="state.showPicker_template" position="bottom">
      <Picker
        :columns="state.templateList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'template')"
        @cancel="state.showPicker_template = false"
      />
    </Popup>
    <!--picker-头部合同切换 --->
    <Popup v-model:show="state.showPicker_contract" position="bottom">
      <Picker
        :columns="state.contract_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          state.contract_list.findIndex((v) => {
            return v.id == form.list.contract_id;
          })
        "
        @confirm="(v) => onConfirm(v, 'contract')"
        @cancel="state.showPicker_contract = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Overlay,
  Loading,
  DatetimePicker,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-头部合同选择-picker
  showPicker_contract: false,
  contract_list: [],
  checkInfo: {},

  //-模板控件
  showPicker_template: false,
  templateList: [],
  time_show: false,
  scheduleName: [
    '【签约-开工准备阶段】完成',
    '【开工准备-隐蔽阶段】完成',
    '【隐蔽-中期阶段】完成',
    '【中期-产品安装阶段】完成',
    '【产品安装-交付阶段】完成',
  ],
  calcContractList: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  },
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    contract_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.cid
      : '',
    contract_name: '',
    contract_no: '',
    house_size: '',
    template_id: '',
    template_name: '',
  },
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    // 头部合同切换
    if (type == 'contract') {
      form['list'][type + '_id'] = value.id;
      form['list'][type + '_name'] = value.name;
      form['list'][type + '_no'] = value.contract_no;
      form['list']['house_size'] = value.house_size;
      state.checkInfo = value;
      getConstructionTemplateList();
    } else if (type == 'template') {
      if (form['list'][type + '_name'] != value.name) {
        form['list'][type + '_id'] = value.id;
        form['list'][type + '_name'] = value.name;
        if (form.list.start_date) {
          btnSumbit();
        }
      }
    }
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getCustomerListParmas();
});

//-@头部合同参数
const getCustomerListParmas = async () => {
  state.loadingConfirm = true;
  await $http
    .construction_getCalcContractList({
      customer_id: router.currentRoute.value.query?.id,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        state.loadingConfirm = false;
        state.contract_list = data;
        if (data?.length) {
          const typeList1 = data.filter((v) => v.id == form.list.contract_id);
          if (typeList1.length) {
            state.checkInfo = typeList1[0];
            form.list.contract_id = typeList1[0].id;
            form.list.contract_name = typeList1[0].name;
            form.list.contract_no = typeList1[0].contract_no;
            form.list.house_size = typeList1[0].house_size;
          } else {
            state.checkInfo = data[0];
            form.list.contract_id = data[0].id;
            form.list.contract_name = data[0].name;
            form.list.contract_no = data[0].contract_no;
            form.list.house_size = data[0].house_size;
          }
          getConstructionTemplateList();
        }
      } else {
        state.loadingConfirm = false;
        Toast(res.data);
      }
    });
};
//-@根据合同取模板
const getConstructionTemplateList = async () => {
  await $http
    .getConstructionTemplateList({
      contract_id: form.list.contract_id,
      house_size: form.list.house_size,
    })
    .then((res) => {
      if (res.code === 0) {
        state.templateList = res.data;
        form.list.template_id = state.templateList[0].id;
        form.list.template_name = state.templateList[0].name;
        if (form.list.start_date) {
          btnSumbit();
        }
      } else {
        state.templateList = [];
        Toast(res.data);
      }
    });
};

//-获取参数--end

//-@日期
const currentDate = ref(new Date());
const changePicker = (value) => {
  state.time_show = false;
  form.list.start_date = moment(value).format('YYYY-MM-DD');
  btnSumbit();
};
//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goConfirm();
    })
    .catch(() => {
      //验证失败
      Toast('请完善信息');
    });
};
const goConfirm = () => {
  state.loadingConfirm = true;
  Object.assign(state.calcContractList, {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  });
  $http
    .construction_calcSchedule({
      template_id: form.list.template_id,
      start_date: form.list.start_date,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Object.assign(state.calcContractList, data);
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
// const goBack = () => {
//   router.back();
// };
//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
.change-box-top {
  border: 1px solid @line-color;
  padding: 10px;
  border-radius: 2px;
}
.temp_info {
  font-size: 15px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid @line-color;
  .item {
    margin-bottom: 10px;
  }
  .temp_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn_temp {
      font-size: 15px;
      line-height: 18px;
      text-decoration: underline;
      color: #0256ff;
    }
  }
}
.schedule_box {
  padding-top: 10px;
  .schedule_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
}
</style>
