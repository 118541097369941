<!--
 * @Author: NanNan
 * @Date: 2022-06-28 09:13:16
 * @LastEditTime: 2024-06-14 11:08:32
 * @Description:V1.3.7 主材订单详情-订单记录
-->
<template>
  <div class="order_reord">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <List
      v-model:loading="_DATA.loading"
      :finished="_DATA.finished"
      :offset="20"
      @load="loadFunc"
    >
      <div
        v-for="(item, index) in _DATA.list_data"
        :key="index"
        class="same-box"
      >
        <div class="title-box">
          <p class="time">{{ item.add_time }}</p>
          <p class="title">{{ item.type_name }}</p>
        </div>

        <div class="con-box">
          <dl class="cus-message">
            <dt>
              <lazy-component>
                <Image
                  v-if="item.face_image"
                  :src="
                    item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                  "
                  v-lazy
                  fit="cover"
                  round
                />
                <img
                  :src="require('@/assets/images/icon-empty-img.png')"
                  v-else
                />
              </lazy-component>
            </dt>
            <dd>
              <p class="name">{{ item.user_name }}</p>
              <p class="depart">
                {{
                  item.department_name +
                  (item.department_name && item.post_name ? '/' : '') +
                  item.post_name
                }}
              </p>
            </dd>
          </dl>

          <!-- <dl class="same-flex" v-for="(val, key) in item.result" :key="val">
            <dt>{{ key }}:</dt>
            <dd>{{ val }}</dd>
          </dl> -->
          <div class="comment_info">{{ item.comment }}</div>

          <!-- 附件 -->
          <div class="fileList" v-if="item.attach && item.attach.length > 0">
            <FileGrid :data="item.attach"></FileGrid>
          </div>
          <!-- end -->

          <!-- 评论区域 -->
          <div class="review-box">
            <p class="title">
              <span style="font-weight: bold; font-size: 15px">
                {{ item.comment_list.length > 0 ? '评论' : '' }}
              </span>
              <a @click="handleAdd(item.id, index)">添加评论</a>
            </p>
            <div class="content" v-if="item.comment_list.length > 0">
              <dl
                class="same-flex"
                v-for="(val, idx) in item.comment_list"
                :key="idx"
              >
                <dt>
                  <lazy-component>
                    <Image
                      width="32px"
                      height="32px"
                      fit="cover"
                      :src="
                        val.face_image.includes('http')
                          ? val.face_image
                          : $host + val.face_image
                      "
                      v-if="val.face_image"
                      round
                    />
                    <Image
                      width="32px"
                      height="32px"
                      :src="require('@/assets/images/icon-empty-img.png')"
                      v-else
                    />
                  </lazy-component>
                </dt>
                <dd>
                  <p class="name">{{ val.user_name }}</p>
                  <p class="depart">
                    {{
                      val.department_name +
                      (val.department_name && val.post_name ? '/' : '') +
                      val.post_name
                    }}
                  </p>
                  <p class="remark">
                    {{ val.content }}
                  </p>
                  <p class="time">
                    <span>{{ val.add_time }}</span>
                    <a
                      v-if="val.delete_flag === 1"
                      @click="handleDel(val.id, index)"
                    >
                      删除
                    </a>
                  </p>
                </dd>
              </dl>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
      <template v-if="_DATA.list_data.length > 0" #finished>
        <Divider class="divider-bottom" style="margin: 0 20px">
          我也是有底线的
        </Divider>
      </template>
    </List>
    <!-- 空数据 -->
    <div
      class="empty-box"
      v-if="!_DATA.loading && _DATA.list_data?.length == 0"
    >
      <img :src="require('@/assets/images/empty-img.png')" alt="" />
      <p>暂无跟进记录</p>
    </div>
  </div>
  <!-- 面板 -->
  <Popup v-model:show="_DATA.show" position="bottom">
    <Field
      v-model="_DATA.form.remark"
      center
      clearable
      placeholder="请填写评论"
      @blur="handleBlur"
      ref="inputField"
      style="border: none"
    >
      <template #button>
        <Button size="small" type="primary" @click="handleSend">发送</Button>
      </template>
    </Field>
  </Popup>
</template>
<script setup>
import FileGrid from '@/components/common/FileGrid';
import { ref, reactive, defineProps, inject, onMounted } from 'vue';
import {
  Field,
  Popup,
  Notify,
  List,
  Overlay,
  Loading,
  Button,
  Image,
  Divider,
} from 'vant';
const $http = inject('$http');
const $host = inject('$host');
const props = defineProps(['id']);
const inputField = ref(null);
const _DATA = reactive({
  loading: false,
  finished: false,
  list_data: [],
  show: false,
  form: {
    record_id: '',
    remark: '',
    index: 0,
  },
});
const page = reactive({
  page: 1,
  pages: 10,
  total: 0,
  count_page: 1,
});
onMounted(() => {});
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .mainOrder_orderLogList({
      id: props.id,
      page: page.page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list_data = _DATA.list_data.concat(data.list);
        Object.assign(page, { ...data.page });
        if (data.page.count_page > data.page.page) {
          page.page++;
        } else {
          _DATA.finished = true;
        }
      } else {
        _DATA.finished = true;
        Notify({ type: 'warning', message: data });
      }
    });
};
//-@ 击添加评论
const handleAdd = (id, index) => {
  _DATA.show = true;
  _DATA.form.record_id = id;
  _DATA.form.remark = '';
  _DATA.form.index = index;
  setTimeout(() => {
    inputField.value.focus();
  }, 100);
};

//-@ 点击完成
const handleBlur = () => {
  inputField.value.blur();
  // show.value = false;
};

//-@ 点击发送
const handleSend = () => {
  $http.mainOrder_addComment({ ..._DATA.form }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.show = false;
      _DATA.loading = true;
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          const index = Math.ceil((_DATA.form.index + 1) / 10);
          commonFunc(index);
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-@ 删除评论
const handleDel = (id, idx) => {
  _DATA.loading = true;
  $http.mainOrder_delComment({ id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          const index = Math.ceil((idx + 1) / 10);
          commonFunc(index);
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-@ 删除、添加之后的操作
const commonFunc = (page) => {
  _DATA.loading = true;
  $http
    .mainOrder_orderLogList({
      id: props.id,
      page: page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list_data.splice((page - 1) * 10, 10, ...data.list);
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
</script>
<style lang="less" scoped>
.order_reord {
  .same-box {
    margin: 10px 0;
    .title-box {
      padding: 0 15px 15px;
      .time {
        font-size: 14px;
        color: @grey-color;
        line-height: 18px;
        padding-bottom: 4px;
      }

      .title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }

    .con-box {
      background: #fff;
      padding: 15px 20px 0 30px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 0px;
        width: 1px;
        background: @line-color;
        height: 100%;
      }

      dl.same-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 5px;

        dt {
          color: @grey-color;
          font-size: 14px;
          line-height: 18px;
          padding-right: 10px;
          flex-shrink: 0;
        }

        dd {
          flex-grow: 1;
          font-size: 14px;
          line-height: 18px;
          flex-wrap: wrap;
          word-break: break-all;
        }
      }

      dl.cus-message {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 15px;

        dt {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          flex-grow: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          & > div {
            width: 35px;
            height: 35px;

            & > div {
              width: 35px;
              height: 35px;
            }
          }

          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
        }

        dd {
          flex-grow: 1;

          .name {
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 3px;
          }

          .depart {
            color: @grey-color;
            font-size: 12px;
          }
        }
      }
      .comment_info {
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
      }
      .fileList {
        margin-top: 15px;
      }

      .review-box {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          margin-top: 20px;
          padding-bottom: 15px;

          a {
            font-size: 14px;
            color: @blue-color;
          }
        }

        .content {
          border-top: 0.5px solid @line-color;
          padding-bottom: 20px;

          .same-flex {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 20px 0 0;

            dt {
              min-width: 35px;
              height: 35px;
              margin-right: 10px;
              flex-grow: 0;
              width: 35px;

              img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
              }
            }

            dd {
              flex-grow: 1;

              .name {
                font-size: 14px;
                line-height: 16px;
                padding-bottom: 3px;
              }

              .depart {
                color: @grey-color;
                font-size: 12px;
              }

              .remark {
                line-height: 18px;
                font-size: 14px;
                margin: 10px 0;
              }

              .time {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                height: 18px;

                span {
                  color: @grey-color;
                  font-size: 12px;
                }

                a {
                  color: @red-color;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.empty-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 90px;
  background: #ffffff;
  img {
    width: 190px;
    height: 190px;
  }

  p {
    color: #3d3d3d;
    font-size: 13px;
    padding-bottom: 30px;
  }
}
</style>
