/*
 * @Author: gest
 * @Date: 2023-05-10 10:56:52
 * @LastEditTime: 2024-06-13 11:39:48
 * @Description:V1.3.7 订单
 */
import Service from '@/utils/request';

export default {
  //主材订单列表
  mainOrder_list(params) {
    return Service.post(`/rs/mainOrder/list`, params);
  },
  //主材订单详情
  mainOrder_info(params) {
    return Service.post(`/rs/mainOrder/info`, params);
  },
  //主材订单公共参数
  mainOrder_getParams(params) {
    return Service.post(`/rs/mainOrder/getParams`, params);
  },
  //主材订单详情-编辑标注、备注
  mainOrder_save(params) {
    return Service.post(`/rs/mainOrder/save`, params);
  },

  //主材订单-材料明细
  mainOrder_materialDetails(params) {
    return Service.post(`/rs/mainOrder/materialDetails`, params);
  },
  //主材订单-订单附件
  mainOrder_orderAttachment(params) {
    return Service.post(`/rs/mainOrder/orderAttachment`, params);
  },
  //主材订单-订单记录
  mainOrder_orderLogList(params) {
    return Service.post(`/rs/mainOrder/orderLogList`, params);
  },
  //主材订单-罚款记录
  mainOrder_penaltyRecord(params) {
    return Service.post(`/rs/mainOrder/penaltyRecord`, params);
  },
  //主材订单-添加评论
  mainOrder_addComment(params) {
    return Service.post(`/rs/mainOrder/addComment`, params);
  },
  //主材订单-删除评论
  mainOrder_delComment(params) {
    return Service.post(`/rs/mainOrder/delComment`, params);
  },
  //主材订单-跟单
  mainOrder_trackOrder(params) {
    return Service.post(`/rs/mainOrder/trackOrder`, params);
  },
  //主材订单-申请下单
  mainOrder_applyPlaceOrder(params) {
    return Service.post(`/rs/mainOrder/applyPlaceOrder`, params);
  },
  //主材订单
  //req_type 1:通知测量；2：通知送货
  mainOrder_notifyMeasure(params) {
    return Service.post(`/rs/mainOrder/notifyMeasure`, params);
  },
  //获取订单产品列表
  mainOrder_orderProductList(params) {
    return Service.post(`/rs/mainOrder/orderProductList`, params);
  },
  //主材订单
  //req_type 1:现场到货验收、2通知安装、3安装验收
  mainOrder_productMove(params) {
    return Service.post(`/rs/mainOrder/productMove`, params);
  },

  //施工-安装计划
  mainOrder_installPlan(params) {
    return Service.post(`/rs/mainOrder/installPlan`, params);
  },
  //订单-安装计划表
  mainOrder_installTimeList(params) {
    return Service.post(`/rs/mainOrder/installTimeList`, params);
  },
  //自购产品订单
  mainSelfOrder_getParams(params) {
    return Service.post(`/rs/mainSelfOrder/getParams`, params);
  },
  //自购产品-空间列表
  mainSelfOrder_spaceList(params) {
    return Service.post(`/rs/mainSelfOrder/spaceList`, params);
  },
  //自购产品-获取子类
  mainSelfOrder_getSubCategory(params) {
    return Service.post(`/rs/mainSelfOrder/getSubCategory`, params);
  },
  //新增/编辑自购订单
  mainSelfOrder_save(params) {
    return Service.post(`/rs/mainSelfOrder/save`, params);
  },
  //自购订单列表
  mainSelfOrder_list(params) {
    return Service.post(`/rs/mainSelfOrder/list`, params);
  },
  //自购订单详情
  mainSelfOrder_info(params) {
    return Service.post(`/rs/mainSelfOrder/info`, params);
  },
  //自购-材料明细
  mainSelfOrder_materialDetails(params) {
    return Service.post(`/rs/mainSelfOrder/materialDetails`, params);
  },
  //自购-订单记录
  mainSelfOrder_orderLogList(params) {
    return Service.post(`/rs/mainSelfOrder/orderLogList`, params);
  },
  //自购-添加评论
  mainSelfOrder_addComment(params) {
    return Service.post(`/rs/mainSelfOrder/addComment`, params);
  },
  //自购-删除评论
  mainSelfOrder_delComment(params) {
    return Service.post(`/rs/mainSelfOrder/delComment`, params);
  },
  //自购-安装时间表
  mainSelfOrder_installTimeList(params) {
    return Service.post(`/rs/mainSelfOrder/installTimeList`, params);
  },
  //自购-标注/备注编辑
  mainSelfOrder_markSave(params) {
    return Service.post(`/rs/mainSelfOrder/markSave`, params);
  },
  //自购-跟单
  mainSelfOrder_trackOrder(params) {
    return Service.post(`/rs/mainSelfOrder/trackOrder`, params);
  },
  //自购-客户下单
  mainSelfOrder_formallyPlaceOrder(params) {
    return Service.post(`/rs/mainSelfOrder/formallyPlaceOrder`, params);
  },
  //自购-完成测量
  mainSelfOrder_measureComplete(params) {
    return Service.post(`/rs/mainSelfOrder/measureComplete`, params);
  },
  //自购-现场到货验收/安装验收
  mainSelfOrder_productMove(params) {
    return Service.post(`/rs/mainSelfOrder/productMove`, params);
  },
  //自购-删除
  mainSelfOrder_delOrder(params) {
    return Service.post(`/rs/mainSelfOrder/delOrder`, params);
  },
  //自购-作废
  mainSelfOrder_invalidOrder(params) {
    return Service.post(`/rs/mainSelfOrder/invalidOrder`, params);
  },
  //自购-获取订单产品列表
  mainSelfOrder_orderProductList(params) {
    return Service.post(`/rs/mainSelfOrder/orderProductList`, params);
  },
  //自购-新增空间
  mainSelfOrder_spaceBatchSave(params) {
    return Service.post(`/rs/mainSelfOrder/spaceBatchSave`, params);
  },
  //结算付款记录
  mainOrder_settlementRecords(params) {
    return Service.post(`/rs/mainOrder/settlementRecords`, params);
  },
};
