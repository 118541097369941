<!--
 * @Author: NanNan
 * @Date: 2023-07-11 14:37:00
 * @LastEditTime: 2023-09-15 14:54:08
 * @Description: v1.4.1 派单详情 - 表单填写
-->
<template>
  <div class="page-wrap">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <h1>
      <em>*</em>
      <b>派单信息</b>
      <span>设计中心确认接单后可见</span>
    </h1>
    <!-- 表单开始 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">客户姓名</label>
            <Field
              v-model="ruleForm.customer_name"
              autosize
              maxlength="20"
              placeholder="请填写客户姓名"
            />
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计见面时间
            </label>
            <Field
              v-model="ruleForm.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择预计见面时间"
              disabled
            />
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面方式
            </label>
            <Field
              v-model="ruleForm.face_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              disabled
              placeholder="请选择见面方式"
            />
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              派单级别
            </label>
            <Field
              v-model="ruleForm.dispatch_level_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择派单级别"
              disabled
            />
          </div>

          <div class="cell">
            <label class="form_title">碰面地点</label>
            <Field
              v-model="ruleForm.meet_addr"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              show-word-limit
            />
          </div>

          <!-- 楼盘 -->
          <div class="cell">
            <label class="form_title floor_title">
              楼盘
              <Checkbox
                v-model="ruleForm.floor_check"
                shape="square"
                style="font-size: 13px; color: #767676"
                :icon-size="16"
                @change="changeNotFound"
              >
                找不到楼盘
              </Checkbox>
            </label>
            <Field
              v-model="ruleForm.floor_name"
              readonly
              right-icon="arrow-down"
              placeholder="未知"
              @click="
                () => {
                  ruleForm.floor_check
                    ? (picker.floor_show = false)
                    : (picker.floor_show = true);
                }
              "
              :disabled="ruleForm.floor_check"
            />
          </div>
          <!-- end -->
          <!-- 区域 -->
          <div class="cell">
            <label class="form_title floor_title">
              区域
              <Checkbox
                v-model="ruleForm.is_city_unknown_type"
                shape="square"
                style="font-size: 13px; color: #767676"
                :icon-size="16"
                @change="changeNotFoundCity"
              >
                区域未知
              </Checkbox>
            </label>
            <Field
              v-model="ruleForm.city_name"
              readonly
              placeholder="选择区域位置"
              @click="picker.city_show = true"
              :disabled="ruleForm.is_city_unknown_type"
            />
          </div>
          <!-- end -->

          <div class="cell">
            <label class="form_title">详细地址</label>
            <Field v-model="ruleForm.address" autosize maxlength="50" />
          </div>

          <div class="cell">
            <label class="form_title">产证面积</label>
            <Field
              type="number"
              v-model="ruleForm.size"
              autosize
              :rules="[
                {
                  required: false,
                  message: '请填写正确产证面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>

          <div class="cell">
            <label class="form_title">扩建面积</label>
            <Field
              type="number"
              v-model="ruleForm.extend_size"
              autosize
              :rules="[
                {
                  required: false,
                  message: '请填写正确扩建面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>

          <div class="cell">
            <label class="form_title">喜欢风格</label>
            <Field
              v-model="ruleForm.like_style"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              show-word-limit
            />
          </div>

          <div class="cell">
            <label class="form_title">对设计师要求</label>
            <Field
              v-model="ruleForm.design_need"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              show-word-limit
            />
          </div>

          <div class="cell">
            <label class="form_title">邀约方式</label>
            <Field
              v-model="ruleForm.invite_method"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              show-word-limit
            />
          </div>

          <div class="cell">
            <label class="form_title">到访人数</label>
            <Field
              v-model="ruleForm.visit_num"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="请填写到访人数"
              show-word-limit
            />
          </div>

          <div class="cell">
            <label class="form_title">关注问题</label>
            <Field
              v-model="ruleForm.care_problem"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="请填写关注问题"
              show-word-limit
            />
          </div>

          <div class="cell">
            <label class="form_title">建议准备</label>
            <Field
              v-model="ruleForm.suggested_pre"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="请填写建议准备"
              show-word-limit
            />
          </div>

          <div class="cell">
            <label class="form_title">客户情况</label>
            <Field
              v-model="ruleForm.customer_situation"
              rows="5"
              autosize
              type="textarea"
              maxlength="2000"
              placeholder="请填写客户情况"
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
      <!-- 楼盘名称 -->
      <Popup
        v-model:show="picker.floor_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempFloor
          v-if="picker.floor_show"
          :kw="ruleForm.floor_name"
          :check="ruleForm.floor_id"
          @reviewClose="
            () => {
              picker.floor_show = false;
            }
          "
          @reviewOk="reviewOk_floor"
        />
      </Popup>
      <!-- 区域位置 -->
      <Popup v-model:show="picker.city_show" position="bottom">
        <Picker
          :columns="props?.cityList"
          :columns-field-names="{
            text: 'name',
            value: 'id',
            children: 'sub',
          }"
          cancel-button-text="清除"
          @confirm="changeCityPicker"
          @cancel="clearCityPicker"
          ref="cityRef"
        />
      </Popup>
    </div>
    <!-- end -->
  </div>
</template>
<script setup>
import {
  Form,
  CellGroup,
  Field,
  // Button,
  Popup,
  // DatetimePicker,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  Checkbox,
  // Icon,
  // Dialog,
} from 'vant';
import {
  inject,
  reactive,
  onMounted,
  defineProps,
  computed,
  defineExpose,
} from 'vue';
import patterns from '@/utils/pattern';
import TempFloor from '@/components/clue/TempFloor';

const { houseSizeReg } = patterns;
const $http = inject('$http');
const props = defineProps(['data', 'customerObj', 'cityList']);
const _DATA = reactive({
  loading: false,
});

const ruleForm = reactive({
  customer_id: props?.customerObj?.customer_id, //客户id
  customer_name: props?.customerObj?.customer_name, //客户姓名
  dispatch_level: computed(() => {
    return props?.data?.dispatch_level
      ? Number(props?.data?.dispatch_level)
      : '';
  }), //派单级别
  dispatch_level_name: computed(() => {
    return props?.data?.dispatch_level_name || '';
  }), //派单级别name
  face_mode: computed(() => {
    return props?.data?.face_mode;
  }), //见面方式
  face_mode_name: computed(() => {
    return props?.data?.face_mode_name;
  }), //见面方式
  face_time: computed(() => {
    return props?.data?.face_time;
  }), //预计见面时间
  meet_addr: '未知', //碰面地点
  is_not_found_floor: 0, // 1是 0否
  floor_check: false, //
  floor_id: '', //
  floor_name: '', //
  city_id: '',
  city_name: '',
  is_city_unknown: 2, //区域未知 1是2否
  is_city_unknown_type: false, //区域未知
  address: '未知', //
  size: 0, //产证面积 undefined
  extend_size: 0, //扩建面积
  like_style: '未知', //喜欢风格
  design_need: '未知', //对设计师要求
  invite_method: '未知', //邀约方式
  visit_num: '未知', //到访人数
  care_problem: '未知', //关注问题
  suggested_pre: '未知', //建议准备
  customer_situation: '未知', //客户情况
});

const picker = reactive({
  floor_show: false,
  city_show: false,
});

// watch(
//   () => props?.data?.face_mode,
//   (cur) => {
//     if (cur == 298) {
//       ruleForm.is_city_unknown_type = false;
//       ruleForm.is_city_unknown = 2;
//     }
//   }
// );
onMounted(() => {
  props?.customerObj?.customer_id && loadInfo(props?.customerObj?.customer_id);
  console.log(props);
});

/**
 * 接口
 */
// -- 详情接口
const loadInfo = (customer_id) => {
  $http.customerManagement_getDispatchDetail({ customer_id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      ruleForm.customer_id =
        data?.customer_id || props?.customerObj?.customer_id;
      ruleForm.customer_name =
        data?.customer_name || props?.customerObj?.customer_name;
      ruleForm.meet_addr = data?.meet_addr || '未知';
      ruleForm.is_not_found_floor = data?.is_not_found_floor;
      ruleForm.floor_check = data?.is_not_found_floor == 0 ? false : true;
      ruleForm.floor_id = data?.floor_id;
      ruleForm.floor_name = data?.floor_name;
      ruleForm.city_id = data?.city_id;
      ruleForm.city_name = data?.city_name;
      ruleForm.is_city_unknown = data?.is_city_unknown;
      ruleForm.is_city_unknown_type = data?.is_city_unknown == 1 ? true : false;
      ruleForm.address = data?.address || '未知';
      ruleForm.size = data?.size || 0;
      ruleForm.extend_size = data?.extend_size || 0;
      ruleForm.like_style = data?.like_style || '未知';
      ruleForm.design_need = data?.design_need || '未知';
      ruleForm.invite_method = data?.invite_method || '未知';
      ruleForm.visit_num = data?.visit_num || '未知';
      ruleForm.care_problem = data?.care_problem || '未知';
      ruleForm.suggested_pre = data?.suggested_pre || '未知';
      ruleForm.customer_situation = data?.customer_situation || '未知';
    } else {
      Toast(data);
    }
  });
};

// -- 提交接口
const submitApi = () => {
  return new Promise((resolve, reject) => {
    ruleForm.is_not_found_floor = ruleForm.floor_check ? 1 : 0;
    ruleForm.is_city_unknown = ruleForm.is_city_unknown_type ? 1 : 2;
    $http.customerManagement_editDispatchDetail({ ...ruleForm }).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        console.log(data);
        resolve('ok');
      } else {
        Notify({ type: 'warning', message: data });
        reject('error');
      }
    });
  });
};
defineExpose({ submitApi });

/**
 * 操作
 */
// -- 面积校验
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

// -- 楼盘popup
const floor_detail_search_list = reactive([]);
const reviewOk_floor = (item, list) => {
  ruleForm.floor_id = item?.id || '';
  ruleForm.floor_name = item?.name || '';
  floor_detail_search_list.value = list;
  picker.floor_show = false;
  console.log(item, list);
};

// -- 找不到楼盘
const changeNotFound = (value) => {
  console.log(value);
  if (value) {
    ruleForm.floor_id = '';
    ruleForm.floor_name = '';
  }
};

// -- 选择城市
const changeCityPicker = (value) => {
  picker.city_show = false;
  ruleForm.city_id = value[2].code;
  ruleForm.city_name =
    value[0].name + '/' + value[1].name + '/' + value[2].name;
};

// -- 选择器 清除城市
const clearCityPicker = () => {
  picker.city_show = false;
  ruleForm.city_id = '';
  ruleForm.city_name = '';
};

// 区域未知
const changeNotFoundCity = (value) => {
  console.log(value);
  if (value) {
    ruleForm.city_id = '';
    ruleForm.city_name = '';
    ruleForm.is_city_unknown = 1;
  } else {
    ruleForm.is_city_unknown = 2;
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
.floor_title {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}
.page-wrap {
  position: relative;
  padding: 64px 0 20px !important;

  h1 {
    position: absolute;
    left: -24px;
    top: 0;
    width: calc(100vw);
    display: flex;
    align-items: center;
    justify-content: left;
    height: 52px;
    font-size: 15px;
    background: #f5f5f5;
    em {
      color: #ea0000;
      margin: 0 2px 0 20px;
    }
    b {
      font-weight: 500;
    }
    span {
      color: #f37020;
      margin-left: 5px;
      font-size: 13px;
    }
  }
}
</style>
