<!--
 * @Author: gest
 * @Date: 2023-03-24 15:38:14
 * @LastEditTime: 2023-03-31 16:55:00
 * @Description: v1.3.4 施工方案预演会表单
-->
<template>
  <div class="formBase">
    <CellGroup>
      <!-- 方案评审 -->
      <div class="content">
        <h3>方案内容</h3>
        <div class="list">
          <div class="cell">
            <div>施工图评审</div>
            <div class="right">
              <p v-if="form.info.review">
                {{
                  form.info.review
                    ? form.info.review == 1
                      ? '通过盖章'
                      : '未通过盖章'
                    : '-'
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="cell">
            <div>施工报价是否和资金规划匹配</div>
            <div class="right">
              <p>
                {{
                  form.info.cash_match
                    ? form.info.cash_match == 1
                      ? '是'
                      : '否'
                    : '-'
                }}
              </p>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">每平米报价</div>
            <div class="right">
              <span class="name_black">
                {{ form.info.per_money == '' ? '-' : form.info.per_money }}
                <span class="ml_5">元</span>
              </span>
            </div>
          </div>
          <div class="cell" style="display: block">
            <div class="name_grey">超出原因</div>
            <div class="remark mt_10">{{ form.info.exceed_remark || '-' }}</div>
          </div>
        </div>
        <div class="list">
          <div class="cell">
            <div>是否扩建</div>
            <div class="right">
              <p>
                {{
                  form.info.expand ? (form.info.expand == 1 ? '是' : '否') : '-'
                }}
              </p>
            </div>
          </div>
          <div class="cell" v-if="form.info.expand && form.info.expand == 1">
            <div class="name_grey">原始面积</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.origin_area == undefined ||
                  form.info.origin_area == ''
                    ? '-'
                    : form.info.origin_area
                }}
                <span class="ml_5">㎡</span>
              </span>
            </div>
          </div>
          <div class="cell" v-if="form.info.expand && form.info.expand == 1">
            <div class="name_grey">扩建面积</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.expand_area == undefined ||
                  form.info.expand_area == ''
                    ? '-'
                    : form.info.expand_area
                }}
                <span class="ml_5">㎡</span>
              </span>
            </div>
          </div>
          <div class="cell" v-if="form.info.expand && form.info.expand == 1">
            <div class="name_grey">扩建金额</div>
            <div class="right">
              <span class="name_black">
                {{
                  form.info.expand_money == undefined ||
                  form.info.expand_money == ''
                    ? '-'
                    : form.info.expand_money
                }}
                <span class="ml_5">元</span>
              </span>
            </div>
          </div>
        </div>

        <div class="list mt_15">
          <div class="cell">
            <div>对这次“方案通过”有信心吗？</div>
            <div class="right">
              <p>
                <span class="score">
                  {{
                    !form.info.pass_confidence &&
                    form.info.pass_confidence !== 0
                      ? '-'
                      : form.info.pass_confidence
                  }}
                </span>
                <span class="score_desc">分</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 商务洽谈 -->
      <div class="content">
        <h3>商务洽谈</h3>
        <div class="list">
          <div class="cell">
            <div class="name_grey">决策人</div>
            <div class="right">
              <span class="name_black">{{ form.info.policy_man || '-' }}</span>
            </div>
          </div>
          <div class="cell">
            <div class="name_grey">见面时间</div>
            <div class="right">
              <span class="name_black">{{ form.info.face_time || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="list mt_15">
          <div class="cell">
            <div>对这次的“收款成功”有信心吗？</div>
            <div class="right">
              <p>
                <span class="score">
                  {{
                    !form.info.success_confidence &&
                    form.info.success_confidence !== 0
                      ? '-'
                      : form.info.success_confidence
                  }}
                </span>
                <span class="score_desc">分</span>
              </p>
            </div>
          </div>
        </div>
        <div class="list mt_15 mb_15">
          <div class="cell">
            <div class="name_grey">收款方式</div>
            <div class="right">
              <span class="name_black">
                {{ form.info.money_type_name || '-' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </CellGroup>
  </div>
</template>

<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { CellGroup } from 'vant';

const props = defineProps(['data']);
const form = reactive({
  info: {},
});
// 参数--调用接口
onMounted(() => {
  form.info = props.data;
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessDetail.less';
</style>
