<!--
 * @Author: Jessica
 * @Date: 2024-05-23 16:56:55
 * @LastEditTime: 2024-05-31 10:38:44
 * @Description:V1.6.1 新增转化产值-三级页面
 区分 当年/往年
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title flex_line_between">
            转化产值
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!-- 总计：当年 -->
          <div v-if="props.data?.now_year == props.data?.yearId">
            <Row>
              <Col span="8">
                <div class="grey align_center">目标(万)</div>
                <div>
                  <span class="size17 bold align_center">
                    {{ _DATA.info?.target === '' ? '-' : _DATA.info?.target }}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div class="grey align_center">完成(万)</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.actual || _DATA.info.actual === 0
                        ? _DATA.info.actual
                        : '-'
                    }}
                  </span>
                </div>
                <div v-if="_DATA.info?.gap !== ''">
                  <div
                    class="flex_line flex_line_center"
                    v-if="_DATA.info?.actual > _DATA.info?.target"
                  >
                    <span class="bold green"> {{ _DATA.info?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <div
                    class="flex_line flex_line_center"
                    v-else-if="_DATA.info?.actual < _DATA.info?.target"
                  >
                    <span class="bold orange"> {{ _DATA.info?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <div class="flex_line flex_line_center" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </Col>
              <Col span="8">
                <div class="grey align_center">完成率</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.done_rate || _DATA.info.done_rate === 0
                        ? _DATA.info.done_rate + '%'
                        : '-'
                    }}
                  </span>
                </div>
              </Col>
            </Row>
            <Row class="pt_20">
              <Col span="8">
                <div class="grey align_center">B+数</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.b_plus_num || _DATA.info.b_plus_num === 0
                        ? _DATA.info.b_plus_num
                        : '-'
                    }}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div class="grey align_center">B+率</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.b_plus_rate || _DATA.info.b_plus_rate === 0
                        ? _DATA.info.b_plus_rate + '%'
                        : '-'
                    }}
                  </span>
                </div>
              </Col>
              <Col span="8"></Col>
            </Row>
          </div>
          <!-- 总计：往年 -->
          <div v-else>
            <Row>
              <Col span="8">
                <div class="grey align_center">完成(万)</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.actual || _DATA.info.actual === 0
                        ? _DATA.info.actual
                        : '-'
                    }}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div class="grey align_center">B+数</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.b_plus_num || _DATA.info.b_plus_num === 0
                        ? _DATA.info.b_plus_num
                        : '-'
                    }}
                  </span>
                </div>
              </Col>
              <Col span="8">
                <div class="grey align_center">B+率</div>
                <div>
                  <span class="size17 bold align_center">
                    {{
                      _DATA.info.b_plus_rate || _DATA.info.b_plus_rate === 0
                        ? _DATA.info.b_plus_rate + '%'
                        : '-'
                    }}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div class="data_card_table border_top">
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">人员</div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col
              :span="props.data?.now_year == props.data?.yearId ? 11 : 15"
              style="position: relative"
            >
              <div class="chart_pos pl_10">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
                <div class="chart_right"></div>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="4" v-if="props.data?.now_year == props.data?.yearId">
              <div class="grey align_center">完成率</div>
            </Col>
            <Col span="4">
              <div class="grey align_center">B+数/率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row v-if="index + 1 <= _DATA.maxCount || _DATA.open">
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ item.name }}
                </div>
              </Col>
              <!-- 进度图内容 begin-->
              <Col
                :span="props.data?.now_year == props.data?.yearId ? 11 : 15"
                class="chart_item"
              >
                <!-- 当年 -->
                <div
                  v-if="props.data?.now_year == props.data?.yearId"
                  style="display: flex"
                  class="pl_10"
                >
                  <chartJD
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      actual: item.actual,
                      target: item.target ? item.target : item.actual,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ item?.target === '' ? '-' : item?.target }}
                  </div>
                </div>
                <!-- 往年 -->
                <div v-else style="display: flex" class="pl_10">
                  <chartJD
                    :data="{
                      type: 3,
                      list: _DATA.line_values,
                      actual: item.actual,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ item?.target }}
                  </div>
                </div>
                <!-- 竖线 begin-->
                <div class="chart_pos pl_10">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      num: _DATA.middle_value,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 进度图内容 end-->
              <Col
                span="4"
                class="flex_line_center"
                v-if="props.data?.now_year == props.data?.yearId"
              >
                <div>
                  {{ item?.done_rate === '' ? '-' : item?.done_rate + '%' }}
                </div>
              </Col>
              <Col span="4" class="flex_line_center">
                <div>
                  <div class="flex_line_center">
                    <span>{{
                      item.b_plus_num || item.b_plus_num === 0
                        ? item.b_plus_num
                        : '-'
                    }}</span>
                  </div>
                  <div class="flex_line_center">
                    <span>{{
                      item.b_plus_rate || item.b_plus_rate === 0
                        ? item.b_plus_rate + '%'
                        : '-'
                    }}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 -->
        <div
          class="p_15 flex_center"
          v-if="props.data?.now_year == props.data?.yearId"
        >
          <p class="box_8 grey_bg"></p>
          <p class="ml_5">目标</p>
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
          <p class="box_8 red2_bg ml_30"></p>
          <p class="ml_5">超额</p>
        </div>
      </Skeleton>
    </div>

    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  defineExpose,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  //-在全部数据||部门数据不同页面所处的位置
  index: 4,
  depIndex: 4, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  collapse_active: [], //锁定
  line_values: [],
  middle_value: '',
});

//-监听 切换部门-切换路由
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);

onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  $http
    .targetStatistic_personConvertPerformance({
      department_id: props.data.depId,
      year: props.data.yearId,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.info = data.info;
        _DATA.list = data.list;
        _DATA.line_values = data.line_values;
        _DATA.middle_value = data.middle_value;
      } else {
        _DATA.info = {};
        Notify({
          type: 'warning',
          message: data,
        });
      }
      emit('loadEnd');
    });
};

//-规则说明--begin
const htmlTitle = '转化产值_规则';
const htmlContent = `<p>1、完成=定制产值+整装产值；</p>
  <p>2、当年签的协议的转化项目【产品&施工业绩】/施工面积≥5000 算B+；</p>`;
const createDanger = () => {
  emit('createDanger', 'transformPerformanceRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
