<!--
 * @Author: Jessica
 * @Date:2022-12-29 16:49:01
 * @LastEditTime: 2023-08-01 15:52:54
 * @Description: V1.3.8分派施工团队
-->
<template>
  <!-- 分派施工团队-->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="state.contract_list?.length > 0"
      @click="
        () => {
          state.contract_list?.length > 1
            ? (state.showPicker_contract = true)
            : null;
        }
      "
    >
      <div class="left">
        <h1>{{ form.list.contract_name }}</h1>
        <p>合同编号：{{ form.list.contract_no }}</p>
      </div>
      <div class="right" v-if="state.contract_list?.length > 1">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工长
            </label>
            <Field
              v-model="form.list.worker_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择工长"
              :rules="[
                {
                  required: true,
                  message: '请选择工长',
                },
              ]"
              @click="state.showPicker_worker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              现场负责人
            </label>
            <Field
              v-model="form.list.scene_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择现场负责人"
              v-if="form.list.worker_name"
              :rules="[
                {
                  required: true,
                  message: '请选择现场负责人',
                },
              ]"
              @click="state.showPicker_scene = true"
            />
            <Field
              v-model="form.list.scene_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择现场负责人"
              disabled
              v-else
              :rules="[
                {
                  required: true,
                  message: '请选择现场负责人',
                },
              ]"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工队
            </label>
            <Field
              v-model="form.list.team_name"
              right-icon="arrow-down"
              disabled
              readonly
              name="picker"
              placeholder="请选择工队"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker 工长--->
    <Popup v-model:show="state.showPicker_worker" position="bottom">
      <Picker
        :columns="state.worker_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'worker')"
        @cancel="state.showPicker_worker = false"
        :default-index="
          state.worker_list.findIndex((v) => {
            return v.id == form.list.worker;
          })
        "
      />
    </Popup>
    <!--picker 现场负责人--->
    <Popup v-model:show="state.showPicker_scene" position="bottom">
      <Picker
        :columns="state.sceneList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'scene')"
        @cancel="state.showPicker_scene = false"
        :default-index="
          state.sceneList.findIndex((v) => {
            return v.id == form.list.scene;
          })
        "
      />
    </Popup>
    <!--picker-头部合同切换 --->
    <Popup v-model:show="state.showPicker_contract" position="bottom">
      <Picker
        :columns="state.contract_list"
        :columns-field-names="{
          text: 'contract_name',
          value: 'contract_id',
        }"
        :default-index="
          state.contract_list.findIndex((v) => {
            return v.contract_id == form.list.contract;
          })
        "
        @confirm="(v) => onConfirm(v, 'contract')"
        @cancel="state.showPicker_contract = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Overlay,
  Loading,
  Dialog,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-头部合同选择-picker
  showPicker_contract: false,
  contract_list: [],
  //-头部合同id
  contract_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cid
    : '',
  //-@根据合同保留工长、现场负责人、工队
  old_worker: '',
  old_scene: '',
  old_team: '',
  checkInfo: {},
  //-工长-picker
  showPicker_worker: false,
  worker_list: [],
  //-现场负责人-picker
  showPicker_scene: false,
  sceneList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    // 头部合同切换
    if (type == 'contract') {
      form['list'][type] = value.contract_id;
      form['list'][type + '_name'] = value.contract_name;
      form['list'][type + '_no'] = value.contract_no;
      form['list']['construction_id'] = value.construction_id;
      state.checkInfo = value;
      getBaseInfo();
    } else if (type == 'worker') {
      //-工长
      if (form['list'][type + '_name'] != value.name) {
        form['list'][type] = value.id;
        form['list'][type + '_name'] = value.name;
        //-@清空现场负责人、工队
        form.list.scene = '';
        form.list.scene_name = '';
        form.list.team_name = '';
        //重新获取现场负责人列表
        getSceneLeaders(value.id);
      }
    } else {
      form['list'][type] = value.id;
      form['list'][type + '_name'] = value.name;
      form.list.team_name = value.team_name;
    }
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getCustomerListParmas();
});

//-@头部合同参数
const getCustomerListParmas = async () => {
  state.loadingConfirm = true;
  await $http
    .getDesignCustomerListApi({
      customer_id: router.currentRoute.value.query?.id,
      type: 2,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        state.contract_list = data.list;
        if (data.list?.length) {
          const typeList1 = data.list.filter(
            (v) => v.contract_id == state.contract_id
          );
          if (typeList1.length) {
            state.checkInfo = typeList1[0];
            form.list.contract = typeList1[0].contract_id;
            form.list.contract_name = typeList1[0].contract_name;
            form.list.contract_no = typeList1[0].contract_no;
            form.list.construction_id = typeList1[0].construction_id;
          } else {
            state.checkInfo = data.list[0];
            form.list.contract = data.list[0].contract_id;
            form.list.contract_name = data.list[0].contract_name;
            form.list.contract_no = data.list[0].contract_no;
            form.list.construction_id = data.list[0].construction_id;
          }
          getBaseInfo();
        } else {
          state.loadingConfirm = false;
        }
      } else {
        state.loadingConfirm = false;
        Toast(res.data);
      }
    });
};
//-@根据合同回显工长、现场负责人、工地
const getBaseInfo = () => {
  state.old_worker = '';
  state.old_scene = '';
  state.old_team = '';
  form.list.worker = '';
  form.list.worker_name = '';
  form.list.scene = '';
  form.list.scene_name = '';
  form.list.team_name = '';
  getWorkersFun(
    state.checkInfo.deliver_center_id,
    state.checkInfo.worker_leader_uid
  );
};

//-获取工长列表参数
const getWorkersFun = async (id, worker) => {
  state.loadingConfirm = false;
  await $http
    .getAssignTeamWorkerLeaders({
      deliver_center_id: id,
      construction_id: form.list.construction_id,
    })
    .then((res) => {
      if (res.code === 0) {
        state.worker_list = res.data;
        changeChecker(res.data, worker ?? '', 'worker');
        getSceneLeaders(
          state.checkInfo.worker_leader_uid,
          state.checkInfo.scene_leader_uid,
          'edit'
        );
      } else {
        state.worker_list = [];
        Toast(res.data);
      }
    });
};
//-获取现场负责人列表参数
const getSceneLeaders = async (id, scene, type) => {
  await $http
    .getAssignTeamSceneLeaders({
      worker_leader_uid: id,
      construction_id: form.list.construction_id,
    })
    .then((res) => {
      if (res.code === 0) {
        state.sceneList = res.data;
        if (type) {
          changeChecker(res.data, scene ?? '', 'scene');
        }
      } else {
        state.sceneList = [];
      }
    });
};

//-数据容错处理
const changeChecker = (dataList, id, type) => {
  const checkedItem =
    dataList.length > 0 && id
      ? dataList.filter((item) => item.id == parseInt(id))
      : [];
  if (checkedItem.length === 0) {
    form['list'][type] = '';
    form['list'][type + '_name'] = '';
    if (type == 'scene') {
      form.list.team_name = '';
    }
  } else {
    form['list'][type] = checkedItem[0]['id'];
    form['list'][type + '_name'] = checkedItem[0]['name'];
    state['old_' + type] = checkedItem[0]['name'];
    if (type == 'scene') {
      form.list.team_name = checkedItem[0]['team_name'];
    }
  }
};
//-获取参数--end

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    construction_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.gdid
      : '',
    contract: router.currentRoute.value.query
      ? router.currentRoute.value.query.cid
      : '',
    contract_name: '',
    contract_no: '',
    remarks: '',
    worker: '',
    worker_name: '',
    scene: '',
    scene_name: '',
    team_name: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (state.old_worker && state.old_worker != form.list.worker_name) {
        if (state.old_scene && state.old_scene != form.list.scene_name) {
          Dialog.confirm({
            message:
              '确认分配选中数据替换原【工长-' +
              state.old_worker +
              '】、【现场负责人-' +
              state.old_scene +
              '】吗？',
          }).then(() => {
            goConfirm();
          });
        } else {
          Dialog.confirm({
            message:
              '确认分配选中数据替换原【工长-' + state.old_worker + '】吗？',
          }).then(() => {
            goConfirm();
          });
        }
      } else {
        if (state.old_scene && state.old_scene != form.list.scene_name) {
          Dialog.confirm({
            message:
              '确认分配选中数据替换原【现场负责人-' +
              state.old_scene +
              '】吗？',
          }).then(() => {
            goConfirm();
          });
        } else {
          goConfirm();
        }
      }
    })
    .catch(() => {
      //验证失败
      Toast('请完善信息');
    });
};
const goConfirm = () => {
  state.loadingConfirm = true;
  $http
    .getAssignTeamApi({
      construction_id: form.list.construction_id,
      worker_leader_uid: form.list.worker,
      scene_leader_uid: form.list.scene,
      remarks: form.list.remarks,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功，已通知分派人员；并生成消息提醒',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
