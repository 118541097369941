<!--
 * @Author: NanNan
 * @Date: 2022-04-19 15:45:34
 * @LastEditTime: 2024-01-27 16:47:28
 * @Description: 合同收款
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
 1.5.4 产品收款隐藏部分非必填字段，不展示款期
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <Form class="form-box" ref="formRef">
      <!-- 合同头部信息 -->
      <div class="mess-top" v-if="!payment_id">
        <p class="label-name white">
          <em>*</em>
          收款合同
        </p>
        <Field
          v-model="form_info.contract_str"
          right-icon="arrow-down"
          readonly
          placeholder="选择收款合同"
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请选择收款合同',
            },
          ]"
          @click="contract_show = true"
        />
        <p class="contract-id">合同编号：{{ form_info.contract_no }}</p>
      </div>
      <!-- 收款期数 -->
      <div
        class="qi-box"
        v-if="
          !payment_id &&
          form_info.contract_type != 53 &&
          form_info.contract_type != 69
        "
      >
        <p class="label-name">
          <em>*</em>
          收款期数
        </p>
        <Field
          class="noBorder noFlex"
          name="validatorMessage"
          :rules="[
            {
              required: true,
              validator,
              message: '请选择收款期数',
            },
          ]"
        >
          <template #input>
            <CheckboxGroup
              v-model="form_info.check"
              direction="horizontal"
              @change="changeCheck"
            >
              <div
                class="width50"
                v-for="item in form_info.payment_periods"
                :key="item.payment_id"
              >
                <Checkbox
                  :key="item.payment_id"
                  :name="item.payment_id"
                  shape="square"
                >
                  {{ item.name }}
                </Checkbox>
              </div>
            </CheckboxGroup>
          </template>
        </Field>
      </div>
      <!-- 合同头部信息（设计节点列表 跳转进入携带） -->
      <div class="mess-top-dis" v-if="payment_id">
        <p class="contract-name">{{ form_info.contract_str }}</p>
        <p class="contract-id">合同编号：{{ form_info.contract_no }}</p>
        <div v-for="(item, index) in form_info.payment_periods" :key="index">
          <p class="qi-flex">
            <em>收款期数</em>
            <span>{{ item.payment_period }}</span>
          </p>
        </div>
      </div>
      <!-- 内容部分 -->
      <div
        class="content-box"
        v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
      >
        <div
          class="first-box"
          v-for="(item, index) in form_info.payment_periods"
          :key="index"
        >
          <div v-if="form_info.check.indexOf(item.payment_id) > -1">
            <p class="label-name">{{ item.payment_period }}</p>
            <Row
              gutter="2"
              v-if="
                form_info.contract_type == 53 || form_info.contract_type == 69
              "
            >
              <Col span="6">
                <p class="gray-title">增减项</p>
                <p class="mess">{{ item.adjust_money }}</p>
              </Col>
              <Col span="6"></Col>
              <Col span="6"></Col>
              <Col span="6">
                <p class="gray-title">本期已收金额</p>
                <p class="mess">{{ item.received_money }}</p>
              </Col>
            </Row>
            <Row gutter="2" v-else>
              <Col span="6">
                <p class="gray-title">本期款项</p>
                <p class="mess">{{ item.receivable_money }}</p>
              </Col>
              <Col span="6">
                <p class="gray-title">增减项</p>
                <p class="mess">{{ item.adjust_money }}</p>
              </Col>
              <Col span="6">
                <p class="gray-title">本期已收金额</p>
                <p class="mess">{{ item.received_money }}</p>
              </Col>
              <Col span="6">
                <p class="gray-title">剩余应收</p>
                <p class="mess">
                  {{
                    (
                      [item.receivable_money, item.adjust_money].reduce(
                        (x, y) => x * 100 + y * 100
                      ) /
                        100 -
                      item.received_money
                    ).toFixed(2)
                  }}
                </p>
              </Col>
            </Row>
            <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
            <div class="wait-box">
              <Icon
                name="info-o"
                color="#EA0000"
                v-if="item.income_amount_str || item.expenditure_amount_str"
              />
              <p>
                {{
                  getString([
                    item.income_amount_str,
                    item.expenditure_amount_str,
                  ])
                }}
              </p>
            </div>
            <!-- end -->
            <Field
              @update:model-value="getAllMoney()"
              v-model="item.amount"
              type="number"
              name="用户名"
              input-align="right"
              label="实收金额"
              placeholder="输入金额"
              error-message-align="right"
              :formatter="formatter"
              format-trigger="onBlur"
              :rules="[
                {
                  required: true,
                  message: '请输入实收金额',
                },
              ]"
              class="mt15"
            />
          </div>
        </div>
      </div>
      <!-- 详细表单信息 填写 -->
      <div class="detail-box">
        <p
          class="label-name"
          v-if="form_info.contract_type == 53 || form_info.contract_type == 69"
        >
          <em>*</em>
          收款金额
        </p>
        <Field
          v-if="form_info.contract_type == 53 || form_info.contract_type == 69"
          @blur="getPeriodMoney()"
          v-model="form_info.all_money"
          type="number"
          placeholder="填写金额"
          :formatter="formatter"
          format-trigger="onBlur"
          :rules="[
            {
              required: true,
              message: '请输入收款金额',
            },
          ]"
          class="mt15"
        />
        <Field
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
          v-model="form_info.all_money"
          input-align="right"
          name="用户名"
          label="实收金额"
          placeholder="0.00"
          class="mt15"
          disabled
        />
        <p class="label-name">
          <em>*</em>
          收款日期
        </p>
        <Field
          v-model="form_info.collection_time"
          name="picker"
          readonly
          placeholder="选择收款日期"
          right-icon="underway-o"
          @click="picker_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择收款日期' },
          ]"
        />

        <p class="label-name">
          <em>*</em>
          收款方式
        </p>
        <Field
          v-model="form_info.collection_method_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择收款方式"
          @click="collect_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择收款方式' },
          ]"
        />

        <p class="label-name">收款账号</p>
        <Field
          v-model="form_info.collection_account_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择收款账号"
          @click="collect_account_show = true"
        />

        <p class="label-name">票据编号</p>
        <Field
          v-model="form_info.bill_no"
          maxlength="50"
          placeholder="输入票据编号"
        />

        <p class="label-name">手工收据号</p>
        <Field
          v-model="form_info.handwork_no"
          maxlength="50"
          placeholder="输入手工票据编号"
        />

        <!-- v0.6.0 新增 -->
        <!-- V1.5.4 产品收款不展示 -->
        <p
          class="label-name"
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
        >
          打款人
        </p>
        <Field
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
          v-model="form_info.drawee"
          maxlength="30"
          placeholder="输入打款人"
        />

        <p
          class="label-name"
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
        >
          手续费
        </p>
        <Field
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
          v-model="form_info.service_charge"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写手续费"
        >
          <template #button>元</template>
        </Field>

        <p
          class="label-name"
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
        >
          优惠活动
        </p>
        <Field
          v-if="form_info.contract_type != 53 && form_info.contract_type != 69"
          v-model="form_info.activity_name"
          maxlength="50"
          placeholder="-"
          disabled
        />
        <!-- end -->

        <p class="label-name">备注</p>
        <Field
          v-model="form_info.remarks"
          rows="3"
          autosize
          type="textarea"
          maxlength="1000"
          placeholder="有什么需要补充的吗..."
          show-word-limit
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">
          <em>*</em>
          附件
          <span class="title_tip">请上传收款凭证</span>
        </p>
        <FileUpload
          :data="form_info.file_urls"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <p class="errorTip" v-if="fileTip">请上传收款凭证</p>
        <!-- end -->

        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>
    <!-- 合同组件 -->
    <Popup v-model:show="contract_show" position="bottom">
      <Picker
        :columns="options.c_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.c_list.findIndex((v) => {
            return v.id == contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="contract_show = false"
      />
    </Popup>
    <!-- 年月日 组件 -->
    <Popup v-model:show="picker_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择收款日期"
        @confirm="changePicker"
        @cancel="picker_show = false"
      />
    </Popup>
    <!-- 收款方式 -->
    <Popup v-model:show="collect_show" position="bottom">
      <Picker
        :columns="options.cash_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollect"
        @cancel="collect_show = false"
      />
    </Popup>
    <!-- 收款账号 -->
    <Popup v-model:show="collect_account_show" position="bottom">
      <Picker
        :columns="options.collection_account"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollectAccount"
        @cancel="collect_account_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';

import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  Form,
  Field,
  Checkbox,
  Button,
  Popup,
  Notify,
  CheckboxGroup,
  DatetimePicker,
  Picker,
  Loading,
  Overlay,
  Row,
  Col,
  Icon,
} from 'vant';
import moment from 'moment';

const $http = inject('$http');
const router = useRouter();

//-附件必填提示
const fileTip = ref(false);

const customer_id = ref(router.currentRoute.value.query.id);
const payment_id = ref(router.currentRoute.value.query.pid); //设计节点列表 过来携带 合同款期id
const contract_id = ref(router.currentRoute.value.query.cid); //设计节点列表 过来携带 合同id
const design_node_id = ref(router.currentRoute.value.query.nid); //设计节点列表 过来携带 节点id
const construction_node_id = ref(router.currentRoute.value.query.gdid); //0.3.0 施工列表 携带过来 节点id

const options = reactive({
  c_list: [], // 合同list
  cash_type: [], //收款方式
  collection_account: [], //收款账号
  payment_contract_list: [], //所有合同对应的数据集合
});

const form_default = reactive({
  contract_id: contract_id.value || '', //合同id
  contract_str: '', //合同_str
  contract_no: '', //合同 编号
  contract_type: '', //-合同类型
  check: [], //款期选择
  payment_periods: [], //款期集合
  collection_time: undefined, //收款时间
  collection_method_id: '', //收款方式
  collection_method_str: '', //收款方式_str
  collection_account_id: '', //收款账号
  collection_account_str: '', //收款账号_str
  bill_no: '', //票据编号
  handwork_no: '', //手工收据号
  drawee: '', //打款人
  service_charge: '', //手续费
  activity_name: '', //优惠活动str
  activity_id: '', //优惠活动id
  remarks: '', //备注
  file_urls: [], //附件集合
  all_money: '', //收款总金额
  design_node_id: design_node_id.value || '',
  construction_node_id: construction_node_id.value || '',
});

const form_info = reactive({});

onMounted(() => {
  Object.assign(form_info, form_default);
  !router.currentRoute.value.query.pid && loadFunc();
  router.currentRoute.value.query.pid && loadPaymentInfo();
});

/**
 * 接口调用
 */
const loading = ref(false);
const loadFunc = () => {
  loading.value = true;
  $http
    .getContractParmas({ customer_id: customer_id.value })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        options.c_list = data.contract_list;
        options.cash_type = data.cash_type;
        options.payment_contract_list = data.payment_contract_list;
        options.collection_account = data.collection_account;

        // 数据格式替换 - 开始
        if (contract_id.value && !payment_id.value) {
          changeData(data.payment_contract_list);
        }

        loading.value = false;
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};
// @获取款期详情 - 携带款期id、合同id,需要获取款期详情
const loadPaymentInfo = (payment_id) => {
  loading.value = true;
  $http
    .getContractCollectionPaymentInfoApi({
      payment_id: router.currentRoute.value.query.pid || payment_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        // 是否特殊款项 0 正常 1 特殊 (特殊款项包含增减项、服务包、产品升级、产品单独销售)
        form_info.contract_id = data.contract_id;
        form_info.contract_str = data.contract_name;
        form_info.contract_no = data.contract_no;
        form_info.all_money = data.all_money;
        form_info.activity_id = data.activity_id || '';
        form_info.activity_name = data.activity_name || '';
        form_info.period_type = data.period_type; //款期类别：1 款期 2 增减项
        form_info.check = [data.payment_id];
        form_info.payment_periods = [
          {
            ...data,
            payment_id: data.payment_id,
            name: data.payment_period,
            amount: '',
            check: true,
            index: 0,
          },
        ];
        loading.value = false;
        loadFunc();
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// v0.6.0 接口调整 @根据款期，获取待审核金额相关(不包含增减项了)
const loadPaymentInfoFunc = async (item) => {
  loading.value = true;
  await $http
    .getContractCollectionPaymentInfoApi({
      payment_id: item.payment_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        item.income_amount_str = data.income_amount_str;
        item.expenditure_amount_str = data.expenditure_amount_str;
        loading.value = false;
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// @数据格式替换
const changeData = (item) => {
  if (contract_id.value) {
    // url携带了参数的话、自动填充
    const objArr = item.filter((val) => val.id == contract_id.value);
    if (objArr.length) {
      let obj = objArr[0];
      form_info.contract_id = obj.id;
      form_info.contract_type = obj.type;
      form_info.contract_str = obj.name;
      form_info.contract_no = obj.contract_no;
      form_info.all_money = obj.all_money;
      form_info.payment_periods = [];
      form_info.activity_id = obj.activity_id || '';
      form_info.activity_name = obj.activity_name || '';
      if (obj.chargeProportion) {
        form_info.check = [Number(payment_id.value)];
        obj.chargeProportion.forEach((val, index) => {
          if (val.id === Number(payment_id.value)) {
            var elemento = {
              ...val,
              payment_id: val.id,
              name: val.payment_period,
              amount: '',
              check: true,
              index,
            };
            form_info.payment_periods.push(elemento);
          }
          if (!payment_id.value) {
            var elementi = {
              ...val,
              payment_id: val.id,
              name: val.payment_period,
              amount: '',
              check: false,
              index,
            };
            form_info.payment_periods.push(elementi);
          }
        });
      }
    }
  } else {
    const obj = JSON.parse(JSON.stringify(item));
    form_info.contract_id = obj.id;
    form_info.contract_type = obj.type;
    form_info.contract_str = obj.name;
    form_info.contract_no = obj.contract_no;
    form_info.all_money = obj.all_money;
    form_info.payment_periods = [];
    form_info.activity_id = obj.activity_id || '';
    form_info.activity_name = obj.activity_name || '';
    if (item.chargeProportion) {
      form_info.check = [];
      item.chargeProportion.forEach((val, index) => {
        var element = {
          ...val,
          payment_id: val.id,
          name: val.payment_period,
          amount: '',
          check: false,
          index,
        };
        form_info.payment_periods.push(element);
      });
    }
  }
  //V1.5.4 产品收款 页面不显示款期  后端不做修改 所以回填款期信息
  if (form_info.contract_type == 53 || form_info.contract_type == 69) {
    form_info.all_money = '';
    if (item.chargeProportion) {
      form_info.check = [item.chargeProportion[0]?.id];
    } else {
      const cItem = item.filter((val) => val.id == form_info.contract_id);
      if (cItem.length) {
        form_info.check = [cItem[0].chargeProportion[0]?.id];
      }
      
    }

    form_info.payment_periods = form_info.payment_periods.filter((i) => {
      if (form_info.check.includes(i.payment_id)) {
        i.check = true;
        loadPaymentInfoFunc(i);
      } else {
        i.check = false;
        i.amount = '';
      }
      return i;
    });
  }
  loading.value = false;
};
/**
 * 操作集合
 */
// @拼接字符串
const getString = (arr) => {
  let strArr = [];
  arr[0] && strArr.push('待审核收款¥' + arr[0]);
  arr[1] && strArr.push('待审核退款¥' + arr[1]);
  return strArr.join('，');
};
// @格式化 金额最大值 99999999.99
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @实收金额
const getAllMoney = () => {
  setTimeout(() => {
    form_info.all_money = form_info.payment_periods.reduce((pre, current) => {
      return ((Number(pre) * 100 + Number(current.amount) * 100) / 100).toFixed(
        2
      );
    }, 0);
  }, 500);
};
// V1.5.4 产品收款 页面不显示款期  后端不做修改 所以回填款期信息
const getPeriodMoney = () => {
  if (form_info.payment_periods[0]) {
    form_info.payment_periods[0].amount = form_info.all_money;
    console.log(form_info.payment_periods[0].amount);
  }
};
// @选择收款期数
const validator = () => {
  form_info.payment_periods.length ? true : false;
};
const changeCheck = (item) => {
  form_info.payment_periods = form_info.payment_periods.filter((i) => {
    if (item.includes(i.payment_id)) {
      i.check = true;
      loadPaymentInfoFunc(i);
    } else {
      i.check = false;
      i.amount = '';
    }
    return i;
  });
  getAllMoney();
};

// @选择合同
const contract_show = ref(false);
const changeContract = (value) => {
  contract_show.value = false;
  Object.assign(form_info, form_default);
  // 变更合同对应数据
  const obj = options.payment_contract_list.find((obj) => {
    return obj.id === value.id;
  });
  contract_id.value = '';
  changeData(obj);
  changeLoading();
};

// @loading加载
const changeLoading = () => {
  loading.value = true;
  setTimeout(() => {
    // 重置
    formRef.value?.resetValidation();
    loading.value = false;
  }, 500);
};

// @收款日期
const picker_show = ref(false);
const currentDate = ref(new Date());
const changePicker = (value) => {
  picker_show.value = false;
  form_info.collection_time = moment(value).format('YYYY-MM-DD');
};

// @收款方式
const collect_show = ref(false);
const changeCollect = (value) => {
  collect_show.value = false;
  form_info.collection_method_id = value.id;
  form_info.collection_method_str = value.name;
};

// @收款账号
const collect_account_show = ref(false);
const changeCollectAccount = (value) => {
  collect_account_show.value = false;
  form_info.collection_account_id = value.id;
  form_info.collection_account_str = value.name;
};

// @附件
const getFileList = (file_list) => {
  form_info.file_urls = file_list;
  fileTip.value = false;
};

// @提交
const formRef = ref(null);
const onSubmit = () => {
  console.log(form_info);
  formRef.value
    ?.validate()
    .then(() => {
      if (form_info.file_urls.length == 0) {
        fileTip.value = true;
        Notify({ type: 'warning', message: '请先完善信息' });
      } else {
        loading.value = true;
        // 格式变化
        const parmars = Object.assign({}, form_info);
        parmars.payment_periods = parmars.payment_periods.filter(
          (currentValue) => currentValue.check
        );
        if (form_info.period_type == 2) {
          // 增减项 收款
          const parmars_obj = {
            contract_id: parmars.contract_id,
            payment_id: parmars.payment_periods[0].payment_id,
            amount: parmars.payment_periods[0].amount,
            collection_time: parmars.collection_time,
            collection_method_id: parmars.collection_method_id,
            collection_account_id: parmars.collection_account_id,
            bill_no: parmars.bill_no,
            handwork_no: parmars.handwork_no,
            remarks: parmars.remarks,
            file_urls: parmars.file_urls,
            drawee: parmars.drawee,
            service_charge: parmars.service_charge,
            activity_id: parmars.activity_id,
            activity_name: parmars.activity_name,
          };
          $http
            .getContractContractContainApi({ ...parmars_obj })
            .then((res) => {
              const { code, data } = res;
              if (code === 0) {
                Notify({
                  type: 'success',
                  message: '操作成功',
                  onClose: () => {
                    loading.value = false;
                    // 哪里来回哪里去
                    router.back();
                  },
                });
              } else {
                loading.value = false;
                Notify({ type: 'warning', message: data });
              }
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          // 合同内 收款
          $http
            .getContractCollectionApi({ ...parmars })
            .then((res) => {
              const { code, data } = res;
              if (code === 0) {
                Notify({
                  type: 'success',
                  message: '操作成功',
                  onClose: () => {
                    loading.value = false;
                    // 哪里来回哪里去
                    router.back();
                  },
                });
              } else {
                loading.value = false;
                Notify({ type: 'warning', message: data });
              }
            })
            .catch(() => {
              loading.value = false;
            });
        }
      }
    })
    .catch(() => {
      //验证失败
      Notify({ type: 'warning', message: '请先完善信息' });
      if (form_info.file_urls.length == 0) {
        fileTip.value = true;
      }
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/collection.less';
</style>
