<!--
 * @Author: gest
 * @Date:2022-09-16 11:19:51
 * @LastEditTime: 2022-09-16 15:41:54
 * @Description: 确认客户信息
-->
<template>
  <div class="pop_select">
    <h1 class="clearfix">
      <span class="c1 pull-left">确认客户信息</span>
      <span class="c2 pull-right" @click="onClosePop">
        <Icon name="cross" :size="20" />
      </span>
    </h1>
    <div class="info_tip">
      根据企微好友的标注手机号，请确认客户信息此绑定操作不可逆！
    </div>
    <div class="checkedList noBorder">
      <RadioGroup v-model="form.checked" v-if="form.list.length">
        <div
          class="member_list"
          v-for="item in form.list"
          :key="item.external_userid"
        >
          <Radio
            :name="item.external_userid"
            @click.stop="getClick(item)"
            :label-position="'left'"
          >
            <div class="member_item">
              <div class="head_box">
                <Image
                  class="img_head"
                  round
                  :src="
                    item.face_image
                      ? item.face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                ></Image>
                <p class="head_name">{{ item.nick_name }}</p>
              </div>
              <div>客户手机号：{{ item.phone }}</div>
              <div>添加好友时间：{{ item.add_time }}</div>
            </div>
          </Radio>
        </div>
      </RadioGroup>
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, defineProps, onMounted } from 'vue';
import { Button, Radio, RadioGroup, Icon, Image } from 'vant';

const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['data']);

const form = reactive({
  checked: '',
  checkedItem: {},
  list: props.data ? props.data : [],
});
onMounted(() => {});

const getClick = (item) => {
  form.checked = item.external_userid;
  form.checkedItem = item;
};
//-确认操作
const btnSumbit = () => {
  emit('reviewOk', form.checkedItem);
};

//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
.pop_select {
  width: 100%;
  .info_tip {
    width: 100%;
    background: #feffe6;
    padding: 10px 25px;
    font-size: 15px;
    line-height: 24px;
    color: #1a1a1a;
  }
  > h1 {
    width: 100%;
    padding: 0px 25px;
    margin: 25px 0 20px;
    > span.c1 {
      font-size: 17px;
      line-height: 22px;
      color: @main_color;
      font-weight: bold;
    }
    > span.c2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: bold;
      color: #52575d;
    }
  }
  .member_list {
    width: 100%;
    .member_item {
      padding: 0px 0px 16px 0px;
      border-bottom: 1px solid rgba(228, 228, 228, 0.3);
      position: relative;
    }
  }

  .checkedList {
    padding: 0 25px 74px 25px;
    .member_list {
      border-bottom: 1px solid #eeeeee;
      > div {
        padding: 10px 0px 20px 0px;

        .member_item {
          width: calc(100vw - 100px);
          padding: 0;
          border-bottom: 0;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 24px;
          .head_box {
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 8px 0;

            .img_head {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background: linear-gradient(135deg, #ace2da 0%, #bdd5ff 100%);
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .head_name {
              padding-left: 10px;
              font-weight: bold;
              color: #1a1a1a;
            }
          }
        }
      }
    }
  }
}
</style>
