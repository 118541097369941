/*
 * @Author: gest
 * @Date:  2023-06-17 16:49:46
 * @LastEditTime: 2024-05-08 15:43:18
 * @Description:V1.4.0-目标数据
 * @Description:V1.4.6-财务大屏
 */
import allTargetData from '@/views/targetData/allTargetData';
import totalTargetData from '@/views/targetData/totalTargetData';
import depDesginData from '@/views/targetData/depDesginData'; //设计部门数据
import depSiteData from '@/views/targetData/depSiteData'; //交付部门数据
import depCustomerData from '@/views/targetData/depCustomerData'; //获客部门数据

// import financeData from '@/views/financeData/totalTargetData'; //财务大屏
import marketExpenseData from '@/views/financeData/marketExpenseData'; //营销费用
import performanceRanking from '@/views/performance/performanceRanking';
const targetDataRoute = [
  {
    path: '/allTargetData',
    component: allTargetData,
    meta: {
      name: '数据看板',
      bar: false,
    },
  },
  {
    path: '/totalTargetData',
    component: totalTargetData,
    meta: {
      name: '数据看板',
      bar: false,
    },
  },
  {
    path: '/depDesginData',
    component: depDesginData,
    meta: {
      name: '数据看板',
      bar: false,
    },
  },
  {
    path: '/depSiteData',
    component: depSiteData,
    meta: {
      name: '数据看板',
      bar: false,
    },
  },
  {
    path: '/depCustomerData',
    component: depCustomerData,
    meta: {
      name: '数据看板',
      bar: false,
    },
  },
  {
    path: '/marketExpenseData',
    component: marketExpenseData,
    meta: {
      name: '营销费用',
      bar: true,
    },
  },
  {
    path: '/performanceRanking',
    component: performanceRanking,
    meta: {
      name: '业绩榜单',
      bar: false,
    },
  },
];
export default targetDataRoute;
