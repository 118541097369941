<!--
 * @Author: Jessica
 * @Date:2023-03-08 14:07:31
 * @LastEditTime: 2023-11-04 15:29:07
 * @Description: 返款
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <Form class="form-box" ref="formRef">
      <!-- 内容部分 -->
      <div class="content-box">
        <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
        <div class="wait-box">
          <Icon
            name="info-o"
            color="#EA0000"
            v-if="
              form_info.income_amount_str || form_info.expenditure_amount_str
            "
          />
          <p>
            {{
              getString([
                form_info.income_amount_str,
                form_info.expenditure_amount_str,
              ])
            }}
          </p>
        </div>
        <!-- end -->
        <div class="font_size_15 btn_radio">
          <div
            class="item"
            :class="form_info.back_type == 1 ? 'choose' : ''"
            @click="radioClick(1)"
          >
            收款
          </div>
          <div
            class="item"
            :class="form_info.back_type == 2 ? 'choose' : ''"
            @click="radioClick(2)"
          >
            退款
          </div>
        </div>
        <p class="label-name">
          <em>*</em>
          {{
            form_info.back_type == 1
              ? '实收金额(返款金额)'
              : '退款金额(实退返款金额)'
          }}
        </p>
        <Field
          v-model="form_info.amount"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写金额"
          :rules="[
            { required: true, trigger: 'onChange', message: '请填写金额' },
          ]"
        >
          <template #button>元</template>
        </Field>

        <p class="label-name">
          <em>*</em>
          收款日期
        </p>
        <Field
          v-model="form_info.collection_time"
          name="picker"
          readonly
          placeholder="选择收款日期"
          right-icon="underway-o"
          @click="picker_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择收款日期' },
          ]"
        />

        <p class="label-name">
          <em>*</em>
          收款方式
        </p>
        <Field
          v-model="form_info.collection_method_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择收款方式"
          @click="collect_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择收款方式' },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          供应商
        </p>
        <Field
          v-model="form_info.supplier_name"
          readonly
          right-icon="arrow-down"
          placeholder="选择供应商"
          @click="supplier_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择供应商' },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          品类归属
        </p>
        <Field
          :disabled="!form_info.supplier_name"
          v-model="form_info.class_name"
          readonly
          right-icon="arrow-down"
          placeholder="选择品类归属"
          @click="cates_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择品类归属' },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          大类
        </p>
        <Field
          :disabled="!form_info.class_name"
          v-model="form_info.category_name"
          readonly
          right-icon="arrow-down"
          placeholder="选择大类"
          @click="categorys_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择大类' },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          品牌
        </p>

        <Field
          :disabled="!form_info.supplier_name"
          v-model="form_info.brand_name"
          readonly
          right-icon="arrow-down"
          placeholder="选择品牌"
          @click="brands_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择品牌' },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          合同金额
        </p>
        <Field
          v-model="form_info.contract_money"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写金额"
          :rules="[
            { required: true, trigger: 'onChange', message: '请填写金额' },
          ]"
        >
          <template #button>元</template>
        </Field>

        <p class="label-name">收款账号</p>
        <Field
          v-model="form_info.collection_account_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择收款账号"
          @click="collect_account_show = true"
        />

        <p class="label-name">票据编号</p>
        <Field
          v-model="form_info.bill_no"
          maxlength="50"
          placeholder="输入票据编号"
        />

        <p class="label-name">手工收据号</p>
        <Field
          v-model="form_info.handwork_no"
          maxlength="50"
          placeholder="输入手工票据编号"
        />

        <!-- v0.6.0 新增  V1.4.9去除-->
        <!-- <p class="label-name">
          <em>*</em>
          打款人
        </p>
        <Field
          v-model="form_info.drawee"
          maxlength="30"
          placeholder="输入打款人"
          :rules="[
            { required: true, trigger: 'onChange', message: '请填写打款人' },
          ]"
        /> -->

        <p class="label-name">手续费</p>
        <Field
          v-model="form_info.service_charge"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写手续费"
        >
          <template #button>元</template>
        </Field>
        <p class="label-name">集成金额</p>
        <Field
          v-model="form_info.integrate_money"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写集成金额"
        >
          <template #button>元</template>
        </Field>
        <p class="label-name">活动扣款金额</p>
        <Field
          v-model="form_info.activity_deduct_money"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写活动扣款金额"
        >
          <template #button>元</template>
        </Field>
        <!-- <p class="label-name">优惠活动</p>
        <Field
          v-model="form_info.activity_name"
          maxlength="50"
          placeholder="-"
          disabled
        /> -->
        <!-- end -->

        <p class="label-name">
          <em>*</em
          >{{ form_info.back_type == 1 ? '备注(返款原因)' : '备注(退款原因)' }}
        </p>
        <Field
          v-model="form_info.remarks"
          rows="3"
          autosize
          type="textarea"
          maxlength="1000"
          placeholder="有什么需要补充的吗..."
          show-word-limit
          :rules="[{ required: true, trigger: 'onChange', message: '请填写' }]"
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">
          <em>*</em>
          附件
          <span class="title_tip">请上传凭证</span>
        </p>
        <FileUpload
          :data="form_info.file_urls"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <p class="errorTip" v-if="fileTip">请上传凭证</p>
        <!-- end -->

        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>
    <!-- 合同组件 -->
    <Popup v-model:show="contract_show" position="bottom">
      <Picker
        :columns="options.c_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.c_list.findIndex((v) => {
            return v.id == contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="contract_show = false"
      />
    </Popup>
    <!-- 年月日 组件 -->
    <Popup v-model:show="picker_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择收款日期"
        @confirm="changePicker"
        @cancel="picker_show = false"
      />
    </Popup>
    <!-- 收款方式 -->
    <Popup v-model:show="collect_show" position="bottom">
      <Picker
        :columns="options.cash_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollect"
        @cancel="collect_show = false"
      />
    </Popup>
    <!-- 收款账号 -->
    <Popup v-model:show="collect_account_show" position="bottom">
      <Picker
        :columns="options.collection_account"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollectAccount"
        @cancel="collect_account_show = false"
      />
    </Popup>
    <!-- 供应商 -->
    <Popup
      v-model:show="supplier_show"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempSupplier
        v-if="supplier_show"
        @reviewClose="
          () => {
            supplier_show = false;
          }
        "
        :suppliers="options.suppliers"
        :check="form_info.supplier_id"
        @reviewOk="reviewOk"
      />
    </Popup>

    <!-- 品类归属 -->
    <Popup v-model:show="cates_show" position="bottom">
      <Picker
        :columns="options.cates"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changecates"
        @cancel="cates_show = false"
      />
    </Popup>
    <!-- 大类 -->
    <Popup v-model:show="categorys_show" position="bottom">
      <Picker
        :columns="options.categorys"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changecategorys"
        @cancel="categorys_show = false"
      />
    </Popup>
    <!-- 品牌 -->
    <Popup v-model:show="brands_show" position="bottom">
      <Picker
        :columns="options.brands"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changebrands"
        @cancel="brands_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';

import { reactive, onMounted, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  Form,
  Field,
  Button,
  Notify,
  Popup,
  DatetimePicker,
  Picker,
  Overlay,
  Loading,
  Icon,
} from 'vant';
import moment from 'moment';
import TempSupplier from '@/components/common/TempSupplier';

const $http = inject('$http');
const router = useRouter();

//-附件必填提示
const fileTip = ref(false);

const customer_id = ref(router.currentRoute.value.query.id);
const contract_id = ref(router.currentRoute.value.query.cid); //携带 合同id
const options = reactive({
  c_list: [], // 合同list
  cash_type: [], //收款方式
  payment_contract_list: [],
  collection_account: [], //所有合同对应的数据集合
  suppliers: [],
  cates: [],
  brands: [],
  categorys: [],
});

const form_default = reactive({
  customer_id: customer_id.value,
  back_type: 1,
  supplier_id: '',
  class_id: '',
  category_id: '',
  brand_id: '',
  contract_money: '',
  integrate_money: '',
  activity_deduct_money: '',

  amount: '', //金额
  collection_time: undefined, //收款时间
  collection_method_id: '', //收款方式id
  collection_method_str: '', //收款方式_str
  collection_account_id: '', //收款账号id
  collection_account_str: '', //收款账号_str
  bill_no: '', //票据编号
  handwork_no: '', //手工收据号

  service_charge: '', //手续费

  activity_id: '', //优惠活动id
  remarks: '', //备注
  file_urls: [], //附件集合

  income_amount_str: '', //合同外收款待审
  expenditure_amount_str: '', //合同外退款待审
});

const form_info = reactive({});
const supplier_show = ref(false);

onMounted(() => {
  Object.assign(form_info, form_default);
  loadFunc();
});

/**
 * 接口调用
 */
const loading = ref(false);
const loadFunc = () => {
  loading.value = true;
  $http
    .getContractParmas({ customer_id: customer_id.value })
    .then((res) => {
      const { data, code } = res;
      loading.value = false;
      if (code === 0) {
        options.c_list = data.contract_back_list;
        options.cash_type = data.cash_type;
        options.payment_contract_list = data.payment_contract_list;
        options.collection_account = data.collection_account;
        options.suppliers = data.suppliers;
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// v0.6.0 接口调整 @根据款期，获取待审核金额相关(不包含增减项了)
const loadPaymentInfoFunc = async (contract_id) => {
  loading.value = true;
  await $http
    .getContractCollectionPaymentInfoApi({
      contract_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        loading.value = false;
        form_info.income_amount_str = data.income_amount_str || '';
        form_info.expenditure_amount_str = data.expenditure_amount_str || '';
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

/**
 * 操作集合
 */
// @loading加载
const changeLoading = () => {
  loading.value = true;
  setTimeout(() => {
    // 重置
    formRef.value?.resetValidation();
    loading.value = false;
  }, 500);
};
//选择返款类型
const radioClick = (value) => {
  form_info.back_type = value;
};
//选择供应商返回
const reviewOk = (item) => {
  form_info.supplier_id = item?.id || '';
  form_info.supplier_name = item?.name || '';
  supplier_show.value = false;
  getCategoryInfo();
};
const getCategoryInfo = async () => {
  await $http
    .getCategoryInfo({
      supplier_id: form_info.supplier_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        options.brands = data.brands;
        options.cates_total = data.cates;
        options.cates = JSON.parse(JSON.stringify(data.cates));
        options.cates.map((e) => {
          delete e.children;
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

//选择品类
const cates_show = ref(false);
const categorys_show = ref(false);
const brands_show = ref(false);
const changecates = (value) => {
  cates_show.value = false;
  form_info.class_id = value.id;
  form_info.class_name = value.name;

  options.categorys = options.cates_total.filter((item) => {
    return item.id == value.id;
  })[0].children;
};
const changecategorys = (value) => {
  categorys_show.value = false;
  form_info.category_id = value.id;
  form_info.category_name = value.name;
};
const changebrands = (value) => {
  brands_show.value = false;
  form_info.brand_id = value.id;
  form_info.brand_name = value.name;
};
// @选择合同
const contract_show = ref(false);
const changeContract = (value) => {
  loading.value = true;
  contract_id.value = value.id;
  contract_show.value = false;
  Object.assign(form_info, form_default);
  changeData(options.payment_contract_list);
  changeLoading();
};

// @数据格式替换
const changeData = (arr) => {
  const objArr = arr.filter((val) => val.id == contract_id.value);
  const obj = objArr.length ? JSON.parse(JSON.stringify(objArr[0])) : '';
  form_info.contract_id = obj.id || '';
  form_info.contract_str = obj.name || '';
  form_info.contract_no = obj.contract_no || '';
  form_info.activity_name = obj.activity_name || '';
  form_info.activity_id = obj.activity_id || '';
  loadPaymentInfoFunc(obj.id || router.currentRoute.value.query.cid);
};

// @收款日期
const picker_show = ref(false);
const currentDate = ref(new Date());
const changePicker = (value) => {
  picker_show.value = false;
  form_info.collection_time = moment(value).format('YYYY-MM-DD');
};

/**
 * 操作集合
 */
// @拼接字符串
const getString = (arr) => {
  let strArr = [];
  arr[0] && strArr.push('待审核收款¥' + arr[0]);
  arr[1] && strArr.push('待审核退款¥' + arr[1]);
  return strArr.join('，');
};
// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};
// @收款方式
const collect_show = ref(false);
const changeCollect = (value) => {
  collect_show.value = false;
  form_info.collection_method_id = value.id;
  form_info.collection_method_str = value.name;
};

// @收款账号
const collect_account_show = ref(false);
const changeCollectAccount = (value) => {
  collect_account_show.value = false;
  form_info.collection_account_id = value.id;
  form_info.collection_account_str = value.name;
};

// @附件
const getFileList = (file_list) => {
  form_info.file_urls = file_list;
  fileTip.value = false;
};

// @提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value
    ?.validate()
    .then(() => {
      if (form_info.file_urls.length == 0) {
        fileTip.value = true;
        Notify({ type: 'warning', message: '请先完善信息' });
      } else {
        loading.value = true;
        // 格式变化
        const parmars = Object.assign({}, form_info);
        $http
          .getContractBackApi({ ...parmars })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
                onClose: () => {
                  loading.value = false;
                  router.push({
                    path: '/sale',
                    query: { id: customer_id.value },
                  });
                },
              });
            } else {
              loading.value = false;
              Notify({ type: 'warning', message: data });
            }
          })
          .catch(() => {
            loading.value = false;
          });
      }
    })
    .catch(() => {
      //验证失败
      Notify({ type: 'warning', message: '请先完善信息' });
      if (form_info.file_urls.length == 0) {
        fileTip.value = true;
      }
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/outCollection.less';
.btn_radio {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 2px;
  .item {
    width: 50%;
    border-radius: 6px;
    padding: 4px 0;
    text-align: center;
  }
  .choose {
    background: @white-color;
    font-weight: bold;
  }
}
</style>
