<!--
 * @Author: gest
 * @Date: 2023-09-14 16:58:05
 * @LastEditTime: 2023-12-15 17:32:07
 * @Description: v1.4.5 工期确认单 
-->
<template>
  <div class="formBase">
    <div class="content mb_15" v-if="!props.info?.is_fine_construction == 1">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">原合同开工时间</div>
            <div class="">{{ form.info.contract_start_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">原合同完工时间</div>
            <div class="">{{ form.info.contract_end_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <p style="width: 150px">
              <span class="red_color">*</span
              >{{
                props.info?.is_fine_construction == 1
                  ? '实际开工日期'
                  : '变更后实际开始时间'
              }}
            </p>
            <Field
              v-model="form.info.start_time"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              :rules="[
                {
                  required: true,
                  message: '请选择时间',
                },
              ]"
              @click="openDateType('start_time')"
            />
          </div>
        </div>
        <div class="field_item right">
          <div class="flex_between">
            <div>
              <span class="red_color">*</span
              >{{
                props.info?.is_fine_construction == 1
                  ? '预计完工日期'
                  : '变更后预计结束时间'
              }}
            </div>
            <div>{{ form.info.end_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15" v-if="!props.info?.is_fine_construction == 1">
      <div class="list_cell white_bg">
        <div class="item p_15 border">
          <div class="font_size_15 mb_15">
            <span class="red_color">*</span>
            工程变更原因
          </div>

          <Field v-if="state.disabled" name="checkboxGroup" label="">
            <template #input>
              <CheckboxGroup v-model="state.checked" style="font-size: 15px">
                <Checkbox
                  :name="index"
                  v-for="(item, index) in form.info.basic_info"
                  :key="index"
                  :disabled="item.disabled"
                >
                  {{ item.text }}
                </Checkbox>
              </CheckboxGroup>
            </template>
          </Field>
          <Field
            v-else
            name="checkboxGroup"
            label=""
            :rules="[
              {
                required: true,
                message: '请选择工程变更原因',
              },
            ]"
          >
            <template #input>
              <CheckboxGroup
                v-model="state.checked"
                style="font-size: 15px"
                @change="checkChange"
              >
                <Checkbox
                  :name="index"
                  v-for="(item, index) in form.info.basic_info"
                  :key="index"
                  :disabled="item.disabled"
                >
                  {{ item.text }}
                </Checkbox>
              </CheckboxGroup>
            </template>
          </Field>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有特殊事项请补充..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v, 'start_time')"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits, inject } from 'vue';
import {
  Field,
  CheckboxGroup,
  Checkbox,
  Popup,
  DatetimePicker,
  Notify,
} from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const $http = inject('$http');
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'contract_id', 'info']);
const state = reactive({
  disabled: false,
  checked: [],
  //-时间控件
  // minDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: { check: [] },
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;

  state.disabled = form.info.basic_info.some((i) => i.disabled);
  let arr = [];
  form.info.basic_info.forEach((item, index) => {
    if (item.checked) {
      arr.push(index);
    }
  });
  state.checked = arr;
  emit('update:data', form.info);
});
const checkChange = (list) => {
  form.info.basic_info = form.info.basic_info.map((item, index) => {
    item.checked = false;
    list.forEach((i) => {
      if (index == i) {
        item.checked = true;
      }
    });
    return item;
  });
};
const getFileList = (files) => {
  form.info.files = files;
};

// @-工地确认单 时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD');
  //-实时计算变更后预计结束时间
  $http
    .public_getScheduleBySize({
      start_date: form['info'][type],
      contract_id: props.contract_id,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        form['info']['end_time'] = data.end_date;
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
        });
      }
    });
};
//-end
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
:deep(.van-checkbox) {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
.pr23 {
  padding-right: 23px;
}
</style>
