<!--
 * @Author: Jessica
 * @Date: 2023-07-05 16:54:52
 * @LastEditTime: 2023-07-07 16:55:41
 * @Description: V1.4.1 更多操作 - 施工报价
-->
<template>
  <div class="page-box" @load="_DATA.loading">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="content">
      <!-- 必报项 -->
      <div class="lists mb_15" v-if="_DATA.list.required_quote_files?.length">
        <h2>必报项报价单</h2>
        <div
          class="item"
          v-for="(item, index) in _DATA.list.required_quote_files"
          :key="index"
          @click.stop="fileDetail(item, _DATA.list.required_quote_files)"
        >
          <svg class="icon-small" aria-hidden="true">
            <use :xlink:href="getIcon(item.type)"></use>
          </svg>
          <div class="detail">
            <p class="name">{{ item.name }}</p>
            <p class="time">上传时间：{{ _DATA.list.upload_time }}</p>
          </div>
          <Icon name="arrow" size="16" color="#7C7B7B" />
        </div>
      </div>
      <div class="lists mb_15" v-else>
        <h2>必报项报价单</h2>
        <div class="no_files">未上传</div>
      </div>

      <!-- 设备电报价单 -->
      <div class="lists mb_15">
        <h2>设备电报价单</h2>
        <div class="item" @click.stop="linkTo('设备电报价单')">
          <svg
            class="icon-small"
            aria-hidden="true"
            v-if="_DATA.list.is_edit == 1"
          >
            <use :xlink:href="getIcon('pdf')"></use>
          </svg>
          <p class="edit" v-else>
            <svg class="icon-small-edit" aria-hidden="true">
              <use xlink:href="#icon-edit-2-fill"></use>
            </svg>
          </p>
          <div class="detail">
            <p class="name">设备电报价单</p>
            <p class="time">
              上传时间：{{ _DATA.list.device_setup_time || '-' }}
            </p>
          </div>
          <Icon name="arrow" size="16" color="#7C7B7B" />
        </div>
      </div>

      <!-- 升级选项报价 -->
      <div class="lists mb_15">
        <h2>升级选项报价</h2>
        <div class="item" @click.stop="linkTo('升级选项报价')">
          <svg
            class="icon-small"
            aria-hidden="true"
            v-if="_DATA.list.is_edit == 1"
          >
            <use :xlink:href="getIcon('pdf')"></use>
          </svg>
          <p class="edit" v-else>
            <svg class="icon-small-edit" aria-hidden="true">
              <use xlink:href="#icon-edit-2-fill"></use>
            </svg>
          </p>
          <div class="detail">
            <p class="name">升级选项报价单</p>
            <p class="time">
              上传时间：{{ _DATA.list.upgrade_setup_time || '-' }}
            </p>
          </div>
          <Icon name="arrow" size="16" color="#7C7B7B" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { inject, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { Notify, Loading, Overlay, Icon } from 'vant';
import { getIcon, fileDetail } from '@/utils/fileUpload';

const $http = inject('$http');
const router = useRouter();
const _DATA = reactive({ loading: false, list: {} });

onMounted(() => {
  loadFunc();
});

/**
 * 接口调用
 */
//--详情
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .getCustomerQuoteOrder({
      customer_id: router.currentRoute.value.query.id,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list = data;
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--------end----------------

//---跳转
const linkTo = (name) => {
  let url = '';
  if (name == '设备电报价单') {
    url = _DATA.list.device_sign_time
      ? '/deviceQuotationInfo'
      : '/deviceQuotationEdit';
  } else if (name == '升级选项报价') {
    url = _DATA.list.upgrade_sign_time
      ? '/upgradeQuotationInfo'
      : '/upgradeQuotationEdit';
  } else return;
  router.push({
    path: url,
    query: {
      id: router.currentRoute.value.query.id,
      type: _DATA.list.is_edit == 1 ? 'view' : 'edit',
    },
  });
};
//--------end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/design/quotationList.less';
</style>
