<!--
 * @Author: Jessica
 * @Date: 2022-08-19 10:48:26
 * @LastEditTime: 2023-10-12 11:47:35
 * @Description: 合同退单
-->
<template>
  <!-- 合同退单 -->
  <div class="page-waper">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <!-- 加载状态 -->
        <Loading class="loading-box" color="#0094ff" v-if="state.loading" />
        <!-- 选择退单合同信息 -->
        <div class="mess-top">
          <p class="label-name white">
            <em>*</em>
            选择退单合同
          </p>
          <Field
            v-model="form.list.contract_name"
            right-icon="arrow-down"
            readonly
            placeholder="选择退单合同"
            @click="state.showTypePicker1 = true"
            :rules="[{ required: true, message: '请选择退单合同' }]"
          />
        </div>
        <!-- 合同基本信息 -->
        <div class="baseInfo" v-if="form.list.contract_id">
          <h3>基本信息</h3>
          <div class="baseInfo_item">
            <p>
              <label>合同编号:</label>
              <span>{{ state.contractBaseInfo.contract_no || '-' }}</span>
            </p>
            <p>
              <label>签署方:</label>
              <span>{{ state.contractBaseInfo.signatory_name || '-' }}</span>
            </p>
            <p>
              <label>执行人:</label>
              <span>
                {{ state.contractBaseInfo.executor_type_name || '-' }}
              </span>
            </p>
            <p>
              <label>签订合同价:</label>
              <span>{{ state.contractBaseInfo.contract_amount || '-' }}</span>
            </p>
            <p>
              <label>签订时间:</label>
              <span>{{ state.contractBaseInfo.signed_up_time || '-' }}</span>
            </p>
          </div>
        </div>
        <!-- 备注 -->
        <div class="cell">
          <label class="form_title">备注</label>
          <Field
            v-model="form.list.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有什么需要补充的吗..."
            show-word-limit
          />
        </div>
        <!-- 附件 -->
        <div class="cell">
          <label class="form_title">
            <span style="color: #ea3d2f">*</span>
            附件
            <span class="title_tip">图片大小小于10M</span>
          </label>
          <FileUpload
            :data="form.list.files"
            :type="state.fileType"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
          <p class="errorTip" v-if="state.fileTip">请上传附件图片</p>
        </div>
        <div class="btnSumbit">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            提交
          </Button>
        </div>
      </Form>

      <!-- 合同组件 -->
      <Popup v-model:show="state.showTypePicker1" position="bottom">
        <Picker
          :columns="state.typeList1"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'type1')"
          @cancel="state.showTypePicker1 = false"
          :default-index="
            state.typeList1.findIndex((v) => {
              return v.id == form.list.contract_id;
            })
          "
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  Form,
  Field,
  Popup,
  Picker,
  Toast,
  Notify,
  Button,
  Loading,
  Overlay,
  Dialog,
} from 'vant';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loading: false,
  loadingConfirm: false,
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  //-作废合同id
  contract_delete_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cdid
    : '',
  //-合同参数
  showTypePicker1: false,
  typeList1: [],
  contractBaseInfo: {
    contract_no: '',
    executor_type_name: '',
    signatory_name: '',
    contract_amount: '',
    signed_up_time: '',
  },
  //-图片类型 4只支持图片
  fileType: 4,
  fileTip: false, //-附件必填提示
});
//-参数
onMounted(() => {
  getContractInvalidParam();
});
//-获取参数
const getContractInvalidParam = async () => {
  state.loading = true;
  await $http
    .getContractInvalidParam({ customer_id: state.customer_id })
    .then((res) => {
      if (res.code === 0) {
        state.typeList1 = res.data.contract_list;
        if (state.contract_delete_id) {
          getContractInfo();
        } else {
          state.loading = false;
        }
      } else {
        state.loading = false;
        Toast(res.data);
      }
    });
};
//-获取合同退单详情
const getContractInfo = async () => {
  await $http
    .getContractInvalidInfo({ contract_id: state.contract_delete_id })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        form.list = {
          contract_id: res.data.list.id,
          contract_name: res.data.list.name,
          files: res.data.list.files,
          remark: res.data.list.remark,
        };
        state.contractBaseInfo = {
          contract_no: res.data.list.contract_no,
          executor_type_name: res.data.list.executor_type_name,
          signatory_name: res.data.list.signatory_name,
          contract_amount: res.data.list.contract_amount,
          signed_up_time: res.data.list.signed_up_time,
        };
      } else {
        Toast(res.data);
      }
    });
};
//--表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    contract_id: '',
    contract_name: '',
    files: [],
    remark: '',
  },
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (type === 'type1') {
    if (value) {
      form.list.contract_name = value.name;
      form.list.contract_id = value.id;
      state.contractBaseInfo = value;
    }
    state.showTypePicker1 = false;
  }
};

// @附件
const getFileList = (files) => {
  form.list.files = files;
  state.fileTip = false;
};
//--上传附件end----------

//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (form.list.files.length == 0) {
        state.fileTip = true;
        Toast('请先完善信息');
      } else {
        state.loadingConfirm = true;
        Dialog.confirm({
          message:
            '<div class="message_tip"><p class="tip_image"></p><p>确认是否要作废该协议？</p></div>',
          confirmButtonColor: '#3471FF',
          cancelButtonColor: '#3471FF',
          allowHtml: true,
        })
          .then(() => {
            $http
              .getAddContractInvalid({
                ...form.list,
              })
              .then((res) => {
                const { code, data } = res;
                state.loadingConfirm = false;
                if (code === 0) {
                  Notify({
                    type: 'success',
                    message: '操作成功',
                    onClose: () => {
                      router.back();
                    },
                  });
                } else {
                  Notify({ type: 'warning', message: data });
                }
              });
          })
          .catch(() => {
            // on cancel
            state.loadingConfirm = false;
          });
      }
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
      if (form.list.files.length == 0) {
        state.fileTip = true;
      }
    });
};
//--表单end---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/change.less';
</style>
