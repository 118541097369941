<!--
 * @Author: Jessica
 * @Date: 2022-06-30 09:00:31
 * @LastEditTime: 2023-02-21 16:55:17
 * @Description: 
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <!-- 设计启动 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              启动日期
            </label>
            <Field
              v-model="form.list.start_day"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择启动日期"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请选择启动日期',
                },
              ]"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              验收模板
            </label>
            <Field
              v-model="form.list.template_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择验收模板"
              :rules="[
                {
                  required: true,
                  message: '请选择验收模板',
                },
              ]"
              @click="state.showPicker_template = true"
            />
          </div>
          <div
            class="cell2 clearfix"
            v-if="
              form.list.is_monday_postponed == 0 ||
              form.list.is_monday_postponed == 1
            "
          >
            <label class="form_title mb_0 pull-left">遇周一工期顺延</label>
            <Switch
              v-model="form.list.is_monday_postponed"
              class="pull-right"
              :active-value="1"
              :inactive-value="0"
              :disabled="true"
              size="20"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.describe"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.files"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-模板 --->
    <Popup v-model:show="state.showPicker_template" position="bottom">
      <Picker
        :columns="state.templateList"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(v) => onConfirm(v, 'template')"
        @cancel="state.showPicker_template = false"
      />
    </Popup>
  </div>
</template>

<script setup>
// 参数：客户id-id，合同id-cid，设计id-did，启动日期-startDate
import { inject, reactive, ref, onBeforeMount } from 'vue';
import FileUpload from '@/components/common/FileUpload';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Toast,
  Notify,
  Overlay,
  Loading,
  Picker,
  Switch,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query?.date,
  //-全部待办类型
  dtype: router.currentRoute.value.query?.dtype,
  //-客户id
  customer_id: router.currentRoute.value.query?.id,
  //-待办来源Id
  source_id: router.currentRoute.value.query?.sid,
  //-模板控件
  showPicker_template: false,
  templateList: [],
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    design_id: router.currentRoute.value.query?.did,
    start_day: router.currentRoute.value.query?.startDate,
    describe: '',
    template_id: '',
    template_name: '',
    is_monday_postponed: null,
    files: [],
  },
});

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  await $http
    .getDesignGetTemplateList({
      contract_id: router.currentRoute.value.query?.cid,
    })
    .then((res) => {
      if (res.code === 0) {
        state.templateList = res.data;
      } else {
        state.templateList = [];
        Toast(res.data);
      }
    });
};
//-获取参数--end
// @附件--begin
const getFileList = (file_list) => {
  form.list.files = file_list;
};
// @附件--end

//-下一步操作
const btnSumbit = () => {
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .getDesignStartDesign({
          ...form.list,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                // 哪里来回哪里去
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-选择模板--begin
const onConfirm = (value, type) => {
  form['list'][type + '_id'] = value.id;
  form['list'][type + '_name'] = value.name;
  form['list']['is_monday_postponed'] = value.is_monday_postponed;
  state['showPicker_' + type] = false;
};
//-选择模板--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
