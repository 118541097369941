/*
 * @Author: NanNan
 * @Date: 2023-05-04 15:56:43
 * @LastEditTime: 2023-12-07 15:23:27
 * @Description: 【汇总】默认页面、授权页面、登录页面、404
 * v1.3.7[smart] 根据环境变更h5登录入口
 */
import IndexPgeA from '@/views/indexPage';
import IndexClue from '@/views/indexClue';
import authPage from '@/views/auth';
import authPageC from '@/views/authC';
import authPageA from '@/views/authA';
import Error404 from '@/views/error/404';
import login from '@/views/login';

const wxwork = /wxwork/i.test(navigator.userAgent);
const authRoute = [
  {
    path: '/',
    component: wxwork ? authPage : login,
    meta: {
      name: wxwork ? '授权' : '登录',
      bar: false,
    },
  },
  {
    path: '/login',
    component: login,
    meta: {
      name: '登录',
      bar: false,
    },
  },
  {
    path: '/authA',
    component: wxwork ? authPageA : login,
    meta: {
      name: wxwork ? '授权' : '登录',
      bar: false,
    },
  },
  {
    path: '/auth',
    component: wxwork ? authPage : login,
    meta: {
      name: wxwork ? '授权' : '登录',
      bar: false,
    },
  },
  {
    path: '/authC',
    // component: wxwork ? authPageC : login,
    component: authPageC,
    meta: {
      name: wxwork ? '授权' : '登录',
      bar: false,
    },
  },
  {
    path: '/index',
    component: IndexPgeA,
    meta: {
      name: '欢迎页面',
      bar: false,
    },
  },
  {
    path: '/indexClue',
    component: IndexClue,
    meta: {
      name: '欢迎页面',
      bar: false,
    },
  },
  {
    path: '/404',
    component: Error404,
  },
];
export default authRoute;
