<!--
 * @Author: gest
 * @Date:2023-06-06 17:55:37
 * @LastEditTime: 2023-10-19 15:39:38
 * @Description: V1.3.8 产品大类搜索选择弹窗
-->
<template>
  <div class="pop_search">
    <div class="pro_title">
      <span style="flex: 1; text-align: center" class="font_size_16 font_bold">
        选择空间复制
      </span>
      <Icon name="cross" :size="18" style="color: #52575d" @click="clear" />
    </div>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="state.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <CheckboxGroup v-model="form.checked" v-if="form.list.length">
        <cell v-for="item in form.list" :key="item.id">
          <div class="member_list">
            <Checkbox :name="item.id" @click.stop="getClick()">
              <div class="member_item">
                <p class="name">{{ item.name }}</p>
              </div>
            </Checkbox>
          </div>
        </cell>
      </CheckboxGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="add_area">
      未找到，<span
        class="blue_color"
        style="text-decoration: underline"
        @click="addAreaPop"
        >新增空间</span
      >
    </div>
    <div class="bottom_btn" @click.stop="btnSumbit">确定</div>
  </div>
  <!-- 添加空间 -->
  <Popup v-model:show="state.addAreaShow" position="bottom">
    <div class="area_pop">
      <Field
        ref="areaRef"
        v-model="state.area_name"
        label=""
        placeholder="填写空间名"
        maxlength="10"
      >
      </Field>
      <Button type="primary" size="large" @click="saveAreaPop">确定</Button>
    </div>
  </Popup>
</template>
<script setup>
import _ from 'lodash';
import {
  inject,
  ref,
  reactive,
  defineEmits,
  onMounted,
  defineProps,
} from 'vue';
import {
  Search,
  Cell,
  Empty,
  Checkbox,
  CheckboxGroup,
  Notify,
  Icon,
  Popup,
  Field,
  Button,
} from 'vant';

const emit = defineEmits(['reviewOk']);
const props = defineProps(['data', 'id']);
const $http = inject('$http');
const state = reactive({
  kw: '',
  addAreaShow: false,
  area_name: '',
});
const form = reactive({
  checked: [],
  checkedItem: {},
  list: [],
  allList: props.data ? props.data : [],
});
const areaRef = ref(null);
onMounted(() => {
  state.kw = '';
  form.list = props.data ? props.data : [];
});
//-搜索
const searchFun = () => {
  if (state.kw) {
    form.list = form.allList.filter((item) => item.name.indexOf(state.kw) > -1);
  } else {
    form.list = _.cloneDeep(form.allList);
  }
};

const getClick = () => {
  form.checkedItem = form.list.filter((v) => {
    return form.checked.indexOf(v.id) > -1;
  });
};
//-确认操作
const btnSumbit = () => {
  emit('reviewOk', form.checkedItem);
};
//V1.4.7新增空间
const addAreaPop = () => {
  state.addAreaShow = true;
  state.area_name = '';
  setTimeout(() => {
    areaRef.value.focus();
  }, 100);
};
const saveAreaPop = () => {
  if (state.area_name) {
    $http
      .mainSelfOrder_spaceBatchSave({
        customer_id: props.id,
        name: state.area_name,
      })
      .then((res) => {
        const { data, code } = res;
        if (code === 0) {
          state.addAreaShow = false;
          Notify({
            type: 'success',
            message: '保存成功',
          });
          state.kw = '';
          form.allList.unshift({ id: data.id, name: data.name });
          form.list = _.cloneDeep(form.allList);
        } else {
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  } else {
    Notify({
      type: 'warning',
      message: '请填写空间名',
    });
  }
};
//-取消
const clear = () => {
  form.checkedItem = [];

  emit('reviewOk', form.checkedItem);
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
.pop_search {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  .pro_title {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
  }

  .search_filed {
    width: 100%;
    background: #ffffff;
    z-index: 9;
    padding: 0 10px 0 25px;
    box-sizing: border-box;
    :deep(.van-search) {
      background: #f5f5f5;
      border-radius: 20px;
      padding: 4px 0;
    }
    :deep(.van-search__content) {
      border: none;
      background: #f5f5f5;
      border-radius: 20px;
    }
    :deep(.van-search__action) {
      margin: 0 6px;
      height: 28px;
      line-height: 28px;
      border-radius: 14px;
      background: #ffffff;
      color: rgba(0, 0, 0, 0.65);
      padding: 0 11px;
    }
  }

  .checkedList {
    background: #ffffff;
    padding: 15px;
    flex: 1;
    height: 100%;
    overflow: auto;
    .member_list {
      width: 100%;
      padding: 6px 0;
      .member_item {
        position: relative;
      }
    }
  }
  .add_area {
    height: 56px;
    line-height: 56px;
    padding: 0 15px 15px 15px;
    text-align: center;
    margin-bottom: 15px;
    color: #8d9091;
    font-size: 13px;
    background: #ffffff;
  }
  .bottom_btn {
    height: 56px;
    line-height: 56px;
    color: @blue-color;
    text-align: center;
    background: #ffffff;
  }
}
.area_pop {
  background-color: #f5f5f5;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  :deep(.van-button) {
    width: 58px;
    height: 32px;
    margin-left: 10px;
  }
  :deep(.van-cell) {
    padding: 8px;
    font-size: 15px;
  }
}
</style>
