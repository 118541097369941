/*
 * @Author: Jessica
 * @Date: 2022-04-29 17:00:10
 * @LastEditTime: 2024-05-09 10:49:16
 * @Description: 更多操作
 */
import Service from '@/utils/request';

export default {
  /**
   * 量房
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getMeasureHouse(params) {
    return Service.post(`/rs/company/measureHouse`, params);
  },
  /**
   * 获取客户公共参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSaleOptionsApi(params) {
    return Service.post(`/rs/company/getParams`, params);
  },
  /**
   * 申请设计派单
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerApplyDesignDispatch(params) {
    return Service.post(`/rs/customerManagement/applyDesignDispatch`, params);
  },
  /**
   * 分配设计中心
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerAssignDesignCenter(params) {
    return Service.post(`/rs/customerManagement/assignDesignCenter`, params);
  },
  /**
   * 分配服务人员
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerAssignServicePerson(params) {
    return Service.post(`/rs/customerManagement/assignServicePerson`, params);
  },
  /**
   * 分配设计中心参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerAssignDesignCenterParam(params) {
    return Service.post(`/rs/public/assignDesignCenterParam`, params);
  },
  /**
   * 分配服务人员参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerAssignServicePersonParam(params) {
    return Service.post(`/rs/public/assignServicePersonParam`, params);
  },
  /**
   * 继续跟进操作
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerChangeHang(params) {
    return Service.post(`/rs/customerManagement/customerChangeHang`, params);
  },
  /**
   * 提交见面
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerPostFace(params) {
    return Service.post(`/rs/fiveStep/postFace`, params);
  },
  /**
   * 见面打回
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerFailFace(params) {
    return Service.post(`/rs/fiveStep/faceFail`, params);
  },
  /**
   * 获取最近待见面时间操作
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerGetReadyFaceTime(params) {
    return Service.post(`/rs/fiveStep/getReadyFaceTime`, params);
  },
  /**
   * 工地列表
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerSiteList(params) {
    return Service.post(`/rs/fiveStep/siteList`, params);
  },
  /**
   * 申请看工地
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerLookSite(params) {
    return Service.post(`/rs/fiveStep/lookSite`, params);
  },
  /**
   * 取消看工地
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerFailLookSite(params) {
    return Service.post(`/rs/fiveStep/failLookSite`, params);
  },
  /**
   * 确认见工地
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerConfirmLookSite(params) {
    return Service.post(`/rs/fiveStep/confirmLookSite`, params);
  },
  /**
   * 跟进
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerFollowOrder(params) {
    return Service.post(`/rs/fiveStep/followOrder`, params);
  },
  getFollowParams(params) {
    return Service.post(`/rs/fiveStep/getFollowParams`, params);
  },

  /**
   * 客户申请死单和确认死单操作
   *
   * @param {*} params 参数
   * @returns Promise
   */
  customerChangeLost(params) {
    return Service.post(`/rs/customerManagement/customerChangeLost`, params);
  },
  /**
   * 死单客户操作
   *
   * @param {*} params 参数
   * @returns Promise
   */
  lostOperation(params) {
    return Service.post(`/rs/customerManagement/lostOperation`, params);
  },
  /**
   * 确认见面操作
   *
   * @param {*} params 参数
   * @returns Promise
   */
  confirmFaceOperation(params) {
    return Service.post(`/rs/fiveStep/confirmFace`, params);
  },

  /**
   *申请分配交付派单参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getAssignDeliverCenterParam(params) {
    return Service.post(`/rs/public/assignDeliverCenterParam`, params);
  },
  /**
   *申请分配交付派单
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getApplyDeliverCenter(params) {
    return Service.post(`/rs/construction/applyDeliverCenter`, params);
  },
  //申请设计改派
  getApplyDesignReassignment(params) {
    return Service.post(
      `/rs/customerManagement/applyDesignReassignment`,
      params
    );
  },
  //验证是否可以申请设计改派
  checkDesignReassignment(params) {
    return Service.post(
      `/rs/customerManagement/checkDesignReassignment`,
      params
    );
  },
  //申请设计改派参数
  designReassignmentParam(params) {
    return Service.post(`/rs/public/designReassignmentParam`, params);
  },
  //楼盘列表
  getFloorsApi(params) {
    return Service.post(`/rs/company/getFloors`, params);
  },
  // 见面自评通知客户
  customerInviteCommentNoticeParam(params) {
    return Service.post(`/rs/fiveStep/inviteCommentNotice`, params);
  },
  //见面订单\申请设计派单 公共参数
  getDispatchParams(params) {
    return Service.post(`/rs/public/getDispatchParams`, params);
  },
  //V1.3.2获取现场负责人列表参数
  getSceneLeaders(params) {
    return Service.post(`/rs/worker/getSceneLeaders`, params);
  },
  //V1.3.2获取现场负责人列表参数
  getTeamParams(params) {
    return Service.post(`/rs/worker/getTeamParams`, params);
  },
  //V1.3.2交付中心改派
  setApplyDeliverReassignment(params) {
    return Service.post(`/rs/construction/applyDeliverReassignment`, params);
  },
  //获取工长参数-分派施工团队
  getAssignTeamWorkerLeaders(params) {
    return Service.post(`/rs/construction/getAssignTeamWorkerLeaders`, params);
  },
  //获取现场负责人参数-分派施工团队
  getAssignTeamSceneLeaders(params) {
    return Service.post(`/rs/construction/getAssignTeamSceneLeaders`, params);
  },
  //分派施工团队
  getAssignTeamApi(params) {
    return Service.post(`/rs/construction/assignTeam`, params);
  },
  //解除施工团队
  construction_cancelTeam(params) {
    return Service.post(`/rs/construction/cancelTeam`, params);
  },
  //测算工期
  construction_calcSchedule(params) {
    return Service.post(`/rs/construction/calcSchedule`, params);
  },
  //测算工期-测算工期-获取合同列表
  construction_getCalcContractList(params) {
    return Service.post(`/rs/construction/getCalcContractList`, params);
  },
  //解除项目经理
  construction_cancelProjectManager(params) {
    return Service.post(`/rs/construction/cancelProjectManager`, params);
  },
  //派单详情
  customerManagement_getDispatchDetail(params) {
    return Service.post(`/rs/customerManagement/getDispatchDetail`, params);
  },
  //派单编辑or新建
  customerManagement_editDispatchDetail(params) {
    return Service.post(`/rs/customerManagement/editDispatchDetail`, params);
  },
  //客户派单信息
  customerManagement_dispatchInfo(params) {
    return Service.post(`/rs/customerManagement/dispatchInfo`, params);
  },
  //解除指派交付中心
  construction_cancelAssign(params) {
    return Service.post(`/rs/construction/cancelAssign`, params);
  },
  //指派交付中心
  construction_assignDeliverCenter(params) {
    return Service.post(`/rs/construction/assignDeliverCenter`, params);
  },
  //分配项目经理参数
  construction_assignProjectManagerParam(params) {
    return Service.post(`/rs/public/assignProjectManagerParam`, params);
  },
  // 标记指派
  construction_tagAssign(params) {
    return Service.post(`/rs/construction/tagAssign`, params);
  },
  // @业绩分摊详情
  company_customerInfo(parmas) {
    return Service.post(`/rs/company/customerInfo`, parmas);
  },
  // @分摊业绩申请
  company_applySplit(parmas) {
    return Service.post(`/rs/company/applySplit`, parmas);
  },
};
