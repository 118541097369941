<!--
 * @Author: NanNan
 * @Date: 2023-10-17 10:27:39
 * @LastEditTime: 2024-06-14 11:10:34
 * @Description: v1.4.7 客资表-跟进记录模版
-->
<template>
  <div class="page-box">
    <!-- 头部标题 -->
    <div class="pageBoxSticky">
      <div class="title-box" v-if="props?.pages != 2">
        <div class="left">
          <p class="title">跟进记录</p>
          <p class="desc">
            <span class="width25">
              {{ props.data?.name }}
            </span>
            <em>|</em>
            <span class="width35">
              {{ props.data?.floor_name }}
            </span>
            <em class="width25">|</em>
            <span>{{ props.data?.size }}</span>
          </p>
        </div>
        <Icon name="cross" :size="16" color="#000" @click.stop="cancel" />
      </div>
    </div>
    <div class="btn-box" v-if="props?.pages == 2">
      <Button
        type="primary"
        size="small"
        class="btn"
        @click.stop="actionFollow()"
        v-if="allOperationAuthority.indexOf('8') > -1"
      >
        写跟进
      </Button>
      <Button
        plain
        type="primary"
        size="small"
        class="btn"
        @click.stop="linkTo"
      >
        客户详情
      </Button>
    </div>
    <!-- 加载状态：初始 -->
    <div class="skeleton" v-if="_DATA.loading">
      <Skeleton title avatar :row="3" />
      <Skeleton title avatar :row="3" />
    </div>
    <!-- 加载状态：添加、删除 -->
    <Overlay :show="_DATA.loading_page" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <List
      v-model:loading="_DATA.loading"
      :finished="finished"
      :offset="50"
      @load="loadFunc"
    >
      <div
        v-for="(item, index) in _DATA.list_data"
        :key="index"
        class="same-box"
        :class="{ pb0: index + 1 === _DATA.list_data.length }"
      >
        <div class="title-box">
          <span class="tag" v-if="item.is_share == 1">首见</span>
          <p class="time">{{ item.add_time }}</p>
        </div>

        <div class="con-box">
          <dl class="cus-message">
            <dt>
              <lazy-component>
                <Image
                  v-if="item.face_image"
                  :src="
                    item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                  "
                  v-lazy
                  fit="cover"
                  round
                />
                <img
                  :src="require('@/assets/images/icon-empty-img.png')"
                  v-else
                />
              </lazy-component>
            </dt>
            <dd>
              <p class="name">{{ item.user_name }}</p>
              <p class="depart">
                {{
                  item.department_name +
                  (item.department_name && item.post_name ? '/' : '') +
                  item.post_name
                }}
              </p>
            </dd>
          </dl>

          <dl class="same-flex" v-for="(val, key) in item.result" :key="val">
            <dt>{{ key }}:</dt>
            <dd v-html="val ? val : '-'"></dd>
          </dl>

          <!-- 附件 -->
          <div class="fileList" v-if="item.attach && item.attach.length > 0">
            <FileGrid :data="item.attach"></FileGrid>
          </div>
          <div v-if="item.next_result">
            <dl
              class="same-flex"
              v-for="(val, key) in item.next_result"
              :key="val"
            >
              <dt>{{ key }}:</dt>
              <dd v-html="val ? val : '-'"></dd>
            </dl>
          </div>
          <!-- end -->

          <!-- 评论区域 -->
          <div class="review-box">
            <p class="title">
              <span>
                {{
                  item.comment_list.length > 0
                    ? '评论（' + item.comment_list.length + '）'
                    : ''
                }}
              </span>
              <span>
                <a
                  @click="shareRecordList(item)"
                  class="mr_15"
                  v-if="item.is_share == 1"
                >
                  分享到客户群
                </a>
                <a @click.stop="handleAdd(item.id, index)">添加评论</a>
              </span>
            </p>
            <div class="content" v-if="item.comment_list.length > 0">
              <dl
                class="same-flex"
                v-for="(val, idx) in item.comment_list"
                :key="idx"
              >
                <dt>
                  <lazy-component>
                    <Image
                      v-lazy
                      fit="cover"
                      :src="
                        val.face_image.includes('http')
                          ? val.face_image
                          : $host + val.face_image
                      "
                      v-if="val.face_image"
                      round
                    />
                    <Image
                      v-lazy
                      fit="cover"
                      :src="require('@/assets/images/icon-empty-img.png')"
                      v-else
                    />
                  </lazy-component>
                </dt>
                <dd>
                  <div class="top">
                    <p class="name">{{ val.user_name }}</p>
                    <p class="depart">
                      {{ val.department_name + '/' + val.post_name }}
                    </p>
                  </div>

                  <p class="remark">
                    {{ val.content }}
                  </p>
                  <p class="time">
                    <span>{{ val.add_time }}</span>
                    <a
                      v-if="val.delete_flag === 1"
                      @click="handleDel(val.id, index)"
                    >
                      删除
                    </a>
                  </p>
                </dd>
              </dl>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
      <template v-if="_DATA.list_data.length > 0 && !props?.finished" #finished>
        <Divider class="divider-bottom">我也是有底线的</Divider>
      </template>
    </List>

    <!-- 空数据 -->
    <!-- 入口：客资表入口 -->
    <div v-if="props?.pages == 2">
      <div
        class="empty-word-box"
        v-if="!_DATA.loading && _DATA.list_data?.length == 0"
      >
        尚未跟进
      </div>
    </div>
    <div v-else>
      <div
        class="empty-box"
        style="background-color: #ffffff"
        v-if="!_DATA.loading && _DATA.list_data?.length == 0"
      >
        <img :src="require('@/assets/images/empty-img.png')" alt="" />
        <p>暂无跟进记录</p>
      </div>
    </div>
  </div>
  <!-- pop 写评论 -->
  <Popup v-model:show="_DATA.show" position="bottom">
    <div style="background: #f5f5f5; padding: 12px 15px">
      <Field
        v-model="_DATA.form.remark"
        rows="3"
        type="textarea"
        center
        clearable
        placeholder="请填写评论"
        @blur="handleBlur"
        ref="inputField"
        style="border: none"
        maxlength="500"
        show-word-limit
        autofocus
        :formatter="(value) => value.replace(/^\s*|\s*$/g, '')"
        format-trigger="onChange"
        :rules="[
          {
            required: true,
            message: '请填写评论',
          },
        ]"
      >
        <template #button>
          <Button
            size="small"
            type="primary"
            @click="handleSend"
            :disabled="_DATA.form.remark == ''"
          >
            确认
          </Button>
        </template>
      </Field>
    </div>
  </Popup>
  <!-- pop 写跟进 -->
  <Popup
    round
    :close-on-popstate="true"
    position="bottom"
    v-model:show="followInfo.show"
    :style="{ maxHeight: '70%' }"
  >
    <TempAddFollow
      v-if="followInfo.show"
      :data="followInfo.pop"
      @reviewOk="reviewOk"
    />
  </Popup>
</template>

<script setup>
import FileGrid from '@/components/common/FileGrid';
import TempAddFollow from '@/components/sale/TempAddFollow';

import {
  inject,
  onMounted,
  reactive,
  ref,
  defineProps,
  computed,
  defineEmits,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  Field,
  Popup,
  Notify,
  Button,
  Image,
  Skeleton,
  List,
  Divider,
  Overlay,
  Loading,
  Icon,
} from 'vant';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const props = defineProps(['id', 'finished', 'pages', 'data']);
const emit = defineEmits(['reviewOk', 'getRecordTotal']);

const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();

const _DATA = reactive({
  list_data: [],
  loading: false,
  show: false,
  form: {
    record_id: '',
    remark: '',
    index: 0,
  },
});

const finished = ref(props?.finished);
const pageInfo = reactive({
  page: 1,
  pages: props?.pages,
  total: 0,
  count_page: 1,
});

onMounted(() => {
  props?.finished && loadFunc();
  console.log(allOperationAuthority.value.indexOf('8') > -1);
});

/**
 * 接口
 */
// -- 客户跟进记录 列表
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .getSaleFollowRecordApi({
      customer_id: props?.id,
      type: 2,
      page: pageInfo.page,
      pages: pageInfo.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list_data = _DATA.list_data.concat(data.list);
        Object.assign(pageInfo, { ...data.page });
        if (data.page.count_page > data.page.page) {
          pageInfo.page++;
        } else {
          finished.value = true;
        }
        emit('getRecordTotal', data.page.total);
        console.log(_DATA.list_data);
      } else {
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    });
};

/**
 * 操作
 */
// -- 写跟进
const followInfo = reactive({
  show: false,
  pop: props?.data,
});

// -- 写跟进：pop展示
const actionFollow = () => {
  followInfo.show = true;
};

// -- 写跟进：pop回调
const reviewOk = (pop) => {
  console.log(pop);
  followInfo.show = false;
  if (pop === 'ok') {
    _DATA.list_data = [];
    pageInfo.page = 1;
    loadFunc();
  }
};

// -- 添加评论：点击事件、唤起键盘
const inputField = ref(null);
const handleAdd = (id, index) => {
  _DATA.show = true;
  _DATA.form.record_id = id;
  _DATA.form.remark = '';
  _DATA.form.index = index;
  setTimeout(() => {
    inputField.value?.focus();
  }, 100);
  return;
};

// -- 添加评论：点击完成
const handleBlur = () => {
  inputField.value.blur();
  return;
};

// --  添加评论：点击发送
const handleSend = () => {
  _DATA.loading_page = true;
  $http.getSaleFollowRecordAddApi({ ..._DATA.form }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.show = false;
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          const index = Math.ceil((_DATA.form.index + 1) / pageInfo.pages);
          commonFunc(index);
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// -- 删除评论
const handleDel = (id, idx) => {
  _DATA.loading_page = true;
  $http.getSaleFollowRecordDelApi({ id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          const index = Math.ceil((idx + 1) / pageInfo.pages);
          commonFunc(index);
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// -- 公共接口调用：删除、添加之后的操作
const commonFunc = (page) => {
  $http
    .getSaleFollowRecordApi({
      customer_id: props?.id,
      type: 2,
      page: page,
      pages: pageInfo.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading_page = false;
      if (code === 0) {
        _DATA.list_data.splice(
          (page - 1) * pageInfo.pages,
          pageInfo.pages,
          ...data.list
        );
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

// -- 取消
const cancel = () => {
  emit('reviewOk', 'cancel', props?.data);
};

// -- 分享操作
const shareRecordList = (itme) => {
  router.push({
    path: '/followInfoShareImg',
    query: {
      id: itme.id,
    },
  });
};

// -- 跳转
const linkTo = () => {
  router.push({ path: '/sale', query: { id: props.data?.id } });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/TempFollowList.less';
</style>
