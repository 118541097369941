/*
 * @Author: gest
 * @Date: 2022-09-08 18:04:03
 * @LastEditTime: 2024-05-23 10:55:35
 * @Description:
 */
// 线索
import clueEditInfo from '@/views/clue/clueEditInfo';
import clueNewInfo from '@/views/clue/clueNewInfo';
import clueList from '@/views/clue/clueList';
import clueDetail from '@/views/clue/clueDetail';
import clueCreateCustomer from '@/views/clue/clueCreateCustomer';
import clueFollow from '@/views/clue/clueFollow';
import clueFollowNew from '@/views/clue/clueFollowNew';
import clueDesignDispatch from '@/components/clue/TempDesignDispatch';
import clueRecord from '@/views/clue/clueRecord';
const clueRoute = [
  {
    path: '/clueList',
    component: clueList,
    meta: {
      name: '线索管理',
      bar: true,
      right: {
        show: true,
        path: '/clueNewInfo',
        auth: '242',
      },
    },
  },
  {
    path: '/clueDetail',
    component: clueDetail,
    meta: {
      name: '线索详情',
      bar: true,
    },
  },
  {
    path: '/clueEditInfo',
    component: clueEditInfo,
    meta: {
      name: '完善信息',
      bar: true,
    },
  },
  {
    path: '/clueCreateCustomer',
    component: clueCreateCustomer,
    meta: {
      name: '生成客户',
      bar: true,
    },
  },
  {
    path: '/clueFollow',
    component: clueFollow,
    meta: {
      name: '跟进记录',
      bar: true,
    },
  },
  {
    path: '/clueFollowNew',
    component: clueFollowNew,
    meta: {
      name: '新增跟进记录',
      bar: true,
    },
  },
  {
    path: '/clueDesignDispatch',
    component: clueDesignDispatch,
    meta: {
      name: '申请设计派单',
      bar: true,
    },
  },
  {
    path: '/clueNewInfo',
    component: clueNewInfo,
    meta: {
      name: '添加线索',
      bar: true,
    },
  },
  {
    path: '/clueRecord',
    component: clueRecord,
    meta: {
      name: '见面记录',
      bar: true,
    },
  },
];
export default clueRoute;
