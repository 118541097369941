<!--
 * @Author: Jessica
 * @Date: 2023-12-21 10:08:30
 * @LastEditTime: 2023-12-22 11:19:14
 * @Description: v1.5.2 交付产品业绩待办
-->
<template>
  <div class="page-box" :class="{ all_height: _DATA.showType == 'look' }">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <!-- 客户信息 -->
    <div class="detail">
      <div class="cell">
        <label class="title">客户编码</label>
        <div class="right">
          <p class="text">{{ detail_info?.no || '-' }}/</p>
          <p class="text">{{ detail_info?.bpm_id || '-' }}</p>
        </div>
      </div>
      <div class="cell">
        <label class="title">工地状态</label>
        <div class="right">
          <p class="text col">
            {{ detail_info?.construction_status_name || '-' }}
          </p>
        </div>
      </div>
      <div class="cell">
        <label class="title">完工日期</label>
        <div class="right">
          <p class="text">{{ detail_info?.construction_done_date || '-' }}</p>
        </div>
      </div>
      <div class="cell">
        <label class="title">精装/毛坯</label>
        <div class="right">
          <p class="text">
            {{
              detail_info.is_fine === 1 || detail_info.is_fine === 0
                ? detail_info.is_fine === 1
                  ? '精装'
                  : '毛坯'
                : '-'
            }}
          </p>
        </div>
      </div>
      <div class="cell">
        <label class="title">客户姓名</label>
        <div class="right">
          <p class="text">{{ detail_info?.customer_name || '-' }}</p>
        </div>
      </div>
      <div class="cell">
        <label class="title">项目地址</label>
        <div class="right">
          <p class="text">{{ detail_info?.estate_str || '-' }}</p>
        </div>
      </div>
    </div>
    <h3 class="top_title">业绩信息</h3>
    <!-- 业绩详情 -->
    <div class="form_detail" v-if="_DATA.showType == 'look'">
      <div class="line1">
        <div class="cell">
          <label class="title">交付产品业绩</label>
          <p class="text">
            {{
              detail_info?.construction_product_performance ||
              detail_info?.construction_product_performance === 0
                ? '¥' + detail_info?.construction_product_performance
                : '-'
            }}
          </p>
        </div>
        <div class="tip_info">
          项目累计业绩金额：{{
            _DATA.all_performance || _DATA.all_performance === 0
              ? _DATA.all_performance + '元'
              : '-'
          }}
        </div>
      </div>
      <div class="line1">
        <div class="cell">
          <label class="title">交付主材业绩</label>
          <p class="text">
            {{
              detail_info?.construction_main_performance ||
              detail_info?.construction_main_performance === 0
                ? '¥' + detail_info?.construction_main_performance
                : '-'
            }}
          </p>
        </div>
      </div>
      <div class="line1">
        <div class="cell">
          <label class="title">交付配饰业绩</label>
          <p class="text">
            {{
              detail_info?.construction_accessory_performance ||
              detail_info?.construction_accessory_performance === 0
                ? '¥' + detail_info?.construction_accessory_performance
                : '-'
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- 业绩表单 -->
    <Form class="form_box" ref="formRef" v-else>
      <div class="line">
        <div class="cell_no">
          <label class="form_title">
            <span style="color: #ea3d2f">*</span>
            交付产品业绩
          </label>
          <div class="next">
            <Field
              v-model="form_info.construction_product_performance"
              type="number"
              placeholder="请输入"
              maxlength="12"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'onChange',
                },
                {
                  validator: validatorAmount,
                  message: '请输入正确的金额',
                  trigger: 'onChange',
                },
              ]"
            />
            <span class="extra">元</span>
          </div>
        </div>
        <div class="tip_info">
          项目累计业绩金额：{{
            _DATA.all_performance || _DATA.all_performance === 0
              ? _DATA.all_performance + '元'
              : '-'
          }}
        </div>
      </div>
      <div class="line">
        <div class="cell_no">
          <label class="form_title">
            <span style="color: #ea3d2f">*</span>
            交付主材业绩
          </label>
          <div class="next">
            <Field
              v-model="form_info.construction_main_performance"
              type="number"
              placeholder="请输入"
              maxlength="12"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'onChange',
                },
                {
                  validator: validatorAmount,
                  message: '请输入正确的金额',
                  trigger: 'onChange',
                },
              ]"
            />
            <span class="extra">元</span>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="cell_no">
          <label class="form_title">
            <span style="color: #ea3d2f">*</span>
            交付配饰业绩
          </label>
          <div class="next">
            <Field
              v-model="form_info.construction_accessory_performance"
              type="number"
              placeholder="请输入"
              maxlength="12"
              :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'onChange',
                },
                {
                  validator: validatorAmount,
                  message: '请输入正确的金额',
                  trigger: 'onChange',
                },
              ]"
            />
            <span class="extra">元</span>
          </div>
        </div>
      </div>
    </Form>
    <div class="stick-box" v-if="!_DATA.showType">
      <Button type="primary" size="large" @click="onSubmit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, inject, ref } from 'vue';
import { Form, Notify, Loading, Overlay, Button, Field } from 'vant';
import { useRouter } from 'vue-router';

const $http = inject('$http');
const loading = ref(false);
const router = useRouter();

const _DATA = reactive({
  todo_id: router.currentRoute.value.query.todo_id, //-待办id
  showType: router.currentRoute.value.query.showType, //-是否为查看待办详情
  all_performance: '',
});

//-详情
const detail_info = {};

//-表单
const form_info = reactive({
  id: _DATA.todo_id,
  //-交付产品业绩
  construction_product_performance: '',
  //-交付主材业绩
  construction_main_performance: '',
  //-交付配饰业绩
  construction_accessory_performance: '',
});
//-正则验证，不为负数,最多两位小数
const amountReg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
const validatorAmount = (val) =>
  val
    ? parseFloat(val) <= 99999999.99 && amountReg.test(val)
      ? true
      : false
    : true;

onMounted(() => {
  loadOptions();
});

/**
 * 接口获取
 */
//-@ 详情接口
const loadOptions = () => {
  loading.value = true;
  $http.todo_productPerformance({ id: _DATA.todo_id }).then((res) => {
    const { data, code } = res;
    loading.value = false;
    if (code === 0) {
      _DATA.all_performance = data.all_performance;
      Object.assign(detail_info, data);
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

/**
 * 操作
 */

//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http
      .todo_productPerformanceDeal({
        ...form_info,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              loading.value = false;
              // 哪里来回哪里去
              router.back();
            },
          });
        } else {
          loading.value = false;
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  });
};
</script>
<style lang="less" scoped>
.page-box {
  min-height: calc(100vh - 90px);
  &.all_height {
    min-height: calc(100vh - 46px);
  }
  padding: 0px;
  background-color: #f5f5f5;
  .detail {
    padding: 0px 20px;
    background: #fff;
    .cell {
      padding: 15px 0;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      .title {
        font-size: 15px;
        color: #8d9091;
        width: 100px;
      }
      .right {
        width: calc(100% - 100px);
        .text {
          font-size: 15px;
          color: #000000;
          text-align: right;
          &.col {
            color: #008911;
          }
        }
      }
    }
  }
  .top_title {
    color: #8e8e8e;
    font-size: 13px;
    padding: 10px 20px;
  }
  .form_detail {
    padding: 0px 20px;
    background: #fff;
    .line1 {
      padding: 15px 0;
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        border-bottom: none;
      }
      .cell {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 15px;
          color: #000000;
          width: 100px;
        }
        .text {
          width: calc(100% - 100px);
          font-size: 15px;
          color: #000000;
          text-align: right;
        }
      }
    }
    .tip_info {
      color: #8d9091;
      font-size: 13px;
    }
  }
  .form_box {
    background: #fff;
    padding-bottom: 90px;
    .line {
      padding: 15px 0px;
      margin: 0 15px;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      &:last-child {
        border-bottom: none;
      }
      .cell_no {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form_title {
          font-size: 15px;
          min-width: fit-content;
        }
        :deep(.van-cell) {
          padding: 5px 0;
          text-align: right;
          border: 0;
          .van-field__error-message {
            text-align: right;
            padding: 0 10px;
          }
        }
        .next {
          width: calc(100% - 120px);
          display: flex;
          align-items: baseline;
          justify-content: right;
          :deep(.van-cell) {
            width: 100%;
            .van-field__control {
              text-align: right;
              justify-content: center;
              padding: 0 10px;
            }
            &:after {
              border-bottom: 0px;
            }
          }
          .extra {
            font-size: 15px;
            color: #000000;
          }
        }
      }
    }
    .tip_info {
      color: #8d9091;
      font-size: 13px;
    }
  }
}
</style>
