<!--
 * @Author: NanNan
 * @Date: 2022-04-12 14:19:59
 * @LastEditTime: 2024-03-05 18:01:15
 * @Description: 
-->
<template>
  <NavBar
    safe-area-inset-top
    :title="title"
    :left-arrow="!isIndex"
    @click-left="onBack"
    v-if="showTitle"
    :fixed="true"
    :placeholder="true"
    style="height: 12.3vw !important; overflow: hidden"
  >
    <template
      #right
      v-if="
        route.meta.right &&
        allOperationAuthority.indexOf(route.meta.right?.auth) > -1
      "
    >
      <div class="right-box" @click="jumpTo(route.meta.right?.path)">
        <Icon name="add-o" size="17" style="margin-right: 4px" />
        <span class="name">新增</span>
      </div>
    </template>
  </NavBar>
  <router-view></router-view>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { NavBar, Icon } from 'vant';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const isIndex = ref(false);
const title = ref('');
const route = useRoute();
const router = useRouter();
const showTitle = ref(true);

/**
 * 方法
 */
const isIndexView = (str) => {
  return str === '' || str === '/';
};

const onBack = () => {
  if (window.history.length > 1) {
    router.back();
  } else {
    if (router.currentRoute.value.path === '/clueFollow') {
      router.push({
        path: '/clueDetail',
        query: { ...router.currentRoute.value.query },
      });
    }
  }
};
watch(
  [() => route.path, () => route.meta],
  ([to, newMeta]) => {
    isIndex.value = isIndexView(to);
    title.value = (newMeta || {}).name || '';
    showTitle.value = newMeta.bar;
  },
  {
    immediate: true,
  }
);

const jumpTo = (path) => {
  router.push({
    path,
  });
};
</script>

<style lang="less" scoped>
.right-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .name {
    font-size: 15px;
    color: #000;
  }
}
</style>
