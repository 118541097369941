<!--
 * @Author: gest
 * @Date: 2023-09-12 13:51:19
 * @LastEditTime: 2023-10-27 11:21:27
 * @Description: v1.4.6 进场产品验收记录单
-->
<template>
  <div class="formBase">
    <div class="content mb_15 white_bg">
      <div class="cell_border flex_between">
        <div class="flex_column flex1 mr_15">
          <div><span class="red_color">*</span>是否为裸单项目</div>
        </div>
        <Field
          name="radio"
          label=""
          :rules="[
            {
              required: true,
              message: '请选择',
            },
          ]"
        >
          <template #input>
            <RadioGroup
              @click="radioClickAll()"
              v-model="form.info.bare_single"
              direction="horizontal"
              checked-color="#008911"
              :disabled="
                form.info.contract_type == 53 || form.info.contract_type == 69
              "
            >
              <Radio :name="1">是</Radio>
              <Radio :name="2">否</Radio>
            </RadioGroup>
          </template>
        </Field>
      </div>
    </div>
    <div class="content mb_15">
      <!-- v-for -->
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="flex1 mr_20">{{ item.name }}</div>
            <Field
              style="height: 6.2vw"
              v-if="form.info.bare_single == 2"
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick(item)"
                  v-model="item.value1"
                  direction="horizontal"
                  checked-color="#008911"
                >
                  <Radio :name="1">有</Radio>
                  <Radio :name="2">无</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>

        <div
          class="list_field"
          style="background-color: #fafafa"
          v-if="item.value1 == 1 && form.info.bare_single == 2"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">
                <span class="red_color">*</span>进场时间
              </div>
              <Field
                style="text-align: right; background-color: #fafafa"
                v-model="item.value2"
                right-icon="arrow"
                name="picker"
                placeholder="选择"
                readonly
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                  },
                ]"
                @click="openDateType(item)"
              />
            </div>
          </div>
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">
                <span class="red_color">*</span>验收状态
              </div>
              <Field
                style="background: transparent"
                class="grey_field"
                name="radio"
                label=""
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                  },
                ]"
              >
                <template #input>
                  <RadioGroup
                    v-model="item.value3"
                    direction="horizontal"
                    checked-color="#008911"
                  >
                    <Radio :name="1">合格</Radio>
                    <Radio :name="2">不合格</Radio>
                  </RadioGroup>
                </template>
              </Field>
            </div>
          </div>
        </div>
      </div>
      <!-- v-for end-->
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <span class="label-name">备注说明</span>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="请说明进场验收情况，如验收不合格写明具体不合格的原因与产品更换时间..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        :max-date="state.maxDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v)"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Popup, DatetimePicker, Dialog } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);
const state = reactive({
  //-时间控件
  maxDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
  selectItem: null,
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

// @-时间控件
const openDateType = (item) => {
  state.showTimePicker = true;
  state.selectItem = item;
  if (item.value2) {
    state.currentDate = new Date(item.value2);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  state.showTimePicker = false;
  state.selectItem.value2 = moment(value).format('YYYY-MM-DD');
};

const radioClick = (item) => {
  // 从【有此产品】切换至【无此产品】需二次弹框确认；
  if (item.value1 == 2) {
    if (item.value2 || item.value3) {
      Dialog.confirm({
        message: '确认将[有此产品]切换成[无此产品]吗？一旦确认，填写数据将清空',
      })
        .then(() => {
          item.value2 = '';
          item.value3 = null;
        })
        .catch(() => {
          item.value1 = 1;
        });
    }
  }
};
const radioClickAll = () => {
  // 切换为裸单项目需二次弹框确认；
  if (form.info.bare_single == 1) {
    let arr = form.info.basic_info.filter(
      (i) => i.value1 || i.value2 || i.value3
    );
    if (arr && arr.length > 0) {
      Dialog.confirm({
        message: '确认切换为裸单项目？一旦确认，填写的数据将清空',
      })
        .then(() => {
          form.info.basic_info = form.info.basic_info.map((v) => {
            v.value1 = null;
            v.value2 = '';
            v.value3 = null;
            return v;
          });
        })
        .catch(() => {
          form.info.bare_single = 2;
        });
    }
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
