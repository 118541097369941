<!--
 * @Author: Jessica
 * @Date: 2023-06-06 10:03:37
 * @LastEditTime: 2024-06-15 11:45:06
 * @Description: v1.3.8 安装计划
-->
<template>
  <div class="page-box">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="top_box">
      <div class="top_content">
        <!-- 头部搜索 -->
        <div class="header-box">
          <form action="/">
            <Search
              placeholder="产品大类/子类/名称/供应商/订单编号/品牌"
              background="#f5f5f5"
              class="search-field"
              v-model="_DATA.parmas.kw"
              @search="onSearch"
              @clear="onClear"
              show-action
            >
              <template #action>
                <div @click="onSearch" style="color: #000">搜索</div>
              </template>
            </Search>
          </form>
        </div>
        <!-- end -->
      </div>
    </div>
    <div class="tab-box-bigger">
      <Tabs
        v-model:active="_DATA.tab_active"
        title-active-color="#0256ff"
        title-inactive-color="#000000"
        :ellipsis="false"
        class="tab_box"
        @change="chageFun"
      >
        <Tab title="代购产品" :name="1"></Tab>
        <Tab title="自购产品" :name="2"></Tab>
      </Tabs>

      <!-- 内容区域 -->
      <div class="tab-content-bigger">
        <!-- 内容开始 -->
        <List
          v-model:loading="loading"
          :finished="finished"
          @load="onLoad"
          class="list-box"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            class="cell-same"
            @click="linkTo(item)"
          >
            <!-- 产品大类 -->
            <div class="sub_box">
              <div class="name_box">
                <p class="name">
                  {{ item.supplier_name }}
                </p>
                <p class="sub_name">
                  {{ item.order_no }}
                </p>
              </div>
              <p class="sub_name">{{ item.supplier_full_name }}</p>
            </div>
            <!-- 产品内容 -->
            <div class="info_box">
              <div class="left" @click.stop="fileDetail(item.img[0], item.img)">
                <Image
                  v-if="_DATA.tab_active == 1"
                  class="avader_img"
                  :src="
                    item.img.length
                      ? $host + item.img[0]['thumbnail_path']
                      : require('@/assets/images/supplier-avder-empty.png')
                  "
                />
                <Image
                  v-else
                  class="avader_img"
                  :src="
                    item.img.length
                      ? $host + item.img[0]['path']
                      : require('@/assets/images/supplier-avder-empty.png')
                  "
                />
              </div>
              <div class="right">
                <div class="name_box">
                  <p class="name">{{ item.name }}</p>
                  <!-- <div class="status_tag red">备货时间紧张</div> -->
                </div>
                <p class="info">
                  <span class="t1">{{ item.space_name || '-' }}</span>
                  <span class="t1">｜{{ item.category_name || '-' }}</span>
                  <span class="t1">｜{{ item.category_sub_name || '-' }}</span>
                  <span class="t1">｜{{ item.brand || '-' }}</span>
                  <span class="t1">｜{{ item.sku || '-' }}</span>
                  <span class="t1">｜{{ item.model || '-' }}</span>
                  <span class="t1">｜{{ item.norms || '-' }}</span>
                </p>
              </div>
            </div>
            <!-- 各类节点时间 -->
            <div class="time_box">
              <div class="line clearfix">
                <p class="c1 t1"></p>
                <p class="c1 t2">节点</p>
                <p class="c1 t3">预计时间</p>
                <p class="c1 t4">实际完成时间</p>
              </div>
              <div class="line clearfix">
                <p class="c1 t1">下单</p>
                <p class="c2 t2">{{ item.order_att_name || '-' }}</p>
                <p class="c2 t3">{{ item.plan_order_time || '-' }}</p>
                <p class="c2 t4">{{ item.actual_order_time || '-' }}</p>
              </div>
              <div class="line clearfix">
                <p class="c1 t1">测量</p>
                <p class="c2 t2">{{ item.measure_att_name || '-' }}</p>
                <p class="c2 t3">{{ item.plan_measure_time || '-' }}</p>
                <p class="c2 t4">{{ item.actual_measure_time || '-' }}</p>
              </div>
              <div class="line clearfix">
                <p class="c1 t1">收货</p>
                <p class="c2 t2">{{ item.receive_att_name || '-' }}</p>
                <p class="c2 t3">{{ item.plan_receive_time || '-' }}</p>
                <p class="c2 t4">{{ item.onsite_receipt_time || '-' }}</p>
              </div>
              <div class="line clearfix">
                <p class="c1 t1">安装</p>
                <p class="c2 t2">{{ item.install_att_name || '-' }}</p>
                <p class="c2 t3">{{ item.plan_install_time || '-' }}</p>
                <p class="c2 t4">{{ item.complete_install_time || '-' }}</p>
              </div>
              <div class="line clearfix">
                <p class="c1 t1">验收</p>
                <p class="c2 t2">{{ item.acceptance_att_name || '-' }}</p>
                <p class="c2 t3">{{ item.plan_accept_time || '-' }}</p>
                <p class="c2 t4">{{ item.install_acceptance_time || '-' }}</p>
              </div>
            </div>
          </div>
          <template v-if="list.length > 0" #finished>没有更多了</template>
        </List>
        <!-- 空状态 -->
        <Empty
          class="empty-fff"
          description="暂无数据"
          v-if="!loading && list.length === 0"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, inject, reactive } from 'vue';
import { Search, Notify, Tab, Tabs, List, Empty, Image } from 'vant';
import { useRouter } from 'vue-router';
import { fileDetail } from '@/utils/fileUpload';
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const _DATA = reactive({
  tab_active: 1,
  parmas: {
    kw: '',
    construction_id: router.currentRoute.value.query.gdid, // 工地id
  },
  page: {
    count_page: 1,
    page: 1,
    pages: 10,
    total: 0,
  },
});

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .mainOrder_installPlan({
      page: _DATA.page.page,
      pages: _DATA.page.pages,
      order_source: _DATA.tab_active,
      ..._DATA.parmas,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        _DATA.page = data.page;
        list.value = list.value.concat(data.list);
        if (data.page.page <= data.page.count_page) {
          _DATA.page.page++;
        } else {
          finished.value = true;
        }
      } else {
        loading.value = false;
        finished.value = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

/*
 * 操作
 */
//-@ 搜索
const onSearch = () => {
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
};

//-切换tab
const chageFun = () => {
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
};
//-@ 清除
const onClear = () => {
  _DATA.parmas.kw = '';
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
};

//-@ 跳转
const linkTo = (item) => {
  if (_DATA.tab_active == 1) {
    //-代购详情页
    router.push({ path: '/mainOrderDetail', query: { id: item.id } });
  } else {
    //-自购详情页
    router.push({ path: '/mainSelfOrderDetail', query: { id: item.id } });
  }
};
</script>
<style lang="less" scoped>
.top_box {
  height: 56px;
  .top_content {
    position: fixed;
    width: 100%;
    z-index: 1;

    .header-box {
      :deep(.van-search__action) {
        padding: 0 10px;
      }
    }
  }
}

.page-box {
  padding: 0;
  min-height: calc(100% - 46px);

  //-tab
  .tab-box-bigger {
    margin-bottom: 15px;
    background: #f5f5f5;
    .tab_box {
      border-bottom: 1px solid rgba(228, 228, 228, 0.3);
      position: fixed;
      width: 100%;
      z-index: 9;
      background: #fff;

      :deep(.van-tabs__line) {
        display: block;
        width: 50%;
        background: @blue-color;
        height: 1px;
      }

      :deep(.van-tabs__nav--line.van-tabs__nav--complete) {
        padding-left: 0;
        padding-right: 0;
      }
      :deep(.van-tab__text) {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  .list-box {
    top: 55px;
    position: relative;
    background: rgb(245, 245, 245);
    .sub_name {
      color: #8d9091;
      font-size: 14px;
    }
    .cell-same {
      border: none;
      width: 100%;
      height: 100%;
      position: relative;
      background: #fff;
      margin-top: 10px;
      &:first-child {
        margin-top: 0px !important;
      }
      .info_box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0px 20px;
        padding: 10px 0px 0px;

        .left {
          .avader_img {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .right {
          flex: 1;
          padding-left: 12px;

          .name_box {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;
            .name {
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
            }
          }

          .t1 {
            font-size: 14px;
            color: #888888;
          }
        }
      }
      .sub_box {
        border-bottom: 1px solid rgba(228, 228, 228, 0.3);
        padding: 9px 20px;
        .name_box {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
          .name {
            font-size: 14px;
            font-weight: 600;
          }
          .sub_name {
            color: #8d9091;
            font-size: 14px;
          }
        }
      }
      .time_box {
        margin: 0px 20px;
        padding: 10px 0px;
        .line {
          background: #fbfbfb;
          border-bottom: 1px solid #ffffff;
          .c1,
          .c2 {
            border-right: 1px solid #ffffff;
            float: left;
            font-size: 12px;
            padding: 5px 5px;
          }
          .c1 {
            color: #8d9091;
            &.t1 {
              text-align: center;
            }
          }
          .c2 {
            color: #000000;
          }
          .t1 {
            width: 45px;
          }

          .t2,
          .t3,
          .t4 {
            width: calc(33.3% - 15px);
          }
          .t4 {
            border-right: none;
          }
        }
      }
      .no_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        .no_info {
          font-size: 15px;
          display: flex;
          justify-content: space-between;
          width: calc(100% - 24px);

          .data {
            color: #8d9091;
          }
        }
      }
    }
    :deep(.van-list__finished-text) {
      background: rgb(245, 245, 245);
    }
  }
  .empty-fff {
    position: relative;
    top: 45px;
  }
}
</style>
