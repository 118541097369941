<!--
 * @Author: gest
 * @Date:  2022-11-02 17:57:45
 * @LastEditTime: 2024-04-27 17:17:14
 * @Description: 【申请设计派单】通过/不通过
 * 入口：
 *     1- 审批：我的审批-申请设计派单
 *     2- 改派审核操作面板
 * --------------- 分割线 ---------------
 * v1.4.7plus smart 派单方式=='自动轮派'，展示排除设计中心选项
 * v1.4.8 派单方式 === 492客户制定设计师中心，上传附件必填最少1个最多3个
 * v1.5.1 smart 更改派单方式 动态获取设计中心
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <h3 class="title_tip">{{ props.title }}</h3>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell" v-if="props.data.checkStaus == 'ok'">
            <div class="cell">
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                派单方式
              </label>
              <Field
                v-model="form.list.dispatch_mode_id_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="请选择派单方式"
                :rules="[
                  {
                    required: true,
                    message: '请选择派单方式',
                  },
                ]"
                @click="state.showPicker_dispatch_mode_id = true"
              />
            </div>
            <div
              class="cell"
              v-if="
                form.list.dispatch_mode_id == 336 &&
                props?.data?.reject_design_center_switch == 1
              "
            >
              <label class="form_title">排除设计中心</label>
              <Field
                v-model="form.list.reject_design_center_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="请选择排除设计中心，最多选择四个"
                @click="state.reject_design_picker.visible = true"
              />
            </div>
            <div
              class="cell"
              v-if="[411, 335, 492].indexOf(form.list.dispatch_mode_id) > -1"
            >
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                {{
                  form.list.dispatch_mode_id == 411
                    ? '优先轮派'
                    : '派单设计中心'
                }}
                <Icon
                  name="question"
                  color="#FFC300"
                  size="16"
                  @click.stop="showDialog"
                  v-if="form.list.dispatch_mode_id == 411"
                />
              </label>
              <Field
                v-model="form.list.design_center_id_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="请选择设计中心"
                :rules="[
                  {
                    required: true,
                    message: '请选择设计中心',
                  },
                ]"
                @click="
                  form.list.dispatch_mode_id == 492
                    ? (state.showPicker_design_select_center_id = true)
                    : (state.showPicker_design_center_id = true)
                "
              />
            </div>
          </div>

          <div class="cell">
            <label class="form_title">审核信息</label>
            <Field
              v-model="form.list.remarks"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注提交人可见"
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" style="box-shadow: none">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        确定
      </Button>
    </div>
    <!-- popup集合 -->

    <!--picker-派单方式 -->
    <Popup v-model:show="state.showPicker_dispatch_mode_id" position="bottom">
      <Picker
        :columns="state.dispatch_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_mode_id')"
        @cancel="state.showPicker_dispatch_mode_id = false"
        :default-index="
          state.dispatch_mode_list.findIndex((v) => {
            return v.id == form.list.dispatch_mode_id;
          })
        "
      />
    </Popup>
    <!--end -->

    <!--picker-设计中心 -->
    <Popup v-model:show="state.showPicker_design_center_id" position="bottom">
      <Picker
        :columns="state.centerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'design_center_id')"
        @cancel="state.showPicker_design_center_id = false"
      />
    </Popup>
    <!--end -->

    <!--picker-派单方式==492 对应设计中心 -->
    <Popup
      v-model:show="state.showPicker_design_select_center_id"
      position="bottom"
    >
      <Picker
        :columns="state.select_centerList"
        :columns-field-names="customFieldName2"
        @confirm="(v) => onConfirm(v, 'design_select_center_id')"
        @cancel="state.showPicker_design_select_center_id = false"
        :default-index="
          state.select_centerList.findIndex((v) => {
            return v.design_id == form.list.design_id;
          })
        "
      />
    </Popup>
    <!--end -->

    <!-- picker-排除设计中心 -->
    <Popup
      v-model:show="state.reject_design_picker.visible"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempMultiple
        v-if="state.reject_design_picker.visible"
        :data="state.reject_design_picker"
        @reviewClose="
          () => {
            state.reject_design_picker.visible = false;
          }
        "
        @reviewOk="reviewOk_design"
      />
    </Popup>
    <!-- end -->
  </div>
</template>

<script setup>
import {
  inject,
  reactive,
  ref,
  onBeforeMount,
  defineProps,
  defineEmits,
} from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  Icon,
  Dialog,
} from 'vant';
import TempMultiple from '@/components/clue/TempMultiple';

import { useRouter } from 'vue-router';
const props = defineProps(['data', 'title', 'id', 'customerObj']);
const emit = defineEmits(['sendForm']);
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-设计中心-picker
  showPicker_design_center_id: false,
  centerList: [],

  //-派单方式-picker
  showPicker_dispatch_mode_id: false,
  dispatch_mode_list: [],
  //-排除设计中心-picker
  reject_design_picker: {
    visible: false,
    title: '选择排除设计中心',
    max: 4,
    list: props?.data?.design_center_list, //排除设计中心list
    checked: [],
    checkedItems: [],
  },
  //-492设计中心-picker
  showPicker_design_select_center_id: false,
  select_centerList: [], //492对应的
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const customFieldName2 = {
  text: 'text',
  value: 'design_id',
  disabled: 'disabled',
};
const onConfirm = (value, type) => {
  if (type === 'design_select_center_id') {
    if (value.disabled) {
      form.list.design_center_id = '';
      form.list.design_center_id_name = '';
      form.list.design_id = '';
    } else {
      form.list.design_center_id = value?.design_center_id;
      form.list.design_center_id_name = value?.text;
      form.list.design_id = value?.design_id;
    }
  } else {
    form['list'][type] = value?.id;
    form['list'][type + '_name'] = value?.name;
  }

  state['showPicker_' + type] = false;
  if (type === 'dispatch_mode_id') {
    form.list.design_center_id = '';
    form.list.design_center_id_name = '';
    form.list.design_id = '';
  }
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
  getSelectDesignParam();
  if (props?.data?.dispatch_mode_list) {
    const filteredData = props?.data?.dispatch_mode_list.map((item) => ({
      ...item,
      disabled: props?.data.disable_dispatch_model.indexOf(item.id) > -1,
    }));
    state.dispatch_mode_list = filteredData;
  }
  console.log(props?.data);
});

/**
 * api
 */
// -- 获取参数
const getPublicParameter = async (dep_id) => {
  await $http
    .customerAssignDesignCenterParam({ dep_id: dep_id ? dep_id : '' })
    .then((res) => {
      if (res.code === 0) {
        state.centerList = res.data.design_center;
      } else {
        Toast(res.data);
      }
    });
};

// -- v1.5.1 根据派单方式，动态获取设计师列表
const getSelectDesignParam = async () => {
  await $http
    .public_assignSelectDesignParam({
      customer_id: props?.customerObj?.customer_id,
      dispatch_model: 492,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        const newData = data.map((item) => ({
          ...item,
          disabled: item.status === 2,
        }));
        console.log(newData);
        state.select_centerList = newData || [];
      } else {
        Toast(res.data);
      }
    });
};

/**
 * 操作
 */
//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    remarks: '',
    approver_remarks: '',
    design_id: props?.data?.default_design_id,
    design_center_id: props?.data?.default_design_center_id,
    design_center_id_name: props?.data?.default_design_center_name,
    dispatch_mode_id: props?.data?.default_dispatch_model,
    dispatch_mode_id_name: props?.data?.default_dispatch_model_name,
    reject_design_center_id: [], //排除设计中心
    reject_design_center_name: '', //排除设计中心，前端拼接字符串
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  emit('sendForm');
  ruleFormRef.value
    .validate()
    .then(() => {
      operateFun();
      console.log('wieiweiwie');
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

// 调用接口
const operateFun = () => {
  state.loadingConfirm = true;
  let obj = {};
  let url = $http.getTaskApproveDesignDispatch;
  if (props.data.type == 1) {
    //派单
    url = $http.getTaskApproveDesignDispatch;
    obj = {
      task_id: props.id,
      design_center_id:
        props.data.checkStaus == 'ok' ? form.list.design_center_id : '',
      approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
      remarks: form.list.remarks,
      face_time: props.data.formInfo.face_time,
      dispatch_level: props.data.formInfo.dispatch_level,
      face_mode: props.data.formInfo.face_mode,
      remark: props.data.formInfo.remark,
      dispatch_mode_id:
        props.data.checkStaus == 'ok' ? form.list.dispatch_mode_id : '',
      reject_design_center_id:
        props.data.checkStaus == 'ok' ? form.list.reject_design_center_id : [],
      design_id: form.list.design_id,
    };
  } else if (props.data.type == 15) {
    //改派
    obj = {
      task_id: props.id,
      design_center_id:
        props.data.checkStaus == 'ok' ? form.list.design_center_id : '',
      approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
      approver_remarks: form.list.remarks,
      face_time: props.data.formInfo.face_time,
      dispatch_level: props.data.formInfo.dispatch_level,
      face_mode: props.data.formInfo.face_mode,
      dispatch_remark: props.data.formInfo.remark,
      redispatch_reason: props.data.formInfo.reassignment_reason,
      designs: props.data.formInfo.designs,
      remark: props.data.formInfo.reassignment_remark,
      dispatch_mode_id:
        props.data.checkStaus == 'ok' ? form.list.dispatch_mode_id : '',
      reject_design_center_id:
        props.data.checkStaus == 'ok' ? form.list.reject_design_center_id : [],
      design_id: form.list.design_id,
    };
    url = $http.approveDesignReassignment;
  }
  url(obj).then((res) => {
    const { code, data } = res;
    state.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//--基本信息表单-----end----------------
//-优先轮派提示文字
const showDialog = () => {
  Dialog.confirm({
    title: '注意',
    message: `优先轮派仅优先派单一次，超时未接单时记录跳单。`,
    messageAlign: 'left',
    allowHtml: true,
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#006BFF',
  });
};

// -- v1.4.7 设计中心
const reviewOk_design = (checked, checkedItems) => {
  state.reject_design_picker.checked = checked;
  state.reject_design_picker.checkedItems = checkedItems;
  state.reject_design_picker.visible = false;
  form.list.reject_design_center_id = checked;
  const names = checkedItems.map((item) => item.name);
  form.list.reject_design_center_name = names.join(',');
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
