/*
 * @Author: Jessica
 * @Date: 2024-05-09 16:29:08
 * @LastEditTime: 2024-05-24 15:16:52
 * @Description:环形图-公共方法
 */
//-@环形图基础配置--begin
import { Chart } from '@antv/g2';

const renderFunnelChart = (containerId, height, padding, data, color) => {
  // 销毁已有的图表
  const canvasExists = document.getElementById(containerId);
  if (canvasExists?.firstChild) {
    canvasExists.removeChild(canvasExists.firstChild);
  }
  const chart = new Chart({
    container: containerId,
    forceFit: true,
    height: height,
    padding: padding,
  });
  commonChart(chart, data, color);
};

const commonChart = (chart, data, color) => {
  chart.coord('theta', {
    innerRadius: 0.6,
  });
  chart.axis(false);
  chart.legend(false);
  chart.tooltip(false);
  chart
    .intervalStack()
    .position('value')
    .color('name', color)
    .opacity(1)
    .select(false);
  chart.source(data);
  chart.render();
};
//-@环形图图基础配置--end
export { renderFunnelChart };
