<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2022-09-01 15:14:47
 * @Description: 
-->
<template>
  <!-- 分配设计中心 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              设计中心
            </label>
            <Field
              v-model="form.list.center_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择设计中心"
              :rules="[
                {
                  required: true,
                  message: '请选择设计中心',
                },
              ]"
              @click="
                () => {
                  state.showPicker_center = true;
                }
              "
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-设计中心 --->
    <Popup v-model:show="state.showPicker_center" position="bottom">
      <Picker
        :columns="state.centerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'center')"
        @cancel="state.showPicker_center = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-设计中心-picker
  showPicker_center: false,
  centerList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (type == 'center') {
    getPublicParameter(value.id);
  }
  if (value) {
    form['list'][type] = value.id;
    form['list'][type + '_name'] = value.name;
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async (dep_id) => {
  await $http
    .customerAssignDesignCenterParam({ dep_id: dep_id ? dep_id : '' })
    .then((res) => {
      if (res.code === 0) {
        state.centerList = res.data.design_center;
      } else {
        Toast(res.data);
      }
    });
};
//-获取参数--end

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    center: '',
    center_name: '',
    remarks: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .customerAssignDesignCenter({
          customer_id: form.list.customer_id,
          design_center_id: form.list.center,
          remarks: form.list.remarks,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (state.taskDate) {
                  router.push('/taskDay?date=' + state.taskDate);
                } else {
                  router.push('/sale?id=' + form.list.customer_id);
                }
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
