/*
 * @Author: NanNan
 * @Date: 2022-04-12 14:51:27
 * @LastEditTime: 2022-05-12 14:46:04
 * @Description:
 */
import { createRouter, createWebHistory } from 'vue-router';
import routeMap from './routeMap';

const router = createRouter({
  scrollBehavior: () => ({ y: 0 }),
  history: createWebHistory(),
  routes: routeMap,
});

router.beforeEach((to, from, next) => {
  next();
});

// update title
router.afterEach((to) => {
  document.title = (to.meta && to.meta.name) || '';
});

export default router;
