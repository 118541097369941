<!--
 * @Author: gest
 * @Date: 2022-05-17 13:24:22
 * @LastEditTime: 2024-06-14 11:12:50
 * @Description: 施工计划
 * 紧急上线0923: 上传操作 部根据小节点状态判断
 * v1.3.1 交互改版 smart
 * v1.3.1 紧急需求 增加验收回访的状态展示 smart
 * V1.4.0 紧急需求，调整施工阶段名称 Jessica
 * V1.6.2 新增标记完成、查看施工成员（只有配置工种且标记完成展示） Jessica
-->
<template>
  <div class="page-box" @load="_DATA.loading">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div v-if="!_DATA.loading && _DATA.infoList.length !== 0">
      <Sticky :offset-top="_DATA.offsetTop">
        <!-- 提示信息 -->
        <div class="tip_box" v-if="getTipInfo()?.length > 0">
          <Icon name="warning-o" color="#EA3D2F" :size="16" />
          <span class="text"
            >{{ '还未分配「' + getTipInfo().join('、') + '」，请尽快分配！' }}
          </span>
        </div>
        <!-- 详情信息 -->
        <div class="base-info">
          <div class="info">
            <h4>
              <span class="estate_str">{{ _DATA.info.estate_str }}</span>
              <span @click="lookDetail">详情></span>
            </h4>
            <p>开工时间：{{ _DATA.info.start_time || '-' }}</p>
          </div>
        </div>
        <!-- 节点切换-->
        <Tabs
          v-model:active="_DATA.tabIndex"
          class="tab-box"
          @change="changeTab"
          :swipe-threshold="3"
        >
          <Tab
            v-for="(item, index) in _DATA.allInfoList"
            :key="item.type_sort + item.node_status + ''"
            :name="item.type_sort"
            style="width: 25%"
          >
            <template #title>
              <div
                class="tab-item"
                :class="{
                  active: item.type_sort === _DATA.tabIndex,
                  last: _DATA.allInfoList.length - 1 == index,
                }"
              >
                <p
                  class="icon-box"
                  :class="{
                    ing: item.node_status === 1,
                    ok: item.node_status === 2,
                  }"
                >
                  <img :src="getTabIcon(item)" alt="" />
                </p>
                <p class="item-name">{{ item.type_name }}</p>
                <div class="flex">
                  <p class="item-status">
                    {{
                      item.node_status === 0
                        ? '未开始'
                        : item.node_status === 1
                        ? '进行中'
                        : '已完成'
                    }}
                  </p>

                  <p class="tag">
                    <span
                      class="delay ml_5"
                      v-if="item.delay_status == 1 && item.delay_day > 0"
                    >
                      延期{{ item.delay_day || 0 }}天
                    </span>
                    <span
                      class="success ml_5"
                      v-if="item.delay_status == 2 && item.delay_day > 0"
                    >
                      提前{{ item.delay_day || 0 }}天
                    </span>

                    <span
                      class="delay ml_5 mr_5"
                      v-if="item.modify_scheduler > 0"
                      >工期变更{{ item.modify_scheduler || 0 }}天</span
                    >
                  </p>
                </div>
              </div>
            </template>
          </Tab>
        </Tabs>
      </Sticky>
      <div class="text_center grey_color font_size_13 pt_15">
        标准工期<span class="black_color">{{
          _DATA.origin_schedule || '-'
        }}</span
        >天
        <span v-if="_DATA.node_status == 2 && _DATA.finish_schedule"
          >，完工工期<span class="black_color">{{ _DATA.finish_schedule }}</span
          >天</span
        >
      </div>
      <!-- 施工计划列表 -->
      <div class="content-box">
        <div
          class="content-item"
          v-for="(val, idx) in _DATA.infoList"
          :key="idx"
        >
          <div class="task-card-same">
            <!-- 状态栏 -->
            <div class="status-box">
              <div class="h1">
                <label
                  v-if="val.node_type === 1 || val.node_type > 3"
                  :class="{
                    green: val.status === 3,
                    blue: val.status === 2,
                    gray: val.status === 1,
                  }"
                >
                  <img
                    :src="
                      require('@/assets/images/icon-svg/icon-nostart-gray.svg')
                    "
                    alt=""
                    v-if="val.status === 1"
                  />
                  <img
                    :src="require('@/assets/images/icon-svg/icon-ing-blue.svg')"
                    alt=""
                    v-if="val.status === 2"
                  />
                  <img
                    :src="require('@/assets/images/icon-cons-ok.png')"
                    alt=""
                    v-if="val.status === 3"
                  />
                  {{ val.status_name }}
                </label>
                <label
                  v-if="val.node_type === 2 || val.node_type === 3"
                  :class="{
                    gray: val.status === 1,
                    blue: val.status === 2,
                    yellow: val.status === 3,
                    red: val.status === 4,
                    green: val.status === 5,
                  }"
                >
                  <img
                    :src="
                      require('@/assets/images/icon-svg/icon-nostart-gray.svg')
                    "
                    alt=""
                    v-if="val.status === 1"
                  />
                  <img
                    :src="require('@/assets/images/icon-svg/icon-ing-blue.svg')"
                    alt=""
                    v-if="val.status === 2"
                  />
                  <img
                    :src="
                      require('@/assets/images/icon-svg/icon-wait-yellow.svg')
                    "
                    alt=""
                    v-if="val.status === 3"
                  />
                  <img
                    :src="require('@/assets/images/icon-svg/icon-no-red.svg')"
                    alt=""
                    v-if="val.status === 4"
                  />
                  <img
                    :src="require('@/assets/images/icon-cons-ok.png')"
                    alt=""
                    v-if="val.status === 5"
                  />
                  {{ val.status_name }}
                </label>
                <!-- 延期相关 -->
                <div class="delay-box" v-if="val.delay_num > 0">
                  <!-- <img
                    :src="require('@/assets/images/icon-svg/icon-cry-red.svg')"
                    alt=""
                  /> -->
                  <span>
                    已延期
                    <b>{{ val.delay_num }}</b>
                    天
                  </span>
                </div>
              </div>
              <div class="h1 line" v-if="val.return_visit_status_name">
                <!-- 0  ‘’;1  验收回访处理中;2  验收回访通过;3  验收回访不通过 -->
                <label
                  :class="{
                    blue: val.return_visit_status === 1,
                    green: val.return_visit_status === 2,
                    red: val.return_visit_status === 3,
                  }"
                >
                  <img
                    :src="require('@/assets/images/icon-svg/icon-ing-blue.svg')"
                    alt=""
                    v-if="val.return_visit_status === 1"
                  />
                  <img
                    :src="require('@/assets/images/icon-cons-ok.png')"
                    alt=""
                    v-if="val.return_visit_status === 2"
                  />
                  <img
                    :src="require('@/assets/images/icon-svg/icon-no-red.svg')"
                    alt=""
                    v-if="val.return_visit_status === 3"
                  />
                  {{ val.return_visit_status_name }}
                </label>
              </div>
            </div>
            <div
              class="font_size_13 flex_between align_center box_tip"
              v-if="val.node_order_show == 1"
            >
              <svg aria-hidden="true" class="icon_svg">
                <use xlink:href="#icon-information-fill"></use>
              </svg>
              <span
                class="flex1"
                v-if="val.node_type != 3 && val.node_type != 4"
                >当前节点有未下单产品，待下单完成&订单专员确认后，才可标记完成！
                <span class="blue_color" @click="dealOrder(val)"
                  >去下单 ></span
                ></span
              >
              <span class="flex1" v-if="val.node_type == 4"
                >当前节点有未下单产品，待下单完成&订单专员确认后，才可结束收款！
                <span class="blue_color" @click="dealOrder(val)"
                  >去下单 ></span
                ></span
              >
              <span
                class="flex1"
                v-if="
                  val.node_type == 3 &&
                  (!val.witness_count || val.witness_count == 0)
                "
                >当前节点有未下单产品，待下单完成&订单专员确认后，才可通知验收！
                <span class="blue_color" @click="dealOrder(val)"
                  >去下单 ></span
                ></span
              >
              <span
                class="flex1"
                v-if="
                  val.node_type == 3 &&
                  val.witness_count &&
                  val.witness_count > 0
                "
                >当前节点有未下单产品，待下单完成&订单专员确认&见证件完成，节点将自动完成！
                <span class="blue_color" @click="dealOrder(val)"
                  >去下单 ></span
                ></span
              >
            </div>
            <!-- V1.5.0动态见证件：进场产品验收记录单 -->
            <div
              class="font_size_13 flex_between align_center box_tip"
              v-if="val.node_order_receipt_show == 1"
            >
              <svg aria-hidden="true" class="icon_svg">
                <use xlink:href="#icon-information-fill"></use>
              </svg>
              <span
                class="flex1"
                v-if="val.node_type != 3 && val.node_type != 4"
                >当前节点有未现场收货验收的产品，待验收完成&客户确认，才可标记完成！
                <span class="blue_color" @click="dealSiteCheck(val)"
                  >去收货 ></span
                ></span
              >
              <span class="flex1" v-if="val.node_type == 4"
                >当前节点有未现场收货验收的产品，待验收完成&客户确认，才可结束收款！
                <span class="blue_color" @click="dealSiteCheck(val)"
                  >去收货 ></span
                ></span
              >
              <span
                class="flex1"
                v-if="
                  val.node_type == 3 &&
                  (!val.witness_count || val.witness_count == 0)
                "
                >当前节点有未现场收货验收的产品，待验收完成&客户确认，才可通知验收！
                <span class="blue_color" @click="dealSiteCheck(val)"
                  >去收货 ></span
                ></span
              >
              <span
                class="flex1"
                v-if="
                  val.node_type == 3 &&
                  val.witness_count &&
                  val.witness_count > 0
                "
                >当前节点有未现场收货验收的产品，待全部验收完成&客户确认&见证件完成，节点将自动完成！
                <span class="blue_color" @click="dealSiteCheck(val)"
                  >去收货 ></span
                ></span
              >
            </div>
            <!-- 头信息 -->
            <div class="do-box">
              <p class="title">{{ val.node_name_id_name }}</p>
              <p class="tag">
                {{ val.node_type_name }}/工期{{ val.schedule }}天
              </p>

              <ul class="ul-flex" v-if="getNodeType(val) !== 1">
                <li class="time-item">
                  <p class="text">实际开始时间</p>
                  <p class="n">{{ val.start_time || '-' }}</p>
                </li>
                <li class="time-item">
                  <p class="text">实际结束时间</p>
                  <p class="n">{{ val.end_time || '-' }}</p>
                </li>

                <li class="time-item">
                  <p class="text">必须完成时间</p>
                  <p class="n">{{ val.original_end_time || '-' }}</p>
                </li>
              </ul>
              <ul class="ul-flex" v-else>
                <li class="time-item">
                  <p class="text">必须完成时间</p>
                  <p class="n">{{ val.original_end_time || '-' }}</p>
                </li>
              </ul>
            </div>

            <!-- 详细信息 -->
            <div class="detail-box">
              <!-- <div class="detail-box" :class="{ white: getNodeType(val) === 1 }"> -->
              <div class="top">
                <p>
                  <label>节点详情</label>
                </p>
                <div class="up-down">
                  <Icon
                    name="play"
                    :class="{ up: val.up_down }"
                    @click="handleArrow(val)"
                  />
                </div>
              </div>
              <ol class="content" v-if="!!val.up_down">
                <li v-if="val.modify_scheduler > 0">
                  <label>工期变更：</label>
                  <span>延长{{ val.modify_scheduler }}天</span>
                </li>
                <li>
                  <label>计划时间：</label>
                  <span>
                    {{ val.plan_start_time + ' - ' + val.plan_end_time }}
                  </span>
                </li>
                <!-- <li v-if="getNodeType(val) !== 3">
                  <label>应结束时间：</label>
                  <span>{{ val.plan_end_time || '-' }}</span>
                </li> -->
                <li>
                  <label>开始条件：</label>
                  <span>
                    <p v-for="(startItem, ii) in val.list" :key="ii">
                      {{
                        startItem.sort_id_name
                          ? startItem.sort_id_name +
                            '，结束后' +
                            startItem.schedule +
                            '天开始'
                          : '-'
                      }}
                    </p>
                  </span>
                </li>
                <li>
                  <label>结束条件：</label>
                  <span
                    v-if="val.end_condition && val.end_condition.length > 0"
                  >
                    <p v-for="(startItem, ii) in val.end_condition" :key="ii">
                      {{
                        startItem.node_name +
                        '，结束' +
                        startItem.day +
                        '天后才可操作完成；'
                      }}
                    </p>
                  </span>
                  <span v-else>-</span>
                </li>
                <li>
                  <label>工种：</label>
                  <span>
                    {{ val.work_type_name || '-' }}
                  </span>
                </li>
                <li>
                  <label>业主自办事项：</label>
                  <span>
                    {{ val.customer_own_matter || '-' }}
                  </span>
                </li>
                <li>
                  <label>消息提醒人：</label>
                  <span>
                    {{
                      val.msg_to_uid?.length > 0
                        ? val.msg_to_uid_name.join('，')
                        : '-'
                    }}
                  </span>
                </li>
                <li>
                  <label>提前几天提醒：</label>
                  <span>
                    {{
                      val.advance_days?.length > 0
                        ? val.advance_days.join('、') + '天'
                        : '-'
                    }}
                  </span>
                </li>
                <li v-if="val.change_confirm == 0 || val.change_confirm == 1">
                  <label>工程&产品变更确认：</label>
                  <span v-if="val.change_confirm == 0"> 禁用 </span>
                  <span v-if="val.change_confirm == 1">
                    启用({{ val.change_confirm_status_name }})
                  </span>
                </li>
              </ol>
            </div>
            <!-- 操作人相关 -->
            <div class="person-box">
              <div class="person-item">
                <Image
                  class="img-head"
                  fit="cover"
                  round
                  :src="
                    val.f_face_image
                      ? val.f_face_image.includes('http')
                        ? val.f_face_image
                        : $host + val.f_face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                ></Image>
                <div class="flex">
                  <div class="name">{{ val.f_uid_name }}</div>
                  <div class="desc">
                    {{ getStr('/', val.f_depart_name, val.f_post_id_name) }}
                  </div>
                </div>
                <!-- 验收节点显示 -->
                <div class="tag" v-if="val.node_type == 3">操作人</div>
              </div>
              <!-- 验收节点显示验收人 -->
              <div class="line" v-if="val.node_type == 3"></div>
              <div class="person-item" v-if="val.node_type == 3">
                <Image
                  class="img-head"
                  fit="cover"
                  round
                  :src="
                    val.c_face_image && val.c_uid !== -1
                      ? val.c_face_image.includes('http')
                        ? val.c_face_image
                        : $host + val.c_face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                ></Image>
                <div class="flex">
                  <div class="name">{{ val.c_uid_name }}</div>
                  <div class="desc">
                    {{ getStr('/', val.c_depart_name, val.c_post_id_name) }}
                  </div>
                </div>
                <!-- 验收节点显示 -->
                <div class="tag">验收人</div>
              </div>
            </div>
            <!-- 操作按钮 -->
            <div class="option-box">
              <Button
                @click="btnNodeOpt(getNodeOpt(val).optId, val)"
                type="primary"
                size="normal"
                class="button-flex"
                v-if="getNodeOpt(val)"
              >
                <span>{{ getNodeOpt(val).name }}</span>
              </Button>
              <Button
                @click="btnNodeOpt('结束收款', val)"
                size="normal"
                class="button-flex"
                v-if="val.node_type == 4 && val.status == 2 && getNodeOpt(val)"
              >
                <span>结束收款</span>
              </Button>
              <!-- v1.3.4 见证件 -->
              <Button
                class="witness_btn"
                v-if="val.witness_count && val.witness_count > 0"
                @click="btnNodeOpt('见证件', val)"
              >
                见证件
                <span class="witness_num">{{ val.witness_count }}</span>
                <div
                  class="witness_status"
                  :class="'status' + val.witness_status"
                >
                  {{ val.witness_status_name }}
                </div>
              </Button>
              <!-- v1.3.1 更多 位置变更 功能不变 -->
              <Button
                class="more"
                v-if="isShowMore(val)"
                @click="showMore(val)"
              >
                更多
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!-- 施工计划列表 end-->
    </div>
    <!-- 空状态 -->
    <Empty
      class="empty-grey"
      description="暂无数据"
      v-if="!_DATA.loading && _DATA.infoList.length === 0"
    />
    <!-- end -->
  </div>
  <!-- 查看施工成员列表 -->
  <TempSiteMember
    ref="tempSiteMember"
    :data="_DATA.siteMember"
    :isShow="_DATA.siteMember.isShow"
    @closePop="
      () => {
        _DATA.siteMember.isShow = false;
      }
    "
  ></TempSiteMember>
  <!-- 查看列表 -->
  <NodeRecord
    ref="nodeRecord"
    :data="_DATA.record"
    :isShow="_DATA.showRecord"
    @closePop="
      () => {
        _DATA.showRecord = false;
      }
    "
  ></NodeRecord>
  <!-- 全部附件 -->
  <FileAll
    ref="fileAll"
    :data="_DATA.files"
    :isShow="_DATA.showAllFile"
    @closePop="
      () => {
        _DATA.showAllFile = false;
      }
    "
  ></FileAll>
  <!-- 上传附件弹窗 -->
  <FileUploadDialog
    ref="fileAll"
    :isShow="_DATA.showUploadFile"
    :type="_DATA.fileType"
    @update:func="(list) => getFileList(list)"
    @closePop="
      () => {
        _DATA.showUploadFile = false;
      }
    "
  ></FileUploadDialog>
  <!-- 动作面板 - 更多 -->
  <ActionSheet
    v-model:show="_DATA.showMoreAction"
    cancel-text="取消"
    close-on-click-action
    @cancel="
      () => {
        _DATA.showMoreAction = false;
      }
    "
  >
    <div class="van-action-sheet__content">
      <div
        @click="btnEndMoney(item.name)"
        v-for="item in _DATA.activeInfo.more_list"
        :key="item.name"
      >
        <Button class="van-action-sheet__item" type="button">
          <span class="van-action-sheet__name">{{ item.name }}</span>
        </Button>
      </div>
    </div>
  </ActionSheet>
  <!-- 见证件列表 -->
  <ActionSheet
    v-model:show="_DATA.showWitnessAction"
    cancel-text="取消"
    close-on-click-action
    :round="false"
    @cancel="
      () => {
        _DATA.showWitnessAction = false;
      }
    "
    class="witness_list_box"
  >
    <ul class="content">
      <li
        v-for="(item, index) in _DATA.witness_list"
        :key="item.id"
        @click="changeWitness(item)"
        :class="{ noborder: index + 1 === _DATA.witness_list.length }"
      >
        <div class="left">{{ item.name }}</div>
        <span class="right" :class="'status' + item.status">
          {{
            item.status == 1 ? '未开始' : item.status == 2 ? '进行中' : '已确认'
          }}
        </span>
      </li>
    </ul>
  </ActionSheet>
  <!-- end -->
  <!-- 款期popup -->
  <Popup
    v-model:show="_DATA.cardShow"
    round
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '70%' }"
  >
    <div class="paymentList" v-if="_DATA.cardShow">
      <h1 class="clearfix">
        <span class="c1 pull-left">款期</span>
        <span
          class="c2 pull-right"
          @click="
            () => {
              _DATA.cardShow = false;
            }
          "
        >
          取消
        </span>
      </h1>
      <div
        class="same-box"
        v-for="(item, index) in _DATA.paymentList"
        :key="index"
      >
        <!-- 状态栏 -->
        <p class="h1">
          <label
            :class="{
              cancel: item.payment_status_str === '未收款',
              done: item.payment_status_str === '已收款',
            }"
          >
            <Icon name="clock-o" v-if="item.payment_status_str === '未收款'" />
            <Icon name="success" v-if="item.payment_status_str === '已收款'" />
            {{ item.payment_status_str }}
          </label>
          <!-- <span
            class="refund-money"
            v-if="item.payment_flag > 1"
            @click="handleClick('退款', item)"
          >
            退款
          </span> -->
        </p>

        <div class="do-box">
          <p class="title">{{ item.payment_period }}</p>
          <ul class="ul-flex">
            <li>
              <p class="text">收款比例</p>
              <p class="n font_bold">{{ item.proportion }}%</p>
            </li>
            <li v-if="item.period_type == 1">
              <p class="text">款期金额</p>
              <p class="n font_bold">{{ item.receivable_money }}</p>
            </li>
            <li
              v-if="
                !(
                  item.contract_type == 51 ||
                  item.contract_type == 52 ||
                  item.contract_type == 55 ||
                  (item.contract_type == 56 && item.charge_setting_id == 0)
                )
              "
            >
              <p class="text">增减项金额</p>

              <p class="n" v-if="item.is_contain == 1">
                <router-link
                  class="link_btn font_bold"
                  :to="
                    '/adjustList?id=' +
                    _DATA.info.customer_id +
                    '&cid=' +
                    _DATA.info.contract_id +
                    '&pid=' +
                    item.payment_id
                  "
                >
                  ¥{{ item.adjust_money }}>
                </router-link>
              </p>
              <p class="n font_bold" v-else>¥{{ item.adjust_money }}</p>
            </li>
          </ul>
        </div>

        <div class="do-box margin20">
          <ol class="ol-flex">
            <li>
              <label>款项说明:</label>
              <span>{{ item.payment_description || '-' }}</span>
            </li>
            <li>
              <label>最新收款时间:</label>
              <span>{{ item.collection_time || '-' }}</span>
            </li>
          </ol>
        </div>

        <div class="total-box" v-if="item.payment_status_str === '已收款'">
          <p>
            <label>已收金额</label>
            <em>
              <i>¥</i>
              {{ item.received_money }}
            </em>
          </p>
          <p
            class="grey_color font_size_13"
            v-if="
              !(
                item.period_type == 2 ||
                item.contract_type == 51 ||
                item.contract_type == 52 ||
                item.contract_type == 55 ||
                (item.contract_type == 56 && item.charge_setting_id == 0)
              )
            "
          >
            <label>应收金额</label>
            <span>
              <i>¥</i>
              {{ item.receivable_amount }}
            </span>
          </p>
        </div>
        <div class="total-box" v-if="item.payment_status_str === '未收款'">
          <p>
            <label>剩余应收</label>
            <em>
              <i>¥</i>
              {{ item.balance_money }}
            </em>
          </p>
          <p class="grey_color font_size_13">
            <label>已收金额</label>
            <span>
              <i>¥</i>
              {{ item.received_money }}
            </span>
          </p>
          <p
            class="grey_color font_size_13 mt_5"
            v-if="
              !(
                item.period_type == 2 ||
                item.contract_type == 51 ||
                item.contract_type == 52 ||
                item.contract_type == 55 ||
                (item.contract_type == 56 && item.charge_setting_id == 0)
              )
            "
          >
            <label>应收金额</label>
            <span>
              <i>¥</i>
              {{ item.receivable_amount }}
            </span>
          </p>
          <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
          <ul class="wait-box orange_color mt_5">
            <li v-if="item.income_amount_str">
              <label>待审核收款</label>
              <span>¥ {{ item.income_amount_str }}</span>
            </li>
            <li v-if="item.expenditure_amount_str">
              <label>待审核退款</label>
              <span>¥ {{ item.expenditure_amount_str }}</span>
            </li>
          </ul>
        </div>

        <!-- 操作按钮 -->

        <div class="option-box" v-if="item.payment_status_str === '未收款'">
          <Row>
            <Col span="12">
              <Button
                type="primary"
                size="large"
                class="button-flex"
                @click="btnNodeOpt('去收款', item)"
              >
                <span>去收款</span>
                <em>
                  <Icon name="arrow" />
                </em>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </Popup>
  <!-- 工程&产品变更确认 -->
  <changeConfirm
    ref="changeConfirmRef"
    :data="_DATA.changeConfirm"
    :isShow="_DATA.changeConfirm.isShow"
    @closePop="
      () => {
        _DATA.changeConfirm.isShow = false;
      }
    "
    @update:func="
      () => {
        _DATA.changeConfirm.isShow = false;
        getList();
      }
    "
  ></changeConfirm>
</template>
<script setup>
import FileAll from '@/components/common/FileAll';
import FileUploadDialog from '@/components/common/FileUploadDialog';
import TempSiteMember from '@/components/construction/TempSiteMember';
import { inject, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  Button,
  Loading,
  Overlay,
  Icon,
  Image,
  Empty,
  ActionSheet,
  Dialog,
  Notify,
  Sticky,
  Tabs,
  Tab,
  Popup,
  Row,
  Col,
} from 'vant';
import _ from 'lodash';
import NodeRecord from '@/components/design/nodeRecord.vue';
import changeConfirm from '@/components/construction/changeConfirm.vue';
const localStorage = window.localStorage;
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const _DATA = reactive({
  construction_id: router.currentRoute.value.query.gdid, //工地id
  info: {},
  tabIndex: router.currentRoute.value.query.tabIndex
    ? Number(router.currentRoute.value.query.tabIndex)
    : null,
  infoList: [],
  allInfoList: [],
  node_status: '',
  origin_schedule: '',
  finish_schedule: '',
  loading: false,
  showAllFile: false, //是否显示附件列表
  showUploadFile: false,
  showMoreAction: false,
  activeInfo: {},
  files: '', //查看附件 公共参数
  fileType: 1,
  offsetTop: '12.3vw',
  showWitnessAction: false,
  witness_node_id: '',
  witness_list: [], //见证件列表
  cardShow: false,
  paymentList: [],
  payment_node_id: '',
  changeConfirm: { isShow: false },
  //-查看施工成员
  siteMember: { isShow: false },
});
const changeConfirmRef = ref(null);
onMounted(() => {
  //工地详情
  loadList();
});

//工地详情
const loadList = () => {
  _DATA.loading = true;
  $http.constructionInfo({ id: _DATA.construction_id }).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data;
      getList();
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//获取施工计划
const getList = () => {
  _DATA.loading = true;
  $http
    .getConstructionNodeList({ construction_id: _DATA.construction_id })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        //节点列表数据
        _DATA.allInfoList = data.list;
        if (_DATA.tabIndex == null) {
          _DATA.tabIndex =
            _DATA.info.life_cycle_status == 0
              ? '1'
              : _DATA.info.life_cycle_status;
          changeRouter(_DATA.tabIndex);
        }
        getInfoList();
        //滚动条
        changeScroll();
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .then(() => {});
};

//点击工地详情
const lookDetail = () => {
  router.push({
    path: '/constructionDetail',
    query: {
      gdid: router.currentRoute.value.query.gdid,
    },
  });
};
//点击上传附件
const goUploadFile = () => {
  _DATA.showUploadFile = true;
};
//点击查看全部附件
const lookFile = (files) => {
  _DATA.files = files;
  _DATA.showAllFile = true;
};
// 动态修改路由参数
const changeRouter = (val) => {
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.tabIndex = val;
  router.replace({ query });
};
//-@切换tab
const changeTab = (val) => {
  // 动态修改路由参数
  changeRouter(val);

  _DATA.tabIndex = val;
  _DATA.infoList = [];
  getInfoList();
  document.documentElement.scrollTop = 0;
};

//获取节点tab图标
const getTabIcon = (val) => {
  let iconName = '';
  if (val.type_sort === _DATA.tabIndex) {
    return require('@/assets/images/icon-cons-active.png');
  }
  switch (val.node_status) {
    case 0:
      // 未开始
      iconName = '-no'; //-
      break;
    case 1:
      // 进行中
      iconName = '-ing'; //->
      break;
    case 2:
      // 已完成
      iconName = '-ok'; //钩
      break;
    default:
      break;
  }
  return require('@/assets/images/icon-cons' + iconName + '.png');
};

//节点列表数据
const getInfoList = () => {
  let infoList = [];
  let curIndex = _DATA.tabIndex || _DATA.info.life_cycle_status;
  for (let i in _DATA.allInfoList) {
    const item = _DATA.allInfoList[i];
    //当前节点
    if (curIndex == item.type_sort) {
      infoList = item.list;
      _DATA.finish_schedule = item.finish_schedule;
      _DATA.origin_schedule = item.origin_schedule;
      _DATA.node_status = item.node_status;
    }
  }
  _DATA.infoList = infoList;
};

//获取节点状态 //1 未开始 2进行中 3已完成 4不通过
const getNodeType = (val) => {
  let type = 1;
  // 节点类型node_type：2 提审  3 验收
  if (val.node_type == 2 || val.node_type == 3) {
    if (val.status == 2 || val.status == 3) {
      type = 2;
    } else if (val.status == 5) {
      type = 3;
    } else if (val.status == 4) {
      type = 4;
    }
  } else {
    type = val.status;
  }
  return type;
};

/**
节点类别操作：
工序/测量/下单/安装/送货：标记完成（进行中）
验收：通知验收（待验收，验收不通过）、确认验收（验收中）
收款：添加收款（代收款）、结束收款（代收款）
 */
const getNodeOpt = (val) => {
  // 节点类型node_type： 1工序 2提审 3验收 4收款 5下单 6测量 7安装 8送货
  const opt = [
    { optId: 1, name: '标记完成', status: [2], type: [1, 5, 6, 7, 8] },
    { optId: 2, name: '通知验收', status: [2, 4], type: [3] },
    { optId: 3, name: '确认验收', status: [3], type: [3] },
    { optId: 4, name: '添加收款', status: [2], type: [4] },
    { optId: 5, name: '提前开始', status: [1], type: [1, 2, 3, 4, 5, 6, 7, 8] },
  ];
  let result = null;
  result = opt.find((v) => {
    return v.type.includes(val.node_type) && v.status.includes(val.status);
  });
  //登录人为处理人  确认验收操作为验收人才有权限 is_start代表是否可以提前开始 0 不可以 1可以
  let ACCOUNT_ID = localStorage.getItem('ACCOUNT_ID');
  if (result) {
    if (result.optId == 3) {
      if (ACCOUNT_ID != val.c_uid) {
        result = null;
      }
    } else if (result.optId == 5) {
      if (ACCOUNT_ID != val.f_uid || val.is_start == 0) {
        result = null;
      }
    } else {
      if (ACCOUNT_ID != val.f_uid) {
        result = null;
      }
    }
  }
  return result;
};

//操作
const btnNodeOpt = (v, val) => {
  switch (v) {
    case 1:
      setScroll();
      //标记完成
      router.push({
        path: '/construc/over',
        query: {
          id: _DATA.info.customer_id,
          nid: val.node_id, //节点id
          tabIndex: _DATA.tabIndex,
        },
      });
      break;
    case 2:
      setScroll();
      //通知验收
      router.push({
        path: '/construc/notice',
        query: {
          id: _DATA.info.customer_id,
          cname: val.c_uid_name,
          nid: val.node_id, //节点id
          nname: val.node_name_id_name, //节点名称名
          tabIndex: _DATA.tabIndex,
        },
      });
      break;
    case 3:
      setScroll();
      //确认验收
      router.push({
        path: '/construc/sure',
        query: {
          id: _DATA.info.customer_id,
          cname: val.c_uid_name,
          nid: val.node_id, //节点id
          nname: val.node_name_id_name, //节点名称名
          tabIndex: _DATA.tabIndex,
        },
      });
      break;
    case 4:
      //添加收款
      //款期列表
      _DATA.loading = true;
      _DATA.showMoreAction = false;
      $http
        .getContractPaymentListApi({
          node_id: val.node_id,
          page: 1,
          pages: 999,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            _DATA.loading = false;
            if (data.list.length == 1) {
              setScroll();
              router.push({
                path: '/Ccollection',
                query: {
                  id: _DATA.info.customer_id,
                  cid: _DATA.info.contract_id, //合同id
                  pid: val.payment_id, //款期id
                  gdid: val.node_id, //节点id
                  tabIndex: _DATA.tabIndex,
                },
              });
            } else {
              _DATA.paymentList = data.list;
              _DATA.payment_node_id = val.node_id;
              _DATA.cardShow = true;
            }
          } else {
            _DATA.loading = false;
            Notify({
              type: 'warning',
              message: data,
            });
          }
        });

      break;
    case '去收款':
      setScroll();
      router.push({
        path: '/Ccollection',
        query: {
          id: _DATA.info.customer_id,
          cid: _DATA.info.contract_id, //合同id
          pid: val.payment_id, //款期id

          gdid: _DATA.payment_node_id, //节点id
          tabIndex: _DATA.tabIndex,
        },
      });

      break;
    case 5:
      // 提前开始
      Dialog.confirm({
        message: '确定要提前开始吗？',
      }).then(() => {
        _DATA.loading = true;
        _DATA.showMoreAction = false;
        $http
          .constructionStrartNowInfo({ node_id: val.node_id })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功！',
                duration: 1000,
                onClose: () => {
                  _DATA.loading = false;
                  getList();
                },
              });
            } else {
              _DATA.loading = false;
              Notify({
                type: 'warning',
                message: data,
                duration: 2000,
              });
            }
          });
      });
      break;
    case '结束收款':
      Dialog.confirm({
        message: '确定要结束收款吗？',
      }).then(() => {
        _DATA.loading = true;
        _DATA.showMoreAction = false;
        $http.getConTagOverApi({ node_id: val.node_id }).then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                _DATA.loading = false;
                getList();
              },
            });
          } else {
            _DATA.loading = false;
            Notify({
              type: 'warning',
              message: data,
              duration: 2000,
            });
          }
        });
      });
      break;
    case '见证件':
      {
        //单个见证件直接跳转
        let arr = val.witness_info;
        if (val.witness_count == 1) {
          setScroll();
          router.push({
            path: '/witnessViewConstruction',
            query: {
              witness_id: arr[0].id,
              node_id: val.node_id,
            },
          });
        } else {
          //多个见证件弹出列表
          _DATA.witness_node_id = val.node_id;
          _DATA.witness_list = val.witness_info;
          _DATA.showWitnessAction = true;
        }
      }
      break;

    default:
      break;
  }
};

// 是否有更多操作
const ellipsis_list = reactive({
  1: {
    list: [
      { name: '查看施工成员', status: [1, 2, 3] },
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3] },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3],
        check: 'guidance_chart_files',
      },
    ],
  },
  2: {
    list: [
      { name: '查看记录', status: [1, 2, 3, 4, 5] },
      { name: '上传附件', status: [1, 2, 3, 4, 5] },
      { name: '查看附件', status: [1, 2, 3, 4, 5], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3, 4, 5],
        check: 'guidance_chart_files',
      },
    ],
  },
  3: {
    list: [
      { name: '查看记录', status: [1, 2, 3, 4, 5] },
      { name: '上传附件', status: [1, 2, 3, 4, 5] },
      { name: '查看附件', status: [1, 2, 3, 4, 5], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3, 4, 5],
        check: 'guidance_chart_files',
      },
    ],
  },
  4: {
    list: [
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3] },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3],
        check: 'guidance_chart_files',
      },
      // { name: '结束收款', status: [2], check: 'f_uid' },
    ],
  },
  5: {
    list: [
      { name: '查看施工成员', status: [1, 2, 3] },
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3] },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3],
        check: 'guidance_chart_files',
      },
    ],
  },
  6: {
    list: [
      { name: '查看施工成员', status: [1, 2, 3] },
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3] },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3],
        check: 'guidance_chart_files',
      },
    ],
  },
  7: {
    list: [
      { name: '查看施工成员', status: [1, 2, 3] },
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3] },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3],
        check: 'guidance_chart_files',
      },
    ],
  },
  8: {
    list: [
      { name: '查看施工成员', status: [1, 2, 3] },
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3] },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      {
        name: '查看作业指导图',
        status: [1, 2, 3],
        check: 'guidance_chart_files',
      },
    ],
  },
});
const ACCOUNT_ID = localStorage.getItem('ACCOUNT_ID');
const isShowMore = (val) => {
  let { status, f_uid, files, guidance_chart_files, work_type, work_members } =
    val;
  const list = ellipsis_list[val.node_type].list.filter((item) => {
    if (item.check && item.check === 'f_uid') {
      return item.status.includes(status) && ACCOUNT_ID == f_uid;
    } else if (item.check && item.check === 'files') {
      return files;
    } else if (item.check && item.check === 'guidance_chart_files') {
      return guidance_chart_files;
    } else {
      // 特殊处理：施工成员入口展示条件：1、有工种 2、status==3时必须判断work_members是否存在，解决接口无法判断是否是历史节点做的特殊处理
      if (item.name == '查看施工成员') {
        if (status == 3) {
          return (
            item.status.includes(status) &&
            work_type?.length &&
            work_members?.length
          );
        } else {
          return item.status.includes(status) && work_type?.length;
        }
      } else {
        return item.status.includes(status);
      }
    }
  });
  if (val.show_change_confirm == 1) {
    list.push({ name: '工程&产品变更确认' });
  }
  val.more_list = list;
  return list.length > 0 ? true : false;
};

// 点击更多操作
const showMore = (val) => {
  _DATA.showMoreAction = true;
  _DATA.activeInfo = val;
};

//操作-结束收款
const btnEndMoney = (type_name) => {
  switch (type_name) {
    case '查看记录':
      _DATA.showMoreAction = false;
      _DATA.showRecord = true;
      _DATA.record = { type: 2, node_id: _DATA.activeInfo.node_id };
      break;
    case '上传附件':
      _DATA.showMoreAction = false;
      goUploadFile();
      break;
    case '查看附件':
      _DATA.showMoreAction = false;
      _DATA.activeInfo.files = _DATA.activeInfo.files.length
        ? _DATA.activeInfo.files
        : [];
      lookFile(_DATA.activeInfo.files);
      break;
    case '查看作业指导图':
      _DATA.showMoreAction = false;
      _DATA.activeInfo.guidance_chart_files = _DATA.activeInfo
        .guidance_chart_files.length
        ? _DATA.activeInfo.guidance_chart_files
        : [];
      lookFile(_DATA.activeInfo.guidance_chart_files);
      break;
    case '工程&产品变更确认':
      _DATA.showMoreAction = false;
      _DATA.changeConfirm.isShow = true;
      _DATA.changeConfirm.node_id = _DATA.activeInfo.node_id;
      break;
    case '查看施工成员':
      _DATA.showMoreAction = false;
      _DATA.siteMember.isShow = true;
      _DATA.siteMember.node_id = _DATA.activeInfo.node_id;
      break;
  }
};
//切换见证件
const changeWitness = (val) => {
  setScroll();
  router.push({
    path: '/witnessViewConstruction',
    query: {
      witness_id: val.id,
      node_id: _DATA.witness_node_id,
    },
  });
};
//-@附件
const getFileList = (file_list) => {
  _DATA.showUploadFile = false;
  file_list?.length > 0 &&
    $http
      .constructionUploadFile({
        node_id: _DATA.activeInfo.node_id,
        files: file_list,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功！',
            duration: 1000,
            onClose: () => {
              _DATA.loading = false;
              getList();
            },
          });
        } else {
          _DATA.loading = false;
          Notify({
            type: 'warning',
            message: data,
            duration: 2000,
          });
        }
      });
};

//-@ 展开收起
const handleArrow = (item) => {
  item.up_down = !item.up_down;
};

// 提示信息
const getTipInfo = () => {
  let arr = [];
  if (
    _DATA.info?.customer_service_uid === 0 ||
    !_DATA.info?.customer_service_uid
  ) {
    arr.push('客服');
  }
  if (_DATA.info?.scene_leader_uid === 0 || !_DATA.info?.scene_leader_uid) {
    arr.push('施工团队');
  }
  if (_DATA.info?.project_uid === 0 || !_DATA.info?.project_uid) {
    arr.push('项目经理');
  }
  if (_DATA.info?.mains_uid === 0 || !_DATA.info?.mains_uid) {
    arr.push('水电工程师');
  }
  if (_DATA.info?.product_design_id === 0 || !_DATA.info?.product_design_id) {
    arr.push('产品设计师');
  }
  return arr;
};

//-@字符串拼接 smart添加
const getStr = (split_str, ...arr) => {
  const new_arr = arr.filter((item) => item !== '');
  return new_arr?.length > 0 ? new_arr.join(split_str) : '';
};
const changeScroll = () => {
  if (localStorage.getItem('scrollTop') > 0) {
    setTimeout(() => {
      let str = localStorage.getItem('scrollTop');
      document.documentElement.scrollTop = Number(str);
      localStorage.setItem('scrollTop', 0);
    }, 0);
  }
};
const setScroll = () => {
  localStorage.setItem('scrollTop', document.documentElement.scrollTop);
};
const dealOrder = (val) => {
  setScroll();
  router.push({
    path: '/productOrder',
    query: {
      id: _DATA.info.customer_id,
      gdid: _DATA.construction_id,
      node_id: val.node_id,
    },
  });
};
const dealSiteCheck = (val) => {
  setScroll();
  router.push({
    path: '/productSiteCheck',
    query: {
      node_id: val.node_id,
    },
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/construction/constructionPlan.less';
// 提示信息
.tip_box {
  width: 100%;
  background: #fff3b5;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  .text {
    color: #f37020;
    padding-left: 10px;
  }
}
.paymentList {
  width: 100%;
  padding: 0px 0px 20px;
  > h1 {
    width: 100%;
    padding: 0px 25px;
    margin: 40px 0 20px;
    > span.c1 {
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      color: #3e4345;
    }
    > span.c2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: @blue-color;
    }
  }
  .same-box {
    background: #ffffff;
    border: 1px solid rgba(228, 228, 228, 0.6);

    border-radius: 2px;
    box-shadow: 0px 4.3vw 4.3vw rgba(50, 50, 71, 0.08),
      0px 6.4vw 8.5vw rgba(50, 50, 71, 0.08);

    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px;
    .link_btn {
      color: @blue-color;
      text-decoration: underline;
    }
    .margin20 {
      margin: 0 20px;
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    p.h1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 16px;
      line-height: 14px;

      & > label {
        color: @orange-color;
        font-size: 12px;

        &.done {
          color: @green-color;
        }

        &.cancel {
          color: @red-color;
        }

        &.wait {
          color: @orange-color;
        }
      }

      & > span {
        color: #999;
        font-size: 14px;
      }

      .refund-money {
        color: #3d3d3d;
        font-size: 15px;
      }
    }

    div.do-box {
      padding: 15px 20px;
      border-top: 0.5px solid rgba(228, 228, 228, 0.6);

      p.title {
        line-height: 26px;
        font-size: 20px;
        font-weight: 700;
      }

      ul.ul-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 0;

        li {
          p.text {
            color: #bfbfbf;
            line-height: 14px;
            font-size: 13px;
          }

          p.n {
            margin-top: 4px;
            font-size: 17px;
            font-family: 'DIN Alternate';
            line-height: 20px;
          }
        }
      }

      ol.ol-flex {
        li {
          display: flex;
          justify-content: left;
          align-items: top;
          margin-bottom: 5px;

          label {
            flex-shrink: 0;
            flex-grow: 0;
            color: #999;
            font-size: 13px;
            padding-right: 4px;
          }

          span {
            font-size: 13px;
            word-break: break-all;
          }
        }
      }
    }

    div.option-box,
    div.total-box {
      border-top: 0.5px solid rgba(228, 228, 228, 0.6);
      padding: 15px 20px;
    }

    .total-box {
      p {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-size: 13px;
        }

        em {
          font-size: 24px;
          font-family: 'DIN Alternate';
          font-weight: bold;

          i {
            font-size: 16px;
            font-style: normal;
          }
        }
      }

      .wait-box {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;

          label {
            font-size: 13px;
          }

          span {
            font-family: SourceHanSansCN-Medium;
            font-size: 13px;
          }
        }
      }
    }
  }
}
.box_tip {
  background: rgba(243, 112, 32, 0.102);
  padding: 8px 16px;
  color: #f37020;
  .icon_svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
    margin-right: 6px;
  }
}
</style>
