/*
 * @Author: Jessica
 * @Date: 2022-05-25 10:40:19
 * @LastEditTime: 2023-10-12 09:53:49
 * @Description:
 * V1.3.7 新增 拨打电话
 * V1.4.3 去掉【预约见面】【确认见面】 修改跟进
 */

const moreList = {
  more: [
    {
      id: 8,
      name: '跟进',
      link: '/documentary',
      icon: 'icon20.png',
    },
    // {
    //   id: 9,
    //   name: '预约见面',
    //   link: '/appointment',
    //   icon: 'icon8.png',
    // },
    {
      id: 12,
      name: '申请设计派单',
      link: '/desginSend',
      icon: 'icon12.png',
    },
    {
      id: 15,
      name: '分配网销专员',
      link: '/netWorkerSend',
      icon: 'icon18.png',
    },
    {
      id: 17,
      name: '分配客户经理',
      link: '/managerSend',
      icon: 'icon19.png',
    },
    {
      id: 16,
      name: '分配设计师',
      link: '/desginerSend',
      icon: 'icon21.png',
    },
    {
      id: 13,
      name: '分配设计中心',
      link: '/desginCenterSend',
      icon: 'icon3.png',
    },
    {
      id: 10,
      name: '申请见工地',
      link: '/applyMeetSite',
      icon: 'icon5.png',
    },
    // {
    //   id: 29,
    //   name: '确认见面',
    //   link: '/confirmFace',
    //   icon: 'icon13.png',
    // },
    {
      id: 34,
      name: '死单激活',
      link: '',
      icon: 'icon4.png',
    },
  ],
  other: [
    {
      id: 480,
      name: '施工报价',
      link: '/desginQuotation',
      icon: 'icon53.png',
    },
    {
      id: 22,
      name: '申请死单',
      link: '/lost',
      icon: 'icon17.png',
    },
    {
      id: 23,
      name: '确认死单',
      link: '/lost',
      icon: 'icon16.png',
    },

    {
      id: 18,
      name: '暂停跟进',
      link: '/up',
      icon: 'icon23.png',
    },
    {
      id: 20,
      name: '继续跟进',
      link: '',
      icon: 'icon22.png',
    },
    {
      id: 25,
      name: '申请量房',
      link: '/measureHouse',
      icon: 'icon18.png',
    },
    {
      id: 26,
      name: '完成量房',
      link: '/measureHouse',
      icon: 'icon19.png',
    },
    {
      id: 62,
      name: '取消量房',
      link: '/measureHouse',
      icon: 'icon21.png',
    },
    {
      id: 10,
      name: '签约',
      link: '/contractIndex',
      icon: 'icon9.png',
    },
  ],
  change: [
    {
      id: 10,
      name: '创建合同增减项',
      link: '/contractChange',
      icon: 'icon1.png',
    },
  ],
  contract: [
    {
      id: 10,
      name: '合同收款',
      link: '/Ccollection',
      icon: 'icon-more0.png',
    },
    {
      id: 10,
      name: '合同外收款',
      link: '/CoutCollection',
      icon: 'icon-more1.png',
    },
    {
      id: 10,
      name: '合同退款',
      link: '/Creturn',
      icon: 'icon-more2.png',
    },
    {
      id: 10,
      name: '合同外退款',
      link: '/CoutReturn',
      icon: 'icon-more3.png',
    },
    {
      id: 10,
      name: '转款',
      link: '/Ctransfer',
      icon: 'icon-more4.png',
    },
    {
      id: 471,
      name: '产品预收款',
      link: '/prepayment',
      icon: 'icon50.png',
    },
  ],
  pull: [
    {
      id: 10,
      name: '分派效果设计师',
      link: '/effectDesignerSend',
      icon: 'icon2.png',
    },
    {
      id: 10,
      name: '分派产品设计师',
      link: 'productDesignerSend',
      icon: 'icon6.png',
    },
    {
      id: 10,
      name: '分派施工神话设计师',
      link: 'constructionDesignerSend',
      icon: 'icon14.png',
    },
  ],
  constuactList: [
    {
      id: 10,
      name: '分配项目经理',
      link: '/productManagerSend',
      icon: 'icon26.png',
    },
    { id: 10, name: '分配客服', link: '/servicerSend', icon: 'icon27.png' },
    {
      id: 10,
      name: '申请交付派单',
      link: '/deliverySend',
      icon: 'icon33.png',
    },
    {
      id: 10,
      name: '申请客户结算',
      link: '/applyCustomerSettlement',
      icon: 'icon29.png',
    },
  ],
  constuactMore: [
    {
      id: 10,
      name: '开工期变更单',
      link: '/periodChangeSheetForm',
      icon: 'icon30.png',
    },
    {
      id: 10,
      name: '开工地整改单',
      link: '/rectificationSheetForm',
      icon: 'icon37.png',
    },
    { id: 10, name: '开质保单', link: '/addWarranty', icon: 'icon38.png' },
  ],
  serviceMore: [
    {
      id: 10,
      name: '新增回访',
      link: '/construc/visitAdd',
      icon: 'icon34.png',
    },
    { id: 10, name: '新增投诉', link: '/complaintEdit', icon: 'icon32.png' },
    {
      id: 10,
      name: '新增售后&报修',
      link: '/editAfterSale',
      icon: 'icon31.png',
    },
  ],
};
//拨打电话
import { ref } from 'vue';
import { Toast } from 'vant';
const telRef = ref(null);
const telPhone = (phone) => {
  console.log(navigator.userAgent);
  let isMobile = /Mobile|Android|iPhone|Windows Phone/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    // 是移动设备
    telRef.value.href = `tel:` + phone;
    telRef.value.click();
  } else {
    Toast('请在手机上操作噢~');
  }
};
const convertCurrency = (money) => {
  //汉字的数字
  var cnNums = new Array(
    '零',
    '壹',
    '贰',
    '叁',
    '肆',
    '伍',
    '陆',
    '柒',
    '捌',
    '玖'
  );
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟');
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆');
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘');
  //整数金额时后面跟的字符
  var cnInteger = '整';
  //整型完以后的单位
  var cnIntLast = '元';
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = '';
  //分离金额后用的数组，预定义
  var parts;
  if (money == '') {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return '';
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf('-') == 0) {
    chineseStr += '负';
    money = money.substr(1);
  }
  if (money.indexOf('.') == -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length;
    for (var k = 0; k < decLen; k++) {
      var j = decimalNum.substr(k, 1);
      if (j != '0') {
        chineseStr += cnNums[Number(j)] + cnDecUnits[k];
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
};
export { moreList, telRef, telPhone, convertCurrency };
