<!--
 * @Author: gest
 * @Date: 2024-03-22 16:59:03
 * @LastEditTime: 2024-03-22 17:26:20
 * @Description: 附件竖着展示   
-->
<template>
  <div class="content">
    <div class="main">
      <div
        v-for="(item, index) in props.data"
        :key="index"
        @click="fileDetail(item, props.data)"
      >
        <div class="file-item">
          <div class="img-box">
            <Image
              v-if="isImg(item.type)"
              class="img-head"
              :src="$host + item.path"
              fit="cover"
              position="center"
            >
              <template v-slot:loading>
                <Loading type="spinner" size="20"></Loading>
              </template>
            </Image>
            <svg v-else class="icon-small" aria-hidden="true">
              <use :xlink:href="getIcon(item.type)"></use>
            </svg>
          </div>
          <div class="right">
            <label class="van-multi-ellipsis--l2">
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Popup v-model:show="_showData.showVideo" style="width: 80%">
    <div style="display: flex">
      <video
        style="width: 100%"
        controls
        autoplay
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5-page"
        preload="auto"
        :src="$host + _showData.info.path"
      ></video>
    </div>
  </Popup>
  <Popup v-model:show="_showData.showAudio" style="width: 80%">
    <div style="display: flex">
      <audio
        style="width: 100%"
        controls
        autoplay
        preload="auto"
        :src="$host + _showData.info.path"
      ></audio>
    </div>
  </Popup>
</template>
<script setup>
import { inject, defineProps } from 'vue';
import { Image, Popup, Loading } from 'vant';
//文件展示通用
import { isImg, getIcon, _showData, fileDetail } from '@/utils/fileUpload';

const $host = inject('$host');

const props = defineProps(['data']);
</script>
<style lang="less" scoped>
.content {
  .file-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
      flex: 1;
      label {
        font-weight: 400;
        font-size: 15px;
        color: @main_color;

        flex: 1;
        word-break: break-word;
      }
      .time {
        color: #aaaaaa;
        font-size: 13px;
      }
    }
  }
  .img-box {
    width: 46px;
    height: 46px;
    background: @grey_color;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .img-head {
      width: 100%;
      height: 100%;
    }
    .icon-small {
      width: 25px;
      height: 28px;
    }
  }
}
</style>
