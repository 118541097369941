<!--
 * @Author: gest
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-31 16:13:35
 * @Description:V1.4.0-全部数据-交付
-->
<template>
  <div v-if="props.data.pos">
    <IndexBar ref="indexBarRef" :index-list="_DATA.indexList" :sticky="false">
      <IndexAnchor index="1"></IndexAnchor>
      <deliveryPerformance
        :data="props.data"
        @loadEnd="loadEnd(1)"
        ref="deliveryPerformanceRef"
        @createDanger="createDanger"
      ></deliveryPerformance>
      <IndexAnchor index="2"></IndexAnchor>
      <delivery
        :data="props.data"
        @loadEnd="loadEnd(2)"
        ref="deliveryRef"
        @createDanger="createDanger"
      ></delivery>
      <IndexAnchor index="3"></IndexAnchor>
      <period :data="props.data" @loadEnd="loadEnd(3)"></period>
      <IndexAnchor index="4"></IndexAnchor>
      <underStateSite :data="props.data" @loadEnd="loadEnd(4)"></underStateSite>
      <IndexAnchor
        index="5"
        v-if="props.data?.now_year == props.data?.yearId"
      ></IndexAnchor>
      <actionSite
        :data="props.data"
        @loadEnd="loadEnd(5)"
        v-if="props.data?.now_year == props.data?.yearId"
      ></actionSite>
    </IndexBar>
  </div>
  <!-- 规则说明组件 -->
  <ActionSheet
    class="dangerActionSheet_rule"
    v-model:show="_DATA.dangerOperate"
    @open="onOpenDanger"
  >
    <div class="content">
      <div class="content_item">
        <h4>{{ _DATA.dangerTitle }}</h4>
        <div v-if="_DATA.dangerTitle == '交付产值_规则'" class="info_rule">
          <!-- 数据定义 -->
          <div>
            <h5>数据定义：</h5>
            <p>
              · 目标：{{
                _DATA.dangerHtml?.yearInfo
              }}交付中心设置至当前月的累计目标产值；
            </p>
          </div>
          <!-- 各交付中心产值 -->
          <div class="mb_10">
            <h5 class="flex_between">
              {{ _DATA.dangerHtml?.yearInfo }}目标（万）：
            </h5>
            <Row class="top_title">
              <Col
                class="align_center color_999"
                span="4"
                v-for="(item, index) in _DATA.dangerHtml?.title"
                :key="index"
              >
                {{ item.name }}
              </Col>
            </Row>
            <div class="top_content">
              <Row
                v-for="(item, index) in _DATA.dangerHtml?.content"
                :key="index"
              >
                <Col span="4" class="align_center color_999">{{
                  item.title
                }}</Col>
                <Col span="4" class="align_center">{{ item.value1 }}</Col>
                <Col span="4" class="align_center">{{ item.value2 }}</Col>
                <Col span="4" class="align_center">{{ item.value3 }}</Col>
                <Col span="4" class="align_center">{{ item.value4 }}</Col>
                <Col span="4" class="align_center">{{ item.value5 }}</Col>
              </Row>
            </div>
          </div>
        </div>
        <div v-else class="info_rule" v-html="_DATA.dangerHtml"></div>
      </div>
      <div class="content_button_blue">
        <Button
          block
          loading-type="spinner"
          @click="closeDanger"
          loading-text="知道了"
        >
          知道了
        </Button>
      </div>
    </div>
  </ActionSheet>
</template>
<script setup>
import { ref, reactive, defineProps, nextTick, defineExpose } from 'vue';
import { IndexBar, IndexAnchor, ActionSheet, Button, Row, Col } from 'vant';
//-交付产值
import deliveryPerformance from '@/components/targetData/siteData/deliveryPerformance';
//-交付数
import delivery from '@/components/targetData/siteData/delivery';
// 交付周期
import period from '@/components/targetData/siteData/period';
// 交付阶段数据
import underStateSite from '@/components/targetData/siteData/underStateSite';
// 交付动作数据
import actionSite from '@/components/targetData/siteData/actionSite';
const props = defineProps(['data']);
const _DATA = reactive({
  indexList: [1, 2, 3, 4, 5],
  //-规则说明
  dangerTitle: '',
  dangerHtml: '',
  dangerOperate: false,
});
const indexBarRef = ref(null);
let loadEndList = [];

const loadEnd = (index) => {
  let pos = Number(props.data.pos);
  if (pos == 1) {
    if (indexBarRef.value) {
      nextTick(() => {
        indexBarRef.value.scrollTo(pos);
      });
    }
  } else if (pos > index) {
    loadEndList.push(index);
    let needLoad = _DATA.indexList.filter((i) => i < pos);
    if (needLoad.sort().toString() == loadEndList.sort().toString()) {
      nextTick(() => {
        if (indexBarRef.value) {
          indexBarRef.value.scrollTo(pos);
        }
      });
    }
  }
};

//-规则说明
const deliveryPerformanceRef = ref();
const deliveryRef = ref();
// 使用nextTick确保DOM已更新 并且回到顶部
const onOpenDanger = () => {
  setTimeout(() => {
    const actionSheet = document.querySelector('.van-action-sheet__content');
    if (actionSheet) {
      actionSheet.scrollTop = 0;
    }
  }, 0);
};
const createDanger = (type) => {
  _DATA.dangerOperate = true;
  if (type == 'deliveryPerformanceRef') {
    _DATA.dangerTitle = deliveryPerformanceRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = deliveryPerformanceRef?.value?._rule.htmlContent;
  } else if (type == 'deliveryRef') {
    _DATA.dangerTitle = deliveryRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = deliveryRef?.value?._rule.htmlContent;
  }
};
const closeDanger = () => {
  _DATA.dangerOperate = false;
};
defineExpose({ createDanger });
//-规则说明 end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/targetData.less';
</style>
