<!--
 * @Author: Jessica
 * @Date:  2022-11-01 15:49:53
 * @LastEditTime: 2024-06-14 11:03:49
 * @Description: 侧边菜单
-->
<template>
  <Popup
    v-model:show="_DATA.showMenu"
    position="right"
    :style="{ width: '75%', height: '100%' }"
    @closed="closed"
  >
    <Skeleton title :row="16" :loading="_DATA.loading" style="padding: 20px">
      <div class="menu_box">
        <div class="head_box">
          <Image
            class="img_head"
            round
            :src="
              _DATA.info?.face_image
                ? _DATA.info.face_image.includes('http')
                  ? _DATA.info.face_image
                  : $host + _DATA.info?.face_image
                : require('@/assets/images/icon-empty-img.png')
            "
          >
            <template v-slot:loading>
              <Loading type="spinner" size="20"></Loading>
            </template>
          </Image>
          <div class="right">
            <div class="name">{{ _DATA.info.name }}</div>
            <div class="desc">{{ _DATA.info.post_name }}</div>
          </div>
        </div>
        <ul class="list_box">
          <li @click="handleClick('消息')">
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-mail-line"></use>
            </svg>

            <span class="name ml_10">消息</span>
            <Badge
              class="ml_10"
              v-if="_DATA.info.message_count > 0"
              :content="_DATA.info.message_count"
              max="99"
            />
          </li>
          <li @click="handleClick('审批')">
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-file-edit-line"></use>
            </svg>

            <span class="name ml_10">审批</span>
            <Badge
              class="ml_10"
              v-if="_DATA.info.task_count > 0"
              :content="_DATA.info.task_count"
              max="99"
            />
          </li>
          <li v-if="props.data.id" @click="handleClick('客户待办')">
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-history-fill"></use>
            </svg>

            <span class="name ml_10">客户待办</span>
            <Badge
              class="ml_10"
              v-if="_DATA.info.todo_count > 0"
              :content="_DATA.info.todo_count"
              max="99"
            />
          </li>
          <li v-else @click="handleClick('全部待办')">
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-history-fill"></use>
            </svg>

            <span class="name ml_10">全部待办</span>
            <Badge
              class="ml_10"
              v-if="_DATA.info.todo_count > 0"
              :content="_DATA.info.todo_count"
              max="99"
            />
          </li>
          <li
            v-if="
              allOperationAuthority.indexOf('238') > -1 ||
              allOperationAuthority.indexOf('246') > -1
            "
            @click="handleClick('线索列表')"
          >
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-route-line"></use>
            </svg>

            <span class="name ml_10">线索列表</span>
          </li>
          <li
            @click="handleClick('全部客户')"
            v-if="
              allOperationAuthority.indexOf('14') > -1 ||
              allOperationAuthority.indexOf('110') > -1
            "
          >
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-group-line"></use>
            </svg>

            <span class="name ml_10">全部客户</span>
          </li>
          <li
            v-if="allOperationAuthority.indexOf('148') > -1"
            @click="handleClick('工地列表')"
          >
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-hammer-line"></use>
            </svg>

            <span class="name ml_10">工地列表</span>
          </li>

          <li
            v-if="_DATA.authorityData.is_show == 1"
            @click="handleClick('数据看板')"
          >
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-scan-2-line"></use>
            </svg>
            <span class="name ml_10">数据看板</span>
          </li>
          <!-- <li
            v-if="allOperationAuthority.indexOf('539') > -1"
            @click="handleClick('财务大屏')"
          >
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-swap-box-line"></use>
            </svg>
            <span class="name ml_10">财务大屏</span>
          </li> -->
          <li @click="handleClick('业绩榜单')">
            <svg class="icon-small" aria-hidden="true">
              <use xlink:href="#icon-trophy-line"></use>
            </svg>
            <span class="name ml_10">业绩榜单</span>
          </li>
        </ul>
      </div>
    </Skeleton>
  </Popup>
</template>

<script setup>
import {
  inject,
  reactive,
  defineProps,
  watch,
  computed,
  defineEmits,
} from 'vue';
import { useStore } from 'vuex';
import { Popup, Image, Badge, Toast, Loading, Skeleton } from 'vant';
import { useRouter } from 'vue-router';
const props = defineProps(['data']);
const emit = defineEmits(['closed']);
const router = useRouter();
const $host = inject('$host');
const $http = inject('$http');
const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const _DATA = reactive({
  showMenu: false,
  loading: false,
  info: {},
  //--目标管理权限
  authorityData: {},
});
watch(
  () => props.data,
  (cur) => {
    _DATA.showMenu = cur.showMenu ? cur.showMenu : false;
    if (_DATA.showMenu) {
      loadTargetStatistic();
    }
  }
);
const onload = () => {
  $http
    .user_profile({
      customer_id: props.data.id ? props.data.id : '',
    })
    .then((res) => {
      _DATA.loading = false;
      if (res.code === 0) {
        _DATA.info = res.data;
      } else {
        Toast(res.msg || res.data);
      }
    });
};
// -- 目标管理权限
const loadTargetStatistic = () => {
  _DATA.loading = true;
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    onload();
    if (code === 0) {
      _DATA.authorityData = data.target_list;
    }
  });
};

//-@-数据看板跳转
const loadTargetStatisticLink = () => {
  localStorage.removeItem('targetYear');
  //入口判断
  if (_DATA.authorityData.show_level == 1) {
    router.push({ path: '/totalTargetData' });
  } else if (_DATA.authorityData.show_level == 2) {
    router.push({
      path: '/allTargetData',
      query: {
        start: 1,
      },
    });
  } else if (_DATA.authorityData.show_level == 3) {
    if (_DATA.authorityData.user_auth.indexOf(517) > -1) {
      router.push({
        path: '/depDesginData',
        query: {
          depId: _DATA.authorityData.dep?.id ?? 0,
          depName: _DATA.authorityData.dep?.short_name ?? '',
          pos: 1,
          start: 1,
        },
      });
    } else if (_DATA.authorityData.user_auth.indexOf(518) > -1) {
      router.push({
        path: '/depSiteData',
        query: {
          depId: _DATA.authorityData.dep?.id ?? 0,
          depName: _DATA.authorityData.dep?.short_name ?? '',
          pos: 1,
          start: 1,
        },
      });
    } else {
      router.push({
        path: '/allTargetData',
        query: {
          subtype: 1,
          pos: 1,
        },
      });
    }
  }
};
//-@操作
const handleClick = (type_name) => {
  _DATA.showMenu = false;
  emit('closed');
  switch (type_name) {
    case '消息':
      router.push({
        path: '/messageAll',
      });
      break;
    case '审批':
      router.push({
        path: '/taskIndex',
      });
      break;
    case '全部待办':
      router.push({
        path: '/taskAll',
      });
      break;

    case '客户待办':
      router.push({
        path: '/taskDay',
        query: {
          id: props.data.id,
        },
      });
      break;
    case '线索列表':
      router.push({
        path: '/clueList',
      });
      break;
    case '全部客户':
      router.push({
        path: '/listPage',
      });
      break;
    case '工地列表':
      router.push({
        path: '/constructionList',
      });
      break;
    case '数据看板':
      console.log('111111111111');
      loadTargetStatisticLink();
      break;
    case '财务大屏':
      router.push({
        path: '/financeData',
      });
      break;
    case '业绩榜单':
      router.push({
        path: '/performanceRanking',
      });
      break;
  }
};
const closed = () => {
  emit('closed');
};
</script>

<style lang="less" scoped>
:deep(.van-badge) {
  transform: none;
  background: #fa5151;
  padding: 0 6px;
  font-weight: bold;
  line-height: 18px;
}
.menu_box {
  padding: 20px;
  .head_box {
    display: flex;
    align-items: center;
    padding: 20px 0;
    .img_head {
      width: 54px;
      height: 54px;
    }
    .right {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .name {
        font-size: 17px;
        font-weight: bold;
        line-height: 24px;
      }
      .desc {
        font-size: 15px;
        color: #3d3d3d;
        line-height: 21px;
      }
    }
  }
  .list_box {
    li {
      padding: 20px 0;
      display: flex;
      align-items: center;
      color: #52575d;
      .name {
        font-size: 17px;
        line-height: 20px;
        color: #000000;
      }

      .icon-small {
        width: 20px;
        height: 20px;
        fill: currentColor;
      }
    }
  }
}
</style>
