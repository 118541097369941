<!--
 * @Author: Jessica
 * @Date: 2022-04-21 17:00:31
 * @LastEditTime: 2023-12-14 16:50:12
 * @Description:【意向单】--基装增减项
-->
<template>
  <!-- 基装增减项 -->
  <div class="temp" :class="{ view: state.optionType === 'view' }">
    <NavBar
      safe-area-inset-top
      :title="
        state.type == 'base'
          ? '基装增减项'
          : state.type == 'main'
          ? '主材增减项'
          : '其他增减项'
      "
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
      v-if="state.optionType === 'edit'"
    />
    <div class="nav">
      <h1 v-if="state.optionType === 'view'">
        <span class="c1">
          {{
            state.type == 'base'
              ? '基装增减项'
              : state.type == 'main'
              ? '主材增减项'
              : '其他增减项'
          }}
        </span>
        <span class="c2" @click="onClosePop">返回</span>
      </h1>
    </div>

    <div class="table" :class="{ table_view: state.optionType === 'view' }">
      <Button
        class="add_btn"
        type="default"
        @click="addFun()"
        v-if="state.optionType === 'edit'"
      >
        + 添加增减项
      </Button>
      <div class="cardList">
        <!-- 基装增减项样式 -->
        <div class="cardItem" v-if="state.type == 'base'">
          <div
            class="same-box"
            v-for="(item, index) in list.base_arr"
            :key="index"
          >
            <!-- 状态栏 -->
            <p class="h1">
              <label class="add" v-if="Math.sign(item.amount) == 1">
                <Icon name="plus" />
                基装增项
              </label>
              <label class="minus" v-else>
                <Icon name="minus" />
                基装减项
              </label>
              <span
                class="time"
                @click="getMoreFun(item, index, 'base')"
                v-if="state.optionType === 'edit'"
              >
                <Icon name="ellipsis" />
              </span>
            </p>

            <div class="do-box">
              <p class="title">
                {{ item.project_name }}
              </p>
              <ul class="ul-flex">
                <li>
                  <p class="text">所属空间</p>
                  <p class="n">{{ item.owner_space || '-' }}</p>
                </li>
                <li>
                  <p class="text">单价</p>
                  <p class="n">{{ '¥' + item.price + '/' + item.unit_name }}</p>
                </li>
                <li>
                  <p class="text">预估增减量</p>
                  <p class="n">{{ item.number }}</p>
                </li>
              </ul>
            </div>

            <div class="do-box margin20">
              <ol class="ol-flex">
                <li>
                  <label>备注:</label>
                  <span>{{ item.remark }}</span>
                </li>
              </ol>
            </div>

            <div class="total-box">
              <label>预估补差合计</label>
              <em>
                <i>¥</i>
                {{ item.amount }}
              </em>
            </div>
          </div>
        </div>
        <!-- 主材增减项样式 -->
        <div class="cardItem" v-if="state.type == 'main'">
          <div
            class="same-box"
            v-for="(item, index) in list.main_arr"
            :key="index"
          >
            <!-- 状态栏 -->
            <p class="h1">
              <label class="add" v-if="Math.sign(item.amount) == 1">
                <Icon name="plus" />
                主材增项
              </label>
              <label class="minus" v-else>
                <Icon name="minus" />
                主材减项
              </label>
              <span
                class="time"
                @click="getMoreFun(item, index, 'main')"
                v-if="state.optionType === 'edit'"
              >
                <Icon name="ellipsis" />
              </span>
            </p>

            <div class="do-box">
              <p class="title">
                {{ item.name }}
              </p>
              <ul class="ul-flex">
                <li>
                  <p class="text">所属空间</p>
                  <p class="n">{{ item.owner_space }}</p>
                </li>
                <li>
                  <p class="text">单价</p>
                  <p class="n">
                    {{ '¥' + item.price + '/' + item.unit_name }}
                  </p>
                </li>
                <li>
                  <p class="text">预估增减量</p>
                  <p class="n">{{ item.number }}</p>
                </li>
              </ul>
            </div>

            <div class="do-box margin20">
              <ol class="ol-flex">
                <li>
                  <label>备注:</label>
                  <span>{{ item.remark }}</span>
                </li>
              </ol>
            </div>

            <div class="total-box">
              <label>预估补差合计</label>
              <em>
                <i>¥</i>
                {{ item.amount }}
              </em>
            </div>
          </div>
        </div>
        <!-- 其他增减项样式 -->
        <div class="cardItem" v-if="state.type == 'other'">
          <div
            class="same-box"
            v-for="(item, index) in list.other_arr"
            :key="index"
          >
            <!-- 状态栏 -->
            <p class="h1">
              <label class="add" v-if="Math.sign(item.amount) == 1">
                <Icon name="plus" />
                其他增项
              </label>
              <label class="minus" v-else>
                <Icon name="minus" />
                其他减项
              </label>
              <span
                class="time"
                @click="getMoreFun(item, index, 'other')"
                v-if="state.optionType === 'edit'"
              >
                <Icon name="ellipsis" />
              </span>
            </p>

            <div class="do-box">
              <p class="title">{{ item.project_name }}</p>
              <ol class="ol-flex marginTop20">
                <li>
                  <label>备注:</label>
                  <span>{{ item.remark }}</span>
                </li>
              </ol>
            </div>

            <div class="total-box">
              <label>预估补差合计</label>
              <em>
                <i>¥</i>
                {{ item.amount }}
              </em>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer clearfix" v-if="state.optionType === 'edit'">
      <p class="pull-left title">
        <span class="c1">预估补差合计</span>
        <span :class="state.total >= 0 ? 'c2' : 'c3'">¥ {{ state.total }}</span>
      </p>
      <Button class="pull-right btn" type="primary" @click="sumbitFun">
        完成
      </Button>
    </div>
    <!-- 增减项编辑popup -->
    <Popup
      v-model:show="state.cardShow"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div class="edit_plus" v-if="state.cardShow">
        <h1 class="clearfix">
          <span class="c1 pull-left">添加增减项</span>
          <span
            class="c2 pull-right"
            @click="
              () => {
                state.cardShow = false;
                state.currentIndex = -1;
              }
            "
          >
            取消
          </span>
        </h1>
        <div>
          <!-- 基装编辑 -->
          <Form
            class="formBaseInset noBorder"
            ref="ruleBaseFormRef"
            v-if="state.type == 'base'"
          >
            <Field
              name="radio"
              :rules="[{ required: true, message: '请选择增减项' }]"
            >
              <template #input>
                <RadioGroup
                  v-model="baseForm.adjustType"
                  direction="horizontal"
                  class="radio_box"
                  @change="blurFun('base', baseForm)"
                >
                  <Radio
                    :name="1"
                    class="radio_btn"
                    :class="{
                      checked: baseForm.adjustType == 1,
                    }"
                    >增项</Radio
                  >
                  <Radio
                    :name="2"
                    class="radio_btn"
                    :class="{
                      checked: baseForm.adjustType == 2,
                    }"
                    >减项</Radio
                  >
                </RadioGroup></template
              >
            </Field>

            <CellGroup inset>
              <Field
                v-model="baseForm.no"
                label="定额编号"
                placeholder="填写定额编号"
                input-align="right"
                error-message-align="right"
                maxlength="30"
              />
              <Field
                v-model="baseForm.project_name"
                label="项目名称"
                placeholder="填写项目名称"
                input-align="right"
                error-message-align="right"
                maxlength="60"
                required
                :rules="[{ required: true, message: '请填写项目名称' }]"
              />
              <Field
                v-model="baseForm.owner_space"
                label="所属空间"
                placeholder="填写所属空间"
                input-align="right"
                error-message-align="right"
                maxlength="10"
              />
              <Field
                v-model="baseForm.price"
                type="number"
                label="单价"
                placeholder="0.00"
                input-align="right"
                error-message-align="right"
                name="validator"
                required
                :rules="[
                  {
                    required: true,
                    message: '请填写正确单价',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
                @blur="blurFun('base', baseForm)"
              />
              <Field
                v-model="baseForm.unit_name"
                right-icon="arrow-down"
                input-align="right"
                error-message-align="right"
                readonly
                label="单位"
                name="picker"
                required
                :rules="[
                  {
                    required: true,
                    message: '请选择单位',
                  },
                ]"
                placeholder="请选择单位"
                @click="state.showUnitPicker = true"
              />
              <Field
                v-model="baseForm.number"
                label="预估增减量"
                placeholder="填写增减量"
                input-align="right"
                error-message-align="right"
                @blur="blurFun('base', baseForm)"
                required
                :rules="[
                  {
                    required: true,
                    message: '请填写小于1000的整数',
                    validator: validatorNum,
                    trigger: 'onChange',
                  },
                ]"
              />
              <Field
                v-model="baseForm.remark"
                label="备注"
                placeholder="填写备注"
                input-align="right"
                error-message-align="right"
                maxlength="100"
              />
            </CellGroup>
            <div class="footer clearfix">
              <p class="pull-left title">
                <span class="c1">预估补差合计</span>
                <span :class="baseForm.amount >= 0 ? 'c2' : 'c3'">
                  ¥
                  {{ baseForm.amount ? baseForm.amount : 0 }}
                </span>
              </p>
              <Button
                class="pull-right btn"
                type="primary"
                @click="saveFun('base')"
              >
                确定
              </Button>
            </div>
          </Form>
          <!-- 主材编辑 -->
          <Form
            class="formBaseInset noBorder"
            ref="ruleMainFormRef"
            v-if="state.type == 'main'"
          >
            <Field
              name="radio"
              :rules="[{ required: true, message: '请选择增减项' }]"
            >
              <template #input>
                <RadioGroup
                  v-model="mainForm.adjustType"
                  direction="horizontal"
                  class="radio_box"
                  @change="blurFun('main', mainForm)"
                >
                  <Radio
                    :name="1"
                    class="radio_btn"
                    :class="{
                      checked: mainForm.adjustType == 1,
                    }"
                    >增项</Radio
                  >
                  <Radio
                    :name="2"
                    class="radio_btn"
                    :class="{
                      checked: mainForm.adjustType == 2,
                    }"
                    >减项</Radio
                  >
                </RadioGroup></template
              >
            </Field>

            <CellGroup inset>
              <Field
                v-model="mainForm.name"
                label="产品名称"
                placeholder="填写产品名称"
                input-align="right"
                error-message-align="right"
                maxlength="100"
                required
                :rules="[{ required: true, message: '请填写产品名称' }]"
              />
              <Field
                v-model="mainForm.brand"
                label="品牌"
                placeholder="填写品牌"
                input-align="right"
                error-message-align="right"
                maxlength="30"
              />
              <Field
                v-model="mainForm.model"
                label="型号"
                placeholder="填写型号"
                input-align="right"
                error-message-align="right"
                maxlength="30"
              />
              <Field
                v-model="mainForm.standard"
                label="规格"
                placeholder="填写规格"
                input-align="right"
                error-message-align="right"
                maxlength="100"
              />
              <Field
                v-model="mainForm.owner_space"
                label="所属空间"
                placeholder="填写所属空间"
                input-align="right"
                error-message-align="right"
                maxlength="100"
                required
                :rules="[{ required: true, message: '请填写所属空间' }]"
              />
              <Field
                v-model="mainForm.price"
                type="number"
                label="单价"
                placeholder="0.00"
                input-align="right"
                error-message-align="right"
                @blur="blurFun('main', mainForm)"
                required
                :rules="[
                  {
                    required: true,
                    message: '请填写正确单价',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
              />
              <Field
                v-model="mainForm.unit_name"
                right-icon="arrow-down"
                input-align="right"
                error-message-align="right"
                readonly
                label="单位"
                name="picker"
                required
                :rules="[
                  {
                    required: true,
                    message: '请选择单位',
                  },
                ]"
                placeholder="请选择单位"
                @click="state.showUnitPicker = true"
              />
              <Field
                v-model="mainForm.number"
                label="预估增减量"
                placeholder="填写增减量"
                input-align="right"
                error-message-align="right"
                @blur="blurFun('main', mainForm)"
                required
                :rules="[
                  {
                    required: true,
                    message: '请填写正确的增减量',
                    validator: validatorNum,
                    trigger: 'onChange',
                  },
                ]"
              />
              <Field
                v-model="mainForm.remark"
                label="备注"
                placeholder="填写备注"
                input-align="right"
                error-message-align="right"
                maxlength="100"
              />
            </CellGroup>
            <div class="footer clearfix">
              <p class="pull-left title">
                <span class="c1">预估补差合计</span>
                <span :class="mainForm.amount >= 0 ? 'c2' : 'c3'">
                  ¥
                  {{ mainForm.amount ? mainForm.amount : 0 }}
                </span>
              </p>
              <Button
                class="pull-right btn"
                type="primary"
                @click="saveFun('main')"
              >
                确定
              </Button>
            </div>
          </Form>
          <!-- 其他编辑 -->
          <Form
            class="formBaseInset noBorder"
            ref="ruleOtherFormRef"
            v-if="state.type == 'other'"
          >
            <Field
              name="radio"
              :rules="[{ required: true, message: '请选择增减项' }]"
            >
              <template #input>
                <RadioGroup
                  v-model="otherForm.adjustType"
                  direction="horizontal"
                  class="radio_box"
                  @change="blurFun('other', otherForm)"
                >
                  <Radio
                    :name="1"
                    class="radio_btn"
                    :class="{
                      checked: otherForm.adjustType == 1,
                    }"
                    >增项</Radio
                  >
                  <Radio
                    :name="2"
                    class="radio_btn"
                    :class="{
                      checked: otherForm.adjustType == 2,
                    }"
                    >减项</Radio
                  >
                </RadioGroup></template
              >
            </Field>

            <CellGroup inset>
              <Field
                v-model="otherForm.project_name"
                label="项目名称"
                placeholder="填写项目名称"
                input-align="right"
                error-message-align="right"
                maxlength="60"
                required
                :rules="[{ required: true, message: '请填写项目名称' }]"
              />
              <Field
                v-model="otherForm.amountMoney"
                label="预估金额"
                placeholder="0.00"
                input-align="right"
                error-message-align="right"
                @blur="blurFun('other', otherForm)"
                required
                :rules="[
                  {
                    required: true,
                    message: '请填写正确的金额',
                    validator: validatorPrice,
                    trigger: 'onChange',
                  },
                ]"
              ></Field>
              <Field
                class="p_space"
                v-model="otherForm.remark"
                label="备注"
                placeholder="填写备注"
                input-align="right"
                error-message-align="right"
                maxlength="100"
              />
            </CellGroup>
            <div class="footer clearfix">
              <p class="pull-left title">
                <span class="c1">预估补差合计</span>
                <span :class="otherForm.amount >= 0 ? 'c2' : 'c3'">
                  ¥
                  {{ otherForm.amount ? otherForm.amount : 0 }}
                </span>
              </p>
              <Button
                class="pull-right btn"
                type="primary"
                @click="saveFun('other')"
              >
                确定
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Popup>
    <!--picker -单位选项-->
    <Popup v-model:show="state.showUnitPicker" position="bottom">
      <Picker
        :columns="state.unitList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v)"
        @cancel="state.showUnitPicker = false"
      />
    </Popup>
    <!-- 更多操作 -->
    <ActionSheet
      v-model:show="state.more_status"
      cancel-text="取消"
      close-on-click-action
      @cancel="state.more_status = false"
      class="more-box"
    >
      <div class="content">
        <div v-for="item in state.more_list" :key="item.id">
          <p @click="getMoreHandel(item.id)">{{ item.name }}</p>
        </div>
      </div>
    </ActionSheet>
  </div>
</template>
<script setup>
import {
  Popup,
  NavBar,
  Button,
  Icon,
  ActionSheet,
  Form,
  CellGroup,
  Field,
  Picker,
  Toast,
  Dialog,
  Notify,
  RadioGroup,
  Radio,
} from 'vant';
import { reactive, defineEmits, defineProps, ref } from 'vue';
import patterns from '@/utils/pattern';

/**
 * 校验方式
 */
const { priceReg } = patterns;
//-单价
const validatorPrice = (val) => (val ? priceReg.test(val) : true);

//-增减量
const { numReg } = patterns;
const validatorNum = (val) => {
  if (val) {
    if (parseFloat(val) <= 999 && parseFloat(val) > 0) {
      if (numReg.test(val)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const state = reactive({
  //-展示类型-edit、编辑 view、查看
  optionType: props.data.optionType ? props.data.optionType : '',
  //-base、基装、main、主材、other、其他类型
  type: props.data.type ? props.data.type : '',

  //-增减项popup
  cardShow: false,
  //-更多操作
  more_status: false,
  more_list: [
    { id: 1, name: '编辑' },
    { id: 2, name: '删除' },
  ],
  //-单位
  showUnitPicker: false,
  unitList: props.unitList ? props.unitList : [],
  //-当前选中项
  current: {},
  currentIndex: -1,
  //-补差合计
  total: props.data.total ? props.data.total : 0,
  totalPlus: props.data.totalPlus ? props.data.totalPlus : 0,
  totalMinus: props.data.totalMinus ? props.data.totalMinus : 0,
});
const emit = defineEmits(['reviewOk']);
const props = defineProps(['data', 'unitList']);
const ruleBaseFormRef = ref(null);
const ruleMainFormRef = ref(null);
const ruleOtherFormRef = ref(null);
//-增减项列表
const list = reactive({
  base_arr: props.data.base_arr
    ? JSON.parse(JSON.stringify(props.data.base_arr))
    : [],
  main_arr: props.data.main_arr
    ? JSON.parse(JSON.stringify(props.data.main_arr))
    : [],
  other_arr: props.data.other_arr
    ? JSON.parse(JSON.stringify(props.data.other_arr))
    : [],
});

//-@@增减项编辑----------------
//-表单
const baseForm = reactive({
  adjustType: '',
  no: '',
  project_name: '',
  owner_space: '',
  unit_id: '',
  unit_name: '',
  price: '',
  number: '',
  remark: '',
  amount: '',
});
const mainForm = reactive({
  adjustType: '',
  name: '',
  brand: '',
  model: '',
  standard: '',
  unit_id: '',
  unit_name: '',
  price: '',
  number: '',
  remark: '',
  amount: '',
});
const otherForm = reactive({
  adjustType: '',
  project_name: '',
  remark: '',
  amount: '',
  amountMoney: '',
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value) => {
  if (state.type == 'base') {
    baseForm.unit_name = value.name;
    baseForm.unit_id = value.id;
  }
  if (state.type == 'main') {
    mainForm.unit_name = value.name;
    mainForm.unit_id = value.id;
  }
  if (state.type == 'other') {
    otherForm.unit_name = value.name;
    otherForm.unit_id = value.id;
  }
  state.showUnitPicker = false;
};
//-失焦填充总计
const blurFun = (type, data) => {
  if (type == 'base') {
    if (data.price && data.number) {
      let num = (parseFloat(data.price) * parseFloat(data.number)).toFixed(2);
      if (data.adjustType == 2) {
        num = (0 - num).toFixed(2);
      }
      baseForm.amount = num;
    }

    if (baseForm.amount === 'NaN') {
      baseForm.amount = 0;
    }
  }
  if (type == 'main') {
    if (data.price && data.number) {
      let num = (parseFloat(data.price) * parseFloat(data.number)).toFixed(2);
      if (data.adjustType == 2) {
        num = (0 - num).toFixed(2);
      }
      mainForm.amount = num;
    }
    if (mainForm.amount === 'NaN') {
      mainForm.amount = 0;
    }
  }
  if (type == 'other') {
    let num = data.amountMoney;
    if (data.adjustType == 2) {
      num = (0 - num).toFixed(2);
    }
    otherForm.amount = num;

    if (otherForm.amount === 'NaN') {
      otherForm.amount = 0;
    }
  }
};

//-重置
const resetForm = () => {
  if (state.type == 'base') {
    baseForm.adjustType = '';
    baseForm.no = '';
    baseForm.project_name = '';
    baseForm.owner_space = '';
    baseForm.unit_id = '';
    baseForm.unit_name = '';
    baseForm.price = '';
    baseForm.number = '';
    baseForm.remark = '';
    baseForm.amount = '';
  } else if (state.type == 'main') {
    mainForm.adjustType = '';
    mainForm.name = '';
    mainForm.brand = '';
    mainForm.model = '';
    mainForm.standard = '';
    mainForm.unit_id = '';
    mainForm.unit_name = '';
    mainForm.price = '';
    mainForm.number = '';
    mainForm.remark = '';
    mainForm.amount = '';
    mainForm.owner_space = '';
  } else {
    otherForm.adjustType = '';
    otherForm.project_name = '';
    otherForm.remark = '';
    otherForm.amount = '';
    otherForm.amountMoney = '';
  }
};

//-新增增减项
const addFun = () => {
  resetForm();
  state.currentIndex = -1;
  state.cardShow = true;
};
//-增减项编辑删除
const getMoreFun = (data, index) => {
  state.current = data;
  state.currentIndex = index;
  state.more_status = true;
};
const getMoreHandel = (type) => {
  if (type === 1) {
    if (!state.current.adjustType) {
      if (state.current.amount > 0) {
        state.current.adjustType = 1;
      } else {
        state.current.adjustType = 2;
      }
    }
    //-编辑
    if (state.type == 'base') {
      baseForm.adjustType = state.current.adjustType;
      baseForm.no = state.current.no;
      baseForm.project_name = state.current.project_name;
      baseForm.owner_space = state.current.owner_space;
      baseForm.unit_id = state.current.unit_id;
      baseForm.unit_name = state.current.unit_name;
      baseForm.price = state.current.price;
      baseForm.number = state.current.number;
      baseForm.remark = state.current.remark;
      baseForm.amount = state.current.amount;
      countFun('base_arr');
    } else if (state.type == 'main') {
      mainForm.adjustType = state.current.adjustType;
      mainForm.name = state.current.name;
      mainForm.brand = state.current.brand;
      mainForm.model = state.current.model;
      mainForm.standard = state.current.standard;
      mainForm.unit_id = state.current.unit_id;
      mainForm.unit_name = state.current.unit_name;
      mainForm.price = state.current.price;
      mainForm.number = state.current.number;
      mainForm.remark = state.current.remark;
      mainForm.amount = state.current.amount;
      countFun('main_arr');
    } else {
      otherForm.adjustType = state.current.adjustType;
      otherForm.project_name = state.current.project_name;
      otherForm.remark = state.current.remark;
      otherForm.amount = state.current.amount;
      otherForm.amountMoney = state.current.amountMoney;
      countFun('other_arr');
    }
    state.cardShow = true;
    state.more_status = false;
  } else {
    Dialog.confirm({
      message: '确定要删除该项吗？',
    })
      .then(() => {
        if (state.type == 'base') {
          list.base_arr = list.base_arr.filter((v, index) => {
            return index != state.currentIndex;
          });
          countFun('base_arr');
        } else if (state.type == 'main') {
          list.main_arr = list.main_arr.filter((v, index) => {
            return index != state.currentIndex;
          });
          countFun('main_arr');
        } else {
          list.other_arr = list.other_arr.filter((v, index) => {
            return index != state.currentIndex;
          });
          countFun('other_arr');
        }
        state.more_status = false;
        state.currentIndex = -1;
        Notify({
          type: 'success',
          message: '操作成功',
        });
      })
      .catch(() => {
        Notify({
          type: 'warning',
          message: '取消操作',
        });
      });
  }
};
//-保存新增编辑
const saveFun = (type) => {
  if (type == 'base') {
    if (!ruleBaseFormRef.value) return;
    ruleBaseFormRef.value
      .validate()
      .then(() => {
        if (state.currentIndex > -1) {
          list.base_arr[state.currentIndex] = JSON.parse(
            JSON.stringify(baseForm)
          );
        } else {
          list.base_arr.push(JSON.parse(JSON.stringify(baseForm)));
        }
        state.currentIndex = -1;
        state.cardShow = false;
        countFun('base_arr');
      })
      .catch(() => {
        //验证失败
        Toast('请先完善信息');
      });
  }
  if (type == 'main') {
    if (!ruleMainFormRef.value) return;
    ruleMainFormRef.value
      .validate()
      .then(() => {
        if (state.currentIndex > -1) {
          list.main_arr[state.currentIndex] = JSON.parse(
            JSON.stringify(mainForm)
          );
        } else {
          list.main_arr.push(JSON.parse(JSON.stringify(mainForm)));
        }
        state.currentIndex = -1;
        state.cardShow = false;
        countFun('main_arr');
      })
      .catch(() => {
        //验证失败
        Toast('请先完善信息');
      });
  }
  if (type == 'other') {
    if (!ruleOtherFormRef.value) return;
    ruleOtherFormRef.value
      .validate()
      .then(() => {
        if (state.currentIndex > -1) {
          list.other_arr[state.currentIndex] = JSON.parse(
            JSON.stringify(otherForm)
          );
        } else {
          list.other_arr.push(JSON.parse(JSON.stringify(otherForm)));
        }
        state.currentIndex = -1;
        state.cardShow = false;
        countFun('other_arr');
      })
      .catch(() => {
        //验证失败
        Toast('请先完善信息');
      });
  }
};
//-计算数据
const countFun = (type) => {
  state.total = list[type]
    .reduce((pre, cur) => {
      let sum = pre + parseFloat(cur.amount);
      return sum;
    }, 0)
    .toFixed(2);
  state.totalMinus = list[type]
    .filter((v) => Math.sign(v.amount) == -1)
    .reduce((pre, cur) => {
      let sum = pre + Math.abs(parseFloat(cur.amount));
      return sum;
    }, 0)
    .toFixed(2);
  state.totalPlus = list[type]
    .filter((v) => Math.sign(v.amount) == 1)
    .reduce((pre, cur) => {
      let sum = pre + parseFloat(cur.amount);
      return sum;
    }, 0)
    .toFixed(2);
};

//-@@增减项编辑----end------------

//-@@@@保存三大类列表数据
//-保存
const sumbitFun = () => {
  emit('reviewOk', state, list);
};
//-取消
const onClosePop = () => {
  if (state.optionType === 'edit') {
    emit('reviewOk', state);
  } else {
    emit('reviewClose', state);
  }
};
//-@@@@保存三大类列表数据------end
</script>
<style lang="less" scoped>
@import '@/assets/styles/change/temp.less';
.radio_box {
  justify-content: space-between;
  width: 100%;
  .radio_btn {
    width: 48%;
    border: 1px solid @line-color !important;
    border-radius: 6px;
    margin-right: 0;
    justify-content: center;
    padding: 8px 0;
    align-items: center;
    :deep(.van-radio__icon) {
      display: none;
    }
    :deep(.van-radio__label) {
      color: @grey-color;
      font-size: 15px;
    }
    &.checked {
      background: @blue-color;

      :deep(.van-radio__label) {
        color: @white-color;
      }
    }
  }
}
:deep(.van-field__label--required:before) {
  position: absolute;
  left: 7px;
}
</style>
