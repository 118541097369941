<!--
 * @Author: gest
 * @Date: 2023-08-08 15:53:21
 * @LastEditTime: 2024-06-14 11:16:47
 * @Description: v1.4.6 跟进分享
-->
<template>
  <div class="shareInfo">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div
      class="font_size_15 grey_color text_center"
      style="height: 44px; line-height: 44px"
    >
      请长按图片，然后点击【转发】分享到客户群
    </div>

    <Image
      :src="htmlImage"
      style="width: 100%; vertical-align: bottom"
      v-show="!_DATA.loading"
    />
    <div class="share_content" id="toImgBox" v-show="_DATA.loading">
      <Image
        :src="require('@/assets/images/share/share_bg.png')"
        style="width: 100%; vertical-align: bottom"
      />

      <div class="share_time font_bold">
        服务时间：{{ _DATA.info.now_follow_time }}
      </div>
      <div class="share_detail">
        <div class="person_title font_bold">服务人员</div>
        <div class="head_box">
          <div
            class="head_item"
            v-for="(item, index) in _DATA.info.service_members"
            :key="index"
          >
            <Image
              class="img_head"
              round
              :src="
                item.face_image
                  ? item.face_image.includes('http')
                    ? item.face_image
                    : $host + item.face_image
                  : index == 0
                  ? require('@/assets/images/share/face_image1.png')
                  : require('@/assets/images/share/face_image2.png')
              "
            ></Image>

            <div class="mr_5 font_size_13 font_bold">{{ item.name }}</div>

            <div
              style="color: rgba(96, 83, 66, 0.5)"
              class="font_size_13 font_bold"
            >
              {{ item.department }}
            </div>
          </div>
        </div>
        <div class="flex_content">
          <div>
            <div class="big" v-if="_DATA.info.is_face_site == 1">
              参观了尚层工地
            </div>
            <div class="big" v-if="_DATA.info.is_design_plan == 1">
              讲设计预案
            </div>
          </div>

          <div class="font_size_12 mt_10 font_bold">承诺上门量房时间</div>
          <div class="big mt_5" style="width: 228px">
            {{
              _DATA.info.is_measure_house == 1
                ? _DATA.info.measure_house_time
                : '暂时不需要量房'
            }}
          </div>

          <div class="mt_5 font_bold" v-if="_DATA.info.next_follow_time">
            并与您约定 {{ _DATA.info.next_follow_time }} 再次沟通
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { Loading, Overlay, Notify, Image } from 'vant';
import { htmlToImg, htmlImage } from '@/utils/htmlToImg';

const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();

const _DATA = reactive({
  id: ref(Number(router.currentRoute.value.query.id)),
  loading: true,
  info: {},
});

onMounted(() => {
  loadFunc();
});

/**
 * 接口调用
 */
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .shareDetail({
      id: _DATA.id,
    })
    .then((res) => {
      const { code, data } = res;

      _DATA.info = data;
      setTimeout(() => {
        htmlToImg('#toImgBox');
        _DATA.loading = false;
      }, 200);
      if (code !== 0) {
        Notify({ type: 'warning', message: data });
      }
    });
};
</script>
<style lang="less" scoped>
.shareInfo {
  .share_content {
    position: relative;
    color: #4a4032;
    font-size: 13px;
    .share_time {
      position: absolute;
      top: 176px;
      left: 68px;
      color: #46382b;
      font-size: 13px;
    }
    .share_detail {
      position: absolute;
      top: 220px;
      left: 68px;
      .person_title {
        font-size: 13px;
        color: #46382b;
        padding-bottom: 10px;
      }
      .head_box {
        .head_item {
          display: flex;
          align-items: center;
          color: #4a4032;
          margin-bottom: 5px;
          .img_head {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
      .big {
        color: #f37020;
        font-weight: 900;
        line-height: 36px;
        font-size: 28px;
      }
      .flex_content {
        height: 216px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
</style>
