<!--
 * @Author: NanNan
 * @Date: 2023-02-17 17:36:22
 * @LastEditTime: 2024-04-19 18:37:09
 * @Description: v1.3.2 线索新增跟进记录
 * v1.4.9 smart 跟进记录新增必填项“线索等级”，线索编辑页面去除线索等级
 * v1.5.4 smart [2024.1.19]紧急上线：“线索等级”，计算规则 >= 200 更改成 >= 180
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <Form class="form-box" ref="formRef" @failed="onFailed" @submit="onSubmit">
      <p class="label-name">
        <em>*</em>
        本次跟进
      </p>
      <div class="active-box">
        客户主动
        <Checkbox v-model="form.is_customer_active" />
      </div>

      <Field
        v-model="formValue.communication_type_id"
        readonly
        right-icon="arrow-down"
        placeholder="选择联系方式"
        class="mb15"
        @click="action.communication_type_id = true"
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请选择联系方式',
          },
        ]"
      />

      <Field
        v-model="form.record_time"
        name="picker"
        readonly
        placeholder="选择跟进时间"
        right-icon="underway-o"
        @click="action.record_time = true"
        :rules="[
          { required: true, trigger: 'onSubmit', message: '请选择跟进时间' },
        ]"
      />

      <!-- v1.4.9 新增线索等级 -->
      <div v-if="clueInfo.source_type == 1 || clueInfo.source_type == 2">
        <p class="label-name">
          <em>*</em>
          线索等级
          <Icon name="question" color="#FFAE4E" @click.stop="getInstructions" />
        </p>
        <Field
          v-model="formValue.level"
          right-icon="arrow-down"
          readonly
          placeholder="选择线索等级"
          @click="action.level = true"
          :rules="[
            {
              required: true,
              trigger: 'onSubmit',
              message: '请选择线索等级',
            },
          ]"
        />
      </div>
      <!-- v1.4.9end -->

      <p class="label-name">
        <em>*</em>
        是否有效
      </p>

      <Field
        v-model="formValue.valid_type"
        readonly
        right-icon="arrow-down"
        placeholder="选择是否有效"
        @click="action.valid_type = true"
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请选择是否有效',
          },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        本次跟进内容
      </p>
      <Field
        v-model="form.content"
        placeholder="填写本次跟进内容..."
        rows="3"
        autosize
        type="textarea"
        maxlength="300"
        show-word-limit
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请填写本次跟进内容',
          },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        下次跟进
      </p>
      <Field
        v-model="formValue.next_follow_type"
        readonly
        right-icon="arrow-down"
        placeholder="选择跟进方式"
        @click="action.next_follow_type = true"
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请选择跟进方式',
          },
        ]"
      />

      <Field
        v-model="form.next_record_time"
        name="picker"
        readonly
        placeholder="选择下次跟进时间"
        right-icon="underway-o"
        @click="action.next_record_time = true"
        class="mt15"
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请选择下次跟进时间',
          },
        ]"
        v-if="!action.next_record_time_disabled"
      />

      <div class="stick-box">
        <Button
          type="primary"
          size="large"
          native-type="submit"
          :loading="loadingBtn"
        >
          确定
        </Button>
      </div>
    </Form>
    <!-- 联系方式 -->
    <Popup v-model:show="action.communication_type_id" position="bottom">
      <Picker
        :columns="options.communication_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="(value) => handelChange(value, 'communication_type_id')"
        @cancel="action.communication_type_id = false"
        :default-index="
          options.communication_type.findIndex((v) => {
            return v.id == form.communication_type_id;
          })
        "
      />
    </Popup>
    <!-- 跟进时间 年月日时分-->
    <Popup v-model:show="action.record_time" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="datetime"
        title="跟进时间"
        @confirm="(value) => handelChange(value, 'record_time')"
        @cancel="action.record_time = false"
      />
    </Popup>
    <!-- 是否有效 -->
    <Popup v-model:show="action.valid_type" position="bottom">
      <Picker
        :columns="options.valid_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="(value) => handelChange(value, 'valid_type')"
        @cancel="action.valid_type = false"
        :default-index="
          options.valid_type.findIndex((v) => {
            return v.id == form.valid_type;
          })
        "
      />
    </Popup>
    <!-- 下次跟进方式 -->
    <Popup v-model:show="action.next_follow_type" position="bottom">
      <Picker
        :columns="options.next_follow_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="(value) => handelChange(value, 'next_follow_type')"
        @cancel="action.next_follow_type = false"
        :default-index="
          options.next_follow_type.findIndex((v) => {
            return v.id == form.next_follow_type;
          })
        "
      />
    </Popup>
    <!-- 下次跟进时间 年月日时分-->
    <Popup v-model:show="action.next_record_time" position="bottom">
      <DatetimePicker
        v-model="currentSevenDate"
        type="datetime"
        title="下次跟进时间"
        @confirm="(value) => handelChange(value, 'next_record_time')"
        @cancel="action.next_record_time = false"
      />
    </Popup>
    <!-- v1.4.9线索等级 -->
    <Popup v-model:show="action.level" position="bottom" :key="keyNumber">
      <Picker
        :columns="options.clue_level"
        :columns-field-names="{
          text: 'name',
          value: 'id',
          disabled: 'disabled',
        }"
        @confirm="(value) => handelChange(value, 'level')"
        @cancel="action.level = false"
        :default-index="
          options.clue_level.findIndex((v) => {
            return v.id == form.level;
          })
        "
      />
    </Popup>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  Loading,
  Overlay,
  Toast,
  Notify,
  Form,
  Checkbox,
  Popup,
  Picker,
  Field,
  DatetimePicker,
  Button,
  Dialog,
  Icon,
} from 'vant';
import moment from 'moment';
const router = useRouter();

const loading = ref(false);
const $http = inject('$http');

const formValue = reactive({
  communication_type_id: '', //联系方式str
  valid_type: '', //是否有效str
  valid_type_default: '', //是否有效str默认值
  next_follow_type: '继续跟进', //下次跟进方式str
  level: '', //v1.4.9线索等级str
});

const form = reactive({
  is_customer_active: false, //是否客户主动：0false 否, 1true 是
  communication_type_id: '', //联系方式
  record_time: undefined, // 本次跟进时间
  valid_type: '', // 是否有效
  content: '', // 本次跟进内容
  next_record_time: undefined, // 下次跟进时间
  next_follow_type: 1, // 下次跟进方式
  level: '', ////v1.4.9线索等级
});

const action = reactive({
  communication_type_id: false, //联系方式
  record_time: false, //本次跟进时间
  valid_type: false, //是否有效
  next_follow_type: false, //下次跟进方式
  next_record_time: false, //下次跟进时间
  next_record_time_disabled: false, //下次跟进时间是否disabled
  level: false, //v1.4.9线索等级
});

const options = reactive({});

onMounted(() => {
  loadParmas();
  loadInfo();
});

/**
 * 接口api
 */

// -- 公共参数接口
const currentDate = ref(new Date());
const currentSevenDate = ref(new Date());
const loadParmas = () => {
  loading.value = true;
  $http.clueFollowRecordParam().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Object.assign(options, data);
      form.record_time = data.now_day;
      form.next_record_time = data.seven_day;
      currentDate.value = data.now_day ? new Date(data.now_day) : new Date();
      currentSevenDate.value = data.seven_day
        ? new Date(data.seven_day)
        : new Date();
      loadClueInfo();
    } else {
      Notify({ type: 'warning', message: data });
    }
    loading.value = false;
  });
};

// -- 最近一次跟进记录详情
const loadInfo = () => {
  $http
    .getClueFollowRecordInfoNew({
      clue_id: router.currentRoute.value.query.cludID,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data && data.valid_type) {
          form.valid_type = data.valid_type;
          formValue.valid_type = options.valid_type.filter(
            (item) => item.id == data.valid_type
          )[0].name;
          formValue.valid_type_default = JSON.parse(
            JSON.stringify(formValue.valid_type)
          );
        }
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

// -- 线索详情
const clueInfo = reactive({});
const loadClueInfo = () => {
  $http
    .getClueInfo({ id: router.currentRoute?.value?.query?.cludID })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Object.assign(clueInfo, data);
        // formValue.level = data.level_name;
        // form.level = data.level;
        levelOptionsHandle(
          [data.size, data.house_category, data.home_type, data.source_type],
          data.level
        );
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

/**
 * 操作
 */

// -- v1.4.9 面积、精装/毛坯、房屋结构修改之后引发的“线索等级”筛选项变动
const keyNumber = ref(Date.parse(new Date()));
const levelOptionsHandle = (newVal, level) => {
  console.log(newVal);
  keyNumber.value = Date.parse(new Date());
  const size = newVal[0]; //面积
  const hose_id = newVal[1]; //精装/毛坯
  const home_id = newVal[2]; //房屋结构
  // A类可选：面积 >= 180 && 精装/毛坯 == 101毛坯 && 房屋结构 != (488未知 || 111自建)；
  // B类可选：面积 >= 140 && 精装/毛坯 == 102精装 && 房屋结构 != (488未知 || 111自建)；
  // C类可选：新建时，永远禁止选择，disabled状态；编辑时，永远可选
  // D类可选：不符合上述情况为D；
  clueInfo.level = '';
  if (
    size &&
    parseFloat(size) >= 180 &&
    hose_id == 101 &&
    home_id != 488 &&
    home_id != 111
  ) {
    options.clue_level[0].disabled = false;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = true;
    clueInfo.level = level || 1;
    clueInfo.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[0].name;
    console.log('A+C');
    return;
  } else if (
    (size &&
      parseFloat(size) >= 140 &&
      hose_id == 102 &&
      home_id != 488 &&
      home_id != 111) ||
    (size && parseFloat(size) >= 180 && home_id == 111)
  ) {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = false;
    options.clue_level[3].disabled = true;
    clueInfo.level = level || 2;
    clueInfo.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[1].name;
    console.log('B+C');
    return;
  } else {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = false;
    clueInfo.level = level || 4;
    clueInfo.level_name = level
      ? options.clue_level[level - 1].name
      : options.clue_level[3].name;
    console.log('D+C');
  }
  console.log(options.clue_level);
};

// -- v1.4.9 新增线索等级 文字信息提示
const getInstructions = () => {
  Dialog.confirm({
    title: '',
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#576B95',
    className: 'dialogDescripton',
    message: `<div class="description"><h3>线索等级判定方式</h3><p>1.A类 - 200平米以上毛坯</p><p>2.B类 - 140平米以上精装、200平米以上自建</p><p>3.C类 - 经判定已无法联系或无需求线索</p><p>4.D类 - 非上述范围，或信息不全的线索</p></div>`,
    allowHtml: true,
  });
};

// -- 组件操作

const popOptions = reactive({
  time: ['record_time', 'next_record_time'],
  select: ['communication_type_id', 'valid_type', 'level'],
});
const handelChange = (value, name) => {
  if (popOptions.time.find((element) => element === name)) {
    //跟进时间、下次跟进时间
    form[name] = moment(value).format('YYYY-MM-DD HH:mm');
    action[name] = false;
  } else if (popOptions.select.find((element) => element === name)) {
    //联系方式、是否有效
    form[name] = value.id;
    formValue[name] = value.name;
    action[name] = false;
  } else if (name === 'next_follow_type') {
    //下次跟进方式
    form[name] = value.id;
    formValue[name] = value.name;
    action[name] = false;
    if (value.name === '不再跟进') {
      action.next_record_time_disabled = true;
      form.next_record_time = undefined;
    } else {
      action.next_record_time_disabled = false;
    }
  }
};

// -- 表单提交
const formRef = ref(null);
const loadingBtn = ref(false);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loadingBtn.value = true;
    loading.value = true;
    if (
      formValue.valid_type_default &&
      formValue.valid_type_default !== formValue.valid_type
    ) {
      Dialog.confirm({
        message: '是否确认将线索标记为【' + formValue.valid_type + '】',
      })
        .then(() => {
          submitApi();
        })
        .catch(() => {
          loadingBtn.value = false;
          loading.value = false;
        });
    } else {
      submitApi();
    }
  });
};
// -- 提交接口
const submitApi = () => {
  const params = JSON.parse(JSON.stringify(form));
  params.is_customer_active = params.is_customer_active ? 1 : 0;
  params.level =
    clueInfo.source_type == 1 || clueInfo.source_type == 2 ? params.level : '';
  $http
    .addClueFollowRecord({
      clue_id: router.currentRoute.value.query.cludID,
      ...params,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loadingBtn.value = true;
            loading.value = true;
            router.back();
          },
        });
      } else {
        loadingBtn.value = false;
        loading.value = false;
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
        });
      }
    });
};
// -- 表单报错
const onFailed = (errorInfo) => {
  Toast('请完善信息!', errorInfo);
  return false;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueFollowNew.less';
</style>
