<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2023-05-26 14:51:30
 * @Description: 
 * v1.3.7 smart 修改分配客户经理组件，下拉单选更改为pop搜索
-->
<template>
  <!-- 分配客户经理 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              客户经理
            </label>
            <Field
              v-model="form.list.manager_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择客户经理"
              :rules="[
                {
                  required: true,
                  message: '请选择客户经理',
                },
              ]"
              @click="openPicker('客户经理')"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <Popup
      v-model:show="picker.show"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempUser
        v-if="picker.show"
        :data="picker"
        @reviewClose="
          () => {
            picker.show = false;
          }
        "
        @reviewOk="(item) => reviewOk_person(item, '客户经理')"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import TempUser from '@/components/task/TempUser';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-客户经理list
  managerList: [],
});

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  state.loadingConfirm = true;
  await $http.customerAssignServicePersonParam().then((res) => {
    if (res.code === 0) {
      state.managerList = res.data.customer_manager;
      changeChecker();
    } else {
      state.loadingConfirm = false;
      Toast(res.data);
    }
  });
};
//-获取参数--end

//-打开人员组件
const picker = reactive({
  show: false,
  title: '', // 客户经理、设计师
  type: 'customer_manager', //customer_manager、design
  checkedItem: { id: '', name: '' }, //客户经理、设计师
});
const openPicker = (type_name) => {
  picker.title = type_name;
  picker.show = true;
};
const reviewOk_person = (checkedItem) => {
  picker.show = false;
  if (picker.type === 'customer_manager') {
    form.list.manager = checkedItem?.id;
    form.list.manager_name = checkedItem?.name;
    picker.checkedItem.id = checkedItem?.id;
    picker.checkedItem.name = checkedItem?.name;
  }
};

//-数据容错处理
const changeChecker = () => {
  let manager = state.managerList;
  const managerList =
    manager.length > 0 && !!router.currentRoute.value.query.muid
      ? manager.filter(
          (item) => item.id == router.currentRoute.value.query.muid
        )
      : [];
  if (managerList.length === 0) {
    form.list.manager = '';
    form.list.manager_name = '';
    picker.checkedItem.id = '';
    picker.checkedItem.name = '';
  } else {
    form.list.manager = managerList[0]['id'];
    form.list.manager_name = managerList[0]['name'];
    picker.checkedItem.id = managerList[0]['id'];
    picker.checkedItem.name = managerList[0]['name'];
  }
  state.loadingConfirm = false;
};

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    type: 3,
    manager: '',
    manager_name: '',
    remarks: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .customerAssignServicePerson({
          customer_id: form.list.customer_id,
          type: form.list.type,
          service_person_id: form.list.manager,
          remarks: form.list.remarks,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (state.taskDate) {
                  router.push('/taskDay?date=' + state.taskDate);
                } else {
                  router.push('/sale?id=' + form.list.customer_id);
                }
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
