<!--
 * @Author: Jessica
 * @Date: 2023-02-08 10:30:27
 * @LastEditTime: 2023-03-09 15:09:37
 * @Description: 隐私信息（小眼睛）编辑
 * v1.3.2 区域位置开放，仅取消禁用，依旧保持楼盘联动
-->
<template>
  <!-- 隐私信息（小眼睛）编辑 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell" v-if="form.list.customer_name_auth == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              客户姓名
            </label>
            <Field
              v-model="form.list.customer_name"
              maxlength="20"
              placeholder="请填写"
              :rules="formRules.customer_name"
              v-if="form.list.customer_name_auth_edit == 1"
            />
            <Field
              v-model="form.list.customer_name"
              placeholder="-"
              disabled
              v-else
            />
          </div>
          <div class="cell" v-if="form.list.customer_name_auth == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              性别
            </label>
            <Field
              v-model="form.list.gender_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择"
              :rules="formRules.gender"
              @click="state.showSexPicker = true"
              v-if="form.list.customer_name_auth_edit == 1"
            />
            <Field
              v-model="form.list.gender_name"
              placeholder="-"
              disabled
              v-else
            />
          </div>
          <div class="cell" v-if="form.list.phone1_auth == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              手机号1
            </label>
            <Field
              v-model="form.list.phone1"
              placeholder="请填写"
              :rules="formRules.phone1"
              v-if="form.list.phone1_auth_edit == 1"
            />
            <Field v-model="form.list.phone1" placeholder="-" disabled v-else />
          </div>
          <div class="cell" v-if="form.list.phone1_auth == 1">
            <label class="form_title">手机号2</label>
            <Field
              v-model="form.list.phone2"
              placeholder="请填写"
              :rules="formRules.phone2"
              v-if="form.list.phone1_auth_edit == 1"
            />
            <Field v-model="form.list.phone2" placeholder="-" disabled v-else />
          </div>
          <div class="cell" v-if="form.list.phone1_auth == 1">
            <label class="form_title">微信号</label>
            <Field
              v-model="form.list.wechat"
              maxlength="50"
              placeholder="请填写"
              v-if="form.list.phone1_auth_edit == 1"
            />
            <Field v-model="form.list.wechat" placeholder="-" disabled v-else />
          </div>
          <div class="cell" v-if="form.list.floor_auth == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              楼盘
            </label>
            <Field
              v-model="form.list.floor_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择"
              :rules="formRules.floor_id"
              @click="state.showFloorPopup = true"
              v-if="form.list.floor_auth_edit == 1"
            />
            <Field
              v-model="form.list.floor_name"
              placeholder="-"
              disabled
              v-else
            />
          </div>
          <div class="cell" v-if="form.list.house_auth == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              单元栋号
            </label>
            <Field
              v-model="form.list.floor_detail_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择"
              :rules="formRules.floor_detail_id"
              @click="state.showFloorDetailPicker = true"
              v-if="form.list.house_auth_edit == 1"
            />
            <Field
              v-model="form.list.floor_detail_name"
              placeholder="-"
              disabled
              v-else
            />
          </div>
          <div class="cell" v-if="form.list.house_auth == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积
            </label>
            <Field
              v-model="form.list.size"
              maxlength="7"
              placeholder="请填写"
              :rules="formRules.size"
              v-if="form.list.house_auth_edit == 1"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
            <Field v-model="form.list.size" placeholder="-" disabled v-else>
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.list.house_auth == 1">
            <label class="form_title">设计面积</label>
            <Field v-model="form.list.design_size" placeholder="-" disabled>
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.list.house_auth == 1">
            <label class="form_title">施工面积</label>
            <Field
              v-model="form.list.construction_size"
              placeholder="-"
              disabled
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.list.floor_auth == 1">
            <label class="form_title">区域位置</label>
            <Field
              v-model="form.list.city_name"
              readonly
              placeholder="请填写"
              @click="state.showPickerCity = true"
              v-if="form.list.floor_auth_edit == 1"
            />
            <Field
              v-model="form.list.city_name"
              placeholder="-"
              disabled
              v-else
            />
          </div>
          <div class="cell" v-if="form.list.house_auth == 1">
            <label class="form_title">详细地址</label>
            <Field
              v-model="form.list.address"
              maxlength="50"
              placeholder="请填写"
              v-if="form.list.house_auth_edit == 1"
            />
            <Field
              v-model="form.list.address"
              placeholder="-"
              disabled
              v-else
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- picker---集合 -->
    <!-- 性别 -->
    <Popup v-model:show="state.showSexPicker" position="bottom">
      <Picker
        :columns="options.gender_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'gender')"
        @cancel="state.showSexPicker = false"
        :default-index="
          options.gender_list.findIndex((v) => {
            return v.id == form.list.gender;
          })
        "
      />
    </Popup>
    <!-- 楼盘 -->
    <Popup
      v-model:show="state.showFloorPopup"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <FloorList
        v-if="state.showFloorPopup"
        :id="form.list.floor_id"
        :name="form.list.floor_name"
        @reviewOk="reviewOk"
        @reviewClose="reviewClose"
      />
    </Popup>
    <!-- 单元栋号 -->
    <Popup v-model:show="state.showFloorDetailPicker" position="bottom">
      <Picker
        :columns="state.floor_detail_list"
        :columns-field-names="customFloorFieldName"
        @confirm="(v) => onConfirm(v, 'floor_detail')"
        @cancel="state.showFloorDetailPicker = false"
        :default-index="
          state.floor_detail_list.findIndex((v) => {
            return v.id == form.list.floor_detail_id;
          })
        "
      />
    </Popup>
    <!-- picker---end -->
    <!-- 区域位置 -->
    <!-- 区域位置 -->
    <Popup v-model:show="state.showPickerCity" position="bottom">
      <Picker
        :columns="options.city_list"
        :columns-field-names="{
          text: 'name',
          value: 'code',
          children: 'sub',
        }"
        @confirm="changePickerCity"
        @cancel="state.showPickerCity = false"
        ref="cityRef"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import FloorList from '@/components/sale/floorList';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
  Popup,
  Picker,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  loadingConfirm: false,
  //-性别
  showSexPicker: false,
  //-楼盘
  showFloorPopup: false,
  //-单元栋号
  showFloorDetailPicker: false,
  floor_detail_list: [],
  //-区域位置
  showPickerCity: false,
});

//-picker-------操作------------

//-picker-选择项字段

//--@ 性别
const customFieldName = {
  text: 'name',
  value: 'id',
};
//--@ 单元栋号
const customFloorFieldName = {
  text: 'detail_name',
  value: 'id',
};

//--@  确认操作
const onConfirm = (value, type) => {
  if (type == 'gender') {
    if (value) {
      form.list.gender = value.id;
      form.list.gender_name = value.name;
    }
    state.showSexPicker = false;
  } else if (type == 'floor_detail') {
    if (value) {
      form.list.floor_detail_id = value.id;
      form.list.floor_detail_name = value.detail_name;
      form.list.size = value.size;
    }
    state.showFloorDetailPicker = false;
  }
};

// 楼盘操作
const reviewOk = (v, detail) => {
  form.list.floor_id = v.id;
  form.list.floor_name = v.name;
  form.list.floor_detail_id = '';
  form.list.floor_detail_name = '';
  form.list.size = '';
  form.list.city_id = v.city_id;
  form.list.city_name = v.city_name;
  state.floor_detail_list = detail.floor_detail_list;
  state.showFloorPopup = false;
};

const reviewClose = () => {
  state.showFloorPopup = false;
};
//- @ 获取单元栋号
const getFloorDetail = (item) => {
  $http
    .getFloorsApi({
      kw: item.floor_name,
    })
    .then((res) => {
      if (res.code === 0) {
        const all_detail_list = res.data.floor_detail_list ?? [];
        const objArr = item.floor_id
          ? all_detail_list.filter((val) => val.floor_id == item.floor_id)
          : undefined;
        state.floor_detail_list = objArr?.length ? objArr : [];
      } else {
        Notify({
          type: 'warning',
          message: res.msg || res.data,
        });
      }
    });
};
//- 区域位置
const changePickerCity = (value) => {
  state.showPickerCity = false;
  form.list.city_id = value[2]?.code;
  form.list.city_name =
    value && value[0]?.name + '/' + value[1]?.name + '/' + value[2]?.name;
};
//-picker-------end------------

//-获取参数--begin
onBeforeMount(() => {
  lookHideInfo();
  loadParams();
});

//-参数值
const options = reactive({});
// -- 公共参数
const loadParams = () => {
  $http.getSaleOptionsApi().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Object.assign(options, data.customer);
      options.city_list = data.city;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-@ 敏感信息详情
const lookHideInfo = () => {
  state.loadingConfirm = true;
  $http
    .getSaleSensitiveFieldInfoApi({ customer_id: state.customer_id })
    .then((res) => {
      state.loadingConfirm = false;
      if (res.code === 0) {
        const { phone1_auth, customer_name_auth, floor_auth, house_auth } =
          res.data;
        if (
          customer_name_auth == 1 ||
          phone1_auth == 1 ||
          floor_auth == 1 ||
          house_auth == 1
        ) {
          form.list = res.data;
          getFloorDetail(res.data);
        } else {
          Notify({
            type: 'warning',
            message: '无权查看',
          });
        }
      } else {
        Notify({
          type: 'warning',
          message: res.message || res.data,
        });
      }
    });
};

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_name: '',
    gender: '',
    gender_name: '',
    phone1: '',
    phone2: '',
    wechat: '',
    size: '',
    design_size: '',
    construction_size: '',
    address: '',
    city_id: '',
    city_name: '',
    floor_id: '',
    floor_name: '',
    floor_detail_id: '',
    floor_detail_name: '',
  },
});

// 表单验证
// 手机号
const phoneReg = /^(1\d{10})$/;
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
//房屋面积
const houseSizeReg = /^(\d{0,4})(\.(\d{0,2}))?$/; //房屋面积-最多输入4位整数，两位小数
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

const formRules = {
  customer_name: [
    {
      required: true,
      message: '请填写客户姓名',
    },
  ],
  gender: [
    {
      required: true,
      message: '请选择性别',
    },
  ],
  phone1: [
    {
      required: true,
      validator: validatorPhone,
      message: '请填写正确的手机号',
    },
  ],
  phone2: [
    {
      validator: validatorPhone,
      message: '请填写正确的手机号',
    },
  ],
  floor_id: [
    {
      required: true,
      message: '请选择楼盘',
    },
  ],
  floor_detail_id: [
    {
      required: true,
      message: '请选择单元栋号',
    },
  ],
  size: [
    {
      required: true,
      validator: validatorSize,
      message: '请填写正确的面积',
    },
  ],
};

//-保存操作;
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .setSensitiveFieldEditApi({
          ...form.list,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};
//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/sale/saleSerectHide.less';
</style>
