<!--
 * @Author: Jessica
 * @Date: 2023-07-22 09:50:07
 * @LastEditTime: 2023-07-22 10:23:21
 * @Description: V1.4.2 新增客户完成 中间页
-->
<template>
  <div class="index-page">
    <Image
      width="80px"
      height="80px"
      fit="cover"
      lazy-load
      :src="require('@/assets/images/complete.png')"
    />
    <h3>完成新增</h3>
    <p class="text">未填写的信息可在客户详情页继续补充</p>
    <div class="btn" @click="addCuntomer">查看客户详情</div>
  </div>
</template>
<script setup>
import { Image } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();

/**
 * 操作
 */

//-添查看客户详情
const addCuntomer = () => {
  router.push('/sale?id=' + router.currentRoute.value.query.id);
};
</script>
<style lang="less" scoped>
.index-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 48px);
  padding: 35% 25px 0;
  > h3 {
    font-size: 34px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #3e4345;
    padding-top: 30px;
  }
  .text {
    font-size: 13px;
    line-height: 18px;
    color: #999999;
    padding-top: 15px;
  }
  .btn {
    width: calc(100% - 50px);
    position: absolute;
    bottom: 30px;
    height: 50px;
    line-height: 50px;
    opacity: 1;
    border: 1px solid #cdd2fd;
    text-align: center;
    color: #3e4345;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 3px rgba(205, 210, 253, 0.2);
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
