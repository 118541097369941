<!--
 * @Author: gest
 * @Date: 2023-09-22 10:55:11
 * @LastEditTime: 2023-11-24 11:29:02
 * @Description: v1.4.6 家庭居室/优选整装阶段结算单
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">实际开工日期</div>
            <div class="">{{ form.info.start_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">预计完工日期</div>
            <div class="">{{ form.info.plan_end_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div
          class="field_item"
          v-if="
            (form.info.contract_type == 56 && props.sub_type == 22) ||
            props.sub_type != 22
          "
        >
          <div class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">
              <div>
                原工程合同{{
                  props.sub_type == 22
                    ? '隐蔽'
                    : props.sub_type == 23
                    ? '中期'
                    : props.sub_type == 24
                    ? '尾期'
                    : ''
                }}款
              </div>

              <div class="grey_color font_size_13">
                {{ form.info.proportion_name }}
              </div>
            </div>
            <div>
              <span class="blue_color">{{
                form.info.payment_period || form.info.payment_period === 0
                  ? form.info.payment_period
                  : '-'
              }}</span>
              元
            </div>
          </div>
        </div>

        <div class="field_item right" v-if="form.info?.is_item_edit == 1">
          <div class="flex_between">
            <div class="flex1 mr_15">
              <span class="red_color">*</span>
              {{
                props.sub_type == 22
                  ? '隐蔽'
                  : props.sub_type == 23
                  ? '中期'
                  : props.sub_type == 24
                  ? '尾期'
                  : ''
              }}变更
            </div>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick"
                  v-model="form.info.change_type"
                  direction="horizontal"
                  checked-color="#0256FF"
                >
                  <Radio :name="1">增加</Radio>
                  <Radio :name="2">减少</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
        <div class="field_item" v-if="form.info?.is_item_edit == 1">
          <div class="flex_between">
            <div>
              <span class="red_color">*</span>
              {{
                props.sub_type == 22
                  ? '隐蔽'
                  : props.sub_type == 23
                  ? '中期'
                  : props.sub_type == 24
                  ? '尾期'
                  : ''
              }}变更款
            </div>

            <div class="flex_between">
              <div
                class="mr_5"
                style="line-height: 20px"
                v-if="form.info.change_type == 1"
              >
                +
              </div>
              <div
                class="mr_5"
                style="line-height: 20px"
                v-if="form.info.change_type == 2"
              >
                -
              </div>
              <Field
                style="text-align: right; width: 116px"
                v-model="form.info.change_amount"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写',
                  },
                ]"
                @blur="changeMoney"
                :disabled="form.info.edit_change_amount != 1"
              >
                <template #button>元</template></Field
              >
            </div>
          </div>
          <div
            class="text_right mt_5"
            v-if="
              form.info.contract_type != 56 &&
              props.sub_type == 22 &&
              (form.info.change_amount || form.info.change_amount === 0)
            "
          >
            {{
              form.info.change_amount || form.info.change_amount === 0
                ? convertCurrency(
                    form.info.change_type == 2
                      ? NP.times(-1, form.info.change_amount)
                      : form.info.change_amount
                  )
                : '-'
            }}
          </div>
        </div>
        <div class="field_item" v-if="form.info?.is_item_edit != 1">
          <div class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">
              <div>
                {{
                  props.sub_type == 22
                    ? '隐蔽'
                    : props.sub_type == 23
                    ? '中期'
                    : props.sub_type == 24
                    ? '尾期'
                    : ''
                }}变更款
              </div>
            </div>
            <div>
              <span class="blue_color">{{
                form.info.change_amount || form.info.change_amount === 0
                  ? form.info.change_type == 2
                    ? NP.times(-1, form.info.change_amount)
                    : form.info.change_amount
                  : '-'
              }}</span>
              元
            </div>
          </div>
          <div
            class="text_right mt_5"
            v-if="
              form.info.contract_type != 56 &&
              props.sub_type == 22 &&
              (form.info.change_amount || form.info.change_amount === 0)
            "
          >
            {{
              form.info.change_amount || form.info.change_amount === 0
                ? convertCurrency(
                    form.info.change_type == 2
                      ? NP.times(-1, form.info.change_amount)
                      : form.info.change_amount
                  )
                : '-'
            }}
          </div>
        </div>
        <template v-if="form.info?.is_item_edit == 1">
          <div
            class="field_item right"
            v-if="
              (form.info.contract_type == 56 && props.sub_type == 22) ||
              props.sub_type != 22
            "
          >
            <div class="flex_between">
              <div style="width: 220px">
                <span class="red_color">*</span> 预收工程合同{{
                  props.sub_type == 22
                    ? '隐蔽'
                    : props.sub_type == 23
                    ? '中期'
                    : props.sub_type == 24
                    ? '尾期'
                    : ''
                }}款
              </div>
              <Field
                style="text-align: right"
                v-model="form.info.received_amount"
                type="number"
                :formatter="formatter2"
                format-trigger="onBlur"
                placeholder="填写"
                :rules="[
                  {
                    required: true,
                    message: '请填写预收款',
                  },
                ]"
                @blur="changeMoney"
              >
                <template #button>元</template></Field
              >
            </div>
          </div></template
        >
        <template v-if="form.info?.is_item_edit != 1">
          <div
            class="field_item"
            v-if="
              (form.info.contract_type == 56 && props.sub_type == 22) ||
              props.sub_type != 22
            "
          >
            <div class="flex_between align_center">
              <div style="max-width: 200px" class="flex1">
                <div>
                  预收工程合同{{
                    props.sub_type == 22
                      ? '隐蔽'
                      : props.sub_type == 23
                      ? '中期'
                      : props.sub_type == 24
                      ? '尾期'
                      : ''
                  }}款
                </div>
              </div>
              <div>
                <span class="blue_color">{{
                  form.info.received_amount || form.info.received_amount === 0
                    ? form.info.received_amount
                    : '-'
                }}</span>
                元
              </div>
            </div>
          </div></template
        >

        <div
          class="field_item"
          v-if="
            (form.info.contract_type == 56 && props.sub_type == 22) ||
            props.sub_type != 22
          "
        >
          <div class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">当前应收工程款</div>
            <div>
              <span class="blue_color">{{
                form.info.settled_amount || form.info.settled_amount === 0
                  ? form.info.settled_amount
                  : '-'
              }}</span>
              元
            </div>
          </div>
          <div
            class="text_right mt_5"
            v-if="form.info.settled_amount || form.info.settled_amount === 0"
          >
            {{
              form.info.settled_amount || form.info.settled_amount === 0
                ? convertCurrency(form.info.settled_amount)
                : '-'
            }}
          </div>
          <div
            class="grey_color font_size_13 mt_15"
            v-if="props.sub_type == 22"
          >
            当前应收工程款=原工程合同隐蔽款+隐蔽变更款-预收工程合同隐蔽款
          </div>
          <div
            class="grey_color font_size_13 mt_15"
            v-if="props.sub_type == 23"
          >
            当前应收工程款=原工程合同中期款+中期变更款-预收工程合同中期款
          </div>

          <div
            class="grey_color font_size_13 mt_15"
            v-if="props.sub_type == 24"
          >
            当前应收工程款=原工程合同尾期款+尾期变更款-预收工程合同尾期款
          </div>
        </div>
        <div class="field_item right">
          <div class="flex_between">
            <div>客户最晚交款时间</div>
            <Field
              style="text-align: right"
              v-model="form.info.expect_end_time"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              @click="openDateType()"
            />
          </div>
          <div class="font_size_13 mt_15 grey_color">
            请客户务必于该日期前缴纳款项到乙方银行账户，否则，可能由此影响工程进度。
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="font_size_13 grey_color border_bottom"
      style="padding: 15px 15px 6px 15px; background: #ffffff"
    >
      当前工程预决算总额
    </div> -->
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div
          class="field_item"
          :class="
            (props.sub_type == 23 || props.sub_type == 24) &&
            form.info.change_amount_total == 0
              ? 'right'
              : ''
          "
        >
          <div
            class="flex_between"
            v-if="
              (props.sub_type == 23 || props.sub_type == 24) &&
              form.info.change_amount_total == 0
            "
          >
            <div style="width: 220px">
              <span class="red_color">*</span> 当前工程预决算总额
            </div>
            <Field
              style="text-align: right"
              v-model="form.info.change_contract_amount"
              type="number"
              :formatter="formatter"
              format-trigger="onBlur"
              placeholder="填写"
              :rules="[
                {
                  required: true,
                  message: '请填写当前工程预决算总额',
                },
              ]"
              @blur="changeMoney"
            >
              <template #button>元</template></Field
            >
          </div>

          <div v-else class="flex_between align_center">
            <div style="max-width: 200px" class="flex1">当前工程预决算总额</div>
            <div>
              <span class="blue_color">{{
                form.info.change_contract_amount ||
                form.info.change_contract_amount === 0
                  ? form.info.change_contract_amount
                  : '-'
              }}</span>
              元
            </div>
          </div>

          <div
            class="text_right mt_5"
            v-if="
              form.info.change_contract_amount ||
              form.info.change_contract_amount === 0
            "
          >
            {{
              form.info.change_contract_amount ||
              form.info.change_contract_amount === 0
                ? convertCurrency(form.info.change_contract_amount)
                : '-'
            }}
          </div>
          <div class="grey_color font_size_13 mt_15">
            工程预决算总额=原合同金额+从签施工合同至{{
              props.sub_type == 22
                ? '隐蔽'
                : props.sub_type == 23
                ? '中期'
                : props.sub_type == 24
                ? '尾期'
                : ''
            }}工程变更确认单金额
          </div>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有特殊事项请补充..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="date"
        title="选择日期"
        cancel-button-text="清除"
        @confirm="(v) => onConfirmDate(v)"
        @cancel="
          (v) => {
            state.showTimePicker = false;
            form.info.expect_end_time = null;
          }
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Popup, DatetimePicker } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
import { convertCurrency } from '@/utils/util';
import NP from 'number-precision';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);
const state = reactive({
  //-时间控件

  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;

  if (form.info.edit_change_amount != 1 && form.info.is_item_edit == 1) {
    form.info.change_amount = 0;
  }
  emit('update:data', form.info);
  changeMoney();
});
const changeMoney = () => {
  if (form.info.change_type == 1) {
    if (props.sub_type == 22 || form.info.change_amount_total != 0) {
      form.info.change_contract_amount = NP.plus(
        Number(form.info.change_amount_total),
        Number(form.info.change_amount)
      );
    }

    form.info.settled_amount = NP.minus(
      NP.plus(
        Number(form.info.payment_period),
        Number(form.info.change_amount)
      ),
      Number(form.info.received_amount)
    );
  } else if (form.info.change_type == 2) {
    if (props.sub_type == 22 || form.info.change_amount_total != 0) {
      form.info.change_contract_amount = NP.minus(
        Number(form.info.change_amount_total),
        Number(form.info.change_amount)
      );
    }

    form.info.settled_amount = NP.minus(
      NP.minus(
        Number(form.info.payment_period),
        Number(form.info.change_amount)
      ),
      Number(form.info.received_amount)
    );
  }
};

const formatter = (value) => {
  let val = '';
  if (value) {
    val = parseFloat(value).toFixed(2);
    if (val > 99999999.99) {
      val = 99999999.99;
    } else if (val < 0) {
      val = '';
    }
  }
  return val;
};
const formatter2 = (value) => {
  let val = '';
  if (value) {
    val = parseFloat(value).toFixed(2);
    if (val > Number(form.info.payment_period)) {
      val = form.info.payment_period;
    } else if (val < 0) {
      val = '';
    }
  }

  return val;
};

const radioClick = () => {
  changeMoney();
};
// @-时间控件
const openDateType = () => {
  state.showTimePicker = true;
  if (form.info.expect_end_time) {
    state.currentDate = new Date(form.info.expect_end_time);
  } else {
    state.currentDate = new Date();
  }
};
const onConfirmDate = (value) => {
  state.showTimePicker = false;
  form.info.expect_end_time = moment(value).format('YYYY-MM-DD');
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
