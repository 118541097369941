<!--
 * @Author: gest
 * @Date: 2022-05-17 13:24:22
 * @LastEditTime: 2023-03-28 17:37:58
 * @Description: 上传附件弹窗
 -->
<template>
  <Popup
    v-model:show="_DATA.showOverlay"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="wrapper" v-if="_DATA.showOverlay" @click.stop>
      <NavBar
        left-arrow
        title="上传附件"
        @click-left="onCloseOverLay"
        :fixed="true"
        :placeholder="true"
      ></NavBar>
      <div class="page-box middle-box">
        <Form class="form-box" ref="formRef">
          <div class="detail-box">
            <!-- 附件组件 -->
            <p class="label-name">附件</p>
            <FileUpload
              :data="_DATA.files"
              :type="_DATA.type"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
            <!-- end -->
          </div>
        </Form>
      </div>
      <div class="stick-box">
        <Button type="primary" size="large" @click="saveBtn">完成</Button>
      </div>
    </div>
  </Popup>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, watch, defineProps, defineEmits, onMounted } from 'vue';
import { Form, Popup, Button, NavBar } from 'vant';

const props = defineProps(['isShow', 'type']);
const emit = defineEmits(['closePop', 'update:func']);
const _DATA = reactive({
  showOverlay: props.isShow ? props.isShow : false,
  type: 2,
  files: [],
});

onMounted(() => {
  _DATA.files = [];
  _DATA.type = props.type ? props.type : 2;
});
watch(
  () => props.isShow,
  (cur) => {
    _DATA.showOverlay = cur ? cur : false;
    _DATA.files = [];
  }
);
// @附件
const getFileList = (files) => {
  _DATA.files = files;
};
const saveBtn = () => {
  emit('update:func', _DATA.files);
};
const onCloseOverLay = () => {
  emit('closePop');
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
.wrapper {
  width: 100%;
  height: 100%;
}
</style>
