<!--
 * @Author: smart
 * @Date:2023-11-04 11:38:42
 * @LastEditTime: 2023-11-04 14:05:55
 * @Description: V1.4.9选择供应商弹窗
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">选择供应商</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="state.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup v-model="form.checked" v-if="form.list.length">
        <cell v-for="item in form.list" :key="item.id">
          <div class="member_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="member_item">
                <p class="name">{{ item.name }}</p>
              </div>
            </Radio>
          </div>
        </cell>
      </RadioGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, onMounted, defineProps } from 'vue';
import { Button, Search, Cell, Empty, Radio, RadioGroup } from 'vant';
import _ from 'lodash';
const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['suppliers', 'check']);

const state = reactive({
  loading: true,
  kw: '',
  list: props.suppliers ? props.suppliers : [],
});
const form = reactive({
  checked: props?.check ? Number(props?.check) : '',
  checkedItem: {},
  list: props?.suppliers || [],
});
onMounted(() => {
  searchFun();
  // form.list = props.suppliers ? props.suppliers : []

  if (props?.check) {
    form.checkedItem = props?.suppliers?.filter(
      (item) => item.id == props?.check
    )?.[0];
  } else {
    form.checkedItem = {};
  }
});
//-搜索
const searchFun = () => {
  if (state.kw) {
    form.list = state.list.filter((i) => i.name.includes(state.kw));
  } else {
    form.list = _.cloneDeep(state.list);
  }
};

const getClick = (item) => {
  form.checked = item.id;
  form.checkedItem = item;
};
//-确认操作
const btnSumbit = () => {
  emit('reviewOk', form.checkedItem);
};

//-取消
const clear = () => {
  form.checkedItem = {};

  emit('reviewOk', form.checkedItem);
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
</style>
