<!--
 * @Author: NanNan
 * @Date: 2024-05-28 18:39:06
 * @LastEditTime: 2024-05-31 10:49:35
 * @Description: v1.6.1新版本 获客-协议：协议(循环遍历)
-->
<template>
  <div
    class="bj-grey"
    :class="{
      bj_gray: props?.padding === 'none' ? true : false,
      border_top: props?.padding === 'none' ? false : true,
      border_bottom: props?.padding === 'none' ? false : true,
    }"
    v-if="props?.data?.expanded && props?.data?.sub?.length > 0"
  >
    <!-- 标题区域 -->
    <div
      class="collapseItemTh border_bottom"
      v-if="!props?.padding"
      style="padding: 11px 10px 11px 15px"
    >
      <Row style="align-items: center; height: 100%">
        <Col span="5"><p class="grey">部门</p></Col>
        <Col span="3">
          <p class="grey align_center">目标</p>
        </Col>
        <Col span="4">
          <p class="grey align_center">完成/</p>
          <p class="grey align_center">差值</p>
        </Col>
        <Col span="4">
          <p class="grey align_center">见面率</p>
        </Col>
        <Col span="4">
          <p class="grey align_center">成功率</p>
        </Col>
        <Col span="4">
          <p class="grey align_center">线-签率</p>
        </Col>
      </Row>
    </div>
    <!-- end -->
    <div
      class="sub_item4"
      v-for="(sub, subIndex) in _DATA.list"
      :key="sub.name"
      @click.stop="handleClick(subIndex)"
    >
      <Row
        class="mb_20"
        style="align-items: center; height: 100%; margin: 0 10px 0 15px"
        :class="{
          border_top:
            subIndex !== 0 ||
            (props?.data?.expanded && props?.padding === 'none')
              ? true
              : false,
        }"
      >
        <!-- 部门、人员 -->
        <Col span="4">
          <div class="size13" style="display: flex; align-items: center">
            <template v-if="sub?.sub?.length > 0">
              <Icon
                name="play"
                :style="
                  sub?.expanded
                    ? 'transform: rotate(90deg)'
                    : 'transform: rotate(0deg)'
                "
                class="arrow"
              />
            </template>
            <p>{{ sub.short_name || sub.name }}</p>
          </div>
        </Col>
        <!-- 目标 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.target === '' ? '-' : sub.target }}
            </span>
          </div>
        </Col>
        <!-- 完成/差值 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.actual === '' ? '-' : sub.actual }}
            </span>
          </div>
          <div v-if="sub.gap !== ''">
            <div
              class="flex_line flex_line_center"
              v-if="sub.actual > sub.target"
            >
              <span class="green">
                {{ sub.gap }}
              </span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/up.png')"
                alt="down"
              />
            </div>
            <div
              class="flex_line flex_line_center"
              v-else-if="sub.actual < sub.target"
            >
              <span class="orange">
                {{ sub.gap }}
              </span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/down.png')"
                alt="down"
              />
            </div>
            <div class="flex_line flex_line_center" v-else>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/equal.png')"
                alt="down"
              />
            </div>
          </div>
        </Col>
        <!-- 见面率 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.meet_rate === '' ? '-' : sub.meet_rate + '%' }}
            </span>
          </div>
        </Col>
        <!-- 成功率 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.sign_rate === '' ? '-' : sub.sign_rate + '%' }}
            </span>
          </div>
        </Col>
        <!-- 线-签率 -->
        <Col span="4">
          <div>
            <span class="align_center">
              {{ sub.clue_sign_rate === '' ? '-' : sub.clue_sign_rate + '%' }}
            </span>
          </div>
        </Col>
      </Row>
      <!-- 递归 -->
      <Transition name="thred-fade">
        <TempAgreement
          v-if="sub.sub && sub.expanded"
          :data="sub"
          :padding="'none'"
        />
      </Transition>
    </div>
  </div>
</template>
<script setup>
import TempAgreement from '@/components/targetData/thredData/collapseItem/TempAgreement';
import { defineProps, reactive } from 'vue';
import { Row, Col, Icon } from 'vant';
const props = defineProps(['data', 'padding']);
const _DATA = reactive({
  list: props?.data?.sub,
});

const handleClick = (subIndex) => {
  console.log('????');
  if (_DATA.list[subIndex]?.children?.length == 0) {
    return;
  }
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
