<!--
 * @Author: gest
 * @Date: 2023-05-17 14:24:22
 * @LastEditTime: 2023-06-16 14:34:43
 * @Description: v1.3.8 自购订单列表
-->
<template>
  <div class="top_box">
    <div class="top_content">
      <!-- 头部搜索 -->
      <div class="header-box">
        <form action="/">
          <Search
            placeholder="搜索订单编号/产品大类/供应商"
            background="#f5f5f5"
            class="search-field"
            v-model="_DATA.parmas.kw"
            @search="onSearch"
            @clear="onClear"
            show-action
          >
            <template #action>
              <div @click="onSearch" style="color: #000">搜索</div>
            </template>
          </Search>
        </form>
      </div>
      <!-- end -->
    </div>
  </div>

  <div class="page-box">
    <!-- 内容开始 -->
    <List
      v-model:loading="loading"
      :finished="finished"
      @load="onLoad"
      class="list-box"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="cell-same"
        @click="linkTo(item)"
      >
        <div class="info_box">
          <div class="left icon_name">{{ item.supplier_name[0] }}</div>
          <div class="right">
            <div class="name_box">
              <p class="name" style="flex: 1">{{ item.supplier_name }}</p>

              <div
                class="status_tag"
                :class="
                  item.status == 1
                    ? 'grey'
                    : item.status == 3
                    ? 'red'
                    : item.status == 4
                    ? 'blue'
                    : item.status == 5
                    ? 'green'
                    : item.status == 6
                    ? 'grey'
                    : 'orange'
                "
              >
                {{ item.status_name }}
              </div>
            </div>
            <p class="info">
              <span class="t1">{{ item.category_name || '-' }}</span>
              <span class="t1">｜数量：{{ item.total_num || '0' }}</span>
            </p>
          </div>
        </div>

        <div class="progress">
          <div class="progreess_info">
            <div class="status">当前进度</div>
            <div class="progreess_name">{{ item.progress_name || '-' }}</div>
          </div>
          <Icon name="arrow" size="12" />
        </div>
      </div>
      <template v-if="list.length > 0" #finished>没有更多了</template>
    </List>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无数据"
      v-if="!loading && list.length === 0"
    />
    <!-- end -->
    <!-- list -->

    <div
      class="stick-box-small"
      v-if="allOperationAuthority.indexOf('461') > -1"
    >
      <Button
        type="primary"
        icon="plus"
        size="large"
        native-type="submit"
        color="#0256FF"
        plain
        style="border: none; font-size: 15px"
        @click="addProduct"
      >
        新增自购产品
      </Button>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, inject, reactive, computed } from 'vue';
import { Search, List, Notify, Empty, Icon, Button } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const $http = inject('$http');

const router = useRouter();
const _DATA = reactive({
  parmas: {
    kw: '',
    construction_id: router.currentRoute.value.query.gdid, // 工地id
  },
  page: {
    count_page: 1,
    page: 1,
    pages: 10,
    total: 0,
  },
});
onMounted(() => {});

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .mainSelfOrder_list({
      page: _DATA.page.page,
      pages: _DATA.page.pages,
      ..._DATA.parmas,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        _DATA.page = data.page;
        list.value = list.value.concat(data.list);
        if (data.page.page <= data.page.count_page) {
          _DATA.page.page++;
        } else {
          finished.value = true;
        }
      } else {
        loading.value = false;
        finished.value = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

/*
 * 操作
 */
//-@ 搜索
const onSearch = () => {
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
};

//-@ 清除
const onClear = () => {
  _DATA.parmas.kw = '';
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
};

//-@ 跳转
const linkTo = (item) => {
  router.push({
    path: '/mainSelfOrderDetail',
    query: {
      id: item.id,
      gdid: router.currentRoute.value.query.gdid,
      cid: router.currentRoute.value.query.id,
    },
  });
};
const addProduct = () => {
  router.push({
    path: '/addMainSelfOrder',
    query: {
      id: router.currentRoute.value.query.id,
      gdid: router.currentRoute.value.query.gdid,
    },
  });
};
</script>
<style lang="less" scoped>
.top_box {
  height: 56px;
  .top_content {
    position: fixed;
    width: 100%;
    z-index: 1;

    .header-box {
      :deep(.van-search__action) {
        padding: 0 15px;
      }
    }
  }
}

.page-box {
  padding: 0;
  height: calc(100% - 102px);
  .list-box {
    padding: 0 20px;
    padding-bottom: 60px;

    padding-bottom: calc(60px + constant(safe-area-inset-bottom));
    padding-bottom: calc(60px + env(safe-area-inset-bottom));

    .cell-same {
      border: none;

      width: 100%;
      height: 100%;
      border-bottom: 1px solid rgba(228, 228, 228, 0.3);
      padding: 14px 0;
      position: relative;
      .info_box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .icon_name {
          background: rgba(0, 137, 17, 0.1);
          color: rgba(0, 137, 17, 0.4);
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          line-height: 46px;
          width: 46px;
        }
        .left {
          .avader_img {
            width: 46px;
            height: 46px;
            border-radius: 4px;
            // border: 1px solid #efefef;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .right {
          flex: 1;
          padding-left: 12px;

          .name_box {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .name {
              font-size: 17px;
              font-weight: bold;
              line-height: 22px;
            }
          }
          .status4 {
            display: inline-block;
            border-radius: 2px;
            font-size: 12px;
            line-height: 20px;
            padding: 0 4px;
            background: @red-bg-color;
            color: @red-color;
          }
          .info {
            line-height: 24px;
            .t1 {
              font-size: 13px;
              color: #767676;
            }
          }
        }
      }

      .progress {
        margin-top: 10px;
        display: flex;
        border-radius: 2px;
        background: #fbfbfb;
        padding: 8px;
        justify-content: space-between;
        align-items: center;
        .progreess_info {
          display: flex;
          align-items: center;
          .status {
            display: inline-block;
            border-radius: 2px;
            font-size: 12px;
            font-weight: bold;
            padding: 1px 4px;
            background: @blue-bg-color;
            color: @blue-color;
            margin-right: 8px;
          }
          .progreess_name {
            font-size: 12px;

            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
