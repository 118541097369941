<!--
 * @Author: NanNan
 * @Date: 2024-05-11 10:24:10
 * @LastEditTime: 2024-05-29 14:06:30
 * @Description: v1.6.0新版本 获客-线索
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、桑基图 -->
        <div class="data_card_top">
          <div class="title" style="padding-bottom: 0">线索</div>
        </div>
        <div
          class="sangji-total flex-box border-bottom"
          style="font-size: 13px; line-height: 40px"
        >
          <div
            style="
              flex-grow: 0;
              flex-shrink: 0;
              width: calc(50vw - 52px);
              display: flex;
            "
          >
            <em class="grey">线索:</em>
            {{ _DATA.total?.clue_total }}
          </div>
          <div
            style="
              flex-grow: 0;
              flex-shrink: 0;
              width: calc(50vw - 52px);
              display: flex;
            "
          >
            <em class="grey">见面:</em>
            {{ _DATA.total?.meet_total }}
          </div>
          <div style="flex-grow: 1; flex-shrink: 1; display: flex">
            <em class="grey">签约:</em>
            {{ _DATA.total?.sign_total }}
          </div>
        </div>
        <div
          ref="chartDom"
          style="width: calc(100vw); height: 300px; overflow: hidden"
        ></div>
        <div class="border_bottom"></div>
      </Skeleton>
    </div>

    <!-- 展开模版引入 -->
    <div v-if="_DATA.open">
      <!-- 渠道线索数 -->
      <TempChannelClue :data="props.data" style="margin-top: 15px" />
      <!-- 线索质量 -->
      <TempChannelQuality :data="props.data" style="margin-top: 15px" />
      <!-- 渠道转化时间 -->
      <TempChannelTransformationTime
        :data="props.data"
        style="margin-top: 15px"
      />
    </div>

    <!-- 展开收起 -->
    <div
      v-if="!_DATA.loading"
      class="expend-new"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
      :class="_DATA.open ? 'grey' : 'white'"
    >
      <span>{{ _DATA.open ? '收起' : '展开查看详情' }}</span>
      <Icon
        name="play"
        style="transform: rotate(90deg)"
        size="14"
        :style="
          !_DATA.open ? 'transform: rotate(90deg)' : 'transform: rotate(-90deg)'
        "
      />
    </div>
  </div>
</template>
<script setup>
import * as echarts from 'echarts';

import TempChannelClue from '@/components/targetData/thredData/channelClue.vue';
import TempChannelQuality from '@/components/targetData/thredData/channelQuality';
import TempChannelTransformationTime from '@/components/targetData/thredData/channelTransformationTime';

import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  ref,
  nextTick,
} from 'vue';
import { Icon, Notify, Skeleton } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  list: [],
  total: {},
  index: props.data.depId ? 2 : 2, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  open: false,
  collapse_active: [], //锁定
  activeIndex: null,
  activeChildIndex: null,
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);

const chartDom = ref();

var option = {
  tooltip: {
    show: false,
    trigger: 'item',
    triggerOn: 'mousemove',
  },

  series: {
    type: 'sankey',
    layout: 'none',
    emphasis: {
      // focus: 'adjacency',// 禁用点击事件的高亮效果
    },
    levels: [
      {
        depth: 0,
        itemStyle: {
          color: '#fbb4ae',
        },
        lineStyle: {
          color: 'source',
          opacity: 0.2,
        },
      },
      {
        depth: 1,
        itemStyle: {
          color: '#b3cde3',
        },
        lineStyle: {
          color: 'source',
          opacity: 0.2,
        },
      },
    ],
    backgroundColor: '#000',
    layoutIterations: 64,
    nodeWidth: 10,
    nodeGap: 30,
    left: 20,
    right: 70,
    bottom: 20,
    edgeLabel: {
      show: true,
      offset: [30, 0], // 向左偏移 10 个像素
      formatter: function (params) {
        return '{black|' + params.data.percent + '%' + '}';
        // return (
        //   '{black|' +
        //   params.data.percent +
        //   '%' +
        //   '}' +
        //   '\n' +
        //   '{gray|' +
        //   '(' +
        //   params.data.day +
        //   ')' +
        //   '}'
        // );
      },
      rich: {
        black: {
          color: '#3D3D3D',
          fontWeight: 'bold',
          fontSize: 11,
          lineHeight: 13,
        },
        gray: {
          color: '#8D9091',
          fontSize: 11,
          lineHeight: 13,
        },
      },
    },
    draggable: false,
    label: {
      show: true,
      emphasis: {}, // 禁用高亮效果
      offset: [0, 0], // 向左偏移 10 个像素
      fontSize: 11, // 设置字体大小为 16 像素
      textBorderColor: 'transparent', // 设置标签文本的边框颜色为透明
      formatter: function (params) {
        var name = '';
        const nameArr = params.name.split('-');

        if (nameArr.length === 1) {
          name = '{black|' + nameArr[0] + ':' + params.value + '}';
        } else {
          name = '{black|' + nameArr[1] + ':' + params.value + '}';
        }
        return name;
      },
      rich: {
        black: {
          width: 70, // 设置宽度为 50 像素
          color: '#3D3D3D',
          fontWeight: 'bold',
          fontSize: 11,
          lineHeight: 13,
          // backgroundColor: '#eee', // 设置背景色
        },
        gray: {
          color: '#8D9091',
          fontSize: 11,
          lineHeight: 13,
        },
      },
    },
    data: [],
    links: [],
  },
};

onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载
const onLoad = () => {
  let url = $http.targetStatistic_channelSourceClueMeetSign;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.list = data.list;
      _DATA.total = data.total;
      nextTick(() => {
        if (chartDom.value) {
          formatData(data.list);
        }
      });
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

//-@ 数据变更
const color = ['#D158B0', '#B7D158', '#58C1D1', '#5874D1', '#D16858'];
const formatData = (data) => {
  const list = data || [];
  // const list = [data[0], data[1], data[4]] || [];
  var new_data = [];
  var new_link = [];
  list.forEach((element, index) => {
    // 塞入data
    var itemStyle = { color: color[index] };
    var grandfather = {
      name: element.name,
      value: element.clue_num,
      itemStyle,
    };
    var father = {
      name: element.name + '-见面',
      itemStyle,
    };
    var son = {
      name: element.name + '-签约',
    };
    new_data.push(grandfather, father, son);
    // 塞入links
    var father_link = {
      source: element.name,
      target: element.name + '-见面',
      value: element.meet_num,
      percent: element.meet_rate,
      // day: '21天',
    };

    var son_link = {
      source: element.name + '-见面',
      target: element.name + '-签约',
      value: element.sign_num,
      percent: element.sign_rate,
      // day: '8天',
    };
    new_link.push(father_link, son_link);
  });
  renderChart(new_data, new_link);
};

const renderChart = (data1, links1) => {
  if (chartDom?.value && myChart1?.value && option1) {
    myChart1.value.setOption(option1);
  } else {
    console.log(chartDom.value);
    var myChart1 = echarts.init(chartDom.value);
    var option1 = {};
    Object.assign(option1, {
      ...option,
      series: { ...option.series, data: data1, links: links1 },
    });
    myChart1.setOption(option1);
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
