/*
 * @Author: gest
 * @Date: 2022-10-12 11:27:39
 * @LastEditTime: 2022-10-12 11:27:39
 * @Description: 消息接口
 */
import Service from '@/utils/request';

export default {
  // 消息列表
  getMessageApi(parmas) {
    return Service.post(`/rs/sysMessage/list`, parmas);
  },
  // 未读消息
  getMessageCountApi(parmars) {
    return Service.post(`/rs/sysMessage/listCount`, parmars);
  },
};
