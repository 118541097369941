/*
 * @Author: NanNan
 * @Date: 2022-05-24 14:56:44
 * @LastEditTime: 2024-06-07 10:45:04
 * @Description: 施工相关
 */
//
import Service from '@/utils/request';

export default {
  /**
   * 施工日志 - 列表
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getConstructionLogApi(params) {
    return Service.post(`/rs/construction/listConstructionLog`, params);
  },
  /**
   * 施工日志 - 添加施工日志
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getAddConstructionCommentApi(params) {
    return Service.post(`/rs/construction/addConstructionComment`, params);
  },
  /**
   * 施工日志 - 删除施工日志
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getDelConstructionLogApi(params) {
    return Service.post(`/rs/construction/delConstructionLog`, params);
  },
  /**
   * 施工日志 - 删除评论
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getDelConstructionCommentApi(params) {
    return Service.post(`/rs/construction/delConstructionComment`, params);
  },
  /**
   * 施工日志 - 设置前端是否可见
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSetFrontFlagApi(params) {
    return Service.post(`/rs/construction/setFrontFlag`, params);
  },
  /**
   * 施工日志 - 获取施工节点列表
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getConstructionNodeListApi(params) {
    return Service.post(`/rs/construction/nodeList`, params);
  },
  /**
   * 施工日志 - 新建、编辑
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getEditConstructionLogApi(params) {
    return Service.post(`/rs/construction/editConstructionLog`, params);
  },

  /**
   * 施工日志 - 编辑详情页
   */
  getInfoConstructionLogApi(params) {
    return Service.post(`/rs/construction/infoConstructionLog`, params);
  },
  /**
   * 施工 - 公共参数
   */
  getCustomerServiceParameterApi(params) {
    return Service.post(`/rs/public/customerServiceParameter`, params);
  },
  /**
   * 施工 - 回访详情
   */
  getCustomerServiceVisitInfoApi(params) {
    return Service.post(`/rs/customerService/visitInfo`, params);
  },
  /**
   * 施工 - 新增回访
   */
  getCustomerServiceAddVisitApi(params) {
    return Service.post(`/rs/customerService/addVisit`, params);
  },
  /**
   * 施工 - 定期回访
   */
  getCustomerServiceRegularVisitApi(params) {
    return Service.post(`/rs/customerService/regularVisit`, params);
  },
  /**
   * 施工 - 验收回访
   */
  getCustomerServiceInspectedVisitApi(params) {
    return Service.post(`/rs/customerService/inspectedVisit`, params);
  },
  /**
   * 施工 - 通知验收
   */
  getConNoticeInspectedApi(params) {
    return Service.post(`/rs/construction/noticeInspected`, params);
  },
  /**
   * 施工 - 确认验收
   */
  getConConfirmInspectedApi(params) {
    return Service.post(`/rs/construction/confirmInspected`, params);
  },
  /**
   * 施工 - 标记完成
   */
  getConTagOverApi(params) {
    return Service.post(`/rs/construction/tagOver`, params);
  },

  // 客户服务回访列表
  getVisitListApi(parmas) {
    return Service.post(`/rs/customerService/visitList`, parmas);
  },
  //客户投诉列表
  getComplaintListApi(parmas) {
    return Service.post(`/rs/customerService/complaintList`, parmas);
  },
  //新增编辑投诉
  complaintEdit(parmas) {
    return Service.post(`/rs/customerService/complaintEdit`, parmas);
  },
  //投诉详情
  getComplaintInfoApi(parmas) {
    return Service.post(`/rs/customerService/complaintInfo`, parmas);
  },
  //客户售后/报修列表
  getAfterSaleListApi(parmas) {
    return Service.post(`/rs/customerService/afterSaleList`, parmas);
  },
  //新增编辑售后
  editAfterSale(parmas) {
    return Service.post(`/rs/customerService/editAfterSale`, parmas);
  },
  //售后详情
  getAfterSaleInfoApi(parmas) {
    return Service.post(`/rs/customerService/afterSaleInfo`, parmas);
  },
  //客户质保单列表
  getWarrantyListApi(parmas) {
    return Service.post(`/rs/customerService/warrantyList`, parmas);
  },
  //开质保单
  addWarranty(parmas) {
    return Service.post(`/rs/customerService/addWarranty`, parmas);
  },
  //申请客户结算
  applyCustomerSettlement(parmas) {
    return Service.post(`/rs/construction/applyCustomerSettlement`, parmas);
  },
  //工地整改单列表
  listConstructionModifyItem(parmas) {
    return Service.post(`/rs/construction/listConstructionModifyItem`, parmas);
  },
  //工地整改单详情
  constructionModifyItemInfo(parmas) {
    return Service.post(`/rs/construction/constructionModifyItemInfo`, parmas);
  },
  //删除工地整改单
  delConstructionModifyItem(parmas) {
    return Service.post(`/rs/construction/delConstructionModifyItem`, parmas);
  },
  //变更整改单状态
  updateConstructionModifyItemStatus(parmas) {
    return Service.post(
      `/rs/construction/updateConstructionModifyItemStatus`,
      parmas
    );
  },
  // 施工节点列表
  getConstructionNodeList(parmas) {
    return Service.post(`/rs/construction/nodeList`, parmas);
  },
  // 开工地整改单
  editConstructionModifyItem(parmas) {
    return Service.post(`/rs/construction/editConstructionModifyItem`, parmas);
  },

  //工期变更单列表
  listConstructionModifyScheduler(parmas) {
    return Service.post(
      `/rs/construction/listConstructionModifyScheduler`,
      parmas
    );
  },
  //开工期变更单
  editConstructionModifyScheduler(parmas) {
    return Service.post(
      `/rs/construction/editConstructionModifyScheduler`,
      parmas
    );
  },
  //变更工期变更单状态
  setSchedulerStatus(parmas) {
    return Service.post(`/rs/construction/setSchedulerStatus`, parmas);
  },
  //工期变更单详情
  constructionModifySchedulerInfo(parmas) {
    return Service.post(
      `/rs/construction/constructionModifySchedulerInfo`,
      parmas
    );
  },
  //工地公共参数接口
  constructionPublicParams(parmas) {
    return Service.post(`/rs/construction/publicParams`, parmas);
  },
  //工地详情
  constructionInfo(parmas) {
    return Service.post(`/rs/construction/info`, parmas);
  },
  //提前开始
  constructionStrartNowInfo(parmas) {
    return Service.post(`/rs/construction/startNow`, parmas);
  },
  // 施工节点上传 附件
  constructionUploadFile(params) {
    return Service.post(`/rs/construction/uploadFile`, params);
  },

  //- @确认开工
  getConstructionStart(params) {
    return Service.post(`/rs/construction/start`, params);
  },
  //- @申请施工开工
  getApplyConstructionStart(params) {
    return Service.post(`/rs/contract/applyConstructionStart`, params);
  },

  //- @模板列表
  getConstructionTemplateList(params) {
    return Service.post(`/rs/construction/getTemplateList`, params);
  },
  //节点操作记录
  getConstructionNodeLogs(params) {
    return Service.post(`/rs/construction/nodeLogs`, params);
  },
  //V1.3.3-申请精装工地
  applyFineConstruction(params) {
    return Service.post(`/rs/construction/applyFineConstruction`, params);
  },
  //V1.3.4全部工地列表
  constructionList(params) {
    return Service.post(`/rs/construction/constructionList`, params);
  },
  //工地停工
  construction_stop(params) {
    return Service.post(`/rs/construction/stop`, params);
  },
  //工地重启
  construction_restart(params) {
    return Service.post(`/rs/construction/restart`, params);
  },
  //工程&产品变更保存
  construction_changeConfirmSave(params) {
    return Service.post(`/rs/construction/changeConfirmSave`, params);
  },
  //获取节点工程&产品变更确认
  construction_getNodeParams(params) {
    return Service.post(`/rs/construction/getNodeParams`, params);
  },

  //V1.4.9节点产品下单列表
  construction_nodeOrderList(params) {
    return Service.post(`/rs/construction/nodeOrderList`, params);
  },
  //节点产品下单保存、确认
  construction_nodeOrderConfirm(params) {
    return Service.post(`/rs/construction/nodeOrderConfirm`, params);
  },
  //  V1.5.0节点产品到货验收订单列表
  construction_nodeOrderReceiptList(params) {
    return Service.post(`/rs/construction/nodeOrderReceiptList`, params);
  },
  //  V1.5.0节点产品验收确认
  construction_nodeOrderReceiptConfirm(params) {
    return Service.post(`/rs/construction/nodeOrderReceiptConfirm`, params);
  },
  //  V1.5.3 派单详情
  constructionDispatch_info(params) {
    return Service.post(`/rs/constructionDispatch/info`, params);
  },
  //  V1.5.3 派单信息完善
  constructionDispatch_save(params) {
    return Service.post(`/rs/constructionDispatch/save`, params);
  },
  //  V1.6.1根据延期天数获取完工时间
  construction_getOriginalDoneTime(params) {
    return Service.post(`/rs/construction/getOriginalDoneTime`, params);
  },
  //  V1.6.1客户项目成员
  construction_projectMembers(params) {
    return Service.post(`/rs/construction/projectMembers`, params);
  },
  //标记完成-详情
  construction_nodeInfo(params) {
    return Service.post(`/rs/construction/nodeInfo`, params);
  },
};
