<!--
 * @Author: Jessica
 * @Date: 2024-05-28 14:00:00
 * @LastEditTime: 2024-06-14 11:07:41
 * @Description:V1.6.1 采购供应商
-->
<template>
  <div class="content-box">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="detail_box" v-for="item in _DATA.info" :key="item.id">
      <div class="top_box">
        <div class="left">
          <lazy-component>
            <Image
              class="avader_img"
              v-if="item.face_image"
              :src="
                item.face_image.includes('http')
                  ? item.face_image
                  : $host + item.face_image
              "
              v-lazy
              fit="cover"
              round
            />
            <img
              class="avader_img"
              :src="require('@/assets/images/icon-empty-img.png')"
              v-else
            />
          </lazy-component>
        </div>
        <div class="right">
          <p class="full_name">{{ item.name }}</p>
          <p class="name">{{ item.full_name }}</p>
        </div>
      </div>
      <div class="dec_box">
        <div class="dec_item">
          <div class="right">
            负责人：{{ item.responsible_name + '·' + item.responsible_phone }}
          </div>
        </div>
        <div class="dec_item">
          <div class="right">
            合同大类：{{ item.product_category_name || '-' }}
          </div>
        </div>
        <div class="dec_item">
          <div class="right">
            合作品牌：{{ item.product_brand_name || '-' }}
          </div>
        </div>
      </div>
    </div>

    <!-- 空数据 -->
    <div class="empty-box" v-if="!_DATA.loading && _DATA.info?.length == 0">
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, onMounted } from 'vue';
import { Notify, Image, Overlay, Loading } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const $http = inject('$http');
const $host = inject('$host');

const _DATA = reactive({
  loading: false,
  info: [],
});
onMounted(() => {
  loadInfo();
});
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .construction_projectMembers({
      customer_id: router.currentRoute.value.query.id,
    })
    .then((res) => {
      _DATA.loading = false;
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data.supplier_list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
</script>
<style lang="less" scoped>
.content-box {
  padding: 10px 0 0;
  background: #f5f5f5;
}
.detail_box {
  background-color: @white-color;
  padding: 0 15px;
  margin-bottom: 10px;
  .top_box {
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .left {
      .avader_img {
        width: 40px;
        height: 40px;
      }
    }
    .right {
      flex: 1;
      padding-left: 12px;
      .full_name {
        font-size: 15px;
        line-height: 24px;
      }
      .name {
        font-size: 13px;
        color: #888888;
      }
    }
  }
  .dec_box {
    padding: 8px 0;
    border-top: 1px solid @line-color;
    .dec_item {
      display: flex;
      align-items: top;
      padding: 4px 0;
      font-size: 14px;
      color: #888888;
      line-height: 20px;
      > .right {
        flex: 1;
      }
    }
  }
}

.empty-box {
  height: calc(100vh - 150px);
  padding: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #979797;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 400;
    margin-top: 15px;
  }
}
</style>
