<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2023-09-06 11:25:22
 * @Description: 
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计启动时间
            </label>
            <Field
              v-model="form.estimated_commencement_time"
              right-icon="underway-o"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择预计启动时间',
                },
              ]"
              placeholder="预计启动时间"
              @click="openDateType('begin')"
            />
            <Popup v-model:show="state.showTimePicker" position="bottom">
              <DatetimePicker
                v-model="state.currentDate"
                type="date"
                title="选择日期"
                :min-date="state.minDate"
                cancel-button-text="清除"
                @confirm="onConfirm"
                @cancel="onRemoveDate"
              />
            </Popup>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计完成时间
            </label>
            <Field
              v-model="form.expected_completion_time"
              right-icon="underway-o"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择预计完成时间',
                },
              ]"
              placeholder="预计完成时间"
              @click="openDateType('end')"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期
            </label>
            <Field
              v-model="form.schedule"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的工期',
                  validator: validatorDay,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工期"
            >
              <template #button>
                <text>天</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积
            </label>
            <Field
              v-model="form.house_size"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的房屋面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写房屋面积"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="extra_operate" v-if="state.contractType == 51">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <Icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <Field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in state.signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <Field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></Field>
          </div>
        </CellGroup>
      </Form>
    </div>
    <!-- 完善客户基础信息 -->
    <Popup
      v-model:show="state.complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="state.complete_card.complete_card_Type == 'base'"
        :data="state.complete_card"
        @reviewClose="
          () => {
            state.complete_card_show = false;
            state.complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </Popup>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  inject,
  defineProps,
  defineExpose,
  onMounted,
} from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  DatetimePicker,
  Icon,
  Notify,
} from 'vant';
import { Toast } from 'vant';
import moment from 'moment';
import patterns from '@/utils/pattern';
import SaleAddMorePage from '@/components/sale/addMore';
const $http = inject('$http');
const props = defineProps([
  'contractType',
  'customer_id',
  'data',
  'parameterListForCustomer',
]);
const state = reactive({
  contractType: props.contractType,
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  //-合同签署方
  signatoryList: [
    { id: 1, name: '个体' },
    { id: 2, name: '企业' },
  ],
  //-完善信息
  complete_card: {
    //-客户Id
    customer_id: props.customer_id ? props.customer_id : '',
    complete_card_Type: '',
  },
  complete_card_show: false,
});

onMounted(() => {
  // 工期自动计算
  if (
    props.data.estimated_commencement_time &&
    props.data.expected_completion_time
  ) {
    getCountAuto();
  }
});
//--表单---------------------
/**
 * 校验方式
 */
const { houseSizeReg } = patterns;
//-房屋面积
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;
//-工期
const validatorDay = (val) =>
  val ? (parseFloat(val) <= 999 ? true : false) : true;

const ruleFormRef = ref(null);
const form = reactive({
  estimated_commencement_time: props.data.estimated_commencement_time
    ? props.data.estimated_commencement_time
    : '',
  expected_completion_time: props.data.expected_completion_time
    ? props.data.expected_completion_time
    : '',
  schedule: props.data.schedule ? props.data.schedule : '',
  house_size: props.data.house_size
    ? props.data.house_size
    : props.parameterListForCustomer.house_size
    ? props.parameterListForCustomer.house_size
    : '',
  signatory: props.data.signatory ? props.data.signatory : '',
  company_name: props.data.company_name ? props.data.company_name : '',
  //-V0.6.0 默认选择本人
  executor_type: 1,
  //-待完善条数-本人
  customer_other_num: props.parameterListForCustomer.customer_other_num
    ? props.parameterListForCustomer.customer_other_num
    : 0,
});
//-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (type === 'begin') {
    if (form.estimated_commencement_time) {
      state.currentDate = new Date(form.estimated_commencement_time);
    } else {
      state.currentDate = new Date();
    }
  }
  if (type === 'end') {
    if (form.expected_completion_time) {
      state.currentDate = new Date(form.expected_completion_time);
    } else {
      state.currentDate = new Date();
    }
  }
};
const onConfirm = (value) => {
  state.showTimePicker = false;
  if (state.timeType === 'begin') {
    form.estimated_commencement_time = moment(value).format('YYYY-MM-DD');
  } else if (state.timeType === 'end') {
    form.expected_completion_time = moment(value).format('YYYY-MM-DD');
  }
  //-预计开始/结束时间同时存在-自动计算工期
  if (form.estimated_commencement_time && form.expected_completion_time) {
    getCountAuto();
  }
};
const onRemoveDate = () => {
  state.showTimePicker = false;
  if (state.timeType === 'begin') {
    form.estimated_commencement_time = '';
  } else if (state.timeType === 'end') {
    form.expected_completion_time = '';
  }
  //-清除预计开始/结束时间--清空工期
  form.schedule = '';
};
//-@选择合同执行人---begin

//-完善合同执行人信息
const getHandelFun = (type) => {
  state.complete_card_show = true;
  state.complete_card.complete_card_Type = type;
};
//-完善合同执行人、委托人信息操作
const reviewOk = () => {
  if (props.customer_id) {
    getCustomerParameter(props.customer_id);
  }
};
//-获取客户参数
const getCustomerParameter = (id) => {
  state.complete_card_show = false;
  state.complete_card.complete_card_Type = '';
  $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
    if (res.code === 0) {
      form.customer_other_num = res.data.customer_other_num;
    } else {
      Toast(res.data);
    }
  });
};
//-@选择合同执行人---end

defineExpose({ form, ruleFormRef });

//--基本信息end单---------------------

//-工期自动计算----begin
const getCountAuto = () => {
  $http
    .getContractScheduleByTime({
      customer_id: props.customer_id ?? '',
      contract_type: state.contractType,
      start_date: form.estimated_commencement_time,
      end_date: form.expected_completion_time,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        form.schedule = data.schedule;
      } else {
        form.schedule = '';
        Notify({ type: 'warning', message: data });
      }
    });
};
//-工期自动计算-----end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
