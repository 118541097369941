<!--
 * @Author: gest
 * @Date:  2022-08-23 15:17:32
 * @LastEditTime: 2022-09-01 14:51:42
 * @Description: 分配服务人员
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              主创设计师
            </label>
            <Field
              v-model="form.list.desginer_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择主创设计师"
              :rules="[
                {
                  required: true,
                  message: '请选择主创设计师',
                },
              ]"
              @click="state.showPicker_desginer = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              客户经理
            </label>
            <Field
              v-model="form.list.manager_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择客户经理"
              :rules="[
                {
                  required: true,
                  message: '请选择客户经理',
                },
              ]"
              @click="state.showPicker_manager = true"
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-设计师 --->
    <Popup v-model:show="state.showPicker_desginer" position="bottom">
      <Picker
        :columns="state.desginerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'desginer')"
        @cancel="state.showPicker_desginer = false"
      />
    </Popup>
    <!--picker-客户经理 --->
    <Popup v-model:show="state.showPicker_manager" position="bottom">
      <Picker
        :columns="state.managerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'manager')"
        @cancel="state.showPicker_manager = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //待办id
  id: router.currentRoute.value.query ? router.currentRoute.value.query.id : '',
  //-客户经理-picker
  showPicker_manager: false,
  managerList: [],
  //-设计师-picker
  showPicker_desginer: false,
  desginerList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    form['list'][type] = value.id;
    form['list'][type + '_name'] = value.name;
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  await $http.customerAssignServicePersonParam().then((res) => {
    if (res.code === 0) {
      state.managerList = res.data.customer_manager;
      state.desginerList = res.data.design;
    } else {
      Toast(res.data);
    }
  });
};
//-获取参数--end
//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    manager: '',
    manager_name: '',
    desginer: '',
    desginer_name: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .designCenterServicePerson({
          todo_id: Number(state.id),
          design_id: form.list.desginer,
          customer_manager_id: form.list.manager,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                // if (state.taskDate) {
                //   router.push('/taskDay?date=' + state.taskDate);
                // } else {
                //   router.push('/sale?id=' + form.list.customer_id);
                // }
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
