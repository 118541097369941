/*
 * @Author: NanNan
 * @Date: 2022-04-28 12:29:10
 * @LastEditTime: 2023-06-09 09:50:10
 * @Description: 合同收款 - 相关
 */
import Service from '@/utils/request';

export default {
  // @合同内收款
  getContractCollectionApi(parmas) {
    return Service.post(`/rs/contractCollection/contractCollection`, parmas);
  },
  // @合同收款 - 款期详情
  getContractCollectionPaymentInfoApi(parmas) {
    return Service.post(`/rs/contractCollection/paymentInfo`, parmas);
  },
  // @合同外收款
  getContractOutsideApi(parmas) {
    return Service.post(`/rs/contractCollection/contractOutside`, parmas);
  },
  // @合同客户退款相关参数
  getContractRefundParameter(parmas) {
    return Service.post(`/rs/public/contractRefundParameter`, parmas);
  },
  // @合同内退款
  getsContractRefundApi(parmas) {
    return Service.post(`/rs/contractCollection/contractRefund`, parmas);
  },
  // @合同外退款
  getContractOutsideRefundApi(parmas) {
    return Service.post(`/rs/contractCollection/contractOutsideRefund`, parmas);
  },
  // @转款-合同内
  getContractTransferApi(parmas) {
    return Service.post(`/rs/contractCollection/contractTransfer`, parmas);
  },
  // @转款-合同外转合同内
  getContractTransferRefundApi(parmas) {
    return Service.post(
      `/rs/contractCollection/contractTransferRefund`,
      parmas
    );
  },
  // @易签保-发起合同
  getContractPostESignApi(parmas) {
    return Service.post(`/rs/contractManagement/postESign`, parmas);
  },
  // @增减项收款
  getContractContractContainApi(parmas) {
    return Service.post(`/rs/contractCollection/contractContain`, parmas);
  },
  // @增减项退款
  getContractContractContainRefundApi(parmas) {
    return Service.post(`/rs/contractCollection/contractContainRefund`, parmas);
  },
  // @返款
  getContractBackApi(parmas) {
    return Service.post(`/rs/contractCollection/contractBack`, parmas);
  },
  // @产品预收款
  getProductCollectionApi(parmas) {
    return Service.post(`/rs/contractCollection/productCollection`, parmas);
  },
  // @预收/收/退款参数-产品预收款
  getContractPaymentParams(parmas) {
    return Service.post(`/rs/contractCollection/getPaymentParams`, parmas);
  },
};
