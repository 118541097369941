<!--
 * @Author: Jessica
 * @Date: 2023-03-21 15:50:58
 * @LastEditTime: 2024-06-14 11:18:47
 * @Description: v1.3.4  设计板块------见证件填写&验收
 * @Description: v1.4.5  设计板块------供应商&验收流
-->
<template>
  <div
    class="page-box"
    :class="[
      {
        btnBig:
          state.detail.can_check == 1 ||
          state.detail.can_submit == 1 ||
          state.detail.can_start == 1,
      },
      {
        prop: props.data,
      },
    ]"
  >
    <Overlay :show="state.loading || state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <div class="detail" v-if="!state.loading">
      <!-- 见证件基础详情 begin-->
      <div class="base_info">
        <p class="name">{{ state.detail.name || '-' }}</p>
        <p>
          客户：{{ state.detail.customer_name || '-' }}
          <span class="ml_10" v-if="state.detail.size">
            {{ state.detail.estate_str + ' ' + state.detail.size + '㎡' }}
          </span>
          <span class="ml_10" v-else>
            {{ state.detail.estate_str }}
          </span>
        </p>
        <p>设计节点：{{ state.detail.node_name || '-' }}</p>
      </div>
      <!-- 见证件基础详情 end-->

      <!-- 详情表单 begin-->
      <Form ref="ruleFormRef">
        <!-- sub_type
        1通用 2概念方案预演会 3效果方案预演会 4产品方案预演会 5施工方案预演会  -->

        <!-- 通用附件 begin-->
        <commonWitness
          v-if="state.detail.sub_type == 1"
          :data="form.form_info"
          :can_edit="state.detail.can_edit"
          @update:data="updateForm"
        ></commonWitness>
        <!-- 通用附件 end-->

        <!-- 概念方案预演会 begin-->
        <WitnessEditGNFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 2 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 概念方案预演会详情 begin-->
        <WitnessDetailGNFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 2 && state.detail.can_edit == 0"
          @update:data="updateForm"
        />
        <!-- 效果方案预演会表单 begin-->
        <WitnessEditXGFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 3 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 效果方案预演会表单详情 begin-->
        <WitnessDetailXGFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 3 && state.detail.can_edit == 0"
          @update:data="updateForm"
        />

        <!-- 产品方案预演会表单 begin-->
        <WitnessEditCPFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 4 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 产品方案预演会表单详情 begin-->
        <WitnessDetailCPFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 4 && state.detail.can_edit == 0"
          @update:data="updateForm"
        />

        <!-- 施工方案预演会表单 begin-->
        <WitnessEditSGFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 5 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 施工方案预演会表单详情 begin-->
        <WitnessDetailSGFAYY
          :data="form.form_info"
          v-if="state.detail.sub_type == 5 && state.detail.can_edit == 0"
          @update:data="updateForm"
        />
        <!--  end-->
      </Form>
      <!-- 详情表单 end-->

      <!-- 供应商选择 begin-->
      <div
        class="supplier_list"
        v-if="
          state.detail.can_submit == 1 && state.detail.has_supplier_role == 1
        "
      >
        <!-- 选择供应商按钮 -->
        <div class="supplier_radio mb_15">
          <!-- 标题 -->
          <p class="title b_b">
            <span class="red">*</span>是否有公司合作供应商确认
          </p>
          <!-- 按钮 -->
          <RadioGroup v-model="checkSupplierForm.supplier_type">
            <div
              class="supplier_item"
              v-for="item in options.supplier_type"
              :key="item.id"
            >
              <Radio :name="item.id" @click.stop="handleSingleBtn(item)">
                <p>{{ item.name }}</p>
              </Radio>
            </div>
          </RadioGroup>
          <!-- 报错提示 -->
          <p
            class="error_text"
            v-if="checkSupplierForm.supplier_type === '' && state.isValiate"
          >
            请选择是否选择供应商
          </p>
        </div>
        <!-- 提示信息 -->
        <p class="tip_text" v-if="checkSupplierForm.supplier_type == 1">
          提交见证件前请先选择需确认供应商，供应商确认不分先后，同时确认！
        </p>
        <!-- 已选供应商 -->
        <div
          class="supplier_checked mb_15"
          v-if="checkSupplierForm.supplier_type == 1"
        >
          <p class="title1"><span class="red">*</span>供应商</p>
          <!-- 供应商缩略 有数据-->
          <div
            class="top_checked"
            v-if="checkSupplierForm.supplier_list?.length"
          >
            <div class="list" v-if="checkSupplierForm.supplier_list?.length">
              <div class="item">
                <p v-for="i in checkSupplierForm.supplier_list" :key="i.id">
                  <span>{{ i.name }}</span>
                  <span @click.stop="delSupFun(i.id)">
                    <Icon name="cross" class="icon_12" />
                  </span>
                </p>
              </div>
            </div>
            <span @click.stop="selectSupFun()">
              <Icon name="arrow" class="icon_16" />
            </span>
          </div>
          <!-- 供应商缩略 无数据-->
          <div
            class="top_checked flex_around"
            v-else
            @click.stop="selectSupFun()"
          >
            <div class="no_text">
              <p class="tip">请选择供应商</p>
              <!-- 报错提示 -->
              <p class="error_text" v-if="state.isValiate">请选择供应商</p>
            </div>
            <Icon name="arrow" class="icon_16" />
          </div>
          <!-- 供应商详细 -->
          <div class="bottom_checked">
            <div
              class="item"
              v-for="i in checkSupplierForm.supplier_list"
              :key="i.id"
            >
              <div class="line">
                <!-- 供应商logo -->
                <Image
                  class="imgIcon"
                  v-if="i.logo"
                  :src="i.logo.includes('http') ? i.logo : $host + i.logo"
                  fit="cover"
                  position="center"
                />
                <span v-else class="pull-left logo">
                  {{ i.name ? i.name.substr(0, 1) : '' }}
                </span>
                <!-- end -->
                <div class="info">
                  <p class="n1">{{ i.name }}</p>
                  <p>
                    <span class="n2">{{ i.responsible_name }}</span>
                    <span class="n2">/{{ i.responsible_phone }}</span>
                  </p>
                </div>
              </div>
              <p @click.stop="delSupFun(i.id)">
                <Icon name="cross" class="icon_16" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 供应商选择 end-->

      <!-- 流程 begin-->
      <witnessLinker
        :data="{
          check_info: state.check_info,
          type: 1,
          witness_id: state.witness_id,
          node_id: state.node_id,
        }"
      >
      </witnessLinker>
      <!-- 流程  end-->

      <!--审批操作 begin-->
      <div
        class="operate_box"
        v-if="
          state.detail.can_check == 1 ||
          state.detail.can_submit == 1 ||
          state.detail.can_start == 1
        "
      >
        <!-- 
          发起人：内容：【查看表单】、操作：【发起验收、取消】
          提交人：内容：【编辑表单】、操作：【保存、提交/重新提交、驳回、取消】
          确认人：内容：【查看表单、备注、通过/不通过】、操作：【取消、确认】 
          -->
        <!-- 发起人：发起验收 begin-->
        <!--@ 发起备注 -->
        <div class="mb_20" v-if="state.detail.can_start == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- 操作 -->
        <Row v-if="state.detail.can_start == 1">
          <Col span="24">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="editStart()"
            >
              发起验收
            </Button>
          </Col>
        </Row>
        <!-- end -->

        <!--提交人  begin-->

        <!--@ 提交备注 -->
        <div class="mb_20" v-if="state.detail.can_submit == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- 操作:判断有无发起人 -->
        <Row
          v-if="state.detail.can_submit == 1 && state.detail.has_starter == 1"
        >
          <Col span="8" class="p_7">
            <Button
              type="default"
              size="large"
              class="button-state"
              @click="editSave()"
            >
              保存
            </Button>
          </Col>
          <Col span="8" class="p_7">
            <Button
              type="danger"
              size="large"
              class="button-state"
              @click="editRejust()"
            >
              驳回
            </Button>
          </Col>
          <Col span="8" class="p_7">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnSumbit()"
            >
              提交
            </Button>
          </Col>
        </Row>
        <Row
          v-if="state.detail.can_submit == 1 && state.detail.has_starter == 0"
        >
          <Col span="12" class="p_7">
            <Button
              type="default"
              size="large"
              class="button-state"
              @click="editSave()"
            >
              保存
            </Button>
          </Col>

          <Col span="12" class="p_7">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnSumbit()"
            >
              提交
            </Button>
          </Col>
        </Row>
        <!-- end -->

        <!-- 验收 begin -->
        <!-- @验收备注  -->
        <div class="mb_20" v-if="state.detail.can_check == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- @验收操作 -->
        <Row v-if="state.detail.can_check == 1">
          <Col span="11">
            <Button
              type="danger"
              size="large"
              class="button-danger"
              @click.stop="btnCheckSumbit(4)"
            >
              <Icon name="cross" />
              不通过
            </Button>
          </Col>
          <Col span="2"></Col>
          <Col span="11">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click.stop="btnCheckSumbit(3)"
            >
              <Icon name="success" />
              通过
            </Button>
          </Col>
        </Row>
      </div>
      <!-- end -->
    </div>

    <!-- 组件集合 -->
    <div>
      <!-- 供应商选择组件 -->
      <Popup
        v-model:show="state.supplierOption.show"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '80%' }"
      >
        <SupplierList
          v-if="state.supplierOption.show"
          :data="state.supplierOption.list"
          :company_id="state.supplierOption.company_id"
          @reviewClose="reviewClose"
          @reviewOk="reviewOk"
        />
      </Popup>
    </div>
    <!-- end -->
  </div>
</template>

<script setup>
import {
  reactive,
  inject,
  onMounted,
  ref,
  defineProps,
  defineEmits,
} from 'vue';
import {
  Field,
  Row,
  Col,
  Icon,
  Button,
  Toast,
  Loading,
  Form,
  Overlay,
  Notify,
  Dialog,
  RadioGroup,
  Radio,
  Popup,
  Image,
} from 'vant';

import { useRouter } from 'vue-router';

import witnessLinker from '@/components/witness/witnessLinker';
import commonWitness from '@/components/witness/commonWitness';
import SupplierList from '@/components/witness/supplierList'; //-供应商选择列表
import WitnessEditCPFAYY from '@/components/witness/desgin/WitnessEditCPFAYY'; //产品方案预演会表单
import WitnessEditXGFAYY from '@/components/witness/desgin/WitnessEditXGFAYY'; //效果方案预演会表单
import WitnessEditGNFAYY from '@/components/witness/desgin/WitnessEditGNFAYY'; //概念方案预演会表单
import WitnessEditSGFAYY from '@/components/witness/desgin/WitnessEditSGFAYY'; //施工方案预演会表单
// import WitnessEditSGFAYY from '@/components/witness/site/cdjjdWitness';
import WitnessDetailCPFAYY from '@/components/witness/desgin/WitnessDetailCPFAYY'; //产品方案预演会表单详情
import WitnessDetailXGFAYY from '@/components/witness/desgin/WitnessDetailXGFAYY'; //效果方案预演会表单详情
import WitnessDetailGNFAYY from '@/components/witness/desgin/WitnessDetailGNFAYY'; //概念方案预演会表单详情
import WitnessDetailSGFAYY from '@/components/witness/desgin/WitnessDetailSGFAYY'; //施工方案预演会表单详情
// import WitnessDetailSGFAYY from '@/components/witness/site/cdjjdWitnessDetail';
const props = defineProps(['data']);
const emit = defineEmits(['reviewOk']);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const state = reactive({
  node_id: props.data
    ? props.data.node_id
    : router.currentRoute.value.query.node_id,
  witness_id: props.data
    ? props.data.witness_id
    : router.currentRoute.value.query.witness_id,
  loading: false,
  loadingConfirm: false,
  detail: {},
  check_info: [],
  //-供应商组件
  isValiate: false,
  supplierOption: {
    show: false,
    list: [],
    company_id: '',
  },
});

//--基本信息表单-------begin-----
const ruleFormRef = ref(null);
const form = reactive({
  form_info: null,
  remark: '',
});
//--基本信息表单-----end--------

//-供应商选择-----begin------
//-@参数
const options = reactive({
  supplier_type: [
    {
      id: 1,
      name: '是',
    },
    {
      id: 0,
      name: '否',
    },
  ],
  supplier_list: [],
});

//-@表单
const checkSupplierForm = reactive({
  supplier_type: '',
  supplier_list: [],
  supplier_ids: [],
});

//-@是否选择供应商--清空已选供应商列表
const handleSingleBtn = (i) => {
  checkSupplierForm.supplier_type = i.id;
  checkSupplierForm.supplier_list = [];
  state.isValiate = false;
};
//-@删除已选供应商
const delSupFun = (id) => {
  state.isValiate = true;
  checkSupplierForm.supplier_list = checkSupplierForm.supplier_list.filter(
    (v) => {
      return v.id != id;
    }
  );
  checkSupplierForm.supplier_ids = checkSupplierForm.supplier_list.map((i) => {
    return i.id;
  });
};
//-@选择供应商
const selectSupFun = () => {
  state.isValiate = true;
  state.supplierOption.show = true;
  state.supplierOption.list = checkSupplierForm.supplier_list;
};
//-保存供应商
const reviewOk = (data) => {
  checkSupplierForm.supplier_list = data;
  state.supplierOption.show = false;
  checkSupplierForm.supplier_ids = data.map((i) => {
    return i.id;
  });
};

//-关闭供应商
const reviewClose = () => {
  state.supplierOption.show = false;
};
//-end

onMounted(() => {
  getDetail();
});

//-获取详情数据
const getDetail = () => {
  state.loading = true;
  $http
    .witness_nodeInfo({
      witness_id: state.witness_id,
      type: 1,
      node_id: state.node_id,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.detail = res.data;
        //测试用
        // state.detail.can_edit = 1;
        // state.detail.can_submit = 1;
        form.form_info = res.data.form_info;
        state.check_info = res.data.check_info;
        //-根据公司id获取供应商列表
        state.supplierOption.company_id = res.data.company_id;
      } else {
        Toast(res.data);
      }
    });
};
const updateForm = (val) => {
  form.form_info = val;
};

//-提交操作
const btnSumbit = () => {
  state.loadingConfirm = true;
  state.isValiate = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      Dialog.confirm({
        message: '请确保数据填写准确无误，是否提交？',
      })
        .then(() => {
          if (state.detail.has_supplier_role == 1) {
            if (checkSupplierForm.supplier_type === '') {
              state.loadingConfirm = false;
              Notify({
                type: 'warning',
                message: '请完善供应商信息',
                duration: 1000,
              });
              return;
            } else if (checkSupplierForm.supplier_type == 1) {
              if (checkSupplierForm.supplier_ids?.length == 0) {
                state.loadingConfirm = false;
                Notify({
                  type: 'warning',
                  message: '请选择供应商',
                  duration: 1000,
                });
                return;
              }
            }
          }
          $http
            .witness_deal({
              witness_id: state.witness_id,
              type: 1,
              node_id: state.node_id,
              form_info: form.form_info,
              is_supplier_check: checkSupplierForm.supplier_type,
              supplier_ids: checkSupplierForm.supplier_ids,
            })
            .then((res) => {
              if (res.code === 0) {
                Notify({
                  type: 'success',
                  message: '操作成功！',
                  duration: 1000,
                  onClose: () => {
                    state.loadingConfirm = false;
                    if (props.data) {
                      emit('reviewOk');
                    } else {
                      router.back();
                    }
                  },
                });
              } else {
                Notify({
                  type: 'warning',
                  message: res.data,
                  duration: 2000,
                  onClose: () => {
                    state.loadingConfirm = false;
                  },
                });
              }
            });
        })
        .catch(() => {
          state.loadingConfirm = false;
          Notify({
            type: 'warning',
            message: '取消操作',
          });
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};
//-验收操作
const btnCheckSumbit = (status) => {
  state.loadingConfirm = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      $http
        .witness_deal({
          witness_id: state.witness_id,
          type: 1,
          node_id: state.node_id,
          status: status,
          form_info: form.form_info,
          remark: form.remark,
        })
        .then((res) => {
          if (res.code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                state.loadingConfirm = false;
                if (props.data) {
                  emit('reviewOk');
                } else {
                  router.back();
                }
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: res.data,
              duration: 2000,
              onClose: () => {
                state.loadingConfirm = false;
              },
            });
          }
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};

//-@保存为草稿
const editSave = () => {
  state.loadingConfirm = true;
  $http
    .witness_saveForm({
      witness_id: state.witness_id,
      type: 1,
      node_id: state.node_id,
      form_info: form.form_info,
    })
    .then((res) => {
      if (res.code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            state.loadingConfirm = false;
            if (props.data) {
              emit('reviewOk');
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: res.data,
          duration: 2000,
          onClose: () => {
            state.loadingConfirm = false;
          },
        });
      }
    });
};
//-@见证件发起
const editStart = () => {
  state.loadingConfirm = true;
  $http
    .witness_start({
      witness_id: state.witness_id,
      type: 1,
      node_id: state.node_id,
      remark: form.remark,
    })
    .then((res) => {
      if (res.code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            state.loadingConfirm = false;
            if (props.data) {
              emit('reviewOk');
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: res.data,
          duration: 2000,
          onClose: () => {
            state.loadingConfirm = false;
          },
        });
      }
    });
};
//-@提交人-驳回
const editRejust = () => {
  state.loadingConfirm = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      $http
        .witness_deal({
          witness_id: state.witness_id,
          type: 1,
          node_id: state.node_id,
          form_info: form.form_info,
          status: 4,
          remark: form.remark,
        })
        .then((res) => {
          if (res.code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                state.loadingConfirm = false;
                if (props.data) {
                  emit('reviewOk');
                } else {
                  router.back();
                }
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: res.data,
              duration: 2000,
              onClose: () => {
                state.loadingConfirm = false;
              },
            });
          }
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessTemp.less';
</style>
