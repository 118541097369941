<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2024-06-14 11:14:29
 * @Description: v0.2.2设计节点 - 列表
 * 紧急上线0923: 上传操作 部根据小节点状态判断
 * v1.3.1 交互改版 smart
-->
<template>
  <div class="page-box">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div v-if="!loading && _DATA.infoList.length !== 0">
      <Sticky :offset-top="_DATA.offsetTop">
        <!-- 详情信息 -->
        <div class="base-info">
          <div class="info">
            <h4>
              <span class="estate_str">{{ _DATA.info.estate_str }}</span>
              <span @click="lookDetail">详情></span>
            </h4>
            <p>启动时间：{{ _DATA.info.start_day || '-' }}</p>
          </div>
        </div>
        <!-- 节点切换 -->
        <Tabs
          v-model:active="_DATA.tabIndex"
          class="tab-box"
          :class="{ length4: _DATA.allInfoList.length === 4 }"
          @change="changeTab"
          :swipe-threshold="3"
        >
          <Tab
            v-for="(item, index) in _DATA.allInfoList"
            :key="item.type_sort + item.node_status + ''"
            :name="item.type_sort"
            style="width: 25%"
          >
            <template #title>
              <div
                class="tab-item margin"
                :class="{
                  active: item.type_sort === _DATA.tabIndex,
                  last: _DATA.allInfoList.length - 1 == index,
                  first: index === 0,
                }"
              >
                <p
                  class="icon-box"
                  :class="{
                    ing: item.node_status === 1,
                    ok: item.node_status === 2,
                    okBefore: _DATA.allInfoList[index - 1]?.node_status === 2,
                  }"
                >
                  <span class="line left"></span>
                  <img :src="getTabIcon(item)" alt="" />
                  <span class="line right"></span>
                </p>
                <p class="item-name">{{ item.type_name }}</p>
                <p class="item-status">
                  {{
                    item.node_status === 0
                      ? '待提交'
                      : item.node_status === 1
                      ? '进行中'
                      : '已完成'
                  }}
                </p>
              </div>
            </template>
          </Tab>
        </Tabs>
      </Sticky>
      <!-- 任务卡片 (未开工)-->
      <div class="delay" v-if="_DATA.is_delay == 1">
        <Icon name="warning" color="#EA0000" size="14" />
        <span class="name">阶段进度延期</span>
      </div>
      <div class="content-box">
        <div
          class="content-item"
          v-for="(item, idx) in _DATA.infoList"
          :key="idx"
        >
          <div class="task-card-same">
            <!-- 状态栏 -->
            <div class="h1">
              <label
                v-if="item.node_type === 1 || item.node_type === 4"
                :class="{
                  green: item.status === 3,
                  blue: item.status === 2,
                  gray: item.status === 1,
                }"
              >
                <img
                  :src="
                    require('@/assets/images/icon-svg/icon-nostart-gray.svg')
                  "
                  alt=""
                  v-if="item.status === 1"
                />
                <img
                  :src="require('@/assets/images/icon-svg/icon-ing-blue.svg')"
                  alt=""
                  v-if="item.status === 2"
                />
                <img
                  :src="require('@/assets/images/icon-cons-ok.png')"
                  alt=""
                  v-if="item.status === 3"
                />
                {{ item.status_name }}
              </label>
              <label
                v-if="item.node_type === 2 || item.node_type === 3"
                :class="{
                  gray: item.status === 1,
                  blue: item.status === 2,
                  yellow: item.status === 3,
                  red: item.status === 4,
                  green: item.status === 5,
                }"
              >
                <img
                  :src="
                    require('@/assets/images/icon-svg/icon-nostart-gray.svg')
                  "
                  alt=""
                  v-if="item.status === 1"
                />
                <img
                  :src="require('@/assets/images/icon-svg/icon-ing-blue.svg')"
                  alt=""
                  v-if="item.status === 2"
                />
                <img
                  :src="
                    require('@/assets/images/icon-svg/icon-wait-yellow.svg')
                  "
                  alt=""
                  v-if="item.status === 3"
                />
                <img
                  :src="require('@/assets/images/icon-svg/icon-no-red.svg')"
                  alt=""
                  v-if="item.status === 4"
                />
                <img
                  :src="require('@/assets/images/icon-cons-ok.png')"
                  alt=""
                  v-if="item.status === 5"
                />
                {{ item.status_name }}
              </label>
              <!-- 延期相关 -->
              <div class="delay-box" v-if="item.delay_type !== 0">
                <img
                  :src="require('@/assets/images/icon-svg/icon-cry-red.svg')"
                  alt=""
                />
                <span>
                  {{ item.delay_type_name }}
                  <b>{{ item.delay_num }}</b>
                  天
                </span>
              </div>
            </div>
            <!-- 头信息 -->
            <div class="do-box">
              <p class="title">{{ item.node_name_id_name }}</p>
              <p class="tag">
                {{ item.node_type_name }}/工期{{ item.schedule }}天
              </p>
              <ul class="ul-flex" v-if="item.status !== 1">
                <li>
                  <p class="text">实际开始时间</p>
                  <p class="n">{{ item.start_time || '-' }}</p>
                </li>
                <li>
                  <p class="text">实际结束时间</p>
                  <p class="n">{{ item.end_time || '-' }}</p>
                </li>
              </ul>
            </div>
            <!-- 详细信息 -->
            <div class="detail-box">
              <!-- <div class="detail-box" :class="{ white: item.status === 1 }"> -->
              <div class="top">
                <p>
                  <label>节点详情</label>
                </p>
                <div class="up-down">
                  <Icon
                    name="play"
                    @click="handleArrow(item)"
                    :class="{ up: item.up_down }"
                  />
                </div>
              </div>
              <ol class="content" v-if="!!item.up_down">
                <!-- <ol class="content" v-if="!!item.up_down || item.status === 1"> -->
                <li>
                  <label>计划时间：</label>
                  <span>
                    {{ item.plan_start_time + ' - ' + item.plan_end_time }}
                  </span>
                </li>
                <li>
                  <label>开始条件：</label>
                  <span>
                    <p v-for="(v, i) in item.list" :key="i">
                      {{
                        v.sort_id_name
                          ? v.sort_id_name + '，结束后' + v.schedule + '天开始'
                          : '-'
                      }}
                    </p>
                  </span>
                </li>
                <li>
                  <label>消息提醒人：</label>
                  <span>
                    {{
                      item.msg_to_uid?.length > 0
                        ? item.msg_to_uid_name.join('，')
                        : '-'
                    }}
                  </span>
                </li>
                <li>
                  <label>提前几天提醒：</label>
                  <span>
                    {{
                      item.advance_days?.length > 0
                        ? item.advance_days.join('、') + '天'
                        : '-'
                    }}
                  </span>
                </li>
              </ol>
            </div>
            <!-- 操作人相关 -->
            <div class="person-box">
              <div class="person-item">
                <Image
                  class="img-head"
                  fit="cover"
                  round
                  :src="
                    item.f_face_image
                      ? item.f_face_image.includes('http')
                        ? item.f_face_image
                        : $host + item.f_face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                ></Image>
                <div class="flex">
                  <div class="name">
                    {{ item.f_uid_name }}
                  </div>
                  <div class="desc">
                    {{
                      getStr('/', item.f_department_name, item.f_post_id_name)
                    }}
                  </div>
                </div>
                <!-- 验收节点显示 -->
                <div class="tag" v-if="item.node_type == 3">操作人</div>
              </div>
              <!-- 验收节点显示验收人 -->
              <div class="line" v-if="item.node_type == 3"></div>
              <div class="person-item" v-if="item.node_type == 3">
                <Image
                  class="img-head"
                  fit="cover"
                  round
                  :src="
                    item.c_face_image && item.c_uid !== -1
                      ? item.c_face_image.includes('http')
                        ? item.c_face_image
                        : $host + item.c_face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                ></Image>

                <div class="flex">
                  <div class="name">{{ item.c_uid_name }}</div>
                  <div class="desc" v-if="item.c_post_id != -1">
                    {{
                      getStr('/', item.c_department_name, item.c_post_id_name)
                    }}
                  </div>
                </div>
                <!-- 验收节点显示 -->
                <div class="tag">验收人</div>
              </div>
              <!-- 验收节点显示验收人2 -->
              <div
                class="line"
                v-if="item.node_type == 3 && item.second_check_role_id != 0"
              ></div>
              <div
                class="person-item"
                v-if="item.node_type == 3 && item.second_check_role_id != 0"
              >
                <Image
                  class="img-head"
                  fit="cover"
                  round
                  :src="
                    item.second_check_user_face_image &&
                    item.second_check_uid !== -1
                      ? item.second_check_user_face_image.includes('http')
                        ? item.second_check_user_face_image
                        : $host + item.second_check_user_face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                ></Image>

                <div class="flex">
                  <div class="name">{{ item.second_check_user_name }}</div>
                  <div class="desc" v-if="item.second_check_role_id != -1">
                    {{
                      getStr(
                        '/',
                        item.second_check_user_department_name,
                        item.second_check_role_name
                      )
                    }}
                  </div>
                </div>
                <!-- 验收节点显示 -->
                <div class="tag">验收人2</div>
              </div>
            </div>
            <!-- 操作按钮 -->
            <div class="option-box">
              <!-- 1.工序 -->
              <Button
                v-if="
                  item.status !== 1 &&
                  item.node_type === 1 &&
                  item.status === 2 &&
                  item.f_uid == _DATA.ACCOUNT_ID
                "
                @click="handleOption('标记完成', item)"
                type="primary"
                size="normal"
                class="button-flex"
              >
                <span>标记完成</span>
                <em><Icon name="arrow" /></em>
              </Button>
              <!-- 2.提审 企微端不显示 -->
              <!-- 3.验收 -->
              <!-- V1.3.3紧急需求，node_name_id=291去除通知验收按钮 -->
              <span v-if="item.node_name_id !== 291">
                <Button
                  v-if="
                    item.status !== 1 &&
                    item.node_type === 3 &&
                    (item.status === 2 || item.status === 4) &&
                    item.f_uid == _DATA.ACCOUNT_ID
                  "
                  @click="handleOption('通知验收', item)"
                  type="primary"
                  size="normal"
                  class="button-flex"
                >
                  <span>通知验收</span>
                </Button>
                <Button
                  v-if="
                    item.node_type === 3 &&
                    item.status === 3 &&
                    ((item.c_uid == _DATA.ACCOUNT_ID &&
                      item.c_check_info == 0) ||
                      (item.second_check_uid == _DATA.ACCOUNT_ID &&
                        item.second_check_info == 0))
                  "
                  @click="handleOption('确认验收', item)"
                  type="primary"
                  size="normal"
                  class="button-flex"
                >
                  <span>确认验收</span>
                </Button>
              </span>

              <!-- 4.收款 -->
              <Button
                v-if="
                  item.status !== 1 &&
                  item.node_type === 4 &&
                  item.status === 2 &&
                  _DATA.ACCOUNT_ID == item.f_uid
                "
                @click="handleOption('添加收款', item)"
                type="primary"
                size="normal"
                class="button-flex"
              >
                <span>添加收款</span>
              </Button>
              <Button
                v-if="
                  item.status !== 1 &&
                  item.node_type === 4 &&
                  item.status === 2 &&
                  _DATA.ACCOUNT_ID == item.f_uid
                "
                @click="handleOption('结束收款', item)"
                size="normal"
                class="button-flex"
              >
                <span>结束收款</span>
              </Button>
              <!-- 其他 所有环节 新增【提前开始】 -->
              <Button
                v-if="
                  item.status == 1 &&
                  _DATA.ACCOUNT_ID == item.f_uid &&
                  item.is_start === 1
                "
                type="primary"
                size="normal"
                class="button-flex"
                @click="handleOption('提前开始', item)"
              >
                <span>提前开始</span>
              </Button>
              <!-- v1.3.4 见证件 -->
              <Button
                class="witness_btn"
                v-if="item.witness_count && item.witness_count > 0"
                @click="handleOption('见证件', item)"
              >
                见证件
                <span class="witness_num">{{ item.witness_count }}</span>
                <div
                  class="witness_status"
                  :class="'status' + item.witness_status"
                >
                  {{ item.witness_status_name }}
                </div>
              </Button>
              <!-- v1.3.1 更多 位置变更 功能不变-->
              <Button v-if="ellipsisShow(item)" @click="showMore(item)">
                更多
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 空状态 -->
  <Empty
    class="empty-fff"
    description="暂无数据"
    v-if="!loading && _DATA.infoList.length === 0"
  />
  <!-- end -->
  <!-- 动作面板 - 更多 -->
  <ActionSheet
    v-model:show="_DATA.showMoreAction"
    cancel-text="取消"
    close-on-click-action
    @cancel="
      () => {
        _DATA.showMoreAction = false;
      }
    "
  >
    <div class="van-action-sheet__content">
      <div
        @click="btnEndMoney(item.name)"
        v-for="item in _DATA.activeInfo.more_list"
        :key="item.name"
      >
        <Button class="van-action-sheet__item" type="button">
          <span class="van-action-sheet__name">{{ item.name }}</span>
        </Button>
      </div>
    </div>
  </ActionSheet>
  <!-- 查看列表 -->
  <NodeRecord
    ref="nodeRecord"
    :data="_DATA.record"
    :isShow="_DATA.showRecord"
    @closePop="
      () => {
        _DATA.showRecord = false;
      }
    "
  ></NodeRecord>
  <!-- 全部附件 -->
  <FileAll
    ref="fileAll"
    :data="_DATA.activeInfo.files"
    :isShow="_DATA.showAllFile"
    @closePop="
      () => {
        _DATA.showAllFile = false;
      }
    "
  ></FileAll>
  <!-- 上传附件弹窗 -->
  <FileUploadDialog
    ref="fileAll"
    :isShow="_DATA.showUploadFile"
    :type="_DATA.fileType"
    @update:func="(list) => getFileList(list)"
    @closePop="
      () => {
        _DATA.showUploadFile = false;
      }
    "
  ></FileUploadDialog>
  <!-- 见证件列表 -->
  <ActionSheet
    v-model:show="_DATA.showWitnessAction"
    cancel-text="取消"
    close-on-click-action
    :round="false"
    @cancel="
      () => {
        _DATA.showWitnessAction = false;
      }
    "
    class="witness_list_box"
  >
    <ul class="content">
      <li
        v-for="(item, index) in _DATA.witness_list"
        :key="item.id"
        @click="changeWitness(item)"
        :class="{ noborder: index + 1 === _DATA.witness_list.length }"
      >
        <div class="left">{{ item.name }}</div>
        <span class="right" :class="'status' + item.status">
          {{
            item.status == 1 ? '未开始' : item.status == 2 ? '进行中' : '已确认'
          }}
        </span>
      </li>
    </ul>
  </ActionSheet>
  <!-- end -->
</template>

<script setup>
import { inject, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import FileAll from '@/components/common/FileAll';
import FileUploadDialog from '@/components/common/FileUploadDialog';

import {
  Button,
  Icon,
  Notify,
  Overlay,
  Loading,
  Empty,
  Dialog,
  ActionSheet,
  Sticky,
  Image,
  Tab,
  Tabs,
} from 'vant';
import _ from 'lodash';
import NodeRecord from '@/components/design/nodeRecord.vue';
const $http = inject('$http');
const router = useRouter();
const $host = inject('$host');

/**
 * 状态staus status_name
1工序：1未开始 2进行中 3已完成
2提审：1未开始 2待审核 3审核中 4审核不通过 5审核通过
3验收：1未开始 2待验收 3验收中 4验收不通过 5验收通过
4收款：1未开始 2待收款 3已收款',
 */

const _DATA = reactive({
  design_id: router.currentRoute.value.query.did, // 设计id
  contract_id: '', //设计合同id-默认值 用于其他地方跳转而来
  customer_id: router.currentRoute.value.query.id, //客户id
  customer_name: '', //客户name

  ACCOUNT_ID: localStorage.getItem('ACCOUNT_ID'),
  showMoreAction: false,
  activeInfo: {},
  more_list: [], //更多操作的 按钮展示
  showUpload: false, //上传附件 是否可以上传
  showUploadFile: false,
  showAllFile: false,
  fileType: 1,
  tabIndex: router.currentRoute.value.query.tabIndex
    ? Number(router.currentRoute.value.query.tabIndex)
    : null,
  infoList: [],
  allInfoList: [],
  is_delay: 0,
  type_sort: 0,
  info: {},
  offsetTop: '12.3vw',
  showWitnessAction: false,
  witness_node_id: '',
  witness_list: [], //见证件列表
});

const loading = ref(false);

onMounted(() => {
  //设计详情
  loadInfo();
});

/**
 * 接口调用
 */
//设计详情
const loadInfo = () => {
  loading.value = true;
  $http.getDesignInfo({ id: _DATA.design_id }).then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      _DATA.info = data;
      loadFunc();
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//@设计节点 列表
const loadFunc = () => {
  loading.value = true;
  $http
    .getDesignListApi({
      design_id: _DATA.design_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.contract_id = data.contract_id;
        _DATA.customer_id = data.customer_id;
        _DATA.customer_name = data.customer_name;
        //节点列表数据
        _DATA.allInfoList = data.list;
        if (_DATA.tabIndex == null) {
          _DATA.tabIndex =
            _DATA.info.life_cycle_status == 0
              ? '1'
              : _DATA.info.life_cycle_status;
          changeRouter(_DATA.tabIndex);
        }
        getInfoList();
        //滚动条
        changeScroll();
        loading.value = false;
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    });
};

//获取节点tab图标
const getTabIcon = (val) => {
  let iconName = '';
  if (val.type_sort === _DATA.tabIndex) {
    return require('@/assets/images/icon-cons-active.png');
  }
  switch (val.node_status) {
    case 0:
      // 未开始
      iconName = '-no'; //-
      break;
    case 1:
      // 进行中
      iconName = '-ing'; //->
      break;
    case 2:
      // 已完成
      iconName = '-ok'; //钩
      break;
    default:
      break;
  }
  return require('@/assets/images/icon-cons' + iconName + '.png');
};

//节点列表数据
const getInfoList = () => {
  let is_delay = 0;
  let type_sort = 0;
  let infoList = [];
  let curIndex = _DATA.tabIndex || _DATA.info.life_cycle_status;
  for (let i in _DATA.allInfoList) {
    const item = _DATA.allInfoList[i];
    //当前节点
    if (curIndex == item.type_sort) {
      infoList = item.list;
      is_delay = item.is_delay;
      type_sort = item.type_sort;
    }
  }
  _DATA.is_delay = is_delay;
  _DATA.infoList = infoList;
  _DATA.type_sort = type_sort;
};
// 动态修改路由参数
const changeRouter = (val) => {
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.tabIndex = val;
  router.replace({ query });
};
/**
 * 操作
 */
//-@切换tab
const changeTab = (val) => {
  changeRouter(val);

  _DATA.tabIndex = val;
  _DATA.infoList = [];
  getInfoList();
  document.documentElement.scrollTop = 0;
};

//-@ 判断更多 更多操作按钮 是否展示

const ellipsis_list = reactive({
  1: {
    list: [
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3], check: 'upload_type' },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
    ],
  },
  2: {
    list: [
      { name: '查看记录', status: [1, 2, 3, 4, 5] },
      { name: '上传附件', status: [1, 2, 3, 4, 5], check: 'upload_type' },
      { name: '查看附件', status: [1, 2, 3, 4, 5], check: 'files' },
    ],
  },
  3: {
    list: [
      { name: '查看记录', status: [1, 2, 3, 4, 5] },
      { name: '上传附件', status: [1, 2, 3, 4, 5], check: 'upload_type' },
      { name: '查看附件', status: [1, 2, 3, 4, 5], check: 'files' },
    ],
  },
  4: {
    list: [
      { name: '查看记录', status: [1, 2, 3] },
      { name: '上传附件', status: [1, 2, 3], check: 'upload_type' },
      { name: '查看附件', status: [1, 2, 3], check: 'files' },
      // { name: '结束收款', status: [2], check: 'f_uid' },
    ],
  },
});
const ellipsisShow = (val) => {
  let { status, f_uid, files, upload_type } = val;
  const list = ellipsis_list[val.node_type].list.filter((item) => {
    if (item.check && item.check === 'f_uid') {
      return item.status.includes(status) && _DATA.ACCOUNT_ID == f_uid;
    } else if (item.check && item.check === 'files') {
      return files;
    } else if (item.check && item.check === 'upload_type') {
      return item.status.includes(status) && upload_type != 1;
    } else {
      return item.status.includes(status);
    }
  });
  val.more_list = list;
  return list.length > 0 ? true : false;
};

//-@ 更多操作 - 操作-结束收款
const btnEndMoney = (type_name) => {
  switch (type_name) {
    case '查看记录':
      _DATA.showMoreAction = false;
      _DATA.showRecord = true;
      _DATA.record = { type: 1, node_id: _DATA.activeInfo.node_id };
      break;
    case '上传附件':
      _DATA.showMoreAction = false;
      goUploadFile();
      break;
    case '查看附件':
      _DATA.showMoreAction = false;
      _DATA.showAllFile = true;
      break;
  }
};
//-@ 提前开始
const handleOption = (type_name, item) => {
  switch (type_name) {
    case '标记完成':
      {
        setScroll();
        router.push({
          path: '/designOver',
          query: {
            did: _DATA.design_id,
            nid: item.node_id,
            id: _DATA.customer_id,
          },
        });
      }
      break;
    case '通知验收':
      {
        setScroll();
        router.push({
          path: '/designNotice',
          query: {
            did: _DATA.design_id,
            nid: item.node_id,
            id: _DATA.customer_id,
            id2: item.second_check_role_id,
            cname: item.c_uid_name,
            cname2: item.second_check_user_name,
            nname: item.node_name_id_name,
            type_sort: _DATA.type_sort,
            c_post_id: item.c_post_id,
          },
        });
      }
      break;
    case '确认验收':
      {
        setScroll();
        router.push({
          path: '/designSure',
          query: {
            did: _DATA.design_id,
            nid: item.node_id,
            id: _DATA.customer_id,
            nname: item.node_name_id_name,
          },
        });
      }
      break;
    case '添加收款':
      {
        setScroll();
        router.push({
          path: '/Ccollection',
          query: {
            did: _DATA.design_id,
            nid: item.node_id,
            id: _DATA.customer_id,
            cid: _DATA.contract_id,
            pid: item.payment_id,
          },
        });
      }
      break;
    case '提前开始':
      Dialog.confirm({
        message: '确定要提前开始吗？',
      }).then(() => {
        loading.value = true;
        _DATA.showMoreAction = false;
        $http
          .getDesignStartNowApi({
            node_id: item.node_id,
          })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功！',
                duration: 1000,
                onClose: () => {
                  loading.value = false;
                  loadFunc();
                },
              });
            } else {
              loading.value = false;
              Notify({
                type: 'warning',
                message: data,
                duration: 2000,
              });
            }
          });
      });
      break;
    case '结束收款':
      Dialog.confirm({
        message: '确定要结束收款吗？',
      }).then(() => {
        loading.value = true;
        _DATA.showMoreAction = false;
        $http
          .getDesignOverApi({ node_id: item.node_id, remark: '' })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功！',
                duration: 1000,
                onClose: () => {
                  loading.value = false;
                  loadFunc();
                },
              });
            } else {
              loading.value = false;
              Notify({
                type: 'warning',
                message: data,
                duration: 2000,
              });
            }
          });
      });
      break;
    case '见证件':
      {
        //单个见证件直接跳转
        let arr = item.witness_info;
        if (item.witness_count == 1) {
          setScroll();
          router.push({
            path: '/witnessViewDesign',
            query: {
              witness_id: arr[0].id,
              node_id: item.node_id,
            },
          });
        } else {
          //多个见证件弹出列表
          _DATA.witness_node_id = item.node_id;
          _DATA.witness_list = item.witness_info;
          _DATA.showWitnessAction = true;
        }
      }
      break;
  }
};
//切换见证件
const changeWitness = (val) => {
  setScroll();
  router.push({
    path: '/witnessViewDesign',
    query: {
      witness_id: val.id,
      node_id: _DATA.witness_node_id,
    },
  });
};
//-@ 点击更多操作
const showMore = (val) => {
  _DATA.activeInfo = val;
  _DATA.showMoreAction = true;
};
//点击上传附件
const goUploadFile = () => {
  _DATA.showUploadFile = true;
};

//-@ 附件
const getFileList = (file_list) => {
  _DATA.showUploadFile = false;
  file_list?.length > 0 &&
    $http
      .getDesignUploadFileApi({
        node_id: _DATA.activeInfo.node_id,
        files: file_list,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功！',
            duration: 1000,
            onClose: () => {
              loadFunc();
            },
          });
        } else {
          Notify({
            type: 'warning',
            message: data,
            duration: 2000,
          });
        }
      });
};

//-@ 展开收起
const handleArrow = (item) => {
  item.up_down = !item.up_down;
};

//-@ 跳转详情
const lookDetail = () => {
  router.push({
    path: '/designDetail',
    query: {
      did: router.currentRoute.value.query.did,
    },
  });
};

//-@字符串拼接 smart添加
const getStr = (split_str, ...arr) => {
  const new_arr = arr.filter((item) => item !== '');
  return new_arr?.length > 0 ? new_arr.join(split_str) : '';
};

const changeScroll = () => {
  if (localStorage.getItem('scrollTop') > 0) {
    setTimeout(() => {
      let str = localStorage.getItem('scrollTop');
      document.documentElement.scrollTop = Number(str);
      localStorage.setItem('scrollTop', 0);
    }, 0);
  }
};
const setScroll = () => {
  localStorage.setItem('scrollTop', document.documentElement.scrollTop);
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/design/design.less';
.witness_btn {
  .witness_num {
    padding: 2px 5px;
    border-radius: 2px;
    background: #d8d8d8;
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    margin-left: 4px;
  }
  .witness_status {
    position: absolute;
    right: -18px;
    top: -12px;

    font-size: 13px;
    font-weight: bold;
    line-height: 21px;
    padding: 0 4px;
    border-radius: 10px 10px 10px 0;
    z-index: 2;
    &.status1 {
      color: #ffffff;
      background: #8d9091;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
    &.status2 {
      color: #ffffff;
      background: #fca326;
      box-shadow: 0px 2px 4px 0px rgba(252, 163, 38, 0.4);
    }
    &.status3 {
      color: #ffffff;
      background: #008911;
      box-shadow: 0px 2px 4px 0px rgba(0, 137, 17, 0.1);
    }
  }
}
// 见证件列表
.witness_list_box {
  .content {
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px;
      border-bottom: 1px solid #f5f5f5;

      &.noborder {
        border: none;
      }

      .left {
        display: flex;
        justify-content: flex-start;
      }

      .right {
        height: 21px;
        line-height: 21px;
        border-radius: 2px;

        font-size: 12px;
        padding: 0 4px;
        font-weight: 700;
        &.status1 {
          color: #767676;
          background: #f5f5f5;
        }
        &.status2 {
          color: #ffffff;
          background: @orange-color;
        }
        &.status3 {
          color: @green-color;
          background: @green-bg-color;
        }
      }
    }
  }
}
</style>
