<!--
 * @Author: gest
 * @Date: 2023-09-08 16:55:55
 * @LastEditTime: 2023-10-27 10:56:18
 * @Description: v1.4.5 阶段验收单 （11个见证件）
7 => '放线、文施保护验收单',
8 => '交接面验收单',
6 => '开工交底验收单',
9 => '隐蔽预验收单',
10 => '隐蔽验收单',
11 => '中期预验收单',
12 => '中期验收单',
13 => '产品安装预验收单',
14 => '产品安装验收单',
15 => '交付预验收单',
16 => '交付验收单',
-->
<template>
  <div class="formBase">
    <!-- v-for -->
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="white_bg p_15 flex_between">
        <div class="flex_between" style="align-items: baseline">
          <div
            class="status_tag mr_5"
            :class="item.tag == 1 ? 'blue' : 'green'"
            v-if="item.tag_name && props.sub_type != 7"
          >
            {{ item.tag_name }}
          </div>
          <div class="font_size_17 font_bold" style="width: 180px">
            {{ item.name }}
          </div>
        </div>
        <Field
          name="radio"
          label=""
          :rules="[
            {
              required: true,
              message: '请选择',
            },
          ]"
          v-if="props.sub_type != 7"
        >
          <template #input>
            <RadioGroup
              @click="radioClick(item)"
              v-model="item.value"
              direction="horizontal"
              checked-color="#0256FF"
            >
              <Radio :name="1">有</Radio>
              <Radio :name="2">无</Radio>
            </RadioGroup>
          </template>
        </Field>
      </div>

      <div
        class="flex_between align_center line_info"
        @click="arrowClick(item)"
      >
        <div class="grey_color font_size_13">
          共{{ item.children.length }}项
          <span
            v-show="
              ((item.value == 1 && props.sub_type != 7) ||
                props.sub_type == 7) &&
              getTotalFun(item.children, '空值') != 0
            "
            >，<span class="red_color">{{
              getTotalFun(item.children, '空值')
            }}</span
            >个未填</span
          >
        </div>
        <div class="right">
          <div
            class="r_item"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <div class="green_color font_size_17">
              {{ getTotalFun(item.children, '合格') }}
            </div>
            <div class="grey_color font_size_12">合格</div>
          </div>
          <div
            class="vline"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          ></div>
          <div
            class="r_item"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <div class="red_color font_size_17">
              {{ getTotalFun(item.children, '不合格') }}
            </div>
            <div class="grey_color font_size_12">不合格</div>
          </div>
          <div
            class="vline"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          ></div>
          <div
            class="r_item"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <div class="grey_color font_size_17">
              {{ getTotalFun(item.children, '无此项') }}
            </div>
            <div class="grey_color font_size_12">无此项</div>
          </div>
          <div class="ml_5" style="height: 40px; line-height: 40px">
            <Icon name="arrow-up" class="grey_color" v-if="item.arrow == 1" />
            <Icon name="arrow-down" class="grey_color" v-else />
          </div>
        </div>
      </div>

      <div class="white_bg" v-show="item.arrow == 1">
        <div
          class="cell_border flex_between"
          v-for="(v, i) in item.children"
          :key="i"
        >
          <div class="font_size_15 flex1 mr_15">{{ v.name }}</div>

          <Field
            name="radio"
            label=""
            :rules="[
              {
                required: true,
                message: '请选择',
              },
            ]"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <template #input>
              <RadioGroup
                v-model="v.value"
                direction="horizontal"
                :checked-color="
                  v.value == 1
                    ? '#008911'
                    : v.value == 2
                    ? '#ea0000'
                    : '#0256FF'
                "
              >
                <Radio :name="1" style="margin-right: 11vw"></Radio>
                <Radio :name="2" style="margin-right: 11vw"></Radio>
                <Radio :name="3" style="margin-right: 8vw"></Radio>
              </RadioGroup>
            </template>
          </Field>
        </div>
      </div>
    </div>
    <!-- v-for end-->
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <p><span class="red_color">*</span>验收时间</p>
            <Field
              style="text-align: right"
              v-model="form.info.accept_time"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              :rules="[
                {
                  required: true,
                  message: '请选择验收时间',
                },
              ]"
              @click="openDateType('accept_time')"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="content mb_15"
      v-if="props.sub_type != 8 && props.sub_type != 7"
    >
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="flex_between">
            <p>特定项验收</p>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.info.special_item"
                  direction="horizontal"
                  checked-color="#0256FF"
                >
                  <Radio :name="1">有</Radio>
                  <Radio :name="2">无</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
        <div class="field_item" v-if="form.info.special_item == 1">
          <Field
            v-model="form.info.special_item_remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="若有特定的验收项，请填写特定事项验收情况"
            show-word-limit
            :rules="[
              {
                required: true,
                message: '请填写',
              },
            ]"
          ></Field>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="备注说明验收结果，验收不合格写明具体不合格的事项与整改完成时间..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        :max-date="state.maxDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v, 'accept_time')"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import {
  Field,
  RadioGroup,
  Radio,
  Popup,
  DatetimePicker,
  Icon,
  Dialog,
} from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);
const state = reactive({
  //-时间控件
  maxDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

// @-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD');
};
const radioClick = (item) => {
  if (item.value == 2) {
    let arr = item.children.filter((i) => {
      return i.value;
    });
    if (arr.length > 0) {
      Dialog.confirm({
        message:
          '确认将[有此验收项]切换成[无此验收项]吗？一旦确认，填写数据将清空',
      })
        .then(() => {
          item.children = item.children.map((i) => {
            i.value = null;
            return i;
          });
        })
        .catch(() => {
          item.value = 1;
        });
    }
  }
};
//-计算 未填、合格、不合格数
const getTotalFun = (data, name) => {
  let list = [];
  if (name == '空值') {
    list = data.filter((v) => {
      return !v.value;
    });
  } else if (name == '合格') {
    list = data.filter((v) => {
      return v.value == 1;
    });
  } else if (name == '不合格') {
    list = data.filter((v) => {
      return v.value == 2;
    });
  } else if (name == '无此项') {
    list = data.filter((v) => {
      return v.value == 3;
    });
  }
  return list.length;
};
const arrowClick = (item) => {
  if (item.arrow == 1) {
    item.arrow = '';
  } else {
    item.arrow = 1;
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
