<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2023-06-26 11:42:38
 * @Description: V1.4.0 合同变更增加金额变更和对应审核流程
-->
<template>
  <!-- 合同变更 -->
  <div class="page-waper">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <!-- 选择变更合同 -->
        <div class="mess-top">
          <p class="label-name white">
            <em>*</em>
            选择变更合同
          </p>
          <Field
            v-model="form.list.change_contract_name"
            right-icon="arrow-down"
            readonly
            placeholder="选择变更合同"
            @click="state.showTypePicker1 = true"
            :rules="[{ required: true, message: '选择变更合同' }]"
          />
        </div>
        <!-- 合同基本信息 -->
        <div class="baseInfo" v-if="form.list.change_contract_id">
          <h3>基本信息</h3>
          <div class="baseInfo_item">
            <p>
              <label>合同编号:</label>
              <span>{{ state.contractBaseInfo.contract_no || '-' }}</span>
            </p>
            <p>
              <label>签署方:</label>
              <span>{{ state.contractBaseInfo.signatory_name || '-' }}</span>
            </p>
            <p>
              <label>执行人:</label>
              <span>
                {{ state.contractBaseInfo.executor_type_name || '-' }}
              </span>
            </p>
            <p>
              <label>签订合同价:</label>
              <span>{{ state.contractBaseInfo.contract_amount || '-' }}</span>
            </p>
            <p>
              <label>签订时间:</label>
              <span>{{ state.contractBaseInfo.signed_up_time || '-' }}</span>
            </p>
          </div>
        </div>
        <!-- 变更原因 -->
        <div class="cell">
          <label class="form_title">
            <span style="color: #ea3d2f">*</span>
            变更原因
          </label>
          <Field
            v-model="form.list.change_type_name"
            right-icon="arrow-down"
            readonly
            name="picker"
            :rules="[
              {
                required: true,
                message: '请选择变更原因',
              },
            ]"
            placeholder="请选择变更原因"
            @click="state.showTypePicker2 = true"
          />
        </div>
        <!-- 备注 -->
        <div class="cell">
          <label class="form_title">备注</label>
          <Field
            v-model="form.list.change_remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有什么需要补充的吗..."
            show-word-limit
          />
        </div>
        <div class="btnSumbit">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            下一步
          </Button>
        </div>
      </Form>

      <!-- 合同组件 -->
      <Popup v-model:show="state.showTypePicker1" position="bottom">
        <Picker
          :columns="state.typeList1"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'type1')"
          @cancel="state.showTypePicker1 = false"
          :default-index="
            state.typeList1.findIndex((v) => {
              return v.id == form.list.change_contract_id;
            })
          "
        />
      </Popup>
      <!-- 变更原因组件 -->
      <Popup v-model:show="state.showTypePicker2" position="bottom">
        <Picker
          :columns="state.typeList2"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'type2')"
          @cancel="state.showTypePicker2 = false"
          :default-index="
            state.typeList2.findIndex((v) => {
              return v.id == form.list.change_type;
            })
          "
        />
      </Popup>
      <!-- 编辑合同变更信息 -->
      <Popup
        v-model:show="state.contractInfo.show"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <ContractChangeTemp
          v-if="state.contractInfo.show"
          :data="form.list"
          :customer_id="state.customer_id"
          :change_detail="state.change_detail"
          :changeType="form.list.change_type"
          @onClosePopFun="onClosePopFun"
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { Dialog } from 'vant';
import { useRouter } from 'vue-router';
import ContractChangeTemp from '@/components/moreOperate/ContractChangeTemp';
import {
  Form,
  Field,
  Popup,
  Picker,
  Toast,
  Button,
  Loading,
  Overlay,
} from 'vant';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loadingConfirm: false,
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  //-合同参数
  showTypePicker1: false,
  typeList1: [],
  contractBaseInfo: {
    contract_no: '',
    executor_type_name: '',
    signatory_name: '',
    contract_amount: '',
    signed_up_time: '',
    change_contract_type_id: '',
    change_contract_type_name: '',
  },
  //-变更原因参数
  showTypePicker2: false,
  typeList2: [],
  //-四合一模式：修改合同信息
  contractInfo: {
    show: false,
  },
  //-变更信息基本数据
  change_detail: {
    contract_no: '',
    customer_name: '',
    change_contract_type_id: '',
    change_contract_type_name: '',
    change_specific_reasons: '',
  },
});
//-参数
onMounted(() => {
  getContractChangeParam();
});
//-获取参数
const getContractChangeParam = async () => {
  state.loadingConfirm = true;
  await $http
    .getContractChangeParam({ customer_id: state.customer_id })
    .then((res) => {
      state.loadingConfirm = false;
      if (res.code === 0) {
        state.typeList1 = res.data.contract_list;
        state.typeList2 = res.data.contract_change_reason;
      } else {
        Toast(res.data);
      }
    });
};

//--表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    change_contract_id: '',
    change_contract_name: '',
    change_contract_type: '',
    change_type: '',
    change_type_name: '',
    change_remark: '',
  },
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (type === 'type1') {
    if (value) {
      form.list.change_contract_name = value.name;
      form.list.change_contract_id = value.id;
      form.list.change_contract_type = value.type;
      state.contractBaseInfo = value;
      state.change_detail = {
        contract_no: value.contract_no,
        customer_name: value.customer_name,
        change_contract_type_id: value.change_contract_type_id,
        change_contract_type_name: value.change_contract_type_name,
        change_specific_reasons: '',
      };
    }
    state.showTypePicker1 = false;
  } else if (type === 'type2') {
    if (value) {
      form.list.change_type_name = value.name;
      form.list.change_type = value.id;
    }
    state.showTypePicker2 = false;
  }
};

//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (state.contractBaseInfo.change_contract_type_id) {
        state.contractInfo.show = true;
      } else {
        Dialog.confirm({
          message:
            '<div class="message_tip"><p class="tip_image"></p><p>您选择的合同类型暂不支持合同变更</p></div>',
          showCancelButton: false,
          confirmButtonText: '知道了',
          confirmButtonColor: '#3471FF',
          allowHtml: true,
        }).then(() => {
          return;
        });
      }
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
    });
};

//-关闭变更信息
const onClosePopFun = () => {
  state.contractInfo.show = false;
};
//--表单end---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/change.less';
</style>
