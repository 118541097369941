<!--
 * @Author: NanNan
 * @Date: 2022-06-28 09:13:16
 * @LastEditTime: 2024-05-18 15:34:59
 * @Description:V1.3.7 主材订单详情-材料明细
 * @Description:V1.5.7 优选订单-材料明细
-->
<template>
  <div>
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="detail_box" v-for="item in _DATA.info" :key="item.id">
      <div class="top_box">
        <div class="left" @click="fileDetail(item.img[0], item.img)">
          <Image class="avader_img" :src="$host + item.img[0].thumbnail_path" />
        </div>
        <div class="right">
          <div class="name_box">
            <p class="name">{{ item.name }}</p>
            <div
              style="flex: 1"
              class="clearfix"
              v-if="item.acceptance_status_name"
            >
              <div class="status_tag red" style="float: right">
                {{ item.acceptance_status_name }}
              </div>
            </div>
          </div>
          <!-- 定制订单 -->
          <p class="info" v-if="props.attr_type == 1">
            <span class="t1">
              {{ item.category_sub_name ? item.category_sub_name + '｜' : '' }}
            </span>
            <span class="t1">
              {{ item.space_name ? item.space_name + '｜' : '' }}
            </span>
            <span class="t1">{{ item.brand ? item.brand + '｜' : '' }}</span>
            <span class="t1">{{ item.sku ? item.sku + '｜' : '' }}</span>
            <span class="t1">{{ item.model ? item.model + '｜' : '' }}</span>
            <span class="t1">{{ item.norms ? item.norms + '｜' : '' }}</span>
            <span class="t1">{{ item.material ? item.material : '' }}</span>
          </p>
          <!-- 优选订单 -->
          <p class="info" v-else>
            <span class="t1">
              {{ item.category_sub_name ? item.category_sub_name + '｜' : '' }}
            </span>
            <span class="t1">
              {{ item.position ? item.position + '｜' : '' }}
            </span>
            <span class="t1">{{ item.brand ? item.brand + '｜' : '' }}</span>
            <span class="t1">{{ item.sku ? item.sku + '｜' : '' }}</span>
            <span class="t1">{{ item.model ? item.model + '｜' : '' }}</span>
            <span class="t1">{{ item.colour ? item.colour : '' }}</span>
          </p>
        </div>
      </div>
      <!-- 定制订单 -->
      <div class="dec_box" v-if="props.attr_type == 1">
        <div class="dec_item">
          <div class="left">成交金额</div>
          <div class="right blue">{{ item.total_deal_price }}元</div>
        </div>
        <div class="dec_item" v-if="_DATA.order_field.indexOf(5) > -1">
          <div class="left">利润率</div>
          <div class="right">
            {{ item.margin || item.margin === 0 ? item.margin + '%' : '-' }}
          </div>
        </div>
        <div class="dec_item" v-if="_DATA.order_field.indexOf(4) > -1">
          <div class="left">集成金额</div>
          <div class="right">
            {{
              item.integrate_money || item.integrate_money === 0
                ? item.integrate_money + '元'
                : '-'
            }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">市场成交价</div>
          <div class="right price">{{ item.total_market_price }}元</div>
        </div>
        <div class="dec_item">
          <div class="left">尚层价</div>
          <div class="right price">{{ item.total_sc_price }}元</div>
        </div>
        <div class="dec_item">
          <div class="left">数量</div>
          <div class="right">
            {{ item.amount }}{{ item.unit }}
            <span v-if="item.return_num_name">
              /
              <span class="red">{{ item.return_num_name }}{{ item.unit }}</span>
            </span>
          </div>
        </div>
        <div class="dec_item">
          <div class="left">备注</div>
          <div class="right">{{ item.remark || '-' }}</div>
        </div>
      </div>
      <!-- 优选订单 -->
      <div class="dec_box" v-else>
        <div class="dec_item">
          <div class="left">销售方式</div>
          <div class="right">{{ item.sale_type_name || '-' }}</div>
        </div>
        <div class="dec_item">
          <div class="left">升级/单独销售价</div>
          <div class="right">
            {{
              item.upgrade_price || item.upgrade_price === 0
                ? item.upgrade_price + '元'
                : '-'
            }}
          </div>
        </div>

        <div class="dec_item">
          <div class="left">升级/单独销售小计</div>
          <div class="right">
            {{
              item.upgrade_total || item.upgrade_total === 0
                ? item.upgrade_total + '元'
                : '-'
            }}
          </div>
        </div>
        <!-- <div class="dec_item">
          <div class="left">活动升级/单独销售小计</div>
          <div class="right">
            {{
              item.activity_upgrade_total || item.activity_upgrade_total === 0
                ? item.activity_upgrade_total + '元'
                : '-'
            }}
          </div>
        </div> -->
        <div class="dec_item" v-if="_DATA.order_field.indexOf(1) > -1">
          <div class="left">采购价</div>
          <div class="right">
            {{
              item.sc_price || item.sc_price === 0 ? item.sc_price + '元' : '-'
            }}
          </div>
        </div>
        <div class="dec_item" v-if="_DATA.order_field.indexOf(2) > -1">
          <div class="left">采购金额小计</div>
          <div class="right">
            {{
              item.total_sc_price || item.total_sc_price === 0
                ? item.total_sc_price + '元'
                : '-'
            }}
          </div>
        </div>
        <div class="dec_item" v-if="_DATA.order_field.indexOf(4) > -1">
          <div class="left">集成金额</div>
          <div class="right">
            {{
              item.integrate_money || item.integrate_money === 0
                ? item.integrate_money + '元'
                : '-'
            }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">数量</div>
          <div class="right">
            {{ item.amount || item.amount === 0 ? item.amount : '-' }}/{{
              item.unit || item.unit === 0 ? item.unit : '-'
            }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">描述</div>
          <div class="right">{{ item.describe || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, inject, onMounted, computed } from 'vue';
import { Notify, Image, Overlay, Loading } from 'vant';
import { fileDetail } from '@/utils/fileUpload';
import { useStore } from 'vuex';
const store = useStore();
const $http = inject('$http');
const $host = inject('$host');
const props = defineProps(['id', 'attr_type']);
const _DATA = reactive({
  loading: false,
  info: [],
  //订单敏感信息字段
  order_field: computed(() => {
    return store.state.account.user_auth?.order_sensitive_auth ?? [];
  }),
});
onMounted(() => {
  loadInfo();
});
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .mainOrder_materialDetails({
      id: props.id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data.list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      setTimeout(() => {
        _DATA.loading = false;
      }, 500);
    });
};
</script>
<style lang="less" scoped>
.detail_box {
  background-color: @white-color;
  padding: 0 16px;
  margin-bottom: 14px;
  .top_box {
    padding: 14px 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    .left {
      .avader_img {
        width: 40px;
        height: 40px;
        // border: 1px solid @line-color;
      }
    }
    .right {
      flex: 1;
      padding-left: 12px;

      .name_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 17px;
          font-weight: bold;
          line-height: 24px;
          padding-right: 5px;
        }
      }
      .status4 {
        display: inline-block;
        border-radius: 2px;
        font-size: 12px;
        line-height: 20px;
        padding: 0 4px;
        background: @red-bg-color;
        color: @red-color;
      }
      .info {
        line-height: 20px;
        .t1 {
          font-size: 13px;
          color: #767676;
        }
      }
    }
  }
  .dec_box {
    padding: 8px 0;
    border-top: 1px solid @line-color;
    .dec_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px;
      font-size: 14px;
      color: @grey-color;
      line-height: 20px;

      .price {
        text-decoration: line-through;
        color: #cccccc;
      }
      .blue {
        color: @blue-color;
      }
      .red {
        color: @red-color;
      }
    }
  }
}
</style>
