<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-03-22 09:39:22
 * @Description: 创建合同/重新生成合同
-->
<template>
  <div class="page-wrap">
    <van-overlay :show="loadingConfirm" class="overlay-fff">
      <van-loading color="#1989fa" type="spinner"></van-loading>
    </van-overlay>
    <div class="title">
      <ul class="clearfix">
        <li
          class="pull-left"
          v-for="i in activeList"
          :key="i.id"
          :class="{ checked: active.indexOf(i.id) > -1 }"
          @click="getActive(i)"
        >
          <p class="p1">{{ i.num }}</p>
          <p class="p2">{{ i.name }}</p>
        </li>
      </ul>
    </div>

    <!-- 不同模板 -->
    <div class="temps">
      <!-- 加载状态 -->
      <van-loading class="loading-box" color="#0094ff" v-if="loading" />
      <!-- 基本信息 -->
      <BaseTemp
        v-if="curActive === 1"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail1"
        @reviewFun="reviewFun"
        @resetFun="resetFun"
        :parameterList="parameterList"
        :parameterListForQuoteOrder="parameterListForQuoteOrder"
        ref="CacheRef1"
      />
      <!-- 房屋信息 -->
      <ZSHouseTemp
        v-if="curActive === 2 && (contractType === 51 || contractType === 52)"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail2"
        :customer_id="customer_id"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForCustomer="parameterListForCustomer"
        ref="CacheRef2"
      />
      <JJDGHouseTemp
        v-if="curActive === 2 && contractType === 53"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail2"
        :customer_id="customer_id"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForCustomer="parameterListForCustomer"
        ref="CacheRef2"
      />
      <JJZSHouseTemp
        v-if="curActive === 2 && contractType === 54"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail2"
        :customer_id="customer_id"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForQuoteOrder="parameterListForQuoteOrder"
        ref="CacheRef2"
      />
      <ZZXSHouseTemp
        v-if="curActive === 2 && contractType === 55"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail2"
        :customer_id="customer_id"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForCustomer="parameterListForCustomer"
        ref="CacheRef2"
      />
      <ZZSGHouseTemp
        v-if="curActive === 2 && contractType === 56"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail2"
        :customer_id="customer_id"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForCustomer="parameterListForCustomer"
        :parameterListForQuoteOrder="parameterListForQuoteOrder"
        ref="CacheRef2"
      />
      <BCXYHouseTemp
        v-if="curActive === 2 && contractType === 69"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail2"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForCustomer="parameterListForCustomer"
        ref="CacheRef2"
      />
      <!-- 合同收款 -->
      <ZSCollectTemp
        v-if="curActive === 3 && (contractType === 51 || contractType === 52)"
        :type="curActive"
        :contractType="contractType"
        :charge_proportions="charge_proportions"
        :contractTypeName="contractTypeName"
        :data="cacheDetail3"
        :house_size="cacheDetail2?.house_size"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        ref="CacheRef3"
      />
      <JJDGCollectTemp
        v-if="curActive === 3 && contractType === 53"
        :type="curActive"
        :contractType="contractType"
        :charge_proportions="charge_proportions"
        :contractTypeName="contractTypeName"
        :data="cacheDetail3"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        ref="CacheRef3"
      />
      <JJZSCollectTemp
        v-if="curActive === 3 && contractType === 54"
        :type="curActive"
        :contractType="contractType"
        :charge_proportions="charge_proportions"
        :contractTypeName="contractTypeName"
        :data="cacheDetail3"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        :parameterListForQuoteOrder="parameterListForQuoteOrder"
        ref="CacheRef3"
      />
      <ZZXSCollectTemp
        v-if="curActive === 3 && contractType === 55"
        :type="curActive"
        :contractType="contractType"
        :charge_proportions="charge_proportions"
        :contractTypeName="contractTypeName"
        :data="cacheDetail3"
        :priceList="cacheDetail2"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        ref="CacheRef3"
      />
      <ZZSGCollectTemp
        v-if="curActive === 3 && contractType === 56"
        :type="curActive"
        :contractType="contractType"
        :charge_proportions="charge_proportions"
        :contractTypeName="contractTypeName"
        :data="cacheDetail3"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        ref="CacheRef3"
      />
      <BCXYCollectTemp
        v-if="curActive === 3 && contractType === 69"
        :type="curActive"
        :contractType="contractType"
        :charge_proportions="charge_proportions"
        :contractTypeName="contractTypeName"
        :data="cacheDetail3"
        @reviewFun="reviewFun"
        :parameterList="parameterList"
        ref="CacheRef3"
      />
      <!-- 项目成员 -->
      <MemberTemp
        v-if="curActive === 4"
        :type="curActive"
        :contractType="contractType"
        :data="cacheDetail4"
        @reviewFun="reviewFun"
        :customer_id="customer_id"
        :parameterListForCustomer="parameterListForCustomer"
        :basicInfo="cacheBasic"
        ref="CacheRef4"
      />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, inject, onBeforeMount, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import BaseTemp from '@/components/contract/BaseTemp';
import ZSHouseTemp from '@/components/contract/ZSHouseTemp';
import ZSCollectTemp from '@/components/contract/ZSCollectTemp';
import JJDGHouseTemp from '@/components/contract/JJDGHouseTemp';
import JJDGCollectTemp from '@/components/contract/JJDGCollectTemp';
import JJZSHouseTemp from '@/components/contract/JJZSHouseTemp';
import JJZSCollectTemp from '@/components/contract/JJZSCollectTemp';
import ZZXSHouseTemp from '@/components/contract/ZZXSHouseTemp';
import ZZXSCollectTemp from '@/components/contract/ZZXSCollectTemp';
import ZZSGHouseTemp from '@/components/contract/ZZSGHouseTemp';
import ZZSGCollectTemp from '@/components/contract/ZZSGCollectTemp';
import BCXYHouseTemp from '@/components/contract/BCXYHouseTemp';
import BCXYCollectTemp from '@/components/contract/BCXYCollectTemp';
import MemberTemp from '@/components/contract/MemberTemp';
import { Popup, Notify, Toast, Loading, Overlay } from 'vant';

export default {
  name: 'contractIndex',
  components: {
    BaseTemp,
    ZSHouseTemp,
    ZSCollectTemp,
    MemberTemp,
    JJDGHouseTemp,
    JJDGCollectTemp,
    JJZSHouseTemp,
    JJZSCollectTemp,
    ZZXSHouseTemp,
    ZZXSCollectTemp,
    ZZSGHouseTemp,
    ZZSGCollectTemp,
    BCXYHouseTemp,
    BCXYCollectTemp,
    [Overlay.name]: Overlay,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
  },
  setup() {
    const $http = inject('$http');
    const router = useRouter();
    const state = reactive({
      loading: false,
      loadingConfirm: false,
      //-权限id
      authId: router.currentRoute.value.query?.authId,
      //-客户id
      customer_id: router.currentRoute.value.query
        ? router.currentRoute.value.query.id
        : '',
      //-合同Id
      contract_id: router.currentRoute.value.query
        ? router.currentRoute.value.query.cid
        : '',
      activeList: [
        { id: 1, num: '01', name: '基础信息' },
        { id: 2, num: '02', name: '房屋信息' },
        { id: 3, num: '03', name: '合同收款' },
        { id: 4, num: '04', name: '项目成员' },
      ],
      active: [],
      curActive: '',
      //-合同类型
      contractType: '',
      contractTypeName: '',
      // 数据缓存
      cacheDetail1: {},
      cacheDetail2: {},
      cacheDetail3: {},
      cacheDetail4: [],
      cacheBasic: {},
      //-参数
      parameterList: [],
      //-客户参数
      parameterListForCustomer: [],
      //-收款期数
      charge_proportions: [],
      //-选报/必报参数
      parameterListForQuoteOrder: [],
    });
    const CacheRef1 = ref(null);
    const CacheRef2 = ref(null);
    const CacheRef3 = ref(null);
    const CacheRef4 = ref(null);

    onBeforeMount(() => {
      getPublicParameter();
    });
    onUpdated(() => {
      window.scroll(0, 0);
    });
    //-计算
    //-获取参数
    const getPublicParameter = async () => {
      state.loading = true;
      await $http
        .getContractPublicParameter({ customer_id: state.customer_id })
        .then((res) => {
          if (res.code === 0) {
            state.parameterList = res.data;
            if (state.customer_id) {
              getQuoteOrderForContract();
              getCustomerParameter();
            }
            if (state.contract_id && !state.authId) {
              getContractInfo();
            } else {
              state.active = [1];
              state.curActive = 1;
            }
          } else {
            Toast(res.data);
          }
        });
    };
    //-获取客户参数
    const getCustomerParameter = () => {
      $http
        .getContractCustomerParameter({ customer_id: state.customer_id })
        .then((res) => {
          state.loading = false;
          if (res.code === 0) {
            state.parameterListForCustomer = res.data;
          } else {
            Toast(res.data);
          }
        });
    };
    //-获取选报/必报参数
    const getQuoteOrderForContract = async () => {
      await $http
        .getQuoteOrderForContract({ customer_id: state.customer_id })
        .then((res) => {
          state.loading = false;
          if (res.code == 0) {
            state.parameterListForQuoteOrder = res.data;
          } else {
            Toast(res.data);
          }
        });
    };
    //-获取合同详情
    const getContractInfo = async () => {
      await $http
        .getContractInfoApi({ contract_id: state.contract_id })
        .then((res) => {
          state.loading = false;
          state.active = [1];
          state.curActive = 1;
          if (res.code === 0) {
            state.contractType = res.data.basic_into.type;
            state.contractTypeName = res.data.basic_into.name;
            state.cacheDetail1 = res.data.basic_into;
            state.cacheDetail2 = res.data.basic_into;
            state.cacheDetail3 = res.data.basic_into;
            state.cacheDetail4 = res.data.project_members;
            state.cacheBasic = res.data.basic_into;
          } else {
            Toast(res.data);
          }
        });
    };
    //-切换类型
    const getActive = (data) => {
      if (data.id === state.curActive) {
        return;
      } else {
        //-暂存之前的数据-只在合同类型修改恢复初始状态
        if (state.active.indexOf(data.id) > -1) {
          if (state.curActive == 1) {
            state.cacheDetail1 = CacheRef1.value ? CacheRef1.value.form : {};
          } else if (state.curActive == 2) {
            state.cacheDetail2 = CacheRef2.value ? CacheRef2.value.form : {};
          } else if (state.curActive == 3) {
            state.cacheDetail3 = CacheRef3.value ? CacheRef3.value.form : {};
          } else if (state.curActive == 4) {
            state.cacheDetail4 = CacheRef4.value
              ? CacheRef4.value.form.list
              : {};
            state.cacheBasic.is_assign = CacheRef4.value
              ? CacheRef4.value.form.is_assign
              : null;
          }
          state.curActive = data.id;
        } else {
          Toast('请先完善当前信息');
        }
      }
    };
    //-合同类型变更数据重置
    const resetFun = (form) => {
      if (state.contractType == form.type) {
        return;
      } else {
        state.active = [1];
        state.curActive = 1;
        state.cacheDetail2 = {};
        state.cacheDetail3 = {};
        state.cacheDetail4 = [];
        state.cacheBasic = {};
      }
      state.contractType = form.type;
      state.charge_proportions = form.charge_proportions;
      state.contractTypeName = form.typeName;
    };
    //-下一步操作
    const reviewFun = (active, form) => {
      if (active === 4) {
        console.log(form);
        let list = form.list;
        getOperateFun(
          list.map((v) => {
            return v.id;
          }),
          form.is_assign
        );
      } else {
        state.active.push(active + 1);
        state.curActive = active + 1;
        if (active === 1) {
          state.cacheDetail1 = form;
          state.charge_proportions = form.charge_proportions;
          state.contractTypeName = form.name;
          state.contractType = form.type;
        } else if (active === 2) {
          state.cacheDetail2 = form;
        } else if (active === 3) {
          state.cacheDetail3 = form;
        }
      }
    };

    const getOperateFun = (member, is_assign) => {
      console.log(is_assign);
      state.loadingConfirm = true;
      let $url = '';
      if (state.contractType == 51 || state.contractType == 52) {
        $url = $http.getSignDecorateContract;
      } else if (state.contractType == 53) {
        $url = $http.getSignEntrustBuyContract;
      } else if (state.contractType == 54) {
        $url = $http.getSignRoomDecorationContract;
      } else if (state.contractType == 55) {
        $url = $http.getSignServiceSellContract;
      } else if (state.contractType == 56) {
        $url = $http.getSignAssemblyWorks;
      } else if (state.contractType == 69) {
        $url = $http.getSignDecorationMatching;
      }
      $url({
        source_type: 1, //1 普通创建 2变更合同创建
        customer_id: state.customer_id,
        type: state.cacheDetail1.type,
        remarks: state.cacheDetail1.remarks,
        file_url: state.cacheDetail1.file_url,
        ...state.cacheDetail2,
        ...state.cacheDetail3,
        project_members: member,
        is_assign: is_assign,
      }).then((res) => {
        const { code, data } = res;
        state.loadingConfirm = false;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              if (state.contract_id) {
                router.push('/contractSign?id=' + state.customer_id);
              } else {
                router.push('/sale?id=' + state.customer_id);
              }
            },
          });
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
    };

    return {
      ...toRefs(state),
      getActive,
      reviewFun,
      CacheRef1,
      CacheRef2,
      CacheRef3,
      CacheRef4,
      resetFun,
      getOperateFun,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/index.less';
</style>
