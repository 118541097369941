<!--
 * @Author: Jessica
 * @Date: 2022-04-29 09:00:31
 * @LastEditTime: 2023-02-21 16:56:56
 * @Description: 
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <NavBar
      safe-area-inset-top
      left-arrow
      :title="
        form.list.type == 1
          ? '申请量房'
          : form.list.type == 2
          ? '完成量房'
          : '取消量房'
      "
      @click-left="onBack"
      :fixed="true"
    />
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell" v-if="form.list.type == 1 || form.list.type == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              量房时间
            </label>
            <Field
              v-model="form.list.measure_date"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择量房时间"
              :rules="[
                {
                  required: true,
                  message: '请选择量房时间',
                },
              ]"
              @click="openDateType('measure_date')"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              备注
            </label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              :rules="[
                {
                  required: true,
                  message: '请添加备注',
                },
              ]"
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.path_arr"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-if="form.list.type == 2"
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :max-date="state.maxDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.measure_date = '';
          }
        "
      />
      <DatetimePicker
        v-else
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="state.minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.measure_date = '';
          }
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import FileUpload from '@/components/common/FileUpload';

import { inject, reactive, ref } from 'vue';
import {
  NavBar,
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-全部待办类型
  dtype: router.currentRoute.value.query
    ? router.currentRoute.value.query.dtype
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  maxDate: new Date(),
  currentDate: new Date(),
});
//-bar返回操作
const onBack = () => {
  router.back();
};

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    type: router.currentRoute.value.query
      ? router.currentRoute.value.query.authId == 25
        ? '1'
        : router.currentRoute.value.query.authId == 26
        ? '2'
        : router.currentRoute.value.query.authId == 62
        ? '3'
        : ''
      : '',
    measure_date: '',
    remark: '',
    path_arr: [],
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .getMeasureHouse({
          ...form.list,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                // 哪里来回哪里去
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end

const getFileList = (files) => {
  form.list.path_arr = files;
};
//--上传附件end----------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
