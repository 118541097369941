<!--
 * @Author: Jessica
 * @Date: 2023-06-07 15:05:51
 * @LastEditTime: 2024-01-06 14:01:39
 * @Description: 产品预收款
-->
<template>
  <div class="page-box formBase">
    <div class="content-box">
      <!-- tab切换 -->
      <div class="stick-box-top">
        <div class="btn_radio">
          <div
            class="item"
            :class="active == 1 ? 'choose' : ''"
            @click="changeActive(1)"
          >
            预收款-收款
          </div>
          <div
            class="item"
            :class="active == 2 ? 'choose' : ''"
            @click="changeActive(2)"
          >
            预收款-退款
          </div>
        </div>
      </div>
      <!-- 产品预收款-收款 -->
      <div class="main_get" v-if="active == 1">
        <prepaymentIn :customer_id="customer_id" />
      </div>
      <!-- 产品预收款-退款 -->
      <div class="main_get" v-if="active == 2">
        <prepaymentOut :customer_id="customer_id" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import prepaymentIn from '@/components/collection/prepaymentIn';
import prepaymentOut from '@/components/collection/prepaymentOut';
const router = useRouter();
const customer_id = ref(router.currentRoute.value.query.id);
// @切换选项卡
const active = ref(1);
const changeActive = (type) => {
  if (active.value !== type) {
    active.value = type;
    window.scroll(0, 0);
  }
};
</script>
<style lang="less" scoped>
//-头部 Tab
.stick-box-top {
  position: fixed;
  left: 20px;
  top: 44px;
  width: calc(100% - 40px);
  padding: 20px 0;
  z-index: 1;
  background: #fff;
}
.btn_radio {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 2px;
  .item {
    width: 50%;
    border-radius: 6px;
    padding: 4px 0;
    text-align: center;
    font-size: 15px;
  }
  .choose {
    background: @white-color;
    font-weight: bold;
  }
}

//-模块切换
.main_get {
  padding-top: 60px;
}
</style>
