/*
 * @Author: NanNan
 * @Date: 2022-04-22 09:32:09
 * @LastEditTime: 2023-12-22 15:49:06
 * @Description:
 */
const numReg = /^\+?[1-9][0-9]*$/; //正整数
const phoneReg = /^(1\d{10})$/;
const accountReg = /^[0-9]*$/; //-银行账号纯数字
const emailReg =
  /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
const cardIDReg = /^([1-9]{1})(\d{14}|\d{17})$/;
const qqReg = /^[1-9]\d{4,20}$/;
const wechatReg = /^[^\u4E00-\u9FA5A]+$/;
const IDReg =
  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //身份证号码
const priceReg = /^(\d{0,7})(\.(\d{0,2}))?$/; //增减项单价-最多输入7位整数，两位小数
const proNumReg = /^(\d{0,8})(\.(\d{0,2}))?$/; //数量-最多输入8位整数，两位小数
const houseSizeReg = /^(\d{0,4})(\.(\d{0,2}))?$/; //房屋面积-最多输入4位整数，两位小数
const discountReg = /^(\d{0,2})(\.(\d{0,2}))?$/; //折扣-最多输入2位整数，两位小数
export default {
  phoneReg,
  emailReg,
  cardIDReg,
  qqReg,
  wechatReg,
  IDReg,
  numReg,
  priceReg,
  houseSizeReg,
  proNumReg,
  discountReg,
  accountReg,
};
