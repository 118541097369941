/*
 * @Author: NanNan
 * @Date: 2022-04-12 14:19:59
 * @LastEditTime: 2024-05-18 15:21:06
 * @Description:
 */

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@vant/touch-emulator';
import Api from '@/api';
import { store } from '@/store';
import { Lazyload, Notify } from 'vant';

import uploader from 'vue-simple-uploader';
import 'vue-simple-uploader/dist/style.css';

import '@/assets/styles/variable.less';
import '@/assets/styles/index.less';

const app = createApp(App);

app.provide('$host', process.env.NODE_ENV === 'production' ? '' : '/api');
app.provide('$http', Api);
app.use(store);
app.use(router);
app.use(Lazyload, {
  lazyComponent: true,
});

app.use(Notify);
Notify.setDefaultOptions({ duration: 1000 });

app.use(uploader);

app.mount('#app');

//路由拦截
router.afterEach((to) => {
  if (
    !(
      to.path == '/authA' ||
      to.path == '/authC' ||
      to.path == '/auth' ||
      to.path == '/' ||
      to.path == '/login' ||
      to.path == '/followInfoShare'
    )
  ) {
    store.dispatch('account/getOperationAuthFunc');
    store.dispatch('account/getUserAuthFunc');
  }
  window.scroll(0, 0);
  document.querySelector('body').setAttribute('style', 'overflow: auto;');
});
