<!--
 * @Author: gest
 * @Date: 2022-09-13 14:47:02
 * @LastEditTime: 2024-05-31 15:01:35
 * @Description: 线索详情
 * v1.3.2 smart 增加卡片信息，跟新相关，线索页面展示模块调整
 * v1.3.3 smart 线索管理整改 - 待完善线索+企微好友 更改为 全部线索+企微线索
 * v1.3.4 smart 新增变更分配按钮 - 1根据全部线索244批量分配权限，2渠道来源 === 0系统同步 不展示跟进按钮以及跟进记录相关的卡片信息
 * v1.3.5 smart 新增派单记录
 * v1.3.6 smart 线索轮派 通用
 * v1.3.7 smart 设计派单前置，根据按钮多少UX变更
 * v1.4.3 smart 取消死单、线索死单、线索改派（企微不做）
 * v1.4.4 smart 见面记录去除 编辑权限判断
 * v1.4.7 smart 线索版面 - 改版
 * v1.4.8 smart 新增变更分配弹窗修改
 * v1.5.1 smart 更多操作增加发送小程序，详情展示新增字段
 * v1.5.5 smart 交互更改布局、增加附件查看功能
 * v1.5.6 smart 增加【线索改派】
 * v1.5.7 smart [线索轮派]轮派功能升级，新增 组内轮派、跨组轮派；新增客户收藏设计师
 * v1.5.9 smart 展示“经常关注”
 * v1.5.9 smart 申请派单 -> 设计派单、生成多派 -> 设计多派、发起改派 -> 设计改派
 * v1.6.1 smart 联合客资表整改
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <!-- 头部卡片 -->
    <div class="head_box">
      <div class="left">
        <div class="child">
          <Image
            v-if="info.info_base.face_image"
            class="img_head"
            :src="info.info_base.face_image"
          ></Image>
          <Image
            v-else
            class="img_head"
            :src="
              info.info_base.gender
                ? _DATA.imageList[info.info_base.gender]
                : _DATA.imageList[0]
            "
          ></Image>
        </div>
      </div>
      <div class="center">
        <div class="title">
          <span class="name">{{ info.info_base.name || '姓名未知' }}</span>
          <span class="split">-</span>
          <span class="floor">
            {{ info.info_base.floor_name || '楼盘未知' }}
          </span>
          <span class="split">-</span>
          <span class="size">
            {{
              info?.info_base?.size === ''
                ? '面积未知'
                : info?.info_base?.size + '㎡'
            }}
          </span>
        </div>
        <div
          class="person-div"
          v-if="
            info.info_base.channel_source_user_name ||
            info.info_base.invite_user_name
          "
        >
          <div class="desc">
            <span class="person" v-if="info.info_base.channel_source_user_name">
              {{ info.info_base.channel_source_user_name }}
            </span>
            <em class="ms" v-if="info.info_base.channel_source_user_name">
              来源人
            </em>
            <span class="person" v-if="info.info_base.invite_user_name">
              {{ info.info_base.invite_user_name }}
            </span>
            <em class="ms" v-if="info.info_base.invite_user_name">邀约人</em>
          </div>
        </div>
        <!-- 标签区域 -->
        <div class="card-box">
          <!-- 阶段 -->
          <span class="blue" v-if="info.label?.clue_step?.label_name">
            {{ info.label?.clue_step?.label_name }}
          </span>
          <!-- 死单 -->
          <span class="gray" v-if="info.label?.is_clue_dead">
            {{ info.label?.is_clue_dead }}
          </span>
          <!-- ABCD类 -->
          <span
            v-if="info.label?.clue_level?.label_name"
            :class="{
              green: info.label?.clue_level?.id == 1,
              gray: info.label?.clue_level?.id == 2,
              orange: info.label?.clue_level?.id == 3,
            }"
          >
            {{ info.label?.clue_level?.label_name }}
          </span>
          <!-- 撞单 -->
          <span class="gray" v-if="info.label?.clue_dash">
            {{ info.label?.clue_dash }}
          </span>
          <!-- 跟进的牌子 -->
          <span
            :class="{
              red: info.label?.clue_overtime?.id == 1,
              blue:
                info.label?.clue_overtime?.id == 2 ||
                info.label?.clue_overtime?.id == 3,
              gray: info.label?.clue_overtime?.id > 3,
            }"
          >
            {{ info.label?.clue_overtime?.label_name }}
          </span>
        </div>
      </div>
    </div>
    <!-- tab切换 -->
    <Tabs
      v-model:active="_DATA.tabIndex"
      title-active-color="#000"
      title-inactive-color="#8D9091"
      type="line"
      line-width="40px"
      swipeable
      border
      animated
    >
      <Tab title="项目" name="1">
        <div class="project-box">
          <ul>
            <li>
              <label>线索编号</label>
              <p>
                <span>{{ info.info_base.clue_no || '-' }}</span>
                <em
                  class="copy"
                  v-if="info.info_base.clue_no"
                  @click="copyToClipboard(info.info_base.clue_no)"
                >
                  复制
                </em>
              </p>
            </li>
            <li>
              <label>客户姓名</label>
              <span>{{ info.info_base.name || '-' }}</span>
            </li>
            <li>
              <label>联系方式</label>
              <p>
                <span>{{ info.info_base.phone || '-' }}</span>
                <em
                  class="copy"
                  v-if="info.info_base.phone"
                  @click="copyToClipboard(info.info_base.phone)"
                >
                  复制
                </em>
              </p>
            </li>
            <li>
              <label>性别</label>
              <span>{{ info.info_base.gender_name || '-' }}</span>
            </li>
            <li v-if="info.info_base.level_name">
              <label>线索等级</label>
              <span>{{ info.info_base.level_name || '-' }}</span>
            </li>
            <li>
              <label>项目等级</label>
              <span>{{ info.info_base.dispatch_level_name || '-' }}</span>
            </li>
            <li>
              <label>楼盘</label>
              <span>{{ info.info_base.floor_name || '-' }}</span>
            </li>
            <li>
              <label>单元栋号</label>
              <span>{{ info.info_base.floor_detail_name || '-' }}</span>
            </li>
            <li>
              <label>所在区域</label>
              <span v-if="info.info_base.is_city_unknown == 2">
                {{ info.info_base.city_name || '-' }}
              </span>
              <span v-else>未知</span>
            </li>
            <li>
              <label>面积</label>
              <span v-if="info.info_base.is_size_unknown == 2">
                {{ info.info_base.size ? info.info_base.size + '㎡' : '-' }}
              </span>
              <span v-else>未知</span>
            </li>
            <li>
              <label>详细地址</label>
              <span>{{ info.info_base.address || '-' }}</span>
            </li>

            <li>
              <label>购房情况</label>
              <span v-if="info.info_base.house_type_name">
                {{ info.info_base.house_type_name }}
                {{
                  ['期房', '半年内期房'].indexOf(
                    info.info_base?.house_type_name
                  ) > -1
                    ? '预计时间' +
                      (info.info_base.is_time_unknown == 1
                        ? '未知'
                        : info.info_base.plan_delivery_day)
                    : ''
                }}
                {{
                  ['未购房'].indexOf(info.info_base?.house_type_name) > -1
                    ? '预计时间' +
                      (info.info_base.is_time_unknown == 1
                        ? '未知'
                        : info.info_base.plan_buy_day)
                    : ''
                }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>精装/毛坯</label>
              <span>{{ info.info_base.house_category_name || '-' }}</span>
            </li>
            <li>
              <label>房屋结构</label>
              <span>{{ info.info_base.home_type_name || '-' }}</span>
            </li>
          </ul>
          <!-- 附件信息 -->
          <p class="same-title">
            <span>附件信息</span>
          </p>
          <div
            class="fileList"
            v-if="info.info_base.files && info.info_base.files.length > 0"
          >
            <FileGrid :data="info.info_base.files"></FileGrid>
          </div>
          <p v-else class="no-message">暂无信息</p>
        </div>
      </Tab>
      <Tab title="跟进" name="2">
        <div class="other-box">
          <ul>
            <li>
              <label>跟进次数</label>
              <span v-if="info.clue_follow_info?.record_count">
                {{ info.clue_follow_info?.record_count }}次
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>最后跟进时间</label>
              <p class="flex-row">
                <span>
                  {{ info.clue_follow_info?.record_last_follow_time || '-' }}
                </span>
                <em
                  class="bj"
                  v-if="info.clue_follow_info?.record_count_day !== ''"
                >
                  {{ info.clue_follow_info?.record_count_day + '天' }}
                </em>
              </p>
            </li>
            <li>
              <label>预计下次跟进</label>
              <p class="flex-row">
                <span>
                  {{ info.clue_follow_info?.next_record_time || '-' }}
                </span>
                <em
                  class="bj"
                  v-if="info.clue_follow_info?.next_record_time_day !== ''"
                  :class="{ red: info.clue_follow_info?.is_over_time?.id == 1 }"
                >
                  {{ info.clue_follow_info?.is_over_time?.label_name }}
                </em>
              </p>
            </li>
          </ul>
          <p class="same-title">
            <span>小程序发送情况</span>
          </p>
          <ul>
            <li>
              <label>企微实际添加情况</label>
              <span v-if="info.send">
                {{
                  info.send?.wechat_friend_status == 1
                    ? '已添加'
                    : info.send?.wechat_friend_status == 2
                    ? '未添加'
                    : '-'
                }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>发送总数</label>
              <span v-if="info.send">
                {{ '共' + info.send?.total_count + '次' }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>首页</label>
              <span v-if="info.send">
                {{ '共' + info.send?.home_count + '次' }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>公司介绍</label>
              <span v-if="info.send">
                {{ '共' + info.send?.introduce_count + '次' }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>推荐卡片</label>
              <span v-if="info.send">
                {{
                  '共' +
                  info.send?.share_card_count +
                  '次（客户浏览：' +
                  info.send?.card_browse_count +
                  '次）'
                }}
              </span>
              <span v-else>-</span>
            </li>
          </ul>
          <div v-if="info.clue_intention?.applet_intention_id">
            <p
              class="same-title2"
              :class="{
                red: info.clue_intention?.applet_intention_id == 1,
                green: info.clue_intention?.applet_intention_id == 2,
                gray: info.clue_intention?.applet_intention_id == 3,
              }"
            >
              <em></em>
              <span>{{ info.clue_intention?.applet_intention_name }}</span>
            </p>
            <ul>
              <li>
                <span>
                  {{
                    info.clue_intention?.applet_intention_content_item || '----'
                  }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Tab>
      <Tab title="派单" name="3">
        <div class="send-box">
          <p class="same-title plr_15">
            <span>派单记录</span>
          </p>
          <div v-if="info.distribute_log?.length > 0" class="meet-list">
            <TransitionGroup name="list" tag="div">
              <div
                class="list-item"
                v-for="(item, index) in info.distribute_log"
                :key="index"
                @click.stop="jumpTo('record', item.dispatch_id)"
              >
                <div class="list-item-left">
                  <em
                    class="link"
                    @click.stop="jumpTo('sale', item.customer_id)"
                  >
                    {{ item.customer_no }}
                  </em>
                  <!-- 多、改逻辑(有改显示改，有多无改显示多) -->
                  <!-- 多 -->
                  <em class="tag red" v-if="item.many_dis && !item.change_dis">
                    {{ item.many_dis }}
                  </em>
                  <!-- 改 -->
                  <em class="tag redin" v-if="item.change_dis">
                    {{ item.change_dis }}
                  </em>
                  <!-- end -->
                  <!-- 设计中心 -->
                  <em class="text" v-if="item.dep_name">
                    {{ item.dep_name }}
                  </em>
                  <!-- 以下是逻辑互斥 -->
                  <!-- 1.显示 已签约 时，不展示待见面、已见面、未派单 -->
                  <div v-if="item.signed_name">
                    <!-- 已签约 -->
                    <em class="tag gray" v-if="item.signed_name">
                      {{ item.signed_name + item.signed_time }}
                    </em>
                    <!-- 派单中 -->
                    <em class="tag gray" v-if="item.progress_dis">
                      {{ item.progress_dis }}
                    </em>
                  </div>
                  <!-- 2.显示 已见面&&!已签约 时，不显示 未派单-->
                  <div v-if="item.already_meet && !item.signed_name">
                    <!-- 已见面 -->
                    <em class="tag gray" v-if="item.already_meet">
                      {{ item.already_meet + item.already_meet_time }}
                    </em>
                    <!-- 派单中 -->
                    <em class="tag gray" v-if="item.progress_dis">
                      {{ item.progress_dis }}
                    </em>
                  </div>
                  <!-- 3.显示 待见面时候 = !已见面&&!已签约 时 -->
                  <div v-if="!item.already_meet && !item.signed_name">
                    <!-- 待见面 -->
                    <em class="tag gray" v-if="item.wait_meet">
                      {{ item.wait_meet + item.meet_time }}
                    </em>
                    <!-- 派单中 -->
                    <em class="tag gray" v-if="item.progress_dis">
                      {{ item.progress_dis }}
                    </em>
                    <!-- 未派单 -->
                    <em class="tag gray" v-if="item.no_dis">
                      {{ item.no_dis }}
                    </em>
                  </div>
                  <!-- 互斥end -->
                  <em
                    class="btn"
                    @click="revocationFunc(item.customer_id, index)"
                    v-if="
                      item.no_dis && !item.already_meet && !item.signed_name
                    "
                  >
                    撤销
                  </em>
                </div>
                <div class="list-item-right" v-if="item.dispatch_id">
                  <Icon name="arrow" color="#C9CDD4" />
                </div>
              </div>
            </TransitionGroup>
          </div>
          <p v-else class="no-message plr_15">暂无记录</p>
        </div>
        <div class="other-box" style="margin-top: 0px">
          <p class="same-title">
            <span>可指派设计师中心</span>
          </p>
          <ul>
            <!-- v1.5.1新增字段 -->
            <li>
              <label>已选设计师</label>
              <span v-if="info.info_base?.reservation?.length > 0">
                {{
                  info.info_base.reservation
                    .map((item) => `${item.value} `)
                    .join('、')
                }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>推荐未选择</label>
              <span v-if="info.info_base?.recommend?.length > 0">
                {{
                  info.info_base.recommend
                    .map((item) => `${item.value} `)
                    .join('、')
                }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <label>客户取消</label>
              <span v-if="info.info_base?.cancel?.length > 0">
                {{
                  info.info_base.cancel
                    .map((item) => `${item.value} `)
                    .join('、')
                }}
              </span>
              <span v-else>-</span>
            </li>
            <!-- end -->
            <!-- v1.5.7 新增客户收藏设计师 -->
            <li>
              <label>收藏设计师</label>
              <span v-if="info.info_base?.collect?.length > 0">
                {{
                  info.info_base.collect
                    .map((item) => `${item.value} `)
                    .join('、')
                }}
              </span>
              <span v-else>-</span>
            </li>
            <!-- end -->
            <!-- v1.5.9 新增经常关注 -->
            <li>
              <label>经常关注</label>
              <span v-if="info.info_base?.often_care?.length > 0">
                {{
                  info.info_base.often_care
                    .map((item) => `${item.value} `)
                    .join('、')
                }}
              </span>
              <span v-else>-</span>
            </li>
            <!-- end -->
          </ul>
        </div>
      </Tab>
      <Tab title="其他" name="4">
        <div class="other-box">
          <!-- 企微好友添加状态 -->
          <p class="same-title">
            <span>企微好友添加</span>
          </p>
          <ul>
            <li>
              <label>添加状态</label>
              <span>
                {{ info.info_base.wechat_friend_status_name || '-' }}
              </span>
            </li>
            <li>
              <label>external_ userld</label>
              <span>{{ info.info_base.external_userid || '-' }}</span>
            </li>
            <li>
              <label>unionld</label>
              <span>{{ info.info_base.wechat_unionid || '-' }}</span>
            </li>
            <li>
              <label>企微联系人</label>
              <span>
                {{
                  info.info_base.wechat_contact_name
                    ? info.info_base.wechat_contact_name +
                      '[' +
                      info.info_base.wechat_contact_company +
                      '/' +
                      info.info_base.wechat_contact_department +
                      '/' +
                      info.info_base.wechat_contact_post +
                      ']'
                    : '-'
                }}
              </span>
            </li>
            <li>
              <label>添加客户时间</label>
              <span>
                {{ info.info_base.wechat_customer_add_time || '-' }}
              </span>
            </li>
            <li>
              <label>好友添加方式</label>
              <span>
                {{ info.info_base.wechat_friend_add_way_name || '-' }}
              </span>
            </li>
          </ul>
          <!-- 来源 -->
          <p class="same-title">
            <span>来源</span>
          </p>
          <ul>
            <li>
              <label>渠道来源</label>
              <span>{{ info.info_base.channel_source_name || '-' }}</span>
            </li>
            <li>
              <label>申请人</label>
              <span>
                {{
                  FormatFullName(
                    info.info_base?.channel_user_name,
                    info.info_base?.channel_user_company,
                    info.info_base?.channel_user_department,
                    info.info_base?.responsible_user_post
                  )
                }}
              </span>
            </li>
            <li>
              <label>来源人</label>
              <span>
                {{
                  FormatFullName(
                    info.info_base?.channel_source_user_name,
                    info.info_base?.channel_source_user_company,
                    info.info_base?.channel_source_user_department,
                    info.info_base?.channel_source_user_post
                  )
                }}
              </span>
            </li>
            <li>
              <label>订单号</label>
              <span>{{ info.info_base.channel_no || '-' }}</span>
            </li>
            <li>
              <label>渠道信息</label>
              <span>{{ info.info_base.channel_info || '-' }}</span>
            </li>
          </ul>
          <!-- 系统信息 -->
          <p class="same-title">
            <span>系统信息</span>
            <span class="pull-right record" @click="distributionRecord">
              <svg class="icon-small" aria-hidden="true">
                <use xlink:href="#icon-file-list-fill"></use>
              </svg>
              分配历史
            </span>
          </p>
          <ul>
            <li>
              <label>已转客户编号</label>
              <span>
                {{ info.info_base.clue_turn_customer_no || '-' }}
              </span>
            </li>
            <li>
              <label>最近转客户时间</label>
              <span>
                {{ info.info_base.turn_customer_time || '-' }}
              </span>
            </li>
            <li>
              <label>线索创建</label>
              <div v-if="info.info_base.add_uid == -1">系统机器人</div>
              <div v-else-if="info.info_base.add_user_name">
                <p>
                  {{
                    FormatFullName(
                      info.info_base?.add_user_name,
                      info.info_base?.add_user_company,
                      info.info_base?.add_user_department,
                      info.info_base?.add_user_post
                    )
                  }}
                </p>
                <p>{{ info.info_base.add_time }}</p>
              </div>
              <div v-else>-</div>
            </li>
            <li>
              <label>最近修改</label>
              <div v-if="info.info_base.update_uid == -1">系统机器人</div>
              <div v-else-if="info.info_base.update_user_name">
                <p>
                  {{
                    FormatFullName(
                      info.info_base?.update_user_name,
                      info.info_base?.update_user_company,
                      info.info_base?.update_user_department,
                      info.info_base?.update_user_post
                    )
                  }}
                </p>
                <p>{{ info.info_base.update_time }}</p>
              </div>
              <div v-else>-</div>
            </li>
            <li>
              <label>分配规则</label>
              <span>
                {{ info.info_base.distribution_rule_name || '-' }}
              </span>
            </li>
            <li>
              <label>最近分配</label>
              <div v-if="info.info_base.distribution_user_name">
                <p>
                  {{
                    FormatFullName(
                      info.info_base?.distribution_user_name,
                      info.info_base?.distribution_user_company,
                      info.info_base?.distribution_user_department,
                      info.info_base?.distribution_user_post
                    )
                  }}
                </p>
                <p>{{ info.info_base.distribution_time }}</p>
              </div>
              <div v-else>-</div>
            </li>
          </ul>
        </div>
      </Tab>
    </Tabs>

    <!-- 判断权限 -->
    <div
      class="stick-box"
      v-if="
        allOperationAuthority && BtnList.length && info.info_base.status !== 1
      "
    >
      <!-- 按钮个数<=3 -->
      <Row :gutter="16" justify="space-between" v-if="BtnList?.length <= 3">
        <Col
          v-for="item in BtnList"
          :key="item.name"
          :span="BtnList?.length > 2 ? 8 : 24 / BtnList?.length"
        >
          <Button
            :type="item.btn_type"
            size="large"
            @click="ActionSheetFunc(item.name)"
          >
            {{ item.name }}
          </Button>
        </Col>
      </Row>
      <Row :gutter="16" justify="space-between" v-else>
        <Col v-for="item in BtnList.slice(0, 2)" :key="item.name" :span="8">
          <Button
            :type="item.btn_type"
            size="large"
            @click="ActionSheetFunc(item.name)"
          >
            {{ item.name }}
          </Button>
        </Col>
        <Col :span="8">
          <Button size="large" @click="ActionSheetFunc('更多')">更多</Button>
        </Col>
      </Row>
    </div>
    <div
      class="stick-box"
      v-if="
        allOperationAuthority.indexOf('239') > -1 &&
        info.info_base.clue_edit == 1 &&
        info.info_base.status === 1 &&
        (allOperationAuthority.indexOf('541') > -1 ||
          allOperationAuthority.indexOf('542') > -1)
      "
    >
      <Row :gutter="16" justify="space-between">
        <Col :span="24">
          <Button
            type="primary"
            size="large"
            @click="ActionSheetFunc('派单分配')"
          >
            派单分配
          </Button>
        </Col>
      </Row>
    </div>
    <!-- 邀约人分配记录-->
    <Popup
      v-model:show="_DATA.showPicker_distributionRecord"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <clueDistributionRecord
        v-if="_DATA.showPicker_distributionRecord"
        :data="_DATA.clue_id"
        @reviewClose="
          () => {
            _DATA.showPicker_distributionRecord = false;
          }
        "
      />
    </Popup>
    <!-- 线索轮派 -->
    <Popup
      v-model:show="_DATA.allot_picker.show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <TempPerson
        v-if="_DATA.allot_picker.show"
        :data="_DATA.allot_picker"
        @reviewFunc="reviewFunc"
      />
    </Popup>
    <!-- v1.4.8变更分配新版、v1.5.6线索改派、v1.5.7线索轮派-->
    <Popup
      v-model:show="changePopup.show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <TempChangeOperation
        v-if="changePopup.show"
        :data="changePopup"
        :allOperationAuthority="allOperationAuthority"
        @reviewFunc="reviewFunc"
      />
    </Popup>
    <!-- end -->
  </div>
  <!-- 动作面板 - 更多 -->
  <ActionSheet
    v-model:show="_DATA.showMoreAction"
    cancel-text="取消"
    close-on-click-action
    @cancel="
      () => {
        _DATA.showMoreAction = false;
      }
    "
  >
    <div class="van-action-sheet__content" style="padding: 10px 0">
      <div
        @click="ActionSheetFunc(item.name)"
        v-for="item in BtnList.slice(2)"
        :key="item.name"
      >
        <Button class="van-action-sheet__item" type="button">
          <span class="van-action-sheet__name" style="font-size: 16px">
            {{ item.name }}
          </span>
        </Button>
      </div>
    </div>
  </ActionSheet>
  <!-- 动作面板 - 分享内容 -->
  <ActionSheet
    v-model:show="action_share_show"
    title="请选择分享内容"
    @cancel="
      () => {
        action_share_show = false;
      }
    "
  >
    <Overlay :show="wechart_loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <ul class="action_sheet_list" style="padding: 10px 0">
      <li
        @click="share(item)"
        v-for="item in wechartInfo.occ_list"
        :key="item.type_name"
      >
        <p class="h1">{{ item.type_name }}</p>
      </li>
      <template v-if="wechartInfo.designer_list.length > 0">
        <li
          @click="share(item)"
          v-for="item in wechartInfo.designer_list"
          :key="item.name"
        >
          <p class="h1">{{ item.type_name }}</p>
          <!-- <p class="des">
          <span>从业{{ item.practitioner_num }}年</span>
          <span>服务客户数{{ item.service_item_number }}个</span>
          <span>设计费{{ item.design_fee_name }}</span>
        </p>
        <p class="time" v-if="item.op_type == 1">
          客户预约时间：{{ item.time }}
        </p>
        <p class="time" v-if="item.op_type == 2">
          客户推荐时间：{{ item.time }}
        </p>
        <p class="time" v-if="item.op_type == 3">
          客户取消时间：{{ item.time }}
        </p>
        <p class="time" v-if="item.op_type == 4">
          客户收藏时间：{{ item.time }}
        </p> -->
        </li>
      </template>
      <template v-else>
        <li>
          <p class="h1 gray">小程序卡片-推荐设计师</p>
          <p class="des">
            <span>
              客户可推荐的设计师人数、收藏或浏览案例次数不满足发送条件
            </span>
          </p>
        </li>
      </template>
    </ul>
  </ActionSheet>
</template>
<script setup>
import { onMounted, reactive, inject, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  Button,
  Overlay,
  Loading,
  Notify,
  Popup,
  Image,
  Dialog,
  ActionSheet,
  Icon,
  Row,
  Col,
  Tabs,
  Tab,
} from 'vant';
import clueDistributionRecord from '@/components/clue/clueDistributionRecord';
import TempPerson from '@/components/clue/TempPerson';
import TempChangeOperation from '@/components/clue/TempChangeOperation';
import { useStore } from 'vuex';
import {
  authWxAgentData,
  getContextFunc,
  shareSendAppFunc,
} from '@/utils/miniprogram';
import { FormatFullName } from '@/utils/unification';
import FileGrid from '@/components/common/FileGrid';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const $http = inject('$http');
const router = useRouter();

console.log(process.env.VUE_APP_API_URL);

const _DATA = reactive({
  clue_id: router.currentRoute.value.query.id,
  clue_type: router.currentRoute.value.query.type || 1,
  tabIndex: router.currentRoute.value.query.tabIndex || '1',
  empty_status: true,
  more_status: false,
  cus_show: false,
  loading: false,
  showPicker_distributionRecord: false, //分配历史
  allot_picker: {
    type: '',
    show: false,
    title: '选择一个人员分配',
    id: router.currentRoute.value.query.id,
  },
  showMoreAction: false, //更多面板
  shareInfo: {},
  //头像list
  imageList: [
    require('@/assets/images/icon-gender0.png'),
    require('@/assets/images/icon-gender1.png'),
    require('@/assets/images/icon-gender2.png'),
  ],
});

onMounted(() => {
  // 初始化企业微信JS-SDK
  // 请替换以下内容为你的实际参数
  authWxAgentData();
  loadFunc();
  console.log(allOperationAuthority);
});
/**
 * 获取接口
 */
const info = reactive({
  info_base: {
    size: '',
  },
  distribute_log: [],
  label: {},
  send: '', //小程序发送情况
  clue_intention: '', //意向度
  clue_follow_info: '', //跟进情况
});

const loadFunc = () => {
  _DATA.loading = true;
  $http.getClueInfo({ id: _DATA.clue_id }).then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      info.info_base = data;
      info.label = data?.label || {};
      info.send = data?.clue_applet_send_info || '';
      info.distribute_log = data?.distribute_log?.length
        ? data?.distribute_log
        : [];
      info.clue_intention = data?.clue_intention || '';
      info.clue_follow_info = data?.clue_follow_info || '';
      btnVisibleFunc(router.currentRoute.value.query.type, data);
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

// -- v1.5.7 api:分享小程序埋点
const shareCardRecordApi = (item, operation_result) => {
  $http
    .clue_designerAppletShareCardRecord({
      type: item.type, //1 首页 2公司介绍 3推荐卡片
      clue_id: _DATA.clue_id,
      card_id: item.type == 3 ? item?.card_id : '', //type=3时推荐卡片id不能为空
      operation_result, //操作结果 1成功 2失败
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        console.log('////');
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

// -- v1.5.6 api:小程序分享卡片列表
const wechart_loading = ref(true);
const wechartInfo = ref({ occ_list: [], designer_list: [] });
const loadWechatFunc = () => {
  $http.clue_designerAppletShareCard({ clue_id: _DATA.clue_id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      wechartInfo.value = data;
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
    wechart_loading.value = false;
  });
};

/**
 * 操作
 */
// -- 权限按钮(显示条件)
const buttonList = [
  {
    name: '完善信息',
    all: '239',
    btn_type: 'default',
    index: 0,
  },
  { name: '线索跟进', channel_sources: 0, btn_type: 'primary', index: 1 },
  {
    name: '设计派单',
    all: '239',
    auth_all: '379',
    status: [2],
    btn_type: 'default',
    index: 2,
  },
  {
    name: '设计多派',
    all: '239',
    auth_all: '379',
    status: [3],
    is_meet: '2',
    btn_type: 'default',
    index: 3,
  },
  {
    name: '设计改派',
    all: '239',
    auth_all: '379',
    status: [3],
    is_meet: '1',
    btn_type: 'default',
    index: 4,
  },
  {
    name: '线索改派',
    auth_all: '508',
    btn_type: 'default',
    index: 5,
  },
  {
    name: '变更分配',
    auth_all: '244',
    btn_type: 'default',
    index: 6,
  },
  {
    name: '线索轮派',
    auth_all: '403',
    btn_type: 'default',
    index: 7,
  },
  {
    name: '线索死单',
    auth_all: '509',
    btn_type: 'default',
    index: 8,
  },
  {
    name: '发送小程序',
    auth_all: '599',
    all: '239',
    btn_type: 'default',
    index: 9,
  },
];
const BtnList = ref([]);
const btnVisibleFunc = (type, info) => {
  //type=1 全部线索，type=2企微线索；
  //info线索详情,info.status=3已转客户
  //info线索详情,info.channel_source=0系统同步
  //info线索详情,info.is_meet?.id=1已见面，2未见面
  //info线索详情,info.is_clue_dead 1死单线索，0正常线索
  BtnList.value = [];
  buttonList.forEach((item, index) => {
    console.log(item, index);
    if (index === 0) {
      //完善信息
      let union =
        allOperationAuthority.value.indexOf(item.all) > -1 &&
        info.clue_edit === 1;
      union && BtnList.value.push(item);
    } else if (index === 1) {
      //线索跟进(!系统同步)
      info.channel_source != item.channel_sources && BtnList.value.push(item);
    } else if (index > 1 && index < 5) {
      //设计派单-额外需要线索编辑权限判断
      const edit_auth =
        info.clue_edit === 1
          ? allOperationAuthority.value.indexOf(item.all) > -1
          : false;
      //设计派单、设计多派、设计改派
      let union = allOperationAuthority.value.indexOf(item.auth_all) > -1;
      let is_meet = item.is_meet ? item.is_meet == info.is_meet?.id : true;
      let status = item.status.indexOf(info.status) > -1;
      if (index === 2) {
        //设计派单
        edit_auth && union && status && BtnList.value.push(item);
      } else {
        edit_auth && union && is_meet && status && BtnList.value.push(item);
      }
    } else if (index == 5 || index == 6 || index == 7) {
      //线索改派、变更分配、线索轮派
      let union = allOperationAuthority.value.indexOf(item.auth_all) > -1;
      union && BtnList.value.push(item);
    } else if (index == 8) {
      //线索死单（线索死单、取消死单）
      let union = allOperationAuthority.value.indexOf(item.auth_all) > -1;
      union &&
        BtnList.value.push({
          name: info?.is_clue_dead == 1 ? '取消死单' : '线索死单',
        });
    } else if (index == 9) {
      //发送小程序-额外需要线索编辑权限判断
      const edit_auth =
        info.clue_edit === 1
          ? allOperationAuthority.value.indexOf(item.all) > -1
          : false;
      let union = allOperationAuthority.value.indexOf(item.auth_all) > -1;
      edit_auth && union && BtnList.value.push(item);
    }
  });
};
// -- 分配历史
const distributionRecord = () => {
  _DATA.showPicker_distributionRecord = true;
};
// -- 更多
const changePopup = reactive({
  show: false,
  type: '',
  title: '',
  id: router.currentRoute.value.query.id,
});
const ActionSheetFunc = (name) => {
  switch (name) {
    case '完善信息':
      router.push({
        path: '/clueEditInfo',
        query: {
          id: _DATA.clue_id,
        },
      });
      break;
    case '线索跟进':
      router.push({
        path: '/clueFollow',
        query: {
          id: _DATA.clue_id,
          type: _DATA.clue_type,
          clueEdit: info.info_base.clue_edit,
        },
      });
      break;
    case '更多':
      _DATA.showMoreAction = true;
      break;
    case '变更分配':
      _DATA.showMoreAction = false;
      changePopup.title = '线索变更分配';
      changePopup.type = 'discover';
      changePopup.show = true;
      break;
    case '线索改派':
      _DATA.showMoreAction = false;
      changePopup.title = '线索改派';
      changePopup.type = 'reassignment';
      //-前端校验三种方式 1.轮派线索，请重新选择；2.已添加企微好友，是否确认继续；
      if (info.info_base.original_clue_id > 0) {
        //1:轮派线索，请重新选择
        Notify({
          type: 'warning',
          message: '线索为轮派线索，无法发起自动改派!',
          duration: 2000,
        });
        return;
      } else if (info.info_base.wechat_friend_status == 2) {
        //2:已添加企微好友，是否确认继续
        Dialog.confirm({
          message:
            '线索已添加企微好友，继续操作会清除原邀约人的企微添加信息，是否确认继续？',
          confirmButtonText: '确认',
          confirmButtonColor: '#0256FF',
        }).then(() => {
          changePopup.show = true;
        });
        return;
      } else {
        changePopup.show = true;
      }
      break;
    case '线索轮派':
      _DATA.showMoreAction = false;
      changePopup.title = '线索轮派';
      changePopup.type = 'redeploy';
      //-前端校验三种方式 1.已添加企微好友，是否确认继续；
      if (info.info_base.wechat_friend_status == 2) {
        //3:已添加企微好友，是否确认继续
        Dialog.confirm({
          message:
            '线索已添加企微好友，继续操作会清除原邀约人的企微添加信息，是否确认继续？',
          confirmButtonText: '确认',
          confirmButtonColor: '#0256FF',
        }).then(() => {
          changePopup.show = true;
        });
        return;
      } else {
        changePopup.show = true;
      }
      break;
    case '线索死单':
      _DATA.showMoreAction = false;
      Dialog.confirm({
        message: '是否确认将所选线索设置死单？将影响所有关联线索！',
        confirmButtonText: '确认死单',
        confirmButtonColor: '#0256FF',
      }).then(() => {
        deadFunc();
      });
      break;
    case '取消死单':
      _DATA.showMoreAction = false;
      Dialog.confirm({
        message: '是否确认将所选线索取消死单？',
        confirmButtonText: '确认',
        confirmButtonColor: '#0256FF',
      }).then(() => {
        cancelDeadFunc();
      });
      break;
    case '派单分配':
      _DATA.showMoreAction = false;
      _DATA.allot_picker.type = name;
      _DATA.allot_picker.title = '选择一个人员派单分配';
      _DATA.allot_picker.show = true;
      break;
    case '发送小程序':
      _DATA.showMoreAction = false;
      action_share_show.value = true;
      wechart_loading.value = true;
      loadWechatFunc();
      break;
    default:
      //设计派单、设计多派、设计改派
      _DATA.showMoreAction = false;
      Dialog.confirm({
        message:
          '是否确认对 ' +
          info.info_base.name +
          ' ' +
          (name === '设计多派' ? '发起多派' : name) +
          '？',
        confirmButtonText: name === '设计多派' ? '确认发起' : '确认申请',
        confirmButtonColor: '#0256FF',
      }).then(() => {
        creatCustomerFunc();
      });
      break;
  }
};

// -- v1.4.3 线索死单
const deadFunc = () => {
  $http.clue_clueDead({ id: [_DATA.clue_id] }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Notify({ type: 'success', message: '操作成功' });
      loadFunc();
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// -- v1.4.3 取消死单
const cancelDeadFunc = () => {
  $http.clue_clueDeadRestore({ id: [_DATA.clue_id] }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Notify({ type: 'success', message: '操作成功' });
      loadFunc();
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// -- 派单分配
const reviewFunc = (value) => {
  _DATA.allot_picker.show = false;
  if (value === 'ok') {
    _DATA.allot_picker.show = false;
    changePopup.show = false;
    loadFunc();
  }
};

// -- 撤销
const revocationFunc = (customer_id, index) => {
  console.log(index);
  Dialog.confirm({
    message: '是否确认撤销？此操作无法撤回！',
  }).then(() => {
    $http.companyDelCustomer({ customer_id }).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({ type: 'success', message: '操作成功' });
        loadFunc();
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
  });
};
// -- 跳转
const jumpTo = (type, id) => {
  if (type === 'sale') {
    router.push({
      path: '/sale',
      query: {
        id,
      },
    });
  } else if (type == 'record' && id) {
    router.push({
      path: '/clueRecord',
      query: {
        id,
      },
    });
  }
};
// -- 步骤4：生成客户
const creatCustomerFunc = () => {
  _DATA.loading = true;
  $http
    .clueCreateCustomer({ clue_id: _DATA.clue_id })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        _DATA.loading = false;
        if (data.customer_id) {
          //生成客户成功
          applyDispatchFunc(data);
        } else {
          //生成客户失败
          router.push({
            path: '/clueCreateCustomer',
            query: { id: _DATA.clue_id, info: JSON.stringify(data) },
          });
        }
      } else {
        _DATA.loading = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      _DATA.loading = false;
    });
};
// -- 提交表单 步骤5:生成客户、设计多派(没有 379申请设计派单权限) ||设计派单、设计多派、设计改派（有379申请设计派单权限）
const applyDispatchFunc = (data) => {
  router.push({
    path: '/clueDesignDispatch',
    query: {
      id: data.customer_id,
      NO: data.customer_no,
      clueID: _DATA.clue_id,
    },
  });
};

// -- v1.5.7 发送小程序分享
const action_share_show = ref(false);
const share = (item) => {
  console.log(item);
  console.log(_DATA.clue_id, info.info_base.invite_user_id, _DATA.shareInfo);

  getContextFunc().then((response) => {
    if (response === 1) {
      // 入口环境：当前会话
      const parmars = {
        clue_id: _DATA.clue_id,
        invite_user_id: info.info_base.invite_user_id,
      };
      shareSendAppFunc(parmars, item);
      shareCardRecordApi(item, 1);
    } else if (response === 2) {
      // 入口环境：工作台
      alert('发送失败');
      shareCardRecordApi(item, 2);
    }
    action_share_show.value = false;
  });
};

// -- v1.5.5 复制功能
const copyToClipboard = (text) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  Notify({ type: 'success', message: '复制成功' });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueDetail.less';
</style>
