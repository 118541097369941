<!--
 * @Author: NanNan
 * @Date: 2022-04-21 13:58:20
 * @LastEditTime: 2022-07-19 15:19:16
 * @Description: 补充客户信息
-->
<template>
  <div class="page-box formBase">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <NavBar
      safe-area-inset-top
      title="补充客户信息"
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
      v-if="edit_type"
    />
    <!-- 表单填写 -->
    <Form class="form-box" :class="{ mt58: edit_type }" ref="formRef">
      <p class="label-name">联系地址</p>
      <Field
        v-model.trim="form_info.info.address"
        placeholder="填写联系地址"
        maxlength="40"
      />

      <p class="label-name">电子邮箱</p>
      <Field
        v-model.trim="form_info.info.email"
        placeholder="填写电子邮箱"
        maxlength="40"
        name="validator"
        :rules="[
          {
            validator: validatorEmail,
            trigger: 'onChange',
            message: '请填写正确的电子邮箱',
          },
        ]"
      />

      <p class="label-name">QQ号</p>
      <Field
        v-model.trim="form_info.info.qq"
        placeholder="填写QQ号"
        type="number"
        maxlength="20"
        name="validator"
        :rules="[
          {
            validator: validatorQq,
            message: '请填写正确的QQ号',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">收货地址</p>
      <Field
        v-model.trim="form_info.info.delivery_address"
        placeholder="填写收货地址"
        maxlength="40"
      />
    </Form>
    <div class="stick-box">
      <Button type="primary" size="large" @click="onSubmit">确定</Button>
    </div>
    <!-- end -->
  </div>
</template>
<script setup>
import {
  inject,
  onMounted,
  reactive,
  ref,
  defineProps,
  defineEmits,
} from 'vue';
import { Form, Field, Button, Notify, NavBar, Loading, Overlay } from 'vant';
import patterns from '@/utils/pattern';
import { useRouter } from 'vue-router';
const $http = inject('$http');
const formRef = ref(null);
const router = useRouter();
const form_info = reactive({
  info: {},
});
const loading = ref(false);

onMounted(() => {
  loadFunc();
});

const props = defineProps(['data']);
const emit = defineEmits(['reviewClose', 'reviewOk']);
//-判断是否从合同入口-补充客户信息
const edit_type = props.data ? props.data.complete_card_Type : '';
const customer_id = props.data
  ? props.data.customer_id
  : router.currentRoute.value.query
  ? router.currentRoute.value.query.id
  : '';

/**
 * 接口调用
 */
//@获取详情
const loadFunc = () => {
  loading.value = true;
  $http.getSaleInfoOtherApi({ id: customer_id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      form_info.info = data;
    } else {
      Notify({ type: 'warning', message: data });
    }
    loading.value = false;
  });
};

/**
 * 校验方式
 */
const { emailReg, qqReg } = patterns;
const validatorEmail = (val) => (val ? emailReg.test(val) : true);
const validatorQq = (val) => (val ? qqReg.test(val) : true);

/**
 * 表单操作
 */

//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//@提交
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http
      .getSaleInfoOtherEditApi(form_info.info)
      .then((res) => {
        const { data, code } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              loading.value = false;
              if (edit_type) {
                emit('reviewOk');
              } else {
                router.push('/saleDetail?id=' + customer_id);
              }
            },
          });
        } else {
          loading.value = false;
          Notify({ type: 'warning', message: data });
        }
      })
      .catch(() => {
        loading.value = false;
      });
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/addMore.less';
</style>
