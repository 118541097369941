<!--
 * @Author: gest
 * @Date: 2024-05-09 15:34:52
 * @LastEditTime: 2024-05-18 14:39:35
 * @Description: v1.6.0 进度组件顶部坐标及覆盖均值
 {
    type: 1, //1有均值 2目标天数 
    tip:1,//1：没有标题 默认有标题
    num: 60, //均值数或目标天数
    list: [0, 100, 200, 300], //坐标值
  },
-->
<template>
  <div
    style="
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      color: #999999;
      text-align: center;
    "
  >
    <div
      class="num_item"
      :style="{
        width: props.data.list?.length == 5 ? '25%' : '33.3%',
        left: props.data.list?.length == 5 ? '-12.5%' : '-16.7%',
      }"
    >
      {{ props.data.list[0] }}{{ props.data?.unit ? props.data?.unit : '' }}
    </div>
    <div
      class="num_item"
      :style="{
        width: props.data.list?.length == 5 ? '25%' : '33.3%',
        left: props.data.list?.length == 5 ? '12.5%' : '16.5%',
      }"
    >
      {{ props.data.list[1] }}{{ props.data?.unit ? props.data?.unit : '' }}
    </div>
    <div
      class="num_item"
      :style="{
        width: props.data.list?.length == 5 ? '25%' : '33.3%',
        left: props.data.list?.length == 5 ? '37.5%' : '49.5%',
      }"
    >
      {{ props.data.list[2] }}{{ props.data?.unit ? props.data?.unit : '' }}
    </div>
    <div
      class="num_item"
      :style="{
        width: props.data.list?.length == 5 ? '25%' : '33.3%',
        left: props.data.list?.length == 5 ? '62.5%' : '82.5%',
      }"
    >
      {{ props.data.list[3] }}{{ props.data?.unit ? props.data?.unit : '' }}
    </div>
    <div
      class="num_item"
      style="left: 87.5%; width: 25%"
      v-if="props.data.list?.length == 5"
    >
      {{ props.data.list[4] }}{{ props.data?.unit ? props.data?.unit : '' }}
    </div>
    <div
      v-if="props.data.type"
      class="mask"
      style="top: 22px"
      :style="{
        left: dis + '%',
      }"
    >
      <div style="position: relative">
        {{
          props.data.type == 2
            ? props.data.tip
              ? props.data.num + '天'
              : '目标' + props.data.num + '天'
            : ' 均值' + props.data.num
        }}
      </div>

      <div v-if="props.data.type" class="arrow"></div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import * as _ from 'lodash';
import NP from 'number-precision';
const props = defineProps(['data']);

const dis = computed(() => {
  let result = 0;
  if (props.data.type) {
    //计算叠加线偏移
    let total =
      props.data.list?.length == 5
        ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
        : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));
    result = NP.times(
      _.floor(NP.divide(parseFloat(props.data.num), total), 4),
      100
    );
  }
  console.log(result);
  return result;
});
</script>
<style lang="less" scoped>
.num_item {
  height: 100%;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.mask {
  position: absolute;
  z-index: 9;
  background: #fff5ca;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  height: 18px;
  padding: 0 3px;
  border-radius: 2px;
  color: #925e00;

  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0px 3px 3px #d8d8d8;
}
.arrow {
  position: absolute;
  top: 18px;
  left: 50%;
  z-index: 9;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 5px solid #fff5ca;
  transform: translateX(-2.2px);
}
</style>
