<!--
 * @Author: Jessica
 * @Date: 2022-04-22 16:16:33
 * @LastEditTime: 2024-06-14 11:17:21
 * @Description: 审批
 * v1.1.0 卡片样式整体修改
 * v1.2.2 审批列表 一级部门
 * v1.3.4 smart 根据url传参id 来判断是否是企微-待办审批消息跳转而来，锁定到相关id任务
 * v1.3.6 smart 待审批板块-type=2分配设计中心,dispatch_mode=335可以取消，336、411不可以
 * v1.3.7 smart 待审批板块-type=2分配设计中心,dispatch_mode=335可以取消，336、411也可以取消，调用特殊接口
 * v1.3.8 smart 审批页面整体调整
 * v1.4.0 Jessica 新增合同变更金额审批流
 * v1.5.1 Jessica 新增分摊业绩审批流
-->
<template>
  <div class="page-box">
    <Tabs
      v-model:active="state.parmars.type"
      swipeable
      class="tabList"
      @click-tab="getTabs"
    >
      <Tab
        v-for="item in state.tabList"
        :key="item.id"
        :name="item.id"
        :title="item.name"
      >
        <template #title>
          <div class="flex-box">
            {{ item.name }}
            <Badge
              :content="task_count"
              max="99"
              v-if="item.id == 2"
              :show-zero="false"
            />
          </div>
        </template>
      </Tab>
    </Tabs>
    <!-- 吸顶 -->
    <Sticky offset-top="25vw">
      <div class="sticky-box">
        <div class="searchBox">
          <Search
            v-model="state.parmars.kw"
            shape="round"
            placeholder="客户姓名/手机号/编号"
            clearable
            @search="onSearch()"
          />
          <span class="btn-search" @click="onSearch()">搜索</span>
        </div>
        <div class="tab-box">
          <span
            v-for="item in state.parmars.type == 1
              ? state.tabListTwo
              : state.tabListThr"
            :key="item.id"
            :class="{ active: item.id == state.parmars.status }"
            @click="clickTab(item.id)"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
    </Sticky>

    <!-- end -->
    <List
      v-model:loading="state.loading"
      :finished="state.finished"
      :offset="1"
      @load="getList"
    >
      <!-- v1.3.8 任务卡片不因状态区分，全部整合 -->
      <div class="card-list">
        <div
          class="task-card-new"
          :class="'listClass' + item.id"
          v-for="(item, index) in state.list"
          :key="index"
          @click.stop="gotoFun(item)"
        >
          <!-- 状态栏 -->
          <div
            v-for="v in state.parmars.type == 1
              ? state.tabListTwo
              : state.tabListFour"
            :key="v.id"
          >
            <p class="h1" v-if="v.id === item.status">
              <label :class="v.class">
                <img :src="v.img" alt="" />
                {{ v.name }}
              </label>
              <em
                v-if="item.is_approve_overtime == 1 && state.parmars.type == 2"
              >
                审批不及时
              </em>
            </p>
          </div>
          <!-- end -->
          <h1>{{ item.type_name }}</h1>
          <ol class="ol-flex">
            <li v-if="item.company_name">
              <label>一级部门：</label>
              <span>{{ item.company_name }}</span>
            </li>
            <li v-for="(val, key) in item.info_list" :key="key">
              <label>{{ key }}</label>
              <span>{{ val }}</span>
            </li>
          </ol>
          <div class="do-box">
            <div class="same-per">
              <lazy-component class="img-box">
                <Image
                  v-if="item.operator_user_face_image"
                  :src="
                    item.operator_user_face_image.includes('http')
                      ? item.operator_user_face_image
                      : $host + item.operator_user_face_image
                  "
                  v-lazy
                  fit="cover"
                  round
                />
                <img
                  :src="require('@/assets/images/icon-empty-img.png')"
                  v-else
                />
              </lazy-component>
              <div class="per">
                <p class="name">{{ item.operator_user_str }}</p>
                <p class="der">{{ item.operator_time }}</p>
              </div>
              <div class="tip">发起申请</div>
            </div>
            <div class="line"></div>
            <div class="same-per">
              <lazy-component class="img-box">
                <Image
                  v-if="item.approver_user_face_image"
                  :src="
                    item.approver_user_face_image.includes('http')
                      ? item.approver_user_face_image
                      : $host + item.approver_user_face_image
                  "
                  v-lazy
                  fit="cover"
                  round
                />
                <img
                  :src="require('@/assets/images/icon-empty-img.png')"
                  v-else
                />
              </lazy-component>
              <div class="per">
                <p class="name">{{ item.approver_user_str }}</p>
                <p class="der" v-if="item.statue == 1 || item.status == 2">
                  {{ item.approver_user_dep + '/' + item.approver_user_pos }}
                </p>
                <p class="der" v-if="item.status == 3">
                  {{ item.approver_time || '-' }}
                </p>
              </div>
              <div class="tip">审核人</div>
            </div>
          </div>
          <div v-if="state.parmars.type == 1">
            <div
              class="oprate-box"
              v-if="
                item.status == 1 && (item.type == 2 ? item.dispatch_mode : true)
              "
            >
              <span class="btn-cancel" @click.stop="cancelFun(item)">
                {{
                  item.dispatch_mode &&
                  [336, 411].indexOf(item?.dispatch_mode) > -1
                    ? '取消轮派'
                    : '取消申请'
                }}
              </span>
            </div>
            <div
              class="oprate-box"
              v-if="
                (item.status == 4 || item.status == 3) && item.is_reapply == 0
              "
            >
              <span class="btn-cancel" @click.stop="reapplyFun(item)">
                重新申请
              </span>
            </div>
          </div>
          <div v-else>
            <div class="oprate-box" v-if="item.status == 1">
              <span class="btn-default" @click.stop="gotoFun(item)">
                去处理
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- end -->
      <!-- 空状态 -->
      <Empty
        class="empty-fff"
        description="暂无数据"
        v-if="!state.loading && state.list.length === 0"
        style="background: #fafafa"
      />
      <!-- end -->
      <template #finished>
        <Divider style="margin-top: 40px" v-if="state.list.length">
          我也是有底线的
        </Divider>
      </template>
    </List>
    <!--V1.4.0 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->
  </div>
</template>

<script setup>
import {
  reactive,
  inject,
  onMounted,
  // computed,
  ref,
} from 'vue';
import {
  Tab,
  Tabs,
  Toast,
  Dialog,
  Search,
  Notify,
  Empty,
  List,
  Divider,
  Badge,
  Image,
  Sticky,
} from 'vant';
import { useRouter } from 'vue-router';
import sideMenu from '@/components/sideMenu';
// import { useStore } from 'vuex';

// const store = useStore();
const $host = inject('$host');
const $http = inject('$http');
const router = useRouter();
const query = router.currentRoute.value.query;

// const allOperationAuthority = computed(
//   () => store.state.account.allOperationAuthority
// );
const _DATA = reactive({});
const state = reactive({
  loading: false,
  finished: query.source_id ? true : false,
  noBackRoutes: ['/', '/authA', '/auth', null],
  backUrl: window.history?.state?.back,
  //-tabs
  tabList: [
    { id: 2, name: '我的审批' },
    { id: 1, name: '我的申请' },
  ],
  tabListTwo: [
    { id: 1, name: '待处理', img: require('@/assets/images/icon-wait.png') },
    {
      id: 2,
      name: '已通过',
      img: require('@/assets/images/icon-pass.png'),
      class: 'done',
    },
    {
      id: 3,
      name: '不通过',
      img: require('@/assets/images/icon-no.png'),
      class: 'rejust',
    },
    {
      id: 4,
      name: '已取消',
      img: require('@/assets/images/icon-cancel.png'),
      class: 'cancel',
    },
  ],
  //-我的审批搜索栏
  tabListThr: [
    { id: 1, name: '待审批', img: require('@/assets/images/icon-wait.png') },
    {
      id: 2,
      name: '已通过',
      img: require('@/assets/images/icon-pass.png'),
      class: 'done',
    },
    {
      id: 3,
      name: '不通过',
      img: require('@/assets/images/icon-no.png'),
      class: 'rejust',
    },
  ],
  //-我的审批状态栏
  tabListFour: [
    { id: 1, name: '待审批', img: require('@/assets/images/icon-wait.png') },
    {
      id: 2,
      name: '已通过',
      img: require('@/assets/images/icon-pass.png'),
      class: 'done',
    },
    {
      id: 3,
      name: '不通过',
      img: require('@/assets/images/icon-no.png'),
      class: 'rejust',
    },
    {
      id: 4,
      name: '已取消',
      img: require('@/assets/images/icon-cancel.png'),
      class: 'cancel',
    },
  ],
  //-搜索条件
  parmars: {
    kw: '',
    type: query.tab ? parseInt(query.tab) : 2, //1我的申请、2我的审批
    status: query.status ? parseInt(query.status) : '', //1待处理、2同意、3拒绝、4拒绝
  },
  //分页
  defaultSize: 1,
  pageInfo: {
    total: 0, //总条数
    page: 1, //当前页
    pages: 10, //每页多少条
  },
  //-列表
  list: [],
  //需要处理的审批type，不加上去默认提示去PC操作噢~
  typeList: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 23, 24, 25,
    29, 30, 33,
  ],
});

const todo_count = ref(0);
const task_count = ref(0);

onMounted(() => {
  getTaskCount();
  query?.source_id && getFixTask();
});

/**
 * api
 */
// -- 获取待办数量
const getTaskCount = () => {
  $http.getTaskStatistics().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      todo_count.value = data?.todo_count;
      task_count.value = data?.task_count;
    }
  });
};

// -- 获取列表
const getList = () => {
  state.loading = true;
  $http
    .getTaskApproveList({
      ...state.parmars,
      page: state.pageInfo.page ? state.pageInfo.page : state.defaultSize,
      pages: state.pageInfo.pages,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.list = state.list.concat(res.data.list);
        Object.assign(state.pageInfo, { ...res.data.page });
        if (state.list.length < res.data.page.total) {
          state.pageInfo.page++;
        } else {
          state.finished = true;
        }
      } else {
        state.finished = true;
        Toast(res.data || res.msg);
      }
    });
};

// -- 根据url穿参id
const getFixTask = () => {
  state.finished = true;
  $http
    .getTaskApproveGetList({
      params: { task_id: query.source_id, type: query?.tab || 2 },
    })
    .then((res) => {
      const { code, data, msg } = res;
      if (code === 0) {
        state.list = data.list;
      } else {
        state.list = [];
        Toast(data || msg);
      }
    });
};
/**
 * 操作
 */
// -- 搜索
const onSearch = () => {
  loadFun(state.parmars.kw);
  getList();
};

// -- 二级tab切换
const clickTab = (name) => {
  if (state.parmars.status == name) {
    state.parmars.status = '';
  } else {
    state.parmars.status = name;
  }
  console.log('///');
  loadFun();
  getList();
};

//-标签切换
const getTabs = (item) => {
  state.parmars.type = item.name;
  state.parmars.status = '';

  router.replace({
    path: '/taskIndex',
    query: { tab: state.parmars.type },
  });
  loadFun();
  getList();
  //请求待办数量
  getTaskCount();
};

// -- 更新列表
const loadFun = (kw) => {
  state.list = [];
  state.finished = false;
  state.parmars.kw = kw || '';
  state.parmars.task_id = '';
  state.pageInfo.total = 0;
  state.pageInfo.page = 1;
  state.pageInfo.pages = 10;
};

// -- 取消操作
const cancelFun = (item) => {
  const { id, type, dispatch_mode } = item;
  let confirmParams = { message: '确定要取消申请吗？' };
  if (item.type == 17) {
    confirmParams = {
      message: '是否确认取消申请？将默认完成接单！',
      confirmButtonText: '取消申请',
      cancelButtonText: '保持回单',
    };
  } else if (type == 2 && [336, 411].indexOf(dispatch_mode) > -1) {
    //特殊化取消接口 - 针对网销经理取消轮派
    confirmParams = {
      message: `是否确认取消轮派？\n待最后中心拒接或超时后生效！`,
      messageAlign: 'left',
    };
  }

  Dialog.confirm(confirmParams)
    .then(() => {
      var requestHttp; //公共取消接口
      if (type == 2 && [336, 411].indexOf(dispatch_mode) > -1) {
        requestHttp = $http.networkManageAutoRotationCancel({ task_id: id });
      } else {
        requestHttp = $http.getTaskApplyCancel({ task_id: id });
      }
      requestHttp.then((res) => {
        const { data, code } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
          });
          loadFun();
          getList();
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
    })
    .catch(() => {
      Notify({
        type: 'warning',
        message: '取消操作',
      });
    });
};
const reapplyFun = (item) => {
  switch (item.type) {
    case 15:
      router.push({
        path: '/applyDesignReassignment',
        query: {
          id: item.customer_id,
          task_id: item.id,
          is_reapply: item.is_reapply,
          type: item.type,
        },
      });
      break;
    case 2:
      if (item.source_type == 15) {
        router.push({
          path: '/applyDesignReassignment',
          query: {
            id: item.customer_id,
            task_id: item.id,
            is_reapply: item.is_reapply,
            type: item.type,
          },
        });
      } else {
        router.push({
          path: '/desginSend',
          query: {
            id: item.customer_id,
            task_id: item.id,
            is_reapply: item.is_reapply,
            type: item.type,
          },
        });
      }

      break;

    default:
      break;
  }
};

//--列表操作--V1.3.0供应商相关审批 提示请去企微端操作
// V1.3.6gest 22审核活动供应商
const gotoFun = (item) => {
  console.log('1111111111111111');
  if (state.typeList.some((i) => i == item.type)) {
    if (state.parmars.type == 2 && item.type == 10 && item.status == 1) {
      // //收退款审核去PC
      Notify({
        type: 'warning',
        message: '请去PC端处理',
      });
    } else if (item.type == 25) {
      //产品下单订单专员审批跳转产品下单页面
      router.push({
        path: '/productOrder',
        query: {
          id: item.customer_id,
          gdid: item.next_ids.construction_id,
          node_id: item.next_ids.node_id,
          task_id: item.id,
          task_status: item.status,
        },
      });
    } else {
      router.push('/taskView?id=' + item.id + '&curTab=' + state.parmars.type);
    }
  } else {
    Notify({
      type: 'warning',
      message: '请去PC端处理/查看',
    });
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/task/checkIndex.less';
</style>
