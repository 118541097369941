<!--
 * @Author: Jessica
 * @Date: 2024-05-09 14:15:22
 * @LastEditTime: 2024-05-28 16:26:45
 * @Description: V1.6.0目标数据-转化产值
-->
<template>
  <!-- 转化产值 -->
  <div class="main-data">
    <div class="warpper" @click.stop="redictFun">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading || props.loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_4.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                转化产值
                <em>{{ detail.total?.actual ?? '-' }}</em>
                <span class="font-size15 pl-3">万</span>
              </div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>
                  <em class="pl-3">{{
                    detail.total?.target === '' ? '-' : detail.total?.target
                  }}</em>
                  <!-- 规则入口 -->
                  <div
                    class="icon_info_box_small pl-3"
                    @click.stop="createDanger"
                  >
                    <img
                      class="icon_img"
                      :src="
                        require('@/assets/images/target-data/icon_info.png')
                      "
                      alt="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap" v-if="detail.total?.gap !== ''">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail.total?.target ?? 0) <
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c1">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail.total?.target ?? 0) >
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c2">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">完成率</span>
                <span class="font-size12 pl-3">
                  {{
                    detail.total?.done_rate === ''
                      ? '-'
                      : detail.total?.done_rate + '%'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 定制产值 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">定制产值</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.custom?.target === ''
                          ? '-'
                          : detail.custom?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.custom?.actual ?? '-' }}</em>
                    <span class="font-size12 pl-3">万</span>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.total?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.custom?.target ?? 0) <
                          parseFloat(detail.custom?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.custom?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.custom?.target ?? 0) >
                          parseFloat(detail.custom?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.custom?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">{{
                        detail.custom?.done_rate === ''
                          ? '-'
                          : detail.custom?.done_rate + '%'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 整装产值 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">整装产值</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.package?.target === ''
                          ? '-'
                          : detail.package?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.package?.actual ?? '-' }}</em>
                    <span class="font-size12 pl-3">万</span>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.package?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.package?.target ?? 0) <
                          parseFloat(detail.package?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.package?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.package?.target ?? 0) >
                          parseFloat(detail.package?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.package?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">{{
                        detail.package?.done_rate === ''
                          ? '-'
                          : detail.package?.done_rate + '%'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- B+数 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">B+数</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.b_plus?.target === ''
                          ? '-'
                          : detail.b_plus?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.b_plus?.actual ?? '-' }}</em>
                  </p>
                  <p class="temp3">
                    {{ detail.b_plus?.done_rate + '%' ?? '-' }}
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.b_plus?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.b_plus?.target ?? 0) <
                          parseFloat(detail.b_plus?.done_rate ?? 0)
                        "
                      >
                        <em class="c1">{{
                          detail.b_plus?.gap + '%' ?? '-'
                        }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.b_plus?.target ?? 0) >
                          parseFloat(detail.b_plus?.done_rate ?? 0)
                        "
                      >
                        <em class="c2">{{
                          detail.b_plus?.gap + '%' ?? '-'
                        }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  defineProps,
  watch,
  defineEmits,
  defineExpose,
} from 'vue';
import { Notify, Skeleton, Row, Col } from 'vant';
import { useRouter } from 'vue-router';
const emit = defineEmits(['createDanger']);
const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .targetStatistic_homeConvertPerformance({ year: props.data.yearId })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        detail.value = data;
      } else {
        loading.value = false;
        detail.value = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 2, pos: 1 },
  });
};

//-规则说明--begin
const htmlTitle = '转化产值_规则';
const htmlContent = `<p>1、定制产值：当年非优选项目产值，包含取产品业绩、返款业绩、产品交付业绩、产品其他业绩、施工首期业绩、施工增减项业绩、施工渠道费业绩（扣减）、施工其他业绩；</p>
<p>2、整装产值：当年优选项目产值，优选施工业绩、 优选增减项业绩、优选其他业绩、优选产品业绩、优选返款业绩；</p>
<p>3、当年签的协议的转化项目【产品&施工业绩】/施工面积≥5000 算B+；</p>`;
const createDanger = () => {
  emit('createDanger', 'transformPerformanceRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
