<!-- 
  * @Author: NanNan 
  * @Date: 2022-04-16 15:30:26 
 * @LastEditTime: 2024-01-31 15:19:53
  * @Description: 合同详情 - 页面 
  * - v0.3.0 客户结算单 
  * - v1.1.0 样式修改，增加功能“关联作废合同”
-->
<template>
  <div class="page-box">
    <!-- 头部卡片 汇总信息 -->
    <div class="task-card-same" v-if="!_TAB_INFO.loading">
      <!-- 状态栏  1待发起 2待签约 3已签约 4作废-->
      <p
        class="h1"
        v-for="(val, idx) in getList(_TAB_INFO.basic_into.status)"
        :key="idx"
      >
        <label :class="val.class">
          <img :src="val.url" alt="" />
          {{ _TAB_INFO.basic_into.status_name }}
        </label>
        <span
          class="time"
          v-if="
            _TAB_INFO.basic_into.contract_url &&
            allOperationAuthority.indexOf('227') > -1
          "
          @click="handleClick('电子合同', _TAB_INFO.basic_into)"
        >
          查看合同
          <Icon name="arrow" color="rgb(52, 113, 255)" />
        </span>
      </p>
      <!-- 合同金额 总计 -->
      <div class="money-all">
        <div class="same">
          <p class="text">
            {{
              _TAB_INFO.basic_into.type == 53 || _TAB_INFO.basic_into.type == 69
                ? '累计产品已收'
                : '合同总额'
            }}
          </p>
          <p class="m green">
            {{ _TAB_INFO.header.contract_all_money || '0' }}
          </p>
        </div>

        <div class="same border" v-if="_TAB_INFO.basic_into.status > 1"></div>
        <div class="same" v-if="_TAB_INFO.basic_into.status > 1">
          <p class="text">
            {{
              _TAB_INFO.basic_into.type == 53 || _TAB_INFO.basic_into.type == 69
                ? '剩余可用金额'
                : '剩余未收'
            }}
          </p>
          <p class="m red">{{ _TAB_INFO.header.contract_balance || '0' }}</p>
        </div>
      </div>
      <!-- 合同金额 详细-->
      <div class="money-box" v-if="_TAB_INFO.basic_into.status > 1">
        <dl
          v-if="
            _TAB_INFO.basic_into.type != 53 && _TAB_INFO.basic_into.type != 69
          "
        >
          <dt>
            <p class="n">实际签订金额</p>
            <p class="m" v-if="_TAB_INFO.basic_into.type == 56">
              ¥{{ _TAB_INFO.header.contract_all_money || 0 }}
            </p>
            <p class="m" v-else>
              ¥{{ _TAB_INFO.basic_into.contract_amount || 0 }}
            </p>
          </dt>
          <dd>
            <p class="n">合同款已收</p>
            <p class="m">¥{{ _TAB_INFO.header.received_money || 0 }}</p>
          </dd>
          <!--V1.5.3 室内设计合同/配饰设计协议/优选整装销售 不显示增减项 -->
          <dd
            v-if="
              _TAB_INFO.basic_into.type != 51 &&
              _TAB_INFO.basic_into.type != 52 &&
              _TAB_INFO.basic_into.type != 55
            "
          >
            <p class="n">增减项合计</p>
            <p class="m">
              ¥{{ _TAB_INFO.header.contract_adjust_all_money || 0 }}
            </p>
          </dd>
        </dl>

        <!-- <dl
          v-if="
            _TAB_INFO.basic_into.type == 54 || _TAB_INFO.basic_into.type == 56
          "
        >
          <dt>
            <p class="n">增减项合计</p>
            <p class="m">
              ¥ {{ _TAB_INFO.header.contract_adjust_all_money || 0 }}
            </p>
          </dt>
        </dl> -->
        <dl
          v-if="
            _TAB_INFO.basic_into.type == 53 || _TAB_INFO.basic_into.type == 69
          "
        >
          <dt>
            <p class="n">累计产品占款金额</p>
            <p class="m">¥ {{ _TAB_INFO.header.received_money || 0 }}</p>
          </dt>
          <dt style="text-align: right">
            <p class="n">合同外收款</p>
            <p class="m">
              ¥ {{ _TAB_INFO.header.contract_outside_money || 0 }}
            </p>
          </dt>
        </dl>
        <div
          class="wai"
          v-if="
            _TAB_INFO.basic_into.type != 53 && _TAB_INFO.basic_into.type != 69
          "
        >
          <Icon name="warning-o" color="#EA3D2F" size="14" />
          <span>合同外收款</span>
          <em>¥{{ _TAB_INFO.header.contract_outside_money || 0 }}</em>
        </div>
      </div>
      <!-- 操作按钮 -->
      <div v-for="(val, idx) in _TAB_INFO.oprate_list" :key="idx">
        <div
          class="option-box"
          :class="{ mlr20: _TAB_INFO.basic_into.status === 1 }"
          v-if="_TAB_INFO.basic_into.status === val.status && val.icons?.length"
        >
          <Row gutter="8">
            <Col
              v-for="(v, i) in val.icons"
              :span="24 / val.icons?.length"
              :key="i"
            >
              <Button size="large" :type="v.type" @click="dialogFunc(v.key)">
                <span style="font-size: 15px">{{ v.name }}</span>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- 粘性布局 -->
    <div ref="container" class="tab-box-bigger">
      <Sticky :container="container">
        <Tabs
          v-model:active="_DATA.tab_active"
          title-active-color="#3471FF"
          title-inactive-color="#A6A6A6"
          @change="tabFunc(value)"
          :ellipsis="false"
        >
          <Tab title="合同信息" :name="0"></Tab>
          <Tab
            title="款项明细"
            :name="1"
            v-if="
              _TAB_INFO.basic_into.type != 53 && _TAB_INFO.basic_into.type != 69
            "
          ></Tab>
          <Tab title="收款流水" :name="2"></Tab>
          <Tab
            title="变更记录"
            :name="3"
            v-if="[51, 52].indexOf(_TAB_INFO.basic_into.type) < 0"
          ></Tab>
          <Tab
            title="客户结算单"
            :name="4"
            v-if="[54, 56, 53, 69].indexOf(_TAB_INFO.basic_into.type) > -1"
          ></Tab>
        </Tabs>
      </Sticky>
      <!-- 加载状态 -->
      <Overlay :show="_DATA.loading" class="overlay-fff">
        <Loading color="#1989fa" type="spinner"></Loading>
      </Overlay>
      <!-- end -->

      <!-- 内容区域 -->
      <div class="tab-content-bigger">
        <!-- tab 合同信息 -->
        <div class="tab-one" v-if="_DATA.tab_active === 0">
          <div class="content-box">
            <p class="same-title">
              <span>基础信息</span>
            </p>
            <DetailTemp
              :info="_TAB_INFO"
              v-if="_DATA.tab_active === 0 && _TAB_INFO"
            />
          </div>
          <div class="content-box">
            <p class="same-title">
              <span>项目成员</span>
            </p>
            <ol>
              <li
                v-for="(item, index) in _TAB_INFO.project_members"
                :key="index"
              >
                <label>{{ item.post_name }}</label>
                <div>
                  <p>{{ item.name }}</p>
                  <p class="text">
                    {{
                      item.phone
                        ? '[' + item.dep_name + '/' + item.phone + ']'
                        : '-'
                    }}
                  </p>
                </div>
              </li>
            </ol>
            <!-- 空状态 -->
            <Empty
              class="empty-fff"
              description="暂无数据"
              v-if="!_DATA.loading && _TAB_INFO.project_members.length === 0"
            />
          </div>
        </div>
        <!-- tab  款项明细-->
        <div class="tab-two" v-if="_DATA.tab_active === 1">
          <!-- 首款样式 -->
          <div class="content-box">
            <List
              v-model:loading="_DATA.loading"
              :loading-text="_DATA.loading_text"
              :finished="_DATA.finished"
              :offset="20"
              @load="loadPayment"
            >
              <div
                class="same-box"
                v-for="(item, index) in _DATA.list"
                :key="index"
              >
                <!-- 状态栏 -->
                <p class="h1">
                  <label
                    class="font_bold"
                    :class="{
                      cancel: item.payment_status_str === '未收款',
                      done: item.payment_status_str === '已收款',
                    }"
                  >
                    <svg
                      class="icon-small"
                      aria-hidden="true"
                      v-if="item.payment_status_str == '未收款'"
                    >
                      <use xlink:href="#icon-a-time-fill5"></use>
                    </svg>
                    <svg
                      class="icon-small"
                      aria-hidden="true"
                      v-if="item.payment_status_str === '已收款'"
                    >
                      <use xlink:href="#icon-a-checkbox-circle-fill2"></use>
                    </svg>
                    {{ item.payment_status_str }}
                  </label>
                  <span
                    class="refund-money"
                    v-if="item.payment_flag > 1"
                    @click="handleClick('退款', item)"
                  >
                    退款
                  </span>
                </p>

                <div class="do-box">
                  <p class="title">{{ item.payment_period }}</p>
                  <ul class="ul-flex">
                    <li>
                      <p class="text">收款比例</p>
                      <p class="n">{{ item.proportion }}%</p>
                    </li>
                    <li v-if="item.period_type == 1">
                      <p class="text">款期金额</p>
                      <p class="n">{{ item.receivable_money }}</p>
                    </li>
                    <li
                      v-if="
                        !(
                          _TAB_INFO.basic_into.type == 51 ||
                          _TAB_INFO.basic_into.type == 52 ||
                          _TAB_INFO.basic_into.type == 55 ||
                          (_TAB_INFO.basic_into.type == 56 &&
                            item.charge_setting_id == 0 &&
                            item.period_type == 1)
                        )
                      "
                    >
                      <p class="text">增减项金额</p>

                      <p class="n" v-if="item.is_contain == 1">
                        <router-link
                          class="link_btn"
                          :to="
                            '/adjustList?id=' +
                            _DATA.customer_id +
                            '&cid=' +
                            _DATA.contract_id +
                            '&pid=' +
                            item.payment_id
                          "
                        >
                          ¥{{ item.adjust_money }}>
                        </router-link>
                      </p>
                      <p class="n" v-else>¥{{ item.adjust_money }}</p>
                    </li>
                  </ul>
                </div>

                <div class="do-box margin20">
                  <ol class="ol-flex">
                    <li>
                      <label>款项说明:</label>
                      <span>{{ item.payment_description || '-' }}</span>
                    </li>
                    <li>
                      <label>最新收款时间:</label>
                      <span>{{ item.collection_time || '-' }}</span>
                    </li>
                  </ol>
                </div>

                <div
                  class="total-box"
                  v-if="item.payment_status_str === '已收款'"
                >
                  <p>
                    <label>已收金额</label>
                    <em>
                      <i>¥</i>
                      {{ item.received_money }}
                    </em>
                  </p>
                  <p
                    class="grey_color font_size_13"
                    v-if="
                      !(
                        item.period_type == 2 ||
                        _TAB_INFO.basic_into.type == 51 ||
                        _TAB_INFO.basic_into.type == 52 ||
                        _TAB_INFO.basic_into.type == 55 ||
                        (_TAB_INFO.basic_into.type == 56 &&
                          item.charge_setting_id == 0)
                      )
                    "
                  >
                    <label>应收金额</label>
                    <span class="font_normal">
                      <i class="font_normal">¥</i>
                      {{ item.receivable_amount }}
                    </span>
                  </p>
                  <ul class="wait-box orange_color">
                    <li v-if="item.income_amount_str" class="mt_5">
                      <label>待审核收款</label>
                      <span class="font_normal"
                        >¥ {{ item.income_amount_str }}</span
                      >
                    </li>
                    <li v-if="item.expenditure_amount_str" class="mt_5">
                      <label>待审核退款</label>
                      <span class="font_normal"
                        >¥ {{ item.expenditure_amount_str }}</span
                      >
                    </li>
                  </ul>
                </div>
                <div
                  class="total-box"
                  v-if="item.payment_status_str === '未收款'"
                >
                  <p>
                    <label>剩余应收</label>
                    <em>
                      <i>¥</i>
                      {{ item.balance_money }}
                    </em>
                  </p>
                  <p class="grey_color font_size_13">
                    <label>已收金额</label>
                    <span class="font_normal">
                      <i class="font_normal">¥</i>
                      {{ item.received_money }}
                    </span>
                  </p>
                  <p
                    class="grey_color font_size_13 mt_5"
                    v-if="
                      !(
                        item.period_type == 2 ||
                        _TAB_INFO.basic_into.type == 51 ||
                        _TAB_INFO.basic_into.type == 52 ||
                        _TAB_INFO.basic_into.type == 55 ||
                        (_TAB_INFO.basic_into.type == 56 &&
                          item.charge_setting_id == 0)
                      )
                    "
                  >
                    <label>应收金额</label>
                    <span class="font_normal">
                      <i class="font_normal">¥</i>
                      {{ item.receivable_amount }}
                    </span>
                  </p>
                  <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
                  <ul class="wait-box orange_color">
                    <li v-if="item.income_amount_str" class="mt_5">
                      <label>待审核收款</label>
                      <span class="font_normal"
                        >¥ {{ item.income_amount_str }}</span
                      >
                    </li>
                    <li v-if="item.expenditure_amount_str" class="mt_5">
                      <label>待审核退款</label>
                      <span class="font_normal"
                        >¥ {{ item.expenditure_amount_str }}</span
                      >
                    </li>
                  </ul>
                </div>

                <!-- 操作按钮 -->
                <!-- 企微 v0.1.0&v0.2.0需求补充 合同管理-未签约合同不展示收款-未签约合同不展示收款 -->
                <div
                  class="option-box"
                  v-if="
                    _TAB_INFO.basic_into.status === 3 &&
                    item.payment_status_str === '未收款'
                  "
                >
                  <Row>
                    <Col span="12">
                      <Button
                        type="primary"
                        size="large"
                        class="button-flex"
                        @click="handleClick('去收款', item)"
                      >
                        <span class="font_size_15">去收款</span>
                        <em>
                          <Icon name="arrow" />
                        </em>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
              <template #finished>
                <Divider v-if="_DATA.list.length > 0" style="margin-top: 40px">
                  我也是有底线的
                </Divider>
              </template>
            </List>
          </div>
          <!-- 空状态 -->
          <Empty
            class="empty-fff"
            description="暂无数据"
            v-if="!_DATA.loading && _DATA.list.length === 0"
          />
          <!-- end -->
        </div>
        <!-- tab  收款流水-->
        <div class="tab-three" v-if="_DATA.tab_active === 2">
          <div>
            <List
              v-model:loading="_DATA.loading"
              :finished="_DATA.finished"
              :offset="20"
              @load="loadReceive"
            >
              <div
                class="same-box"
                v-for="(item, index) in _DATA.list"
                :key="index"
              >
                <div class="title-box">
                  <p class="time">{{ item.add_time }}</p>
                  <dl>
                    <dt>
                      <em>{{ item.is_positive == 0 ? '+' : '-' }}</em>
                      {{ item.collection_money }}
                    </dt>
                    <dd>
                      <span>{{ item.payment_detail_type_name }}</span>
                      <em
                        class="split"
                        v-if="
                          item.payment_detail_type_name && item.payment_period
                        "
                      ></em>
                      <span>{{ item.payment_period }}</span>
                    </dd>
                  </dl>
                </div>
                <div class="con-box">
                  <!-- 状态栏 -->
                  <p
                    class="h1"
                    :class="{
                      cancel: [3].indexOf(item.status) > -1,
                      done: [2].indexOf(item.status) > -1,
                      wait: [1].indexOf(item.status) > -1,
                    }"
                  >
                    <Icon name="success" v-if="[2].indexOf(item.status) > -1" />
                    <Icon name="clock-o" v-if="[1].indexOf(item.status) > -1" />
                    <Icon name="cross" v-if="[3].indexOf(item.status) > -1" />
                    {{ item.status_str }}
                  </p>
                  <dl class="same-flex">
                    <dt>合同编号:</dt>
                    <dd>{{ item.contract_no }}</dd>
                  </dl>
                  <dl class="same-flex">
                    <dt>票据编号:</dt>
                    <dd>{{ item.bill_no || '-' }}</dd>
                  </dl>
                  <dl class="same-flex">
                    <dt>收款方式:</dt>
                    <dd>{{ item.collection_method_str || '-' }}</dd>
                  </dl>
                  <dl class="same-flex">
                    <dt>收款账号:</dt>
                    <dd>{{ item.collection_account || '-' }}</dd>
                  </dl>
                  <dl class="same-flex">
                    <dt>收款日期:</dt>
                    <dd>{{ item.collection_time || '-' }}</dd>
                  </dl>
                  <dl class="same-flex">
                    <dt>提交人:</dt>
                    <dd>{{ item.operator_user_str || '-' }}</dd>
                  </dl>
                  <dl class="same-flex">
                    <dt>备注:</dt>
                    <dd>{{ item.remarks || '-' }}</dd>
                  </dl>
                  <!-- 附件 -->
                  <div
                    class="fileList"
                    v-if="item.file_url && item.file_url.length > 0"
                  >
                    <FileGrid :data="item.file_url"></FileGrid>
                  </div>

                  <!-- end -->
                </div>
              </div>
              <template v-if="_DATA.list.length > 0" #finished>
                <Divider class="divider-bottom">我也是有底线的</Divider>
              </template>
            </List>
          </div>
          <!-- 空状态 -->
          <Empty
            class="empty-fff"
            description="暂无数据"
            v-if="!_DATA.loading && _DATA.list.length === 0"
          />
          <!-- end -->
        </div>
        <!-- tab  合同增减项 V1.4.7变更记录-->
        <div class="tab-four" v-if="_DATA.tab_active === 3">
          <contractChangeInfo></contractChangeInfo>
        </div>
        <!-- tab 客户结算单 v0.3.0 -->
        <div class="tab-five" v-if="_DATA.tab_active === 4">
          <CellGroup class="same-box noBorder">
            <Cell
              title="结算日期"
              :value="_TAB_BILL.info.statement_date || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="票据编号"
              :value="_TAB_BILL.info.bill_no || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="合同编号"
              :value="_TAB_BILL.info.contract_no || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
          </CellGroup>

          <CellGroup class="same-box noBorder">
            <Cell
              title="客户编号"
              :value="_TAB_BILL.info.customer_no || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="客户名称"
              :value="_TAB_BILL.info.customer_name || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="电话"
              :value="_TAB_BILL.info.customer_phone || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="楼盘信息"
              :value="_TAB_BILL.info.estate_str || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
          </CellGroup>

          <CellGroup class="same-box noBorder">
            <Cell
              title="项目经理"
              :value="_TAB_BILL.info.project_manager_name || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="主创设计师"
              :value="_TAB_BILL.info.designer_name || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
          </CellGroup>

          <CellGroup class="same-box noBorder vanCellGroup">
            <Cell
              :title="
                _TAB_INFO.basic_into.type == 53 ||
                _TAB_INFO.basic_into.type == 69
                  ? '累计产品已收款'
                  : '签订合同价'
              "
              title-class="title"
              value-class="value"
              class="no"
            >
              <template #value>
                <div class="right">
                  <h1>¥{{ _TAB_BILL.info.contract_amount }}</h1>
                </div>
              </template>
            </Cell>
            <p class="p-describe">{{ _TAB_BILL.info.contract_amount_str }}</p>
          </CellGroup>

          <CellGroup
            class="same-box noBorder"
            v-if="
              _TAB_INFO.basic_into.type != 53 && _TAB_INFO.basic_into.type != 69
            "
          >
            <Cell
              title="基装增项"
              :value="_TAB_BILL.info.base_plus_amount || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="基装减项"
              :value="_TAB_BILL.info.base_minus_amount || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              v-if="
                _TAB_INFO.basic_into.type != 54 &&
                _TAB_INFO.basic_into.type != 56
              "
              title="主材增项"
              :value="_TAB_BILL.info.main_plus_amount || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              v-if="
                _TAB_INFO.basic_into.type != 54 &&
                _TAB_INFO.basic_into.type != 56
              "
              title="主材减项"
              :value="_TAB_BILL.info.main_minus_amount || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="其他增项"
              :value="_TAB_BILL.info.other_plus_amount || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="其他减项"
              :value="_TAB_BILL.info.other_minus_amount || '-'"
              title-class="title"
              value-class="value"
            ></Cell>
            <Cell
              title="增减项合计"
              title-class="title"
              value-class="value"
              class="no"
            >
              <template #value>
                <div class="right">
                  <h1>¥{{ _TAB_BILL.info.item_amount }}</h1>
                </div>
              </template>
            </Cell>
            <p class="p-describe">{{ _TAB_BILL.info.item_amount_str }}</p>
          </CellGroup>

          <CellGroup class="same-box noBorder">
            <Cell
              :title="
                _TAB_INFO.basic_into.type == 53 ||
                _TAB_INFO.basic_into.type == 69
                  ? '累计产品占款金额'
                  : '已缴费金额'
              "
              title-class="title"
              value-class="value"
              class="no"
            >
              <template #value>
                <div class="right">
                  <h1>¥{{ _TAB_BILL.info.received_money }}</h1>
                </div>
              </template>
            </Cell>
            <p class="p-describe" style="border-bottom: 1px solid #f7f7f8">
              {{ _TAB_BILL.info.received_money_str }}
            </p>
            <Cell
              :title="
                _TAB_INFO.basic_into.type == 54 ||
                _TAB_INFO.basic_into.type == 56
                  ? '剩余应缴'
                  : _TAB_INFO.basic_into.type == 53 ||
                    _TAB_INFO.basic_into.type == 69
                  ? '剩余可用金额'
                  : '应缴尾款'
              "
              :value="_TAB_BILL.info.statement_date"
              title-class="title"
              value-class="value"
              class="no"
            >
              <template #value>
                <div class="right">
                  <h1>¥{{ _TAB_BILL.info.receivable_money }}</h1>
                </div>
              </template>
            </Cell>
            <p class="p-describe">{{ _TAB_BILL.info.receivable_money_str }}</p>
          </CellGroup>

          <div class="same-box-detail">
            <h2>缴费明细</h2>
            <CellGroup
              v-if="_TAB_BILL.info.receive_logs?.length > 0"
              class="group"
            >
              <Cell
                v-for="(item, index) in _TAB_BILL.info.receive_logs"
                :key="index"
              >
                {{ item }}
              </Cell>
            </CellGroup>
            <p v-else>-</p>
          </div>
          <div
            class="same-box-detail"
            v-if="
              _TAB_INFO.basic_into.type == 53 || _TAB_INFO.basic_into.type == 69
            "
          >
            <h2>占款记录</h2>
            <CellGroup
              v-if="_TAB_BILL.paymentDetailList?.length > 0"
              class="group"
            >
              <Cell
                v-for="(item, index) in _TAB_BILL.paymentDetailList"
                :key="index"
              >
                <div class="flex_between align_center font_size_15">
                  <div class="flex_column">
                    <div>{{ item.add_time }}|{{ item.type_name }}</div>
                    <div class="font_size_13 grey_color">
                      {{ item.supplier_name
                      }}{{ item.category_name ? ';' + item.category_name : ''
                      }}{{ ';' + item.order_no }}
                    </div>
                  </div>
                  <div>{{ item.amount }}</div>
                </div>
              </Cell>
            </CellGroup>
            <p v-else>-</p>
          </div>
          <div
            class="same-box-detail"
            v-if="
              _TAB_INFO.basic_into.type == 54 || _TAB_INFO.basic_into.type == 56
            "
          >
            <h2>结算单</h2>
            <CellGroup
              v-if="_TAB_BILL.info.witness_list?.length > 0"
              class="group"
            >
              <Cell
                is-link
                v-for="(item, index) in _TAB_BILL.info.witness_list"
                :key="index"
                @click="goWintess(item)"
              >
                {{ item.witness_name }}
              </Cell>
            </CellGroup>
            <p v-else>-</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 动作面板 - 合同增减项 - 更多 -->
    <ActionSheet
      v-model:show="_TAB_ADJUST.actiones_show"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
    >
      <div class="van-action-sheet__content">
        <div
          v-for="(item, index) in _TAB_ADJUST.actions_list"
          :key="index"
          @click="adjustFunc(item.type)"
        >
          <Button
            class="van-action-sheet__item"
            type="button"
            v-if="item.action.indexOf(_TAB_ADJUST.actions_status) > -1"
          >
            <span class="van-action-sheet__name">
              {{ item.name }}
            </span>
          </Button>
        </div>
      </div>
    </ActionSheet>
    <!-- 风险提示 -->
    <ActionSheet
      class="dangerActionSheet"
      v-model:show="_DATA.dangerOperate.show"
    >
      <div class="content">
        <div class="content_item">
          <h3>风险提示</h3>
          <p>
            合同发起前，请确认客户的姓名与对应手机号正确，如
            <span>姓名输入错误</span>
            或
            <span>非运营商所登记的手机号持有人</span>
            ，将需要客户亲自操作三方合同签署平台注销账号
          </p>
          <div class="dangerForm">
            <Form ref="ruleFormRef">
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.name"
                  label="客户姓名"
                  placeholder="请输入正确的姓名"
                  input-align="right"
                  error-message-align="right"
                  maxlength="20"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的姓名',
                      validator: validatorName,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.phone"
                  type="number"
                  label="手机号"
                  placeholder="请输入正确的手机号"
                  input-align="right"
                  error-message-align="right"
                  maxlength="11"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的手机号',
                      validator: validatorPhone,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
              <p class="tip">
                请再次确认系统中录入的楼盘名称与单元栋号正确，此信息将记录于合同中，发送客户在线签署
              </p>
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.house"
                  label="楼盘"
                  placeholder="请输入正确的楼盘"
                  input-align="right"
                  error-message-align="right"
                  maxlength="20"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的楼盘',
                      validator: validatorHouse,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.floor"
                  label="单元栋号"
                  placeholder="请输入正确的单元栋号"
                  input-align="right"
                  error-message-align="right"
                  maxlength="30"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的单元栋号',
                      validator: validatorFloor,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
            </Form>
          </div>
        </div>
        <div class="content_button">
          <Button
            block
            loading-type="spinner"
            @click="sumbitAndCreate"
            :loading="_DATA.dangerOperate.btnLoading"
            loading-text="确认并创建合同"
          >
            确认并创建合同
          </Button>
        </div>
      </div>
    </ActionSheet>
    <!-- end -->
    <!-- v1.2.0 BPM -->
    <ActionSheet v-model:show="_DATA.BPM.show" class="dangerActionSheet">
      <BPMAction
        v-if="_DATA.BPM.show"
        :data="_DATA.BPM"
        @changeBPM="changeBPM"
        :id="_DATA.customer_id"
      />
    </ActionSheet>
    <!-- BPM end -->
  </div>
</template>

<script setup>
import FileGrid from '@/components/common/FileGrid';
import DetailTemp from '@/components/contract/DetailTemp';
import BPMAction from '@/components/contract/BPMAction';
import contractChangeInfo from '@/components/contract/contractChangeInfo.vue';
import { onMounted, reactive, ref, inject, nextTick, computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  Button,
  Icon,
  Row,
  Col,
  Sticky,
  Tabs,
  Tab,
  ActionSheet,
  Dialog,
  Notify,
  // Image,
  Loading,
  Overlay,
  Empty,
  List,
  Divider,
  // ImagePreview,
  Form,
  CellGroup,
  Cell,
  Field,
} from 'vant';
import { useStore } from 'vuex';

const $http = inject('$http');
// const $host = inject('$host');
const router = useRouter();
const container = ref(null);
const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const _DATA = reactive({
  customer_id: router.currentRoute.value.query.id, //客户ID
  contract_id: router.currentRoute.value.query.cid, //合同ID
  loading: false,
  finished: false,
  refreshing: false,
  tab_active: 0,
  loading_text: ' ',
  status_list: ['全部', '待签约', '已签约', '作废'],
  type_list: ['-', '汇入合同款', '单独收款'],

  list: [],
  page: {
    page: 1,
    pages: 10,
    total: 0,
    count_page: 1,
  },
  showAllFile: false, //是否显示附件列表
  //-风险提示相关--begin
  dangerOperate: {
    show: false,
    btnLoading: false,
  },
  //-风险提示相关--end
  //-BPM--begin
  BPM: {
    show: false,
    query: {},
  },
  //-BPM--end
});
// @默认值
const Data = reactive({
  loading: false,
  list: [],
  page: {
    page: 1,
    pages: 10,
    total: 0,
    count_page: 1,
  },
});

const optionList = [
  {
    status: 1,
    status_name: '待发起',
    icons: [
      { name: '发起合同', key: 3, type: 'primary' },
      { name: '作废', key: 1, type: 'default' },
    ],
  },
  {
    status: 2,
    status_name: '待签约',
    icons: [{ name: '作废', key: 1, type: 'default' }],
  },
  {
    status: 3,
    status_name: '已签约',
    icons: [
      { name: '去收款', key: 5, type: 'primary' },
      { name: '关联作废合同', key: 4, type: 'default' },
    ],
  },
  {
    status: 4,
    status_name: '作废',
    icons: [
      { name: '重新生成合同', key: 2, type: 'default' },
      { name: '关联作废合同', key: 4, type: 'default' },
    ],
  },
];
// @合同详情相关
const _TAB_INFO = reactive({
  loading: false,
  basic_into: {},
  header: {}, //合同详情
  project_members: [], //项目成员
  oprate_list: [],
  // -- 更多操作
  //action 对应合同详情 - v1.1.0更改 只存在“查看电子合同”操作
});

// @增减项相关
const _TAB_ADJUST = reactive({
  // -- 更多操作
  // -- 1 待审核、2 审核不通过、3 待客户确认、4 已完成、5 客户已拒绝、6 已取消
  actiones_show: false,
  actions_list: [
    {
      type: 1,
      name: '作废增减项',
      action: [1, 3],
    },
    {
      type: 2,
      name: '重新生成增减项',
      action: [1, 2, 3, 5, 6],
    },
  ],
  actions_id: '',
  actions_status: null, // 状态值 ‘合同详情状态’、‘合同增减项状态’
});
// @合同状态图标
const Icons = ref([
  {
    status: 1,
    url: require('@/assets/images/icon-wait.png'),
    class: 'yellow',
  },
  {
    status: 2,
    url: require('@/assets/images/icon-wait-blue.png'),
    class: 'blue',
  },
  {
    status: 3,
    url: require('@/assets/images/icon-pass.png'),
    class: 'green',
  },
  {
    status: 4,
    url: require('@/assets/images/icon-delete.png'),
    class: 'gray',
  },
]);

// @结算单相关
const _TAB_BILL = reactive({
  info: {},
});

onMounted(() => {
  _TAB_INFO.oprate_list = JSON.parse(JSON.stringify(optionList));
  loadInfo();
});

/**
 * 接口调用
 */
const tabFunc = () => {
  _DATA.page = JSON.parse(JSON.stringify(Data.page));
  _DATA.list = JSON.parse(JSON.stringify(Data.list));
  _DATA.finished = false;
  _DATA.refreshing = false;
  _DATA.tab_active === 0 && loadInfo();
  _DATA.tab_active === 4 && loadAccount();
};
//@tab0 合同详情
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .getContractInfoApi({
      contract_id: _DATA.contract_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data.basic_into.invalid_flag < 1) {
          _TAB_INFO.oprate_list[2].icons =
            _TAB_INFO.oprate_list[2].icons.filter((item) => item.key !== 4);
          _TAB_INFO.oprate_list[3].icons =
            _TAB_INFO.oprate_list[3].icons.filter((item) => item.key !== 4);
        } else if (
          data.basic_into.invalid_flag === 1 &&
          data.basic_into.status === 4
        ) {
          _TAB_INFO.oprate_list[3].icons =
            _TAB_INFO.oprate_list[3].icons.filter((item) => item.key !== 2);
        }

        _TAB_INFO.basic_into = data.basic_into;
        _TAB_INFO.header = data.header;
        _TAB_INFO.project_members = data.project_members;
        // 更改操作默认数据，判断查看“关联作废合同”按钮是否展示
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      setTimeout(() => {
        _DATA.loading = false;
      }, 500);
    });
};
//@tab1 款项明细
const loadPayment = () => {
  _DATA.loading = true;
  $http
    .getContractPaymentListApi({
      contract_id: _DATA.contract_id,
      page: _DATA.page.page,
      pages: _DATA.page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list = _DATA.list.concat(data.list);
        _DATA.page = data.page;
        if (data.page.page < data.page.count_page) {
          _DATA.page.page++;
        } else {
          _DATA.finished = true;
        }
      } else {
        _DATA.finished = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    })
    .catch(() => {
      _DATA.finished = true;
    });
};
//@tab2 收款流水
const loadReceive = () => {
  console.log('收款流水');
  _DATA.loading = true;
  $http
    .getContractPaymentDetailApi({
      contract_id: _DATA.contract_id,
      page: _DATA.page.page,
      pages: _DATA.page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list = _DATA.list.concat(data.list);
        _DATA.page = data.page;
        if (data.page.page < data.page.count_page) {
          _DATA.page.page++;
        } else {
          _DATA.finished = true;
        }
      } else {
        _DATA.finished = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    })
    .catch(() => {
      _DATA.finished = true;
    });
};
//@tab3 合同增减项
const loadAdjust = () => {
  console.log('合同增减项');
  _DATA.loading = true;
  // _DATA.list = [];
  $http
    .getContractAdjustListApi({
      contract_id: _DATA.contract_id,
      page: _DATA.page.page,
      pages: _DATA.page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list = _DATA.list.concat(data.list);
        Object.assign(_DATA.page, { ...data.page });
        if (data.page.page < data.page.count_page) {
          _DATA.page.page++;
        } else {
          _DATA.finished = true;
        }
      } else {
        _DATA.finished = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    })
    .catch(() => {
      _DATA.finished = true;
    });
};
//@tab4 客户结算单
const loadAccount = () => {
  console.log('客户结算单');
  $http
    .getCustomerStatementApi({
      contract_id: _DATA.contract_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _TAB_BILL.info = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
  //产品 占款记录
  if (_TAB_INFO.basic_into.type == 53 || _TAB_INFO.basic_into.type == 69) {
    $http
      .customerPaymentDetailList({
        customer_id: _DATA.customer_id,
        page: 1,
        pages: 999,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          _TAB_BILL.paymentDetailList = data.list;
        } else {
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  }
};
const goWintess = (item) => {
  router.push({
    path: '/witnessViewConstruction',
    query: {
      witness_id: item.witness_id,
      node_id: item.node_id,
    },
  });
};
//-------------------------

/**
 * 操作
 */
//-@ 图片预览
// const previewImage = (arr, index) => {
//   ImagePreview({
//     images: arr.map((v) => $host + v.path), // 预览图片的那个数组
//     startPosition: index || 0, // 指明预览第几张图
//   });
// };
//-@ 获取当前iconList
const getList = (status) => {
  const list = Icons.value.filter((val) => val.status == status);
  return list;
};
//-@ [合同详情] - 更多拉起
// const showMore = () => {
//   _TAB_INFO.actiones_show = true;
// };
// const showMoreB = (status, id) => {
//   _TAB_ADJUST.actions_id = id;
//   _TAB_ADJUST.actions_status = status;
//   _TAB_ADJUST.actiones_show = true;
// };
//-@ 取消拉起
const onCancel = () => {
  _TAB_INFO.actiones_show = false;
  _TAB_ADJUST.actiones_show = false;
};
//-@ 作废、重新生成合同、关联作废合同
const dialogFunc = (type) => {
  _TAB_INFO.actiones_show = false;
  if (type === 4) {
    router.push({
      path: '/Ccancellation',
      query: {
        id: router.currentRoute.value.query.id,
        cdid: router.currentRoute.value.query.cid,
      },
    });
    return;
  } else if (type === 5) {
    _DATA.BPM.query = {};
    Object.assign(_DATA.BPM.query, {
      id: router.currentRoute.value.query.id,
      cid: router.currentRoute.value.query.cid,
    });
    getBPM();
    return;
  }
  Dialog.confirm({
    message:
      type === 1
        ? '确定要作废该合同吗？'
        : type === 2
        ? '是否重新生成新的合同？'
        : '确定给客户发起签署合同吗？',
  }).then(() => {
    // on confirm
    if (type === 1) {
      $http
        .getContractInvalidApi({
          contract_id: _DATA.contract_id,
        })
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
            });
            _DATA.list = [];
            _DATA.page.page = 1;
            loadInfo();
          } else {
            Notify({
              type: 'warning',
              message: data,
            });
          }
        });
    } else if (type === 2) {
      //-风险提示
      _DATA.dangerOperate.show = true;
      $http
        .getCustomerManagementSimpleInfoApi({
          customer_id: _DATA.customer_id,
        })
        .then((res) => {
          if (res.code === 0) {
            form.dangerList.name = res.data.customer_name;
            form.dangerList.phone = res.data.customer_phone;
            form.dangerList.house = res.data.estate_name;
            form.dangerList.floor = res.data.house_unit_home_name;
          } else {
            Notify({ type: 'warning', message: res.data });
          }
        });
      nextTick(() => {
        ruleFormRef.value?.resetValidation();
      });
    } else if (type === 3) {
      $http
        .getContractPostESignApi({
          contract_id: _DATA.contract_id,
        })
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
            });
            _DATA.list = [];
            _DATA.page.page = 1;

            loadInfo();
          } else {
            Notify({
              type: 'warning',
              message: data,
            });
          }
        });
    }
  });
};
//-@作废、重新生成 [增减项]变更单
const adjustFunc = (type) => {
  _TAB_ADJUST.actiones_show = false;
  Dialog.confirm({
    message:
      type === 1
        ? '确定要作废该合同增减项吗？'
        : '是否重新生成新的合同增减项？',
  }).then(() => {
    // on confirm
    if (type === 1) {
      $http
        .getContractAdjustFailApi({
          id: _TAB_ADJUST.actions_id,
        })
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
            });
            _DATA.list = [];
            _DATA.page.page = 1;
            loadAdjust();
          } else {
            Notify({
              type: 'warning',
              message: data,
            });
          }
        });
    } else if (type === 2) {
      router.push({
        path: '/contractChange',
        query: {
          id: _DATA.customer_id,
          gId: _TAB_ADJUST.actions_id,
        },
      });
    }
  });
};

//-@v1.2.0 判断BPM
const getBPM = () => {
  store.dispatch('BPM/getBpmAction', _DATA.customer_id).then((res) => {
    if (res) {
      changeBPM();
    } else {
      _DATA.BPM.show = true;
    }
  });
};
//-@v1.2.0 BPM成功返回
const changeBPM = () => {
  router.push({
    path: '/Ccollection',
    query: { ..._DATA.BPM.query },
  });
};
//-@
const handleClick = (name, item) => {
  if (name === '去收款') {
    _DATA.BPM.query = {};
    Object.assign(_DATA.BPM.query, {
      id: router.currentRoute.value.query.id,
      cid: router.currentRoute.value.query.cid,
      pid: item.payment_id, //款期id
    });
    getBPM();
    return;
  } else if (name === '退款') {
    router.push({
      path: '/Creturn',
      query: {
        id: _DATA.customer_id,
        pid: item.payment_id,
        cid: _DATA.contract_id,
      },
    });
  } else if (name === '电子合同') {
    router.push({
      path: '/pdf',
      query: {
        url: item.contract_url,
      },
    });
  }
};
//-风险提示--begin
const form = reactive({
  list: [],
  //-风险提示相关
  dangerList: {
    name: '',
    phone: '',
    house: '',
    floor: '',
  },
});
const ruleFormRef = ref(null);
// 表单验证
const phoneReg = /^(1\d{10})$/;
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
const nameReg = /^.{2,20}$/;
const validatorName = (val) => (val ? nameReg.test(val) : true);
const houseReg = /^.{0,20}$/;
const validatorHouse = (val) => (val ? houseReg.test(val) : true);
const floorReg = /^.{0,30}$/;
const validatorFloor = (val) => (val ? floorReg.test(val) : true);

const sumbitAndCreate = () => {
  _DATA.dangerOperate.btnLoading = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      _DATA.dangerOperate.btnLoading = false;
      $http
        .getCustomerChangeSign({
          customer_id: _DATA.customer_id,
          phone: form.dangerList.phone,
          name: form.dangerList.name,
          estate_name: form.dangerList.house,
          house_unit_home_name: form.dangerList.floor,
        })
        .then((res) => {
          if (res.code === 0) {
            _DATA.dangerOperate.show = false;
            if (_TAB_INFO.basic_into.change_contract_type_id) {
              router.push({
                path: '/contractModifyInfo',
                query: {
                  id: _DATA.customer_id,
                  cid: _DATA.contract_id,
                },
              });
            } else {
              router.push({
                path: '/contractIndex',
                query: {
                  id: _DATA.customer_id,
                  cid: _DATA.contract_id,
                },
              });
            }
          } else {
            Notify({ type: 'warning', message: res.data });
          }
        });
    })
    .catch(() => {
      _DATA.dangerOperate.btnLoading = false;
      //验证失败
      console.log('请先完善风险提示信息');
    });
};
//-风险提示--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/contractDetail.less';
</style>
