<!--
 * @Author: gest
 * @Date:2023-07-05 11:35:35
 * @LastEditTime: 2023-07-05 16:31:12
 * @Description: V1.4.1  解除施工团队
-->
<template>
  <!-- 分派施工团队-->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="state.contract_list?.length > 0"
      @click="
        () => {
          state.contract_list?.length > 1
            ? (state.showPicker_contract = true)
            : null;
        }
      "
    >
      <div class="left">
        <h1>{{ form.list.contract_name }}</h1>
        <p>合同编号：{{ form.list.contract_no }}</p>
      </div>
      <div class="right" v-if="state.contract_list?.length > 1">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              解除说明
            </label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="填写解除说明"
              :rules="[
                {
                  required: true,
                  message: '请填写解除说明',
                },
              ]"
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.files"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->

    <!--picker-头部合同切换 --->
    <Popup v-model:show="state.showPicker_contract" position="bottom">
      <Picker
        :columns="state.contract_list"
        :columns-field-names="{
          text: 'contract_name',
          value: 'contract_id',
        }"
        :default-index="
          state.contract_list.findIndex((v) => {
            return v.contract_id == form.list.contract;
          })
        "
        @confirm="(v) => onConfirm(v, 'contract')"
        @cancel="state.showPicker_contract = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-头部合同选择-picker
  showPicker_contract: false,
  contract_list: [],
  //-头部合同id
  contract_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cid
    : '',

  checkInfo: {},
});

const onConfirm = (value, type) => {
  if (value) {
    // 头部合同切换
    if (type == 'contract') {
      form['list'][type] = value.contract_id;
      form['list'][type + '_name'] = value.contract_name;
      form['list'][type + '_no'] = value.contract_no;
      form['list']['construction_id'] = value.construction_id;
      state.checkInfo = value;
    }
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getCustomerListParmas();
});

//-@头部合同参数
const getCustomerListParmas = async () => {
  state.loadingConfirm = true;
  await $http
    .getDesignCustomerListApi({
      customer_id: router.currentRoute.value.query?.id,
      type: 2,
    })
    .then((res) => {
      const { data, code } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        state.contract_list = data.list;
        if (data.list?.length) {
          const typeList1 = data.list.filter(
            (v) => v.contract_id == state.contract_id
          );
          if (typeList1.length) {
            state.checkInfo = typeList1[0];
            form.list.contract = typeList1[0].contract_id;
            form.list.contract_name = typeList1[0].contract_name;
            form.list.contract_no = typeList1[0].contract_no;
            form.list.construction_id = typeList1[0].construction_id;
          } else {
            state.checkInfo = data.list[0];
            form.list.contract = data.list[0].contract_id;
            form.list.contract_name = data.list[0].contract_name;
            form.list.contract_no = data.list[0].contract_no;
            form.list.construction_id = data.list[0].construction_id;
          }
        }
      } else {
        Toast(res.data);
      }
    });
};

//-获取参数--end

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    construction_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.gdid
      : '',
    contract: router.currentRoute.value.query
      ? router.currentRoute.value.query.cid
      : '',
    contract_name: '',
    contract_no: '',
    remarks: '',
    files: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goConfirm();
    })
    .catch(() => {
      //验证失败
      Toast('请完善信息');
    });
};
const goConfirm = () => {
  state.loadingConfirm = true;
  $http
    .construction_cancelTeam({
      construction_id: form.list.construction_id,

      remarks: form.list.remarks,
      files: form.list.files,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

//-@附件
const getFileList = (file_list) => {
  form.list.files = file_list;
};
//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
