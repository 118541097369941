<!--
 * @Author: gest
 * @Date: 2022-11-04 15:28:59
 * @LastEditTime: 2023-10-31 14:45:54
 * @Description: 派单确认见面
 * v1.3.5 smart 客户经理选择回单，增加回单原因
 * v1.4.3 gest 首见跟进
 * v1.4.8 smart 客户经理选择回单，回单原因变更为 下拉选择
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    title="首见跟进"
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="tip_box" v-if="state.showType != 'look'">
      <Icon name="warning-o" :size="20" style="margin-top: 4px" />
      <p class="ml_10">首见跟进必须分享到客户群</p>
    </div>

    <!-- 表单操作 -->

    <div class="formBase" v-if="state.showType != 'look'">
      <Form ref="ruleFormRef">
        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item right">
              <div class="flex_between">
                <p>
                  <span class="red_color">*</span>
                  本次跟进时间
                </p>
                <div>{{ form.list.now_follow_time }}</div>
              </div>
              <p class="font_size_13 red_color mt_5">
                此为网销确认的见面时间，如有疑问，请联系人力申诉。
              </p>
            </div>
          </div>
        </div>
        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="flex_between">
                <div>
                  <span class="red_color">*</span>
                  跟进类型
                </div>
                <Field
                  name="radio"
                  label=""
                  :rules="[
                    {
                      required: true,
                      message: '请选择跟进类型',
                    },
                  ]"
                >
                  <template #input>
                    <RadioGroup
                      v-model="form.list.meet_type"
                      direction="horizontal"
                      checked-color="#008911"
                    >
                      <Radio :name="2">见面</Radio>
                      <Radio :name="3">回访</Radio>
                    </RadioGroup>
                  </template>
                </Field>
              </div>
            </div>
            <div class="field_item">
              <div class="flex_between">
                <div>
                  <span class="red_color">*</span>
                  见工地
                </div>
                <Field
                  name="radio"
                  label=""
                  :rules="[
                    {
                      required: true,
                      message: '请选择见工地',
                      trigger: 'onChange',
                    },
                  ]"
                >
                  <template #input>
                    <RadioGroup
                      v-model="form.list.is_face_site"
                      direction="horizontal"
                      checked-color="#008911"
                    >
                      <Radio :name="1">是</Radio>
                      <Radio :name="2">否</Radio>
                    </RadioGroup>
                  </template>
                </Field>
              </div>
            </div>
            <div class="field_item">
              <div class="flex_between">
                <div>
                  <span class="red_color">*</span>
                  讲预案
                </div>
                <Field
                  name="radio"
                  label=""
                  :rules="[
                    {
                      required: true,
                      message: '请选择讲预案',
                      trigger: 'onChange',
                    },
                  ]"
                >
                  <template #input>
                    <RadioGroup
                      v-model="form.list.is_design_plan"
                      direction="horizontal"
                      checked-color="#008911"
                    >
                      <Radio :name="1">是</Radio>
                      <Radio :name="2">否</Radio>
                    </RadioGroup>
                  </template>
                </Field>
              </div>
            </div>
          </div>
        </div>

        <div class="content mb_15">
          <div class="white_bg p_15 flex_between">
            <div class="flex_column flex1 mr_15">
              <div>
                <span class="red_color">*</span>
                量房
              </div>
            </div>

            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'onChange',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick"
                  v-model="form.list.is_measure_house"
                  direction="horizontal"
                  checked-color="#008911"
                >
                  <Radio :name="1">需要</Radio>
                  <Radio :name="2">不需要</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>

          <div
            class="list_field"
            style="background-color: #fafafa"
            v-if="form.list.is_measure_house == 1"
          >
            <div class="field_item right">
              <div class="flex_between">
                <div class="grey_color">
                  <span class="red_color">*</span>
                  承诺量房时间
                </div>
                <Field
                  style="text-align: right; background-color: #fafafa"
                  v-model="form.list.measure_house_time"
                  right-icon="arrow"
                  name="picker"
                  placeholder="选择"
                  readonly
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                    },
                  ]"
                  @click="openDateType('measure_house_time')"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="label-name">
                <span class="red_color">*</span>
                本次跟进内容
              </div>
              <Field
                v-model="form.list.follow_remark"
                rows="5"
                autosize
                type="textarea"
                maxlength="2000"
                placeholder="仅做记录，客户不可见"
                show-word-limit
                :rules="[
                  {
                    required: true,
                    message: '请填写本次跟进内容',
                  },
                ]"
              ></Field>
            </div>
          </div>
        </div>

        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item right">
              <div class="flex_between">
                <div>
                  <span class="red_color">*</span>
                  是否确认接单
                </div>
                <Field
                  name="radio"
                  label=""
                  :rules="[
                    {
                      required: true,
                      message: '请选择是否确认接单',
                    },
                  ]"
                >
                  <template #input>
                    <RadioGroup
                      v-model="form.list.approve_type"
                      direction="horizontal"
                      checked-color="#008911"
                    >
                      <div
                        v-for="item in state.approve_type_list"
                        :key="item.id"
                      >
                        <div>
                          <Radio :name="item.id">
                            <div>
                              {{ item.name }}
                            </div>
                          </Radio>
                        </div>
                      </div>
                    </RadioGroup>
                  </template>
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div
          class="font_size_13 grey_color"
          style="padding: 0 15px 6px 15px"
          v-if="form.list.approve_type == 2"
        >
          下次跟进内容分享客户可见
        </div>

        <div class="content mb_15" v-if="form.list.approve_type == 2">
          <div class="list_field white_bg">
            <div class="field_item right">
              <div class="flex_between">
                <p>
                  <span class="red_color">*</span>
                  下次跟进时间
                </p>
                <Field
                  style="text-align: right"
                  v-model="form.list.next_follow_time"
                  right-icon="arrow"
                  name="picker"
                  placeholder="选择"
                  readonly
                  :rules="[
                    {
                      required: true,
                      message: '请选择',
                    },
                  ]"
                  @click="openDateType('next_follow_time')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="content mb_15" v-if="form.list.approve_type == 2">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="label-name">
                <span class="red_color">*</span>
                下次跟进内容
              </div>
              <Field
                v-model="form.list.next_follow_remark"
                rows="5"
                autosize
                type="textarea"
                maxlength="500"
                placeholder="添加下次跟进内容..."
                show-word-limit
                :rules="[
                  {
                    required: true,
                    message: '请填写下次跟进内容',
                  },
                ]"
              ></Field>
            </div>
          </div>
        </div>

        <div class="content mb_15" v-if="form.list.approve_type == 3">
          <div class="list_field white_bg">
            <div class="field_item right">
              <div class="flex_between">
                <p>
                  <span class="red_color">*</span>
                  回单原因
                </p>
                <Field
                  v-model="form.list.receipt_cause"
                  right-icon="arrow"
                  name="picker"
                  placeholder="选择"
                  readonly
                  :disabled="state.showType == 'look'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择回单原因',
                    },
                  ]"
                  @click="openReceiptType('receipt_cause')"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="label-name">备注</div>
              <Field
                v-model="form.list.remark"
                rows="3"
                autosize
                type="textarea"
                maxlength="100"
                placeholder="此备注派单部门可见"
                show-word-limit
                :disabled="state.showType == 'look'"
              ></Field>
            </div>
          </div>
        </div>
      </Form>
    </div>

    <followInfo
      :data="state.meetInfo"
      v-if="state.showType == 'look'"
    ></followInfo>

    <div class="btnSumbit" v-if="state.showType != 'look'">
      <Button
        class="btn"
        type="default"
        size="large"
        @click.stop="btnSumbitDraft"
      >
        保存
      </Button>
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        提交
      </Button>
    </div>
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="state.minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>
    <!-- 回单原因 -->
    <Popup v-model:show="receiptPicker.show" position="bottom">
      <Picker
        :columns="state.receipt_cause_type_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="(value) => changeReceiptPicker(value, 'receipt_cause')"
        @cancel="clearReceiptPicker('receipt_cause')"
        cancel-button-text="清除"
        :default-index="
          state.receipt_cause_type_list.findIndex((v) => {
            return v.id == form.list.receipt_cause_type;
          })
        "
      />
    </Popup>
    <!-- 二次确认 action -->
    <ActionSheet v-model:show="action.action_show" class="actionbox">
      <template #default>
        <div class="action-content">
          <Icon name="warning" color="#FFC300" size="54" />
          <h1>是否确认回单？</h1>
          <p class="des">
            中心经理必须于{{
              state.deadline_time
            }}前通过才能生效，提交后请尽快联系
          </p>
          <Button
            type="danger"
            block
            size="large"
            @click="actionConfirm"
            :loading="state.loadingConfirm"
          >
            确认回单
          </Button>
          <a href="javascript:;" @click="action.action_show = false">取消</a>
        </div>
      </template>
    </ActionSheet>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, onMounted } from 'vue';
import followInfo from '@/components/moreOperate/followInfo.vue'; //v1.4.3 跟进详情

// import { authWxAgentData, shareRecordList } from '@/utils/wechat';
import moment from 'moment';
import {
  Field,
  Button,
  Toast,
  Loading,
  Overlay,
  Icon,
  RadioGroup,
  Radio,
  Notify,
  ActionSheet,
  Popup,
  DatetimePicker,
  Dialog,
  NavBar,
  Form,
  Picker,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');

const state = reactive({
  showType: router.currentRoute.value.query
    ? router.currentRoute.value.query.showType
    : '',
  schedule: router.currentRoute.value.query
    ? router.currentRoute.value.query.schedule
    : '',
  loading: false,
  loadingConfirm: false,
  five_step_service_list: [],
  sign_trilogy_list: [],
  receipt_cause_type_list: [], //回单原因
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  approve_type_list: [
    { id: 2, name: '接单' },
    { id: 3, name: '回单' },
  ],
  deadline_time: '',
  is_show_receipt: 0, //是否展示回单按钮
  meetInfo: {},
});
//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    todo_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    now_follow_time: '',
    // five_step_service: [],
    // sign_trilogy: [],
    follow_remark: '',
    attach: [],
    next_follow_time: '',
    next_follow_remark: '',
    approve_type: '',
    receipt_cause: '', //回单原因
    receipt_cause_type: '', //v1.4.8 新版回单原因
    remark: '',
    // measure_house_attach: [],
    // design_plan_attach: [],
    // design_rehearsal_attach: [],
    meet_type: '',
    is_face_site: '',
    is_design_plan: '',
    is_measure_house: '',
    measure_house_time: '',
  },
});
const action = reactive({
  action_show: false,
});
//-获取参数--begin
onBeforeMount(() => {
  if (state.schedule == 1) {
    followTodoDetail();
  } else {
    getConfirmMeetingInfo();
  }
});
onMounted(() => {
  // authWxAgentData();
});
const followTodoDetail = () => {
  state.loading = true;
  $http
    .followTodoDetail({
      source_id: form.list.todo_id,
      type: 1,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        if (res.data.status == 1) {
          state.showType = 'look';
        }
        getConfirmMeetingInfo();
      } else {
        Toast(res.data);
      }
    });
};
//-获取确认见面详情
const getConfirmMeetingInfo = () => {
  state.loading = true;
  $http
    .getConfirmMeetingInfo({
      id: form.list.todo_id,
      type: 1,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.deadline_time = res.data.deadline_time;
        state.is_show_receipt = res.data.is_show_receipt;
        state.receipt_cause_type_list = res.data.receipt_cause_type_list;
        if (state.is_show_receipt == 1) {
          state.approve_type_list = [
            { id: 2, name: '接单' },
            { id: 3, name: '回单' },
          ];
          form.list.approve_type = res.data.approve_type;
        } else {
          state.approve_type_list = [{ id: 2, name: '接单' }];
          form.list.approve_type = state.approve_type_list[0].id;
        }
        form.list.meet_type = res.data.meet_type || '';
        form.list.now_follow_time = res.data.now_follow_time;
        form.list.next_follow_time = res.data.next_follow_time;
        form.list.is_face_site = res.data.is_face_site || '';
        form.list.is_measure_house = res.data.is_measure_house || '';
        form.list.is_design_plan = res.data.is_design_plan || '';
        form.list.measure_house_time = res.data.measure_house_time;
        form.list.follow_remark = res.data.follow_remark;
        form.list.next_follow_remark = res.data.next_follow_remark;
        form.list.receipt_cause = res.data.receipt_cause_type
          ? res.data.receipt_cause
          : '';
        form.list.receipt_cause_type = res.data?.receipt_cause_type || '';
        form.list.remark = res.data.remark;

        if (state.showType == 'look') {
          state.meetInfo = res.data;
        }
      }
    });
};

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

//-回单原因
const receiptPicker = reactive({ show: false });
const openReceiptType = () => {
  receiptPicker.show = true;
};
const changeReceiptPicker = (value) => {
  form.list.receipt_cause_type = value.id;
  form.list.receipt_cause = value.name;
  receiptPicker.show = false;
};
const clearReceiptPicker = () => {
  form.list.receipt_cause_type = '';
  form.list.receipt_cause = '';
  receiptPicker.show = false;
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};
const radioClick = (e) => {
  console.log(e);
  // form.info.review = 1;
};
//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      console.log('11111111111111111111111111111');
      if (form.list.approve_type == 3) {
        //回单二次确认弹出
        action.action_show = true;
      } else {
        goConfirm();
      }
    })
    .catch(() => {
      //验证失败
      Toast('请完善信息');
    });
};
//保存草稿
const btnSumbitDraft = () => {
  state.loadingConfirm = true;
  $http
    .dispatch_saveDraft({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            if (state.schedule == 1) {
              window.close();
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
const actionConfirm = () => {
  goConfirm();
};
const goConfirm = () => {
  // V1.4.8 紧急需求（暂时注释弹窗）：选择回单，不给客户发送短信，不显示弹窗
  // let str =
  //   '是否确认？请将此跟进结果分享到客户群，系统将同时发送短信，通知客户查看';
  // Dialog.confirm({
  //   message: str,
  // }).then(() => {
  //   state.loadingConfirm = true;
  //   $http
  //     .confirmMeetingForCustomerManager({
  //       ...form.list,
  //     })
  //     .then((res) => {
  //       const { code, data } = res;
  //       state.loadingConfirm = false;
  //       if (code === 0) {
  //         action.action_show = false;

  //         Notify({
  //           type: 'success',
  //           message: '操作成功',
  //           onClose: () => {
  //             shareRecordList(data);

  //             // if (state.schedule == 1) {
  //             //   window.close();
  //             // } else {
  //             //   router.back();
  //             // }
  //           },
  //         });
  //       } else {
  //         Notify({ type: 'warning', message: data });
  //       }
  //     });
  // });
  $http
    .confirmMeetingForCustomerManager({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        action.action_show = false;

        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            shareRecordList(data);

            // if (state.schedule == 1) {
            //   window.close();
            // } else {
            //   router.back();
            // }
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
const shareRecordList = (item) => {
  router.replace({
    path: '/followInfoShareImg',
    query: {
      id: item.record_id,
    },
  });
};
//-@ 返回
const onBack = () => {
  if (state.showType == 'look') {
    router.back();
  } else {
    let str = '是否将填写内容保存为草稿，否则会被清空';
    Dialog.confirm({
      message: str,
    })
      .then(() => {
        //保存草稿
        btnSumbitDraft();
      })
      .catch(() => {
        router.back();
      });
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/comonFormGrey.less';
.page-wrap {
  height: calc(100vh - 46px);
  background-color: #f5f5f5;
  .formBase {
    padding-bottom: 72px;
  }
}
.list_field {
  :deep(.van-radio__label) {
    width: 32px;
    text-align: left;
  }
}
.actionbox {
  .action-content {
    padding: 60px 25px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      padding: 40px 0 20px;
    }

    p.des {
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 80px;
      color: #767676;
    }

    & > a {
      color: #1a1a1a;
      font-size: 16px;
      line-height: 50px;
      padding: 0 40px;
      margin-top: 15px;
    }
  }
}
.tip_box {
  display: flex;
  background: #ffedd6;
  color: #f37020;
  font-size: 15px;
  padding: 15px 20px;
}
.btnSumbit {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 74px;
  background: #fff;
  box-shadow: 0px -1px 4vw rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  .btn {
    width: 48%;
    position: relative;
  }
}
</style>
