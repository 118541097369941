<!--
 * @Author: Jessica
 * @Date: 2022-08-23 09:52:32
 * @LastEditTime: 2024-03-23 17:42:41
 * @Description: 合同变更模版
-->
<template>
  <div class="page-wrap">
    <!-- 加载状态 -->
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <NavBar
      safe-area-inset-top
      title="变更合同信息"
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
    />
    <!-- 不同模板 -->
    <div class="temps" v-if="state.cacheDetailBase.id">
      <!-- 合同变更信息 -->
      <ChangeTemp :data="state.cacheDetailChange" ref="CacheRef5" />
      <!-- 基本信息 -->
      <BaseTemp
        :contractType="state.contractType"
        :data="state.cacheDetailBase"
        :parameterList="state.parameterList"
        ref="CacheRef1"
      />
      <!-- 房屋信息 -->
      <ZSHouseTemp
        v-if="state.contractType === 51 || state.contractType === 52"
        :contractType="state.contractType"
        :data="state.cacheDetailHouse"
        :customer_id="state.customer_id"
        :parameterList="state.parameterList"
        :parameterListForCustomer="state.parameterListForCustomer"
        ref="CacheRef2"
      />
      <JJDGHouseTemp
        v-if="state.contractType === 53"
        :data="state.cacheDetailHouse"
        :customer_id="state.customer_id"
        :parameterList="state.parameterList"
        :parameterListForCustomer="state.parameterListForCustomer"
        ref="CacheRef2"
      />
      <JJZSHouseTemp
        v-if="state.contractType === 54"
        :contractType="state.contractType"
        :data="state.cacheDetailHouse"
        :customer_id="state.customer_id"
        :parameterList="state.parameterList"
        :parameterListForQuoteOrder="state.parameterListForQuoteOrder"
        ref="CacheRef2"
      />
      <ZZXSHouseTemp
        v-if="state.contractType === 55"
        :data="state.cacheDetailHouse"
        :customer_id="state.customer_id"
        :parameterList="state.parameterList"
        :parameterListForCustomer="state.parameterListForCustomer"
        ref="CacheRef2"
      />
      <ZZSGHouseTemp
        v-if="state.contractType === 56"
        :contractType="state.contractType"
        :data="state.cacheDetailHouse"
        :customer_id="state.customer_id"
        :parameterList="state.parameterList"
        :parameterListForCustomer="state.parameterListForCustomer"
        :changeType="props.changeType"
        :parameterListForQuoteOrder="state.parameterListForQuoteOrder"
        ref="CacheRef2"
      />
      <BCXYHouseTemp
        v-if="state.contractType === 69"
        :data="state.cacheDetailHouse"
        :customer_id="state.customer_id"
        :parameterList="state.parameterList"
        :parameterListForCustomer="state.parameterListForCustomer"
        ref="CacheRef2"
      />
      <!-- 合同收款 -->
      <ZSCollectTemp
        v-if="state.contractType === 51 || state.contractType === 52"
        :contractType="state.contractType"
        :data="state.cacheDetailCollect"
        :customer_id="state.customer_id"
        :contractTypeName="state.change_contract_name"
        :parameterList="state.parameterList"
        :changeType="props.changeType"
        ref="CacheRef3"
      />
      <JJDGCollectTemp
        v-if="state.contractType === 53"
        :contractType="state.contractType"
        :data="state.cacheDetailCollect"
        :customer_id="state.customer_id"
        :contractTypeName="state.change_contract_name"
        :parameterList="state.parameterList"
        :changeType="props.changeType"
        ref="CacheRef3"
      />
      <JJZSCollectTemp
        v-if="state.contractType === 54"
        :contractType="state.contractType"
        :data="state.cacheDetailCollect"
        :customer_id="state.customer_id"
        :contractTypeName="state.change_contract_name"
        :parameterList="state.parameterList"
        :changeType="props.changeType"
        :parameterListForQuoteOrder="state.parameterListForQuoteOrder"
        ref="CacheRef3"
      />
      <ZZXSCollectTemp
        v-if="state.contractType === 55"
        :contractType="state.contractType"
        :data="state.cacheDetailCollect"
        :customer_id="state.customer_id"
        :contractTypeName="state.change_contract_name"
        :parameterList="state.parameterList"
        :priceList="CacheRef2?.form"
        ref="CacheRef3"
      />
      <ZZSGCollectTemp
        v-if="state.contractType === 56"
        :contractType="state.contractType"
        :data="state.cacheDetailCollect"
        :customer_id="state.customer_id"
        :contractTypeName="state.change_contract_name"
        :parameterList="state.parameterList"
        :changeType="props.changeType"
        ref="CacheRef3"
      />
      <BCXYCollectTemp
        v-if="state.contractType === 69"
        :contractType="state.contractType"
        :data="state.cacheDetailCollect"
        :customer_id="state.customer_id"
        :contractTypeName="state.change_contract_name"
        :parameterList="state.parameterList"
        :changeType="props.changeType"
        ref="CacheRef3"
      />
      <!-- 项目成员 -->
      <MemberTemp
        :data="state.cacheDetailMember"
        :basicInfo="state.cacheBasic"
        :contractType="state.contractType"
        :parameterListForCustomer="state.parameterListForCustomer"
        ref="CacheRef4"
      />
      <div class="btnSumbit">
        <Button class="btn" type="primary" size="large" @click="btnSumbit">
          提交
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  inject,
  onMounted,
  defineProps,
  ref,
  defineEmits,
} from 'vue';
import { useRouter } from 'vue-router';
//-变更信息
import ChangeTemp from '@/components/contractChange/ChangeTemp';
//-基本信息
import BaseTemp from '@/components/contractChange/BaseTemp';
//-配饰、装饰
import ZSHouseTemp from '@/components/contractChange/ZSHouseTemp';
import ZSCollectTemp from '@/components/contractChange/ZSCollectTemp';
//- 家居产品代购
import JJDGHouseTemp from '@/components/contractChange/JJDGHouseTemp';
import JJDGCollectTemp from '@/components/contractChange/JJDGCollectTemp';
//- 家居装饰施工
import JJZSHouseTemp from '@/components/contractChange/JJZSHouseTemp';
import JJZSCollectTemp from '@/components/contractChange/JJZSCollectTemp';
//- 优选整装销售
import ZZXSHouseTemp from '@/components/contractChange/ZZXSHouseTemp';
import ZZXSCollectTemp from '@/components/contractChange/ZZXSCollectTemp';
//- 优选整装施工
import ZZSGHouseTemp from '@/components/contractChange/ZZSGHouseTemp';
import ZZSGCollectTemp from '@/components/contractChange/ZZSGCollectTemp';
//-产品补充协议
import BCXYHouseTemp from '@/components/contractChange/BCXYHouseTemp';
import BCXYCollectTemp from '@/components/contractChange/BCXYCollectTemp';
import MemberTemp from '@/components/contractChange/MemberTemp';
import { Notify, Toast, Loading, Overlay, NavBar, Button } from 'vant';
const $http = inject('$http');
const router = useRouter();
const props = defineProps([
  'data',
  'customer_id',
  'change_detail',
  'changeType',
]);
const emits = defineEmits(['onClosePopFun']);
const state = reactive({
  loadingConfirm: false,
  //-客户id
  customer_id: props.customer_id,
  //-变更合同Id
  change_contract_id: props.data.change_contract_id,
  change_contract_name: props.data.change_contract_name,
  //-合同类型
  contractType: props.data.change_contract_type,
  //-参数
  parameterList: [],
  //-客户参数
  parameterListForCustomer: [],
  // 合同详情
  cacheDetailChange: props.change_detail ? props.change_detail : {},
  cacheDetailBase: {},
  cacheDetailHouse: {},
  cacheDetailCollect: {},
  cacheDetailMember: {},
  cacheBasic: {},
  //-选报/必报参数
  parameterListForQuoteOrder: [],
});

onMounted(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  state.loadingConfirm = true;
  await $http
    .getContractPublicParameter({ customer_id: props.customer_id })
    .then((res) => {
      if (res.code === 0) {
        state.parameterList = res.data;
        if (props.customer_id) {
          getCustomerParameter();
          getQuoteOrderForContract();
        }
        if (props.data.change_contract_id) {
          getContractInfo();
        }
      } else {
        Toast(res.data);
      }
    });
};
//-获取客户参数
const getCustomerParameter = async () => {
  await $http
    .getContractCustomerParameter({
      customer_id: props.customer_id,
    })
    .then((res) => {
      state.loadingConfirm = false;
      if (res.code === 0) {
        state.parameterListForCustomer = res.data;
      } else {
        Toast(res.data);
      }
    });
};
//-获取选报/必报参数
const getQuoteOrderForContract = async () => {
  await $http
    .getQuoteOrderForContract({ customer_id: state.customer_id })
    .then((res) => {
      if (res.code == 0) {
        state.parameterListForQuoteOrder = res.data;
      } else {
        Toast(res.data);
      }
    });
};
//-获取合同详情
const getContractInfo = async () => {
  await $http
    .getContractInfoApi({ contract_id: props.data.change_contract_id })
    .then((res) => {
      state.loadingConfirm = false;
      if (res.code === 0) {
        state.cacheDetailBase = res.data.basic_into;
        state.cacheDetailHouse = res.data.basic_into;
        state.cacheDetailCollect = res.data.basic_into;
        state.cacheDetailMember = res.data.project_members;
        state.cacheBasic = res.data.basic_into;
      } else {
        Toast(res.data);
      }
    });
};
const CacheRef1 = ref(null);
const CacheRef2 = ref(null);
const CacheRef3 = ref(null);
const CacheRef4 = ref(null);
const CacheRef5 = ref(null);
//-保存操作
const btnSumbit = () => {
  if (
    !CacheRef1.value?.ruleFormRef ||
    !CacheRef2.value?.ruleFormRef ||
    !CacheRef3.value?.ruleFormRef ||
    !CacheRef5.value?.ruleFormRef
  ) {
    return;
  } else {
    CacheRef5.value?.ruleFormRef
      .validate()
      .then(() => {
        CacheRef1.value?.ruleFormRef
          .validate()
          .then(() => {
            CacheRef2.value?.ruleFormRef
              .validate()
              .then(() => {
                CacheRef3.value?.ruleFormRef
                  .validate()
                  .then(() => {
                    if (
                      state.contractType === 51 ||
                      state.contractType === 52
                    ) {
                      if (
                        parseFloat(CacheRef3.value?.form.contract_amount ?? 0) >
                        parseFloat(CacheRef3.value?.form.design_amount ?? 0) *
                          parseFloat(CacheRef2.value?.form.house_size ?? 0)
                      ) {
                        Notify({
                          type: 'warning',
                          message: '合同金额不能超过房屋面积 x 设计费金额',
                        });
                      } else {
                        //-通过验证-提交操作
                        getOperateFun(
                          CacheRef4.value?.form.list.map((v) => {
                            return v.id;
                          }),
                          CacheRef4.value?.form.is_assign
                        );
                      }
                    } else {
                      //-通过验证-提交操作
                      getOperateFun(
                        CacheRef4.value?.form.list.map((v) => {
                          return v.id;
                        }),
                        CacheRef4.value?.form.is_assign
                      );
                    }
                  })
                  .catch(() => {
                    //验证失败
                    Notify({ type: 'warning', message: '请先完善信息' });
                  });
              })
              .catch(() => {
                //验证失败
                Notify({ type: 'warning', message: '请先完善信息' });
              });
          })
          .catch(() => {
            //验证失败
            Notify({ type: 'warning', message: '请先完善信息' });
          });
      })
      .catch(() => {
        //验证失败
        Notify({ type: 'warning', message: '请先完善信息' });
      });
  }
};

const getOperateFun = (member, is_assign) => {
  state.loadingConfirm = true;
  let $url = '';
  if (state.contractType == 51 || state.contractType == 52) {
    $url = $http.getSignDecorateContract;
  } else if (state.contractType == 53) {
    $url = $http.getSignEntrustBuyContract;
  } else if (state.contractType == 54) {
    $url = $http.getSignRoomDecorationContract;
  } else if (state.contractType == 55) {
    $url = $http.getSignServiceSellContract;
  } else if (state.contractType == 56) {
    $url = $http.getSignAssemblyWorks;
  } else if (state.contractType == 69) {
    $url = $http.getSignDecorationMatching;
  }
  $url({
    source_type: 2, //1 普通创建 2变更合同创建
    customer_id: state.customer_id,
    ...CacheRef1.value.form,
    ...CacheRef2.value.form,
    ...CacheRef3.value.form,
    project_members: member,
    change_contract_id: props.data.change_contract_id,
    change_type: props.data.change_type,
    change_remark: props.data.change_remark,
    change_contract_type_id: CacheRef5.value.form.change_contract_type_id,
    change_specific_reasons: CacheRef5.value.form.change_specific_reasons,
    is_assign: is_assign,
  }).then((res) => {
    const { code, data } = res;
    state.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-返回操作
const onClosePop = () => {
  emits('onClosePopFun');
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractIndex.less';
</style>
