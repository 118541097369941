<!--
 * @Author: Jessica
 * @Date: 2023-06-17 16:36:06
 * @LastEditTime: 2024-05-31 16:16:17
 * @Description: 目标数据
-->
<template>
  <div class="change_box">
    <div>
      <div class="type_box" v-if="_DATA.show_simple_page == 1">
        <div
          class="type_item"
          :class="_DATA.type == 1 ? 'check' : ''"
          @click="changeType(1)"
        >
          简单
        </div>
        <div
          class="type_item"
          :class="_DATA.type == 2 ? 'check' : ''"
          @click="changeType(2)"
        >
          详细
        </div>
      </div>
    </div>

    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker_year = true"
    >
      {{ _DATA.yearName }}
    </Button>
  </div>
  <div class="page-box" ref="mainScroll">
    <div class="simple" v-show="_DATA.type == 1 && _DATA.show_simple_page == 1">
      <!-- 获客数据 -->
      <thredSimple
        :data="{ yearId: _DATA.yearId, now_year: _DATA.now_year }"
        :loading="loading"
      />
      <!-- 转化数据 -->
      <designSimple
        :data="{ yearId: _DATA.yearId, now_year: _DATA.now_year }"
        :loading="loading"
        ref="desginRef"
        @createDanger="createDanger"
      />
      <!-- 交付数据 -->
      <siteSimple
        :data="{ yearId: _DATA.yearId, now_year: _DATA.now_year }"
        :loading="loading"
        ref="siteRef"
        @createDanger="createDanger"
      />
    </div>
    <div v-show="_DATA.type != 1 || _DATA.show_simple_page != 1">
      <!-- 线索数 -->
      <thredTemp :data="{ yearId: _DATA.yearId }" :loading="loading" />
      <!-- 见面数 -->
      <meetingTemp :data="{ yearId: _DATA.yearId }" :loading="loading" />
      <!-- 协议数 -->
      <protocolTemp
        :data="{ yearId: _DATA.yearId }"
        :loading="loading"
        ref="protocolRef"
        @createDanger="createDanger"
      />
      <!-- 转化产值 -->
      <transformPerformanceTemp
        :data="{ yearId: _DATA.yearId }"
        :loading="loading"
        ref="transformPerformanceRef"
        @createDanger="createDanger"
      />
      <!-- 转化数 -->
      <transformTemp
        :data="{ yearId: _DATA.yearId }"
        :loading="loading"
        ref="transformRef"
        @createDanger="createDanger"
      />
      <!-- 交付产值 -->
      <deliverPerformanceTemp
        :data="{ yearId: _DATA.yearId, now_year: _DATA.now_year }"
        :loading="loading"
        ref="deliverPerformanceTempRef"
        @createDanger="createDanger"
      />
      <!-- 交付数 -->
      <deliverTemp
        :data="{ yearId: _DATA.yearId, now_year: _DATA.now_year }"
        :loading="loading"
        ref="deliverTempRef"
        @createDanger="createDanger"
      />
    </div>
  </div>
  <!-- 年份 -->
  <Popup v-model:show="_DATA.showPicker_year" position="bottom">
    <Picker
      :columns="_DATA.yearList"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirm(v, 'year')"
      @cancel="_DATA.showPicker_year = false"
      :default-index="
        _DATA.yearList.findIndex((v) => {
          return v.id == _DATA.yearId;
        })
      "
    />
  </Popup>
  <!-- end -->

  <!-- 规则说明组件 -->
  <ActionSheet
    class="dangerActionSheet_rule"
    v-model:show="_DATA.dangerOperate"
    @open="onOpenDanger"
  >
    <div class="content">
      <div class="content_item">
        <h4>{{ _DATA.dangerTitle }}</h4>
        <div v-if="_DATA.dangerTitle == '交付产值_规则'" class="info_rule">
          <!-- 数据定义 -->
          <div>
            <h5>数据定义：</h5>
            <p>
              · 当年：{{ _DATA.dangerHtml?.yearInfo }}开工在{{
                _DATA.dangerHtml?.yearInfo
              }}产生的累计交付业绩；
            </p>
            <p>
              · 存量：{{ _DATA.dangerHtml?.yearInfo }}前年开工在{{
                _DATA.dangerHtml?.yearInfo
              }}产生的累计交付业绩；
            </p>
            <p v-if="_DATA.yearId == _DATA.now_year">
              · 目标：{{
                _DATA.dangerHtml?.yearInfo
              }}各交付中心设置至当前月的累计目标产值；
            </p>
          </div>
          <!-- 目标定义 -->
          <div class="mb_20" v-if="_DATA.yearId == _DATA.now_year">
            <h5 class="flex_between">
              {{ _DATA.dangerHtml?.yearInfo }}目标（万）：
            </h5>
            <Row class="top_title">
              <Col
                class="align_center color_999"
                span="4"
                v-for="(item, index) in _DATA.dangerHtml?.title"
                :key="index"
              >
                {{ item.name }}
              </Col>
            </Row>
            <div class="top_content">
              <Row
                v-for="(item, index) in _DATA.dangerHtml?.content"
                :key="index"
              >
                <Col span="4" class="align_center color_999">
                  {{ item.title }}
                </Col>
                <Col span="4" class="align_center">{{ item.value1 }}</Col>
                <Col span="4" class="align_center">{{ item.value2 }}</Col>
                <Col span="4" class="align_center">{{ item.value3 }}</Col>
                <Col span="4" class="align_center">{{ item.value4 }}</Col>
                <Col span="4" class="align_center">{{ item.value5 }}</Col>
              </Row>
            </div>
          </div>
        </div>
        <div v-else class="info_rule" v-html="_DATA.dangerHtml"></div>
      </div>
      <div class="content_button_blue">
        <Button
          block
          loading-type="spinner"
          @click="closeDanger"
          loading-text="知道了"
        >
          知道了
        </Button>
      </div>
    </div>
  </ActionSheet>
</template>

<script setup>
import { reactive, onMounted, inject, ref, defineExpose } from 'vue';

import protocolTemp from '@/components/targetData/totalData/protocolTemp'; // 协议数
import transformTemp from '@/components/targetData/totalData/transformTemp'; // 转化数
import deliverTemp from '@/components/targetData/totalData/deliverTemp'; // 交付数
import thredTemp from '@/components/targetData/totalData/thredTemp'; // 线索数
import meetingTemp from '@/components/targetData/totalData/meetingTemp'; // 见面数
import transformPerformanceTemp from '@/components/targetData/totalData/transformPerformanceTemp'; // 转化产值
import deliverPerformanceTemp from '@/components/targetData/totalData/deliverPerformanceTemp'; // 交付产值

//简单数据
import thredSimple from '@/components/targetData/totalData/thredSimple';
import designSimple from '@/components/targetData/totalData/designSimple';
import siteSimple from '@/components/targetData/totalData/siteSimple';

import { Picker, Button, Popup, Notify, ActionSheet, Row, Col } from 'vant';
const loading = ref(false);
const $http = inject('$http');
const customFieldName = {
  text: 'short_name',
  value: 'id',
};
const _DATA = reactive({
  showPicker_year: false,
  now_year: null,
  show_simple_page: null,
  yearId: null,
  yearName: null,
  yearList: [],
  type: 1,
  //-规则说明
  dangerTitle: '',
  dangerHtml: '',
  dangerOperate: false,
});
const mainScroll = ref(null);
onMounted(() => {
  getParams();
});
//-@ 加载
const getParams = () => {
  loading.value = true;
  $http.targetStatistic_getParams().then((res) => {
    loading.value = false;
    const { code, data } = res;
    if (code === 0) {
      _DATA.now_year = data.now_year;
      _DATA.show_simple_page = data.show_simple_page;
      let years = data.years;
      let yearList = [];
      years.forEach((v) => {
        yearList.push({ id: v, short_name: v });
      });
      _DATA.yearList = yearList;
      _DATA.yearId = Number(localStorage.getItem('targetYear'));
      _DATA.yearName = Number(localStorage.getItem('targetYear'));
      if (!_DATA.yearId || !years.includes(_DATA.yearId)) {
        let yearArr = years.slice(-1);
        _DATA.yearId = yearArr[0];
        _DATA.yearName = yearArr[0];
      }
      console.log(_DATA.yearId);
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

const onConfirm = (value, type) => {
  if (type == 'year') {
    //切换部门
    _DATA.showPicker_year = false;
    if (_DATA.yearId != value.id) {
      localStorage.setItem('targetYear', value.id);
      _DATA.yearId = value.id;
      _DATA.yearName = value.short_name;
      mainScroll.value.scrollTo({ top: 0 });
    }
  }
};
const changeType = (type) => {
  if (type != _DATA.type) {
    _DATA.type = type;
    mainScroll.value.scrollTo({ top: 0 });
  }
};

//-规则说明
const desginRef = ref();
const protocolRef = ref();
const transformPerformanceRef = ref();
const transformRef = ref();
const siteRef = ref();
const deliverPerformanceTempRef = ref();
const deliverTempRef = ref();
// 使用nextTick确保DOM已更新 并且回到顶部
const onOpenDanger = () => {
  setTimeout(() => {
    const actionSheet = document.querySelector('.van-action-sheet__content');
    if (actionSheet) {
      actionSheet.scrollTop = 0;
    }
  }, 0);
};
const createDanger = (type) => {
  _DATA.dangerOperate = true;
  if (type == 'desginRef') {
    //简单-转化
    _DATA.dangerTitle = desginRef?.value.htmlTitle;
    _DATA.dangerHtml = desginRef?.value.htmlContent;
  } else if (type == 'siteRef') {
    //简单-交付
    _DATA.dangerTitle = siteRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = siteRef?.value?._rule.htmlContent;
  } else if (type == 'protocolRef') {
    //详细-协议
    _DATA.dangerTitle = protocolRef?.value.htmlTitle;
    _DATA.dangerHtml = protocolRef?.value.htmlContent;
  } else if (type == 'transformPerformanceRef') {
    //详细-转化产值
    _DATA.dangerTitle = transformPerformanceRef?.value.htmlTitle;
    _DATA.dangerHtml = transformPerformanceRef?.value.htmlContent;
  } else if (type == 'transformRef') {
    //详细-转化数
    _DATA.dangerTitle = transformRef?.value.htmlTitle;
    _DATA.dangerHtml = transformRef?.value.htmlContent;
  } else if (type == 'deliverPerformanceTempRef') {
    //详细-交付产值
    _DATA.dangerTitle = deliverPerformanceTempRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = deliverPerformanceTempRef?.value?._rule.htmlContent;
  } else if (type == 'deliverTempRef') {
    //详细-交付数
    _DATA.dangerTitle = deliverTempRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = deliverTempRef?.value?._rule.htmlContent;
  }
};
const closeDanger = () => {
  _DATA.dangerOperate = false;
};
defineExpose({ createDanger });
//-规则说明 end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
.change_box {
  padding: 10px 15px;
  background: #f5f5f5;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .year_btn {
    border: none;
    border-radius: 2px;
    font-size: 13px;
    padding: 0 10px;
    margin-left: 10px;
    height: 30px;
  }
}
.page-box {
  padding: 0 15px 80px 15px;
  background: #f5f5f5;
  height: calc(100% - 48px);
  overflow: auto;
}
.type_box {
  padding: 2px;
  background: rgba(118, 118, 128, 0.08);
  border-radius: 3px;
  display: flex;
  align-items: center;
  .type_item {
    border-radius: 2px;
    line-height: 26px;
    width: 68px;
    text-align: center;
    font-size: 13px;
    color: #8d9091;
    &.check {
      background: #ffffff;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08),
        0px 1px 3px 0px rgba(0, 0, 0, 0.04);
      color: #000000;
      font-weight: 600;
    }
  }
}
</style>
