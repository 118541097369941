<!--
 * @Author: gest
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-31 16:13:41
 * @Description:V1.4.0-部门数据-交付
-->
<template>
  <div class="change_box">
    <span class="name">{{ _DATA.depName || '-' }}</span>
    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker = true"
      v-if="_DATA.depList?.length > 1"
    >
      切换部门
    </Button>
    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker_year = true"
    >
      {{ _DATA.yearName }}
    </Button>
  </div>
  <div class="page-box">
    <IndexBar ref="indexBarRef" :index-list="_DATA.indexList" :sticky="false">
      <!-- 交付产值 -->
      <IndexAnchor index="1"></IndexAnchor>
      <deliveryPerformance
        :data="{
          depName: _DATA.depName,
          depId: _DATA.depId,
          pos: _DATA.pos,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
        @loadEnd="loadEnd(1)"
        ref="deliveryPerformanceRef"
        @createDanger="createDanger"
      ></deliveryPerformance>
      <!-- 交付数 -->
      <IndexAnchor index="2"></IndexAnchor>
      <delivery
        :data="{
          depId: _DATA.depId,
          pos: _DATA.pos,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
        @loadEnd="loadEnd(2)"
        ref="deliveryRef"
        @createDanger="createDanger"
      ></delivery>
      <!-- 交付周期 -->
      <IndexAnchor index="3"></IndexAnchor>
      <period
        :data="{
          depId: _DATA.depId,
          pos: _DATA.pos,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
        @loadEnd="loadEnd(3)"
      ></period>
      <!-- 交付阶段数据 -->
      <IndexAnchor index="4"></IndexAnchor>
      <underStateSite
        :data="{
          depId: _DATA.depId,
          pos: _DATA.pos,
          tabActive: _DATA.tabActive,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
        @loadEnd="loadEnd(4)"
      ></underStateSite>
      <!-- 交付动作数据 -->
      <IndexAnchor
        index="5"
        v-if="_DATA.now_year == _DATA.yearId"
      ></IndexAnchor>
      <actionSite
        :data="{
          depId: _DATA.depId,
          pos: _DATA.pos,
          tabActive: _DATA.tabSiteActive,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
        @loadEnd="loadEnd(5)"
        v-if="_DATA.now_year == _DATA.yearId"
      ></actionSite>
    </IndexBar>

    <!--picker 部门-->
    <Popup v-model:show="_DATA.showPicker" position="bottom">
      <Picker
        :columns="_DATA.depList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v)"
        @cancel="_DATA.showPicker = false"
        :default-index="
          _DATA.depList.findIndex((v) => {
            return v.id == _DATA.depId;
          })
        "
      />
    </Popup>
    <!-- end -->
    <!-- 年份 -->
    <Popup v-model:show="_DATA.showPicker_year" position="bottom">
      <Picker
        :columns="_DATA.yearList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'year')"
        @cancel="_DATA.showPicker_year = false"
        :default-index="
          _DATA.yearList.findIndex((v) => {
            return v.id == _DATA.yearId;
          })
        "
      />
    </Popup>
    <!-- end -->
  </div>
  <!--V1.4.0 菜单 悬浮按钮 -->
  <ol class="fixed-ol-box">
    <li class="icon-box" @click="_DATA.showMenu = true">
      <img
        class="icon-fixed"
        :src="require('@/assets/images/icon/icon_menu.png')"
      />
    </li>
  </ol>
  <sideMenu
    :data="{ showMenu: _DATA.showMenu }"
    @closed="
      () => {
        _DATA.showMenu = false;
      }
    "
  ></sideMenu>
  <!-- end -->
  <!-- 规则说明组件 -->
  <ActionSheet
    class="dangerActionSheet_rule"
    v-model:show="_DATA.dangerOperate"
    @open="onOpenDanger"
  >
    <div class="content">
      <div class="content_item">
        <h4>{{ _DATA.dangerTitle }}</h4>
        <div v-if="_DATA.dangerTitle == '交付产值_规则'" class="info_rule">
          <!-- 数据定义 -->
          <div>
            <h5>数据定义：</h5>
            <p>
              · 目标：{{
                _DATA.dangerHtml?.yearInfo
              }}交付中心设置至当前月的累计目标产值；
            </p>
          </div>
          <!-- 三级页只展示该交付中心产值 -->
          <div class="mb_10">
            <h5 class="flex_between">
              {{ _DATA.dangerHtml?.yearInfo }}目标（万）：
            </h5>
            <Row class="top_title">
              <Col
                class="align_center color_999"
                span="12"
                v-for="(item, index) in _DATA.dangerHtml?.title"
                :key="index"
              >
                {{ item.name }}
              </Col>
            </Row>
            <div class="top_content">
              <Row
                v-for="(item, index) in _DATA.dangerHtml?.content"
                :key="index"
              >
                <Col span="12" class="align_center color_999">{{
                  item.title
                }}</Col>
                <Col span="12" class="align_center">{{ item.value }}</Col>
              </Row>
            </div>
          </div>
        </div>
        <div v-else class="info_rule" v-html="_DATA.dangerHtml"></div>
      </div>
      <div class="content_button_blue">
        <Button
          block
          loading-type="spinner"
          @click="closeDanger"
          loading-text="知道了"
        >
          知道了
        </Button>
      </div>
    </div>
  </ActionSheet>
</template>
<script setup>
import _ from 'lodash';
import { ref, reactive, inject, onMounted, nextTick, defineExpose } from 'vue';
import {
  IndexBar,
  IndexAnchor,
  Picker,
  Button,
  Popup,
  ActionSheet,
  Row,
  Col,
} from 'vant';
import { useRouter } from 'vue-router';
// 交付产值
import deliveryPerformance from '@/components/targetData/siteData/deliveryPerformance';
// 交付数
import delivery from '@/components/targetData/siteData/delivery';
// 交付周期
import period from '@/components/targetData/siteData/period';
// 交付阶段数据
import underStateSite from '@/components/targetData/siteData/underStateSite';
// 交付动作数据
import actionSite from '@/components/targetData/siteData/actionSite';
import sideMenu from '@/components/sideMenu';
const $http = inject('$http');
const router = useRouter();
const customFieldName = {
  text: 'short_name',
  value: 'id',
};

const _DATA = reactive({
  depId: Number(router.currentRoute.value.query.depId) || '0',
  depName: router.currentRoute.value.query.depName || '-',
  pos: Number(router.currentRoute.value.query.pos), // 与全部数据卡片位置顺序一样
  //-交付阶段
  tabActive: Number(router.currentRoute.value.query.tabActive),
  //-交付动作
  tabSiteActive: Number(router.currentRoute.value.query.tabSiteActive),
  indexList: [1, 2, 3, 4, 5],
  depList: [],
  showPicker: false,
  showPicker_year: false,
  yearId: null,
  yearName: null,
  yearList: [],
  now_year: null,
  // 规则说明
  dangerTitle: '',
  dangerHtml: '',
  dangerOperate: false,
});
const indexBarRef = ref(null);
onMounted(() => {
  onLoad();
});
let loadEndList = [];
const loadEnd = (index) => {
  let pos = Number(_DATA.pos);
  if (pos > index) {
    loadEndList.push(index);
    let needLoad = _DATA.indexList.filter((i) => i < pos);
    if (needLoad.sort().toString() == loadEndList.sort().toString()) {
      nextTick(() => {
        indexBarRef.value.scrollTo(pos);
      });
    }
  }
};
//-@ 加载

const onLoad = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.depList = data.construction_departments;
      // 年份
      let years = data.years;
      _DATA.now_year = data.now_year;
      let yearList = [];
      years.forEach((v) => {
        yearList.push({ id: v, short_name: v });
      });
      _DATA.yearList = yearList;
      _DATA.yearId = Number(localStorage.getItem('targetYear'));
      _DATA.yearName = Number(localStorage.getItem('targetYear'));
      if (!_DATA.yearId || !years.includes(_DATA.yearId)) {
        let yearArr = years.slice(-1);
        _DATA.yearId = yearArr[0];
        _DATA.yearName = yearArr[0];
      }
      console.log(_DATA.yearId);
      //end
    } else {
      _DATA.depList = [];
    }
  });
};
//
const onConfirm = (value, type) => {
  if (type == 'year') {
    //切换年份
    localStorage.setItem('targetYear', value.id);
    _DATA.yearId = value.id;
    _DATA.yearName = value.short_name;
    _DATA.showPicker_year = false;
  } else {
    //切换部门
    _DATA.depId = value.id;
    _DATA.depName = value.short_name;
    _DATA.showPicker = false;
    // 动态修改路由参数
    const query = _.cloneDeep(router.currentRoute.value.query);
    query.depId = _DATA.depId;
    query.depName = _DATA.depName;
    router.replace({ query });
    indexBarRef.value.scrollTo(1);
  }
};

//-规则说明
const deliveryPerformanceRef = ref();
const deliveryRef = ref();
// 使用nextTick确保DOM已更新 并且回到顶部
const onOpenDanger = () => {
  setTimeout(() => {
    const actionSheet = document.querySelector('.van-action-sheet__content');
    if (actionSheet) {
      actionSheet.scrollTop = 0;
    }
  }, 0);
};
const createDanger = (type) => {
  _DATA.dangerOperate = true;
  if (type == 'deliveryPerformanceRef') {
    _DATA.dangerTitle = deliveryPerformanceRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = deliveryPerformanceRef?.value?._rule.htmlContent;
  } else if (type == 'deliveryRef') {
    _DATA.dangerTitle = deliveryRef?.value?._rule.htmlTitle;
    _DATA.dangerHtml = deliveryRef?.value?._rule.htmlContent;
  }
};
const closeDanger = () => {
  _DATA.dangerOperate = false;
};
defineExpose({ createDanger });
//-规则说明 end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/targetData.less';
.change_box {
  padding: 10px 15px;
  background: #f5f5f5;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    font-size: 17px;
    font-weight: bold;
    flex: 1;
    margin-right: 20px;
  }
  .year_btn {
    border: none;
    border-radius: 2px;
    font-size: 13px;
    padding: 0 10px;
    margin-left: 10px;
    height: 30px;
  }
}
.page-box {
  padding: 0 0 40px 0;
  background: #f5f5f5;
  height: calc(100% - 48px);
  overflow: auto;
}
</style>
