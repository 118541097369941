<!--
 * @Author: gest
 * @Date: 2023-09-12 13:51:19
 * @LastEditTime: 2023-10-27 11:25:14
 * @Description: v1.4.5 进场材料检验记录单-详情
-->
<template>
  <div class="formBase">
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="white_bg">
        <div class="flex_between p_15">
          <div class="flex_column flex1 mr_15">
            <div>{{ item.name || '-' }}</div>
            <div class="grey_color font_size_13">
              {{ item.sub ? '规格：' + item.sub : '规格：-' }}
            </div>
          </div>
          <div
            :class="
              item.value ? (item.value == 1 ? 'blue_color' : 'red_color') : ''
            "
          >
            {{ item.value ? (item.value == 1 ? '有' : '无') : '-' }}
          </div>
        </div>
      </div>
      <div class="list_field white_bg" v-if="item.value == 1">
        <div class="field_item right border_top">
          <div class="flex_between">
            <div class="grey_color">进场时间</div>
            <div>{{ item.inTime || '-' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>
            {{ form.info.remark || '-' }}
          </div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
    <!-- popup -->
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);
const props = defineProps(['data']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
