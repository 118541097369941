<!--
 * @Author: gest
 * @Date: 2022-05-26 11:09:18
 * @LastEditTime: 2023-11-30 16:46:17
 * @Description: V1.3.8 新增自购产品(编辑)
-->
<template>
  <NavBar
    safe-area-inset-top
    :title="barTitle"
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-box middle-box">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- 表单 -->
    <Form class="form-box mt_10" ref="formRef">
      <div class="detail-box">
        <p class="label-name">
          <em>*</em>
          供应商
        </p>
        <Field
          v-model="form_info.supplier_name"
          maxlength="30"
          placeholder="请填写供应商"
          :rules="[
            {
              required: true,
              message: '请请填写供应商',
            },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          负责人姓名
        </p>
        <Field
          v-model="form_info.responsible_name"
          maxlength="6"
          placeholder="请填写负责人姓名"
          :rules="[
            {
              required: true,
              message: '请填写负责人姓名',
            },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          负责人手机号
        </p>
        <Field
          type="tel"
          v-model.trim="form_info.responsible_phone"
          maxlength="11"
          placeholder="请填写负责人手机号"
          :rules="[
            {
              required: true,
              message: '请填写负责人手机号',
            },
            {
              validator: validatorPhone,
              message: '请填写正确的手机号码',
              trigger: 'onChange',
            },
          ]"
        />
        <p class="label-name">
          <em>*</em>
          产品大类
        </p>
        <Field
          v-model="form_info.category_name"
          right-icon="arrow-down"
          readonly
          placeholder="选择产品大类"
          :rules="[
            {
              required: true,
              message: '请选择产品大类',
            },
          ]"
          :disabled="form_info.product_detail.length > 0 ? true : false"
          @click="
            () => {
              if (form_info.product_detail.length > 0) {
                return;
              }
              _DATA.show_category = true;
              _DATA.categoryInfo.check = form_info.category_id;
              _DATA.categoryInfo.list = _DATA.category_list;
            }
          "
        />
        <p class="label-name">订单标注</p>
        <div class="mark_select" @click="_DATA.markShow = true">
          <span>{{ form_info.mark_name || '-' }}</span>
          <Icon name="arrow" size="12" class="ml_5" />
        </div>
        <p class="label-name">下单备注</p>
        <Field
          v-model="form_info.remark"
          rows="3"
          autosize
          type="textarea"
          maxlength="30"
          placeholder="请填写"
          show-word-limit
        ></Field>
      </div>
      <div class="detail-box mt_10" style="padding: 0 20px">
        <p class="label-name">
          <em>*</em>
          材料明细
        </p>
        <Field
          v-model="form_info.hasProduct"
          :rules="[
            {
              required: true,
              message: '请添加产品',
            },
          ]"
          class="noBorder"
          v-if="_DATA.status == 3 || !form_info.id"
        >
          <template #input>
            <div class="btn_pro_box">
              <div class="btn_pro" @click="addPro">+ 添加产品</div>
            </div>
          </template>
        </Field>
      </div>
      <div class="add_pro_box">
        <div
          class="detail_box"
          v-for="(item, index) in form_info.product_detail"
          :key="index"
        >
          <div class="top_box">
            <div
              class="left"
              v-if="item.img.length > 0"
              @click="fileDetail(item.img[0], item.img)"
            >
              <Image class="avader_img" :src="host + item.img[0].path" />
            </div>
            <div class="left icon_name" v-else>自购</div>

            <div class="right">
              <div class="name_box">
                <p class="name" style="flex: 1">{{ item.name }}</p>
                <div class="clearfix" v-if="item.space_name">
                  <div class="status_tag blue" style="float: right">
                    {{ item.space_name }}
                  </div>
                </div>
              </div>
              <p class="info">
                <span class="t1 blue">
                  {{ item.category_sub_name ? item.category_sub_name : '' }}
                </span>

                <span class="t1" v-if="item.brand">
                  {{ item.brand }}
                </span>
                <span class="t1" v-if="item.model">
                  {{ item.model }}
                </span>
                <span class="t1" v-if="item.norms">
                  {{ item.norms }}
                </span>
                <span class="t1" v-if="item.material">
                  {{ item.material }}
                </span>
                <span class="t1" v-if="item.colour">
                  {{ item.colour }}
                </span>
              </p>
            </div>
          </div>
          <div class="dec_box">
            <div class="dec_item">
              <div class="left">数量</div>
              <div class="right">
                {{ item.amount || '-' }}{{ item.unit }}
                <!-- <span v-if="item.return_num_name">
                    /
                    <span class="red">
                      {{ item.return_num_name }}{{ item.unit }}
                    </span>
                  </span> -->
              </div>
            </div>
            <div class="dec_item">
              <div class="left">供货周期</div>
              <div class="right">
                {{ item.lead_period ? item.lead_period + '天' : '-' }}
              </div>
            </div>
            <div class="dec_item">
              <div class="left">备注</div>
              <div class="right">{{ item.remark || '-' }}</div>
            </div>
          </div>
          <div class="opt_box" v-if="_DATA.status == 3 || !form_info.id">
            <div class="opt_item" @click="copyPro(item, index)">
              <svg class="icon-small" aria-hidden="true">
                <use xlink:href="#icon-a-drag-drop-line1"></use>
              </svg>
              <span>复制</span>
            </div>
            <span style="margin: 0 20px">|</span>
            <div class="opt_item" @click="editPro(item, index)">
              <svg class="icon-small" aria-hidden="true">
                <use xlink:href="#icon-a-edit-line1"></use>
              </svg>
              <span>编辑</span>
            </div>
            <span style="margin: 0 20px">|</span>
            <div class="opt_item" @click="delPro(index)">
              <svg class="icon-small" aria-hidden="true">
                <use xlink:href="#icon-delete-bin-2-line"></use>
              </svg>
              <span>删除</span>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-box mt_10">
        <p class="label-name">备注</p>
        <Field
          v-model="form_info.detail_remark"
          rows="3"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="有什么需要补充吗"
          show-word-limit
        ></Field>
        <!-- 附件组件 -->
        <p class="label-name">附件</p>
        <FileUpload
          :data="form_info.files"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <!-- end -->
      </div>
      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">完成</Button>
      </div>
    </Form>
  </div>
  <!-- 选择 标注 -->
  <Popup v-model:show="_DATA.markShow" position="bottom">
    <div class="mark_pop">
      <div class="mark_title">
        <span style="flex: 1; text-align: center">选择标注</span>
        <Icon
          name="cross"
          :size="18"
          style="color: #8d9091"
          @click="
            () => {
              _DATA.markShow = false;
            }
          "
        />
      </div>
      <CheckboxGroup
        v-model="form_info.mark"
        class="mt_15"
        style="max-height: 500px; overflow: auto; height: 200px"
      >
        <Checkbox
          v-for="val in _DATA.allMarkList"
          :key="val.name"
          class="checkboxNoIcon"
          :name="val.id"
        >
          <template #icon></template>
          <p
            class="menuBox"
            :class="{
              checked: form_info.mark.indexOf(val.id) > -1,
            }"
          >
            {{ val.name }}
          </p>
        </Checkbox>
      </CheckboxGroup>
      <Button type="primary" size="large" @click="saveMark">保存</Button>
    </div>
  </Popup>
  <Popup
    v-model:show="_DATA.productShow"
    position="bottom"
    round
    :style="{ height: '90%' }"
  >
    <div class="pro_pop" v-if="_DATA.productShow">
      <div class="pro_title">
        <span
          style="flex: 1; text-align: center"
          class="font_size_16 font_bold"
        >
          {{ _DATA.productInfo.title }}
        </span>
        <Icon
          name="cross"
          :size="18"
          style="color: #8d9091"
          @click="
            () => {
              _DATA.productShow = false;
            }
          "
        />
      </div>
      <div>
        <Form class="form-box" ref="productformRef">
          <div class="detail-box" style="padding: 0 20px 94px 20px">
            <!-- 附件组件 -->
            <p class="label-name">产品图片</p>
            <FileUpload4
              :type="4"
              :data="_DATA.form_info_pro.img"
              @update:func="(list) => getFileList_pro(list)"
            ></FileUpload4>
            <!-- end -->

            <p class="label-name">
              <em>*</em>
              产品子类
            </p>
            <Field
              v-model="_DATA.form_info_pro.category_sub_name"
              right-icon="arrow-down"
              readonly
              placeholder="选择产品子类"
              :rules="[
                {
                  required: true,
                  message: '请选择产品子类',
                },
              ]"
              @click="_DATA.show_category_sub = true"
            />
            <p class="label-name">
              <em>*</em>
              产品名称
            </p>
            <Field
              v-model="_DATA.form_info_pro.name"
              maxlength="30"
              placeholder="请填写产品名称"
              :rules="[
                {
                  required: true,
                  message: '请请填写产品名称',
                },
              ]"
            />
            <div class="label-name" style="position: relative">
              <em>*</em>
              空间
              <div class="add_area" @click="addArea">
                未找到，<span class="blue_color">新增空间</span>
              </div>
            </div>
            <Field
              v-model="_DATA.form_info_pro.space_name"
              right-icon="arrow-down"
              readonly
              placeholder="选择空间"
              :rules="[
                {
                  required: true,
                  message: '请选择空间',
                },
              ]"
              @click="_DATA.showSpace = true"
            />

            <p class="label-name">供货周期</p>
            <Field
              type="digit"
              v-model.trim="_DATA.form_info_pro.lead_period"
              maxlength="3"
              placeholder="请填写供货周期"
              :rules="[
                {
                  validator: validatorNum,
                  message: '请填写正确的供货周期',
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <text>天</text>
              </template>
            </Field>
            <p class="label-name">品牌</p>
            <Field
              v-model="_DATA.form_info_pro.brand"
              maxlength="30"
              placeholder="请填写品牌"
            />
            <p class="label-name">型号</p>
            <Field
              v-model="_DATA.form_info_pro.model"
              maxlength="30"
              placeholder="请填写型号"
            />
            <p class="label-name">规格</p>
            <Field
              v-model="_DATA.form_info_pro.norms"
              maxlength="30"
              placeholder="请填写规格"
            />
            <p class="label-name">材质</p>
            <Field
              v-model="_DATA.form_info_pro.material"
              maxlength="30"
              placeholder="请填写材质"
            />
            <p class="label-name">颜色</p>
            <Field
              v-model="_DATA.form_info_pro.colour"
              maxlength="30"
              placeholder="请填写颜色"
            />
            <p class="label-name">数量</p>
            <Field
              v-model="_DATA.form_info_pro.amount"
              maxlength="30"
              placeholder="请填写数量"
              :rules="[
                {
                  validator: validatorProNum,
                  message: '请填写正确的数量',
                  trigger: 'onChange',
                },
              ]"
            />
            <p class="label-name">单位</p>
            <Field
              v-model="_DATA.form_info_pro.unit"
              right-icon="arrow-down"
              readonly
              placeholder="选择单位"
              @click="_DATA.showUnit = true"
            />

            <p class="label-name">备注</p>
            <Field
              v-model="_DATA.form_info_pro.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="有什么需要补充吗"
              show-word-limit
            ></Field>
          </div>
          <div class="stick-box">
            <Button type="primary" size="large" @click="onSubmitPro">
              完成
            </Button>
          </div>
        </Form>
      </div>
    </div>
  </Popup>
  <!-- 选择 产品大类 -->
  <Popup
    v-model:show="_DATA.show_category"
    round
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '70%' }"
  >
    <productCategory
      v-if="_DATA.show_category"
      @reviewClose="
        () => {
          _DATA.show_category = false;
        }
      "
      :data="_DATA.categoryInfo"
      @reviewOk="reviewOk"
    />
  </Popup>
  <!-- 选择产品子类弹框 -->
  <Popup v-model:show="_DATA.show_category_sub" position="bottom">
    <Picker
      :columns="_DATA.category_sub_list"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirmType(v)"
      @cancel="_DATA.show_category_sub = false"
    />
  </Popup>
  <!-- 选择单位弹框 -->
  <Popup v-model:show="_DATA.showUnit" position="bottom">
    <Picker
      :columns="_DATA.unitList"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirmUnit(v)"
      @cancel="_DATA.showUnit = false"
    />
  </Popup>
  <!-- 选择空间弹框 -->
  <Popup v-model:show="_DATA.showSpace" position="bottom">
    <Picker
      :columns="_DATA.spaceList"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirmSpace(v)"
      @cancel="_DATA.showSpace = false"
    />
  </Popup>
  <!-- 添加空间 -->
  <Popup v-model:show="_DATA.addAreaShow" position="bottom">
    <div class="area_pop">
      <Field
        ref="areaRef"
        v-model="_DATA.area_name"
        label=""
        placeholder="填写空间名"
        maxlength="10"
      >
      </Field>
      <Button type="primary" size="large" @click="saveArea">确定</Button>
    </div>
  </Popup>
  <!-- 选择 空间列表 -->
  <Popup
    v-model:show="_DATA.show_areaList"
    round
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '70%' }"
  >
    <areaSearchList
      v-if="_DATA.show_areaList"
      :data="_DATA.spaceList"
      :id="_DATA.customer_id"
      @reviewOk="reviewArea"
    />
  </Popup>
</template>
<script setup>
import _ from 'lodash';

import FileUpload4 from '@/components/common/FileUpload';
import FileUpload from '@/components/common/FileUpload';
import { fileDetail } from '@/utils/fileUpload';
import { inject, reactive, ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

import {
  NavBar,
  Form,
  Field,
  Button,
  Loading,
  Overlay,
  Popup,
  Notify,
  Picker,
  CheckboxGroup,
  Checkbox,
  Dialog,
  Image,
  Icon,
} from 'vant';
import productCategory from '@/components/order/mainSelfOrder/productCategory';
import areaSearchList from '@/components/order/mainSelfOrder/areaSearchList';
import patterns from '@/utils/pattern';
const host = inject('$host');
const $http = inject('$http');
const router = useRouter();

const barTitle = ref('新增自购产品');

const _DATA = reactive({
  loading: false,
  customer_id: router.currentRoute.value.query.id || '',
  status: router.currentRoute.value.query.status || '',
  show_category: false,
  category_list: [],
  show_category_sub: false,
  category_sub_list: [],
  showSpace: false,
  spaceList: [],
  showUnit: false,
  unitList: [],
  markShow: false,

  allMarkList: [],
  categoryInfo: {
    check: '',
    list: [],
  },
  productInfo: {
    title: '',
    index: '',
  },
  form_info_pro_default: {
    id: '', //数据id
    title: '添加产品',
    space_id: '',
    space_name: '',
    amount: '',
    category_sub_id: '',
    category_sub_name: '',

    brand: '',
    model: '',
    unit_id: '',
    unit: '',
    norms: '',
    material: '',
    colour: '',
    img: [],
    lead_period: '',
    remark: [],
  },
  form_info_pro: {},
  addAreaShow: false, //V1.4.7
  area_name: '',
  show_areaList: false,
});

const form_info = reactive({
  id: router.currentRoute.value.query.curid || '', //数据id
  construction_id: Number(router.currentRoute.value.query.gdid), //工地id
  supplier_name: '',
  responsible_name: '',
  responsible_phone: '',
  category_id: '',
  category_name: '',
  report_time: '',
  mark: [],
  mark_name: '',
  files: [],
  remark: '',
  product_detail: [],
  hasProduct: '',
});
// V1.4.7
const areaRef = ref(null);

watch(
  () => form_info.product_detail,
  (cur) => {
    let result = '';
    if (cur && cur.length > 0) {
      result = 1;
    }
    form_info.hasProduct = result;
  },
  {
    deep: true,
  }
);
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};

onMounted(() => {
  Object.assign(_DATA.form_info_pro, _DATA.form_info_pro_default);
  //获取参数列表
  getParams();

  if (form_info.id) {
    loadFunc();

    barTitle.value = '编辑自购产品';
  } else {
    barTitle.value = '新增自购产品';
  }
});

const getParams = () => {
  _DATA.loading = true;
  $http.mainSelfOrder_getParams().then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.allMarkList = data.main_order_mark;
      _DATA.category_list = data.product_category;
      _DATA.unitList = data.material_type;
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
  getAreaList();
};
const getAreaList = () => {
  _DATA.loading = true;
  $http
    .mainSelfOrder_spaceList({ customer_id: _DATA.customer_id })
    .then((res) => {
      const { data, code } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.spaceList = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//查询详情
const loadFunc = () => {
  _DATA.loading = true;
  $http.mainSelfOrder_info({ id: form_info.id }).then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      Object.assign(form_info, data);
      getCategory();
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

//选择产品子类
const onConfirmType = (value) => {
  _DATA.form_info_pro.category_sub_id = value.id;
  _DATA.form_info_pro.category_sub_name = value.name;
  _DATA.show_category_sub = false;
};
//选择单位
const onConfirmUnit = (value) => {
  _DATA.form_info_pro.unit_id = value.id;
  _DATA.form_info_pro.unit = value.name;
  _DATA.showUnit = false;
};
//选择空间
const onConfirmSpace = (value) => {
  if (value.id) {
    _DATA.form_info_pro.space_id = value.id;
    _DATA.form_info_pro.space_name = value.name;
    _DATA.showSpace = false;
  }
};

const saveMark = () => {
  if (form_info.mark && form_info.mark.length > 0) {
    let markList = _DATA.allMarkList.filter(
      (item) => form_info.mark.indexOf(item.id) > -1
    );
    let markNameList = markList.map((i) => i.name);
    form_info.mark_name = markNameList.join(',');
  }
  _DATA.markShow = false;
};

// @附件
const getFileList = (files) => {
  form_info.files = files;
};
const getFileList_pro = (files) => {
  _DATA.form_info_pro.img = files;
};

//材料明细
const addPro = () => {
  if (!form_info.category_id) {
    Notify({
      type: 'warning',
      message: '请先选择产品大类',
    });
    return;
  }

  //获取空间列表
  getAreaList();
  _DATA.productShow = true;
  _DATA.productInfo.title = '添加产品';
  _DATA.form_info_pro = JSON.parse(JSON.stringify(_DATA.form_info_pro_default));
};
const editPro = (item, index) => {
  //获取空间列表
  getAreaList();
  _DATA.productShow = true;
  _DATA.form_info_pro = JSON.parse(JSON.stringify(_DATA.form_info_pro_default));
  _DATA.form_info_pro = JSON.parse(JSON.stringify(item));
  _DATA.productInfo.title = '编辑产品';
  _DATA.productInfo.index = index;
};
const delPro = (index) => {
  Dialog.confirm({
    message: '是否确认删除？',
  }).then(() => {
    form_info.product_detail = form_info.product_detail.filter(
      (v, i) => i !== index
    );
  });
};

const reviewOk = (item) => {
  form_info.category_id = item?.id || '';
  form_info.category_name = item?.name || '';
  _DATA.show_category = false;
  getCategory();
};

const getCategory = () => {
  if (form_info.category_id) {
    $http
      .mainSelfOrder_getSubCategory({ category_id: form_info.category_id })
      .then((res) => {
        const { data, code } = res;
        if (code === 0) {
          _DATA.category_sub_list = data;
        } else {
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  }
};

// V1.4.7复制产品
const copyPro = (item, index) => {
  console.log(item, index);
  _DATA.areaList_item = _.cloneDeep(item);
  getAreaList();
  setTimeout(() => {
    _DATA.show_areaList = true;
  }, 300);
};
//V1.4.7 选择空间回调
const reviewArea = (val) => {
  console.log(val);
  _DATA.show_areaList = false;
  if (val && val.length > 0) {
    val.forEach((item, index) => {
      console.log(item, index);
      let insert = _.cloneDeep(_DATA.areaList_item);
      insert.space_id = item.id;
      insert.space_name = item.name;
      form_info.product_detail.push(insert);
    });
    Notify({
      type: 'success',
      message: '复制成功',
    });
  }
};
// V1.4.7保存空间
const saveArea = () => {
  if (_DATA.area_name) {
    $http
      .mainSelfOrder_spaceBatchSave({
        customer_id: _DATA.customer_id,
        name: _DATA.area_name,
      })
      .then((res) => {
        const { data, code } = res;
        if (code === 0) {
          _DATA.addAreaShow = false;
          Notify({
            type: 'success',
            message: '保存成功',
          });
          _DATA.form_info_pro.space_id = data.id;
          _DATA.form_info_pro.space_name = data.name;
          //刷新空间列表
          getAreaList();
        } else {
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  } else {
    Notify({
      type: 'warning',
      message: '请填写空间名',
    });
  }
};
//V1.4.7新增空间
const addArea = () => {
  _DATA.addAreaShow = true;
  _DATA.area_name = '';
  setTimeout(() => {
    areaRef.value.focus();
  }, 100);
};

// 校验
const { numReg, proNumReg, phoneReg } = patterns;
const validatorNum = (val) => (val ? numReg.test(val) : true);
const validatorProNum = (val) => (val ? proNumReg.test(val) : true);
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);

const formRef = ref(null);
const productformRef = ref(null);

//保存、编辑变更单
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    Dialog.confirm({
      message: '确认提交订单吗？请仔细核对填写数据',
    }).then(() => {
      _DATA.loading = true;
      $http
        .mainSelfOrder_save({
          ...form_info,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: data,
              duration: 1000,
              onClose: () => {
                _DATA.loading = false;
                router.back();
              },
            });
          } else {
            _DATA.loading = false;
            Notify({
              type: 'warning',
              message: data,
              duration: 2000,
            });
          }
        })
        .catch(() => {
          _DATA.loading = false;
        });
    });
  });
};
//保存、编辑产品明细
const onSubmitPro = () => {
  productformRef.value?.validate().then(() => {
    if (_DATA.productInfo.title == '添加产品') {
      form_info.product_detail.unshift(_DATA.form_info_pro);
      _DATA.productShow = false;
    } else {
      form_info.product_detail = form_info.product_detail.map((item, i) => {
        if (i == _DATA.productInfo.index) {
          item = _DATA.form_info_pro;
        }
        return item;
      });
      _DATA.productShow = false;
    }
  });
};
//-返回
const onClosePop = () => {
  router.back();
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
.page-box {
  background: @grey_color;
}

.mark_select {
  color: @grey-color;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;

  border-radius: 5px;

  padding: 10px 20px;

  border: 1px solid @line-color;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.mark_pop {
  padding: 20px;
  .mark_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .menuBox {
    margin: 0 10px 8px 0px;
    padding: 9px 20px;
    background: #ffffff;
    border: 1px solid @line-color;
    // border-radius: 25px;
    &.checked {
      background: @blue-bg-color;
      color: @blue-color;
    }
  }
}
.btn_pro_box {
  width: 100%;
  margin: 2px 0 14px 0;
  .btn_pro {
    color: @blue-color;
    border: 1px solid @line-color;
    font-size: 15px;
    line-height: 20px;
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }
}

.add_pro_box {
  width: 100%;
  background: #f5f5f5;

  .detail_box {
    padding: 0 20px 14px 20px;
    background: #ffffff;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    .top_box {
      padding: 14px 0;

      display: flex;
      align-items: flex-start;
      justify-content: center;
      .icon_name {
        background: rgba(0, 137, 17, 0.1);
        color: rgba(0, 137, 17, 0.4);
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        width: 40px;
        height: 40px;
      }
      .left {
        width: 40px;
        height: 40px;
      }
      .right {
        flex: 1;
        padding-left: 12px;

        .name_box {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          .name {
            font-size: 17px;
            font-weight: bold;
            line-height: 20px;
            padding-right: 5px;
          }
        }

        .info {
          line-height: 22px;
          .t1 {
            font-size: 13px;
            color: #767676;
            line-height: 20px;
            border-right: 1px solid #767676;
            padding: 0 5px;
            &.blue {
              color: #576b95;
            }
            &:last-child {
              border-right: none;
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }

    .dec_box {
      padding: 8px 0;
      border-top: 1px solid @line-color;
      .dec_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        font-size: 14px;
        color: @grey-color;
        line-height: 20px;
      }
    }
  }
  .opt_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .opt_item {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #576b95;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;

      .icon-small {
        width: 16px;
        height: 16px;
        fill: currentColor;
        margin-right: 5px;
      }
    }
  }
}
.pro_pop {
  .pro_title {
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
}
.add_area {
  position: absolute;
  top: 14px;
  right: 0;
  font-size: 13px;
}
.area_pop {
  background-color: #f5f5f5;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  :deep(.van-button) {
    width: 58px;
    height: 32px;
    margin-left: 10px;
  }
  :deep(.van-cell) {
    padding: 8px;
    font-size: 15px;
  }
}
</style>
