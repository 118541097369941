<!--
 * @Author: smart
 * @Date: 2022-11-02 17:57:45
 * @LastEditTime: 2023-12-26 10:19:22
 * @Description: 分派设计中心审核操作面板
 * v1.3.6 smart 同意之后渲染订单详细信息
 * v1.3.7 smart 接单客户经理、接单设计师交互变更，附带搜索条件
 * v1.4.4 smart 派单信息挪进来
 * v1.4.7 smart 根据接口要求，公共参数获取“接单设计师”增加customer_id传参
-->
<template>
  <div class="page-wrap">
    <Overlay :show="_DATA.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <h3 class="title_tip">{{ props.title }}</h3>
    <div class="formBase">
      <div
        class="warn"
        v-if="props.tipInfo?.receiving_time && props.data.checkStaus == 'ok'"
      >
        {{ props?.tipInfo?.tip_confirm_msg }}
      </div>
      <Collapse
        v-model="_DATA.activeNames"
        v-if="props.tipInfo?.receiving_time && props.data.checkStaus == 'ok'"
        style="margin-top: 15px"
      >
        <CollapseItem title="派单信息" name="1">
          <template #title>
            <div>
              <em style="font-weight: bold">派单信息</em>
              <span
                style="color: #f37020; font-size: 13px"
                v-if="props?.isDesignCenterManager != 1"
              >
                设计中心确认接单后可见
              </span>
            </div>
          </template>
          <ul class="ul-message" v-if="!_DATA.showMore">
            <li>
              <em>客户姓名：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.customer_name || '-' }}
              </span>
            </li>
            <li>
              <em>派单级别：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.dispatch_level_name || '-' }}
              </span>
            </li>
            <li>
              <em>预计见面时间：</em>
              <span>
                {{
                  props.tipInfo?.dispatch_info?.face_time ||
                  props.tipInfo?.dispatch_info?.meeting_time ||
                  '-'
                }}
              </span>
            </li>
            <li class="look-more">
              <b @click.stop="() => (_DATA.showMore = !_DATA.showMore)">
                全部详情
              </b>
            </li>
          </ul>
          <!-- 全部详情 -->
          <ul class="ul-message" v-if="_DATA.showMore">
            <li>
              <em>派单编号：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.dispatch_no || '-' }}
              </span>
            </li>
            <li>
              <em>线索编号：</em>
              <span>{{ props.tipInfo?.dispatch_info?.clue_no || '-' }}</span>
            </li>
            <li>
              <em>派单时间：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.dispatch_time || '-' }}
              </span>
            </li>
            <li>
              <em>派单负责人：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.responsible_name || '-' }}
              </span>
            </li>
            <li>
              <em>邀约人：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.add_uid_name || '-' }}
              </span>
            </li>
            <li>
              <em>派单备注：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.dispatch_remark || '-' }}
              </span>
            </li>
            <Divider style="margin: 10px 0" />
            <li>
              <em>客户姓名：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.customer_name || '-' }}
              </span>
            </li>
            <li>
              <em>派单级别：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.dispatch_level_name || '-' }}
              </span>
            </li>
            <li>
              <em>预计见面时间：</em>
              <span>
                {{
                  props.tipInfo?.dispatch_info?.face_time ||
                  props.tipInfo?.dispatch_info?.meeting_time ||
                  '-'
                }}
              </span>
            </li>
            <li>
              <em>见面方式：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.face_mode_name || '-' }}
              </span>
            </li>
            <li>
              <em>碰面地点：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.meet_addr || '-' }}
              </span>
            </li>
            <li>
              <em>楼盘：</em>
              <span>
                {{
                  props.tipInfo?.dispatch_info?.is_not_found_floor == 1
                    ? '找不到楼盘'
                    : props.tipInfo?.dispatch_info?.floor_name || '-'
                }}
              </span>
            </li>
            <li>
              <em>区域：</em>
              <span>
                {{
                  props.tipInfo?.dispatch_info?.is_city_unknown == 1
                    ? '未知'
                    : props.tipInfo?.dispatch_info?.city_name || '-'
                }}
              </span>
            </li>
            <li>
              <em>详细地址：</em>
              <span>{{ props.tipInfo?.dispatch_info?.address || '-' }}</span>
            </li>
            <li>
              <em>产证面积：</em>
              <span v-if="!!props.tipInfo?.dispatch_info?.size">
                {{ props.tipInfo?.dispatch_info?.size + '㎡' }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <em>扩建面积：</em>
              <span v-if="!!props.tipInfo?.dispatch_info?.extend_size">
                {{ props.tipInfo?.dispatch_info?.extend_size + '㎡' }}
              </span>
              <span v-else>-</span>
            </li>
            <li>
              <em>喜欢风格：</em>
              <span>{{ props.tipInfo?.dispatch_info?.like_style || '-' }}</span>
            </li>
            <li>
              <em>对设计师要求：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.design_need || '-' }}
              </span>
            </li>
            <li>
              <em>邀约方式：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.invite_method || '-' }}
              </span>
            </li>
            <li>
              <em>到访人数：</em>
              <span>{{ props.tipInfo?.dispatch_info?.visit_num || '-' }}</span>
            </li>
            <li>
              <em>关注问题：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.care_problem || '-' }}
              </span>
            </li>
            <li>
              <em>建议准备：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.suggested_pre || '-' }}
              </span>
            </li>
            <li>
              <em>客户情况：</em>
              <span>
                {{ props.tipInfo?.dispatch_info?.customer_situation || '-' }}
              </span>
            </li>
          </ul>
          <!-- end -->
        </CollapseItem>
      </Collapse>

      <Form ref="ruleFormRef" style="margin-top: 16px">
        <CellGroup>
          <div class="cell" v-if="props.data.checkStaus == 'ok'">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              接单客户经理
            </label>
            <Field
              v-model="form.list.manager_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择接单客户经理"
              :rules="[
                {
                  required: true,
                  message: '请选择接单客户经理',
                },
              ]"
              @click="openPicker('接单客户经理')"
            />
          </div>
          <div class="cell" v-if="props.data.checkStaus == 'ok'">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              接单设计师
            </label>
            <Field
              v-model="form.list.desginer_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择接单设计师"
              :rules="[
                {
                  required: true,
                  message: '请选择接单设计师',
                },
              ]"
              @click="openPicker('接单设计师')"
            />
          </div>
          <div class="cell">
            <label class="form_title">审核信息</label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注由提交人可见"
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" style="box-shadow: none">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        确定
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-设计师 --->
    <!--picker-客户经理 --->
    <!-- <Popup v-model:show="_DATA.showPicker_manager" position="bottom">
      <Picker
        :columns="_DATA.managerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'manager')"
        @cancel="_DATA.showPicker_manager = false"
        :default-index="
          _DATA.managerList.findIndex((v) => {
            return v.id == form.list.manager;
          })
        "
      />
    </Popup> -->
    <!--picker-设计师 --->
    <!--picker-客户经理 --->
    <Popup
      v-model:show="picker.show"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempUser
        v-if="picker.show"
        :data="picker"
        @reviewClose="
          () => {
            picker.show = false;
          }
        "
        @reviewOk="(item) => reviewOk_person(item, '接单客户经理')"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, defineProps } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Toast,
  Notify,
  Overlay,
  Loading,
  Collapse,
  CollapseItem,
  Divider,
} from 'vant';
import { useRouter } from 'vue-router';
import TempUser from '@/components/task/TempUser';

const props = defineProps([
  'data',
  'title',
  'id',
  'tipInfo',
  'isDesignCenterManager',
  'dispatchId',
]);
const router = useRouter();
const $http = inject('$http');
const _DATA = reactive({
  loadingConfirm: false,
  //-客户经理-picker
  showPicker_manager: false,
  managerList: [],
  //-设计师-picker
  showPicker_desginer: false,
  desginerList: [],
  activeNames: ['1'],
  tipInfo: {},
  showMore: false, //全部详情
});

//-获取参数--begin
onBeforeMount(() => {
  console.log(props);
  getPublicParameter();
});

/**
 * 接口api
 */
// -- 获取参数
const getPublicParameter = async () => {
  await $http
    .customerAssignServicePersonParam({
      customer_id: props?.tipInfo?.dispatch_info?.customer_id,
      dispatch_id: props?.dispatchId,
    })
    .then((res) => {
      if (res.code === 0) {
        _DATA.managerList = res.data.customer_manager;
        _DATA.desginerList = res.data.design;
      } else {
        Toast(res.data);
      }
    });
};

/**
 * 操作
 */

// -- 客户经理、设计师
const picker = reactive({
  show: false,
  title: '', // 接单客户经理、接单设计师
  type: 'customer_manager', //customer_manager、design
  checkedItem: { id: '', name: '' }, //接单客户经理、接单设计师
  customer_id: props?.tipInfo?.dispatch_info?.customer_id,
  dispatch_id: props?.dispatchId,
});
const openPicker = (type_name) => {
  picker.title = type_name;
  picker.show = true;
  if (type_name === '接单客户经理' || type_name === '客户经理') {
    picker.type = 'customer_manager';
    picker.checkedItem.id = form.list.manager;
    picker.checkedItem.name = form.list.manager_name;
  } else if (type_name === '接单设计师' || type_name === '设计师') {
    picker.type = 'design';
    picker.checkedItem.id = form.list.desginer;
    picker.checkedItem.name = form.list.desginer_name;
  }
};

// -- 选择器回调
const reviewOk_person = (checkedItem) => {
  picker.show = false;
  if (picker.type === 'customer_manager') {
    form.list.manager = checkedItem.id;
    form.list.manager_name = checkedItem.name;
  } else if (picker.type === 'design') {
    form.list.desginer = checkedItem.id;
    form.list.desginer_name = checkedItem.name;
  }
};

// -- 表单
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    remark: '',
    manager: '',
    manager_name: '',
    desginer: '',
    desginer_name: '',
  },
});

// -- 下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      _DATA.loadingConfirm = true;
      operateFun();
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

// -- 调用接口
const operateFun = () => {
  let obj = {
    task_id: props.id,
    approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
    design_id: form.list.desginer,
    customer_manager_id: form.list.manager,
    remark: form.list.remark,
  };

  $http.getTaskApproveDesignCenter(obj).then((res) => {
    const { code, data } = res;
    _DATA.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
.ul-message {
  line-height: 26px;
  li {
    display: flex;
    em {
      flex-grow: 0;
      flex-shrink: 0;
    }
    span {
      flex-wrap: wrap;
    }
    &.look-more {
      justify-content: center;
      margin: 6px 0 0px;
      b {
        color: #3577c7;
        cursor: pointer;
      }
    }
  }
}
:deep(.van-collapse-item__content) {
  padding: 0 14px 0 !important;
}
:deep(.van-collapse-item__title) {
  font-size: 15px;
  font-weight: bold;
  background: #f5f5f5;
  border: none !important;
  margin-bottom: 10px;
  padding: 10px var(--van-cell-horizontal-padding) 10px 10px;
}
:deep(.van-cell--clickable:active) {
  background: #f5f5f5;
}
:deep([class*='van-hairline']:after) {
  border: none;
}
:deep(.van-cell__title > span) {
  font-size: 15px !important;
  font-weight: bold !important;
}
.warn {
  color: #f37020;
  font-size: 14px;
}
</style>
