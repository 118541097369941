/*
 * @Author: gest
 * @Date: 2022-05-24 18:35:30
 * @LastEditTime: 2023-07-13 18:38:01
 * @Description:文件上传展示通用方法
 */
import { reactive } from 'vue';
import { ImagePreview, Toast } from 'vant';
// import _ from 'lodash';
const $host = process.env.NODE_ENV === 'production' ? '' : '/api';

// const file_type = ref(2); //

const jsonIcon = [
  {
    type: ['png', 'jpg', 'jpeg'],
    name: '#icon-tupian-jpg_png',
    isImg: true,
    isVideo: false,
    isAudio: false,
  },
  {
    type: ['mp3', 'wav', 'ogg'],
    name: '#icon-mp3',
    isImg: false,
    isVideo: false,
    isAudio: true,
  },
  {
    type: ['mp4', '3gp', 'wmv', 'mov'],
    name: '#icon-shipin-mov_mp4_avi',
    isVideo: true,
    isImg: false,
    isAudio: false,
  },
  {
    type: ['zip', 'rar'],
    name: '#icon-yasuowenjian-zip_rar_7z',
    isImg: false,
    isVideo: false,
    isAudio: false,
  },
  {
    type: ['doc', 'docx', 'dotx', 'dot'],
    name: '#icon-wendang-docx_doc',
    isImg: false,
    isVideo: false,
    isAudio: false,
  },
  {
    type: ['xlsx', 'xls', 'xltx', 'xlt'],
    name: '#icon-biaoge-xlxs_xls',
    isImg: false,
    isVideo: false,
    isAudio: false,
  },
  {
    type: ['ppt', 'pptx'],
    name: '#icon-yanshiwendang-ppt_pptx',
    isImg: false,
    isVideo: false,
    isAudio: false,
  },
  {
    type: ['pdf'],
    name: '#icon-pdf',
    isImg: false,
    isVideo: false,
    isAudio: false,
  },

  {
    type: ['dwg', 'dxf', 'dws', 'dwt'],
    name: '#icon-CAD',
    isImg: false,
    isVideo: false,
    isAudio: false,
  },
];
const _showData = reactive({
  showVideo: false,
  showAudio: false,
  info: {},
});

const isImg = (type) => {
  let result = true;
  if (type) {
    const fileItem = jsonIcon.find((item) => {
      return item.type.includes(type);
    });
    result = fileItem && fileItem.isImg;
  }
  return result;
};
const isVideo = (type) => {
  let result = false;
  const fileItem = jsonIcon.find((item) => {
    return item.type.includes(type);
  });
  result = fileItem && fileItem.isVideo;
  return result;
};
const isAudio = (type) => {
  let result = false;
  if (type) {
    const fileItem = jsonIcon.find((item) => {
      return item.type.includes(type);
    });
    result = fileItem && fileItem.isAudio;
  }
  return result;
};
const getIcon = (type) => {
  const result = jsonIcon.find((item) => {
    return item.type.includes(type);
  });

  return result ? result.name : '';
};
//-预览
const fileDetail = (item, itemList) => {
  if (isImg(item.type)) {
    const images = [];
    itemList.forEach((e) => {
      if (isImg(e.type)) {
        images.push({ path: e.path });
      }
    });
    let pos = images.findIndex((v) => {
      return v.path == item.path;
    });
    ImagePreview({
      images: images.map((v) => $host + v.path), // 预览图片
      startPosition: pos,
    });
  } else if (isVideo(item.type)) {
    //视频弹窗播放
    _showData.info = item;
    _showData.showVideo = true;
    _showData.showAudio = false;
  } else if (isAudio(item.type)) {
    //音频弹窗播放
    _showData.info = item;
    _showData.showAudio = true;
    _showData.showVideo = false;
  } else {
    // window.location.href = $host + item.path;
    window.open($host + item.path);
  }
};
const acceptFile = (type) => {
  let limit_accept =
    '.png,.jpg,.jpeg,.mp3,.wav,.ogg,.mp4,.3gp,.mov,.zip,.rar,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.dwg,.dxf';
  switch (type) {
    case 3:
      limit_accept = '.png,.jpg,.jpeg,.mp4,.3gp,.mov';
      break;
    case 4:
      limit_accept = '.png,.jpg,.jpeg';
      break;
    case 5:
      limit_accept = '.pdf';
      break;
    default:
      break;
  }
  return limit_accept;
};
// 文件类型
const acceptFileStr = (type) => {
  let str = acceptFile(type);
  let re = new RegExp(',', 'g');
  let str2 = str.replace(re, '、');
  let str3 = str2.replace(/\./g, '');
  return str3;
};
const getlimitSize = (type) => {
  let limit_size = 100 * 1024 * 1024;
  switch (type) {
    case 1:
      limit_size = 700 * 1024 * 1024;
      break;
    case 3:
      limit_size = 50 * 1024 * 1024;
      break;
    case 4:
      limit_size = 10 * 1024 * 1024;
      break;
    case 5:
      limit_size = 40 * 1024 * 1024;
      break;
    default:
      break;
  }
  return limit_size;
};
// 文件大小
const fileSizeStr = (type) => {
  let size = getlimitSize(type);
  if (size < 1024) {
    return size + 'B';
  } else if (size >= 1024 && size < Math.pow(1024, 2)) {
    return parseFloat(size / 1024).toFixed(2) + 'KB';
  } else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) {
    return parseFloat(size / Math.pow(1024, 2)).toFixed(2) + 'MB';
  } else if (size > Math.pow(1024, 3)) {
    return parseFloat(size / Math.pow(1024, 3)).toFixed(2) + 'GB';
  } else {
    return 0 + 'B';
  }
};
//-附件上传 限制
// const beforeRead = (file) => {
//   let result = true;
//   if (file instanceof Array) {
//     //判断是否是数组
//     let isNot = true;
//     file.forEach((v) => {
//       isNot = checkFile(v);
//       if (!isNot) {
//         result = false;
//       }
//     });
//   } else {
//     result = checkFile(file,file_type);
//   }
//   return result;
// };
//类型检查
const checkFile = (file, file_type) => {
  let result = true;
  let typeName = file.name.slice(file.name.lastIndexOf('.') + 1);
  if (file.size > getlimitSize(file_type)) {
    Toast('上传附件不得大于' + fileSizeStr(file_type));
    result = false;
    return result;
  }
  let limit_accept = acceptFile(file_type);
  if (!limit_accept.includes(typeName.toLowerCase())) {
    Toast('上传附件类型' + typeName + '不支持');
    result = false;
    return result;
  }
  return result;
};
export {
  // file_type,
  acceptFile,
  acceptFileStr,
  // beforeRead,
  isImg,
  isVideo,
  isAudio,
  getIcon,
  _showData,
  fileDetail,
  checkFile,
};
