<!--
 * @Author: Jessica
 * @Date: 2023-06-20 15:34:11
 * @LastEditTime: 2024-05-28 16:22:09
 * @Description:V1.4.0-转化时间
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title flex_line_between">
            转化时间
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!-- 时间目标/当年完成/差值 -->
          <Row>
            <Col span="8">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info.now?.target + '天' }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info.now?.actual + '天' }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info.now?.actual > _DATA.info.now?.target"
              >
                <span class="bold green">{{ _DATA.info.now?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info.now?.actual < _DATA.info.now?.target"
              >
                <span class="bold orange">{{ _DATA.info.now?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">履约率</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info.reach?.actual + '%' }}
                </span>
              </div>
            </Col>
          </Row>
        </div>

        <div class="data_card_table_bottom border_top">
          <Row class="mb_15">
            <Col span="6">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="14" style="position: relative">
              <div class="chart_pos pr_30 pl_20">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 2,
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
              </div>
            </Col>
            <!-- 进度图标题 end-->

            <Col span="4">
              <div class="grey align_center">履约率</div>
            </Col>
          </Row>
          <Collapse
            v-model="_DATA.collapse_active"
            ref="collapseGroupRef"
            :accordion="false"
            :border="false"
          >
            <div
              class="sub_list"
              v-for="(item, index) in _DATA.list"
              :key="index"
            >
              <CollapseItem
                :name="index"
                :is-link="false"
                v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              >
                <template #title>
                  <Row class="mb_20" style="align-items: center; height: 100%">
                    <Col span="6">
                      <div
                        class="size13"
                        style="display: flex; align-items: center"
                      >
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) == -1"
                          style="transform: rotate(0deg)"
                          class="arrow"
                        />
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) > -1"
                          style="transform: rotate(90deg)"
                          class="arrow"
                        />
                        <p>{{ item.short_name }}</p>
                      </div>
                    </Col>
                    <!-- 进度图内容 begin-->
                    <Col span="14" class="chart_item">
                      <div style="display: flex" class="pr_30 pl_20">
                        <chartJDZW
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            actual: item.now.actual,
                            target: item.now.target,
                          }"
                        ></chartJDZW>
                      </div>

                      <!-- 竖线 begin-->
                      <div class="chart_pos pr_30 pl_20">
                        <chartCoverLine
                          :data="{
                            type: 2,
                            list: _DATA.line_values,
                            num: _DATA.middle_value,
                          }"
                        ></chartCoverLine>
                      </div>
                      <!-- 竖线 end-->
                    </Col>
                    <!-- 进度图内容 end-->

                    <Col span="4">
                      <div>
                        <span class="lineheight16 align_center">
                          {{ item.reach.actual + '%' }}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </template>
                <Row
                  class="mb_10 mt_10"
                  v-show="item.sub_list && item.sub_list.length > 0"
                >
                  <Col span="6">
                    <div class="grey pr_10">
                      {{ props.data.depId ? '人员' : '部门' }}
                    </div>
                  </Col>

                  <Col span="7"><div class="grey align_center">目标</div></Col>
                  <Col span="7">
                    <div class="grey align_center">完成</div>
                  </Col>

                  <Col span="4">
                    <div class="grey align_center">履约率</div>
                  </Col>
                </Row>
                <div
                  class="sub_item"
                  v-for="(sub, index) in item.sub_list"
                  :key="index"
                >
                  <Row class="mb_20" @click="goDetail(sub)">
                    <Col span="6">
                      <div class="size13 pr_10">
                        {{ sub.short_name }}
                      </div>
                    </Col>
                    <Col span="7">
                      <div>
                        <span class="align_center">
                          {{ sub.now.target + '天' }}
                        </span>
                      </div>
                    </Col>
                    <Col span="7">
                      <div>
                        <span class="align_center">
                          {{ sub.now.actual + '天' }}
                        </span>
                      </div>
                    </Col>

                    <Col span="4">
                      <div>
                        <span class="align_center">
                          {{ sub.reach.actual + '%' }}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CollapseItem>
            </div>
          </Collapse>
        </div>
        <div class="p_15 flex_center">
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
        </div>
      </Skeleton>
    </div>

    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  defineExpose,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify, Collapse, CollapseItem } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJDZW from '@/components/targetData/chatTemp/chartJDZW';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  //-在全部数据||部门数据不同页面所处的位置
  index: props.data.depId ? 5 : 2,
  depIndex: 6, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  open: false,
  collapse_active: [], //锁定
  line_values: [],
  middle_value: '',
});

//-监听 切换部门-切换路由
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);

onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depConvertTime;
  let params = { year: props.data.yearId, column_num: 4 };
  if (props.data.depId) {
    url = $http.targetStatistic_personConvertTime;
    params = { department_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.info = {};
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */
const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depDesginData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};

//-规则说明--begin
const htmlTitle = '转化时间_规则';
const htmlContent = `<p>转化时间=客户转化时间-协议有效签约时间</p>
  <p>当年转化时间=累计当年转化时间/累计当年转化数量（天/保留1位小数）</p>`;
const createDanger = () => {
  emit('createDanger', 'transformTimeTempRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
