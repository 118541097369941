<!--
 * @Author: gest
 * @Date: 2023-09-12 15:51:51
 * @LastEditTime: 2023-11-09 15:45:02
 * @Description: v1.4.6 产品下单记录表
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="mb_15">{{ item.name }}</div>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick(item)"
                  v-model="item.value1"
                  direction="horizontal"
                  checked-color="#008911"
                >
                  <Radio :name="1">公司采购</Radio>
                  <Radio :name="2">客户自购</Radio>
                  <Radio :name="3">无此产品</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>

        <div
          class="list_field"
          style="background-color: #fafafa"
          v-if="item.value1 == 1 || item.value1 == 2"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">下单完成</div>
              <Field
                style="background: transparent"
                class="grey_field"
                name="radio"
                label=""
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                  },
                ]"
              >
                <template #input>
                  <RadioGroup
                    v-model="item.value2"
                    direction="horizontal"
                    checked-color="#008911"
                  >
                    <Radio :name="1">是</Radio>
                  </RadioGroup>
                </template>
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="有什么需要补充的..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio } from 'vant';
import FileUpload from '@/components/common/FileUpload';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
const radioClick = (data) => {
  data.value2 = null;
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
:deep(.van-radio-group--horizontal) {
  justify-content: space-between;
  width: 100%;
}
</style>
