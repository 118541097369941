<!--
 * @Author: Jessica
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-30 14:49:22
 * @Description:V1.4.0-累计阶段在建工地数
 * @Description:V1.6.0-交付阶段数据--重构
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title">交付阶段数据</div>
          <!-- 图例 -->
          <Row v-if="props.data?.now_year == props.data?.yearId">
            <Col span="6"></Col>
            <Col span="12">
              <div class="lenged">
                <p class="len1">
                  <span class="sq blue"></span>
                  <span class="t">存量</span>
                </p>
                <p class="len2">
                  <span class="sq green"></span>
                  <span class="t">延期</span>
                </p>
              </div>
            </Col>
            <Col span="6"></Col>
          </Row>
          <!-- 漏斗图 -->
          <div class="chart_ld chart_ld2" ref="chartsRef">
            <!-- 当年 -->
            <Row v-if="props.data?.now_year == props.data?.yearId">
              <Col span="6">
                <p class="ld_title" v-for="i in _DATA.typeList" :key="i.id">
                  {{ i.name }}
                </p>
              </Col>
              <Col span="12">
                <!-- 对比漏斗图 -->
                <div class="main">
                  <div class="chart" id="container_1"></div>
                  <div class="chart" id="container_2"></div>
                </div>
              </Col>
              <Col span="3">
                <p
                  class="num_dot"
                  v-for="(i, index) in _DATA.passed_list"
                  :key="i.id"
                >
                  <span :class="`dot dotp` + index"></span>
                  <span class="it">{{ i.value }}</span>
                </p>
              </Col>
              <Col span="3">
                <p
                  class="num_dot"
                  v-for="(i, index) in _DATA.delay_list"
                  :key="i.id"
                >
                  <span :class="`dot dotd` + index"></span>
                  <span class="it">{{ i.value }}</span>
                </p>
              </Col>
            </Row>
            <!-- 往年 -->
            <Row v-else>
              <Col span="6">
                <p class="ld_title" v-for="i in _DATA.typeList" :key="i.id">
                  {{ i.name }}
                </p>
              </Col>
              <Col span="14">
                <!-- 对比漏斗图 -->
                <div class="main">
                  <div class="chart" id="container_3"></div>
                </div>
              </Col>
              <Col span="4">
                <p
                  class="num_dot"
                  v-for="(i, index) in _DATA.done_list"
                  :key="i.id"
                >
                  <span :class="`dot dotp` + index"></span>
                  <span class="it">{{ i.value }}</span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
      <!-- 按钮切换-->
      <div class="data_card_table pb_10">
        <ul class="site_type_list clearfix">
          <li
            class="size15 pull-left"
            :class="{ checked: _DATA.tabActive == i.id }"
            v-for="i in _DATA.typeList"
            :key="i.id"
            @click="changeStage(i)"
          >
            {{ i.name }}
          </li>
        </ul>
      </div>

      <!-- 按部门 加载图表-->
      <div>
        <!-- 当年 -->
        <div
          class="data_card_table border_top"
          v-if="props.data?.now_year == props.data?.yearId"
        >
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col span="15" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->

                <chartCoverTop
                  :data="{
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <Col span="4">
              <div class="grey size13 align_center">存量</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.listStage" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="15" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      actual: item.delay_num,
                      target: item.doing_num,
                      list: _DATA.line_values,
                      type: 2,
                    }"
                  ></chartJD>

                  <div class="chart_right"></div>
                </div>
                <div style="display: flex" class="mt_10">
                  <chartJD
                    :data="{
                      actual: item.done_num,
                      list: _DATA.line_values,
                      type: 4,
                    }"
                  ></chartJD>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <Col span="4" class="flex_line_center">
                <div>
                  {{ item.doing_num }}
                </div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 往年 -->
        <div class="data_card_table border_top" v-else>
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col span="19" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->

                <chartCoverTop
                  :data="{
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.listStage" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="19" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      actual: item.done_num,
                      list: _DATA.line_values,
                      type: 3,
                    }"
                  ></chartJD>
                  <div class="chart_right"></div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 区分当年/往年-->
        <div
          class="p_15 flex_center"
          v-if="props.data?.now_year == props.data?.yearId"
        >
          <p class="box_8 grey_bg"></p>
          <p class="ml_5">存量</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">延期</p>
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">完成</p>
        </div>
        <div class="p_15 flex_center" v-else>
          <p class="box_8 blue_bg"></p>
          <p class="ml_5">完成</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import _ from 'lodash';
import { useRouter } from 'vue-router';
import { renderFunnelChart } from '@/utils/chartLD';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
import {
  defineProps,
  defineEmits,
  reactive,
  inject,
  watch,
  onMounted,
  nextTick,
  ref,
} from 'vue';
import { Row, Col, Skeleton, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 4, //处在页面的第几个位置
  depIndex: 4, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  typeList: [],
  loadingStage: false,
  tabActive: props.data.tabActive ? props.data.tabActive : 1,
  //-对比漏斗图数据
  //-@存量List
  passed_list: [],
  //-@延期List
  delay_list: [],
  //-@已完成
  done_list: [],
  //-进度条相关
  line_values: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      _DATA.tabActive = 2;
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_stageConstructionNum;
  let params = { year: props.data.yearId };
  if (props.data.depId) {
    params = { department_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      getListFun(data.list);
      getParams();
      getStage('load');
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
//-获取阶段参数
const getParams = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.typeList = data.construction_stage;
    } else {
      _DATA.typeList = [];
    }
  });
};
//-获取阶段数据
const getStage = (type) => {
  _DATA.loadingStage = true;
  let url = $http.targetStatistic_getConstructionNumByStage;
  let params = {
    type_sort: _DATA.tabActive,
    year: props.data.yearId,
    column_num: 3,
  };
  if (props.data.depId) {
    params = {
      department_id: props.data.depId,
      type_sort: _DATA.tabActive,
      year: props.data.yearId,
      column_num: 3,
    };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loadingStage = false;
    if (code === 0) {
      _DATA.listStage = data.list;
      _DATA.line_values = data.line_values;
    } else {
      _DATA.listStage = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    if (type) {
      nextTick(() => {
        emit('loadEnd');
      });
    }
  });
};
/**
 * 操作
 */
//-@切换阶段
const changeStage = (i) => {
  _DATA.tabActive = i.id;
  getStage();
};
//-@跳转三级页面
const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depSiteData',
    query: {
      depId: item.id,
      depName: item.short_name,
      pos: _DATA.depIndex,
      tabActive: _DATA.tabActive,
    },
  });
};

//-对比漏斗图--begin
const chartsRef = ref();
//-@-获取对比漏斗图数据/加载--begin
const getListFun = (list) => {
  var height = 125;
  const padding = [0, 10];
  const color_1 = ['#0256FF', '#165DFF', '#4080FF', '#94BFFF', '#d1e3ff'];
  // const color_2 = ['#00000000', '#4DAD59', '#A6D6AC', '#E5F3E7', '#edfaef'];
  const color_2 = ['#008911', '#4DAD59', '#A6D6AC', '#E5F3E7', '#edfaef'];
  //-判断是否为当年
  if (props.data?.now_year == props.data?.yearId) {
    _DATA.passed_list = list.map((v) => {
      return { name: v.name, id: v.id, value: Number(v.doing_num) };
    });
    _DATA.delay_list = list.map((v) => {
      return { name: v.name, id: v.id, value: Number(v.delay_num) };
    });
    const indices = _.filter(_.keys(_DATA['delay_list']), function (index) {
      return _DATA['delay_list'][index].value === 0;
    });
    // 特殊处理 为0改为透明色
    if (indices?.length) {
      if (indices.indexOf('0') > -1) {
        color_2[0] = '#00000000';
      }
      if (indices.indexOf('1') > -1) {
        color_2[1] = '#00000000';
      }
      if (indices.indexOf('2') > -1) {
        color_2[2] = '#00000000';
      }
      if (indices.indexOf('3') > -1) {
        color_2[3] = '#00000000';
      }
      if (indices.indexOf('4') > -1) {
        color_2[4] = '#00000000';
      }
    }

    const passed_list = _DATA.passed_list;
    const delay_list = _DATA.delay_list;
    const maxValue1 = Math.max(...passed_list.map((d) => d.value));
    const maxValue2 = Math.max(...delay_list.map((d) => d.value));
    nextTick(() => {
      height = chartsRef?.value?.clientHeight ?? 125;
      renderFunnelChart(
        'container_1',
        height,
        padding,
        _DATA.passed_list,
        color_1,
        maxValue1,
        1
      );
      renderFunnelChart(
        'container_2',
        height,
        padding,
        _DATA.delay_list,
        color_2,
        maxValue2,
        maxValue2 / maxValue1
      );
    });
  } else {
    //-往年
    _DATA.done_list = list.map((v) => {
      return { name: v.name, id: v.id, value: Number(v.done_num) };
    });
    const done_list = _DATA.done_list;
    const maxValue3 = Math.max(...done_list.map((d) => d.value));
    nextTick(() => {
      height = chartsRef?.value?.clientHeight ?? 125;
      renderFunnelChart(
        'container_3',
        height,
        padding,
        _DATA.done_list,
        color_1,
        maxValue3,
        1
      );
    });
  }
};
//-@-获取对比漏斗图数据/加载--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
