<!--
 * @Author: Jessica
 * @Date: 2023-07-21 15:09:37
 * @LastEditTime: 2023-10-18 15:25:47
 * @Description: v1.4.2 新增客户信息
-->
<template>
  <NavBar
    safe-area-inset-top
    title="新增客户"
    left-arrow
    :fixed="true"
    @click-left="close"
  />
  <!-- 新增客户信息 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <!-- ------基础信息------ -->
          <div class="title_box">基础信息</div>
          <div class="cell flex">
            <div class="w60">
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                客户姓名
              </label>
              <Field
                v-model="form.list.name"
                maxlength="20"
                placeholder="填写客户姓名"
                :rules="formRules.name"
              />
            </div>
            <div class="w35">
              <label class="form_title">性别</label>
              <Field
                v-model="form.list.gender_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="选择性别"
                @click="state.showSexPicker = true"
              />
            </div>
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              手机号1
            </label>
            <Field
              v-model="form.list.phone1"
              placeholder="填写手机号"
              :rules="formRules.phone1"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              客户来源
            </label>
            <Field
              v-model="form.list.customer_type_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="选择客户来源"
              :rules="formRules.customer_type"
              @click="state.showSourcePicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">手机号2</label>
            <Field
              v-model="form.list.phone2"
              placeholder="填写手机号"
              :rules="formRules.phone2"
            />
          </div>
          <div class="cell">
            <label class="form_title">微信号</label>
            <Field
              v-model="form.list.wechat"
              maxlength="50"
              placeholder="填写客户微信号"
            />
          </div>
          <div class="cell">
            <label class="form_title">推荐人</label>
            <Field
              v-model="form.list.recommander"
              maxlength="20"
              placeholder="请填写推荐人"
            />
          </div>

          <div class="cell">
            <label class="form_title">BPM编码</label>
            <Field
              v-model="form.list.bpm_id"
              maxlength="20"
              placeholder="请输入BPM编码"
            />
          </div>
          <!-- ------房屋信息------ -->
          <div class="title_box">房屋信息</div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              楼盘
              <Icon name="question" color="#FFAE4E" @click.stop="getContent" />
            </label>
            <Field
              v-model="form.list.floor_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择楼盘名称"
              :rules="formRules.floor_id"
              @click="state.showFloorPopup = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              单元栋号
            </label>
            <Field
              v-model="form.list.floor_detail_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="选择单元栋号"
              :rules="formRules.floor_detail_id"
              @click="state.showFloorDetailPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              面积
            </label>
            <Field
              v-model="form.list.size"
              maxlength="7"
              placeholder="请填写房屋面积"
              :rules="formRules.size"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              区域
            </label>
            <Field
              v-model="form.list.city_name"
              readonly
              placeholder="请选择省-市-区"
              :rules="formRules.city_id"
              @click="getCityFun"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              详细地址
            </label>
            <Field
              v-model="form.list.address"
              maxlength="50"
              placeholder="填写详细地址"
              :rules="formRules.address"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              精装/毛坯
            </label>
            <Field
              v-model="form.list.house_category_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="选择精装/毛坯"
              :rules="formRules.house_category"
              @click="state.showCategoryPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">购房情况</label>
            <Field
              v-model="form.list.house_type_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="现房/期房/二手房"
              @click="state.showHouseTypePicker = true"
            />
          </div>
          <div
            class="cell"
            v-if="
              ['期房', '半年内期房'].indexOf(form.list.house_type_name) > -1
            "
          >
            <label class="form_title">
              <div class="flex_left">
                <p class="label_title">
                  <span style="color: #ea3d2f">*</span>
                  预计交房时间
                </p>
                <Checkbox
                  class="check_text"
                  v-model="state.known_situation"
                  shape="square"
                  :icon-size="16"
                  @change="changeNotFound"
                >
                  时间未知
                </Checkbox>
              </div>
            </label>
            <Field
              v-if="state.known_situation"
              v-model="form.list.plan_delivery_day"
              right-icon="clock-o"
              readonly
              name="picker"
              placeholder="请选择预计交房时间"
              disabled
            />
            <Field
              v-else
              v-model="form.list.plan_delivery_day"
              right-icon="clock-o"
              readonly
              name="picker"
              placeholder="请选择预计交房时间"
              :rules="formRules.plan_delivery_day"
              @click.stop="state.showDeliveryDate = true"
            />
          </div>
          <div class="cell" v-if="form.list.house_type_name == '未购房'">
            <label class="form_title">
              <div class="flex_left">
                <p class="label_title">
                  <span style="color: #ea3d2f">*</span>
                  预计购房时间
                </p>
                <Checkbox
                  class="check_text"
                  v-model="state.known_situation"
                  shape="square"
                  :icon-size="16"
                  @change="changeNotFound"
                >
                  时间未知
                </Checkbox>
              </div>
            </label>
            <Field
              v-if="state.known_situation"
              v-model="form.list.plan_buy_day"
              right-icon="clock-o"
              readonly
              name="picker"
              placeholder="请选择预计购房时间"
              disabled
            />
            <Field
              v-else
              v-model="form.list.plan_buy_day"
              right-icon="clock-o"
              readonly
              name="picker"
              placeholder="请选择预计购房时间"
              :rules="formRules.plan_buy_day"
              @click.stop="state.showBuyDate = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">房屋结构</label>
            <Field
              v-model="form.list.home_type_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="选择房屋结构"
              @click="state.showHomeTypePicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写备注..."
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.path_arr"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- picker---集合 -->
    <div>
      <!-- 性别 -->
      <Popup v-model:show="state.showSexPicker" position="bottom">
        <Picker
          :columns="options.gender_list"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'gender')"
          @cancel="state.showSexPicker = false"
          :default-index="
            options.gender_list.findIndex((v) => {
              return v.id == form.list.gender;
            })
          "
        />
      </Popup>
      <!-- 客户来源 -->
      <Popup v-model:show="state.showSourcePicker" position="bottom">
        <Picker
          :columns="options.customer_source"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'source')"
          @cancel="state.showSourcePicker = false"
          :default-index="
            options.customer_source.findIndex((v) => {
              return v.id == form.list.gender;
            })
          "
        />
      </Popup>
      <!--  精装/毛坯  house_category-->
      <Popup v-model:show="state.showCategoryPicker" position="bottom">
        <Picker
          :columns="options.house_category"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'category')"
          @cancel="state.showCategoryPicker = false"
          :default-index="
            options.house_category.findIndex((v) => {
              return v.id == form.list.house_category;
            })
          "
        />
      </Popup>
      <!-- 购房情况 house_type-->
      <Popup v-model:show="state.showHouseTypePicker" position="bottom">
        <Picker
          :columns="options.house_type"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'house_type')"
          @cancel="state.showHouseTypePicker = false"
          :default-index="
            options.house_type.findIndex((v) => {
              return v.id == form.list.house_type;
            })
          "
        />
      </Popup>
      <!-- 房屋结构 home_type-->
      <Popup v-model:show="state.showHomeTypePicker" position="bottom">
        <Picker
          :columns="options.home_type"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'home_type')"
          @cancel="state.showHomeTypePicker = false"
          :default-index="
            options.home_type.findIndex((v) => {
              return v.id == form.list.home_type;
            })
          "
        />
      </Popup>
      <!-- 楼盘 -->
      <Popup
        v-model:show="state.showFloorPopup"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <FloorList
          v-if="state.showFloorPopup"
          :id="form.list.floor_id"
          :name="form.list.floor_name"
          @reviewOk="reviewOk"
          @reviewClose="reviewClose"
        />
      </Popup>
      <!-- 单元栋号 -->
      <Popup v-model:show="state.showFloorDetailPicker" position="bottom">
        <Picker
          :columns="state.floor_detail_list"
          :columns-field-names="customFloorFieldName"
          @confirm="(v) => onConfirm(v, 'floor_detail')"
          @cancel="state.showFloorDetailPicker = false"
          :default-index="
            state.floor_detail_list.findIndex((v) => {
              return v.id == form.list.floor_detail_id;
            })
          "
        />
      </Popup>
      <!-- 区域位置 -->
      <Popup v-model:show="state.showPickerCity" position="bottom">
        <Picker
          :columns="options.city_list"
          :columns-field-names="{
            text: 'name',
            value: 'code',
            children: 'sub',
          }"
          @confirm="changePickerCity"
          @cancel="state.showPickerCity = false"
          ref="cityRef"
        />
      </Popup>
      <!-- 购房 年月日 组件 -->
      <Popup v-model:show="state.showBuyDate" position="bottom">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          title="选择时间"
          @confirm="(v) => getTimeFun('buy', v)"
          @cancel="state.showBuyDate = false"
        />
      </Popup>
      <!-- 交房 年月日 组件 -->
      <Popup v-model:show="state.showDeliveryDate" position="bottom">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          title="选择时间"
          @confirm="(v) => getTimeFun('delivery', v)"
          @cancel="state.showDeliveryDate = false"
        />
      </Popup>
      <!-- picker---end -->
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, nextTick } from 'vue';
import moment from 'moment';
import FloorList from '@/components/sale/floorList';
import FileUpload from '@/components/common/FileUpload';

import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
  Popup,
  Picker,
  Icon,
  Dialog,
  Checkbox,
  DatetimePicker,
  NavBar,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const currentDate = ref(new Date());
const state = reactive({
  loadingConfirm: false,
  //-性别
  showSexPicker: false,
  //-客户来源
  showSourcePicker: false,
  // 精装/毛坯
  showCategoryPicker: false,
  // 购房情况
  showHouseTypePicker: false,
  // 房屋结构
  showHomeTypePicker: false,
  //-楼盘
  showFloorPopup: false,
  //-单元栋号
  showFloorDetailPicker: false,
  floor_detail_list: [],
  //-区域位置
  showPickerCity: false,
  city_ids: [0, 0, 0],
  // 购房/交付时间是否未知：1 是 0 否
  known_situation: false,
  //-购房 时间组件
  showBuyDate: false,
  //-购房 时间组件
  showDeliveryDate: false,
});

//@@@picker-------操作---begin---------
//-picker-选择项字段
//--@ 性别、客户来源
const customFieldName = {
  text: 'name',
  value: 'id',
};
//--@ 单元栋号
const customFloorFieldName = {
  text: 'detail_name',
  value: 'id',
};

//--@  确认操作
const onConfirm = (value, type) => {
  if (type == 'gender') {
    if (value) {
      form.list.gender = value.id;
      form.list.gender_name = value.name;
    }
    state.showSexPicker = false;
  } else if (type == 'floor_detail') {
    if (value) {
      form.list.floor_detail_id = value.id;
      form.list.floor_detail_name = value.detail_name;
      form.list.size = value.size;
    }
    state.showFloorDetailPicker = false;
  } else if (type == 'source') {
    if (value) {
      form.list.customer_type = value.id;
      form.list.customer_type_name = value.name;
    }
    state.showSourcePicker = false;
  } else if (type == 'category') {
    if (value) {
      form.list.house_category = value.id;
      form.list.house_category_name = value.name;
    }
    state.showCategoryPicker = false;
  } else if (type == 'house_type') {
    if (value) {
      form.list.house_type = value.id;
      form.list.house_type_name = value.name;
      state.known_situation = false;
      form.list.known_situation = 0;
      form.list.plan_delivery_day = '';
      form.list.plan_buy_day = '';
    }
    state.showHouseTypePicker = false;
  } else if (type == 'home_type') {
    if (value) {
      form.list.home_type = value.id;
      form.list.home_type_name = value.name;
    }
    state.showHomeTypePicker = false;
  }
};

// 楼盘操作
const reviewOk = (v, detail) => {
  form.list.floor_id = v.id;
  form.list.floor_name = v.name;
  form.list.floor_detail_id = '';
  form.list.floor_detail_name = '';
  form.list.size = '';
  form.list.city_id = v.city_id;
  form.list.city_name = v.city_name;
  state.city_ids = v.city_ids;
  state.floor_detail_list = detail.floor_detail_list;
  state.showFloorPopup = false;
};

const reviewClose = () => {
  state.showFloorPopup = false;
};

//- 区域位置
const cityRef = ref(null);
//-省市区 默认值
const getCityFun = () => {
  state.showPickerCity = true;
  nextTick(() => {
    let column1 = options.city_list.findIndex((v) => {
      return v.code == state.city_ids[0];
    });
    let column2 = options.city_list[column1 > -1 ? column1 : 0][
      'sub'
    ].findIndex((v) => {
      return v.code == state.city_ids[1];
    });
    let column3 = options.city_list[column1 > -1 ? column1 : 0]['sub'][
      column2 > -1 ? column2 : 0
    ]['sub'].findIndex((v) => {
      return v.code == state.city_ids[2];
    });
    cityRef.value.setColumnIndex(0, column1 > -1 ? column1 : 0);
    cityRef.value.setColumnIndex(1, column2 > -1 ? column2 : 0);
    cityRef.value.setColumnIndex(2, column3 > -1 ? column3 : 0);
  });
};

const changePickerCity = (value) => {
  state.showPickerCity = false;
  form.list.city_id = value[2]?.code;
  form.list.city_name =
    value && value[0]?.name + '/' + value[1]?.name + '/' + value[2]?.name;
};

//-end

//-购房时间/交房时间check
const changeNotFound = (value) => {
  if (value) {
    form.list.known_situation = 1;
    form.list.plan_delivery_day = '';
    form.list.plan_buy_day = '';
  } else {
    form.list.known_situation = 0;
  }
};

//-交房日期/购房日期
const getTimeFun = (type, value) => {
  if (type == 'delivery') {
    state.showDeliveryDate = false;
    form.list.plan_delivery_day = moment(value).format('YYYY-MM-DD');
  } else {
    state.showBuyDate = false;
    form.list.plan_buy_day = moment(value).format('YYYY-MM-DD');
  }
};
//-picker-------end------------

//-@@获取参数--begin
onBeforeMount(() => {
  loadParams();
});

//-参数值
const options = reactive({});
// -- 公共参数
const loadParams = () => {
  $http.getCustomerParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Object.assign(options, data.customer);
      options.city_list = data.city;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};
//---------end---------

//-@@基本信息表单----------begin------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    name: '',
    gender: '',
    gender_name: '',
    phone1: '',
    phone2: '',
    wechat: '',
    customer_type: '',
    customer_type_name: '',
    recommander: '',
    bpm_id: '',
    size: '',
    address: '',
    city_id: '',
    city_name: '',
    floor_id: '',
    floor_name: '',
    floor_detail_id: '',
    floor_detail_name: '',
    house_category: '',
    house_category_name: '',
    house_type: '',
    house_type_name: '',
    known_situation: 0,
    plan_delivery_day: '',
    plan_buy_day: '',
    home_type: '',
    home_type_name: '',
    remark: '',
    path_arr: '',
  },
});

// 表单验证
// 手机号
const phoneReg = /^(1\d{10})$/;
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
//房屋面积
const houseSizeReg = /^(\d{0,4})(\.(\d{0,2}))?$/; //房屋面积-最多输入4位整数，两位小数
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

const formRules = {
  name: [
    {
      required: true,
      message: '请填写客户姓名',
    },
  ],
  phone1: [
    {
      required: true,
      validator: validatorPhone,
      message: '请填写正确的手机号',
    },
  ],
  customer_type: [
    {
      required: true,
      message: '请选择客户来源',
    },
  ],
  phone2: [
    {
      validator: validatorPhone,
      message: '请填写正确的手机号',
    },
  ],
  floor_id: [
    {
      required: true,
      message: '请选择楼盘',
    },
  ],
  floor_detail_id: [
    {
      required: true,
      message: '请选择单元栋号',
    },
  ],
  size: [
    {
      required: true,
      validator: validatorSize,
      message: '请填写正确的面积',
    },
  ],
  city_id: [
    {
      required: true,
      message: '请选择区域',
    },
  ],
  address: [
    {
      required: true,
      message: '请填写详细地址',
    },
  ],
  house_category: [
    {
      required: true,
      message: '请选择精装/毛坯',
    },
  ],
  plan_buy_day: [
    {
      required: true,
      message: '请选择预计购房时间',
    },
  ],
  plan_delivery_day: [
    {
      required: true,
      message: '请选择预计交房时间',
    },
  ],
};
//-------end---------

//-说明展示-begin
const getContent = () => {
  Dialog.confirm({
    title: '',
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#576B95',
    className: 'dialogDescripton',
    message: `<div class="description"><h3>楼盘信息说明</h3><p>自建房、住房未登记等原因找不到对应信息时，请在楼盘处输入"未找到+选择区域"，或在单元栋号选择"未找到单元栋号"，并完善详细地址。</p></div>`,
    allowHtml: true,
  }).then(() => {});
};
//---------end---------

//-@附件-begin
const getFileList = (file_list) => {
  form.list.path_arr = file_list;
};
//--上传附件end----------

//-保存操作;
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .getCustomerSave({
          ...form.list,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (router.currentRoute.value.query.source) {
                  router.push('/completeTemp?id=' + data.customer_id);
                } else {
                  router.back();
                }
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//-返回 二次确认
const close = () => {
  Dialog.confirm({
    title: '',
    cancelButtonText: '继续编辑',
    confirmButtonText: '仍要退出',
    confirmButtonColor: '#576B95',
    message: `<h3>现在取消，数据将不会保存<h3>`,
    allowHtml: true,
  }).then(() => {
    router.back();
  });
};
//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/sale/customerNewInfo.less';
</style>
