<!--
 * @Author: Jessica
 * @Date: 2022-04-22 16:16:33
 * @LastEditTime: 2024-06-14 11:18:14
 * @Description: 审批操作页面
 * v1.1.0样式修改、新增合同作废审批
 * v1.2.3审批详情交互
 * v1.3.0type=12 申请交付派单 不通过按钮不可点击disabled
 * v1.3.5 smart 全局更改 - 变更设计团队
 * v1.3.6 smart 分配设计中心审批-点击通过-唤起处理提示信息弹窗，点击确认继续操作，展示相关详情
 * v1.4.0 Jessica 新增合同变更金额审批流
 * v1.4.1 smart 派单、改派 派单信息
 * v1.5.1 smart 模块引入-可指派设计师中心
 * v1.5.6 Jessica 添加死单流程附件
 * v1.5.8 smart type==2 分配设计中心，拒绝按钮文案展示
 * v1.6.1 smart 模块引入-跟进情况
-->
<template>
  <NavBar
    safe-area-inset-top
    title="审批"
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
  />
  <div
    class="page-box"
    :class="[
      {
        'pb-80': state.detail.type == 16,
      },
      {
        btn:
          state.detail.status == 1 &&
          state.detail.is_approver_user == 1 &&
          state.is_show_receipt != 0,
      },
      {
        btnBig:
          state.detail.status == 1 &&
          state.detail.is_approver_user == 1 &&
          state.is_show_receipt == 0,
      },
    ]"
  >
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 加载状态 -->
    <Loading class="loading-box" color="#0094ff" v-if="state.loading"></Loading>
    <div class="detail" v-if="!state.loading">
      <!-- 审核状态栏 begin-->
      <div class="check_view">
        <!-- 审批类型 -->
        <div class="type_title">
          {{ state.detail.type_name }}
        </div>
        <!-- 申请信息 -->
        <div class="check_info clearfix h1">
          <img
            class="icon pull-left"
            :src="
              state.detail.operator_user_face_image
                ? state.detail.operator_user_face_image.includes('http')
                  ? state.detail.operator_user_face_image
                  : $host + state.detail.operator_user_face_image
                : require('@/assets/images/icon-empty-img.png')
            "
          />
          <p class="name pull-left">{{ state.detail.operator_user_name }}</p>
          <p class="extra pull-left" v-if="state.detail.operator_user_dep">
            {{
              state.detail.operator_user_dep +
              '/' +
              state.detail.operator_user_pos
            }}
          </p>

          <img
            class="statusIcon"
            :src="require('@/assets/images/pass.png')"
            alt="审核通过"
            v-if="state.detail.status == 2"
          />
          <img
            class="statusIcon"
            :src="require('@/assets/images/rejust.png')"
            alt="审核拒绝"
            v-if="state.detail.status == 3"
          />
          <img
            class="statusIcon"
            :src="require('@/assets/images/wait.png')"
            alt="待审核"
            v-if="state.detail.status == 1"
          />
          <img
            class="statusIcon"
            :src="require('@/assets/images/cancle.png')"
            alt="已取消"
            v-if="state.detail.status == 4"
          />
        </div>
        <!-- 审批信息 -->
        <div
          class="check_view"
          v-if="state.detail.status == 2 || state.detail.status == 3"
        >
          <h3 :class="{ status3: state.detail.status == 3 }">
            {{ state.detail.status == 2 ? '审批通过' : '审批不通过' }}
          </h3>
          <ol
            class="ol-flex"
            v-if="
              state.detail.type == 1 ||
              state.detail.type == 2 ||
              state.detail.type == 15 ||
              state.detail.type == 21 ||
              state.detail.type == 12
            "
          >
            <li v-if="state.detail.type == 2 && state.detail.status == 2">
              <label>接单客户经理：</label>
              <span class="c1">
                {{ state.detail.customer_manager_name || '-' }}
              </span>
            </li>
            <li v-if="state.detail.type == 2 && state.detail.status == 2">
              <label>接单设计师：</label>
              <span class="c1">{{ state.detail.design_name || '-' }}</span>
            </li>
            <li v-if="state.detail.type == 1 && state.detail.status == 2">
              <label>派单设计中心：</label>
              <span class="c1">
                {{ state.detail.design_center_name || '-' }}
              </span>
            </li>
            <li v-if="state.detail.type == 15 && state.detail.status == 2">
              <label>派单设计中心：</label>
              <span class="c1">
                {{ state.detail.new_design_center_name || '-' }}
              </span>
            </li>
            <!--v1.3.3 申请精装工地/交付派单 begin -->
            <li v-if="state.detail.type == 21 || state.detail.type == 12">
              <label>交付中心：</label>
              <span class="c1">
                {{ state.detail.next_ids.deliver_center_name || '-' }}
              </span>
            </li>
            <!-- 申请精装工地 end -->
            <li>
              <label>审核信息：</label>
              <span class="c1">{{ state.detail.approver_remark || '-' }}</span>
            </li>
          </ol>
          <ol class="ol-flex" v-else-if="state.detail.type == 17">
            <li v-if="state.meetInfo.approve_type == 2">
              <label>回单原因：</label>
              <span class="c1">
                {{ state.meetInfo.receipt_cause || '-' }}
              </span>
            </li>
            <li>
              <label>备注：</label>
              <span class="c1">{{ state.detail.approver_remark || '-' }}</span>
            </li>
          </ol>
          <ol class="ol-flex" v-else>
            <li>
              <label>备注：</label>
              <span class="c1">{{ state.detail.approver_remark || '-' }}</span>
            </li>
          </ol>
        </div>
      </div>
      <!-- 审核状态栏 end-->

      <!-- 审核详情 begin-->
      <div class="apply_info">
        <h3>申请详情</h3>
        <!--v1.5.3 申请精装工地/交付派单审核 显示是否B+、是否返单 -->
        <div
          class="display_flex"
          v-if="state.detail.type == 21 || state.detail.type == 12"
        >
          <div v-if="state.detail.is_b == 1" class="status_tag green mt_15">
            B+项目
          </div>
          <div
            v-if="state.detail.is_return == 1"
            class="status_tag green ml_5 mt_15"
          >
            工地返单
          </div>
        </div>
        <!--  end -->
        <!-- 1申请设计派单/申请设计改派/分配设计中心/见面回单  begin-->
        <div
          v-if="
            state.detail.type == 1 ||
            state.detail.type == 2 ||
            state.detail.type == 15 ||
            state.detail.type == 17
          "
        >
          <!-- 申请设计派单/改派申请详情 begin-->
          <div
            class="formBase designReassignment formCheck"
            v-if="
              state.detail.type == 15 ||
              state.detail.type == 1 ||
              state.detail.type == 2
            "
            style="padding-top: 15px"
          >
            <!-- 设计改派 begin-->
            <div class="cellInfo" v-if="state.detail.type == 15">
              <div
                class="item"
                v-for="(val, key) in state.detail.info_list"
                :key="key"
              >
                <div class="left">{{ key }}</div>
                <span class="right">{{ val || '-' }}</span>
              </div>
            </div>
            <!-- 设计改派 end-->
            <Form
              ref="ruleFormRef"
              class="form_main"
              :disabled="
                state.detail.status == 1 &&
                state.detail.is_approver_user == 1 &&
                state.detail.type != 2
                  ? false
                  : true
              "
            >
              <!-- 设计改派 begin-->
              <template v-if="state.detail.type == 15">
                <CellGroup class="form_cell">
                  <div class="cell">
                    <label class="form_title">
                      <span style="color: #ea3d2f">*</span>
                      变更原因
                    </label>
                    <Field
                      v-model="form.list.reassignment_reason_name"
                      right-icon="arrow-down"
                      readonly
                      name="picker"
                      placeholder="请选择变更原因"
                      :disabled="true"
                    />
                  </div>
                  <div class="cell">
                    <label class="form_title">备注</label>
                    <Field
                      v-model="form.list.reassignment_remark"
                      rows="3"
                      autosize
                      type="textarea"
                      maxlength="100"
                      placeholder=""
                      :disabled="true"
                    />
                  </div>
                </CellGroup>
                <template
                  v-if="
                    state.detail.contract_progress &&
                    state.contract_progress.length > 0
                  "
                >
                  <div class="design_list">
                    <div class="title">变更后设计进度</div>
                    <div
                      class="list"
                      v-for="(item, index) in state.contract_progress"
                      :key="index"
                    >
                      <div class="item">
                        <div class="left">合同编号：</div>
                        <div class="right">
                          {{ item.contract_no ? item.contract_no : '-' }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="left">合同状态：</div>
                        <div class="right">
                          {{
                            item.contract_status_name
                              ? item.contract_status_name
                              : '-'
                          }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="left">设计编号：</div>
                        <div class="right">
                          {{ item.design_no ? item.design_no : '-' }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="left">目前进度：</div>
                        <div class="right">
                          {{ item.progress_name ? item.progress_name : '-' }}
                        </div>
                      </div>
                      <div v-if="item.design_no">
                        <div class="info">变更后阶段调整</div>
                        <Field
                          v-model="item.design_reassignment_stage_name"
                          :right-icon="
                            state.detail.status == 1 &&
                            state.detail.is_approver_user == 1
                              ? 'arrow-down'
                              : ''
                          "
                          readonly
                          name="picker"
                          placeholder=" "
                          @click="selectNodeClick(item, index, 'node')"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <!-- 设计改派 end-->
              <CellGroup class="form_cell">
                <div class="cell">
                  <label class="form_title">
                    <span style="color: #ea3d2f">*</span>
                    预计见面时间
                  </label>
                  <Field
                    v-model="form.list.face_time"
                    right-icon="underway-o"
                    readonly
                    name="picker"
                    placeholder="请选择预计见面时间"
                    :rules="[
                      {
                        required: true,
                        message: '请选择预计见面时间',
                      },
                    ]"
                    @click="
                      () => {
                        if (
                          state.detail.status == 1 &&
                          state.detail.is_approver_user == 1 &&
                          state.detail.type != 2
                        ) {
                          openDateType('face_time');
                        }
                      }
                    "
                  />
                </div>
                <div class="cell">
                  <label class="form_title">
                    <span style="color: #ea3d2f">*</span>
                    见面方式
                  </label>
                  <Field
                    v-model="form.list.face_mode_name"
                    right-icon="arrow-down"
                    readonly
                    name="picker"
                    :rules="[
                      {
                        required: true,
                        message: '请选择见面方式',
                      },
                    ]"
                    placeholder="请选择见面方式"
                    @click="
                      () => {
                        if (
                          state.detail.status == 1 &&
                          state.detail.is_approver_user == 1 &&
                          state.detail.type != 2
                        ) {
                          state.showPicker_face_mode = true;
                        }
                      }
                    "
                  />
                </div>
                <div class="cell">
                  <label class="form_title">
                    <span style="color: #ea3d2f">*</span>
                    派单级别
                  </label>
                  <Field
                    v-model="form.list.dispatch_level_name"
                    right-icon="arrow-down"
                    readonly
                    name="picker"
                    :rules="[
                      {
                        required: true,
                        message: '请选择派单级别',
                      },
                    ]"
                    placeholder="请选择派单级别"
                    @click="
                      () => {
                        if (
                          state.detail.status == 1 &&
                          state.detail.is_approver_user == 1 &&
                          state.detail.type != 2
                        ) {
                          state.showPicker_dispatch_level = true;
                        }
                      }
                    "
                  />
                </div>
                <div class="cell">
                  <label
                    class="form_title"
                    v-if="state.detail.type == 1 || state.detail.type == 15"
                  >
                    派单备注
                    <span
                      style="color: #f37020; font-size: 13px"
                      v-if="state.detail?.is_design_center_manager != 1"
                    >
                      设计中心接单前可见
                    </span>
                  </label>
                  <label class="form_title" v-if="state.detail.type == 2">
                    派单备注
                    <span
                      style="color: #f37020; font-size: 13px"
                      v-if="
                        state?.detail?.operator_user_id == state?.ACCOUNT_ID ||
                        state?.detail?.is_approver_user != 1
                      "
                    >
                      设计中心接单前可见
                    </span>
                  </label>
                  <Field
                    v-model="form.list.remark"
                    rows="3"
                    autosize
                    type="textarea"
                    maxlength="100"
                    :placeholder="
                      state.detail.status == 1 &&
                      state.detail.is_approver_user == 1 &&
                      state.detail.type != 2
                        ? '此备注设计中心可见'
                        : ''
                    "
                    show-word-limit
                  />
                </div>
                <!-- v1.6.1 跟进情况 -->
                <TempSendFollow
                  v-if="state.detail?.is_show_appoint_designer_info == 1"
                  :customerID="state?.customerObj?.customer_id"
                  :taskID="state?.task_id"
                />
                <!-- v1.5.1 可指派设计师中心 -->
                <TempAppointDesigner
                  v-if="state.detail?.is_show_appoint_designer_info == 1"
                  :customerID="state?.customerObj?.customer_id"
                  :taskID="state?.task_id"
                />
                <!-- end -->
              </CellGroup>
            </Form>
            <!-- v1.4.1 派单信息 展示 -->
            <!-- 1、申请设计派单(详情) -->
            <div v-if="state.detail.type == 1">
              <div v-if="state?.detail?.is_design_center_manager == 1">
                <!-- 中心经理 -->
                <TempSendFormLook
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="state.detail.is_design_center_manager"
                  v-if="state.detail.status != 3"
                />
              </div>
              <div v-else>
                <!-- 非中心经理 -->
                <TempSendForm
                  ref="TempSendFormRef"
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="state.detail.is_design_center_manager"
                  :data="form.list"
                  v-if="
                    state?.detail?.status == 1 &&
                    state.detail.is_approver_user == 1
                  "
                  :cityList="state.city_list"
                />
                <TempSendFormLook
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="state.detail.is_design_center_manager"
                  v-else
                />
              </div>
            </div>
            <!-- 2、分配设计中心 -->
            <div v-if="state.detail.type == 2">
              <!-- 判断独特，偶尔客户经理也可以操作 -->
              <!-- 展示 -->
              <div v-if="state?.detail?.operator_user_id == state?.ACCOUNT_ID">
                <TempSendFormLook
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="0"
                />
              </div>
              <div v-if="state?.detail?.is_approver_user == 1">
                <TempSendFormLook
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="1"
                  v-if="
                    state?.detail?.status == 1
                      ? state?.tip_button_disabled
                      : state?.detail?.status == 2
                  "
                />
              </div>
            </div>
            <!-- 15、变更设计团队 -->
            <div v-if="state.detail.type == 15">
              <div v-if="state?.detail?.is_design_center_manager == 1">
                <!-- 中心经理 -->
                <TempSendFormLook
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="state.detail.is_design_center_manager"
                  v-if="state.detail.status != 3"
                />
              </div>
              <div v-else>
                <!-- 非中心经理 -->
                <TempSendForm
                  ref="TempSendFormRef"
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="state.detail.is_design_center_manager"
                  :data="form.list"
                  v-if="
                    state.detail?.status == 1 &&
                    state.detail?.is_approver_user == 1
                  "
                  :cityList="state.city_list"
                />
                <TempSendFormLook
                  :customerObj="state?.customerObj"
                  :isDesignCenterManager="state.detail.is_design_center_manager"
                  v-else
                />
              </div>
            </div>
          </div>
          <!-- 申请设计改派申请详情 end-->
          <!-- 见面回单 详情 -->
          <div v-if="state.detail.type == 17" class="mt_10">
            <followCheckInfo :data="state.meetInfo"></followCheckInfo>
          </div>
          <!--见面回单 end -->
        </div>

        <!-- 通用模板、交付派单审核、设计改派提醒、申请死单 begin-->
        <div class="content" v-else>
          <!-- 渠道费申请审核 begin -->
          <ol class="ol-flex" v-if="state.detail.type == 30">
            <li>
              <label class="col1">渠道费金额：</label>
              <span class="c1 col1">
                {{
                  state.detail?.contents?.channel_fee ||
                  state.detail.contents.channel_fee === 0
                    ? '¥' + state.detail?.contents?.channel_fee
                    : '-'
                }}
              </span>
            </li>
            <li>
              <label class="col1">税负：</label>
              <span class="c1 col1">
                {{
                  state.detail?.contents?.channel_tax_fee ||
                  state.detail.contents.channel_tax_fee === 0
                    ? '¥' + state.detail?.contents?.channel_tax_fee
                    : '-'
                }}
              </span>
            </li>
            <li>
              <label class="col1">总计支付金额：</label>
              <span class="c1 col2">
                {{
                  state.detail?.contents?.channel_pay_fee ||
                  state.detail.contents.channel_pay_fee === 0
                    ? '¥' + state.detail?.contents?.channel_pay_fee
                    : '-'
                }}
              </span>
            </li>
          </ol>
          <!-- 渠道费申请审核 end -->
          <ol class="ol-flex">
            <li v-for="(val, key) in state.detail.info_list" :key="key">
              <label>{{ key }}</label>
              <span class="c1">{{ val || '-' }}</span>
            </li>
            <!-- <li
              v-if="state.detail.type == 10 && state.detail.contents.file_url"
            >
              <label>附件：</label>
              <span class="a-files c1" @click="lookFile()">
                查看全部{{ state.detail.contents.file_url.length }}个附件>
              </span>
            </li> -->
            <li v-if="state.detail.type == 13 && state.detail.file_urls">
              <label>附件：</label>
              <span class="a-files c1" @click="lookFile()">
                查看全部{{ state.detail.file_urls.length }}个附件>
              </span>
            </li>
            <!-- 合同作废详情附件 -->
            <li v-if="state.detail.type == 14 && state.detail.files">
              <label>附件：</label>
              <span class="a-files c1" @click="lookFile()">
                查看全部{{ state.detail.files.length }}个附件>
              </span>
            </li>
          </ol>
          <p class="a-look" v-if="state.detail.type == 4">
            <router-link
              :to="'/contractChangeView?gId=' + state.detail.item_id"
            >
              查看合同增减项详情>
            </router-link>
          </p>
          <!-- 申请死单添加附件 -->
          <div
            v-if="
              state.detail.type == 3 &&
              state.detail?.contents?.files &&
              state.detail?.contents?.files.length > 0
            "
          >
            <p style="color: #999; font-size: 14px">附件（上传现场照片）：</p>
            <div style="padding: 6px 0 0">
              <FileGrid :data="state.detail?.contents?.files"></FileGrid>
            </div>
          </div>
        </div>
        <!-- 通用模板、交付派单审核、设计改派提醒 end-->

        <!-- 分摊业绩审核 begin -->
        <div
          v-if="state.detail.type == 29"
          class="content"
          style="border-top: 1px solid #ededed"
        >
          <ol class="ol-flex">
            <li>
              <label>分摊部门：</label>
              <span class="c1">
                {{ state.detail?.contents?.split_department_name || '-' }}
              </span>
            </li>
            <li>
              <label>协议：</label>
              <span class="c1">
                {{ state.detail?.contents?.protocol_val || '-' }}
              </span>
            </li>
            <li>
              <label>产品业绩：</label>
              <span class="c1">
                {{ state.detail?.contents?.product_val || '-' }}
              </span>
            </li>
            <li>
              <label>施工业绩：</label>
              <span class="c1">
                {{ state.detail?.contents?.construction_val || '-' }}
              </span>
            </li>
          </ol>
        </div>
        <!-- 分摊业绩审核 end -->

        <!-- 渠道费申请审核 begin -->
        <div
          v-if="state.detail.type == 30"
          class="content"
          style="border-top: 1px solid #ededed"
        >
          <ol class="ol-flex">
            <li>
              <label>收款人：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_user || '-' }}
              </span>
            </li>
            <li>
              <label>联系方式：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_contact || '-' }}
              </span>
            </li>
            <li>
              <label>银行账号：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_bank_account || '-' }}
              </span>
            </li>
            <li>
              <label>身份证号：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_card_no || '-' }}
              </span>
            </li>
            <li>
              <label>银行名称：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_bank_name || '-' }}
              </span>
            </li>
            <li>
              <label>银行支行名称：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_bank_branch || '-' }}
              </span>
            </li>
            <li>
              <label>联行号：</label>
              <span class="c1">
                {{ state.detail?.contents?.collect_bank_branch_code || '-' }}
              </span>
            </li>
            <li>
              <label>备注说明：</label>
              <span class="c1">
                {{ state.detail?.contents?.remark || '-' }}
              </span>
            </li>
          </ol>
        </div>
        <!-- 渠道费申请审核 end -->
      </div>
      <!--33 施工图纸确认详情 -->
      <div v-if="state.detail.type == 33">
        <div
          v-for="(item, index) in state.detail.detail_list"
          :key="index"
          class="p_20 mb_10 white_bg"
        >
          <div class="font_size_15 font_bold mb_20">
            {{
              item.type == 3
                ? '审核项-施工图'
                : item.type == 4
                ? '审核项-施报价(含水电)'
                : item.type == 5
                ? '审核项-设备电图纸'
                : ''
            }}
          </div>
          <p class="font_size_13 grey_color">提交人：</p>
          <p class="font_size_15 mb_10">
            {{ item.operator_name || '-' }}
            <span>{{ item.post_name ? ' / ' + item.post_name : '' }}</span>
          </p>
          <p class="font_size_13 grey_color">提交文件：</p>
          <div class="mb_10">
            <FileShow :data="item.attach"></FileShow>
          </div>

          <p class="font_size_13 grey_color">上传时间：</p>
          <p class="font_size_15">{{ item.upload_time || '-' }}</p>
        </div>
      </div>
      <!-- 审核详情 end-->

      <!-- 人物审核线 begin -->
      <div class="linker_view">
        <h3>流程</h3>
        <div class="h2">
          <div
            v-for="(item, index) in state.detail.apply_user_list"
            :key="index"
          >
            <div class="clearfix">
              <div class="pull-left iconBox">
                <Image
                  round
                  class="icon"
                  :src="
                    item.face_image
                      ? item.face_image.includes('http')
                        ? item.face_image
                        : $host + item.face_image
                      : require('@/assets/images/icon-empty-img.png')
                  "
                />

                <Icon
                  name="checked"
                  class="view-icon pass"
                  v-if="item.status == 2"
                />
                <Icon
                  name="more"
                  class="view-icon wait"
                  v-if="item.status == 1"
                />
                <Icon
                  name="clear"
                  class="view-icon cancel"
                  v-if="item.status == 3 || item.status == 4"
                />
              </div>

              <div class="pull-left infoBox">
                <p class="name">
                  {{ item.name }}
                </p>
                <p class="extra" v-if="item.dep_name">
                  {{ item.dep_name + '/' + item.pos_name }}
                </p>
                <p
                  class="status"
                  :class="{ status1: state.detail.status == 4 }"
                  v-if="index == 0"
                >
                  {{ state.detail.status == 4 ? '已取消' : '发起申请' }}
                </p>
                <p class="status status2" v-else-if="item.status == 1">
                  审批中...
                </p>
                <p class="status" v-else-if="item.status == 2">已审批</p>
                <p class="status status3" v-else-if="item.status == 3">
                  已拒绝
                </p>
              </div>

              <span class="time">{{ item.add_time }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 人物审核线 end -->

      <!--审批操作 begin-->
      <div
        class="operateNew"
        v-if="state.detail.status == 1 && state.detail.is_approver_user == 1"
      >
        <!-- 见面回单-只能接单时候特殊处理 -->
        <div v-if="state.detail.type == 17 && state.is_show_receipt == 0">
          <div class="btn_tip">
            注意：当前时间已超过客户确认见面24小时，无法发起回单
          </div>
          <Button
            type="primary"
            size="large"
            class="button-state"
            @click="btnCheckSumbit(state.detail.type)"
          >
            <Icon name="success" />
            接单
          </Button>
        </div>
        <Row v-else-if="state.detail.type == 12">
          <Col span="24">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnCheckSumbit(state.detail.type)"
            >
              <Icon name="success" />
              通过
            </Button>
          </Col>
        </Row>
        <!-- 申请精装工地 -->
        <Row v-else-if="state.detail.type == 21">
          <Col span="24">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnCheckSumbit(state.detail.type)"
            >
              <Icon name="success" />
              通过
            </Button>
          </Col>
        </Row>
        <!-- 分配设计中心 -->
        <Row v-else-if="state.detail.type == 2">
          <Col span="11">
            <Button
              type="danger"
              size="large"
              class="button-danger"
              @click="btnCheckRejust(state.detail.type)"
              :disabled="state?.tip_button_disabled"
            >
              <Icon name="cross" v-if="!state?.tip_button_disabled" />
              <span
                style="font-size: 13px"
                v-if="state.tipInfo?.receiving_time"
              >
                {{
                  '已于 ' +
                  state.tipInfo?.receiving_time?.split(' ')[1] +
                  ' 确认接单'
                }}
              </span>
              <span v-else>不通过</span>
            </Button>
          </Col>
          <Col span="2"></Col>
          <Col span="11">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnCheckSumbit(state.detail.type)"
            >
              <Icon name="success" />
              {{ '通过' }}
            </Button>
          </Col>
        </Row>
        <!-- else -->
        <Row v-else>
          <Col span="11">
            <Button
              type="danger"
              size="large"
              class="button-danger"
              @click="btnCheckRejust(state.detail.type)"
              :disabled="
                state.detail.type == 12 ||
                (state.detail.type == 2 ? state?.tip_button_disabled : false)
              "
            >
              <Icon name="cross" />
              {{
                state.detail.type == 16
                  ? '不改派'
                  : state.detail.type == 15
                  ? '不变更'
                  : state.detail.type == 17
                  ? '回单'
                  : '不通过'
              }}
            </Button>
          </Col>
          <Col span="2"></Col>
          <Col span="11">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnCheckSumbit(state.detail.type)"
            >
              <Icon name="success" />
              {{
                state.detail.type == 16
                  ? '改派'
                  : state.detail.type == 15
                  ? '变更'
                  : state.detail.type == 17
                  ? '接单'
                  : '通过'
              }}
            </Button>
          </Col>
        </Row>
      </div>
      <!--审批操作: 通用模板、交付派单审核、设计改派提醒  end-->

      <!-- 分配设计中心审核 Begin-->
      <!-- <div class="formBase formCheck" v-if="state.detail.type == 2">
          <Form ref="ruleFormRef">
            <CellGroup>
              <div class="cell">
                <label class="form_title">主创设计师</label>
                <Field
                  v-model="form.list.desginer_name"
                  right-icon="arrow-down"
                  readonly
                  name="picker"
                  placeholder="请选择主创设计师"
                  @click="state.showPicker_desginer = true"
                  :rules="[
                    {
                      required: true,
                      message: '请选择主创设计师',
                    },
                  ]"
                />
              </div>
              <div class="cell">
                <label class="form_title">客户经理</label>
                <Field
                  v-model="form.list.manager_name"
                  right-icon="arrow-down"
                  readonly
                  name="picker"
                  placeholder="请选择客户经理"
                  @click="state.showPicker_manager = true"
                  :rules="[
                    {
                      required: true,
                      message: '请选择客户经理',
                    },
                  ]"
                />
              </div>
            </CellGroup>
          </Form>
        </div> -->
      <!-- 分配设计中心审核 end-->
    </div>

    <!--picker-选项-->

    <!-- 全部附件 begin-->
    <FileAll
      ref="fileAll"
      :data="
        state.detail.type == 13
          ? state.detail.file_urls
          : state.detail.type == 10
          ? state.detail.contents.file_url
          : state.detail.files
      "
      :isShow="showAllFile"
      @closePop="
        () => {
          showAllFile = false;
        }
      "
    ></FileAll>
    <!-- 全部附件 end-->

    <!-- 设计改派/派单相关---begin --->

    <!--picker 设计改派后阶段 begin--->
    <Popup
      v-model:show="state.showPicker_designNode_reassignment"
      position="bottom"
    >
      <Picker
        :columns="state.pickerItem_reassignment.design_reassignment_stage"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'designerNode_reassignment')"
        @cancel="state.showPicker_designNode_reassignment = false"
      />
    </Popup>
    <!--picker 设计改派后阶段 end--->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="state.minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>

    <!--picker -见面方式-->
    <Popup v-model:show="state.showPicker_face_mode" position="bottom">
      <Picker
        :columns="state.face_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'face_mode')"
        @cancel="state.showPicker_face_mode = false"
      />
    </Popup>
    <!--picker 派单级别-->
    <Popup v-model:show="state.showPicker_dispatch_level" position="bottom">
      <Picker
        :columns="state.dispatch_level_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_level')"
        @cancel="state.showPicker_dispatch_level = false"
      />
    </Popup>
    <!-- 设计改派相关---end -->

    <!-- 设计改派提醒 操作面板 -->
    <ActionSheet class="actionSheet" v-model:show="state.actionSheetData.show">
      <!-- 设计改派提醒 -->
      <desginChangeTipTemp
        :data="state.actionSheetData"
        :title="state.detail.type_name"
        :id="state.task_id"
        v-if="state.actionSheetData.show"
      />
    </ActionSheet>

    <!-- 通用审核/审核不通过 操作面板-->
    <ActionSheet class="actionSheet" v-model:show="state.commonSheetData.show">
      <!-- 通用审核/审核不通过 -->
      <commonTemp
        :data="state.commonSheetData"
        :title="state.detail.type_name"
        :id="state.task_id"
        :type="state.task_type"
        v-if="state.commonSheetData.show"
      />
    </ActionSheet>

    <!-- 分派交付中心审核 操作面板-->
    <ActionSheet class="actionSheet" v-model:show="state.deliverSheetData.show">
      <!-- 审核通过 -->
      <deliverTemp
        :data="state.deliverSheetData"
        :title="state.detail.type_name"
        :id="state.task_id"
        :type="state.task_type"
        v-if="state.deliverSheetData.show"
      />
    </ActionSheet>
    <!-- end -->
    <!-- 设计派单/改派审核 操作面板-->
    <ActionSheet
      class="actionSheet"
      v-model:show="state.designDispatchData.show"
    >
      <!-- 审核通过 -->
      <designDispatchTemp
        :data="state.designDispatchData"
        :title="state.detail.type_name"
        :id="state.task_id"
        :type="state.task_type"
        :customerObj="state.customerObj"
        @sendForm="sendForm"
        v-if="state.designDispatchData.show"
      />
    </ActionSheet>
    <!-- end -->
    <!-- 分派设计中心审核 操作面板-->
    <ActionSheet class="actionSheet" v-model:show="state.designCenterData.show">
      <!-- 审核通过 -->
      <designCenterTemp
        :data="state.designCenterData"
        :tipInfo="state.tipInfo"
        :isDesignCenterManager="state.detail.is_design_center_manager"
        :title="state.detail.type_name"
        :id="state.task_id"
        :dispatchId="state.detail.dispatch_id"
        :type="state.task_type"
        v-if="state.designCenterData.show"
      />
    </ActionSheet>
    <!-- end -->
    <!-- 见面回单审核 操作面板-->
    <ActionSheet class="actionSheet" v-model:show="state.meetData.show">
      <!-- 审核通过 -->
      <meetTemp
        :data="state.meetData"
        :title="state.detail.type_name"
        :id="state.task_id"
        :type="state.task_type"
        v-if="state.meetData.show"
      />
    </ActionSheet>
    <!-- end -->
  </div>
</template>

<script setup>
import { reactive, inject, onMounted, onBeforeMount, ref } from 'vue';
import {
  Image,
  Field,
  Picker,
  Popup,
  Row,
  Col,
  Icon,
  Button,
  Toast,
  Loading,
  Form,
  Overlay,
  NavBar,
  CellGroup,
  ActionSheet,
  DatetimePicker,
  Dialog,
} from 'vant';
import moment from 'moment';

import { useRouter } from 'vue-router';
import FileAll from '@/components/common/FileAll';
import desginChangeTipTemp from '@/components/task/desginChangeTipTemp';
import commonTemp from '@/components/task/commonTemp';
import deliverTemp from '@/components/task/deliverTemp';
import designDispatchTemp from '@/components/task/designDispatchTemp';
import designCenterTemp from '@/components/task/designCenterTemp';
import meetTemp from '@/components/task/meetTemp';
import TempSendFormLook from '@/components/moreOperate/TempSendFormLook'; //v1.4.1 派单信息 展示
import TempSendForm from '@/components/moreOperate/TempSendForm.vue'; //v1.4.1 派单信息 表单
import followCheckInfo from '@/components/moreOperate/followCheckInfo.vue'; //v1.4.3 跟进详情
import TempAppointDesigner from '@/components/moreOperate/TempAppointDesigner.vue';
import TempSendFollow from '@/components/moreOperate/TempSendFollow.vue';
import FileShow from '@/components/common/FileShow';
import FileGrid from '@/components/common/FileGrid';
const $host = inject('$host');
let ACCOUNT_ID = localStorage.getItem('ACCOUNT_ID');
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  ACCOUNT_ID: localStorage.getItem('ACCOUNT_ID'),
  //-tabs
  curTab: router.currentRoute.value.query.curTab
    ? parseInt(router.currentRoute.value.query.curTab)
    : 1,
  task_id: router.currentRoute.value.query.id,
  task_type: '', //-审核类型
  loading: false,
  loadingConfirm: false,
  detail: {
    is_show_appoint_designer_info: 1, //1 显示 2不显示(针对于跟进情况模版、可指派设计中心模版)
  },

  //-申请设计改派/申请设计派单
  contract_progress: [],
  customer_info: [],
  design_center_reassignment: [],
  showPicker_desginer_reassignment: false,
  showPicker_designNode_reassignment: false,
  pickerItem_reassignment: '',
  pickerIndex_reassignment: null,
  showPicker_face_mode: false,
  showPicker_dispatch_level: false,
  showTimePicker: false,
  currentDate: new Date(),
  face_mode_list: [],
  dispatch_level_list: [],
  city_list: [], //省市区

  //见面回单
  is_show_receipt: 1,
  meetInfo: {},

  //-end

  //---设计提醒审核面板----
  actionSheetData: {
    show: false,
    checkStaus: '', //-操作状态-只有ok和rejust两种值
    manager: '',
    manager_name: '',
    desginer: '',
    desginer_name: '',
  },
  //-通用模板审核通过/审核不通过面板
  commonSheetData: {
    show: false,
    checkStaus: '', //-操作状态-只有ok和rejust两种值
  },
  //-分派交付中心审核通过面板
  deliverSheetData: {
    show: false,
    checkStaus: '', //-操作状态-只有ok和rejust两种值
    deliver: '', //-交付中心id
    deliver_name: '', //-交付中心name
    customer_id: '', //-客户Id
    info: {},
  },
  //-设计派单/改派审核面板
  designDispatchData: {
    show: false,
    checkStaus: '', //-操作状态-只有ok和rejust两种值
    type: '',
    formInfo: {},
    dispatch_mode_list: [], //派单方式
    design_center_list: [], //排除设计中心
    reject_design_center_switch: '', //是否开启-排除设计中心选项
    default_design_id: '', //v1.5.1 默认设计师id
    default_design_center_id: '', //v1.5.1 默认设计中心
    default_design_center_name: '',
    default_dispatch_model: '', //v1.5.1 默认派单方式
    default_dispatch_model_name: '',
    disable_dispatch_model: [], //v1.5.1禁用的派单方式
  },
  //-分派设计中心审核面板
  designCenterData: {
    show: false,
    checkStaus: '', //-操作状态-只有ok和rejust两种值
    type: '',
    formInfo: {},
  },
  //-见面回单审核面板
  meetData: {
    show: false,
    checkStaus: '', //-操作状态-只有ok和rejust两种值
    type: '',
    remark: '',
    receipt_cause: '', //回单原因name
    receipt_cause_type: '', //回单原因id
    receipt_cause_type_list: '', //回单原因 - 动态下拉list
  },
  //-获取设计中心经理审批点击接单展示信息及操作记录
  tipInfo: {},
  tip_button_disabled: false,
  customerObj: {
    customer_id: '',
    customer_name: '',
  },
});

onMounted(() => {
  getDetail();
});

//-获取详情数据
const getDetail = () => {
  state.loading = true;
  $http
    .getTaskApplyInfo({
      id: state.task_id,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.detail = res.data;
        state.customerObj.customer_id = res.data.customer_id;
        state.customerObj.customer_name = res.data.customer_name;
        state.detail?.type === 2 &&
          ACCOUNT_ID == state.detail?.approver_user_id &&
          state.detail?.status == 1 &&
          getCenterInfo();
        if (res.data.type == 1 || res.data.type == 15 || res.data.type == 2) {
          //申请设计派单/改派/分配设计中心回显
          form.list.face_time = state.detail.face_time;
          form.list.face_mode = state.detail.face_mode;
          form.list.face_mode_name = state.detail.face_mode_name;
          form.list.dispatch_level = state.detail.dispatch_level;
          form.list.dispatch_level_name = state.detail.dispatch_level_name;
          form.list.remark = state.detail.operator_remark;
          form.list.design_id = state.detail.default_design_id;
          form.list.design_center_id = state.detail.default_design_center_id;
          form.list.design_center_name =
            state.detail.default_design_center_name;
          designReassignmentParam();
          getSelectDesignParam();
        }
        if (
          state.detail.type == 15 ||
          (state.detail.type == 2 && state.detail.source_type == 15)
        ) {
          //申请设计改派参数
          if (state.detail.type == 2 && state.detail.source_type == 15) {
            form.list.remark = res.data.operator_remark;
          } else {
            form.list.remark = res.data.dispatch_remark;
          }

          form.list.reassignment_reason = res.data.redispatch_reason;
          form.list.reassignment_reason_name = res.data.redispatch_reason_name;
          form.list.reassignment_remark = res.data.operator_remark;
        }

        if (state.detail.type == 17) {
          //获取确认见面详情
          getConfirmMeetingInfo();
        }
      } else {
        Toast(res.data);
      }
    });
};

//-申请设计改派参数- 待修改
const designReassignmentParam = async () => {
  await $http
    .designReassignmentParam({ customer_id: state.detail.customer_id })
    .then((res) => {
      if (res.code === 0) {
        state.customer_info = res.data.customer_info;
        state.contract_progress = res.data.contract_progress;
        state.design_center_reassignment = res.data.design_center;

        //改派后阶段调整
        if (state.contract_progress && state.contract_progress.length > 0) {
          let designList = [];
          state.contract_progress.forEach((item) => {
            if (item.design_no) {
              if (
                state.detail.contract_progress &&
                state.detail.contract_progress.length > 0
              ) {
                item.design_reassignment_stage_name =
                  state.detail.contract_progress.find((val) => {
                    return item.design_no == val.design_no;
                  })?.design_reassignment_stage_name;
                item.design_reassignment_stage_id =
                  state.detail.contract_progress.find((val) => {
                    return item.design_no == val.design_no;
                  })?.design_reassignment_stage;
                designList.push({
                  design_id: item.design_id,
                  design_reassignment_stage: item.design_reassignment_stage_id,
                });
              }
            }
          });
          form.list.designs = designList;
        }
      } else {
        Toast(res.data);
      }
    });
};
//-获取确认见面详情
const getConfirmMeetingInfo = () => {
  state.loading = true;
  $http
    .getConfirmMeetingInfo({
      id: state.task_id,
      type: 2,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.is_show_receipt = res.data.is_show_receipt;
        state.meetInfo = res.data;

        form.list.remark = state.meetInfo.remark;
        form.list.receipt_cause = state.meetInfo.receipt_cause;
        form.list.receipt_cause_type = state.meetInfo.receipt_cause_type;
        form.list.receipt_cause_type_list =
          state.meetInfo.receipt_cause_type_list;
      }
    });
};

//-获取设计中心经理审批点击接单展示信息及操作记录
const getCenterInfo = (type) => {
  return new Promise((resolve, reject) => {
    $http
      .designCenterReceivingOrder({ task_id: state.task_id, type })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          console.log('????v1.5.8', data);
          state.tipInfo = data;
          state.tip_button_disabled = data?.receiving_time ? true : false;
          resolve(data);
        } else {
          state.tipInfo = {};
          state.tip_button_disabled = false;
          Toast(res.data);
          reject('no');
        }
      });
  });
};

//-v1.5.1 动态派单设计中心
const getSelectDesignParam = async () => {
  await $http
    .public_assignSelectDesignParam({
      customer_id: state.detail.customer_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data.length > 0) {
          const newData = data.map((item) => ({
            ...item,
            disabled: item.status === 2,
          }));
          console.log(newData);
          state.select_design_center_list = newData || [];
        }
      } else {
        Toast(data);
      }
    });
};

//-获取参数  begin
onBeforeMount(() => {
  getPublicParameter();
});

const getPublicParameter = async (dep_id) => {
  await $http
    .getDispatchParams({ dep_id: dep_id ? dep_id : '' })
    .then((res) => {
      if (res.code === 0) {
        state.dispatch_level_list = res.data.dispatch_level;
        state.face_mode_list = res.data.face_mode;
        state.designDispatchData.dispatch_mode_list = res.data.dispatch_mode;
        state.designDispatchData.design_center_list = res.data.design_center;
        state.city_list = res.data.city;
      } else {
        Toast(res.data);
      }
    });
};
//-获取参数--- 待修改(可能要删除) end

//点击选择节点阶段- 待修改
// const selectNodeClick = (item, index, type) => {
//   if (state.detail.status == 1 && state.detail.is_approver_user == 1) {
//     if (type == 'node') {
//       state.showPicker_designNode_reassignment = true;
//       state.pickerItem_reassignment = item;
//       state.pickerIndex_reassignment = index;
//     } else {
//       state.showPicker_desginer_reassignment = true;
//     }
//   }
// };
const onConfirm = (value, type) => {
  if (type == 'designerNode_reassignment') {
    state.contract_progress[
      state.pickerIndex_reassignment
    ].design_reassignment_stage_name = value.name;
    if (form.list.designs[state.pickerIndex_reassignment]) {
      form.list.designs[state.pickerIndex_reassignment].design_id =
        state.pickerItem_reassignment.design_id;
      form.list.designs[
        state.pickerIndex_reassignment
      ].design_reassignment_stage = value.id;
    }
    state.showPicker_designNode_reassignment = false;
  } else {
    form['list'][type] = value.id;
    form['list'][type + '_name'] = value.name;
    state['showPicker_' + type] = false;
  }
};
//end

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};

//--基本信息表单-------begin-----
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    center: '',
    center_name: '',
    remarks: '',
    //设计派单/改派-备注
    face_time: '',
    face_mode: '',
    face_mode_name: '',
    dispatch_level: '',
    dispatch_level_name: '',
    dispatch_remark: '',
    remark: '',
    reassignment_reason: '',
    reassignment_reason_name: '',

    designs: [],
    reassignment_remark: '', //改派备注
    //见面回单
    receipt_cause: '', //回单原因name
    receipt_cause_type: '', //回单原因id
  },
});
//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};
const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};
//-时间控件--end

//--基本信息表单-----end--------

//-@ 点击查看全部附件
const showAllFile = ref(false);
const lookFile = () => {
  showAllFile.value = true;
};

//-返回
const onClosePop = () => {
  router.back();
};

//-【通过/改派】操作
const btnCheckSumbit = (type) => {
  state.task_type = type;
  if (type == 16) {
    //--设计改派提醒
    state.actionSheetData.show = true;
    state.actionSheetData.checkStaus = 'ok';
    state.actionSheetData.manager = state.detail.customer_manager_id;
    state.actionSheetData.manager_name = state.detail.customer_manager_name;
    state.actionSheetData.desginer = state.detail.design_id;
    state.actionSheetData.desginer_name = state.detail.design_name;
  } else if (type == 12 || type == 21) {
    //-申请交付派单//精装工地审核
    state.deliverSheetData.show = true;
    state.deliverSheetData.customer_id = state.detail.customer_id;
    state.deliverSheetData.checkStaus = 'ok';
    state.deliverSheetData.info = state.detail;
  } else if (type == 1 || type == 15) {
    //-申请设计派单/改派
    // 表单验证
    if (!ruleFormRef.value) return;
    ruleFormRef.value
      .validate()
      .then(() => {
        state.designDispatchData.show = true;
        state.designDispatchData.checkStaus = 'ok';
        state.designDispatchData.type = type;
        state.designDispatchData.formInfo = form.list;
        state.designDispatchData.reject_design_center_switch =
          state.detail.reject_design_center_switch;
        state.designDispatchData.default_design_id =
          state.detail.default_design_id;
        state.designDispatchData.default_design_center_id =
          state.detail.default_design_center_id;
        state.designDispatchData.default_design_center_name =
          state.detail?.default_design_center_name;
        state.designDispatchData.default_dispatch_model =
          state.detail.default_dispatch_model;
        state.designDispatchData.default_dispatch_model_name =
          state.detail?.default_dispatch_model_name;
        state.designDispatchData.disable_dispatch_model =
          state.detail.disable_dispatch_model;
      })
      .catch(() => {
        //验证失败
        Toast('完善信息');
      });
  } else if (type == 2) {
    console.log('分派设计中心');
    //-分派设计中心
    // 表单验证
    if (!ruleFormRef.value) return;
    ruleFormRef.value
      .validate()
      .then(() => {
        if (state?.tipInfo?.receiving_time) {
          state.designCenterData.show = true;
          state.designCenterData.checkStaus = 'ok';
          state.designCenterData.type = type;
          state.designCenterData.formInfo = {};
        } else {
          Dialog.confirm({
            message: `${state?.tipInfo?.tip_msg}`,
            messageAlign: 'left',
          }).then(() => {
            getCenterInfo(1).then((response) => {
              state.tipInfo = response;
              state.tip_button_disabled = response?.receiving_time
                ? true
                : false;
              state.designCenterData.show = true;
              state.designCenterData.checkStaus = 'ok';
              state.designCenterData.type = type;
              state.designCenterData.formInfo = {};
            });
          });
        }
      })
      .catch(() => {
        //验证失败
        Toast('完善信息');
      });
  } else if (type == 17) {
    //-见面回单-接单是拒绝
    state.meetData.show = true;
    state.meetData.checkStaus = 'rejust';
    state.meetData.type = type;
    state.meetData.remark = form.list.remark;
    state.meetData.receipt_cause = form.list.receipt_cause;
    state.meetData.receipt_cause_type = form.list.receipt_cause_type;
    state.meetData.receipt_cause_type_list = form.list.receipt_cause_type_list;
  } else {
    //-客户死单&通用审核
    state.commonSheetData.show = true;
    state.commonSheetData.checkStaus = 'ok';
  }
};

//-【不通过/不改派】操作
const btnCheckRejust = (type) => {
  state.task_type = type;
  if (type == 16) {
    //--设计改派提醒
    state.actionSheetData.show = true;
    state.actionSheetData.checkStaus = 'rejust';
  } else if (type == 12) {
    //-申请交付派单
    state.commonSheetData.show = true;
    state.commonSheetData.checkStaus = 'rejust';
  } else if (type == 1 || type == 15) {
    //派单 改派
    if (!ruleFormRef.value) return;
    ruleFormRef.value
      .validate()
      .then(() => {
        state.designDispatchData.show = true;
        state.designDispatchData.checkStaus = 'rejust';
        state.designDispatchData.type = type;
        state.designDispatchData.formInfo = form.list;
      })
      .catch(() => {
        //验证失败
        Toast('完善信息');
      });
  } else if (type == 2) {
    //-分派设计中心
    // 表单验证
    if (!ruleFormRef.value) return;
    ruleFormRef.value
      .validate()
      .then(() => {
        state.designCenterData.show = true;
        state.designCenterData.checkStaus = 'rejust';
        state.designCenterData.type = type;
        state.designCenterData.formInfo = {};
      })
      .catch(() => {
        //验证失败
        Toast('完善信息');
      });
  } else if (type == 17) {
    //-见面回单-接单是拒绝
    state.meetData.show = true;
    state.meetData.checkStaus = 'ok';
    state.meetData.type = type;
    state.meetData.remark = form.list.remark;
    state.meetData.receipt_cause = form.list.receipt_cause;
    state.meetData.receipt_cause_type = form.list.receipt_cause_type;
    state.meetData.receipt_cause_type_list = form.list.receipt_cause_type_list;
  } else {
    //-客户死单&通用审核
    state.commonSheetData.show = true;
    state.commonSheetData.checkStaus = 'rejust';
  }
};

const TempSendFormRef = ref(null);
const sendForm = () => {
  if (TempSendFormRef.value) {
    TempSendFormRef.value
      .submitApi()
      .then((response) => {
        state.loading = false;
        console.log(response);
      })
      .catch(() => {
        state.loading = false;
      });
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/task/checkView.less';
@import '@/assets/styles/moreOperate/designReassignment.less';
.btn_tip {
  font-size: 12px;
  padding-bottom: 10px;
  color: #ea0000;
}
.col1 {
  color: #000000 !important;
  font-weight: 600 !important;
}
.col2 {
  color: #0256ff !important;
  font-weight: 600 !important;
}
</style>
