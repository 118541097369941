<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2024-03-07 16:47:54
 * @Description: 整装销售房屋信息
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同执行人
            </label>
            <Field
              class="noBorder"
              v-model="form.executor_type"
              :rules="[
                {
                  required: true,
                  message: '请选择合同执行人',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in state.personList"
                    :key="item.id"
                    :class="{ checked: form.executor_type == item.id }"
                    class="pull-left"
                    @click="getChecked(item.id)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </Field>
          </div>
          <div class="extra_operate" v-if="form.executor_type">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <Icon name="arrow" class="icon" />
            </div>
            <div
              class="extra_item"
              @click.stop="getHandelFun('helper')"
              v-if="form.executor_type == 2"
            >
              <p class="t1">完善委托人信息</p>
              <p class="t2" v-if="form.customer_entrust_num > 0">
                <span>{{ form.customer_entrust_num }}</span>
                项内容待完善
              </p>
              <Icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              关联合同
            </label>
            <Field
              v-model="form.relation_contract_name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择关联合同',
                },
              ]"
              placeholder="请选择关联合同"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积
            </label>
            <Field
              v-model="form.house_size"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的房屋面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写房屋面积"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <Field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in state.signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <Field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              整装优选产品
            </label>
            <Field
              v-model="form.product_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择整装优选产品',
                },
              ]"
              placeholder="请选择整装优选产品"
              @click="state.showTypePicker1 = true"
            />
            <Popup v-model:show="state.showTypePicker1" position="bottom">
              <Picker
                :columns="state.preferred_packaged_products"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type1')"
                @cancel="state.showTypePicker1 = false"
                :default-index="
                  state.preferred_packaged_products.findIndex((v) => {
                    return v.id == form.product_id;
                  })
                "
              />
            </Popup>
          </div>
        </CellGroup>
      </Form>
    </div>
    <!-- 完善客户基础信息/完善执行人信息 -->
    <Popup
      v-model:show="state.complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="state.complete_card.complete_card_Type == 'base'"
        :data="state.complete_card"
        @reviewClose="
          () => {
            state.complete_card_show = false;
            state.complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
      <SaleAddExecutorPage
        v-if="state.complete_card.complete_card_Type == 'helper'"
        :data="state.complete_card"
        @reviewClose="
          () => {
            state.complete_card_show = false;
            state.complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </Popup>
  </div>
</template>
<script setup>
import { reactive, defineProps, ref, inject, defineExpose } from 'vue';
import { Form, Field, CellGroup, Popup, Picker, Icon } from 'vant';
import { Toast } from 'vant';
import SaleAddMorePage from '@/components/sale/addMore';
import SaleAddExecutorPage from '@/components/sale/addExecutor';
import patterns from '@/utils/pattern';
const props = defineProps([
  'contractType',
  'customer_id',
  'data',
  'parameterList',
  'parameterListForCustomer',
]);
const $http = inject('$http');
const state = reactive({
  personList: [
    { id: 1, name: '本人' },
    { id: 2, name: '委托人' },
  ],
  //-整装优选产品参数
  preferred_packaged_products: props.parameterList
    ? props.parameterList.preferred_packaged_sell_products
    : [],
  showTypePicker1: false,
  //-合同签署方
  signatoryList: [
    { id: 1, name: '个体' },
    { id: 2, name: '企业' },
  ],
  //-完善信息
  complete_card: {
    //-客户Id
    customer_id: props.customer_id ? props.customer_id : '',
    complete_card_Type: '',
  },
  complete_card_show: false,
});

//--表单---------------------
/**
 * 校验方式
 */
const { houseSizeReg } = patterns;
//-房屋面积
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

const ruleFormRef = ref(null);
const form = reactive({
  executor_type: props.data.executor_type ? props.data.executor_type : '',
  house_size: props.data.house_size
    ? props.data.house_size
    : props.parameterListForCustomer.house_size
    ? props.parameterListForCustomer.house_size
    : '',
  product_id: props.data.product_id ? props.data.product_id : '',
  product_name: props.data.product_name ? props.data.product_name : '',
  relation_contract_id: props.data.relation_contract_id
    ? props.data.relation_contract_id
    : props.parameterListForCustomer
    ? props.parameterListForCustomer.relation_contract.contract_id
    : '',
  relation_contract_name: props.data.relation_contract_name
    ? props.data.relation_contract_name
    : props.parameterListForCustomer
    ? props.parameterListForCustomer.relation_contract.contract_name +
      '(' +
      props.parameterListForCustomer.relation_contract.contract_no +
      ')'
    : '',
  signatory: props.data.signatory ? props.data.signatory : '',
  company_name: props.data.company_name ? props.data.company_name : '',
  //-待完善条数-本人
  customer_other_num: props.parameterListForCustomer.customer_other_num
    ? props.parameterListForCustomer.customer_other_num
    : 0,
  //-待完善条数-委托人
  customer_entrust_num: props.parameterListForCustomer.customer_entrust_num
    ? props.parameterListForCustomer.customer_entrust_num
    : 0,
  //-整装优选产品单价
  product_price: props.data.pack_amount ? props.data.pack_amount : '',
});

//-@选择合同执行人---begin
const getChecked = (id) => {
  form.executor_type = id;
};

//-完善合同执行人信息
const getHandelFun = (type) => {
  state.complete_card_show = true;
  state.complete_card.complete_card_Type = type;
};
//-完善合同执行人、委托人信息操作
const reviewOk = () => {
  if (props.customer_id) {
    getCustomerParameter(props.customer_id);
  }
};
//-获取客户参数
const getCustomerParameter = (id) => {
  state.complete_card_show = false;
  state.complete_card.complete_card_Type = '';
  $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
    if (res.code === 0) {
      form.customer_other_num = res.data.customer_other_num;
      form.customer_entrust_num = res.data.customer_entrust_num;
    } else {
      Toast(res.data);
    }
  });
};
//-@选择合同执行人---end

//-picker-类型选择
const customFieldName = {
  text: 'name',
  value: 'id',
};

const onConfirmType = (value, type) => {
  if (type === 'type1') {
    if (value) {
      form.product_name = value.name;
      form.product_id = value.id;
      form.product_price = value.product_price;
    }
    state.showTypePicker1 = false;
  }
};

defineExpose({ form, ruleFormRef });

//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
