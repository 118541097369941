<!--
 * @Author: NanNan
 * @Date: 2023-02-17 17:31:05
 * @LastEditTime: 2024-05-29 15:45:44
 * @Description: v1.3.2 线索跟进记录页面
 * v1.3.2 优化评论
 * v1.3.3 smart 线索管理整改 - 待完善线索+企微好友 更改为 全部线索+企微线索
-->
<template>
  <div class="page-box" :class="{ pb74: !!_DATA.is_comment }">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <List
      v-model:loading="_DATA.loading"
      :finished="finished"
      :offset="20"
      @load="loadFunc"
    >
      <div
        class="same-box"
        v-for="(item, index) in _DATA.list_data"
        :key="index"
      >
        <p class="record_time">{{ item.record_time }}</p>
        <p class="record_day">{{ item.record_day }}</p>
        <div class="timeContent">
          <div class="left" v-if="item?.add_uid == -1">
            <img :src="require('@/assets/images/icon-jiqiren.png')" alt="" />
          </div>
          <div class="left" v-if="item?.add_uid != -1">
            <lazy-component>
              <Image
                v-if="item.add_user_face_image"
                :src="$host + item.add_user_face_image"
                v-lazy
                fit="cover"
                round
              />
              <img
                :src="require('@/assets/images/icon-empty-img.png')"
                v-else
              />
            </lazy-component>
          </div>
          <div class="right">
            <p class="h-name">{{ item.add_uid_name }}</p>
            <ul class="info">
              <li v-if="item?.add_uid != -1">
                <p>线索等级：{{ item.level_name || '-' }}</p>
              </li>

              <li v-if="item?.add_uid != -1">
                <p>联系方式：{{ item.communication_type_name || '-' }}</p>
              </li>
              <li>
                <p>
                  {{ item?.add_uid != -1 ? '跟进内容：' : '记录内容：'
                  }}{{ item.content || '-' }}
                </p>
              </li>
              <li v-if="item?.add_uid != -1">
                <p class="flex-p">
                  下次跟进：
                  {{
                    (item.next_record_type === 2
                      ? item.next_record_type_name
                      : item.next_record_time) || '-'
                  }}
                  <i class="icon-day" v-if="item.next_record_type === 1">
                    {{ '(' + item.next_record_type_day + '后)' }}
                  </i>
                </p>
              </li>
            </ul>
            <div class="item-oprate">
              <div>
                <em>{{ item.add_time }}</em>
                <i
                  v-if="item.delete_flag == 1"
                  @click="handleFunc('删除跟进记录', item, index)"
                >
                  删除
                </i>
              </div>
              <em class="blue" @click="handleFunc('添加评论', item, index)">
                评论
              </em>
            </div>
            <div class="comment-box">
              <div class="same" v-for="val in item.comment_list" :key="val.id">
                <div class="avater-img">
                  <lazy-component>
                    <Image
                      v-if="val.face_image"
                      :src="$host + val.face_image"
                      v-lazy
                      fit="cover"
                      round
                    />
                    <img
                      :src="require('@/assets/images/icon-empty-img.png')"
                      v-else
                    />
                  </lazy-component>
                </div>
                <div class="right-box">
                  <div class="name-box">
                    <span class="name">{{ val.user_name }}</span>
                    <em class="gray">
                      {{
                        getStr(
                          '-',
                          val.company_name,
                          val.department_name,
                          val.post_name
                        )
                      }}
                    </em>
                  </div>
                  <p class="text">{{ val.content }}</p>
                  <p class="time">
                    {{ val.add_time }}
                    <span @click="handleFunc('删除评论', val, index)">
                      删除
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="empty-timeline"
        v-if="!_DATA.loading && _DATA.list_data?.length === 0"
      >
        <img :src="require('@/assets/images/empty-thred.png')" alt="" />
        <h4>暂无跟进记录</h4>
        <p>喔哦，客户需要及时跟进哦～</p>
      </div>
      <!-- end -->
    </List>
    <div class="stick-box-small" v-if="!!_DATA.is_comment">
      <Button
        type="primary"
        icon="plus"
        size="large"
        native-type="submit"
        color="#0256FF"
        plain
        style="border: none; font-size: 15px"
        @click="handleFunc('添加跟进记录')"
      >
        添加跟进记录
      </Button>
    </div>
    <!-- 面板 -->

    <Popup v-model:show="_DATA.show" position="bottom">
      <div style="background: #f5f5f5; padding: 12px 15px">
        <Field
          v-model="_DATA.form.remark"
          rows="3"
          type="textarea"
          center
          clearable
          placeholder="请填写评论"
          @blur="handleBlur"
          ref="inputField"
          style="border: none"
          maxlength="100"
          show-word-limit
          autofocus
          :formatter="(value) => value.replace(/^\s*|\s*$/g, '')"
          format-trigger="onChange"
          :rules="[
            {
              required: true,
              message: '请填写评论',
            },
          ]"
        >
          <template #button>
            <Button
              size="small"
              type="primary"
              @click="handleSend"
              :disabled="_DATA.form.remark == ''"
            >
              确认
            </Button>
          </template>
        </Field>
      </div>
    </Popup>

    <Popup v-model:show="_DATA.show" position="bottom" :overlay="true">
      <div style="background: #f5f5f5; padding: 12px 15px">
        <Field
          v-model="_DATA.form.remark"
          rows="3"
          type="textarea"
          center
          clearable
          placeholder="请填写评论"
          @blur="handleBlur"
          ref="inputField"
          style="border: none"
          maxlength="100"
          show-word-limit
          autofocus
          :formatter="(value) => value.replace(/^\s*|\s*$/g, '')"
          format-trigger="onChange"
          :rules="[
            {
              required: true,
              message: '请填写评论',
            },
          ]"
        >
          <template #button>
            <Button
              size="small"
              type="primary"
              @click="handleSend"
              :disabled="_DATA.form.remark == ''"
              :loading="_DATA.loadingSend"
            >
              确认
            </Button>
          </template>
        </Field>
      </div>
    </Popup>
  </div>
  <!-- pop 写跟进 -->
  <Popup
    round
    :close-on-popstate="true"
    position="bottom"
    v-model:show="followInfo.show"
    :style="{ maxHeight: '70%' }"
  >
    <TempAddFollow
      v-if="followInfo.show"
      :data="followInfo.pop"
      :clueID="_DATA.clueID"
      @reviewOk="reviewOk"
    />
  </Popup>
</template>
<script setup>
import TempAddFollow from '@/components/clue/TempAddFollow';

import { reactive, inject, ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  Loading,
  Overlay,
  Notify,
  List,
  Image,
  Button,
  Dialog,
  Popup,
  Field,
} from 'vant';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();

const _DATA = reactive({
  clueID: router.currentRoute.value.query.id,
  list_data: [],
  loading: false,
  loadingSend: false,
  show: false,
  is_comment: '',
  form: {
    record_id: '',
    remark: '',
    index: 0,
  },
});

const finished = ref(false);
const page = reactive({
  page: 1,
  pages: 10,
  total: 0,
  count_page: 1,
});

onMounted(() => {});

/**
 * 接口调用
 */
// -- 跟进记录列表
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .clueFollowRecordList({
      clue_id: router.currentRoute.value.query.id,
      page: page.page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list_data = _DATA.list_data.concat(data.list);
        Object.assign(page, { ...data.page });
        if (data.page.count_page > data.page.page) {
          page.page++;
        } else {
          finished.value = true;
        }
      } else {
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    })
    .then(() => {
      // type == 1 全部线索 238查看 239编辑 242添加线索
      // type == 2 企微线索 246查看 247编辑(也走239)
      _DATA.is_comment =
        allOperationAuthority?.value?.indexOf('239') > -1 &&
        router.currentRoute.value?.query?.clueEdit == 1;
    });
};

/**
 * 操作
 */
// -- 写跟进
const followInfo = reactive({
  show: false,
});

const reviewOk = (pop) => {
  followInfo.show = false;
  if (pop === 'ok') {
    _DATA.list_data = [];
    page.page = 1;
    loadFunc();
  }
};

// -- 字符串拼接
const getStr = (split, ...values) => {
  const arr = [...values].filter((str) => !!str);
  return arr.join(split);
};

// -- 相关操作
const inputField = ref(null);

const handleFunc = (type, item, idx) => {
  if (type === '添加跟进记录') {
    followInfo.show = true;
    // router.push({
    //   path: '/clueFollowNew',
    //   query: { cludID: router.currentRoute.value.query.id },
    // });
  } else if (type === '删除跟进记录') {
    Dialog.confirm({
      message: '是否确认删除该跟进记录？',
    }).then(() => {
      $http
        .delClueFollowRecord({ clue_follow_record_id: item.id })
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            const pageIndex = Math.ceil((idx + 1) / 10);
            commonFunc('删除跟进记录', pageIndex);
            Notify({ type: 'success', message: '操作成功' });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    });
  } else if (type === '删除评论') {
    Dialog.confirm({
      message: '是否确认删除该评论？',
    }).then(() => {
      _DATA.loading = true;
      $http
        .delClueFollowRecordComment({ record_comment_id: item.id })
        .then((res) => {
          const { code, data } = res;
          _DATA.loading = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              duration: '1000',
              onClose: () => {
                const pageIndex = Math.ceil((idx + 1) / 10);
                commonFunc('删除评论', pageIndex);
              },
            });
          } else {
            _DATA.loading = false;
            Notify({ type: 'warning', message: data });
          }
        });
    });
  } else if (type === '添加评论') {
    _DATA.show = true;
    _DATA.form.clue_follow_record_id = item.id;
    _DATA.form.remark = '';
    _DATA.form.index = idx;
    setTimeout(() => {
      inputField.value.focus();
    }, 100);
  }
};

// -- 评论完成
const handleBlur = () => {
  inputField.value.blur();
  // show.value = false;
};

// -- 发送评论
const handleSend = () => {
  _DATA.loadingSend = true;
  $http.addClueFollowRecordComment({ ..._DATA.form }).then((res) => {
    const { code, data } = res;
    _DATA.loadingSend = false;
    if (code === 0) {
      _DATA.show = false;
      _DATA.loading = true;
      Notify({
        type: 'success',
        message: '操作成功',
        duration: '1000',
        onClose: () => {
          const pageIndex = Math.ceil((_DATA.form.index + 1) / 10);
          commonFunc('评论', pageIndex);
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// -- 删除跟进记录、删除评论、添加评论之后的操作
const commonFunc = (type, pageIndex) => {
  _DATA.loading = true;
  $http
    .clueFollowRecordList({
      clue_id: router.currentRoute.value.query.id,
      page: pageIndex,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        if (type === '删除跟进记录') {
          // 有点问题
          _DATA.list_data = data.list;
          Object.assign(page, { ...data.page });
          if (data.page.count_page > data.page.page) {
            page.page++;
          } else {
            finished.value = true;
          }
        } else {
          _DATA.list_data.splice((pageIndex - 1) * 10, 10, ...data.list);
        }
      } else {
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueFollow.less';
</style>
