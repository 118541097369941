<!--
 * @Author: gest
 * @Date: 2022-05-18 13:24:22
 * @LastEditTime: 2022-12-08 16:45:58
 * @Description: 附件9宫格
-->
<template>
  <div class="fileGrid">
    <Grid :border="false" :column-num="3" square :gutter="10">
      <GridItem
        v-for="(item, index) in props.data"
        :key="index"
        @click="fileDetail(item, props.data)"
      >
        <div v-if="isImg(item.type)" class="img-box">
          <Image
            lazy-load
            fit="cover"
            position="center"
            class="img-head"
            :src="$host + item.path"
          >
            <template v-slot:loading>
              <Loading type="spinner" size="20"></Loading>
            </template>
          </Image>
        </div>

        <div v-else class="img-box other">
          <svg class="icon-small" aria-hidden="true">
            <use :xlink:href="getIcon(item.type)"></use>
          </svg>
          <div class="filename van-ellipsis">{{ item.name }}</div>
        </div>
      </GridItem>
    </Grid>
  </div>
  <Popup v-model:show="_showData.showVideo" style="width: 80%">
    <div style="display: flex">
      <video
        style="width: 100%"
        controls
        autoplay
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5-page"
        preload="auto"
        :src="$host + _showData.info.path"
      ></video>
    </div>
  </Popup>
  <Popup v-model:show="_showData.showAudio" style="width: 80%">
    <div style="display: flex">
      <audio
        style="width: 100%"
        controls
        autoplay
        preload="auto"
        :src="$host + _showData.info.path"
      ></audio>
    </div>
  </Popup>
</template>
<script setup>
import { inject, defineProps } from 'vue';
import { Grid, GridItem, Image, Popup, Loading } from 'vant';
import { isImg, getIcon, _showData, fileDetail } from '@/utils/fileUpload';
const $host = inject('$host');
const props = defineProps(['data']);
</script>
<style lang="less" scoped>
.fileGrid {
  :deep(.van-grid) {
    padding-left: 0 !important;
  }
  :deep(.van-grid-item__content) {
    padding: 0;
  }
  .img-box {
    width: 100%;
    height: 100%;
    background-color: @grey_color;
    &.other {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .filename {
      font-size: 11px;
      color: @main_color;
      padding: 10px 10px 0 10px;
      width: 100%;
      text-align: center;
    }
    .img-head {
      width: 100%;
      height: 100%;
    }
    .icon-small {
      width: 32px;
      height: 36px;
    }
  }
}
</style>
