<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2022-07-22 11:25:05
 * @Description: 
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <van-field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <van-field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同执行人
            </label>
            <van-field
              class="noBorder"
              v-model="form.executor_type"
              :rules="[
                {
                  required: true,
                  message: '请选择合同执行人',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in personList"
                    :key="item.id"
                    :class="{ checked: form.executor_type == item.id }"
                    class="pull-left"
                    @click="getChecked(item.id)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="extra_operate" v-if="form.executor_type">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
            <div
              class="extra_item"
              @click.stop="getHandelFun('helper')"
              v-if="form.executor_type == 2"
            >
              <p class="t1">完善委托人信息</p>
              <p class="t2" v-if="form.customer_entrust_num > 0">
                <span>{{ form.customer_entrust_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
    <!-- 完善客户基础信息/完善执行人信息 -->
    <van-popup
      v-model:show="complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="complete_card.complete_card_Type == 'base'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
      <SaleAddExecutorPage
        v-if="complete_card.complete_card_Type == 'helper'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </van-popup>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
  Notify,
} from 'vant';
import SaleAddMorePage from '@/components/sale/addMore';
import SaleAddExecutorPage from '@/components/sale/addExecutor';
import { Toast } from 'vant';
export default {
  props: ['type', 'data', 'customer_id', 'parameterListForCustomer'],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    [Notify.name]: Notify,
    SaleAddMorePage,
    SaleAddExecutorPage,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      personList: [
        { id: 1, name: '本人' },
        { id: 2, name: '委托人' },
      ],
      //-合同签署方
      signatoryList: [
        { id: 1, name: '个体' },
        { id: 2, name: '企业' },
      ],

      //-完善信息
      complete_card: {
        //-客户Id
        customer_id: props.customer_id ? props.customer_id : '',
        complete_card_Type: '',
      },
      complete_card_show: false,
    });
    onMounted(() => {
      getCustomerParameter(props.customer_id);
    });
    //-完善信息操作
    const reviewOk = () => {
      if (props.customer_id) {
        getCustomerParameter(props.customer_id);
      }
    };
    //-获取客户参数
    const getCustomerParameter = (id) => {
      state.complete_card_show = false;
      state.complete_card.complete_card_Type = '';
      $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
        if (res.code === 0) {
          form.customer_other_num = res.data.customer_other_num;
          form.customer_entrust_num = res.data.customer_entrust_num;
        } else {
          Toast(res.data);
        }
      });
    };
    //--表单---------------------

    const ruleFormRef = ref(null);
    const form = reactive({
      executor_type: props.data.executor_type ? props.data.executor_type : '',
      signatory: props.data.signatory ? props.data.signatory : '',
      company_name: props.data.company_name ? props.data.company_name : '',
      //-待完善条数-本人
      customer_other_num: 0,
      //-待完善条数-委托人
      customer_entrust_num: 0,
    });

    //-选择合同执行人
    const getChecked = (id) => {
      form.executor_type = id;
    };

    //-完善合同执行人信息
    const getHandelFun = (type) => {
      state.complete_card_show = true;
      state.complete_card.complete_card_Type = type;
    };
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      btnSumbit,
      getChecked,
      getHandelFun,
      reviewOk,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
