<!--
 * @Author: gest
 * @Date:2022-09-09 11:03:35
 * @LastEditTime: 2022-09-14 16:43:51
 * @Description: 联系方式记录
-->
<template>
  <div class="pop_list">
    <h1 class="clearfix">
      <span class="c1 pull-left">联系方式记录</span>
      <span class="c2 pull-right" @click="onClosePop">
        <Icon name="cross" :size="20" />
      </span>
    </h1>

    <div class="info_list noBorder">
      <cell class="info_item" v-for="item in _DATA.list" :key="item.id">
        <div class="cell_item">
          <span class="left">修改人：</span>
          <span class="right">{{ item.uid_name }}</span>
        </div>

        <div class="cell_item">
          <span class="left">联系方式：</span>
          <span class="right">{{ item.phone }}</span>
        </div>
        <div class="cell_item">
          <span class="left">修改时间：</span>
          <span class="right">{{ item.add_time }}</span>
        </div>
      </cell>

      <Empty v-if="!_DATA.list.length" image="search" description="没有数据" />
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, defineProps, onMounted, inject } from 'vue';
import { Cell, Empty, Notify } from 'vant';

import { Icon } from 'vant';
const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['data']);
const $http = inject('$http');
const _DATA = reactive({
  clue_id: props.data,
  list: [],
});
onMounted(() => {
  totalFunc();
});
const totalFunc = () => {
  $http
    .clueContactChangeList({
      clue_id: Number(_DATA.clue_id),
      page: 1,
      pages: 100,
    })
    .then((res) => {
      const { data, code } = res;

      if (code === 0) {
        _DATA.list = data.list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
//-取消
const onClosePop = () => {
  emit('reviewClose');
};
</script>

<style lang="less" scoped>
.pop_list {
  width: 100%;

  > h1 {
    width: 100%;
    padding: 0px 25px;
    margin: 25px 0 20px;
    > span.c1 {
      font-size: 17px;
      line-height: 22px;
      color: @main_color;
      font-weight: bold;
    }
    > span.c2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: bold;
      color: #52575d;
    }
  }
  .info_list {
    padding: 0 25px;
    .info_item {
      border-bottom: 1px solid #eeeeee;
      .cell_item {
        display: flex;
        padding: 10px 0;
        .left {
          color: #aaaaaa;
          font-size: 15px;
          line-height: 20px;
        }
        .right {
          color: #1a1a1a;
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
