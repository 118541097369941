<!--
 * @Author: Jessica
 * @Date: 2023-03-23 10:10:13
 * @LastEditTime: 2023-03-28 15:05:28
 * @Description: v1.3.4 施工方案预演会表单
-->
<template>
  <div class="formBase">
    <CellGroup>
      <!-- 方案内容 -->
      <div class="content">
        <h3>方案内容</h3>
        <div class="list1">
          <!-- 施工图评审 -->
          <div class="item">
            <p>施工图评审</p>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup v-model="form.info.review">
                  <Radio name="1">通过盖章</Radio>
                  <Radio name="2">未通过盖章</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
      </div>
      <!-- 信息对称 -->
      <div class="content">
        <h3>信息对称</h3>
        <!-- 施工报价是否和资金规划匹配 -->
        <div class="list1">
          <div class="item border_b">
            <p>施工报价是否和资金规划匹配</p>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup v-model="form.info.cash_match">
                  <Radio name="1">是</Radio>
                  <Radio name="2">否</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div class="item1">
            <!-- 每平米报价 -->
            <p class="field_item">
              <Field
                label="每平米报价"
                v-model="form.info.per_money"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
            <!-- 超出原因 -->
            <p class="field_item">
              <span class="label-name">超出原因</span>
              <Field
                v-model="form.info.exceed_remark"
                rows="3"
                autosize
                type="textarea"
                maxlength="500"
                placeholder="填写超出原因..."
                show-word-limit
                :rules="[
                  {
                    required: true,
                    message: '请填写超出原因',
                  },
                ]"
              ></Field>
            </p>
          </div>
        </div>
        <!-- 是否扩建 -->
        <div class="list1 mt_15">
          <div class="item pb_15">
            <p>是否扩建</p>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup v-model="form.info.expand" @change="setExpandFun">
                  <Radio name="1">是</Radio>
                  <Radio name="2">否</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div class="item1 border_t" v-if="form.info.expand == 1">
            <!-- 原始面积 -->
            <p class="field_item">
              <Field
                label="原始面积"
                v-model="form.info.origin_area"
                type="number"
                :formatter="formatterSize"
                format-trigger="onBlur"
                placeholder="输入原始面积"
                :rules="[
                  {
                    required: true,
                    message: '请输入原始面积',
                  },
                ]"
              >
                <template #button>m²</template>
              </Field>
            </p>
            <!-- 扩建面积 -->
            <p class="field_item">
              <Field
                label="扩建面积"
                v-model="form.info.expand_area"
                type="number"
                :formatter="formatterSize"
                format-trigger="onBlur"
                placeholder="输入扩建面积"
                :rules="[
                  {
                    required: true,
                    message: '请输入扩建面积',
                  },
                ]"
              >
                <template #button>m²</template>
              </Field>
            </p>
            <!-- 扩建金额 -->
            <p class="field_item">
              <Field
                label="扩建金额"
                v-model="form.info.expand_money"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="输入扩建金额"
                :rules="[
                  {
                    required: true,
                    message: '请输入扩建金额',
                  },
                ]"
              >
                <template #button>元</template>
              </Field>
            </p>
          </div>
        </div>
        <!-- 对这次“方案通过”有信心吗？ -->
        <div class="list3 mt_15">
          <h2>对这次“方案通过”有信心吗？</h2>
          <div class="score_item clearfix">
            <p
              class="pull-left"
              :class="{ checked: i == form.info.pass_confidence }"
              v-for="i in 10"
              :key="i"
              :label="i"
              @click.stop="getScore(i, 'pass_confidence')"
            >
              <span>{{ i + '分' }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 商务洽谈 -->
      <div class="content">
        <h3>商务洽谈</h3>
        <div class="list2">
          <!-- 决策人 -->
          <p class="field_item max">
            <Field
              label="决策人"
              v-model="form.info.policy_man"
              type="text"
              placeholder="输入决策人姓名"
              :rules="[
                {
                  required: true,
                  message: '请输入决策人姓名',
                },
              ]"
            ></Field>
          </p>
          <!-- 见面时间 -->
          <p class="field_item max">
            <Field
              label="见面时间"
              v-model="form.info.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="选择见面时间"
              :rules="[
                {
                  required: true,
                  message: '请选择见面时间',
                },
              ]"
              @click="openDateType('face_time')"
            />
          </p>
        </div>
        <!-- 对这次的“收款成功”有信心吗？ -->
        <div class="list3 mt_15">
          <h2>对这次的“收款成功”有信心吗？</h2>
          <div class="score_item clearfix">
            <p
              class="pull-left"
              :class="{ checked: i == form.info.success_confidence }"
              v-for="i in 10"
              :key="i"
              :label="i"
              @click.stop="getScore(i, 'success_confidence')"
            >
              <span>{{ i + '分' }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 收款方式 -->
      <div class="content mb_15">
        <div class="list2 mt_15">
          <!-- 收款方式 -->
          <p class="field_item max">
            <Field
              label="收款方式"
              v-model="form.info.money_type_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="选择收款方式"
              :rules="[
                {
                  required: true,
                  message: '请选择收款方式',
                },
              ]"
              @click="state.showCashPicker = true"
            />
          </p>
        </div>
      </div>

      <!-- popup -->
      <!-- 时间选择控件 -->
      <Popup v-model:show="state.showTimePicker" position="bottom">
        <DatetimePicker
          v-model="state.currentDate"
          :min-date="state.minDate"
          type="datetime"
          title="选择日期"
          @confirm="(v) => onConfirmDate(v, 'face_time')"
          @cancel="state.showTimePicker = false"
        />
      </Popup>
      <!-- 收款方式选择控件 -->
      <Popup v-model:show="state.showCashPicker" position="bottom">
        <Picker
          :columns="state.cash_list"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(v) => onConfirm(v, 'money_type')"
          @cancel="state.showCashPicker = false"
          :default-index="
            state.cash_list.findIndex((v) => {
              return v.id == form.info.money_type;
            })
          "
        />
      </Popup>
    </CellGroup>
  </div>
</template>

<script setup>
import { defineProps, reactive, inject, onMounted, defineEmits } from 'vue';
import {
  Field,
  CellGroup,
  RadioGroup,
  Radio,
  Popup,
  DatetimePicker,
  Notify,
  Picker,
} from 'vant';
import moment from 'moment';
const emit = defineEmits(['update:data']);
const $http = inject('$http');
const props = defineProps(['data']);
const state = reactive({
  //-时间控件
  minDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
  //-收款方式
  showCashPicker: false,
  cash_list: [],
});
const form = reactive({
  info: {},
  loading: false,
  cash_list: [], //收款方式
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
  loadParams();
});

const loadParams = async () => {
  await $http.getDesignNodeFormEditParams().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      state.cash_list = data.cash_type_list;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @格式化 面积
const formatterSize = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 9999.99) {
    val = 9999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD HH:mm');
};

//-@-是否扩建
const setExpandFun = (value) => {
  //-value=2：是否扩建选否，清空原始面积、扩建面积、扩建金额
  switch (value) {
    case '2':
      form.info.origin_area = '';
      form.info.expand_area = '';
      form.info.expand_money = '';
      break;

    default:
      break;
  }
};

//-@ picker选择控件
const onConfirm = (value, type) => {
  state.showCashPicker = false;
  form['info'][type] = value.id;
  form['info'][type + '_name'] = value.name;
};

//-@ 分数选择空间
const getScore = (value, type) => {
  if (form['info'][type] == value) {
    form['info'][type] = 0;
  } else {
    form['info'][type] = value;
  }
};
</script>

<style lang="less" scoped>
.formBase {
  .mt_15 {
    margin-top: 15px;
  }

  :deep(.van-cell-group) {
    background-color: #f5f5f5;
  }
  :deep(.van-cell) {
    border: 0;
    border-radius: 0;
    width: auto;
    padding: 0;
  }
  //-单选框样式自定义
  :deep(.van-radio) {
    height: 40px;
  }

  :deep(.van-radio__label) {
    font-size: 15px;
  }
  .content {
    h3 {
      color: #939aa3;
      text-indent: 20px;
      font-size: 14px;
      padding: 10px 0px;
    }

    .icon-small {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }

    .list1 {
      padding: 10px 20px;
      background: #fff;
      .item {
        &.pb_15 {
          padding-bottom: 15px;
        }
        &.border_b {
          padding-bottom: 15px;
          border-bottom: 1px solid #efefef;
        }

        > p {
          font-size: 17px;
          font-weight: bold;
          color: #1a1a1a;
          padding: 10px 0px;
        }
      }
      .item1 {
        background: #fff;
        &.border_t {
          border-top: 1px solid #efefef;
        }
        .field_item {
          padding: 15px 0px;
          border-bottom: 1px solid #efefef;
          .label-name {
            color: #1d2129;
            font-size: 15px;
            margin-bottom: 8px;
            display: inline-block;
          }
          &:last-child {
            border-bottom: 0px;
          }
          :deep(.van-field) {
            font-size: 15px;
          }
          :deep(.van-field__value) {
            color: #1d2129;
          }
          :deep(.van-field__label) {
            color: #1d2129;
            width: 90px;
          }
        }
      }
    }
    //-自定义field-商务洽谈
    .list2 {
      padding: 0px 20px;
      background: #fff;
      .field_item {
        padding: 10px 0;
        border-bottom: 1px solid #efefef;
        .label-name {
          color: #1d2129;
          font-size: 15px;
          margin-bottom: 8px;
          display: inline-block;
        }
        &:last-child {
          border-bottom: 0px;
        }
        :deep(.van-field) {
          font-size: 15px;
        }
        :deep(.van-field__value) {
          color: #1d2129;
        }
        :deep(.van-field__label) {
          color: #1d2129;
          width: 60px;
        }
        &.max {
          :deep(.van-field__label) {
            width: 110px;
          }
        }
      }
    }
    // 分数
    .list3 {
      padding: 0px 20px;
      background: #fff;
      > h2 {
        font-size: 17px;
        font-weight: bold;
        color: #1a1a1a;
        text-align: center;
        padding: 20px 0px 10px;
      }
      .score_item {
        padding: 15px 0;
        > p {
          width: 20%;
          margin-bottom: 10px;
          &.checked {
            > span {
              color: #165dff;
              border: 1px solid #bedaff;
              background: #e8f3ff;
            }
          }
          > span {
            display: block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border: 1px solid #efefef;
            border-radius: 100%;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: #000000;
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
