<!--
 * @Author: NanNan
 * @Date: 2022-05-24 14:25:52
 * @LastEditTime: 2022-07-07 16:52:25
 * @Description: v0.3.0 施工日志-添加、编辑
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="_DATA.contractList?.length > 0"
      @click="
        _DATA.contractList?.length > 1 && !_DATA.log_id
          ? (_DATA.showContract = true)
          : null
      "
    >
      <div class="left">
        <h1>{{ form_info.contract_name }}</h1>
        <p>合同编号：{{ form_info.contract_no }}</p>
      </div>
      <div class="right" v-if="_DATA.contractList?.length > 1 && !_DATA.log_id">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <Form class="form-box" ref="formRef">
      <p class="label-name">
        <em>*</em>
        施工节点
      </p>
      <Field
        v-model="form_info.node_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择施工节点"
        @click="_DATA.node_show = true"
        :rules="[
          {
            required: true,
            message: '请选择施工节点',
            trigger: 'onChange',
          },
        ]"
      />
      <p class="label-name">
        <em>*</em>
        施工内容
      </p>
      <Field
        v-model="form_info.remark"
        rows="3"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加施工内容..."
        show-word-limit
        :rules="[
          {
            required: true,
            message: '请添加施工内容',
            trigger: 'onChange',
          },
        ]"
      ></Field>
      <!-- 附件组件 -->
      <p class="label-name">附件</p>
      <FileUpload
        :type="3"
        :data="form_info.files"
        @update:func="(list) => getFileList(list)"
      ></FileUpload>
      <!-- end -->
      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>
    <!-- 施工节点列表 -->
    <Popup v-model:show="_DATA.node_show" position="bottom">
      <Area
        :value="form_info.area_id"
        :area-list="_DATA.area_list"
        :columns-num="2"
        @confirm="onConfirm"
        @cancel="_DATA.node_show = false"
      ></Area>
    </Popup>
    <!--picker-头部合同切换 --->
    <Popup v-model:show="_DATA.showContract" position="bottom">
      <Picker
        :columns="_DATA.contractList"
        :columns-field-names="{
          text: 'contract_name',
          value: 'contract_id',
        }"
        :default-index="
          _DATA.contractList.findIndex((v) => {
            return v.contract_id == form_info.contract_id;
          })
        "
        @confirm="(v) => onConfirmContract(v)"
        @cancel="_DATA.showContract = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, inject, ref, onMounted } from 'vue';
import {
  Form,
  Field,
  Notify,
  Loading,
  Overlay,
  Area,
  Popup,
  Button,
  Icon,
  Picker,
} from 'vant';
import { useRouter } from 'vue-router';

const $http = inject('$http');
const loading = ref(false);
const router = useRouter();
const _DATA = reactive({
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  construction_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.gdid
    : '',
  contract_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cid
    : '',
  log_id: router.currentRoute.value.query.logid || '',
  node_show: false,
  node_list: [],
  area_list: {
    province_list: {},
    city_list: {},
  },
  info: '',
  showContract: false, //切换合同弹框
  contractList: [],
});

const form_info = reactive({
  id: '', //编辑有id
  node_id: '',
  remark: '',
  files: [],
  node_name: '',
  area_id: '', //组件展示id
  contract_id: router.currentRoute.value.query.cid || '', //合同
  contract_name: '',
  contract_no: '',
  construction_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.gdid
    : '',
});

onMounted(() => {
  loading.value = true;
  //合同列表
  getContractListParmas();
});

//-@头部合同参数
const getContractListParmas = async () => {
  await $http
    .getDesignCustomerListApi({
      customer_id: router.currentRoute.value.query?.id,
      type: 2,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        _DATA.contractList = data.list;
        if (data.list?.length) {
          const typeList1 = data.list.filter(
            (v) => v.contract_id == _DATA.contract_id
          );
          if (typeList1 && typeList1.length > 0) {
            form_info.contract_id = typeList1[0].contract_id;
            form_info.contract_name = typeList1[0].contract_name;
            form_info.contract_no = typeList1[0].contract_no;
            form_info.construction_id = typeList1[0].construction_id;
          } else {
            form_info.contract_id = data.list[0].contract_id;
            form_info.contract_name = data.list[0].contract_name;
            form_info.contract_no = data.list[0].contract_no;
            form_info.construction_id = data.list[0].construction_id;
          }
          //请求数据
          router.currentRoute.value.query.logid && loadFunc();
          !router.currentRoute.value.query.logid && loadOptions();
        }
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: res.data });
      }
    });
};
//选择合同确认
const onConfirmContract = (value) => {
  form_info.contract_id = value.contract_id;
  form_info.contract_name = value.contract_name;
  form_info.contract_no = value.contract_no;
  form_info.construction_id = value.construction_id;
  loadOptions();
  _DATA.showContract = false;
};

/**
 * 接口调用
 */
//-@ 节点参数调用
const loadOptions = () => {
  loading.value = true;
  form_info.node_name = '';
  form_info.area_id = '';
  $http
    .getConstructionNodeListApi({ construction_id: form_info.construction_id })
    .then((res) => {
      const { data, code } = res;
      loading.value = false;
      if (code === 0) {
        _DATA.node_list = data.list;
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .then(() => {
      for (let i in _DATA.node_list) {
        const item = _DATA.node_list[i];
        let key = Number(i) + 1 + '00000';
        let val = item.type_name;
        _DATA.area_list.province_list[key] = val;
        for (let n in item.list) {
          var obj = item.list[n];
          var k = Number(i) + 1 + '0' + n;
          var v = obj.node_name_id_name;
          _DATA.area_list.city_list[k] = v;
          if (_DATA.info.node_id == obj.node_id) {
            // form_info.node_name = val + '-' + obj.node_name_id_name; //全称
            form_info.node_name = obj.node_name_id_name; //二级节点名称
            form_info.area_id = Number(i) + 1 + '0' + n;
          }
        }
      }
    });
};
//-@ 编辑调用
const loadFunc = () => {
  loading.value = true;
  $http
    .getInfoConstructionLogApi({ id: router.currentRoute.value.query.logid })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data;
        form_info.id = data.id;
        form_info.node_id = data.node_id;
        form_info.remark = data.comment;
        form_info.files = data.files;
        loadOptions();
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    });
};
/**
 * 操作集合
 */
//-@附件
const getFileList = (file_list) => {
  form_info.files = file_list;
};

//-@确定
const onConfirm = (areaValues) => {
  _DATA.node_show = false;
  // 文本拼接
  // form_info.node_name = areaValues[0].name + '-' + areaValues[1].name; //全称 父-子
  form_info.node_name = areaValues[1].name; //子
  // id获取
  let index = Number(areaValues[0].code[0]) - 1;
  let idx = Number(areaValues[1].code.slice(2, areaValues[1].code.length));
  form_info.node_id = _DATA.node_list[index].list[idx].node_id;
};

//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http
      .getEditConstructionLogApi({ ...form_info })
      .then((res) => {
        const { data, code } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            duration: 1000,
            onClose: () => {
              loading.value = false;
              router.back();
            },
          });
        } else {
          loading.value = false;
          Notify({
            type: 'warning',
            message: data,
            duration: 2000,
          });
        }
      })
      .catch(() => {
        loading.value = false;
      });
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/construction/TempLogAdd.less';
:deep(.van-cell) {
  &::after {
    border-bottom: none;
  }
}
</style>
