/*
 * @Author: gest
 * @Date: 2023-08-08 15:16:06
 * @LastEditTime: 2024-05-09 15:15:55
 * @Description: 线索 - 分享小程序
 * 参考文档：https://developer.work.weixin.qq.com/document/path/94797
 */

let wx = window.wx;
import axios from 'axios';
import { Toast } from 'vant';
const $host = process.env.NODE_ENV === 'production' ? '' : '/api';
let FORCE_UPDATE_NUMBER = 0;
//-获取微信config配置签名数据
const authWxAgentData = (isRefresh) => {
  let url = window.location.href;
  axios
    .post($host + '/rs/wechat/getAgentConfig', {
      url: url,
      refresh: isRefresh ? 1 : 0,
    })
    .then((res) => {
      const resData = res.data;
      if (resData.code === 0) {
        authWxAgentConfig(resData.data);
      } else if (resData.code === 1) {
        Toast(resData.data);
      } else {
        Toast(resData.msg);
      }
    });
};

//-配置config签名数据
const authWxAgentConfig = (appSignature) => {
  wx.agentConfig({
    beta: true,
    debug: false,
    corpid: appSignature.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
    agentid: appSignature.agentid, // 必填，企业微信的应用id 生产环境
    timestamp: appSignature.timestamp, // 必填，生成签名的时间戳
    nonceStr: appSignature.noncestr, // 必填，生成签名的随机串
    signature: appSignature.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
    jsApiList: ['shareAppMessage', 'sendChatMessage', 'getContext'], // 必填
    success() {},
    fail(res) {
      FORCE_UPDATE_NUMBER++;
      if (FORCE_UPDATE_NUMBER <= 1) {
        authWxAgentData(true);
      } else {
        Toast('签名失败，请联系管理员');
      }
      if (res.errMsg.indexOf('function not exist') > -1) {
        Toast('版本过低请升级');
      }
    },
  });
};

// -- 检查
const checkApiFunc = () => {
  wx.checkJsApi({
    jsApiList: ['getNetworkType', 'sendChatMessage', 'shareAppMessage'],
    success: function (e) {
      alert(JSON.stringify(e));
    },
  });
};

// -- 判断入口环境
//https://developer.work.weixin.qq.com/document/path/96948
const getContextFunc = () => {
  return new Promise((resolve, reject) => {
    wx.invoke('getContext', {}, function (res) {
      if (res.err_msg == 'getContext:ok') {
        const entry = res.entry;
        if (entry === 'single_chat_tools') {
          resolve(1);
          // 当前会话
        } else {
          resolve(2);
          // 工作台
        }
      } else {
        //错误处理
        alert('发送失败');
        reject('发送失败');
      }
    });
  });
};

// -- 分享方式 sendAppMessage 小程序
const shareSendAppFunc = (parmars, shareInfo) => {
  const { clue_id, invite_user_id } = parmars;
  console.log('????', shareInfo);
  wx.invoke(
    'sendChatMessage',
    {
      msgtype: 'miniprogram',
      miniprogram: {
        appid: shareInfo.appid, //小程序的appid，企业已关联的任一个小程序
        title: shareInfo.share_title, //小程序消息的title
        imgUrl: shareInfo.share_img, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
        page:
          shareInfo.type == 3
            ? `/pages/user/loading.html?share=qiwei&sharetype=${shareInfo.type}&clueid=${clue_id}&inviteid=${invite_user_id}&cardid=${shareInfo.card_id}`
            : `/pages/user/loading.html?share=qiwei&sharetype=${shareInfo.type}&clueid=${clue_id}&inviteid=${invite_user_id}`, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
      },
    },
    function (res) {
      if (res.err_msg == 'sendChatMessage:ok') {
        //发送成功
        alert('发送成功');
      } else {
        alert('发送失败');
      }
    }
  );
};

// -- 分享方式 shareAppMessage 小程序
const shareAppFunc = () => {
  // 替换成你的小程序的 appid
  const miniProgramAppId = 'wxebb99b8b721ede15';
  // 小程序的跳转链接
  const miniProgramPagePath =
    '/pages/market/home/index.html?qz_gdt=99&gdt_vid=999';
  const miniProgramLink = `https://open.work.weixin.qq.com/wwopen/route/miniprogram?appid=${miniProgramAppId}&path=${encodeURIComponent(
    miniProgramPagePath
  )}`;
  wx.invoke('shareAppMessage', {
    title: '分享标题',
    desc: '分享描述',
    link: miniProgramLink,
    imgUrl: '分享图片链接',
    success: (res) => {
      // 分享成功回调
      console.log('分享成功', res);
      alert('分享成功', JSON.stringify(res));
    },
    fail: (res) => {
      // 分享失败回调
      console.error('分享失败', res);
      alert('分享失败', JSON.stringify(res));
    },
  });
};

// -- 分享方式 shareAppMessage h5
const shareAppH5Func = () => {
  var shareConfig = {
    title: 'shareAppMessage：h5',
    desc: '描述文字自定义',
    link: 'http://movie.douban.com/subject/25785114/',
    imgUrl: 'http://demo.open.weixin.qq.com/jssdk/images/p2166127561.jpg',
    success: function () {
      alert('分享success');
    },
    cancel: function () {
      alert('分享cancel');
    },
    fail: function () {
      alert('分享fail');
    },
  };
  wx.invoke('shareAppMessage', shareConfig, function (e) {
    'shareAppMessage:ok' == e.err_msg && alert('分享h5成功'),
      'shareAppMessage:cancel' == e.err_msg && alert('分享h5取消');
  });
};

export {
  authWxAgentData,
  checkApiFunc,
  shareSendAppFunc,
  shareAppFunc,
  shareAppH5Func,
  getContextFunc,
};
