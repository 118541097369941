<!--
 * @Author: NanNan
 * @Date: 2023-03-21 15:59:48
 * @LastEditTime: 2024-04-08 15:28:57
 * @Description: v1.3.4 分配人员（单选+二次确认，无单选按钮）
 * v1.3.6 smart 发起轮派 通用
-->
<template>
  <div class="page-box">
    <NavBar
      safe-area-inset-top
      :title="_DATA.title"
      left-arrow
      :fixed="true"
      @click-left="close"
    />
    <div class="search noBorder">
      <Search
        v-model="_DATA.kw"
        placeholder="搜索人员"
        @search="onSearch()"
        @cancel="onCancel"
        @clear="onClear()"
        show-action
      >
        <template #action>
          <div @click="onSearch()" style="color: #0256ff">搜索</div>
        </template>
      </Search>
    </div>
    <!-- list -->
    <div class="list">
      <List
        v-model:loading="_DATA.loading"
        :finished="_DATA.finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          v-for="(item, index) in _DATA.list"
          :key="index"
          class="cell-same"
          @click.stop="choseItem(item)"
        >
          <div class="left">
            <Image
              class="image"
              :src="
                item.face_image
                  ? host + item.face_image
                  : require('@/assets/images/icon-empty-img.png')
              "
            />
          </div>
          <div
            class="right"
            v-if="['派单分配', '变更分配'].indexOf(props?.data?.type) > -1"
          >
            <p class="name">{{ item.label }}</p>
            <p class="part">{{ item.dep_str + '/' + item.post_str }}</p>
          </div>
          <div class="right" v-else>
            <p class="name">{{ item.name }}</p>
            <p class="part">{{ item.dep_name + '/' + item.post_name }}</p>
          </div>
        </div>
      </List>
    </div>
    <!-- action -->
    <ActionSheet
      v-model:show="_DATA.showMoreAction"
      cancel-text="取消"
      close-on-click-action
      @select="selectAction"
    >
      <template #description>
        <div>已选择：{{ _DATA.action.description }}</div>
        <div style="margin: 20px 0 0">
          <Field
            v-model.trim="_DATA.action.remark"
            type="textarea"
            placeholder="填写备注"
            maxlength="100"
            show-word-limit
            :rows="3"
          />
        </div>
        <Button
          type="primary"
          size="large"
          block
          v-for="item in _DATA.action.list"
          :key="item.type"
          @click="selectAction(item)"
          style="margin-top: 12px"
          :loading="loadingBtn"
        >
          {{ item.name }}
        </Button>
      </template>
    </ActionSheet>
  </div>
</template>
<script setup>
import {
  defineProps,
  reactive,
  defineEmits,
  onMounted,
  inject,
  computed,
  ref,
} from 'vue';
import {
  List,
  NavBar,
  Search,
  Notify,
  Image,
  Dialog,
  ActionSheet,
  Field,
  Button,
} from 'vant';
import { useStore } from 'vuex';

const store = useStore();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['reviewFunc']);
const host = inject('$host');
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const _DATA = reactive({
  title: props?.data?.title,
  kw: '',
  kw_copy: '',
  list: [],
  page: {
    count_page: 1,
    page: 1,
    pages: 10,
    total: 0,
  },
  loading: false,
  finished: false,
  refreshing: false,
  dialog: {
    show: false,
    choseItem: {},
  },
  showMoreAction: false,
  action: {
    description: '',
    invite_user_id: '',
    remark: '',
    list: [
      { name: '确认派单', type: 2 },
      { name: '仅分配不派单', type: 1 },
    ],
  },
});

onMounted(() => {
  props?.data?.type === '派单分配' && buttonFunc();
  refreshFunc();
});

/**
 * 接口
 */
const onLoad = () => {
  _DATA.loading = true;
  const requestApi =
    ['派单分配', '变更分配'].indexOf(props?.data.type) > -1
      ? $http.public_servicePersonalList({
          kw: _DATA.kw_copy,
          is_show_depart: 0,
          is_all_person: 2,
          page: _DATA.page.page,
          pages: _DATA.page.pages,
        })
      : $http.getMemberList({
          kw: _DATA.kw_copy,
          page: _DATA.page.page,
          pages: _DATA.page.pages,
        });
  requestApi.then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.page = data.page;
      _DATA.list = _DATA.list.concat(data.list);
      if (data.page.page <= data.page.count_page) {
        _DATA.page.page++;
      } else {
        _DATA.finished = true;
      }
    } else {
      _DATA.loading = false;
      _DATA.finished = true;
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

/**
 * 操作
 */
// -- 针对派单分配 - 权限按钮展示
const buttonFunc = () => {
  _DATA.action.list = [];
  if (allOperationAuthority.value.indexOf('541') > -1)
    _DATA.action.list.push({ name: '确认派单', type: 2 });
  if (allOperationAuthority.value.indexOf('542') > -1)
    _DATA.action.list.push({ name: '仅分配不派单', type: 1 });
};

// -- 选择
const choseItem = (item) => {
  console.log(item);

  const imgUrl = item.face_image
    ? host + item.face_image
    : require('@/assets/images/icon-empty-img.png');
  if (props?.data?.type === '派单分配') {
    _DATA.showMoreAction = true;
    _DATA.action.description =
      item.label + '[' + item.dep_str + '/' + item.post_str + ']';
    _DATA.action.invite_user_id = item.id;
    return false;
  }

  Dialog.confirm({
    title:
      props?.data?.type === '变更分配' ? '线索变更分配为：' : '线索轮派为：',
    confirmButtonText: '确定',
    confirmButtonColor: '#0256FF',
    className: 'dialogChose',
    message: `<div class="flex"><Image class="image" src="${imgUrl}"/><div class="right"><p class="name">${
      item.name || item.label
    }</p><p class="part">${
      (item.dep_name || item.dep_str) + '/' + (item.post_name || item.post_str)
    }</p></div></div>`,
    allowHtml: true,
  }).then(() => {
    const requestApi =
      props?.data?.type === '变更分配'
        ? $http.clueChangeDistribution({
            clue_id: props?.data?.id,
            invite_user_id: item.id,
          })
        : $http.clueCopyClue({
            clue_id: props?.data?.id,
            invite_user_id: item.id,
          });
    requestApi.then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          duration: 500,
          onClose: () => {
            emit('reviewFunc', 'ok');
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
  });
};

// -- 搜索
const onSearch = () => {
  _DATA.page.page = 1;
  _DATA.kw_copy = JSON.parse(JSON.stringify(_DATA.kw));
  _DATA.list = [];
  _DATA.refreshing = false;
  _DATA.finished = false;
  onLoad();
};

// -- 清除
const onClear = () => {
  _DATA.page.page = 1;
  _DATA.kw = '';
  _DATA.kw_copy = '';
  _DATA.list = [];
  _DATA.refreshing = false;
  _DATA.finished = false;
  onLoad();
};

// -- 取消
const onCancel = () => {
  _DATA.kw = JSON.parse(JSON.stringify(_DATA.kw_copy));
};

// -- 加载
const refreshFunc = () => {
  setTimeout(() => {
    if (_DATA.refreshing) {
      _DATA.list = [];
      _DATA.refreshing = false;
    }
  }, 1000);
};

// -- 派单
const loadingBtn = ref(false);
const selectAction = (action) => {
  loadingBtn.value = true;
  $http
    .clue_dispatchAllocation({
      clue_ids: [props?.data?.id],
      type: action.type,
      distribution_rule_id: 1,
      responsible_user_id: localStorage.getItem('ACCOUNT_ID'),
      invite_user_id: _DATA.action.invite_user_id,
      remark: _DATA.action.remark,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: action.type === 1 ? '分配完成！' : '派单完成！',
          duration: 500,
          onClose: () => {
            loadingBtn.value = false;
            emit('reviewFunc', 'ok');
          },
        });
        console.log(data);
      } else {
        loadingBtn.value = false;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

// -- 关闭
const close = () => {
  emit('reviewFunc', 'cancel');
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/TempPerson.less';
</style>
