<!--
 * @Author: NanNan
 * @Date: 2023-10-13 14:50:12
 * @LastEditTime: 2024-05-31 19:02:37
 * @Description: v1.4.7 smart 客资表全新改版
 * v1.4.8 总计增加服务人员筛选
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- 头部搜索: 有kw -->
    <Sticky offset-top="12.3vw" v-if="_DATA.parmas.kw">
      <div class="header-box">
        <div class="left">
          <form action="/">
            <Search
              placeholder="姓名/楼盘/手机号/编号"
              show-action
              maxlength="20"
              v-model="_DATA.kw"
              :clearable="false"
              :formatter="formatterSearch"
              format-trigger="onChange"
              @search="onSearch"
              @cancel="onCancel"
              @clear="onClear"
            >
              <template #action>
                <div @click="onSearch" style="color: #0256ff; font-size: 15px">
                  搜索
                </div>
              </template>
              <template #right-icon>
                <div @click="onClear">
                  <Icon name="clear" color="#c8c9cc" />
                </div>
              </template>
            </Search>
          </form>
        </div>
      </div>
      <div class="total-box">
        <p class="result">
          <span>全部客户(结果</span>
          <em>{{ _DATA.page.total }}</em>
          <span>条)</span>
        </p>
      </div>
    </Sticky>
    <!-- 头部搜索: 无kw -->
    <Sticky offset-top="12.3vw" v-else>
      <div class="header-box">
        <div class="left">
          <form action="/">
            <Search
              placeholder="姓名/楼盘/手机号/编号"
              show-action
              maxlength="20"
              v-model="_DATA.kw"
              :clearable="false"
              :formatter="formatterSearch"
              format-trigger="onChange"
              @search="onSearch"
              @cancel="onCancel"
            >
              <template #action>
                <div @click="onSearch" style="color: #0256ff; font-size: 15px">
                  搜索
                </div>
              </template>
              <template #right-icon>
                <div @click="onClear" v-if="_DATA.kw != ''">
                  <Icon name="clear" color="#c8c9cc" />
                </div>
              </template>
            </Search>
          </form>
        </div>
      </div>
      <!-- tab切换 -->
      <div class="tab_box">
        <Tabs
          v-model:active="tab.tabIndex"
          title-active-color="#0256FF"
          shrink
          :before-change="beforeChangeTab"
          class="tabclass"
          :class="{ lose: !tab.tabActive }"
        >
          <Tab
            v-for="item in tab.list"
            :key="item.id"
            :name="item.id"
            :title="item.name"
          ></Tab>
        </Tabs>
      </div>
      <!-- end -->
      <!-- 总计、延期未跟进、本月待签 -->
      <div class="total-box">
        <template v-for="(item, index) in sub.list" :key="item.name">
          <div
            class="same"
            @click.stop="changeSub(index, item, sub.list)"
            :class="{ active: item.active }"
          >
            <!-- 总计栏目 -->
            <div v-if="index == 0">
              <p>
                <span v-if="sub.chose_items?.length === 0">
                  {{ item.name }}
                </span>
                <span v-if="sub.chose_items?.length === 1">
                  {{ sub.chose_items[0].name }}
                </span>
                <span v-if="sub.chose_items?.length > 1">
                  {{ sub.chose_items.length + '人' }}
                </span>
                <em>
                  {{ _DATA.nums[item.type] }}
                </em>
              </p>
              <Icon
                name="play"
                style="transform: rotate(0deg)"
                v-if="!personnel.show"
                class="icon-play"
              />
              <Icon
                name="play"
                style="transform: rotate(90deg)"
                v-if="personnel.show"
                class="icon-play"
              />
            </div>
            <!-- 其余 -->
            <div v-else>
              <p>
                <span>{{ item.name }}</span>
                <em>{{ _DATA.nums[item.type] }}</em>
              </p>
            </div>
          </div>
        </template>
      </div>
    </Sticky>
    <!-- end -->

    <!-- 内容开始 -->
    <List
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="loadList"
      class="list-box"
    >
      <!-- v1.4.7plus 客资表样式 -->
      <template v-if="!_DATA.parmas.kw">
        <div v-for="(item, index) in list" :key="index" class="cell-same-new">
          <div class="cell-top" @click.stop="handleArrow(item.id)">
            <div class="left">
              <Badge
                :content="item.avatar_label"
                :color="item.avatar_color ? labelColors[item.avatar_color] : ''"
              >
                <div class="child">
                  <Image
                    class="img_head"
                    :src="item.avatar || imageList[item.gender].src"
                  ></Image>
                </div>
              </Badge>
            </div>
            <div class="center">
              <div class="title">
                <span class="name">{{ item.name }}</span>
                <span class="split">-</span>
                <span class="floor">{{ item.floor_name }}</span>
                <span class="split">-</span>
                <span class="size">{{ item.size }}</span>
              </div>
              <div class="person-div">
                <div
                  class="last-record"
                  v-html="item.last_follow_remark || '尚未跟进'"
                  v-if="item.is_customer_service_user == 1"
                ></div>
                <div class="desc" v-else>
                  <span class="person" v-if="item.network_commissioner_name">
                    {{ item.network_commissioner_name }}
                  </span>
                  <span class="person" v-if="item.customer_manager_name">
                    {{ item.customer_manager_name }}
                  </span>
                  <span class="person" v-if="item.designer_name">
                    {{ item.designer_name }}
                  </span>
                </div>
                <Icon
                  name="arrow"
                  size="9"
                  color="#8D9091"
                  v-if="arrowActive != item.id"
                />
                <Icon name="arrow-down" size="9" color="#0256FF" v-else />
              </div>
            </div>
            <div class="right">
              <span
                class="tag"
                :class="{ on: item.wait_sign == 1 }"
                v-if="tab.tabIndex == 12"
                @click.stop="handleWait(1, item, item.wait_sign)"
              >
                <Icon name="checked" :size="14" />
                <em>待签</em>
              </span>
              <span
                class="tag"
                :class="{ on: item.wait_conversion == 1 }"
                v-if="tab.tabIndex == 13"
                @click.stop="handleWait(2, item, item.wait_conversion)"
              >
                <Icon name="checked" :size="14" />
                <em>待转</em>
              </span>
            </div>
          </div>
          <!-- 展开收起 内容区域 -->
          <div
            class="arrow-inner-box"
            v-if="arrowActive == item.id"
            @click.stop="() => javascript"
          >
            <TempFollowList
              :id="arrowActive"
              :finished="true"
              :pages="2"
              :data="item"
              :key="recordInfo.key"
              @getRecordTotal="getRecordTotal"
            />
            <!-- 全部跟进记录 -->
            <div
              class="record-all"
              @click.stop="recordPop(item)"
              v-if="recordInfo.total > 0"
            >
              <span>全部跟进记录</span>
              <Icon name="arrow" color="#0256FF" size="12" />
            </div>
          </div>
          <!-- end -->
        </div>
      </template>
      <!-- end -->

      <!-- 搜索之后的样式 -->
      <template v-else>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="cell-same"
          @click="linkTo(item)"
        >
          <div class="head_box">
            <div class="left">
              <Image
                class="img_head"
                round
                :src="
                  item.avatar
                    ? item.avatar
                    : require('@/assets/images/icon-empty-img.png')
                "
              ></Image>
              <div class="con">
                <span class="head_name">{{ item.name }}</span>
              </div>
            </div>
            <div class="right flex">
              <em class="tag blue" v-if="item.customer_type_name">
                {{ item.customer_type_name }}
              </em>
              <em class="tag green" v-if="item.is_cross_department_name">
                {{ item.is_cross_department_name }}
              </em>
            </div>
          </div>
          <div class="desc_box">
            <div class="cell_info clue_no">
              {{ item.no }}
            </div>
            <div class="cell_info more_message">
              {{ item.estate_str }}
            </div>
            <div class="tag_box">
              <!-- 待交付派单 -->
              <em class="tag orange" v-if="item.un_deliver_dispatch_name">
                {{ item.un_deliver_dispatch_name }}
              </em>
              <!-- dispatch_type: 1待设计派单 2 待网销确认首见3待变更设计团队4 待客户经理确认首见-->
              <em class="tag orange" v-if="item.dispatch_type > 0">
                {{ item.dispatch_type_name }}
              </em>
              <!-- contract_sign_cycle_name: 1：首见首签 7：7天内签约-->
              <em class="tag blue" v-if="item.contract_sign_cycle_name">
                {{ item.contract_sign_cycle_name }}
              </em>
              <!-- is_sign_contract_name:1：未签协议-->
              <em class="tag orange" v-if="item.is_sign_contract_name">
                {{ item.is_sign_contract_name }}
              </em>
              <!-- is_blank_agreement_name:1：毛坯协议-->
              <em class="tag blue" v-if="item.is_blank_agreement_name">
                {{ item.is_blank_agreement_name }}
              </em>
              <!-- is_hardback_agreement_name:1：精装协议-->
              <em class="tag blue" v-if="item.is_hardback_agreement_name">
                {{ item.is_hardback_agreement_name }}
              </em>
              <!-- is_blank_converted_name:1：毛坯转化-->
              <em class="tag blue" v-if="item.is_blank_converted_name">
                {{ item.is_blank_converted_name }}
              </em>
              <!-- is_hardback_converted_name:1：精装转化-->
              <em class="tag blue" v-if="item.is_hardback_converted_name">
                {{ item.is_hardback_converted_name }}
              </em>
              <!-- is_refund_agreement_name: 退协议(红色) -->
              <em class="tag red" v-if="item.is_refund_agreement_name">
                {{ item.is_refund_agreement_name }}
              </em>
              <!-- is_lost_name: 1申请死单(黄色) 2死单(灰色) -->
              <em class="tag grey" v-if="item.is_lost == 2">
                {{ item.is_lost_name }}
              </em>
              <!-- is_hang_name: 1:暂停跟进 0继续跟进 -->
              <em class="tag grey" v-if="item.is_hang == 1">
                {{ item.is_hang_name }}
              </em>
            </div>
          </div>
        </div>
      </template>
      <!-- end -->
    </List>
    <!-- list -->

    <!--V1.4.0 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->

    <!-- Popup 全部跟进记录 -->
    <Popup
      round
      :close-on-popstate="true"
      position="bottom"
      v-model:show="recordInfo.show"
      :style="{ maxHeight: '70%' }"
      @closed="reviewOk"
    >
      <TempFollowList
        :id="arrowActive"
        :finished="false"
        :pages="10"
        :data="recordInfo.data"
        v-if="recordInfo.show"
        @reviewOk="reviewOk"
      />
    </Popup>

    <!-- Popup 选择服务人员-->
    <Popup
      round
      :close-on-popstate="true"
      position="bottom"
      v-model:show="personnel.show"
      :style="{ maxHeight: '70%' }"
    >
      <TempSelector
        v-if="personnel.show"
        :data="personnel"
        @reviewTempSelect="reviewTempSelect"
      />
    </Popup>
  </div>
</template>
<script setup>
import { ref, onMounted, inject, reactive, computed } from 'vue';

import {
  Search,
  List,
  Notify,
  Sticky,
  Overlay,
  Loading,
  Tabs,
  Tab,
  Image,
  Badge,
  Icon,
  Popup,
} from 'vant';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import sideMenu from '@/components/sideMenu';
import TempFollowList from '@/components/sale/TempFollowList.vue';
import TempSelector from '@/components/common/TempSelectorMultiple.vue';

const $http = inject('$http');
const store = useStore();
const router = useRouter();
const route = useRoute();

const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);

const _DATA = reactive({
  customer_id: router.currentRoute.value?.query?.id,
  kw: '', //假数据
  follow_progress_type: router.currentRoute?.value?.query
    ? router.currentRoute.value?.query?.type
    : '', //11 线索未见 12 见面待签 13协议待转 14已转/在施 15 已交付
  parmas: {
    kw: '',
    delay_follow: '', //延期未跟进 1是
    wait_sign: '', //本月待签 1是
    wait_conversion: '', //本月待转 1是
    customer_service_user_ids: [], //服务人员
  },
  nums: {
    all_num: null,
    follow_num: null,
    wait_conversion_num: null,
    wait_sign_num: null,
  },
  page: {
    count_page: 1,
    page: 1,
    pages: 10,
    total: 0,
  },
  searchType: router.currentRoute.value?.query?.type ? 1 : 0, //1默认tabar 2模糊搜索 3筛选项
  //--目标管理权限
  authorityData: {},
});

const parmas = reactive({
  delay_follow: '', //延期未跟进 1是
  wait_sign: '', //本月待签 1是
  wait_conversion: '', //本月待转 1是
  customer_service_user_ids: [], //服务人员
});
const parmasEmpty = reactive({});

onMounted(() => {
  // 根据权限--跳转不同的页面
  loadTargetStatistic();

  const parmasCopy = JSON.parse(JSON.stringify(parmas));
  Object.assign(parmasEmpty, parmasCopy);

  if (router.currentRoute?.value?.query?.type)
    getSubList(router.currentRoute?.value?.query?.type);
});

/**
 * 接口
 */
// -- 列表
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const loadList = (parmas) => {
  loading.value = true;
  $http
    .getSaleCustomerList({
      ..._DATA.parmas,
      follow_progress_type: _DATA.follow_progress_type,
      page: _DATA.page.page,
      pages: _DATA.page.pages,
      ...parmas,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        _DATA.page = data.page;
        _DATA.nums = data.nums;
        list.value = list.value.concat(data.list);
        if (data.page.page < data.page.count_page) {
          _DATA.page.page++;
        } else {
          finished.value = true;
        }
      } else {
        loading.value = false;
        finished.value = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

/*
 * 操作
 */

// -- 待签、待转
const handleWait = (type, item, status) => {
  loading.value = true;
  const new_status = status == 1 ? 0 : 1;
  $http
    .customerManagement_customerChangeWaitLabel({
      customer_id: item.id,
      type,
      status: new_status,
      follow_progress_type: _DATA.follow_progress_type,
      customer_service_user_ids: _DATA.parmas.customer_service_user_ids,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          duration: '1000',
          onClose: () => {
            if (type == 1) {
              item.wait_sign = new_status;
            } else {
              item.wait_conversion = new_status;
            }
            loading.value = false;
            //虚拟刷新赋值
            _DATA.nums.wait_conversion_num = data?.wait_conversion_num || 0;
            _DATA.nums.wait_sign_num = data?.wait_sign_num || 0;
            return item;
          },
        });
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// -- tab：参数
const tab = reactive({
  tabIndex: router.currentRoute.value?.query?.type
    ? Number(router.currentRoute.value?.query?.type)
    : null,
  tabActive: router.currentRoute.value?.query?.type
    ? Number(router.currentRoute.value?.query?.type)
    : null,
  list: [
    { id: 11, name: '线索未见' },
    { id: 12, name: '见面待签' },
    { id: 13, name: '协议待转' },
    { id: 14, name: '已转/在施' },
    { id: 15, name: '已交付' },
  ],
});

// -- tab：切换标签前的回调函数
const beforeChangeTab = (val) => {
  arrowActive.value = '';
  if (tab.tabIndex == val && tab.tabActive) {
    console.log('相等，置null');
    tab.tabIndex = null;
    tab.tabActive = null;
    _DATA.searchType = 0;
  } else {
    console.log('不等，设值');
    tab.tabIndex = _.cloneDeep(val);
    tab.tabActive = _.cloneDeep(val);
    _DATA.searchType = 1;
  }
  tabReset(tab.tabActive);
};

// -- tab：切换数据处理
const tabReset = (newVal) => {
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.type = newVal;
  router.replace({ query });
  getSubList().then((sub_list) => {
    console.log(sub_list);
    //搜索参数变更
    _DATA.follow_progress_type = _.cloneDeep(tab.tabActive);
    if (newVal) {
      console.log('....');
      _DATA.parmas.delay_follow =
        newVal == 12 || newVal == 13 ? (sub_list[1].active ? 1 : '') : '';
      _DATA.parmas.wait_sign =
        newVal == 12 ? (sub_list[2].active ? 1 : '') : '';
      _DATA.parmas.wait_conversion =
        newVal == 13 ? (sub_list[2].active ? 1 : '') : '';
      _DATA.parmas.customer_service_user_ids = sub.chose_ids;
    } else {
      console.log('cccc');
      _DATA.parmas.delay_follow = '';
      _DATA.parmas.wait_sign = '';
      _DATA.parmas.wait_conversion = '';
      _DATA.parmas.customer_service_user_ids = sub.chose_ids;
    }
    //相关数据处理
    list.value = [];
    _DATA.parmas.kw = '';
    _DATA.kw = '';
    _DATA.page.page = 1;
    refreshing.value = false;
    finished.value = false;
    loadList();
  });
};

// -- sub：参数
const sub = reactive({
  active: '',
  chose_items: [],
  chose_ids: [],
  list: [
    {
      name: '总计',
      type: 'all_num',
      active: true,
      search: 'customer_service_user_ids',
      total: 0,
    },
  ],
  list_all: [
    {
      name: '总计',
      type: 'all_num',
      active: true,
      search: 'customer_service_user_ids',

      total: 0,
    },
  ],
  data: {
    11: {
      list: [
        {
          name: '总计',
          type: 'all_num',
          active: true,
          search: 'customer_service_user_ids',
          total: 0,
        },
      ],
    },
    12: {
      list: [
        {
          name: '总计',
          type: 'all_num',
          active: true,
          search: 'customer_service_user_ids',

          total: 0,
        },
        {
          name: '延期未跟进',
          type: 'follow_num',
          active: false,
          search: 'delay_follow',
        },
        {
          name: '本月待签',
          type: 'wait_sign_num',
          active: false,
          search: 'wait_sign',
        },
      ],
    },
    13: {
      list: [
        {
          name: '总计',
          type: 'all_num',
          active: true,
          search: 'customer_service_user_ids',

          total: 0,
        },
        {
          name: '延期未跟进',
          type: 'follow_num',
          active: false,
          search: 'delay_follow',
        },
        {
          name: '本月待转',
          type: 'wait_conversion_num',
          active: false,
          search: 'wait_conversion',
        },
      ],
    },
    14: {
      list: [
        {
          name: '总计',
          type: 'all_num',
          active: true,
          search: 'customer_service_user_ids',
          total: 0,
        },
      ],
    },
    15: {
      list: [
        {
          name: '总计',
          type: 'all_num',
          active: true,
          search: 'customer_service_user_ids',
          total: 0,
        },
      ],
    },
  },
});

// -- sub：赋值
const getSubList = (active) =>
  new Promise((resove) => {
    console.log('点击', active, tab.tabActive);
    if (active) {
      // url携带参数
      sub.list = sub.data[active].list;
    } else {
      // 本页面操作
      sub.list = tab.tabActive ? sub.data[tab.tabActive].list : sub.list_all;
    }
    resove(sub.list);
  });

// --  sub：切换
const changeSub = (index, item, subList) => {
  console.log(index, item, subList);
  const active = JSON.parse(JSON.stringify(item.active));
  if (index === 0) {
    //总计
    if (active) {
      personnel.show = true;
      personnel.chose_items = JSON.parse(JSON.stringify(sub.chose_items));
      personnel.chose_ids = JSON.parse(JSON.stringify(sub.chose_ids));
      return;
    } else {
      subList.filter((val) => (val.active = false));
      item.active = true;
      _DATA.parmas = JSON.parse(JSON.stringify(parmasEmpty));
      _DATA.parmas.customer_service_user_ids = sub.chose_ids;
    }
  } else if (index === 1 || index == 2) {
    _DATA.parmas = JSON.parse(JSON.stringify(parmasEmpty));
    //延期未跟进,本月待签、本月待转
    subList.filter((val) => (val.active = false));
    _DATA.parmas.customer_service_user_ids = sub.chose_ids;
    if (active) {
      subList[0].active = true;
    } else {
      subList[index].active = true;
      _DATA.parmas[item.search] = 1;
    }
  }
  //相关数据处理
  list.value = [];
  _DATA.parmas.kw = '';
  _DATA.kw = '';
  _DATA.page.page = 1;
  refreshing.value = false;
  finished.value = false;
  loadList();
};

// -- 展开收起：操作
const arrowActive = ref();
const handleArrow = (customer_id) => {
  arrowActive.value = arrowActive.value == customer_id ? '' : customer_id;
};

// -- 搜索框：取消kw
const onCancel = () => {
  _DATA.kw = JSON.parse(JSON.stringify(_DATA.parmas.kw));
};

// -- 搜索框：input格式化去除左右空格
const formatterSearch = (value) => {
  return value.replace(/^\s*|\s*$/g, '');
};

// -- 搜索框：点击搜索按钮
const onSearch = () => {
  _DATA.searchType = 2;
  _DATA.parmas.kw = JSON.parse(JSON.stringify(_DATA.kw));
  //相关数据处理
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  //直接传参给列表：需要判断kw是否存在
  if (_DATA.kw) {
    const parmas = {
      ...parmasEmpty,
    };
    loadList(parmas);
  } else {
    loadList();
  }
};

// -- 搜索框：清空搜索条件
const onClear = () => {
  //相关数据处理
  _DATA.parmas.kw = '';
  _DATA.kw = '';
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  loadList();
};

// -- 跟进记录：参数
const recordInfo = reactive({
  show: false,
  data: {},
  key: new Date(),
  total: 0,
});

// -- 跟进记录：点击 全部跟进记录
const recordPop = (item) => {
  recordInfo.show = true;
  recordInfo.data = item;
};

// -- 跟进记录：关闭回调
const reviewOk = () => {
  recordInfo.show = false;
  recordInfo.key = new Date();
};

// -- 跟进记录：动态获取总数，判断是否展示”全部跟进记录“
const getRecordTotal = (total) => {
  console.log('////', total);
  recordInfo.total = total;
};

// -- 选择服务人员
const personnel = reactive({
  show: false,
  title: '选择服务人员',
  placeholder: '请输入人员姓名',
  http: `customerManagement_customerServiceRoleParam`,
  chose_items: [],
  chose_ids: [],
});

// -- 选择服务人员：回调
const reviewTempSelect = (status, items, ids) => {
  console.log(status, items, ids);
  personnel.show = false;
  sub.chose_items = items;
  sub.chose_ids = ids;
  // sub.list[0].chose_items = items;
  // sub.list[0].chose_ids = ids;
  //相关数据处理
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  _DATA.parmas.customer_service_user_ids = ids;
  loadList();
};
/**
 * 其他
 */
// -- 头像
const imageList = reactive({
  0: {
    name: '未知',
    src: require('@/assets/images/icon-gender0.png'),
  },
  1: {
    name: '男',
    src: require('@/assets/images/icon-gender1.png'),
  },
  2: {
    name: '女',
    src: require('@/assets/images/icon-gender2.png'),
  },
});

// -- 头像徽标
const labelColors = { 1: '#ACACAC', 2: '#F37020', 3: '#EA0000' }; //1灰色 2橘色 3红色 空值不展示

// -- 跳转
const linkTo = (item) => {
  router.push({ path: '/sale', query: { id: item.id } });
};

// -- 目标管理权限
const loadTargetStatistic = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.authorityData = data.target_list;
      //入口判断
      if (/wxwork/i.test(navigator.userAgent)) {
        if (route.meta.firstPage) {
          //v1.4.0 跳转目标管理
          if (_DATA.authorityData.is_show == 1) {
            if (_DATA.authorityData.show_level == 1) {
              router.replace({ path: '/totalTargetData' });
            } else if (_DATA.authorityData.show_level == 2) {
              router.replace({
                path: '/allTargetData',
                query: {
                  start: 1,
                },
              });
            } else if (_DATA.authorityData.show_level == 3) {
              if (_DATA.authorityData.user_auth.indexOf(517) > -1) {
                router.push({
                  path: '/depDesginData',
                  query: {
                    depId: _DATA.authorityData.dep?.id ?? 0,
                    depName: _DATA.authorityData.dep?.short_name ?? '',
                    pos: 1,
                    start: 1,
                  },
                });
              } else if (_DATA.authorityData.user_auth.indexOf(518) > -1) {
                router.push({
                  path: '/depSiteData',
                  query: {
                    depId: _DATA.authorityData.dep?.id ?? 0,
                    depName: _DATA.authorityData.dep?.short_name ?? '',
                    pos: 1,
                    start: 1,
                  },
                });
              } else {
                router.push({
                  path: '/allTargetData',
                  query: {
                    subtype: 1,
                    pos: 1,
                  },
                });
              }
            }
          } else {
            //跳转工地
            if (
              allOperationAuthority.value.indexOf('148') > -1 &&
              allOperationAuthority.value.indexOf('14') == -1 &&
              allOperationAuthority.value.indexOf('110') == -1 &&
              allOperationAuthority.value.indexOf('238') == -1
            ) {
              router.replace({ path: '/constructionList' });
            }
            //v1.3.5跳转线索管理
            if (
              allOperationAuthority.value.indexOf('14') > -1 &&
              (allOperationAuthority.value.indexOf('238') > -1 ||
                allOperationAuthority.value.indexOf('246') > -1) &&
              allOperationAuthority.value.indexOf('110') == -1 &&
              allOperationAuthority.value.indexOf('148') == -1
            ) {
              router.replace({ path: '/clueList' });
            }
          }
        }
      }
      // console.log(route.meta.firstPage);
    }
  });
};

// -- 列表：上拉刷新
// const onRefresh = () => {
//   // 清空列表数据
//   finished.value = false;
//   loading.value = false;
//   _DATA.page.page = 1;
//   // 重新加载数据
//   // 将 loading 设置为 true，表示处于加载状态
//   loading.value = true;
//   refreshFunc();
// };
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/listPage.less';
</style>
