<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2024-05-28 16:26:46
 * @Description: 【申请设计派单】
 * 入口：
 *     1- 客户首页：网销专员：更多操作-申请设计派单 ok
 *     2- 我的申请：网销经理：我的申请-重新申请【分配设计中心】
 * --------------- 分割线 ---------------
 * v1.4.7plus smart 派单方式=='自动轮派'，展示排除设计中心选项
 * v1.4.8 smart 派单方式 === 492客户制定设计师中心，上传附件必填最少1个最多3个
 * v1.5.1 smart 模块引入-可指派设计师中心
 * v1.6.1 smart 模块引入-跟进情况
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计见面时间
            </label>
            <Field
              v-model="form.list.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择预计见面时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计见面时间',
                },
              ]"
              @click="openDateType('face_time')"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面方式
            </label>
            <Field
              v-model="form.list.face_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面方式',
                },
              ]"
              placeholder="请选择见面方式"
              @click="state.showPickerMode = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              派单级别
            </label>
            <Field
              v-model="form.list.dispatch_level_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择派单级别',
                },
              ]"
              placeholder="请选择派单级别"
              @click="state.dispatch_level_showPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              派单备注
              <span
                style="color: #f37020; font-size: 13px; margin-left: 5px"
                v-if="state.info?.is_design_center_manager != 1"
              >
                设计中心接单前可见
              </span>
            </label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注设计中心可见"
              show-word-limit
            />
          </div>
          <!-- v1.6.1 跟进情况 -->
          <TempSendFollow
            v-if="state.info?.is_show_appoint_designer_info == 1"
            :taskID="form?.list?.task_id"
            :customerID="router?.currentRoute?.value?.query?.id"
          />
          <!-- v1.5.1 可指派设计师中心 -->
          <TempAppointDesigner
            v-if="state.info?.is_show_appoint_designer_info == 1"
            :taskID="form?.list?.task_id"
            :customerID="router?.currentRoute?.value?.query?.id"
          />
          <div class="cell" v-if="!state.type">
            <label class="form_title">派单设计中心</label>
            <Field
              v-model="form.list.design_center_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择派单设计中心"
              @click="state.showPicker_design_select_center_id = true"
            />
          </div>
          <!-- end -->
          <div v-if="state.is_reapply && state.type == 2">
            <div class="cell">
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                派单原因
              </label>
              <Field
                v-model="form.list.redispatch_reason_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                :rules="[
                  {
                    required: true,
                    message: '请选择派单原因',
                  },
                ]"
                placeholder="请选择派单原因"
                @click="state.redispatch_reason_showPicker = true"
              />
            </div>
            <div class="cell">
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                派单方式
              </label>
              <Field
                v-model="form.list.dispatch_mode_id_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                :rules="[
                  {
                    required: true,
                    message: '请选择派单方式',
                  },
                ]"
                placeholder="请选择派单方式"
                @click="state.showPicker_dispatch_mode_id = true"
              />
            </div>
            <!-- v1.4.7plus 派单方式=='自动轮派'，展示排除设计中心选项 -->
            <div
              class="cell"
              v-if="
                form.list.dispatch_mode_id == 336 &&
                state.info?.reject_design_center_switch == 1
              "
            >
              <label class="form_title">排除设计中心</label>
              <Field
                v-model="form.list.reject_design_center_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                placeholder="请选择排除设计中心，最多选择四个"
                @click="state.reject_design_picker.visible = true"
              />
            </div>
            <!-- end(v1.4.7plus) -->
            <div
              class="cell"
              v-if="[411, 335, 492].indexOf(form.list.dispatch_mode_id) > -1"
            >
              <label class="form_title">
                <span style="color: #ea3d2f">*</span>
                {{
                  form.list.dispatch_mode_id == 411
                    ? '优先轮派'
                    : '派单设计中心'
                }}

                <Icon
                  name="question"
                  color="#FFC300"
                  size="16"
                  @click.stop="showDialog"
                  v-if="form.list.dispatch_mode_id == 411"
                />
              </label>
              <Field
                v-model="form.list.design_center_name"
                right-icon="arrow-down"
                readonly
                name="picker"
                :rules="[
                  {
                    required: true,
                    message: '请选择设计中心',
                  },
                ]"
                placeholder="请选择设计中心"
                @click="
                  form.list.dispatch_mode_id == 492
                    ? (state.showPicker_design_select_center_id = true)
                    : (state.showPicker_design_center_id = true)
                "
              />
            </div>
          </div>
        </CellGroup>
      </Form>

      <!-- v1.4.1 表单填写 -->
      <TempSendForm
        ref="TempSendFormRef"
        :data="form?.list"
        :customerObj="state.customerObj"
        :cityList="state.city_list"
      />
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="state.minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>
    <!--picker -派单级别-->
    <Popup v-model:show="state.dispatch_level_showPicker" position="bottom">
      <Picker
        :columns="state.dispatch_level_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_level')"
        @cancel="state.dispatch_level_showPicker = false"
      />
    </Popup>
    <!--picker -见面方式-->
    <Popup v-model:show="state.showPickerMode" position="bottom">
      <Picker
        :columns="state.face_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'face_mode')"
        @cancel="state.showPickerMode = false"
      />
    </Popup>
    <!--picker -派单原因-->
    <Popup v-model:show="state.redispatch_reason_showPicker" position="bottom">
      <Picker
        :columns="state.redispatch_reason_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'redispatch_reason')"
        @cancel="state.redispatch_reason_showPicker = false"
      />
    </Popup>
    <!--picker -派单方式-->
    <Popup v-model:show="state.showPicker_dispatch_mode_id" position="bottom">
      <Picker
        :columns="state.dispatch_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_mode_id')"
        @cancel="state.showPicker_dispatch_mode_id = false"
      />
    </Popup>
    <!--picker -派单设计中心-->
    <Popup v-model:show="state.showPicker_design_center_id" position="bottom">
      <Picker
        :columns="state.design_center_id_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'design_center_id')"
        @cancel="state.showPicker_design_center_id = false"
      />
    </Popup>
    <!--picker -派单设计中心2 用于动态设计中心-->
    <Popup
      v-model:show="state.showPicker_design_select_center_id"
      position="bottom"
    >
      <Picker
        cancel-button-text="清除"
        :columns="state.design_select_center_list"
        :columns-field-names="customFieldName2"
        @confirm="(v) => onConfirm(v, 'design_select_center_id')"
        @cancel="onClear('design_select_center_id')"
        :default-index="
          state.design_select_center_list.findIndex((v) => {
            return v.design_id == form.list.design_id;
          })
        "
      />
    </Popup>
    <!--多选公共 - 排除设计中心 -->
    <Popup
      v-model:show="state.reject_design_picker.visible"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <TempMultiple
        v-if="state.reject_design_picker.visible"
        :data="state.reject_design_picker"
        @reviewClose="
          () => {
            state.reject_design_picker.visible = false;
          }
        "
        @reviewOk="reviewOk_design"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  Icon,
  Dialog,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
import TempSendForm from '@/components/moreOperate/TempSendForm.vue';
import TempMultiple from '@/components/clue/TempMultiple';
import TempAppointDesigner from '@/components/moreOperate/TempAppointDesigner.vue';
import TempSendFollow from '@/components/moreOperate/TempSendFollow.vue';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  info: {
    is_design_center_manager: 0, //0不是中心经理，1中心经理
    is_show_appoint_designer_info: 1, //1 显示 2不显示(针对于跟进情况模版、可指派设计中心模版)
  },
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  is_reapply: router.currentRoute.value.query.is_reapply == 0 ? true : false,
  type: router.currentRoute.value.query.type
    ? router.currentRoute.value.query.type
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  //-picker
  dispatch_level_showPicker: false,
  showPickerMode: false,
  redispatch_reason_showPicker: false,
  dispatch_level_list: [],
  face_mode_list: [],
  redispatch_reason_list: [],
  design_center_id_list: [],
  dispatch_mode_list: [],
  showPicker_dispatch_mode_id: false,
  city_list: [], //省市区

  reject_design_picker: {
    visible: false,
    title: '选择排除设计中心',
    max: 4,
    list: [], //排除设计中心list
    checked: [],
    checkedItems: [],
  },

  customerObj: {
    customer_id: router.currentRoute.value.query.id,
    customer_name: router.currentRoute.value.query.cname,
  },
  //v1.5.1 可指派设计师中心
  showPicker_design_select_center_id: false, //v1.5.1 申请设计派单 派单设计中心-
  design_select_center_list: [], //设计中心参数
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    task_id: router.currentRoute.value.query.task_id
      ? router.currentRoute.value.query.task_id
      : '',
    face_time: '',
    dispatch_level: '',
    dispatch_level_name: '',
    face_mode: '',
    face_mode_name: '',
    remark: '',
    redispatch_reason: '',
    redispatch_reason_name: '',
    design_id: '', //v1.5.1 派单设计中心 - 设计师id
    design_name: '',
    design_center_id: '', //v1.5.1 派单设计中心 - 设计中心id
    design_center_name: '',
    dispatch_mode_id: '',
    dispatch_mode_id_name: '',
    reject_design_center_id: [], //排除设计中心
    reject_design_center_name: '',
  },
});
//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter().then(() => {
    //重新申请
    if (state.is_reapply) {
      getDispatchList();
      getDetailInfo();
      getSelectDesignParam(router.currentRoute?.value?.query?.id, 492);
    } else {
      getSelectDesignParam(router.currentRoute?.value?.query?.id);
    }
  });
});

/**
 * api
 */

// -- 公共参数
const getPublicParameter = async () => {
  await $http.getDispatchParams().then((res) => {
    if (res.code === 0) {
      state.dispatch_level_list = res.data.dispatch_level;
      state.face_mode_list = res.data.face_mode;
      state.dispatch_mode_list = res.data.dispatch_mode;
      state.city_list = res.data.city;
      state.reject_design_picker.list = res.data.design_center;
    } else {
      Toast(res.data);
    }
  });
};
// -- 设计中心
const getDispatchList = async () => {
  await $http.customerAssignDesignCenterParam().then((res) => {
    state.loading = false;
    if (res.code === 0) {
      state.design_center_id_list = res.data.design_center;
    } else {
      state.design_center_id_list = [];
    }
  });
};

// -- v1.5.1 动态派单设计中心
const getSelectDesignParam = async (customer_id, dispatch_mode) => {
  await $http
    .public_assignSelectDesignParam({
      customer_id,
      dispatch_mode,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data.length > 0) {
          const newData = data.map((item) => ({
            ...item,
            disabled: item.status === 2,
          }));
          state.design_select_center_list = newData || [];
        }
      } else {
        Toast(data);
      }
    });
};

// -- 获取审批详情
const getDetailInfo = async () => {
  state.loading = true;
  await $http.getTaskApplyInfo({ id: form.list.task_id }).then((res) => {
    state.loading = false;
    if (res.code === 0) {
      state.info = res.data;
      Object.assign(form.list, res.data);
      form.list.remark = res.data.operator_remark;
      // *****数据特殊处理
      state.redispatch_reason_list = res.data.redispatch_reason_list;
      if (
        state.redispatch_reason_list &&
        state.redispatch_reason_list.length == 1
      ) {
        form.list.redispatch_reason = state.redispatch_reason_list[0].id;
        form.list.redispatch_reason_name = state.redispatch_reason_list[0].name;
      }
      // *****v1.5.1 派单方式、派单设计中心、派单设计师 默认值
      form.list.design_id = res.data.default_design_id;
      form.list.design_center_id = res.data.default_design_center_id;
      form.list.design_center_name = res.data.default_design_center_name;
      form.list.dispatch_mode_id = res.data.default_dispatch_model;
      form.list.dispatch_mode_id_name = res.data.default_dispatch_model_name;
      if (state.dispatch_mode_list) {
        const filteredData = state.dispatch_mode_list.map((item) => ({
          ...item,
          disabled: res.data.disable_dispatch_model.indexOf(item.id) > -1,
        }));
        state.dispatch_mode_list = filteredData;
      }
      //end
    } else {
      Notify({ type: 'warning', message: res.data });
    }
  });
};
//
const TempSendFormRef = ref(null);
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      let apiUrl = $http.customerApplyDesignDispatch;
      if (state.is_reapply) {
        apiUrl = $http.approveReapply;
      }
      TempSendFormRef.value
        .submitApi()
        .then(() => {
          apiUrl({
            ...form.list,
          }).then((res) => {
            const { code, data } = res;
            state.loadingConfirm = false;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
                onClose: () => {
                  if (state.taskDate) {
                    router.push('/taskDay?date=' + state.taskDate);
                  } else {
                    router.back();
                  }
                },
              });
            } else {
              Notify({ type: 'warning', message: data });
            }
          });
        })
        .catch(() => {
          state.loadingConfirm = false;
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end

//-picker-选择项字段--begin
const customFieldName = {
  text: 'name',
  value: 'id',
};
const customFieldName2 = {
  text: 'text',
  value: 'design_id',
  disabled: 'disabled',
};
const onConfirm = (value, type) => {
  switch (type) {
    case 'face_mode':
      form.list.face_mode = value.id;
      form.list.face_mode_name = value.name;
      state.showPickerMode = false;
      break;
    case 'dispatch_level':
      form.list.dispatch_level = value.id;
      form.list.dispatch_level_name = value.name;
      state.dispatch_level_showPicker = false;
      break;
    case 'redispatch_reason':
      form.list.redispatch_reason = value.id;
      form.list.redispatch_reason_name = value.name;
      state.redispatch_reason_showPicker = false;
      break;
    case 'design_center_id':
      form.list.design_center_id = value?.id;
      form.list.design_center_name = value?.name;
      state.showPicker_design_center_id = false;
      break;
    case 'design_select_center_id':
      if (!value.disabled) {
        form.list.design_center_id = value?.design_center_id;
        form.list.design_center_name = value?.text;
        form.list.design_id = value?.design_id;
      } else {
        form.list.design_center_id = '';
        form.list.design_center_name = '';
        form.list.design_id = '';
      }
      state.showPicker_design_select_center_id = false;
      break;
    case 'dispatch_mode_id':
      form.list.dispatch_mode_id = value.id;
      form.list.dispatch_mode_id_name = value.name;
      state.showPicker_dispatch_mode_id = false;
      form.list.design_center_id = '';
      form.list.design_center_name = '';
      form.list.design_id = '';
      break;
    default:
      break;
  }
};

const onClear = (type) => {
  switch (type) {
    case 'design_select_center_id':
      form.list.design_center_id = '';
      form.list.design_center_name = '';
      form.list.design_id = '';
      state.showPicker_design_select_center_id = false;
      break;
    default:
      break;
  }
};

//-优先轮派提示文字
const showDialog = () => {
  Dialog.confirm({
    title: '注意',
    message: `优先轮派仅优先派单一次，超时未接单时记录跳单。`,
    messageAlign: 'left',
    allowHtml: true,
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#006BFF',
  });
};
//-picker-选择项字段--end

// -- v1.4.7 设计中心
const reviewOk_design = (checked, checkedItems) => {
  state.reject_design_picker.checked = checked;
  state.reject_design_picker.checkedItems = checkedItems;
  state.reject_design_picker.visible = false;
  form.list.reject_design_center_id = checked;
  const names = checkedItems.map((item) => item.name);
  form.list.reject_design_center_name = names.join(',');
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
