<!--
 * @Author: NanNan
 * @Date: 2023-06-25 10:22:25
 * @LastEditTime: 2023-12-22 16:31:13
 * @Description: v1.4.0 部门数据 - 见面质量：协议
 * v1.4.3 部门数据 - 模块3：见面质量：协议
-->
<template>
  <div class="data_item extend">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title">见面质量：协议</div>
          <!-- 协议总数&当年协议成功率 -->
          <Row class="mb_15">
            <Col span="12">
              <div class="grey">协议总数</div>
              <div class="mb_5">
                <span class="size24 bold">
                  {{ _DATA.info.contract?.actual }}
                </span>
              </div>
              <div class="flex_line mb_2">
                <div class="grey">
                  <em>目标</em>
                  <em class="bold ml_5">
                    {{ _DATA.info.contract?.target ?? '-' }}
                  </em>
                </div>
                <div class="ml_5">
                  <!-- 超目标 -->
                  <div
                    class="flex_line"
                    v-if="
                      _DATA.info.contract?.actual > _DATA.info.contract?.target
                    "
                  >
                    <span class="bold green">
                      {{ _DATA.info.contract?.gap }}
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 距目标 -->
                  <div
                    class="flex_line"
                    v-else-if="
                      _DATA.info.contract?.actual < _DATA.info.contract?.target
                    "
                  >
                    <span class="bold orange">
                      {{ _DATA.info.contract?.gap }}
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 已达标 -->
                  <div class="flex_line" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </div>
              <div class="flex_line grey mb_2">
                <em>完成率</em>
                <em class="ml_5 bold">
                  {{ _DATA.info.contract?.rate + '%' ?? '-' }}
                </em>
              </div>
            </Col>
            <Col span="12">
              <div class="grey">当年协议成功率</div>
              <div class="mb_5">
                <span class="size24 bold">
                  {{ _DATA.info.success_rate?.actual }}
                </span>
                <span class="size13 bold">%</span>
              </div>
              <div class="flex_line mb_2">
                <div class="grey">
                  <em>目标</em>
                  <em class="bold ml_5">
                    {{ _DATA.info.success_rate?.target + '%' ?? '-' }}
                  </em>
                </div>
                <div class="ml_5">
                  <div
                    class="flex_line"
                    v-if="
                      _DATA.info.success_rate?.actual >
                      _DATA.info.success_rate?.target
                    "
                  >
                    <span class="bold green">
                      {{ _DATA.info.success_rate?.gap }}%
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <div
                    class="flex_line"
                    v-else-if="
                      _DATA.info.success_rate?.actual <
                      _DATA.info.success_rate?.target
                    "
                  >
                    <span class="bold orange">
                      {{ _DATA.info.success_rate?.gap }}%
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <div class="flex_line" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <!-- 存量/当年 -->
          <Row class="pt_15 border_top">
            <Col span="12">
              <div class="grey">存量</div>
              <div class="mb_5">
                <span class="size24 bold">{{ _DATA.info.passed?.actual }}</span>
                <span class="grey size13">
                  /{{ _DATA.info.passed?.meet_num }}
                </span>
              </div>
              <div class="flex_line mb_2">
                <div class="grey">
                  <em>目标</em>
                  <em class="bold ml_5">
                    {{ _DATA.info.passed?.target ?? '-' }}
                  </em>
                </div>
                <div class="ml_5">
                  <!-- 超目标 -->
                  <div
                    class="flex_line"
                    v-if="_DATA.info.passed?.actual > _DATA.info.passed?.target"
                  >
                    <span class="bold green">{{ _DATA.info.passed?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 距目标 -->
                  <div
                    class="flex_line"
                    v-else-if="
                      _DATA.info.passed?.actual < _DATA.info.passed?.target
                    "
                  >
                    <span class="bold orange">
                      {{ _DATA.info.passed?.gap }}
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 已达标 -->
                  <div class="flex_line" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </div>
              <div class="flex_line grey mb_2">
                <em>完成率</em>
                <em class="ml_5 bold">
                  {{ _DATA.info.passed?.rate + '%' ?? '-' }}
                </em>
              </div>
            </Col>
            <Col span="12">
              <div class="grey">当年</div>
              <div class="mb_5">
                <span class="size24 bold">
                  {{ _DATA.info.now?.actual }}
                </span>
                <span class="grey size13">/{{ _DATA.info.now?.meet_num }}</span>
              </div>
              <div class="flex_line mb_2">
                <div class="grey">
                  <em>目标</em>
                  <em class="bold ml_5">{{ _DATA.info.now?.target ?? '-' }}</em>
                </div>
                <div class="ml_5">
                  <!-- 超目标 -->
                  <div
                    class="flex_line"
                    v-if="_DATA.info.now?.actual > _DATA.info.now?.target"
                  >
                    <span class="bold green">{{ _DATA.info.now?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 距目标 -->
                  <div
                    class="flex_line"
                    v-else-if="_DATA.info.now?.actual < _DATA.info.now?.target"
                  >
                    <span class="bold orange">{{ _DATA.info.now?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 已达标 -->
                  <div class="flex_line" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </div>
              <div class="flex_line grey mb_2">
                <em>完成率</em>
                <em class="ml_5 bold">
                  {{ _DATA.info.now?.rate + '%' ?? '-' }}
                </em>
              </div>
            </Col>
          </Row>
        </div>

        <div class="data_card_table border_top">
          <Row class="mb_10">
            <Col span="7">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <Col span="6">
              <div class="grey">存量</div>
            </Col>
            <Col span="6">
              <div class="grey">当年</div>
            </Col>
            <Col span="5">
              <div class="grey">
                成功率
                <!-- <br />
                ({{ _DATA.all_rate }}%) -->
              </div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              class="mb_20"
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="7">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="6">
                <div>
                  <span class="bold">{{ item.passed?.actual }}</span>
                  <span class="ml_3 grey bold">
                    /{{ item.passed?.meet_num }}
                  </span>
                </div>

                <div
                  class="flex_line"
                  v-if="item.passed?.actual > item.passed?.target"
                >
                  <span class="bold green">{{ item.passed?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line"
                  v-else-if="item.passed?.actual < item.passed?.target"
                >
                  <span class="bold orange">{{ item.passed?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </Col>
              <Col span="6">
                <div>
                  <span class="bold">{{ item.now?.actual }}</span>
                  <span class="ml_3 grey bold">/{{ item.now?.meet_num }}</span>
                </div>

                <div
                  class="flex_line"
                  v-if="item.now?.actual > item.now?.target"
                >
                  <span class="bold green">{{ item.now?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line"
                  v-else-if="item.now?.actual < item.now?.target"
                >
                  <span class="bold orange">{{ item.now?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </Col>

              <Col span="5">
                <div>
                  <span class="bold">{{ item.success_rate.actual }}%</span>
                </div>

                <!-- <div
                  class="flex_line"
                  v-if="item.success_rate.actual > item.success_rate.target"
                >
                  <span class="bold green">{{ item.success_rate.gap }}%</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line"
                  v-else-if="
                    item.success_rate.actual < item.success_rate.target
                  "
                >
                  <span class="bold orange">{{ item.success_rate.gap }}%</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div> -->
              </Col>
            </Row>
          </template>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import {
  Row,
  Col,
  // Overlay,
  // Loading,
  Icon,
  Notify,
  Skeleton,
} from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 3, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 3, //部门数据页面所处位置
  maxCount: 5, //超过则显示展开收起
  open: false,
  all_rate: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depClueContract;
  let params = { year: props.data.yearId };
  if (props.data.depId) {
    url = $http.targetStatistic_personClueContract;
    params = { dep_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.all_rate = data?.all_dep_rate || data?.all_person_rate;
      _DATA.info = Object.assign(_DATA.info, data.info);
      _DATA.list = data.list;
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depCustomerData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
