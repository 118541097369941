<!--
 * @Author: NanNan
 * @Date: 2022-04-19 15:26:46
 * @LastEditTime: 2024-06-14 11:09:31
 * @Description: V1.4.7 客户/type=1 合同详情 - [工程&产品变更]变更确认单
-->
<template>
  <div>
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="page-box">
      <List
        v-model:loading="loading"
        :finished="finished"
        :offset="20"
        @load="loadFunc"
      >
        <div class="same-box" v-for="(item, index) in list_data" :key="index">
          <div class="title-box">
            <p class="time">{{ item.add_time }}</p>
            <dl>
              <dt>
                {{ item.amount }}
              </dt>
              <dd>
                <span>{{ item.contract_edit_type_str }}</span>
              </dd>
            </dl>
          </div>
          <div class="con-box">
            <!-- 状态栏 -->
            <p
              class="h1"
              :class="{
                gray: [6].indexOf(item.status) > -1,
                cancel: [2, 5].indexOf(item.status) > -1,
                done: [4].indexOf(item.status) > -1,
                wait: [1, 3].indexOf(item.status) > -1,
              }"
            >
              <span>
                <Icon name="success" v-if="[4].indexOf(item.status) > -1" />
                <Icon name="clock-o" v-if="[1, 3].indexOf(item.status) > -1" />
                <Icon name="cross" v-if="[2, 5, 6].indexOf(item.status) > -1" />
                <span>{{ item.status_str }}</span>
              </span>
              <Icon
                name="ellipsis"
                color="#999"
                @click="showMore(item)"
                v-if="operationAuthority(item)"
              />
            </p>
            <!-- 内容 -->
            <dl class="same-flex">
              <dt>合同编号:</dt>
              <dd>{{ item.contract_no || '-' }}</dd>
            </dl>
            <dl class="same-flex">
              <dt>确认单号:</dt>
              <dd>{{ item.no || '-' }}</dd>
            </dl>
            <dl class="same-flex">
              <dt>变更阶段:</dt>
              <dd>{{ item.construction_stage_name || '-' }}</dd>
            </dl>
            <dl class="same-flex">
              <dt>变更金额:</dt>
              <dd>{{ item.amount || '-' }}元</dd>
            </dl>
            <dl class="same-flex">
              <dt>收款方式:</dt>
              <dd>{{ item.type_str || '-' }}</dd>
            </dl>
            <!-- 附件 -->
            <div class="fileList" v-if="item.attach && item.attach.length > 0">
              <FileGrid :data="item.attach"></FileGrid>
            </div>
            <!-- end -->
            <div class="about" v-if="item.intend_list?.length > 0">
              已绑定变更意向单：<span
                v-for="(v, index) in item.intend_list"
                :key="index"
              >
                <router-link
                  :to="'/contractChangePurposeView?gId=' + v.id"
                  class="blue_color underline"
                >
                  {{ v.no }} </router-link
                ><span v-if="index + 1 != item.intend_list.length"
                  >,</span
                ></span
              >
            </div>
            <div class="mt_15" v-if="item.status == 1">
              <div
                class="person_box flex_between align_center"
                v-for="(v, index) in item.check_info"
                :key="index"
              >
                <div class="flex1 display_flex align_center">
                  <div class="close_text" v-if="v.user_status == 2">
                    {{ v.user_dep_name == '供应商' ? '禁用' : '离职' }}
                  </div>
                  <Image
                    v-else
                    round
                    class="icon"
                    :src="
                      v.user_face_image
                        ? v.user_face_image.includes('http')
                          ? v.user_face_image
                          : $host + v.user_face_image
                        : require('@/assets/images/icon-empty-img.png')
                    "
                  />

                  <div class="name">
                    {{ v.user_name }}
                    <span class="extra" v-if="v.user_dep_name">
                      {{
                        v.user_post_name
                          ? v.user_dep_name + '/' + v.user_post_name
                          : v.user_dep_name
                      }}
                    </span>
                  </div>
                </div>
                <div class="status" :class="'status' + v.status">
                  {{ v.status_name }}
                </div>
              </div>
            </div>

            <!-- 查看详情 -->
            <p class="a-look">
              <router-link :to="'/contractChangeView?gId=' + item.id">
                查看详情
              </router-link>
            </p>
          </div>
        </div>
        <template v-if="list_data.length > 0" #finished>
          <Divider class="divider-bottom">我也是有底线的</Divider>
        </template>
      </List>
    </div>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无数据"
      v-if="!loading && list_data.length === 0"
    />
    <!-- end -->
    <!-- 动作面板 - 合同增减项 - 更多 -->
    <ActionSheet
      v-model:show="action.show"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
    >
      <div class="van-action-sheet__content">
        <div
          v-for="(item, index) in action.list"
          :key="index"
          @click="adjustFunc(item.type)"
        >
          <Button
            class="van-action-sheet__item"
            type="button"
            v-if="authBtn(item)"
          >
            <span class="van-action-sheet__name" style="margin-left: 10px">
              {{ item.name }}
            </span>
          </Button>
        </div>
      </div>
    </ActionSheet>
  </div>
</template>
<script setup>
import FileGrid from '@/components/common/FileGrid';
import { ref, inject, reactive, defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';
import {
  Icon,
  Notify,
  List,
  Divider,
  Empty,
  ActionSheet,
  Button,
  Dialog,
  Loading,
  Overlay,
  Image,
} from 'vant';
import { useStore } from 'vuex';

const store = useStore();
const props = defineProps(['type']);
const $http = inject('$http');

const $host = inject('$host');
const router = useRouter();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const customer_id = ref(router.currentRoute.value.query.id);
const contract_id = ref(router.currentRoute.value.query.cid);

const loading = ref(false);
const finished = ref(false);
// const refreshing = ref(false);
//         1 => '待审核',
//         2 => '审核不通过',
//         3 => '待客户确认',
//         4 => '已完成',
//         5 => '客户已拒绝',
//         6 => '已取消',
const list_data = ref([]);
const action = ref({
  id: '',
  status: '',
  change_item_type: '',
  show: false,
  list: [
    { type: 1, name: '作废变更确认单', action: [1, 3] },
    {
      type: 2,
      name: '重新生成变更确认单',
      action: [1, 2, 3, 5, 6],
    },
  ],
});
const page = reactive({
  page: 1,
  pages: 10,
  total: 0,
  count_page: 1,
});

// const type_list = ref(['-', '汇入合同款', '单独收款']);

/**
 * 接口调用
 */
const loadFunc = () => {
  let params = { contract_id: contract_id.value };
  if (props.type == 'all') {
    params = { customer_id: customer_id.value };
  }
  loading.value = true;
  $http
    .getContractAdjustListApi({
      ...params,
      page: page.page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        if (data.page.page == page.page) {
          list_data.value = list_data.value.concat(data.list);
          Object.assign(page, { ...data.page });
          if (data.page.page < data.page.count_page) {
            page.page++;
          } else {
            finished.value = true;
          }
        }
      } else {
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    });
};

const operationAuthority = (item) => {
  let result = false;
  let authId = '551'; //作废权限
  let authId2 = '552'; //重新生成权限
  if (item.change_item_type == 1) {
    //工程变更
    authId = '82';
    authId2 = '83';
  }

  if (
    allOperationAuthority.value.indexOf(authId) > -1 &&
    [1, 3].indexOf(item.status) > -1
  ) {
    result = true;
  }
  if (
    allOperationAuthority.value.indexOf(authId2) > -1 &&
    [1, 2, 3, 5, 6].indexOf(item.status) > -1
  ) {
    result = true;
  }
  return result;
};
const authBtn = (item) => {
  let result = false;
  let authId = '551'; //作废权限
  let authId2 = '552'; //重新生成权限
  if (action.value.change_item_type == 1) {
    //工程变更
    authId = '82';
    authId2 = '83';
  }
  if (item.type == 1) {
    //作废
    if (
      item.action.indexOf(action.value.status) > -1 &&
      allOperationAuthority.value.indexOf(authId) > -1
    ) {
      result = true;
    }
  } else {
    //重新生成
    if (
      item.action.indexOf(action.value.status) > -1 &&
      allOperationAuthority.value.indexOf(authId2) > -1
    ) {
      result = true;
    }
  }

  return result;
};
const showMore = (item) => {
  action.value.id = item.id;
  action.value.change_item_type = item.change_item_type;
  action.value.status = item.status;
  action.value.show = true;
};

const adjustFunc = (type) => {
  action.value.show = false;
  Dialog.confirm({
    message:
      type === 1
        ? '确定要作废该变更确认单吗？'
        : '是否重新生成新的变更确认单？',
  }).then(() => {
    // on confirm
    if (type === 1) {
      $http.getContractAdjustFailApi({ id: action.value.id }).then((res) => {
        const { data, code } = res;
        if (code === 0) {
          Notify({ type: 'success', message: '操作成功' });
          page.page = 1;
          list_data.value = [];
          loadFunc();
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
    } else if (type === 2) {
      if (action.value.change_item_type == 1) {
        router.push({
          path: '/contractChange',
          query: { id: customer_id.value, gId: action.value.id },
        });
      } else {
        router.push({
          path: '/productChange',
          query: { id: customer_id.value, gId: action.value.id },
        });
      }
    }
  });
};

const onCancel = () => {
  action.value.show = false;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/addReduce.less';
</style>
