<!--
 * @Author: gest
 * @Date: 2023-09-12 13:51:19
 * @LastEditTime: 2023-10-27 11:20:25
 * @Description: v1.4.5 防水验收记录表 
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <!-- v-for -->
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="flex1 mr_20">{{ item.name }}</div>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  @click="radioClick(item)"
                  v-model="item.value"
                  direction="horizontal"
                  checked-color="#0256ff"
                >
                  <Radio :name="1">合格</Radio>
                  <Radio :name="2">不合格</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>

        <div
          class="list_field"
          style="background-color: #fafafa"
          v-if="item.value == 2"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">
                <span class="red_color">*</span>整改日期
              </div>
              <Field
                style="text-align: right; background-color: #fafafa"
                v-model="item.time"
                right-icon="arrow"
                name="picker"
                placeholder="选择"
                readonly
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                  },
                ]"
                @click="openDateType(item)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- v-for end-->
    </div>
    <div class="font_size_13 grey_color" style="padding: 0 15px 6px 15px">
      验收分析
    </div>
    <div class="content mb_15">
      <div class="white_bg">
        <div class="radio_box">
          <div class="radio_item">
            <p class="name">验收结果</p>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.info.check_pass"
                  direction="horizontal"
                >
                  <Radio
                    :name="1"
                    class="radio_ok"
                    :class="{
                      checked: form.info.check_pass == 1,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-fill1"></use>
                      </svg>
                    </template>
                    通过
                  </Radio>
                  <Radio
                    :name="2"
                    class="radio_no"
                    :class="{
                      checked: form.info.check_pass == 2,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-sad-fill1"></use>
                      </svg>
                    </template>
                    不通过
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div class="radio_item">
            <p class="name">是否合格</p>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.info.check_result"
                  direction="horizontal"
                >
                  <Radio
                    :name="1"
                    class="radio_ok"
                    :class="{
                      checked: form.info.check_result == 1,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-fill1"></use>
                      </svg>
                    </template>
                    合格
                  </Radio>
                  <Radio
                    :name="2"
                    class="radio_no"
                    :class="{
                      checked: form.info.check_result == 2,
                    }"
                  >
                    <template #icon>
                      <svg class="icon-small" aria-hidden="true">
                        <use xlink:href="#icon-a-emotion-sad-fill1"></use>
                      </svg>
                    </template>
                    不合格
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <span class="label-name">备注说明</span>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="若验收不合格写明具体不合格的事项与整改完成时间..."
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v)"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Popup, DatetimePicker } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);

const props = defineProps(['data']);
const state = reactive({
  //-默认选项
  initData: {},
  //-时间控件
  // minDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  console.log(props.data);
  emit('update:data', form.info);
});

// @-时间控件
const openDateType = (data) => {
  state.showTimePicker = true;
  state.initData = data;
  if (data.time) {
    state.currentDate = new Date(data.time);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  state.showTimePicker = false;
  state.initData.time = moment(value).format('YYYY-MM-DD');
};
const radioClick = (data) => {
  if (data.value === 2 && data.time) {
    data.time = null;
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
.radio_box {
  padding: 0 15px;
  .radio_item {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
    &:last-child {
      border-bottom: 0px;
    }
    .name {
      padding: 15px 0 10px 0;
    }
    .icon-small {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
    //-单选框样式自定义
    :deep(.van-radio-group--horizontal) {
      display: block;
      width: 100%;
      margin: 0;
    }
    :deep(.van-radio) {
      float: left;
      width: calc(50% - 50px);
      height: 44px;
      border-radius: 5px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      margin-right: 10px;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-right: 0px;
      }
    }
    :deep(.van-radio__icon) {
      height: auto;
    }
    :deep(.van-radio__label) {
      font-size: 15px;
    }
    .radio_no {
      color: #ea0000;
      :deep(.van-radio__icon) {
        fill: #ea0000;
      }
      :deep(.van-radio__label) {
        color: #ea0000;
      }
      &.checked {
        background: rgba(234, 0, 0, 0.1);
        border: 1px solid #ea0000 !important;
      }
    }
    .radio_ok {
      color: #0256ff;
      :deep(.van-radio__icon) {
        fill: #0256ff;
      }
      :deep(.van-radio__label) {
        color: #0256ff;
      }
      &.checked {
        background: #e0eaff;
        border: 1px solid #0256ff !important;
      }
    }
  }
}
</style>
