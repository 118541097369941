<!--
 * @Author: Jessica
 * @Date: 2023-07-05 16:54:52
 * @LastEditTime: 2023-07-18 16:04:34
 * @Description: V1.4.1 【模版】设备电报价单/升级选项报价
-->
<template>
  <!-- 加载状态 -->
  <Overlay :show="_DATA.loading" class="overlay-fff">
    <Loading color="#1989fa" type="spinner"></Loading>
  </Overlay>
  <div
    class="content"
    :class="{
      pb_54:
        props.data.type == 'edit' && allOperationAuthority.indexOf('480') > -1,
    }"
  >
    <!-- 客户信息 -->
    <div class="baseInfo bg_white mb_15">
      <div class="item">
        <label>客户姓名</label>
        <span>{{ _DATA.info.customer_name || '-' }}</span>
      </div>
      <div class="item">
        <label>项目地址</label>
        <span>{{ _DATA.info.estate_str || '-' }}</span>
      </div>
      <div class="item">
        <label>客户编号</label>
        <span>{{ _DATA.info.customer_no || '-' }}</span>
      </div>
    </div>
    <!-- 项目明细 -->
    <div class="lists bg_white mb_15">
      <h2 class="title">
        {{
          props.data.name == '设备电报价单' ? '设备电报价项目' : '升级选报项目'
        }}
      </h2>
      <div class="items" v-if="_DATA.info.list?.length">
        <div class="item" v-for="(item, index) in _DATA.info.list" :key="index">
          <label>项目{{ index + 1 }}</label>
          <div class="detail">
            <p class="t1">{{ item.project_name || '-' }}</p>
            <p class="t2">
              <span
                >差价
                {{ item.project_cost ? '+' + item.project_cost : '-' }}</span
              >
              <span>
                | 工程量
                {{
                  item.num
                    ? item.unit_name
                      ? item.num + item.unit_name
                      : item.num
                    : '-'
                }}
              </span>
            </p>
            <p class="t3">合计 {{ item.subtotal || '-' }}</p>
            <p
              class="btn"
              v-if="
                props.data.type == 'edit' &&
                allOperationAuthority.indexOf('480') > -1
              "
              @click.stop="removeFun(item.id)"
            >
              移除
            </p>
          </div>
        </div>
      </div>
      <div class="items" v-else><Empty description="暂无选报项目" /></div>
    </div>
    <!-- 总价明细 -->
    <div>
      <h2 class="title bg_white">总价明细</h2>
      <TempQuotationFlow :data="_DATA.info.total_info" />
    </div>
  </div>
  <!-- 编辑入口 -->
  <div
    class="stick-box-small flex"
    v-if="
      props.data.type == 'edit' && allOperationAuthority.indexOf('480') > -1
    "
    @click.stop="linkTo()"
  >
    <svg class="icon-small" aria-hidden="true">
      <use xlink:href="#icon-edit-2-fill"></use>
    </svg>
    <span class="btn pl_10">编辑</span>
  </div>
</template>
<script setup>
import { computed, defineProps, inject, onMounted, reactive } from 'vue';
import { Empty, Notify, Overlay, Loading, Dialog } from 'vant';
import { useRouter } from 'vue-router';
import TempQuotationFlow from '@/components/design/TempQuotationFlow'; //-总价明细
const router = useRouter();
const $http = inject('$http');
import { useStore } from 'vuex';
const store = useStore();
// 权限
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const props = defineProps(['data']);
const _DATA = reactive({
  loading: false,
  info: {},
});

onMounted(() => {
  loadFunc();
});
/**
 * 接口调用
 */
// 列表
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .getChooseQuoteInfo({
      //报价类型：1设备电；2升级选项
      type: props.data?.class,
      customer_id: props.data?.customer_id,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.info = data;
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

// 移除项目明细
const removeFun = (id) => {
  Dialog.confirm({
    message: `<p style="font-size:16px">确定要移除该项目吗？</p>`,
    allowHtml: true,
  }).then(() => {
    $http
      .getChooseQuoteRemove({
        id: id,
        customer_id: props.data?.customer_id,
        type: props.data?.class,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          loadFunc();
          Notify({
            type: 'success',
            message: '操作成功！',
          });
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
  });
};

//---跳转
const linkTo = () => {
  let url = '';
  if (props.data?.class == 1) {
    url = '/deviceQuotationEdit';
  } else if (props.data?.class == 2) {
    url = '/upgradeQuotationEdit';
  } else return;
  router.push({
    path: url,
    query: {
      id: props.data?.customer_id,
      type: 'edit',
    },
  });
};
//--------end----------------
</script>

<style lang="less" scoped>
.baseInfo {
  padding: 20px 20px 15px;
  .item {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    > label {
      width: 80px;
      font-size: 15px;
      color: #8d9091;
      text-align: left;
    }
    > span {
      width: calc(100% - 80px);
      font-size: 15px;
      text-align: right;
      color: #000000;
      display: inline-block;
    }
  }
}
.lists {
  .item {
    display: flex;
    padding: 15px 16px 15px;
    > label {
      width: 60px;
    }
    .detail {
      width: calc(100% - 60px);
      padding-right: 20px;
      .t1,
      .t3 {
        font-size: 16px;
        color: #000000;
        margin-bottom: 10px;
      }
      .t2 {
        font-size: 16px;
        color: #8d9091;
        margin-bottom: 10px;
      }
    }
  }
}

.title {
  height: 40px;
  line-height: 40px;
  padding: 0px 16px;
  font-size: 14px;
  color: #8e8e8e;
  border-bottom: 1px solid #f5f5f5;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-small {
  width: 20px;
  height: 20px;
  fill: #576b95;
}
.btn {
  font-size: 16px;
  color: #576b95;
  cursor: pointer;
}
.mb_15 {
  margin-bottom: 15px;
}
.pl_10 {
  padding-left: 10px;
}
.pb_54 {
  padding-bottom: 54px;
}
.bg_white {
  background: #ffffff;
}
</style>
