<!--
 * @Author: NanNan
 * @Date: 2022-08-24 10:15:27
 * @LastEditTime: 2022-08-31 14:45:41
 * @Description: v1.1.0 合同作废详情页
-->
<template>
  <!-- 加载状态 -->
  <Overlay :show="_DATA.loading" class="overlay-fff">
    <Loading color="#1989fa" type="spinner"></Loading>
  </Overlay>
  <!-- end -->
  <div class="page-box" v-if="!_DATA.loading">
    <!-- 状态栏 -->
    <div
      class="status-box"
      v-for="(item, index) in _DATA.status_list"
      :key="index"
    >
      <h1 :class="item.class" v-if="item.status === _DATA.info.status">
        <img :src="item.src" alt="" />
        {{ item.name }}
      </h1>
    </div>
    <!-- 作废信息 -->
    <div class="content-box">
      <h2>作废信息</h2>
      <ul>
        <li>
          <label>作废合同</label>
          <span>{{ _DATA.info.contract_no || '-' }}</span>
        </li>
        <li>
          <label>签订日期</label>
          <span>{{ _DATA.info.signed_up_time || '-' }}</span>
        </li>
        <li>
          <label>作废类型</label>
          <span>{{ _DATA.info.invalid_type_name || '-' }}</span>
        </li>
        <li>
          <label>备注</label>
          <span>
            {{ _DATA.info.remark || '-' }}
          </span>
        </li>
        <li>
          <label>附件</label>
          <span v-if="_DATA.info.files?.length > 0">
            <a style="color: #3471ff" @click="lookFile()">查看附件</a>
          </span>
          <span v-else>-</span>
        </li>
      </ul>
    </div>
    <!-- 操作按钮 -->
    <div
      class="oprate-box"
      v-for="(item, index) in _DATA.status_list"
      :key="index"
    >
      <Row
        v-if="item.status === _DATA.info.status && item.oprate?.length > 0"
        class="row"
      >
        <Col
          :span="24 / item.oprate?.length"
          v-for="val in item.oprate"
          :key="val"
          class="col"
          @click.stop="handleClick(val)"
        >
          {{ val }}
        </Col>
        <span class="split" v-if="item.oprate?.length === 2"></span>
      </Row>
    </div>
  </div>
  <!-- 全部附件 -->
  <FileAll
    ref="fileAll"
    :data="_DATA.info.files"
    :isShow="_DATA.showAllFile"
    @closePop="
      () => {
        _DATA.showAllFile = false;
      }
    "
  ></FileAll>
</template>
<script setup>
import FileAll from '@/components/common/FileAll';
import { onMounted, reactive, inject } from 'vue';
import { useRouter } from 'vue-router';
import {
  Notify,
  // Button,
  // Icon,
  Row,
  Col,
  Dialog,
  // List,
  // PullRefresh,
  // Divider,
  Overlay,
  Loading,
} from 'vant';
const $http = inject('$http');
const router = useRouter();

const _DATA = reactive({
  loading: false,
  showAllFile: false,
  info: {},
  status_list: [
    {
      name: '审核中...',
      status: 1,
      class: 'yellow',
      src: require('@/assets/images/icon-wait.png'),
      oprate: ['取消'],
    },
    {
      name: '已通过',
      status: 2,
      class: 'green',
      src: require('@/assets/images/icon-pass.png'),
      oprate: [],
    },
    {
      name: '未通过',
      status: 3,
      class: 'red',
      src: require('@/assets/images/icon-no.png'),
      oprate: ['重新编辑'],
    },
    {
      name: '已取消',
      status: 4,
      class: 'gray',
      src: require('@/assets/images/icon-cancel2.png'),
      oprate: ['重新编辑'],
    },
  ],
});

onMounted(() => {
  loadInfo();
});

/**
 * 接口调用
 */
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .getContractInvalidInfo({
      contract_id: router.currentRoute.value.query.cdid,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        console.log(data.list);
        _DATA.info = data.list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      _DATA.loading = false;
    });
};

/**
 * 操作
 */
// -@点击查看全部附件
const lookFile = () => {
  _DATA.showAllFile = true;
};
// -@重新编辑、取消
const handleClick = (val) => {
  if (val === '取消') {
    Dialog.confirm({
      message: '确定要取消申请吗？',
    })
      .then(() => {
        $http
          .getTaskApplyCancel({ task_id: _DATA.info.task_id })
          .then((res) => {
            const { data, code } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
              });
              loadInfo();
            } else {
              Notify({ type: 'warning', message: data });
              loadInfo();
            }
          });
      })
      .catch(() => {
        Notify({
          type: 'warning',
          message: '取消操作',
        });
      });
  } else if (val === '重新编辑') {
    router.push({
      path: '/contractCancel',
      query: {
        cdid: router.currentRoute.value.query.cdid,
        id: router.currentRoute.value.query.id,
      },
    });
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/contractCancellation.less';
</style>
