<!--
 * @Author: Jessica
 * @Date: 2024-05-28 10:12:11
 * @LastEditTime: 2024-06-14 11:05:56
 * @Description: V1.6.1 施工团队
-->
<template>
  <div class="content-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <!-- 施工团队 -->
    <div v-for="(item, index) in _DATA.list_data" :key="index" class="same-box">
      <div class="con-box">
        <dl class="cus-message">
          <dt>
            <lazy-component>
              <Image
                v-if="item.face_image"
                :src="
                  item.face_image.includes('http')
                    ? item.face_image
                    : $host + item.face_image
                "
                v-lazy
                fit="cover"
                round
              />
              <img
                :src="require('@/assets/images/icon-empty-img.png')"
                v-else
              />
            </lazy-component>
          </dt>
          <dd>
            <div class="flex_between">
              <div>
                <p class="name">{{ item.name + ' · ' + item.phone }}</p>
                <p class="depart">
                  {{ item.work_type_names }}
                </p>
              </div>
              <!-- 离职标签 -->
              <div class="tag" v-if="item.status == 2">离职</div>
            </div>
          </dd>
        </dl>
      </div>
    </div>

    <!-- 空数据 -->
    <div
      class="empty-box"
      v-if="!_DATA.loading && _DATA.list_data?.length == 0"
    >
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script setup>
import { inject, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Notify, Image, Loading, Overlay } from 'vant';
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const _DATA = reactive({
  list_data: [],
  loading: false,
});

onMounted(() => {
  loadFunc();
});
/**
 * 接口调用
 */
//@施工团队-列表
const loadFunc = () => {
  _DATA.loading = true;
  $http
    .construction_projectMembers({
      customer_id: router.currentRoute.value.query.id,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list_data = data.work_teams;
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
</script>
<style lang="less" scoped>
.content-box {
  padding: 0 0;
  background: #f5f5f5;
  padding-top: 10px;
}
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
// 施工团队
.same-box {
  background: #fff;
  border-bottom: 1px solid rgba(228, 228, 228, 0.3);
  dl.cus-message {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 20px;

    dt {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      flex-grow: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        width: 35px;
        height: 35px;

        & > div {
          width: 35px;
          height: 35px;
        }
      }

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }

    dd {
      flex-grow: 1;
      .tag {
        background: #dcdcdc;
        padding: 2px 5px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #999999;
        line-height: 20px;
      }
      .name {
        padding-bottom: 3px;
        font-size: 15px;
      }

      .depart {
        font-size: 13px;
        color: #8d9091;
      }
    }
  }
}
.empty-box {
  height: calc(100vh - 150px);
  padding: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #979797;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 400;
    margin-top: 15px;
  }
}
</style>
