/*
 * @Author: gest
 * @Date: 2022-07-21 09:13:09
 * @LastEditTime: 2022-09-09 17:15:01
 * @Description:
 */
import { createStore } from 'vuex';

import AccountStore from './modules/account'; //账号，权限相关
import BpmStore from './modules/BPM'; //BPM

//好吧，我要开始创建一个新的 store 实例
export const store = createStore({
  modules: {
    account: AccountStore,
    BPM: BpmStore,
  },
});
