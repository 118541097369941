<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-02-03 09:37:14
 * @Description: 2024/02/02-紧急需求：优选施工合同--附件带上施工报价
-->
<template>
  <!-- 基本信息 -->
  <div class="temp">
    <h1>合同基础信息</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同类型
            </label>
            <van-field
              v-model="form.name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择合同类型',
                },
              ]"
              placeholder="请选择合同类型"
              @click="showTypePicker = true"
            />
            <van-popup v-model:show="showTypePicker" position="bottom">
              <van-picker
                :columns="typeList"
                :columns-field-names="customFieldName"
                @confirm="onConfirm"
                @cancel="showTypePicker = false"
                @change="onChangePick"
                :default-index="
                  typeList.findIndex((v) => {
                    return v.id == form.type;
                  })
                "
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <van-field
              v-model="form.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加合同备注..."
              show-word-limit
            />
          </div>
          <!-- 上传类型--施工合同只支持小于40M的pdf -->
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              v-if="form.type == 54"
              :type="5"
              :data="form.file_url"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
            <FileUpload
              v-else
              :data="form.file_url"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
  </div>
</template>
<script>
import FileUpload from '@/components/common/FileUpload';

import { reactive, toRefs, ref } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Uploader,
  Button,
  Image,
  Icon,
} from 'vant';
import { Toast } from 'vant';
export default {
  props: ['type', 'data', 'parameterList', 'parameterListForQuoteOrder'],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Image.name]: Image,
    [Icon.name]: Icon,
    FileUpload,
  },
  setup(props, { emit }) {
    // const $http = inject('$http');
    // const $host = inject('$host');
    const state = reactive({
      type: props.type,
      //-合同类型参数
      showTypePicker: false,
      //-2023-03-03 紧急隐藏添加家居产品委托代购协议入口
      typeList: props.parameterList
        ? props.parameterList.contract_type.filter((v) => {
            return v.id != 53;
          })
        : [],
      //-end
    });

    //--基本信息表单---------------------
    const ruleFormRef = ref(null);
    const form = reactive({
      name: props.data.name ? props.data.name : '',
      type: props.data.type ? props.data.type : '',
      remarks: props.data.remarks ? props.data.remarks : '',
      file_url: props.data.file_url?.length ? props.data.file_url : [],
      charge_proportions: props.data.charge_proportions
        ? props.data.charge_proportions
        : [],
    });
    //-picker-合同类型选择项字段
    const customFieldName = {
      text: 'name',
      value: 'id',
    };
    const onConfirm = (value) => {
      //-家庭居室装饰装修工程施工合同、优选施工合同--附件带上施工报价
      if (value.id == 54 || value.id == 56) {
        form.file_url = props.parameterListForQuoteOrder.files ?? [];
      } else {
        form.file_url = [];
      }
      form.name = value.name;
      form.type = value.id;
      form.charge_proportions = value.charge_proportions;
      state.showTypePicker = false;
      emit('resetFun', form);
    };

    //-@附件
    const getFileList = (file_list) => {
      form.file_url = file_list;
    };

    //--上传附件end----------

    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      form,
      onConfirm,
      customFieldName,
      btnSumbit,
      ruleFormRef,
      getFileList,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
