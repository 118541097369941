<!--
 * @Author: gest
 * @Date:  2022-11-01 15:49:53
 * @LastEditTime: 2023-09-06 15:44:16
 * @Description: 通用附件见证件
-->
<template>
  <div class="formBase">
    <CellGroup>
      <div class="cell mb_15">
        <template v-if="props.can_edit == 1">
          <label class="form_title">
            <span class="red_color">*</span>
            上传附件
          </label>
          <FileUpload
            :data="form.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </template>

        <!-- 详情 -->
        <template v-else>
          <p class="mb_15">附件</p>
          <!-- 附件 -->
          <div class="fileList" v-if="form.files && form.files.length > 0">
            <FileGrid :data="form.files"></FileGrid>
          </div>
          <div v-else>-</div>
          <!-- end -->
        </template>
      </div>
      <div class="cell mb_15">
        <template v-if="props.can_edit == 1">
          <label class="form_title">备注说明</label>
          <Field
            v-model="form.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="1000"
            placeholder="有什么需要补充吗..."
            show-word-limit
            @blur="
              () => {
                emit('update:data', form);
              }
            "
          />
        </template>
        <template v-else>
          <p class="desc">备注说明</p>
          <div>{{ form.remark || '-' }}</div>
        </template>
      </div>
    </CellGroup>
  </div>
</template>

<script setup>
import FileGrid from '@/components/common/FileGrid';
import FileUpload from '@/components/common/FileUpload';

import { reactive, defineProps, defineEmits, watch } from 'vue';
import { CellGroup, Field } from 'vant';

const emit = defineEmits(['update:data']);
const props = defineProps(['data', 'can_edit']);

//--基本信息表单---------------------
const form = reactive({
  files: props.data ? props.data.files : [],
  remark: props.data ? props.data.remark : '',
});
//--基本信息表单-----end----------------
watch(
  () => props.data,
  (cur) => {
    console.log(cur);
    form.files = cur.files ? cur.files : [];
    form.remark = cur.remark;
  }
);

//-@附件
const getFileList = (files) => {
  form.files = files;
  emit('update:data', form);
};

//--上传附件end----------
</script>

<style lang="less" scoped>
.formBase {
  :deep(.van-cell-group) {
    background-color: #f5f5f5;
  }
  .mt_15 {
    margin-top: 15px;
  }
  .cell {
    background-color: #ffffff;
    padding: 20px;
    .form_title {
      padding-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      display: inline-block;
    }
    .desc {
      font-size: 15px;
      line-height: 21px;
      color: #000;
    }
  }
}
</style>
