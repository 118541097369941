<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-06-14 11:07:50
 * @Description: 
-->
<template>
  <!-- 项目成员 -->
  <div class="member">
    <!-- 展示选择的项目成员列表 -->
    <div class="temp" v-if="isList">
      <h1 style="margin-bottom: 20px">项目成员</h1>
      <!-- V1.5.3 是否指派去除 -->
      <!-- <van-form
        ref="ruleFormRef"
        class="mb_20"
        v-if="
          construction_return_customer == 0 &&
          (contractType == 56 || contractType == 54)
        "
      >
        <div
          style="
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 5px;
          "
        >
          <span style="color: #ea3d2f">*</span>
          是否指派
        </div>
        <div
          style="
            font-size: 12px;
            letter-spacing: 0em;
            color: #8d9091;
            margin-bottom: 5px;
          "
        >
          若需指派交付中心需走线下指派流程：客户经理>中心经理>总监>总经理>交付中心经理，请谨慎选择
        </div>
        <van-field
          name="radio"
          style="border: none; padding: 0"
          :rules="[
            {
              required: true,
              message: '请选择是否指派',
            },
          ]"
        >
          <template #input>
            <van-radio-group style="width: 100%" v-model="form.is_assign">
              <van-radio
                name="1"
                style="
                  color: #000000;
                  padding: 17px 0;
                  border-bottom: 1px solid #efefef;
                "
                >是</van-radio
              >
              <van-radio
                name="0"
                style="
                  color: #000000;
                  padding: 17px 0;
                  border-bottom: 1px solid #efefef;
                "
                >否</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
      </van-form> -->
      <div class="formBase">
        <div class="member_list">
          <div
            class="member_item clearfix"
            v-for="(item, index) in form.list"
            :key="index"
          >
            <van-image
              class="pull-left imgIcon"
              round
              :src="
                item.face_image
                  ? item.face_image.includes('http')
                    ? item.face_image
                    : $host + item.face_image
                  : require('@/assets/images/icon-empty-img.png')
              "
            />

            <div class="pull-left detail">
              <p class="name">{{ item.name }}</p>
              <p class="extra">
                {{ item.dep_name + ' / ' + item.post_name }}
              </p>
            </div>
            <van-icon
              class="pull-left icon"
              name="cross"
              @click.stop="delMember(item)"
            />
          </div>
          <div class="add" @click="getCheckedFun()">
            <van-icon name="plus" style="color: #999999; margin-right: 10px" />
            添加项目成员
          </div>
        </div>
      </div>
      <div class="btnSumbit">
        <van-button class="btn" type="primary" size="large" @click="btnSumbit">
          完成
        </van-button>
      </div>
    </div>
  </div>
  <!-- 项目成员 -->
  <van-popup
    v-model:show="memberShow"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <MemberList
      v-if="memberShow"
      :data="form.list"
      @reviewClose="reviewClose"
      @reviewOk="reviewOk"
    />
  </van-popup>
</template>
<script>
import { reactive, toRefs, inject, ref } from 'vue';
import {
  Button,
  Icon,
  Image,
  Popup,
  ActionSheet,
  Form,
  Field,
  CellGroup,
  Notify,
  RadioGroup,
  Radio,
} from 'vant';
import MemberList from '@/components/contract/MemberList';
// import { Toast } from 'vant';
export default {
  props: [
    'type',
    'data',
    'contractType',
    'parameterListForCustomer',
    'customer_id',
    'basicInfo',
  ],
  components: {
    MemberList,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [ActionSheet.name]: ActionSheet,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Notify.name]: Notify,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      isList: true,
      memberShow: false,
      construction_return_customer:
        props.parameterListForCustomer.construction_return_customer,
      contractType: props.contractType,
    });

    const ruleFormRef = ref(null);
    const form = reactive({
      list: props.data ? props.data : [],

      // is_assign:
      //   props.basicInfo.is_assign == 0 || props.basicInfo.is_assign == 1
      //     ? props.basicInfo.is_assign + ''
      //     : null,
    });

    //-删除
    const delMember = (item) => {
      form.list = form.list.filter((v) => v.id != item.id);
    };

    //-编辑项目成员
    const getCheckedFun = () => {
      state.memberShow = true;
    };

    //-保存项目成员popup
    const reviewOk = (data) => {
      form.list = data;
      state.memberShow = false;
    };

    //-关闭项目成员popup
    const reviewClose = () => {
      state.memberShow = false;
    };

    //-下一步操作-打开风险弹窗
    const btnSumbit = () => {
      console.log(state.construction_return_customer);
      console.log(state.contractType == 56);
      if (
        state.construction_return_customer == 0 &&
        (state.contractType == 56 || state.contractType == 54)
      ) {
        // if (!ruleFormRef.value) return;
        // ruleFormRef.value
        //   .validate()
        //   .then(() => {
        emit('reviewFun', state.type, form);
        // })
        // .catch(() => {
        //   //验证失败
        //   Toast('请先完善信息');
        // });
      } else {
        emit('reviewFun', state.type, form);
      }
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      btnSumbit,
      delMember,
      getCheckedFun,
      reviewClose,
      reviewOk,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
