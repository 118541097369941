<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2023-02-22 10:23:21
 * @Description: v0.3.0 施工 - 确认验收
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="node-name-box">
      <em>节点名称：</em>
      {{ node_name || '-' }}
    </div>
    <Form class="form-box" ref="formRef">
      <Field
        v-model.trim="form_info.remarks"
        rows="10"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加备注..."
        style="border: none; background: #f0f0f0"
      ></Field>
      <!-- end -->
      <div class="stick-box">
        <Row>
          <Col span="11">
            <Button
              type="danger"
              size="large"
              class="button-danger"
              @click="onSubmit(2)"
            >
              <Icon name="cross" />
              不通过
            </Button>
          </Col>
          <Col span="2"></Col>
          <Col span="11">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="onSubmit(1)"
            >
              <Icon name="success" />
              通过
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import {
  Form,
  Field,
  Overlay,
  Loading,
  Button,
  Notify,
  Toast,
  Icon,
  Row,
  Col,
} from 'vant';

const $http = inject('$http');
const router = useRouter();
const node_name = ref(router.currentRoute.value.query.nname); //携带 节点名称
const node_id = ref(router.currentRoute.value.query.nid); //节点id

const form_default = reactive({
  node_id: node_id.value, //*设计节点id
  status: 1, //1 通过 2 拒绝
  remarks: '', //*备注
});
const form_info = reactive({});
const loading = ref(false);

/**
 * 初始化
 */
onMounted(() => {
  Object.assign(form_info, form_default);
});

/**
 * 操作
 */
//-@ 提交
const formRef = ref(null);
const onSubmit = (status) => {
  if (!form_info.remarks) {
    Toast('请填写备注');
    return false;
  }
  formRef.value?.validate().then(() => {
    loading.value = true;
    form_info.status = status;
    $http.getConConfirmInspectedApi(form_info).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loading.value = false;
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
  });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;

  .node-name-box {
    padding: 20px;
    font-size: 16px;
    line-height: 18px;
    background: #f5f5f5;
    text-align: center;

    em {
      color: #999;
    }
  }

  .form-box {
    padding: 20px 20px 95px;
  }
}
</style>
