<!--
 * @Author: NanNan
 * @Date: 2022-04-19 15:45:34
 * @LastEditTime: 2024-05-23 15:58:23
 * @Description: 合同退款
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <Form class="form-box" ref="formRef">
      <!-- 合同头部信息 -->
      <div class="mess-top" v-if="!payment_id">
        <p class="label-name white">
          <em>*</em>
          退款合同
        </p>
        <Field
          v-model="form_info.contract_str"
          right-icon="arrow-down"
          readonly
          placeholder="选择退款合同"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择退款合同' },
          ]"
          @click="contract_show = true"
        />
        <p class="contract-id">合同编号：{{ form_info.contract_no }}</p>
      </div>
      <!-- 退款期数 -->
      <div class="qi-box" v-if="!payment_id">
        <p class="label-name">
          <em>*</em>
          退款期数
        </p>
        <Field
          class="noBorder"
          name="validatorMessage"
          :rules="[
            {
              required: true,
              validator,
              message: '请选择退款合同',
            },
          ]"
        >
          <template #input>
            <RadioGroup
              v-model="form_info.payment_id"
              direction="horizontal"
              style="width: 100%"
            >
              <div
                class="width50"
                v-for="item in form_info.payment_periods"
                :key="item.payment_id"
              >
                <Radio
                  :name="item.payment_id"
                  @click="changeRadio(item)"
                  shape="square"
                  style="margin-bottom: 15px"
                >
                  {{ item.name }}
                </Radio>
              </div>
            </RadioGroup>
          </template>
        </Field>
      </div>
      <!--合同信息头部、退款期数 携带过来 -->
      <div class="mess-top-dis" v-if="payment_id">
        <p class="contract-name">{{ form_info.contract_str }}</p>
        <p class="contract-id">合同编号：{{ form_info.contract_no }}</p>
        <div>
          <p class="qi-flex">
            <em>退款期数</em>
            <span>{{ form_info.payment_period }}</span>
          </p>
        </div>
      </div>
      <!-- 详细表单信息 填写 -->
      <div class="detail-box" :class="{ pt20: payment_id }">
        <Field
          v-model="form_info.received_money"
          input-align="right"
          name="用户名"
          label="已收金额"
          placeholder="0"
          disabled
        >
          <template #button>元</template>
        </Field>

        <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
        <div
          class="wait-box"
          :class="{
            pd10:
              form_info.income_amount_str || form_info.expenditure_amount_str,
          }"
        >
          <Icon
            name="info-o"
            color="#EA0000"
            v-if="
              form_info.income_amount_str || form_info.expenditure_amount_str
            "
          />
          <p>
            {{
              getString([
                form_info.income_amount_str,
                form_info.expenditure_amount_str,
              ])
            }}
          </p>
        </div>
        <!-- end -->
        <div class="flex_between align_center">
          <p class="label-name">
            <em>*</em>
            实退金额
          </p>
          <!-- 产品款退款显示可用金额 -->
          <p
            class="red_color font_size_14"
            v-if="
              form_info.contract_type == 53 || form_info.contract_type == 69
            "
          >
            可退金额：{{ form_info.available_money }}
          </p>
          <!-- end -->
        </div>

        <Field
          type="number"
          v-model="form_info.amount"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写实退金额"
          :rules="[
            { required: true, trigger: 'onChange', message: '请填写实退金额' },
          ]"
        >
          <template #button>元</template>
        </Field>

        <p class="label-name">
          <em>*</em>
          退款日期
        </p>
        <Field
          v-model="form_info.collection_time"
          name="picker"
          readonly
          placeholder="选择退款日期"
          right-icon="underway-o"
          @click="picker_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择退款日期' },
          ]"
        />

        <p class="label-name">
          <em>*</em>
          退款方式
        </p>
        <Field
          v-model="form_info.collection_method_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择退款方式"
          @click="collect_show = true"
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请选选择退款方式',
            },
          ]"
        />

        <p class="label-name">退款账号</p>
        <Field
          v-model="form_info.collection_account_str"
          readonly
          right-icon="arrow-down"
          placeholder="选择退款账号"
          @click="collect_account_show = true"
        />

        <p class="label-name">票据编号</p>
        <Field
          v-model="form_info.bill_no"
          maxlength="50"
          placeholder="输入票据编号"
        />

        <p class="label-name">手工收据号</p>
        <Field
          v-model="form_info.handwork_no"
          maxlength="50"
          placeholder="输入手工票据编号"
        />

        <!-- v0.6.0 新增 -->
        <p class="label-name">打款人</p>
        <Field
          v-model="form_info.drawee"
          maxlength="30"
          placeholder="输入打款人"
        />

        <p class="label-name">手续费</p>
        <Field
          v-model="form_info.service_charge"
          type="number"
          :formatter="formatter"
          format-trigger="onBlur"
          placeholder="填写手续费"
        >
          <template #button>元</template>
        </Field>

        <!-- <p class="label-name">优惠活动</p>
        <Field
          v-model="form_info.activity_name"
          maxlength="50"
          placeholder="-"
          disabled
        /> -->
        <!-- end -->

        <p class="label-name">备注</p>
        <Field
          v-model="form_info.remarks"
          rows="3"
          autosize
          type="textarea"
          maxlength="1000"
          placeholder="有什么需要补充的吗..."
          show-word-limit
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">附件</p>
        <FileUpload
          :data="form_info.file_urls"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <!-- end -->
        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>
    <!-- 合同组件 -->
    <Popup v-model:show="contract_show" position="bottom">
      <Picker
        :columns="options.payment_contract_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.payment_contract_list.findIndex((v) => {
            return v.id == contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="contract_show = false"
      />
    </Popup>
    <!-- 年月日 组件 -->
    <Popup v-model:show="picker_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择退款日期"
        @confirm="changePicker"
        @cancel="picker_show = false"
      />
    </Popup>
    <!-- 退款方式 -->
    <Popup v-model:show="collect_show" position="bottom">
      <Picker
        :columns="options.cash_type"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollect"
        @cancel="collect_show = false"
      />
    </Popup>
    <!-- 退款账号 -->
    <Popup v-model:show="collect_account_show" position="bottom">
      <Picker
        :columns="options.collection_account"
        :columns-field-names="{ text: 'name', value: 'id' }"
        @confirm="changeCollectAccount"
        @cancel="collect_account_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload';

import {
  Form,
  Field,
  RadioGroup,
  Radio,
  Button,
  Notify,
  DatetimePicker,
  Picker,
  Popup,
  Overlay,
  Loading,
  Icon,
} from 'vant';
import moment from 'moment';

const $http = inject('$http');
const router = useRouter();
const customer_id = ref(router.currentRoute.value.query.id);
const contract_id = ref(router.currentRoute.value.query.cid); //携带 合同id
const payment_id = ref(router.currentRoute.value.query.pid); //携带 合同款期id
const options = reactive({
  payment_contract_list: [], // 合同list
  cash_type: [], //退款方式
  collection_account: [], //退款账号
});

const form_default = reactive({
  contract_id: '', //*合同id
  contract_str: '', //合同_str
  contract_no: '', //合同 编号
  payment_id: '', //*款期 单选
  payment_periods: [], //款期集合
  received_money: '', //已收金额 - 联动
  income_amount_str: '',
  expenditure_amount_str: '',
  amount: '', //*退款金额
  collection_time: undefined, //退款时间
  collection_method_id: '', //退款方式
  collection_method_str: '', //退款方式_str
  collection_account_id: '', //退款账号
  collection_account_str: '', //收款账号_str
  bill_no: '', //票据编号
  handwork_no: '', //手工收据号
  drawee: '', //打款人
  service_charge: '', //手续费
  activity_name: '', //优惠活动str
  activity_id: '', //优惠活动id
  remarks: '', //备注
  file_urls: [], //附件集合
  period_type: 1,
});
const form_info = reactive({});

onMounted(() => {
  Object.assign(form_info, form_default);
  loading.value = true;
  loadFunc();
});

/**
 * 接口调用
 */
const loading = ref(false);
const loadFunc = () => {
  $http
    .getContractRefundParameter({ customer_id: customer_id.value })
    .then((res) => {
      const { data, code } = res;
      loading.value = false;
      if (code === 0) {
        options.cash_type = data.cash_type;
        options.payment_contract_list = data.payment_contract_list;
        options.collection_account = data.collection_account;
        // 数据格式替换 - 开始
        if (contract_id.value && !payment_id.value) {
          changeData(data.payment_contract_list);
        } else {
          loadPaymentInfo(payment_id.value);
        }
        loading.value = false;
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};
// @获取款期详情
const loadPaymentInfo = (payment_id) => {
  $http
    .getContractCollectionPaymentInfoApi({
      payment_id: payment_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        // 合同相关
        form_info.contract_id = data.contract_id;
        form_info.contract_str = data.contract_name;
        form_info.contract_no = data.contract_no;
        // 款期相关
        form_info.payment_id = data.payment_id; //款期id
        form_info.payment_period = data.payment_period; //款期str

        form_info.received_money = data.received_money; //已收金额
        form_info.expenditure_amount_str = data.expenditure_amount_str;
        form_info.income_amount_str = data.income_amount_str;
        form_info.period_type = data.period_type; //款期类别：1 款期 2 增减项
        form_info.activity_id = data.activity_id || '';
        form_info.activity_name = data.activity_name || '';
        form_info.contract_type = data.contract_type; //合同类型
        form_info.available_money = data.available_money; //可退金额
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// @数据格式替换
const changeData = (item) => {
  const objArr = item.filter((val) => val.id == contract_id.value);
  const obj = objArr.length ? JSON.parse(JSON.stringify(objArr[0])) : '';
  form_info.contract_id = obj.id || '';
  form_info.contract_str = obj.name || '';
  form_info.contract_no = obj.contract_no || '';
  form_info.payment_periods = [];
  form_info.activity_id = obj.activity_id || '';
  form_info.activity_name = obj.activity_name || '';

  if (obj.chargeProportion) {
    form_info.payment_id = obj.chargeProportion[0].id;
    loadPaymentInfo(obj.chargeProportion[0].id);
    obj.chargeProportion.forEach((val) => {
      var element = {
        ...val,
        payment_id: val.id,
        name: val.payment_period,
      };
      form_info.payment_periods.push(element);
    });
  }
};

/**
 * 操作集合
 */
// @拼接字符串
const getString = (arr) => {
  let strArr = [];
  arr[0] && strArr.push('待审核收款¥' + arr[0]);
  arr[1] && strArr.push('待审核退款¥' + arr[1]);
  return strArr.join('，');
};

// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @选择退款期数
const validator = () => {
  form_info.payment_periods.length ? true : false;
};
const changeRadio = (val) => {
  loadPaymentInfo(val.payment_id);
};

// @选择合同
const contract_show = ref(false);
const changeContract = (value) => {
  contract_id.value = value.id;
  contract_show.value = false;
  Object.assign(form_info, form_default);
  // 变更合同对应数据
  changeData(options.payment_contract_list);
  changeLoading();
};

// @loading加载
const changeLoading = () => {
  loading.value = true;
  setTimeout(() => {
    // 重置
    formRef.value?.resetValidation();
    loading.value = false;
  }, 500);
};

// @退款日期
const picker_show = ref(false);
const currentDate = ref(new Date());
const changePicker = (value) => {
  picker_show.value = false;
  form_info.collection_time = moment(value).format('YYYY-MM-DD');
};

// @退款方式
const collect_show = ref(false);
const changeCollect = (value) => {
  collect_show.value = false;
  form_info.collection_method_id = value.id;
  form_info.collection_method_str = value.name;
};

// @退款账号
const collect_account_show = ref(false);
const changeCollectAccount = (value) => {
  collect_account_show.value = false;
  form_info.collection_account_id = value.id;
  form_info.collection_account_str = value.name;
};

// @附件
const getFileList = (file_list) => {
  form_info.file_urls = file_list;
};

// @提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    // 格式变化
    const parmars = Object.assign({}, form_info);
    if (parmars.period_type == 1) {
      // 普通退款
      $http
        .getsContractRefundApi({ ...parmars })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                loading.value = false;
                router.back();
              },
            });
          } else {
            loading.value = false;
            Notify({ type: 'warning', message: data });
          }
        })
        .catch(() => {
          loading.value = false;
        });
    } else {
      // 增减项退款
      $http
        .getContractContractContainRefundApi({ ...parmars })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                loading.value = false;
                router.back();
              },
            });
          } else {
            loading.value = false;
            Notify({ type: 'warning', message: data });
          }
        })
        .catch(() => {
          loading.value = false;
        });
    }
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/return.less';
</style>
