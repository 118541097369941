<!--
 * @Author: NanNan
 * @Date: 2023-03-21 14:55:33
 * @LastEditTime: 2024-03-06 11:17:57
 * @Description: 线索模块 - 人员选择组件
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">{{ _DATA.title }}</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="_DATA.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup
        v-model="_DATA.checked"
        v-if="_DATA.list.length"
        :loading="_DATA.loading"
      >
        <cell v-for="item in _DATA.list" :key="item.id">
          <div class="member_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="member_item">
                <p class="name">
                  {{
                    `${item.name}[${item.company_name}/${item.dep_name}/${item.post_name}]`
                  }}
                </p>
                <p class="tag" v-if="item.status == 2">已离职</p>
              </div>
            </Radio>
          </div>
        </cell>
      </RadioGroup>
      <Empty
        v-if="!_DATA.loading && !_DATA.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, defineEmits, defineProps, onMounted } from 'vue';
import { Button, Search, Cell, Empty, Radio, RadioGroup } from 'vant';

import { Toast } from 'vant';
const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['data', 'kw', 'check', 'auth']);
const $http = inject('$http');

const _DATA = reactive({
  title: props.data ? props.data : '',
  loading: true,
  kw: '',
  list: [],
  checked: props?.check || '',
  checkedItem: {},
});

onMounted(() => {
  console.log('???');
  formatKwFunc();
  searchFun();
});

/**
 * 接口
 */
// -- 获取人员
const onLoad = () => {
  _DATA.loading = true;
  // const httpRequest =
  //   props?.auth == 1
  //     ? $http.publicUserListParameterByAuth({
  //         kw: _DATA.kw,
  //         page: 1,
  //         pages: 1000,
  //       })
  //     : $http.getMemberList({
  //         kw: _DATA.kw,
  //         page: 1,
  //         pages: 1000,
  //       });
  const httpRequest = $http.getMemberList({
    kw: _DATA.kw,
    page: 1,
    pages: 1000,
  });
  httpRequest.then((res) => {
    _DATA.loading = false;
    if (res.code === 0) {
      _DATA.list = res.data.list;
      _DATA.checkedItem = props?.check
        ? res.data.list.filter((item) => item.id == props?.check)?.[0]
        : {};
    } else {
      _DATA.list = [];
      Toast(res.msg || res.data);
    }
  });
};

/**
 * 操作
 */
// -- 关键词初始化
const formatKwFunc = async () => {
  if (props?.kw) {
    _DATA.kw = props?.kw.split('[')[0];
  }
};

// -- 关键字搜索
const searchFun = () => {
  _DATA.list = [];
  if (_DATA.kw) {
    onLoad();
  }
};

const getClick = (item) => {
  _DATA.checked = item.id;
  _DATA.checkedItem = item;
};

// -- 确认
const btnSumbit = () => {
  emit('reviewOk', _DATA.checkedItem);
};

// -- 清除
const clear = () => {
  _DATA.checkedItem = {};
  emit('reviewOk', _DATA.checkedItem);
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
.member_item {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
  .tag {
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    background: #eeeeee;
    padding: 0 5px;
    color: #767676;
    border-radius: 2px;
    margin-top: 4px;
  }
}
:deep(.van-cell) {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
:deep(.van-search) {
  padding: 0px !important;
}
:deep(.van-radio) {
  align-items: flex-start;
}
</style>
