<!--
 * @Author: gest
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-29 18:30:39
 * @Description:V1.6.0协议  
 // 进度条顶部 type  //1有均值 2目标天数
 //进度条type 1 默认对比（总数） 2绿+蓝（阶段/存量）  3蓝色单条 4红色单条        
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top border_bottom">
          <div class="title flex_line_between">
            协议
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDangerHtml">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!-- 协议目标/当年完成/完成率 -->
          <Row>
            <Col span="8">
              <div class="grey align_center">协议目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.info.total?.target === ''
                      ? '-'
                      : _DATA.info.total?.target
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info.total?.actual }}
                </span>
              </div>
              <div v-if="_DATA.info.total?.gap !== ''">
                <div
                  class="flex_line flex_line_center"
                  v-if="_DATA.info.total?.actual > _DATA.info.total?.target"
                >
                  <span class="bold green">{{ _DATA.info.total?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_line_center"
                  v-else-if="
                    _DATA.info.total?.actual < _DATA.info.total?.target
                  "
                >
                  <span class="bold orange">{{ _DATA.info.total?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_line_center" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">成功率</div>
              <!-- 差值 -->
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info.success?.actual + '%' }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div class="data_card_table_bottom" v-if="_DATA.open">
          <Row class="mb_15">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="11" style="position: relative">
              <div class="chart_pos">
                <!-- 左侧文案位置-不一定存在 -->
                <div class="chart_left"></div>
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="4">
              <div class="grey align_center">完成率</div>
            </Col>

            <Col span="4">
              <div class="grey align_center">成功率</div>
            </Col>
          </Row>
          <Collapse
            v-model="_DATA.collapse_active"
            ref="collapseGroupRef"
            :accordion="false"
            :border="false"
          >
            <div
              class="sub_list"
              v-for="(item, index) in _DATA.list"
              :key="index"
            >
              <CollapseItem
                :name="index"
                :is-link="false"
                v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              >
                <template #title>
                  <Row class="mb_20" style="align-items: center; height: 100%">
                    <Col span="5">
                      <div
                        class="size13"
                        style="display: flex; align-items: center"
                      >
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) == -1"
                          style="transform: rotate(0deg)"
                          class="arrow"
                        />
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) > -1"
                          style="transform: rotate(90deg)"
                          class="arrow"
                        />
                        <p>{{ item.short_name }}</p>
                      </div>
                    </Col>
                    <!-- 进度图内容 begin-->
                    <Col span="11" class="chart_item">
                      <div style="display: flex">
                        <div class="chart_left lineheight13">线上</div>
                        <chartJD
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            actual: item.online.actual,
                            target: item.online.target
                              ? item.online.target
                              : item.online.actual,
                          }"
                        ></chartJD>
                        <div class="chart_right">
                          {{
                            item.online?.target === ''
                              ? '-'
                              : item.online?.target
                          }}
                        </div>
                      </div>

                      <div style="display: flex; margin-top: 2px">
                        <div class="chart_left lineheight13">自主</div>
                        <chartJD
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            actual: item.diy.actual,
                            target: item.diy.target
                              ? item.diy.target
                              : item.diy.actual,
                          }"
                        ></chartJD>
                        <div class="chart_right">
                          {{ item.diy?.target === '' ? '-' : item.diy?.target }}
                        </div>
                      </div>
                      <!-- 竖线 begin-->
                      <div class="chart_pos">
                        <div class="chart_left"></div>
                        <chartCoverLine
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            num: _DATA.middle_value,
                          }"
                        ></chartCoverLine>
                        <div class="chart_right"></div>
                      </div>
                      <!-- 竖线 end-->
                    </Col>
                    <!-- 进度图内容 end-->
                    <Col span="4">
                      <!-- 完成率 -->
                      <div>
                        <span class="lineheight16 align_center">
                          {{
                            item.online?.done_rate === ''
                              ? '-'
                              : item.online?.done_rate + '%'
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="lineheight16 align_center">
                          {{
                            item.diy?.done_rate === ''
                              ? '-'
                              : item.diy?.done_rate + '%'
                          }}
                        </span>
                      </div>
                    </Col>

                    <Col span="4">
                      <div>
                        <span class="align_center">
                          {{ item.success_rate + '%' }}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </template>
                <Row
                  class="mb_10 mt_10"
                  v-show="item.sub_list && item.sub_list.length > 0"
                >
                  <Col span="5">
                    <div class="grey pr_10">
                      {{ props.data.depId ? '人员' : '部门' }}
                    </div>
                  </Col>

                  <Col span="7">
                    <div class="grey align_center ml_20">协议</div>
                  </Col>
                  <Col span="4">
                    <div class="grey align_center">差值</div>
                  </Col>
                  <Col span="4">
                    <div class="grey align_center">完成率</div>
                  </Col>
                  <Col span="4">
                    <div class="grey align_center">成功率</div>
                  </Col>
                </Row>
                <div
                  class="sub_item"
                  v-for="(sub, index) in item.sub_list"
                  :key="index"
                >
                  <Row
                    class="mb_20"
                    style="align-items: center; height: 100%"
                    @click="goDetail(sub)"
                  >
                    <Col span="5">
                      <div class="size13 pr_10">
                        {{ sub.short_name }}
                      </div>
                    </Col>

                    <Col span="7" style="display: flex">
                      <div>
                        <div class="chart_left lineheight20">线上</div>
                        <div class="chart_left lineheight20">自主</div>
                      </div>
                      <div class="flex1">
                        <div class="flex_line_center">
                          <span class="">{{ sub.online.actual }}</span>
                          <span class="ml_3 grey">
                            /{{
                              sub.online?.target === ''
                                ? '-'
                                : sub.online?.target
                            }}
                          </span>
                        </div>
                        <div class="flex_line_center">
                          <span class="">{{ sub.diy.actual }}</span>
                          <span class="ml_3 grey"
                            >/{{
                              sub.diy?.target === '' ? '-' : sub.diy?.target
                            }}</span
                          >
                        </div>
                      </div>
                    </Col>
                    <Col span="4">
                      <!-- 差值 -->
                      <div v-if="sub.online?.gap !== ''">
                        <div
                          class="flex_line flex_line_center"
                          v-if="sub.online.actual > sub.online.target"
                        >
                          <span class="green">{{ sub.online.gap }}</span>
                          <img
                            class="icon_img"
                            :src="require('@/assets/images/target-data/up.png')"
                            alt="down"
                          />
                        </div>
                        <div
                          class="flex_line flex_line_center"
                          v-else-if="sub.online.actual < sub.online.target"
                        >
                          <span class="orange">{{ sub.online.gap }}</span>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/down.png')
                            "
                            alt="down"
                          />
                        </div>
                        <div class="flex_line flex_line_center" v-else>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/equal.png')
                            "
                            alt="down"
                          />
                        </div>
                      </div>
                      <div v-else class="flex_line flex_line_center">-</div>

                      <!-- 差值 -->
                      <div v-if="sub.diy?.gap !== ''">
                        <div
                          class="flex_line flex_line_center"
                          v-if="sub.diy.actual > sub.diy.target"
                        >
                          <span class="green">{{ sub.diy.gap }}</span>
                          <img
                            class="icon_img"
                            :src="require('@/assets/images/target-data/up.png')"
                            alt="down"
                          />
                        </div>
                        <div
                          class="flex_line flex_line_center"
                          v-else-if="sub.diy.actual < sub.diy.target"
                        >
                          <span class="orange">{{ sub.diy.gap }}</span>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/down.png')
                            "
                            alt="down"
                          />
                        </div>
                        <div class="flex_line flex_line_center" v-else>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/equal.png')
                            "
                            alt="down"
                          />
                        </div>
                      </div>
                      <div v-else class="flex_line flex_line_center">-</div>
                    </Col>
                    <Col span="4">
                      <!-- 完成率 -->
                      <div>
                        <span class="align_center">
                          {{
                            sub.online?.done_rate === ''
                              ? '-'
                              : sub.online?.done_rate + '%'
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="align_center">
                          {{
                            sub.diy?.done_rate === ''
                              ? '-'
                              : sub.diy?.done_rate + '%'
                          }}
                        </span>
                      </div>
                    </Col>
                    <Col span="4">
                      <div>
                        <span class="align_center">
                          {{ sub.success_rate + '%' }}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CollapseItem>
            </div>
          </Collapse>
          <div class="p_15 flex_center">
            <p class="box_8 grey_bg"></p>
            <p class="ml_5">目标</p>
            <p class="box_8 red_bg ml_30"></p>
            <p class="ml_5">达标</p>
            <p class="box_8 green_bg ml_30"></p>
            <p class="ml_5">未达标</p>
            <p class="box_8 red2_bg ml_30"></p>
            <p class="ml_5">超额</p>
          </div>
        </div>
      </Skeleton>
    </div>
    <!-- 模块：见面-协议时间 -->
    <noneMeetingTime
      v-if="_DATA.open"
      :data="props.data"
      ref="noneMeetingTimeRef"
      @createDangerHtml="createDangerHtml"
    />
    <!-- 展开收起 -->
    <div
      v-if="!_DATA.loading"
      class="expend-new"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
      :class="_DATA.open ? 'grey' : 'white'"
    >
      <span>{{ _DATA.open ? '收起' : '展开查看详情' }}</span>
      <Icon
        name="play"
        style="transform: rotate(90deg)"
        size="14"
        :style="
          !_DATA.open ? 'transform: rotate(90deg)' : 'transform: rotate(-90deg)'
        "
      />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  defineExpose,
  ref,
} from 'vue';
import { Row, Col, Icon, Notify, Skeleton, Collapse, CollapseItem } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';

import noneMeetingTime from '@/components/targetData/customerData/noneMeetingTime';

const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);

const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: props.data.depId ? 2 : 7, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 2, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  open: false,
  collapse_active: [], //锁定
  line_values: [],
  middle_value: '',
  //-规则
  htmlTitle: '',
  htmlContent: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depContractNum;
  let params = { year: props.data.yearId };
  if (props.data.depId) {
    url = $http.targetStatistic_personContractNum;
    params = { year: props.data.yearId, department_id: props.data.depId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = Object.assign(_DATA.info, data.info);
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

/**
 * 操作
 */

const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depDesginData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};

//-规则说明--begin
const noneMeetingTimeRef = ref();

const createDangerHtml = (type) => {
  if (type == 'noneMeetingTimeRef') {
    _DATA.htmlTitle = noneMeetingTimeRef?.value.htmlTitle;
    _DATA.htmlContent = noneMeetingTimeRef?.value.htmlContent;
  } else {
    _DATA.htmlTitle = '协议_规则';
    _DATA.htmlContent = `<p>1、【尚层装饰室内设计协议】满足合同签约金额≥15000且财务确认收首款，计数1；</p>
    <p>2、【尚层装饰配饰设计协议】满足合同签约金额≥8000且财务确认收首款，计数0.5；</p>
    <p>3、【尚层装饰室内设计协议】＜15000累计财务确认收产品款金额≥500000，协议数量计数1；</p>
    <p>4、【尚层装饰配饰设计协议】＜8000累计财务确认收产品款金额≥200000，协议数量计数0.5；</p>
    <p>5、成功率：当年非自主见面客户协议成功率；</p>`;
  }
  emit('createDanger', 'TempDesignAgreementRef');
};
defineExpose({ _DATA });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
