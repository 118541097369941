/*
 * @Author: NanNan
 * @Date: 2022-04-14 10:21:49
 * @LastEditTime: 2024-03-23 14:41:47
 * @Description: 账号体系
 */
import Service from '@/utils/request';

export default {
  /**
   * 获取客户相关
   */
  // @修改客户手机号
  getWechatPhoneApi(parmas) {
    return Service.post(`/rs/wechat/updateCustomerPhone`, parmas);
  },
  // @获取客户信息 {phone: '', userid: ''}
  getInfoApi(parmars) {
    return Service.post(`/rs/wechat/getInfo`, parmars);
  },
  // @获取客户手机号
  getCustomerPhoneApi(parmars) {
    return Service.post(`/rs/wechat/getCustomerPhone`, parmars);
  },
  // @h5登录
  getH5LoginApi(parmars) {
    return Service.post(`/rs/wechat/h5Login`, parmars);
  },
  getH5InfoApi(parmars) {
    return Service.post(`/rs/wechat/getH5Info`, parmars);
  },
  user_profile(parmars) {
    return Service.post(`/rs/user/profile`, parmars);
  },
  /**
   * 线索分享相关
   */
  // 线索-发送案例
  getClueShareDesignCase(parmars) {
    return Service.post(`/rs/clue/shareDesignCase`, parmars);
  },
  // 发送案例-线索绑定/变更
  getClueClueBind(parmars) {
    return Service.post(`/rs/clue/clueBind`, parmars);
  },
};
