<!--
 * @Author: NanNan
 * @Date: 2022-09-10 14:16:22
 * @LastEditTime: 2024-05-28 14:12:23
 * @Description: v1.3.4 smart 添加线索
 * v1.4.3 smart 线索更改 - 市场到网销的自动派单功能
 * v1.4.9 smart 分配规则 === 3暂不分配 清空邀约人、禁用邀约人
 * v1.5.4 smart [2024.1.19]紧急上线：“线索等级”，计算规则 >= 200 更改成 >= 180
 * v1.6.1 smart 联合客资表整改
-->
<template>
  <div class="page-box formBase">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="number">线索编号: -</div>
    <!-- 表单填写 -->
    <Form class="form-box" ref="formRef">
      <!-- ------项目------ -->
      <div class="title_box">项目</div>
      <p class="label-name">
        <em>*</em>
        客户姓名
      </p>
      <Field
        v-model.trim="form_info.info.name"
        placeholder="填写客户姓名"
        maxlength="20"
        name="validator"
        :rules="[
          {
            required: true,
            validator: validatorName,
            message: '请填写正确的客户姓名',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        联系方式
      </p>
      <Field
        v-model.trim="form_info.info.phone"
        placeholder="填写联系方式"
        maxlength="20"
        :rules="[
          {
            required: true,
            validator: validatorPhone,
            message: '请填写正确的联系方式',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">性别</p>
      <Field
        v-model="form_info.info.gender_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择性别"
        @click="picker.gender_show = true"
        class="mb20"
      />

      <div
        v-if="
          form_info.info.source_type == 1 || form_info.info.source_type == 2
        "
      >
        <p class="label-name">
          <em>*</em>
          线索等级
          <Icon name="question" color="#FFAE4E" @click.stop="getInstructions" />
        </p>
        <Field
          v-model="form_info.info.level_name"
          right-icon="arrow-down"
          readonly
          placeholder="选择线索等级"
          @click="picker.level_show = true"
          :rules="[
            {
              required: true,
              trigger: 'onSubmit',
              message: '请选择线索等级',
            },
          ]"
        />
      </div>
      <p class="label-name">项目等级</p>
      <Field
        v-model="form_info.info.dispatch_level_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择项目等级"
        @click="picker.dispatch_level_show = true"
      />
      <p class="label-name">楼盘</p>
      <Field
        v-model="form_info.info.floor_name"
        readonly
        right-icon="arrow-down"
        placeholder="选择楼盘"
        @click="picker.floor_show = true"
      />
      <p class="label-name">单元栋号</p>
      <Field
        v-model="form_info.info.floor_detail_name"
        readonly
        right-icon="arrow-down"
        placeholder="选择单元栋号"
        @click="picker.floor_detail_show = true"
      />
      <p class="label-name-flex">
        <span class="left">区域位置</span>
        <Checkbox
          v-model="form_info.is_city_unknown_type"
          icon-size="16px"
          shape="square"
          style="font-size: 15px"
          @change="changeCityCheck"
        >
          区域未知
        </Checkbox>
      </p>

      <Field
        v-model="form_info.info.city_name"
        readonly
        placeholder="选择区域位置"
        @click="picker.city_show = true"
        :disabled="form_info.is_city_unknown_type"
      />
      <p class="label-name-flex">
        <span class="left">面积</span>
        <Checkbox
          v-model="form_info.is_size_unknown_type"
          icon-size="16px"
          shape="square"
          style="font-size: 15px"
          @change="changeSizeCheck"
        >
          面积未知
        </Checkbox>
      </p>
      <Field
        v-model="form_info.info.size"
        type="number"
        :rules="[
          {
            message: '请填写正确的房屋面积',
            validator: validatorSize,
            trigger: 'onChange',
          },
        ]"
        placeholder="填写房屋面积"
        :disabled="form_info.is_size_unknown_type"
      >
        <template #button>
          <text>㎡</text>
        </template>
      </Field>
      <p class="label-name">详细地址</p>
      <Field
        v-model="form_info.info.address"
        placeholder="填写详细地址"
        maxlength="50"
      />
      <p class="label-name">购房情况</p>
      <Field
        v-model="form_info.info.house_type_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择购房情况"
        @click="picker.house_type_show = true"
      />
      <!-- 时间未知 -->
      <div
        class="active-box"
        v-if="
          form_info?.info?.house_type_name === '期房' ||
          form_info?.info?.house_type_name === '半年内期房' ||
          form_info?.info?.house_type_name === '未购房'
        "
      >
        时间未知
        <Checkbox
          v-model="form_info.is_time_unknown_type"
          @change="changeCheck"
        />
      </div>
      <!-- 预计交房时间 -->
      <div
        v-if="
          form_info.info.house_type_name === '期房' ||
          form_info.info.house_type_name === '半年内期房'
        "
      >
        <p class="label-name">
          <em v-if="!form_info.is_time_unknown_type">*</em>
          预计交房时间
        </p>
        <Field
          v-model="form_info.info.plan_delivery_day"
          name="picker"
          readonly
          placeholder="选择预计交房时间"
          right-icon="underway-o"
          @click="
            !form_info.is_time_unknown_type
              ? (picker.plan_delivery_day_show = true)
              : (picker.plan_delivery_day_show = false)
          "
          :rules="
            form_info.is_time_unknown_type
              ? [{ required: false }]
              : [
                  {
                    required: true,
                    trigger: 'onSubmit',
                    message: '请选择预计交房时间',
                  },
                ]
          "
          :disabled="form_info.is_time_unknown_type"
        />
      </div>
      <!-- 预计购房时间 -->
      <div v-if="form_info.info.house_type_name === '未购房'">
        <p class="label-name">
          <em v-if="!form_info.is_time_unknown_type">*</em>
          预计购房时间
        </p>
        <Field
          v-model="form_info.info.plan_buy_day"
          name="picker"
          readonly
          placeholder="选择预计购房时间"
          right-icon="underway-o"
          @click="
            !form_info.is_time_unknown_type
              ? (picker.plan_buy_day_show = true)
              : (picker.plan_buy_day_show = false)
          "
          :rules="
            form_info.is_time_unknown_type
              ? [{ required: false }]
              : [
                  {
                    required: true,
                    trigger: 'onSubmit',
                    message: '请选择预计购房时间',
                  },
                ]
          "
          :disabled="form_info.is_time_unknown_type"
        />
      </div>
      <p class="label-name">精装/毛坯</p>
      <Field
        v-model="form_info.info.house_category_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择精装/毛坯"
        @click="picker.house_category_show = true"
      />
      <p class="label-name">房屋结构</p>
      <Field
        v-model="form_info.info.home_type_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择房屋结构"
        @click="picker.home_type_show = true"
        class="mb20"
      />

      <!-- ------来源------ -->
      <div class="title_box">来源</div>
      <p class="label-name">
        <em>*</em>
        渠道来源
      </p>
      <Field
        v-model="form_info.info.channel_source_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择渠道来源"
        @click="picker.channel_source_show = true"
        :rules="[
          {
            required: true,
            message: '请选择渠道来源',
            trigger: 'onChange',
          },
        ]"
      />
      <div v-if="form_info.info.channel_source != 84">
        <p class="label-name">申请人</p>
        <Field
          v-model="form_info.channelUser"
          readonly
          placeholder="选择渠道申请人"
          @click="picker.channel_user_show = true"
        />
      </div>
      <div v-else>
        <p class="label-name">
          <em>*</em>
          申请人
        </p>
        <Field
          v-model="form_info.channelUser"
          readonly
          placeholder="选择渠道申请人"
          @click="picker.construction_channel_show = true"
          :rules="[
            {
              required: true,
              trigger: 'onSubmit',
              message: '请选择渠道申请人',
            },
          ]"
        />
      </div>
      <p class="label-name">订单号</p>
      <Field
        v-model="form_info.info.channel_no"
        placeholder="填写渠道订单号"
        maxlength="20"
      />
      <div v-if="form_info.info.channel_source != 84">
        <p class="label-name">渠道信息</p>
        <Field
          v-model="form_info.info.channel_info"
          placeholder="填写渠道信息"
          maxlength="1000"
          class="mb20"
          :rows="3"
          autosize
          type="textarea"
          show-word-limit
        />
      </div>
      <div v-if="form_info.info.channel_source == 84">
        <p class="label-name">
          <em>*</em>
          返单工地信息
        </p>
        <Field
          v-model="form_info.constructionName"
          readonly
          placeholder="选择返单工地信息"
          @click="
            () => {
              picker.construction_id_show = true;
            }
          "
          :rules="[
            {
              required: true,
              trigger: 'onSubmit',
              message: '请选择返单工地信息',
            },
          ]"
        />
      </div>

      <!-- ------企微好友添加------ -->
      <div class="title_box">企微好友添加</div>
      <p class="label-name">添加状态</p>
      <Field
        v-model="form_info.info.wechat_friend_status_name"
        right-icon="arrow-down"
        readonly
        :disabled="allOperationAuthority.indexOf('241') > -1 ? false : true"
        placeholder="选择添加状态"
        @click="
          () => {
            if (allOperationAuthority.indexOf('241') <= -1) {
              return;
            }
            picker.wechat_friend_status_show = true;
          }
        "
      />
      <div class="cell_info">
        <div class="left">external_userld</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">unionld</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">企微联系人</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">添加客户时间</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">好友添加方式</div>
        <div class="right">-</div>
      </div>

      <!-- ------系统信息------ -->
      <div class="title_box">系统信息</div>
      <p class="label-name">分配规则</p>
      <Field
        v-model="form_info.info.distribution_rule_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择分配规则"
        @click="picker.distribution_rule_show = true"
      />
      <p class="label-name">负责人</p>
      <Field
        v-model="form_info.responsibleUser"
        right-icon="arrow-down"
        readonly
        placeholder="选择负责人"
        disabled
      />
      <p class="label-name">邀约人</p>
      <Field
        v-model="form_info.inviteUser"
        right-icon="arrow-down"
        readonly
        :disabled="
          form_info.info.distribution_edit == 0 ||
          [2, 3, 4, 5].indexOf(form_info.info.distribution_rule_id) > -1
        "
        placeholder="选择邀约人"
        @click="
          () => {
            [2, 3, 4, 5].indexOf(form_info.info.distribution_rule_id) > -1
              ? (picker.invite_user_show = false)
              : (picker.invite_user_show = true);
          }
        "
      />
      <div class="cell_info">
        <div class="left">最近分配</div>
        <div class="right">-</div>
      </div>

      <div class="cell_info">
        <div class="left">已转客户编号</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">最近转客户时间</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">线索创建</div>
        <div class="right">-</div>
      </div>
      <div class="cell_info">
        <div class="left">最近修改</div>
        <div class="right">-</div>
      </div>
    </Form>
    <!-- end -->
    <!-- 操作按钮 -->
    <div class="stick-box">
      <Button
        size="large"
        class="button-danger"
        type="primary"
        @click="onSubmit()"
        :loading="loadingBtn"
      >
        保存线索
      </Button>
    </div>
    <!-- end -->
    <div>
      <!-- 性别 -->
      <Popup v-model:show="picker.gender_show" position="bottom">
        <Picker
          :columns="options.gender_list"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'gender')"
          @cancel="clearPicker('gender')"
          cancel-button-text="清除"
          :default-index="
            options.gender_list.findIndex((v) => {
              return v.id == form_info.info.gender;
            })
          "
        />
      </Popup>
      <!-- 项目信息 - 线索等级 -->
      <Popup
        v-model:show="picker.level_show"
        position="bottom"
        :key="keyNumber"
      >
        <Picker
          :columns="options.clue_level"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'level')"
          @cancel="clearPicker('level')"
          cancel-button-text="清除"
          :default-index="
            options.clue_level.findIndex((v) => {
              return v.id == form_info.info.level;
            })
          "
        />
      </Popup>
      <!-- 项目信息 - 项目等级 -->
      <Popup v-model:show="picker.dispatch_level_show" position="bottom">
        <Picker
          :columns="options.dispatch_level"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'dispatch_level')"
          @cancel="clearPicker('dispatch_level')"
          cancel-button-text="清除"
          :default-index="
            options.dispatch_level.findIndex((v) => {
              return v.id == form_info.info.dispatch_level;
            })
          "
        />
      </Popup>
      <!-- 项目信息 - 楼盘 -->
      <Popup
        v-model:show="picker.floor_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempFloor
          v-if="picker.floor_show"
          :kw="form_info.info.floor_name"
          :check="form_info.info.floor_id"
          @reviewClose="
            () => {
              picker.floor_show = false;
            }
          "
          @reviewOk="reviewOk_floor"
        />
      </Popup>
      <!-- 项目信息 - 单元栋号 -->
      <Popup
        v-model:show="picker.floor_detail_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempFloorDetail
          ref="floorDetailRef"
          :data="options.floor_detail_search_list"
          :check="form_info.info.floor_detail_id"
          v-if="picker.floor_detail_show"
          @reviewClose="
            () => {
              picker.floor_detail_show = false;
            }
          "
          @reviewOk="reviewOk_floorDetail"
        />
      </Popup>
      <!-- 项目信息 - 区域位置 -->
      <Popup v-model:show="picker.city_show" position="bottom">
        <Picker
          :columns="options.city"
          :columns-field-names="{
            text: 'name',
            value: 'id',
            children: 'sub',
          }"
          cancel-button-text="清除"
          @confirm="(value) => changePicker(value, 'city')"
          @cancel="clearPicker('city')"
          ref="cityRef"
        />
      </Popup>
      <!-- 项目信息 - 购房情况 -->
      <Popup v-model:show="picker.house_type_show" position="bottom">
        <Picker
          :columns="options.house_type"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'house_type')"
          @cancel="clearPicker('house_type')"
          cancel-button-text="清除"
          :default-index="
            options.house_type.findIndex((v) => {
              return v.id == form_info.info.house_type;
            })
          "
        />
      </Popup>
      <!-- 项目信息 - 精装/毛坯 -->
      <Popup v-model:show="picker.house_category_show" position="bottom">
        <Picker
          :columns="options.house_category"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'house_category')"
          @cancel="clearPicker('house_category')"
          cancel-button-text="清除"
          :default-index="
            options.house_category.findIndex((v) => {
              return v.id == form_info.info.house_category;
            })
          "
        />
      </Popup>
      <!-- 项目信息 - 房屋结构 -->
      <Popup v-model:show="picker.home_type_show" position="bottom">
        <Picker
          :columns="options.home_type"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'home_type')"
          @cancel="clearPicker('home_type')"
          cancel-button-text="清除"
          :default-index="
            options.home_type.findIndex((v) => {
              return v.id == form_info.info.home_type;
            })
          "
        />
      </Popup>
      <!-- 项目信息 - 预计购房时间 -->
      <Popup v-model:show="picker.plan_buy_day_show" position="bottom">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          title="预计购房时间"
          @confirm="(value) => changePicker(value, 'plan_buy_day')"
          @cancel="clearPicker('plan_buy_day')"
          cancel-button-text="清除"
        />
      </Popup>
      <!-- 项目信息 - 预计交房时间 -->
      <Popup v-model:show="picker.plan_delivery_day_show" position="bottom">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          title="预计交房时间"
          @confirm="(value) => changePicker(value, 'plan_delivery_day')"
          @cancel="clearPicker('plan_delivery_day')"
          cancel-button-text="清除"
        />
      </Popup>
      <!-- 来源信息 - 渠道来源 -->
      <!-- <Popup v-model:show="picker.channel_source_show" position="bottom">
        <Picker
          :columns="options.channel_source"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'channel_source')"
          @cancel="clearPicker('channel_source')"
          cancel-button-text="清除"
          :default-index="
            options.channel_source.findIndex((v) => {
              return v.id == form_info.info.channel_source;
            })
          "
        />
      </Popup> -->
      <!-- 来源信息 - 渠道申请人-->
      <Popup
        v-model:show="picker.channel_user_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempUser
          v-if="picker.channel_user_show"
          :data="'选择渠道申请人'"
          :check="form_info.info.channel_user_id"
          :kw="form_info.channelUser"
          @reviewClose="
            () => {
              picker.channel_user_show = false;
            }
          "
          @reviewOk="(item) => reviewOk_person(item, '渠道申请人')"
        />
      </Popup>
      <!-- 来源信息 - 渠道申请人（针对渠道来源 = 84工地返单） -->
      <Popup
        v-model:show="picker.construction_channel_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempList
          v-if="picker.construction_channel_show"
          :data="'选择渠道申请人'"
          :check="form_info.info.channel_user_id"
          :kw="form_info.channelUser"
          :requestType="2"
          @reviewClose="
            () => {
              picker.construction_channel_show = false;
            }
          "
          @reviewOk="(item) => reviewOk_person(item, '工地-渠道申请人')"
        />
      </Popup>
      <!-- 来源信息 - 返单工地信息（针对渠道来源 = 84工地返单） -->
      <Popup
        v-model:show="picker.construction_id_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempList
          v-if="picker.construction_id_show"
          :data="'选择返单工地信息'"
          :check="form_info.info.construction_id"
          :kw="form_info.constructionName"
          :requestType="1"
          @reviewClose="
            () => {
              picker.construction_id_show = false;
            }
          "
          @reviewOk="(item) => reviewOk_person(item, '返单工地信息')"
        />
      </Popup>
      <!-- 分配信息 - 分配规则 -->
      <Popup v-model:show="picker.distribution_rule_show" position="bottom">
        <Picker
          :columns="options.distribution_rule"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'distribution_rule')"
          @cancel="picker.distribution_rule_show = false"
          :default-index="
            options.distribution_rule.findIndex((v) => {
              return v.id == form_info.info.distribution_rule_id;
            })
          "
        />
      </Popup>
      <!-- 分配信息 - 邀约人 -->
      <Popup
        v-model:show="picker.invite_user_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempUser
          v-if="picker.invite_user_show"
          :data="'选择邀约人'"
          :check="form_info.info.invite_user_id"
          :kw="form_info.inviteUser"
          :auth="1"
          @reviewClose="
            () => {
              picker.invite_user_show = false;
            }
          "
          @reviewOk="(item) => reviewOk_person(item, '邀约人')"
        />
      </Popup>
      <!-- 企微好友 - 添加状态 -->
      <Popup v-model:show="picker.wechat_friend_status_show" position="bottom">
        <Picker
          :columns="options.wechat_friend_status"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          @confirm="(value) => changePicker(value, 'wechat_friend_status')"
          @cancel="picker.wechat_friend_status_show = false"
          :default-index="
            options.wechat_friend_status.findIndex((v) => {
              return v.id == form_info.info.wechat_friend_status;
            })
          "
        />
      </Popup>
      <!-- 来源信息 - 渠道来源 -->
      <Popup
        v-model:show="picker.channel_source_show"
        round
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '70%' }"
      >
        <TempChannelSource
          v-if="picker.channel_source_show"
          :data="'选择渠道来源'"
          :check="form_info.info.channel_source"
          :kw="form_info.info.channel_source_name"
          :auth="1"
          @reviewClose="
            () => {
              picker.channel_source_show = false;
            }
          "
          @reviewOk="(item) => changePicker(item, 'channel_source')"
        />
      </Popup>
    </div>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, inject, computed, watch } from 'vue';
import {
  Overlay,
  Loading,
  Form,
  Field,
  Button,
  Notify,
  Picker,
  Popup,
  Checkbox,
  DatetimePicker,
  Toast,
  Icon,
  Dialog,
} from 'vant';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import moment from 'moment';

import patterns from '@/utils/pattern';
import TempFloor from '@/components/clue/TempFloor';
import TempFloorDetail from '@/components/clue/TempFloorDetail';
import TempUser from '@/components/clue/TempUser';
import TempList from '@/components/clue/TempList';
import TempChannelSource from '@/components/clue/TempChannelSource';

const router = useRouter();
const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);

const loading = ref(false);
const form_info = reactive({
  userInfo: {
    name: '', ////登录账号：姓名[苏州/部门/岗位]
    id: '', //登录id
  },
  channelUser: '', //渠道申请人
  responsibleUser: '', //负责人
  inviteUser: '', //邀约人
  constructionName: '', //返单工地信息
  is_time_unknown_type: false, //时间未知（预计购房时间、预计交房时间）
  is_city_unknown_type: false, //区域未知
  is_size_unknown_type: false, //面积未知
  info: {
    is_city_unknown: 2, //区域未知 1是2否
    is_size_unknown: 2, //面积未知 1是2否
    is_time_unknown: 2, //时间未知 1是2否
    distribution_rule_id: 1,
    distribution_rule_name: '手动分配邀约人',
    // 企微好友添加
    wechat_friend_status: 1,
    wechat_friend_status_name: '未添加',
    // 渠道来源-对应渠道类型1-集团、2-市场、3-网销自主、4-中心自主
    source_type: '',
  },
});
const options = reactive({ clue_level: [] });
const $http = inject('$http');
const { houseSizeReg } = patterns;
const currentDate = ref(new Date());
const keyNumber = ref(Date.parse(new Date()));

onMounted(() => {
  getPublicParameter();
});
/**
 * 接口
 */
// -- 获取登录者信息
const getUserFunc = () => {
  $http.getUserInfo().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      const info = JSON.parse(JSON.stringify(data));
      const userInfo = `${info.name}[${info?.company_name}/${info.department_name}/${info.post_name}]`;
      form_info.userInfo.name = userInfo;
      form_info.userInfo.id = info.id;
      form_info.responsibleUser = userInfo;
      form_info.info.responsible_user_id = info.id;
    } else {
      form_info.responsibleUser = '';
    }
  });
};
// -- 公共参数
const getPublicParameter = async () => {
  loading.value = true;
  $http.getSaleOptionsApi().then((res) => {
    loading.value = false;
    if (res.code === 0) {
      Object.assign(options, res.data.customer);
      options.city = res.data.city;
      // 线索等级 格式化
      const clue_level = res.data.customer.clue_level.map((item) => {
        return { id: item.id, name: item.name, disabled: true };
      });
      options.clue_level = clue_level;
      getUserFunc();
    } else {
      loading.value = false;
      Notify({ type: 'warning', message: res.data });
    }
  });
};

/**
 * 操作
 */

// -- 选择器
const picker = reactive({
  gender_show: false, //性别选择器
  dispatch_level_show: false, //项目等级
  floor_show: false, //楼盘名称
  floor_detail_show: false, //单元栋号
  city_show: false, //区域位置
  house_type_show: false, //购房情况
  house_category_show: false, //精装/毛坯
  home_type_show: false, //房屋结构
  plan_delivery_day_show: false, //预计交房时间
  plan_buy_day_show: false, //预计购房时间
  channel_source_show: false, //渠道来源
  channel_user_show: false, //渠道申请人
  distribution_rule_show: false, //分配规则
  invite_user_show: false, //邀约人
  wechat_friend_status_show: false, //企微好友 - 添加状态
  construction_id_show: false, //返单工地信息pop
  construction_channel_show: false, //渠道来源 == 84工地返单，渠道申请人pop
  level_show: false, //线索等级
});
const changePicker = (value, type) => {
  switch (type) {
    case 'gender':
      picker.gender_show = false;
      form_info.info.gender = value.id;
      form_info.info.gender_name = value.name;
      break;
    case 'level':
      picker.level_show = false;
      form_info.info.level = value.id;
      form_info.info.level_name = value.name;
      break;
    case 'dispatch_level':
      picker.dispatch_level_show = false;
      form_info.info.dispatch_level = value.id;
      form_info.info.dispatch_level_name = value.name;
      break;
    case 'city':
      picker.city_show = false;
      form_info.info.city_id = value[2].code;
      form_info.info.city_name =
        value[0].name + '/' + value[1].name + '/' + value[2].name;
      break;
    case 'house_type':
      picker.house_type_show = false;
      form_info.info.house_type = value.id;
      form_info.info.house_type_name = value.name;
      break;
    case 'house_category':
      picker.house_category_show = false;
      form_info.info.house_category = value.id;
      form_info.info.house_category_name = value.name;
      break;
    case 'home_type':
      picker.home_type_show = false;
      form_info.info.home_type = value.id;
      form_info.info.home_type_name = value.name;
      break;
    case 'plan_delivery_day':
      picker.plan_delivery_day_show = false;
      form_info.info.plan_delivery_day = moment(value).format('YYYY-MM-DD');
      break;
    case 'plan_buy_day':
      picker.plan_buy_day_show = false;
      form_info.info.plan_buy_day = moment(value).format('YYYY-MM-DD');
      break;
    case 'channel_source':
      console.log(value);
      picker.channel_source_show = false;
      form_info.info.channel_source = value?.id || '';
      form_info.info.channel_source_name = value?.name || '';
      if (value?.id == 84) {
        form_info.info.channel_user_id = '';
        form_info.info.channel_user_name = '';
        form_info.channelUser = '';
      }
      // v1.4.3特殊处理 赋值渠道来源对应的渠道类型type1-集团、2-市场、3-网销自主、4-中心自主
      var source_type = value?.id
        ? options.channel_source.filter((item) => item.id == value?.id)[0].type
        : '';
      form_info.info.source_type = source_type;
      if (source_type && parseFloat(source_type) > 2) {
        form_info.info.level = '';
      } else {
        levelOptionsHandle([
          form_info.info?.size,
          form_info.info?.house_category,
          form_info.info?.home_type,
        ]);
      }
      break;
    case 'distribution_rule':
      picker.distribution_rule_show = false;
      form_info.info.distribution_rule_id = value.id;
      form_info.info.distribution_rule_name = value.name;
      if (value.id == 2) {
        form_info.responsibleUser = '-';
        form_info.info.responsible_user_id = '';
        form_info.inviteUser = '-';
        form_info.info.invite_user_id = '';
      } else if (value.id == 3) {
        form_info.responsibleUser = JSON.parse(
          JSON.stringify(form_info.userInfo.name)
        );
        form_info.info.responsible_user_id = JSON.parse(
          JSON.stringify(form_info.userInfo.id)
        );
        form_info.inviteUser = '-';
        form_info.info.invite_user_id = '';
      } else if (value.id == 4 || value.id == 5) {
        form_info.responsibleUser = JSON.parse(
          JSON.stringify(form_info.userInfo.name)
        );
        form_info.info.responsible_user_id = JSON.parse(
          JSON.stringify(form_info.userInfo.id)
        );
        form_info.inviteUser = '-';
        form_info.info.invite_user_id = '';
      } else {
        form_info.responsibleUser = JSON.parse(
          JSON.stringify(form_info.userInfo.name)
        );
        form_info.info.responsible_user_id = JSON.parse(
          JSON.stringify(form_info.userInfo.id)
        );
        form_info.inviteUser = '';
        form_info.info.invite_user_id = '';
      }
      break;
    case 'wechat_friend_status':
      // 企微好友 - 添加状态
      picker.wechat_friend_status_show = false;
      form_info.info.wechat_friend_status = value.id;
      form_info.info.wechat_friend_status_name = value.name;
      break;
    default:
      break;
  }
};

// -- 选择器清除
const clearPicker = (type) => {
  switch (type) {
    case 'gender':
      picker.gender_show = false;
      form_info.info.gender = '';
      form_info.info.gender_name = '';
      break;
    case 'level':
      picker.level_show = false;
      form_info.info.level = '';
      form_info.info.level_name = '';
      break;
    case 'dispatch_level':
      picker.dispatch_level_show = false;
      form_info.info.dispatch_level = '';
      form_info.info.dispatch_level_name = '';
      break;
    case 'city':
      picker.city_show = false;
      form_info.info.city_id = '';
      form_info.info.city_name = '';
      break;
    case 'house_type':
      picker.house_type_show = false;
      form_info.info.house_type = '';
      form_info.info.house_type_name = '';
      break;
    case 'house_category':
      picker.house_category_show = false;
      form_info.info.house_category = '';
      form_info.info.house_category_name = '';
      break;
    case 'home_type':
      picker.home_type_show = false;
      form_info.info.home_type = '';
      form_info.info.home_type_name = '';
      break;
    case 'plan_delivery_day':
      picker.plan_delivery_day_show = false;
      form_info.info.plan_delivery_day = '';
      break;
    case 'plan_buy_day':
      picker.plan_buy_day_show = false;
      form_info.info.plan_buy_day = '';
      break;
    case 'channel_source':
      picker.channel_source_show = false;
      form_info.info.channel_source = '';
      form_info.info.channel_source_name = '';
      break;
    default:
      break;
  }
};

// -- v1.4.3 面积、精装/毛坯、房屋结构修改之后引发的“线索等级”筛选项变动
const levelOptionsHandle = (newVal, oldVal) => {
  console.log(newVal, oldVal);
  keyNumber.value = Date.parse(new Date());
  const size = newVal[0]; //面积
  const hose_id = newVal[1]; //精装/毛坯
  const home_id = newVal[2]; //房屋结构
  // A类可选：面积 >= 180 && 精装/毛坯 == 101毛坯 && 房屋结构 != (488未知 || 111自建)；
  // B类可选：面积 >= 140 && 精装/毛坯 == 102精装 && 房屋结构 != (488未知 || 111自建)；
  // C类可选：新建时，永远禁止选择，disabled状态；编辑时，永远可选
  // D类可选：不符合上述情况为D；
  form_info.info.level = '';
  if (
    size &&
    parseFloat(size) >= 180 &&
    hose_id == 101 &&
    home_id != 488 &&
    home_id != 111
  ) {
    options.clue_level[0].disabled = false;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = true;
    form_info.info.level = 1;
    form_info.info.level_name = options.clue_level[0].name;
    return;
  } else if (
    (size &&
      parseFloat(size) >= 140 &&
      hose_id == 102 &&
      home_id != 488 &&
      home_id != 111) ||
    (size && parseFloat(size) >= 180 && home_id == 111)
  ) {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = false;
    options.clue_level[3].disabled = true;
    form_info.info.level = 2;
    form_info.info.level_name = options.clue_level[1].name;
    return;
  } else {
    options.clue_level[0].disabled = true;
    options.clue_level[1].disabled = true;
    options.clue_level[3].disabled = false;
    form_info.info.level = 4;
    form_info.info.level_name = options.clue_level[3].name;
  }
};
watch(
  () => [
    form_info.info.size,
    form_info.info.house_category,
    form_info.info.home_type,
    form_info.info.source_type,
  ],
  (newVal, oldVal) => {
    if (newVal[3] && Number(newVal[3]) > 2) {
      form_info.info.level = '';
      return;
    }
    if (newVal.toString() !== oldVal.toString()) {
      levelOptionsHandle(newVal, oldVal);
    }
  }
);

// -- 楼盘popup
const reviewOk_floor = (item, list) => {
  form_info.info.floor_id = item?.id || '';
  form_info.info.floor_name = item?.name || '';
  form_info.info.city_name = item?.city_name || '';
  form_info.info.city_id = item?.city_id || '';
  options.floor_detail_search_list = list;
  form_info.info.floor_detail_id = '';
  form_info.info.floor_detail_name = '';
  form_info.info.size = null;
  picker.floor_show = false;
};

// -- 单元栋号popup
const reviewOk_floorDetail = (data) => {
  form_info.info.floor_detail_id = data?.id || '';
  form_info.info.floor_detail_name = data?.detail_name || '';
  form_info.info.size = data?.size || null;
  picker.floor_detail_show = false;
};

// -- 验证规则（姓名）
const validatorName = (val) => {
  let result = false;
  if (val && val.length >= 2) {
    result = true;
  }
  return result;
};

// -- 验证规则（手机号码）
const validatorPhone = (val) => {
  let result = false;
  if (val && val.length >= 5) {
    result = true;
  }
  return result;
};

// -- 验证规则（面积）
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;

// -- 时间未知
const changeCheck = (value) => {
  if (value) {
    form_info.info.plan_buy_day = undefined;
    form_info.info.plan_delivery_day = undefined;
  }
};

// -- 区域未知
const changeCityCheck = (value) => {
  if (value) {
    form_info.info.city_name = '';
    form_info.info.city_id = '';
  }
};

// -- 面积未知
const changeSizeCheck = (value) => {
  if (value) {
    form_info.info.size = null;
  }
};

// -- 渠道申请人
const reviewOk_person = (item, type) => {
  if (type === '返单工地信息') {
    form_info.constructionName = item.name;
    form_info.info.construction_id = item?.id || '';
    picker.construction_id_show = false;
    return;
  }
  const nameStr = item.name
    ? `${item.name}[${item.company_name}/${item.dep_name}/${item.post_name}]`
    : '';
  if (type === '渠道申请人') {
    form_info.channelUser = nameStr;
    form_info.info.channel_user_id = item?.id || '';
    picker.channel_user_show = false;
  } else if (type === '邀约人') {
    form_info.inviteUser = nameStr;
    form_info.info.invite_user_id = item?.id || '';
    picker.invite_user_show = false;
  } else if (type === '工地-渠道申请人') {
    form_info.channelUser = nameStr;
    form_info.info.channel_user_id = item?.id || '';
    picker.construction_channel_show = false;
  }
};

// -- 保存线索
const formRef = ref(null);
const loadingBtn = ref(false);
const onSubmit = () => {
  formRef.value
    ?.validate()
    .then(() => {
      loading.value = true;
      loadingBtn.value = true;
      form_info.info.is_time_unknown = form_info.is_time_unknown_type ? 1 : 2;
      form_info.info.is_size_unknown = form_info.is_size_unknown_type ? 1 : 2;
      form_info.info.is_city_unknown = form_info.is_city_unknown_type ? 1 : 2;
      $http.editClue(form_info.info).then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            duration: 1000,
            onClose: () => {
              loading.value = false;
              loadingBtn.value = false;
              router.push('/clueList');
            },
          });
        } else {
          loading.value = false;
          loadingBtn.value = false;
          Notify({
            type: 'warning',
            message: data,
            duration: 2000,
          });
        }
      });
    })
    .catch((error) => {
      if (error) {
        Toast('请完善信息');
        // Toast(error[0].message);
      }
    });
};

// -- v1.4.3新增线索等级 文字信息提示
const getInstructions = () => {
  Dialog.confirm({
    title: '',
    showCancelButton: false,
    confirmButtonText: '我知道了',
    confirmButtonColor: '#576B95',
    className: 'dialogDescripton',
    message: `<div class="description"><h3>线索等级判定方式</h3><p>1.A类 - 200平米以上毛坯</p><p>2.B类 - 140平米以上精装、200平米以上自建</p><p>3.C类 - 经判定已无法联系或无需求线索</p><p>4.D类 - 非上述范围，或信息不全的线索</p></div>`,
    allowHtml: true,
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueNewInfo.less';
</style>
