<!--
 * @Author: Jessica
 * @Date: 2022-04-14 16:01:05
 * @LastEditTime: 2023-11-11 15:36:55
 * @Description: 
 * v1.3.7[smart] 增加H5登录相关交互展示
-->
<template>
  <!-- <div> -->
  <Layout />
  <!-- <div>{{ name }}</div> -->
  <!-- </div> -->
  <Popup
    v-model:show="isScreen"
    class="popup-background-none"
    overlay-class="popup-background-none"
    :style="{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }"
  >
    <div class="popCenter">
      <img class="isScreen" :src="require('@/assets/images/isScreen.png')" />
      <p>为了不影响展示效果，请您竖屏预览</p>
    </div>
  </Popup>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Popup } from 'vant';
import Layout from '@/components/Layout';

const isScreen = ref(false);
const isWxwork = /wxwork/i.test(navigator.userAgent);

onMounted(() => {
  if (!isWxwork) {
    listenScreen();
    window.addEventListener(
      'onorientationchange' in window ? 'orientationchange' : 'resize',
      function () {
        listenScreen();
      },
      false
    );
  }
});

const listenScreen = () => {
  if (window.orientation === 180 || window.orientation === 0) {
    isScreen.value = false;
  }
  if (window.orientation === 90 || window.orientation === -90) {
    isScreen.value = true;
  }
};
</script>

<style lang="less" scoped>
.popCenter {
  text-align: center;
  margin: 0 auto;
  .isScreen {
    width: 40px;
  }
  p {
    color: #fff;
    font-size: 13px;
  }
}
</style>
