<!--
 * @Author: gest
 * @Date: 2023-05-09 11:36:41
 * @LastEditTime: 2024-06-14 11:15:59
 * @Description: V1.3.8-自购订单详情
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="detail_box">
      <div class="top_box">
        <div class="left icon_name">
          {{ _DATA.info.supplier_name ? _DATA.info.supplier_name[0] : '' }}
        </div>
        <div class="right">
          <div class="left">
            <div class="name">{{ _DATA.info.supplier_name }}</div>
            <div class="desc">
              {{ _DATA.info.category_name }}
            </div>
          </div>
          <!-- <div class="right">
            <div class="name">{{ _DATA.info.deal_price }}</div>
            <div class="desc">成交金额/元</div>
          </div> -->
        </div>
      </div>
      <div class="dec_box">
        <div class="dec_item">
          <div class="left">订单状态</div>
          <div
            class="right status_tag"
            :class="
              _DATA.info.status == 3
                ? 'red'
                : _DATA.info.status == 4
                ? 'blue'
                : _DATA.info.status == 5
                ? 'green'
                : _DATA.info.status == 6
                ? 'grey'
                : 'orange'
            "
          >
            {{ _DATA.info.status_name }}
          </div>
        </div>
        <div class="dec_item">
          <div class="left">当前进度</div>
          <div class="right">{{ _DATA.info.progress_name }}</div>
        </div>
        <div class="dec_item">
          <div class="left">订单编号</div>
          <div class="right">{{ _DATA.info.order_no }}</div>
        </div>
      </div>
      <div v-show="_DATA.arrow == 1">
        <div class="dec_box">
          <!-- <div class="dec_item" v-if="_DATA.info.type == 3">
            <div class="left">原订单编号</div>
            <div
              class="right link"
              @click="
                () => {
                  router.push({
                    path: '/mainOrderDetail',
                    query: { id: _DATA.info.source_order_id },
                  });
                }
              "
            >
              {{ _DATA.info.source_order_no }}
            </div>
          </div> -->
          <div class="dec_item">
            <div class="left">工地编号</div>
            <div class="right">{{ _DATA.info.construction_no }}</div>
          </div>
          <div class="dec_item">
            <div class="left">完成测量日期</div>
            <div class="right">{{ _DATA.info.measure_time || '-' }}</div>
          </div>
          <div class="dec_item">
            <div class="left">正式下单日期</div>
            <div class="right">{{ _DATA.info.place_order_time || '-' }}</div>
          </div>

          <div class="dec_item">
            <div class="left">完成送货日期</div>
            <div class="right">{{ _DATA.info.delivery_time || '-' }}</div>
          </div>
          <div class="dec_item">
            <div class="left">完成验收日期</div>
            <div class="right">{{ _DATA.info.accept_time || '-' }}</div>
          </div>
        </div>
        <div class="dec_box">
          <div class="dec_title">服务人员</div>
          <div
            class="list_item"
            v-for="(item, index) in _DATA.info.service_staff"
            :key="index"
          >
            <div class="img-box">
              <Image
                class="img-head"
                fit="cover"
                round
                :src="
                  item.face_image
                    ? item.face_image.includes('http')
                      ? item.face_image
                      : $host + item.face_image
                    : require('@/assets/images/icon-empty-img.png')
                "
              ></Image>
            </div>
            <div class="right">
              <div class="name_box">
                <div class="name">
                  {{ item.name || '-' }}
                </div>
                <div class="desc">
                  {{ item.dep_name }}
                </div>
              </div>
              <div class="btn_phone" @click="telPhone(item.phone)">联系</div>
            </div>
          </div>
        </div>
        <div class="info_item">
          <div class="title">订单标注</div>
          <div
            class="content mark_select"
            v-if="allOperationAuthority.indexOf('462') > -1"
            @click="_DATA.markShow = true"
          >
            <span>{{ _DATA.info.mark_name || '-' }}</span>
            <Icon name="arrow" size="12" class="ml_5" />
          </div>
          <div class="content" v-else>
            {{ _DATA.info.mark_name || '-' }}
          </div>
        </div>
        <div class="info_item">
          <div class="title">下单备注</div>
          <div v-if="allOperationAuthority.indexOf('462') > -1">
            <Field
              @blur="blurFun"
              v-model="_DATA.info.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="30"
              placeholder="请填写"
              show-word-limit
            ></Field>
          </div>
          <div class="content" v-else>
            {{ _DATA.info.remark || '-' }}
          </div>
        </div>
      </div>

      <div class="arrow_box" @click="arrowBtn('1')" v-show="_DATA.arrow == 0">
        <span>详细信息</span>
        <Icon name="arrow-down" class="arrow" />
      </div>
      <div
        class="arrow_box"
        @click="arrowBtn('0')"
        v-show="_DATA.arrow == 1"
        style="padding: 22px 0"
      >
        <span>收起</span>
        <Icon name="arrow-up" class="arrow" />
      </div>
    </div>
    <!-- 粘性布局 -->
    <div ref="container" class="tab-box-bigger">
      <Sticky :container="container">
        <Tabs
          v-model:active="_DATA.tab_active"
          title-active-color="#0256ff"
          title-inactive-color="#000000"
          :ellipsis="false"
          class="tab_box"
        >
          <Tab title="材料明细" :name="1"></Tab>
          <Tab title="其他信息" :name="2"></Tab>
          <Tab title="订单记录" :name="3"></Tab>
          <Tab title="安装时间表" :name="4"></Tab>
        </Tabs>
      </Sticky>
      <!-- 加载状态 -->
      <Overlay :show="_DATA.loading" class="overlay-fff">
        <Loading color="#1989fa" type="spinner"></Loading>
      </Overlay>
      <!-- end -->

      <!-- 内容区域 -->
      <div class="tab-content-bigger">
        <!-- tab 材料明细 -->
        <OrderDetailLits v-if="_DATA.tab_active == 1" :id="_DATA.id" />
        <!-- tab  其他信息-->

        <div v-if="_DATA.tab_active == 2" class="other_info">
          <div>
            <p class="name">备注</p>
            <div>
              {{ _DATA.info.detail_remark || '-' }}
            </div>
          </div>
          <div class="mt_10">
            <p class="name">附件</p>
            <div class="fileList" v-if="_DATA.info.files.length > 0">
              <FileGrid :data="_DATA.info.files"></FileGrid>
            </div>
            <div v-else>-</div>
          </div>
        </div>
        <!-- tab  订单记录-->
        <OrderRecords v-if="_DATA.tab_active == 3" :id="_DATA.id" />
        <!-- tab 安装时间表-->
        <installationTable v-if="_DATA.tab_active == 4" :id="_DATA.id" />
      </div>
    </div>
    <!-- 判断权限 -->
    <div class="stick-box" v-if="allOperationAuthority">
      <Button
        type="default"
        size="large"
        v-if="_DATA.more_list.length"
        @click="_DATA.showMoreAction = true"
      >
        更多
      </Button>

      <Button
        type="primary"
        size="large"
        v-if="allOperationAuthority.indexOf('464') > -1"
        @click="handelFuns({ type: 464 })"
      >
        跟单
      </Button>
    </div>
    <a href="" v-show="false" ref="telRef"></a>
  </div>
  <!-- 动作面板 - 更多 -->
  <ActionSheet
    v-model:show="_DATA.showMoreAction"
    cancel-text="取消"
    close-on-click-action
    @cancel="
      () => {
        _DATA.showMoreAction = false;
      }
    "
    class="more-box"
  >
    <div class="content">
      <div class="same">
        <div
          v-for="(item, index) in _DATA.more_list"
          :key="index"
          @click="handelFuns(item)"
        >
          <svg class="icon_svg" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </ActionSheet>
  <Popup v-model:show="_DATA.markShow" position="bottom">
    <div class="mark_pop">
      <div class="mark_title">
        <span style="flex: 1; text-align: center">选择标注</span>
        <Icon
          name="cross"
          :size="18"
          style="color: #8d9091"
          @click="
            () => {
              _DATA.markShow = false;
            }
          "
        />
      </div>
      <CheckboxGroup
        v-model="_DATA.markList"
        class="mt_15"
        style="max-height: 500px; overflow: auto; height: 200px"
      >
        <Checkbox
          v-for="val in _DATA.allMarkList"
          :key="val.name"
          class="checkboxNoIcon"
          :name="val.id"
        >
          <template #icon></template>
          <p
            class="menuBox"
            :class="{
              checked: _DATA.markList.indexOf(val.id) > -1,
            }"
          >
            {{ val.name }}
          </p>
        </Checkbox>
      </CheckboxGroup>
      <Button type="primary" size="large" @click="saveMark">保存</Button>
    </div>
  </Popup>
</template>
<script setup>
import { onMounted, reactive, inject, computed, ref, watch } from 'vue';
import FileGrid from '@/components/common/FileGrid';
import OrderDetailLits from '@/components/order/mainSelfOrder/OrderDetailLits';

import OrderRecords from '@/components/order/mainSelfOrder/OrderRecords';

import installationTable from '@/components/order/mainSelfOrder/installationTable';

import { useRouter, useRoute } from 'vue-router';
import {
  Button,
  Overlay,
  Loading,
  Notify,
  Image,
  ActionSheet,
  Tab,
  Tabs,
  Sticky,
  Icon,
  Field,
  Popup,
  CheckboxGroup,
  Checkbox,
  Dialog,
} from 'vant';
import { telRef, telPhone } from '@/utils/util';
import { useStore } from 'vuex';

const store = useStore();
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const route = useRoute();
const _DATA = reactive({
  id: router.currentRoute.value.query.id,
  loading: false,
  info: '',
  showMoreAction: false, //更多面板
  more_list: [],
  arrow: 0,
  markShow: false,
  markList: [],
  allMarkList: [],
});

const container = ref(null);
onMounted(() => {
  loadFunc();
});
watch(
  () => route.query,
  (cur) => {
    if (cur.id && !cur.gdid) {
      _DATA.id = cur.id;
      loadFunc();
    }
  }
);
/**
 * 获取接口
 */

const loadFunc = () => {
  _DATA.loading = true;
  $http.mainSelfOrder_info({ id: _DATA.id }).then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data;
      _DATA.markList = _DATA.info.mark_list.map((v) => Number(v.id));
      getMoreAuth();
      getParams();
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
const getParams = () => {
  _DATA.loading = true;
  $http.mainOrder_getParams().then((res) => {
    const { data, code } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.allMarkList = data.main_order_mark;
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
//备注编辑
const blurFun = () => {
  _DATA.loading = true;
  $http
    .mainSelfOrder_markSave({
      id: _DATA.id,
      remark: _DATA.info.remark,
      type: 2,
    })
    .then((res) => {
      const { data, code } = res;
      _DATA.loading = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '下单备注保存成功',
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//@-更多操作-根据状态展示不同的操作权限
/*

订单状态：未正式下单——463致电商家、465客户下单、466完成测量、469作废
订单状态：正式下单——463致电商家、466完成测量、467现场到货验收、468安装验收、469作废
订单状态：已验收——致电商家
订单状态：作废——致电商家、470删除
PS：正式下单、已验收、作废：材料明细不可编辑；供应商、负责人姓名、负责人手机号、订单标注、下单备注：任何状态都可编辑；
*/
const getMoreAuth = () => {
  let opt = [
    {
      name: '致电商家',
      icon: '#icon-phone-line',
      type: '463',
    },
    {
      name: '编辑订单',
      icon: '#icon-a-edit-line1',
      type: '462',
    },
    {
      name: '客户下单',
      icon: '#icon-clipboard-line',
      type: '465',
    },

    {
      name: '完成测量',
      icon: '#icon-ruler-2-line',
      type: '466',
    },

    {
      name: '现场到货验收',
      icon: '#icon-lightbulb-line',
      type: '467',
    },

    {
      name: '安装验收',
      icon: '#icon-file-list-line',
      type: '468',
    },
    {
      name: '作废',
      icon: '#icon-close-circle-line',
      type: '469',
    },
    {
      name: '删除',
      icon: '#icon-delete-bin-2-line',
      type: '470',
    },
  ];
  opt = opt.filter((k) => {
    return allOperationAuthority.value.indexOf(k.type) > -1;
  });
  if (_DATA.info.status == 3) {
    opt = opt.filter((k) => {
      return (
        k.type == 463 ||
        k.type == 462 ||
        k.type == 465 ||
        k.type == 466 ||
        k.type == 469
      );
    });
  } else if (_DATA.info.status == 4) {
    opt = opt.filter((k) => {
      return (
        k.type == 463 ||
        k.type == 462 ||
        k.type == 466 ||
        k.type == 467 ||
        k.type == 468 ||
        k.type == 469
      );
    });
  } else if (_DATA.info.status == 5) {
    opt = opt.filter((k) => {
      return k.type == 463 || k.type == 462;
    });
  } else {
    opt = opt.filter((k) => {
      return k.type == 463 || k.type == 462 || k.type == 470;
    });
  }
  _DATA.more_list = opt;
};
/**
 * 操作
 */
const arrowBtn = (type) => {
  _DATA.arrow = type;
};

// -- 更多
//-@更多操作
const handelFuns = (item) => {
  if (
    item.type == 464 ||
    item.type == 465 ||
    item.type == 466 ||
    item.type == 469
  ) {
    //464跟单/465客户下单/466完成测量/469作废

    router.push({
      path: '/mainSelfOrderOperate',
      query: {
        id: _DATA.id,
        type: item.type,
      },
    });
  } else if (item.type == 467 || item.type == 468) {
    //467现场到货验收/468安装验收

    router.push({
      path: '/mainSelfOrderOperateProduct',
      query: {
        id: _DATA.id,
        type: item.type,
      },
    });
  } else if (item.type == '463') {
    //致电商家
    telPhone(_DATA.info.supplier_phone);
  } else if (item.type == '470') {
    //删除

    Dialog.confirm({
      message: '确定要删除吗？',
    }).then(() => {
      _DATA.loading = true;
      $http.mainSelfOrder_delOrder({ id: _DATA.id }).then((res) => {
        const { data, code } = res;

        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              _DATA.loading = false;
              router.back();
            },
          });
        } else {
          _DATA.loading = false;
          Notify({ type: 'warning', message: data });
        }
      });
    });
  } else if (item.type == '462') {
    //编辑

    router.push({
      path: '/addMainSelfOrder',
      query: {
        id: router.currentRoute.value.query.cid,
        curid: router.currentRoute.value.query.id,
        gdid: router.currentRoute.value.query.gdid,
        status: _DATA.info.status,
      },
    });
  } else {
    Notify({
      type: 'warning',
      message: '企微版该功能暂未上线，请去网页版-红珊瑚操作！',
    });
  }
};
const saveMark = () => {
  _DATA.loading = true;
  $http
    .mainSelfOrder_markSave({ id: _DATA.id, mark: _DATA.markList, type: 1 })
    .then((res) => {
      const { data, code } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.markShow = false;
        Notify({
          type: 'success',
          message: '保存成功',
        });
        loadFunc();
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 74px;
  background: @grey_color;
  min-height: calc(100vh - 118px);
  .detail_box {
    background-color: @white-color;
    padding: 0 16px;
    .top_box {
      padding: 14px 0;

      display: flex;
      .left {
        .avader_img {
          width: 40px;
          height: 40px;
          // border: 1px solid @line-color;
        }
      }
      .right {
        flex: 1;
        padding-left: 12px;
        display: flex;

        align-items: center;

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .name {
            font-size: 17px;
            font-weight: bold;
            line-height: 22px;
          }
          .desc {
            font-size: 14px;
            color: @grey-color;
            line-height: 22px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          .name {
            font-size: 20px;
            line-height: 26px;
            color: @blue-color;
          }
          .desc {
            font-size: 12px;
            line-height: 18px;
            color: @grey-color;
          }
        }
      }
    }
    .dec_box {
      padding: 8px 0;
      border-top: 1px solid @line-color;
      .dec_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        font-size: 14px;
        color: @grey-color;
        line-height: 20px;

        .price {
          text-decoration: line-through;
          color: #cccccc;
        }
        .link {
          color: @blue-color;
          text-decoration: underline;
        }
      }
      .dec_title {
        padding: 10px 0;
        color: @grey-color;
        font-size: 14px;
      }
      .list_item {
        display: flex;
        align-items: center;
        .img-box {
          .img-head {
            width: 40px;
            height: 40px;
          }
        }
        .right {
          flex: 1;
          display: flex;
          border-bottom: 1px solid @line-color;
          justify-content: space-between;
          align-items: center;
          margin-left: 12px;
          padding: 16px 0;
          .name_box {
            display: flex;
            flex-direction: column;
            .name {
              font-size: 17px;
              line-height: 24px;
              color: @black-color;
            }
            .desc {
              font-size: 12px;
              line-height: 17px;
              color: @grey-color;
            }
          }

          .btn_phone {
            font-size: 15px;
            line-height: 21px;
            color: @blue-color;
            padding: 4px 14px;
            background: #f8f8f8;
            border-radius: 3px;
          }
        }
      }
    }
    .info_item {
      .title {
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        color: @black-color;
        padding: 16px 0;
      }
      .content {
        // padding: 10px 20px;
        color: @grey-color;
        font-size: 15px;
        font-weight: normal;
        line-height: 18px;
        // border: 1px solid @line-color;
        border-radius: 5px;
        &.mark_select {
          padding: 10px 20px;

          border: 1px solid @line-color;
          display: flex;

          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .arrow_box {
      color: #576b95;
      line-height: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0 0;
      padding-bottom: 16px;
      .arrow {
        color: #576b95;
        margin-left: 6px;
      }
    }
  }
  .tab-box-bigger {
    margin-bottom: 15px;
    margin-top: 10px;
    .tab_box {
      border-bottom: 1px solid @line-color;
      :deep(.van-tabs__line) {
        display: block;
        width: 20%;
        background: @blue-color;
        height: 1px;
      }
      :deep(.van-tab__text) {
        font-weight: bold;
      }
    }
  }
  .stick-box {
    display: flex;
    justify-content: space-around;
    padding: 12px 16px;
    z-index: 9;

    button {
      margin: 0 8px;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
//@更多操作
.more-box {
  .content {
    padding: 10px;

    .same {
      display: flex;
      justify-content: left;
      align-items: flex-start;
      flex-wrap: wrap;

      div {
        width: 25%;
        text-align: center;
        padding: 10px 5px;
        color: #3e4345;

        .icon_svg {
          width: 24px;
          height: 24px;
          fill: currentColor;
        }
        p {
          font-size: 13px;
          line-height: 20px;
          margin-top: 2px;
          color: #3e4345;
        }
      }
    }
  }
}
.mark_pop {
  padding: 20px;
  .mark_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .menuBox {
    margin: 0 10px 8px 0px;
    padding: 9px 20px;
    background: #ffffff;
    border: 1px solid @line-color;
    // border-radius: 25px;
    &.checked {
      background: @blue-bg-color;
      color: @blue-color;
    }
  }
}

.icon_name {
  background: rgba(0, 137, 17, 0.1);
  color: rgba(0, 137, 17, 0.4);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
.other_info {
  background: #ffffff;
  padding: 20px;
  .name {
    font-size: 14px;
    color: @grey-color;
    line-height: 20px;
    padding-bottom: 4px;
  }
}
</style>
