<!-- 
  * @Author: NanNan 
  * @Date: 2022-04-16 15:30:26 
 * @LastEditTime: 2024-01-24 14:11:51
  * @Description: 合同详情 - 页面 
  * - v0.3.0 客户结算单 
  * - v1.1.0 样式修改，增加功能“关联作废合同”
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <List
      v-model:loading="_DATA.loading"
      :finished="_DATA.finished"
      :offset="20"
      @load="loadAdjust"
    >
      <div class="same-box" v-for="(item, index) in _DATA.list" :key="index">
        <div class="title-box">
          <p class="time">{{ item.add_time }}</p>
          <dl>
            <dt>
              {{ item.amount || '0.0' }}
            </dt>
            <dd>
              <span>{{ item.contract_edit_type_str }}</span>
            </dd>
          </dl>
        </div>
        <div class="con-box">
          <!-- 状态栏 状态：1 待审核、2 审核不通过、3 待客户确认、4 已完成、5 客户已拒绝、6 已取消 -->
          <p
            class="h1"
            :class="{
              gray: [6].indexOf(item.status) > -1,
              cancel: [2, 5].indexOf(item.status) > -1,
              done: [4].indexOf(item.status) > -1,
              wait: [1, 3].indexOf(item.status) > -1,
            }"
          >
            <span>
              <Icon name="success" v-if="item.status === 4" />
              <Icon
                name="clock-o"
                v-if="item.status === 1 || item.status === 3"
              />
              <Icon name="cross" v-if="[2, 5, 6].indexOf(item.status) > -1" />
              {{ item.status_str }}
            </span>

            <Icon
              name="ellipsis"
              color="#999"
              @click="showMoreB(item.status, item.id)"
              v-if="[1, 2, 3, 5, 6].indexOf(item.status) > -1"
            />
          </p>
          <!-- 内容 -->
          <!-- <dl class="same-flex">
                  <dt>合同编号:</dt>
                  <dd>{{ item.contract_no || '-' }}</dd>
                </dl> -->
          <dl class="same-flex">
            <dt>确认单号:</dt>
            <dd>{{ item.no || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>变更阶段:</dt>
            <dd>{{ item.construction_stage_name || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>变更金额:</dt>
            <dd>{{ item.amount || '0.0' }}元</dd>
          </dl>
          <dl class="same-flex">
            <dt>收款方式:</dt>
            <dd>
              {{ item.type_str || '-' }}
            </dd>
          </dl>
          <!-- 附件 -->
          <div class="fileList" v-if="item.attach && item.attach.length > 0">
            <FileGrid :data="item.attach"></FileGrid>
          </div>

          <!-- end -->
          <div class="about" v-if="item.intend_list?.length > 0">
            已绑定变更意向单：<span
              v-for="(v, index) in item.intend_list"
              :key="index"
            >
              <router-link
                :to="'/contractChangePurposeView?gId=' + v.id"
                class="blue_color underline"
              >
                {{ v.no }} </router-link
              ><span v-if="index + 1 != item.intend_list.length">,</span></span
            >
          </div>
          <!-- 查看详情 -->
          <p class="a-look">
            <router-link :to="'/contractChangeView?gId=' + item.id">
              查看详情
            </router-link>
          </p>
        </div>
      </div>
      <template v-if="_DATA.list.length > 0" #finished>
        <Divider class="divider-bottom">我也是有底线的</Divider>
      </template>
    </List>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无数据"
      v-if="!_DATA.loading && _DATA.list.length === 0"
    />
    <!-- end -->

    <!-- 动作面板 - 合同增减项 - 更多 -->
    <ActionSheet
      v-model:show="_TAB_ADJUST.actiones_show"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
    >
      <div class="van-action-sheet__content">
        <div
          v-for="(item, index) in _TAB_ADJUST.actions_list"
          :key="index"
          @click="adjustFunc(item.type)"
        >
          <Button
            class="van-action-sheet__item"
            type="button"
            v-if="item.action.indexOf(_TAB_ADJUST.actions_status) > -1"
          >
            <span class="van-action-sheet__name">
              {{ item.name }}
            </span>
          </Button>
        </div>
      </div>
    </ActionSheet>
  </div>
</template>

<script setup>
import FileGrid from '@/components/common/FileGrid';

import { onMounted, reactive, inject } from 'vue';
import { useRouter } from 'vue-router';
import {
  Button,
  Icon,
  ActionSheet,
  Dialog,
  Notify,
  Loading,
  Overlay,
  Empty,
  List,
  Divider,
} from 'vant';

const $http = inject('$http');

const router = useRouter();

const _DATA = reactive({
  customer_id: router.currentRoute.value.query.id, //客户ID
  contract_id: router.currentRoute.value.query.cid, //合同ID
  payment_id: router.currentRoute.value.query.pid, //款期ID
  loading: false,
  finished: false,
  refreshing: false,

  loading_text: ' ',

  type_list: ['-', '汇入合同款', '单独收款'],

  list: [],
  page: {
    page: 1,
    pages: 10,
    total: 0,
    count_page: 1,
  },
  showAllFile: false, //是否显示附件列表

  //-BPM--end
});

// @增减项相关
const _TAB_ADJUST = reactive({
  // -- 更多操作
  // -- 1 待审核、2 审核不通过、3 待客户确认、4 已完成、5 客户已拒绝、6 已取消
  actiones_show: false,
  actions_list: [
    {
      type: 1,
      name: '作废增减项',
      action: [1, 3],
    },
    {
      type: 2,
      name: '重新生成增减项',
      action: [1, 2, 3, 5, 6],
    },
  ],
  actions_id: '',
  actions_status: null, // 状态值 ‘合同详情状态’、‘合同增减项状态’
});

onMounted(() => {});

/**
 * 接口调用
 */

//合同增减项
const loadAdjust = () => {
  console.log('合同增减项');
  _DATA.loading = true;

  $http
    .getContractAdjustListApi({
      contract_id: _DATA.contract_id,
      payment_id: _DATA.payment_id,
      status: 4,
      page: _DATA.page.page,
      pages: _DATA.page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list = _DATA.list.concat(data.list);

        Object.assign(_DATA.page, { ...data.page });

        if (data.page.page < data.page.count_page) {
          _DATA.page.page++;
        } else {
          _DATA.finished = true;
        }
      } else {
        _DATA.finished = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    })
    .catch(() => {
      _DATA.finished = true;
    });
};

//-------------------------

/**
 * 操作
 */

const showMoreB = (status, id) => {
  _TAB_ADJUST.actions_id = id;
  _TAB_ADJUST.actions_status = status;
  _TAB_ADJUST.actiones_show = true;
};
//-@ 取消拉起
const onCancel = () => {
  _TAB_ADJUST.actiones_show = false;
};

//-@作废、重新生成 [增减项]变更单
const adjustFunc = (type) => {
  _TAB_ADJUST.actiones_show = false;
  Dialog.confirm({
    message:
      type === 1
        ? '确定要作废该合同增减项吗？'
        : '是否重新生成新的合同增减项？',
  }).then(() => {
    // on confirm
    if (type === 1) {
      $http
        .getContractAdjustFailApi({
          id: _TAB_ADJUST.actions_id,
        })
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
            });
            _DATA.list = [];
            _DATA.page.page = 1;
            loadAdjust();
          } else {
            Notify({
              type: 'warning',
              message: data,
            });
          }
        });
    } else if (type === 2) {
      router.push({
        path: '/contractChange',
        query: {
          id: _DATA.customer_id,
          gId: _TAB_ADJUST.actions_id,
        },
      });
    }
  });
};
</script>

<style lang="less" scoped>
.page-box {
  padding: 0;
  background: #f5f5f5;
  height: calc(100vh - 46px);
  overflow: auto;
  .same-box {
    margin-bottom: 10px;

    .title-box {
      padding: 10px 15px 8px;

      p.time {
        font-size: 14px;
        line-height: 18px;
        color: #a6a6a6;
      }

      dl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;

        dt {
          line-height: 22px;
          font-size: 18px;
          font-weight: 700;
          font-family: 'DIN Alternate';
        }

        dd {
          display: flex;
          justify-content: right;
          align-items: center;

          span {
            font-size: 14px;
          }

          em.split {
            margin: 0 15px;
            width: 1px;
            height: 8px;
            background: #e5e5e5;
          }
        }
      }
    }

    .con-box {
      background: #fff;
      padding: 15px 20px 0 30px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 14px;
        top: 0px;
        width: 1px;
        background: #e4e4e4;
        height: 100%;
      }

      p.h1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 15px;
        line-height: 14px;

        & > span {
          font-size: 12px;
        }

        &.gray {
          & > span {
            color: #d8d8d8;
          }
        }

        &.wait {
          & > span {
            color: #fca326;
          }
        }

        &.done {
          & > span {
            color: #008911;
          }
        }

        &.cancel {
          & > span {
            color: #ea3d2f;
          }
        }
      }

      dl.same-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 5px;

        dt {
          // width: 70px;
          color: #999;
          font-size: 14px;
          line-height: 18px;
          padding-right: 6px;
          flex-shrink: 0;
        }

        dd {
          flex-grow: 1;
          font-size: 14px;
          line-height: 18px;
          word-break: break-all;
        }
      }

      .file-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 5px 0 20px;

        li {
          width: 98px;
          height: 98px;
          margin: 10px 10px 0 0;

          img {
            display: block;
          }
        }
      }

      p.a-look {
        text-align: right;
        padding: 6px 0 15px;

        a {
          color: #3471ff;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
.about {
  background: rgba(2, 86, 255, 0.05);
  padding: 6px 10px;
  font-size: 13px;
  margin-top: 5px;
}
</style>
