<!--
 * @Author: NanNan
 * @Date: 2023-05-04 15:28:38
 * @LastEditTime: 2023-09-13 14:11:25
 * @Description: v1.3.7[smart] 登录页面
-->
<template>
  <div class="page-box-login" ref="loginBox">
    <div class="box">
      <div class="top">
        <svg class="logo" aria-hidden="true">
          <use xlink:href="#icon-a-hongshanhudandulogo"></use>
        </svg>
        <h1>欢迎使用红珊瑚系统</h1>
      </div>
      <div class="form">
        <Form class="form-box">
          <Field
            v-model.trim="form.account"
            placeholder="请输入账号"
            @change="flag = false"
          />
          <Field
            v-model.trim="form.password"
            placeholder="请输入密码"
            :type="pswObj.type"
            :right-icon="pswObj.icon_name"
            @change="flag = false"
            @click-right-icon="changeIcon"
          />
        </Form>
        <Button
          size="large"
          class="button-danger"
          type="primary"
          @click="onSubmit()"
          :loading="loadingBtn"
          :disabled="flag ? false : !form.account || !form.password"
        >
          立即登录
        </Button>
        <div class="about">
          <Checkbox shape="square" v-model="form.rememberMe">记住密码</Checkbox>
          <a @click="helpFunc">无法登录?</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Form, Field, Button, Checkbox, Dialog, Toast } from 'vant';
import { reactive, ref, inject, onMounted, watchEffect } from 'vue';

const $http = inject('$http');
const flag = ref(false);
const form = reactive({
  account: '',
  password: '',
  rememberMe: false,
});
onMounted(() => {
  localStorage.removeItem('ACC_TOKEN');
});

/**
 * 操作
 */
// -- 小眼睛
const pswObj = reactive({
  show: false,
  icon_name: 'closed-eye',
  type: 'password',
});
const changeIcon = () => {
  pswObj.show = !pswObj.show;
  pswObj.icon_name = pswObj.show ? 'eye-o' : 'closed-eye';
  pswObj.type = pswObj.show ? 'text' : 'password';
};
// -- 无法登录
const helpFunc = () => {
  Dialog.alert({
    title: '无法登录?',
    message: `如遇<em style="color:#0256FF">无账号,忘记密码,登录失败,忘记账号等问题,</em>可至企业微信联系<em style="color:#0256FF">人力资源部。</em>`,
    messageAlign: 'left',
    allowHtml: true,
    confirmButtonText: '知道了',
    confirmButtonColor: '#0256FF',
  }).then(() => {
    // on close
  });
};
// -- 表单提交
const loadingBtn = ref(false);
const onSubmit = () => {
  loadingBtn.value = true;
  submitApi();
};

// -- api 提交
const submitApi = () => {
  $http
    .getH5LoginApi(form)
    .then((res) => {
      const { code, data, message } = res;
      loadingBtn.value = false;
      if (code === 0) {
        localStorage.setItem('ACC_TOKEN', data['acc-token']);
        localStorage.setItem('USERID_ID', data.userid);
        localStorage.setItem('ACCOUNT_ID', data.id);
        window.location.href = '/listPage';
      } else {
        Toast(data || message);
      }
    })
    .catch(() => {
      loadingBtn.value = false;
    });
};
// -- 监听回车
watchEffect(() => {
  document.onkeydown = () => {
    let _key = window.event.keyCode;
    if (_key === 13 && !loadingBtn.value) {
      onSubmit();
    }
  };
});
</script>
<style lang="less" scoped>
@import '@/assets/styles/login.less';
</style>
