<!--
 * @Author: Jessica
 * @Date: 2024-05-23 13:57:49
 * @LastEditTime: 2024-05-28 16:22:39
 * @Description:V1.6.1-协议-三级页面-重构
 不区分 当年/往年
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 总计 -->
        <div class="data_card_top">
          <div class="title flex_line_between">
            <span class="size17 bold">协议</span>
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!--不区分 当年/往年 -->
          <Row>
            <Col span="7">
              <div class="grey align_center">协议目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target === '' ? '-' : _DATA.info?.target }}
                </span>
              </div>
            </Col>
            <Col span="9">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info.actual || _DATA.info.actual === 0
                      ? _DATA.info.actual
                      : '-'
                  }}
                </span>
              </div>
              <!-- 差值 -->
              <div v-if="_DATA.info?.gap !== ''">
                <div
                  class="flex_line flex_line_center"
                  v-if="_DATA.info.actual > _DATA.info.target"
                >
                  <span class="bold green">{{ _DATA.info.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_line_center"
                  v-else-if="_DATA.info.actual < _DATA.info.target"
                >
                  <span class="bold orange">{{ _DATA.info.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_line_center" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">成功率</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.info.success_rate || _DATA.info.success_rate === 0
                      ? _DATA.info.success_rate + '%'
                      : '-'
                  }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 图表 -->
        <!-- 不区分 当年/往年 -->
        <div class="data_card_table border_top">
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">人员</div>
            </Col>
            <Col span="11" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <div class="chart_left"></div>
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <Col span="4">
              <div class="grey align_center">完成率</div>
            </Col>
            <Col span="4">
              <div class="grey align_center">成功率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row v-if="index + 1 <= _DATA.maxCount || _DATA.open">
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ item.name }}
                </div>
              </Col>
              <Col span="11" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <div class="chart_left">线上</div>
                  <chartJD
                    :data="{
                      actual: item.online.actual,
                      target: item.online.target
                        ? item.online.target
                        : item.online.actual,
                      list: _DATA.line_values,
                      type: 1,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ item.online?.target === '' ? '-' : item.online?.target }}
                  </div>
                </div>
                <div style="display: flex">
                  <div class="chart_left">自主</div>
                  <chartJD
                    :data="{
                      actual: item.diy.actual,
                      target: item.diy.target
                        ? item.diy.target
                        : item.diy.actual,
                      list: _DATA.line_values,
                      type: 1,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ item.diy?.target === '' ? '-' : item.diy?.target }}
                  </div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <div class="chart_left"></div>
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 完成率 -->
              <Col span="4" class="flex_line_center">
                <div>
                  <div class="flex_line_center">
                    <span>{{
                      item.online?.done_rate === ''
                        ? '-'
                        : item.online?.done_rate + '%'
                    }}</span>
                  </div>
                  <div class="flex_line_center">
                    <span>{{
                      item.diy?.done_rate === ''
                        ? '-'
                        : item.diy?.done_rate + '%'
                    }}</span>
                  </div>
                </div>
              </Col>
              <!-- 成功率 -->
              <Col span="4" class="flex_line_center">
                <div>
                  {{
                    item.success_rate || item.success_rate === 0
                      ? item.success_rate + '%'
                      : '-'
                  }}
                </div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 -->
        <div class="p_15 flex_center">
          <p class="box_8 grey_bg"></p>
          <p class="ml_5">目标</p>
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
          <p class="box_8 red2_bg ml_30"></p>
          <p class="ml_5">超额</p>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  defineExpose,
} from 'vue';
import { Row, Col, Icon, Notify, Skeleton } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 2, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 2, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  //-进度条相关
  line_values: [],
  middle_value: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  $http
    .targetStatistic_personContractNum({
      department_id: props.data.depId,
      year: props.data.yearId,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.info = data.info.total;
        _DATA.list = data.list;
        _DATA.line_values = data.line_values;
        _DATA.middle_value = data.middle_value;
      } else {
        _DATA.info = {};
        _DATA.list = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
      emit('loadEnd');
    });
};

//-规则说明--begin
const htmlTitle = '协议_规则';
const htmlContent = `<p>1、【尚层装饰室内设计协议】满足合同签约金额≥15000且财务确认收首款，计数1；</p>
  <p>2、【尚层装饰配饰设计协议】满足合同签约金额≥8000且财务确认收首款，计数0.5；</p>
  <p>3、【尚层装饰室内设计协议】＜15000累计财务确认收产品款金额≥500000，协议数量计数1；</p>
  <p>4、【尚层装饰配饰设计协议】＜8000累计财务确认收产品款金额≥200000，协议数量计数0.5；</p>
  <p>5、成功率：当年非自主见面客户协议成功率；</p>`;
const createDanger = () => {
  emit('createDanger', 'protocolCountRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
