<!--
 * @Author: NanNan
 * @Date: 2022-09-08 17:28:04
 * @LastEditTime: 2022-09-09 19:25:35
 * @Description: v1.2.0 BPM去收款 前客户编码验证
-->
<template>
  <div class="content">
    <div class="content_item nopadding">
      <h3>请录入BPM客户编码</h3>
      <p class="description p25">
        在红珊瑚签约之后，需要在BPM内创建客户并进行录入合同，请将BPM客户编码录入红珊瑚，录入后可下载合同。
      </p>

      <div class="dangerForm">
        <Form ref="ruleFormRef" @failed="failed()" @submit="onSubmit()">
          <div class="content_box p25">
            <CellGroup inset>
              <Field
                v-model="_DATA.info.bpm_id"
                label="客户编码"
                name="客户编码"
                placeholder="请输入BPM客户编码"
                input-align="right"
                error-message-align="right"
                maxlength="20"
                :rules="[
                  {
                    required: true,
                    message: '请输入BPM客户编码',
                    trigger: 'onChange',
                  },
                ]"
              />
            </CellGroup>
          </div>
          <div class="content_button">
            <Button block :loading="_DATA.btnLoading" native-type="submit">
              提交
            </Button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  onMounted,
  reactive,
  ref,
  inject,
  defineEmits,
} from 'vue';
import { Form, CellGroup, Field, Button, Notify } from 'vant';

const props = defineProps(['data', 'id']);
const $http = inject('$http');
const emit = defineEmits(['changeBPM']);
onMounted(() => {
  console.log(props.data.show, props.id);
});

const _DATA = reactive({
  info: {
    bpm_id: null,
    customer_id: props.id, //客户ID
  },
  btnLoading: false,
});

const ruleFormRef = ref(null);
const onSubmit = () => {
  _DATA.btnLoading = true;
  if (!ruleFormRef.value) return;
  $http.getCustomerBpmEditApi({ ..._DATA.info }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      emit('changeBPM');
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

const failed = () => {
  _DATA.btnLoading = false;
};
</script>
