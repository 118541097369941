<!--
 * @Author: Jessica
 * @Date: 2024-05-24 10:23:19
 * @LastEditTime: 2024-05-29 18:08:43
 * @Description:V1.6.1-交付动作数据
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title">交付动作数据</div>
          <!-- 图表 -->
          <Row>
            <Col span="24">
              <div class="chart" id="container"></div>
            </Col>
          </Row>
          <!-- 图例 -->
          <Row class="mt_20">
            <Col
              style="padding-left: 30px"
              span="12"
              v-for="(item, index) in _DATA.circle_list"
              :key="index"
            >
              <div class="flex_line">
                <div class="flex_line w90">
                  <p
                    class="box_8"
                    :class="{
                      blue_bg: index == 0,
                      purple_bg: index == 1,
                      green2_bg: index == 2,
                      orange_bg: index == 3,
                    }"
                  ></p>
                  <p class="ml_5 color_4d size13">{{ item.name }}</p>
                </div>
                <p class="ml_5 color_1d size15 bold">{{ item.value }}</p>
              </div>
            </Col>
          </Row>
        </div>
      </Skeleton>
      <!-- 按钮切换-->
      <div class="data_card_table">
        <ul class="site_type_list clearfix">
          <li
            class="size15 pull-left"
            :class="{ checked: _DATA.tabActive == i.id }"
            v-for="i in _DATA.typeList"
            :key="i.id"
            @click="changeStage(i)"
          >
            {{ i.name }}
          </li>
        </ul>
      </div>

      <!-- 按部门 加载图表-->
      <div>
        <div class="data_card_table border_top">
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col span="15" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <Col span="4">
              <div class="grey size13 align_center">存量</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.listStage" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="15" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      actual: [
                        item.start,
                        item.pre_check,
                        item.check,
                        item.visit,
                      ],
                      list: _DATA.line_values,
                    }"
                  ></chartJD>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <Col span="4" class="flex_line_center">
                <div>
                  {{ item.all }}
                </div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 区分当年/往年-->
        <div class="p_15 flex_center">
          <p class="box_8 blue_bg"></p>
          <p class="ml_5">已启动</p>
          <p class="box_8 purple_bg ml_15"></p>
          <p class="ml_5">待预验收</p>
          <p class="box_8 green2_bg ml_15"></p>
          <p class="ml_5">待客户验收</p>
          <p class="box_8 orange_bg ml_15"></p>
          <p class="ml_5">待客服回访</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD2';
import { renderFunnelChart } from '@/utils/chartCircle';
import {
  defineProps,
  defineEmits,
  reactive,
  inject,
  watch,
  onMounted,
  ref,
  nextTick,
} from 'vue';
import { Row, Col, Skeleton, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  index: 5, //处在页面的第几个位置
  depIndex: 5, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  typeList: [],
  listStage: [],
  loadingStage: false,
  tabActive: props.data.tabActive ? props.data.tabActive : 1,
  //-环形图
  circle_list: [],
  //-进度条相关
  line_values: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      _DATA.tabActive = 2;
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载
const onLoad = (type) => {
  $http
    .targetStatistic_getActionNumByStage({
      department_id: props.data.depId,
      year: props.data.yearId,
      type_sort: _DATA.tabActive,
      column_num: 3,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.line_values = data.line_values;
        _DATA.info = data.info;
        _DATA.listStage = data.list;
        getListFun(data.info);
        getParams();
      } else {
        _DATA.listStage = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
      // 非切换阶段
      if (!type) {
        nextTick(() => {
          emit('loadEnd');
        });
      }
    });
};
//-获取阶段参数
const getParams = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.typeList = data.construction_stage;
    } else {
      _DATA.typeList = [];
    }
  });
};
/**
 * 操作
 */
//-@切换阶段
const changeStage = (i) => {
  _DATA.tabActive = i.id;
  onLoad('changeStage');
};
//-@跳转三级页面
const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depSiteData',
    query: {
      depId: item.id,
      depName: item.short_name,
      pos: _DATA.depIndex,
      tabSiteActive: _DATA.tabActive,
    },
  });
};

//-环形图--begin
const chartsRef = ref();
//-@-获取环形图数据/加载--begin
const getListFun = (list) => {
  var height = 125;
  const padding = [0, 10];
  const color = ['#0256FF', '#722ED1', '#14C9C9', '#F7BA1E'];
  _DATA.circle_list = [
    { id: 1, name: '已启动', value: list.start },
    { id: 2, name: '待预验收', value: list.pre_check },
    { id: 3, name: '待客户验收', value: list.check },
    { id: 4, name: '待客服回访', value: list.visit },
  ];
  nextTick(() => {
    height = chartsRef?.value?.clientHeight ?? 125;
    renderFunnelChart('container', height, padding, _DATA.circle_list, color);
  });
};
//-@-获取对比漏斗图数据/加载--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
