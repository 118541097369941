<!--
 * @Author: NanNan
 * @Date: 2022-04-12 14:52:37
 * @LastEditTime: 2022-04-12 14:58:00
 * @Description: 
-->
<template>
  <div>404</div>
</template>

<script>
export default {
  name: '404Page',
};
</script>

<style scoped></style>
