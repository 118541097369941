<!--
 * @Author: Jessica
 * @Date: 2023-09-19 16:19:49
 * @LastEditTime: 2023-09-22 15:05:18
 * @Description: 二级页面-营销费用
-->
<template>
  <div class="page-box page-box-all">
    <!-- 集团投放 -->
    <groupLaunchData id="indexGroup" :data="detail" :loading="loading" />
    <!-- 自主投放 -->
    <autoLaunchData :data="detail" :loading="loading" />
    <!--V1.4.6 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { reactive, ref, onMounted, inject, nextTick } from 'vue';
import { Notify } from 'vant';
import groupLaunchData from '@/components/financeData/groupLaunchData'; // 集团投放
import autoLaunchData from '@/components/financeData/autoLaunchData'; // 自主投放
import sideMenu from '@/components/sideMenu';
const router = useRouter();
const _DATA = reactive({
  // 卡片位置
  pos: Number(router.currentRoute.value.query.pos),
});
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');

onMounted(() => {
  onLoad();
});
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http.targetStatistic_financeData().then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      detail.value = data;
      nextTick(() => {
        let height =
          _DATA.pos == 2
            ? document.getElementById('indexGroup').offsetHeight + 10
            : 0;
        window.scrollTo({ top: height, behavior: 'smooth' });
      });
    } else {
      loading.value = false;
      detail.value = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/financeData/targetIndex.less';
</style>
