<!--
 * @Author: gest
 * @Date: 2023-10-13 17:48:39
 * @LastEditTime: 2024-01-18 09:56:38
 * @Description:  v1.3.3 申请精装工地
  V1.5.3 申请精装工地改版
-->
<template>
  <div class="page-waper">
    <Overlay :show="state.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <div class="top_info white_color">
        <div class="font_size_15 font_bold">
          {{ state.baseInfo.name || '' }}
          {{ state.baseInfo.estate_str ? '·' + state.baseInfo.estate_str : '' }}
        </div>
        <!-- <div class="font_size_13" style="color: rgba(255, 255, 255, 0.7)">
          客户编码：{{ state.info.customer_no || '-'
          }}<span class="ml_5 mr_5">|</span> BPM编码：{{
            state.info.bpm_id || '-'
          }}
        </div> -->
        <div class="display_flex mt_5">
          <div v-if="state.info.is_b == 1" class="tag_box">B+项目</div>
          <div v-if="state.info.is_return == 1" class="tag_box">工地返单</div>
        </div>
      </div>

      <Form ref="ruleFormRef">
        <div class="part mb_15">
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              选择合同
            </label>
            <Field
              v-model="form_info.contract_name"
              name="picker"
              readonly
              placeholder="选择"
              right-icon="arrow"
              @click="
                if (state.contractList && state.contractList.length > 0) {
                  state.showContract = true;
                } else {
                  Notify({ type: 'warning', message: '您还没有产品合同噢~' });
                }
              "
              :rules="[
                {
                  required: true,
                  trigger: 'onChange',
                  message: '选择',
                },
              ]"
            />
          </div>
          <!-- 69 精装 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计开工日期
            </label>
            <Field
              v-model="form_info.start_date"
              name="picker"
              readonly
              placeholder="选择"
              right-icon="arrow"
              @click="state.time_show = true"
              :rules="[
                {
                  required: true,
                  trigger: 'onChange',
                  message: '请选择预计开工日期',
                },
              ]"
            />
          </div>
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工面积
            </label>
            <Field
              v-model="form_info.size"
              type="number"
              @blur="getCountAuto"
              :rules="[
                {
                  required: true,
                  message: '请填写施工面积',
                  trigger: 'onChange',
                },
                {
                  message: '请填写正确的施工面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>

          <div class="cell_no">
            <label class="form_title">
              <span class="grey_color">最迟派单时间</span>
            </label>
            <p class="show_text">{{ form_info.dispatch_date || '-' }}</p>
          </div>
        </div>

        <div class="part mb_15">
          <div class="font_size_18 p_15 font_bold">选择派单方式</div>
          <!-- 提示信息 -->
          <div class="tip_box">
            <div class="tip_info">
              <Icon name="warning" color="#F37020" size="16" />
              <p class="tip_title">提示</p>
            </div>

            <!-- 工地返单-->
            <p class="tip_text" v-if="state.info.is_return == 1">
              工地返单项目默认分派给返单中心
            </p>
            <p class="tip_text" v-else>
              若需要指定派，请务必在最迟派单时间之前达到B+项目，并及时修改派单信息！否则，到达系统最迟派单时间将自动轮派
            </p>
          </div>
          <div class="cell_no" v-if="state.info.is_return != 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              是否标记指派
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form_info.tag_assign"
                  direction="horizontal"
                  checked-color="#008911"
                  :disabled="state.info.is_b != 1"
                >
                  <Radio :name="1">是</Radio>
                  <Radio :name="2">否</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div class="cell no_border">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              标记派单形式
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form_info.tag_dispatch_type"
                  checked-color="#008911"
                >
                  <Radio :name="1">立刻派</Radio>
                  <Radio :name="2">到达最迟派单时间派</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <div
            class="cell_no"
            v-if="form_info.tag_assign == 1 || state.info.is_return == 1"
          >
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              标记派单中心
            </label>

            <p class="show_text" v-if="state.info.is_return == 1">
              {{ form_info.tag_deliver_center || '-' }}
            </p>
            <Field
              v-model="form_info.tag_deliver_center"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '选择',
                },
              ]"
              placeholder="选择"
              @click="state.showPicker_center = true"
              v-else
            />
          </div>
          <div class="cell_no" v-if="form_info.tag_deliver_center_status == 0">
            <div class="font_size_13" style="color: #ea0000">
              所选择的指定交付中心咱不满足接单条件，待其满足接单条件后系统将会自动派单！
            </div>
          </div>
        </div>
        <div class="part">
          <!-- 备注 -->
          <div class="cell no_border block mt_15">
            <label class="form_title">备注说明</label>
            <Field
              v-model="form_info.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            />
          </div>
        </div>

        <div class="btnSumbit btnSumbit1">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            提交
          </Button>
        </div>
      </Form>
      <!--picker-选择合同 --->
      <Popup v-model:show="state.showContract" position="bottom">
        <Picker
          :columns="state.contractList"
          :columns-field-names="{
            text: 'name',
            value: 'id',
          }"
          :default-index="
            state.contractList.findIndex((v) => {
              return v.contract_id == form_info.contract_id;
            })
          "
          @confirm="(v) => onConfirmContract(v)"
          @cancel="state.showContract = false"
        />
      </Popup>
      <!-- 年月日 组件 -->
      <Popup v-model:show="state.time_show" position="bottom">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          title="选择预计开工日期"
          @confirm="changePicker"
          @cancel="state.time_show = false"
        />
      </Popup>
      <!-- 派单中心组件 -->
      <Popup v-model:show="state.showPicker_center" position="bottom">
        <Picker
          :columns="state.info.departments"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'center')"
          @cancel="state.showPicker_center = false"
          :default-index="
            state.info.departments?.findIndex((v) => {
              return v.id == form_info.tag_deliver_center_id;
            })
          "
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import {
  Form,
  Field,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Button,
  Loading,
  Overlay,
  RadioGroup,
  Radio,
  DatetimePicker,
} from 'vant';
import moment from 'moment';
import patterns from '@/utils/pattern';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loading: false,
  loadingConfirm: false,
  baseInfo: {},
  info: {},
  time_show: false,
  showPicker_center: false,
  showContract: false,
});

const form_info = reactive({
  contract_id: router.currentRoute.value.query.cid || '', //合同
  start_date: '',
  dispatch_date: '',
  tag_assign: null,
  size: '',
  tag_dispatch_type: null,
  tag_deliver_center_id: '',
  tag_deliver_center: '',
  tag_deliver_center_status: null,
  remark: '',
});
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
//-end
const ruleFormRef = ref(null);
//-验证
const { houseSizeReg } = patterns;

const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;
//-参数&详情-begin
onMounted(() => {
  getBaseInfo();
  getContractListParmas();
});
const getBaseInfo = async () => {
  state.loading = true;

  await $http
    .getSaleInfoBaseApi({
      id: router.currentRoute.value.query?.id,
    })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        state.baseInfo = data;
      } else {
        Notify({ type: 'warning', message: res.data });
      }
    });
};
//-@头部合同参数
const getContractListParmas = async () => {
  state.loading = true;
  await $http
    .getSimpleListByCustomer({
      customer_id: router.currentRoute.value.query?.id,
      type: 3, //产品
    })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        state.contractList = data;
      } else {
        Notify({ type: 'warning', message: res.data });
      }
    });
};
//-获取详情
const getInfo = async () => {
  state.loading = true;
  await $http
    .constructionDispatch_info({ contract_id: form_info.contract_id })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        state.info = data;
        form_info.tag_assign = data.tag_assign;
        form_info.tag_dispatch_type = data.tag_dispatch_type;
        form_info.size = data.size;
        form_info.remark = data.remark;
        if (data.start_date) {
          form_info.start_date = data.start_date;
        }
        if (data.dispatch_date) {
          form_info.dispatch_date = data.dispatch_date;
        }

        if (state.info.is_b != 1 && state.info.is_return != 1) {
          form_info.tag_assign = 2;
        }
        if (state.info.tag_deliver_center_id) {
          form_info.tag_deliver_center_id = data.tag_deliver_center_id;
          form_info.tag_deliver_center = data.tag_deliver_center;
          form_info.tag_deliver_center_status = data.departments.find((v) => {
            return (v.id = form_info.tag_deliver_center_id);
          })?.status;
        }
      } else {
        Toast(data);
      }
    });
};
//-end
//-预计派单时间自动计算----begin
const getCountAuto = () => {
  $http
    .getContractScheduleBySize({
      customer_id: state.info.customer_id,
      contract_type: form_info.contract_type ?? '',
      size: form_info.size,
      start_date: form_info.start_date,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        form_info.dispatch_date = data.expected_dispatch_date;
      } else {
        form_info.dispatch_date = '';
      }
    });
};
//-操作&详情--begin

//-picked 操作
const onConfirm = (value, type) => {
  if (type === 'center') {
    if (value) {
      form_info.tag_deliver_center_id = value.id;
      form_info.tag_deliver_center = value.name;
      form_info.tag_deliver_center_status = value.status;
    }
    state.showPicker_center = false;
  }
};
//-@日期
const currentDate = ref(new Date());
const changePicker = (value) => {
  state.time_show = false;
  form_info.start_date = moment(value).format('YYYY-MM-DD');
};
//选择合同确认
const onConfirmContract = (value) => {
  form_info.contract_id = value.id;
  form_info.contract_name = value.name;
  form_info.contract_type = value.type;
  console.log(form_info.contract_type);
  state.showContract = false;
  getInfo();
};
//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goSave();
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
    });
};
const goSave = () => {
  state.loadingConfirm = true;
  console.log(form_info);
  $http
    .applyFineConstruction({
      ...form_info,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--表单end---------------------
</script>

<style lang="less" scoped>
.page-waper {
  width: 100%;
  background: #f8f8f8;
  box-sizing: border-box;
  padding-bottom: 90px;
  .top_info {
    background: linear-gradient(90deg, #0256ff 2%, #437df1 98%);
    padding: 15px;
  }
  .tag_box {
    padding: 2px 6px;
    border-radius: 2px;
    color: #008911;
    font-size: 12px;
    font-weight: 600;
    background: #ffffff;
    margin-right: 8px;
  }
  .part {
    background: #fff;
    margin-bottom: 15px;
    .show_text {
      font-size: 15px;
      color: #3d3d3d;
    }
    .cell {
      padding: 15px 0 0 0;
      margin: 0 15px;

      .form_title {
        font-size: 15px;
        margin-bottom: 15px;
      }
      &.no_border {
        :deep(.van-cell) {
          padding: 0px;
          border: 0;
        }
      }
      :deep(.van-radio-group) {
        width: 100%;
      }
      :deep(.van-radio) {
        padding: 15px 0;
        border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      }
    }
    .cell_no {
      padding: 10px 0px;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      &:last-child {
        border-bottom: 0;
      }
      .text {
        color: #3471ff;
      }
      .form_title {
        font-size: 15px;
        min-width: fit-content;
      }
      :deep(.van-cell) {
        padding: 5px 0;
        text-align: right;
        border: 0;
        .van-field__error-message {
          text-align: right;
        }
        .van-field__control {
          text-align: right;
          justify-content: flex-end;
          padding-left: 10px;
        }
        .van-checkbox-group {
          justify-content: flex-end;
        }
        .van-radio,
        .van-checkbox {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .btnSumbit {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 120px;
    background: #fff;
    box-shadow: 0px -1px 4vw rgb(0 0 0 / 10%);
    &.btnSumbit1 {
      height: 90px;
    }
    .btn {
      width: calc(100% - 50px);
      margin: 0 25px;
      position: relative;
      top: 20px;
    }
    > p {
      width: 100%;
      height: 20px;
      position: relative;
      top: 30px;
      font-size: 15px;
      line-height: 20px;
      color: #aaaaaa;
      text-align: center;
    }
  }
  //-提示信息
  .tip_box {
    padding: 15px 20px;
    background: #fdf0e8;
    .tip_info {
      display: flex;
      align-items: center;
      .tip_title {
        font-size: 15px;
        color: #f37020;
        padding-left: 5px;
        font-weight: bold;
      }
    }
    .tip_text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      padding-top: 10px;
    }
  }
}
</style>
