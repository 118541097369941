<!--
 * @Author: gest
 * @Date: 2023-09-12 13:51:19
 * @LastEditTime: 2023-10-27 11:25:02
 * @Description: v1.4.6 进场产品验收记录单
-->
<template>
  <div class="formBase">
    <div class="content mb_15 white_bg">
      <div class="cell_border flex_between">
        <div class="flex_column flex1 mr_15">
          <div>是否为裸单项目</div>
        </div>
        <div>
          {{
            form.info.bare_single == 1
              ? '是'
              : form.info.bare_single == 2
              ? '否'
              : '-'
          }}
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <!-- v-for -->
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="flex1 mr_20">{{ item.name }}</div>
            <div>
              {{ item.value1 == 1 ? '有' : item.value1 == 2 ? '无' : '-' }}
            </div>
          </div>
        </div>

        <div
          class="list_field"
          style="background-color: #fafafa"
          v-if="item.value1 == 1"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">进场时间</div>
              <div>{{ item.value2 || '-' }}</div>
            </div>
          </div>
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">验收状态</div>
              <div
                :class="
                  item.value3 == 1
                    ? 'green_color'
                    : item.value3 == 2
                    ? 'red_color'
                    : ''
                "
              >
                {{
                  item.value3 == 1 ? '合格' : item.value3 == 2 ? '不合格' : '-'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- v-for end-->
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>
            {{ form.info.remark || '-' }}
          </div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
