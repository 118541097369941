<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2023-11-30 16:44:22
 * @Description: v0.2.2 设计节点 - 列表 - 通知验收
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="node-name-box">
      <em>节点名称：</em>
      {{ node_name || '-' }}
    </div>
    <Form class="form-box" ref="formRef">
      <div class="person">
        <p class="label-name">验收人</p>
        <div class="cus-name">
          <span>{{ customer_name }}</span>
        </div>
      </div>
      <div class="person" v-if="second_check_role_id != 0">
        <p class="label-name">验收人2</p>
        <div class="cus-name">
          <span>{{ second_check_user_name }}</span>
        </div>
      </div>
      <p class="label-name">
        <em>*</em>
        备注
      </p>
      <Field
        v-model.trim="form_info.remarks"
        rows="5"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加备注..."
        style="border: none; padding: 0"
      ></Field>
      <template v-if="type_sort == 1 && c_post_id == -1">
        <p class="label-name">
          <em>*</em>
          资金规划
        </p>
        <Field
          v-model="form_info.plan_fund_name"
          right-icon="arrow-down"
          readonly
          name="picker"
          placeholder="请选择资金规划"
          @click="fundOption.showFundPicker = true"
        />
        <Popup v-model:show="fundOption.showFundPicker" position="bottom">
          <Picker
            :columns="fundOption.plan_fund_list"
            :columns-field-names="customFieldName"
            @confirm="onConfirmFund"
            cancel-button-text="清除"
            @cancel="onRemoveFund"
            :default-index="
              fundOption.plan_fund_list.findIndex((v) => {
                return v.id == form_info.plan_fund;
              })
            "
          />
        </Popup>
        <div class="tip">
          注意事项：请如实填写为客户做的资金规划金额，客户会在线确认！
        </div>
      </template>

      <!-- end -->
    </Form>
    <div class="stick-box">
      <Button type="primary" size="large" @click="onSubmit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  Form,
  Field,
  Overlay,
  Loading,
  Button,
  Notify,
  Toast,
  Popup,
  Picker,
} from 'vant';
const $http = inject('$http');
const router = useRouter();
// const design_id = ref(router.currentRoute.value.query.did); //设计节点列表 过来携带 设计id
const design_node_id = ref(router.currentRoute.value.query.nid); //设计节点列表 过来携带 节点id
const node_name = ref(router.currentRoute.value.query.nname); //携带 节点名称

const customer_id = ref(router.currentRoute.value.query.id);
const customer_name = ref(router.currentRoute.value.query.cname || '-');
const second_check_role_id = ref(router.currentRoute.value.query.id2);
const second_check_user_name = ref(
  router.currentRoute.value.query.cname2 || '-'
);
//type_sort == 1 && c_post_id == -1 显示资金规划，必填
const type_sort = ref(router.currentRoute.value.query.type_sort);
const c_post_id = ref(router.currentRoute.value.query.c_post_id);

// 资金规划
const fundOption = reactive({
  showFundPicker: false,
  plan_fund_list: [],
});

//-@下拉配置
const customFieldName = {
  text: 'name',
  value: 'id',
};
//-资金规划参数
const loadParams = () => {
  $http.getDesignNodeFormEditParams().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      fundOption.plan_fund_list = data.plan_fund_list;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//-选择资金规划
const onConfirmFund = (value) => {
  if (value) {
    form_info.plan_fund_name = value.name;
    form_info.plan_fund = value.id;
  }
  fundOption.showFundPicker = false;
};
//-清空资金规划
const onRemoveFund = () => {
  form_info.plan_fund_name = '';
  form_info.plan_fund = '';
  fundOption.showFundPicker = false;
};
//-end

const form_default = reactive({
  design_node_id: design_node_id.value, //*设计节点id
  customer_id: customer_id.value, //客户id
  remarks: '', //*备注
  //-资金规划
  plan_fund: '',
  plan_fund_name: '',
});
const form_info = reactive({});
const loading = ref(false);

/**
 * 初始化
 */
onMounted(() => {
  Object.assign(form_info, form_default);
  loadParams();
});

/**
 * 操作
 */
//-@ 提交
const formRef = ref(null);
const onSubmit = () => {
  if (!form_info.remarks) {
    Toast('请填写备注');
    return false;
  }
  if (type_sort.value == 1 && c_post_id.value == -1 && !form_info.plan_fund) {
    Toast('请填写资金规划');
    return false;
  }
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http.getDesignNoticeApi(form_info).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
  });
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;

  .node-name-box {
    padding: 20px;
    font-size: 16px;
    line-height: 18px;
    background: #f5f5f5;
    text-align: center;

    em {
      color: #999;
    }
  }

  .form-box {
    padding: 0 20px;
    padding-bottom: 84px;
    .person {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      align-items: center;
    }

    .label-name {
      font-size: 16px;
      line-height: 20px;
      margin: 15px 0;
      font-weight: 700;

      &.white {
        color: #fff;
      }

      em {
        color: #ea3d2f;
      }
    }

    .cus-name {
      span {
        color: #1a1a1a;
        font-size: 15px;
      }
    }
    .tip {
      padding-top: 10px;
      font-size: 13px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #e6a23c;
    }
  }
}
</style>
