<!--
 * @Author: Jessica
 * @Date: 2022-05-19 09:37:45
 * @LastEditTime: 2023-02-18 11:24:04
 * @Description: 
-->
<template>
  <!-- 工地列表组件 -->
  <div class="page-wrap">
    <NavBar
      safe-area-inset-top
      title="选择工地"
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
    />
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="state.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
        />
      </form>
    </div>

    <div class="checkedList noBorder">
      <CheckboxGroup
        v-model="form.checked"
        v-if="form.list.length"
        :max="state.maxLength"
      >
        <List
          v-model:loading="state.loading"
          :finished="state.finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <Cell v-for="item in form.list" :key="item.id">
            <div class="site_list">
              <Checkbox :name="item.id" @click.stop="getClick(item)">
                <div class="site_item clearfix">
                  <p class="name">
                    {{ item.estate_str }}
                  </p>
                  <p class="extra">
                    {{
                      item.project_manager_name +
                      (item.project_manager_name && item.worker_leader_name
                        ? ' / '
                        : '') +
                      item.worker_leader_name +
                      (item.scene_leader_name ? ' / ' : '') +
                      item.scene_leader_name
                    }}
                  </p>

                  <div class="tags">
                    <span v-for="(v, index) in item.tag_names" :key="index">
                      {{ v }}
                    </span>
                  </div>
                </div>
              </Checkbox>
            </div>
          </Cell>
        </List>
      </CheckboxGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="footer clearfix">
      <p class="pull-left">
        已选
        <span>{{ form.checked.length }}</span>
        个
      </p>
      <Button
        class="pull-right"
        type="primary"
        size="normal"
        style="width: 115px; margin: 9px 0px; border-radius: 5px"
        @click="btnSumbit()"
      >
        确定
      </Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, onMounted, defineProps, defineEmits } from 'vue';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Search,
  List,
  Cell,
  Empty,
  NavBar,
  Notify,
  Toast,
} from 'vant';
const props = defineProps(['data']);
const emit = defineEmits(['reviewClose', 'reviewOk']);
const $http = inject('$http');
const state = reactive({
  loading: true,
  finished: false,
  kw: '',
  defaultPage: 1,
  maxLength: 3,
  pageInfo: {
    total: 0, //总条数
    page: 1, //当前页
    count_page: 1,
    pages: 10, //每页多少条
  },
});
const form = reactive({
  checked: [],
  checkedList: props.data ? JSON.parse(JSON.stringify(props.data)) : [],
  clickChecked: [],
  clickCheckedList: props.data ? JSON.parse(JSON.stringify(props.data)) : [],
  list: [],
});
onMounted(() => {
  form.clickChecked = form.checked = form.checkedList.map((v) => {
    return v.id;
  });
  searchFun();
});
//-搜索
const searchFun = () => {
  form.list = [];
  state.finished = false;
  state.pageInfo.total = 0;
  state.pageInfo.page = 1;
  state.pageInfo.count_page = 1;
  state.pageInfo.pages = 10;
  onLoad();
};

//-获取工地列表
const onLoad = () => {
  state.loading = true;
  $http
    .customerSiteList({
      kw: state.kw,
      page: state.pageInfo.page ? state.pageInfo.page : state.defaultPage,
      pages: state.pageInfo.pages,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.pageInfo = res.data.page;
        form.list = [...form.list, ...res.data.list];
        if (state.pageInfo.page < state.pageInfo.count_page) {
          state.pageInfo.page++;
        } else {
          state.finished = true;
        }
      } else {
        state.finished = true;
        Toast(res.msg || res.data);
      }
    });
};

const getClick = (item) => {
  if (form.clickChecked.indexOf(item.id) > -1) {
    form.clickChecked.splice(form.clickChecked.indexOf(item.id), 1);
    form.clickCheckedList = form.clickCheckedList.filter((v) => {
      return v.id != item.id;
    });
  } else {
    if (form.clickChecked.length < state.maxLength) {
      form.clickChecked.push(item.id);
      form.clickCheckedList.push(item);
    } else {
      Notify({
        type: 'warning',
        message: '您最多只能选择三个工地，目前已超过限制啦',
      });
    }
  }
};
//-确认操作
const btnSumbit = () => {
  form.checkedList = JSON.parse(JSON.stringify(form.clickCheckedList));
  emit('reviewOk', form.checkedList);
};

//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/siteList.less';
</style>
