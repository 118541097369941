<!--
 * @Author: Jessica
 * @Date: 2023-07-07 10:46:21
 * @LastEditTime: 2023-07-08 09:23:33
 * @Description: V1.4.1 升级选项报价编辑
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <TempQuotationEdit :data="_DATA.tempOptions" />
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { Loading, Overlay } from 'vant';
import TempQuotationEdit from '@/components/design/TempQuotationEdit'; //-设备/升级项报价编辑模板
const router = useRouter();
const _DATA = reactive({
  loading: false,
  //-模板-参数
  tempOptions: {
    type: 2,
    name: '升级选项报价单',
    customer_id: router.currentRoute.value.query.id,
  },
});
</script>

<style lang="less" scoped>
.page-box {
  padding: 0 0px;
  background: #f5f5f5;
  min-height: calc(100% - 46px);
}
</style>
