<!--
 * @Author: gest
 * @Date: 2023-09-14 17:28:59
 * @LastEditTime: 2023-10-27 11:17:41
 * @Description: v1.4.5 产品安装计划表
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <div
        class="item"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="white_bg">
          <div class="flex_between pt_15 pb_15 ml_15 mr_15 border_bottom">
            <div class="flex1 mr_15">{{ item.name }}</div>
            <div :class="item.value == 1 ? 'blue_color' : 'grey_color'">
              {{ item.value ? (item.value == 1 ? '有' : '无') : '-' }}
            </div>
          </div>
        </div>

        <div
          class="list_field white_bg"
          style="background-color: #fafafa"
          v-if="item.value == 1"
        >
          <div class="field_item right">
            <div class="flex_between">
              <div class="grey_color">安装时间</div>
              <div>{{ item.time || '-' }}</div>
            </div>
          </div>
          <div class="field_item">
            <div class="label-name grey_color">备注说明</div>
            <div>{{ item.remark || '-' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>{{ form.info.remark || '-' }}</div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, inject, onMounted, defineEmits } from 'vue';
import { Notify } from 'vant';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);
const $http = inject('$http');
const props = defineProps(['data']);
const state = reactive({
  //-时间控件
  minDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
  loadParams();
});

const loadParams = async () => {
  await $http.getDesignNodeFormEditParams().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      state.cash_list = data.cash_type_list;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
