/*
 * @Author: gest
 * @Date: 2023-03-22 10:38:16
 * @LastEditTime: 2023-12-09 13:54:42
 * @Description:V1.3.4见证件
 */
import Service from '@/utils/request';

export default {
  //节点见证件详情
  witness_nodeInfo(params) {
    return Service.post(`/rs/witness/nodeInfo`, params);
  },
  //见证件提交/审核
  witness_deal(params) {
    return Service.post(`/rs/witness/deal`, params);
  },

  // 概念/产品/效果/施工参数
  getDesignNodeFormEditParams() {
    return Service.post(`/rs/design/nodeFormEditParams`);
  },
  //提交人-选择供应商
  witness_simpleList(params) {
    return Service.post(`/rs/supplier/simpleList`, params);
  },
  //节点见证件表单记录
  witness_getNodeForm(params) {
    return Service.post(`/rs/witness/getNodeForm`, params);
  },
  //保存表单
  witness_saveForm(params) {
    return Service.post(`/rs/witness/saveForm`, params);
  },
  //见证件发起
  witness_start(params) {
    return Service.post(`/rs/witness/start`, params);
  },
  //根据面积或合同获取工期
  public_getScheduleBySize(params) {
    return Service.post(`/rs/public/getScheduleBySize`, params);
  },
};
