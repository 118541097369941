<!--
 * @Author: Jessica
 * @Date: 2024-05-28 09:41:54
 * @LastEditTime: 2024-05-28 13:59:40
 * @Description: 客户首页-项目成员
-->
<template>
  <div class="tabBar-box">
    <div class="tabBar">
      <Tabs
        v-model:active="_DATA.tabIndex"
        title-active-color="#0256FF"
        title-inactive-color="#000000"
        @change="changeTab"
        type="line"
        swipeable
        animated
      >
        <Tab title="服务人员" :name="1"></Tab>
        <Tab title="施工团队" :name="2"></Tab>
        <Tab title="采购供应商" :name="3"></Tab>
      </Tabs>
    </div>
  </div>
  <div class="page-box">
    <div ref="container" class="tab-box-bigger">
      <div class="tab-content-bigger">
        <!-- 服务人员 -->
        <div v-if="_DATA.tabIndex === 1">
          <serviceList />
        </div>
        <!-- 施工团队 -->
        <div v-if="_DATA.tabIndex === 2">
          <MembersList />
        </div>
        <!-- 采购供应商 -->
        <div v-if="_DATA.tabIndex === 3">
          <supplierList />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// 服务人员
import serviceList from '@/components/construction/serviceList';
//-施工团队
import MembersList from '@/components/construction/MembersList';
//-采购供应商
import supplierList from '@/components/construction/supplierList';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { Tabs, Tab } from 'vant';
import _ from 'lodash';
const router = useRouter();
const _DATA = reactive({
  tabIndex: Number(router.currentRoute.value.query.type) || 1,
});

//-@切换tab
const changeTab = (val) => {
  // 动态修改路由参数
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.type = val;
  router.replace({ query });
};
</script>
<style lang="less" scoped>
.tabBar-box {
  height: 44px;
}
.tabBar {
  position: fixed;
  top: 45px;
  left: 0;
  width: 100%;
  height: 44px;
  z-index: 1;
  :deep(.van-tabs) {
    border-bottom: 1px solid rgba(228, 228, 228, 0.3);
    .van-tab__text {
      font-size: 14px;
    }
  }

  :deep(.van-tabs__line) {
    display: block;
    width: 16px;
    background: #0256ff;
  }
}
.page-box {
  padding: 0;
  background-color: @grey_color;
  height: calc(100vh - 90px);
  overflow: auto;
  .tab-box-bigger {
    .tab-content-bigger {
      background: @grey_color;
    }
  }
}
</style>
