<!--
 * @Author: Jessica
 * @Date: 2024-05-10 10:29:10
 * @LastEditTime: 2024-05-18 14:45:24
 * @Description:V1.6.0-交付周期
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 总计 -->
        <div class="data_card_top">
          <div class="title flex_line_between">
            <span class="size17 bold">交付周期</span>
          </div>
          <!-- 目标/完成/完成率 -->
          <!-- 当年 -->
          <Row v-if="props.data?.now_year == props.data?.yearId">
            <Col span="7">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target + '天' }}
                </span>
              </div>
            </Col>
            <Col span="9">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.actual + '天' }}
                </span>
              </div>
              <!-- 差值 -->
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info.target > _DATA.info.actual"
              >
                <span class="bold green">{{ _DATA.info.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info.target < _DATA.info.actual"
              >
                <span class="bold orange">{{ _DATA.info.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">180天履约率</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.rate + '%' }}
                </span>
              </div>
            </Col>
          </Row>
          <!-- 往年 -->
          <Row v-else>
            <Col span="10">
              <div class="grey align_center">完成</div>
              <div class="align_center size17 bold">
                {{ _DATA.info?.actual + '天' }}
              </div>
            </Col>
            <Col span="14">
              <div class="grey align_center">180天履约率</div>
              <div class="align_center size17 bold">
                {{ _DATA.info?.rate + '%' }}
              </div>
            </Col>
          </Row>
        </div>
        <!-- 图表 -->
        <div class="data_card_table border_top">
          <Row class="mb_20">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>

            <!-- 进度图标题 begin-->
            <Col span="13" style="position: relative">
              <!-- 当年 -->
              <div
                class="chart_pos pr_30 pl_20"
                v-if="props.data?.now_year == props.data?.yearId"
              >
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 2,
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
              </div>
              <!-- 往年 -->
              <div class="chart_pos pr_30 pl_20" v-else>
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 2,
                    tip: 1,
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="6">
              <div class="grey align_center">180天履约率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>

              <Col span="13" class="chart_item">
                <!-- 进度图内容 begin-->
                <!-- 当年 -->
                <div
                  style="display: flex"
                  class="pr_30 pl_20"
                  v-if="props.data?.now_year == props.data?.yearId"
                >
                  <chartJDZW
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      actual: item.actual,
                      target: item.target,
                    }"
                  ></chartJDZW>
                </div>
                <!-- 往年 -->
                <div style="display: flex" class="pr_30 pl_20" v-else>
                  <chartJDZW
                    :data="{
                      type: 2,
                      list: _DATA.line_values,
                      actual: item.actual,
                      target: item.target,
                    }"
                  ></chartJDZW>
                </div>
                <!-- 竖线 begin-->
                <div class="chart_pos pr_30 pl_20">
                  <chartCoverLine
                    :data="{
                      type: 2,
                      list: _DATA.line_values,
                      num: _DATA.middle_value,
                    }"
                  ></chartCoverLine>
                </div>
                <!-- 竖线 end-->
                <!-- 进度图内容 end-->
              </Col>

              <Col span="6" class="flex_line_center">
                <span class="align_center">
                  {{ item.rate + '%' }}
                </span>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 -->
        <div
          class="p_15 flex_center"
          v-if="props.data?.now_year == props.data?.yearId"
        >
          <p class="box_8 red_bg"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  inject,
  watch,
  onMounted,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJDZW from '@/components/targetData/chatTemp/chartJDZW';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 3, //处在页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 3, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  // 进度条相关--begin
  line_values: [],
  middle_value: 180,
  // 进度条相关--end
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depConstructionSchedule;
  let params = { time_type: 'now', year: props.data.yearId };
  if (props.data.depId) {
    url = $http.targetStatistic_personConstructionSchedule;
    params = {
      time_type: 'now',
      department_id: props.data.depId,
      year: props.data.yearId,
    };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
    } else {
      _DATA.info = {};
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */

const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depSiteData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
