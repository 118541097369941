<!--
 * @Author: Jessica
 * @Date: 2022-04-21 17:00:31
 * @LastEditTime: 2023-12-19 16:10:03
 * @Description: 家居装饰施工合同
 * v1.3.1紧急需求 - 施工合同签署人地址由20字调整为35字
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工程地址
            </label>
            <van-field
              v-model="form.city_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择城市区',
                  trigger: 'onChange',
                },
              ]"
              placeholder="选择城市区"
              @click="getCityFun"
            ></van-field>
            <van-popup v-model:show="showTypePicker3" position="bottom">
              <van-picker
                ref="cityPickerRef"
                :columns="city_list"
                :columns-field-names="cityFieldName"
                @confirm="(v) => onConfirmType(v, 'type3')"
                @cancel="showTypePicker3 = false"
              />
            </van-popup>
          </div>
          <div class="cell">
            <van-field
              v-model="form.street"
              placeholder="填写街道"
              maxlength="11"
            >
              <template #button>
                <van-text>街</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <van-field
              v-model="form.village"
              placeholder="填写小区名称"
              maxlength="11"
            >
              <template #button>
                <van-text>小区</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <van-field
              v-model="form.house_number"
              placeholder="填写楼号"
              maxlength="8"
            >
              <template #button>
                <van-text>（号）楼</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell clearfix">
            <van-field
              v-model="form.unit_number"
              placeholder="填写单元"
              class="pull-left"
              style="width: calc(50% - 5px)"
              maxlength="7"
            >
              <template #button>
                <van-text>单元</van-text>
              </template>
            </van-field>
            <van-field
              v-model="form.home_number"
              placeholder="填写门牌"
              class="pull-right"
              style="width: calc(50% - 5px)"
              maxlength="7"
              :rules="[
                {
                  required: true,
                  message: '请填写门牌',
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>室</van-text>
              </template>
            </van-field>
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工程套内建筑面积（施工面积）
            </label>
            <van-field
              v-model="form.house_size"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写工程套内建筑面积',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工程套内建筑面积"
            >
              <template #button>
                <van-text>㎡</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期约定方式
            </label>
            <van-field
              v-model="form.schedule_agreed_method_name"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请选择工期约定方式',
                  trigger: 'onChange',
                },
              ]"
              placeholder="选择工期约定方式"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期
            </label>
            <van-field
              v-model="form.schedule"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的工期',
                  validator: validatorDay,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工期"
            >
              <template #button>
                <van-text>天</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              开工日期
            </label>
            <van-field
              v-model="form.interior_decoration_start_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="开工日期"
              :rules="[
                {
                  required: true,
                  message: '请选择开工日期',
                },
              ]"
              @click="openDateType('interior_decoration_start_time')"
            />
          </div>
          <div class="cell">
            <label class="form_title">预计派单时间</label>
            <van-field
              v-model="form.expected_dispatch_date"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计派单时间"
              disabled
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              交付日期
            </label>
            <van-field
              v-model="form.interior_decoration_end_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="交付日期"
              :rules="[
                {
                  required: true,
                  message: '请选择交付日期',
                },
              ]"
              disabled
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工程承包方式
            </label>
            <van-field
              v-model="form.contracting_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择工程承包方式',
                },
              ]"
              placeholder="请选择工程承包方式"
              @click="showTypePicker1 = true"
            />
            <van-popup v-model:show="showTypePicker1" position="bottom">
              <van-picker
                :columns="typeList1"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type1')"
                @cancel="showTypePicker1 = false"
                :default-index="
                  typeList1.findIndex((v) => {
                    return v.id == form.contracting_mode;
                  })
                "
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工图纸提供方式
            </label>
            <van-field
              v-model="form.construction_drawings_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择施工图纸提供方式',
                },
              ]"
              placeholder="请选择施工图纸提供方式"
              @click="showTypePicker2 = true"
            />
            <van-popup v-model:show="showTypePicker2" position="bottom">
              <van-picker
                :columns="typeList2"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type2')"
                @cancel="showTypePicker2 = false"
                :default-index="
                  typeList2.findIndex((v) => {
                    return v.id == form.construction_drawings_mode;
                  })
                "
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <van-field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <van-field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
              maxlength="30"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签署人姓名
            </label>
            <van-field
              v-model="form.signatory_person_name"
              :rules="[
                {
                  required: true,
                  message: '请填写签署人姓名',
                },
              ]"
              placeholder="填写签署人姓名"
              maxlength="20"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签署人身份证号码
            </label>
            <van-field
              v-model="form.signatory_identity"
              :rules="[
                {
                  validator: validatorID,
                  required: true,
                  message: '请填写正确签署人身份证号码',
                },
              ]"
              placeholder="填写签署人身份证号码"
              maxlength="18"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签署人联系地址
            </label>
            <van-field
              v-model="form.signatory_address"
              :rules="[
                {
                  required: true,
                  message: '请填写签署人联系地址',
                },
              ]"
              placeholder="填写签署人联系地址"
              maxlength="35"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签署人联系电话
            </label>
            <van-field
              v-model="form.signatory_phone"
              :rules="[
                {
                  validator: validatorPhone,
                  required: true,
                  message: '请填写正确签署人联系电话',
                },
              ]"
              placeholder="填写签署人联系电话"
              maxlength="11"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签署人微信
            </label>
            <van-field
              v-model="form.signatory_wechat"
              :rules="[
                {
                  required: true,
                  message: '请填写签署人微信',
                },
              ]"
              placeholder="填写签署人微信"
              maxlength="20"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签署人电子邮箱
            </label>
            <van-field
              v-model="form.signatory_email"
              :rules="[
                {
                  validator: validatorEmail,
                  required: true,
                  message: '请填写正确签署人电子邮箱',
                },
              ]"
              placeholder="填写签署人电子邮箱"
              maxlength="40"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              经办人姓名
            </label>
            <van-field
              v-model="form.hand_name"
              :rules="[
                {
                  required: true,
                  message: '请填写经办人姓名（客户经理）',
                },
              ]"
              placeholder="填写经办人姓名"
              maxlength="20"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              经办人电话
            </label>
            <van-field
              v-model="form.hand_phone"
              :rules="[
                {
                  validator: validatorPhone,
                  required: true,
                  message: '请填写正确经办人电话',
                },
              ]"
              placeholder="填写经办人电话"
              maxlength="11"
            ></van-field>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
    <!-- 时间组件 -->
    <van-popup v-model:show="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        :min-date="minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="onRemoveDate"
      />
    </van-popup>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted, nextTick } from 'vue';
import patterns from '@/utils/pattern';
import moment from 'moment';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
  Notify,
  Toast,
} from 'vant';

export default {
  props: [
    'type',
    'data',
    'parameterList',
    'contractType',
    'customer_id',
    'parameterListForQuoteOrder',
  ],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    [Notify.name]: Notify,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const cityPickerRef = ref(null);
    const state = reactive({
      contractType: props.contractType,
      type: props.type,
      //-时间控件
      showTimePicker: false,
      timeType: '',
      minDate: new Date(),
      currentDate: new Date(),
      //-类型控件
      showTypePicker1: false,
      showTypePicker2: false,
      typeList1: props.parameterList.contracting_mode
        ? props.parameterList.contracting_mode
        : [],
      typeList2: props.parameterList.construction_drawings_mode
        ? props.parameterList.construction_drawings_mode
        : [],
      //-工期约定方式
      dateList: props.parameterList.schedule_agreed_method
        ? props.parameterList.schedule_agreed_method
        : [],
      //-合同签署方
      signatoryList: [
        { id: 1, name: '个体' },
        { id: 2, name: '企业' },
      ],
      //-城市区
      showTypePicker3: false,
      city_list: [],
    });
    onMounted(() => {
      getCityParameter();
      // 工期自动计算,重新生成数据不变，失去焦点重新计算
      if (props.data.interior_decoration_start_time) {
        getCountAuto();
      }
    });

    //-获取城市
    const getCityParameter = () => {
      $http.getSaleOptionsApi().then((res) => {
        if (res.code === 0) {
          state.city_list = res.data.city;
        } else {
          Toast(res.data);
        }
      });
    };
    //--表单---------------------
    /**house_size
     * 校验方式
     */
    const { emailReg, phoneReg, IDReg } = patterns;
    const validatorEmail = (val) => (val ? emailReg.test(val) : true);
    const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
    const validatorID = (val) => (val ? IDReg.test(val) : true);

    //-工期
    const validatorDay = (val) =>
      val ? (parseFloat(val) <= 999 ? true : false) : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      city_id: props.data.city_id ? props.data.city_id : '',
      city_name: props.data.city_name ? props.data.city_name : '',
      city_ids: props.data.city_ids ? props.data.city_ids : ['0', '0', '0'],
      street: props.data.street ? props.data.street : '',
      village: props.data.village ? props.data.village : '',
      house_number: props.data.house_number ? props.data.house_number : '',
      unit_number: props.data.unit_number ? props.data.unit_number : '',
      home_number: props.data.home_number ? props.data.home_number : '',
      schedule_agreed_method: props.data.schedule_agreed_method
        ? props.data.schedule_agreed_method
        : state.dateList.length
        ? state.dateList[0].id
        : '',
      schedule_agreed_method_name: props.data.schedule_agreed_method_name
        ? props.data.schedule_agreed_method_name
        : state.dateList.length
        ? state.dateList[0].name
        : '',
      schedule:
        props.parameterListForQuoteOrder.schedule ||
        props.parameterListForQuoteOrder.schedule === 0
          ? props.parameterListForQuoteOrder.schedule
          : '',
      house_size:
        props.parameterListForQuoteOrder.house_size ||
        props.parameterListForQuoteOrder.house_size === 0
          ? props.parameterListForQuoteOrder.house_size
          : '',
      contracting_mode: props.data.contracting_mode
        ? props.data.contracting_mode
        : '',
      contracting_mode_name: props.data.contracting_mode_name
        ? props.data.contracting_mode_name
        : '',
      construction_drawings_mode: props.data.construction_drawings_mode
        ? props.data.construction_drawings_mode
        : '',
      construction_drawings_mode_name: props.data
        .construction_drawings_mode_name
        ? props.data.construction_drawings_mode_name
        : '',
      interior_decoration_start_time: props.data.interior_decoration_start_time
        ? props.data.interior_decoration_start_time
        : '',
      interior_decoration_end_time: props.data.interior_decoration_end_time
        ? props.data.interior_decoration_end_time
        : '',
      signatory: props.data.signatory ? props.data.signatory : '',
      company_name: props.data.company_name ? props.data.company_name : '',
      signatory_person_name: props.data.signatory_person_name
        ? props.data.signatory_person_name
        : '',
      signatory_identity: props.data.signatory_identity
        ? props.data.signatory_identity
        : '',
      signatory_address: props.data.signatory_address
        ? props.data.signatory_address
        : '',
      signatory_phone: props.data.signatory_phone
        ? props.data.signatory_phone
        : '',
      signatory_wechat: props.data.signatory_wechat
        ? props.data.signatory_wechat
        : '',
      signatory_email: props.data.signatory_email
        ? props.data.signatory_email
        : '',
      hand_name: props.data.hand_name ? props.data.hand_name : '',
      hand_phone: props.data.hand_phone ? props.data.hand_phone : '',
      //-预计派单时间
      expected_dispatch_date: props.data.expected_dispatch_date
        ? props.data.expected_dispatch_date
        : '',
    });
    //-时间控件
    const openDateType = (type) => {
      state.showTimePicker = true;
      state.timeType = type;
      if (form[type]) {
        state.currentDate = new Date(form[type]);
      } else {
        state.currentDate = new Date();
      }
    };

    const onConfirmDate = (value) => {
      form[state.timeType] = moment(value).format('YYYY-MM-DD');
      state.showTimePicker = false;
      //-自动匹配结束时间
      if (state.timeType == 'interior_decoration_start_time') {
        getCountAuto();
      }
    };

    const onRemoveDate = () => {
      form[state.timeType] = '';
      state.showTimePicker = false;
      //-自动匹配结束时间
      if (state.timeType == 'interior_decoration_start_time') {
        form.interior_decoration_end_time = '';
      }
    };

    //-picker-承包方式等控件
    const customFieldName = {
      text: 'name',
      value: 'id',
    };
    //-picker-省市区--begin
    const cityFieldName = {
      text: 'name',
      value: 'code',
      children: 'sub',
    };
    const getCityFun = () => {
      state.showTypePicker3 = true;
      nextTick(() => {
        let column1 = state.city_list.findIndex((v) => {
          return v.code == form.city_ids[0];
        });
        let column2 = state.city_list[column1 > -1 ? column1 : 0][
          'sub'
        ].findIndex((v) => {
          return v.code == form.city_ids[1];
        });
        let column3 = state.city_list[column1 > -1 ? column1 : 0]['sub'][
          column2 > -1 ? column2 : 0
        ]['sub'].findIndex((v) => {
          return v.code == form.city_ids[2];
        });
        cityPickerRef.value.setColumnIndex(0, column1 > -1 ? column1 : 0);
        cityPickerRef.value.setColumnIndex(1, column2 > -1 ? column2 : 0);
        cityPickerRef.value.setColumnIndex(2, column3 > -1 ? column3 : 0);
      });
    };

    //-picker-省市区--end
    const onConfirmType = (value, type) => {
      if (type === 'type1') {
        form.contracting_mode_name = value.name;
        form.contracting_mode = value.id;
        state.showTypePicker1 = false;
      } else if (type === 'type2') {
        form.construction_drawings_mode_name = value.name;
        form.construction_drawings_mode = value.id;
        state.showTypePicker2 = false;
      } else if (type === 'type3') {
        form.city_ids = [value[0].code, value[1].code, value[2].code];
        form.city_id = value[2].code;
        form.city_name =
          value[0].name + '/' + value[1].name + '/' + value[2].name;
        state.showTypePicker3 = false;
      }
    };
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    //-工期自动计算----begin
    const getCountAuto = () => {
      $http
        .getContractScheduleBySize({
          customer_id: props.customer_id ?? '',
          contract_type: state.contractType,
          size: form.house_size,
          start_date: form['interior_decoration_start_time'],
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            form['interior_decoration_end_time'] = data.end_date;
            form['expected_dispatch_date'] = data.expected_dispatch_date;
          } else {
            form['interior_decoration_end_time'] = '';
            form['expected_dispatch_date'] = '';
            Notify({ type: 'warning', message: data });
          }
        });
    };
    //-工期自动计算-----end
    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      onConfirmType,
      onConfirmDate,
      onRemoveDate,
      btnSumbit,
      openDateType,
      customFieldName,
      validatorDay,
      getCountAuto,
      validatorEmail,
      validatorPhone,
      validatorID,
      cityFieldName,
      cityPickerRef,
      getCityFun,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
