/*
 * @Author: Jessica
 * @Date: 2022-04-15 11:50:12
 * @LastEditTime: 2023-12-21 10:16:00
 * @Description:
 */
import Service from '@/utils/request';

export default {
  /**
   * 审批列表
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApproveList(params) {
    return Service.post(`/rs/taskManagement/approveList`, params);
  },
  getTaskApproveGetList(params) {
    return Service.get(`/rs/taskManagement/approveList`, params);
  },
  /**
   * 常规审核
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApproveRoutine(params) {
    return Service.post(`/rs/taskManagement/approveRoutine`, params);
  },
  /**
   * 审核详情
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApplyInfo(params) {
    return Service.post(`/rs/taskManagement/applyInfo`, params);
  },
  /**
   * 申请取消
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApplyCancel(params) {
    return Service.post(`/rs/taskManagement/applyCancel`, params);
  },
  /**
   * 设计派单审核
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApproveDesignDispatch(params) {
    return Service.post(`/rs/taskManagement/approveDesignDispatch`, params);
  },
  /**
   * 分配设计中心审核
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApproveDesignCenter(params) {
    return Service.post(`/rs/taskManagement/approveDesignCenter`, params);
  },
  /**
   * 死单审核
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskApproveLost(params) {
    return Service.post(`/rs/taskManagement/approveLost`, params);
  },
  /**
   * 待办列表
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getTaskTodoList(params) {
    return Service.post(`/rs/todo/list`, params);
  },
  /**
   * 审核交付派单参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getAssignDeliverCenterParam(params) {
    return Service.post(`/rs/public/assignDeliverCenterParam`, params);
  },
  /**
   * 交付派单审核
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getApproveDeliverCenter(params) {
    return Service.post(`/rs/taskManagement/approveDeliverCenter`, params);
  },
  //未处理待办和审批数量
  getTaskStatistics(params) {
    return Service.post(`/rs/taskManagement/taskStatistics`, params);
  },
  //设计改派审核
  approveDesignReassignment(params) {
    return Service.post(`/rs/taskManagement/approveDesignReassignment`, params);
  },
  //原中心经理处理待办通知
  designReassignmentNotice(params) {
    return Service.post(`/rs/todo/designReassignmentNotice`, params);
  },
  //中心经理处理分配服务人员待办
  designCenterServicePerson(params) {
    return Service.post(`/rs/todo/designCenterServicePerson`, params);
  },

  //改派提醒审批
  approveDesignRemind(params) {
    return Service.post(`/rs/taskManagement/approveDesignRemind`, params);
  },
  //网销处理确认见面待办
  confirmMeetingForNetwork(params) {
    return Service.post(`/rs/dispatch/confirmMeetingForNetwork`, params);
  },
  //客户经理处理确认见面待办（V1.4.3首见跟进）
  confirmMeetingForCustomerManager(params) {
    return Service.post(
      `/rs/dispatch/confirmMeetingForCustomerManager`,
      params
    );
  },
  //中心经理处理确认见面待办
  confirmMeetingForCenterManager(params) {
    return Service.post(`/rs/dispatch/confirmMeetingForCenterManager`, params);
  },
  //获取确认见面详情
  getConfirmMeetingInfo(params) {
    return Service.post(`/rs/dispatch/getConfirmMeetingInfo`, params);
  },
  //网销市拓展经理重新申请
  approveReapply(params) {
    return Service.post(`/rs/taskManagement/approveReapply`, params);
  },
  //重新申请设计改派
  approveReapplyNetwork(params) {
    return Service.post(`/rs/taskManagement/approveReapplyNetwork`, params);
  },
  //设计中心经理审批点击接单展示信息及操作记录
  designCenterReceivingOrder(params) {
    return Service.post(
      `/rs/taskManagement/designCenterReceivingOrder`,
      params
    );
  },
  //网销经理取消轮派
  networkManageAutoRotationCancel(params) {
    return Service.post(
      `/rs/taskManagement/networkManageAutoRotationCancel`,
      params
    );
  },
  //合同金额变更审批
  approveContractAmountChangeApi(params) {
    return Service.post(
      `/rs/taskManagement/approveContractAmountChange`,
      params
    );
  },
  //跟进代办详情
  followTodoDetail(params) {
    return Service.post(`/rs/fiveStep/followTodoDetail`, params);
  },
  //跟进保存草稿
  dispatch_saveDraft(params) {
    return Service.post(`/rs/dispatch/saveDraft`, params);
  },
  //跟进草稿详情
  dispatch_getDraftDetail(params) {
    return Service.post(`/rs/dispatch/getDraftDetail`, params);
  },
  //交付产品业绩代办详情
  todo_productPerformance(params) {
    return Service.post(`/rs/todo/productPerformance`, params);
  },
  //交付产品业绩代办处理
  todo_productPerformanceDeal(params) {
    return Service.post(`/rs/todo/productPerformanceDeal`, params);
  },
};
