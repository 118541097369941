<!--
 * @Author: NanNan
 * @Date: 2023-03-09 13:56:00
 * @LastEditTime: 2024-05-28 16:25:50
 * @Description: 【申请设计派单】
 * 入口：
 *     1- 线索编辑：生成多派-申请设计派单
 * --------------- 分割线 ---------------
 * v1.5.1 smart 模块引入-可指派设计师中心
 * v1.6.1 smart 模块引入-跟进情况
-->
<template>
  <div class="page-wrap">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="number">客户 {{ _DATA.NO }} 申请设计派单</div>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计见面时间
            </label>
            <Field
              v-model="form.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择预计见面时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计见面时间',
                },
              ]"
              @click="_DATA.showTimePicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面方式
            </label>
            <Field
              v-model="form.face_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面方式',
                },
              ]"
              placeholder="请选择见面方式"
              @click="_DATA.showPickerMode = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              派单级别
            </label>
            <Field
              v-model="form.dispatch_level_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择派单级别',
                },
              ]"
              placeholder="请选择派单级别"
              @click="_DATA.dispatch_level_showPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              派单备注
              <span style="color: #f37020; font-size: 13px">
                设计中心接单前可见
              </span>
            </label>
            <Field
              v-model="form.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注设计中心可见"
              show-word-limit
            />
          </div>
          <!-- v1.6.1 跟进情况 -->
          <TempSendFollow
            :customerID="router?.currentRoute?.value?.query?.id"
          />
          <!-- v1.5.1 可指派设计师中心 -->
          <TempAppointDesigner
            :customerID="router?.currentRoute?.value?.query?.id"
          />

          <div class="cell">
            <label class="form_title">派单设计中心</label>
            <Field
              v-model="form.design_center_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择派单设计中心"
              @click="_DATA.showPicker_design_center_id = true"
            />
          </div>
          <!-- end -->
        </CellGroup>
      </Form>

      <!-- v1.4.1 表单填写 -->
      <TempSendForm
        ref="TempSendFormRef"
        :data="form"
        :customerObj="_DATA.customerObj"
        :cityList="_DATA.city_list"
      />
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="submitFunc">
        完成
      </Button>
    </div>
    <!-- end -->

    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="_DATA.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="_DATA.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="_DATA.minDate"
        @confirm="(v) => onConfirm(v, 'face_time')"
        @cancel="_DATA.showTimePicker = false"
      />
    </Popup>
    <!--picker -派单级别-->
    <Popup v-model:show="_DATA.dispatch_level_showPicker" position="bottom">
      <Picker
        :columns="_DATA.dispatch_level_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'dispatch_level')"
        @cancel="_DATA.dispatch_level_showPicker = false"
      />
    </Popup>
    <!--picker -见面方式-->
    <Popup v-model:show="_DATA.showPickerMode" position="bottom">
      <Picker
        :columns="_DATA.face_mode_list"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'face_mode')"
        @cancel="_DATA.showPickerMode = false"
      />
    </Popup>
    <!-- end -->
    <!--picker -派单设计中心-->
    <Popup v-model:show="_DATA.showPicker_design_center_id" position="bottom">
      <Picker
        :columns="_DATA.design_center_list"
        :columns-field-names="customFieldName2"
        cancel-button-text="清除"
        @confirm="(v) => onConfirm(v, 'design_center_id')"
        @cancel="(v) => onClear(v, 'design_center_id')"
      />
    </Popup>
  </div>
</template>
<script setup>
import { onMounted, reactive, inject, ref } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Picker,
  Overlay,
  Loading,
  Notify,
  Toast,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
import TempSendForm from '@/components/moreOperate/TempSendForm.vue';
import TempAppointDesigner from '@/components/moreOperate/TempAppointDesigner.vue';
import TempSendFollow from '@/components/moreOperate/TempSendFollow.vue';

const $http = inject('$http');
const router = useRouter();

const _DATA = reactive({
  clueID: router.currentRoute.value.query
    ? router.currentRoute.value.query.clueID
    : '',
  NO: router.currentRoute.value.query ? router.currentRoute.value.query.NO : '',
  loading: false,
  dispatch_level_list: [],
  face_mode_list: [],
  city_list: [],
  design_center_list: [],
  //-时间控件
  showTimePicker: false,
  minDate: new Date(),
  currentDate: new Date(),
  //-见面方式
  showPickerMode: false,
  //-派单级别
  dispatch_level_showPicker: false,
  customerObj: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    customer_name: '',
  },
  //可指派设计师中心
  showPicker_design_center_id: false, //派单设计中心
});

const form = reactive({
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  face_time: '',
  dispatch_level: '',
  dispatch_level_name: '',
  face_mode: '',
  face_mode_name: '',
  design_center_id: '',
  design_center_name: '',
  design_id: '',
  remark: '',
});

const customFieldName = {
  text: 'name',
  value: 'id',
};

const customFieldName2 = {
  text: 'text',
  value: 'design_id',
  disabled: 'disabled',
};

onMounted(() => {
  loadParams();
  getSelectDesignParam(router.currentRoute.value?.query?.id);
});

/**
 * 接口
 */
// -- 公共参数
const loadParams = async () => {
  await $http.getDispatchParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.dispatch_level_list = data.dispatch_level;
      _DATA.face_mode_list = data.face_mode;
      _DATA.city_list = data.city;
    }
  });
};

// -- v1.5.1 动态派单设计中心
const getSelectDesignParam = async (customer_id) => {
  await $http
    .public_assignSelectDesignParam({
      customer_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data.length > 0) {
          const newData = data.map((item) => ({
            ...item,
            disabled: item.status === 2,
          }));
          console.log(newData);
          _DATA.design_center_list = newData || [];
        }
      } else {
        Toast(data);
      }
    });
};
/**
 * 操作
 */
// -- 预计见面时间、见面方式、派单级别、派单设计中心
const onConfirm = (value, type) => {
  switch (type) {
    case 'face_time':
      form.face_time = moment(value).format('YYYY-MM-DD HH:mm');
      _DATA.showTimePicker = false;
      break;
    case 'face_mode':
      form.face_mode = value.id;
      form.face_mode_name = value.name;
      _DATA.showPickerMode = false;
      break;
    case 'dispatch_level':
      form.dispatch_level = value.id;
      form.dispatch_level_name = value.name;
      _DATA.dispatch_level_showPicker = false;
      break;
    case 'design_center_id':
      if (value.disabled) {
        form.design_center_id = '';
        form.design_center_name = '';
        form.design_id = '';
      } else {
        form.design_center_id = value.design_center_id;
        form.design_center_name = value.text;
        form.design_id = value.design_id;
      }
      _DATA.showPicker_design_center_id = false;
      break;
    default:
      break;
  }
};

const onClear = (value, type) => {
  console.log(value);
  switch (type) {
    case 'design_center_id':
      form.design_center_id = '';
      form.design_center_name = '';
      _DATA.showPicker_design_center_id = false;
      break;
    default:
      break;
  }
};

// -- 提交表单
const ruleFormRef = ref(null);
const TempSendFormRef = ref(null);
const submitFunc = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      _DATA.loading = true;
      TempSendFormRef.value
        .submitApi()
        .then((response) => {
          console.log(response);
          $http
            .customerApplyDesignDispatch({
              ...form,
            })
            .then((res) => {
              const { code, data } = res;
              _DATA.loading = false;
              if (code === 0) {
                // TempSendFormRef.value.submitApi().then((response) => {
                //   console.log(response);
                // });
                Notify({
                  type: 'success',
                  message: '操作成功',
                  onClose: () => {
                    router.push('/clueDetail?id=' + _DATA.clueID);
                  },
                });
              } else {
                Notify({ type: 'warning', message: data });
              }
            });
        })
        .catch(() => {
          _DATA.loading = false;
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/TempCommon.less';
</style>
