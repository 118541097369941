<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-03-20 09:34:36
 * @Description: 【尚层装饰室内设计协议】【尚层装饰配饰设计协议】房屋信息
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计启动时间
            </label>
            <van-field
              v-model="form.estimated_commencement_time"
              right-icon="underway-o"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择预计启动时间',
                },
              ]"
              placeholder="预计启动时间"
              @click="openDateType('begin')"
            />
            <van-popup v-model:show="showTimePicker" position="bottom">
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择日期"
                :min-date="minDate"
                @confirm="onConfirm"
                cancel-button-text="清除"
                @cancel="onRemoveDate"
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计完成时间
            </label>
            <van-field
              v-model="form.expected_completion_time"
              right-icon="underway-o"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择预计完成时间',
                },
              ]"
              placeholder="预计完成时间"
              @click="openDateType('end')"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期
            </label>
            <van-field
              v-model="form.schedule"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的工期',
                  validator: validatorDay,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工期"
            >
              <template #button>
                <van-text>天</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积
            </label>
            <van-field
              v-model="form.house_size"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的房屋面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写房屋面积"
            >
              <template #button>
                <van-text>㎡</van-text>
              </template>
            </van-field>
          </div>
          <div class="extra_operate" v-if="contractType == 51">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <van-field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <van-field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></van-field>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
    <!-- 完善客户基础信息 -->
    <van-popup
      v-model:show="complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="complete_card.complete_card_Type == 'base'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </van-popup>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
  Notify,
} from 'vant';
import { Toast } from 'vant';
import moment from 'moment';
import patterns from '@/utils/pattern';
import SaleAddMorePage from '@/components/sale/addMore';
export default {
  props: [
    'type',
    'contractType',
    'customer_id',
    'data',
    'parameterListForCustomer',
  ],
  components: {
    SaleAddMorePage,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    [Notify.name]: Notify,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      contractType: props.contractType,
      //-时间控件
      showTimePicker: false,
      timeType: '',
      minDate: new Date(),
      currentDate: new Date(),
      //-合同签署方
      signatoryList: [
        { id: 1, name: '个体' },
        { id: 2, name: '企业' },
      ],
      //-完善信息
      complete_card: {
        //-客户Id
        customer_id: props.customer_id ? props.customer_id : '',
        complete_card_Type: '',
      },
      complete_card_show: false,
    });

    onMounted(() => {
      if (state.contractType == 51) {
        getCustomerParameter(props.customer_id);
      }
      // 工期自动计算
      if (
        props.data.estimated_commencement_time &&
        props.data.expected_completion_time
      ) {
        getCountAuto();
      }
    });

    //--表单---------------------
    /**
     * 校验方式
     */
    const { houseSizeReg } = patterns;
    //-房屋面积
    const validatorSize = (val) =>
      val
        ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
          ? true
          : false
        : true;
    //-工期
    const validatorDay = (val) =>
      val ? (parseFloat(val) <= 999 ? true : false) : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      estimated_commencement_time: props.data.estimated_commencement_time
        ? props.data.estimated_commencement_time
        : '',
      expected_completion_time: props.data.expected_completion_time
        ? props.data.expected_completion_time
        : '',
      schedule: props.data.schedule ? props.data.schedule : '',
      house_size: props.data.house_size
        ? props.data.house_size
        : props.parameterListForCustomer.house_size
        ? props.parameterListForCustomer.house_size
        : '',
      signatory: props.data.signatory ? props.data.signatory : '',
      company_name: props.data.company_name ? props.data.company_name : '',
      //-V0.6.0 默认选择本人
      executor_type: 1,
      //-待完善条数-本人
      customer_other_num: 0,
    });
    //-时间控件
    const openDateType = (type) => {
      state.showTimePicker = true;
      state.timeType = type;
      if (type === 'begin') {
        if (form.estimated_commencement_time) {
          state.currentDate = new Date(form.estimated_commencement_time);
        } else {
          state.currentDate = new Date();
        }
      }
      if (type === 'end') {
        if (form.expected_completion_time) {
          state.currentDate = new Date(form.expected_completion_time);
        } else {
          state.currentDate = new Date();
        }
      }
    };
    const onConfirm = (value) => {
      state.showTimePicker = false;
      if (state.timeType === 'begin') {
        form.estimated_commencement_time = moment(value).format('YYYY-MM-DD');
      } else if (state.timeType === 'end') {
        form.expected_completion_time = moment(value).format('YYYY-MM-DD');
      }
      //-预计开始/结束时间同时存在-自动计算工期
      if (form.estimated_commencement_time && form.expected_completion_time) {
        getCountAuto();
      }
    };
    const onRemoveDate = () => {
      state.showTimePicker = false;
      if (state.timeType === 'begin') {
        form.estimated_commencement_time = '';
      } else if (state.timeType === 'end') {
        form.expected_completion_time = '';
      }
      //-清除预计开始/结束时间--清空工期
      form.schedule = '';
    };
    //-@选择合同执行人---begin

    //-完善合同执行人信息
    const getHandelFun = (type) => {
      state.complete_card_show = true;
      state.complete_card.complete_card_Type = type;
    };
    //-完善合同执行人、委托人信息操作
    const reviewOk = () => {
      if (props.customer_id) {
        getCustomerParameter(props.customer_id);
      }
    };
    //-获取客户参数
    const getCustomerParameter = (id) => {
      state.complete_card_show = false;
      state.complete_card.complete_card_Type = '';
      $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
        if (res.code === 0) {
          form.customer_other_num = res.data.customer_other_num;
        } else {
          Toast(res.data);
        }
      });
    };
    //-@选择合同执行人---end
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------
    //-工期自动计算----begin
    const getCountAuto = () => {
      $http
        .getContractScheduleByTime({
          customer_id: props.customer_id ?? '',
          contract_type: state.contractType,
          start_date: form.estimated_commencement_time,
          end_date: form.expected_completion_time,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            form.schedule = data.schedule;
          } else {
            form.schedule = '';
            Notify({ type: 'warning', message: data });
          }
        });
    };
    //-工期自动计算-----end
    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      onConfirm,
      onRemoveDate,
      btnSumbit,
      openDateType,
      validatorSize,
      validatorDay,
      getHandelFun,
      reviewOk,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
