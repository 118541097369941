<!--
 * @Author: Jessica
 * @Date: 2022-08-23 10:48:26
 * @LastEditTime: 2024-03-23 11:40:37
 * @Description: 优选整装施工
 * @Description: V1.5.4Plus年前最后一个版本，合同金额变更才能变更【整装优选产品】
 * @Description: V1.5.7施工面积、工期取报价单内数据
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积（施工面积）
            </label>
            <Field
              v-model="form.house_size"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的房屋面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写房屋面积"
              @blur="getCountAuto()"
            >
              <template #button>
                <text>㎡</text>
              </template>
            </Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期
            </label>
            <Field
              v-model="form.schedule"
              type="number"
              disabled
              :rules="[
                {
                  message: '请填写正确的工期',
                  validator: validatorDay,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工期"
            >
              <template #button>
                <text>天</text>
              </template>
            </Field>
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计开工时间
            </label>
            <Field
              v-model="form.estimated_commencement_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计开工时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计开工时间',
                },
              ]"
              @click="openDateType('estimated_commencement_time')"
            />
            <Popup v-model:show="state.showTimePicker" position="bottom">
              <DatetimePicker
                v-model="state.currentDate"
                type="date"
                title="选择日期"
                :min-date="state.minDate"
                @confirm="onConfirmDate"
                cancel-button-text="清除"
                @cancel="onRemoveDate"
              />
            </Popup>
          </div>
          <div class="cell">
            <label class="form_title">预计派单时间</label>
            <Field
              v-model="form.expected_dispatch_date"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计派单时间"
              disabled
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计完工时间
            </label>
            <Field
              v-model="form.expected_completion_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计完工时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计完工时间',
                },
              ]"
              disabled
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工图纸提供方式
            </label>
            <Field
              v-model="form.construction_drawings_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择施工图纸提供方式',
                },
              ]"
              placeholder="请选择施工图纸提供方式"
              @click="state.showTypePicker = true"
            />
            <Popup v-model:show="state.showTypePicker" position="bottom">
              <Picker
                :columns="state.typeList"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type')"
                @cancel="state.showTypePicker = false"
                :default-index="
                  state.typeList.findIndex((v) => {
                    return v.id == form.construction_drawings_mode;
                  })
                "
              />
            </Popup>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同执行人
            </label>
            <Field
              class="noBorder"
              v-model="form.executor_type"
              :rules="[
                {
                  required: true,
                  message: '请选择合同执行人',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in state.personList"
                    :key="item.id"
                    :class="{ checked: form.executor_type == item.id }"
                    class="pull-left"
                    @click="getChecked(item.id)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </Field>
          </div>
          <div class="extra_operate" v-if="form.executor_type">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <Icon name="arrow" class="icon" />
            </div>
            <div
              class="extra_item"
              @click.stop="getHandelFun('helper')"
              v-if="form.executor_type == 2"
            >
              <p class="t1">完善委托人信息</p>
              <p class="t2" v-if="form.customer_entrust_num > 0">
                <span>{{ form.customer_entrust_num }}</span>
                项内容待完善
              </p>
              <Icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <Field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in state.signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </Field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <Field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></Field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              整装优选产品
            </label>
            <!-- 合同金额才能变更【整装优选产品】 -->
            <Field
              v-if="props.changeType && props.changeType == 6"
              v-model="form.product_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择整装优选产品',
                },
              ]"
              placeholder="请选择整装优选产品"
              @click="state.showTypePicker1 = true"
            />
            <Field
              v-else
              v-model="form.product_name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择整装优选产品',
                },
              ]"
              placeholder="请选择整装优选产品"
            />
            <Popup v-model:show="state.showTypePicker1" position="bottom">
              <Picker
                :columns="state.preferred_packaged_products"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type1')"
                @cancel="state.showTypePicker1 = false"
                :default-index="
                  state.preferred_packaged_products.findIndex((v) => {
                    return v.id == form.product_id;
                  })
                "
              />
            </Popup>
          </div>
        </CellGroup>
      </Form>
    </div>
    <!-- 完善客户基础信息/完善执行人信息 -->
    <Popup
      v-model:show="state.complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="state.complete_card.complete_card_Type == 'base'"
        :data="state.complete_card"
        @reviewClose="
          () => {
            state.complete_card_show = false;
            state.complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
      <SaleAddExecutorPage
        v-if="state.complete_card.complete_card_Type == 'helper'"
        :data="state.complete_card"
        @reviewClose="
          () => {
            state.complete_card_show = false;
            state.complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </Popup>
  </div>
</template>
<script setup>
import {
  reactive,
  defineProps,
  ref,
  inject,
  defineExpose,
  onMounted,
} from 'vue';
import moment from 'moment';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Icon,
  DatetimePicker,
  Notify,
} from 'vant';
import SaleAddMorePage from '@/components/sale/addMore';
import SaleAddExecutorPage from '@/components/sale/addExecutor';
import patterns from '@/utils/pattern';
import { Toast } from 'vant';
const $http = inject('$http');
const props = defineProps([
  'contractType',
  'customer_id',
  'data',
  'parameterList',
  'parameterListForCustomer',
  'changeType',
  'parameterListForQuoteOrder',
]);
const state = reactive({
  personList: [
    { id: 1, name: '本人' },
    { id: 2, name: '委托人' },
  ],
  typeList: [
    { id: 1, name: '甲方（客户）提供' },
    { id: 2, name: '乙方（尚层）提供' },
  ],
  showTypePicker: false,
  //-合同签署方
  signatoryList: [
    { id: 1, name: '个体' },
    { id: 2, name: '企业' },
  ],
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  //-整装优选产品参数
  preferred_packaged_products: props.parameterList
    ? props.parameterList.preferred_packaged_products
    : [],
  showTypePicker1: false,
  //-完善信息
  complete_card: {
    //-客户Id
    customer_id: props.customer_id ? props.customer_id : '',
    complete_card_Type: '',
  },
  complete_card_show: false,
});

onMounted(() => {
  // 工期自动计算,重新生成数据不变，失去焦点重新计算
  if (props.data.estimated_commencement_time) {
    getCountAuto();
  }
});
//--表单---------------------
/**
 * 校验方式
 */
const { houseSizeReg } = patterns;
//-房屋面积
const validatorSize = (val) =>
  val
    ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
      ? true
      : false
    : true;
//-工期
const validatorDay = (val) =>
  val ? (parseFloat(val) <= 999 ? true : false) : true;

const ruleFormRef = ref(null);
const form = reactive({
  schedule:
    props.parameterListForQuoteOrder.contract_schedule ||
    props.parameterListForQuoteOrder.contract_schedule === 0
      ? props.parameterListForQuoteOrder.contract_schedule
      : '',
  house_size:
    props.parameterListForQuoteOrder.house_size ||
    props.parameterListForQuoteOrder.house_size === 0
      ? props.parameterListForQuoteOrder.house_size
      : '',
  estimated_commencement_time: props.data.estimated_commencement_time
    ? props.data.estimated_commencement_time
    : '',
  expected_completion_time: props.data.expected_completion_time
    ? props.data.expected_completion_time
    : '',
  construction_drawings_mode: props.data.construction_drawings_mode
    ? props.data.construction_drawings_mode
    : '',
  construction_drawings_mode_name: props.data.construction_drawings_mode_name
    ? props.data.construction_drawings_mode_name
    : '',
  executor_type: props.data.executor_type ? props.data.executor_type : '',
  product_id: props.data.product_id ? props.data.product_id : '',
  product_name: props.data.product_name ? props.data.product_name : '',
  signatory: props.data.signatory ? props.data.signatory : '',
  company_name: props.data.company_name ? props.data.company_name : '',
  //-待完善条数-本人
  customer_other_num: props.parameterListForCustomer.customer_other_num
    ? props.parameterListForCustomer.customer_other_num
    : 0,
  //-待完善条数-委托人
  customer_entrust_num: props.parameterListForCustomer.customer_entrust_num
    ? props.parameterListForCustomer.customer_entrust_num
    : 0,
  //-预计派单时间
  expected_dispatch_date: props.data.expected_dispatch_date
    ? props.data.expected_dispatch_date
    : '',
});
//-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form[type]) {
    state.currentDate = new Date(form[type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form[state.timeType] = moment(value).format('YYYY-MM-DD');
  state.showTimePicker = false;
  //-自动匹配结束时间
  if (state.timeType == 'estimated_commencement_time') {
    getCountAuto();
  }
};

const onRemoveDate = () => {
  form[state.timeType] = '';
  state.showTimePicker = false;
  //-自动匹配结束时间
  if (state.timeType == 'estimated_commencement_time') {
    form.expected_completion_time = '';
  }
};
//-@选择合同执行人---begin
const getChecked = (id) => {
  form.executor_type = id;
};

//-完善合同执行人信息
const getHandelFun = (type) => {
  state.complete_card_show = true;
  state.complete_card.complete_card_Type = type;
};
//-完善合同执行人、委托人信息操作
const reviewOk = () => {
  if (props.customer_id) {
    getCustomerParameter(props.customer_id);
  }
};
//-获取客户参数
const getCustomerParameter = (id) => {
  state.complete_card_show = false;
  state.complete_card.complete_card_Type = '';
  $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
    if (res.code === 0) {
      form.customer_other_num = res.data.customer_other_num;
      form.customer_entrust_num = res.data.customer_entrust_num;
    } else {
      Toast(res.data);
    }
  });
};
//-@选择合同执行人---end

//-picker-类型选择
const customFieldName = {
  text: 'name',
  value: 'id',
};

const onConfirmType = (value, type) => {
  if (type === 'type') {
    form.construction_drawings_mode_name = value.name;
    form.construction_drawings_mode = value.id;
    state.showTypePicker = false;
  } else if (type === 'type1') {
    form.product_name = value.name;
    form.product_id = value.id;
    state.showTypePicker1 = false;
  }
};

defineExpose({ form, ruleFormRef });
//--基本信息end单---------------------

//-工期自动计算----begin
const getCountAuto = () => {
  $http
    .getContractScheduleBySize({
      customer_id: props.customer_id ?? '',
      contract_type: props.contractType,
      size: form.house_size,
      start_date: form.estimated_commencement_time,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        form.expected_completion_time = data.end_date;
        form.expected_dispatch_date = data.expected_dispatch_date;
      } else {
        form.expected_completion_time = '';
        form.expected_dispatch_date = '';
        Notify({ type: 'warning', message: data });
      }
    });
};
//-工期自动计算-----end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
