<!--
 * @Author: Jessica
 * @Date: 2023-09-19 16:20:48
 * @LastEditTime: 2024-05-11 17:08:50
 * @Description: 财务大屏-净利润
-->
<template>
  <!-- 净利润 -->
  <div class="main-data">
    <div class="warpper">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_6.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                净利润
                {{ detail.finance_profit?.actual + '万' ?? '-' }}
              </div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>
                  <em class="pl-3">
                    {{ detail.finance_profit?.target + '万' ?? '-' }}
                  </em>
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail.finance_profit?.target ?? 0) <
                      parseFloat(detail.finance_profit?.actual ?? 0)
                    "
                  >
                    <em class="c1">
                      {{ detail.finance_profit?.gap + '万' ?? '-' }}
                    </em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail.finance_profit?.target ?? 0) >
                      parseFloat(detail.finance_profit?.actual ?? 0)
                    "
                  >
                    <em class="c2">
                      {{ detail.finance_profit?.gap + '万' ?? '-' }}
                    </em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">达成率</span>
                <span class="font-size12 pl-3">
                  {{ detail.finance_profit?.rate + '%' ?? '-' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 主营 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">主营</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">
                        {{ detail.finance_business?.target + '万' ?? '-' }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="7">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.finance_business?.actual + '万' ?? '-' }}
                    </em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.finance_business?.target ?? 0) <
                          parseFloat(detail.finance_business?.actual ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.finance_business?.gap + '万' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.finance_business?.target ?? 0) >
                          parseFloat(detail.finance_business?.actual ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.finance_business?.gap + '万' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 达成率 -->
                    <div class="rate">
                      <em>达成率</em>
                      <em class="pl-3">
                        {{ detail.finance_business?.rate + '%' ?? '-' }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 非主营 -->
          <div class="item b_b clearfix">
            <Collapse
              v-model="_DATA.collapse_active"
              :accordion="false"
              :border="false"
            >
              <div class="sub_list">
                <CollapseItem :name="1" :is-link="false">
                  <template #title>
                    <Row>
                      <Col class="flex_left" span="7">
                        <div class="col">
                          <p class="t">
                            非主营
                            <Icon
                              name="play"
                              v-if="_DATA.collapse_active.indexOf(1) == -1"
                              style="transform: rotate(0deg)"
                            />
                            <Icon
                              name="play"
                              v-if="_DATA.collapse_active.indexOf(1) > -1"
                              style="transform: rotate(90deg)"
                            />
                          </p>
                          <div class="temp2">
                            <!-- 目标值 -->
                            <div class="target">
                              <em>目标</em>
                              <em class="pl-3">
                                {{
                                  detail.finance_no_business?.target + '万' ??
                                  '-'
                                }}
                              </em>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col class="flex_right" span="7">
                        <div class="col">
                          <p class="temp1">
                            <em>
                              {{
                                detail.finance_no_business?.actual + '万' ?? '-'
                              }}
                            </em>
                          </p>
                        </div>
                      </Col>
                      <Col class="flex_right" span="10">
                        <div class="col">
                          <div class="temp2">
                            <!-- 差值 -->
                            <div class="gap">
                              <!-- 超目标 -->
                              <p
                                v-if="
                                  parseFloat(
                                    detail.finance_no_business?.target ?? 0
                                  ) <
                                  parseFloat(
                                    detail.finance_no_business?.actual ?? 0
                                  )
                                "
                              >
                                <em class="c1">
                                  {{
                                    detail.finance_no_business?.gap + '万' ??
                                    '-'
                                  }}
                                </em>
                                <img
                                  :src="
                                    require('@/assets/images/target-data/up_total.png')
                                  "
                                  alt="down"
                                />
                              </p>
                              <!-- 距目标 -->
                              <p
                                v-else-if="
                                  parseFloat(
                                    detail.finance_no_business?.target ?? 0
                                  ) >
                                  parseFloat(
                                    detail.finance_no_business?.actual ?? 0
                                  )
                                "
                              >
                                <em class="c2">
                                  {{
                                    detail.finance_no_business?.gap + '万' ??
                                    '-'
                                  }}
                                </em>
                                <img
                                  :src="
                                    require('@/assets/images/target-data/down_total.png')
                                  "
                                  alt="down"
                                />
                              </p>
                              <!-- 已达标 -->
                              <p v-else>
                                <img
                                  :src="
                                    require('@/assets/images/target-data/equal_total.png')
                                  "
                                  alt="down"
                                />
                              </p>
                            </div>
                            <!-- 达成率 -->
                            <div class="rate">
                              <em>达成率</em>
                              <em class="pl-3">
                                {{
                                  detail.finance_no_business?.rate + '%' ?? '-'
                                }}
                              </em>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </template>
                  <div class="sub_item data_card_box">
                    <!-- 标题 -->
                    <Row class="mb_10">
                      <Col span="5">
                        <div class="grey">名称</div>
                      </Col>
                      <Col span="6">
                        <div class="grey align_center">目标</div>
                      </Col>
                      <Col span="6">
                        <div class="grey align_center">当年完成</div>
                      </Col>
                      <Col span="7">
                        <div class="grey flex_right">差值&达成率</div>
                      </Col>
                    </Row>
                    <!-- 内容 -->
                    <Row
                      class="mb_10"
                      v-for="(sub, index) in _DATA.sub_list"
                      :key="index"
                    >
                      <Col span="5">
                        <div class="size13">{{ sub.name }}</div>
                      </Col>
                      <Col span="6">
                        <div>
                          <span class="align_center">
                            {{ sub?.target + '万' ?? '-' }}
                          </span>
                        </div>
                      </Col>
                      <Col span="6">
                        <div>
                          <span class="align_center">
                            {{ sub?.actual + '万' ?? '-' }}
                          </span>
                        </div>
                      </Col>
                      <Col span="7">
                        <!-- 差值 -->
                        <div
                          class="flex_line flex_right"
                          v-if="sub?.actual > sub?.target"
                        >
                          <span class="green">
                            {{ sub?.gap + '万' ?? '-' }}
                          </span>
                          <img
                            class="icon_img"
                            :src="require('@/assets/images/target-data/up.png')"
                            alt="down"
                          />
                        </div>
                        <div
                          class="flex_line flex_right"
                          v-else-if="sub?.actual < sub?.target"
                        >
                          <span class="orange">
                            {{ sub?.gap + '万' ?? '-' }}
                          </span>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/down.png')
                            "
                            alt="down"
                          />
                        </div>
                        <div class="flex_line flex_right" v-else>
                          <img
                            class="icon_img"
                            :src="
                              require('@/assets/images/target-data/equal.png')
                            "
                            alt="down"
                          />
                        </div>
                        <!-- 达成率 -->
                        <div>
                          <span class="flex_right">
                            {{ sub?.rate + '%' ?? '-' }}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CollapseItem>
              </div>
            </Collapse>
          </div>
          <!-- 净利率 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">净利率</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">
                        {{ detail.finance_net_rate?.target + '%' ?? '-' }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="7">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.finance_net_rate?.actual + '%' ?? '-' }}
                    </em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.finance_net_rate?.target ?? 0) <
                          parseFloat(detail.finance_net_rate?.actual ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.finance_net_rate?.gap + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.finance_net_rate?.target ?? 0) >
                          parseFloat(detail.finance_net_rate?.actual ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.finance_net_rate?.gap + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, defineProps } from 'vue';
import { Skeleton, Row, Col, Icon, Collapse, CollapseItem } from 'vant';
const props = defineProps(['data', 'loading']);
const loading = computed(() => props.loading ?? false);
const detail = computed(() => props.data ?? {});
const _DATA = reactive({
  collapse_active: [], //锁定
  sub_list: computed(() => [
    {
      name: '设计费',
      target: props.data?.finance_design_fee?.target ?? '',
      actual: props.data?.finance_design_fee?.actual ?? '',
      gap: props.data?.finance_design_fee?.gap ?? '',
      rate: props.data?.finance_design_fee?.rate ?? '',
    },
    {
      name: '年度返利',
      target: props.data?.finance_product_return?.target ?? '',
      actual: props.data?.finance_product_return?.actual ?? '',
      gap: props.data?.finance_product_return?.gap ?? '',
      rate: props.data?.finance_product_return?.rate ?? '',
    },
    {
      name: '产值活动',
      target: props.data?.finance_activity_deduct?.target ?? '',
      actual: props.data?.finance_activity_deduct?.actual ?? '',
      gap: props.data?.finance_activity_deduct?.gap ?? '',
      rate: props.data?.finance_activity_deduct?.rate ?? '',
    },
    {
      name: '其他',
      target: props.data?.finance_profit_other?.target ?? '',
      actual: props.data?.finance_profit_other?.actual ?? '',
      gap: props.data?.finance_profit_other?.gap ?? '',
      rate: props.data?.finance_profit_other?.rate ?? '',
    },
  ]),
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/financeData/targetIndex.less';
</style>
