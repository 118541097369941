<!--
 * @Author: Jessica
 * @Date: 2024-05-23 13:57:49
 * @LastEditTime: 2024-05-24 10:28:44
 * @Description:V1.6.1-见面数-三级页面-重构
 不区分 当年/往年
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 总计 -->
        <div class="data_card_top">
          <div class="title flex_line_between">
            <span class="size17 bold">见面数</span>
          </div>
          <!--不区分 当年/往年 -->
          <Row>
            <Col span="24">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info.actual }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 图表 -->
        <!-- 不区分 当年/往年 -->
        <div class="data_card_table border_top">
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">人员</div>
            </Col>
            <Col span="19" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row v-if="index + 1 <= _DATA.maxCount || _DATA.open">
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ item.name }}
                </div>
              </Col>
              <Col span="19" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      actual: item.actual,
                      list: _DATA.line_values,
                      type: 3,
                    }"
                  ></chartJD>
                  <div class="chart_right"></div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
            </Row>
          </template>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 1,
  depIndex: 1, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  //-进度条相关
  line_values: [],
  middle_value: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  $http
    .targetStatistic_clueCustomerFaceNum({
      department_id: props.data.depId,
      year: props.data.yearId,
    })
    .then((res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.info = data.info;
        _DATA.list = data.list;
        _DATA.line_values = data.line_values;
        _DATA.middle_value = data.middle_value;
      } else {
        _DATA.info = {};
        _DATA.list = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
      emit('loadEnd');
    });
};
/**
 * 操作
 */
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
