/*
 * @Author: gest
 * @Date:2022-07-21 09:15:16
 * @LastEditTime: 2024-05-18 15:32:00
 * @Description:权限
 */

// import axios from 'axios'; //引入axios
// const host = process.env.NODE_ENV === 'production' ? '' : '/api';
// var fetch = axios.create({
//   baseURL: host,
// });
import Service from '@/utils/request';

const AccountStore = {
  namespaced: true,
  state() {
    return {
      //操作权限
      allOperationAuthority: [],
      user_auth: {},
    };
  },
  actions: {
    //操作权限
    getOperationAuthFunc({ commit }) {
      return new Promise((resolve) => {
        Service.post(`/rs/public/allOperationAuthority`, {}).then((res) => {
          const { code, data } = res;
          let authList = [];
          if (code === 0) {
            if (data) {
              authList = data;
            }
            console.log(authList);
            commit('handleOperationAuth', authList);
            resolve(authList);
          } else {
            commit('handleOperationAuth', authList);
          }
        });
      });
    },
    // @用户权限
    getUserAuthFunc({ commit }) {
      return new Promise(() => {
        Service.post(`/rs/user/profile`).then((res) => {
          const { code, data } = res;
          if (code === 0) {
            commit('handleUserAuth', data);
          }
        });
      });
    },
  },
  mutations: {
    handleOperationAuth: (state, val) => {
      state.allOperationAuthority = val;
    },
    handleUserAuth: (state, val) => {
      state.user_auth = val;
    },
  },
};

export default AccountStore;
