<!--
 * @Author: NanNan
 * @Date: 2022-06-28 09:13:16
 * @LastEditTime: 2023-08-08 16:57:34
 * @Description:V1.3.7 主材订单详情-罚款记录
-->
<template>
  <div>
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <div class="detail_box" v-for="item in _DATA.info" :key="item.id">
      <div class="top_box">
        <div class="status_tag" :class="item.status == 2 ? 'grey' : 'blue'">
          {{ item.status_name }}
        </div>
        <div class="right">
          <div class="name_box">
            <p class="name">{{ item.penalty_type_name }}</p>
          </div>
          <p class="right">
            <span class="money">
              {{ item.penalty_amount }}
            </span>
            <span class="grey_color font_size_12">罚款金额/元</span>
          </p>
        </div>
      </div>
      <div class="dec_box">
        <div class="dec_item">
          <div class="left">罚款单号</div>
          <div class="right">{{ item.no }}</div>
        </div>

        <div class="dec_item">
          <div class="left">备注</div>
          <div class="right">{{ item.remark || '-' }}</div>
        </div>
        <div class="dec_item">
          <div class="left">附件</div>
          <div class="right">
            <span v-if="item.files?.length > 0">
              <a style="color: #3471ff" @click="lookFile(item.files)">
                查看附件>
              </a>
            </span>
            <span v-else>-</span>
          </div>
        </div>
        <div class="dec_item">
          <div class="left">创建人</div>
          <div class="right">{{ item.add_uname || '-' }}</div>
        </div>
        <div class="dec_item">
          <div class="left">创建日期</div>
          <div class="right">{{ item.add_time || '-' }}</div>
        </div>
      </div>
    </div>
    <div class="detail_box" v-if="!_DATA.info.length">
      <Empty image="search" description="没有数据" />
    </div>
  </div>
  <!-- 全部附件 -->
  <FileAll
    ref="fileAll"
    :data="_DATA.files"
    :isShow="_DATA.showAllFile"
    @closePop="
      () => {
        _DATA.showAllFile = false;
      }
    "
  ></FileAll>
</template>
<script setup>
import { reactive, defineProps, inject, onMounted } from 'vue';
import { Notify, Overlay, Empty, Loading } from 'vant';

import FileAll from '@/components/common/FileAll';
const $http = inject('$http');

const props = defineProps(['id']);
const _DATA = reactive({
  loading: false,
  showAllFile: false,
  info: [],
  files: [],
});
onMounted(() => {
  loadInfo();
});
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .mainOrder_penaltyRecord({
      id: props.id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data.list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      setTimeout(() => {
        _DATA.loading = false;
      }, 500);
    });
};
// -@点击查看全部附件
const lookFile = (files) => {
  _DATA.files = files;
  _DATA.showAllFile = true;
};
</script>
<style lang="less" scoped>
.detail_box {
  background-color: @white-color;
  padding: 0 16px;
  margin-bottom: 14px;
  .top_box {
    padding: 14px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    .right {
      flex: 1;
      padding-left: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .name_box {
        .name {
          font-size: 17px;
          font-weight: bold;
          line-height: 24px;
          padding-right: 5px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .money {
          font-weight: bold;
          font-size: 20px;
          color: @black-color;
          line-height: 26px;
        }
      }
    }
  }
  .dec_box {
    padding: 8px 0;
    border-top: 1px solid @line-color;
    .dec_item {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 4px;
      font-size: 14px;
      color: @grey-color;
      line-height: 20px;
      .right {
        flex: 1;
        margin-left: 10px;
        text-align: right;
      }

      .price {
        text-decoration: line-through;
        color: #cccccc;
      }
      .blue {
        color: @blue-color;
      }
      .red {
        color: @red-color;
      }
    }
  }
}
</style>
