<!--
 * @Author: Jessica
 * @Date: 2023-08-11 10:29:12
 * @LastEditTime: 2024-05-29 14:07:33
 * @Description: v1.4.3 全部数据 - tab1线索
 * v1.6.0 运营-获客模块
 * v1.6.1 运营-获客模块-增加人员展示
-->
<template>
  <div v-if="props.data.pos">
    <IndexBar ref="indexBarRef" :index-list="_DATA.indexList" :sticky="false">
      <!-- 模块1:协议 -->
      <IndexAnchor
        index="1"
        v-if="allOperationAuthority.indexOf('630') > -1"
      ></IndexAnchor>
      <TempModuleAgreement
        :data="props.data"
        @loadEnd="loadEnd(1)"
        v-if="allOperationAuthority.indexOf('630') > -1"
      />
      <!-- 模块2:线索 -->
      <IndexAnchor
        index="2"
        v-if="allOperationAuthority.indexOf('514') > -1"
      ></IndexAnchor>
      <TempModuleClue
        :data="props.data"
        @loadEnd="loadEnd(2)"
        v-if="allOperationAuthority.indexOf('514') > -1"
      />
      <!-- 模块3:见面 -->
      <IndexAnchor
        index="3"
        v-if="allOperationAuthority.indexOf('515') > -1"
      ></IndexAnchor>
      <TempModuleMeet
        :data="props.data"
        @loadEnd="loadEnd(3)"
        v-if="allOperationAuthority.indexOf('515') > -1"
      />
      <!-- 模块4:设计模块协议 -->
      <IndexAnchor
        index="4"
        v-if="allOperationAuthority.indexOf('516') > -1"
      ></IndexAnchor>
      <TempDesignAgreement
        v-if="allOperationAuthority.indexOf('516') > -1"
        :data="props.data"
        @loadEnd="loadEnd(4)"
        ref="TempDesignAgreementRef"
        @createDanger="createDanger"
      />
    </IndexBar>
  </div>
  <!-- 规则说明组件 -->
  <ActionSheet
    class="dangerActionSheet_rule"
    v-model:show="_DATA.dangerOperate"
  >
    <div class="content">
      <div class="content_item">
        <h4>{{ _DATA.dangerTitle }}</h4>
        <div class="info_rule" v-html="_DATA.dangerHtml"></div>
      </div>
      <div class="content_button_blue">
        <Button
          block
          loading-type="spinner"
          @click="closeDanger"
          loading-text="知道了"
        >
          知道了
        </Button>
      </div>
    </div>
  </ActionSheet>
</template>
<script setup>
import { useStore } from 'vuex';
import {
  ref,
  reactive,
  defineProps,
  nextTick,
  computed,
  defineExpose,
} from 'vue';
import { IndexBar, IndexAnchor, ActionSheet, Button } from 'vant';
// v1.6.0 - 1协议（获客）
import TempModuleAgreement from '@/components/targetData/thredData/moduleAgreement';
// v1.6.0 - 2线索
import TempModuleClue from '@/components/targetData/thredData/moduleClue';
// v1.6.0 - 3见面
import TempModuleMeet from '@/components/targetData/thredData/moduleMeet';
// v1.6.0 - 4协议（设计）
import TempDesignAgreement from '@/components/targetData/customerData/TempDesignAgreement';
const store = useStore();
//-权限数据
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const props = defineProps(['data']);
const _DATA = reactive({
  indexList: [1, 2, 3, 4],
  //-规则说明
  dangerTitle: '',
  dangerHtml: '',
  dangerOperate: false,
});
const indexBarRef = ref(null);
let loadEndList = [];

const loadEnd = (index) => {
  let pos = Number(props.data.pos);
  if (pos == 1) {
    if (indexBarRef.value) {
      nextTick(() => {
        indexBarRef.value.scrollTo(pos);
      });
    }
  } else if (pos > index) {
    loadEndList.push(index);
    let needLoad = _DATA.indexList.filter((i) => i < pos);
    if (needLoad.sort().toString() == loadEndList.sort().toString()) {
      nextTick(() => {
        if (indexBarRef.value) {
          indexBarRef.value.scrollTo(pos);
        }
      });
    }
  }
};

//-规则说明
const TempDesignAgreementRef = ref();
const createDanger = (type) => {
  _DATA.dangerOperate = true;
  if (type == 'TempDesignAgreementRef') {
    //设计模块协议
    _DATA.dangerTitle = TempDesignAgreementRef?.value?._DATA?.htmlTitle;
    _DATA.dangerHtml = TempDesignAgreementRef?.value?._DATA?.htmlContent;
  }
};
const closeDanger = () => {
  _DATA.dangerOperate = false;
};
defineExpose({ createDanger });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/targetData.less';
</style>
