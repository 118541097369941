<!--
 * @Author: Jessica
 * @Date: 2024-05-09 09:35:30
 * @LastEditTime: 2024-05-28 18:36:05
 * @Description: V1.6.0目标数据-交付产值
-->
<template>
  <!-- 交付产值 -->
  <div class="main-data">
    <div class="warpper" @click.stop="redictFun">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading || props.loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_10.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                交付产值
                <em>{{ detail?.all ?? '-' }}</em>
                <span class="font-size15 pl-3">万</span>
              </div>
              <div
                class="temp2"
                v-if="props.data.now_year == props.data.yearId"
              >
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>
                  <em class="pl-3">{{
                    detail?.target === '' ? '-' : detail?.target
                  }}</em>
                  <!-- 规则入口 -->
                  <div
                    class="icon_info_box_small pl-3"
                    @click.stop="createDanger"
                  >
                    <img
                      class="icon_img"
                      :src="
                        require('@/assets/images/target-data/icon_info.png')
                      "
                      alt="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex" v-if="props.data.now_year == props.data.yearId">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap" v-if="detail?.gap !== ''">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail?.target ?? 0) <
                      parseFloat(detail?.all ?? 0)
                    "
                  >
                    <em class="c1">{{ detail?.gap ?? '-' }}</em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail?.target ?? 0) >
                      parseFloat(detail?.all ?? 0)
                    "
                  >
                    <em class="c2">{{ detail?.gap ?? '-' }}</em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">完成率</span>
                <span class="font-size12 pl-3">
                  {{ detail?.rate === '' ? '-' : detail?.rate + '%' }}
                </span>
              </div>
            </div>
          </div>
          <!-- 往年规则入口 -->
          <div class="flex" v-else>
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 当年 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">当年</p>
                </div>
              </Col>
              <Col class="flex_right" span="16">
                <div class="temp1">
                  <em class="font-size15"> {{ detail?.now ?? '-' }}</em>
                  <span class="font-size12 pl-3">万</span>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 存量 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">存量</p>
                </div>
              </Col>
              <Col class="flex_right" span="16">
                <div class="temp1">
                  <em class="font-size15"> {{ detail?.passed ?? '-' }}</em>
                  <span class="font-size12 pl-3">万</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  defineProps,
  watch,
  defineExpose,
  defineEmits,
  reactive,
} from 'vue';
import { Notify, Skeleton, Row, Col } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
const emit = defineEmits(['createDanger']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .targetStatistic_homeConstructPerformance({ year: props.data.yearId })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        detail.value = data;
      } else {
        loading.value = false;
        detail.value = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 3, pos: 1 },
  });
};

//-规则说明--begin
const _rule = reactive({
  htmlTitle: '交付产值_规则',
  htmlContent: {
    yearInfo: '',
    title: [
      { name: '月份' },
      { name: '交付一' },
      { name: '交付二' },
      { name: '交付三' },
      { name: '交付四' },
      { name: '交付五' },
    ],
    content: [
      {
        title: '1月',
        value1: 720,
        value2: 1000,
        value3: 1000,
        value4: 1300,
        value5: 0,
      },
      {
        title: '2月',
        value1: 900,
        value2: 700,
        value3: 500,
        value4: 500,
        value5: 0,
      },
      {
        title: '3月',
        value1: 950,
        value2: 1200,
        value3: 1000,
        value4: 1200,
        value5: 20,
      },
      {
        title: '4月',
        value1: 1500,
        value2: 1200,
        value3: 1000,
        value4: 800,
        value5: 50,
      },
      {
        title: '5月',
        value1: 1080,
        value2: 1500,
        value3: 1000,
        value4: 1000,
        value5: 80,
      },
      {
        title: '6月',
        value1: 1300,
        value2: 1500,
        value3: 1000,
        value4: 1500,
        value5: 100,
      },

      {
        title: '7月',
        value1: 1400,
        value2: 1500,
        value3: 1100,
        value4: 1000,
        value5: 100,
      },
      {
        title: '8月',
        value1: 1500,
        value2: 1500,
        value3: 1200,
        value4: 800,
        value5: 300,
      },
      {
        title: '9月',
        value1: 1100,
        value2: 1300,
        value3: 1200,
        value4: 1000,
        value5: 400,
      },
      {
        title: '10月',
        value1: 1600,
        value2: 1200,
        value3: 1200,
        value4: 1000,
        value5: 400,
      },
      {
        title: '11月',
        value1: 1700,
        value2: 1500,
        value3: 1200,
        value4: 1000,
        value5: 400,
      },
      {
        title: '12月',
        value1: 2000,
        value2: 1650,
        value3: 1200,
        value4: 1500,
        value5: 400,
      },
    ],
  },
});
const createDanger = () => {
  _rule.htmlContent.yearInfo = props.data.yearId + '年';
  emit('createDanger', 'deliverPerformanceTempRef');
};
defineExpose({ _rule });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
