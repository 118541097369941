/*
 * @Author: NanNan
 * @Date: 2022-04-14 17:00:10
 * @LastEditTime: 2024-05-28 16:20:04
 * @Description: 客户信息 - 展示相关
 */
import Service from '@/utils/request';

export default {
  /**
   * 客户详情 相关
   */
  // @全部客户列表
  getSaleCustomerList(parmas) {
    return Service.post(`/rs/customerManagement/customerList`, parmas);
  },
  // @基本信息
  getSaleInfoBaseApi(parmas) {
    return Service.post(`/rs/company/baseInfo`, parmas);
  },
  // @基本信息 - 编辑
  getSaleInfoBaseEditApi(parmas) {
    return Service.post(`/rs/company/editBaseInfo`, parmas);
  },

  // @装修需求
  getSaleInfoFurnishApi(parmas) {
    return Service.post(`/rs/company/decorateDemandInfo`, parmas);
  },
  // @装修需求 - 编辑
  getSaleInfoFurnishEditApi(parmas) {
    return Service.post(`/rs/company/editDecorateDemandInfo`, parmas);
  },

  // @补充信息
  getSaleInfoOtherApi(parmas) {
    return Service.post(`/rs/company/getOther`, parmas);
  },
  // @补充信息 - 编辑
  getSaleInfoOtherEditApi(parmas) {
    return Service.post(`/rs/company/editOther`, parmas);
  },

  // @执行人信息
  getSaleInfoExectorApi(parmas) {
    return Service.post(`/rs/company/getExector`, parmas);
  },
  // @执行人信息 - 编辑
  getSaleInfoExectorEditApi(parmas) {
    return Service.post(`/rs/company/editExector`, parmas);
  },

  // @首次联系记录
  getSaleInfoContactApi(parmas) {
    return Service.post(`/rs/company/firstContact`, parmas);
  },
  // @首次联系记录 - 编辑
  getSaleInfoContactEditApi(parmas) {
    return Service.post(`/rs/company/editFirstContact`, parmas);
  },
  // @切换客户
  getSaleSwitchCustomerApi(parmas) {
    return Service.post(`/rs/company/switchCustmerList`, parmas);
  },
  // @v0.3.0敏感信息详情页
  getSaleSensitiveFieldInfoApi(parmas) {
    return Service.post(`/rs/customerManagement/sensitiveFieldInfo`, parmas);
  },

  /**
   * 跟进记录相关
   */
  // @获取跟进记录列表
  getSaleFollowRecordApi(parmas) {
    return Service.post(`/rs/fiveStep/getRecordList`, parmas);
  },
  // @删除评论
  getSaleFollowRecordDelApi(parmas) {
    return Service.post(`/rs/fiveStep/delComment`, parmas);
  },
  // @添加评论
  getSaleFollowRecordAddApi(parmas) {
    return Service.post(`/rs/fiveStep/addComment`, parmas);
  },

  /**
   * 客户 - 收款流水 相关
   */
  // @获取客户收款流水列表 {customer_id: '',page,pages}
  getCustomerPaymentDetailApi(parmas) {
    return Service.post(`/rs/customerManagement/paymentDetail`, parmas);
  },
  // @获取客户 增减项列表{}
  getCustomerAdjustListlApi(parmas) {
    return Service.post(`/rs/customerManagement/adjustList`, parmas);
  },

  /**
   * 客户 - 客户结算单
   */
  getCustomerStatementApi(parmas) {
    return Service.post(`/rs/contractManagement/getCustomerStatement`, parmas);
  },
  /**
   * 合同签约相关
   */
  // @合同客户参数
  getContractParmas(parmas) {
    return Service.post(`/rs/public/contractCustomerParameter`, parmas);
  },
  // @获取合同签约列表 {customer_id: ''}
  getContractListApi(parmas) {
    return Service.post(`/rs/contractManagement/contractList`, parmas);
  },
  // @合同详情 {contract_id: '33'}
  getContractInfoApi(parmas) {
    return Service.post(`/rs/contractManagement/contractInfo`, parmas);
  },
  // @作废合同 {customer_id: ''}
  getContractInvalidApi(parmas) {
    return Service.post(`/rs/contractManagement/contractInvalid`, parmas);
  },
  // @款项明细 {contract_id: '31||32||33',page,pages}
  getContractPaymentListApi(parmas) {
    return Service.post(`/rs/contractManagement/paymentList`, parmas);
  },
  // @合同收款流水 {contract_id: '20||21',page,pages}
  getContractPaymentDetailApi(parmas) {
    return Service.post(`/rs/contractManagement/paymentDetail`, parmas);
  },
  // @合同增减项列表 {contract_id: '20||21',page,pages}
  getContractAdjustListApi(parmas) {
    return Service.post(`/rs/contractManagement/adjustList`, parmas);
  },
  // @作废 - 增减想（变更单）
  getContractAdjustFailApi(parmas) {
    return Service.post(`/rs/contractManagement/cancelItem`, parmas);
  },
  // @用户权限(更多操作)
  getOperationAuthorityApi(parmas) {
    return Service.post(`/rs/public/operationAuthority`, parmas);
  },
  // @客户首页 用户权限 /rs/public/homeAuthority
  getOperationHomeAuthorityApi(parmas) {
    return Service.post(`/rs/public/homeAuthority`, parmas);
  },
  // @客户简要信息
  getCustomerManagementSimpleInfoApi(parmas) {
    return Service.post(`/rs/customerManagement/simpleInfo`, parmas);
  },
  // @合同退单详情
  getContractInvalidInfo(parmas) {
    return Service.post(`/rs/contractManagement/contractInvalidInfo`, parmas);
  },
  // @v1.2.0 获取客户编码
  getCustomerBpmApi(params) {
    return Service.post(`/rs/company/getBpm`, params);
  },
  // @v1.2.0 修改客户编码
  getCustomerBpmEditApi(params) {
    return Service.post(`/rs/company/editBpm`, params);
  },
  // @v1.3.2 编辑敏感信息
  setSensitiveFieldEditApi(params) {
    return Service.post(`/rs/customerManagement/sensitiveFieldEdit`, params);
  },
  //v1.3.3 根据客户获取简单的合同列表
  getSimpleListByCustomer(params) {
    return Service.post(
      `/rs/contractManagement/getSimpleListByCustomer`,
      params
    );
  },
  //v1.3.4客户列表搜索参数
  customerSearchParameter(params) {
    return Service.post(`/rs/public/customerSearchParameter`, params);
  },
  //v1.4.2新增客户&全部客户列表筛选
  getCustomerParams(params) {
    return Service.post(`/rs/customerManagement/customerParams`, params);
  },
  //v1.4.2新增/编辑客户
  getCustomerSave(params) {
    return Service.post(`/rs/company/save`, params);
  },

  //V1.4.3 分享跟进记录
  shareDetail(params) {
    return Service.post(`/rs/fiveStep/shareDetail`, params);
  },
  //v1.4.7 线索列表-客户待签待转操作
  customerManagement_customerChangeWaitLabel(params) {
    return Service.post(
      `/rs/customerManagement/customerChangeWaitLabel`,
      params
    );
  },
  //v1.4.8 客户列表按角色服务人员搜索参数
  customerManagement_customerServiceRoleParam(params) {
    return Service.post(
      `/rs/customerManagement/customerServiceRoleParam`,
      params
    );
  },
  //v1.5.1 获取可指派设计师中心信息
  customerManagement_getAppointDesignerInfo(params) {
    return Service.post(
      `/rs/customerManagement/getAppointDesignerInfo`,
      params
    );
  },
  //v1.5.1 派单- 根据条件获取设计中心列表
  public_assignSelectDesignParam(params) {
    return Service.post(`/rs/public/assignSelectDesignParam`, params);
  },
  //渠道费详情
  company_channelFeeInfoDetail(params) {
    return Service.post(`/rs/company/channelFeeInfoDetail`, params);
  },
  //渠道费申请
  company_channelFeeApply(params) {
    return Service.post(`/rs/company/channelFeeApply`, params);
  },
  // v1.6.1 派单-跟进情况（获取跟进情况&小程序发送情况&客户意愿度）
  customerManagement_getAppointDesignerFollowInfo(params) {
    return Service.post(
      `/rs/customerManagement/getAppointDesignerFollowInfo`,
      params
    );
  },
};
