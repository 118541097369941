<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2022-11-05 15:38:36
 * @Description: 
-->
<template>
  <!-- 分配产品设计师 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="state.contract_list?.length > 0"
      @click="
        () => {
          state.contract_list?.length > 1
            ? (state.showPicker_contract = true)
            : null;
        }
      "
    >
      <div class="left">
        <h1>{{ form.list.contract_name }}</h1>
        <p>合同编号：{{ form.list.contract_no }}</p>
      </div>
      <div class="right" v-if="state.contract_list?.length > 1">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              产品设计师
            </label>
            <Field
              v-model="form.list.desginer_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择产品设计师"
              :rules="[
                {
                  required: true,
                  message: '请选择产品设计师',
                },
              ]"
              @click="state.showPicker_desginer = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-设计师 --->
    <Popup v-model:show="state.showPicker_desginer" position="bottom">
      <Picker
        :columns="state.desginerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'desginer')"
        @cancel="state.showPicker_desginer = false"
        :default-index="
          state.desginerList.findIndex((v) => {
            return v.id == form.list.desginer;
          })
        "
      />
    </Popup>
    <!--picker-头部合同切换 --->
    <Popup v-model:show="state.showPicker_contract" position="bottom">
      <Picker
        :columns="state.contract_list"
        :columns-field-names="{
          text: 'contract_name',
          value: 'contract_id',
        }"
        :default-index="
          state.contract_list.findIndex((v) => {
            return v.contract_id == form.list.contract;
          })
        "
        @confirm="(v) => onConfirm(v, 'contract')"
        @cancel="state.showPicker_contract = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-产品设计师-picker
  showPicker_desginer: false,
  desginerList: [],
  //-头部合同选择-picker
  showPicker_contract: false,
  contract_list: [],
  //-头部合同id
  contract_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cid
    : '',
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    // 头部合同切换
    if (type == 'contract') {
      form['list'][type] = value.contract_id;
      form['list'][type + '_name'] = value.contract_name;
      form['list'][type + '_no'] = value.contract_no;
      form['list']['design_id'] = value.design_id;
      changeChecker(value);
    } else {
      form['list'][type] = value.id;
      form['list'][type + '_name'] = value.name;
    }
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  await $http.customerAssignServicePersonParam().then((res) => {
    if (res.code === 0) {
      state.desginerList = res.data.product_design;
      getCustomerListParmas();
    } else {
      Toast(res.data);
    }
  });
};

//-@头部合同参数
const getCustomerListParmas = async () => {
  state.loadingConfirm = true;
  await $http
    .getDesignCustomerListApi({
      customer_id: router.currentRoute.value.query?.id,
      type: 1,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        state.contract_list = data.list;
        if (data.list?.length) {
          const typeList1 = data.list.filter(
            (v) => v.contract_id == state.contract_id
          );
          if (typeList1.length) {
            form.list.contract = typeList1[0].contract_id;
            form.list.contract_name = typeList1[0].contract_name;
            form.list.contract_no = typeList1[0].contract_no;
            form.list.design_id = typeList1[0].design_id;
            changeChecker(typeList1[0]);
          } else {
            form.list.contract = data.list[0].contract_id;
            form.list.contract_name = data.list[0].contract_name;
            form.list.contract_no = data.list[0].contract_no;
            form.list.design_id = data.list[0].design_id;
            changeChecker(data.list[0]);
          }
        } else {
          state.loadingConfirm = false;
        }
      } else {
        Toast(res.data);
        state.loadingConfirm = false;
      }
    });
};
//-获取参数--end

//-数据容错处理
const changeChecker = (data) => {
  let desginer = state.desginerList;
  const desginerList =
    desginer.length > 0 && !!data.product_design_id
      ? desginer.filter((item) => item.id == data.product_design_id)
      : [];
  if (desginerList.length === 0) {
    form.list.desginer = '';
    form.list.desginer_name = '';
  } else {
    form.list.desginer = desginerList[0]['id'];
    form.list.desginer_name = desginerList[0]['name'];
  }
  state.loadingConfirm = false;
};

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    design_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.did
      : '',
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    type: 5,
    desginer: '',
    desginer_name: '',
    contract: router.currentRoute.value.query
      ? router.currentRoute.value.query.cid
      : '',
    contract_name: '',
    contract_no: '',
    remarks: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .customerAssignServicePerson({
          design_id: form.list.design_id,
          type: form.list.type,
          service_person_id: form.list.desginer,
          contract_id: form.list.contract,
          remarks: form.list.remarks,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (state.taskDate) {
                  router.push('/taskDay?date=' + state.taskDate);
                } else {
                  router.push('/sale?id=' + form.list.customer_id);
                }
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
