<!--
 * @Author: Jessica
 * @Date:2022-08-23 10:48:26
 * @LastEditTime: 2024-06-14 11:07:55
 * @Description: 
-->
<template>
  <!-- 项目成员 -->
  <div class="member">
    <!-- 展示选择的项目成员列表 -->
    <div class="temp" v-if="state.isList">
      <h1 style="margin-bottom: 20px">项目成员</h1>
      <!-- <Form
        ref="ruleFormRef"
        class="mb_20"
        v-if="
          state.construction_return_customer == 0 &&
          (state.contractType == 56 || state.contractType == 54)
        "
      >
        <div
          style="
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 5px;
          "
        >
          <span style="color: #ea3d2f">*</span>
          是否指派
        </div>
        <div
          style="
            font-size: 12px;
            letter-spacing: 0em;
            color: #8d9091;
            margin-bottom: 5px;
          "
        >
          若需指派交付中心需走线下指派流程：客户经理>中心经理>总监>总经理>交付中心经理，请谨慎选择
        </div>
        <Field
          name="radio"
          style="border: none; padding: 0"
          :rules="[
            {
              required: true,
              message: '请选择是否指派',
            },
          ]"
        >
          <template #input>
            <RadioGroup style="width: 100%" v-model="form.is_assign">
              <Radio
                name="1"
                style="
                  color: #000000;
                  padding: 17px 0;
                  border-bottom: 1px solid #efefef;
                "
                >是</Radio
              >
              <Radio
                name="0"
                style="
                  color: #000000;
                  padding: 17px 0;
                  border-bottom: 1px solid #efefef;
                "
                >否</Radio
              >
            </RadioGroup>
          </template>
        </Field>
      </Form> -->
      <div class="form_end">
        <div class="member_list">
          <div
            class="member_item clearfix"
            v-for="(item, index) in form.list"
            :key="index"
          >
            <Image
              class="pull-left imgIcon"
              round
              :src="
                item.face_image
                  ? item.face_image.includes('http')
                    ? item.face_image
                    : $host + item.face_image
                  : require('@/assets/images/icon-empty-img.png')
              "
            />

            <div class="pull-left detail">
              <p class="name">{{ item.name }}</p>
              <p class="extra">
                {{ item.dep_name + ' / ' + item.post_name }}
              </p>
            </div>
            <Icon
              class="pull-left icon"
              name="cross"
              @click.stop="delMember(item)"
            />
          </div>
          <div class="add" @click="getCheckedFun()">
            <Icon name="plus" style="color: #999999; margin-right: 10px" />
            添加项目成员
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 项目成员 -->
  <Popup
    v-model:show="state.memberShow"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <MemberList
      v-if="state.memberShow"
      :data="form.list"
      @reviewClose="reviewClose"
      @reviewOk="reviewOk"
    />
  </Popup>
</template>
<script setup>
import { reactive, defineProps, inject, defineExpose } from 'vue';
import {
  Icon,
  Image,
  Popup,
  // RadioGroup, Radio, Field, Form
} from 'vant';
import MemberList from '@/components/contract/MemberList';

const $host = inject('$host');
const props = defineProps([
  'data',
  'contractType',
  'parameterListForCustomer',
  'basicInfo',
]);
const state = reactive({
  isList: true,
  memberShow: false,
  construction_return_customer:
    props.parameterListForCustomer.construction_return_customer,
  contractType: props.contractType,
});
const form = reactive({
  list: props.data ? props.data : [],
  // is_assign:
  //   props.basicInfo.is_assign == 0 || props.basicInfo.is_assign == 1
  //     ? props.basicInfo.is_assign + ''
  //     : null,
});

//-删除
const delMember = (item) => {
  form.list = form.list.filter((v) => v.id != item.id);
};

//-编辑项目成员
const getCheckedFun = () => {
  state.memberShow = true;
};

//-保存项目成员popup
const reviewOk = (data) => {
  form.list = data;
  state.memberShow = false;
};

//-关闭项目成员popup
const reviewClose = () => {
  state.memberShow = false;
};
defineExpose({ form });
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/contractTemp.less';
</style>
