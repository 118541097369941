<!--
 * @Author: NanNan
 * @Date: 2024-05-15 11:03:24
 * @LastEditTime: 2024-05-31 11:04:14
 * @Description: v1.6.0新版本 获客-线索：渠道转化时间
-->
<template>
  <div class="data_item extend chart_jd" style="padding-bottom: 0px">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、头部 -->
        <div class="data_card_top" style="padding-bottom: 0px">
          <div class="title">渠道转化时间</div>
        </div>
        <!-- 特殊区域 -->
        <!-- 线索-见面/见面-协议/协议-转化 -->
        <div class="channelTransformationTimeCount border_top border_bottom">
          <div class="thred-title">集团</div>
          <Row>
            <Col span="8">
              <div class="grey align_center">线索-见面</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.groupInfo?.clue_meet?.actual === ''
                      ? '-'
                      : _DATA.groupInfo?.clue_meet?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="
                  _DATA.groupInfo?.clue_meet?.actual <
                  _DATA.groupInfo?.clue_meet?.target
                "
              >
                <span class="bold green">
                  {{ _DATA.groupInfo?.clue_meet?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="
                  _DATA.groupInfo?.clue_meet?.actual >
                  _DATA.groupInfo?.clue_meet?.target
                "
              >
                <span class="bold orange">
                  {{ _DATA.groupInfo?.clue_meet?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">见面-协议</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.groupInfo?.meet_sign?.actual === ''
                      ? '-'
                      : _DATA.groupInfo?.meet_sign?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="
                  _DATA.groupInfo?.meet_sign?.actual <
                  _DATA.groupInfo?.meet_sign?.target
                "
              >
                <span class="bold green">
                  {{ _DATA.groupInfo?.meet_sign?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="
                  _DATA.groupInfo?.meet_sign?.actual >
                  _DATA.groupInfo?.meet_sign?.target
                "
              >
                <span class="bold orange">
                  {{ _DATA.groupInfo?.meet_sign?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">协议-转化</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.groupInfo?.sign_conversion?.actual === ''
                      ? '-'
                      : _DATA.groupInfo?.sign_conversion?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="
                  _DATA.groupInfo?.sign_conversion?.actual <
                  _DATA.groupInfo?.sign_conversion?.target
                "
              >
                <span class="bold green">
                  {{ _DATA.groupInfo?.sign_conversion?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="
                  _DATA.groupInfo?.sign_conversion?.actual >
                  _DATA.groupInfo?.sign_conversion?.target
                "
              >
                <span class="bold orange">
                  {{ _DATA.groupInfo?.sign_conversion?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
          </Row>
          <div class="thred-title">市场</div>
          <Row>
            <Col span="8">
              <div class="grey align_center">线索-见面</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.marketInfo?.clue_meet?.actual === ''
                      ? '-'
                      : _DATA.marketInfo?.clue_meet?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="
                  _DATA.marketInfo?.clue_meet?.actual <
                  _DATA.marketInfo?.clue_meet?.target
                "
              >
                <span class="bold green">
                  {{ _DATA.marketInfo?.clue_meet?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="
                  _DATA.marketInfo?.clue_meet?.actual >
                  _DATA.marketInfo?.clue_meet?.target
                "
              >
                <span class="bold orange">
                  {{ _DATA.marketInfo?.clue_meet?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">见面-协议</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.marketInfo?.meet_sign?.actual === ''
                      ? '-'
                      : _DATA.marketInfo?.meet_sign?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="
                  _DATA.marketInfo?.meet_sign?.actual <
                  _DATA.marketInfo?.meet_sign?.target
                "
              >
                <span class="bold green">
                  {{ _DATA.marketInfo?.meet_sign?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="
                  _DATA.marketInfo?.meet_sign?.actual >
                  _DATA.marketInfo?.meet_sign?.target
                "
              >
                <span class="bold orange">
                  {{ _DATA.marketInfo?.meet_sign?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">协议-转化</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.marketInfo?.sign_conversion?.actual === ''
                      ? '-'
                      : _DATA.marketInfo?.sign_conversion?.actual + '天'
                  }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="
                  _DATA.marketInfo?.sign_conversion?.actual <
                  _DATA.marketInfo?.sign_conversion?.target
                "
              >
                <span class="bold green">
                  {{ _DATA.marketInfo?.sign_conversion?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="
                  _DATA.marketInfo?.sign_conversion?.actual >
                  _DATA.marketInfo?.sign_conversion?.target
                "
              >
                <span class="bold orange">
                  {{ _DATA.marketInfo?.sign_conversion?.gap }}天
                </span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
          </Row>
        </div>
        <!-- 三、内容 -->
        <div class="data_card_table_bottom p0">
          <!-- 1标题 -->
          <Row class="p_l15_r10" style="align-items: center; height: 100%">
            <Col span="9">
              <div class="grey pr_10">渠道</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">目标</div>
              <div class="grey align_center">线索-见面</div>
              <div class="grey align_center">差值</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">目标</div>
              <div class="grey align_center">见面-协议</div>
              <div class="grey align_center">差值</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">目标</div>
              <div class="grey align_center">协议-转化</div>
              <div class="grey align_center">差值</div>
            </Col>
          </Row>
          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(item, index) in _DATA.list"
            :key="index"
          >
            <div class="sub_item4">
              <Row
                class="p_l15_r10"
                @click.stop="toggleAccordion(index)"
                style="align-items: center; height: 100%"
              >
                <Col span="9">
                  <div
                    class="size13"
                    style="display: flex; align-items: center"
                  >
                    <template v-if="item?.children?.length > 0">
                      <Icon
                        name="play"
                        :style="
                          item?.expanded
                            ? 'transform: rotate(90deg)'
                            : 'transform: rotate(0deg)'
                        "
                        class="arrow"
                      />
                    </template>
                    <p>{{ item.name }}</p>
                  </div>
                </Col>
                <!-- 目标/线索-见面/差值 -->
                <Col span="5">
                  <div>
                    <span class="align_center">
                      {{
                        item?.clue_meet?.target === ''
                          ? '-'
                          : item?.clue_meet?.target + '天'
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="align_center">
                      {{
                        item?.clue_meet?.actual === ''
                          ? '-'
                          : item?.clue_meet?.actual + '天'
                      }}
                    </span>
                  </div>
                  <div v-if="item?.clue_meet?.gap !== ''">
                    <div
                      class="flex_line flex_line_center"
                      v-if="item?.clue_meet?.actual < item?.clue_meet?.target"
                    >
                      <span class="green">{{ item?.clue_meet?.gap }}天</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/up.png')"
                        alt="down"
                      />
                    </div>
                    <div
                      class="flex_line flex_line_center"
                      v-else-if="
                        item?.clue_meet?.actual > item?.clue_meet?.target
                      "
                    >
                      <span class="orange">{{ item?.clue_meet?.gap }}天</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/down.png')"
                        alt="down"
                      />
                    </div>
                    <div class="flex_line flex_line_center" v-else>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/equal.png')"
                        alt="down"
                      />
                    </div>
                  </div>
                </Col>
                <!-- 目标/见面-协议/差值 -->
                <Col span="5">
                  <div>
                    <span class="align_center">
                      {{
                        item?.meet_sign?.target === ''
                          ? '-'
                          : item?.meet_sign?.target + '天'
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="align_center">
                      {{
                        item?.meet_sign?.actual === ''
                          ? '-'
                          : item?.meet_sign?.actual + '天'
                      }}
                    </span>
                  </div>
                  <div v-if="item?.meet_sign?.gap !== ''">
                    <div
                      class="flex_line flex_line_center"
                      v-if="item?.meet_sign?.actual < item?.meet_sign?.target"
                    >
                      <span class="green">{{ item?.meet_sign?.gap }}天</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/up.png')"
                        alt="down"
                      />
                    </div>
                    <div
                      class="flex_line flex_line_center"
                      v-else-if="
                        item?.meet_sign?.actual > item?.meet_sign?.target
                      "
                    >
                      <span class="orange">{{ item?.meet_sign?.gap }}天</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/down.png')"
                        alt="down"
                      />
                    </div>
                    <div class="flex_line flex_line_center" v-else>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/equal.png')"
                        alt="down"
                      />
                    </div>
                  </div>
                </Col>
                <!-- 目标/协议-转化/差值 -->
                <Col span="5">
                  <div>
                    <span class="align_center">
                      {{
                        item?.sign_conversion?.target === ''
                          ? '-'
                          : item?.sign_conversion?.target + '天'
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="align_center">
                      {{
                        item?.sign_conversion?.actual === ''
                          ? '-'
                          : item?.sign_conversion?.actual + '天'
                      }}
                    </span>
                  </div>
                  <div v-if="item?.sign_conversion?.gap !== ''">
                    <div
                      class="flex_line flex_line_center"
                      v-if="
                        item?.sign_conversion?.actual <
                        item?.sign_conversion?.target
                      "
                    >
                      <span class="green">
                        {{ item?.sign_conversion?.gap }}天
                      </span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/up.png')"
                        alt="down"
                      />
                    </div>
                    <div
                      class="flex_line flex_line_center"
                      v-else-if="
                        item?.sign_conversion?.actual >
                        item?.sign_conversion?.target
                      "
                    >
                      <span class="orange">
                        {{ item?.sign_conversion?.gap }}天
                      </span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/down.png')"
                        alt="down"
                      />
                    </div>
                    <div class="flex_line flex_line_center" v-else>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/equal.png')"
                        alt="down"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Transition name="thred-fade">
              <TempChannelTransformationTime
                v-if="item.children && item.expanded"
                :data="item"
                :index="index"
              />
            </Transition>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import { Icon, Notify, Skeleton, Row, Col } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import TempChannelTransformationTime from '@/components/targetData/thredData/collapseItem/TempChannelTransformationTime';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  groupInfo: {}, //集团信息汇总
  marketInfo: {}, //市场信息汇总
  list: [],
  index: props.data.depId ? 2 : 7, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 2, //部门数据页面所处位置
  open: false,
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);

onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载
const onLoad = () => {
  let url = $http.targetStatistic_channelSourceConversion;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.groupInfo = data.group;
      _DATA.marketInfo = data.market;
      console.log(data.total, [data.market, data.group]);
      _DATA.list = data.market?.list || [];
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

//展开收起
const toggleAccordion = (subIndex) => {
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
