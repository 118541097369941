<!--
 * @Author: Jessica
 * @Date: 2022-07-08 09:00:31
 * @LastEditTime: 2022-08-04 11:50:51
 * @Description: 
-->
<template>
  <!-- 申请交付派单 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div class="change-box-top" v-if="state.cname">
      <div class="left">
        <h1>{{ state.cname }}</h1>
        <p>合同编号：{{ state.cno }}</p>
      </div>
    </div>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">预计开工日期</label>
            <Field v-model="form.list.start_time" disabled />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, ref } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  cname: router.currentRoute.value.query?.cname,
  cno: router.currentRoute.value.query?.cno,
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query?.id,
    construction_id: router.currentRoute.value.query?.gdid,
    contract: router.currentRoute.value.query?.cid,
    start_time: router.currentRoute.value.query?.startDate,
    remarks: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .getApplyDeliverCenter({
          construction_id: form.list.construction_id,
          contract_id: form.list.contract,
          remarks: form.list.remarks,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
