<!--
 * @Author: Jessica
 * @Date:  2022-10-25 15:49:48
 * @LastEditTime: 2024-01-12 16:47:33
 * @Description: 分派交付中心操作面板
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <h3 class="title_tip">{{ props.title }}</h3>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <!--V1.4.2是否指派 若客户【渠道来源】为【工地返单】，则不显示 -->
          <div class="cell" v-if="props.data.info.is_return != 1">
            <label class="form_title" style="margin-bottom: 5px">
              <span style="color: #ea3d2f">*</span>
              是否指派
            </label>
            <Field
              style="border: none; padding: 0"
              name="radio"
              :rules="[
                {
                  required: true,
                  message: '请选择是否指派',
                  trigger: 'onChange',
                },
              ]"
            >
              <template #input>
                <RadioGroup style="width: 100%" v-model="form.list.is_assign">
                  <Radio
                    :name="1"
                    style="
                      color: #000000;
                      padding: 17px 0;
                      border-bottom: 1px solid #efefef;
                    "
                    >是</Radio
                  >
                  <Radio
                    :name="0"
                    style="
                      color: #000000;
                      padding: 17px 0;
                      border-bottom: 1px solid #efefef;
                    "
                    >否</Radio
                  >
                </RadioGroup></template
              ></Field
            >
          </div>
          <!-- end -->
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              交付中心
            </label>
            <Field
              v-model="form.list.deliver_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择交付中心"
              :rules="[
                {
                  required: true,
                  message: '请选择交付中心',
                },
              ]"
              @click="state.showPicker_deliver = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写具体原因..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" style="box-shadow: none">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        确定
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-交付中心 --->
    <Popup v-model:show="state.showPicker_deliver" position="bottom">
      <Picker
        :columns="state.deliverList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'deliver')"
        @cancel="state.showPicker_deliver = false"
        :default-index="
          state.deliverList.findIndex((v) => {
            return v.id == form.list.deliver_center_id;
          })
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, defineProps } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  RadioGroup,
  Radio,
} from 'vant';
import { useRouter } from 'vue-router';
const props = defineProps(['data', 'title', 'id']);
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-交付中心-picker
  showPicker_deliver: false,
  deliverList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    form['list'][type] = value.id;
    form['list'][type + '_name'] = value.name;
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = () => {
  state.loadingConfirm = true;
  $http
    .getAssignDeliverCenterParam({
      customer_id: props.data.customer_id ? props.data.customer_id : '',
      contract_id: props.data.info?.contract_id ?? '',
    })
    .then((res) => {
      if (res.code === 0) {
        state.deliverList = res.data.deliver_center;
        changeChecker();
      } else {
        state.loadingConfirm = false;
        Toast(res.data);
      }
    });
};
//-获取参数--end
//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    remarks: '',
    deliver: props.data.deliver ? props.data.deliver : '',
    deliver_name: props.data.deliver_name ? props.data.deliver_name : '',
    is_assign: null,
  },
});
//-数据容错处理
const changeChecker = () => {
  let deliver = state.deliverList;
  const deliverList =
    deliver.length > 0 && !!props.data.deliver
      ? deliver.filter((item) => item.id == props.data.deliver)
      : [];

  if (deliverList.length === 0) {
    form.list.deliver = '';
    form.list.deliver_name = '';
  }
  state.loadingConfirm = false;
};

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      operateFun();
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

// 调用接口
const operateFun = () => {
  let obj = {
    task_id: props.id,
    tag_deliver_center_id: form.list.deliver,
    approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
    remark: form.list.remarks,
    tag_assign: form.list.is_assign,
  };

  $http.getApproveDeliverCenter(obj).then((res) => {
    const { code, data } = res;
    state.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
