<!--
 * @Author: gest
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-29 14:03:56
 * @Description:V1.4.0-全部数据
-->
<template>
  <div
    class="tab_grey"
    v-show="
      (sameKey.length > 0 && allOperationAuthority.indexOf('517') > -1) ||
      (sameKey.length > 0 && allOperationAuthority.indexOf('518') > -1) ||
      (allOperationAuthority.indexOf('517') > -1 &&
        allOperationAuthority.indexOf('518') > -1)
    "
  >
    <div class="type_box">
      <div
        class="type_item"
        :class="_DATA.tabIndex == 1 ? 'check' : ''"
        @click="changeType(1)"
        v-if="sameKey.length > 0"
      >
        获客
      </div>
      <div class="sline"></div>
      <div
        class="type_item"
        :class="_DATA.tabIndex == 2 ? 'check' : ''"
        @click="changeType(2)"
        v-if="allOperationAuthority.indexOf('517') > -1"
      >
        转化
      </div>
      <div class="sline"></div>
      <div
        class="type_item"
        :class="_DATA.tabIndex == 3 ? 'check' : ''"
        @click="changeType(3)"
        v-if="allOperationAuthority.indexOf('518') > -1"
      >
        交付
      </div>
    </div>
  </div>

  <div class="change_box">
    <div></div>
    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker_year = true"
    >
      {{ _DATA.yearName }}
    </Button>
  </div>
  <div
    class="page-box"
    :class="{
      has_top:
        (sameKey.length > 0 && allOperationAuthority.indexOf('517') > -1) ||
        (sameKey.length > 0 && allOperationAuthority.indexOf('518') > -1) ||
        (allOperationAuthority.indexOf('517') > -1 &&
          allOperationAuthority.indexOf('518') > -1),
    }"
    ref="pageBoxRef"
  >
    <!-- 获客 -->
    <div v-if="_DATA.tabIndex === 1">
      <thredData
        :data="{
          tabIndex: _DATA.tabIndex,
          pos: _DATA.pos,
          pt: _DATA.pt,
          yearId: _DATA.yearId,
          auth: allOperationAuthority,
        }"
      ></thredData>
    </div>

    <!-- 转化 -->
    <template v-if="_DATA.tabIndex === 2">
      <designData
        :data="{
          tabIndex: _DATA.tabIndex,
          pos: _DATA.pos,
          pt: _DATA.pt,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
      ></designData>
    </template>
    <!-- 交付 -->
    <div v-if="_DATA.tabIndex === 3">
      <siteData
        :data="{
          tabIndex: _DATA.tabIndex,
          pos: _DATA.pos,
          pt: _DATA.pt,
          yearId: _DATA.yearId,
          now_year: _DATA.now_year,
        }"
      ></siteData>
    </div>
  </div>

  <!-- 年份 -->
  <Popup v-model:show="_DATA.showPicker_year" position="bottom">
    <Picker
      :columns="_DATA.yearList"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirm(v, 'year')"
      @cancel="_DATA.showPicker_year = false"
      :default-index="
        _DATA.yearList.findIndex((v) => {
          return v.id == _DATA.yearId;
        })
      "
    />
  </Popup>
  <!-- end -->
</template>
<script setup>
import { reactive, onMounted, inject, computed } from 'vue';
import { Picker, Button, Popup, Notify } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import thredData from '@/components/targetData/thredData/thredData';
// import meetingData from '@/components/targetData/meetingData/meetingData';
// import customerData from '@/components/targetData/customerData/customerData';
import designData from '@/components/targetData/designData/designData';
import siteData from '@/components/targetData/siteData/siteData';

import _ from 'lodash';
const router = useRouter();
const $http = inject('$http');
const customFieldName = {
  text: 'short_name',
  value: 'id',
};
const _DATA = reactive({
  tabIndex: Number(router.currentRoute.value.query.subtype) || 1, // 1 获客 2 设计 3交付
  pos: Number(router.currentRoute.value.query.pos) || 1, // 与全部数据卡片位置顺序一样
  authorList: ['514', '515', '516', '630'], //获客 '517'转化, '518'交付
  showPicker_year: false,
  yearId: null,
  yearName: null,
  now_year: '',
  yearList: [],
});
const store = useStore();
//-权限数据
const allOperationAuthority = computed(
  () => store.state.account.allOperationAuthority
);
const sameKey = computed(() =>
  allOperationAuthority.value.filter((item) =>
    _DATA.authorList.some((e) => e == item)
  )
);
//-end
onMounted(() => {
  getParams();
});
//-@ 加载
const getParams = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      let years = data.years;
      _DATA.now_year = data.now_year;
      let yearList = [];
      years.forEach((v) => {
        yearList.push({ id: v, short_name: v });
      });
      _DATA.yearList = yearList;
      _DATA.yearId = Number(localStorage.getItem('targetYear'));
      _DATA.yearName = Number(localStorage.getItem('targetYear'));
      if (!_DATA.yearId || !years.includes(_DATA.yearId)) {
        let yearArr = years.slice(-1);
        _DATA.yearId = yearArr[0];
        _DATA.yearName = yearArr[0];
      }
      console.log(_DATA.yearId);
      //判断权限改变tabindex;
      if (
        sameKey.value.length > 0 &&
        allOperationAuthority.value.indexOf('517') > -1 &&
        allOperationAuthority.value.indexOf('518') <= -1
      ) {
        //只有获客和转化
        if (_DATA.tabIndex == 3) {
          changeType(1);
        }
      }
      if (
        sameKey.value.length > 0 &&
        allOperationAuthority.value.indexOf('518') > -1 &&
        allOperationAuthority.value.indexOf('517') <= -1
      ) {
        //只有获客和交付
        if (_DATA.tabIndex == 2) {
          changeType(1);
        }
      }
      if (
        sameKey.value.length <= 0 &&
        allOperationAuthority.value.indexOf('517') > -1 &&
        allOperationAuthority.value.indexOf('518') > -1
      ) {
        console.log(_DATA.tabIndex);
        //只有转化和交付
        if (_DATA.tabIndex == 1) {
          changeType(2);
        }
      }
      if (
        sameKey.value.length > 0 &&
        allOperationAuthority.value.indexOf('517') <= -1 &&
        allOperationAuthority.value.indexOf('518') <= -1
      ) {
        console.log(_DATA.tabIndex);
        //只有获客
        if (_DATA.tabIndex != 1) {
          changeType(1);
        }
      }
      if (
        sameKey.value.length <= 0 &&
        allOperationAuthority.value.indexOf('517') > -1 &&
        allOperationAuthority.value.indexOf('518') <= -1
      ) {
        console.log(_DATA.tabIndex);
        //只有转化
        if (_DATA.tabIndex != 2) {
          changeType(2);
        }
      }
      if (
        sameKey.value.length <= 0 &&
        allOperationAuthority.value.indexOf('517') <= -1 &&
        allOperationAuthority.value.indexOf('518') > -1
      ) {
        console.log(_DATA.tabIndex);
        //只有交付
        if (_DATA.tabIndex != 3) {
          changeType(3);
        }
      }
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
/*
 * 操作
 */
//-@切换tab
const changeType = (val) => {
  // 动态修改路由参数
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.subtype = val;
  query.pos = 1;
  router.replace({ query });
  _DATA.tabIndex = val;
  _DATA.pos = 1;
};
const onConfirm = (value, type) => {
  if (type == 'year') {
    //切换部门
    localStorage.setItem('targetYear', value.id);
    _DATA.yearId = value.id;
    _DATA.yearName = value.short_name;
    _DATA.showPicker_year = false;
  }
};
</script>
<style lang="less" scoped>
.tab_grey {
  padding: 15px 15px 0 15px;
  .type_box {
    padding: 2px;
    background: rgba(118, 118, 128, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    .type_item {
      line-height: 26px;
      border-radius: 4px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #171a1d;

      &.check {
        background: #ffffff;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08),
          0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        color: #000000;
        font-weight: 600;
      }
    }
  }
}

.change_box {
  padding: 10px 15px;
  background: #f5f5f5;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    font-size: 17px;
    font-weight: bold;
    flex: 1;
    margin-right: 20px;
  }
  .year_btn {
    border: none;
    border-radius: 2px;
    font-size: 13px;
    padding: 0 10px;
    margin-left: 10px;
    height: 30px;
  }
}
.page-box {
  padding: 0 0 55px 0;
  background: #f5f5f5;
  height: calc(100% - 48px);
  overflow: scroll;
  &.has_top {
    height: calc(100% - 92px);
  }
}
.sline {
  height: 12px;
  border: 1px solid rgba(126, 134, 142, 0.16);
}
</style>
