<!--
 * @Author: NanNan
 * @Date: 2023-12-11 15:04:41
 * @LastEditTime: 2024-05-28 16:19:56
 * @Description: v1.5.1 smart 派单模块 - 可指派设计师中心（多场景应用）
 * v1.5.7 smart 展示“收藏设计师”
 * v1.5.9 smart 展示“经常关注”
-->
<template>
  <!-- v1.5.1 可指派设计师中心 -->
  <div class="can-chose-designer-box">
    <Collapse v-model="_DATA.collapse_active" ref="collapse">
      <CollapseItem title="标题1" name="1">
        <template #title>可指派设计师中心</template>
        <div class="inner">
          <label>已选设计师：</label>
          <p v-if="_DATA.specifyInfo?.reservation?.length > 0">
            {{
              _DATA.specifyInfo?.reservation
                .map((item) => item.value)
                .join('、')
            }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>推荐未选择：</label>
          <p v-if="_DATA.specifyInfo?.recommend?.length > 0">
            {{
              _DATA.specifyInfo?.recommend.map((item) => item.value).join('、')
            }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>客户取消：</label>
          <p v-if="_DATA.specifyInfo?.cancel?.length > 0">
            {{ _DATA.specifyInfo?.cancel.map((item) => item.value).join('、') }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>收藏设计师：</label>
          <p v-if="_DATA.specifyInfo?.collect?.length > 0">
            {{
              _DATA.specifyInfo?.collect.map((item) => item.value).join('、')
            }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="inner">
          <label>经常关注：</label>
          <p v-if="_DATA.specifyInfo?.often_care?.length > 0">
            {{
              _DATA.specifyInfo?.often_care.map((item) => item.value).join('、')
            }}
          </p>
          <p v-else>-</p>
        </div>
      </CollapseItem>
    </Collapse>
  </div>
</template>
<script setup>
import { reactive, inject, defineProps, onMounted } from 'vue';
import { Toast, Collapse, CollapseItem } from 'vant';

const $http = inject('$http');
const props = defineProps(['customerID', 'taskID']);

const _DATA = reactive({
  collapse_active: ['1'],
  specifyInfo: {},
});

onMounted(() => {
  getAppointDesignerInfo();
});
/**
 * api
 */
// -- v1.5.1指定设计师信息
const getAppointDesignerInfo = () => {
  $http
    .customerManagement_getAppointDesignerInfo({
      customer_id: props?.customerID,
      task_id: props?.taskID,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Object.assign(_DATA.specifyInfo, data);
      } else {
        Toast(data);
      }
    });
};
</script>
<style lang="less" scoped>
// v1.5.1 申请设计派单 - 文本信息 - 可指派设计师中心
.can-chose-designer-box {
  margin-bottom: 15px;

  .inner {
    display: flex;
    justify-content: left;
    align-items: start;
    color: #767676 !important;
    line-height: 26px;

    label {
      text-align: right;
      box-sizing: border-box;
      flex-shrink: 0;
      flex-grow: 0;
    }

    p {
      flex-grow: 1;
      flex-wrap: wrap;
    }
  }

  :deep(.van-collapse-item__title) {
    padding: 10px !important;
    font-size: 15px;
    font-weight: bold !important;
    background: #f5f5f5;
    border: none !important;
    margin-bottom: 10px;
  }

  :deep(.van-cell__title) {
    font-weight: bold !important;
  }

  :deep(.van-collapse-item__content) {
    padding-top: 0px !important;
  }
}
</style>
