<!--
 * @Author: NanNan
 * @Date: 2023-12-07 09:25:35
 * @LastEditTime: 2023-12-14 18:23:25
 * @Description: v1.5.1 案例分享 - 授权页面
-->
<template>
  <div class="index-page">
    <div class="input-box1">
      <img
        :src="require('@/assets/images/login_loading.gif')"
        alt="loding"
        srcset=""
        style="width: 100%"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
const host = process.env.NODE_ENV === 'production' ? '' : '/api';

let FORCE_UPDATE_NUMBER = 0;
let wx = window.wx;
const localStorage = window.localStorage;
export default {
  name: 'authPage',
  data() {
    return {
      FLAG: localStorage.getItem('FLAG'),
    };
  },
  mounted() {
    localStorage.removeItem('WX_EXTERNAL_ID');
    this.getConfig();
    console.log(location);
  },
  methods: {
    getConfig() {
      const $this = this;
      const router = useRouter();
      const userId = localStorage.getItem('USERID_ID');
      const FLAG3 = localStorage.getItem('FLAG3');
      const codeValue = router && router.currentRoute.value.query?.code;
      if (codeValue || userId) {
        axios
          .post(host + '/rs/wechat/getStaff', {
            code: codeValue,
            userid: userId,
          })
          .then((res) => {
            const resData = res.data;
            if (resData.code === 0) {
              localStorage.setItem('ACCOUNT_ID', resData.data.id);
              localStorage.setItem('USERID_ID', resData.data.userid);
              localStorage.setItem('ACC_TOKEN', resData.data['acc-token']);
              localStorage.removeItem('FLAG3');
              $this.jumpTo();
            } else if (resData.code === 1) {
              if (!FLAG3) {
                localStorage.removeItem('USERID_ID');
                $this.authWxAgentData();
              } else {
                localStorage.removeItem('FLAG3');
              }
              Toast(resData.data);
            } else {
              Toast(resData.msg);
            }
          });
      } else {
        $this.authWxAgentData();
      }
    },
    //-获取微信config配置签名数据
    authWxAgentData(isRefresh) {
      axios
        .post(host + '/rs/wechat/getAgentConfig', {
          url: window.location.href,
          refresh: isRefresh ? 1 : 0,
        })
        .then((res) => {
          const resData = res.data;
          if (resData.code === 0) {
            this.authWxAgentConfig(resData.data);
          } else if (resData.code === 1) {
            Toast(resData.data);
          } else {
            Toast(resData.msg);
          }
        });
    },
    //-配置config签名数据
    authWxAgentConfig(appSignature) {
      var $this = this;
      wx.agentConfig({
        beta: true,
        debug: false,
        corpid: appSignature.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: appSignature.agentid, // 必填，企业微信的应用id 生产环境
        timestamp: appSignature.timestamp, // 必填，生成签名的时间戳
        nonceStr: appSignature.noncestr, // 必填，生成签名的随机串
        signature: appSignature.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact'], // 必填
        success() {
          $this.initInvokWxConfig(appSignature);
        },
        fail(res) {
          console.log('////');
          FORCE_UPDATE_NUMBER++;
          if (FORCE_UPDATE_NUMBER <= 1) {
            $this.authWxAgentData(true);
          } else {
            Toast('访问受限，请联系管理员');
          }
          if (res.errMsg.indexOf('function not exist') > -1) {
            Toast('版本过低请升级');
          }
        },
      });
    },
    //-获取当前客户的userid
    initInvokWxConfig(appSignature) {
      var $this = this;
      const userId = localStorage.getItem('USERID_ID');
      try {
        wx.invoke('getCurExternalContact', {}, (res) => {
          if (res.err_msg == 'getCurExternalContact:ok') {
            localStorage.setItem('WX_EXTERNAL_ID', res.userId);
          } else {
            Toast('连接失败，请退出重试');
          }
          if (userId) {
            localStorage.removeItem('FLAG3');
            $this.jumpTo();
          } else {
            var timestamp = new Date().getTime();
            localStorage.setItem('FLAG3', timestamp.toString());
            $this.wxCbRedirectUrl(appSignature);
          }
        });
      } catch (error) {
        Toast('访问受限，请联系管理员!');
      }
    },
    //-构造授权链接
    wxCbRedirectUrl(data) {
      const originUrl = location && location.href;
      const redictUrl = encodeURIComponent(originUrl);
      const { corpid } = data;
      const redirectUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpid}&redirect_uri=${redictUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      location.href = redirectUrl;
    },
    //-授权完成之后的操作-跳转
    jumpTo() {
      const $this = this;
      const external_userid = localStorage.getItem('WX_EXTERNAL_ID');
      if (external_userid) {
        window.location.href = '/indexClue';
      } else {
        $this.authWxAgentData();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/welcome.less';
</style>
