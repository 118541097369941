<!--
 * @Author: NanNan
 * @Date: 2022-04-19 15:01:33
 * @LastEditTime: 2023-01-12 10:02:42
 * @Description: 客户 - [收款流水] 主页面
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <List
      v-model:loading="loading"
      :finished="finished"
      :offset="20"
      @load="loadFunc"
    >
      <div class="same-box" v-for="(item, index) in list_data" :key="index">
        <div class="title-box">
          <p class="time">{{ item.add_time }}</p>
          <dl>
            <dt>
              <em>{{ item.is_positive == 0 ? '+' : '-' }}</em>
              {{ item.collection_money }}
            </dt>
            <dd>
              <span>{{ item.payment_detail_type_name }}</span>
              <em
                class="split"
                v-if="item.payment_detail_type_name && item.payment_period"
              ></em>
              <span>{{ item.payment_period }}</span>
            </dd>
          </dl>
        </div>
        <div class="con-box">
          <!-- 状态栏 -->
          <p
            class="h1"
            :class="{
              cancel: [3].indexOf(item.status) > -1,
              done: [2].indexOf(item.status) > -1,
              wait: [1].indexOf(item.status) > -1,
            }"
          >
            <Icon name="success" v-if="[2].indexOf(item.status) > -1" />
            <Icon name="clock-o" v-if="[1].indexOf(item.status) > -1" />
            <Icon name="cross" v-if="[3].indexOf(item.status) > -1" />
            {{ item.status_str }}
          </p>
          <dl class="same-flex">
            <dt>合同编号:</dt>
            <dd>{{ item.contract_no || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>票据编号:</dt>
            <dd>{{ item.bill_no || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>收款方式:</dt>
            <dd>{{ item.collection_method_str || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>收款账号:</dt>
            <dd>{{ item.collection_account || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>收款日期:</dt>
            <dd>{{ item.collection_time || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>提交人:</dt>
            <dd>{{ item.operator_user_str || '-' }}</dd>
          </dl>
          <dl class="same-flex">
            <dt>备注:</dt>
            <dd>{{ item.remarks || '-' }}</dd>
          </dl>
          <!-- 附件 -->
          <div
            class="fileList"
            v-if="item.file_url && item.file_url.length > 0"
          >
            <FileGrid :data="item.file_url"></FileGrid>
          </div>

          <!-- end -->
        </div>
      </div>
      <template v-if="list_data.length > 0" #finished>
        <Divider class="divider-bottom">我也是有底线的</Divider>
      </template>
    </List>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无数据"
      v-if="!loading && list_data.length === 0"
    />
    <!-- end -->
  </div>
</template>
<script setup>
import FileGrid from '@/components/common/FileGrid';
import { ref, inject, reactive } from 'vue';
import { useRouter } from 'vue-router';

import { Icon, Notify, List, Divider, Empty, Loading, Overlay } from 'vant';
const $http = inject('$http');
// const $host = inject('$host');
const router = useRouter();

const customer_id = ref(router.currentRoute.value.query.id);
const loading = ref(false);
const finished = ref(false);
// const refreshing = ref(false);
const list_data = ref([]);
const page = reactive({
  page: 1,
  pages: 10,
  total: 0,
  count_page: 1,
});

/**
 * 接口调用
 */
//-@ 收款流水列表
const loadFunc = () => {
  loading.value = true;
  $http
    .getCustomerPaymentDetailApi({
      customer_id: customer_id.value,
      page: page.page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        list_data.value = list_data.value.concat(data.list);
        Object.assign(page, { ...data.page });
        if (data.page.page < data.page.count_page) {
          page.page++;
        } else {
          finished.value = true;
        }
      } else {
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    });
};

/**
 * 操作
 */
//-@ 图片预览
// const previewImage = (arr, index) => {
//   ImagePreview({
//     images: arr.map((v) => $host + v.path), // 预览图片的那个数组
//     startPosition: index, // 指明预览第几张图
//   });
// };
</script>

<style lang="less" scoped>
@import '@/assets/styles/sale/cashFlow.less';
</style>
