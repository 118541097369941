<!--
 * @Author: gest
 * @Date: 2022-05-17 13:24:22
 * @LastEditTime: 2022-12-08 16:58:10
 * @Description: 全部附件列表
//引用方式
<FileAll
    ref="fileAll"
    :data="_DATA.info.files"
    :isShow="_DATA.showAllFile"
    @closePop="
      () => {
        _DATA.showAllFile = false;
      }
    "
  ></FileAll>              
-->
<template>
  <Popup
    v-model:show="_DATA.showOverlay"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="wrapper" v-if="_DATA.showOverlay" @click.stop>
      <NavBar
        left-arrow
        title="全部附件"
        @click-left="onCloseOverLay"
        :fixed="true"
      ></NavBar>
      <div class="content">
        <div class="main">
          <Cell
            v-for="(item, index) in _DATA.info"
            :key="index"
            @click="fileDetail(item, _DATA.info)"
          >
            <template #title>
              <div class="file-item">
                <div class="img-box">
                  <Image
                    v-if="isImg(item.type)"
                    class="img-head"
                    :src="$host + item.path"
                    fit="cover"
                    position="center"
                  >
                    <template v-slot:loading>
                      <Loading type="spinner" size="20"></Loading>
                    </template>
                  </Image>
                  <svg v-else class="icon-small" aria-hidden="true">
                    <use :xlink:href="getIcon(item.type)"></use>
                  </svg>
                </div>
                <div class="right">
                  <label class="van-multi-ellipsis--l2">
                    {{ item.name }}
                  </label>
                  <div class="time" v-if="item.add_time">
                    上传时间：{{ item.add_time }}
                  </div>
                </div>
              </div>
            </template>
          </Cell>
        </div>
      </div>
    </div>
  </Popup>
  <Popup v-model:show="_showData.showVideo" style="width: 80%">
    <div style="display: flex">
      <video
        style="width: 100%"
        controls
        autoplay
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5-page"
        preload="auto"
        :src="$host + _showData.info.path"
      ></video>
    </div>
  </Popup>
  <Popup v-model:show="_showData.showAudio" style="width: 80%">
    <div style="display: flex">
      <audio
        style="width: 100%"
        controls
        autoplay
        preload="auto"
        :src="$host + _showData.info.path"
      ></audio>
    </div>
  </Popup>
</template>
<script setup>
import { watch, inject, reactive, defineProps, defineEmits } from 'vue';
import { Image, Popup, Cell, NavBar, Loading } from 'vant';
//文件展示通用
import { isImg, getIcon, _showData, fileDetail } from '@/utils/fileUpload';

const $host = inject('$host');

const props = defineProps(['data', 'isShow']);
const emit = defineEmits(['closePop', 'update:func']);
const _DATA = reactive({
  info: {},
  showOverlay: false,
});

watch(
  () => props.data,
  (cur) => {
    _DATA.info = cur ? cur : [];
  }
);
watch(
  () => props.isShow,
  (cur) => {
    _DATA.showOverlay = cur ? cur : false;
  }
);
const onCloseOverLay = () => {
  emit('closePop');
};
</script>
<style lang="less" scoped>
.wrapper {
  min-height: 100%;
  background-color: @grey_color;
  .content {
    padding-top: 12.3vw;

    .main {
      padding-top: 10px;
    }
    .file-item {
      display: flex;
      align-items: center;
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        flex: 1;
        label {
          font-weight: 400;
          font-size: 15px;
          color: @main_color;

          flex: 1;
          word-break: break-word;
        }
        .time {
          color: #aaaaaa;
          font-size: 13px;
        }
      }
    }
    .img-box {
      width: 46px;
      height: 46px;
      background: @grey_color;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .img-head {
        width: 100%;
        height: 100%;
      }
      .icon-small {
        width: 25px;
        height: 28px;
      }
    }
  }
}
:deep(.van-cell) {
  border: none;
}
.btn_opt {
  height: 40px;
  background: linear-gradient(135deg, #81b3ff 0%, #3471ff 100%);
  box-shadow: 0px 8px 10px rgba(52, 113, 255, 0.2);
  border: none;
  border-radius: 20px;
}
.mask-box {
  position: absolute;
  top: 0;
  width: 46px;
  height: 46px;
  .mask {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
  }
}
</style>
