<!--
 * @Author: Jessica
 * @Date: 2023-09-19 16:19:49
 * @LastEditTime: 2023-09-23 10:23:11
 * @Description: 营销费用-集团投放
-->
<template>
  <div class="main-data">
    <div class="data_card_box">
      <Skeleton title :row="10" :loading="loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title">集团投放</div>
          <!-- 目标/当年完成/达成率 -->
          <Row>
            <Col span="8">
              <div class="mb_5">
                <span class="size17 bold align_center">
                  {{ detail.finance_company_put_money?.target ?? '-' }}
                </span>
              </div>
              <div class="grey align_center">投放目标(万)</div>
            </Col>
            <Col span="8">
              <div class="mb_5">
                <span class="size17 bold align_center">
                  {{ detail.finance_company_put_money?.actual ?? '-' }}
                </span>
              </div>
              <div class="grey align_center">当年完成(万)</div>
            </Col>
            <Col span="8">
              <!-- 差值 -->
              <div class="mb_5">
                <div
                  class="flex_line_center"
                  v-if="
                    detail.finance_company_put_money?.target <
                    detail.finance_company_put_money?.actual
                  "
                >
                  <span class="bold orange pr_6 size17">
                    {{ detail.finance_company_put_money?.gap ?? '-' }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up_total1.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line_center"
                  v-else-if="
                    detail.finance_company_put_money?.target >
                    detail.finance_company_put_money?.actual
                  "
                >
                  <span class="bold green pr_6 size17">
                    {{ detail.finance_company_put_money?.gap ?? '-' }}
                  </span>
                  <img
                    class="icon_img"
                    :src="
                      require('@/assets/images/target-data/down_total1.png')
                    "
                    alt="down"
                  />
                </div>
                <div class="flex_line_center" v-else>
                  <img
                    class="icon_img"
                    :src="
                      require('@/assets/images/target-data/equal_total.png')
                    "
                    alt="down"
                  />
                  <span class="bold orange pr_6 size17 hideen">0</span>
                </div>
              </div>
              <div class="grey align_center">
                达成率{{ detail.finance_company_put_money?.rate + '%' ?? '-' }}
              </div>
            </Col>
          </Row>
        </div>
        <!-- 列表开始 -->
        <div class="data_card_table border_top">
          <!-- 标题 -->
          <Row class="mb_10">
            <Col span="5">
              <div class="grey">类型</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">目标</div>
            </Col>
            <Col span="7">
              <div class="grey align_center">当年完成</div>
            </Col>
            <Col span="7">
              <div class="grey flex_right">差值&达成率</div>
            </Col>
          </Row>
          <!-- 内容 -->
          <!-- @线索 -->
          <div class="b_b pb_10">
            <Row
              class="mb_10"
              v-for="(sub, index) in _DATA.sub_list_tred"
              :key="index"
            >
              <Col span="5">
                <div class="size13">{{ sub.name }}</div>
                <div class="size13">{{ '(' + sub.unit + ')' }}</div>
              </Col>
              <Col span="5">
                <div>
                  <span class="align_center bold">
                    {{ sub?.target ?? '-' }}
                  </span>
                </div>
              </Col>
              <Col span="7">
                <div>
                  <span class="align_center bold">
                    {{ sub?.actual ?? '-' }}
                  </span>
                </div>
              </Col>
              <!-- @线索成本外差值 差值展示色相反 -->
              <Col span="7" v-if="sub.name === '线索成本'">
                <!-- 差值 -->
                <div
                  class="flex_line flex_right"
                  v-if="sub?.actual > sub?.target"
                >
                  <span class="orange bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up1.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_right"
                  v-else-if="sub?.actual < sub?.target"
                >
                  <span class="green bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down1.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_right" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
                <!-- 达成率 -->
                <div>
                  <span class="flex_right bold">
                    {{ sub?.rate + '%' ?? '-' }}
                  </span>
                </div>
              </Col>
              <!-- @除线索成本外的差值 -->
              <Col span="7" v-else>
                <!-- 差值 -->
                <div
                  class="flex_line flex_right"
                  v-if="sub?.actual > sub?.target"
                >
                  <span class="green bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_right"
                  v-else-if="sub?.actual < sub?.target"
                >
                  <span class="orange bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_right" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
                <!-- 达成率 -->
                <div>
                  <span class="flex_right bold">
                    {{ sub?.rate + '%' ?? '-' }}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <!-- @见面 -->
          <div class="b_b pt_10 pb_10">
            <Row
              class="mb_10"
              v-for="(sub, index) in _DATA.sub_list_meet"
              :key="index"
            >
              <Col span="5">
                <div class="size13">{{ sub.name }}</div>
                <div class="size13">{{ '(' + sub.unit + ')' }}</div>
              </Col>
              <Col span="5">
                <div>
                  <span class="align_center bold">
                    {{ sub?.target ?? '-' }}
                  </span>
                </div>
              </Col>
              <Col span="7">
                <div>
                  <span class="align_center bold">
                    {{ sub?.actual ?? '-' }}
                  </span>
                </div>
              </Col>
              <!-- @见面成本外差值 差值展示色相反 -->
              <Col span="7" v-if="sub.name === '见面成本'">
                <!-- 差值 -->
                <div
                  class="flex_line flex_right"
                  v-if="sub?.actual > sub?.target"
                >
                  <span class="orange bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up1.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_right"
                  v-else-if="sub?.actual < sub?.target"
                >
                  <span class="green bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down1.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_right" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
                <!-- 达成率 -->
                <div>
                  <span class="flex_right bold">
                    {{ sub?.rate + '%' ?? '-' }}
                  </span>
                </div>
              </Col>
              <!-- @除见面成本外的差值 -->
              <Col span="7" v-else>
                <!-- 差值 -->
                <div
                  class="flex_line flex_right"
                  v-if="sub?.actual > sub?.target"
                >
                  <span class="green bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_right"
                  v-else-if="sub?.actual < sub?.target"
                >
                  <span class="orange bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_right" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
                <!-- 达成率 -->
                <div>
                  <span class="flex_right bold">
                    {{ sub?.rate + '%' ?? '-' }}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <!-- @协议 -->
          <div class="pt_10">
            <Row
              class="mb_10"
              v-for="(sub, index) in _DATA.sub_list_protocol"
              :key="index"
            >
              <Col span="5">
                <div class="size13">{{ sub.name }}</div>
                <div class="size13">{{ '(' + sub.unit + ')' }}</div>
              </Col>
              <Col span="5">
                <div>
                  <span class="align_center bold">
                    {{ sub?.target ?? '-' }}
                  </span>
                </div>
              </Col>
              <Col span="7">
                <div>
                  <span class="align_center bold">
                    {{ sub?.actual ?? '-' }}
                  </span>
                </div>
              </Col>
              <!-- @协议成本外差值 差值展示色相反 -->
              <Col span="7" v-if="sub.name === '协议成本'">
                <!-- 差值 -->
                <div
                  class="flex_line flex_right"
                  v-if="sub?.actual > sub?.target"
                >
                  <span class="orange bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up1.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_right"
                  v-else-if="sub?.actual < sub?.target"
                >
                  <span class="green bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down1.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_right" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
                <!-- 达成率 -->
                <div>
                  <span class="flex_right bold">
                    {{ sub?.rate + '%' ?? '-' }}
                  </span>
                </div>
              </Col>
              <!-- @除协议成本外的差值 -->
              <Col span="7" v-else>
                <!-- 差值 -->
                <div
                  class="flex_line flex_right"
                  v-if="sub?.actual > sub?.target"
                >
                  <span class="green bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line flex_right"
                  v-else-if="sub?.actual < sub?.target"
                >
                  <span class="orange bold">
                    {{ sub?.gap }}
                  </span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line flex_right" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
                <!-- 达成率 -->
                <div>
                  <span class="flex_right bold">
                    {{ sub?.rate + '%' ?? '-' }}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, reactive } from 'vue';
import { Skeleton, Row, Col } from 'vant';
const props = defineProps(['data', 'loading']);
const loading = computed(() => props.loading ?? false);
const detail = computed(() => props.data ?? {});
const _DATA = reactive({
  // 线索
  sub_list_tred: computed(() => [
    {
      name: '线索数',
      target: props.data?.finance_company_clue_num?.target ?? '',
      actual: props.data?.finance_company_clue_num?.actual ?? '',
      gap: props.data?.finance_company_clue_num?.gap ?? '',
      rate: props.data?.finance_company_clue_num?.rate ?? '',
      unit: '个',
    },
    {
      name: '线索成本',
      target: props.data?.finance_company_clue_cost?.target ?? '',
      actual: props.data?.finance_company_clue_cost?.actual ?? '',
      gap: props.data?.finance_company_clue_cost?.gap ?? '',
      rate: props.data?.finance_company_clue_cost?.rate ?? '',
      unit: '元',
    },
  ]),
  // 见面
  sub_list_meet: computed(() => [
    {
      name: '见面数',
      target: props.data?.finance_company_meet_num?.target ?? '',
      actual: props.data?.finance_company_meet_num?.actual ?? '',
      gap: props.data?.finance_company_meet_num?.gap ?? '',
      rate: props.data?.finance_company_meet_num?.rate ?? '',
      unit: '个',
    },
    {
      name: '见面率',
      target: props.data?.finance_company_meet_rate?.target ?? '',
      actual: props.data?.finance_company_meet_rate?.actual ?? '',
      gap: props.data?.finance_company_meet_rate?.gap ?? '',
      rate: props.data?.finance_company_meet_rate?.rate ?? '',
      unit: '%',
    },
    {
      name: '见面成本',
      target: props.data?.finance_company_meet_cost?.target ?? '',
      actual: props.data?.finance_company_meet_cost?.actual ?? '',
      gap: props.data?.finance_company_meet_cost?.gap ?? '',
      rate: props.data?.finance_company_meet_cost?.rate ?? '',
      unit: '元',
    },
  ]),
  //-协议
  sub_list_protocol: computed(() => [
    {
      name: '协议数',
      target: props.data?.finance_company_contract_num?.target ?? '',
      actual: props.data?.finance_company_contract_num?.actual ?? '',
      gap: props.data?.finance_company_contract_num?.gap ?? '',
      rate: props.data?.finance_company_contract_num?.rate ?? '',
      unit: '个',
    },
    {
      name: '协议率',
      target: props.data?.finance_company_contract_rate?.target ?? '',
      actual: props.data?.finance_company_contract_rate?.actual ?? '',
      gap: props.data?.finance_company_contract_rate?.gap ?? '',
      rate: props.data?.finance_company_contract_rate?.rate ?? '',
      unit: '%',
    },
    {
      name: '协议成本',
      target: props.data?.finance_company_contract_cost?.target ?? '',
      actual: props.data?.finance_company_contract_cost?.actual ?? '',
      gap: props.data?.finance_company_contract_cost?.gap ?? '',
      rate: props.data?.finance_company_contract_cost?.rate ?? '',
      unit: '元',
    },
  ]),
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/financeData/targetIndex.less';
</style>
