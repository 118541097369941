<!--
 * @Author: gest
 * @Date: 2023-09-12 13:51:19
 * @LastEditTime: 2023-10-27 11:21:12
 * @Description: v1.4.5 进场材料检验记录单 
-->
<template>
  <div class="formBase">
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="white_bg p_15 flex_between">
        <div class="flex_column flex1 mr_15">
          <div>{{ item.name || '-' }}</div>
          <div class="grey_color font_size_13">
            {{ item.sub ? '规格：' + item.sub : '规格：-' }}
          </div>
        </div>

        <Field
          name="radio"
          label=""
          :rules="[
            {
              required: true,
              message: '请选择',
            },
          ]"
        >
          <template #input>
            <RadioGroup
              @click="radioClick(item)"
              v-model="item.value"
              direction="horizontal"
              checked-color="#0256ff"
            >
              <Radio :name="1">有</Radio>
              <Radio :name="2">无</Radio>
            </RadioGroup>
          </template>
        </Field>
      </div>

      <div
        class="list_field"
        style="background-color: #fafafa"
        v-if="item.value == 1"
      >
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">
              <span class="red_color">*</span>
              进场时间
            </div>
            <Field
              style="text-align: right; background-color: #fafafa"
              v-model="item.inTime"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              :rules="[
                {
                  required: true,
                  message: '请选择进场时间',
                },
              ]"
              @click="openDateType(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- v-for  end -->
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">备注说明</div>
          <Field
            v-model="form.info.remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="备注说明进场材料验收合格情况，如验收不合格写明具体不合格的原因与材料更换时间"
            show-word-limit
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>
    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        :max-date="state.maxDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v, 'inTime')"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Popup, DatetimePicker, Dialog } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);
const props = defineProps(['data']);
const state = reactive({
  //-默认选项
  initData: {},
  //-时间控件
  maxDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

// @-时间控件
const openDateType = (data) => {
  state.showTimePicker = true;
  state.initData = data;
  if (data.inTime) {
    state.currentDate = new Date(data.inTime);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  state.showTimePicker = false;
  state.initData.inTime = moment(value).format('YYYY-MM-DD');
};
const radioClick = (data) => {
  if (data.value === 2 && data.inTime) {
    Dialog.confirm({
      message:
        '确认将【有此材料】切换成【无此材料】吗？一旦确认，填写的数据将清空',
    })
      .then(() => {
        data.inTime = null;
      })
      .catch(() => {
        data.value = 1;
      });
  }
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
