<!--
 * @Author: NanNan
 * @Date: 2023-05-23 13:55:33
 * @LastEditTime: 2023-12-26 10:19:56
 * @Description: 中心经理审批-分配主创设计师，客户经理+设计师人员组件选择器
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">{{ _DATA.title }}</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="_DATA.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup
        v-model="_DATA.checkedItem.id"
        v-if="_DATA.list.length"
        :loading="_DATA.loading"
      >
        <cell v-for="item in _DATA.list" :key="item.id">
          <div class="member_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="member_item">
                <p class="name">
                  {{ `${item.name}` }}
                </p>
              </div>
            </Radio>
          </div>
        </cell>
      </RadioGroup>
      <Empty
        v-if="!_DATA.loading && !_DATA.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, inject, defineEmits, defineProps, onMounted } from 'vue';
import { Button, Search, Cell, Empty, Radio, RadioGroup } from 'vant';

import { Toast } from 'vant';
const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['data']);
const $http = inject('$http');

const _DATA = reactive({
  title: props.data ? props.data.title : '',
  loading: true,
  kw: props.data ? props.data.checkedItem?.name : '',
  list: [],
  checkedItem: props?.data?.checkedItem,
});

onMounted(() => {
  onLoad();
  console.log(props.data);
});

/**
 * 接口
 */
// -- 获取人员
const onLoad = () => {
  _DATA.loading = true;
  const httpRequest = $http.customerAssignServicePersonParam({
    service_user_name: _DATA.kw,
    customer_id: props?.data.customer_id,
    dispatch_id: props?.data?.dispatch_id,
  });
  httpRequest.then((res) => {
    _DATA.loading = false;
    if (res.code === 0) {
      _DATA.list = res.data[props?.data?.type];
    } else {
      _DATA.list = [];
      Toast(res.msg || res.data);
    }
  });
};

/**
 * 操作
 */
// -- 关键字搜索
const searchFun = () => {
  _DATA.list = [];
  onLoad();
};

const getClick = (item) => {
  Object.assign(_DATA.checkedItem, item);
};

// -- 确认
const btnSumbit = () => {
  emit('reviewOk', _DATA.checkedItem);
};

// -- 清除
const clear = () => {
  _DATA.checkedItem = {};
  emit('reviewOk', _DATA.checkedItem);
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
</style>
