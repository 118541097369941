<!--
 * @Author: gest
 * @Date: 2024-05-09 15:34:52
 * @LastEditTime: 2024-05-21 10:00:18
 * @Description: v1.6.0 进度组件
 {
    actual: 180, //实际值
    target: 150, //目标或总数
    list: [0, 100, 200, 300], //坐标值
    type: 1, //1 默认对比（总数） 2绿+蓝（阶段/存量）  3蓝色单条 4红色单条
  },
-->
<template>
  <div class="chart_box">
    <div
      class="chart_line text_indent"
      :class="
        props.data.type == 1
          ? props.data.target > props.data.actual
            ? props.data.actual < 0
              ? 'green_nobg green_stroke'
              : 'green green_stroke'
            : 'red red_stroke'
          : props.data.type == 2
          ? 'green green_stroke'
          : props.data.type == 3
          ? 'blue blue_stroke'
          : 'red red_stroke'
      "
      style="z-index: 3"
      :style="{
        width: leftDis + '%',
      }"
      :data-attr="props.data.actual"
    >
      {{ props.data.actual }}
    </div>
    <div
      class="chart_line"
      :class="
        props.data.type == 1
          ? props.data.target > props.data.actual
            ? 'grey'
            : props.data.target == 0
            ? 'red'
            : 'red2'
          : 'grey chart_total'
      "
      v-if="props.data.type == 1 || props.data.type == 2"
      :style="{
        width: props.data.type == 1 ? rightDis + '%' : rightDis + leftDis + '%',
      }"
    >
      <!-- 数值显示位移，避免重叠 -->
      <!-- <div
        style="position: absolute; width: 100%; height: 100%"
        :style="{
          left: leftDis < 25 && leftDis + rightDis < 60 ? '110%' : '',
          textAlign:
            leftDis < 25 && leftDis + rightDis < 60 ? 'left' : 'center',
        }"
      >
        <div
          style="position: relative"
          :class="
            props.data.type == 1
              ? props.data.target > props.data.actual
                ? 'green_stroke'
                : 'red2_stroke'
              : 'blue_stroke'
          "
          :data-attr="props.data.type == 2 ? '' : rightNum"
        >
          {{ props.data.type == 2 ? '' : rightNum }}
        </div>
      </div> -->
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import * as _ from 'lodash';
import NP from 'number-precision';

const props = defineProps(['data']);

const leftNum = computed(() => {
  let result = 0;
  if (props.data.type == 1) {
    if (props.data.target > props.data.actual) {
      result = parseFloat(props.data.actual);
    } else {
      result = parseFloat(props.data.target);
    }
  } else {
    result = parseFloat(props.data.actual);
  }
  console.log(result);
  return result;
});
const rightNum = computed(() => {
  let result = 0;
  if (props.data.type == 1) {
    if (props.data.target > props.data.actual) {
      result = _.floor(
        NP.minus(parseFloat(props.data.target), parseFloat(props.data.actual)),
        2
      );
    } else {
      result = _.floor(
        NP.minus(parseFloat(props.data.actual), parseFloat(props.data.target)),
        2
      );
    }
  } else if (props.data.type == 2) {
    result = _.floor(
      NP.minus(parseFloat(props.data.target), parseFloat(props.data.actual)),
      2
    );
  }

  console.log(result);
  return result;
});
const leftDis = computed(() => {
  let result = 0;
  //计算进度条宽度百分比
  if (props.data.actual >= 0) {
    //负数
    let total =
      props.data.list?.length == 5
        ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
        : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));

    result = NP.times(
      _.floor(NP.divide(parseFloat(leftNum.value), total), 4),
      100
    );
  }

  console.log(result);
  return result;
});
const rightDis = computed(() => {
  console.log(rightNum.value);
  let result = 0;
  let total =
    props.data.list?.length == 5
      ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
      : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));
  //计算进度条宽度百分比

  result = NP.times(
    _.floor(NP.divide(parseFloat(rightNum.value), total), 4),
    100
  );

  console.log(result);
  return result;
});
</script>
<style lang="less" scoped>
.chart_box {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  .chart_line {
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    height: 13px;

    white-space: nowrap;
    position: relative;

    z-index: 2;

    &.green {
      background: #008911;
      color: #ffffff;
      // text-shadow: -1px -1px 0 #008911, 1px -1px 0 #008911, -1px 1px 0 #008911,
      //   1px 1px 0 #008911;
    }
    &.green_nobg {
      color: #ffffff;
    }
    &.grey {
      background: #f3f3f3;
      color: #ffffff;
    }
    &.red {
      background: #ea0000;
      color: #ffffff;
    }

    &.red2 {
      background: #8c0000;
      color: #ffffff;
    }
    &.blue {
      background: #0256ff;
      color: #ffffff;
    }
    &.chart_total {
      position: absolute;
      left: 0;
      height: 25px;
    }
  }
}
.green_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #008911;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}

.red_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #ea0000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
.red2_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #8c0000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
.blue_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #0256ff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
.text_indent {
  text-indent: 4px;
}
</style>
