<!--
 * @Author: gest
 * @Date:2023-06-06 17:55:37
 * @LastEditTime: 2023-06-09 15:36:05
 * @Description: V1.3.8 产品大类搜索选择弹窗
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">选择产品大类</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="state.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup v-model="form.checked" v-if="form.list.length">
        <cell v-for="item in form.list" :key="item.id">
          <div class="member_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="member_item">
                <p class="name">{{ item.name }}</p>
              </div>
            </Radio>
          </div>
        </cell>
      </RadioGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import _ from 'lodash';
import { reactive, defineEmits, onMounted, defineProps } from 'vue';
import { Button, Search, Cell, Empty, Radio, RadioGroup } from 'vant';

const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['data']);

const state = reactive({
  kw: '',
});
const form = reactive({
  checked: props?.data ? Number(props?.data.check) : '',
  checkedItem: {},
  list: [],
  allList: props?.data ? props?.data.list : [],
});
onMounted(() => {
  state.kw = '';
  form.list = props?.data ? _.cloneDeep(props?.data.list) : [];
});
//-搜索
const searchFun = () => {
  if (state.kw) {
    form.list = form.allList.filter((item) => item.name.indexOf(state.kw) > -1);
  } else {
    form.list = _.cloneDeep(form.allList);
  }
};

const getClick = (item) => {
  form.checked = item.id;
  form.checkedItem = item;
};
//-确认操作
const btnSumbit = () => {
  emit('reviewOk', form.checkedItem);
};

//-取消
const clear = () => {
  form.checkedItem = {};

  emit('reviewOk', form.checkedItem);
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
</style>
