<!--
 * @Author: NanNan
 * @Date: 2023-10-26 16:21:17
 * @LastEditTime: 2024-05-14 13:54:16
 * @Description: v1.4.8 线索模块：变更分配
 * 'discover'线索变更分配;'reassignment' 线索改派;
 * v1.4.9 smart 分配规则 === 3暂不分配 清空邀约人、禁用邀约人
 * v1.5.6 smart type === 'reassignment' 线索改派
 * v1.5.7 smart type === 'redeploy' 线索轮派功能升级，新增 组内轮派、跨组轮派
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <NavBar
      safe-area-inset-top
      :title="props?.data?.title"
      left-arrow
      :fixed="true"
      @click-left="close"
    />
    <!-- 内容部分 -->
    <div class="inner-top">
      <Icon name="warning" size="14" />
      <p v-if="props?.data?.type === 'discover'">
        您正在对所选的 1
        条线索进行变更分配，请选择分配规则；设置后将直接取代现有邀约人、保存派单业绩，并通知新邀约人完善客户信息。
      </p>
      <p v-else-if="props?.data?.type === 'redeploy'">
        您正在对所选的 1
        条线索发起轮派，请选择分配规则。设置后将复制新的线索，并通知邀约人跟进；已添加企微后如跨组轮派，将清除原邀约人的企微添加信息。
      </p>
      <p v-else>
        您正在对所选的 1
        条线索发起改派，请选择分配规则；设置后将复制一条新的线索，并通知新邀约人完善客户信息。
      </p>
    </div>
    <Form class="form-new-box" ref="formRef" error-message-align="right">
      <!-- 分配规则(线索轮派，禁止选择) -->
      <div class="field-new">
        <Field
          required
          label="分配规则"
          is-link
          readonly
          :disabled="props?.data?.type === 'redeploy'"
          v-model="form.distribution_rule_name"
          :rules="[{ required: true, message: '请选择分配规则' }]"
          input-align="right"
          @click.stop="
            () =>
              props?.data?.type === 'redeploy'
                ? ''
                : (popup.distribution_show = true)
          "
        ></Field>
      </div>

      <!-- 负责人 -->
      <div class="field-new">
        <Field
          label="负责人"
          readonly
          v-model="form.responsible_user_name"
          input-align="right"
          disabled
        ></Field>
      </div>

      <!-- 邀约人 -->
      <div class="field-new">
        <Field
          label="邀约人"
          :is-link="!invite_disabled"
          readonly
          v-model="form.invite_user_name"
          input-align="right"
          placeholder="选择"
          :disabled="invite_disabled"
          @click.stop="
            () =>
              !invite_disabled
                ? (personnel.show = true)
                : (personnel.show = false)
          "
        ></Field>
      </div>
      <!-- 备注说明 -->
      <div class="field-same-top">
        <p class="label">备注</p>
        <Field
          v-model="form.remark"
          rows="3"
          autosize
          type="textarea"
          maxlength="100"
          placeholder="请填写备注..."
          show-word-limit
          :border="false"
          class="field-textarea"
        ></Field>
      </div>
    </Form>
    <!-- 按钮 -->
    <div class="stick-box">
      <Button
        type="primary"
        size="large"
        @click="sumbit"
        :loading="loadingBtn"
        v-if="props?.data?.type === 'discover'"
      >
        确定分配
      </Button>
      <Button
        type="primary"
        size="large"
        @click="sumbit"
        :loading="loadingBtn"
        v-if="props?.data?.type === 'reassignment'"
      >
        确定改派
      </Button>
      <Button
        type="primary"
        size="large"
        @click="sumbit(1)"
        :loading="loadingBtn"
        v-if="
          props?.data?.type === 'redeploy' &&
          props?.allOperationAuthority?.indexOf('597') > -1
        "
      >
        组内轮派
      </Button>
      <Button
        type="primary"
        size="large"
        @click="sumbit(2)"
        :loading="loadingBtn"
        v-if="
          props?.data?.type === 'redeploy' &&
          props?.allOperationAuthority?.indexOf('598') > -1
        "
      >
        跨组轮派
      </Button>
    </div>
  </div>

  <!-- 分配规则 -->
  <Popup v-model:show="popup.distribution_show" position="bottom">
    <Picker
      :columns="options.distribution_rule"
      :columns-field-names="{
        text: 'name',
        value: 'id',
      }"
      @confirm="(value) => openPopup(value, 'distribution_rule')"
      @cancel="clearPop('distribution_rule')"
      cancel-button-text="清除"
      :default-index="
        options.distribution_rule.findIndex((v) => {
          return v.id == form.distribution_rule_id;
        })
      "
    />
  </Popup>
  <!-- Popup 选择服务人员-->
  <Popup
    round
    :close-on-popstate="true"
    position="bottom"
    v-model:show="personnel.show"
    :style="{ maxHeight: '70%' }"
  >
    <TempSelector
      v-if="personnel.show"
      :data="personnel"
      @reviewTempSelect="reviewTempSelect"
    />
  </Popup>
</template>
<script setup>
import {
  defineProps,
  reactive,
  ref,
  defineEmits,
  onMounted,
  inject,
} from 'vue';
import {
  NavBar,
  Notify,
  Overlay,
  Loading,
  Icon,
  Field,
  Popup,
  Picker,
  Button,
  Form,
} from 'vant';
import TempSelector from '@/components/common/TempSelector.vue';

const props = defineProps(['data', 'allOperationAuthority']);
const emit = defineEmits(['reviewFunc']);
const $http = inject('$http');

onMounted(() => {
  loadParams();
  getUserFunc();
  console.log(props?.data);
});

/**
 * 接口
 */
// -- 公共参数
const loading = ref(false);
const options = reactive({ distribution_rule: [] });
const loadParams = () => {
  loading.value = true;
  $http.getSaleOptionsApi().then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      options.distribution_rule = data.customer.distribution_rule || [];
      //分配规则默认值设置
      var distribution_rule_id = '';
      var distribution_rule_name = '';
      if (
        props?.data?.type === 'reassignment' &&
        data.customer.distribution_rule?.length > 0
      ) {
        // 线索改派
        const defaultItem = data.customer.distribution_rule.filter(
          (item) => item.reassignment_default == 1
        );
        distribution_rule_id = defaultItem[0].id;
        distribution_rule_name = defaultItem[0].name;
      } else if (
        props?.data?.type === 'discover' &&
        data.customer.distribution_rule?.length > 0
      ) {
        // 变更分配
        const defaultItem = data?.customer?.distribution_rule;
        distribution_rule_id = defaultItem[0].id;
        distribution_rule_name = defaultItem[0].name;
      } else {
        // 其他（暂时用不到，与pc统一）
        distribution_rule_id = 1;
        distribution_rule_name = '手动分配邀约人';
      }
      form.distribution_rule_name = distribution_rule_name;
      form.distribution_rule_id = distribution_rule_id;
      resetForm(data.customer.distribution_rule[0]);
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
// -- 获取登录者信息
const userInfo = reactive({});
const responsibleUser = ref('');
const getUserFunc = () => {
  $http.getUserInfo().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      const info = JSON.parse(JSON.stringify(data));
      const user_all = `${info.name}[${info?.company_name}/${info.department_name}/${info.post_name}]`;
      Object.assign(userInfo, data);
      responsibleUser.value = user_all;
    } else {
      responsibleUser.value = '';
    }
  });
};

/**
 * 操作
 */
// -- 分配规则
const popup = reactive({
  distribution_show: false, //分配规则组件-是否展示
  distribution_disabled: false, //分配规则-是否禁用
  responsible_show: false, //负责人组件-是否展示
  responsible_disabled: true, //负责人-是否禁用
  invite_show: false, //邀约人组件-是否展示
  invite_disabled: false, //邀约人-是否禁用
});
const invite_disabled = ref(false);

// -- 选择服务人员
const personnel = reactive({
  show: false,
  title: '选择邀约人',
  placeholder: '请输入人员姓名',
  http: `public_servicePersonalList`,
  max: 1,
  parmars: {
    is_all_person: 2,
    is_show_depart: 0,
  },
  substr: {
    id: 'id',
    name: 'label',
  },
  chose_items: [],
  chose_ids: [],
});

// -- 打开组件
const openPopup = (item, type_name) => {
  console.log(item, type_name);
  if (type_name === 'distribution_rule') {
    //分配规则
    popup.distribution_show = false;
    resetForm(item);
  } else if (type_name === 'invite') {
    //邀约人
    personnel.show = true;
    console.log('///', personnel.chose_items);
  }
};

// -- 选择服务人员：回调
const reviewTempSelect = (status, items, ids) => {
  console.log(status, items, ids);
  if (items.length === 1) {
    form.invite_user_id = ids[0];
    form.invite_user_name =
      items[0]?.label +
      '[' +
      items[0]?.company_str +
      '/' +
      items[0]?.dep_str +
      '/' +
      items[0]?.post_str +
      ']';
  } else {
    form.invite_user_id = '';
    form.invite_user_name = '';
  }
  personnel.chose_ids = ids || [];
  personnel.chose_items = items || [];
  personnel.show = false;
};

// -- 切换分配规则：联动响应
const resetForm = (item) => {
  if (item.id != form.distribution_rule_id) {
    personnel.chose_ids = [];
    personnel.chose_items = [];
  }
  form.distribution_rule_id = item.id;
  form.distribution_rule_name = item.name;
  if (item.id === 1) {
    //1、手动分配邀约人
    form.responsible_user_name = responsibleUser.value;
    form.responsible_user_id = userInfo.id;

    form.invite_user_name = '';
    form.invite_user_id = '';
    invite_disabled.value = false;
  } else if (item.id === 2) {
    //2、网销轮派负责人，无邀约人
    form.responsible_user_name = '-';
    form.responsible_user_id = '';
    form.invite_user_id = '';
    form.invite_user_name = '-';
    invite_disabled.value = true;
  } else if (item.id === 3) {
    //3、暂不分配
    form.responsible_user_name = responsibleUser.value;
    form.responsible_user_id = userInfo.id;
    form.invite_user_id = '';
    form.invite_user_name = '-';
    invite_disabled.value = true;
  } else if (item.id === 4 || item.id === 5) {
    //4、网销轮派各组主管为邀约人
    //5、自动派单网销主管为邀约人
    form.responsible_user_name = responsibleUser.value;
    form.responsible_user_id = userInfo.id;
    form.invite_user_id = '';
    form.invite_user_name = '-';
    invite_disabled.value = true;
  }
};

// -- 清除
const clearPop = (type_name) => {
  if (type_name === 'distribution_rule') {
    //分配规则
    form.distribution_rule_id = '';
    form.distribution_rule_name = '';
    popup.distribution_show = false;
  }
};

const form = reactive({
  clue_ids: [props?.data?.id],
  distribution_rule_id: '',
  distribution_rule_name: '',
  responsible_user_id: '',
  responsible_user_name: '',
  invite_user_id: '',
  invite_user_name: '',
  remark: '',
});

// -- 确定分配
const loadingBtn = ref(false);
const formRef = ref(null);
const sumbit = (type) => {
  formRef.value?.validate().then(() => {
    loadingBtn.value = true;
    const requestApi =
      props?.data?.type === 'discover'
        ? $http.clueDistribution({
            ...form,
          })
        : props?.data?.type === 'redeploy'
        ? $http.clueCopyClue({ ...form, clue_id: props?.data?.id, type })
        : $http.clue_clueReassignment({
            ...form,
          });
    requestApi.then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          duration: 2000,
          onClose: () => {
            loadingBtn.value = false;
            emit('reviewFunc', 'ok');
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loadingBtn.value = false;
          },
        });
      }
    });
  });
};

// -- 关闭
const close = () => {
  emit('reviewFunc', 'ok');
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/commonField.less';

.page-box {
  height: 100%;
  box-sizing: border-box;
  padding: 0 0;
  .inner-top {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 56px 16px 10px;
    background: #fdf0e8;
    color: #f37020;
    font-size: 13px;
    line-height: 19px;
    p {
      margin-left: 8px;
    }
  }
  .form-new-box {
    padding-bottom: 90px;
  }
}

.stick-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  button {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 12px;
  }
}
</style>
