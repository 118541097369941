<!--
 * @Author: gest
 * @Date: 2024-05-09 15:34:52
 * @LastEditTime: 2024-05-18 17:36:17
 * @Description: v1.6.0 进度组件-中位图
 {
    actual: 220, //实际值
    target: 180, //目标
    list: [140, 160, 180, 200,220], //坐标值
    type: 1, //1 默认（绿+红） 2蓝色
  },
-->
<template>
  <div class="chart_box" v-if="props.data.actual > 0">
    <div
      class="chart_line"
      :class="
        props.data.type == 1
          ? props.data.target > props.data.actual
            ? 'red left_pos  '
            : 'green  '
          : props.data.target > props.data.actual
          ? 'blue left_pos  '
          : 'blue   '
      "
      :style="{
        left: leftDis + '%',
        width: dis + '%',
      }"
    ></div>
    <div
      class="chart_line"
      :class="
        props.data.type == 1
          ? props.data.target > props.data.actual
            ? 'left_pos red_stroke  '
            : 'green_stroke   '
          : props.data.target > props.data.actual
          ? ' left_pos blue_stroke '
          : ' blue_stroke    '
      "
      :style="{
        left:
          props.data.target > props.data.actual
            ? leftDis - 2 + '%'
            : leftDis + 2 + '%',
      }"
      :data-attr="leftNum"
    >
      {{ leftNum }}
    </div>

    <!--实际值显示位移，避免重叠 -->
    <!-- 右边 -->
    <!-- <div
      v-if="props.data.target < props.data.actual"
      class="grey_text"
      :class="leftDis + dis < 90 ? 'pl_10' : 'pl_2'"
      :style="{
        left: dis < 20 ? leftDis + dis + 20 + '%' : leftDis + dis + '%',
      }"
    >
      {{ props.data.actual }}
    </div> -->

    <div
      class="grey_text pl_2"
      :style="{
        left: '100%',
      }"
    >
      {{ props.data.actual }}
    </div>
    <!-- 左边 -->
    <!-- <div
      v-else
      class="grey_text left_pos text_right pr_5"
      :style="{
        left: '0%',
      }"
    >
      {{ props.data.actual }}
    </div> -->
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import * as _ from 'lodash';
import NP from 'number-precision';
const props = defineProps(['data']);

const leftNum = computed(() => {
  let result = 0;
  if (props.data.target > props.data.actual) {
    result = _.floor(
      NP.minus(parseFloat(props.data.target), parseFloat(props.data.actual)),
      2
    );
  } else {
    result = _.floor(
      NP.minus(parseFloat(props.data.actual), parseFloat(props.data.target)),
      2
    );
  }
  console.log(result);
  return result;
});

const dis = computed(() => {
  let result = 0;
  //计算进度条宽度百分比
  let total =
    props.data.list?.length == 5
      ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
      : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));

  result = NP.times(
    _.floor(NP.divide(parseFloat(leftNum.value), total), 4),
    100
  );

  console.log(result);
  return result;
});
const leftDis = computed(() => {
  let result = 0;
  //计算进度条偏移
  let total =
    props.data.list?.length == 5
      ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
      : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));

  result = NP.times(
    _.floor(NP.divide(parseFloat(props.data.target), total), 4),
    100
  );

  console.log(result);
  return result;
});
</script>
<style lang="less" scoped>
.chart_box {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  height: 18px;
  .chart_line {
    text-align: left;
    // font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    z-index: 2;
    white-space: nowrap;
    position: absolute;
    color: #ffffff;
    top: 0;
    &.green {
      background: #008911;
    }

    &.red {
      background: #ea0000;
    }

    &.blue {
      background: #0256ff;
    }
  }
  .grey_text {
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    white-space: nowrap;
    color: #999999;
    position: absolute;
    top: 0;
    text-shadow: none;
    width: 38px;
  }
  .left_pos {
    transform: translateX(-100%);
    text-align: right;
  }
}
.green_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #008911;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}

.red_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #ea0000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}

.blue_stroke {
  &::after {
    content: attr(data-attr);
    -webkit-text-stroke: 2px #0256ff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
}
.text_indent {
  text-indent: 4px;
}
.text_indent_left {
  text-indent: -4px;
}
</style>
