<!--
 * @Author: Jessica
 * @Date: 2023-09-19 16:20:48
 * @LastEditTime: 2024-05-18 09:20:21
 * @Description: 财务大屏-毛利润
-->
<template>
  <!-- 毛利润 -->
  <div class="main-data">
    <div class="warpper">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_12.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                毛利润
                {{ detail.finance_gross_profit?.actual + '万' ?? '-' }}
              </div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>
                  <em class="pl-3">
                    {{ detail.finance_gross_profit?.target + '万' ?? '-' }}
                  </em>
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail.finance_gross_profit?.target ?? 0) <
                      parseFloat(detail.finance_gross_profit?.actual ?? 0)
                    "
                  >
                    <em class="c1">
                      {{ detail.finance_gross_profit?.gap + '万' ?? '-' }}
                    </em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail.finance_gross_profit?.target ?? 0) >
                      parseFloat(detail.finance_gross_profit?.actual ?? 0)
                    "
                  >
                    <em class="c2">
                      {{ detail.finance_gross_profit?.gap + '万' ?? '-' }}
                    </em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">达成率</span>
                <span class="font-size12 pl-3">
                  {{ detail.finance_gross_profit?.rate + '%' ?? '-' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 毛利率 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">毛利率</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">
                        {{ detail.finance_rate?.target + '%' ?? '-' }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="7">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.finance_rate?.actual + '%' ?? '-' }}
                    </em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.finance_rate?.target ?? 0) <
                          parseFloat(detail.finance_rate?.actual ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.finance_rate?.gap + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.finance_rate?.target ?? 0) >
                          parseFloat(detail.finance_rate?.actual ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.finance_rate?.gap + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { Skeleton, Row, Col } from 'vant';
const props = defineProps(['data', 'loading']);
const loading = computed(() => props.loading ?? false);
const detail = computed(() => props.data ?? {});
</script>

<style lang="less" scoped>
@import '@/assets/styles/financeData/targetIndex.less';
</style>
