<!--
 * @Author: NanNan
 * @Date: 2023-10-25 10:01:38
 * @LastEditTime: 2024-03-06 09:51:51
 * @Description: v1.4.8 公共组建 - 选择服务人员
 * 详细描述：嵌套在Popup内，底部弹出，包含搜索筛选、重新选择、已选择人员展示等
 * mastergo：https://mastergo.com/file/91167116246104?fileOpenFrom=project&page_id=M
-->
<template>
  <div class="TempPopupBox" ref="containerRef">
    <Overlay :show="_DATA.loading" class="overlay-fff-inner">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 公共头 -->
    <div class="title-box">
      <p>{{ props?.data?.title }}</p>
      <Icon name="cross" class="icon-close" size="15" @click="sumbit" />
    </div>
    <!-- 搜索框 -->
    <div class="search-box">
      <form action="/">
        <Search
          :placeholder="props?.data?.placeholder"
          show-action
          maxlength="20"
          v-model="kw"
          :clearable="false"
          :formatter="formatterSearch"
          format-trigger="onChange"
          @search="onSearch"
          @cancel="onCancel"
        >
          <template #action>
            <div @click="onSearch" style="color: #0256ff; font-size: 15px">
              搜索
            </div>
          </template>
          <template #right-icon>
            <div @click="onClear" v-if="kw != ''">
              <Icon name="clear" color="#c8c9cc" />
            </div>
          </template>
        </Search>
      </form>
    </div>
    <!-- 已选择类目 -->
    <div class="chose-box" v-if="_DATA.chose_items?.length > 0">
      <div class="left" @click="choseReset">
        重新选择
        <Icon name="replay" size="13" class="ml4" />
      </div>
      <div class="right">
        <p>
          {{ _DATA.chose_items[0]?.[_DATA.substr_name] }}
        </p>
        <span v-if="_DATA.chose_items?.length > 1">
          +{{ _DATA.chose_items?.length - 1 }}
        </span>
      </div>
    </div>
    <!-- 列表展示 -->
    <div class="list-box" v-if="_DATA.list.length > 0">
      <List
        v-model:loading="_DATA.loading"
        :finished="_DATA.finished"
        finished-text="没有更多了"
        @load="loadList"
      >
        <div
          v-for="(item, index) in _DATA.list"
          :key="index"
          class="cell-same"
          @click.stop="handleChose(item)"
          :class="{ on: _DATA.chose_ids.indexOf(item.id) > -1 }"
        >
          <div class="left">
            <Image
              class="image"
              v-if="item?.sex == 1"
              :src="
                item.face_image
                  ? host + item.face_image
                  : require('@/assets/images/icon-gender-new1.png')
              "
            />
            <Image
              class="image"
              v-else-if="item?.sex == 2"
              :src="
                item.face_image
                  ? host + item.face_image
                  : require('@/assets/images/icon-gender-new2.png')
              "
            />
            <Image
              class="image"
              v-else
              :src="
                item?.face_image
                  ? host + item.face_image
                  : require('@/assets/images/icon-gender-new.png')
              "
            />
          </div>
          <div class="right">
            <p class="name">
              <span>{{ item.label }}</span>
              <em v-if="item.status == 2">已离职</em>
            </p>
            <p class="part">{{ item.dep_str + '/' + item.post_str }}</p>
          </div>
        </div>
      </List>
    </div>
    <!-- 空数展示 -->
    <div
      class="empty-search-box"
      v-if="!_DATA.loading && _DATA.list.length === 0"
    >
      <img :src="require('@/assets/images/search-empty.png')" alt="" />
    </div>
    <!-- 操作按钮 -->
    <div class="stick-box">
      <Button type="primary" size="large" @click="sumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  reactive,
  defineProps,
  ref,
  inject,
  defineEmits,
} from 'vue';
import {
  Search,
  Icon,
  Button,
  Overlay,
  Loading,
  Notify,
  Image,
  List,
} from 'vant';

const props = defineProps(['data']);
const emit = defineEmits(['reviewTempSelect']);
const $http = inject('$http');
const host = inject('$host');

const containerRef = ref(null);
const kw = ref('');

const _DATA = reactive({
  kw: '',
  loading: false,
  finished: false,
  refreshing: false,
  list: [],
  page: {
    count_page: 1,
    page: 1,
    pages: 10,
    total: 0,
  },
  chose_items: props?.data?.chose_items,
  chose_ids: props?.data?.chose_ids,

  substr_id: props?.data?.substr?.id || 'id',
  substr_name: props?.data?.substr?.name || 'name',
});

onMounted(() => {
  loadList();
});

/**
 * 接口
 */
// -- 获取列表

const loadList = () => {
  _DATA.loading = true;
  $http[props?.data?.http]({
    kw: _DATA.kw,
    page: _DATA.page.page,
    pages: _DATA.page.pages,
    ...props?.data?.parmars,
  }).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.page = data.page;
      _DATA.list = _DATA.list.concat(data.list);
      if (data.page.page <= data.page.count_page) {
        _DATA.page.page++;
      } else {
        _DATA.finished = true;
      }
    } else {
      _DATA.loading = false;
      _DATA.finished = true;
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

/**
 * 操作
 */
// -- 选择人员
const handleChose = (item) => {
  const has_index = _DATA.chose_ids.indexOf(item.id);
  const length = props?.data?.max;
  if (length === 1) {
    // 单选
    _DATA.chose_ids = [item.id];
    _DATA.chose_items = [item];
  } else {
    // 多选
    if (has_index > -1) {
      // 存在
      _DATA.chose_ids.splice(has_index, 1);
      _DATA.chose_items.splice(has_index, 1);
    } else {
      // 不存在
      if (props?.data?.max && _DATA.chose_items?.length === props?.data?.max) {
        Notify({
          type: 'warning',
          message: '最多可选择' + _DATA.chose_items?.length + '人',
        });
        return;
      }
      _DATA.chose_items.push(item);
      _DATA.chose_ids.push(item.id);
    }
  }
  // console.log('已选择ids', _DATA.chose_ids);
  // console.log('已选择items', _DATA.chose_items);
};

// -- 点击搜索
const onSearch = () => {
  console.log('搜索');
  _DATA.page.page = 1;
  _DATA.kw = JSON.parse(JSON.stringify(kw.value));
  _DATA.list = [];
  _DATA.refreshing = false;
  _DATA.finished = false;
  loadList();
};

// -- 搜索框：取消kw
const onCancel = () => {
  console.log('取消');
  kw.value = JSON.parse(JSON.stringify(_DATA.kw));
};

// -- 搜索框：清空
const onClear = () => {
  _DATA.kw = '';
  kw.value = '';
  _DATA.page.page = 1;
  _DATA.list = [];
  _DATA.refreshing = false;
  _DATA.finished = false;
  loadList();
};

// -- 已选人员：重新选择
const choseReset = () => {
  _DATA.chose_items = [];
  _DATA.chose_ids = [];
  console.log('已选择ids', _DATA.chose_ids);
  console.log('已选择items', _DATA.chose_items);
};

// -- 确定
const sumbit = () => {
  console.log('点击确定');

  emit('reviewTempSelect', 'ok', _DATA.chose_items, _DATA.chose_ids);
};

/**
 * 其他
 */
// -- 搜索框：input格式化去除左右空格
const formatterSearch = (value) => {
  return value.replace(/^\s*|\s*$/g, '');
};
</script>

<style lang="less" scoped>
.TempPopupBox {
  padding: 25px 20px 74px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: visible;

  .title-box {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 25px;
    font-size: 17px;
    text-align: center;
    position: relative;
    .icon-close {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  .search-box {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 15px 0;
  }

  .chose-box {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    margin: 0 0 15px;
    .left {
      flex-grow: 0;
      flex-shrink: 0;
      height: 23px;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 15px;
      margin-right: 15px;
      cursor: pointer;
      .ml4 {
        margin-left: 4px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 23px;
      p {
        font-size: 15px;
        color: #8d9091;
        flex-grow: 1;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1; //确定超出行数
        -webkit-box-orient: vertical;
      }
      span {
        height: 23px;
        color: #767676;
        background: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        font-size: 15px;
        margin-left: 4px;
      }
    }
  }

  .list-box {
    flex-grow: 0;
    flex-shrink: 1;
    max-height: inherit;
    overflow-y: auto;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 18px 0 0;
    .cell-same {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 15px;
      &.on {
        .right {
          .name,
          .part {
            color: #0256ff !important;
          }
        }
      }
      .left {
        flex-grow: 0;
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin-right: 6px;
        overflow: #f9fbff;
        .image {
          display: block;
          width: 50px;
          height: 50px;
        }
      }
      .right {
        flex-grow: 1;
        display: flex;
        justify-content: left;
        flex-direction: column;

        .name {
          height: 25px;
          line-height: 24px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1 !important; //确定超出行数
          -webkit-box-orient: vertical;
          word-break: break-all;
          font-size: 15px;
          display: flex;
          justify-content: left;
          align-items: center;
          em {
            background: #eeeeee;
            font-size: 12px;
            color: #767676;
            height: 20px;
            line-height: 20px;
            padding: 0 5px;
            margin-left: 8px;
            border-radius: 2px;
            font-weight: 500;
          }
        }
        .part {
          width: 100%;
          line-height: 16px;
          height: 16px;
          font-size: 13px;
          color: #8d9091;

          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1 !important; //确定超出行数
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
    }
  }

  .empty-search-box {
    height: auto;
    margin: 0 20px;
    background: #fff;
    border-radius: 14px;
    width: calc(100% - 40px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 74px;

    img {
      display: block;
      width: 200px;
      height: auto;
    }
  }
}

:deep(.van-search) {
  padding: 0 !important;
}
:deep(.van-search__field) {
  border: none !important;
}
</style>
