<!--
 * @Author: gest
 * @Date: 2023-05-11 20:04:33
 * @LastEditTime: 2023-05-17 18:24:59
 * @Description: V1.3.7 主材订单操作
    //429跟单/437通知送货/432申请下单/435通知测量
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    :title="getTitle()"
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-box middle-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- end -->
    <Form class="form-box" ref="formRef">
      <div class="detail-box">
        <p class="label-name" v-if="_DATA.type == 435 || _DATA.type == 437">
          <em>*</em>
          {{ _DATA.type == 435 ? '测量日期' : '预计送货日期' }}
        </p>
        <Field
          v-if="_DATA.type == 435 || _DATA.type == 437"
          v-model="form_info.custom_time"
          name="picker"
          readonly
          :placeholder="_DATA.type == 435 ? '选择测量日期' : '选择预计送货日期'"
          right-icon="underway-o"
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请选择日期',
            },
          ]"
          @click="clickFun"
        />

        <p class="label-name">
          <em>*</em>
          备注
        </p>
        <Field
          v-model="form_info.remark"
          rows="3"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请填写"
          show-word-limit
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请填写备注',
            },
          ]"
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">附件</p>

        <FileUpload
          :data="form_info.files"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <!-- end -->

        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>

    <!-- 年月日 组件 -->
    <Popup v-model:show="_DATA.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="_DATA.currentDate"
        type="date"
        title="选择日期"
        :min-date="_DATA.minDate"
        @confirm="changePicker"
        @cancel="_DATA.showTimePicker = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { onMounted, reactive, inject, ref } from 'vue';
import {
  Form,
  Field,
  Notify,
  Loading,
  Overlay,
  Button,
  Popup,
  DatetimePicker,
  NavBar,
} from 'vant';
import { useRouter } from 'vue-router';

import moment from 'moment';

const $http = inject('$http');
const loading = ref(false);
const router = useRouter();

const _DATA = reactive({
  id: router.currentRoute.value.query.id,

  type: router.currentRoute.value.query.type,
  showTimePicker: false,
  currentDate: new Date(),
  minDate: new Date(),
});

const form_info = reactive({
  id: router.currentRoute.value.query.id,
  custom_time: '', //日期
  files: [], //附件 name path
  remark: '', //备注
  req_type: '',
});
const typeList = [
  { type: 429, name: '跟单', url: $http.mainOrder_trackOrder, remark: '' },
  {
    type: 437,
    name: '通知送货',
    url: $http.mainOrder_notifyMeasure,
    req_type: 2,
    remark: '请按照以上时间送货，谢谢！',
  },
  {
    type: 432,
    name: '申请下单',
    url: $http.mainOrder_applyPlaceOrder,
    remark: '',
  },
  {
    type: 435,
    name: '通知测量',
    url: $http.mainOrder_notifyMeasure,
    req_type: 1,
    remark: '请按照以上时间测量，谢谢！',
  },
];

onMounted(() => {
  form_info.remark = typeList.filter(
    (item) => item.type == _DATA.type
  )[0].remark;
});

const getTitle = () => {
  return typeList.filter((item) => item.type == _DATA.type)[0].name;
};

/**
 * 接口获取
 */

/**
 * 操作
 */

//-@日期

const changePicker = (value) => {
  _DATA.showTimePicker = false;
  form_info.custom_time = moment(value).format('YYYY-MM-DD');
};

//-@附件
const getFileList = (file_list) => {
  form_info.files = file_list;
};

const clickFun = () => {
  _DATA.showTimePicker = true;
};
//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    let url = typeList.filter((item) => item.type == _DATA.type)[0].url;

    form_info.req_type =
      typeList.filter((item) => item.type == _DATA.type)[0].req_type || '';

    url({ ...form_info }).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    });
  });
};

//-@ 返回
const onBack = () => {
  router.back();
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
</style>
