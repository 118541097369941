<!--
 * @Author: NanNan
 * @Date: 2023-07-11 16:37:20
 * @LastEditTime: 2023-09-15 10:54:41
 * @Description: v1.4.1 派单信息展示详情
-->
<template>
  <div style="padding: 0 0 6px; margin-top: -16px">
    <Collapse v-model="_DATA.activeName">
      <CollapseItem title="" name="1">
        <template #title>
          <div>
            <em style="font-weight: bold">派单信息</em>
            <span
              style="color: #f37020; font-size: 13px"
              v-if="props?.isDesignCenterManager != 1"
            >
              设计中心确认接单后可见
            </span>
          </div>
        </template>
        <ul class="ul-message">
          <li>
            <em>派单编号：</em>
            <span>{{ ruleForm?.dispatch_no || '-' }}</span>
          </li>
          <li>
            <em>线索编号：</em>
            <span>{{ ruleForm?.clue_no || '-' }}</span>
          </li>
          <li>
            <em>派单时间：</em>
            <span>{{ ruleForm?.dispatch_time || '-' }}</span>
          </li>
          <li>
            <em>派单负责人：</em>
            <span>{{ ruleForm?.responsible_name || '-' }}</span>
          </li>
          <li>
            <em>邀约人：</em>
            <span>{{ ruleForm?.add_uid_name || '-' }}</span>
          </li>
          <li>
            <em>派单备注：</em>
            <span>{{ ruleForm?.dispatch_remark || '-' }}</span>
          </li>
          <Divider style="margin: 10px 0" />
          <li>
            <em>客户姓名：</em>
            <span>{{ ruleForm?.customer_name || '-' }}</span>
          </li>
          <li>
            <em>派单级别：</em>
            <span>{{ ruleForm?.dispatch_level_name || '-' }}</span>
          </li>
          <li>
            <em>预计见面时间：</em>
            <span>
              {{ ruleForm?.face_time || ruleForm?.meeting_time || '-' }}
            </span>
          </li>
          <li>
            <em>见面方式：</em>
            <span>{{ ruleForm?.face_mode_name || '-' }}</span>
          </li>
          <li>
            <em>碰面地点：</em>
            <span>{{ ruleForm?.meet_addr || '-' }}</span>
          </li>
          <li>
            <em>楼盘：</em>
            <span>
              {{
                ruleForm?.is_not_found_floor == 1
                  ? '找不到楼盘'
                  : ruleForm?.floor_name || '-'
              }}
            </span>
          </li>
          <li>
            <em>区域：</em>
            <span>
              {{
                ruleForm?.is_city_unknown == 1
                  ? '未知'
                  : ruleForm?.city_name || '-'
              }}
            </span>
          </li>
          <li>
            <em>详细地址：</em>
            <span>{{ ruleForm?.address || '-' }}</span>
          </li>
          <li>
            <em>产证面积：</em>
            <span v-if="!!ruleForm?.size">
              {{ ruleForm?.size + '㎡' }}
            </span>
            <span v-else>-</span>
          </li>
          <li>
            <em>扩建面积：</em>
            <span v-if="!!ruleForm?.extend_size">
              {{ ruleForm?.extend_size + '㎡' }}
            </span>
            <span v-else>-</span>
          </li>
          <li>
            <em>喜欢风格：</em>
            <span>{{ ruleForm?.like_style || '-' }}</span>
          </li>
          <li>
            <em>对设计师要求：</em>
            <span>{{ ruleForm?.design_need || '-' }}</span>
          </li>
          <li>
            <em>邀约方式：</em>
            <span>{{ ruleForm?.invite_method || '-' }}</span>
          </li>
          <li>
            <em>到访人数：</em>
            <span>{{ ruleForm?.visit_num || '-' }}</span>
          </li>
          <li>
            <em>关注问题：</em>
            <span>{{ ruleForm?.care_problem || '-' }}</span>
          </li>
          <li>
            <em>建议准备：</em>
            <span>{{ ruleForm?.suggested_pre || '-' }}</span>
          </li>
          <li>
            <em>客户情况：</em>
            <span>{{ ruleForm?.customer_situation || '-' }}</span>
          </li>
        </ul>
      </CollapseItem>
    </Collapse>
  </div>
</template>
<script setup>
import { Collapse, CollapseItem, Toast, Divider } from 'vant';
import { reactive, onMounted, defineProps, inject } from 'vue';

const props = defineProps(['data', 'customerObj', 'isDesignCenterManager']);
const $http = inject('$http');
const _DATA = reactive({
  activeName: ['1'],
});

const ruleForm = reactive({});
onMounted(() => {
  loadInfo(props?.customerObj?.customer_id);
});
/**
 * 接口
 */
// -- 详情接口
const loadInfo = (customer_id) => {
  $http.customerManagement_getDispatchDetail({ customer_id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      Object.assign(ruleForm, data);
    } else {
      Toast(data);
    }
  });
};
</script>

<style lang="less" scoped>
.ul-message {
  line-height: 26px;
  li {
    display: flex;
    em {
      flex-grow: 0;
      flex-shrink: 0;
      color: #767676 !important;
    }
    span {
      flex-wrap: wrap;
      color: #767676 !important;
    }
  }
}
:deep(.van-collapse-item__content) {
  padding: 0 14px 0 !important;
}
:deep(.van-collapse-item__title) {
  padding: 10px !important;
  font-size: 15px;
  font-weight: bold;
  background: #f5f5f5;
  border: none !important;
  margin-bottom: 10px;
}
:deep(.van-cell--clickable:active) {
  background: #f5f5f5;
}
:deep([class*='van-hairline']:after) {
  border: none;
}
:deep(.van-cell__title > span) {
  font-size: 15px !important;
  font-weight: bold !important;
}
.warn {
  color: #f37020;
  font-size: 14px;
}
</style>
