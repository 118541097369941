<!--
 * @Author: gest
 * @Date: 2023-09-12 15:51:51
 * @LastEditTime: 2023-10-27 11:16:35
 * @Description: v1.4.5 场地交接单
-->
<template>
  <div class="formBase">
    <div class="content mb_15 white_bg">
      <div class="item cell_border flex_between">
        <div class="flex_column flex1 mr_15">
          <div>是否精装</div>
        </div>
        <div
          :class="
            form.info.is_fine_construction
              ? form.info.is_fine_construction == 1
                ? 'blue_color'
                : 'red_color'
              : ''
          "
        >
          {{
            form.info.is_fine_construction
              ? form.info.is_fine_construction == 1
                ? '是'
                : '否'
              : '-'
          }}
        </div>
      </div>
      <div
        class="item cell_border flex_between"
        v-if="form.info.is_fine_construction == 1"
      >
        <div class="flex_column flex1 mr_15">
          <div>精装是否已按照合同要求进行保护</div>
        </div>
        <div
          :class="
            form.info.fine_protect
              ? form.info.fine_protect == 1
                ? 'blue_color'
                : 'red_color'
              : ''
          "
        >
          {{
            form.info.fine_protect
              ? form.info.fine_protect == 1
                ? '是'
                : '否'
              : '-'
          }}
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">土建检查情况</div>
          <div>{{ form.info?.content || '-' }}</div>
        </div>
      </div>
    </div>
    <!-- 进场时现场财产数量及部位 v-for-->
    <div class="font_size_13 grey_color" style="padding: 0 15px 6px 15px">
      进场时现场财产数量及部位：
    </div>
    <!-- v-for循环 -->
    <div class="content mb_15 white_bg">
      <div
        class="cell_border flex_between"
        v-for="(item, index) in form.info.basic_info"
        :key="index"
      >
        <div class="flex_column flex1 mr_15">
          <div>{{ item.name || '-' }}</div>
        </div>

        <div class="grey_color" v-if="item.name == '燃气表表数'">
          {{ item.num || item.num === 0 ? item.num + 'm³' : '-' }}
        </div>
        <div class="grey_color" v-else>
          {{ item.value ? (item.value == 2 ? '无' : item.num + '个') : '-' }}
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- 原房屋外观质量内容 v-for-->
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.content_info"
      :key="index"
    >
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">{{ item.name || '-' }}</div>
          <div>{{ item.value || '-' }}</div>
        </div>
      </div>
    </div>
    <!-- end -->
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">交接日期</div>
            <div>{{ form.info.hand_over_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>{{ form.info.remark || '-' }}</div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);
const props = defineProps(['data']);
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
