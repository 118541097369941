<!--
 * @Author: NanNan
 * @Date: 2022-10-11 16:22:46
 * @LastEditTime: 2023-11-30 16:44:49
 * @Description: v1.2.1 报备单
 * v1.3.2 日期优化 当日在可选范围默认当日，不在的话，可选范围第一天
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <div class="message-top">
      <p>创建时间：{{ options.create_date || '-' }}</p>
      <p>报备次数：{{ form.node_name ? options.filing_num : '-' }}</p>
      <p>
        报备时间：{{
          form.start_date && form.end_date
            ? form.start_date + ' 至 ' + form.end_date
            : '-'
        }}
      </p>
    </div>

    <Form class="form-box" ref="formRef" @failed="onFailed" @submit="onSubmit">
      <p class="label-name">
        <em>*</em>
        设计阶段
      </p>
      <Field
        v-model="form.type_name"
        readonly
        right-icon="arrow-down"
        placeholder="请选择设计阶段"
        @click="action.type_sort = true"
        :rules="[
          { required: true, trigger: 'onSubmit', message: '请选择设计阶段' },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        设计节点
      </p>
      <Field
        v-model="form.node_name"
        readonly
        right-icon="arrow-down"
        placeholder="请选择设计节点"
        @click="
          () => {
            if (form.type_name !== '') action.node_id = true;
          }
        "
        :rules="[
          { required: true, trigger: 'onSubmit', message: '请选择设计节点' },
        ]"
        :disabled="form.type_name === ''"
      />

      <p class="label-name">
        <em>*</em>
        预计下次见面日期
      </p>
      <Field
        v-model="form.next_date"
        name="picker"
        readonly
        placeholder="请选择见面日期"
        right-icon="underway-o"
        @click="
          () => {
            if (form.node_name !== '') action.picker_show = true;
          }
        "
        :rules="[
          {
            required: true,
            trigger: 'onSubmit',
            message: '请选择见面日期',
          },
        ]"
        :disabled="form.node_name === ''"
      />

      <p class="label-name">
        <em>*</em>
        未见理由
      </p>
      <Field
        v-model="form.remark"
        placeholder="请填写未见理由..."
        rows="3"
        autosize
        type="textarea"
        maxlength="500"
        show-word-limit
        :rules="[
          { required: true, trigger: 'onSubmit', message: '请填写未见理由' },
        ]"
      />

      <div class="stick-box">
        <Button
          type="primary"
          size="large"
          native-type="submit"
          :loading="loadingBtn"
        >
          确定
        </Button>
      </div>
    </Form>

    <!-- 设计阶段 -->
    <Popup v-model:show="action.type_sort" position="bottom">
      <Picker
        :columns="options.type_list"
        :columns-field-names="{ text: 'type_name', value: 'type_sort' }"
        @confirm="(value) => handelChange(value, 'type_name', 'type_sort')"
        @cancel="action.type_sort = false"
        :default-index="
          options.type_list.findIndex((v) => {
            return v.type_sort == form.type_sort;
          })
        "
      />
    </Popup>
    <!-- 设计节点 -->
    <Popup v-model:show="action.node_id" position="bottom">
      <Picker
        :columns="options.node_list"
        :columns-field-names="{ text: 'node_name', value: 'node_id' }"
        @confirm="(value) => handelChange(value, 'node_name', 'node_id')"
        @cancel="action.node_id = false"
        :default-index="
          options.type_list.findIndex((v) => {
            return v.node_id == form.node_id;
          })
        "
      />
    </Popup>
    <!-- 年月日 组件 -->
    <Popup v-model:show="action.picker_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="预计下次见面日期"
        :min-date="action.minDate"
        @confirm="changePicker"
        @cancel="action.picker_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, inject } from 'vue';
import { useRouter } from 'vue-router';

import {
  Form,
  Field,
  Loading,
  Overlay,
  Popup,
  Picker,
  DatetimePicker,
  Button,
  Notify,
  Toast,
} from 'vant';
import moment from 'moment';
const $http = inject('$http');
const router = useRouter();

const loading = ref(false);

const options = reactive({
  create_date: '', //创建日期
  delay_reason: null, //设计工期顺延时间
  filing_num: '', //报备单提交次数
  memorandum_num: '', //备忘录提交次数
  stage_node: '', //阶段+节点参数 联动
  type_list: [], //阶段list 前端虚拟
  node_list: [], //节点list 前端虚拟
});

const form = reactive({
  design_id: router.currentRoute.value.query.did,
  type_sort: null, //阶段id
  type_name: '',
  node_id: null, //节点id
  node_name: '',
  start_date: undefined, //报备开始时间
  end_date: undefined, //报备结束时间
  next_date: undefined, //延期日期 YYYY-MM-DD
  remark: '', //备注500
});

const action = reactive({
  type_sort: false, //设计阶段
  node_id: false, //设计节点

  picker_show: false, //时间组件
  plan_end_time: '', //前端需要，预计结束时间
  minDate: undefined,
});
onMounted(() => {
  loadParams();
});
/**
 * 接口
 */
const loadParams = (node_id) => {
  loading.value = true;
  $http
    .getDesignDelayOrderParams({ design_id: form.design_id, node_id })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        loading.value = false;
        var arr = [];
        for (let i in data.delay_reason) {
          let o = { id: i, name: data.delay_reason[i] };
          arr.push(o);
        }
        data.delay_reason = arr;
        const dataJson = JSON.parse(JSON.stringify(data));
        Object.assign(options, data);
        options.type_list = dataJson.stage_node.filter((item) => {
          return delete item.children;
        });
        options.filing_num = data.filing_num;
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
};
/**
 * 操作
 */
//-时间组件
const currentDate = ref(new Date());

const changePicker = (value) => {
  action.picker_show = false;
  form.next_date = moment(value).format('YYYY-MM-DD');
};

//-天数计算 90个工作日
const getBeforeDate = (datt, day) => {
  if (datt) {
    const defaultDatt = JSON.parse(JSON.stringify(datt.split('-')));
    var newDate = new Date(defaultDatt[0], defaultDatt[1] - 1, defaultDatt[2]);
    var befminuts = newDate.getTime() + 1000 * 60 * 60 * 24 * parseInt(day);
    var beforeDat = new Date();
    beforeDat.setTime(befminuts);
    var befMonth = beforeDat.getMonth() + 1;
    var mon = befMonth >= 10 ? befMonth : '0' + befMonth;
    var befDate = beforeDat.getDate();
    var da = befDate >= 10 ? befDate : '0' + befDate;
    var newDates = beforeDat.getFullYear() + '-' + mon + '-' + da;
    return new Date(newDates);
  }
};

//-pop确认
const handelChange = (value, name, visible_name) => {
  action[visible_name] = false;
  form[name] = value.type_name || value.node_name || value.name;
  form[visible_name] = value.type_sort || value.node_id || value.id;
  if (name === 'type_name') {
    // 设计阶段
    options.node_list = options.stage_node.filter(
      (item) => item.type_sort == value.type_sort
    )[0].children;
    // 更改关联数据
    form.node_id = '';
    form.node_name = '';
    form.start_date = '';
    form.end_date = '';
    form.next_date = '';
  } else if (name === 'node_name') {
    // 设计节点
    form.start_date = value.plan_end_time;
    action.minDate = getBeforeDate(value.plan_end_time, 1);
    currentDate.value = action.minDate || new Date();
    // 更改关联数据
    form.next_date = '';
    // 获取提交次数
    loadParams(value.node_id);
    // 获取报备时间
    $http
      .getDesignEndCycle({
        design_id: form.design_id,
        start_date: value.plan_end_time,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          form.end_date = data.end_day;
        } else {
          Notify({
            type: 'warning',
            message: data,
            duration: 1000,
          });
        }
      });
  }
};

//-提交
const formRef = ref(null);
const loadingBtn = ref(false);
const onSubmit = () => {
  console.log(form);

  formRef.value?.validate().then(() => {
    loadingBtn.value = true;
    $http.getDesignFilingOrder(form).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loadingBtn.value = false;
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loadingBtn.value = false;
          },
        });
      }
    });
  });
};
const onFailed = (errorInfo) => {
  Toast('请完善信息!', errorInfo);
  return false;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/design/TempCommonBill.less';
</style>
