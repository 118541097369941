<!--
 * @Author: Jessica
 * @Date: 2024-01-06 11:17:38
 * @LastEditTime: 2024-01-06 14:02:02
 * @Description: 产品预收款-收款
-->
<template>
  <div>
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="content_warpper">
      <Form class="form-box" ref="formRef" style="padding: 0 0 95px">
        <!-- 内容部分 -->
        <div class="box">
          <p class="label-name">
            <em>*</em>
            预收金额
          </p>
          <Field
            v-model="form_info.amount"
            type="number"
            :formatter="formatter"
            format-trigger="onBlur"
            placeholder="填写预收金额"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请填写预收金额',
              },
            ]"
          >
            <template #button>元</template>
          </Field>

          <p class="label-name">
            <em>*</em>
            收款日期
          </p>
          <Field
            v-model="form_info.collection_time"
            name="picker"
            readonly
            placeholder="选择收款日期"
            right-icon="underway-o"
            @click="picker_show = true"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请选择收款日期',
              },
            ]"
          />

          <p class="label-name">
            <em>*</em>
            收款方式
          </p>
          <Field
            v-model="form_info.collection_method_str"
            readonly
            right-icon="arrow-down"
            placeholder="选择收款方式"
            @click="collect_show = true"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请选择收款方式',
              },
            ]"
          />

          <p class="label-name">收款账号</p>
          <Field
            v-model="form_info.collection_account_str"
            readonly
            right-icon="arrow-down"
            placeholder="选择收款账号"
            @click="collect_account_show = true"
          />

          <p class="label-name">票据编号</p>
          <Field
            v-model="form_info.bill_no"
            maxlength="50"
            placeholder="输入票据编号"
          />

          <p class="label-name">手工收据号</p>
          <Field
            v-model="form_info.handwork_no"
            maxlength="50"
            placeholder="输入手工票据编号"
          />

          <!-- v0.6.0 新增 -->
          <p class="label-name">打款人</p>
          <Field
            v-model="form_info.drawee"
            maxlength="30"
            placeholder="输入打款人"
          />

          <p class="label-name">手续费</p>
          <Field
            v-model="form_info.service_charge"
            type="number"
            :formatter="formatter"
            format-trigger="onBlur"
            placeholder="填写手续费"
          >
            <template #button>元</template>
          </Field>

          <p class="label-name">优惠活动</p>
          <Field
            v-model="form_info.activity_name"
            maxlength="50"
            placeholder="-"
            disabled
          />
          <!-- end -->

          <p class="label-name">备注</p>
          <Field
            v-model="form_info.remarks"
            rows="3"
            autosize
            type="textarea"
            maxlength="1000"
            placeholder="有什么需要补充的吗..."
            show-word-limit
          ></Field>

          <!-- 附件组件 -->
          <p class="label-name">
            <em>*</em>
            附件
            <span class="title_tip">请上传收款凭证</span>
          </p>
          <FileUpload
            :data="form_info.file_urls"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
          <p class="errorTip" v-if="fileTip">请上传收款凭证</p>
          <!-- end -->

          <div class="stick-box">
            <Button type="primary" size="large" @click="onSubmit">确定</Button>
          </div>
        </div>
      </Form>
      <!-- 年月日 组件 -->
      <Popup v-model:show="picker_show" position="bottom">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          title="选择收款日期"
          @confirm="changePicker"
          @cancel="picker_show = false"
        />
      </Popup>
      <!-- 收款方式 -->
      <Popup v-model:show="collect_show" position="bottom">
        <Picker
          :columns="options.cash_type"
          :columns-field-names="{ text: 'name', value: 'id' }"
          @confirm="changeCollect"
          @cancel="collect_show = false"
        />
      </Popup>
      <!-- 收款账号 -->
      <Popup v-model:show="collect_account_show" position="bottom">
        <Picker
          :columns="options.collection_account"
          :columns-field-names="{ text: 'name', value: 'id' }"
          @confirm="changeCollectAccount"
          @cancel="collect_account_show = false"
        />
      </Popup>
    </div>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, onMounted, inject, ref, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import {
  Form,
  Field,
  Button,
  Notify,
  Popup,
  DatetimePicker,
  Picker,
  Overlay,
  Loading,
} from 'vant';
import moment from 'moment';
const $http = inject('$http');
const router = useRouter();
const props = defineProps(['customer_id']);

//-附件必填提示
const fileTip = ref(false);

const options = reactive({
  cash_type: [], //收款方式
  payment_contract_list: [],
  collection_account: [],
});

const form_default = reactive({
  amount: '', //金额
  collection_time: undefined, //收款时间
  collection_method_id: '', //收款方式id
  collection_method_str: '', //收款方式_str
  collection_account_id: '', //收款账号id
  collection_account_str: '', //收款账号_str
  bill_no: '', //票据编号
  handwork_no: '', //手工收据号
  drawee: '', //打款人
  service_charge: '', //手续费
  activity_name: '', //优惠活动str
  activity_id: '', //优惠活动id
  remarks: '', //备注
  file_urls: [], //附件集合
});

const form_info = reactive({});

onMounted(() => {
  Object.assign(form_info, form_default);
  loadFunc();
});

/**
 * 接口调用
 */
const loading = ref(false);
const loadFunc = () => {
  loading.value = true;
  $http
    .getContractParmas({ customer_id: props.customer_id })
    .then((res) => {
      const { data, code } = res;
      loading.value = false;
      if (code === 0) {
        options.cash_type = data.cash_type;
        options.payment_contract_list = data.payment_contract_list;
        options.collection_account = data.collection_account;
        form_info.activity_id = data.activity?.id ?? '';
        form_info.activity_name = data.activity?.name ?? '';
      } else {
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

/**
 * 操作集合
 */

// @收款日期
const picker_show = ref(false);
const currentDate = ref(new Date());
const changePicker = (value) => {
  picker_show.value = false;
  form_info.collection_time = moment(value).format('YYYY-MM-DD');
};

/**
 * 操作集合
 */

// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};
// @收款方式
const collect_show = ref(false);
const changeCollect = (value) => {
  collect_show.value = false;
  form_info.collection_method_id = value.id;
  form_info.collection_method_str = value.name;
};

// @收款账号
const collect_account_show = ref(false);
const changeCollectAccount = (value) => {
  collect_account_show.value = false;
  form_info.collection_account_id = value.id;
  form_info.collection_account_str = value.name;
};

// @附件
const getFileList = (file_list) => {
  form_info.file_urls = file_list;
  fileTip.value = false;
};

// @提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value
    ?.validate()
    .then(() => {
      if (form_info.file_urls.length == 0) {
        fileTip.value = true;
        Notify({ type: 'warning', message: '请先完善信息' });
      } else {
        loading.value = true;
        // 格式变化
        const parmars = Object.assign({}, form_info);
        $http
          .getProductCollectionApi({
            ...parmars,
            customer_id: props.customer_id,
          })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
                onClose: () => {
                  loading.value = false;
                  router.push({
                    path: '/sale',
                    query: { id: props.customer_id },
                  });
                },
              });
            } else {
              loading.value = false;
              Notify({ type: 'warning', message: data });
            }
          })
          .catch(() => {
            loading.value = false;
          });
      }
    })
    .catch(() => {
      //验证失败
      Notify({ type: 'warning', message: '请先完善信息' });
      if (form_info.file_urls.length == 0) {
        fileTip.value = true;
      }
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/outCollection.less';
</style>
