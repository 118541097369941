<!--
 * @Author: Jessica
 * @Date: 2024-06-07 14:47:58
 * @LastEditTime: 2024-06-11 17:55:11
 * @Description: v1.6.1 施工 - 查看施工成员
-->
<template>
  <Popup
    v-model:show="showOverlay"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="wrapper" v-if="showOverlay" @click.stop>
      <NavBar
        left-arrow
        title="查看施工成员"
        @click-left="onCloseOverLay"
        :fixed="true"
      ></NavBar>
      <div class="page-box formBase bg_fa">
        <Overlay :show="loading" class="overlay-fff">
          <Loading color="#1989fa" type="spinner"></Loading>
        </Overlay>
        <div class="bg_fa">
          <!-- 基础信息 -->
          <div class="base_info">
            <SiteNodeDateLine :detail="detail" />
          </div>
          <!-- 施工团队 -->
          <div class="team_work">
            <SiteMembersList
              :work_members="detail.work_members"
              :other_work_members="detail.other_work_members"
            />
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<script setup>
import { ref, inject, watch, defineProps, defineEmits } from 'vue';
import { Overlay, Loading, Notify, Popup, NavBar } from 'vant';
import SiteNodeDateLine from '@/components/construction/SiteNodeDateLine'; //节点信息
import SiteMembersList from '@/components/construction/SiteMembersList'; //施工团队
const props = defineProps(['isShow', 'data']);
const emit = defineEmits(['closePop']);
const $http = inject('$http');

const loading = ref(false);
//-是否展示
const showOverlay = ref(false);
//-详情
const detail = ref({});

/**
 * 初始化
 */
watch(
  () => props.isShow,
  (cur) => {
    showOverlay.value = cur ? cur : false;
    if (cur) {
      loading.value = false;
      loadFunc();
    }
  }
);

const loadFunc = () => {
  loading.value = true;
  $http
    .construction_nodeInfo({ node_id: props.data?.node_id })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        detail.value = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    })
    .then(() => {
      loading.value = false;
    });
};

const onCloseOverLay = () => {
  emit('closePop');
};
</script>
<style lang="less" scoped>
.bg_fa {
  background: #fafafa;
}

.page-box {
  padding: 0;
  height: 100%;
  padding-top: 12.3vw;
  min-height: 100vh;

  //-施工团队
  .team_work {
    width: 100%;
  }
}
</style>
