<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2023-10-18 18:09:02
 * @Description: 合同签约 - 列表
 * v1.1.0 合同卡片样式修改
-->
<template>
  <div
    class="page-box-sign"
    :class="{ pt20: _DATA.router_path === '/contractSign' }"
  >
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <!-- 任务卡片 (未开工)-->
      <List
        v-model:loading="loading"
        :finished="finished"
        :offset="1"
        @load="loadFunc"
      >
        <div
          class="task-card-same"
          v-for="(item, index) in list_data"
          :key="index"
        >
          <!-- 状态栏 1待发起 2 待签约 3已签约 4作废-->
          <p class="h1" v-for="(val, idx) in getList(item.status)" :key="idx">
            <label :class="val.class">
              <img :src="val.url" alt="" />
              {{ item.status_name }}
            </label>
          </p>
          <!-- 相关事项 -->
          <div class="do-box">
            <p class="title">{{ item.name }}</p>
            <ol class="ol-flex">
              <li>
                <label>合同编号:</label>
                <span>{{ item.contractNo }}</span>
              </li>
              <li>
                <label>签订日期:</label>
                <span>{{ item.signed_up_time || '-' }}</span>
              </li>
            </ol>
          </div>
          <!-- 合同金额行管 -->
          <div class="money-box">
            <dl>
              <dt>
                {{
                  item.type == 53 || item.type == 69
                    ? '剩余可用金额'
                    : '合同金额'
                }}
              </dt>
              <dd>
                <em>¥</em>
                {{
                  item.type == 53 || item.type == 69
                    ? item.contract_balance
                    : item.contract_all_money
                }}
              </dd>
            </dl>
            <div class="pro-box">
              <p
                v-if="item.received_money > 0"
                :style="{
                  width:
                    (item.received_money / item.contract_all_money) * 100 + '%',
                }"
              >
                <span><Icon name="success" /></span>
              </p>
            </div>
            <dl>
              <dt>
                <p class="n">
                  {{
                    item.type == 53 || item.type == 69
                      ? '累计产品已收'
                      : '合同款已收'
                  }}
                </p>
                <p class="m" v-if="item.type == 53 || item.type == 69">
                  {{ '¥' + item.contract_all_money }}
                </p>
                <p class="m" v-else>{{ '¥' + item.received_money }}</p>
              </dt>
              <dd>
                <p class="n">
                  {{
                    item.type == 53 || item.type == 69
                      ? '累计产品占款金额'
                      : '合同款未收'
                  }}
                </p>
                <p class="m" v-if="item.type == 53 || item.type == 69">
                  {{ '¥' + item.received_money }}
                </p>
                <p class="m" v-else>
                  {{ '¥' + item.contract_balance }}
                </p>
              </dd>
            </dl>
          </div>
          <!-- 操作按钮 -->
          <div class="option-box">
            <Row>
              <Col span="24">
                <Button
                  :to="
                    '/contractDetail?cid=' +
                    item.id +
                    '&id=' +
                    _DATA.customer_id
                  "
                  type="primary"
                  size="large"
                  class="button-flex"
                >
                  <span>合同详情</span>
                </Button>
              </Col>
              <Col span="24" v-if="item.invalid_flag > 0 && item.status > 2">
                <p
                  class="button-fail"
                  @click="
                    () =>
                      router.push({
                        path: '/Ccancellation',
                        query: {
                          id: router.currentRoute.value.query.id,
                          cdid: item.id,
                        },
                      })
                  "
                >
                  关联作废合同
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <template #finished>
          <Divider style="margin-top: 40px" v-if="list_data?.length > 0">
            我也是有底线的
          </Divider>
        </template>
      </List>
    </PullRefresh>
    <!-- 空数据 -->
    <div class="empty-img-box" v-if="!loading && list_data?.length == 0">
      <img :src="require('@/assets/images/empty-img2.png')" alt="" />
      <p>暂无签约合同</p>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import {
  Button,
  Icon,
  Row,
  Col,
  Notify,
  List,
  PullRefresh,
  Divider,
  Overlay,
  Loading,
} from 'vant';
const $http = inject('$http');
const router = useRouter();
// const $host = inject('$host');
const _DATA = reactive({
  router_path: router.currentRoute.value.path,
  customer_id: router.currentRoute.value.query.id,
  status_list: ['全部', '待签约', '已签约', '作废'],
});

const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const list_data = ref([]);
const page = reactive({
  page: 1,
  pages: 10,
  total: 0,
  count_page: 1,
});
// status 1待发起 2 待签约 3已签约 4作废
const Icons = ref([
  {
    status: 1,
    url: require('@/assets/images/icon-wait.png'),
    class: 'yellow',
  },
  {
    status: 2,
    url: require('@/assets/images/icon-wait-blue.png'),
    class: 'blue',
  },
  {
    status: 3,
    url: require('@/assets/images/icon-pass.png'),
    class: 'green',
  },
  {
    status: 4,
    url: require('@/assets/images/icon-delete.png'),
    class: 'gray',
  },
]);
onMounted(() => {});

/**
 * 接口调用
 */
//@合同签约 列表
const loadFunc = () => {
  loading.value = true;
  $http
    .getContractListApi({
      customer_id: _DATA.customer_id,
      page: page.page,
      pages: page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        if (refreshing.value) {
          // 下拉刷新
          list_data.value = [];
          finished.value = false;
        }
        // 滚动加载
        list_data.value = list_data.value.concat(data.list);
        Object.assign(page, { ...data.page });
        if (list_data.value.length < data.page.total) {
          page.page++;
        } else {
          finished.value = true;
        }
        refreshing.value = false;
      } else {
        refreshing.value = false;
        finished.value = true;
        Notify({ type: 'warning', message: data });
      }
    });
};

/**
 * 操作
 */
//-@ 下拉刷新
const onRefresh = () => {
  page.page = 1;
  refreshing.value = true;
  loadFunc();
};
//-@ 获取当前iconList
const getList = (status) => {
  const list = Icons.value.filter((val) => val.status == status);
  return list;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/contractSign.less';
</style>
