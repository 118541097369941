<!--
 * @Author: NanNan
 * @Date: 2024-05-14 10:24:10
 * @LastEditTime: 2024-05-29 14:22:26
 * @Description: v1.6.0新版本 获客-线索：线索质量
-->
<template>
  <div class="data_item extend chart_jd" style="padding-bottom: 0px">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、头部 -->
        <div class="data_card_top border_bottom">
          <div class="title">线索质量</div>
          <Row style="margin-bottom: 16px">
            <Col span="8">
              <div class="grey align_center">线索数</div>
              <div>
                <span class="size17 bold align_center">
                  {{
                    _DATA.total?.clue_num === '' ? '-' : _DATA.total?.clue_num
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">见面数</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.total?.meet_num === '' ? '-' : _DATA.total?.meet_num
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">协议数</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.total?.sign_num === '' ? '-' : _DATA.total?.sign_num
                  }}
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span="8">
              <div class="grey align_center">见面率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.total?.meet_rate === ''
                      ? '-'
                      : _DATA.total?.meet_rate + '%'
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">成功率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.total?.clue_rate === ''
                      ? '-'
                      : _DATA.total?.clue_rate + '%'
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">线-签率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.total?.sign_rate === ''
                      ? '-'
                      : _DATA.total?.sign_rate + '%'
                  }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 三、内容 -->
        <div class="data_card_table_bottom p0">
          <!-- 1标题 -->
          <Row
            class="mb_15 p_l15_r10"
            style="align-items: center; height: 100%"
          >
            <Col span="6">
              <div class="grey pr_10">渠道</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">线索数</div>
            </Col>
            <Col span="4">
              <div class="grey align_center">见面数</div>
              <div class="grey align_center">见面率</div>
            </Col>
            <Col span="4">
              <div class="grey align_center">协议数</div>
              <div class="grey align_center">成功率</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">线-签率</div>
            </Col>
          </Row>
          <!-- list递归引入 -->

          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(item, index) in _DATA.list"
            :key="index"
          >
            <div class="sub_item3">
              <Row
                class="p_l15_r10"
                @click.stop="toggleAccordion(index)"
                style="align-items: center; height: 100%"
              >
                <Col span="6">
                  <div
                    class="size13"
                    style="display: flex; align-items: center"
                  >
                    <template v-if="item?.list?.length > 0">
                      <Icon
                        name="play"
                        :style="
                          item?.expanded
                            ? 'transform: rotate(90deg)'
                            : 'transform: rotate(0deg)'
                        "
                        class="arrow"
                      />
                    </template>
                    <p class="size15 bold">
                      {{ index === 0 ? '集团' : '市场' }}
                    </p>
                  </div>
                </Col>
                <!-- 线索数 -->
                <Col span="5">
                  <div>
                    <span class="align_center">
                      {{ item.clue_num === '' ? '-' : item.clue_num }}
                    </span>
                  </div>
                </Col>
                <!-- 见面数/见面率 -->
                <Col span="4">
                  <div>
                    <span class="align_center">
                      {{ item.meet_num === '' ? '-' : item.meet_num }}
                    </span>
                  </div>
                  <div>
                    <span class="grey align_center">
                      {{ item.meet_rate === '' ? '-' : item.meet_rate + '%' }}
                    </span>
                  </div>
                </Col>
                <!-- 协议数/成功率 -->
                <Col span="4">
                  <div>
                    <span class="align_center">
                      {{ item.sign_num === '' ? '-' : item.sign_num }}
                    </span>
                  </div>
                  <div>
                    <span class="grey align_center">
                      {{ item.sign_rate === '' ? '-' : item.sign_rate + '%' }}
                    </span>
                  </div>
                </Col>
                <!-- 线-签率 -->
                <Col span="5">
                  <div>
                    <span class="grey align_center">
                      {{
                        item.clue_sign_rate === ''
                          ? '-'
                          : item.clue_sign_rate + '%'
                      }}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <Transition name="thred-fade">
              <TempChannelQuality
                v-if="item.list && item.expanded"
                :data="item"
                :index="index"
              />
            </Transition>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import { Icon, Notify, Skeleton, Row, Col } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import TempChannelQuality from '@/components/targetData/thredData/collapseItem/TempChannelQuality';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  total: {},
  list: [],
  index: props.data.depId ? 2 : 7, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 2, //部门数据页面所处位置
  open: false,
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);

onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载
const onLoad = () => {
  let url = $http.targetStatistic_channelSourceQuality;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      console.log(data.total, [data.market, data.group]);
      _DATA.list = [data.group, data.market];
      _DATA.total = data.total;
      console.log('????', [data.market, data.group]);
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

//展开收起
const toggleAccordion = (subIndex) => {
  if (!_DATA.list[subIndex]?.list) {
    return;
  } else {
    // 切换当前点击子项的展开状态
    _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
