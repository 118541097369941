<!--
 * @Author: NanNan
 * @Date: 2022-05-20 15:22:10
 * @LastEditTime: 2023-12-07 09:59:53
 * @Description: v0.3.0 客户改版 - 详情页(改动人员请写好描述，以便追溯)
 * v1.2.0 新增线索模块
 * v1.4.1 smart 删除线索模块、首次联系记录、装修需求
 * v1.4.7 smart 新增派单信息模块、修改补充信息模块、服务人员、项目信息
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->

    <Tabs
      offset-top="12.3vw"
      v-model:active="tabIndex"
      scrollspy
      sticky
      title-active-color="#000000"
      title-inactive-color="#8d9091"
      :swipe-threshold="4"
      line-width="60px"
      @click-tab="changeTab"
      v-if="!_DATA.loading"
    >
      <Tab title="客户信息" :name="1">
        <!-- 敏感信息 -->
        <template #default>
          <div class="basis-box">
            <div
              class="content-box"
              v-if="info?.info_base?.sensitive_flag == 1"
            >
              <div class="same-title">
                <span>客户信息</span>
                <router-link
                  class="linker-a"
                  v-if="_DATA.hide_info_edit"
                  :to="'/sale/saleSerectHide?id=' + _DATA.customer_id"
                >
                  <svg class="icon-small2" aria-hidden="true">
                    <use xlink:href="#icon-a-edit-line1"></use>
                  </svg>
                  编辑
                </router-link>
              </div>
              <ul>
                <li v-if="info.hide_info.customer_name_auth">
                  <label>客户姓名</label>
                  <span>{{ info.hide_info.customer_name || '-' }}</span>
                </li>
                <li v-if="info.hide_info.customer_name_auth">
                  <label>性别</label>
                  <span>{{ info.hide_info.gender_name || '-' }}</span>
                </li>
                <li v-if="info.hide_info.phone1_auth">
                  <label>手机号1</label>
                  <span>{{ info.hide_info.phone1 || '-' }}</span>
                </li>
                <li v-if="info.hide_info.phone1_auth">
                  <label>手机号2</label>
                  <span>{{ info.hide_info.phone2 || '-' }}</span>
                </li>
                <li v-if="info.hide_info.phone1_auth">
                  <label>微信号</label>
                  <span>{{ info.hide_info.wechat || '-' }}</span>
                </li>
                <li v-if="info.hide_info.floor_auth">
                  <label>楼盘</label>
                  <span>{{ info.hide_info.floor_name || '-' }}</span>
                </li>
                <li v-if="info.hide_info.house_auth">
                  <label>单元栋号</label>
                  <span>{{ info.hide_info.floor_detail_name || '-' }}</span>
                </li>
                <li v-if="info.hide_info.floor_auth">
                  <label>区域位置</label>
                  <span>{{ info.hide_info.city_name || '-' }}</span>
                </li>
                <li v-if="info.hide_info.house_auth">
                  <label>详细地址</label>
                  <span>{{ info.hide_info.address || '-' }}</span>
                </li>
                <li v-if="info.hide_info.house_auth">
                  <label>房屋面积</label>
                  <span>
                    {{ info.hide_info.size ? info.hide_info.size + 'm²' : '-' }}
                  </span>
                </li>
                <li v-if="info.hide_info.house_auth">
                  <label>设计面积</label>
                  <span>
                    {{
                      info.hide_info.design_size
                        ? info.hide_info.design_size + 'm²'
                        : '-'
                    }}
                  </span>
                </li>
                <li v-if="info.hide_info.house_auth">
                  <label>施工面积</label>
                  <span>
                    {{
                      info.hide_info.construction_size
                        ? info.hide_info.construction_size + 'm²'
                        : '-'
                    }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </Tab>
      <Tab title="项目信息" :name="2">
        <template #default>
          <div class="basis-box">
            <div class="content-box" v-if="info?.operat?.customer_show == 1">
              <p class="same-title">
                <span>项目基础信息</span>
              </p>
              <ul>
                <li>
                  <label>BPM编码</label>
                  <span>{{ info.info_base.bpm_id || '-' }}</span>
                </li>
                <li>
                  <label>精装/毛坯</label>
                  <span>{{ info.info_base.house_category_name || '-' }}</span>
                </li>
                <li>
                  <label>购房情况</label>
                  <span v-if="info.info_base.house_type_name">
                    {{ info.info_base.house_type_name || '-' }}
                  </span>
                </li>
                <li
                  v-if="
                    ['期房', '半年内期房'].indexOf(
                      info.info_base?.house_type_name
                    ) > -1
                  "
                >
                  <label>预计交房时间</label>
                  <span>
                    {{
                      info.info_base.is_time_unknown == 1
                        ? '未知'
                        : info.info_base.plan_delivery_day
                    }}
                  </span>
                </li>

                <li
                  v-if="
                    ['未购房'].indexOf(info.info_base?.house_type_name) > -1
                  "
                >
                  <label>预计购房时间</label>
                  <span>
                    {{
                      info.info_base.is_time_unknown == 1
                        ? '未知'
                        : info.info_base.plan_buy_day
                    }}
                  </span>
                </li>

                <li>
                  <label>房屋结构</label>
                  <span>{{ info.info_base.home_type_name || '-' }}</span>
                </li>
                <!-- <li>
                <label>一级部门</label>
                <span>{{ info.info_base.company_name || '-' }}</span>
              </li> -->

                <li>
                  <label>备注</label>
                  <span>{{ info.info_base.remark || '-' }}</span>
                </li>
                <li>
                  <label>附件</label>
                  <span
                    class="arrow-item between"
                    @click="lookFile(info.info_base?.files)"
                    v-if="info.info_base?.files?.length > 0"
                  >
                    <a style="color: #3577c7">
                      全部{{ info.info_base?.files?.length }}个附件
                    </a>
                  </span>
                  <span v-else>-</span>
                </li>
              </ul>
              <p class="same-title" style="padding-top: 15px">
                <span>来源信息</span>
              </p>
              <ul>
                <li>
                  <label>渠道来源</label>
                  <span>{{ info.info_base.customer_type_name || '-' }}</span>
                </li>
                <li>
                  <label>推荐人</label>
                  <span>{{ info.info_base.recommander || '-' }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </Tab>
      <Tab title="服务人员" :name="3">
        <!-- V1.4.8紧急需求 插入设计中心、交付中心，调整顺序 -->
        <template #default>
          <div class="basis-box">
            <div class="content-box" v-if="info?.operat?.customer_show == 1">
              <p class="same-title">
                <span>服务人员</span>
              </p>
              <ol>
                <template
                  v-for="(item, index) in info.info_contact.service_memebers"
                  :key="index"
                >
                  <li v-if="[0].indexOf(item.user_type) > -1">
                    <label>{{ item.department }}</label>
                    <div v-if="item.name && item.department">
                      <p>{{ item.name }}</p>
                      <p class="text">
                        [{{ item.department + '/' + item.phone }}]
                      </p>
                    </div>
                    <div v-else>-</div>
                  </li>
                </template>
                <li>
                  <label>设计中心</label>
                  <div v-if="info.info_contact.design_center_name">
                    <p>{{ info.info_contact.design_center_name }}</p>
                  </div>
                  <div v-else>-</div>
                </li>
                <template
                  v-for="(item, index) in info.info_contact.service_memebers"
                  :key="index"
                >
                  <li v-if="[1, 2, 3, 4, 5].indexOf(item.user_type) > -1">
                    <label>{{ item.department }}</label>
                    <div v-if="item.name && item.department">
                      <p>{{ item.name }}</p>
                      <p class="text">
                        [{{ item.department + '/' + item.phone }}]
                      </p>
                    </div>
                    <div v-else>-</div>
                  </li>
                </template>
                <li>
                  <label>交付中心</label>
                  <div v-if="info.info_contact.deliver_center_name">
                    <p>{{ info.info_contact.deliver_center_name }}</p>
                  </div>
                  <div v-else>-</div>
                </li>
                <template
                  v-for="(item, index) in info.info_contact.service_memebers"
                  :key="index"
                >
                  <li v-if="[6].indexOf(item.user_type) > -1">
                    <label>{{ item.department }}</label>
                    <div v-if="item.name && item.department">
                      <p>{{ item.name }}</p>
                      <p class="text">
                        [{{ item.department + '/' + item.phone }}]
                      </p>
                    </div>
                    <div v-else>-</div>
                  </li>
                </template>
              </ol>
            </div>
          </div>
        </template>
      </Tab>
      <Tab
        title="分摊业绩"
        :name="6"
        v-if="info?.info_base?.split_info?.add_time"
      >
        <template #default>
          <div class="basis-box">
            <div class="content-box">
              <p class="same-title">
                <span>分摊业绩</span>
              </p>
              <ol>
                <li>
                  <label>提交人</label>
                  <div>
                    <p>{{ info?.info_base?.split_info?.add_uname || '-' }}</p>
                  </div>
                </li>
                <li>
                  <label>提交时间</label>
                  <div>
                    <p>{{ info?.info_base?.split_info?.add_time || '-' }}</p>
                  </div>
                </li>
                <li>
                  <label>审批通过时间</label>
                  <div>
                    <p>
                      {{ info?.info_base?.split_info?.approve_time || '-' }}
                    </p>
                  </div>
                </li>
                <li>
                  <label>分摊部门</label>
                  <div>
                    <p>
                      {{
                        info?.info_base?.split_info?.split_department_name ||
                        '-'
                      }}
                    </p>
                  </div>
                </li>
                <li>
                  <label>协议</label>
                  <div>
                    <p>
                      {{ info?.info_base?.split_info?.protocol_val || '-' }}
                    </p>
                  </div>
                </li>
                <li>
                  <label>产品业绩</label>
                  <div>
                    <p>{{ info?.info_base?.split_info?.product_val || '-' }}</p>
                  </div>
                </li>
                <li>
                  <label>施工业绩</label>
                  <div>
                    <p>
                      {{ info?.info_base?.split_info?.construction_val || '-' }}
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </template>
      </Tab>
      <Tab title="派单信息" :name="4">
        <template #default>
          <div class="basis-box">
            <div class="content-box" v-if="info?.operat?.customer_show == 1">
              <p class="same-title">
                <span>派单信息</span>
              </p>
              <ul>
                <li>
                  <label>客户姓名</label>
                  <span>{{ info.info_base?.name || '-' }}</span>
                </li>
                <li>
                  <label>派单级别</label>
                  <span>
                    {{ info.info_base?.dispatch_level_name || '-' }}
                  </span>
                </li>
              </ul>
              <div class="look-more-text">
                <span @click="sendFunc()">查看全部</span>
              </div>
            </div>
          </div>
        </template>
      </Tab>
      <Tab title="补充信息" :name="5">
        <template #default>
          <div class="basis-box">
            <div class="content-box" v-if="info?.operat?.customer_show == 1">
              <!-- 模块1: 合同基础信息 -->
              <p class="same-title">
                <span>合同基础信息</span>
                <router-link
                  class="linker-a"
                  v-if="info.operat.customer_edit"
                  :to="'/sale/addMore?id=' + _DATA.customer_id"
                >
                  <svg class="icon-small2" aria-hidden="true">
                    <use xlink:href="#icon-a-edit-line1"></use>
                  </svg>
                  编辑
                </router-link>
              </p>
              <ul v-if="info.flag_other">
                <li>
                  <label>联系地址</label>
                  <span>{{ info.info_other.address || '-' }}</span>
                </li>
                <li>
                  <label>电子邮箱</label>
                  <span>{{ info.info_other.email || '-' }}</span>
                </li>
                <li>
                  <label>QQ号</label>
                  <span>{{ info.info_other.qq || '-' }}</span>
                </li>
                <li>
                  <label>收货地址</label>
                  <span>{{ info.info_other.delivery_address || '-' }}</span>
                </li>
              </ul>
              <div class="empty-box" v-if="!info.flag_other">—</div>
              <!-- 模块2: 执行人信息 -->
              <p class="same-title mt20">
                <span>执行人信息</span>
                <router-link
                  class="linker-a"
                  v-if="info.operat.customer_edit"
                  :to="'/sale/addExecutor?id=' + _DATA.customer_id"
                >
                  <svg class="icon-small2" aria-hidden="true">
                    <use xlink:href="#icon-a-edit-line1"></use>
                  </svg>
                  编辑
                </router-link>
              </p>
              <ul v-if="info.flag_exector">
                <li>
                  <label>姓名</label>
                  <span>{{ info.info_exector.operator_name || '-' }}</span>
                </li>
                <li>
                  <label>手机号</label>
                  <span>{{ info.info_exector.operator_phone || '-' }}</span>
                </li>
                <li>
                  <label>联系地址</label>
                  <span>{{ info.info_exector.operator_address || '-' }}</span>
                </li>
                <li>
                  <label>电子邮箱</label>
                  <span>{{ info.info_exector.operator_email || '-' }}</span>
                </li>

                <li>
                  <label>微信号</label>
                  <span>{{ info.info_exector.operator_wechat || '-' }}</span>
                </li>
                <li>
                  <label>QQ号</label>
                  <span>{{ info.info_exector.operator_qq || '-' }}</span>
                </li>
                <li>
                  <label>收货地址</label>
                  <span>
                    {{ info.info_exector.operator_delivery_address || '-' }}
                  </span>
                </li>
              </ul>
              <!-- 空数据 -->
              <div class="empty-box" v-if="!info.flag_exector">—</div>
            </div>
          </div>
        </template>
      </Tab>
    </Tabs>
    <!-- v1.6.0 查看派单信息 -->
    <ActionSheet
      class="actionSheet"
      v-model:show="_DATA.send_show"
      title="派单信息"
    >
      <TempSendFormLookSale
        :customerObj="_DATA?.customerObj"
        v-if="_DATA.send_show"
      />
    </ActionSheet>
    <!-- 全部附件 -->
    <FileAll
      ref="fileAll"
      :data="_DATA.files"
      :isShow="_DATA.showAllFile"
      @closePop="
        () => {
          _DATA.showAllFile = false;
        }
      "
    ></FileAll>
  </div>
</template>
<script setup>
import { onMounted, reactive, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Overlay, Loading, Notify, Tabs, Tab, ActionSheet } from 'vant';

import TempSendFormLookSale from '@/components/sale/TempSendFormLookSale'; //v1.6.0 派单信息 展示
import FileAll from '@/components/common/FileAll'; // 附件查看
const $http = inject('$http');
const router = useRouter();
// const $host = inject('$host');

const _DATA = reactive({
  customer_id: router.currentRoute.value.query.id,
  empty_status: true,
  more_status: false,
  cus_show: false,
  loading: false,
  hide_info_edit: false, //敏感信息是否有编辑权限
  // 用于派单信息
  send_show: false,
  customerObj: {
    customer_id: '',
    customer_name: '',
  },
  // 附件
  showAllFile: false, //是否显示附件列表
  files: '', //查看附件 公共参数
});

onMounted(() => {
  lookHideInfo();
  totalFunc();
  document.querySelector('body').setAttribute('style', 'overflow: hidden;');
});
/**
 * 获取接口
 */
//获取变量值
const info = reactive({
  info_base: {},
  info_furnish: {},
  info_other: {},
  info_exector: {},
  info_contact: {},
  hide_info: {}, //隐蔽信息

  operat: {
    customer_show: 1, //销售查看权限 0无 1有
    customer_edit: 0, //销售编辑权限 0无 1有
    contract_show: 0, //合同查看权限 0无 1有
    design_show: 0, //设计查看权限 0无 1有
    construction_show: 0, //工地查看权限 0无 1有
    contract_flag: 0, //是否有创建合同 0无 1有
  },

  flag_other: false, // 补充客户信息 是否为空
  flag_exector: false, // 执行人信息 是否为空
});
const loadFunc = (id) => {
  return {
    //@基本信息
    getBase: async () => {
      return await $http.getSaleInfoBaseApi({ id });
    },
    //@装修需求
    getFurnish: async () => {
      return await $http.getSaleInfoFurnishApi({ id });
    },
    //@补充信息
    getOther: async () => {
      return await $http.getSaleInfoOtherApi({ id });
    },
    //@执行人信息
    getExector: async () => {
      return await $http.getSaleInfoExectorApi({ id });
    },
    //@首次联系记录
    getContact: async () => {
      return await $http.getSaleInfoContactApi({ id });
    },
    //@v0.5.0获取首页客户 权限参数
    getOperation: async () => {
      return await $http.getOperationHomeAuthorityApi({
        customer_id: id,
      });
    },
  };
};
const { getBase, getFurnish, getOther, getExector, getContact, getOperation } =
  loadFunc(_DATA.customer_id);

const totalFunc = () => {
  _DATA.loading = true;
  Promise.all([
    getBase(),
    getFurnish(),
    getOther(),
    getExector(),
    getContact(),
    getOperation(),
  ])
    .then((res) => {
      info.info_base = res[0].code === 0 ? res[0].data : {};
      info.info_furnish = res[1].code === 0 ? res[1].data : {};
      info.info_other = res[2].code === 0 ? res[2].data : {};
      info.info_exector = res[3].code === 0 ? res[3].data : {};
      info.info_contact = res[4].code === 0 ? res[4].data : {};
      info.operat = res[5].code === 0 ? res[5].data : {};
      _DATA.loading = false;
      localStorage.setItem('customName', info.info_base.name);
    })
    .then(() => {
      _DATA.loading = false;
      info.flag_other = judgeEmpty(info.info_other);
      info.flag_exector = judgeEmpty(info.info_exector);
    })
    .catch((err) => {
      _DATA.loading = false;
      throw new Error('报错终止', err);
    });
};

/**
 * 操作
 */
//-@ 图片预览
// const previewImage = (arr) => {
//   ImagePreview({
//     images: arr.map((v) => $host + v.path), // 预览图片的那个数组
//   });
// };
//点击查看全部附件
// const lookFile = () => {
//   _DATA.showAllFile = true;
// };
//-@ 判断信息展示是否为空
const judgeEmpty = (info) => {
  const Info = JSON.parse(JSON.stringify(info));
  delete Info.id;
  for (let key in Info) {
    if (Info[key]) {
      return true;
    }
  }
  return false;
};
//-@ 查看敏感信息
const lookHideInfo = () => {
  $http
    .getSaleSensitiveFieldInfoApi({ customer_id: _DATA.customer_id })
    .then((res) => {
      if (res.code === 0) {
        const {
          phone1_auth,
          customer_name_auth,
          floor_auth,
          house_auth,
          phone1_auth_edit,
          customer_name_auth_edit,
          floor_auth_edit,
          house_auth_edit,
        } = res.data;
        if (
          customer_name_auth == 1 ||
          phone1_auth == 1 ||
          floor_auth == 1 ||
          house_auth == 1
        ) {
          info.hide_info = res.data;
          //-判断是否有编辑按钮
          if (
            phone1_auth_edit == 0 &&
            customer_name_auth_edit == 0 &&
            floor_auth_edit == 0 &&
            house_auth_edit == 0
          ) {
            _DATA.hide_info_edit = false;
          } else {
            _DATA.hide_info_edit = true;
          }
        }
      } else {
        Notify({
          type: 'warning',
          message: res.message || res.data,
        });
      }
    });
};

// -@v1.4.7 派单信息查看
const sendFunc = () => {
  _DATA.send_show = true;
  _DATA.customerObj.customer_id = router.currentRoute.value.query.id;
  _DATA.customerObj.customer_name = info.info_dispatch?.customer_name;
};

// -@v1.4.7 点击查看全部附件
const lookFile = (files) => {
  _DATA.files = files;
  _DATA.showAllFile = true;
};

// -@v1.4.7 修复原始问题，tab点击失效
const tabIndex = ref(1);
const changeTab = (val) => {
  tabIndex.value = val.name;
  return false;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/saleDetail.less';

:deep(.van-tabs__line) {
  display: block;
  width: 18%;
  background: #0256ff;
}
#app {
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
