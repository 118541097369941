<!--
 * @Author: gest
 * @Date:  2022-11-01 15:49:53
 * @LastEditTime: 2024-06-14 11:01:56
 * @Description: 流程验收模板
-->
<template>
  <!-- 流程 begin -->
  <div class="linker_view mb_15">
    <h3>流程</h3>
    <div class="h2">
      <div
        class="item_box"
        v-for="(item, index) in props.data.check_info"
        :key="index"
      >
        <div class="clearfix">
          <div class="pull-left iconBox">
            <span class="close_text" v-if="item.user_status == 2">
              {{ item.user_dep_name == '供应商' ? '禁用' : '离职' }}
            </span>
            <Image
              v-else
              round
              class="icon"
              :src="
                item.user_face_image
                  ? item.user_face_image.includes('http')
                    ? item.user_face_image
                    : $host + item.user_face_image
                  : require('@/assets/images/icon-empty-img.png')
              "
            />
            <svg
              class="view-icon"
              :class="'status' + item.status"
              aria-hidden="true"
            >
              <use
                :xlink:href="
                  item.status == 1
                    ? '#icon-indeterminate-circle-fill'
                    : item.status == 2
                    ? '#icon-Group'
                    : item.status == 3
                    ? '#icon-checkbox-circle-fill'
                    : '#icon-close-circle-fill'
                "
              ></use>
            </svg>
          </div>

          <div class="pull-left infoBox">
            <p class="name">
              {{ item.user_name }}
              <span class="tag" :class="item.user_type == 2 ? 'green' : 'blue'">
                {{ item.user_type_name }}
              </span>
            </p>
            <p class="extra" v-if="item.user_dep_name">
              {{
                item.user_post_name
                  ? item.user_dep_name + '/' + item.user_post_name
                  : item.user_dep_name
              }}
            </p>
            <div class="flex_between">
              <p class="status" :class="'status' + item.status">
                {{ item.status_name }}
              </p>
              <p
                class="edit_log"
                v-if="item.form_version && item.user_type == 1"
                @click.stop="getLogFun(item)"
              >
                查看此次记录
              </p>
            </div>
          </div>

          <span class="time">{{ item.operate_time }}</span>
        </div>
        <!-- 备注 -->
        <p class="remarks" v-show="item.remark">
          {{ item.remark }}
        </p>
      </div>
    </div>
  </div>
  <!-- 流程 end -->
  <!-- 组件集合 -->
  <div>
    <!-- 见证件展示 -->
    <Popup
      v-model:show="_DATA.witnessOptions.show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <witnessPropsDetailTemp
        v-if="_DATA.witnessOptions.show"
        :data="_DATA.witnessOptions"
        @reviewOk="
          () => {
            _DATA.witnessOptions.show = false;
          }
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, inject, reactive } from 'vue';
import { Image, Popup } from 'vant';
//-见证件记录
import witnessPropsDetailTemp from '@/components/witness/witnessPropsDetailTemp';
const $host = inject('$host');
const props = defineProps(['data']);
const _DATA = reactive({
  //-见证件
  witnessOptions: {
    show: false,
    type: props.data.type ?? '1', //1设计 2施工
    node_id: props.data.node_id,
    witness_id: props.data.witness_id,
    form_version: '',
    log: true,
  },
});
//-@查看历史记录
const getLogFun = (data) => {
  _DATA.witnessOptions.show = true;
  _DATA.witnessOptions.form_version = data.form_version;
};
</script>

<style lang="less" scoped>
//-流程审核线
.linker_view {
  background: #fff;
  padding: 0 20px 0 25px;

  > h3 {
    padding: 15px 0px 0px;
    color: #1a1a1a;
    font-size: 15px;
    font-weight: bold;
  }

  div.h2 {
    align-items: center;
    margin-top: 20px;

    .item_box {
      padding: 0 0px 20px 0;
      line-height: 40px;
      position: relative;
      &:not(:last-child) {
        &::after {
          content: '';
          height: calc(100% - 46px);
          top: 44px;
          left: 20px;
          position: absolute;
          width: 2px;
          background: linear-gradient(
            to bottom,
            transparent 0%,
            transparent 50%,
            #d8d8d8 50%,
            #d8d8d8 100%
          );
          background-size: 1px 5px;
          background-repeat: repeat-y;
        }
      }

      & > p {
        color: #999;
        font-size: 13px;
      }

      .remarks {
        padding-left: 50px;
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
        color: #1a1a1a;
        background: #f5f5f5;
        padding: 10px;
        margin: 10px 0 0 50px;
      }

      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        margin-right: 10px;
        border: 1px solid rgba(228, 228, 228, 0.6);
      }
      .close_text {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        background: #a0a8b6;
        line-height: 40px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 700;
      }

      div.infoBox {
        width: calc(100% - 55px);
      }

      div.iconBox {
        position: relative;
        .view-icon {
          background: #fff;
          border-radius: 50%;
          position: absolute;
          bottom: 2px;
          right: 6px;
          font-size: 18px;
          width: 16px;
          height: 16px;
          fill: currentColor;
          &.status1 {
            color: @grey-color;
          }
          &.status2 {
            color: @orange-color;
          }
          &.status3 {
            color: @green-color;
          }
          &.status4 {
            color: @red_color;
          }
        }
      }

      .name {
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        color: #3e4345;
        width: calc(100% - 60px);
      }

      .extra {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #979797;
      }

      .time {
        position: absolute;
        right: 0;
        top: 1px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #d9d9d9;
      }

      .status {
        font-size: 13px;
        line-height: 20px;

        &.status1 {
          color: #767676;
        }
        &.status2 {
          color: #faad14;
        }
        &.status3 {
          color: #00bc19;
        }
        &.status4 {
          color: #ea0000;
        }
      }
    }
  }
}
.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit_log {
  color: #0256ff;
  cursor: pointer;
  font-size: 12px;
  line-height: 17px;
  border-bottom: 1px solid #0256ff;
}
//-发起人/提交人/确认人
.tag {
  display: inline-block;
  padding: 4px 4px;
  font-size: 12px;
  border-radius: 2px;
  line-height: 12px;
  font-weight: bold;
  margin: 0 4px 0 4px;

  &.green {
    color: @green-color;
    background: @green-bg-color;
  }

  &.blue {
    color: @blue-color;
    background: @blue-bg-color;
  }
}
</style>
