<!--
 * @Author: NanNan
 * @Date: 2022-04-12 14:53:05
 * @LastEditTime: 2023-05-04 15:48:21
 * @Description: 
-->
<template>
  <div class="index-page">
    <div class="input-box1">
      <img
        :src="require('@/assets/images/login_loading.gif')"
        alt="loding"
        srcset=""
        style="width: 100%"
      />
    </div>
    <!-- 切换客户 -->
    <van-action-sheet
      v-model:show="this.cus_show"
      :closeable="false"
      close-on-click-action
      :close-on-click-overlay="false"
      class="change-cus-box"
      title="切换客户"
    >
      <ul class="content">
        <li
          v-for="(item, index) in switch_list"
          :key="item.id"
          @click="changeCus(item.id)"
          :class="{ noborder: index + 1 === switch_list.length }"
        >
          <div class="left">
            <p class="avder">{{ item.name && item.name[0] }}</p>
            <div class="mess-box">
              <p class="no">{{ 'NO.' + item.no }}</p>
              <p class="post">
                {{ '客户经理：' + item.customer_manager_name }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </van-action-sheet>
  </div>
</template>

<script>
import Api from '@/api';
import axios from 'axios';
import { Toast } from 'vant';
import { ActionSheet } from 'vant';
import { useRouter } from 'vue-router';
const host = process.env.NODE_ENV === 'production' ? '' : '/api';

let FORCE_UPDATE_NUMBER = 0;
let wx = window.wx;
const localStorage = window.localStorage;
export default {
  name: 'authPage',
  components: {
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      //--@切换客户--begin
      cus_show: false,
      switch_list: [],
      //--@切换客户--end
      FLAG: localStorage.getItem('FLAG'),
    };
  },
  mounted() {
    localStorage.removeItem('WX_EXTERNAL_ID');
    this.getConfig();
  },
  methods: {
    getConfig() {
      const $this = this;
      const router = useRouter();
      const userId = localStorage.getItem('USERID_ID');
      const FLAG3 = localStorage.getItem('FLAG3');
      const codeValue = router && router.currentRoute.value.query?.code;
      if (codeValue || userId) {
        axios
          .post(host + '/rs/wechat/getStaff', {
            code: codeValue,
            userid: userId,
          })
          .then((res) => {
            const resData = res.data;
            if (resData.code === 0) {
              localStorage.setItem('ACCOUNT_ID', resData.data.id);
              localStorage.setItem('USERID_ID', resData.data.userid);
              localStorage.setItem('ACC_TOKEN', resData.data['acc-token']);
              localStorage.removeItem('FLAG3');
              $this.getCustomerInfo();
            } else if (resData.code === 1) {
              if (!FLAG3) {
                localStorage.removeItem('USERID_ID');
                $this.authWxAgentData();
              } else {
                localStorage.removeItem('FLAG3');
              }
              Toast(resData.data);
            } else {
              Toast(resData.msg);
            }
          });
      } else {
        $this.authWxAgentData();
      }
    },

    //-获取微信config配置签名数据
    authWxAgentData(isRefresh) {
      axios
        .post(host + '/rs/wechat/getAgentConfig', {
          url: window.location.href,
          refresh: isRefresh ? 1 : 0,
        })
        .then((res) => {
          const resData = res.data;
          if (resData.code === 0) {
            this.authWxAgentConfig(resData.data);
          } else if (resData.code === 1) {
            Toast(resData.data);
          } else {
            Toast(resData.msg);
          }
        });
    },
    //-配置config签名数据
    authWxAgentConfig(appSignature) {
      var $this = this;
      wx.agentConfig({
        beta: true,
        debug: false,
        corpid: appSignature.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: appSignature.agentid, // 必填，企业微信的应用id 生产环境
        timestamp: appSignature.timestamp, // 必填，生成签名的时间戳
        nonceStr: appSignature.noncestr, // 必填，生成签名的随机串
        signature: appSignature.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: ['getCurExternalContact'], // 必填
        success() {
          $this.initInvokWxConfig(appSignature);
        },
        fail(res) {
          console.log('////');
          FORCE_UPDATE_NUMBER++;
          if (FORCE_UPDATE_NUMBER <= 1) {
            $this.authWxAgentData(true);
          } else {
            Toast('访问受限，请联系管理员');
          }
          if (res.errMsg.indexOf('function not exist') > -1) {
            Toast('版本过低请升级');
          }
        },
      });
    },
    //-获取当前客户的userid
    initInvokWxConfig(appSignature) {
      var $this = this;
      const userId = localStorage.getItem('USERID_ID');
      try {
        wx.invoke('getCurExternalContact', {}, (res) => {
          if (res.err_msg == 'getCurExternalContact:ok') {
            localStorage.setItem('WX_EXTERNAL_ID', res.userId);
          } else {
            Toast('连接失败，请退出重试');
          }
          if (userId) {
            localStorage.removeItem('FLAG3');
            $this.getCustomerInfo();
          } else {
            var timestamp = new Date().getTime();
            localStorage.setItem('FLAG3', timestamp.toString());
            $this.wxCbRedirectUrl(appSignature);
          }
        });
      } catch (error) {
        Toast('访问受限，请联系管理员!');
      }
    },
    //-构造授权链接
    wxCbRedirectUrl(data) {
      const originUrl = location && location.origin;
      const redictUrl = encodeURIComponent(originUrl);
      const { corpid } = data;
      const redirectUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpid}&redirect_uri=${redictUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      location.href = redirectUrl;
    },
    //-判断客户是否有手机号
    getCustomerInfo() {
      const $this = this;
      const userId = localStorage.getItem('USERID_ID');
      const external_userid = localStorage.getItem('WX_EXTERNAL_ID');
      if (external_userid) {
        Api.getCustomerPhoneApi({ external_userid: external_userid }).then(
          (res) => {
            if (res.code === 0) {
              if (res.data.phone) {
                $this.getIsCustomerInfo(res.data.phone, userId);
              } else {
                window.location.href = '/index';
              }
            } else if (res.code === 1) {
              Toast(res.data);
            } else {
              Toast(res.msg);
            }
          }
        );
      } else {
        $this.authWxAgentData();
      }
    },
    //-@切换客户
    changeCus(id) {
      this.cus_show = false;
      window.location.href = '/sale?id=' + id;
    },
    //-手机号存在
    getIsCustomerInfo(phone, id) {
      Api.getInfoApi({ phone: phone, userid: id }).then((res) => {
        if (res.code === 0) {
          if (res.data.status == 1) {
            if (res.data.list.length) {
              if (res.data.list.length > 1) {
                this.cus_show = true;
                this.switch_list = res.data.list;
              } else {
                window.location.href = '/sale?id=' + res.data.list[0].id;
              }
            } else {
              Toast('授权失败，请联系管理员');
            }
          } else {
            window.location.href = '/index?active=2';
          }
        } else {
          Toast(res.data);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/welcome.less';
</style>
