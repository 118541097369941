<!--
 * @Author: NanNan
 * @Date: 2022-05-19 15:04:08
 * @LastEditTime: 2023-12-22 15:41:10
 * @Description: v0.3.0新版 第二版 客户信息 主页
 * @Description: v0.5.0新版 第三版 客户信息 主页
-->
<template>
  <div
    class="page-sale-box"
    :class="{ white: info.info_base.life_cycle_status < 3 }"
  >
    <!-- 头部卡片 -->
    <div class="header-box">
      <!-- 基本信息 -->
      <div class="base-box">
        <div class="name-list clearfix">
          <div class="left pull-left">
            <p class="name">
              {{
                info.info_base.sensitive_flag == 1
                  ? info.info_base.name
                  : '无权限'
              }}
              <Icon
                name="play"
                style="transform: rotate(90deg)"
                class="icon-arrow"
                size="16"
                @click="() => (_DATA.cus_show = true)"
                v-if="_DATA.switch_list?.length > 1"
              />
            </p>
          </div>
          <div class="right pull-right">
            <div
              class="message_box pr-10"
              @click="linkToFunc('/saleDetail')"
              v-if="
                info.operat.customer_show === 1 ||
                info.info_base.sensitive_flag == 1
              "
            >
              <svg class="icon-small2" aria-hidden="true">
                <use xlink:href="#icon-a-IconpackVariants1"></use>
              </svg>
              <span class="detail">客户资料</span>
            </div>
            <p
              class="split"
              v-if="
                info.operat.customer_show === 1 ||
                info.info_base.sensitive_flag == 1
              "
            ></p>

            <div class="message_box pl-10" @click="linkToFunc('/message')">
              <Badge dot class="redPoint" v-if="_DATA.messageCount > 0" />
              <span class="info">消息</span>
            </div>
          </div>
        </div>
        <div class="house" v-if="info.info_base.sensitive_flag == 1">
          <span>{{ info.info_base.gender_name }}</span>
          <em v-if="info.info_base.gender_name"></em>
          <span style="flex: 1">{{ info.info_base.estate_str }}</span>
        </div>
      </div>
      <!-- 标签卡 -->
      <div class="tags-box">
        <span
          v-for="item in info.info_base.labels"
          :key="item.flag"
          :class="{
            blue: [1].includes(item.flag),
            green: [2].includes(item.flag),
            orange: [3].includes(item.flag),
            red: [4].includes(item.flag),
            grey: [5].includes(item.flag),
          }"
        >
          {{ item.name }}
        </span>
      </div>
      <!-- 生命周期 -->
      <div class="life-box">
        <div
          class="line"
          :style="{
            width:
              info.info_base.life_cycle_status === 5
                ? '100%'
                : info.info_base.life_cycle_status * 25 - 12.5 + '%',
          }"
        ></div>
        <div class="circle-box">
          <ul>
            <li
              v-for="item in _DATA.step_list"
              :key="item.id"
              :class="{ on: info.info_base.life_cycle_status >= item.id }"
            >
              <p class="circle"><span></span></p>
              <p
                class="name"
                :class="{ first: item.id === 1, last: item.id === 5 }"
              >
                {{ item.name }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- tab层级 -->
    <div class="tab-box" :class="{ none: _DATA.tab_list?.length === 0 }">
      <Row gutter="14">
        <Col
          :span="24 / _DATA.tab_list.length"
          v-for="(item, index) in _DATA.tab_list"
          :key="index"
        >
          <div class="tab-same" @click="linkToFunc(item.link)">
            <div class="tab-img-box"><img :src="item.src" alt="" /></div>

            <p>{{ item.name }}</p>
          </div>
        </Col>
      </Row>
    </div>
    <!-- end -->

    <!-- 生命周期-对应内容展示 -->
    <!-- 1线索获取、2见面 -->
    <SaleCycleSeen v-if="info.active < 3" style="background-color: #ffffff" />
    <!-- 3设计签约 -->
    <ContractSign v-if="info.active === 3" />
    <!-- 4产值转化5施工 -->
    <SaleCycleValue
      v-if="info.active === 5"
      :data="info.operat"
      @update:func="(obj) => watchFunc(obj)"
      :auth="_DATA.auth_arr"
    />
    <!-- end -->
    <!--V1.4.0 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="handleMore(true)" style="margin-right: 10px">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_more.png')"
        />
      </li>
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu, id: _DATA.customer_id }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->

    <!-- 切换客户 -->
    <ActionSheet
      v-model:show="_DATA.cus_show"
      cancel-text="取消"
      close-on-click-action
      @cancel="handleMore(false)"
      class="change-cus-box"
      title="切换客户"
    >
      <ul class="content">
        <li
          v-for="(item, index) in _DATA.switch_list"
          :key="item.id"
          @click="changeCus(item.id)"
          :class="{ noborder: index + 1 === _DATA.switch_list.length }"
        >
          <div class="left">
            <p class="avder">{{ item.name && item.name[0] }}</p>
            <div class="mess-box">
              <p class="no">{{ 'NO.' + item.no }}</p>
              <p class="post">
                {{ '客户经理：' + item.customer_manager_name }}
              </p>
            </div>
          </div>
          <span class="right" v-if="item.now_flag === 1">当前</span>
        </li>
      </ul>
    </ActionSheet>
    <!-- end -->
    <!-- 更多操作 -->
    <ActionSheet
      v-model:show="_DATA.more_status"
      cancel-text="取消"
      close-on-click-action
      @cancel="handleMore(false)"
      class="more-box"
    >
      <div class="content">
        <!-- 客户相关 -->
        <div class="same">
          <div v-for="(item, index) in _DATA.more_list.more" :key="index">
            <a
              v-if="item.id == 34 || item.id == 230"
              @click="handelFuns(item.id)"
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <router-link
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&authId=' +
                item.id +
                '&isManager=' +
                info.info_base.is_customer_manager +
                '&cid=' +
                _DATA.contract_id +
                '&cname=' +
                info.info_base.name +
                '&duid=' +
                info.info_base.design_uid +
                '&suid=' +
                info.info_base.network_commissioner +
                '&muid=' +
                info.info_base.customer_manager_id
              "
              v-else
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 客户相关操作 -->
        <div class="same">
          <div v-for="(item, index) in _DATA.more_list.other" :key="index">
            <a v-if="item.id == 20" @click="handelFuns(item.id)">
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <a
              v-else-if="item.id == 24 || item.id == 580"
              @click="handelFuns(item.id)"
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <router-link
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&authId=' +
                item.id +
                '&cid=' +
                _DATA.contract_id
              "
              v-else
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 变更单操作 -->
        <div class="same" v-if="info.info_base.life_cycle_status >= 3">
          <div v-for="(item, index) in _DATA.more_list.change" :key="index">
            <router-link
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&authId=' +
                item.id +
                '&cid=' +
                _DATA.contract_id
              "
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 设计相关 -->
        <div class="same" v-if="info.info_base.life_cycle_status >= 3">
          <div v-for="(item, index) in _DATA.more_list.pull" :key="index">
            <router-link
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&did=' +
                _DATA.design_id +
                '&cid=' +
                _DATA.contract_id
              "
              v-if="[276, 277, 278].includes(item.id) ? _DATA.design_id : true"
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 合同&收款相关 -->
        <div class="same" v-if="info.info_base.life_cycle_status >= 3">
          <div v-for="(item, index) in _DATA.more_list.contract" :key="index">
            <a v-if="item.id == 229" @click="handelFuns(item.id)">
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <a v-else-if="item.id == 228" @click="handelFuns(item.id)">
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <router-link
              v-else
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&cid=' +
                _DATA.contract_id +
                '&customer_type=' +
                info.info_base.customer_type
              "
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 施工相关 -->
        <div class="same" v-if="info.info_base.life_cycle_status >= 4">
          <div v-for="(item, index) in _DATA.constuactlist" :key="index">
            <a v-if="item.id == 483" @click="handelFuns(item.id)">
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <!-- 工地停工:工地停工、工地重启在工地状态为【在建/停工】时展示 -->
            <a v-else-if="item.id == 494" @click="handelFuns(item.id)">
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <!-- 工地重启:工地停工、工地重启在工地状态为【在建/停工】时展示 -->
            <a v-else-if="item.id == 495" @click="handelFuns(item.id)">
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </a>
            <router-link
              v-else
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&gdid=' +
                _DATA.site_id +
                '&cid=' +
                _DATA.contract_id
              "
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 施工 单据相关 -->
        <div class="same" v-if="info.info_base.life_cycle_status >= 4">
          <div
            v-for="(item, index) in _DATA.more_list.constuactmore"
            :key="index"
          >
            <router-link
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&gdid=' +
                _DATA.site_id +
                '&cid=' +
                _DATA.contract_id
              "
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
        <!-- 客服相关 -->
        <div class="same" v-if="info.info_base.life_cycle_status >= 4">
          <div
            v-for="(item, index) in _DATA.more_list.servicemore"
            :key="index"
          >
            <router-link
              :to="
                item.link +
                '?id=' +
                _DATA.customer_id +
                '&gdid=' +
                _DATA.site_id +
                '&cid=' +
                _DATA.contract_id
              "
            >
              <img
                :src="require('@/assets/images/icon/' + `${item.icon}`)"
                alt=""
              />
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </ActionSheet>
    <!-- end -->
    <!-- 风险提示 -->
    <ActionSheet
      class="dangerActionSheet"
      v-model:show="_DATA.dangerOperate.show"
    >
      <div class="content">
        <div class="content_item">
          <h3>风险提示</h3>
          <p>
            合同发起前，请确认客户的姓名与对应手机号正确，如
            <span>姓名输入错误</span>
            或
            <span>非运营商所登记的手机号持有人</span>
            ，将需要客户亲自操作三方合同签署平台注销账号
          </p>
          <div class="dangerForm">
            <Form ref="ruleFormRef">
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.name"
                  label="客户姓名"
                  placeholder="请输入正确的姓名"
                  input-align="right"
                  error-message-align="right"
                  maxlength="20"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的姓名',
                      validator: validatorName,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.phone"
                  type="number"
                  label="手机号"
                  placeholder="请输入正确的手机号"
                  input-align="right"
                  error-message-align="right"
                  maxlength="11"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的手机号',
                      validator: validatorPhone,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
              <p class="tip">
                请再次确认系统中录入的楼盘名称与单元栋号正确，此信息将记录于合同中，发送客户在线签署
              </p>
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.house"
                  label="楼盘"
                  placeholder="请输入正确的楼盘"
                  input-align="right"
                  error-message-align="right"
                  maxlength="20"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的楼盘',
                      validator: validatorHouse,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
              <CellGroup inset>
                <Field
                  v-model="form.dangerList.floor"
                  label="单元栋号"
                  placeholder="请输入正确的单元栋号"
                  input-align="right"
                  error-message-align="right"
                  maxlength="30"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的单元栋号',
                      validator: validatorFloor,
                      trigger: 'onChange',
                    },
                  ]"
                />
              </CellGroup>
            </Form>
          </div>
        </div>
        <div class="content_button">
          <Button
            block
            loading-type="spinner"
            @click="sumbitAndCreate"
            :loading="_DATA.dangerOperate.btnLoading"
            loading-text="确认并创建合同"
          >
            确认并创建合同
          </Button>
        </div>
      </div>
    </ActionSheet>
    <!-- end -->
  </div>
</template>
<script setup>
import { onMounted, reactive, inject, ref, nextTick, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import {
  Icon,
  ActionSheet,
  Badge,
  Notify,
  Dialog,
  Button,
  Row,
  Col,
  Form,
  CellGroup,
  Field,
} from 'vant';
import SaleCycleSeen from '@/components/sale/saleCycleSeen.vue';
import ContractSign from '@/views/contract/contractSign.vue';
import SaleCycleValue from '@/components/sale/saleCycleValue.vue';
import sideMenu from '@/components/sideMenu';
const $http = inject('$http');
const router = useRouter();
const store = useStore();
onMounted(() => {
  getMessageCount();
  getParmarsFun();
  totalFunc();
  console.log(store.state.account.allOperationAuthority.indexOf('99') > -1);
});
const _DATA = reactive({
  messageCount: 0,
  info: '',
  customer_id: router.currentRoute.value.query.id,
  //-v0.5.0客户权限
  /**
   * customer_show 销售查看权限 0无 1有
   * customer_edit 销售编辑权限 0无 1有
   * contract_show 合同查看权限 0无 1有
   * design_show 设计查看权限 0无 1有
   * construction_show 工地查看权限 0无 1有
   * contract_flag 是否有创建合同 0无 1有
   */
  //-设计Id
  design_id: '',
  //-施工Id
  site_id: '',
  //-合同Id
  contract_id: '',
  switch_list: [], //切换客户list
  loading: false,
  cus_show: false,
  step_list: [
    {
      name: '线索',
      id: 1,
    },
    {
      name: '见面',
      id: 2,
    },
    {
      name: '签约',
      id: 3,
    },
    {
      name: '转化',
      id: 4,
    },
    {
      name: '施工',
      id: 5,
    },
  ],
  tab_list: [
    {
      name: '跟进记录',
      link: '/followRecord',
      src: require('@/assets/images/icon-follow.png'),
    },
    {
      name: '合同签约',
      link: '/contractSign',
      src: require('@/assets/images/icon-sign.png'),
    },
    {
      name: '收款流水',
      link: '/cashFlow',
      src: require('@/assets/images/icon-cash.png'),
    },
    {
      name: '合同增减项',
      link: '/addReduce',
      src: require('@/assets/images/icon-add.png'),
    },
  ],
  // 更多操作
  more_status: false,
  more_list: [],
  more_obj: {},
  //-更多操作权限id
  authId: '',
  //-风险提示相关--begin
  dangerOperate: {
    show: false,
    btnLoading: false,
    sourceId: '',
  },
  //-风险提示相关--end
  //-权限
  auth_arr: computed(() => {
    return store.state.account.allOperationAuthority;
  }),
  //-工地停工/重启状态
  construction_status_val: '',
  //-更多操作-施工列表
  constuactlist: [],
});

/**
 * 接口
 */
const info = reactive({
  info_base: {},
  operat: {
    customer_show: 0, //销售查看权限 0无 1有
    customer_edit: 0, //销售编辑权限 0无 1有
    contract_show: 0, //合同查看权限 0无 1有
    design_show: 0, //设计查看权限 0无 1有
    construction_show: 0, //工地查看权限 0无 1有
    contract_flag: 0, //是否有创建合同 0无 1有
  },
  active: 1, //内容展示根据权限判断 1跟进记录、3设计签约、4产值转化、5施工
});
const loadFunc = (id) => {
  return {
    //@获取 所有客户
    getSwitch: async () => {
      return await $http.getSaleSwitchCustomerApi({ customer_id: id });
    },
    //@基本信息
    getBase: async () => {
      return await $http.getSaleInfoBaseApi({
        id,
      });
    },
    //@v0.5.0获取首页客户 权限参数
    getOperation: async () => {
      return await $http.getOperationHomeAuthorityApi({
        customer_id: id,
      });
    },
  };
};

const { getSwitch, getBase, getOperation } = loadFunc(_DATA.customer_id);

const totalFunc = () => {
  _DATA.loading = true;
  Promise.all([getSwitch(), getBase(), getOperation()])
    .then((res) => {
      _DATA.switch_list = res[0].code === 0 ? res[0].data.list : [];
      info.info_base = res[1].code === 0 ? res[1].data : {};
      info.operat = res[2].code === 0 ? res[2].data : {};
      operatStatus();
    })
    .then(() => {
      _DATA.loading = false;
      console.log('当前应该展示哪个周期的 数据：', info.active);
    })
    .catch((err) => {
      _DATA.loading = false;
      throw new Error('报错终止', err);
    });
};

//-@获取未读消息数
const getMessageCount = () => {
  $http
    .getMessageCountApi({
      cid: _DATA.customer_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.messageCount = data.count;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
//-@获取参数列表
const getParmarsFun = () => {
  $http
    .getOperationAuthorityApi({ customer_id: _DATA.customer_id })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.more_list = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

/**
 * 操作
 */
//-@生命周期 + 权限判断
const operatStatus = () => {
  const { contract_show, design_show, construction_show, contract_flag } =
    info.operat;
  if (!contract_show) {
    _DATA.tab_list = [];
  }
  // 1、 没有合同、设计、施工权限，显示跟进记录，跟进记录平铺；
  // 2、 线索获取/见面阶段，有合同权限，且有创建合同，显示样式为【设计签约】样式；
  // if (info.info_base.life_cycle_status < 3) {
  //   if (contract_flag && contract_show) {
  //     info.active = 3;
  //   } else {
  //     info.active = 1;
  //   }
  //   return;
  // } else if (info.info_base.life_cycle_status === 3) {
  //   if (contract_show) {
  //     info.active = 3;
  //   } else {
  //     info.active = 1;
  //   }
  //   return;
  // } else if (info.info_base.life_cycle_status === 4) {
  //   if (design_show) {
  //     info.active = 4;
  //   } else {
  //     info.active = 1;
  //   }
  //   return;
  // } else if (info.info_base.life_cycle_status === 5) {
  //   if (construction_show || design_show) {
  //     info.active = 5;
  //   } else {
  //     info.active = 1;
  //   }
  //   return;
  // }
  if (info.info_base.life_cycle_status < 3) {
    if (contract_flag && contract_show) {
      info.active = 3;
    } else {
      info.active = 1;
    }
    return;
  } else if (info.info_base.life_cycle_status > 2) {
    if (construction_show || design_show) {
      info.active = 5;
    } else {
      info.active = 1;
    }
    return;
  }
};
//-@跳转
const linkToFunc = (name) => {
  if (name == '/addReduce' && _DATA.auth_arr.indexOf('81') < 0) {
    Notify({
      type: 'warning',
      message: '暂无权限！',
    });
    return;
  }
  router.push({
    path: name,
    query: {
      id: _DATA.customer_id,
    },
  });
};

//-@切换客户
const changeCus = (id) => {
  _DATA.cus_show = false;
  window.location.href = '/sale?id=' + id;
};

//-@ 更多状态更改
const handleMore = (type) => {
  _DATA.more_status = type;
};

//-@更多操作
const handelFuns = (id) => {
  _DATA.authId = id;
  //设计改派
  if (id == 230) {
    //判断是否可以设计改派
    $http
      .designReassignmentParam({
        customer_id: _DATA.customer_id ? _DATA.customer_id : '',
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          if (data.is_reassignment == 1) {
            router.push({
              path: '/applyDesignReassignment',
              query: {
                id: _DATA.customer_id,
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: '您当前不符合改派条件',
            });
          }
        } else {
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  }
  if (id == 20) {
    //-继续跟进
    Dialog.confirm({
      message: '确定要继续跟进吗？',
    })
      .then(() => {
        $http
          .customerChangeHang({
            customer_id: _DATA.customer_id ? _DATA.customer_id : '',
            type: 2,
          })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
                onClose: () => {
                  location.reload();
                },
              });
            } else {
              Notify({
                type: 'warning',
                message: data,
              });
            }
          });
      })
      .catch(() => {
        Notify({
          type: 'warning',
          message: '取消操作',
        });
      });
  }
  if (id == 34) {
    //-激活
    Dialog.confirm({
      message: '确定要激活客户吗？',
    })
      .then(() => {
        $http
          .lostOperation({
            customer_id: _DATA.customer_id ? _DATA.customer_id : '',
            operation_type: 1,
          })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
                onClose: () => {
                  location.reload();
                },
              });
            } else {
              Notify({
                type: 'warning',
                message: data,
              });
            }
          });
      })
      .catch(() => {
        Notify({
          type: 'warning',
          message: '取消操作',
        });
      });
  }
  if (id == 24) {
    //-风险提示
    _DATA.dangerOperate.sourceId = id;
    _DATA.dangerOperate.show = true;
    $http
      .getCustomerManagementSimpleInfoApi({
        customer_id: _DATA.customer_id,
      })
      .then((res) => {
        if (res.code === 0) {
          form.dangerList.name = res.data.customer_name;
          form.dangerList.phone = res.data.customer_phone;
          form.dangerList.house = res.data.estate_name;
          form.dangerList.floor = res.data.house_unit_home_name;
        } else {
          Notify({ type: 'warning', message: res.data });
        }
      });
    nextTick(() => {
      ruleFormRef.value?.resetValidation();
    });
  }
  if (id == 228) {
    //-合同退单-前置判断是否有已签约合同
    if (info.info_base.contract_sign_count > 0) {
      router.push({
        path: '/contractCancel',
        query: {
          id: _DATA.customer_id,
        },
      });
    } else {
      Dialog.confirm({
        message:
          '<div class="message_tip"><p class="tip_image"></p><p>暂无可退单合同</p></div>',
        showCancelButton: false,
        confirmButtonText: '知道了',
        confirmButtonColor: '#3471FF',
        allowHtml: true,
      }).then(() => {
        return;
      });
    }
  }
  if (id == 229) {
    //-合同变更-前置判断是否有已签约合同
    if (info.info_base.contract_sign_count > 0) {
      //-风险提示
      _DATA.dangerOperate.sourceId = id;
      _DATA.dangerOperate.show = true;
      $http
        .getCustomerManagementSimpleInfoApi({
          customer_id: _DATA.customer_id,
        })
        .then((res) => {
          if (res.code === 0) {
            form.dangerList.name = res.data.customer_name;
            form.dangerList.phone = res.data.customer_phone;
            form.dangerList.house = res.data.estate_name;
            form.dangerList.floor = res.data.house_unit_home_name;
          } else {
            Notify({ type: 'warning', message: res.data });
          }
        });
      nextTick(() => {
        ruleFormRef.value?.resetValidation();
      });
    } else {
      Dialog.confirm({
        message:
          '<div class="message_tip"><p class="tip_image"></p><p>暂无可变更合同</p></div>',
        showCancelButton: false,
        confirmButtonText: '知道了',
        confirmButtonColor: '#3471FF',
        allowHtml: true,
      }).then(() => {
        return;
      });
    }
  }
  if (id == 483) {
    //V1.4.2 解除指派交付中心
    if (_DATA.site_id) {
      Dialog.confirm({
        message: '确定要解除指派交付中心吗？',
      })
        .then(() => {
          $http
            .construction_cancelAssign({
              construction_id: _DATA.site_id,
            })
            .then((res) => {
              const { code, data } = res;
              if (code === 0) {
                Notify({
                  type: 'success',
                  message: '操作成功',
                  onClose: () => {
                    location.reload();
                  },
                });
              } else {
                Notify({
                  type: 'warning',
                  message: data,
                });
              }
            });
        })
        .catch(() => {
          Notify({
            type: 'warning',
            message: '取消操作',
          });
        });
    } else {
      Notify({
        type: 'warning',
        message: '请切换施工合同~',
      });
    }
  }
  if (id == 494) {
    //-工地停工
    if (_DATA.construction_status_val && _DATA.construction_status_val == 5) {
      Notify({
        type: 'warning',
        message: '该工地处于停工状态，操作失败!',
      });
    } else {
      router.push({
        path: '/siteStop',
        query: {
          gdid: _DATA.site_id,
        },
      });
    }
  }
  if (id == 495) {
    //-工地重启
    if (_DATA.construction_status_val && _DATA.construction_status_val == 2) {
      Notify({
        type: 'warning',
        message: '该工地未被停工，不能操作工地重启!',
      });
    } else {
      router.push({
        path: '/siteRestart',
        query: {
          gdid: _DATA.site_id,
        },
      });
    }
  }
  if (id == 580) {
    //-渠道费申请
    if (info.info_base?.can_apply_channel_fee === 1) {
      router.push({
        path: '/channelFees',
        query: {
          id: _DATA.customer_id,
        },
      });
    } else {
      Notify({
        type: 'warning',
        message: '该客户不符合渠道费申请条件!',
      });
    }
  }
};

//-@监听各个生命周期 返回参数
const watchFunc = (obj) => {
  console.log(obj);
  _DATA.more_obj = Object.assign({}, obj);
  _DATA.design_id = obj.design_id;
  _DATA.site_id = obj.construction_id;
  _DATA.contract_id = obj.contract_id;
  _DATA.construction_status_val = obj.construction_status_val ?? '';
  //-【更多操作】：工地停工、工地重启状态判断
  getConstuactlist();
};

//-【更多操作】：工地停工、工地重启在工地状态为【在建/停工】时展示-begin
const getConstuactlist = () => {
  let constuactlist = _.cloneDeep(_DATA.more_list.constuactlist);
  if (_DATA.construction_status_val) {
    if (
      _DATA.construction_status_val !== 2 &&
      _DATA.construction_status_val !== 5
    ) {
      _DATA.constuactlist = constuactlist.filter((v) => {
        return v.id != 494 && v.id != 495;
      });
    } else {
      _DATA.constuactlist = constuactlist;
    }
  } else {
    _DATA.constuactlist = constuactlist.filter((v) => {
      return v.id != 494 && v.id != 495;
    });
  }
};
//-----end

//-风险提示--begin
const form = reactive({
  list: [],
  //-风险提示相关
  dangerList: {
    name: '',
    phone: '',
    house: '',
    floor: '',
  },
});
const ruleFormRef = ref(null);
// 表单验证
const phoneReg = /^(1\d{10})$/;
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
const nameReg = /^.{2,20}$/;
const validatorName = (val) => (val ? nameReg.test(val) : true);
const houseReg = /^.{0,20}$/;
const validatorHouse = (val) => (val ? houseReg.test(val) : true);
const floorReg = /^.{0,30}$/;
const validatorFloor = (val) => (val ? floorReg.test(val) : true);

const sumbitAndCreate = () => {
  _DATA.dangerOperate.btnLoading = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      _DATA.dangerOperate.btnLoading = false;
      $http
        .getCustomerChangeSign({
          customer_id: _DATA.customer_id,
          phone: form.dangerList.phone,
          name: form.dangerList.name,
          estate_name: form.dangerList.house,
          house_unit_home_name: form.dangerList.floor,
        })
        .then((res) => {
          if (res.code === 0) {
            _DATA.dangerOperate.show = false;
            if (_DATA.dangerOperate.sourceId == 229) {
              router.push({
                path: '/contractModify',
                query: {
                  id: _DATA.customer_id,
                },
              });
            } else {
              router.push({
                path: '/contractIndex',
                query: {
                  id: _DATA.customer_id,
                  authId: _DATA.authId,
                  cid: _DATA.contract_id,
                },
              });
            }
          } else {
            Notify({ type: 'warning', message: res.data });
          }
        });
    })
    .catch(() => {
      _DATA.dangerOperate.btnLoading = false;
      //验证失败
      console.log('请先完善风险提示信息');
    });
};
//-风险提示--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/sale.less';
</style>
