<!--
 * @Author: NanNan
 * @Date: 2022-06-28 09:13:16
 * @LastEditTime: 2023-05-20 14:21:09
 * @Description:V1.3.7 主材订单详情-材料明细
-->
<template>
  <div class="file_content">
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="detail_box" v-for="item in _DATA.info" :key="item.id">
      <div>
        <p class="name">{{ item.name }}</p>
        <div class="fileList" v-if="item.files.length > 0">
          <FileGrid :data="item.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import FileGrid from '@/components/common/FileGrid';
import { reactive, defineProps, inject, onMounted } from 'vue';
import { Notify, Overlay, Loading } from 'vant';
const $http = inject('$http');

const props = defineProps(['id']);
const _DATA = reactive({
  loading: false,
  info: [],
});
onMounted(() => {
  loadInfo();
});
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .mainOrder_orderAttachment({
      id: props.id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      setTimeout(() => {
        _DATA.loading = false;
      }, 500);
    });
};
</script>
<style lang="less" scoped>
.file_content {
  padding: 16px;
  background-color: #ffffff;
  .detail_box {
    margin-bottom: 5px;
    .name {
      font-size: 14px;
      color: @grey-color;
      line-height: 20px;
      padding-bottom: 4px;
    }
  }
  .name {
    font-size: 14px;
    color: @grey-color;
    line-height: 20px;
    padding-bottom: 4px;
  }
}
</style>
