<!--
 * @Author: gest
 * @Date: 2022-09-16 10:27:09
 * @LastEditTime: 2023-09-12 10:10:30
 * @Description: 生成客户提示
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <div class="basis-box">
      <div class="content-box">
        <div class="top_box">
          <Icon name="info-o" class="big_icon" />
          <div class="big_text">客户信息不足</div>
          <div class="desc_text">请确认完善以下信息以生成客户</div>
        </div>

        <div class="error_box">
          <div class="error_item">
            <div class="left">客户信息</div>
            <div v-if="!_DATA.info.name" class="right">
              <Icon name="checked" class="pass" />
              <div class="text">已完善</div>
            </div>
            <div v-else class="right">
              <Icon name="clear" class="cancel" />
              <div class="tip">{{ _DATA.info.name }}</div>
            </div>
          </div>
          <div class="error_item">
            <div class="left">联系方式</div>
            <div v-if="!_DATA.info.phone" class="right">
              <Icon name="checked" class="pass" />
              <div class="text">已完善</div>
            </div>
            <div v-else class="right">
              <Icon name="clear" class="cancel" />
              <div class="tip">{{ _DATA.info.phone }}</div>
            </div>
          </div>
          <div class="error_item">
            <div class="left">渠道来源</div>
            <div v-if="!_DATA.info.channel_source" class="right">
              <Icon name="checked" class="pass" />
              <div class="text">已完善</div>
            </div>
            <div v-else class="right">
              <Icon name="clear" class="cancel" />
              <div class="tip">{{ _DATA.info.channel_source }}</div>
            </div>
          </div>
          <div class="error_item">
            <div class="left">区域位置</div>
            <div v-if="!_DATA.info.city" class="right">
              <Icon name="checked" class="pass" />
              <div class="text">已完善</div>
            </div>
            <div v-else class="right">
              <Icon name="clear" class="cancel" />
              <div class="tip">{{ _DATA.info.city }}</div>
            </div>
          </div>
          <div class="error_item">
            <div class="left">添加企微好友</div>
            <div v-if="!_DATA.info.wechat_friend_status" class="right">
              <Icon name="checked" class="pass" />
              <div class="text">已完善</div>
            </div>
            <div v-else class="right">
              <Icon name="clear" class="cancel" />
              <div class="tip">
                {{ _DATA.info.wechat_friend_status }}
                <div class="tag" @click="clickClueTagging()">我已标注</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="stick-box">
      <Button type="primary" size="large" @click="onSubmit()">知道了</Button>
    </div>
    <!-- 确认客户信息-->
    <Popup
      v-model:show="_DATA.showPicker_customerInfo"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <clueCustomerInfo
        v-if="_DATA.showPicker_customerInfo"
        :data="_DATA.clueCustomerInfo_list"
        @reviewClose="
          () => {
            _DATA.showPicker_customerInfo = false;
          }
        "
        @reviewOk="clueTaggingConfirm"
      />
    </Popup>
  </div>
</template>
<script setup>
import { onMounted, reactive, inject } from 'vue';
import { useRouter } from 'vue-router';
import { Button, Overlay, Loading, Notify, Popup, Dialog, Icon } from 'vant';
import clueCustomerInfo from '@/components/clue/clueCustomerInfo';
const router = useRouter();
const $http = inject('$http');
const _DATA = reactive({
  clue_id: router.currentRoute.value.query.id,
  info: JSON.parse(router.currentRoute.value.query.info),

  loading: false,
  showPicker_customerInfo: false, //确认客户信息
  clueCustomerInfo_list: [],
});

onMounted(() => {});

/**
 * 操作
 */
const clickClueTagging = () => {
  //标注
  _DATA.loading = true;
  $http.clueTagging({ clue_id: _DATA.clue_id }).then((res) => {
    const { data, code } = res;
    if (code === 0) {
      _DATA.loading = false;
      if (data && data.length > 1) {
        _DATA.clueCustomerInfo_list = data;
        _DATA.showPicker_customerInfo = true;
      } else {
        //找到1个对应手机号的企微好友，自动绑定，弹出信息完善抽屉
        Dialog.alert({
          message: '绑定成功，请继续操作生成客户！',
          confirmButtonText: '知道了',
        }).then(() => {
          router.back();
        });
      }
    } else {
      _DATA.loading = false;
      if (code == 1) {
        //未找到对应手机号的企微好友
        Dialog.alert({
          message: data,
          confirmButtonText: '知道了',
        }).then(() => {
          // on close
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    }
  });
};
//标注确认
const clueTaggingConfirm = (v) => {
  _DATA.showPicker_customerInfo = false;
  $http
    .clueTaggingConfirm({
      clue_id: _DATA.clue_id,
      external_userid: v.external_userid,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        Dialog.alert({
          message: '绑定成功，请继续操作生成客户！',
          confirmButtonText: '知道了',
        }).then(() => {
          router.back();
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
const onSubmit = () => {
  router.back();
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
  background: #f5f5f5;

  .basis-box {
    padding: 10px 0 0 0;

    .content-box {
      background: #fff;

      padding: 20px 20px 74px 20px;
      .top_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0 30px 0;
        .big_icon {
          font-size: 60px;
          color: #fca326;
        }
        .big_text {
          margin-top: 20px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: bold;
        }
        .desc_text {
          color: #767676;
          font-size: 14px;
          line-height: 26px;
        }
      }

      .error_box {
        border-top: 1px solid #eeeeee;
        padding: 10px 0;
        .error_item {
          display: flex;
          font-size: 14px;
          padding-top: 20px;
          .left {
            width: 84px;
            color: #1a1a1a;
          }
          .right {
            flex: 1;
            display: flex;
            margin-left: 20px;

            .pass {
              color: #00c160;
              font-size: 20px;
              position: relative;
              top: 2px;
            }
            .text {
              color: #00c160;
              padding-left: 10px;
            }
            .cancel {
              color: #ea0000;
              font-size: 20px;
              position: relative;
              top: 2px;
            }
            .tip {
              color: #ea0000;
              padding-left: 10px;
            }

            .tag {
              font-size: 14px;
              margin-top: 10px;
              color: #0256ff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
