<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2024-03-15 10:17:02
 * @Description: V1.5.6紧急需求 死单流程添加附件
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <NavBar
      safe-area-inset-top
      left-arrow
      :title="form.list.type == 1 ? '申请死单' : '确认死单'"
      @click-left="onBack"
      :fixed="true"
    />
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              死单原因
            </label>
            <Field
              v-model="form.list.lost_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择死单原因',
                },
              ]"
              placeholder="请选择死单原因"
              @click="state.showPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              备注
              <span class="title_tip">（填写回访记录）</span>
            </label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              :rules="[
                {
                  required: true,
                  message: '请添加备注',
                },
              ]"
              show-word-limit
            />
          </div>
          <div class="cell">
            <!-- 附件组件 -->
            <p class="form_title">
              <span style="color: #ea3d2f">*</span>
              附件
              <span class="title_tip">（上传现场照片）</span>
            </p>
            <FileUpload
              :data="form.list.files"
              :type="4"
              :amount="9"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
            <p class="errorTip" v-if="fileTip">请上传</p>
            <!-- end -->
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker -死单原因-->
    <Popup v-model:show="state.showPicker" position="bottom">
      <Picker
        :columns="state.reasonList"
        :columns-field-names="customFieldName"
        @confirm="onConfirm"
        @cancel="state.showPicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  NavBar,
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload'; //-上传附件
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-picker
  showPicker: false,
  reasonList: [],
});
//-bar返回操作
const onBack = () => {
  router.back();
};
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value) => {
  if (value) {
    form.list.lost_id = value.id;
    form.list.lost_name = value.name;
  }
  state.showPicker = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  await $http.getSaleOptionsApi().then((res) => {
    if (res.code === 0) {
      state.reasonList = res.data.customer.loss_type;
    } else {
      Toast(res.data);
    }
  });
};
//-获取参数--end

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    type: router.currentRoute.value.query
      ? router.currentRoute.value.query.authId == 22
        ? '1'
        : '2'
      : '',
    lost_id: '',
    lost_name: '',
    remarks: '',
    files: [],
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (form.list.files.length == 0) {
        fileTip.value = true;
        Notify({ type: 'warning', message: '请先完善信息' });
      } else {
        state.loadingConfirm = true;
        $http
          .customerChangeLost({
            ...form.list,
          })
          .then((res) => {
            const { code, data } = res;
            state.loadingConfirm = false;
            if (code === 0) {
              Notify({
                type: 'success',
                message: '操作成功',
                onClose: () => {
                  if (state.taskDate) {
                    router.push('/taskDay?date=' + state.taskDate);
                  } else {
                    router.push('/sale?id=' + form.list.customer_id);
                  }
                },
              });
            } else {
              Notify({ type: 'warning', message: data });
            }
          });
      }
    })
    .catch(() => {
      //验证失败
      Notify({ type: 'warning', message: '请先完善信息' });
      if (form.list.files.length == 0) {
        fileTip.value = true;
      }
    });
};

//--基本信息表单-----end----------------

//-附件必填提示
const fileTip = ref(false);
// @附件
const getFileList = (file_list) => {
  form.list.files = file_list;
  fileTip.value = false;
};
//-----end------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
