<!--
 * @Author: Jessica
 * @Date: 2024-05-09 15:56:57
 * @LastEditTime: 2024-05-28 16:51:53
 * @Description:V1.6.0-交付数
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 总计 -->
        <div class="data_card_top">
          <div class="title flex_line_between">
            <span class="size17 bold">交付数</span>
            <div
              class="size13 color_999 flex_line_between"
              v-if="props.data?.now_year == props.data?.yearId"
            >
              应交付为累计至当月数
              <!-- 规则入口 -->
              <div class="icon_info_box_small pl_3" @click.stop="createDanger">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/icon_info.png')"
                  alt="info"
                />
              </div>
            </div>
          </div>
          <!-- 应交付/完成/完成率 -->
          <!-- 当年 -->
          <Row v-if="props.data?.now_year == props.data?.yearId">
            <Col span="7">
              <div class="grey align_center">应交付</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target }}
                </span>
              </div>
            </Col>
            <Col span="9">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.actual }}
                </span>
              </div>
              <!-- 差值 -->
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info.actual > _DATA.info.target"
              >
                <span class="bold green">{{ _DATA.info.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info.actual < _DATA.info.target"
              >
                <span class="bold orange">{{ _DATA.info.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成率</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target_rate + '%' }}
                </span>
              </div>
            </Col>
            <Col span="7" class="pt_20">
              <div class="grey align_center">锦旗数</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.pennant_num }}
                </span>
              </div>
            </Col>
            <Col span="9" class="pt_20">
              <div class="grey align_center">锦旗率</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.pennant_rate + '%' }}
                </span>
              </div>
            </Col>
          </Row>
          <!-- 往年 -->
          <Row v-else>
            <Col span="9">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.actual }}
                </span>
              </div>
            </Col>
            <Col span="7">
              <div class="grey align_center">锦旗数</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.pennant_num }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">锦旗率</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.pennant_rate + '%' }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 图表 -->
        <!-- 当年 -->
        <div
          class="data_card_table border_top"
          v-if="props.data?.now_year == props.data?.yearId"
        >
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col :span="props.data.depId ? 14 : 10" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <Col span="4" v-if="!props.data.depId">
              <div class="grey align_center">完成率</div>
            </Col>
            <Col span="5">
              <div class="grey align_center">锦旗数/率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col :span="props.data.depId ? 14 : 10" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    v-if="props.data.depId"
                    :data="{
                      actual: item.actual,
                      list: _DATA.line_values,
                      type: 3,
                    }"
                  ></chartJD>
                  <chartJD
                    v-else
                    :data="{
                      actual: item.actual,
                      target: item.target,
                      list: _DATA.line_values,
                      type: 1,
                    }"
                  ></chartJD>
                  <div class="chart_right" v-if="props.data.depId"></div>
                  <div class="chart_right" v-else>
                    {{ item.target }}
                  </div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 完成率:二级页展示 -->
              <Col span="4" class="flex_line_center" v-if="!props.data.depId">
                <div>{{ item.target_rate + '%' }}</div>
              </Col>
              <!-- 锦旗数/率 -->
              <Col span="5" class="flex_line_center">
                <div>
                  <div class="flex_line_center">
                    <span>{{ item.pennant_num }}</span>
                  </div>
                  <div class="flex_line_center">
                    <span>{{ item.pennant_rate + '%' }}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 往年 -->
        <div class="data_card_table border_top" v-else>
          <Row class="mb_10">
            <Col span="5">
              <div class="grey pr_10">
                {{ props.data.depId ? '项目经理' : '部门' }}
              </div>
            </Col>
            <Col span="14" style="position: relative">
              <!-- 进度图标题 -->
              <div class="chart_pos">
                <!-- 坐标数量，4-5个 -->

                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <Col span="5">
              <div class="grey align_center">锦旗数/率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click="goDetail(item)"
            >
              <Col span="5" class="flex_line">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="14" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      actual: item.actual,
                      list: _DATA.line_values,
                      type: 3,
                    }"
                  ></chartJD>

                  <div class="chart_right"></div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>
              <!-- 锦旗数/率 -->
              <Col span="5">
                <div class="flex_line_center">
                  <span>{{ item.pennant_num }}</span>
                </div>
                <div class="flex_line_center">
                  <span>{{ item.pennant_rate + '%' }}</span>
                </div>
              </Col>
            </Row>
          </template>
        </div>
        <!-- 图例 -->
        <div
          class="p_15 flex_center"
          v-if="props.data?.now_year == props.data?.yearId && !props.data.depId"
        >
          <p class="box_8 grey_bg"></p>
          <p class="ml_5">目标</p>
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
          <p class="box_8 red2_bg ml_30"></p>
          <p class="ml_5">超额</p>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  inject,
  watch,
  onMounted,
  defineExpose,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: 2, //处在页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 2, //部门数据页面所处位置
  maxCount: 999, //超过则显示展开收起
  open: false,
  //-进度条相关
  line_values: [],
  middle_value: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depConstructionNum;
  let params = { time_type: 'now', year: props.data.yearId, column_num: 3 };
  if (props.data.depId) {
    url = $http.targetStatistic_personConstructionNum;
    params = {
      time_type: 'now',
      department_id: props.data.depId,
      year: props.data.yearId,
      column_num: 3,
    };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.info = {};
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */

const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depSiteData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};

//-规则说明--begin
const _rule = reactive({
  htmlTitle: '交付数_规则',
  htmlContent: '',
});
const createDanger = () => {
  let yearInfo = props.data.yearId + '年';
  let isNowYearInfo =
    props.data.yearId === props.data.now_year
      ? '当月底'
      : props.data.yearId + '-12-31';
  _rule.htmlContent = `<h5>数据定义：</h5>
<p>· 应交付：a + b + c</p>
<p class="pl_20">a. 合同完工日期<${isNowYearInfo}的未开工工地数；</p>
<p class="pl_20">b. 必须完工日期<${isNowYearInfo}的在建工地数；</p>
<p class="pl_20">c. 必须完工日期<${isNowYearInfo}且在${yearInfo}完工的工地数；</p>
<p>· 完成数：${yearInfo}完工工地数；</p>
<p>· 完成率：完成数/应交付数；</p>
<p>· 锦旗数：${yearInfo}累计获得的锦旗数；</p>
<p>· 锦旗率：${yearInfo}获得锦旗完工工地数/${yearInfo}完工工地数；</p>
`;
  emit('createDanger', 'deliveryRef');
};
defineExpose({ _rule });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
