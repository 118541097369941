<!--
 * @Author: gest
 * @Date: 2024-05-09 15:34:52
 * @LastEditTime: 2024-05-18 16:42:13
 * @Description: v1.6.0 进度组件坐标线
 {
    type: 1, //1有均值 2目标天数
    num: 60, //均值数或目标天数
    list: [0, 100, 200, 300], //坐标值
  },
-->
<template>
  <div
    style="
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
    "
  >
    <div
      class="line_item"
      :class="props.data.type == 2 ? 'border2' : 'border1'"
    ></div>
    <div
      class="line_item border2"
      :style="{
        left: props.data.list?.length == 5 ? '25%' : '33.3%',
      }"
    ></div>
    <div
      class="line_item border2"
      :style="{
        left: props.data.list?.length == 5 ? '50%' : '66.6%',
      }"
    ></div>
    <div
      class="line_item border2"
      :style="{
        left: props.data.list?.length == 5 ? '75%' : '100%',
      }"
    ></div>
    <div
      class="line_item border2"
      style="left: 100%"
      v-if="props.data.list?.length == 5"
    ></div>
    <div
      v-if="props.data.type"
      class="line_item"
      :class="props.data.type == 2 ? 'border4' : 'border3'"
      :style="{
        left: dis + '%',
      }"
    ></div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import * as _ from 'lodash';
import NP from 'number-precision';
const props = defineProps(['data']);
const dis = computed(() => {
  let result = 0;
  if (props.data.type) {
    //计算叠加线偏移
    let total =
      props.data.list?.length == 5
        ? NP.minus(parseFloat(props.data.list[4], props.data.list[0]))
        : NP.minus(parseFloat(props.data.list[3], props.data.list[0]));
    result = NP.times(
      _.floor(NP.divide(parseFloat(props.data.num), total), 4),
      100
    );
  }
  console.log(result);
  return result;
});
</script>
<style lang="less" scoped>
.line_item {
  height: 100%;
  position: absolute;
}
.border1 {
  border-left: 1px solid #dcdcdc;
}
.border2 {
  border-left: 1px dashed #dcdcdc;
}
.border3 {
  border-left: 1px dashed #dbaf00;
}
.border4 {
  border-left: 1px solid #000000;
}
</style>
