<!--
 * @Author: Jessica
 * @Date:2023-10-13 11:41:41
 * @LastEditTime: 2023-10-19 19:23:13
 * @Description: V1.4.7Plus绑定意向单
-->
<template>
  <!--绑定意向单-->
  <div class="page-wrap">
    <Overlay :show="state.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="top">
      <NavBar
        safe-area-inset-top
        title="绑定变更意向单"
        left-arrow
        @click-left="onClosePop"
      />
    </div>

    <div class="checkedList noBorder">
      <CheckboxGroup
        v-model="form.checked"
        v-if="form.list.length"
        :max="state.maxLength"
      >
        <List>
          <Cell>
            <div class="member_list" v-for="item in form.list" :key="item.id">
              <Checkbox :name="item.id" @click.stop="getClick(item)">
                <div class="member_item clearfix">
                  <span
                    class="n1"
                    :class="{
                      green: item.change_item_type == 1,
                      blue: item.change_item_type == 2,
                    }"
                  >
                    {{ item.change_item_type_name || '-' }}
                  </span>
                  <div class="flex">
                    <div class="n2">
                      <p>
                        <span class="t1">变更阶段：</span>
                        <span class="t2">{{
                          item.construction_stage_name || '-'
                        }}</span>
                      </p>
                      <p>
                        <span class="t1">变更类别：</span>
                        <span class="t2">
                          {{ item.change_type_names.join('/') || '-' }}
                        </span>
                      </p>
                      <p>
                        <span class="t1">预估金额：</span>
                        <span class="t2">{{ item.amount || '-' }}</span>
                      </p>
                    </div>
                    <span class="n3" @click.stop="getDetailFun(item.id)">
                      详情 >
                    </span>
                  </div>
                </div>
              </Checkbox>
            </div>
          </Cell>
        </List>
      </CheckboxGroup>
      <Empty
        v-if="!state.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="footer clearfix">
      <Button
        class="pull-right"
        type="primary"
        size="normal"
        style="width: 100%; margin: 9px 0px"
        @click="btnSumbit()"
      >
        确定
      </Button>
    </div>
    <!-- 详情组件 -->
    <Popup
      v-model:show="state.cardShow"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <NavBar
        safe-area-inset-top
        title="意向单详情"
        left-arrow
        @click-left="onCloseDetail"
        :fixed="true"
      />
      <div class="main_next">
        <contractChangePurposeView
          v-if="state.cardShow"
          :id="state.cardOptions.id"
        />
      </div>
    </Popup>
  </div>
</template>

<script setup>
import { reactive, inject, onMounted, defineProps, defineEmits } from 'vue';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  List,
  Cell,
  Empty,
  NavBar,
  Notify,
  Toast,
  Overlay,
  Loading,
  Popup,
} from 'vant';
import contractChangePurposeView from '@/views/contract/contractChangePurposeView';
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['reviewOk', 'reviewClose']);
const state = reactive({
  loading: false,
  maxLength: 10000,
  //-详情组件
  cardShow: false,
  cardOptions: { id: '' },
});
const form = reactive({
  checked: [],
  checkedList: props.data.list
    ? JSON.parse(JSON.stringify(props.data.list))
    : [],
  clickChecked: [],
  clickCheckedList: props.data.list
    ? JSON.parse(JSON.stringify(props.data.list))
    : [],
  list: [],
});
onMounted(() => {
  form.clickChecked = form.checked = form.checkedList.map((v) => {
    return v.id;
  });
  onLoad();
});

//-获取列表
const onLoad = () => {
  state.loading = true;
  $http
    .contract_getIntensions({
      change_item_type: props.data.change_item_type ?? '',
      customer_id: props.data.customer_id ?? '',
      construction_stage: props.data.construction_stage ?? '',
      page: 1,
      pages: 10000,
    })
    .then((res) => {
      state.loading = false;
      const { code, data } = res;
      if (code === 0) {
        form.list = data;
      } else {
        Toast(res.msg || data);
      }
    });
};

const getClick = (item) => {
  if (form.clickChecked.indexOf(item.id) > -1) {
    form.clickChecked.splice(form.clickChecked.indexOf(item.id), 1);
    form.clickCheckedList = form.clickCheckedList.filter((v) => {
      return v.id != item.id;
    });
  } else {
    if (form.clickChecked.length < state.maxLength) {
      form.clickChecked.push(item.id);
      form.clickCheckedList.push(item);
    } else {
      Notify({
        type: 'warning',
        message: `您最多只能选择${state.maxLength}个意向单，目前已超过限制啦`,
      });
    }
  }
};

//-查看详情
const getDetailFun = (id) => {
  state.cardShow = true;
  state.cardOptions.id = id;
};
//-关闭意向单详情
const onCloseDetail = () => {
  state.cardShow = false;
};
//-end

//-确认操作
const btnSumbit = () => {
  form.checkedList = JSON.parse(JSON.stringify(form.clickCheckedList));
  emit('reviewOk', form.checkedList);
};

//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
.page-wrap {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  .main_next {
    padding-top: 45px;
  }
  .top {
    width: 100%;
    position: fixed;
    z-index: 9;
    :deep(.van-nav-bar) {
      border-radius: 25px;
    }
  }
  .footer {
    width: 100%;
    height: 64px;
    position: fixed;
    background: #fff;
    z-index: 9;
    left: 0px;
    bottom: 0;
    box-shadow: 0px -1px 0px rgba(62, 67, 69, 0.08);
    padding: 0px 20px;
    box-sizing: border-box;
  }

  //-编辑
  .checkedList {
    position: relative;
    top: 50px;
    padding-bottom: 64px;
    .member_list {
      border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
      &:last-child {
        border-bottom: 0;
      }
      :deep(.van-checkbox__label) {
        width: 100%;
      }
      > div {
        padding: 16px 0px 10px;
        .member_item {
          padding: 0px 0px 0px 10px;
          border-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: left;

          .n1 {
            width: 40px;
            text-align: center;
            font-size: 12px;
            padding: 2px 6px;
            border-radius: 2px;
            display: inline-block;
            &.green {
              color: @green-color;
              background: @green-bg-color;
            }
            &.blue {
              color: @blue-color;
              background: @blue-bg-color;
            }
          }
          .flex {
            width: calc(100% - 50px);
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .n2 {
            padding: 0px 10px;
            > p {
              font-size: 15px;
              .t1 {
                color: #8d9091;
              }
              .t2 {
                color: #262626;
              }
            }
          }
          .n3 {
            width: 45px;
            display: inline-block;
            text-align: right;
            font-size: 13px;
            color: @blue-color;
          }
        }
      }
    }
  }
}
</style>
