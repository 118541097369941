/*
 * @Author: gest
 * @Date: 2023-06-17 15:14:00
 * @LastEditTime: 2024-05-24 10:21:40
 * @Description:V1.4.0 目标数据
 */
import Service from '@/utils/request';

export default {
  //公共参数
  targetStatistic_getParams(params) {
    return Service.post(`/rs/targetStatistic/getParams`, params);
  },
  //首页线索数
  targetStatistic_homeClue(params) {
    return Service.post(`/rs/targetStatistic/homeClue`, params);
  },
  //首页见面数
  targetStatistic_homeMeet(params) {
    return Service.post(`/rs/targetStatistic/homeMeet`, params);
  },
  //首页协议数
  targetStatistic_homeContract(params) {
    return Service.post(`/rs/targetStatistic/homeContract`, params);
  },
  //首页转化数
  targetStatistic_homeDesign(params) {
    return Service.post(`/rs/targetStatistic/homeDesign`, params);
  },
  //首页交付数
  targetStatistic_homeConstruction(params) {
    return Service.post(`/rs/targetStatistic/homeConstruction`, params);
  },
  //部门-交付数/交付率
  targetStatistic_depConstructionNum(params) {
    return Service.post(`/rs/targetStatistic/depConstructionNum`, params);
  },
  //人员-交付数/交付率
  targetStatistic_personConstructionNum(params) {
    return Service.post(`/rs/targetStatistic/personConstructionNum`, params);
  },
  //部门-线索数
  targetStatistic_depClueData(params) {
    return Service.post(`/rs/targetStatistic/depClueData`, params);
  },
  //人员-线索数
  targetStatistic_personClueData(params) {
    return Service.post(`/rs/targetStatistic/personClueData`, params);
  },
  //部门-当年交付工期
  targetStatistic_depConstructionSchedule(params) {
    return Service.post(`/rs/targetStatistic/depConstructionSchedule`, params);
  },
  //人员-当年交付工期
  targetStatistic_personConstructionSchedule(params) {
    return Service.post(
      `/rs/targetStatistic/personConstructionSchedule`,
      params
    );
  },
  //阶段工地数汇总
  targetStatistic_stageConstructionNum(params) {
    return Service.post(`/rs/targetStatistic/stageConstructionNum`, params);
  },
  //某个阶段工地数
  targetStatistic_getConstructionNumByStage(params) {
    return Service.post(
      `/rs/targetStatistic/getConstructionNumByStage`,
      params
    );
  },
  //部门-协议数/成功率
  targetStatistic_depContractNum(params) {
    return Service.post(`/rs/targetStatistic/depContractNum`, params);
  },
  //人员-协议数/成功率
  targetStatistic_personContractNum(params) {
    return Service.post(`/rs/targetStatistic/personContractNum`, params);
  },
  //转化-人员-见面数
  targetStatistic_clueCustomerFaceNum(params) {
    return Service.post(`/rs/targetStatistic/clueCustomerFaceNum`, params);
  },
  //部门-未开工和在建工地数
  targetStatistic_depConstructionDelayNum(params) {
    return Service.post(`/rs/targetStatistic/depConstructionDelayNum`, params);
  },
  //人员-未开工和在建工地数
  targetStatistic_personConstructionDelayNum(params) {
    return Service.post(
      `/rs/targetStatistic/personConstructionDelayNum`,
      params
    );
  },
  //转化-部门-非自主见面-协议时间
  targetStatistic_clueFaceContract(params) {
    return Service.post(`/rs/targetStatistic/clueFaceContract`, params);
  },
  //转化-人员-非自主见面-协议时间
  targetStatistic_depClueFaceContract(params) {
    return Service.post(`/rs/targetStatistic/depClueFaceContract`, params);
  },
  //转化-部门-转化数/转化率
  targetStatistic_depConvert(params) {
    return Service.post(`/rs/targetStatistic/depConvert`, params);
  },
  //转化-人员-转化数/转化率
  targetStatistic_personConvert(params) {
    return Service.post(`/rs/targetStatistic/personConvert`, params);
  },
  //转化-部门-转化时间
  targetStatistic_depConvertTime(params) {
    return Service.post(`/rs/targetStatistic/depConvertTime`, params);
  },
  //转化-人员-转化时间
  targetStatistic_personConvertTime(params) {
    return Service.post(`/rs/targetStatistic/personConvertTime`, params);
  },
  //转转化-设计阶段汇总
  targetStatistic_stageDesignNum(params) {
    return Service.post(`/rs/targetStatistic/stageDesignNum`, params);
  },
  //转化-设计阶段数据
  targetStatistic_getDesignNumByStage(params) {
    return Service.post(`/rs/targetStatistic/getDesignNumByStage`, params);
  },
  //部门-获客签约数
  targetStatistic_depClueContract(params) {
    return Service.post(`/rs/targetStatistic/depClueContract`, params);
  },
  //个人-获客签约数
  targetStatistic_personClueContract(params) {
    return Service.post(`/rs/targetStatistic/personClueContract`, params);
  },
  //部门-见面数
  targetStatistic_depMeetData(params) {
    return Service.post(`/rs/targetStatistic/depMeetData`, params);
  },
  //个人-见面数
  targetStatistic_personMeetData(params) {
    return Service.post(`/rs/targetStatistic/personMeetData`, params);
  },
  //部门-线索到见面
  targetStatistic_depClueToMeet(params) {
    return Service.post(`/rs/targetStatistic/depClueToMeet`, params);
  },
  //个人-线索到见面
  targetStatistic_personClueToMeet(params) {
    return Service.post(`/rs/targetStatistic/personClueToMeet`, params);
  },
  //部门-见面到签约
  targetStatistic_depMeetToSigning(params) {
    return Service.post(`/rs/targetStatistic/depMeetToSigning`, params);
  },
  //个人-见面到签约
  targetStatistic_personMeetToSigning(params) {
    return Service.post(`/rs/targetStatistic/personMeetToSigning`, params);
  },
  //【获客】- 二级页面 渠道线索数
  targetStatistic_channelSourceClue(params) {
    return Service.post(`/rs/targetStatistic/channelSourceClue`, params);
  },
  //【获客】- 二级页面 渠道线索质量
  targetStatistic_channelSourceQuality(params) {
    return Service.post(`/rs/targetStatistic/channelSourceQuality`, params);
  },
  //【获客】- 二级页面 渠道见面数
  targetStatistic_channelSourceMeet(params) {
    return Service.post(`/rs/targetStatistic/channelSourceMeet`, params);
  },
  //【获客】- 二级页面 渠道协议数
  targetStatistic_channelSourceContract(params) {
    return Service.post(`/rs/targetStatistic/channelSourceContract`, params);
  },
  //财务数据
  targetStatistic_financeData(params) {
    return Service.post(`/rs/targetStatistic/financeData`, params);
  },
  //业绩榜单
  targetStatistic_performanceRankList(params) {
    return Service.post(`/rs/targetStatistic/performanceRankList`, params);
  },
  //简单版首页转化数据
  targetStatistic_simpleConvert(params) {
    return Service.post(`/rs/targetStatistic/simpleConvert`, params);
  },
  //简版首页交付数据
  targetStatistic_simpleConstruction(params) {
    return Service.post(`/rs/targetStatistic/simpleConstruction`, params);
  },
  //简版首页线索数据
  targetStatistic_simpleClue(params) {
    return Service.post(`/rs/targetStatistic/simpleClue`, params);
  },
  //详版首页交付产值
  targetStatistic_homeConstructPerformance(params) {
    return Service.post(`/rs/targetStatistic/homeConstructPerformance`, params);
  },
  //详版首页转化产值
  targetStatistic_homeConvertPerformance(params) {
    return Service.post(`/rs/targetStatistic/homeConvertPerformance`, params);
  },
  //交付产值-部门
  targetStatistic_depConstructPerformance(params) {
    return Service.post(`/rs/targetStatistic/depConstructPerformance`, params);
  },
  //交付产值-人员
  targetStatistic_personConstructPerformance(params) {
    return Service.post(
      `/rs/targetStatistic/personConstructPerformance`,
      params
    );
  },
  //部门转化产值
  targetStatistic_depConvertPerformance(params) {
    return Service.post(`/rs/targetStatistic/depConvertPerformance`, params);
  },
  //人员转化产值
  targetStatistic_personConvertPerformance(params) {
    return Service.post(`/rs/targetStatistic/personConvertPerformance`, params);
  },
  //获客
  targetStatistic_channelSourceClueMeetSign(params) {
    return Service.post(
      `/rs/targetStatistic/channelSourceClueMeetSign`,
      params
    );
  },
  //获客渠道转化时间
  targetStatistic_channelSourceConversion(params) {
    return Service.post(`/rs/targetStatistic/channelSourceConversion`, params);
  },
  //交付-阶段动作数
  targetStatistic_getActionNumByStage(params) {
    return Service.post(`/rs/targetStatistic/getActionNumByStage`, params);
  },
};
