<!--
 * @Author: gest
 * @Date: 2024-06-13 10:51:59
 * @LastEditTime: 2024-06-14 15:10:17
 * @Description:V1.6.2 主材订单详情-结算付款记录
-->
<template>
  <div>
    <Overlay :show="_DATA.loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <div class="detail_box" v-for="(item, index) in _DATA.info" :key="index">
      <div class="flex_between align_center pt_15 pb_15">
        <div class="font_size_17 font_bold">
          {{ item.settlement_clause_name }}
        </div>
        <div
          class="status_tag"
          :class="
            item.status == 1 ? 'grey' : item.status == 2 ? 'orange' : 'green'
          "
        >
          {{ item.status_name }}
        </div>
      </div>
      <div
        class="flex_between align_center font_size_13 mb_5"
        v-if="props.type != 3"
      >
        <div>结算节点：{{ item.settlement_node_name }}</div>
        <div
          :class="item.settlement_node_done == 1 ? 'green_color' : 'grey_color'"
        >
          {{ item.settlement_node_done == 1 ? '完成' : '未完成' }}
        </div>
      </div>
      <div class="flex_between align_center font_size_13">
        <div>结算订单状态：{{ item.settlement_order_status_name }}</div>
        <div
          :class="
            item.settlement_node_status_done == 1 ? 'green_color' : 'grey_color'
          "
        >
          {{ item.settlement_node_status_done == 1 ? '完成' : '未完成' }}
        </div>
      </div>

      <div class="dec_box pt_15 border_top font_size_15 mt_15">
        <div class="dec_item" v-for="(val, i) in item.list" :key="i">
          <div class="flex_between align_center">
            <div class="display_flex align_center">
              <svg
                class="icon-small2 grey_color"
                aria-hidden="true"
                v-if="val.status == 1"
              >
                <use xlink:href="#icon-pause-circle-fill"></use>
              </svg>
              <svg
                class="icon-small2 blue_color"
                aria-hidden="true"
                v-if="val.status == 2"
              >
                <use xlink:href="#icon-play-circle-fill"></use>
              </svg>
              <svg
                class="icon-small2 green_color"
                aria-hidden="true"
                v-if="val.status == 3"
              >
                <use xlink:href="#icon-checkbox-circle-fill"></use>
              </svg>
              <div class="font_bold ml_10">{{ val.tag }}</div>
            </div>
            <div class="font_size_13 grey_color">
              {{ val.operate_time || '' }}
            </div>
          </div>
          <div class="display_flex person_box">
            <Image
              class="img_head"
              fit="cover"
              round
              :src="
                val?.user_face_image
                  ? val.user_face_image.includes('http')
                    ? val.user_face_image
                    : $host + val.user_face_image
                  : require('@/assets/images/icon-empty-img2.png')
              "
            ></Image>
            <div class="ml_10 flex1">
              <div>{{ val.user_name }}</div>
              <div class="font_size_13 grey_color">
                {{ getStr('/', val.user_dep_name, val.user_post_name) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail_box" v-if="!_DATA.info.length">
      <Empty image="search" description="没有数据" />
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, inject, onMounted } from 'vue';
import { Notify, Overlay, Empty, Loading, Image } from 'vant';

const $http = inject('$http');
const $host = inject('$host');
const props = defineProps(['id', 'type']);
const _DATA = reactive({
  loading: false,
  info: [],
});
onMounted(() => {
  loadInfo();
});
const loadInfo = () => {
  _DATA.loading = true;
  $http
    .mainOrder_settlementRecords({
      id: props.id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        _DATA.info = data;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
      setTimeout(() => {
        _DATA.loading = false;
      }, 500);
    });
};

//-@字符串拼接 smart添加
const getStr = (split_str, ...arr) => {
  const new_arr = arr.filter((item) => item !== '');
  return new_arr?.length > 0 ? new_arr.join(split_str) : '';
};
</script>
<style lang="less" scoped>
.detail_box {
  background-color: @white-color;
  padding: 0 16px;
  margin-top: 15px;
  .icon-small2 {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }
  .img_head {
    width: 42px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #efefef;
  }
  .person_box {
    padding: 10px 0 20px 26px;
  }
  .dec_item {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 8px;
      top: 20px;
      border-left: 1px dashed #d8d8d8;
      height: calc(100% - 16px);
    }
    &:last-child {
      &::after {
        border-left: none;
      }
    }
  }
}
</style>
