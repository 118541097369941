<!--
 * @Author: gest
 * @Date: 2023-03-18 11:01:18
 * @LastEditTime: 2024-06-14 11:18:55
 * @Description: v1.3.4施工见证件填写&验收
-->
<template>
  <div
    class="page-box"
    :class="[
      {
        btnBig:
          (state.detail.can_check == 1 ||
            state.detail.can_submit == 1 ||
            state.detail.can_start == 1 ||
            state.detail.can_notice == 1) &&
          (state.detail.change_confirm != 1 ||
            state.detail.change_confirm_status != 1),
      },
      {
        prop: props.data,
      },
    ]"
  >
    <Overlay :show="state.loading || state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <div class="detail" v-if="!state.loading">
      <!-- 提示信息 -->
      <div
        class="tip_box"
        v-if="
          state.detail.change_confirm == 1 &&
          state.detail.change_confirm_status == 1
        "
      >
        <div class="tip_info">
          <Icon name="warning" color="#F37020" size="14" />
          <p class="tip_text">需先完成[工程&产品变更确认]才可操作见证件</p>
        </div>
        <p class="blue_color pr_15" @click="btnClick()">去处理>></p>
      </div>
      <!-- 见证件基础详情 begin-->
      <div class="base_info mb_15">
        <p class="name">{{ state.detail.name }}</p>
        <p>
          客户：{{ state.detail.customer_name }}
          <span class="ml_10" v-if="state.detail.size">
            {{ state.detail.estate_str + ' ' + state.detail.size + '㎡' }}
          </span>
          <span class="ml_10" v-else>
            {{ state.detail.estate_str }}
          </span>
        </p>
        <p>施工节点：{{ state.detail.node_name }}</p>
      </div>
      <!-- 见证件基础详情 end-->

      <!-- 详情表单 begin-->
      <Form ref="ruleFormRef">
        <!-- sub_type
        1通用 2概念方案预演会 3效果方案预演会 4产品方案预演会 5施工方案预演会  -->
        <!-- 通用附件 begin-->
        <commonWitness
          v-if="state.detail.sub_type == 1"
          :data="form.form_info"
          :can_edit="state.detail.can_edit"
          @update:data="updateForm"
        ></commonWitness>
        <!-- 通用附件 end-->
        <!-- 开工交底验收单 begin-->
        <jdysdWitness
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 5 &&
            state.detail.sub_type < 17 &&
            state.detail.can_edit == 1
          "
          @update:data="updateForm"
        />
        <!-- 开工交底验收单 详情 begin-->
        <jdysdWitnessDetail
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 5 &&
            state.detail.sub_type < 17 &&
            state.detail.can_edit == 0
          "
        />
        <!--  end-->
        <!-- 进场材料记录单 begin-->
        <jccljyWitness
          :data="form.form_info"
          v-if="state.detail.sub_type == 25 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 进场材料记录单 详情 begin-->
        <jccljyWitnessDetail
          :data="form.form_info"
          v-if="state.detail.sub_type == 25 && state.detail.can_edit == 0"
        />
        <!--  end-->
        <!-- 交底单 begin-->
        <jdjldWitness
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            (state.detail.sub_type == 26 || state.detail.sub_type == 27) &&
            state.detail.can_edit == 1
          "
          @update:data="updateForm"
        />
        <!-- 交底单 详情 begin-->
        <jdjldWitnessDetail
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            (state.detail.sub_type == 26 || state.detail.sub_type == 27) &&
            state.detail.can_edit == 0
          "
        />
        <!--  end-->
        <!-- 场地交接单 begin-->
        <cdjjdWitness
          :data="form.form_info"
          v-if="state.detail.sub_type == 28 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 场地交接单 详情 begin-->
        <cdjjdWitnessDetail
          :data="form.form_info"
          v-if="state.detail.sub_type == 28 && state.detail.can_edit == 0"
        />
        <!--  end-->
        <!-- 防水验收记录表 begin-->
        <fsysWitness
          :data="form.form_info"
          v-if="state.detail.sub_type == 29 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 防水验收记录表 详情 begin-->
        <fsysWitnessDetail
          :data="form.form_info"
          v-if="state.detail.sub_type == 29 && state.detail.can_edit == 0"
        />
        <!--  end-->
        <!-- 产品二次交底单 begin-->
        <cpecjddWitness
          :data="form.form_info"
          v-if="state.detail.sub_type == 30 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 产品二次交底单 详情 begin-->
        <cpecjddWitnessDetail
          :data="form.form_info"
          v-if="state.detail.sub_type == 30 && state.detail.can_edit == 0"
        />
        <!--  end-->
        <!-- 工期确认单 begin-->
        <gqqrdWitness
          :data="form.form_info"
          :contract_id="form.contract_id"
          :info="state.detail"
          v-if="state.detail.sub_type == 31 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 工期确认单 详情 begin-->
        <gqqrdWitnessDetail
          :data="form.form_info"
          :info="state.detail"
          v-if="state.detail.sub_type == 31 && state.detail.can_edit == 0"
        />
        <!--  end-->
        <!-- 产品安装计划表 begin-->
        <cpazjhbWitness
          :data="form.form_info"
          v-if="state.detail.sub_type == 32 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 产品安装计划表 详情 begin-->
        <cpazjhbWitnessDetail
          :data="form.form_info"
          v-if="state.detail.sub_type == 32 && state.detail.can_edit == 0"
        />
        <!--  end-->
        <!-- 产品下单记录表 begin-->
        <cpxdjlbWitness
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 16 &&
            state.detail.sub_type < 20 &&
            state.detail.can_edit == 1
          "
          @update:data="updateForm"
        />
        <!-- 产品下单记录表 详情 begin-->
        <cpxdjlbWitnessDetail
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 16 &&
            state.detail.sub_type < 20 &&
            state.detail.can_edit == 0
          "
        />
        <!--  end-->
        <!-- 进场产品验收记录单 begin-->
        <jccpysWitness
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 19 &&
            state.detail.sub_type < 22 &&
            state.detail.can_edit == 1
          "
          @update:data="updateForm"
        />
        <!-- 进场产品验收记录单 详情 begin-->
        <jccpysWitnessDetail
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 19 &&
            state.detail.sub_type < 22 &&
            state.detail.can_edit == 0
          "
        />
        <!--  end-->
        <!-- 家庭居室/优选整装阶段结算单 begin-->
        <jtjsjdjsdWitness
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="
            state.detail.sub_type > 21 &&
            state.detail.sub_type < 25 &&
            state.detail.can_edit == 1
          "
          @update:data="updateForm"
        />
        <!-- 家庭居室/优选整装阶段结算单 详情 begin-->
        <jtjsjdjsdWitnessDetail
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          :witness_status="state.detail.witness_status"
          v-if="
            state.detail.sub_type > 21 &&
            state.detail.sub_type < 25 &&
            state.detail.can_edit == 0
          "
        />
        <!--  end-->
        <!-- 三级安全教育承诺书 begin-->
        <sjaqjyWitness
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="state.detail.sub_type == 33 && state.detail.can_edit == 1"
          @update:data="updateForm"
        />
        <!-- 三级安全教育承诺书 详情 begin-->
        <sjaqjyWitnessDetail
          :data="form.form_info"
          :sub_type="state.detail.sub_type"
          v-if="state.detail.sub_type == 33 && state.detail.can_edit == 0"
        />
        <!--  end-->
      </Form>
      <!-- 详情表单 end-->

      <!-- 供应商选择 begin-->
      <div
        class="supplier_list"
        v-if="
          state.detail.can_submit == 1 && state.detail.has_supplier_role == 1
        "
      >
        <!-- 选择供应商按钮 -->
        <div class="supplier_radio mb_15">
          <!-- 标题 -->
          <p class="title b_b">
            <span class="red">*</span>是否有公司合作供应商确认
          </p>
          <!-- 按钮 -->
          <RadioGroup v-model="checkSupplierForm.supplier_type">
            <div
              class="supplier_item"
              v-for="item in options.supplier_type"
              :key="item.id"
            >
              <Radio :name="item.id" @click.stop="handleSingleBtn(item)">
                <p>{{ item.name }}</p>
              </Radio>
            </div>
          </RadioGroup>
          <!-- 报错提示 -->
          <p
            class="error_text"
            v-if="checkSupplierForm.supplier_type === '' && state.isValiate"
          >
            请选择是否选择供应商
          </p>
        </div>
        <!-- 提示信息 -->
        <p class="tip_text" v-if="checkSupplierForm.supplier_type == 1">
          提交见证件前请先选择需确认供应商，供应商确认不分先后，同时确认！
        </p>
        <!-- 已选供应商 -->
        <div
          class="supplier_checked mb_15"
          v-if="checkSupplierForm.supplier_type == 1"
        >
          <p class="title1"><span class="red">*</span>供应商</p>
          <!-- 供应商缩略 有数据-->
          <div
            class="top_checked"
            v-if="checkSupplierForm.supplier_list?.length"
          >
            <div class="list" v-if="checkSupplierForm.supplier_list?.length">
              <div class="item">
                <p v-for="i in checkSupplierForm.supplier_list" :key="i.id">
                  <span>{{ i.name }}</span>
                  <span @click.stop="delSupFun(i.id)">
                    <Icon name="cross" class="icon_12" />
                  </span>
                </p>
              </div>
            </div>
            <span @click.stop="selectSupFun()">
              <Icon name="arrow" class="icon_16" />
            </span>
          </div>
          <!-- 供应商缩略 无数据-->
          <div
            class="top_checked flex_around"
            v-else
            @click.stop="selectSupFun()"
          >
            <div class="no_text">
              <p class="tip">请选择供应商</p>
              <!-- 报错提示 -->
              <p class="error_text" v-if="state.isValiate">请选择供应商</p>
            </div>
            <Icon name="arrow" class="icon_16" />
          </div>
          <!-- 供应商详细 -->
          <div class="bottom_checked">
            <div
              class="item"
              v-for="i in checkSupplierForm.supplier_list"
              :key="i.id"
            >
              <div class="line">
                <!-- 供应商logo -->
                <Image
                  class="imgIcon"
                  v-if="i.logo"
                  :src="i.logo.includes('http') ? i.logo : $host + i.logo"
                  fit="cover"
                  position="center"
                />
                <span v-else class="pull-left logo">
                  {{ i.name ? i.name.substr(0, 1) : '' }}
                </span>
                <!-- end -->
                <div class="info flex1">
                  <p class="n1">
                    {{ i.name }}
                  </p>
                  <p class="n3">
                    <span class="n2">{{ i.full_name }}</span>
                  </p>
                  <p class="n3">
                    <span class="n2">{{ i.category_name_str }}</span>
                  </p>
                  <p class="n3">
                    <span class="n2">{{ i.brand_name_str }}</span>
                  </p>
                  <p class="n3">
                    <span class="n2">{{ i.responsible_name }}</span>
                    <span class="n2">/{{ i.responsible_phone }}</span>
                  </p>
                </div>
              </div>
              <p @click.stop="delSupFun(i.id)">
                <Icon name="cross" class="icon_16" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mb_15 supplier_list"
        style="background: #ffffff"
        v-if="state.detail.can_edit == 0 && state.detail.has_supplier_role == 1"
      >
        <div
          class="flex_between align_center"
          style="margin: 0px 5.3vw; border-bottom: 1px solid #f5f5f5"
        >
          <!-- 标题 -->
          <div class="grey_color" style="font-size: 15px; padding: 4vw 0px">
            是否有公司合作供应商确认
          </div>
          <div>
            {{
              checkSupplierForm.supplier_type == null
                ? '-'
                : checkSupplierForm.supplier_type == 1
                ? '是'
                : '否'
            }}
          </div>
        </div>
        <div v-if="checkSupplierForm.supplier_type == 1">
          <div class="supplier_checked">
            <!-- 标题 -->
            <div
              class="flex_between align_center grey_color"
              style="font-size: 15px; padding: 15px 20px 0 20px"
            >
              合作供应商
            </div>
            <!-- 供应商详细 -->
            <div class="bottom_checked">
              <div
                class="item"
                v-for="i in checkSupplierForm.supplier_list"
                :key="i.id"
              >
                <div class="line">
                  <!-- 供应商logo -->
                  <Image
                    class="imgIcon"
                    v-if="i.logo"
                    :src="i.logo.includes('http') ? i.logo : $host + i.logo"
                    fit="cover"
                    position="center"
                  />
                  <span v-else class="pull-left logo">
                    {{ i.name ? i.name.substr(0, 1) : '' }}
                  </span>
                  <!-- end -->
                  <div class="info flex1">
                    <p class="n1">{{ i.name }}</p>
                    <p class="n3">
                      <span class="n2">{{ i.full_name }}</span>
                    </p>
                    <p class="n3">
                      <span class="n2">{{ i.category_name_str }}</span>
                    </p>
                    <p class="n3">
                      <span class="n2">{{ i.brand_name_str }}</span>
                    </p>
                    <p class="n3">
                      <span class="n2">{{ i.responsible_name }}</span>
                      <span class="n2">/{{ i.responsible_phone }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 供应商选择 end-->
      <!-- 自购供应商 -->
      <div
        class="mb_15"
        v-if="
          state.detail.can_submit == 1 && state.detail.has_supplier_role == 1
        "
      >
        <div style="padding: 0px 5.3vw 1.3vw; background: #ffffff">
          <!-- 标题 -->
          <p
            style="
              color: #000000;
              font-size: 15px;
              padding: 4vw 0px;
              border-bottom: 1px solid #f5f5f5;
            "
          >
            <span class="red_color">*</span>是否有客户自购供应商确认
          </p>
          <!-- 按钮 -->
          <RadioGroup
            v-model="state.detail.is_self_supplier_check"
            @change="changeRadio()"
          >
            <div
              style="
                border-bottom: 1px solid #f5f5f5;
                padding: 12px 0;
                display: block;
              "
            >
              <Radio :name="1">
                <p>是</p>
              </Radio>
            </div>
            <div style="padding: 12px 0; display: block">
              <Radio :name="0">
                <p>否</p>
              </Radio>
            </div>
          </RadioGroup>
        </div>
        <div
          v-if="state.detail.is_self_supplier_check == 1"
          style="border-top: 1px solid #f5f5f5"
        >
          <!-- <p style="padding: 0px 25px 10px; font-size: 13px; color: #f37020">
            提交见证件前请先填写自购供应商，自购供应商验收默认由项目经理代替验收！
          </p> -->
          <div
            class="mb_15"
            style="padding: 0px 5.3vw 1.3vw; background: #ffffff"
          >
            <!-- 标题 -->
            <div
              class="flex_between align_center"
              style="color: #000000; font-size: 15px; padding: 15px 0px"
            >
              <div><span class="red_color">*</span>自购供应商</div>
              <div
                class="blue_color flex_between align_center"
                @click="addSupplierPop"
              >
                <svg
                  aria-hidden="true"
                  style="width: 16px; height: 16px; fill: currentColor"
                >
                  <use xlink:href="#icon-add-fill"></use>
                </svg>
                添加
              </div>
            </div>
            <div
              style="display: flex; flex-wrap: wrap; padding-bottom: 9px"
              v-if="
                state.detail.self_supplier_names &&
                state.detail.self_supplier_names.length > 0
              "
            >
              <div
                class="blue_color"
                style="
                  padding: 4px 0 4px 10px;
                  background: rgba(0, 82, 217, 0.1);
                  border: 1px solid rgba(0, 82, 217, 0.2);
                  margin: 0px 6px 6px 0px;
                  display: flex;
                  align-items: center;
                  line-height: 20px;
                "
                v-for="(i, index) in state.detail.self_supplier_names"
                :key="index"
              >
                <span>{{ i }}</span>
                <Icon
                  name="cross"
                  :size="14"
                  style="padding: 3px 7px"
                  @click.stop="delSupplier(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mb_15"
        style="background: #ffffff"
        v-if="state.detail.can_edit == 0 && state.detail.has_supplier_role == 1"
      >
        <div
          class="flex_between align_center"
          style="margin: 0px 5.3vw; border-bottom: 1px solid #f5f5f5"
        >
          <!-- 标题 -->
          <div class="grey_color" style="font-size: 15px; padding: 4vw 0px">
            是否有客户自购供应商确认
          </div>
          <div>
            {{
              state.detail.is_self_supplier_check == null
                ? '-'
                : state.detail.is_self_supplier_check == 1
                ? '是'
                : '否'
            }}
          </div>
        </div>
        <div v-if="state.detail.is_self_supplier_check == 1">
          <div style="padding: 0px 5.3vw 1.3vw; background: #ffffff">
            <!-- 标题 -->
            <div
              class="flex_between align_center grey_color"
              style="font-size: 15px; padding: 15px 0px"
            >
              自购供应商
            </div>
            <div
              style="display: flex; flex-wrap: wrap; padding-bottom: 9px"
              v-if="
                state.detail.self_supplier_names &&
                state.detail.self_supplier_names.length > 0
              "
            >
              <div
                class="blue_color"
                style="
                  padding: 4px 10px 4px 10px;
                  background: rgba(0, 82, 217, 0.1);
                  border: 1px solid rgba(0, 82, 217, 0.2);
                  margin: 0px 6px 6px 0px;
                  display: flex;
                  align-items: center;
                  line-height: 20px;
                "
                v-for="(i, index) in state.detail.self_supplier_names"
                :key="index"
              >
                {{ i }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 自购供应商 end -->
      <!-- 流程 begin-->
      <witnessLinker
        :data="{
          check_info: state.check_info,
          type: 2,
          witness_id: state.witness_id,
          node_id: state.node_id,
        }"
      >
      </witnessLinker>
      <!-- 流程  end-->

      <!--审批操作 begin-->
      <div
        class="operate_box"
        v-if="
          (state.detail.can_check == 1 ||
            state.detail.can_submit == 1 ||
            state.detail.can_start == 1 ||
            state.detail.can_notice == 1) &&
          (state.detail.change_confirm != 1 ||
            state.detail.change_confirm_status != 1)
        "
      >
        <!-- 
          发起人：内容：【查看表单】、操作：【发起验收、取消】
          提交人：内容：【编辑表单】、操作：【保存、提交/重新提交、驳回、取消】
          确认人：内容：【查看表单、备注、通过/不通过】、操作：【取消、确认】 
          -->
        <!-- 发起人：发起验收 begin-->
        <!--@ 发起备注 -->
        <div class="mb_20" v-if="state.detail.can_start == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- 操作 -->
        <Row v-if="state.detail.can_start == 1">
          <Col span="24">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="editStart()"
            >
              发起验收
            </Button>
          </Col>
        </Row>
        <!-- end -->

        <!--提交人  begin-->

        <!--@ 提交备注 -->
        <div class="mb_20" v-if="state.detail.can_submit == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- 操作:判断有无发起人 -->
        <Row
          v-if="state.detail.can_submit == 1 && state.detail.has_starter == 1"
        >
          <Col span="8" class="p_7">
            <Button
              type="default"
              size="large"
              class="button-state"
              @click="editSave()"
            >
              保存
            </Button>
          </Col>
          <Col span="8" class="p_7">
            <Button
              type="danger"
              size="large"
              class="button-state"
              @click="editRejust()"
            >
              驳回
            </Button>
          </Col>
          <Col span="8" class="p_7">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnSumbit()"
            >
              提交
            </Button>
          </Col>
        </Row>
        <Row
          v-if="state.detail.can_submit == 1 && state.detail.has_starter == 0"
        >
          <Col span="12" class="p_7">
            <Button
              type="default"
              size="large"
              class="button-state"
              @click="editSave()"
            >
              保存
            </Button>
          </Col>

          <Col span="12" class="p_7">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="btnSumbit()"
            >
              提交
            </Button>
          </Col>
        </Row>
        <!-- end -->

        <!-- 验收 begin -->
        <!-- @验收备注  -->
        <div class="mb_20" v-if="state.detail.can_check == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- @验收操作 -->
        <Row v-if="state.detail.can_check == 1">
          <Col span="11">
            <Button
              type="danger"
              size="large"
              class="button-danger"
              @click.stop="btnCheckSumbit(4)"
            >
              <Icon name="cross" />
              不通过
            </Button>
          </Col>
          <Col span="2"></Col>
          <Col span="11">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click.stop="btnCheckSumbit(3)"
            >
              <Icon name="success" />
              通过
            </Button>
          </Col>
        </Row>
        <!--知会人 -->
        <div class="mb_20" v-if="state.detail.can_notice == 1">
          <Field
            v-model="form.remark"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="需要知会见证件流转人在此补充....."
            show-word-limit
          />
        </div>
        <!-- 操作 -->
        <Row v-if="state.detail.can_notice == 1">
          <Col span="24">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="editNotice()"
            >
              已知晓，通过
            </Button>
          </Col>
        </Row>
        <!-- end -->
      </div>
      <!-- end -->
    </div>

    <!-- 组件集合 -->
    <div>
      <!-- 供应商选择组件 -->
      <Popup
        v-model:show="state.supplierOption.show"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '80%' }"
      >
        <SupplierList
          v-if="state.supplierOption.show"
          :data="state.supplierOption.list"
          :node_id="state.node_id"
          :company_id="state.supplierOption.company_id"
          @reviewClose="reviewClose"
          @reviewOk="reviewOk"
        />
      </Popup>
      <!-- 添加空间 -->
      <Popup v-model:show="state.addSupplierShow" position="bottom">
        <div class="field_pop">
          <Field
            ref="supplierRef"
            v-model="state.supplier_name"
            label=""
            placeholder="填写自购供应商名称"
            maxlength="20"
          >
          </Field>
          <Button type="primary" size="large" @click="saveSupplierPop"
            >确定</Button
          >
        </div>
      </Popup>
      <!-- 工程&产品变更确认 -->
      <changeConfirm
        ref="changeConfirmRef"
        :data="state.changeConfirm"
        :isShow="state.changeConfirm.isShow"
        @closePop="
          () => {
            state.changeConfirm.isShow = false;
          }
        "
        @update:func="
          () => {
            state.changeConfirm.isShow = false;
            getDetail();
          }
        "
      ></changeConfirm>
    </div>
    <!-- end -->
  </div>
</template>

<script setup>
import {
  reactive,
  inject,
  onMounted,
  ref,
  defineProps,
  defineEmits,
} from 'vue';
import {
  Field,
  Row,
  Col,
  Icon,
  Button,
  Toast,
  Loading,
  Form,
  Overlay,
  Notify,
  Dialog,
  RadioGroup,
  Radio,
  Popup,
  Image,
} from 'vant';

import { useRouter } from 'vue-router';

import witnessLinker from '@/components/witness/witnessLinker';
import commonWitness from '@/components/witness/commonWitness';
import SupplierList from '@/components/witness/supplierList'; //-供应商选择列表
import jdysdWitness from '@/components/witness/site/jdysdWitness';
import jdysdWitnessDetail from '@/components/witness/site/jdysdWitnessDetail';
import jccljyWitness from '@/components/witness/site/jccljyWitness';
import jccljyWitnessDetail from '@/components/witness/site/jccljyWitnessDetail';
import jdjldWitness from '@/components/witness/site/jdjldWitness';
import jdjldWitnessDetail from '@/components/witness/site/jdjldWitnessDetail';

import cdjjdWitness from '@/components/witness/site/cdjjdWitness';
import cdjjdWitnessDetail from '@/components/witness/site/cdjjdWitnessDetail';

import fsysWitness from '@/components/witness/site/fsysWitness';
import fsysWitnessDetail from '@/components/witness/site/fsysWitnessDetail';

import cpecjddWitness from '@/components/witness/site/cpecjddWitness';
import cpecjddWitnessDetail from '@/components/witness/site/cpecjddWitnessDetail';
import gqqrdWitness from '@/components/witness/site/gqqrdWitness';
import gqqrdWitnessDetail from '@/components/witness/site/gqqrdWitnessDetail';
import cpazjhbWitness from '@/components/witness/site/cpazjhbWitness';
import cpazjhbWitnessDetail from '@/components/witness/site/cpazjhbWitnessDetail';
import cpxdjlbWitness from '@/components/witness/site/cpxdjlbWitness';
import cpxdjlbWitnessDetail from '@/components/witness/site/cpxdjlbWitnessDetail';
import jccpysWitness from '@/components/witness/site/jccpysWitness';
import jccpysWitnessDetail from '@/components/witness/site/jccpysWitnessDetail';
import jtjsjdjsdWitness from '@/components/witness/site/jtjsjdjsdWitness';
import jtjsjdjsdWitnessDetail from '@/components/witness/site/jtjsjdjsdWitnessDetail';

import sjaqjyWitness from '@/components/witness/site/sjaqjyWitness';
import sjaqjyWitnessDetail from '@/components/witness/site/sjaqjyWitnessDetail';

import changeConfirm from '@/components/construction/changeConfirm.vue';

const props = defineProps(['data']);
const emit = defineEmits(['reviewOk']);
const $http = inject('$http');
const $host = inject('$host');
const router = useRouter();
const state = reactive({
  node_id: props.data
    ? props.data.node_id
    : router.currentRoute.value.query.node_id,
  witness_id: props.data
    ? props.data.witness_id
    : router.currentRoute.value.query.witness_id,
  loading: false,
  loadingConfirm: false,
  detail: {},
  check_info: [],
  //-供应商组件
  isValiate: false,
  supplierOption: {
    show: false,
    list: [],
    company_id: '',
  },
  addSupplierShow: false,
  supplier_name: '',
  changeConfirm: { isShow: false },
});
const changeConfirmRef = ref(null);
const supplierRef = ref(null);
//--基本信息表单-------begin-----
const ruleFormRef = ref(null);
const form = reactive({
  form_info: null,
  remark: '',
  contract_id: '',
});
//--基本信息表单-----end--------

//-供应商选择-----begin------
//-@参数
const options = reactive({
  supplier_type: [
    {
      id: 1,
      name: '是',
    },
    {
      id: 0,
      name: '否',
    },
  ],
  supplier_list: [],
});

//-@表单
const checkSupplierForm = reactive({
  supplier_type: '',
  supplier_list: [],
  supplier_ids: [],
});

//-@是否选择供应商--清空已选供应商列表
const handleSingleBtn = (i) => {
  checkSupplierForm.supplier_type = i.id;
  checkSupplierForm.supplier_list = [];
  state.isValiate = false;
};
const changeRadio = () => {
  if (state.detail.is_self_supplier_check == 0) {
    state.detail.self_supplier_names = [];
  }
};
//-@删除已选供应商
const delSupFun = (id) => {
  state.isValiate = true;
  checkSupplierForm.supplier_list = checkSupplierForm.supplier_list.filter(
    (v) => {
      return v.id != id;
    }
  );
  checkSupplierForm.supplier_ids = checkSupplierForm.supplier_list.map((i) => {
    return i.id;
  });
};
//-@选择供应商
const selectSupFun = () => {
  state.isValiate = true;
  state.supplierOption.show = true;
  state.supplierOption.list = checkSupplierForm.supplier_list;
};
//-保存供应商
const reviewOk = (data) => {
  checkSupplierForm.supplier_list = data;
  state.supplierOption.show = false;
  checkSupplierForm.supplier_ids = data.map((i) => {
    return i.id;
  });
};

//-关闭供应商
const reviewClose = () => {
  state.supplierOption.show = false;
};
//-end

onMounted(() => {
  getDetail();
});

//-获取详情数据
const getDetail = () => {
  state.loading = true;
  $http
    .witness_nodeInfo({
      witness_id: state.witness_id,
      type: 2,
      node_id: state.node_id,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        state.detail = res.data;
        //测试用
        // state.detail.can_edit = 1;
        // state.detail.can_submit = 1;
        form.form_info = res.data.form_info;
        state.check_info = res.data.check_info;
        form.contract_id = res.data.contract_id;
        //-根据公司id获取供应商列表
        state.supplierOption.company_id = res.data.company_id;
        //供应商详情
        checkSupplierForm.supplier_ids = res.data.supplier_ids;
        checkSupplierForm.supplier_type = res.data.is_supplier_check;
        checkSupplierForm.supplier_list = res.data.supplier_list;
      } else {
        Toast(res.data);
      }
    });
};
const updateForm = (val) => {
  form.form_info = val;
};
//自购供应商填写
const addSupplierPop = () => {
  state.addSupplierShow = true;
  state.supplier_name = '';
  setTimeout(() => {
    supplierRef.value.focus();
  }, 100);
};
const saveSupplierPop = () => {
  if (state.supplier_name) {
    state.addSupplierShow = false;
    state.detail.self_supplier_names.unshift(state.supplier_name);
  } else {
    Notify({
      type: 'warning',
      message: '请填写自购供应商名称',
    });
  }
};
const delSupplier = (index) => {
  state.detail.self_supplier_names.splice(index, 1);
};
//-获取除放线、文施保护验收单外10个见证件不合格验收项
const getArray = (data) => {
  let arr = [];
  data &&
    data.map((i) => {
      if (i.value == 1) {
        i.children.filter((v) => {
          if (v.value == 2) {
            arr.push(v);
          }
        });
      }
    });
  return arr;
};

//-获取放线、文施保护验收单见证件不合格验收项
const getArrayFX = (data) => {
  let arr = [];
  data &&
    data.map((i) => {
      i.children.filter((v) => {
        if (v.value == 2) {
          arr.push(v);
        }
      });
    });
  return arr;
};
//-进场产品验收见证件不合格验收项
const getArrayFX2 = (data) => {
  let arr = [];
  data &&
    data.map((i) => {
      if (i.value1 == 1 && i.value3 == 2) {
        arr.push(i);
      }
    });
  return arr;
};
const limitForm = () => {
  let result = true;
  //
  // 开工交底验收单等11个见证件验证:需要所有的项目都合格才能提交
  if (state.detail.sub_type > 5 && state.detail.sub_type < 17) {
    let basic = form.form_info?.basic_info ?? [];

    let fail_list = [];
    if (state.detail.sub_type == 7) {
      fail_list = getArrayFX(basic);
    } else {
      fail_list = getArray(basic);
    }

    if (fail_list?.length) {
      Notify({
        type: 'warning',
        message: '所有验收项合格或无此项才能提交，请完善见证件！',
        duration: 1500,
      });

      result = false;
    }
  }
  // 防水验收记录表:需要所有的项目都合格才能提交
  if (state.detail.sub_type == 29) {
    let basic = form.form_info?.basic_info ?? [];

    let fail_list = basic.filter((v) => {
      return v.value == 2;
    });

    if (
      fail_list?.length ||
      form.form_info?.check_result == 2 ||
      form.form_info?.check_pass == 2
    ) {
      Notify({
        type: 'warning',
        message: '所有验收项合格才能提交，请完善见证件！',
        duration: 1500,
      });

      result = false;
    }
  }
  // 产品二次交底单:所有交底项需填写且没有【无交底】才可提交
  if (state.detail.sub_type == 30) {
    let basic = form.form_info?.basic_info ?? [];

    let fail_list = getArrayFX(basic);

    if (fail_list?.length) {
      Notify({
        type: 'warning',
        message: '所有交底项需没有【未交底】才可提交！',
        duration: 1500,
      });

      result = false;
    }
  }
  //进场产品验收记录单
  if (state.detail.sub_type > 19 && state.detail.sub_type < 22) {
    let basic = form.form_info?.basic_info ?? [];

    let fail_list = getArrayFX2(basic);

    if (fail_list?.length) {
      Notify({
        type: 'warning',
        message: '所有验收项合格才能提交，请完善见证件！',
        duration: 1500,
      });

      result = false;
    }
  }
  //自购供应商验收必填
  if (state.detail.can_submit == 1 && state.detail.has_supplier_role == 1) {
    if (state.detail.is_self_supplier_check == null) {
      Notify({
        type: 'warning',
        message: '请选择是否需要客户自购供应商验收！',
        duration: 1500,
      });

      result = false;
    } else {
      if (state.detail.is_self_supplier_check == 1) {
        if (
          !state.detail.self_supplier_names ||
          state.detail.self_supplier_names.length <= 0
        ) {
          Notify({
            type: 'warning',
            message: '请添加客户自购供应商！',
            duration: 1500,
          });

          result = false;
        }
      }
    }
  }
  return result;
};
//跳转工程&产品变更确认
const btnClick = () => {
  state.changeConfirm.isShow = true;
  state.changeConfirm.node_id = state.node_id;
};

//-提交操作
const btnSumbit = () => {
  //前置限制条件
  if (!limitForm()) {
    return;
  }
  //去除冗余字段
  dealArrow();
  state.loadingConfirm = true;
  state.isValiate = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      Dialog.confirm({
        message: '请确保数据填写准确无误，是否提交？',
      })
        .then(() => {
          if (state.detail.has_supplier_role == 1) {
            if (checkSupplierForm.supplier_type === '') {
              state.loadingConfirm = false;
              Notify({
                type: 'warning',
                message: '请完善供应商信息',
                duration: 1000,
              });
              return;
            } else if (checkSupplierForm.supplier_type == 1) {
              if (checkSupplierForm.supplier_ids?.length == 0) {
                state.loadingConfirm = false;
                Notify({
                  type: 'warning',
                  message: '请选择供应商',
                  duration: 1000,
                });
                return;
              }
            }
          }

          $http
            .witness_deal({
              witness_id: state.witness_id,
              type: 2,
              node_id: state.node_id,
              form_info: form.form_info,
              is_supplier_check: checkSupplierForm.supplier_type,
              supplier_ids: checkSupplierForm.supplier_ids,
              remark: form.remark,
              is_self_supplier_check: state.detail.is_self_supplier_check,
              self_supplier_names: state.detail.self_supplier_names,
            })
            .then((res) => {
              if (res.code === 0) {
                Notify({
                  type: 'success',
                  message: '操作成功！',
                  duration: 1000,
                  onClose: () => {
                    state.loadingConfirm = false;
                    if (props.data) {
                      emit('reviewOk');
                    } else {
                      router.back();
                    }
                  },
                });
              } else {
                Notify({
                  type: 'warning',
                  message: res.data,
                  duration: 2000,
                  onClose: () => {
                    state.loadingConfirm = false;
                  },
                });
              }
            });
        })
        .catch(() => {
          state.loadingConfirm = false;
          Notify({
            type: 'warning',
            message: '取消操作',
          });
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};
//-验收操作
const btnCheckSumbit = (status) => {
  state.loadingConfirm = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      $http
        .witness_deal({
          witness_id: state.witness_id,
          type: 2,
          node_id: state.node_id,
          status: status,
          form_info: form.form_info,
          remark: form.remark,
        })
        .then((res) => {
          if (res.code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                state.loadingConfirm = false;
                if (props.data) {
                  emit('reviewOk');
                } else {
                  router.back();
                }
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: res.data,
              duration: 2000,
              onClose: () => {
                state.loadingConfirm = false;
              },
            });
          }
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};

//-@保存为草稿
const editSave = () => {
  dealArrow();
  state.loadingConfirm = true;
  $http
    .witness_saveForm({
      witness_id: state.witness_id,
      type: 2,
      node_id: state.node_id,
      form_info: form.form_info,
      remark: form.remark,
      is_supplier_check: checkSupplierForm.supplier_type,
      supplier_ids: checkSupplierForm.supplier_ids,

      is_self_supplier_check: state.detail.is_self_supplier_check,
      self_supplier_names: state.detail.self_supplier_names,
    })
    .then((res) => {
      if (res.code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            state.loadingConfirm = false;
            if (props.data) {
              emit('reviewOk');
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: res.data,
          duration: 2000,
          onClose: () => {
            state.loadingConfirm = false;
          },
        });
      }
    });
};
//-@见证件发起
const editStart = () => {
  state.loadingConfirm = true;
  $http
    .witness_start({
      witness_id: state.witness_id,
      type: 2,
      node_id: state.node_id,
      remark: form.remark,
    })
    .then((res) => {
      if (res.code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            state.loadingConfirm = false;
            if (props.data) {
              emit('reviewOk');
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: res.data,
          duration: 2000,
          onClose: () => {
            state.loadingConfirm = false;
          },
        });
      }
    });
};
//-@提交人-驳回
const editRejust = () => {
  //去除冗余字段
  dealArrow();

  state.loadingConfirm = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      $http
        .witness_deal({
          witness_id: state.witness_id,
          type: 2,
          node_id: state.node_id,
          form_info: form.form_info,
          status: 4,
          remark: form.remark,
          is_supplier_check: checkSupplierForm.supplier_type,
          supplier_ids: checkSupplierForm.supplier_ids,
          is_self_supplier_check: state.detail.is_self_supplier_check,
          self_supplier_names: state.detail.self_supplier_names,
        })
        .then((res) => {
          if (res.code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                state.loadingConfirm = false;
                if (props.data) {
                  emit('reviewOk');
                } else {
                  router.back();
                }
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: res.data,
              duration: 2000,
              onClose: () => {
                state.loadingConfirm = false;
              },
            });
          }
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};
//-@知会人
const editNotice = () => {
  state.loadingConfirm = true;
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      $http
        .witness_deal({
          witness_id: state.witness_id,
          type: 2,
          node_id: state.node_id,
          form_info: form.form_info,
          status: 3,
          remark: form.remark,
        })
        .then((res) => {
          if (res.code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                state.loadingConfirm = false;
                if (props.data) {
                  emit('reviewOk');
                } else {
                  router.back();
                }
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: res.data,
              duration: 2000,
              onClose: () => {
                state.loadingConfirm = false;
              },
            });
          }
        });
    })
    .catch(() => {
      //验证失败
      state.loadingConfirm = false;
      Toast('请先完善信息');
    });
};

//去除箭头的头冗余字段
const dealArrow = () => {
  // 开工交底验收单等11个见证件验证    产品二次交底单
  if (
    (state.detail.sub_type > 5 && state.detail.sub_type < 17) ||
    state.detail.sub_type == 30
  ) {
    form.form_info.basic_info = form.form_info.basic_info.map((item) => {
      if (item.arrow) {
        item.arrow = '';
      }
      return item;
    });
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessTemp.less';
//-提示信息
.tip_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fdf0e8;
  font-size: 12px;
  .tip_info {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    .tip_text {
      font-size: 12px;
      color: #f37020;
      padding-left: 5px;
    }
  }
}

.field_pop {
  background-color: #f5f5f5;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  :deep(.van-button) {
    width: 58px;
    height: 32px;
    margin-left: 10px;
  }
  :deep(.van-cell) {
    padding: 8px;
    font-size: 15px;
  }
}
</style>
