<!--
 * @Author: Jessica
 * @Date: 2022-04-19 09:37:45
 * @LastEditTime: 2023-02-08 15:12:45
 * @Description: 
-->
<template>
  <!-- 项目成员组件 -->
  <div class="page-wrap">
    <van-nav-bar
      safe-area-inset-top
      title="选择成员"
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
    />
    <div class="search_filed noBorder">
      <form action="/">
        <van-search
          v-model="kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
        />
      </form>
    </div>

    <div class="checkedList noBorder">
      <van-checkbox-group
        v-model="form.checked"
        v-if="form.list.length"
        :max="maxLength"
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="item in form.list" :key="item.id">
            <div class="member_list">
              <van-checkbox :name="item.id" @click.stop="getClick(item)">
                <div class="member_item clearfix">
                  <van-image
                    class="pull-left imgIcon"
                    round
                    :src="
                      item.face_image
                        ? host + item.face_image
                        : require('@/assets/images/icon-empty-img.png')
                    "
                  />
                  <div class="pull-left detail">
                    <p class="name">{{ item.name }}</p>
                    <p class="extra">
                      {{ item.dep_name + ' / ' + item.post_name }}
                    </p>
                  </div>
                </div>
              </van-checkbox>
            </div>
          </van-cell>
        </van-list>
      </van-checkbox-group>
      <van-empty
        v-if="!loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="footer clearfix">
      <p class="pull-left">
        已选
        <span>{{ form.checked.length }}</span>
        人
      </p>
      <van-button
        class="pull-right"
        type="primary"
        size="normal"
        style="width: 115px; margin: 9px 0px; border-radius: 5px"
        @click="btnSumbit()"
      >
        确定
      </van-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import {
  Button,
  Icon,
  Image,
  Collapse,
  CollapseItem,
  Checkbox,
  CheckboxGroup,
  Search,
  List,
  Cell,
  Empty,
  NavBar,
  Notify,
} from 'vant';

import { Toast } from 'vant';
export default {
  props: ['data'],
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Cell.name]: Cell,
    [Empty.name]: Empty,
    [NavBar.name]: NavBar,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const host = inject('$host');
    const router = useRouter();
    const state = reactive({
      loading: true,
      finished: false,
      kw: '',
      defaultPage: 1,
      maxLength: 7,
      pageInfo: {
        total: 0, //总条数
        page: 1, //当前页
        count_page: 1,
        pages: 10, //每页多少条
      },
    });
    const form = reactive({
      checked: [],
      checkedList: props.data ? JSON.parse(JSON.stringify(props.data)) : [],
      clickChecked: [],
      clickCheckedList: props.data
        ? JSON.parse(JSON.stringify(props.data))
        : [],
      list: [],
    });
    onMounted(() => {
      form.clickChecked = form.checked = form.checkedList.map((v) => {
        return v.id;
      });
      searchFun();
    });
    //-搜索
    const searchFun = () => {
      form.list = [];
      state.finished = false;
      state.pageInfo.total = 0;
      state.pageInfo.page = 1;
      state.pageInfo.count_page = 1;
      state.pageInfo.pages = 10;
      onLoad();
    };

    //-获取用户列表
    const onLoad = () => {
      state.loading = true;
      $http
        .getMemberList({
          kw: state.kw,
          customer_id: router.currentRoute.value.query?.id || '',
          page: state.pageInfo.page ? state.pageInfo.page : state.defaultPage,
          pages: state.pageInfo.pages,
        })
        .then((res) => {
          state.loading = false;
          if (res.code === 0) {
            state.pageInfo = res.data.page;
            form.list = [...form.list, ...res.data.list];
            if (state.pageInfo.page < state.pageInfo.count_page) {
              state.pageInfo.page++;
            } else {
              state.finished = true;
            }
          } else {
            state.finished = true;
            Toast(res.msg || res.data);
          }
        });
    };

    const getClick = (item) => {
      if (form.clickChecked.indexOf(item.id) > -1) {
        form.clickChecked.splice(form.clickChecked.indexOf(item.id), 1);
        form.clickCheckedList = form.clickCheckedList.filter((v) => {
          return v.id != item.id;
        });
      } else {
        if (form.clickChecked.length < state.maxLength) {
          form.clickChecked.push(item.id);
          form.clickCheckedList.push(item);
        } else {
          Notify({
            type: 'warning',
            message: '您最多只能选择七位项目成员，目前已超过限制啦',
          });
        }
      }
    };
    //-确认操作
    const btnSumbit = () => {
      form.checkedList = JSON.parse(JSON.stringify(form.clickCheckedList));
      emit('reviewOk', form.checkedList);
    };

    //-取消
    const onClosePop = () => {
      emit('reviewClose');
    };
    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      host,
      form,
      searchFun,
      btnSumbit,
      onLoad,
      onClosePop,
      getClick,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/member.less';
</style>
