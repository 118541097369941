<!--
 * @Author: NanNan
 * @Date: 2024-05-14 10:24:10
 * @LastEditTime: 2024-05-29 14:22:06
 * @Description: v1.6.0新版本 获客-线索: 渠道线索数
-->
<template>
  <div class="data_item extend chart_jd" style="padding-bottom: 0px">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、头部 -->
        <div class="data_card_top border_bottom">
          <div class="title">渠道线索数</div>
          <!-- 目标/完成/完成率 -->
          <Row>
            <Col span="8">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.total?.target === '' ? '-' : _DATA.total?.target }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.total?.actual === '' ? '-' : _DATA.total?.actual }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.total?.actual > _DATA.total?.target"
              >
                <span class="bold green">{{ _DATA.total?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.total?.actual < _DATA.total?.target"
              >
                <span class="bold orange">{{ _DATA.total?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.total?.rate === '' ? '-' : _DATA.total?.rate + '%' }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 二、集团、市场模块 -->
        <div class="data_card_center border_bottom">
          <Row class="pt_15 pb_15">
            <Col span="12">
              <div class="channelThredCount pl_20">
                <p class="titile">集团</p>
                <div class="ok-box">
                  <div class="tt grey">完成</div>
                  <div class="dd">
                    <em class="size20 bold">
                      {{
                        _DATA.group?.actual === '' ? '-' : _DATA.group?.actual
                      }}
                    </em>
                    <div
                      class="flex_line flex_line_center"
                      v-if="_DATA.group?.actual > _DATA.group?.target"
                    >
                      <span class="green bold">{{ _DATA.group?.gap }}</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/up.png')"
                        alt="down"
                      />
                    </div>
                    <div
                      class="flex_line flex_line_center"
                      v-else-if="_DATA.group?.actual < _DATA.group?.target"
                    >
                      <span class="orange bold">{{ _DATA.group?.gap }}</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/down.png')"
                        alt="down"
                      />
                    </div>
                    <div class="flex_line flex_line_center" v-else>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/equal.png')"
                        alt="down"
                      />
                    </div>
                  </div>
                </div>
                <div class="goal">
                  <em class="grey">目标：</em>
                  <em class="bold">
                    {{ _DATA.group?.target === '' ? '-' : _DATA.group?.target }}
                  </em>
                </div>
                <div class="goal">
                  <em class="grey">完成率：</em>
                  <em class="bold">
                    {{
                      _DATA.group?.rate === '' ? '-' : _DATA.group?.rate + '%'
                    }}
                  </em>
                </div>
              </div>
            </Col>
            <Col span="12">
              <div class="channelThredCount pl_20">
                <p class="titile">市场</p>
                <div class="ok-box">
                  <p class="tt grey">完成</p>
                  <div class="dd">
                    <em class="size20 bold">
                      {{
                        _DATA.market?.actual === '' ? '-' : _DATA.market?.actual
                      }}
                    </em>
                    <div
                      class="flex_line flex_line_center"
                      v-if="_DATA.market?.actual > _DATA.market?.target"
                    >
                      <span class="green bold">{{ _DATA.market?.gap }}</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/up.png')"
                        alt="down"
                      />
                    </div>
                    <div
                      class="flex_line flex_line_center"
                      v-else-if="_DATA.market?.actual < _DATA.market?.target"
                    >
                      <span class="orange bold">{{ _DATA.market?.gap }}</span>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/down.png')"
                        alt="down"
                      />
                    </div>
                    <div class="flex_line flex_line_center" v-else>
                      <img
                        class="icon_img"
                        :src="require('@/assets/images/target-data/equal.png')"
                        alt="down"
                      />
                    </div>
                  </div>
                </div>
                <div class="goal">
                  <em class="grey">目标：</em>
                  <em class="bold">
                    {{
                      _DATA.market?.target === '' ? '-' : _DATA.market?.target
                    }}
                  </em>
                </div>
                <div class="goal">
                  <em class="grey">完成率：</em>
                  <em class="bold">
                    {{
                      _DATA.market?.rate === '' ? '-' : _DATA.market?.rate + '%'
                    }}
                  </em>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 三、内容 -->
        <div class="data_card_table_bottom p0">
          <!-- 1标题 -->
          <Row class="mb_15 p_l15_r10">
            <Col span="7">
              <div class="grey pr_10">渠道</div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="11" style="position: relative">
              <div class="chart_pos">
                <!-- 左侧文案位置-不一定存在 -->
                <div class="chart_left" v-if="props.data.depId"></div>
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="6">
              <div class="grey align_center">完成率</div>
            </Col>
          </Row>
          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(parentItem, parentIndex) in _DATA.list"
            :key="parentIndex"
          >
            <Row
              class="p_l15_r10"
              style="align-items: center; height: 100%"
              @click.stop="toggleAccordion(parentIndex)"
            >
              <Col span="7">
                <div class="size13" style="display: flex; align-items: center">
                  <template v-if="parentItem?.children?.length > 0">
                    <Icon
                      name="play"
                      :style="
                        parentItem?.expanded
                          ? 'transform: rotate(90deg)'
                          : 'transform: rotate(0deg)'
                      "
                      class="arrow"
                    />
                  </template>
                  <p>{{ parentItem.name }}</p>
                </div>
              </Col>
              <Col span="11" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      actual: parentItem.actual,
                      target: parentItem.target
                        ? parentItem.target
                        : parentItem.actual,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ parentItem.target === '' ? '-' : parentItem.target }}
                  </div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <chartCoverLine
                    :data="{
                      type: 1,
                      num: _DATA.middle_value,
                      list: _DATA.line_values,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>

              <!-- 完成率 -->
              <Col span="6">
                <div>
                  <span class="lineheight16 align_center">
                    {{ parentItem.rate === '' ? '-' : parentItem.rate + '%' }}
                  </span>
                </div>
              </Col>
            </Row>

            <Transition name="thred-fade">
              <TempChannelClue
                v-if="parentItem.children && parentItem?.expanded"
                :data="parentItem"
                :index="parentIndex"
              />
            </Transition>
          </div>
          <!-- 图例 -->
          <div class="p_15 flex_center">
            <p class="box_8 grey_bg"></p>
            <p class="ml_5">目标</p>
            <p class="box_8 red_bg ml_30"></p>
            <p class="ml_5">达标</p>
            <p class="box_8 green_bg ml_30"></p>
            <p class="ml_5">未达标</p>
            <p class="box_8 red2_bg ml_30"></p>
            <p class="ml_5">超额</p>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import { Row, Col, Notify, Skeleton, Icon } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';

import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
import TempChannelClue from '@/components/targetData/thredData/collapseItem/TempChannelClue';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  total: {}, //总数
  index: 1, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 1, //部门数据页面所处位置
  group: {}, //集团信息
  market: {}, //市场信息
  list: [], //市场层级list
  line_values: [],
  middle_value: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_channelSourceClue;
  let params = { year: props.data.yearId };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.total = data.total;
      _DATA.group = data.group;
      _DATA.market = data.market;
      _DATA.line_values = data.market?.line_values;
      _DATA.list = data.market?.list;
      _DATA.middle_value = data.market?.middle_value;
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

/**
 * 操作
 */
//展开收起
const toggleAccordion = (subIndex) => {
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
  console.log(_DATA.list[subIndex]);
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
