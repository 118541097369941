<!--
 * @Author: Jessica
 * @Date: 2023-07-21 16:11:50
 * @LastEditTime: 2023-07-25 18:02:52
 * @Description: v1.4.2设计变更单
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 提交表单 -->
    <Form class="form-box" ref="formRef">
      <p class="label-name">
        <em>*</em>
        当前设计阶段
      </p>
      <Field
        v-model="_DATA.type_name"
        disabled
        placeholder="选择当前设计阶段"
        :rules="[
          {
            required: true,
            message: '请选择当前设计阶段',
            trigger: 'onChange',
          },
        ]"
      />
      <p class="label-name">
        <em>*</em>
        退回阶段
      </p>
      <Field
        v-model="form_info.change_type_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择设计阶段"
        @click="_DATA.stage_node_show = true"
        :rules="[
          {
            required: true,
            message: '请选择设计阶段',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        备注
      </p>
      <Field
        v-model.trim="form_info.remark"
        rows="3"
        autosize
        type="textarea"
        maxlength="500"
        show-word-limit
        placeholder="添加备注..."
        :rules="[
          {
            required: true,
            message: '请填写备注',
            trigger: 'onChange',
          },
        ]"
      ></Field>
      <!-- end -->

      <div class="stick-box">
        <Row>
          <Col span="24">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="onSubmit()"
            >
              确认
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
    <!-- 提示信息 -->
    <div class="tip">
      <p>提示</p>
      <p>
        提交之后需要客户在【尚层客户端】小程序点击通过，通过后设计变更立即生效
      </p>
    </div>

    <!-- 组件 begin-->
    <!-- 退回阶段 -->
    <Popup v-model:show="_DATA.stage_node_show" position="bottom">
      <Picker
        :columns="_DATA.stage_node_list"
        :columns-field-names="{
          text: 'change_type_name',
          value: 'change_type_sort',
        }"
        @confirm="change_backStage"
        @cancel="_DATA.stage_node_show = false"
      />
    </Popup>
    <!-- 组件 end-->
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import {
  Form,
  Field,
  Overlay,
  Loading,
  Button,
  Notify,
  Row,
  Col,
  Picker,
  Popup,
} from 'vant';

const $http = inject('$http');
const router = useRouter();
const design_id = ref(router.currentRoute.value.query.did); //设计节点列表 过来携带 设计id

const _DATA = reactive({
  type_name: '', //当前设计阶段名
  stage_node_show: false,
  stage_node_list: [],
});
const form_default = reactive({
  remark: '', //备注
  change_type_sort: '', //退回阶段
  change_type_name: '', // 退回阶段名
});
const form_info = reactive({});
const loading = ref(false);

/**
 * 初始化
 */
onMounted(() => {
  loading.value = true;
  Object.assign(form_info, form_default);
  //请求参数
  $http
    .getChangeOrderParams({
      design_id: Number(design_id.value),
    })
    .then((res) => {
      loading.value = false;
      const { data, code } = res;
      if (code === 0) {
        _DATA.type_name = data.type_name;
        _DATA.stage_node_list = data.stage_node;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
});

/**
 * 操作
 */

const change_backStage = (value) => {
  _DATA.stage_node_show = false;
  form_info.change_type_sort = value.change_type_sort;
  form_info.change_type_name = value.change_type_name;
};

//-@ 提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http
      .getChangeOrder({
        design_id: Number(design_id.value),
        ...form_info,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功！',
            duration: 1000,
            onClose: () => {
              loading.value = false;
              // 哪里来回哪里去
              router.back();
            },
          });
        } else {
          Notify({
            type: 'warning',
            message: data,
            duration: 2000,
            onClose: () => {
              loading.value = false;
            },
          });
        }
      });
  });
};
</script>

<style lang="less" scoped>
.page-box {
  padding: 0;
  background: #f5f5f5;
  min-height: calc(100% - 46px);
  .label-name {
    font-size: 15px;
    line-height: 20px;
    margin: 15px 0;
    font-weight: 700;
    em {
      color: #ea3d2f;
    }
  }

  .form-box {
    padding: 20px;
    background: #fff;
  }

  .stick-box {
    z-index: 9;
  }
  .tip {
    padding: 20px;
    > p {
      font-size: 15px;
      color: #8d9091;
    }
  }
}
</style>
