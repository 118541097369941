<!--
 * @Author: gest
 * @Date: 2022-05-19 09:41:23
 * @LastEditTime: 2024-03-07 15:30:33
 * @Description: 上传附件 - 组件
 //父组件使用 
//非必传 
类型
type 默认2:1 上传图纸、设计节点验收、施工节点验收 2其他页面 3施工日志 4头像 (图片 10M)
//引用方式
import FileUpload from '@/components/common/FileUpload';
 <FileUpload
                :data="form_info.files"
                @update:func="(list) => getFileList(list)"
              ></FileUpload>
const getFileList = (files) => {
  form_info.files = files;
};
-->
<template>
  <div class="file-list">
    <Grid
      :border="false"
      :column-num="3"
      square
      :gutter="10"
      style="width: 100%"
    >
      <GridItem
        v-for="(item, index) in _DATA.file_list"
        :key="index"
        @click="fileDetail(item, _DATA.file_list)"
      >
        <template v-if="item.precent >= 0 && item.precent <= 99">
          <div class="mask-box">
            <div class="mask">
              <Loading size="24" color="#FFFFFF"></Loading>
              <span>
                上传中
                <span>{{ item.precent }}%</span>
              </span>
            </div>
            <div class="delIcon">
              <Icon name="cross" @click.stop="handleFileCancel(item)" />
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="isImg(item.type)" class="img-box">
            <Image
              class="img-head"
              :src="$host + item.path"
              fit="cover"
              position="center"
            >
              <template v-slot:loading>
                <Loading type="spinner" size="20"></Loading>
              </template>
              <div class="delIcon" v-if="!item.del_status">
                <Icon name="cross" @click.stop="delUpload(item)" />
              </div>
            </Image>
          </div>
          <div v-else class="img-box other">
            <svg class="icon-small" aria-hidden="true">
              <use :xlink:href="getIcon(item.type)"></use>
            </svg>
            <div class="filename van-ellipsis">{{ item.name }}</div>
            <div class="delIcon" v-if="!item.del_status">
              <Icon name="cross" @click.stop="delUpload(item)" />
            </div>
          </div> </template
      ></GridItem>
      <GridItem
        v-show="
          (props.amount && _DATA.file_list.length < props.amount) ||
          !props.amount
        "
      >
        <uploader
          style="width: 100%; height: 100%"
          ref="myUploader"
          class="pull-left"
          :options="_DATA.options"
          @file-added="onFileAdded"
          @file-progress="onFileProgress"
          @file-success="onFileSuccess"
        >
          <uploader-btn :attrs="_DATA.attrs" class="btn">
            <Icon name="plus" />
          </uploader-btn>
        </uploader> </GridItem
    ></Grid>
  </div>
  <Popup v-model:show="_showData.showVideo" style="width: 80%">
    <div style="display: flex">
      <video
        v-if="_showData.showVideo"
        style="width: 100%"
        controls
        autoplay
        webkit-playsinline="true"
        playsinline="true"
        x5-video-player-type="h5-page"
        preload="auto"
        :src="$host + _showData.info.path"
      ></video>
    </div>
  </Popup>
  <Popup v-model:show="_showData.showAudio" style="width: 80%">
    <div style="display: flex">
      <audio
        style="width: 100%"
        controls
        autoplay
        preload="auto"
        :src="$host + _showData.info.path"
      ></audio>
    </div>
  </Popup>
</template>
<script setup>
import { nanoid } from 'nanoid';
import {
  inject,
  defineProps,
  ref,
  reactive,
  watch,
  onMounted,
  defineEmits,
} from 'vue';
import { Image, Toast, Icon, Popup, Loading, Grid, GridItem } from 'vant';
import {
  // file_type,
  acceptFile,
  isImg,
  getIcon,
  _showData,
  fileDetail,
  checkFile,
} from '@/utils/fileUpload';
import { useCustomFieldValue } from '@vant/use';
const myUploader = ref(null);
const acceptFileStr = ref('');
const $host = inject('$host');
const $http = inject('$http');

const props = defineProps(['data', 'type', 'amount']);
const emit = defineEmits(['update:func']);
//类型 1 上传图纸、设计节点验收、施工节点验收 2其他页面 3施工日志 4头像
const file_type = ref('2');

const _DATA = reactive({
  file_list: props.data ? props.data : [],
  options: {
    target: $host + '/rs/public/chunkUpload',
    testChunks: false,
    allowDuplicateUploads: true,
    processParams(params) {
      // 自定义每一次分片传给后台的参数，params是该方法返回的形参，包含分片信息,
      return {
        // 返回一个对象，会添加到每一个分片的请求参数里面
        // totalChunks: params.totalChunks,
        identifier: params.identifier,
        chunk_number: params.chunkNumber,
        type: file_type.value,
        // chunkSize: 5 * 1024 * 1024,
      };
    },
    generateUniqueIdentifier() {
      return nanoid();
    },
  },
  attrs: {
    // 接受的文件类型，形如['.png', '.jpg', '.jpeg', '.gif', '.bmp'...]
    accept: [],
  },
});

onMounted(() => {
  file_type.value = props.type ? props.type : 2;
  acceptFileStr.value = acceptFile(file_type.value);
  _DATA.attrs.accept = acceptFileStr.value.split(',');
});
watch(
  () => props.data,
  (cur) => {
    _DATA.file_list = cur ? cur : [];
  }
);
useCustomFieldValue(() => _DATA.file_list);
const onFileAdded = (file) => {
  let result = checkFile(file, file_type.value);
  if (props.amount) {
    //上传限制数量
    result =
      _DATA.file_list.length >= props.amount
        ? false
        : checkFile(file, file_type.value);
  }
  if (!result) {
    file.ignored = true;
  } else {
    let data = {
      identifier: file.uniqueIdentifier,
      precent: 0,
      name: file.name,
    };
    _DATA.file_list.push(data);
    emit('update:func', _DATA.file_list);
  }
  return result;
};
const onFileProgress = (rootFile, file) => {
  let loadIndex = _DATA.file_list.findIndex((v) => {
    return v.identifier && v.identifier == file.uniqueIdentifier;
  });
  if (loadIndex < 0) {
    return;
  }
  let precent = Math.round(file.progress() * 100);

  let newParams = {
    identifier: file.uniqueIdentifier,
    precent: Number(precent == 100 ? 99 : precent),
    name: file.name,
  };
  _DATA.file_list.splice(loadIndex, 1, newParams);
  emit('update:func', _DATA.file_list);
};
const onFileSuccess = (rootFile, file, response) => {
  //
  let loadIndex = _DATA.file_list.findIndex((v) => {
    return v.identifier && v.identifier == file.uniqueIdentifier;
  });
  if (JSON.parse(response).code == 0) {
    $http
      .getUploadChunkMerge({
        identifier: file.uniqueIdentifier,
        name: file.name,
        type: file_type.value,
      })
      .then((res) => {
        if (res.code === 0) {
          let fileData = {
            type: res.data.type,
            name: res.data.name,
            path: res.data.path,
            add_time: res.data.add_time ? res.data.add_time : '',
          };
          _DATA.file_list.splice(loadIndex, 1, fileData);
          Toast('上传成功');
          emit('update:func', _DATA.file_list);
        } else {
          _DATA.file_list.splice(loadIndex, 1);
          emit('update:func', _DATA.file_list);
          Toast(res.data || res.msg);
        }
      });
  } else {
    _DATA.file_list.splice(loadIndex, 1);
    emit('update:func', _DATA.file_list);
    Toast('上传失败');
  }
};
const handleFileCancel = (file) => {
  _DATA.file_list = _DATA.file_list.filter(
    (v) => v.identifier !== file.identifier
  );
  emit('update:func', _DATA.file_list);
  const uploaderInstance = myUploader.value.uploader;
  let fileObj = uploaderInstance.files.find((v) => {
    return v.uniqueIdentifier == file.identifier;
  });
  uploaderInstance.removeFile(fileObj);
};
//删除
const delUpload = (item) => {
  _DATA.file_list = _DATA.file_list.filter((v) => v.path != item.path);
  emit('update:func', _DATA.file_list);
};
</script>
<style lang="less" scoped>
.file-list {
  width: 100%;
  :deep(.van-grid) {
    padding-left: 0 !important;
  }
  :deep(.van-grid-item__content) {
    padding: 0;
  }
  .btn {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #f5f5f5;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
  }
  > div:nth-child(3n) {
    margin-right: 0;
  }
  .mask-box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .mask {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.7);
      span {
        padding-top: 4px;
        font-size: 13px;
        color: @white_color;
      }
    }
  }
  .delIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--van-uploader-delete-icon-size);
    height: var(--van-uploader-delete-icon-size);
    background: var(--van-uploader-delete-background-color);
    border-radius: 0 0 0 12px;

    .van-icon {
      position: absolute;
      top: 0;
      right: 0;
      color: var(--van-uploader-delete-color);
      font-size: var(--van-uploader-delete-icon-size);
      transform: scale(0.7) translate(10%, -10%);
      color: #fff;
    }
  }
  .list-file {
    width: 32%;

    margin-right: 2%;
    margin-bottom: 2%;
  }

  .img-box {
    width: 100%;
    height: 100%;
    background-color: @grey_color;
    &.other {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .filename {
      font-size: 11px;
      color: @main_color;
      padding: 10px 10px 0 10px;
      width: 100%;
      text-align: center;
    }
    .img-head {
      width: 100%;
      height: 100%;
    }
    .icon-small {
      width: 32px;
      height: 36px;
    }
  }
}
</style>
