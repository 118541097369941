<!--
 * @Author: NanNan
 * @Date: 2023-10-25 10:01:38
 * @LastEditTime: 2024-05-24 16:07:31
 * @Description: v1.4.8 公共组建 - 选择服务人员
 * 详细描述：嵌套在Popup内，底部弹出，包含搜索筛选、重新选择、已选择人员展示等
 * 用于全部客户列表、线索列表
 * mastergo：https://mastergo.com/file/91167116246104?fileOpenFrom=project&page_id=M
-->
<template>
  <div class="TempPopupBox" ref="containerRef">
    <Overlay :show="_DATA.loading" class="overlay-fff-inner">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 公共头 -->
    <div class="title-box">
      <p>{{ props?.data?.title }}</p>
      <Icon name="cross" class="icon-close" size="15" @click="sumbit" />
    </div>
    <!-- 搜索框 -->
    <div class="search-box">
      <form action="/">
        <Search
          :placeholder="props?.data?.placeholder"
          show-action
          maxlength="20"
          v-model="kw"
          :clearable="false"
          :formatter="formatterSearch"
          format-trigger="onChange"
          @search="onSearch"
          @cancel="onCancel"
        >
          <template #action>
            <div @click="onSearch" style="color: #0256ff; font-size: 15px">
              搜索
            </div>
          </template>
          <template #right-icon>
            <div @click="onClear" v-if="kw != ''">
              <Icon name="clear" color="#c8c9cc" />
            </div>
          </template>
        </Search>
      </form>
    </div>
    <!-- 已选择类目 -->
    <div class="chose-box" v-if="_DATA.chose_items?.length > 0">
      <div class="left" @click="choseReset">
        重新选择
        <Icon name="replay" size="13" class="ml4" />
      </div>
      <div class="right">
        <p>
          {{ _DATA.chose_items[0]?.[_DATA.substr_name] }}
        </p>
        <span v-if="_DATA.chose_items?.length > 1">
          +{{ _DATA.chose_items?.length - 1 }}
        </span>
      </div>
    </div>
    <!-- 列表展示 -->
    <div class="list-box">
      <ul>
        <li
          v-for="item in _DATA.list"
          :key="item.id"
          @click.stop="handleChose(item)"
          :class="{
            disabled: item.status == 2,
            on: _DATA.chose_ids.indexOf(item.id) > -1,
          }"
        >
          <div>
            <span>{{ item[_DATA.substr_name] }}</span>
            <em v-if="item.status == 2">离职</em>
          </div>
        </li>
      </ul>
    </div>
    <!-- 空数展示 -->
    <div
      class="empty-search-box"
      v-if="!_DATA.loading && _DATA.list.length === 0"
    >
      <img :src="require('@/assets/images/search-empty.png')" alt="" />
    </div>
    <!-- 操作按钮 -->
    <div class="stick-box">
      <Button type="primary" size="large" @click="sumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  reactive,
  defineProps,
  ref,
  inject,
  defineEmits,
} from 'vue';
import { Search, Icon, Button, Overlay, Loading, Notify } from 'vant';

const props = defineProps(['data']);
const emit = defineEmits(['reviewTempSelect']);
const $http = inject('$http');
const containerRef = ref(null);
const kw = ref('');

const _DATA = reactive({
  kw: '',
  loading: false,
  list: [],
  chose_items: props?.data?.chose_items,
  chose_ids: props?.data?.chose_ids,

  substr_id: props?.data?.substr?.id || 'id',
  substr_name: props?.data?.substr?.name || 'name',
});

onMounted(() => {
  loadList();
});

/**
 * 接口
 */
// -- 获取列表
const loadList = () => {
  _DATA.loading = true;
  $http[props?.data?.http]({ kw: _DATA.kw, ...props?.data?.parmars }).then(
    (res) => {
      const { code, data } = res;
      _DATA.loading = false;
      if (code === 0) {
        _DATA.list = data.list;
      }
    }
  );
};

/**
 * 操作
 */
// -- 选择人员
const handleChose = (item) => {
  const has_index = _DATA.chose_ids.indexOf(item.id);
  if (has_index > -1) {
    // 存在
    _DATA.chose_ids.splice(has_index, 1);
    _DATA.chose_items.splice(has_index, 1);
  } else {
    // 不存在
    if (props?.data?.max && _DATA.chose_items?.length === props?.data?.max) {
      Notify({
        type: 'warning',
        message: '最多可选择' + _DATA.chose_items?.length + '人',
      });
      return;
    }
    _DATA.chose_items.push(item);
    _DATA.chose_ids.push(item.id);
  }
  // console.log('已选择ids', _DATA.chose_ids);
  // console.log('已选择items', _DATA.chose_items);
};

// -- 点击搜索
const onSearch = () => {
  console.log('搜索');
  _DATA.kw = JSON.parse(JSON.stringify(kw.value));
  loadList();
};

// -- 搜索框：取消kw
const onCancel = () => {
  console.log('取消');
  kw.value = JSON.parse(JSON.stringify(_DATA.kw));
};

// -- 搜索框：清空
const onClear = () => {
  _DATA.kw = '';
  kw.value = '';
  loadList();
};

// -- 已选人员：重新选择
const choseReset = () => {
  _DATA.chose_items = [];
  _DATA.chose_ids = [];
  console.log('已选择ids', _DATA.chose_ids);
  console.log('已选择items', _DATA.chose_items);
};

// -- 确定
const sumbit = () => {
  console.log('点击确定');

  emit('reviewTempSelect', 'ok', _DATA.chose_items, _DATA.chose_ids);
};

/**
 * 其他
 */
// -- 搜索框：input格式化去除左右空格
const formatterSearch = (value) => {
  return value.replace(/^\s*|\s*$/g, '');
};
</script>

<style lang="less" scoped>
.TempPopupBox {
  padding: 25px 20px 74px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;

  .title-box {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 25px;
    font-size: 17px;
    text-align: center;
    position: relative;
    .icon-close {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  .search-box {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 15px 0;
  }

  .chose-box {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    margin: 0 0 15px;
    .left {
      flex-grow: 0;
      flex-shrink: 0;
      height: 23px;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 15px;
      margin-right: 15px;
      cursor: pointer;
      .ml4 {
        margin-left: 4px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 23px;
      p {
        font-size: 15px;
        color: #8d9091;
        flex-grow: 1;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1; //确定超出行数
        -webkit-box-orient: vertical;
      }
      span {
        height: 23px;
        color: #767676;
        background: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        font-size: 15px;
        margin-left: 4px;
      }
    }
  }

  .list-box {
    flex-grow: 0;
    flex-shrink: 1;
    max-height: inherit;
    overflow-y: auto;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    ul {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      padding-bottom: 15px;
      li {
        cursor: pointer;
        max-width: 100%;
        height: 34px;
        font-size: 15px;
        line-height: 34px;
        border: 0.5px solid #dcdee0;
        box-sizing: border-box;
        border-radius: 17px;
        padding: 0 15px;
        margin: 15px 12px 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1; //确定超出行数
        -webkit-box-orient: vertical;
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.disabled {
          & > div {
            color: #8d9091;
            em {
              font-size: 12px !important;
              position: relative;
              margin-left: 8px;
              padding-left: 12px;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -4px;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #8d9091;
              }
            }
          }
        }
        &.on {
          color: #0256ff;
          background: #e5eeff;
          & > div {
            color: #0256ff;
            em {
              &::before {
                background: #0256ff;
              }
            }
          }
        }
      }
    }
  }

  .empty-search-box {
    height: auto;
    margin: 0 20px;
    background: #fff;
    border-radius: 14px;
    width: calc(100% - 40px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 74px;

    img {
      display: block;
      width: 200px;
      height: auto;
    }
  }
}

:deep(.van-search) {
  padding: 0 !important;
}
:deep(.van-search__field) {
  border: none !important;
}
</style>
