<!--
 * @Author: gest
 * @Date: 2023-05-11 20:06:58
 * @LastEditTime: 2023-06-15 09:29:33
 * @Description: V1.3.8 自购产品操作
   //467现场到货验收/468安装验收
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    :title="getTitle()"
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-box middle-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- end -->
    <Form class="form-box" ref="formRef">
      <div class="detail-box">
        <p class="label-name">
          <em>*</em>
          验收日期
        </p>
        <Field
          v-model="form_info.custom_time"
          name="picker"
          readonly
          placeholder="请选择日期"
          right-icon="underway-o"
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请选择日期',
            },
          ]"
          @click="clickFun"
        />
        <p class="label-name">
          <em>*</em>
          选择产品
        </p>
        <Field
          name="checkboxGroup"
          class="noBorder"
          :rules="[
            {
              required: true,
              message: '请选择产品',
            },
          ]"
        >
          <template #input>
            <CheckboxGroup
              class="product_box"
              v-model="form_info.product_ids"
              v-if="_DATA.list.length"
            >
              <Checkbox
                class="product_box_item"
                :name="item.id"
                v-for="item in _DATA.list"
                :key="item.id"
              >
                <div class="product_item">
                  <div class="info_box">
                    <div
                      class="left"
                      v-if="item.img && item.img.length > 0"
                      @click="fileDetail(item.img[0], item.img)"
                    >
                      <Image
                        class="avader_img"
                        :src="$host + item.img[0].path"
                      />
                    </div>
                    <div v-else class="left icon_name">自购</div>
                    <div class="right">
                      <div class="name_box">
                        <p class="name" style="flex: 1">
                          {{ item.name }}
                        </p>

                        <p
                          class="status_tag red"
                          v-if="
                            (_DATA.type == 467 &&
                              item.is_onsite_receipt == 2) ||
                            (_DATA.type == 468 &&
                              item.is_install_acceptance == 2)
                          "
                        >
                          验收不通过
                        </p>
                        <p
                          class="status_tag green"
                          v-if="
                            (_DATA.type == 467 &&
                              item.is_onsite_receipt == 1) ||
                            (_DATA.type == 468 &&
                              item.is_install_acceptance == 1)
                          "
                        >
                          已验收
                        </p>
                      </div>
                      <p class="info">
                        <!-- <span class="t1">
                          {{ item.category_sub_name || '-' }}
                        </span> -->
                        <span class="t1">{{ item.brand || '-' }}</span>
                        <span class="t1">｜{{ item.model || '-' }}</span>
                        <span class="t1">｜{{ item.norms || '-' }}</span>
                        <span class="t1">｜{{ item.material || '-' }}</span>
                        <span class="t1">｜{{ item.colour || '-' }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="desc_item">
                    <span class="left">空间</span>
                    <span class="right">{{ item.space_name }}</span>
                  </div>
                  <div class="desc_item">
                    <span class="left">数量</span>
                    <span class="right">
                      {{ item.amount || '-' }}{{ item.unit || '' }}
                      <!-- <span v-if="item.return_num_name">
                        /
                        <span class="red">
                          {{ item.return_num_name }}{{ item.unit }}
                        </span>
                      </span> -->
                    </span>
                  </div>
                  <div class="desc_item">
                    <span class="left">备注</span>
                    <span class="right grey">
                      {{ item.remark || '-' }}
                    </span>
                  </div>
                </div>
              </Checkbox>
            </CheckboxGroup>
          </template>
        </Field>
      </div>
      <div class="detail-box">
        <p class="label-name">
          <em>*</em>
          验收结果
        </p>
        <Field
          v-model="form_info.is_acceptance"
          name="radio"
          class="noBorder"
          :rules="[
            {
              required: true,
              message: '请选择验收结果',
            },
          ]"
        >
          <template #input>
            <Row style="width: 100%">
              <Col span="11">
                <div
                  class="btn_box blue"
                  :class="form_info.is_acceptance == 1 ? 'selected' : ''"
                  @click.stop="btnCheck(1)"
                >
                  <svg class="icon-small" aria-hidden="true">
                    <use xlink:href="#icon-a-emotion-fill1"></use>
                  </svg>
                  <p class="name">验收通过</p>
                </div>
              </Col>
              <Col span="2"></Col>
              <Col span="11">
                <div
                  class="btn_box red"
                  :class="form_info.is_acceptance == 2 ? 'selected' : ''"
                  @click.stop="btnCheck(2)"
                >
                  <svg class="icon-small" aria-hidden="true">
                    <use xlink:href="#icon-a-emotion-sad-fill1"></use>
                  </svg>

                  <p class="name">验收不通过</p>
                </div>
              </Col>
            </Row>
          </template>
        </Field>
      </div>
      <div class="detail-box">
        <p class="label-name">
          <em>*</em>
          备注
        </p>
        <Field
          v-model="form_info.remark"
          rows="3"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请填写"
          show-word-limit
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请填写备注',
            },
          ]"
        ></Field>
        <!-- 附件组件 -->
        <p class="label-name">
          <em v-if="_DATA.type == 467 || _DATA.type == 468">*</em>
          {{ getFileName() }}
        </p>

        <FileUpload
          :data="form_info.files"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>

        <!-- end -->
      </div>
      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>

    <!-- 年月日 组件 -->
    <Popup v-model:show="_DATA.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="_DATA.currentDate"
        type="date"
        title="选择日期"
        @confirm="changePicker"
        @cancel="_DATA.showTimePicker = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { onMounted, reactive, inject, ref } from 'vue';
import {
  Form,
  Field,
  Notify,
  Loading,
  Overlay,
  Button,
  Popup,
  DatetimePicker,
  NavBar,
  Row,
  Col,
  CheckboxGroup,
  Checkbox,
  Image,
} from 'vant';
import { fileDetail } from '@/utils/fileUpload';
import { useRouter } from 'vue-router';

import moment from 'moment';

const $http = inject('$http');
const $host = inject('$host');
const loading = ref(false);
const router = useRouter();

const _DATA = reactive({
  id: router.currentRoute.value.query.id,

  type: router.currentRoute.value.query.type,
  showTimePicker: false,
  currentDate: new Date(),
  list: [],
});

const form_info = reactive({
  id: router.currentRoute.value.query.id,
  custom_time: '', //测量日期
  files: [], //附件 name path
  remark: '', //备注
  is_acceptance: '', //1通过，2不通过
  product_ids: [],
});
//438现场到货验收/439通知安装/440安装验收
const typeList = [
  {
    type: 467,
    name: '现场到货验收',
    url: $http.mainSelfOrder_productMove,
    req_type: 1,
    remark: '已验收',
    fileName: '进场验收单',
  },
  {
    type: 468,
    name: '安装验收',
    url: $http.mainSelfOrder_productMove,
    req_type: 3,
    remark: '已完成验收',
    fileName: '安装验收单',
  },
];

onMounted(() => {
  form_info.remark = typeList.filter(
    (item) => item.type == _DATA.type
  )[0].remark;
  form_info.req_type = typeList.filter(
    (item) => item.type == _DATA.type
  )[0].req_type;
  onLoad();
});

const getTitle = () => {
  return typeList.filter((item) => item.type == _DATA.type)[0].name;
};
const getFileName = () => {
  return typeList.filter((item) => item.type == _DATA.type)[0].fileName;
};
/**
 * 接口获取
 */
const onLoad = () => {
  loading.value = true;
  $http
    .mainSelfOrder_orderProductList({
      id: _DATA.id,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        _DATA.list = data.list;
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
/**
 * 操作
 */

//-@日期

const changePicker = (value) => {
  _DATA.showTimePicker = false;
  form_info.custom_time = moment(value).format('YYYY-MM-DD');
};

const btnCheck = (val) => {
  form_info.is_acceptance = val;
};
//-@附件
const getFileList = (file_list) => {
  form_info.files = file_list;
};

const clickFun = () => {
  _DATA.showTimePicker = true;
};
//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    if (!form_info.files || form_info.files.length == 0) {
      if (_DATA.type == 467) {
        Notify({ type: 'warning', message: '请上传进场验收单' });
        return;
      }
      if (_DATA.type == 468) {
        Notify({ type: 'warning', message: '请上传安装验收单' });
        return;
      }
    }

    loading.value = true;
    let url = typeList.filter((item) => item.type == _DATA.type)[0].url;
    url({ ...form_info }).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    });
  });
};

//-@ 返回
const onBack = () => {
  router.back();
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
.page-box {
  background: #f5f5f5;
  .form-box {
    .tip_box {
      background: #ffef98;
      font-size: 14px;
      line-height: 20px;
      color: #3d3d3d;
      padding: 18px 10px;
      text-align: center;
    }
    .detail-box {
      margin-top: 15px;
      .label-name {
        margin: 0;
        padding: 20px 0;
      }
      .btn_box {
        height: 48px;
        border-radius: 4px;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        &.blue {
          color: @blue-color;
          &.selected {
            color: @blue-color;
            background: @blue-bg-color;
            border-color: @blue-color;
          }
        }
        &.red {
          color: @red-color;
          &.selected {
            color: @red-color;
            background: @red-bg-color;
            border-color: @red-color;
          }
        }
        .icon-small {
          width: 17px;
          height: 17px;
          fill: currentColor;
        }
        .name {
          font-size: 15px;
          line-height: 22px;
          margin-left: 5px;
        }
      }
    }
  }
}
:deep(.van-field__control--custom) {
  display: block;
}
.product_box {
  :deep(.van-checkbox) {
    align-items: flex-start;
  }

  :deep(.van-checkbox__label) {
    width: 100%;
  }
  .product_box_item {
    padding-bottom: 15px;
    &:last-child {
      padding-bottom: 0;
      .product_item {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .product_item {
    padding-left: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;

    .info_box {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .left {
        .avader_img {
          width: 46px;
          height: 46px;

          // border: 1px solid #efefef;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .right {
        flex: 1;
        padding-left: 12px;

        .name_box {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          .name {
            font-size: 17px;
            font-weight: bold;
            line-height: 24px;
          }
        }
        .status4 {
          display: inline-block;
          border-radius: 2px;
          font-size: 12px;
          line-height: 20px;
          padding: 0 4px;
          background: @red-bg-color;
          color: @red-color;
        }
        // .t1 {
        //   font-size: 13px;
        //   color: #767676;
        //   line-height: 20px;
        //   border-right: 1px solid #767676;
        //   padding: 0 5px;
        //   &.blue {
        //     color: #576b95;
        //   }
        //   &:last-child {
        //     border-right: none;
        //   }
        //   &:first-child {
        //     padding-left: 0;
        //   }
        // }
        .t1 {
          font-size: 14px;
          color: #888888;
        }
      }
    }
    .desc_item {
      margin-top: 8px;
      display: flex;
      align-items: flex-start;

      .left {
        font-size: 14px;
        line-height: 20px;
        color: @grey-color;
      }
      .right {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        color: @black-color;
        text-align: right;
        &.grey {
          color: @grey-color;
        }
        .red {
          color: @red-color;
        }
      }
    }
  }
}
.icon_name {
  background: rgba(0, 137, 17, 0.1);
  color: rgba(0, 137, 17, 0.4);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
</style>
