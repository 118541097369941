<!--
 * @Author: Jessica
 * @Date:2023-07-25 14:05:21
 * @LastEditTime: 2023-07-25 15:21:30
 * @Description: V1.4.2  工地停工
-->
<template>
  <!-- 工地停工-->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              停工说明
            </label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="填写说明"
              :rules="[
                {
                  required: true,
                  message: '请填写说明',
                },
              ]"
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.files"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, ref } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    construction_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.gdid
      : '',
    remark: '',
    files: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goConfirm();
    })
    .catch(() => {
      //验证失败
      Toast('请完善信息');
    });
};
const goConfirm = () => {
  state.loadingConfirm = true;
  $http
    .construction_stop({
      construction_id: form.list.construction_id,
      remark: form.list.remark,
      files: form.list.files,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

//-@附件
const getFileList = (file_list) => {
  form.list.files = file_list;
};
//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
