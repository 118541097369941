<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2023-12-07 11:28:18
 * @Description:  产品变更意向单
-->
<template>
  <div class="page-waper page-waper1">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <div class="formBase">
      <!-- 提示信息 -->
      <div class="tip_info">
        <Icon name="warning" color="#F37020" size="14" />
        <p class="tip_text">
          此表仅代表客户的变更意向，数量、费用、工期均为预估，不可作为施工依据，以产品变更确认单为准。
        </p>
      </div>
      <Form ref="ruleFormRef">
        <!-- 加载状态 -->
        <Loading class="loading-box" color="#0094ff" v-if="state.loading" />
        <!-- 合同头部信息 -->
        <div class="mess-top">
          <p class="label-name white">
            <em>*</em>
            变更合同
          </p>
          <Field
            v-model="form.list.contract_name"
            right-icon="arrow-down"
            readonly
            placeholder="选择变更合同"
            @click="state.showContractPicker = true"
            :rules="[{ required: true, message: '请选择变更合同' }]"
          />
          <p class="contract-id">
            合同编号：{{ form.list.contract_no ? form.list.contract_no : '-' }}
          </p>
        </div>
        <div class="part mb_15">
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更阶段
            </label>
            <Field
              v-model="form.list.construction_stage_name"
              right-icon="arrow"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              placeholder="请选择"
              @click="state.showTypePicker_stage = true"
            />
          </div>
          <!-- 变更类型 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更类型
            </label>
            <p class="show_text">主材变更</p>
          </div>
          <!-- 变更项是否现场确认 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更项是否现场确认
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.list.scene_confirm"
                  direction="horizontal"
                >
                  <Radio
                    :name="item.id"
                    v-for="(
                      item, index
                    ) in state.contract_change_scene_confirm_labels"
                    :key="index"
                  >
                    {{ item.name }}
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <!-- 变更类别 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              变更类别
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <CheckboxGroup
                  v-model="form.list.change_types"
                  direction="horizontal"
                >
                  <Checkbox
                    :name="item.id"
                    v-for="(item, index) in state.contract_change_type_labels"
                    :key="index"
                  >
                    {{ item.name }}
                  </Checkbox>
                </CheckboxGroup>
              </template>
            </Field>
          </div>
        </div>
        <div class="part mb_15">
          <!-- 预估工期是否变更 -->
          <div class="cell_no">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预估工期是否变更
            </label>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.list.is_change_schedule"
                  direction="horizontal"
                  @change="clearFun"
                >
                  <Radio
                    :name="item.id"
                    v-for="(
                      item, index
                    ) in state.contract_change_is_schedule_labels"
                    :key="index"
                  >
                    {{ item.name }}
                  </Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
          <!-- 预估变更后工期 -->
          <div class="cell_no" v-if="form.list.is_change_schedule == 1">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预估变更后工期
            </label>
            <div class="next">
              <span class="line">预计延期</span>
              <Field
                v-model="form.list.change_schedule"
                type="number"
                placeholder="请填写"
                maxlength="3"
                :rules="[
                  {
                    required: true,
                    message: '请填写',
                    trigger: 'onChange',
                  },
                  {
                    validator: validatorNum,
                    message: '可输入1-90天',
                    trigger: 'onChange',
                  },
                ]"
              />
              <span class="line">天</span>
            </div>
          </div>
        </div>
        <!-- 三大类卡片 -->
        <!-- 主材实际增减金额 -->
        <div class="cell-card" @click="getPopup('main')">
          <div class="do-box">
            <p class="title">主材预估增减金额</p>
          </div>
          <div class="do-box bottom no_padding">
            <ul class="ul-flex bottom">
              <li>
                <p class="text c1">增项合计</p>
                <p class="n">{{ form.list.main_plus_amount }}</p>
              </li>
              <li>
                <p class="text c1">减项合计</p>
                <p class="n">{{ form.list.main_minus_amount }}</p>
              </li>
              <li>
                <p class="text c2">预估增减金额</p>
                <p class="n n1">{{ form.list.main_amount }}</p>
              </li>
            </ul>
          </div>
          <div class="do-box bottom clearfix">
            <label class="label1 pull-left">增减明细:</label>
            <div class="box-list pull-left" v-if="form.list.main_arr.length">
              <p v-for="(i, index) in form.list.main_arr" :key="index">
                {{ i.name }}
              </p>
            </div>
            <div class="box-list pull-left" v-else>-</div>
          </div>
          <div class="do-box clearfix">
            <Icon name="plus" class="pull-left icon_plus" />
            <p class="add pull-left">添加明细</p>
          </div>
        </div>
        <!-- 其他实际增减金额 -->
        <div class="cell-card" @click="getPopup('other')">
          <div class="do-box">
            <p class="title">其他预估增减金额</p>
          </div>
          <div class="do-box bottom no_padding">
            <ul class="ul-flex bottom">
              <li>
                <p class="text c1">增项合计</p>
                <p class="n">{{ form.list.other_plus_amount }}</p>
              </li>
              <li>
                <p class="text c1">减项合计</p>
                <p class="n">{{ form.list.other_minus_amount }}</p>
              </li>
              <li>
                <p class="text c2">预估增减金额</p>
                <p class="n n1">{{ form.list.other_amount }}</p>
              </li>
            </ul>
          </div>
          <div class="do-box bottom clearfix">
            <label class="label1 pull-left">增减明细:</label>
            <div class="box-list pull-left" v-if="form.list.other_arr.length">
              <p v-for="(i, index) in form.list.other_arr" :key="index">
                {{ i.project_name }}
              </p>
            </div>
            <div class="box-list pull-left" v-else>-</div>
          </div>
          <div class="do-box clearfix">
            <Icon name="plus" class="pull-left icon_plus" />
            <p class="add pull-left">添加明细</p>
          </div>
        </div>
        <!-- 总计 -->
        <div class="card-total clearfix">
          <label class="pull-left">预估合计</label>
          <p class="pull-right">¥ {{ form.list.amount }}</p>
        </div>

        <div class="part">
          <!-- 通知客户 只有【产品代购合同】和【装饰合同工程配套配套材料补充协议】有权限选择是否通知客户-->
          <div class="cell switch_cell clearfix">
            <div class="switch pull-left">
              <p class="t1">通知客户</p>
              <p class="t2">产品变更意向单默认通知客户确认。</p>
            </div>
            <Switch
              v-model="form.list.notify_customer_flag"
              size="22"
              class="pull-right switchBtn"
              disabled
            />
          </div>
          <!-- 备注 -->
          <div class="cell no_border block mt_15">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            />
          </div>
          <!-- 附件 -->
          <div class="cell">
            <label class="form_title"> 附件 </label>
            <FileUpload
              :data="form.list.attach"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </div>

        <div class="btnSumbit btnSumbit1">
          <Button class="btn" type="primary" size="large" @click="btnSumbit">
            提交
          </Button>
          <!-- <p v-if="state.contractType != 53 && state.contractType != 69">
            合同增减项默认通知客户
          </p> -->
        </div>
      </Form>

      <!-- 变更合同组件 -->
      <Popup v-model:show="state.showContractPicker" position="bottom">
        <Picker
          :columns="state.contract_list"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'contract')"
          @cancel="state.showContractPicker = false"
          :default-index="
            state.contract_list.findIndex((v) => {
              return v.id == form.list.contract_id;
            })
          "
        />
      </Popup>
      <!-- 变更阶段组件 -->
      <Popup v-model:show="state.showTypePicker_stage" position="bottom">
        <Picker
          :columns="state.construction_stages"
          :columns-field-names="customFieldName"
          @confirm="(v) => onConfirm(v, 'stage')"
          @cancel="state.showTypePicker_stage = false"
          :default-index="
            state.construction_stages.findIndex((v) => {
              return v.id == form.list.contract_id;
            })
          "
        />
      </Popup>
      <!-- 基装\主材\其它组件 -->
      <Popup
        v-model:show="state.cardShow"
        :close-on-popstate="true"
        position="bottom"
        :style="{ height: '100%' }"
      >
        <BaseTemp
          v-if="state.cardShow"
          :data="state.cardOptions"
          :unitList="state.unitList"
          @reviewOk="reviewOk"
        />
      </Popup>
    </div>
  </div>
</template>

<script setup>
import FileUpload from '@/components/common/FileUpload';
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import BaseTemp from '@/components/change/BasePurposeTemp'; //基装\主材\其它
import {
  Form,
  Field,
  Popup,
  Picker,
  Icon,
  Toast,
  Notify,
  Button,
  Loading,
  Overlay,
  Switch,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
} from 'vant';
import patterns from '@/utils/pattern';
const $http = inject('$http');
const router = useRouter();
const state = reactive({
  loading: false,
  loadingConfirm: false,
  //-客户id
  customer_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  //-变更单Id
  change_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.gId
    : '',
  //-合同Id
  contract_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.cid
    : '',
  contractType: '', //-合同类型
  unitList: [], //-单位方式参数
  //-变更合同参数
  showContractPicker: false,
  contract_list: [],
  // end
  //-基装等三大类型编辑参数
  cardOptions: {
    optionType: 'edit',
    type: '',
    base_arr: [],
    main_arr: [],
    other_arr: [],
    total: 0,
    totalPlus: 0,
    totalMinus: 0,
  },
  cardShow: false,
  //-end
  // 变更阶段组件
  showTypePicker_stage: false,
  construction_stages: [],
  //-end
  construction_info: {}, //-工地状态
});
//-参数&详情-begin
onMounted(() => {
  getContractPublicParameter();
});
//-获取参数
const getContractPublicParameter = async () => {
  state.loading = true;
  await $http
    .getContractPublicParameter({ customer_id: state.customer_id })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        state.unitList = data.material_type;
        state.construction_stages = data.construction_stages;
        if (state.customer_id) {
          getContractCustomerParameter();
        }
      } else {
        Toast(data);
      }
    });
};
//-获取客户参数
const getContractCustomerParameter = async () => {
  await $http
    .getContractCustomerParameter({ customer_id: state.customer_id })
    .then((res) => {
      state.loading = false;
      const { data, code } = res;
      if (code === 0) {
        state.contract_change_scene_confirm_labels =
          data.contract_change_scene_confirm_labels;
        state.contract_change_is_schedule_labels =
          data.contract_change_is_schedule_labels;
        state.contract_change_type_labels = data.contract_change_type_labels;
        // 工程：54，56 产品：53，69
        state.contract_list = data.item_contract_list.filter(
          (v) => v.type == 53 || v.type == 69
        );
        state.construction_info = data.construction_info;

        if (state.contract_id) {
          const contract_list = state.contract_list.filter(
            (v) => v.id == state.contract_id
          );
          form.list.contract_id = contract_list.length ? state.contract_id : '';
          form.list.contract_name = contract_list.length
            ? contract_list[0].name
            : '';
          form.list.contract_no = contract_list.length
            ? contract_list[0].contract_no
            : '';
        }

        if (state.change_id) {
          getContractInfo();
        }
      } else {
        Toast(data);
      }
    });
};

//-获取变更单详情
const getContractInfo = async () => {
  await $http.contract_intendInfo({ id: state.change_id }).then((res) => {
    state.loading = false;
    const { data, code } = res;
    if (code === 0) {
      form.list = data;
      form.list.attach = form.list.attach.length ? form.list.attach : [];
      //-兼容【产品代购合同】和【装饰合同工程配套配套材料补充协议】有权限选择是否通知客户,其他类型合同 默认通知客户
      state.contractType = data.contract_type;
      // if (data.contract_type != 53 && data.contract_type != 69) {
      //   form.list.notify_customer_flag = false;
      // }
      //-end
    } else {
      Toast(data);
    }
  });
};
//-end

//--表单&验证--------begin
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    change_item_type: 2, //-1工程 2产品
    // 合同
    contract_id: '',
    contract_no: '',
    contract_name: '',
    // 变更的施工阶段
    construction_stage_name: '',
    construction_stage: '',
    //-现场确认
    scene_confirm: '',
    // 变更类别
    change_types: [],
    // 预估工期是否变更
    is_change_schedule: '',
    //-变更工期
    change_schedule: '',
    //-通知客户
    notify_customer_flag: true,
    //-附件
    attach: [],
    //-备注
    remark: '',
    base_arr: [],
    main_arr: [],
    other_arr: [],
    base_plus_amount: '0.00',
    base_minus_amount: '0.00',
    base_amount: '0.00',
    main_plus_amount: '0.00',
    main_minus_amount: '0.00',
    main_amount: '0.00',
    other_plus_amount: '0.00',
    other_minus_amount: '0.00',
    other_amount: '0.00',
    amount: '0.00',
  },
});
//-验证
const { numReg } = patterns;
const validatorNum = (val) => {
  if (val) {
    if (parseFloat(val) <= 90 && parseFloat(val) >= 1) {
      if (numReg.test(val)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};
//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
//-end

//-操作&详情--begin

//-picked 操作
const onConfirm = (value, type) => {
  //-变更合同
  if (type === 'contract') {
    if (value) {
      form.list.contract_name = value.name;
      form.list.contract_id = value.id;
      form.list.contract_no = value.contract_no;
      state.contractType = value.type;
    }
    state.showContractPicker = false;
  } else if (type === 'stage') {
    //-变更阶段
    if (value) {
      form.list.construction_stage_name = value.name;
      form.list.construction_stage = value.id;
    }
    state.showTypePicker_stage = false;
  }
};
//-切换是否，清空数据
const clearFun = (v) => {
  if (v == 2) {
    form.list.change_schedule = '';
  }
};

//-基装等三大卡片类型
const getPopup = (type) => {
  state.cardOptions.type = type;
  state.cardShow = true;
  if (type == 'base') {
    state.cardOptions.base_arr = form.list.base_arr;
    state.cardOptions.total = form.list.base_amount;
    state.cardOptions.totalPlus = form.list.base_plus_amount;
    state.cardOptions.totalMinus = form.list.base_minus_amount;
  }
  if (type == 'main') {
    state.cardOptions.main_arr = form.list.main_arr;
    state.cardOptions.total = form.list.main_amount;
    state.cardOptions.totalPlus = form.list.main_plus_amount;
    state.cardOptions.totalMinus = form.list.main_minus_amount;
  }
  if (type == 'other') {
    state.cardOptions.other_arr = form.list.other_arr;
    state.cardOptions.total = form.list.other_amount;
    state.cardOptions.totalPlus = form.list.other_plus_amount;
    state.cardOptions.totalMinus = form.list.other_minus_amount;
  }
};
//-保存三大类数据
const reviewOk = (total, list) => {
  if (total.type == 'base') {
    form.list.base_arr = list ? list.base_arr : state.cardOptions.base_arr;
    form.list.base_plus_amount = list
      ? total.totalPlus
      : state.cardOptions.totalPlus;
    form.list.base_minus_amount = list
      ? total.totalMinus
      : state.cardOptions.totalMinus;
    form.list.base_amount = list ? total.total : state.cardOptions.total;
  }
  if (total.type == 'main') {
    form.list.main_arr = list ? list.main_arr : state.cardOptions.main_arr;
    form.list.main_plus_amount = list
      ? total.totalPlus
      : state.cardOptions.totalPlus;
    form.list.main_minus_amount = list
      ? total.totalMinus
      : state.cardOptions.totalMinus;
    form.list.main_amount = list ? total.total : state.cardOptions.total;
  }
  if (total.type == 'other') {
    form.list.other_arr = list ? list.other_arr : state.cardOptions.other_arr;
    form.list.other_plus_amount = list
      ? total.totalPlus
      : state.cardOptions.totalPlus;
    form.list.other_minus_amount = list
      ? total.totalMinus
      : state.cardOptions.totalMinus;
    form.list.other_amount = list ? total.total : state.cardOptions.total;
  }
  state.cardShow = false;
  form.list.amount = (
    parseFloat(form.list.base_amount) +
    parseFloat(form.list.main_amount) +
    parseFloat(form.list.other_amount)
  ).toFixed(2);
  if (parseFloat(form.list.amount) < 0) {
    if (form.list.type == 2) {
      form.list.type = '';
      form.list.type_str = '';
      form.list.proportion_id = '';
    }
  } else {
    if (form.list.type == 1) {
      form.list.type = '';
      form.list.type_str = '';
    }
  }
};

// @附件
const getFileList = (files) => {
  form.list.attach = files;
};

//-创建
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      goSave();
    })
    .catch(() => {
      //验证失败
      Toast('请先完善信息');
    });
};
const goSave = () => {
  state.loadingConfirm = true;
  // if (state.contractType != 53 && state.contractType != 69) {
  form.list.notify_customer_flag = true;
  // }
  $http
    .contract_addIntendItem({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//--表单end---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/change/change.less';
</style>
