<!--
 * @Author: gest
 * @Date:  2022-08-23 15:17:32
 * @LastEditTime: 2024-03-23 10:20:56
 * @Description: 分配服务人员 
 待办类型 47分配施工图深化设计师 48分配水电工程师 49 分配图审专员
-->
<template>
  <NavBar
    safe-area-inset-top
    :title="
      state.task_type == 47
        ? '分配施工图深化设计师'
        : state.task_type == 48
        ? '分配水电工程师'
        : state.task_type == 49
        ? '分配图审专员'
        : ''
    "
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
  />
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              {{
                state.task_type == 47
                  ? '选择施工图深化设计师'
                  : state.task_type == 48
                  ? '选择水电工程师'
                  : state.task_type == 49
                  ? '选择图审专员'
                  : ''
              }}
            </label>
            <Field
              v-model="form.list.service_person_id_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              @click="state.showPicker = true"
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->

    <Popup v-model:show="state.showPicker" position="bottom">
      <Picker
        :columns="state.personList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v)"
        @cancel="state.showPicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  NavBar,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  customer_id: router.currentRoute.value.query?.customer_id,
  task_type: router.currentRoute.value.query?.type, //待办类型
  source_id: router.currentRoute.value.query?.source_id,
  todo_id: router.currentRoute.value.query?.todo_id ?? '',
  //picker
  showPicker: false,
  personList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    service_person_id: '',
    service_person_id_name: '',
  },
});

const onConfirm = (value) => {
  if (value) {
    form.list.service_person_id = value.id;
    form.list.service_person_id_name = value.name;
  }
  state.showPicker = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  await $http.customerAssignServicePersonParam().then((res) => {
    if (res.code === 0) {
      state.personList =
        state.task_type == 47
          ? res.data.construction_deepen_design
          : state.task_type == 48
          ? res.data.mains_user
          : state.task_type == 49
          ? res.data.drawing_review_user
          : [];
    } else {
      Toast(res.data);
    }
  });
};
//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .customerAssignServicePerson({
          customer_id: state.customer_id,
          todo_id: state.todo_id,
          type:
            state.task_type == 47
              ? 6
              : state.task_type == 48
              ? 15
              : state.task_type == 49
              ? 18
              : '',
          service_person_id: form.list.service_person_id,
          drawing_id: state.source_id,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
const onClosePop = () => {
  router.back();
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
.page-wrap {
  margin-top: 44px;
}
</style>
