<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-03-20 09:43:34
 * @Description: 【尚层装饰室内设计协议】【尚层装饰配饰设计协议】-合同收款
-->
<template>
  <!-- 合同收款 -->
  <div class="temp">
    <h1>合同收款</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签订合同价
            </label>
            <van-field
              v-model="form.contract_amount"
              type="number"
              @blur="getAmountNum"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的签订合同价',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写签订合同价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              收费比例
            </label>
            <van-field
              v-model="form.directory_attr_name"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请选择收费比例',
                },
              ]"
              placeholder="收费比例"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              设计费
            </label>
            <van-field
              v-model="form.design_amount"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的设计费',
                  validator: validatorDesgin,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写设计费"
            >
              <template #button>
                <van-text>元/㎡</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">优惠活动</label>
            <van-field
              v-model="form.discount_activity_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择优惠活动"
              @click="showActivePicker = true"
            />
            <van-popup v-model:show="showActivePicker" position="bottom">
              <van-picker
                :columns="activeList"
                :columns-field-names="customFieldName"
                @confirm="onConfirm"
                cancel-button-text="清除"
                @cancel="onRemoveActive"
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">优惠金额</label>
            <van-field
              v-model="form.discount_amount"
              type="number"
              placeholder="0.00"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的优惠金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>

          <div class="cell">
            <label class="form_title">贷款金额</label>
            <van-field
              v-model="form.loan_amount"
              type="number"
              placeholder="0.00"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的贷款金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell2">
            <label class="form_title">收款期数</label>
            <div
              class="collect_list"
              v-for="(item, index) in form.charge_proportion_details"
              :key="index"
            >
              <div class="line1 clearfix">
                <div class="pull-left header">
                  {{ item.payment_period }}
                </div>
                <div class="pull-left">
                  <p class="title">收款比例</p>
                  <p class="num">{{ item.proportion + '%' }}</p>
                </div>
                <div class="pull-left">
                  <p class="title">应收金额</p>
                  <p class="num">{{ item.receivable_money }}</p>
                </div>
              </div>
              <div class="line2">
                <van-field
                  v-model="item.payment_description"
                  placeholder="输入款项说明"
                  maxlength="100"
                />
              </div>
            </div>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
  Notify,
} from 'vant';
import { Toast } from 'vant';
export default {
  props: [
    'type',
    'contractType',
    'data',
    'parameterList',
    'charge_proportions',
    'contractTypeName',
    'house_size',
  ],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    [Notify.name]: Notify,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      contractType: props.contractType,
      //-活动
      showActivePicker: false,
      activeList: props.parameterList
        ? props.parameterList.contract_activity
        : [],
    });

    //--表单---------------------
    //-贷款/签订合同价/优惠金额
    const validatorAmount = (val) =>
      val ? (parseFloat(val) <= 99999999.99 ? true : false) : true;
    //-设计费
    const validatorDesgin = (val) =>
      val ? (parseFloat(val) <= 9999.99 ? true : false) : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      contract_amount: props.data.contract_amount
        ? props.data.contract_amount
        : '',
      directory_attr_id: props.data.directory_attr_id
        ? props.data.directory_attr_id
        : props.contractType,
      directory_attr_name: props.data.directory_attr_name
        ? props.data.directory_attr_name
        : props.contractTypeName,
      discount_activity_id: props.data.discount_activity_id
        ? props.data.discount_activity_id
        : '',
      discount_activity_name: props.data.discount_activity_name
        ? props.data.discount_activity_name
        : '',
      discount_amount: props.data.discount_amount
        ? props.data.discount_amount
        : '',
      design_amount: props.data.design_amount ? props.data.design_amount : '',

      charge_proportion_details: props.data.charge_proportion_details
        ? props.data.charge_proportion_details
        : props.charge_proportions
        ? props.charge_proportions
        : [],
      loan_amount: props.data.loan_amount ? props.data.loan_amount : '',
    });
    //-picker-活动选择项字段
    const customFieldName = {
      text: 'name',
      value: 'id',
    };
    const onConfirm = (value) => {
      if (value) {
        form.discount_activity_name = value.name;
        form.discount_activity_id = value.id;
      }
      state.showActivePicker = false;
    };
    const onRemoveActive = () => {
      form.discount_activity_name = '';
      form.discount_activity_id = '';
      state.showActivePicker = false;
    };
    //-获取款期
    const floatNum1 = /^[0-9]+([.]{1}[0-9]{1})?$/;
    const floatNum2 = /^[0-9]+([.]{1}[0-9][0-9]{1})?$/;
    const getAmountNum = () => {
      if (form.charge_proportion_details.length) {
        let total = 0;
        return form.charge_proportion_details.map((v, index) => {
          if (
            index == form.charge_proportion_details.length - 1 &&
            form.charge_proportion_details.length > 1
          ) {
            v.receivable_money = (
              parseFloat(form.contract_amount ? form.contract_amount : 0) -
              parseFloat(total)
            ).toFixed(2);
          } else {
            var currentMoney =
              parseFloat(form.contract_amount ? form.contract_amount : 0) *
              (parseFloat(v.proportion) / 100);
            var currentMoneySpecial =
              Math.ceil(
                parseFloat(form.contract_amount ? form.contract_amount : 0) *
                  (parseFloat(v.proportion) / 100) *
                  100
              ) / 100;
            v.receivable_money =
              Number.isInteger(currentMoney) || floatNum1.test(currentMoney)
                ? currentMoney.toFixed(2)
                : floatNum2.test(currentMoney)
                ? currentMoney
                : floatNum1.test(currentMoneySpecial)
                ? currentMoneySpecial.toFixed(2)
                : currentMoneySpecial;

            total += parseFloat(v.receivable_money);
          }

          v.payment_period;
          v.proportion;
          v.payment_period;
        });
      }
    };
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          if (
            parseFloat(form.contract_amount ?? 0) >
            parseFloat(form.design_amount ?? 0) *
              parseFloat(props.house_size ?? 0)
          ) {
            Notify({
              type: 'warning',
              message: '合同金额不能超过房屋面积 x 设计费金额',
            });
          } else {
            emit('reviewFun', state.type, form);
          }
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      onConfirm,
      btnSumbit,
      customFieldName,
      getAmountNum,
      validatorAmount,
      validatorDesgin,
      onRemoveActive,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
