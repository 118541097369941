<!--
 * @Author: gest
 * @Date: 2023-10-12 16:05:21
 * @LastEditTime: 2023-10-14 09:54:45
 * @Description:V1.4.7 合同详情- 变更记录
-->
<template>
  <div>
    <Sticky>
      <Tabs
        class="tabList"
        v-model:active="_DATA.tabIndex"
        @click-tab="clickTab"
      >
        <Tab title="变更确认单" :name="1"> </Tab>
        <!-- <Tab title="五步 + 三部曲" :name="2"></Tab> -->
        <Tab title="变更意向单" :name="2"></Tab>
      </Tabs>
    </Sticky>
    <div class="info_box">
      <contractChangeList v-if="_DATA.tabIndex == 1" />

      <contractChangePurposeList v-if="_DATA.tabIndex == 2" />
    </div>
  </div>
</template>
<script setup>
import contractChangeList from '@/components/sale/contractChangeList.vue';
import contractChangePurposeList from '@/components/sale/contractChangePurposeList.vue';

import { reactive } from 'vue';

import { Tabs, Tab, Sticky } from 'vant';

const _DATA = reactive({
  tabIndex: '',
});

const clickTab = (val) => {
  _DATA.tabIndex = val.name;
};
</script>
<style lang="less" scoped>
.tabList {
  position: absolute;
  top: 46px;
  background: #fff;
  z-index: 99;
  width: 100vw;
  left: 0px;
}
.info_box {
  margin-top: 46px;
  background-color: #f5f5f5;
}
</style>
