<!--
 * @Author: Jessica
 * @Date: 2023-06-17 16:36:06
 * @LastEditTime: 2024-05-31 16:11:29
 * @Description: 目标数据-交付数
-->
<template>
  <!-- 交付数 -->
  <div class="main-data">
    <div class="warpper" @click.stop="redictFun">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading || props.loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_5.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                交付数
                <em>{{ detail.total?.actual ?? '-' }}</em>
                <span class="font-size15">
                  {{ ' /' + detail.total?.all ?? '-' }}
                </span>
              </div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>应交付</em>
                  <em class="pl-3">{{ detail.total?.target ?? '-' }}</em>
                  <em>，完成率</em>
                  <em class="pl-3">
                    {{ detail.total?.done_rate + '%' ?? '-' }}
                  </em>
                  <!-- 规则入口 -->
                  <div
                    class="icon_info_box_small pl-3"
                    @click.stop="createDanger"
                  >
                    <img
                      class="icon_img"
                      :src="
                        require('@/assets/images/target-data/icon_info.png')
                      "
                      alt="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 应交付数 -->
                <span class="font-size15 bold text_red">
                  {{ detail.total?.should ?? '-' }}
                </span>
              </div>
              <div class="flex_right">
                <div class="temp1 text_right">
                  <span class="font-size12">待交付</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <!-- 当年 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">当年</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>应交付</em>
                      <em class="pl-3">{{ detail.now?.target ?? '-' }}</em>
                    </div>
                    <div class="target">
                      <em>完成率</em>
                      <em class="pl-3">{{
                        detail.now?.done_rate + '%' ?? '-'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.now?.actual ?? '-' }}</em>
                    <span>{{ ' /' + detail.now?.all ?? '-' }}</span>
                  </p>
                  <p class="temp3">交付率{{ detail.now?.rate + '%' ?? '-' }}</p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <div class="temp2">
                    <!-- 应交付数 -->
                    <span class="font-size15 bold flex_right text_red">
                      {{ detail.now?.should ?? '-' }}
                    </span>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>待交付</em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 存量 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">存量</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>应交付</em>
                      <em class="pl-3">{{ detail.passed?.target ?? '-' }}</em>
                    </div>
                    <div class="target">
                      <em>完成率</em>
                      <em class="pl-3">
                        {{ detail.passed?.done_rate + '%' ?? '-' }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.passed?.actual ?? '-' }}</em>
                    <span>{{ ' /' + detail.passed?.all ?? '-' }}</span>
                  </p>
                  <p class="temp3">
                    交付率{{ detail.passed?.rate + '%' ?? '-' }}
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <div class="temp2">
                    <!-- 应交付数 -->
                    <span class="font-size15 bold flex_right text_red">
                      {{ detail.passed?.should ?? '-' }}
                    </span>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>待交付</em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 锦旗数 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">锦旗数</p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.pennant?.num ?? '-' }}
                    </em>
                    <!-- <span style="font-size: 12px; padding-left: 10px">面</span> -->
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em class="text_green">
                      {{ detail.pennant?.rate + '%' ?? '-' }}
                    </em>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 履约率 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">履约率</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.reach_rate?.target_rate + '%' ?? '-'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.reach_rate?.actual_rate + '%' ?? '-' }}</em>
                  </p>
                  <p class="temp3">
                    <span>{{ detail.reach_rate?.reach_num ?? '-' }}</span>
                    <span>{{ '/' + detail.reach_rate?.all_num ?? '-' }}</span>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.reach_rate?.target_rate ?? 0) <
                          parseFloat(detail.reach_rate?.actual_rate ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.reach_rate?.gap_rate + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.reach_rate?.target_rate ?? 0) >
                          parseFloat(detail.reach_rate?.actual_rate ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.reach_rate?.gap_rate + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 当年交付 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="8">
                <div class="col">
                  <p class="t">交付工期</p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.now_time?.deliver_schedule + '天' ?? '-' }}
                    </em>
                  </p>
                  <p class="temp3">当年</p>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>
                      {{ detail.passed_time?.deliver_schedule + '天' ?? '-' }}
                    </em>
                  </p>
                  <p class="temp3">存量</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  defineProps,
  watch,
  defineExpose,
  defineEmits,
  reactive,
} from 'vue';
import { Notify, Skeleton, Row, Col } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
const emit = defineEmits(['createDanger']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http
    .targetStatistic_homeConstruction({ year: props.data.yearId })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        detail.value = data;
      } else {
        loading.value = false;
        detail.value = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 3, pos: 2 },
  });
};

//-规则说明--begin
const _rule = reactive({
  htmlTitle: '交付数_规则',
  htmlContent: '',
});

const createDanger = () => {
  let yearInfo = props.data.yearId + '年';
  let isNowYearInfo =
    props.data.yearId === props.data.now_year
      ? '当月底'
      : props.data.yearId + '-12-31';
  _rule.htmlContent = `<h5>数据定义：</h5>
<p>· 当年：指${yearInfo}开工在${yearInfo}完工工地；</p>
<p class="pl_10">（1）应交付数：a+b+c</p>
<p class="pl_20">a. 合同完工日期<${isNowYearInfo}的未开工工地数；</p>
<p class="pl_20">b. 必须完工日期<${isNowYearInfo}且在${yearInfo}开工的在建工地数；</p>
<p class="pl_20">c. 必须完工日期<${isNowYearInfo}且在${yearInfo}开工${yearInfo}完工的工地数；</p>
<p class="pl_10">（2）交付数：${yearInfo}开工在${yearInfo}完工工地；</p>
<p class="pl_10">（3）完成率：交付数/应交付数；</p>
<p class="pl_10">（4）工地数：${yearInfo}开工的工地数；</p>
<p class="pl_10">（5）交付率：交付数/工地数；</p>
<p class="pl_10">（6）待交付：a+b</p>
<p class="pl_20">a. 合同完工日期<${isNowYearInfo}的未开工工地数；</p>
<p class="pl_20">b. 必须完工日期<${isNowYearInfo}的在建工地数；</p>
<p>· 存量：指${yearInfo}前开工在${yearInfo}完工工地；</p>
<p class="pl_10">（1）应交付：b+c</p>
<p class="pl_20">b. 必须完工日期<${isNowYearInfo}且在${yearInfo}前开工的在建工地数；</p>
<p class="pl_20">c. 必须完工日期<${isNowYearInfo}且在${yearInfo}前开工${yearInfo}完工的工地数；</p>
<p class="pl_10">（2）交付数：指${yearInfo}前开工在${yearInfo}完工工地；</p>
<p class="pl_10">（3）完成率：交付数/应交付数；</p>
<p class="pl_10">（4）工地数：${yearInfo}前开工的在建工地与${yearInfo}前开工并在${yearInfo}完工的工地数之和；</p>
<p class="pl_10">（5）交付率：${yearInfo}完工工地/工地数；</p>
<p class="pl_10">（6）待交付：b</p>
<p class="pl_20">b. 必须完工日期<${isNowYearInfo}且在${yearInfo}前开工的在建工地数；</p>
<p>· 锦旗数：${yearInfo}累计获得的锦旗数；</p>
<p>· 锦旗率：${yearInfo}获得锦旗完工工地数/${yearInfo}完工工地数；</p>
<p>· 履约率：${yearInfo}实际履约工地数/${yearInfo}应履约工地基数；</p>
<p class="pl_10">（1）实际履约工地数：</p>
<p class="pl_20">d. 完工日期在${yearInfo}未延期的工地数；</p>
<p class="pl_10">（2）应履约工地基数：a + b + c</p>
<p class="pl_20">a. 合同完工日期<${isNowYearInfo}的未开工工地数；</p>
<p class="pl_20">b. 必须完工日期<${isNowYearInfo}的在建工地数；</p>
<p class="pl_20">c. 必须完工日期<${isNowYearInfo}且在${yearInfo}完工的工地数；</p>
<p>· 交付工期(当年)：${yearInfo}开工在${yearInfo}完工工地平均交付工期；</p>
<p>· 交付工期(存量)：${yearInfo}前开工在${yearInfo}完工工地平均交付工期；</p>`;
  emit('createDanger', 'deliverTempRef');
};
defineExpose({ _rule });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
