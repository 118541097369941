<!--
 * @Author: NanNan
 * @Date: 2022-04-16 09:35:25
 * @LastEditTime: 2024-05-10 11:15:57
 * @Description: 待办事项 - 全部（企微入口）
 * v1.3.2 smart UX改版（去除生命周期，内容显示参照客户待办）
 * v1.3.4 smart 根据url传参id 来判断是否是待办审批跳转而来，锁定到相关id任务
-->
<template>
  <div class="page-box">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 日期栏 -->
    <Tabs
      v-model:active="_DATA.form.date"
      class="tab-day-box"
      sticky
      @change="changeTab"
    >
      <Tab v-for="item in _DATA.day_list" :key="item.date" :name="item.date">
        <template #title>
          <Badge color="#008911" dot v-if="item.green_flag === 1">
            <div>
              <p class="day">{{ item.day }}</p>
              <p class="date">{{ item.day_name }}</p>
            </div>
          </Badge>
          <div v-else>
            <p class="day">{{ item.day }}</p>
            <p class="date">{{ item.day_name }}</p>
          </div>
        </template>
        <!-- 任务内容 -->
        <div class="content">
          <div v-if="!loading_list && _DATA.list?.length > 0">
            <div class="title">
              <span class="day-name">今日任务</span>
              <span class="percent">
                已完成
                <i>{{ item.over_count }}</i>
                <em>/{{ item.total }}</em>
              </span>
            </div>
            <p class="percent-line">
              <span
                v-if="item.over_count > 0"
                :style="{ width: (item.over_count / item.total) * 100 + '%' }"
              ></span>
            </p>
          </div>
          <!-- 待办卡片 -->
          <div
            class="task-card-same"
            v-for="(val, idx) in _DATA.list"
            :key="idx"
            :class="'listClass' + val.id"
            @click="
              () => {
                val.status != 1 &&
                  val.type == 27 &&
                  router.push(
                    '/taskNotice?id=' +
                      val.id +
                      '&source_id=' +
                      val.source_id +
                      '&status=' +
                      val.status
                  );
              }
            "
          >
            <!-- 状态栏 状态：1 待处理 2 已处理 3 取消-->
            <p class="h1">
              <label
                :class="{ done: val.status === 2, cancel: val.status === 3 }"
              >
                <img
                  :src="require('@/assets/images/icon-wait.png')"
                  alt="待处理"
                  v-if="val.status === 1"
                />
                <img
                  :src="require('@/assets/images/icon-pass.png')"
                  alt="已处理"
                  v-if="val.status === 2"
                />
                <img
                  :src="require('@/assets/images/icon-cancle.png')"
                  alt="取消"
                  v-if="val.status === 3"
                />
                {{ val.status_str }}
              </label>
              <span class="delay_time" v-if="val.decline_day">
                已延期{{ val.decline_day }}天
              </span>
            </p>
            <div class="tip_box" v-if="val.remain_day && val.type == 51">
              <svg class="icon-small" aria-hidden="true">
                <use xlink:href="#icon-information-line"></use>
              </svg>
              {{
                val.remain_day < 0
                  ? `距自动派单不足一小时`
                  : `距自动派单还剩${val.remain_day}天`
              }}
            </div>
            <!-- 相关事项 -->
            <div class="do-box">
              <p class="title">{{ val.type_str }}</p>
              <ol class="ol-flex">
                <li v-for="(v, i) in val.content" :key="i">
                  <label>{{ i + '：' }}</label>
                  <span>{{ v }}</span>
                </li>
              </ol>
            </div>
            <!-- 操作按钮 (只有待处理才展示)-->
            <div class="option-box" v-if="val.status === 1">
              <Button
                type="primary"
                class="button-flex"
                @click="
                  routerTo(val.type, val.source_id, {
                    todo_id: val.id,
                    isManager: val.is_customer_manager,
                    design_id: val.design_id,
                    node_id: val.node_id,
                    contract_id: val.contract_id,
                    construction_id: val.construction_id,
                    payment_id: val.payment_id,
                    customer_id: val.customer_id,
                    source_id: val.source_id,
                    node_name: val.node_name,
                    faceType: val.face_type,
                    faceMode: val.face_mode,
                    c_uid_name: val.c_uid_name,
                    status: val.status,
                    c_post_id: val.node_info ? val.node_info.c_post_id : '',
                    type_sort: val.node_info ? val.node_info.type_sort : '',
                    second_check_role_id: val.node_info
                      ? val.node_info.second_check_role_id
                      : '',
                    second_check_user_name: val.node_info
                      ? val.node_info.second_check_user_name
                      : '',
                    next_ids: val.next_ids,
                  })
                "
              >
                <span>去处理</span>
              </Button>
              <Button
                class="button-flex"
                v-if="val.type === 1 || val.type === 2"
                @click="
                  routerFailTo(val.type, val.source_id, {
                    isManager: val.is_customer_manager,
                    design_id: val.design_id,
                    node_id: val.node_id,
                    contract_id: val.contract_id,
                    payment_id: val.payment_id,
                    source_id: val.source_id,
                    node_name: val.node_name,
                    customer_name: val.customer_name,
                  })
                "
              >
                <span v-if="val.type === 1">取消见面</span>
                <span v-if="val.type === 2">取消见工地</span>
              </Button>
            </div>
            <!--V1.2.3 查看详情 -->
            <div
              class="option-box"
              v-if="
                val.status != 1 &&
                (val.type == 30 || val.type == 31 || val.type == 50)
              "
            >
              <Button
                type="primary"
                class="button-flex"
                @click="
                  routerLook(val.type, val.source_id, {
                    todo_id: val.id,
                    isManager: val.is_customer_manager,
                    design_id: val.design_id,
                    node_id: val.node_id,
                    contract_id: val.contract_id,
                    payment_id: val.payment_id,
                    customer_id: val.customer_id,
                    source_id: val.source_id,
                    node_name: val.node_name,
                    faceType: val.face_type,
                    faceMode: val.face_mode,
                    c_uid_name: val.c_uid_name,
                    status: val.status,
                  })
                "
              >
                <span>去查看</span>
              </Button>
            </div>
          </div>
          <!-- 空状态 -->
          <Empty
            class="empty-grey"
            description="暂无数据"
            v-if="!loading_list && _DATA.list.length === 0"
          />
          <!-- end -->
        </div>
      </Tab>
    </Tabs>
    <!--V1.4.0 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->
    <!-- v1.2.0 BPM -->
    <ActionSheet v-model:show="_DATA.BPM.show" class="dangerActionSheet">
      <BPMAction
        v-if="_DATA.BPM.show"
        :data="_DATA.BPM"
        @changeBPM="changeBPM"
        :id="_DATA.form.customer_id"
      />
    </ActionSheet>
    <!-- BPM end -->
    <!-- 款期popup -->
    <Popup
      v-model:show="_DATA.cardShow"
      round
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="paymentList" v-if="_DATA.cardShow">
        <h1 class="clearfix">
          <span class="c1 pull-left">款期</span>
          <span
            class="c2 pull-right"
            @click="
              () => {
                _DATA.cardShow = false;
              }
            "
          >
            取消
          </span>
        </h1>
        <div
          class="same-box"
          v-for="(item, index) in _DATA.paymentList"
          :key="index"
        >
          <!-- 状态栏 -->
          <p class="h1">
            <label
              :class="{
                cancel: item.payment_status_str === '未收款',
                done: item.payment_status_str === '已收款',
              }"
            >
              <Icon
                name="clock-o"
                v-if="item.payment_status_str === '未收款'"
              />
              <Icon
                name="success"
                v-if="item.payment_status_str === '已收款'"
              />
              {{ item.payment_status_str }}
            </label>
            <!-- <span
            class="refund-money"
            v-if="item.payment_flag > 1"
            @click="handleClick('退款', item)"
          >
            退款
          </span> -->
          </p>

          <div class="do-box">
            <p class="title">{{ item.payment_period }}</p>
            <ul class="ul-flex">
              <li>
                <p class="text">收款比例</p>
                <p class="n font_bold">{{ item.proportion }}%</p>
              </li>
              <li v-if="item.period_type == 1">
                <p class="text">款期金额</p>
                <p class="n font_bold">{{ item.receivable_money }}</p>
              </li>
              <li
                v-if="
                  !(
                    item.contract_type == 51 ||
                    item.contract_type == 52 ||
                    item.contract_type == 55 ||
                    (item.contract_type == 56 && item.charge_setting_id == 0)
                  )
                "
              >
                <p class="text">增减项金额</p>

                <p class="n" v-if="item.is_contain == 1">
                  <router-link
                    class="link_btn font_bold"
                    :to="
                      '/adjustList?id=' +
                      _DATA.form.customer_id +
                      '&cid=' +
                      _DATA.contract_id +
                      '&pid=' +
                      item.payment_id
                    "
                  >
                    ¥{{ item.adjust_money }}>
                  </router-link>
                </p>
                <p class="n font_bold" v-else>¥{{ item.adjust_money }}</p>
              </li>
            </ul>
          </div>

          <div class="do-box margin20">
            <ol class="ol-flex">
              <li>
                <label>款项说明:</label>
                <span>{{ item.payment_description || '-' }}</span>
              </li>
              <li>
                <label>最新收款时间:</label>
                <span>{{ item.collection_time || '-' }}</span>
              </li>
            </ol>
          </div>

          <div class="total-box" v-if="item.payment_status_str === '已收款'">
            <p>
              <label>已收金额</label>
              <em>
                <i>¥</i>
                {{ item.received_money }}
              </em>
            </p>
            <p
              class="grey_color font_size_13"
              v-if="
                !(
                  item.period_type == 2 ||
                  item.contract_type == 51 ||
                  item.contract_type == 52 ||
                  item.contract_type == 55 ||
                  (item.contract_type == 56 && item.charge_setting_id == 0)
                )
              "
            >
              <label>应收金额</label>
              <span>
                <i>¥</i>
                {{ item.receivable_amount }}
              </span>
            </p>
          </div>
          <div class="total-box" v-if="item.payment_status_str === '未收款'">
            <p>
              <label>剩余应收</label>
              <em>
                <i>¥</i>
                {{ item.balance_money }}
              </em>
            </p>
            <p class="grey_color font_size_13">
              <label>已收金额</label>
              <span>
                <i>¥</i>
                {{ item.received_money }}
              </span>
            </p>
            <p
              class="grey_color font_size_13 mt_5"
              v-if="
                !(
                  item.period_type == 2 ||
                  item.contract_type == 51 ||
                  item.contract_type == 52 ||
                  item.contract_type == 55 ||
                  (item.contract_type == 56 && item.charge_setting_id == 0)
                )
              "
            >
              <label>应收金额</label>
              <span>
                <i>¥</i>
                {{ item.receivable_amount }}
              </span>
            </p>
            <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
            <ul class="wait-box orange_color mt_5">
              <li v-if="item.income_amount_str">
                <label>待审核收款</label>
                <span>¥ {{ item.income_amount_str }}</span>
              </li>
              <li v-if="item.expenditure_amount_str">
                <label>待审核退款</label>
                <span>¥ {{ item.expenditure_amount_str }}</span>
              </li>
            </ul>
          </div>

          <!-- 操作按钮 -->

          <div class="option-box" v-if="item.payment_status_str === '未收款'">
            <Button
              type="primary"
              class="button-flex"
              @click="btnNodeOpt('去收款', item)"
            >
              <span>去收款</span>
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script setup>
import {
  onMounted,
  reactive,
  ref,
  inject,
  // computed
} from 'vue';
import {
  Tab,
  Tabs,
  Icon,
  Notify,
  Overlay,
  Loading,
  Button,
  Empty,
  Badge,
  ActionSheet,
  Popup,
} from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BPMAction from '@/components/contract/BPMAction';
import sideMenu from '@/components/sideMenu';

const $http = inject('$http');
const router = useRouter();
const store = useStore();
// const allOperationAuthority = computed(
//   () => store.state.account.allOperationAuthority
// );
const _DATA = reactive({
  form: {
    type: router.currentRoute.value.query.dtype || '', //类型：1 线索获取 2 见面 3 设计签约 4 产值转化 5 施工
    date: router.currentRoute.value.query.date || '', //日期
  },
  info: '', // 卡片信息
  // day_active: '', //日期锁定
  day_list: [], //日期
  taskInfo: {
    todo_count: 0,
    task_count: 0,
  },
  //-BPM--begin
  BPM: {
    show: false,
    query: {},
  },
  //-BPM--end
  curSourceId: router.currentRoute.value.query.source_id || '',
  noBackRoutes: ['/', '/authA', '/auth', null],
  backUrl: window.history?.state?.back,
  cardShow: false,
  paymentList: [],
  payment_node_id: '',
  contract_id: '',
});

onMounted(() => {
  loading.value = true;
  loadFunc(_DATA.form.date);
  getTaskCount();
});
/**
 * 接口
 */
const loading = ref(false);
const loading_list = ref(false);
const loadFunc = (date) => {
  loading_list.value = true;
  $http.getTaskTodoList(_DATA.form).then((res) => {
    const { code, data } = res;
    loading.value = false;
    loading_list.value = false;
    if (code === 0) {
      _DATA.list = data.list;
      _DATA.day_list = data.summary;
      if (!date) {
        _DATA.form.date = data.summary.filter(
          (item) => item.is_today === 1
        )[0].date;
      }
      scrollTop(_DATA.list);
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
//待办数量
const getTaskCount = () => {
  $http.getTaskStatistics().then((res) => {
    const { code, data } = res;

    if (code === 0) {
      _DATA.taskInfo = data;
    } else {
      // Notify({
      //   type: 'warning',
      //   message: data,
      // });
    }
  });
};
/**
 * 操作
 */
//-@切换tab
const changeTab = (name) => {
  _DATA.form.date = name;
  loadFunc(name);
  router.replace({
    path: '/taskAll',
    query: { date: name },
  });
};
//-@跳转 type类型 1 见面、2 确认见工地、3 跟进  8 量房 9通知验收 10确认验收 11 合同收款 12图纸设计 14验收回访 15定期回访 16施工节点（施工） 17通知验收（施工） 18确认验收（施工） 19收款（施工） 26 分配服务人员 30获客确认见面 31派单确认见面 32待设计改派
//- 24 分配项目经理 v1.3.0紧急需求
const routerTo = (type, source_id, more) => {
  _DATA.form.customer_id = more.customer_id;
  _DATA.BPM.query = {};
  switch (type) {
    case 1:
      router.push({
        path: '/confirmFace',
        query: {
          id: more.customer_id,
          sid: source_id,
          isManager: more.isManager,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          faceType: more.faceType,
          faceMode: more.faceMode,
          cname: more.customer_name,
        },
      });
      break;
    case 2:
      router.push({
        path: '/confirmMeetSite',
        query: {
          id: more.customer_id,
          sid: source_id,
          isManager: more.isManager,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
        },
      });
      break;
    case 3:
      router.push({
        path: '/documentary',
        query: {
          id: more.customer_id,
          sid: source_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
        },
      });
      break;
    case 8:
      // 量房
      router.push({
        path: '/measureHouse',
        query: {
          id: more.customer_id,
          sid: source_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          authId: 26,
        },
      });
      break;
    case 9:
      // 设计节点 - 通知验收
      router.push({
        path: '/designNotice',
        query: {
          id: more.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
          cname: more.c_uid_name,
          type_sort: more.type_sort,
          c_post_id: more.c_post_id,
          id2: more.second_check_role_id,
          cname2: more.second_check_user_name,
        },
      });
      break;
    case 10:
      // 设计节点 - 确认验收
      router.push({
        path: '/designSure',
        query: {
          id: more.customer_id,
          did: more.design_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
        },
      });
      break;
    case 11:
      // 合同收款（v1.2.0修改）
      Object.assign(_DATA.BPM.query, {
        id: more.customer_id,
        date: _DATA.form.date,
        dtype: _DATA.form.type,
        cid: more.contract_id, //合同id
        pid: more.payment_id, //款期id
        nid: more.node_id, //设计节点id
      });
      getBPM();
      break;
    case 12:
      // 图纸设计、标记完成
      router.push({
        path: '/designOver',
        query: {
          id: more.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
        },
      });
      break;
    case 14:
      // v0.3.0 验收回访
      router.push({
        path: '/construc/visitCheck',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          sid: more.source_id,
          nname: more.node_name,
        },
      });
      break;
    case 15:
      // v0.3.0 定期回访
      router.push({
        path: '/construc/visitRegular',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          sid: more.source_id,
        },
      });
      break;
    case 16:
      // v0.3.0 施工 - 标记完成
      router.push({
        path: '/construc/over',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
        },
      });
      break;
    case 17:
      // v0.3.0 施工 - 通知验收
      router.push({
        path: '/construc/notice',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
          cname: more.c_uid_name,
        },
      });
      break;
    case 18:
      // v0.3.0 施工 - 确认验收
      router.push({
        path: '/construc/sure',
        query: {
          id: _DATA.form.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          nid: more.node_id,
          nname: more.node_name,
        },
      });
      break;
    case 19:
      // 合同收款（v1.2.0修改）
      //V1.4.1 款期列表
      $http
        .getContractPaymentListApi({
          node_id: more.node_id,
          page: 1,
          pages: 999,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            if (data.list.length == 1) {
              router.push({
                path: '/Ccollection',
                query: {
                  id: more.customer_id,
                  date: _DATA.form.date,
                  dtype: _DATA.form.type,
                  cid: more.contract_id, //合同id
                  pid: more.payment_id, //款期id
                  gdid: more.node_id, //施工节点id
                },
              });
            } else {
              _DATA.paymentList = data.list;
              _DATA.payment_node_id = more.node_id;
              _DATA.contract_id = more.contract_id;

              _DATA.cardShow = true;
            }
          } else {
            Notify({
              type: 'warning',
              message: data,
            });
          }
        });

      break;
    case 23:
      // 合同收款(v1.2.0修改)
      Object.assign(_DATA.BPM.query, {
        id: more.customer_id,
        date: _DATA.form.date,
        dtype: _DATA.form.type,
        cid: more.contract_id, //合同id
        pid: more.payment_id, //款期id
      });
      getBPM();
      break;
    case 26:
      // 设计中心经理分配服务人员
      router.push({
        path: '/designSerciverSend',
        query: {
          id: more.todo_id,
        },
      });
      break;
    case 27:
      // 设计改派通知
      router.push({
        path: '/taskNotice',
        query: {
          id: more.todo_id,
          source_id: more.source_id,
          status: more.status,
        },
      });
      break;
    case 30:
      // 获客确认见面
      router.push({
        path: '/customerMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: '',
        },
      });
      break;
    case 31:
      // 派单确认见面
      router.push({
        path: '/dispatchMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: '',
        },
      });
      break;
    case 32:
      //待设计改派
      router.push({
        path: '/applyDesignReassignment',
        query: {
          todo_id: more.todo_id,
        },
      });
      break;
    case 24:
      // 分配项目经理
      router.push({
        path: '/productManagerSend',
        query: {
          id: more.customer_id,
          date: _DATA.form.date,
          dtype: _DATA.form.type,
          gdid: more.construction_id,
        },
      });
      break;
    case 35:
      //V1.3.4 见证件
      {
        let pathStr = '/witnessViewConstruction';
        if (more.next_ids && more.next_ids.type == 1) {
          pathStr = '/witnessViewDesign';
        }
        router.push({
          path: pathStr,
          query: {
            witness_id: more.next_ids.witness_id,
            node_id: more.next_ids.node_id,
          },
        });
      }
      break;
    case 47:
      //V1.5.0 图审-分配人员
      {
        router.push({
          path: '/assignmentPerson',
          query: {
            todo_id: more.todo_id,
            customer_id: more.customer_id,
            type: type,
            source_id: source_id,
          },
        });
      }
      break;
    case 48:
      //V1.5.0 图审-分配人员
      {
        router.push({
          path: '/assignmentPerson',
          query: {
            todo_id: more.todo_id,
            customer_id: more.customer_id,
            type: type,
            source_id: source_id,
          },
        });
      }
      break;
    case 49:
      //V1.5.0 图审-分配人员
      {
        router.push({
          path: '/assignmentPerson',
          query: {
            todo_id: more.todo_id,
            customer_id: more.customer_id,
            type: type,
            source_id: source_id,
          },
        });
      }
      break;
    case 50:
      //V1.5.2 交付产品业绩
      {
        router.push({
          path: '/completeConstruction',
          query: {
            todo_id: more.todo_id,
          },
        });
      }
      break;
    case 51:
      //V1.5.3 完善派单信息
      {
        router.push({
          path: '/perfectDispatch',
          query: {
            id: source_id,
          },
        });
      }
      break;
    default:
      Notify({
        type: 'warning',
        message: '企微版该功能暂未上线，请去网页版-红珊瑚操作！',
      });
      break;
  }
};
const btnNodeOpt = (type, item) => {
  if (type == '去收款') {
    router.push({
      path: '/Ccollection',
      query: {
        id: _DATA.form.customer_id,
        cid: _DATA.contract_id, //合同id
        pid: item.payment_id, //款期id
        gdid: _DATA.payment_node_id, //节点id
        date: _DATA.form.date,
        dtype: _DATA.form.type,
      },
    });
  }
};
//-@跳转 type类型 1见面 2确认见工地
const routerFailTo = (type, source_id, more) => {
  console.log(type, source_id, more);
  switch (type) {
    case 1:
      router.push({
        path: '/commonRemark',
        query: {
          id: _DATA.form.customer_id,
          paramsID: more.source_id,
          title: '取消见面',
        },
      });
      break;
    case 2:
      router.push({
        path: '/commonRemark',
        query: {
          id: _DATA.form.customer_id,
          paramsID: more.source_id,
          title: '取消见工地',
        },
      });
      break;
  }
};
//v1.2.3待办查看详情
const routerLook = (type, source_id, more) => {
  switch (type) {
    case 30:
      // 获客确认见面
      router.push({
        path: '/customerMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: 'look',
        },
      });
      break;
    case 31:
      router.push({
        path: '/dispatchMeet',
        query: {
          id: more.todo_id,
          date: _DATA.form.date,
          showType: 'look',
        },
      });
      break;
    case 50:
      router.push({
        path: '/completeConstruction',
        query: {
          todo_id: more.todo_id,
          showType: 'look',
        },
      });
      break;
  }
};
//-@v1.2.0 判断BPM
const getBPM = () => {
  store.dispatch('BPM/getBpmAction', _DATA.form.customer_id).then((res) => {
    if (res) {
      changeBPM();
    } else {
      _DATA.BPM.show = true;
    }
  });
};

//-@v1.2.0 BPM成功返回
const changeBPM = () => {
  router.push({
    path: '/Ccollection',
    query: { ..._DATA.BPM.query },
  });
};

// -- v1.3.4锁定id自定义滚动
const scrollItem = reactive({});
const scrollTop = (list) => {
  if (!_DATA.curSourceId) {
    return;
  }
  const checkItems = list.filter((item) => item.id == _DATA.curSourceId);
  if (checkItems.length > 0) {
    Object.assign(scrollItem, checkItems[0]);
    setTimeout(function () {
      document.documentElement.scrollTop = document.querySelector(
        '.listClass' + checkItems[0].id
      ).offsetTop;
    }, 100);
    return;
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/task/all.less';
.paymentList {
  width: 100%;
  padding: 0px 0px 20px;
  > h1 {
    width: 100%;
    padding: 0px 25px;
    margin: 40px 0 20px;
    > span.c1 {
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      color: #3e4345;
    }
    > span.c2 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: @blue-color;
    }
  }
  .same-box {
    background: #ffffff;
    border: 1px solid rgba(228, 228, 228, 0.6);

    border-radius: 2px;
    box-shadow: 0px 4.3vw 4.3vw rgba(50, 50, 71, 0.08),
      0px 6.4vw 8.5vw rgba(50, 50, 71, 0.08);

    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px;
    .link_btn {
      color: @blue-color;
      text-decoration: underline;
    }
    .margin20 {
      margin: 0 20px;
      padding-right: 0px !important;
      padding-left: 0px !important;
    }

    p.h1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 16px;
      line-height: 14px;

      & > label {
        color: @orange-color;
        font-size: 12px;

        &.done {
          color: @green-color;
        }

        &.cancel {
          color: @red-color;
        }

        &.wait {
          color: @orange-color;
        }
      }

      & > span {
        color: #999;
        font-size: 14px;
      }

      .refund-money {
        color: #3d3d3d;
        font-size: 15px;
      }
    }

    div.do-box {
      padding: 15px 20px;
      border-top: 0.5px solid rgba(228, 228, 228, 0.6);

      p.title {
        line-height: 26px;
        font-size: 20px;
        font-weight: 700;
      }

      ul.ul-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 0;

        li {
          p.text {
            color: #bfbfbf;
            line-height: 14px;
            font-size: 13px;
          }

          p.n {
            margin-top: 4px;
            font-size: 17px;
            font-family: 'DIN Alternate';
            line-height: 20px;
          }
        }
      }

      ol.ol-flex {
        li {
          display: flex;
          justify-content: left;
          align-items: top;
          margin-bottom: 5px;

          label {
            flex-shrink: 0;
            flex-grow: 0;
            color: #999;
            font-size: 13px;
            padding-right: 4px;
          }

          span {
            font-size: 13px;
            word-break: break-all;
          }
        }
      }
    }

    div.option-box,
    div.total-box {
      border-top: 0.5px solid rgba(228, 228, 228, 0.6);
      padding: 15px 20px;
    }

    .total-box {
      p {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-size: 13px;
        }

        em {
          font-size: 24px;
          font-family: 'DIN Alternate';
          font-weight: bold;

          i {
            font-size: 16px;
            font-style: normal;
          }
        }
      }

      .wait-box {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;

          label {
            font-size: 13px;
          }

          span {
            font-family: SourceHanSansCN-Medium;
            font-size: 13px;
          }
        }
      }
    }
  }
}
/*
* 自定义 按钮部分
*/
.button-flex {
  width: 110px;
  height: 40px;
  margin-right: 10px;
}
</style>
