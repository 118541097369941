<!--
 * @Author: Jessica
 * @Date:  2022-11-01 15:49:53
 * @LastEditTime: 2023-06-27 14:06:51
 * @Description: 通用审核/审核不通过
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <h3 class="title_tip">{{ props.title }}</h3>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" style="box-shadow: none">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        确定
      </Button>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, ref, defineProps } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const props = defineProps(['data', 'title', 'id', 'type']);
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    remarks: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      operateFun();
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

// 调用接口
const operateFun = () => {
  let obj = {
    task_id: props.id,
    approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
    remarks: form.list.remarks,
  };
  //-通用审核接口-通过&不通过
  let url = $http.getTaskApproveRoutine;
  if (props.type == 3) {
    //-客户死单审核接口-通过&不通过
    url = $http.getTaskApproveLost;
  } else if (props.type == 12) {
    //-交付派单审核接口--不通过
    url = $http.getApproveDeliverCenter;
  } else if (props.type == 23) {
    //-合同金额变更审批-通过&不通过
    url = $http.approveContractAmountChangeApi;
    obj = {
      task_id: props.id,
      approve_type: props.data.checkStaus == 'ok' ? 2 : 3,
      remark: form.list.remarks,
    };
  }
  url(obj).then((res) => {
    const { code, data } = res;
    state.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
