<!--
 * @Author: NanNan
 * @Date: 2022-05-25 10:26:13
 * @LastEditTime: 2023-05-11 15:44:40
 * @Description: v0.3.0 新增回访
-->
<template>
  <div class="page-box middle-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 头部合同切换-->
    <div
      class="change-box-top"
      v-if="_DATA.contractList?.length > 0"
      @click="
        _DATA.contractList?.length > 1 ? (_DATA.showContract = true) : null
      "
    >
      <div class="left">
        <h1>{{ form_info.contract_name }}</h1>
        <p>合同编号：{{ form_info.contract_no }}</p>
      </div>
      <div class="right" v-if="_DATA.contractList?.length > 1">
        <Icon name="arrow-down" size="16" color="#7C7B7B" />
      </div>
    </div>
    <!-- end -->
    <Form class="form-box" ref="formRef">
      <div class="detail-box">
        <p class="label-name">
          <em>*</em>
          回访类型
        </p>
        <Field
          v-model="form_info.type_id_name"
          right-icon="arrow-down"
          readonly
          placeholder="选择回访类型"
          @click="_DATA.type_show = true"
          :rules="[
            {
              required: true,
              message: '请选择回访类型',
              trigger: 'onChange',
            },
          ]"
        />

        <p class="label-name">
          <em>*</em>
          回访结果
        </p>
        <Field
          v-model="form_info.result_id_name"
          right-icon="arrow-down"
          readonly
          placeholder="选择回访结果"
          @click="_DATA.result_show = true"
          :rules="[
            {
              required: true,
              message: '请选择回访结果',
              trigger: 'onChange',
            },
          ]"
        />

        <p class="label-name">
          <em>*</em>
          回访日期
        </p>
        <Field
          v-model="form_info.visit_time"
          name="picker"
          readonly
          placeholder="选择回访日期"
          right-icon="underway-o"
          @click="_DATA.time_show = true"
          :rules="[
            { required: true, trigger: 'onChange', message: '请选择回访日期' },
          ]"
        />

        <p class="label-name">回访内容</p>
        <Field
          v-model="form_info.remarks"
          rows="3"
          autosize
          type="textarea"
          maxlength="1000"
          placeholder="添加回访内容..."
          show-word-limit
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">附件</p>

        <FileUpload
          :data="form_info.files"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <!-- end -->

        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>

    <!-- 年月日 组件 -->
    <Popup v-model:show="_DATA.time_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择回访日期"
        @confirm="changePicker"
        @cancel="_DATA.time_show = false"
      />
    </Popup>
    <!-- 回访类型 -->
    <Popup v-model:show="_DATA.type_show" position="bottom">
      <Picker
        :columns="_DATA.visit_type_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="changeType"
        @cancel="_DATA.type_show = false"
      />
    </Popup>
    <!-- 回访结果 -->
    <Popup v-model:show="_DATA.result_show" position="bottom">
      <Picker
        :columns="_DATA.visit_result_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="changeResult"
        @cancel="_DATA.result_show = false"
      />
    </Popup>
    <!--picker-头部合同切换 --->
    <Popup v-model:show="_DATA.showContract" position="bottom">
      <Picker
        :columns="_DATA.contractList"
        :columns-field-names="{
          text: 'contract_name',
          value: 'contract_id',
        }"
        :default-index="
          _DATA.contractList.findIndex((v) => {
            return v.contract_id == form_info.contract_id;
          })
        "
        @confirm="(v) => onConfirmContract(v)"
        @cancel="_DATA.showContract = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { onMounted, reactive, inject, ref } from 'vue';
import {
  Form,
  Field,
  Notify,
  Loading,
  Overlay,
  Button,
  Popup,
  DatetimePicker,
  Picker,
  Icon,
} from 'vant';
import { useRouter } from 'vue-router';

import moment from 'moment';

const $http = inject('$http');
const loading = ref(false);
const router = useRouter();

const _DATA = reactive({
  construction_id: router.currentRoute.value.query.gdid, //工地id
  visit_type_list: [], //公共参数-回访类型
  visit_result_list: [], //公共参数-回访结果
  type_show: false, //组件-回访类型
  result_show: false, //组件-回访结果
  time_show: false, //组件-回访日期
  showContract: false, //切换合同弹框
  contractList: [],
  contract_id: router.currentRoute.value.query.cid || '', //合同
});

const form_info = reactive({
  construction_id: router.currentRoute.value.query.gdid || '', //工地id
  type_id: '', //回访类型id
  type_id_name: '',
  result_id: '', //回访结果id
  result_id_name: '',
  visit_time: '', //回访日期
  files: [], //附件 name path
  remarks: '', //备注
  contract_id: router.currentRoute.value.query.cid || '', //合同
  contract_name: '',
  contract_no: '',
});

onMounted(() => {
  loading.value = true;
  //合同列表
  getContractListParmas();
  loadOptions();
});
//-@头部合同参数
const getContractListParmas = async () => {
  await $http
    .getDesignCustomerListApi({
      customer_id: router.currentRoute.value.query?.id,
      type: 2,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        loading.value = false;
        _DATA.contractList = data.list;
        if (data.list?.length) {
          const typeList1 = data.list.filter(
            (v) => v.contract_id == _DATA.contract_id
          );
          if (typeList1 && typeList1.length > 0) {
            form_info.contract_id = typeList1[0].contract_id;
            form_info.contract_name = typeList1[0].contract_name;
            form_info.contract_no = typeList1[0].contract_no;
            form_info.construction_id = typeList1[0].construction_id;
          } else {
            form_info.contract_id = data.list[0].contract_id;
            form_info.contract_name = data.list[0].contract_name;
            form_info.contract_no = data.list[0].contract_no;
            form_info.construction_id = data.list[0].construction_id;
          }
        }
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: res.data });
      }
    });
};
//选择合同确认
const onConfirmContract = (value) => {
  form_info.contract_id = value.contract_id;
  form_info.contract_name = value.contract_name;
  form_info.contract_no = value.contract_no;
  form_info.construction_id = value.construction_id;
  _DATA.showContract = false;
};
/**
 * 接口获取
 */
//-@ 客服管理参数
const loadOptions = () => {
  // loading.value = false;
  $http.getCustomerServiceParameterApi().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      _DATA.visit_type_list = data.visit_type;
      _DATA.visit_result_list = data.visit_result;
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

/**
 * 操作
 */
//-@回访类型
const changeType = (value) => {
  _DATA.type_show = false;
  form_info.type_id = value.id;
  form_info.type_id_name = value.name;
};

//-@回访结果
const changeResult = (value) => {
  _DATA.result_show = false;
  form_info.result_id = value.id;
  form_info.result_id_name = value.name;
};

//-@回访日期
const currentDate = ref(new Date());
const changePicker = (value) => {
  _DATA.time_show = false;
  form_info.visit_time = moment(value).format('YYYY-MM-DD');
};

//-@附件
const getFileList = (file_list) => {
  form_info.files = file_list;
};

//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http.getCustomerServiceAddVisitApi({ ...form_info }).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    });
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
</style>
