<!--
 * @Author: gest
 * @Date: 2023-08-08 10:14:11
 * @LastEditTime: 2023-09-23 17:21:54
 * @Description: 跟进
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    title="跟进"
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- 表单操作 -->

    <div class="formBase">
      <Form ref="ruleFormRef">
        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item right">
              <div class="flex_between">
                <p><span class="red_color">*</span>本次跟进时间</p>
                <Field
                  v-model="form.list.now_follow_time"
                  right-icon="arrow"
                  name="picker"
                  placeholder="选择时间"
                  readonly
                  :rules="[
                    {
                      required: true,
                      message: '请选择本次跟进时间',
                    },
                  ]"
                  @click="openDateType('now_follow_time')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="flex_between">
                <div><span class="red_color">*</span>跟进类型</div>
                <Field
                  name="radio"
                  label=""
                  :rules="[
                    {
                      required: true,
                      message: '请选择跟进类型',
                    },
                  ]"
                >
                  <template #input>
                    <RadioGroup
                      v-model="form.list.meet_type"
                      direction="horizontal"
                      checked-color="#008911"
                    >
                      <Radio :name="2">见面</Radio>
                      <Radio :name="3">回访</Radio>
                    </RadioGroup>
                  </template>
                </Field>
              </div>
            </div>
          </div>
        </div>

        <div class="content mb_15">
          <div class="list_field white_bg">
            <div class="field_item">
              <div class="label-name">
                <span class="red_color">*</span>本次跟进内容
              </div>
              <Field
                v-model="form.list.follow_remark"
                rows="5"
                autosize
                type="textarea"
                maxlength="2000"
                placeholder="仅做记录，客户不可见"
                show-word-limit
                :rules="[
                  {
                    required: true,
                    message: '请填写本次跟进内容',
                  },
                ]"
              ></Field>
            </div>
          </div></div
      ></Form>
    </div>

    <div class="btnSumbit">
      <Button
        class="btn"
        type="default"
        size="large"
        @click.stop="btnSumbitDraft"
      >
        保存
      </Button>
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        :min-date="state.minDate"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>
    <!--picker -见面类型-->
    <Popup v-model:show="state.showPicker" position="bottom">
      <Picker
        :columns="state.meetList"
        :columns-field-names="customFieldName"
        @confirm="onConfirm"
        @cancel="state.showPicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, onMounted } from 'vue';
import {
  Field,
  Button,
  Popup,
  DatetimePicker,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  Dialog,
  RadioGroup,
  Radio,
  NavBar,
  Form,
} from 'vant';
import moment from 'moment';

// import { authWxAgentData, shareRecordList } from '@/utils/wechat';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');

const state = reactive({
  loadingConfirm: false,
  schedule: router.currentRoute.value.query
    ? router.currentRoute.value.query.schedule
    : '', //1  从日程点进来 已处理的跳转待办列表
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  //-picker
  showPicker: false,
  showPickerMode: false,
  meetList: [
    { id: 2, name: '见面' },
    { id: 3, name: '未见面' },
  ],
  is_convert: '',
  five_step_service_list: [],
  sign_trilogy_list: [],
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    //-待办来源Id
    todo_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.sid
      : '',
    meet_type: '',
    meet_type_name: '',
    now_follow_time: '',
    // five_step_service: [],
    // sign_trilogy: [],
    follow_remark: '',
    // attach: [],
    // next_follow_time: '',
    // next_follow_remark: '',
    // measure_house_attach: [],
    // design_plan_attach: [],
    // design_rehearsal_attach: [],
  },
});
//--基本信息表单-----end----------------
//-获取参数--begin
onBeforeMount(() => {
  if (state.schedule == 1) {
    followTodoDetail();
  } else {
    form.list.now_follow_time = moment(new Date()).format('YYYY-MM-DD HH:mm');

    getPublicParameter();
  }
});

onMounted(() => {
  // authWxAgentData();
});
const followTodoDetail = () => {
  state.loading = true;
  $http
    .followTodoDetail({
      source_id: form.list.todo_id,
      type: 2,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        if (res.data.status == 1) {
          router.push({
            path: '/taskAll',
            query: {
              date: res.data.check_time,
            },
          });
        } else {
          getPublicParameter();
        }
      } else {
        Toast(res.data);
      }
    });
};
//-获取参数
const getPublicParameter = async () => {
  await $http.getFollowParams({ id: form.list.customer_id }).then((res) => {
    if (res.code === 0) {
      state.is_convert = res.data.is_convert; //是否转化
      state.five_step_service_list = res.data.five_step_service_list;
      state.sign_trilogy_list = res.data.sign_trilogy_list;
      dispatch_getDraftDetail();
    } else {
      Toast(res.data);
    }
  });
};
//-获取详情
const dispatch_getDraftDetail = () => {
  state.loading = true;
  $http
    .dispatch_getDraftDetail({
      customer_id: form.list.customer_id,
      todo_id: form.list.todo_id,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        form.list.meet_type = res.data.meet_type;
        if (res.data.now_follow_time) {
          form.list.now_follow_time = res.data.now_follow_time;
        }

        form.list.follow_remark = res.data.follow_remark;
      }
    });
};
//-获取参数--end
//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (type == 'now_follow_time') {
    state.minDate = undefined;
  } else {
    state.minDate = new Date();
  }
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};
const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end

//-picker-选择项字段--begin
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value) => {
  if (value) {
    form.list.meet_type = value.id;
    form.list.meet_type_name = value.name;
  }
  state.showPicker = false;
};

//-picker-选择项字段--end

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      // if (
      //   (form.list.sign_trilogy.includes(1) &&
      //     form.list.measure_house_attach.length == 0) ||
      //   (form.list.sign_trilogy.includes(2) &&
      //     form.list.design_plan_attach.length == 0) ||
      //   (form.list.sign_trilogy.includes(3) &&
      //     form.list.design_rehearsal_attach.length == 0)
      // ) {
      //   Toast('请上传已选的三部曲图片');
      //   return;
      // }
      let needShare = false;
      // if (form.list.sign_trilogy && form.list.sign_trilogy.length) {
      //   state.sign_trilogy_list.forEach((i) => {
      //     if (i.is_first == 1 && form.list.sign_trilogy.includes(i.id)) {
      //       needShare = true;
      //     }
      //   });
      // }
      if (needShare) {
        let str = '是否确认？请将此跟进结果分享到客户群?';

        Dialog.confirm({
          message: str,
        }).then(() => {
          goConfirm(true);
        });
      } else {
        // let str = '是否将本次跟进记录分享至客户群?';
        // Dialog.confirm({
        //   message: str,
        // })
        //   .then(() => {
        //     goConfirm(true);
        //   })
        //   .catch(() => {
        goConfirm();
        // });
      }
    })
    .catch(() => {
      //验证失败
      Toast('请完善信息');
    });
};
const goConfirm = (needShare) => {
  state.loadingConfirm = true;
  $http
    .customerFollowOrder({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            if (needShare) {
              // shareRecordList(data.record_id, data.record_time);
            }

            if (state.schedule == 1) {
              window.close();
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//保存草稿
const btnSumbitDraft = () => {
  state.loadingConfirm = true;
  $http
    .dispatch_saveDraft({
      ...form.list,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            if (state.schedule == 1) {
              window.close();
            } else {
              router.back();
            }
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//-@ 返回
const onBack = () => {
  let str = '是否将填写内容保存为草稿，否则会被清空';
  Dialog.confirm({
    message: str,
  })
    .then(() => {
      //保存草稿
      btnSumbitDraft();
    })
    .catch(() => {
      router.back();
    });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/comonFormGrey.less';
.page-wrap {
  height: calc(100vh - 46px);
  background-color: #f5f5f5;
  .formBase {
    padding-bottom: 72px;
  }
}
.list_field {
  :deep(.van-radio__label) {
    width: 32px;
    text-align: left;
  }
}
.btnSumbit {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 74px;
  background: #fff;
  box-shadow: 0px -1px 4vw rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  .btn {
    width: 48%;
    position: relative;
  }
}
</style>
