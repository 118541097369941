<!--
 * @Author: gest
 * @Date:2024-01-24 11:33:29
 * @LastEditTime: 2024-05-31 15:30:24
 * @Description: V1.5.4 目标数据-简单交付数据
-->
<template>
  <!-- 线索数 -->
  <div class="main-data" @click.stop="redictFun">
    <div class="warpper_simple">
      <Skeleton
        title
        avatar
        :row="5"
        :loading="loading || props.loading"
        style="padding: 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_s_3.png')"
                alt="icon"
              />
            </div>

            <div class="font_size_17 font_bold">交付数据</div>
          </div>
          <!-- 规则入口 -->
          <div class="icon_info_box" @click.stop="createDanger">
            <img
              class="icon_img"
              :src="require('@/assets/images/target-data/icon_info.png')"
              alt="info"
            />
          </div>
        </div>

        <div class="pb_15 flex_evenly align_center font_size_13 grey_color">
          <div class="flex_column align_center simple_item">
            <div>交付(万)</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.performance?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.performance.today }}
                </span>
                <!-- <div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.performance?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.performance.today }}
                </span>
                <!-- <div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>

              <div class="icon_box ml_2" v-if="detail.performance?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.performance?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.performance.month }}
                </span>
                <!-- <div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div> -->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.performance?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.performance.month }}
                </span>
                <!-- <div class="icon_box ml_2">
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>

              <div class="icon_box ml_2" v-if="detail.performance?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>

            <div class="font_size_20 black_color font_bold">
              {{ detail.performance?.all }}
            </div>
            <div class="mt_10">
              阶：<span class="black_color font_bold">{{
                detail.performance?.stage
              }}</span
              >万
            </div>
            <div class="mt_2">
              交：<span class="black_color font_bold">{{
                detail.performance?.deliver
              }}</span
              >万
            </div>
          </div>
          <div class="line"></div>
          <div class="flex_column align_center simple_item">
            <div>交付数</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.construction?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.construction.today }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.construction?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.construction.today }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div> -->
              </div>

              <div class="icon_box ml_2" v-if="detail.construction?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.construction?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.construction.month }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.construction?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.construction.month }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>

              <div class="icon_box ml_2" v-if="detail.construction?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>

            <div class="font_size_20 black_color font_bold">
              {{ detail.construction?.num }}
            </div>
            <div class="mt_10">履约率</div>
            <div class="black_color font_bold font_size_15 line_h20">
              {{ detail.construction?.rate }}%
            </div>
          </div>
          <div class="line"></div>
          <div class="flex_column align_center simple_item">
            <div>按期完工率</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.normal_finish_rate?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.normal_finish_rate.today + '%' }}
                </span>
                <!-- <div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.normal_finish_rate?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.normal_finish_rate.today + '%' }}
                </span>
                <!--<div class="icon_box ml_2">
                   <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div> -->
              </div>

              <div
                class="icon_box ml_2"
                v-if="detail.normal_finish_rate?.today == 0"
              >
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.normal_finish_rate?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.normal_finish_rate.month + '%' }}
                </span>
                <!-- <div class="icon_box ml_2">
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.normal_finish_rate?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.normal_finish_rate.month + '%' }}
                </span>
                <!--  <div class="icon_box ml_2">
                 <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  /> 
                </div>-->
              </div>

              <div
                class="icon_box ml_2"
                v-if="detail.normal_finish_rate?.month == 0"
              >
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>

            <div class="font_size_20 black_color font_bold">
              {{ detail.normal_finish_rate?.num + '%' }}
            </div>
            <div class="mt_10">锦旗率</div>
            <div class="black_color font_bold font_size_15 line_h20">
              {{ detail.pennant?.rate }}%
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  watch,
  defineProps,
  defineEmits,
  defineExpose,
  reactive,
} from 'vue';
import { Notify, Skeleton } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
const emit = defineEmits(['createDanger']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载
const onLoad = () => {
  loading.value = true;
  $http
    .targetStatistic_simpleConstruction({ year: props.data.yearId })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        detail.value = data;
      } else {
        loading.value = false;
        detail.value = [];
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 3, pos: 1 },
  });
};

//-规则说明--begin
const _rule = reactive({
  htmlTitle: '交付数据_规则',
  htmlContent: '',
});

const createDanger = () => {
  let yearInfo = props.data.yearId + '年';
  let isNowYearInfo =
    props.data.yearId === props.data.now_year
      ? '当月底'
      : props.data.yearId + '-12-31';
  _rule.htmlContent = `<h5> 数据定义：</h5>
  <p>· 阶：3.0客户${yearInfo}交付业绩；</p>
  <p>· 交：2.0客户${yearInfo}交付业绩；</p>
  <p>· 履约率：${yearInfo}实际履约工地数/${yearInfo}应履约工地基数；</p>
  <p class="pl_10">（1）实际履约工地数：</p>
  <p class="pl_20">a. ${yearInfo}完工未延期工地数；</p>
  <p class="pl_10">（2）应履约工地基数：a + b + c</p>
  <p class="pl_20">a. 合同完工日期<${isNowYearInfo}的未开工工地数；</p>
  <p class="pl_20">b. 必须完工日期<${isNowYearInfo}的在建工地数；</p>
  <p class="pl_20">c. 必须完工日期<${isNowYearInfo}且在${yearInfo}完工的工地数；</p>
  <p>· 按期完工率：${yearInfo}未延期完工工地数/${yearInfo}完工工地数；</p>
  <p>· 锦旗率：${yearInfo}获得锦旗完工工地数/${yearInfo}完工工地数；</p>`;
  emit('createDanger', 'siteRef');
};
defineExpose({ _rule });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
