<!--
 * @Author: Jessica
 * @Date: 2023-09-19 16:19:49
 * @LastEditTime: 2024-05-18 17:50:25
 * @Description: 财务大屏
-->
<template>
  <div class="change_box">
    <div></div>
    <Button
      type="primary"
      icon="arrow-down"
      icon-position="right"
      native-type="submit"
      color="#000000"
      plain
      size="small"
      class="year_btn"
      @click="_DATA.showPicker_year = true"
    >
      {{ _DATA.yearName }}
    </Button>
  </div>
  <div class="page-box">
    <!-- 净利润 -->
    <profitTemp :data="detail" :loading="loading" />
    <!-- 毛利润 -->
    <grossProfitTemp :data="detail" :loading="loading" />
    <!-- 收入 -->
    <incomeTemp :data="detail" :loading="loading" />
    <!-- 成本 -->
    <costTemp :data="detail" :loading="loading" />
    <!-- 费用 -->
    <feeTemp :data="detail" :loading="loading" />
  </div>
  <!-- 年份 -->
  <Popup v-model:show="_DATA.showPicker_year" position="bottom">
    <Picker
      :columns="_DATA.yearList"
      :columns-field-names="customFieldName"
      @confirm="(v) => onConfirm(v, 'year')"
      @cancel="_DATA.showPicker_year = false"
      :default-index="
        _DATA.yearList.findIndex((v) => {
          return v.id == _DATA.yearId;
        })
      "
    />
  </Popup>
  <!-- end -->
</template>

<script setup>
import { reactive, ref, onMounted, inject } from 'vue';

import { Notify, Picker, Button, Popup } from 'vant';
import profitTemp from '@/components/financeData/profitTemp'; // 净利润
import grossProfitTemp from '@/components/financeData/grossProfitTemp'; // 毛利润
import incomeTemp from '@/components/financeData/incomeTemp'; // 收入
import costTemp from '@/components/financeData/costTemp'; // 成本
import feeTemp from '@/components/financeData/feeTemp'; // 费用

const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');

const customFieldName = {
  text: 'short_name',
  value: 'id',
};
const _DATA = reactive({
  showPicker_year: false,
  yearId: null,
  yearName: null,
  yearList: [],
});
onMounted(() => {
  getParams();
});
//-@ 加载
const getParams = () => {
  loading.value = true;
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      let years = data.years;
      let yearList = [];
      years.forEach((v) => {
        yearList.push({ id: v, short_name: v });
      });

      _DATA.yearList = yearList;
      [_DATA.yearId] = years.slice(-1);
      [_DATA.yearName] = years.slice(-1);
      onLoad();
    } else {
      loading.value = false;
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
const onLoad = () => {
  $http.targetStatistic_financeData({ year: _DATA.yearId }).then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      detail.value = data;
    } else {
      detail.value = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
const onConfirm = (value, type) => {
  if (type == 'year') {
    //切换部门
    if (value.id == _DATA.yearId) {
      _DATA.showPicker_year = false;
      return;
    }
    _DATA.yearId = value.id;
    _DATA.yearName = value.short_name;
    _DATA.showPicker_year = false;
    loading.value = true;
    onLoad();
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/financeData/targetIndex.less';
.change_box {
  padding: 10px 15px;
  background: #f5f5f5;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .year_btn {
    border: none;
    border-radius: 2px;
    font-size: 13px;
    padding: 0 10px;
    margin-left: 10px;
    height: 30px;
  }
}
.page-box {
  padding: 0 15px 80px 15px;
  background: #f5f5f5;
  height: calc(100% - 48px);
  overflow: auto;
}
</style>
