/*
 * @Author: Jessica
 * @Date: 2022-04-15 11:50:12
 * @LastEditTime: 2023-11-04 15:00:52
 * @Description:
 */
import Service from '@/utils/request';

export default {
  /**
   * 附件上传
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getUploadFile(params) {
    return Service.post(`/rs/public/uploadBigFile`, params);
  },
  //上传进度
  checkSpeed(params) {
    return Service.post(`/rs/public/checkSpeed`, params);
  },
  //文件分片合并
  getUploadChunkMerge(params) {
    return Service.post(`/rs/public/chunkMerge`, params);
  },
  /**
   * 获取部门接口
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getMemberList(params) {
    return Service.post(`/rs/public/userListParameter`, params);
  },
  /**
   * 合同公共参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getContractPublicParameter(params) {
    return Service.post(`/rs/public/contractPublicParameter`, params);
  },
  /**
   * 合同客户参数
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getContractCustomerParameter(params) {
    return Service.post(`/rs/public/contractCustomerParameter`, params);
  },
  /**
   * 新建尚层装饰室内/配饰设计协议合同
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSignDecorateContract(params) {
    return Service.post(`/rs/contractManagement/signDecorateContract`, params);
  },
  /**
   * 新建家居委托代购协议合同
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSignEntrustBuyContract(params) {
    return Service.post(
      `/rs/contractManagement/signEntrustBuyContract`,
      params
    );
  },
  /**
   * 新建家庭居室装饰装修工程施工合同
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSignRoomDecorationContract(params) {
    return Service.post(
      `/rs/contractManagement/signRoomDecorationContract`,
      params
    );
  },
  /**
   * 新建整装服务销售合同
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSignServiceSellContract(params) {
    return Service.post(
      `/rs/contractManagement/signServiceSellContract`,
      params
    );
  },
  /**
   * 新建整装工程施工合同
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSignAssemblyWorks(params) {
    return Service.post(`/rs/contractManagement/signAssemblyWorks`, params);
  },
  /**
   * 新建装饰合同工程配套材料补充协议
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getSignDecorationMatching(params) {
    return Service.post(
      `/rs/contractManagement/signDecorationMatching`,
      params
    );
  },
  /**
   * 增减项（变更单）添加
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getContractAddPlusMinusItem(params) {
    return Service.post(`/rs/contractManagement/addPlusMinusItem`, params);
  },
  /**
   * 增减项（变更单）详情
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getContractItemInfo(params) {
    return Service.post(`/rs/contractManagement/itemInfo`, params);
  },
  /**
   * 客户签合同签名信息编辑
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getCustomerChangeSign(params) {
    return Service.post(`/rs/customerManagement/customerChangeSign`, params);
  },
  /**
   * 客户信息楼盘是否有未找到
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getCustomerFloorStatus(params) {
    return Service.post(`/rs/public/customerFloorStatus`, params);
  },
  /**
   * 根据面积获取工期
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getContractScheduleBySize(params) {
    return Service.post(`/rs/public/getScheduleBySize`, params);
  },
  /**
   * 根据客户获取选报/必报
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getQuoteOrderForContract(params) {
    return Service.post(`/rs/public/getQuoteOrder`, params);
  },
  /**
   * 【尚层装饰室内设计协议】【尚层装饰配饰设计协议】根据开始/结束实际获取工期
   *
   * @param {*} params 参数
   * @returns Promise
   */
  getContractScheduleByTime(params) {
    return Service.post(`/rs/public/getScheduleByTime`, params);
  },
  //占款记录
  customerPaymentDetailList(params) {
    return Service.post(`rs/productProposal/customerPaymentDetailList`, params);
  },
  //意向单列表
  contractManagement_intendList(params) {
    return Service.post(`/rs/contractManagement/intendList`, params);
  },
  //意向单详情
  contractManagement_intendInfo(params) {
    return Service.post(`/rs/contractManagement/intendInfo`, params);
  },
  //作废意向单
  contractManagement_intendCancel(params) {
    return Service.post(`/rs/contractManagement/intendCancel`, params);
  },
  //获取供应商的分类和品牌
  getCategoryInfo(params) {
    return Service.post(`/rs/supplier/getCategoryInfo`, params);
  },
};
