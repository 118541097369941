/*
 * @Author: Jessica
 * @Date: 2022-08-04 09:43:49
 * @LastEditTime: 2023-10-17 10:50:45
 * @Description:
 */
import Service from '@/utils/request';

export default {
  /**
   * 合同退单参数
   */
  getContractInvalidParam(params) {
    return Service.post(`/rs/public/contractInvalidParam`, params);
  },
  /**
   * 合同退单申请
   */
  getAddContractInvalid(params) {
    return Service.post(`/rs/contractManagement/addContractInvalid`, params);
  },
  /**
   * 合同作废详情
   */
  getContractInvalidInfo(params) {
    return Service.post(`/rs/contractManagement/contractInvalidInfo`, params);
  },
  /**
   * 合同变更参数
   */
  getContractChangeParam(params) {
    return Service.post(`/rs/public/contractChangeParam`, params);
  },
  //获取意向单简单列表
  contract_getIntensions(params) {
    return Service.post(`/rs/contractManagement/getIntensions`, params);
  },
  //创建工程/产品意向单
  contract_addIntendItem(params) {
    return Service.post(`/rs/contractManagement/addIntendItem`, params);
  },
  //意向单详情
  contract_intendInfo(params) {
    return Service.post(`/rs/contractManagement/intendInfo`, params);
  },
};
