<!--
 * @Author: NanNan
 * @Date: 2024-05-23 10:35:12
 * @LastEditTime: 2024-06-01 11:11:53
 * @Description: v1.6.1 smart 客资表全新改版
-->
<template>
  <!-- 加载状态 -->
  <Overlay :show="loading" class="overlay-fff">
    <Loading color="#1989fa" type="spinner"></Loading>
  </Overlay>

  <div class="page-box" v-if="_DATA.authList?.length > 0">
    <!-- 头部搜索: 有kw -->
    <Sticky offset-top="12.3vw" v-if="kw_default">
      <div class="header-box">
        <div class="left">
          <form action="/">
            <Search
              placeholder="姓名/楼盘/手机号/编号"
              show-action
              maxlength="20"
              v-model="kw"
              :clearable="false"
              :formatter="formatterSearch"
              format-trigger="onChange"
              @search="() => resetSearchCommon('search')"
              @cancel="onCancel"
              @clear="resetSearchCommon('clear')"
            >
              <template #action>
                <div
                  @click.stop="resetSearchCommon('search')"
                  style="color: #0256ff; font-size: 15px"
                >
                  搜索
                </div>
              </template>
              <template #right-icon>
                <div @click="resetSearchCommon('clear')">
                  <Icon name="clear" color="#c8c9cc" />
                </div>
              </template>
            </Search>
          </form>
        </div>
      </div>
      <div class="total-box">
        <p class="result">
          <span>{{ _DATA.clue_type == 2 ? '企微线索' : '全部线索' }}(结果</span>
          <em>{{ _DATA.page.total }}</em>
          <span>条)</span>
        </p>
      </div>
    </Sticky>
    <!-- 头部搜索: 无kw -->
    <Sticky offset-top="12.3vw" v-else>
      <!-- 头部-tab切换卡片 -->
      <div class="tab_top_box">
        <Tabs
          v-model:active="_DATA.clue_type"
          title-active-color="#1A1A1A"
          title-inactive-color="#8D9091"
          shrink
          :before-change="beforeChangeTabTop"
          class="tabtopclass"
          :class="{ lose: !_DATA.clue_type }"
        >
          <Tab
            v-for="item in topTabList"
            :key="item.id"
            :name="item.id"
            :title="item.name"
          ></Tab>
        </Tabs>
      </div>
      <div class="header-box">
        <div class="left">
          <form action="/">
            <Search
              placeholder="姓名/楼盘/手机号/编号"
              show-action
              maxlength="20"
              v-model="kw"
              :clearable="false"
              :formatter="formatterSearch"
              format-trigger="onChange"
              @search="() => resetSearchCommon('search')"
              @cancel="onCancel"
            >
              <template #action>
                <div
                  @click="() => resetSearchCommon('search')"
                  style="color: #0256ff; font-size: 15px"
                >
                  搜索
                </div>
              </template>
              <template #right-icon>
                <div @click="resetSearchCommon('clear')" v-if="kw != ''">
                  <Icon name="clear" color="#c8c9cc" />
                </div>
              </template>
            </Search>
          </form>
        </div>
      </div>
      <!-- tab切换 -->
      <div class="tab_box">
        <Tabs
          v-model:active="tab.tabIndex"
          title-active-color="#0256FF"
          shrink
          :before-change="beforeChangeTab"
          class="tabclass"
          :class="{ lose: !tab.tabActive }"
        >
          <Tab
            v-for="item in tab.list"
            :key="item.id"
            :name="item.id"
            :title="item.name"
          ></Tab>
        </Tabs>
      </div>
      <!-- end -->
      <!-- 总计、逾期未跟进 -->
      <div class="total-box">
        <template v-for="(item, index) in sub.list" :key="item.name">
          <div
            class="same"
            @click.stop="changeSub(index, item, sub.list)"
            :class="{ active: item.active }"
          >
            <!-- 总计栏目 -->
            <div v-if="index == 0">
              <p>
                <span v-if="sub.chose_items?.length === 0">
                  {{ item.name }}
                </span>
                <span v-if="sub.chose_items?.length === 1">
                  {{ sub.chose_items[0].name }}
                </span>
                <span v-if="sub.chose_items?.length > 1">
                  {{ sub.chose_items.length + '人' }}
                </span>
                <em>
                  {{ _DATA.nums[item.type] }}
                </em>
              </p>
              <Icon
                name="play"
                style="transform: rotate(0deg)"
                v-if="!personnel.show"
                class="icon-play"
                size="10"
              />
              <Icon
                name="play"
                style="transform: rotate(90deg)"
                v-if="personnel.show"
                class="icon-play"
                size="10"
              />
            </div>
            <!-- 其余 -->
            <div v-else>
              <p>
                <span>{{ item.name }}</span>
                <em>{{ _DATA.nums[item.type] }}</em>
              </p>
            </div>
          </div>
        </template>
        <div
          class="same-right"
          @click.stop="openAction()"
          :class="{ active: actionHasValue, hasline: _DATA.clue_step }"
        >
          <div class="shaixuan">
            筛选
            <Icon
              name="play"
              style="transform: rotate(0deg)"
              class="icon-play"
              size="10"
            />
          </div>
        </div>
      </div>
    </Sticky>
    <!-- end -->

    <!-- 内容开始 -->
    <div v-if="_DATA.clue_type">
      <List
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadList"
        class="list-box"
      >
        <!-- v1.4.7plus 客资表样式 -->
        <div v-for="(item, index) in list" :key="index" class="cell-same-new">
          <div class="cell-top" @click.stop="handleArrow(item.id)">
            <div class="left">
              <div class="child">
                <Image
                  v-if="item.face_image"
                  class="img_head"
                  :src="item.face_image"
                ></Image>
                <Image
                  v-else
                  class="img_head"
                  :src="
                    item.gender
                      ? _DATA.imageList[item.gender]
                      : _DATA.imageList[0]
                  "
                ></Image>
              </div>
            </div>
            <div class="center">
              <div class="title">
                <span class="name">{{ item.name || '姓名未知' }}</span>
                <span class="split">-</span>
                <span class="floor">{{ item.floor_name || '楼盘未知' }}</span>
                <span class="split">-</span>
                <span class="size">
                  {{ item.size === '' ? '面积未知' : item.size + '㎡' }}
                </span>
              </div>
              <div
                class="person-div"
                v-if="item.channel_source_user_name || item.invite_user_name"
              >
                <div class="desc">
                  <span class="person" v-if="item.channel_source_user_name">
                    {{ item.channel_source_user_name }}
                  </span>
                  <em class="ms" v-if="item.channel_source_user_name">
                    来源人
                  </em>
                  <span class="person" v-if="item.invite_user_name">
                    {{ item.invite_user_name }}
                  </span>
                  <em class="ms" v-if="item.invite_user_name">邀约人</em>
                </div>
                <Icon
                  name="arrow"
                  size="10"
                  color="#8D9091"
                  v-if="arrowActive != item.id"
                />
                <Icon name="arrow-down" size="10" color="#0256FF" v-else />
              </div>
              <!-- 标签区域 -->
              <div class="card-box">
                <!-- 阶段 -->
                <span
                  class="blue"
                  v-if="item.label?.clue_step?.label_name && !_DATA.clue_step"
                >
                  {{ item.label?.clue_step?.label_name }}
                </span>
                <!-- 死单 -->
                <span class="gray" v-if="item?.label?.is_clue_dead">
                  {{ item?.label?.is_clue_dead }}
                </span>
                <!-- ABCD类 -->
                <span
                  v-if="item?.label?.clue_level?.label_name"
                  :class="{
                    green: item?.label?.clue_level?.id == 1,
                    gray: item?.label?.clue_level?.id == 2,
                    orange: item?.label?.clue_level?.id == 3,
                  }"
                >
                  {{ item?.label?.clue_level?.label_name }}
                </span>
                <!-- 撞单 -->
                <span class="gray" v-if="item?.label?.clue_dash">
                  {{ item?.label?.clue_dash }}
                </span>
                <!-- 跟进的牌子 -->
                <span
                  :class="{
                    red: item.label?.clue_overtime?.id == 1,
                    blue:
                      item.label?.clue_overtime?.id == 2 ||
                      item.label?.clue_overtime?.id == 3,
                    gray: item.label?.clue_overtime?.id > 3,
                  }"
                >
                  {{ item.label?.clue_overtime?.label_name }}
                </span>
              </div>
            </div>
          </div>
          <!-- 展开收起 内容区域 -->
          <div
            class="arrow-inner-box"
            v-if="arrowActive == item.id"
            @click.stop="() => javascript"
          >
            <TempFollowList
              :id="arrowActive"
              :finished="true"
              :clueType="params.clue_type"
              :type="1"
              :pages="2"
              :data="item"
              :key="recordInfo.key"
              @getRecordTotal="getRecordTotal"
            />
            <!-- 全部跟进记录 -->
            <div
              class="record-all"
              @click.stop="recordPop(item)"
              v-if="recordInfo.total > 0"
            >
              <span>全部跟进记录</span>
              <Icon name="arrow" color="#0256FF" size="12" />
            </div>
          </div>
          <!-- end -->
        </div>
        <!-- end -->
      </List>
    </div>
    <!-- list -->

    <!--V1.4.0 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->

    <!-- Popup 全部跟进记录 -->
    <Popup
      round
      :close-on-popstate="true"
      position="bottom"
      v-model:show="recordInfo.show"
      :style="{ maxHeight: '70%' }"
      @closed="reviewOk"
    >
      <TempFollowList
        :id="arrowActive"
        :finished="false"
        :pages="10"
        :data="recordInfo.data"
        v-if="recordInfo.show"
        @reviewOk="reviewOk"
      />
    </Popup>

    <!-- Popup 选择服务人员-->
    <Popup
      round
      :close-on-popstate="true"
      position="bottom"
      v-model:show="personnel.show"
      :style="{ maxHeight: '70%' }"
    >
      <TempSelector
        v-if="personnel.show"
        :data="personnel"
        :fromPage="'clueList'"
        @reviewTempSelect="reviewTempSelect"
      />
    </Popup>

    <!-- 筛选条件弹窗 -->
    <ActionSheet
      v-model:show="actionShow"
      close-on-click-action
      :title="params.clue_type == 1 ? '全部线索-筛选' : '企微线索-筛选'"
      class="action_box"
      @cancel="() => resetSearchCommon('action_submit')"
      @click-overlay="() => resetSearchCommon('action_submit')"
    >
      <div class="action_content">
        <div class="select_box">
          <!-- 线索等级 -->
          <div class="select_item">
            <h1>线索等级</h1>
            <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
              <GridItem
                v-for="item in options.clue_list_level"
                :key="item.id"
                @click="changeSelect('clue_list_level', 'array', item.id)"
              >
                <Button
                  :class="
                    actionParmars.clue_list_level.some((v) => v == item.id)
                      ? 'selected'
                      : ''
                  "
                >
                  {{ item.name }}
                </Button>
              </GridItem>
            </Grid>
          </div>
          <!-- 最近跟进时间 -->
          <div class="select_item">
            <h1>最近跟进时间</h1>
            <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
              <GridItem
                v-for="item in options.clue_lasttime_follow"
                :key="item.id"
                @click="changeSelect('clue_lasttime_follow', 'number', item.id)"
              >
                <Button
                  :class="
                    actionParmars.clue_lasttime_follow == item.id
                      ? 'selected'
                      : ''
                  "
                >
                  {{ item.name }}
                </Button>
              </GridItem>
            </Grid>
          </div>
          <!-- 是否待改派 -->
          <div class="select_item">
            <h1>是否待改派</h1>
            <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
              <GridItem
                v-for="item in options.able_to_reassignment"
                :key="item.id"
                @click="changeSelect('able_to_reassignment', 'number', item.id)"
              >
                <Button
                  :class="
                    actionParmars.able_to_reassignment == item.id
                      ? 'selected'
                      : ''
                  "
                >
                  {{ item.name }}
                </Button>
              </GridItem>
            </Grid>
          </div>
          <!-- 小程序客户意向度 -->
          <div class="select_item">
            <h1>小程序客户意向度</h1>
            <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
              <GridItem
                v-for="item in options.applet_intention"
                :key="item.id"
                @click="changeSelect('applet_intention', 'number', item.id)"
              >
                <Button
                  :class="
                    actionParmars.applet_intention == item.id ? 'selected' : ''
                  "
                >
                  {{ item.name }}
                </Button>
              </GridItem>
            </Grid>
          </div>
          <!-- 是否已轮派 -->
          <div class="select_item">
            <h1>是否已轮派</h1>
            <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
              <GridItem
                v-for="item in options.clue_is_rotation"
                :key="item.id"
                @click="changeSelect('clue_is_rotation', 'number', item.id)"
              >
                <Button
                  :class="
                    actionParmars.clue_is_rotation == item.id ? 'selected' : ''
                  "
                >
                  {{ item.name }}
                </Button>
              </GridItem>
            </Grid>
          </div>
          <!-- 预计下次跟进时间 -->
          <div class="select_item">
            <h1>预计下次跟进时间</h1>
            <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
              <GridItem
                v-for="item in options.clue_plan_next_follow_time"
                :key="item.id"
                @click="
                  changeSelect('clue_plan_next_follow_time', 'number', item.id)
                "
              >
                <Button
                  :class="
                    actionParmars.clue_plan_next_follow_time == item.id
                      ? 'selected'
                      : ''
                  "
                >
                  {{ item.name }}
                </Button>
              </GridItem>
            </Grid>
          </div>
        </div>
        <div class="line"></div>
        <Row class="bottom_btn" gutter="12">
          <Col span="12">
            <Button
              plain
              hairline
              type="primary"
              class="reset"
              @click.stop="() => resetSearchCommon('action_reset')"
            >
              重置
            </Button>
          </Col>
          <Col span="12" @click.stop="() => resetSearchCommon('action_submit')">
            <Button>确定</Button>
          </Col>
        </Row>
      </div>
    </ActionSheet>
  </div>
</template>
<script setup>
import { ref, onMounted, inject, reactive, nextTick, computed } from 'vue';

import {
  Search,
  List,
  Notify,
  Sticky,
  Overlay,
  Loading,
  Tabs,
  Tab,
  Image,
  Icon,
  Popup,
  ActionSheet,
  Row,
  Col,
  Grid,
  GridItem,
  Button,
} from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import sideMenu from '@/components/sideMenu';
import TempFollowList from '@/components/clue/TempFollowList.vue';
import TempSelector from '@/components/common/TempSelectorMultiple.vue';

const $http = inject('$http');
const store = useStore();
const router = useRouter();

const _DATA = reactive({
  customer_id: router.currentRoute.value?.query?.id,
  //11 待分配 12 邀约中 13 已约未见 14已见未签 15 已签约-保护器 16 已签约-已过保
  clue_step: router.currentRoute.value?.query?.step
    ? Number(router.currentRoute.value?.query?.step)
    : null,
  //1全部线索 2企微线索
  clue_type: router.currentRoute.value?.query?.type
    ? Number(router.currentRoute.value?.query?.type)
    : null,
  params: {
    is_clue_over_time_no_follow: '', //逾期未跟进 1是
    clue_service_user_ids: [], //服务人员
  },
  nums: {
    all_clue_count: null,
    over_time_follow_clue_count: null,
  },
  page: {
    page: 1,
    pages: 30,
    count_page: 1,
    total: 0,
  },
  //1默认tabar 2模糊搜索 3筛选项
  searchType: router.currentRoute.value?.query?.type ? 1 : 0,
  //权限list
  authList: [],
  //头像list
  imageList: [
    require('@/assets/images/icon-gender0.png'),
    require('@/assets/images/icon-gender1.png'),
    require('@/assets/images/icon-gender2.png'),
  ],
});

const params = reactive({
  is_clue_over_time_no_follow: '', //逾期未跟进 1是
  clue_service_user_ids: [], //服务人员
});
const paramsEmpty = reactive({});

onMounted(() => {
  store.dispatch('account/getOperationAuthFunc').then((res) => {
    if (res) {
      _DATA.authList = res;
      // 权限判断-全部线索、企微线索
      judgePermissionsFunc(res);
      loadParmars();
      nextTick(() => {
        const paramsCopy = JSON.parse(JSON.stringify(params));
        Object.assign(paramsEmpty, paramsCopy);
        const actionParmarsCopy = JSON.parse(JSON.stringify(actionParmars));
        Object.assign(actionParmarsEmpty, actionParmarsCopy);
        const tabCopy = JSON.parse(JSON.stringify(tab));
        Object.assign(tabEmpty, tabCopy);
        const subCopy = JSON.parse(JSON.stringify(sub));
        Object.assign(subEmpty, subCopy);
        if (router.currentRoute?.value?.query?.step)
          getSubList(router.currentRoute?.value?.query?.step);
      });
    }
  });
});

/**
 * 接口
 */
// -- api:参数
const options = ref({});
const loadParmars = () => {
  $http.getSaleOptionsApi().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      options.value = data.customer;
    }
  });
};
// -- api:列表
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const loadList = (obj) => {
  loading.value = true;
  _DATA.authList &&
    $http
      .getClueList({
        ...params,
        ...actionParmars,
        kw: kw_default.value,
        clue_step: _DATA.clue_step,
        clue_type: _DATA.clue_type,
        page: _DATA.page.page,
        pages: _DATA.page.pages,
        ...obj,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          _DATA.page = data.page;
          _DATA.nums = data.nums;
          list.value = list.value.concat(data.list);
          if (data.page.page < data.page.count_page) {
            _DATA.page.page++;
          } else {
            finished.value = true;
          }
          loading.value = false;
        } else {
          finished.value = true;
          Notify({
            type: 'warning',
            message: data,
          });
          loading.value = false;
        }
      });
};

/*
 * 操作
 */
// -------- 以下是全部线索、企微线索切换 -------- //
//@数据
const topTabList = reactive([
  {
    id: 1,
    name: '全部线索',
    auth: '238',
    disabled: false,
  },
  {
    id: 2,
    name: '企微线索',
    auth: '246',
    disabled: false,
  },
]);

//@判断权限
const judgePermissionsFunc = (authArray) => {
  topTabList.forEach((element) => {
    element.disabled = authArray.indexOf(element.auth) == -1;
  });
  const firstDisabledTab = topTabList.find((tab) => !tab.disabled);
  const type = router.currentRoute.value?.query?.type;

  if (!type && firstDisabledTab) {
    _DATA.clue_type = firstDisabledTab.id;
  }
};

//@切换标签前的回调函数
const beforeChangeTabTop = (val) => {
  console.log('beforeChangeTabTop');
  if (loading.value) return;
  const foundItem = topTabList.find((item) => item.id === val);
  if (foundItem.disabled) return;
  if (foundItem.id == _DATA.clue_type) return;
  _DATA.clue_type = val;
  _DATA.clue_step = null;
  router.replace({ query: { type: val } });
  resetSearchCommon('tab_all_wechat');
};

// -------- 以下是筛选窗口 -------- //
const actionShow = ref(false);
//@打开筛选
const openAction = () => {
  if (loading.value) return;
  actionShow.value = true;
};
//@判断是否有属性有值的方法
const actionHasValue = computed(() => {
  return Object.values(actionParmars).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== '';
  });
});
//@关闭筛选面板
const actionParmars = reactive({
  clue_list_level: [], //线索等级
  clue_lasttime_follow: '', //最近跟进时间
  able_to_reassignment: '', //是否待改派
  clue_is_rotation: '', //是否已轮派
  applet_intention: '', //小程序客户意向度
  clue_plan_next_follow_time: '', //预计下次跟进时间
});
const actionParmarsEmpty = reactive({});

//@筛选选择器
const changeSelect = (name, type, id) => {
  if (type === 'array') {
    if (actionParmars[name].some((v) => v == id)) {
      actionParmars[name] = actionParmars[name].filter((v) => v !== id);
    } else {
      actionParmars[name].push(id);
    }
  } else if (type === 'number') {
    actionParmars[name] = actionParmars[name] == id ? '' : id;
  }
};

// -- tab：参数 1 待分配 2 邀约中 3 已约未见 4 已见未签 5 已签约-保护器 6 已签约-已过保
const tab = reactive({
  tabIndex: router.currentRoute.value?.query?.step
    ? Number(router.currentRoute.value?.query?.step)
    : null,
  tabActive: router.currentRoute.value?.query?.step
    ? Number(router.currentRoute.value?.query?.step)
    : null,
  list: [
    { id: 1, name: '待分配' },
    { id: 2, name: '邀约中' },
    { id: 3, name: '已约未见' },
    { id: 4, name: '已见未签' },
    { id: 5, name: '已签约-保护期' },
    { id: 6, name: '已签约-已过保' },
  ],
});
const tabEmpty = reactive({});

// -- tab：切换标签前的回调函数
const beforeChangeTab = (val) => {
  if (loading.value) return;
  arrowActive.value = '';
  if (tab.tabIndex == val && tab.tabActive) {
    console.log('相等，置null');
    tab.tabIndex = null;
    tab.tabActive = null;
    _DATA.searchType = 0;
  } else {
    console.log('不等，设值');
    tab.tabIndex = _.cloneDeep(val);
    tab.tabActive = _.cloneDeep(val);
    _DATA.searchType = 1;
  }
  tabReset(tab.tabActive);
};

// -- tab：切换数据处理
const tabReset = (newVal) => {
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.step = newVal;
  router.replace({ query });
  getSubList().then((sub_list) => {
    //搜索参数变更
    _DATA.clue_step = _.cloneDeep(tab.tabActive);
    if (newVal) {
      params.is_clue_over_time_no_follow = sub_list[1].active ? 1 : '';
      params.clue_service_user_ids = sub.chose_ids;
    } else {
      params.is_clue_over_time_no_follow = '';
      params.clue_service_user_ids = sub.chose_ids;
    }
    //相关数据处理
    list.value = [];
    kw_default.value = '';
    _DATA.kw = '';
    _DATA.page.page = 1;
    refreshing.value = false;
    finished.value = false;
    loadList();
  });
};

// -- sub：参数
const sub = reactive({
  active: '',
  chose_items: [],
  chose_ids: [],
  list: [
    {
      name: '总计',
      type: 'all_clue_count',
      active: true,
      search: 'clue_service_user_ids',
      total: 0,
    },
  ],
  list_all: [
    {
      name: '总计',
      type: 'all_clue_count',
      active: true,
      search: 'clue_service_user_ids',
      total: 0,
    },
  ],
  data: {
    1: {
      list: [
        {
          name: '总计',
          type: 'all_clue_count',
          active: true,
          search: 'clue_service_user_ids',
          total: 0,
        },
        {
          name: '逾期未跟进',
          type: 'over_time_follow_clue_count',
          active: false,
          search: 'is_clue_over_time_no_follow',
        },
      ],
    },
    2: {
      list: [
        {
          name: '总计',
          type: 'all_clue_count',
          active: true,
          search: 'clue_service_user_ids',
          total: 0,
        },
        {
          name: '逾期未跟进',
          type: 'over_time_follow_clue_count',
          active: false,
          search: 'is_clue_over_time_no_follow',
        },
      ],
    },
    3: {
      list: [
        {
          name: '总计',
          type: 'all_clue_count',
          active: true,
          search: 'clue_service_user_ids',
          total: 0,
        },
        {
          name: '逾期未跟进',
          type: 'over_time_follow_clue_count',
          active: false,
          search: 'is_clue_over_time_no_follow',
        },
      ],
    },
    4: {
      list: [
        {
          name: '总计',
          type: 'all_clue_count',
          active: true,
          search: 'clue_service_user_ids',
          total: 0,
        },
        {
          name: '逾期未跟进',
          type: 'over_time_follow_clue_count',
          active: false,
          search: 'is_clue_over_time_no_follow',
        },
      ],
    },
    5: {
      list: [
        {
          name: '总计',
          type: 'all_clue_count',
          active: true,
          search: 'clue_service_user_ids',
          total: 0,
        },
        {
          name: '逾期未跟进',
          type: 'over_time_follow_clue_count',
          active: false,
          search: 'is_clue_over_time_no_follow',
        },
      ],
    },
    6: {
      list: [
        {
          name: '总计',
          type: 'all_clue_count',
          active: true,
          search: 'clue_service_user_ids',
          total: 0,
        },
        {
          name: '逾期未跟进',
          type: 'over_time_follow_clue_count',
          active: false,
          search: 'is_clue_over_time_no_follow',
        },
      ],
    },
  },
});
const subEmpty = reactive({});

// -- sub：赋值
const getSubList = (active) =>
  new Promise((resove) => {
    if (active) {
      // url携带参数
      sub.list = sub.data[active].list;
    } else {
      // 本页面操作
      sub.list = tab.tabActive ? sub.data[tab.tabActive].list : sub.list_all;
    }
    resove(sub.list);
  });

// --  sub：切换
const changeSub = (index, item, subList) => {
  console.log('changeSub', index, item, subList);
  const active = JSON.parse(JSON.stringify(item.active));
  if (index === 0) {
    //总计
    if (active) {
      personnel.show = true;
      personnel.chose_items = JSON.parse(JSON.stringify(sub.chose_items));
      personnel.chose_ids = JSON.parse(JSON.stringify(sub.chose_ids));
      return;
    } else {
      subList.filter((val) => (val.active = false));
      item.active = true;
      Object.assign(params, JSON.parse(JSON.stringify(paramsEmpty)));
      params.clue_service_user_ids = sub.chose_ids;
    }
  } else if (index === 1 || index == 2) {
    Object.assign(params, JSON.parse(JSON.stringify(paramsEmpty)));
    //逾期未跟进,本月待签、本月待转
    subList.filter((val) => (val.active = false));
    params.clue_service_user_ids = sub.chose_ids;
    if (active) {
      subList[0].active = true;
    } else {
      subList[index].active = true;
      params[item.search] = 1;
    }
  }
  //相关数据处理
  resetSearchCommon('sub');
};

// -- 展开收起：操作
const arrowActive = ref();
const handleArrow = (clue_id) => {
  arrowActive.value = arrowActive.value == clue_id ? '' : clue_id;
};

// -- 搜索框：取消kw
const onCancel = () => {
  _DATA.kw = JSON.parse(JSON.stringify(kw_default.value));
};

// -- 搜索框：input格式化去除左右空格
const formatterSearch = (value) => {
  return value.replace(/^\s*|\s*$/g, '');
};

// -- 跟进记录：参数
const recordInfo = reactive({
  show: false,
  data: {},
  key: new Date(),
  total: 0,
});

// -- 跟进记录：点击 全部跟进记录
const recordPop = (item) => {
  recordInfo.show = true;
  recordInfo.data = item;
};

// -- 跟进记录：关闭回调
const reviewOk = () => {
  recordInfo.show = false;
  recordInfo.key = new Date();
};

// -- 跟进记录：动态获取总数，判断是否展示”全部跟进记录“
const getRecordTotal = (total) => {
  console.log(total);
  recordInfo.total = total;
};

// -- 选择服务人员
const personnel = reactive({
  show: false,
  title: '选择服务人员',
  placeholder: '请输入人员姓名',
  http: `customerManagement_clueCustomerServiceRoleParam`,
  chose_items: [],
  chose_ids: [],
});

// -- 选择服务人员：回调
const reviewTempSelect = (status, items, ids) => {
  personnel.show = false;
  sub.chose_items = items;
  sub.chose_ids = ids;
  //相关数据处理
  params.clue_service_user_ids = ids;
  resetSearchCommon('clue_service_user_ids');
};

// -- 各种条件下附带搜索参数汇总方法
const kw = ref('');
const kw_default = ref('');
const resetSearchCommon = (type_name) => {
  if (loading.value) {
    return;
  }
  _DATA.page.page = 1;
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  _DATA.showAction = false;
  if (type_name === 'search') {
    //点击搜索按钮
    if (kw.value === '') {
      //输入框没有参数值
      loadList();
    } else {
      _DATA.clue_step = '';
      kw_default.value = JSON.parse(JSON.stringify(kw.value));
      const obj = {
        clue_step: '',
        ...paramsEmpty,
        ...actionParmarsEmpty,
      };
      loadList(obj);
    }
  } else if (type_name === 'clear') {
    //有搜索条件下的-清空叉号icon
    console.log(type_name);
    kw_default.value = '';
    kw.value = '';
    const obj = {
      ...params,
      ...actionParmars,
    };
    loadList(obj);
  } else if (type_name === 'tab_all_wechat') {
    //切换全部线索、企微线索数据处理
    kw.value = '';
    kw_default.value = '';
    _DATA.clue_step = '';
    const obj = {
      params: JSON.parse(JSON.stringify(paramsEmpty)),
      actionParmars: JSON.parse(JSON.stringify(actionParmarsEmpty)),
    };
    Object.assign(tab, JSON.parse(JSON.stringify(tabEmpty)));
    Object.assign(sub, JSON.parse(JSON.stringify(subEmpty)));
    Object.assign(params, obj.params);
    Object.assign(actionParmars, obj.actionParmars);
    loadList();
  } else if (type_name === 'action_submit') {
    //筛选条件-确定按钮
    actionShow.value = false;
    kw_default.value = JSON.parse(JSON.stringify(kw.value));
    loadList();
  } else if (type_name === 'action_reset') {
    //筛选条件-重置按钮
    actionShow.value = false;
    Object.assign(
      actionParmars,
      JSON.parse(JSON.stringify(actionParmarsEmpty))
    );
    loadList();
  } else if (type_name === 'clue_service_user_ids') {
    //服务人员-数据处理

    loadList();
  } else if (type_name === 'sub') {
    //总计、逾期未跟进-数据处理
    kw.value = '';
    kw_default.value = '';
    loadList();
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/clueList.less';
</style>
