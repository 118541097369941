<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2022-09-09 16:10:36
 * @Description: 
-->
<template>
  <div class="wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <NavBar
      safe-area-inset-top
      left-arrow
      title="见面自评"
      @click-left="onBack"
      :fixed="true"
    />
    <!-- 表单操作 -->
    <div class="formBase">
      <!-- 加载状态 -->
      <Loading
        class="loading-box"
        color="#0094ff"
        v-if="state.loading"
      ></Loading>
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell borderBottom">
            <!-- <Image
              class="imgIcon"
              round
              :src="$host + state.face_image"
              v-if="state.face_image"
            /> -->
            <span class="avader-img">
              {{ state.customer_name ? state.customer_name[0] : '客' }}
            </span>
            <h2 class="h2">
              {{ state.customer_name ? state.customer_name : '客户' }}的性格类型
            </h2>
            <Field
              class="noBorder"
              name="checkboxGroup"
              :rules="[
                {
                  required: true,
                  message: '请选择性格类型',
                },
              ]"
            >
              <template #input>
                <CheckboxGroup v-model="form.list.character_type">
                  <Checkbox
                    v-for="(val, key) in state.characterList"
                    :key="val"
                    class="checkboxNoIcon"
                    :name="key"
                  >
                    <template #icon></template>
                    <p
                      class="menuBox"
                      :class="{
                        checked: form.list.character_type.indexOf(key) > -1,
                      }"
                    >
                      {{ val }}
                    </p>
                  </Checkbox>
                </CheckboxGroup>
              </template>
            </Field>
          </div>
          <!-- 五步评价-----begin -->
          <div class="cell borderBottom">
            <h2 class="h2 tr">五步自评</h2>
            <!-- 五步自评 -->
            <div class="fiveList">
              <ul class="clearfix">
                <li
                  class="pull-left"
                  v-for="i in state.evaluateList"
                  :key="i.id"
                  :class="{ checked: state.curActive == i.id }"
                  @click="getActive(i)"
                >
                  <p class="p1">{{ i.num }}</p>
                  <p class="p2">{{ i.name }}</p>
                </li>
              </ul>
            </div>
          </div>
          <!-- 五步评价-见工地 -->
          <div v-if="state.curActive == 1">
            <div class="cell clearfix">
              <label class="form_title pull-left">是否完成</label>
              <Field class="noBorder switchBoxForEvaluate pull-right">
                <template #input>
                  <Switch
                    v-model="form.list.face_place_flag"
                    active-value="1"
                    inactive-value="2"
                    size="24"
                    @change="(v) => changeSwitch(v, 'face_place')"
                  />
                </template>
              </Field>
            </div>

            <div
              class="cell borderBottom clearfix"
              v-if="form.list.face_place_flag == 1"
            >
              <h3 class="h3">自我评价</h3>
              <h4 class="h4">你对自己这次见客户打几分呢？</h4>
              <Field
                class="noBorder rateForEvaluate mb-20"
                :rules="[
                  {
                    required: true,
                    message: '请对自己这次见客户打分',
                  },
                ]"
              >
                <template #input>
                  <Rate
                    v-model="form.list.face_place_score"
                    allow-half
                    :size="25"
                  />
                </template>
              </Field>
            </div>
          </div>
          <!-- 五步评价-讲公司 -->
          <div v-if="state.curActive == 2">
            <div class="cell clearfix">
              <label class="form_title pull-left">是否完成</label>
              <Field class="noBorder switchBoxForEvaluate pull-right">
                <template #input>
                  <Switch
                    v-model="form.list.company_intro_flag"
                    active-value="1"
                    inactive-value="2"
                    size="24"
                    @change="(v) => changeSwitch(v, 'company_intro')"
                  />
                </template>
              </Field>
            </div>
            <div
              class="cell borderBottom clearfix"
              v-if="form.list.company_intro_flag == 1"
            >
              <h3 class="h3">自我评价</h3>
              <h4 class="h4">你对自己这次见客户打几分呢？</h4>
              <Field
                class="noBorder rateForEvaluate mb-20"
                :rules="[
                  {
                    required: true,
                    message: '请对自己这次见客户打分',
                  },
                ]"
              >
                <template #input>
                  <Rate
                    v-model="form.list.company_intro_score"
                    allow-half
                    :size="25"
                  />
                </template>
              </Field>
            </div>
          </div>
          <!-- 五步评价-讲预案 -->
          <div v-if="state.curActive == 3">
            <div class="cell clearfix">
              <label class="form_title pull-left">是否完成</label>
              <Field class="noBorder switchBoxForEvaluate pull-right">
                <template #input>
                  <Switch
                    v-model="form.list.ready_design_flag"
                    active-value="1"
                    inactive-value="2"
                    size="24"
                    @change="(v) => changeSwitch(v, 'ready_design')"
                  />
                </template>
              </Field>
            </div>
            <div
              class="cell borderBottom clearfix"
              v-if="form.list.ready_design_flag == 1"
            >
              <h3 class="h3">自我评价</h3>
              <h4 class="h4">你对自己这次见客户打几分呢？</h4>
              <Field
                class="noBorder rateForEvaluate mb-20"
                :rules="[
                  {
                    required: true,
                    message: '请对自己这次见客户打分',
                  },
                ]"
              >
                <template #input>
                  <Rate
                    v-model="form.list.ready_design_score"
                    allow-half
                    :size="25"
                  />
                </template>
              </Field>
            </div>
          </div>
          <!-- 五步评价-压单 -->
          <div v-if="state.curActive == 4">
            <div class="cell clearfix">
              <label class="form_title pull-left">是否完成</label>
              <Field class="noBorder switchBoxForEvaluate pull-right">
                <template #input>
                  <Switch
                    v-model="form.list.press_order_flag"
                    active-value="1"
                    inactive-value="2"
                    size="24"
                    @change="(v) => changeSwitch(v, 'press_order')"
                  />
                </template>
              </Field>
            </div>
            <div
              class="cell borderBottom clearfix"
              v-if="form.list.press_order_flag == 1"
            >
              <h3 class="h3">自我评价</h3>
              <h4 class="h4">你对自己这次见客户打几分呢？</h4>
              <Field
                class="noBorder rateForEvaluate mb-20"
                :rules="[
                  {
                    required: true,
                    message: '请对自己这次见客户打分',
                  },
                ]"
              >
                <template #input>
                  <Rate
                    v-model="form.list.press_order_score"
                    allow-half
                    :size="25"
                  />
                </template>
              </Field>
            </div>
          </div>
          <!-- 五步评价-礼貌送行 -->
          <div v-if="state.curActive == 5">
            <div class="cell clearfix">
              <label class="form_title pull-left">是否完成</label>
              <Field class="noBorder switchBoxForEvaluate pull-right">
                <template #input>
                  <Switch
                    v-model="form.list.harmony_send_flag"
                    active-value="1"
                    inactive-value="2"
                    size="24"
                    @change="(v) => changeSwitch(v, 'harmony_send')"
                  />
                </template>
              </Field>
            </div>
            <div
              class="cell borderBottom clearfix"
              v-if="form.list.harmony_send_flag == 1"
            >
              <h3 class="h3">自我评价</h3>
              <h4 class="h4">你对自己这次见客户打几分呢？</h4>
              <Field
                class="noBorder rateForEvaluate mb-20"
                :rules="[
                  {
                    required: true,
                    message: '请对自己这次见客户打分',
                  },
                ]"
              >
                <template #input>
                  <Rate
                    v-model="form.list.harmony_send_score"
                    allow-half
                    :size="25"
                  />
                </template>
              </Field>
            </div>
          </div>
          <!-- 五步评价-----end -->
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              求教
            </label>
            <Field
              v-model="form.list.need_help"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="请填写与客户见面时发生的问题和需要协调的问题"
              :rules="[
                {
                  required: true,
                  message: '请填写与客户见面时发生的问题和需要协调的问题',
                },
              ]"
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              我为此做过些什么
            </label>
            <Field
              v-model="form.list.action_detail"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="请填写与客户见面时做的事情"
              :rules="[
                {
                  required: true,
                  message: '请填写与客户见面时做的事情',
                },
              ]"
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
  </div>
</template>

<script setup>
import {
  inject,
  reactive,
  ref,
  onBeforeMount,
  defineEmits,
  defineProps,
  defineExpose,
} from 'vue';
import {
  NavBar,
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Loading,
  CheckboxGroup,
  Checkbox,
  Switch,
  Rate,
  Overlay,
} from 'vant';
const $http = inject('$http');
const emit = defineEmits(['reviewClose', 'reviewOk']);
const props = defineProps(['data']);

const state = reactive({
  characterList: [],
  loading: false,
  loadingConfirm: false,
  customer_name: props.data?.customer_name,
  //-五步自评
  evaluateList: [
    { id: 1, num: '1', name: '见工地' },
    { id: 2, num: '2', name: '讲公司' },
    { id: 3, num: '3', name: '讲预案' },
    { id: 4, num: '4', name: '压单' },
    { id: 5, num: '5', name: '礼貌送行' },
  ],
  active: [1],
  curActive: 1,
});

//-bar返回操作
const onBack = () => {
  emit('reviewClose');
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  state.loading = true;
  await $http.getSaleOptionsApi().then((res) => {
    state.loading = false;
    if (res.code === 0) {
      state.characterList = res.data.character_type;
    } else {
      Toast(res.data);
    }
  });
};
//-获取参数--end

//-五步自评操作-----begin
const getActive = (data) => {
  if (data.id === state.curActive) {
    return;
  } else {
    state.curActive = data.id;
  }
};

// 未完成清空相应打分
const changeSwitch = (v, type) => {
  if (v == 2) {
    form['list'][type + '_score'] = 0;
  }
};
//-五步自评操作-----end

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    character_type: props.character_type ? props.character_type : [],
    face_place_flag: props.face_place_flag ? props.face_place_flag : 2,
    face_place_score: props.face_place_score ? props.face_place_score : 0,
    company_intro_flag: props.company_intro_flag ? props.company_intro_flag : 2,
    company_intro_score: props.company_intro_score
      ? props.company_intro_score
      : 0,
    ready_design_flag: props.ready_design_flag ? props.ready_design_flag : 2,
    ready_design_score: props.ready_design_score ? props.ready_design_score : 0,
    press_order_flag: props.press_order_flag ? props.press_order_flag : 2,
    press_order_score: props.press_order_score ? props.press_order_score : 0,
    harmony_send_flag: props.harmony_send_flag ? props.harmony_send_flag : 2,
    harmony_send_score: props.harmony_send_score ? props.harmony_send_score : 0,
    need_help: props.need_help ? props.need_help : '',
    action_detail: props.action_detail ? props.action_detail : '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      emit('reviewOk', form.list);
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//-操作失败
const getFailFun = () => {
  state.loadingConfirm = false;
};

defineExpose({ getFailFun });

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/evaluateTemp.less';
</style>
