<!--
 * @Author: gest
 * @Date:  2023-10-26 15:26:09
 * @LastEditTime: 2023-11-30 16:42:49
 * @Description:V1.4.8工程&产品变更确认
 -->
<template>
  <Popup
    v-model:show="_DATA.showOverlay"
    :close-on-popstate="true"
    position="bottom"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="wrapper" v-if="_DATA.showOverlay" @click.stop>
      <NavBar
        left-arrow
        title="工程&产品变更确认"
        @click-left="onCloseOverLay"
        :fixed="true"
        :placeholder="true"
      ></NavBar>
      <div class="page-box middle-box">
        <Form class="form-box" ref="formRef">
          <!-- <div class="tip_box">
            若选择无变更,结算单变更款默认为0,不可更改,谨慎操作！
          </div> -->
          <div class="detail-box">
            <p class="label-name" style="padding: 20px 0 0 0">
              <em>*</em>工程变更
            </p>
            <span
              class="red_color"
              style="font-size: 12px; margin-left: 5px"
              v-if="
                _DATA.base_item == 1 && _DATA.info.is_base_change_order != 1
              "
              >未检测到该阶段工程变更确认单，请先创建！</span
            >
            <Field
              style="border: none; padding: 0"
              name="radio"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'onChange',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  style="width: 100%"
                  v-model="_DATA.base_item"
                  :disabled="
                    _DATA.info.edit_change_confirm != 1 ||
                    _DATA.info.is_base_change_order == 1
                  "
                >
                  <Radio
                    :name="1"
                    style="
                      color: #000000;
                      padding: 17px 0;
                      border-bottom: 1px solid #efefef;
                    "
                    >有变更</Radio
                  >
                  <Radio
                    :name="2"
                    style="
                      color: #000000;
                      padding: 17px 0;
                      border-bottom: 1px solid #efefef;
                    "
                    >无变更</Radio
                  >
                </RadioGroup></template
              ></Field
            >
            <p class="label-name" style="padding: 20px 0 0 0">
              <em>*</em>产品变更
            </p>
            <span
              class="red_color"
              style="font-size: 12px; margin-left: 5px"
              v-if="
                _DATA.main_item == 1 && _DATA.info.is_main_change_order != 1
              "
              >未检测到该阶段产品变更确认单，请先创建！</span
            >
            <Field
              style="border: none; padding: 0"
              name="radio"
              :rules="[
                {
                  required: true,
                  message: '请选择',
                  trigger: 'onChange',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  style="width: 100%"
                  v-model="_DATA.main_item"
                  :disabled="
                    _DATA.info.edit_change_confirm != 1 ||
                    _DATA.info.is_main_change_order == 1
                  "
                >
                  <Radio
                    :name="1"
                    style="
                      color: #000000;
                      padding: 17px 0;
                      border-bottom: 1px solid #efefef;
                    "
                    >有变更</Radio
                  >
                  <Radio
                    :name="2"
                    style="
                      color: #000000;
                      padding: 17px 0;
                      border-bottom: 1px solid #efefef;
                    "
                    >无变更</Radio
                  >
                </RadioGroup></template
              ></Field
            >
          </div>
        </Form>
      </div>
      <div class="stick-box">
        <Button type="primary" size="large" @click="saveBtn">确认</Button>
      </div>
    </div>
  </Popup>
</template>
<script setup>
import {
  reactive,
  ref,
  inject,
  watch,
  defineProps,
  defineEmits,
  onMounted,
} from 'vue';
import {
  Form,
  Popup,
  Button,
  NavBar,
  Notify,
  Field,
  RadioGroup,
  Radio,
  Dialog,
} from 'vant';
const $http = inject('$http');

const props = defineProps(['isShow', 'data']);
const emit = defineEmits(['closePop', 'update:func']);
const _DATA = reactive({
  showOverlay: props.isShow ? props.isShow : false,
  node_id: props.data.node_id ? props.data.node_id : '',
  base_item: null,
  main_item: null,
  info: {},
});

onMounted(() => {
  _DATA.node_id = props.data.node_id ? props.data.node_id : '';
  // getDetail();
});
//-@头部合同参数
const getDetail = async () => {
  await $http
    .construction_getNodeParams({
      node_id: _DATA.node_id,
    })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        _DATA.info = data;
        if (data.change_confirm_info.base_item) {
          _DATA.base_item = data.change_confirm_info?.base_item;
        }
        if (data.change_confirm_info.main_item) {
          _DATA.main_item = data.change_confirm_info?.main_item;
        }
        if (data.is_base_change_order == 1) {
          _DATA.base_item = 1;
        }
        if (data.is_main_change_order == 1) {
          _DATA.main_item = 1;
        }
      } else {
        Notify({ type: 'warning', message: res.data });
      }
    });
};
watch(
  () => props.isShow,
  (cur) => {
    _DATA.showOverlay = cur ? cur : false;
    _DATA.node_id = props.data.node_id ? props.data.node_id : '';
    _DATA.base_item = null;
    _DATA.main_item = null;
    getDetail();
  }
);
const formRef = ref(null);
const saveBtn = () => {
  formRef.value?.validate().then(() => {
    Dialog.confirm({
      message: '请仔细核对填写数据，确认无误后再提交！',
    }).then(() => {
      $http
        .construction_changeConfirmSave({
          node_id: _DATA.node_id,
          base_item: _DATA.base_item,
          main_item: _DATA.main_item,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                emit('update:func', '');
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: data,
              duration: 2000,
            });
          }
        });
    });
  });
};
const onCloseOverLay = () => {
  emit('closePop');
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
.wrapper {
  width: 100%;
  height: 100%;
}
.tip_box {
  background: @orange-bg-color;
  font-size: 14px;
  line-height: 20px;
  color: @orange-color;
  padding: 18px 10px;
  text-align: center;
}
</style>
