<!--
 * @Author: NanNan
 * @Date: 2022-04-21 16:27:31
 * @LastEditTime: 2024-05-23 15:49:23
 * @Description: 转款
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- tab切换 -->
    <Row class="p20 stick-box-top" gutter="15">
      <Col span="12" @click="changeActive(1)">
        <Button
          :type="active === 1 ? 'primary' : 'default'"
          size="small"
          class="b100"
        >
          合同转款
        </Button>
      </Col>
      <Col span="12" @click="changeActive(2)">
        <Button
          :type="active === 2 ? 'primary' : 'default'"
          size="small"
          class="b100"
        >
          合同外转款
        </Button>
      </Col>
    </Row>
    <!-- 合同转款 -->
    <div class="tab-two tab-same" v-if="active === 1">
      <Form class="form-box" ref="formInRef">
        <!-- 转出合同相关 --- 灰色背景区域 -->
        <div class="top">
          <p class="label-name">
            <em>*</em>
            转出合同
          </p>
          <Field
            v-model="form_info.out_contract_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转出合同"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请选择转出合同',
              },
            ]"
            @click="openContract('out_pre')"
          />

          <p class="text" v-if="form_info.out_contract_no">
            合同编号：{{ form_info.out_contract_no }}
          </p>

          <p class="label-name">
            <em>*</em>
            转出款期
          </p>
          <Field
            v-model="form_info.out_payment_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转出款期"
            @click="openStage('out')"
            :rules="[
              {
                required: true,
                message: '请选择转出款期',
              },
            ]"
          />

          <div class="flex-box-two" style="margin-top: 0px; border: 0">
            <div
              v-if="
                form_info.out_contract_type != 53 &&
                form_info.out_contract_type != 69
              "
            >
              <p class="t">本期款项</p>
              <p class="w">{{ out_payment.receivable_money }}</p>
            </div>
            <div>
              <p class="t">增减项</p>
              <p class="w">{{ out_payment.adjust_money }}</p>
            </div>
            <div>
              <p class="t">本期已收金额</p>
              <p class="w">{{ out_payment.received_money }}</p>
            </div>
            <div
              v-if="
                form_info.out_contract_type != 53 &&
                form_info.out_contract_type != 69
              "
            >
              <p class="t">剩余应收</p>
              <p class="w">
                {{
                  out_payment.receivable_money === '-'
                    ? '0'
                    : (
                        [
                          out_payment.receivable_money,
                          out_payment.adjust_money,
                        ].reduce((x, y) => x * 100 + y * 100) /
                          100 -
                        out_payment.received_money
                      ).toFixed(2)
                }}
              </p>
            </div>
          </div>

          <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
          <div class="wait-box">
            <Icon
              name="info-o"
              color="#EA0000"
              v-if="
                out_payment.income_amount_str ||
                out_payment.expenditure_amount_str
              "
            />
            <p>
              {{
                getString([
                  out_payment.income_amount_str,
                  out_payment.expenditure_amount_str,
                ])
              }}
            </p>
          </div>
          <!-- end -->
          <!-- v1.6.1 产品款新增可转金额-->
          <div
            class="flex_between align_center pt_10"
            v-if="
              (form_info.out_contract_type == 53 ||
                form_info.out_contract_type == 69) &&
              out_payment.available_money >= 0
            "
          >
            <p></p>
            <p class="red_color font_size_14">
              可转金额：{{ out_payment.available_money }}
            </p>
          </div>
          <!-- end -->
          <Field
            label="转出金额"
            type="number"
            v-model="form_info.out_amount"
            placeholder="填写转出金额"
            :formatter="formatter"
            format-trigger="onBlur"
            :rules="[
              {
                required: true,
                message: '请填写转出金额',
              },
            ]"
            input-align="right"
            error-message-align="right"
            style="margin-top: 15px"
          ></Field>

          <!-- 0.6.0 新增【退款账号、打款人、手续费、优惠活动】 -->
          <div class="border-top">
            <p class="label-name">退款账号</p>
            <Field
              v-model="form_info.out_collection_account_str"
              right-icon="arrow-down"
              readonly
              placeholder="选择退款账号"
              @click="openCollection('out')"
            />
            <p class="label-name">打款人</p>
            <Field
              v-model="form_info.out_drawee"
              maxlength="30"
              placeholder="输入打款人"
            />
            <p class="label-name">手续费</p>
            <Field
              v-model="form_info.out_service_charge"
              type="number"
              :formatter="formatter"
              format-trigger="onBlur"
              placeholder="填写手续费"
            >
              <template #button>元</template>
            </Field>
            <!-- <p class="label-name">优惠活动</p>
            <Field
              v-model="form_info.out_activity_name"
              placeholder="-"
              disabled
              style="background: #eee"
            /> -->
          </div>
          <!-- end -->
        </div>
        <!-- 转入合同相关 --- 白色区域 -->
        <div class="content">
          <p class="label-name">
            <em>*</em>
            转入合同
          </p>
          <Field
            v-model="form_info.enter_contract_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转入合同"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请选择转入合同',
              },
            ]"
            @click="openContract('enter')"
          />
          <p class="text gray">合同编号：{{ form_info.enter_contract_no }}</p>

          <p class="label-name">
            <em>*</em>
            转入款期
          </p>
          <Field
            v-model="form_info.enter_payment_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转入款期"
            @click="openStage('enter')"
            :rules="[
              {
                required: true,
                message: '请选择转入款期',
              },
            ]"
          />
          <!-- 期款 -->
          <div class="qi-box">
            <p class="label-name">{{ form_info.enter_payment_str }}</p>
            <ul class="flex-box">
              <li
                v-if="
                  form_info.enter_contract_type != 53 &&
                  form_info.enter_contract_type != 69
                "
              >
                <p class="t">本期款项</p>
                <p class="n">{{ enter_payment.receivable_money }}</p>
              </li>
              <li>
                <p class="t">增减项</p>
                <p class="n">{{ enter_payment.adjust_money }}</p>
              </li>
              <li>
                <p class="t">本期已收金额</p>
                <p class="n">{{ enter_payment.received_money }}</p>
              </li>
              <li
                v-if="
                  form_info.enter_contract_type != 53 &&
                  form_info.enter_contract_type != 69
                "
              >
                <p class="t">剩余应收</p>
                <p class="n">
                  {{
                    enter_payment.receivable_money === '-'
                      ? '0'
                      : (
                          [
                            enter_payment.receivable_money,
                            enter_payment.adjust_money,
                          ].reduce((x, y) => x * 100 + y * 100) /
                            100 -
                          enter_payment.received_money
                        ).toFixed(2)
                  }}
                </p>
              </li>
            </ul>
            <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
            <div class="wait-box">
              <Icon
                name="info-o"
                color="#EA0000"
                v-if="
                  enter_payment.income_amount_str ||
                  enter_payment.expenditure_amount_str
                "
              />
              <p>
                {{
                  getString([
                    enter_payment.income_amount_str,
                    enter_payment.expenditure_amount_str,
                  ])
                }}
              </p>
            </div>
            <!-- end -->
            <Field
              v-model="form_info.out_amount"
              label="转入金额"
              disabled
              placeholder="0"
              input-align="right"
              style="margin-top: 20px"
            />

            <!-- 0.6.0 新增【收款账号、打款人、手续费、优惠活动】 -->
            <div>
              <p class="label-name">收款账号</p>
              <Field
                v-model="form_info.enter_collection_account_str"
                right-icon="arrow-down"
                readonly
                placeholder="选择收款账号"
                @click="openCollection('enter')"
              />
              <p class="label-name">打款人</p>
              <Field
                v-model="form_info.enter_drawee"
                maxlength="30"
                placeholder="输入打款人"
              />
              <p class="label-name">手续费</p>
              <Field
                v-model="form_info.enter_service_charge"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="填写手续费"
              >
                <template #button>元</template>
              </Field>
              <!-- <p class="label-name">优惠活动</p>
              <Field
                v-model="form_info.enter_activity_name"
                placeholder="-"
                disabled
              /> -->
            </div>
            <!-- end -->

            <p class="label-name">备注</p>
            <Field
              v-model="form_info.remarks"
              rows="3"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            ></Field>
            <!-- 附件组件 -->
            <p class="label-name">附件</p>
            <FileUpload
              :data="form_info.file_urls"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
            <!-- end -->
          </div>
          <div class="stick-box">
            <Button type="primary" size="large" @click="onSubmit">确定</Button>
          </div>
        </div>
      </Form>
    </div>
    <!-- 合同外转款 -->
    <div class="tab-one tab-same" v-if="active === 2">
      <Form class="form-box" ref="formOutRef">
        <!-- 转出合同相关 --- 灰色背景区域 -->
        <div class="top">
          <p class="label-name">
            <em>*</em>
            转出关联合同
          </p>
          <Field
            v-model="form_info.out_contract_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转出关联合同"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请选择转出关联合同',
              },
            ]"
            @click="openContract('out')"
          />

          <p class="text">合同编号：{{ form_info.out_contract_no }}</p>
          <div class="flex-box">
            <div>
              <p class="t">已收金额</p>
              <p class="w">
                <em v-if="out_payment.received_money !== '-'">¥</em>
                {{ out_payment.received_money || '0.00' }}
              </p>
            </div>
            <!-- <div>
              <p class="t">已退金额</p>
              <p class="w">0.0</p>
            </div> -->
          </div>

          <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
          <div class="wait-box">
            <Icon
              name="info-o"
              color="#EA0000"
              v-if="
                out_payment.income_amount_str ||
                out_payment.expenditure_amount_str
              "
            />
            <p>
              {{
                getString([
                  out_payment.income_amount_str,
                  out_payment.expenditure_amount_str,
                ])
              }}
            </p>
          </div>
          <!-- end -->

          <p class="label-name">
            <em>*</em>
            转出金额
          </p>
          <Field
            type="number"
            v-model="form_info.out_amount"
            :formatter="formatter"
            format-trigger="onBlur"
            placeholder="填写转出金额"
            :rules="[
              {
                required: true,
                message: '请填写转出金额',
              },
            ]"
          >
            <template #button>元</template>
          </Field>

          <!-- 0.6.0 新增【退款账号、打款人、手续费、优惠活动】 -->
          <div class="border-top">
            <p class="label-name">退款账号</p>
            <Field
              v-model="form_info.out_collection_account_str"
              right-icon="arrow-down"
              readonly
              placeholder="选择退款账号"
              @click="openCollection('out')"
            />
            <p class="label-name">打款人</p>
            <Field
              v-model="form_info.out_drawee"
              maxlength="30"
              placeholder="输入打款人"
            />
            <p class="label-name">手续费</p>
            <Field
              v-model="form_info.out_service_charge"
              type="number"
              :formatter="formatter"
              format-trigger="onBlur"
              placeholder="填写手续费"
            >
              <template #button>元</template>
            </Field>
            <!-- <p class="label-name">优惠活动</p>
            <Field
              v-model="form_info.out_activity_name"
              placeholder="-"
              disabled
              style="background: #eee"
            /> -->
          </div>
          <!-- end -->
        </div>

        <!-- 转入合同相关 --- 白色区域 -->
        <div class="content">
          <p class="label-name">
            <em>*</em>
            转入合同
          </p>
          <Field
            v-model="form_info.enter_contract_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转入合同"
            :rules="[
              {
                required: true,
                trigger: 'onChange',
                message: '请选择转入合同',
              },
            ]"
            @click="openContract('enter')"
          />
          <p class="text gray">合同编号：{{ form_info.enter_contract_no }}</p>

          <p class="label-name">
            <em>*</em>
            转入款期
          </p>
          <Field
            v-model="form_info.enter_payment_str"
            right-icon="arrow-down"
            readonly
            placeholder="选择转入款期"
            @click="openStage('enter')"
            :rules="[
              {
                required: true,
                message: '请选择转入款期',
              },
            ]"
          />

          <!-- 期款 -->
          <div class="qi-box">
            <p class="label-name">{{ form_info.enter_payment_str }}</p>
            <ul class="flex-box">
              <li
                v-if="
                  form_info.enter_contract_type != 53 &&
                  form_info.enter_contract_type != 69
                "
              >
                <p class="t">本期款项</p>
                <p class="n">{{ enter_payment.receivable_money }}</p>
              </li>
              <li>
                <p class="t">增减项</p>
                <p class="n">{{ enter_payment.adjust_money }}</p>
              </li>
              <li>
                <p class="t">本期已收金额</p>
                <p class="n">{{ enter_payment.received_money }}</p>
              </li>
              <li
                v-if="
                  form_info.enter_contract_type != 53 &&
                  form_info.enter_contract_type != 69
                "
              >
                <p class="t">剩余应收</p>
                <p class="n">
                  {{
                    enter_payment.receivable_money === '-'
                      ? '0'
                      : (
                          [
                            enter_payment.receivable_money,
                            enter_payment.adjust_money,
                          ].reduce((x, y) => x * 100 + y * 100) /
                            100 -
                          enter_payment.received_money
                        ).toFixed(2)
                  }}
                </p>
              </li>
            </ul>
            <!-- v0.5.0 新增 [待审核收款金额、待审核退款金额]-->
            <div class="wait-box">
              <Icon
                name="info-o"
                color="#EA0000"
                v-if="
                  enter_payment.income_amount_str ||
                  enter_payment.expenditure_amount_str
                "
              />
              <p>
                {{
                  getString([
                    enter_payment.income_amount_str,
                    enter_payment.expenditure_amount_str,
                  ])
                }}
              </p>
            </div>
            <!-- end -->
            <Field
              v-model="form_info.out_amount"
              label="转入金额"
              disabled
              placeholder="0"
              input-align="right"
              style="margin-top: 20px"
            />

            <!-- 0.6.0 新增【收款账号、打款人、手续费、优惠活动】 -->
            <div>
              <p class="label-name">收款账号</p>
              <Field
                v-model="form_info.enter_collection_account_str"
                right-icon="arrow-down"
                readonly
                placeholder="选择收款账号"
                @click="openCollection('enter')"
              />
              <p class="label-name">打款人</p>
              <Field
                v-model="form_info.enter_drawee"
                maxlength="30"
                placeholder="输入打款人"
              />
              <p class="label-name">手续费</p>
              <Field
                v-model="form_info.enter_service_charge"
                type="number"
                :formatter="formatter"
                format-trigger="onBlur"
                placeholder="填写手续费"
              >
                <template #button>元</template>
              </Field>
              <!-- <p class="label-name">优惠活动</p>
              <Field
                v-model="form_info.enter_activity_name"
                placeholder="-"
                disabled
              /> -->
            </div>
            <!-- end -->
            <p class="label-name">备注</p>
            <Field
              v-model="form_info.remarks"
              rows="3"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="有什么需要补充的吗..."
              show-word-limit
            ></Field>
            <!-- 附件组件 -->
            <p class="label-name">附件</p>
            <FileUpload
              :data="form_info.file_urls"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
            <!-- end -->
          </div>
          <div class="stick-box">
            <Button type="primary" size="large" @click="onSubmit">确定</Button>
          </div>
        </div>
      </Form>
    </div>
    <!-- 合同组件 -->
    <Popup v-model:show="contract_show" position="bottom">
      <Picker
        :columns="options.c_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.c_list.findIndex((v) => {
            return c_type == 'out'
              ? v.id == form_info.out_contract_id
              : v.id == form_info.enter_contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="contract_show = false"
      />
    </Popup>
    <!-- @（产品预收款）合同转款-转出合同-合同组件 -->
    <Popup v-model:show="pre_contract_show" position="bottom">
      <Picker
        :columns="options.pre_c_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.pre_c_list.findIndex((v) => {
            return v.id == form_info.out_contract_id;
          })
        "
        @confirm="changeContract"
        @cancel="pre_contract_show = false"
      />
    </Popup>
    <!-- 款期组件 -->
    <Popup v-model:show="stage_show" position="bottom">
      <Picker
        :columns="
          stage_type === 'out'
            ? options.out_stage_list
            : options.enter_stage_list
        "
        :columns-field-names="{
          text: 'payment_period',
          value: 'id',
        }"
        @confirm="changeStage"
        @cancel="stage_show = false"
      />
    </Popup>
    <!-- 收退款账号 -->
    <Popup v-model:show="collection_show" position="bottom">
      <Picker
        :columns="options.collection_account"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        :default-index="
          options.collection_account.findIndex((v) => {
            return collection_type == 'out'
              ? v.id == form_info.out_collection_account_id
              : v.id == form_info.enter_collection_account_id;
          })
        "
        @confirm="changeCollection"
        @cancel="collection_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import {
  Row,
  Col,
  Button,
  Field,
  Form,
  Notify,
  Picker,
  Popup,
  Loading,
  Overlay,
  Icon,
} from 'vant';
import FileUpload from '@/components/common/FileUpload';

const $http = inject('$http');
const router = useRouter();
const customer_id = ref(router.currentRoute.value.query.id);

const options = reactive({
  c_list: [], // 合同list
  cash_type: [], //收款方式
  out_stage_list: [], //转出 - 款期list
  enter_stage_list: [], //转入 - 款期list
  collection_account: [], //收退款账号 - list
  pre_c_list: [], //-V1.3.8-合同转款-转出合同-合同组件（产品预收款）
});
const form_default = reactive({
  //-转出合同
  out_contract_id: '', //*合同id
  out_payment_id: '', //*款期id
  out_amount: '', //*转出金额
  out_collection_account_id: '', //退款账号id
  out_collection_account_str: '', //退款账号str
  out_drawee: '', //转出 打款人
  out_service_charge: '', //转出手续费
  out_activity_id: '', //转出优惠活动id
  out_activity_name: '', //转出优惠活动str
  //-展示信息
  out_contract_str: '', // 合同_str
  out_contract_no: '-', // 合同_编号
  out_contract_type: '', // 合同类型
  available_money: '', //V1.6.1可转金额
  out_payment_str: '', //转出款期_str
  //-转入合同
  enter_contract_id: '', //*合同id
  enter_payment_id: '', //*款期id
  enter_amount: '', //转入金额
  enter_collection_account_id: '', //收款账号id
  enter_collection_account_str: '', //收款账号str
  enter_drawee: '', //转入 打款人
  enter_service_charge: '', //转入 手续费
  enter_activity_id: '', //转入 优惠活动id
  enter_activity_name: '', //转入 优惠活动str
  //-展示信息
  enter_contract_str: '', // 合同_str
  enter_contract_no: '-', // 合同_编号
  enter_contract_type: '', //合同类型
  enter_payment_str: '', //转入款期_str
  //- 合同外专款

  remarks: '', //备注
  file_urls: [], //附件集合
  //-data_type:1:合同,2：产品预收款
  data_type: 1,
});

const payment_default = reactive({
  id: '',
  receivable_money: '-', //应收款
  received_money: '-', //本期已收
  adjust_money: '-', //增减项金额
  income_amount_str: '', //待审核收款
  expenditure_amount_str: '', //待审核退款
});

const form_info = reactive({ file_urls: [] });
const out_payment = reactive({});
const enter_payment = reactive({});

onMounted(() => {
  loadFunc();
  loadPaymentFunc();
});

/**
 * 接口调用
 */
const loading = ref(false);
const loadFunc = (type) => {
  loading.value = true;
  $http
    .getContractParmas({ customer_id: customer_id.value })
    .then((res) => {
      const { data, code } = res;
      loading.value = false;
      if (code === 0) {
        options.c_list = data.payment_contract_list;
        options.cash_type = data.cash_type;
        options.payment_contract_list = data.payment_contract_list;
        options.collection_account = data.collection_account;
        changeData();
      } else {
        Notify({ type: 'warning', message: data });
      }
      active.value = type || 1;
    })
    .catch(() => {
      loading.value = false;
      active.value = type || 1;
    });
};

// v0.6.0 接口调整 @根据款期，获取待审核金额相关(不包含增减项了)
const loadPaymentInfoFunc = async (contract_id, payment_id, type) => {
  console.log(type);
  loading.value = true;
  await $http
    .getContractCollectionPaymentInfoApi({
      contract_id,
      payment_id,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        loading.value = false;
        if (type === 'out') {
          out_payment.received_money = data.received_money;
          out_payment.income_amount_str = data.income_amount_str;
          out_payment.expenditure_amount_str = data.expenditure_amount_str;
          out_payment.available_money = data.available_money;
        } else {
          enter_payment.received_money = data.received_money;
          enter_payment.income_amount_str = data.income_amount_str;
          enter_payment.expenditure_amount_str = data.expenditure_amount_str;
        }
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// @数据格式替换
const changeData = () => {
  Object.assign(form_info, form_default);
  Object.assign(out_payment, payment_default);
  Object.assign(enter_payment, payment_default);
  form_info.file_urls = [];
};
/**
 * 操作集合
 */
// @拼接字符串
const getString = (arr) => {
  let strArr = [];
  arr[0] && strArr.push('待审核收款¥' + arr[0]);
  arr[1] && strArr.push('待审核退款¥' + arr[1]);
  return strArr.join('，');
};

// @格式化 金额
const formatter = (value) => {
  let val = parseFloat(value).toFixed(2);
  if (val > 99999999.99) {
    val = 99999999.99;
  } else if (val < 0.0) {
    val = '';
  }
  return val;
};

// @切换选项卡
const active = ref(1);
const changeActive = (type) => {
  loadFunc(type);
  form_info.data_type = 1;
  if (type == 1) {
    loadPaymentFunc();
  }
};

// @选择（非产品预收款）合同
const c_type = ref('out');
const contract_show = ref(false);
const openContract = (name) => {
  c_type.value = name;
  //-1.3.8-合同转款-转出合同-合同组件（产品预收款）
  if (name == 'out_pre') {
    pre_contract_show.value = true;
  } else {
    contract_show.value = true;
  }
};
const changeContract = (value) => {
  console.log(value);
  if (c_type.value === 'out') {
    form_info.out_contract_id = value.id;
    form_info.out_contract_str = value.name;
    form_info.out_contract_no = value.contract_no;
    form_info.out_contract_type = value.type;
    form_info.contract_outside_received_money =
      value.contract_outside_received_money === 0
        ? '0.00'
        : value.contract_outside_received_money || '-';
    options.out_stage_list = value.chargeProportion;
    form_info.income_amount_str = value.income_amount_str;
    form_info.contract_outside_expenditure_amount_str =
      value.contract_outside_expenditure_amount_str;
    // 清空相关数据
    Object.assign(out_payment, payment_default);
    form_info.out_payment_id = ''; //*款期id
    form_info.out_payment_str = ''; //*转出款期_str
    form_info.out_amount = ''; //*转出金额
    form_info.out_collection_account_id = ''; //退款账号id
    form_info.out_collection_account_str = ''; //退款账号str
    form_info.out_drawee = ''; //转出 打款人
    form_info.out_service_charge = ''; //转出手续费
    form_info.out_activity_id = value.activity_id; //转出优惠活动id
    form_info.out_activity_name = value.activity_name; //转出优惠活动str
    active.value === 2 && loadPaymentInfoFunc(value.id, '', 'out');
  } else if (c_type.value === 'out_pre') {
    let contract_lits = JSON.parse(
      JSON.stringify(options.payment_contract_list)
    );
    //-1.3.8-合同转款-[转出合同]-合同组件（产品预收款）
    form_info.out_contract_id = value.id;
    form_info.out_contract_str = value.name;
    form_info.out_contract_no = value.contract_no;
    form_info.out_contract_type = value.type;
    form_info.contract_outside_received_money =
      value.contract_outside_received_money === 0
        ? '0.00'
        : value.contract_outside_received_money || '-';
    options.out_stage_list = value.chargeProportion;
    form_info.income_amount_str = value.income_amount_str;
    form_info.contract_outside_expenditure_amount_str =
      value.contract_outside_expenditure_amount_str;
    //-data_type:1:合同,2：产品预收款
    form_info.data_type = value.data_type || 1;
    //-产品预收款-转入合同过滤，只保留type=53||69的合同[清空转入合同以及款期]
    form_info.enter_contract_id = '';
    form_info.enter_contract_str = '';
    form_info.enter_contract_no = '';
    form_info.enter_contract_type = '';
    options.enter_stage_list = [];
    form_info.enter_payment_id = ''; //*款期id
    form_info.enter_payment_str = ''; //*转出款期_str
    form_info.enter_amount = ''; //*转出金额
    Object.assign(enter_payment, payment_default);
    if (value.data_type == 2) {
      // V1.5.0紧急需求上线 转入合同过滤，只保留type=53||54||55||56||69的合同[清空转入合同以及款期]
      options.c_list = contract_lits.filter((v) => {
        return (
          v.type == 53 ||
          v.type == 54 ||
          v.type == 55 ||
          v.type == 56 ||
          v.type == 69
        );
      });
    } else {
      options.c_list = contract_lits;
    }
    // 清空相关数据
    Object.assign(out_payment, payment_default);
    form_info.out_payment_id = ''; //*款期id
    form_info.out_payment_str = ''; //*转出款期_str
    form_info.out_amount = ''; //*转出金额
    form_info.out_collection_account_id = ''; //退款账号id
    form_info.out_collection_account_str = ''; //退款账号str
    form_info.out_drawee = ''; //转出 打款人
    form_info.out_service_charge = ''; //转出手续费
    form_info.out_activity_id = value.activity_id; //转出优惠活动id
    form_info.out_activity_name = value.activity_name; //转出优惠活动str
  } else {
    form_info.enter_contract_id = value.id;
    form_info.enter_contract_str = value.name;
    form_info.enter_contract_no = value.contract_no;
    form_info.enter_contract_type = value.type;
    options.enter_stage_list = value.chargeProportion;
    // 清空相关数据
    Object.assign(enter_payment, payment_default);
    form_info.enter_payment_id = ''; //*款期id
    form_info.enter_payment_str = ''; //*转出款期_str
    form_info.enter_amount = ''; //*转出金额
    form_info.enter_collection_account_id = ''; //退款账号id
    form_info.enter_collection_account_str = ''; //退款账号str
    form_info.enter_drawee = ''; //转出 打款人
    form_info.enter_service_charge = ''; //转出手续费
    form_info.enter_activity_id = value.activity_id;
    form_info.enter_activity_name = value.activity_name;
  }
  // 更换款期
  if (c_type.value === 'out_pre') {
    pre_contract_show.value = false;
  } else {
    contract_show.value = false;
  }

  loading.value = true;
  setTimeout(() => {
    loading.value = false;
  }, 200);
};

// @V1.3.8-合同转款-转出合同-合同组件（产品预收款）
const pre_contract_show = ref(false);

//-v1.3.8产品预收款接口
const loadPaymentFunc = () => {
  // 1合同内收款 2合同内退款 3合同外收款 4合同外退款 5转出转款 7转款 9增减项 11返款 12产品预收款
  $http
    .getContractPaymentParams({ customer_id: customer_id.value, type: 5 })
    .then((res) => {
      const { data, code } = res;
      if (code === 0) {
        options.pre_c_list = data.payment_contract_list;
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

// @选择款期
const stage_type = ref('out');
const stage_show = ref(false);
const openStage = (name) => {
  stage_type.value = name;
  stage_show.value = true;
};
const changeStage = (value) => {
  console.log(value);
  if (stage_type.value === 'out') {
    form_info.out_payment_id = value.id;
    form_info.out_payment_str = value.payment_period;
    Object.assign(out_payment, value);
  } else {
    form_info.enter_payment_id = value.id;
    form_info.enter_payment_str = value.payment_period;
    Object.assign(enter_payment, value);
  }
  loadPaymentInfoFunc(form_info.out_contract_id, value.id, stage_type.value);
  stage_show.value = false;
};

// @选择收退款账号
const collection_type = ref('out');
const collection_show = ref(false);
const openCollection = (name) => {
  collection_type.value = name;
  collection_show.value = true;
};
const changeCollection = (value) => {
  if (collection_type.value === 'out') {
    form_info.out_collection_account_id = value.id;
    form_info.out_collection_account_str = value.name;
  } else {
    form_info.enter_collection_account_id = value.id;
    form_info.enter_collection_account_str = value.name;
  }
  collection_show.value = false;
};

// @附件
const getFileList = (file_list) => {
  form_info.file_urls = file_list;
};

// @提交
const formInRef = ref(null);
const formOutRef = ref(null);
const onSubmit = () => {
  if (active.value === 1) {
    // 合同内转款
    formInRef.value?.validate().then(() => {
      // 格式变化
      const parmars = Object.assign({}, form_info);
      $http.getContractTransferApi({ ...parmars }).then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              router.push({
                path: '/sale',
                query: { id: customer_id.value },
              });
            },
          });
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
    });
  } else if (active.value === 2) {
    // 合同外转款
    formOutRef.value?.validate().then(() => {
      // 格式变化
      const parmars = Object.assign({}, form_info);
      $http.getContractTransferRefundApi({ ...parmars }).then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              router.push({
                path: '/sale',
                query: { id: customer_id.value },
              });
            },
          });
        } else {
          Notify({ type: 'warning', message: data });
        }
      });
    });
  }
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/contract/transfer.less';
</style>
