<!--
 * @Author: NanNan
 * @Date: 2024-05-14 18:39:06
 * @LastEditTime: 2024-05-31 11:01:59
 * @Description: v1.6.0新版本 获客-线索：渠道转化时间(循环遍历)
-->
<template>
  <div
    class="bj-grey"
    :class="{
      bj_gray: props?.padding === 'none' ? true : false,
      border_top: props?.padding === 'none' ? false : true,
      border_bottom: props?.padding === 'none' ? false : true,
    }"
    v-if="props?.data?.expanded && props?.data?.children?.length > 0"
  >
    <div
      v-for="(sub, subIndex) in _DATA.list"
      :key="sub.name"
      @click.stop="handleClick(subIndex)"
      class="sub_item4"
    >
      <Row
        style="align-items: center; height: 100%; margin: 0 10px 0 15px"
        :class="{
          border_top:
            subIndex !== 0 ||
            (props?.data?.expanded && props?.padding === 'none')
              ? true
              : false,
        }"
      >
        <Col span="9">
          <div class="size13" style="display: flex; align-items: center">
            <template v-if="sub?.children?.length > 0">
              <Icon
                name="play"
                :style="
                  sub?.expanded
                    ? 'transform: rotate(90deg)'
                    : 'transform: rotate(0deg)'
                "
                class="arrow"
              />
            </template>
            <p>{{ sub?.name }}</p>
          </div>
        </Col>
        <!-- 目标/线索-见面/差值 -->
        <Col span="5">
          <div>
            <span class="align_center">
              {{
                sub?.clue_meet?.target === ''
                  ? '-'
                  : sub?.clue_meet?.target + '天'
              }}
            </span>
          </div>
          <div>
            <span class="align_center">
              {{
                sub?.clue_meet?.actual === ''
                  ? '-'
                  : sub?.clue_meet?.actual + '天'
              }}
            </span>
          </div>
          <div v-if="sub?.clue_meet?.gap !== ''">
            <div
              class="flex_line flex_line_center"
              v-if="sub?.clue_meet?.actual < sub?.clue_meet?.target"
            >
              <span class="green">{{ sub?.clue_meet?.gap }}天</span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/up.png')"
                alt="down"
              />
            </div>
            <div
              class="flex_line flex_line_center"
              v-else-if="sub?.clue_meet?.actual > sub?.clue_meet?.target"
            >
              <span class="orange">{{ sub?.clue_meet?.gap }}天</span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/down.png')"
                alt="down"
              />
            </div>
            <div class="flex_line flex_line_center" v-else>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/equal.png')"
                alt="down"
              />
            </div>
          </div>
        </Col>
        <!-- 目标/见面-协议/差值 -->
        <Col span="5">
          <div>
            <span class="align_center">
              {{
                sub?.meet_sign?.target === ''
                  ? '-'
                  : sub?.meet_sign?.target + '天'
              }}
            </span>
          </div>
          <div>
            <span class="align_center">
              {{
                sub?.meet_sign?.actual === ''
                  ? '-'
                  : sub?.meet_sign?.actual + '天'
              }}
            </span>
          </div>
          <div v-if="sub?.meet_sign?.gap !== ''">
            <div
              class="flex_line flex_line_center"
              v-if="sub?.meet_sign?.actual < sub?.meet_sign?.target"
            >
              <span class="green">{{ sub?.meet_sign?.gap }}天</span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/up.png')"
                alt="down"
              />
            </div>
            <div
              class="flex_line flex_line_center"
              v-else-if="sub?.meet_sign?.actual > sub?.meet_sign?.target"
            >
              <span class="orange">{{ sub?.meet_sign?.gap }}天</span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/down.png')"
                alt="down"
              />
            </div>
            <div class="flex_line flex_line_center" v-else>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/equal.png')"
                alt="down"
              />
            </div>
          </div>
        </Col>
        <!-- 目标/协议-转化/差值 -->
        <Col span="5">
          <div>
            <span class="align_center">
              {{
                sub?.sign_conversion?.target === ''
                  ? '-'
                  : sub?.sign_conversion?.target + '天'
              }}
            </span>
          </div>
          <div>
            <span class="align_center">
              {{
                sub?.sign_conversion?.actual === ''
                  ? '-'
                  : sub?.sign_conversion?.actual + '天'
              }}
            </span>
          </div>
          <div v-if="sub?.sign_conversion?.gap !== ''">
            <div
              class="flex_line flex_line_center"
              v-if="sub?.sign_conversion?.actual < sub?.sign_conversion?.target"
            >
              <span class="green">{{ sub?.sign_conversion?.gap }}天</span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/up.png')"
                alt="down"
              />
            </div>
            <div
              class="flex_line flex_line_center"
              v-else-if="
                sub?.sign_conversion?.actual > sub?.sign_conversion?.target
              "
            >
              <span class="orange">{{ sub?.sign_conversion?.gap }}天</span>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/down.png')"
                alt="down"
              />
            </div>
            <div class="flex_line flex_line_center" v-else>
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/equal.png')"
                alt="down"
              />
            </div>
          </div>
        </Col>
      </Row>
      <!-- 递归 -->
      <Transition name="thred-fade">
        <TempChannelTransformationTime
          v-if="sub.children && sub.expanded"
          :data="sub"
          :padding="'none'"
        />
      </Transition>
    </div>
  </div>
</template>
<script setup>
import TempChannelTransformationTime from '@/components/targetData/thredData/collapseItem/TempChannelTransformationTime';
import { defineProps, reactive } from 'vue';
import { Row, Col, Icon } from 'vant';
const props = defineProps(['data', 'padding']);
const _DATA = reactive({
  list: props?.data?.children,
});

const handleClick = (subIndex) => {
  if (_DATA.list[subIndex]?.children?.length == 0) {
    return;
  }
  // 收起其他兄弟子项
  // _DATA.list.forEach((item, index) => {
  //   if (index !== subIndex) {
  //     item.expanded = false;
  //   }
  // });
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
