/*
 * @Author: gest
 * @Date: 2022-05-18 10:05:20
 * @LastEditTime: 2024-05-28 09:49:00
 * @Description:
 */
// 施工计划
import constructionPlan from '@/views/construction/constructionPlan';
// 施工详情
const constructionDetail = () =>
  import('@/views/construction/constructionDetail');
// 现场管理
const siteManagement = () => import('@/views/construction/siteManagement');
// 整改单详情
const rectificationSheetDetail = () =>
  import('@/views/construction/rectificationSheetDetail');
// 整改单验收
const rectificationSheetInspect = () =>
  import('@/views/construction/rectificationSheetInspect');
// 新建、编辑整改单
const rectificationSheetForm = () =>
  import('@/views/construction/rectificationSheetForm');
// 开质保单
const addWarranty = () => import('@/views/construction/addWarranty');
// 客户服务
const customerService = () => import('@/views/construction/customerService');

// 申请客户结算
const applyCustomerSettlement = () =>
  import('@/views/construction/applyCustomerSettlement');
// 新建、编辑工期变更单
const periodChangeSheetForm = () =>
  import('@/views/construction/periodChangeSheetForm');
// 新建、编辑客户投诉
const complaintEdit = () => import('@/views/construction/complaintEdit');
// 新建、编辑客户售后
const editAfterSale = () => import('@/views/construction/editAfterSale');

// 施工合同-施工报价
const siteQuotation = () => import('@/views/construction/siteQuotation');
//V1.4.9动态见证件 产品下单
const productOrder = () => import('@/components/construction/productOrder');
//V1.5.0动态见证件 现场到货验收
const productSiteCheck = () =>
  import('@/components/construction/productSiteCheck');
//V1.6.1项目成员
import siteProjectMembers from '@/views/construction/siteProjectMembers';

const constructionRoute = [
  {
    path: '/constructionPlan',
    component: constructionPlan,
    meta: {
      name: '施工计划',
      bar: true,
    },
  },
  {
    path: '/constructionDetail',
    component: constructionDetail,
    meta: {
      name: '工地详情',
      bar: true,
    },
  },
  {
    path: '/siteManagement',
    component: siteManagement,
    meta: {
      name: '现场管理',
      bar: true,
    },
  },
  {
    path: '/rectificationSheetDetail',
    component: rectificationSheetDetail,
    meta: {
      name: '整改单详情',
      bar: true,
    },
  },
  {
    path: '/rectificationSheetInspect',
    component: rectificationSheetInspect,
    meta: {
      name: '整改单验收',
      bar: true,
    },
  },
  {
    path: '/rectificationSheetForm',
    component: rectificationSheetForm,
    meta: {
      name: '开工地整改单',
      bar: false,
    },
  },
  {
    path: '/customerService',
    component: customerService,
    meta: {
      name: '客户服务',
      bar: true,
    },
  },
  {
    path: '/addWarranty',
    component: addWarranty,
    meta: {
      name: '开质保单',
      bar: true,
    },
  },
  {
    path: '/applyCustomerSettlement',
    component: applyCustomerSettlement,
    meta: {
      name: '申请客户结算',
      bar: true,
    },
  },
  {
    path: '/periodChangeSheetForm',
    component: periodChangeSheetForm,
    meta: {
      name: '开工期变更单',
      bar: false,
    },
  },
  {
    path: '/complaintEdit',
    component: complaintEdit,
    meta: {
      name: '新增投诉',
      bar: false,
    },
  },
  {
    path: '/editAfterSale',
    component: editAfterSale,
    meta: {
      name: '新增售后',
      bar: false,
    },
  },
  {
    path: '/siteQuotation',
    component: siteQuotation,
    meta: {
      name: '施工报价',
      bar: true,
    },
  },
  {
    path: '/productOrder',
    component: productOrder,
    meta: {
      name: '产品下单',
      bar: false,
    },
  },
  {
    path: '/productSiteCheck',
    component: productSiteCheck,
    meta: {
      name: '现场到货验收',
      bar: false,
    },
  },
  {
    path: '/siteProjectMembers',
    component: siteProjectMembers,
    meta: {
      name: '项目成员',
      bar: true,
    },
  },
];
export default constructionRoute;
