<!--
 * @Author: Jessica
 * @Date:  2022-10-25 15:49:48
 * @LastEditTime: 2022-11-04 14:12:17
 * @Description: 设计改派提醒操作面板
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <h3 class="title_tip">{{ props.title }}</h3>
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell" v-if="props.data.checkStaus == 'ok'">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              客户经理
            </label>
            <Field
              v-model="form.list.manager_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择客户经理"
              :rules="[
                {
                  required: true,
                  message: '请选择客户经理',
                },
              ]"
              @click="state.showPicker_manager = true"
            />
          </div>
          <div class="cell" v-if="props.data.checkStaus == 'ok'">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              主创设计师
            </label>
            <Field
              v-model="form.list.desginer_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择主创设计师"
              :rules="[
                {
                  required: true,
                  message: '请选择主创设计师',
                },
              ]"
              @click="state.showPicker_desginer = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              备注
            </label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写具体原因..."
              show-word-limit
              :rules="[
                {
                  required: true,
                  message: '请填写具体原因',
                },
              ]"
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" style="box-shadow: none">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        确定
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-设计师 --->
    <Popup v-model:show="state.showPicker_desginer" position="bottom">
      <Picker
        :columns="state.desginerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'desginer')"
        @cancel="state.showPicker_desginer = false"
        :default-index="
          state.desginerList.findIndex((v) => {
            return v.id == form.list.desginer;
          })
        "
      />
    </Popup>
    <!--picker-客户经理 --->
    <Popup v-model:show="state.showPicker_manager" position="bottom">
      <Picker
        :columns="state.managerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'manager')"
        @cancel="state.showPicker_manager = false"
        :default-index="
          state.managerList.findIndex((v) => {
            return v.id == form.list.manager;
          })
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount, defineProps } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const props = defineProps(['data', 'title', 'id']);
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-客户经理-picker
  showPicker_manager: false,
  managerList: [],
  //-设计师-picker
  showPicker_desginer: false,
  desginerList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    form['list'][type] = value.id;
    form['list'][type + '_name'] = value.name;
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  state.loadingConfirm = true;
  await $http.customerAssignServicePersonParam().then((res) => {
    if (res.code === 0) {
      state.managerList = res.data.customer_manager;
      state.desginerList = res.data.design;
      changeChecker();
    } else {
      state.loadingConfirm = false;
      Toast(res.data);
    }
  });
};
//-获取参数--end
//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    remarks: '',
    manager: props.data.manager ? props.data.manager : '',
    manager_name: props.data.manager_name ? props.data.manager_name : '',
    desginer: props.data.desginer ? props.data.desginer : '',
    desginer_name: props.data.desginer_name ? props.data.desginer_name : '',
  },
});
//-数据容错处理
const changeChecker = () => {
  let desginer = state.desginerList;
  let manager = state.managerList;
  const desginerList =
    desginer.length > 0 && !!props.data.desginer
      ? desginer.filter((item) => item.id == props.data.desginer)
      : [];
  const managerList =
    manager.length > 0 && !!props.data.manager
      ? manager.filter((item) => item.id == props.data.manager)
      : [];
  if (managerList.length === 0) {
    form.list.manager = '';
    form.list.manager_name = '';
  }
  if (desginerList.length === 0) {
    form.list.desginer = '';
    form.list.desginer_name = '';
  }
  state.loadingConfirm = false;
};

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      operateFun();
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

// 调用接口
const operateFun = () => {
  let obj = {
    task_id: props.id,
    approve_type: 3,
    remark: form.list.remarks,
  };
  if (props.data.checkStaus == 'ok') {
    obj = {
      task_id: props.id,
      approve_type: 2,
      design_id: form.list.desginer,
      customer_manager_id: form.list.manager,
      remark: form.list.remarks,
    };
  }
  $http.approveDesignRemind(obj).then((res) => {
    const { code, data } = res;
    state.loadingConfirm = false;
    if (code === 0) {
      Notify({
        type: 'success',
        message: '操作成功',
        onClose: () => {
          router.back();
        },
      });
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
