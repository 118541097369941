<!--
 * @Author: gest
 * @Date: 2023-09-14 16:58:05
 * @LastEditTime: 2023-12-15 16:09:11
 * @Description: v1.4.5 工期确认单详情
-->
<template>
  <div class="formBase">
    <div class="content mb_15" v-if="!props.info?.is_fine_construction == 1">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">原合同开工时间</div>
            <div class="">{{ form.info.contract_start_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">原合同完工时间</div>
            <div class="">{{ form.info.contract_end_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">
              {{
                props.info?.is_fine_construction == 1
                  ? '实际开工日期'
                  : '变更后实际开始时间'
              }}
            </div>
            <div class="">{{ form.info.start_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">
              {{
                props.info?.is_fine_construction == 1
                  ? '预计完工日期'
                  : '变更后预计结束时间'
              }}
            </div>
            <div class="">{{ form.info.end_time || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15" v-if="!props.info?.is_fine_construction == 1">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">工程变更原因</div>
          <div>{{ state.reason || '-' }}</div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>{{ form.info.remark || '-' }}</div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'info']);
const state = reactive({
  reason: '',
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
  if (form.info.basic_info) {
    let checkList = form.info.basic_info.filter((item) => {
      return item.checked;
    });
    let arr = checkList.map((item) => {
      if (item.checked) {
        return item.text;
      }
    });
    state.reason = arr.join('；');
  }
});
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
