<!--
 * @Author: Jessica
 * @Date: 2023-07-06 11:29:10
 * @LastEditTime: 2023-07-08 10:15:40
 * @Description: V1.4.1 升级选项报价详情
-->
<template>
  <div class="page-box">
    <TempQuotation :data="_DATA.tempOptions" />
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import TempQuotation from '@/components/design/TempQuotation'; //-设备/升级项报价模板
const router = useRouter();
const _DATA = reactive({
  loading: false,
  //-模板-参数
  tempOptions: {
    name: '升级选项报价单',
    class: 2,
    customer_id: router.currentRoute.value.query.id,
    //-type:view--只查看；其它情况需判断是否有编辑权限
    type: router.currentRoute.value.query.type ?? 'view',
  },
});
</script>

<style lang="less" scoped>
.page-box {
  padding: 0 0px;
  background: #f5f5f5;
  min-height: calc(100% - 46px);
}
</style>
