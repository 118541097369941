<!--
 * @Author: Jessica
 * @Date: 2022-04-30 09:00:31
 * @LastEditTime: 2023-02-21 16:56:26
 * @Description: 
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面日期
            </label>
            <Field
              v-model="form.list.face_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择见面日期"
              :rules="[
                {
                  required: true,
                  message: '请选择见面日期',
                },
              ]"
              @click="openDateType('face_time')"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面方式
            </label>
            <Field
              v-if="(state.dtype || state.taskDate) && form.list.face_mode"
              v-model="form.list.face_mode_name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面方式',
                },
              ]"
              placeholder="请选择见面方式"
            />
            <Field
              v-else
              v-model="form.list.face_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面方式',
                },
              ]"
              placeholder="请选择见面方式"
              @click="state.showPickerMode = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面类型
            </label>
            <Field
              v-if="(state.dtype || state.taskDate) && form.list.face_type"
              v-model="form.list.face_type_name"
              right-icon="arrow-down"
              disabled
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面类型',
                },
              ]"
              placeholder="请选择见面类型"
            />
            <Field
              v-else
              v-model="form.list.face_type_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面类型',
                },
              ]"
              placeholder="请选择见面类型"
              @click="state.showPicker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
          <div class="cell">
            <label class="form_title">附件</label>
            <FileUpload
              :data="form.list.attach_arr"
              @update:func="(list) => getFileList(list)"
            ></FileUpload>
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button
        class="btn"
        type="primary"
        size="large"
        @click.stop="btnSumbit('evaluate')"
        v-if="state.isManager == 1 && form.list.face_type == 68"
      >
        填写自评表
      </Button>
      <Button
        class="btn"
        type="primary"
        size="large"
        @click.stop="btnSumbit('sumbit')"
        v-else
      >
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.face_time = '';
          }
        "
      />
    </Popup>
    <!--picker -见面类型-->
    <Popup v-model:show="state.showPicker" position="bottom">
      <Picker
        :columns="state.meetList"
        :columns-field-names="customFieldName"
        @confirm="onConfirm"
        @cancel="state.showPicker = false"
      />
    </Popup>
    <!--picker -见面方式-->
    <Popup v-model:show="state.showPickerMode" position="bottom">
      <Picker
        :columns="state.modeList"
        :columns-field-names="customFieldName"
        @confirm="onConfirmMode"
        @cancel="state.showPickerMode = false"
      />
    </Popup>
    <!-- 客户经理自评表 -->
    <Popup
      v-model:show="state.evaluatePopup"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <EvaluateTemp
        v-if="state.evaluatePopup"
        :data="state.cacheEvaluate"
        @reviewClose="
          () => {
            state.evaluatePopup = false;
          }
        "
        ref="evaluateRef"
        @reviewOk="reviewOk"
      />
    </Popup>
    <!-- 通知客户 -->
    <ActionSheet
      v-model:show="state.noticeCustomer.show"
      class="dangerActionSheet"
      closeable
      title="通知客户"
      :close-on-click-overlay="false"
      @close="allokFunc()"
    >
      <div class="content">
        <div class="content_item">
          <div class="dangerForm">
            <Form ref="noticeFormRef">
              <div class="cell" style="margin-top: 20px">
                <Field
                  v-model="state.noticeCustomer.form.remark"
                  rows="5"
                  autosize
                  type="textarea"
                  maxlength="1000"
                  placeholder="请填写跟客户传达的内容"
                  :rules="[
                    {
                      required: true,
                      message: '请填写跟客户传达的内容',
                    },
                  ]"
                  show-word-limit
                />
              </div>
            </Form>
          </div>
        </div>
        <div class="content_button">
          <Button
            block
            loading-type="spinner"
            loading-text="通知客户"
            :loading="state.noticeCustomer.btnLoading"
            @click="noticeFunc()"
          >
            通知客户
          </Button>
        </div>
      </div>
    </ActionSheet>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
  ActionSheet,
  Dialog,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';
import FileUpload from '@/components/common/FileUpload';
import EvaluateTemp from '@/components/moreOperate/EvaluateTemp';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-全部待办类型
  dtype: router.currentRoute.value.query
    ? router.currentRoute.value.query.dtype
    : '',
  //-是否是客户经理
  isManager: router.currentRoute.value.query
    ? router.currentRoute.value.query.isManager
    : 0,
  //-见面类型
  faceType: router.currentRoute.value.query
    ? router.currentRoute.value.query.faceType
    : '',
  //-见面方式
  faceMode: router.currentRoute.value.query
    ? router.currentRoute.value.query.faceMode
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  minDate: new Date(),
  currentDate: new Date(),
  //-picker
  showPicker: false,
  meetList: [],
  showPickerMode: false,
  modeList: [],
  //-客户自评表
  evaluatePopup: false,
  //-客户自评缓存数据
  cacheEvaluate: { customer_name: router.currentRoute.value.query?.cname },
  //-v1.2.0 通知客户
  noticeCustomer: {
    show: false,
    btnLoading: false,
    form: {
      invite_id: '',
      remark: '',
    },
  },
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const evaluateRef = ref(null);
const noticeFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    //-待办来源Id
    source_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.sid
      : '',
    face_time: '',
    face_type: '',
    face_type_name: '',
    face_mode: '',
    face_mode_name: '',
    face_remark: '',
    attach_arr: [],
  },
});
//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数--begin
const getPublicParameter = async () => {
  await $http.getSaleOptionsApi().then((res) => {
    if (res.code === 0) {
      const list = res.data.customer.face_type;
      const listMode = res.data.customer.face_mode;
      state.meetList = res.data.customer.face_type;
      state.modeList = res.data.customer.face_mode;
      //-待办-选中见面类型切不可编辑
      if (state.faceType) {
        const typeList = list.filter((v) => v.id == state.faceType);
        form.list.face_type = state.faceType;
        form.list.face_type_name = typeList.length ? typeList[0]['name'] : '';
      }
      if (state.faceMode) {
        const typeModeList = listMode.filter((v) => v.id == state.faceMode);
        form.list.face_mode = state.faceMode;
        form.list.face_mode_name = typeModeList.length
          ? typeModeList[0]['name']
          : '';
      }
    } else {
      Toast(res.data);
    }
  });
};
//-获取参数--end

// @附件--begin
const getFileList = (file_list) => {
  form.list.attach_arr = file_list;
};
// @附件--end

//- v1.2.0 通知客户
const noticeFunc = () => {
  if (!noticeFormRef.value) return;
  noticeFormRef.value.validate().then(() => {
    state.noticeCustomer.btnLoading = true;
    $http
      .customerInviteCommentNoticeParam({ ...state.noticeCustomer.form })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          allokFunc();
          state.noticeCustomer.btnLoading = false;
        } else {
          Notify({ type: 'warning', message: data });
          state.noticeCustomer.btnLoading = false;
        }
      })
      .catch(() => {
        state.noticeCustomer.btnLoading = false;
      });
  });
};
//end

//-下一步操作
const btnSumbit = (type) => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (type == 'evaluate') {
        state.evaluatePopup = true;
      } else {
        operationApiFun({ ...form.list });
      }
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//-完成自评表提交
const reviewOk = (data) => {
  operationApiFun({ ...form.list, ...data });
};

const operationApiFun = (obj) => {
  if (!state.evaluatePopup) {
    state.loadingConfirm = true;
  }

  $http.confirmFaceOperation(obj).then((res) => {
    const { code, data } = res;
    if (state.evaluatePopup) {
      evaluateRef.value.getFailFun();
    } else {
      state.loadingConfirm = false;
    }
    if (code === 0) {
      if (state.isManager == 1 && form.list.face_type == 68) {
        //-- v1.2.0 通知客户
        state.noticeCustomer.form.invite_id = data.invite_id;
        Dialog.confirm({
          message: '是否通知客户确认？',
        })
          .then(() => {
            state.noticeCustomer.show = true;
            return;
          })
          .catch(() => {
            allokFunc();
          });
      } else {
        allokFunc();
      }
    } else {
      Notify({ type: 'warning', message: data });
    }
  });
};

const allokFunc = () => {
  Notify({
    type: 'success',
    message: '操作成功',
    onClose: () => {
      // 哪里来回哪里去
      router.back();
    },
  });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end

//-picker-选择项字段--begin
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value) => {
  if (value) {
    form.list.face_type = value.id;
    form.list.face_type_name = value.name;
  }
  state.showPicker = false;
};
const onConfirmMode = (value) => {
  if (value) {
    form.list.face_mode = value.id;
    form.list.face_mode_name = value.name;
  }
  state.showPickerMode = false;
};
//-picker-选择项字段--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
