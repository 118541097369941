/*
 * @Author: NanNan
 * @Date: 2022-04-12 14:51:55
 * @LastEditTime: 2024-01-10 09:52:39
 * @Description: router 集合
 */
import constructionRoute from './constructionRoute';
import clueRoute from './clueRoute';
import authRoute from './authRoute';
import targetDataRoute from './targetDataRoute';

//-销售模块
import SalePage from '@/views/sale/sale';
import SaleDetailPage from '@/views/sale/saleDetail';
import SaleAddMorePage from '@/components/sale/addMore';
import SaleAddExecutorPage from '@/components/sale/addExecutor';
import followRecord from '@/views/sale/followRecord';
import SalecashFlow from '@/views/sale/cashFlow';
import SaleAddReduce from '@/views/sale/addReduce';
import SaleSerectHide from '@/views/sale/saleSerectHide';
import customerNewInfo from '@/views/sale/customerNewInfo';
import completeTemp from '@/views/sale/completeTemp';

//-设计板块
import DesignPage from '@/views/design/design';
import DesignDetailPage from '@/views/design/designDetail';
import DesignSureTemp from '@/components/design/TempSure';
import DesignOverTemp from '@/components/design/TempOver';
import DesignNoticeTemp from '@/components/design/TempNotice';
import DesginOperation from '@/views/design/desginOperation'; //-设计启动

//-合同模块
import contractIndex from '@/views/contract/index';
import contractChangeView from '@/views/contract/contractChangeView';
import contractChangePurposeView from '@/views/contract/contractChangePurposeView'; //V1.4.7意向单详情
import contractSignPage from '@/views/contract/contractSign';
import contractDetailPage from '@/views/contract/contractDetail';
import contractCollectionPage from '@/views/contract/collection';
import contractOutCollectionPage from '@/views/contract/outCollection';
import prepaymentCollection from '@/views/contract/prepaymentCollection';
import contractReturnPage from '@/views/contract/return';
import contractOutReturnPage from '@/views/contract/outReturn';
import contractTransferPage from '@/views/contract/transfer';
import contractCancellation from '@/views/contract/contractCancellation';
import pdfIndex from '@/views/pdf';
import contractModifyInfo from '@/views/contract/contractModifyInfo'; //-合同变更信息
import contractRefundPage from '@/views/contract/refund'; //-返款
import adjustList from '@/views/contract/adjustList'; //款项的增减项列表
//-我的任务
import taskDayPage from '@/views/task/day';
import taskAllPage from '@/views/task/all';
import taskAllDetailPage from '@/views/task/allDetail';
import taskIndexPage from '@/views/task/checkIndex';
import taskViewPage from '@/views/task/checkView';
import taskNotice from '@/views/task/taskNotice';
//-全部客户
import SaleListPage from '@/views/sale/listPage';
//-施工模块
import ConstrucLog from '@/views/construction/log';
import ConstrucLogAdd from '@/components/construction/TempLogAdd';
import ConstrucVisitAdd from '@/components/construction/TempVisit';
import ConstrucVisitRegular from '@/components/construction/TempVisitRegular';
import ConstrucVisitCheck from '@/components/construction/TempVisitCheck';
import ConstrucSureTemp from '@/components/construction/TempSure';
import ConstrucNoticeTemp from '@/components/construction/TempNotice';
import ConstrucOverTemp from '@/components/construction/TempOver';
import ConstrucOperation from '@/views/construction/construcOperation'; //-确认开工
import ConstrucReapplyOpen from '@/views/construction/reApplyOpen'; //-申请交付派单

//-更多操作
import measureHouse from '@/views/moreOperate/measureHouse';
// V1.4.7标记指派
import markAssign from '@/views/moreOperate/markAssign';
import lost from '@/views/moreOperate/lost';
import up from '@/views/moreOperate/up';
import desginSend from '@/views/moreOperate/desginSend';
import netWorkerSend from '@/views/moreOperate/netWorkerSend';
import markerSend from '@/views/moreOperate/markerSend';
import managerSend from '@/views/moreOperate/managerSend';
import desginerSend from '@/views/moreOperate/desginerSend';
import designSerciverSend from '@/views/moreOperate/designSerciverSend';

import desginCenterSend from '@/views/moreOperate/desginCenterSend';
import effectDesignerSend from '@/views/moreOperate/effectDesignerSend';
import productDesignerSend from '@/views/moreOperate/productDesignerSend';
import constructionDesignerSend from '@/views/moreOperate/constructionDesignerSend';
import mainUserSend from '@/views/moreOperate/mainUserSend';
// import appointment from '@/views/moreOperate/appointment';
import documentary from '@/views/moreOperate/documentary';
import confirmFace from '@/views/moreOperate/confirmFace';
import applyMeetSite from '@/views/moreOperate/applyMeetSite';
import confirmMeetSite from '@/views/moreOperate/confirmMeetSite';
import deliverySend from '@/views/moreOperate/deliverySend';
import productManagerSend from '@/views/moreOperate/productManagerSend';
import servicerSend from '@/views/moreOperate/servicerSend';
import orderSend from '@/views/moreOperate/orderSend'; //-订单专员
import sceneSend from '@/views/moreOperate/sceneSend'; //-分派施工团队
import cancelTeam from '@/views/moreOperate/cancelTeam'; //-解除施工团队
import calcSchedule from '@/views/moreOperate/calcSchedule'; //-测算工期
import cancelProjectManager from '@/views/moreOperate/cancelProjectManager'; //-解除项目经理
//申请设计改派
import applyDesignReassignment from '@/views/moreOperate/applyDesignReassignment';
import commonRemarkTemp from '@/components/common/TempRemark';
import contractCancel from '@/views/moreOperate/contractCancel'; //--合同退单
import contractModify from '@/views/moreOperate/contractModify'; //-合同变更
import deliveryCenterSend from '@/views/moreOperate/deliveryCenterSend'; //-交付中心改派
import desginChange from '@/views/moreOperate/desginChange'; //-设计变更单
import assignDeliverCenter from '@/views/moreOperate/assignDeliverCenter'; //-指派交付中心
import siteStop from '@/views/moreOperate/siteStop'; //-工地停工
import siteRestart from '@/views/moreOperate/siteRestart'; //-工地重启

//消息列表
import messageList from '@/views/message/messageList';
import messageListAll from '@/views/message/messageListAll';
import DesignMemoTemp from '@/components/design/TempMemo';
import DesignPostponeTemp from '@/components/design/TempPostpone';
import DesignReportTemp from '@/components/design/TempReport';

//获客确认见面
import customerMeet from '@/views/moreOperate/customerMeet';
//派单确认见面
import dispatchMeet from '@/views/moreOperate/dispatchMeet';
//V1.3.3申请精装工地
import applyFineSite from '@/views/moreOperate/applyFineSite';
//V1.3.4见证件
import witnessViewConstruction from '@/views/witness/witnessViewConstruction';
import witnessViewDesign from '@/views/witness/witnessViewDesign';
//V1.3.4工地列表
import constructionList from '@/views/construction/constructionList';
//V1.3.6工地主材报价
import mainQuotation from '@/views/productOrder/mainQuotation';
//V1.3.7工地主材订单
import mainOrder from '@/views/construction/mainOrder';
import mainOrderDetail from '@/views/order/mainOrder/mainOrderDetail';
import mainOrderOperate from '@/views/order/mainOrder/mainOrderOperate';
import mainOrderOperateProduct from '@/views/order/mainOrder/mainOrderOperateProduct';
//V1.3.8工地自购产品
import mainSelfOrder from '@/views/construction/mainSelfOrder';
import mainSelfOrderDetail from '@/views/order/mainSelfOrder/mainSelfOrderDetail';
import addMainSelfOrder from '@/views/order/mainSelfOrder/addMainSelfOrder';
import mainSelfOrderOperate from '@/views/order/mainSelfOrder/mainSelfOrderOperate';
import mainSelfOrderOperateProduct from '@/views/order/mainSelfOrder/mainSelfOrderOperateProduct';
//V1.4.1更多操作-施工报价
import desginQuotation from '@/views/design/desginQuotation';
//V1.4.1更多操作-施工报价-设备电报价单详情
import deviceQuotationInfo from '@/views/design/deviceQuotationInfo';
//V1.4.1更多操作-施工报价-设备电报价单编辑
import deviceQuotationEdit from '@/views/design/deviceQuotationEdit';
//V1.4.1更多操作-施工报价-升级选项报价详情
import upgradeQuotationInfo from '@/views/design/upgradeQuotationInfo';
//V1.4.1更多操作-施工报价-升级选项报价编辑
import upgradeQuotationEdit from '@/views/design/upgradeQuotationEdit';

//V1.3.8安装时间安排表
import installation from '@/views/order/mainOrder//installation';
//1.4.3客户服务记录
import followInfoShare from '@/views/share/followInfoShare';
//1.4.6跟进分享
import followInfoShareImg from '@/views/sale/followInfoShareImg';
//1.4.7工程变更意向单
import contractChangePurpose from '@/views/contract/contractChangePurpose';
//1.4.7工程变更确认单
import contractChange from '@/views/contract/contractChange';
//1.4.7产品变更意向单
import productChangePurpose from '@/views/contract/productChangePurpose';
//1.4.产品变更确认单
import productChange from '@/views/contract/productChange';

//1.5.0 图审-分配服务人员
import assignmentPerson from '@/views/task/assignmentPerson';
//1.5.1 分摊业绩
import sharePerformance from '@/views/design/sharePerformance';
//1.5.2 交付产品业绩待办
import completeConstruction from '@/views/task/completeConstruction';
//1.5.2 渠道费申请
import channelFees from '@/views/sale/channelFees';
// V1.5.3 完善派单信息
import perfectDispatch from '@/views/construction/perfectDispatch';

const routeMap = [
  {
    path: '/sale',
    component: SalePage,
    meta: {
      name: '客户信息',
      bar: false,
    },
  },
  {
    path: '/customerNewInfo',
    component: customerNewInfo,
    meta: {
      name: '新增客户',
      bar: false,
    },
  },
  {
    path: '/completeTemp',
    component: completeTemp,
    meta: {
      name: '新增客户',
      bar: true,
    },
  },
  {
    path: '/saleDetail',
    component: SaleDetailPage,
    meta: {
      name: '客户资料',
      bar: true,
    },
  },
  {
    path: '/sale/addMore',
    component: SaleAddMorePage,
    meta: {
      name: '补充客户信息',
      bar: true,
    },
  },
  {
    path: '/sale/addExecutor',
    component: SaleAddExecutorPage,
    meta: {
      name: '补充执行人信息',
      bar: true,
    },
  },
  {
    path: '/contractIndex',
    component: contractIndex,
    meta: {
      name: '签约',
      bar: true,
    },
  },
  {
    path: '/contractChange',
    component: contractChange,
    meta: {
      name: '工程变更确认单',
      bar: true,
    },
  },
  {
    path: '/contractChangePurpose',
    component: contractChangePurpose,
    meta: {
      name: '工程变更意向单',
      bar: true,
    },
  },
  {
    path: '/productChange',
    component: productChange,
    meta: {
      name: '产品变更确认单',
      bar: true,
    },
  },
  {
    path: '/productChangePurpose',
    component: productChangePurpose,
    meta: {
      name: '产品变更意向单',
      bar: true,
    },
  },
  {
    path: '/contractChangeView',
    component: contractChangeView,
    meta: {
      name: '变更确认单详情',
      bar: true,
    },
  },
  {
    path: '/contractChangePurposeView',
    component: contractChangePurposeView,
    meta: {
      name: '变更意向单详情',
      bar: true,
    },
  },

  {
    path: '/adjustList',
    component: adjustList,
    meta: {
      name: '变更确认单',
      bar: true,
    },
  },

  {
    path: '/contractModifyInfo',
    component: contractModifyInfo,
    meta: {
      name: '变更合同信息',
      bar: true,
    },
  },
  {
    path: '/contractSign',
    component: contractSignPage,
    meta: {
      name: '合同签约',
      bar: true,
    },
  },
  {
    path: '/contractDetail',
    component: contractDetailPage,
    meta: {
      name: '合同详情',
      bar: true,
    },
  },
  {
    path: '/followRecord',
    component: followRecord,
    meta: {
      name: '跟进记录',
      bar: true,
    },
  },
  {
    path: '/cashFlow',
    component: SalecashFlow,
    meta: {
      name: '收款流水',
      bar: true,
    },
  },
  {
    path: '/addReduce',
    component: SaleAddReduce,
    meta: {
      name: '合同增减项',
      bar: true,
    },
  },
  {
    path: '/taskDay',
    component: taskDayPage,
    meta: {
      name: '客户待办事项',
      bar: true,
    },
  },
  {
    path: '/taskAll',
    component: taskAllPage,
    meta: {
      name: '待办事项',
      bar: true,
    },
  },
  {
    path: '/taskAllDetail',
    component: taskAllDetailPage,
    meta: {
      name: '待办事项',
      bar: false,
    },
  },
  {
    path: '/taskNotice',
    component: taskNotice,
    meta: {
      name: '任务通知',
      bar: false,
    },
  },

  {
    path: '/taskIndex',
    component: taskIndexPage,
    meta: {
      name: '审批',
      bar: true,
    },
  },
  {
    path: '/taskView',
    component: taskViewPage,
    meta: {
      name: '审批操作',
      bar: false,
    },
  },
  {
    path: '/Ccollection',
    component: contractCollectionPage,
    meta: {
      name: '合同收款',
      bar: true,
    },
  },
  {
    path: '/CoutCollection',
    component: contractOutCollectionPage,
    meta: {
      name: '合同外收款',
      bar: true,
    },
  },
  {
    path: '/Creturn',
    component: contractReturnPage,
    meta: {
      name: '合同退款',
      bar: true,
    },
  },
  {
    path: '/CoutReturn',
    component: contractOutReturnPage,
    meta: {
      name: '合同外退款',
      bar: true,
    },
  },
  {
    path: '/Ctransfer',
    component: contractTransferPage,
    meta: {
      name: '转款',
      bar: true,
    },
  },
  {
    path: '/prepayment',
    component: prepaymentCollection,
    meta: {
      name: '产品预收款',
      bar: true,
    },
  },
  {
    path: '/Ccancellation',
    component: contractCancellation,
    meta: {
      name: '作废合同详情',
      bar: true,
    },
  },
  {
    path: '/measureHouse',
    component: measureHouse,
    meta: {
      name: '量房',
      bar: true,
    },
  },
  {
    path: '/markAssign',
    component: markAssign,
    meta: {
      name: '标记指派',
      bar: true,
    },
  },
  {
    path: '/lost',
    component: lost,
    meta: {
      name: '确认死单',
      bar: true,
    },
  },
  {
    path: '/up',
    component: up,
    meta: {
      name: '暂停跟进',
      bar: true,
    },
  },
  {
    path: '/desginSend',
    component: desginSend,
    meta: {
      name: '申请设计派单',
      bar: true,
    },
  },
  {
    path: '/netWorkerSend',
    component: netWorkerSend,
    meta: {
      name: '分配网销专员/主管',
      bar: true,
    },
  },
  {
    path: '/markerSend',
    component: markerSend,
    meta: {
      name: '分配市拓专员',
      bar: true,
    },
  },
  {
    path: '/managerSend',
    component: managerSend,
    meta: {
      name: '分配客户经理',
      bar: true,
    },
  },
  {
    path: '/desginerSend',
    component: desginerSend,
    meta: {
      name: '分配设计师',
      bar: true,
    },
  },
  {
    path: '/designSerciverSend',
    component: designSerciverSend,
    meta: {
      name: '分配服务人员',
      bar: true,
    },
  },
  {
    path: '/desginCenterSend',
    component: desginCenterSend,
    meta: {
      name: '分配设计中心',
      bar: true,
    },
  },
  {
    path: '/effectDesignerSend',
    component: effectDesignerSend,
    meta: {
      name: '分配效果设计师',
      bar: true,
    },
  },
  {
    path: '/productDesignerSend',
    component: productDesignerSend,
    meta: {
      name: '分配产品设计师',
      bar: true,
    },
  },
  {
    path: '/constructionDesignerSend',
    component: constructionDesignerSend,
    meta: {
      name: '分配施工图深化设计师',
      bar: true,
    },
  },
  {
    path: '/mainUserSend',
    component: mainUserSend,
    meta: {
      name: '分配水电工程师',
      bar: true,
    },
  },
  {
    path: '/documentary',
    component: documentary,
    meta: {
      name: '跟进',
      bar: false,
    },
  },
  {
    path: '/confirmFace',
    component: confirmFace,
    meta: {
      name: '确认见面',
      bar: true,
    },
  },
  {
    path: '/applyMeetSite',
    component: applyMeetSite,
    meta: {
      name: '申请看工地',
      bar: true,
    },
  },
  {
    path: '/confirmMeetSite',
    component: confirmMeetSite,
    meta: {
      name: '确认见工地',
      bar: true,
    },
  },
  {
    path: '/deliverySend',
    component: deliverySend,
    meta: {
      name: '申请交付派单',
      bar: true,
    },
  },
  {
    path: '/productManagerSend',
    component: productManagerSend,
    meta: {
      name: '分配项目经理',
      bar: true,
    },
  },
  {
    path: '/servicerSend',
    component: servicerSend,
    meta: {
      name: '分配客服专员',
      bar: true,
    },
  },
  {
    path: '/orderSend',
    component: orderSend,
    meta: {
      name: '分配订单专员',
      bar: true,
    },
  },
  {
    path: '/sceneSend',
    component: sceneSend,
    meta: {
      name: '分派施工团队',
      bar: true,
    },
  },
  {
    path: '/cancelTeam',
    component: cancelTeam,
    meta: {
      name: '解除施工团队',
      bar: true,
    },
  },
  {
    path: '/cancelProjectManager',
    component: cancelProjectManager,
    meta: {
      name: '解除项目经理',
      bar: true,
    },
  },
  {
    path: '/calcSchedule',
    component: calcSchedule,
    meta: {
      name: '测算工期',
      bar: true,
    },
  },

  // v1.3.5 全局更改 - 变更设计团队
  {
    path: '/applyDesignReassignment',
    component: applyDesignReassignment,
    meta: {
      name: '变更设计团队',
      bar: true,
    },
  },

  // 只包含备注的弹窗
  {
    path: '/commonRemark',
    component: commonRemarkTemp,
    meta: {
      name: '取消见面',
      bar: false,
    },
  },
  {
    path: '/contractCancel',
    component: contractCancel,
    meta: {
      name: '合同退单',
      bar: true,
    },
  },
  {
    path: '/contractModify',
    component: contractModify,
    meta: {
      name: '合同变更',
      bar: true,
    },
  },
  {
    path: '/refundPage',
    component: contractRefundPage,
    meta: {
      name: '返款',
      bar: true,
    },
  },
  {
    path: '/sale/saleSerectHide',
    component: SaleSerectHide,
    meta: {
      name: '客户信息编辑',
      bar: true,
    },
  },
  {
    path: '/deliveryCenterSend',
    component: deliveryCenterSend,
    meta: {
      name: '交付中心改派',
      bar: true,
    },
  },
  {
    path: '/assignDeliverCenter',
    component: assignDeliverCenter,
    meta: {
      name: '指派交付中心',
      bar: true,
    },
  },

  /**
   * v0.2.2 设计板块
   */
  // 设计启动
  {
    path: '/desginOperation',
    component: DesginOperation,
    meta: {
      name: '设计启动',
      bar: true,
    },
  },
  // 设计节点列表
  {
    path: '/design',
    component: DesignPage,
    meta: {
      name: '设计节点',
      bar: true,
    },
  },
  {
    path: '/designDetail',
    component: DesignDetailPage,
    meta: {
      name: '设计详情',
      bar: true,
    },
  },
  // 设计节点列表 - 标记完成
  {
    path: '/designOver',
    component: DesignOverTemp,
    meta: {
      name: '标记完成',
      bar: true,
    },
  },
  // 设计节点列表 - 通知验收
  {
    path: '/designNotice',
    component: DesignNoticeTemp,
    meta: {
      name: '通知验收',
      bar: true,
    },
  },
  // 设计节点列表 - 确认验收
  {
    path: '/designSure',
    component: DesignSureTemp,
    meta: {
      name: '确认验收',
      bar: true,
    },
  },
  /**
   * v0.3.0 企微入口 - 全部客户列表
   */
  {
    path: '/listPage',
    component: SaleListPage,
    meta: {
      name: '全部客户',
      bar: true,
      right: {
        show: true,
        path: '/customerNewInfo',
        auth: '6',
      },
    },
    beforeEnter: (to, from) => {
      if (from.meta.name) {
        if (from.meta.name == '授权') {
          to.meta.firstPage = true;
        } else {
          to.meta.firstPage = false;
        }
      } else {
        to.meta.firstPage = true;
      }
    },
  },
  /**
   * v0.3.0 施工模块
   */
  // 确认开工
  {
    path: '/construcOperation',
    component: ConstrucOperation,
    meta: {
      name: '确认开工',
      bar: true,
    },
  },
  // 申请交付派单
  {
    path: '/construcReapplyOpen',
    component: ConstrucReapplyOpen,
    meta: {
      name: '申请交付派单',
      bar: true,
    },
  },
  // 施工日志
  {
    path: '/construc/log',
    component: ConstrucLog,
    meta: {
      name: '施工日志',
      bar: true,
    },
  },
  // 施工日志 - 新增、编辑
  {
    path: '/construc/logAdd',
    component: ConstrucLogAdd,
    meta: {
      name: '写施工日志',
      bar: true,
    },
  },
  // 施工 - 新增回访
  {
    path: '/construc/visitAdd',
    component: ConstrucVisitAdd,
    meta: {
      name: '新增回访',
      bar: true,
    },
  },
  // 施工 - 定期回访
  {
    path: '/construc/visitRegular',
    component: ConstrucVisitRegular,
    meta: {
      name: '定期回访',
      bar: true,
    },
  },
  // 施工 - 验收回访
  {
    path: '/construc/visitCheck',
    component: ConstrucVisitCheck,
    meta: {
      name: '验收回访',
      bar: true,
    },
  },
  // 施工 - 通知验收
  {
    path: '/construc/notice',
    component: ConstrucNoticeTemp,
    meta: {
      name: '通知验收',
      bar: true,
    },
  },
  // 施工 - 确认验收
  {
    path: '/construc/sure',
    component: ConstrucSureTemp,
    meta: {
      name: '确认验收',
      bar: true,
    },
  },
  // 施工 - 标记完成
  {
    path: '/construc/over',
    component: ConstrucOverTemp,
    meta: {
      name: '标记完成',
      bar: true,
    },
  },
  // v1.2.1 洽谈备忘录
  {
    path: '/design/memo',
    component: DesignMemoTemp,
    meta: {
      name: '创建项目洽谈备忘录',
      bar: true,
    },
  },
  // v1.2.1 延期单
  {
    path: '/design/postpone',
    component: DesignPostponeTemp,
    meta: {
      name: '设计工期延期单',
      bar: true,
    },
  },
  // v1.2.1 报备单
  {
    path: '/design/report',
    component: DesignReportTemp,
    meta: {
      name: '创建客户未见报备单',
      bar: true,
    },
  },
  {
    path: '/pdf',
    component: pdfIndex,
    meta: {
      name: '电子合同',
      bar: true,
    },
  },
  {
    path: '/message',
    component: messageList,
    meta: {
      name: '消息通知',
      bar: true,
    },
  },
  {
    path: '/messageAll',
    component: messageListAll,
    meta: {
      name: '消息通知',
      bar: true,
    },
  },
  // 获客确认见面
  {
    path: '/customerMeet',
    component: customerMeet,
    meta: {
      name: '获客确认见面',
      bar: true,
    },
  },
  // 派单确认见面
  {
    path: '/dispatchMeet',
    component: dispatchMeet,
    meta: {
      name: '首见跟进',
      bar: false,
    },
  },
  // 申请精装工地
  {
    path: '/applyFineSite',
    component: applyFineSite,
    meta: {
      name: '申请精装工地',
      bar: true,
    },
  },
  {
    path: '/witnessViewConstruction',
    component: witnessViewConstruction,
    meta: {
      name: '见证件',
      bar: true,
    },
  },
  {
    path: '/witnessViewDesign',
    component: witnessViewDesign,
    meta: {
      name: '见证件',
      bar: true,
    },
  },
  {
    path: '/constructionList',
    component: constructionList,
    meta: {
      name: '全部工地',
      bar: true,
    },
  },
  {
    path: '/mainQuotation',
    component: mainQuotation,
    meta: {
      name: '主材报价',
      bar: true,
    },
  },
  {
    path: '/mainOrder',
    component: mainOrder,
    meta: {
      name: '主材订单',
      bar: true,
    },
  },

  {
    path: '/mainSelfOrder',
    component: mainSelfOrder,
    meta: {
      name: '自购产品',
      bar: true,
    },
  },

  {
    path: '/addMainSelfOrder',
    component: addMainSelfOrder,
    meta: {
      name: '新增自购产品',
      bar: false,
    },
  },
  {
    path: '/installation',
    component: installation,
    meta: {
      name: '安装计划',
      bar: true,
    },
  },
  // v1.4.1更多操作-施工报价
  {
    path: '/desginQuotation',
    component: desginQuotation,
    meta: {
      name: '施工报价',
      bar: true,
    },
  },

  {
    path: '/mainOrderDetail',
    component: mainOrderDetail,
    meta: {
      name: '订单详情',
      bar: true,
    },
  },
  {
    path: '/mainSelfOrderDetail',
    component: mainSelfOrderDetail,
    meta: {
      name: '订单详情',
      bar: true,
    },
  },
  {
    path: '/mainOrderOperate',
    component: mainOrderOperate,
    meta: {
      name: '订单操作',
      bar: false,
    },
  },
  {
    path: '/mainOrderOperateProduct',
    component: mainOrderOperateProduct,
    meta: {
      name: '订单操作',
      bar: false,
    },
  },

  {
    path: '/mainSelfOrderOperate',
    component: mainSelfOrderOperate,
    meta: {
      name: '订单操作',
      bar: false,
    },
  },
  {
    path: '/mainSelfOrderOperateProduct',
    component: mainSelfOrderOperateProduct,
    meta: {
      name: '订单操作',
      bar: false,
    },
  },
  //V1.4.1更多操作-施工报价-设备电报价单详情
  {
    path: '/deviceQuotationInfo',
    component: deviceQuotationInfo,
    meta: {
      name: '设备电报价单',
      bar: true,
    },
  },
  //V1.4.1更多操作-施工报价-设备电报价单编辑
  {
    path: '/deviceQuotationEdit',
    component: deviceQuotationEdit,
    meta: {
      name: '编辑设备电报价单',
      bar: true,
    },
  },
  //V1.4.1更多操作-施工报价-升级选项报价详情
  {
    path: '/upgradeQuotationInfo',
    component: upgradeQuotationInfo,
    meta: {
      name: '升级选项报价单',
      bar: true,
    },
  },
  //V1.4.1更多操作-施工报价-升级选项报价编辑
  {
    path: '/upgradeQuotationEdit',
    component: upgradeQuotationEdit,
    meta: {
      name: '编辑升级选项报价单',
      bar: true,
    },
  },
  //V1.4.2更多操作-设计变更单
  {
    path: '/desginChange',
    component: desginChange,
    meta: {
      name: '设计变更单',
      bar: true,
    },
  },
  //V1.4.2更多操作-工地停工
  {
    path: '/siteStop',
    component: siteStop,
    meta: {
      name: '工地停工',
      bar: true,
    },
  },
  //V1.4.2更多操作-工地重启
  {
    path: '/siteRestart',
    component: siteRestart,
    meta: {
      name: '工地重启',
      bar: true,
    },
  },
  {
    path: '/followInfoShare',
    component: followInfoShare,
    meta: {
      name: '客户服务记录',
      bar: false,
    },
  },
  {
    path: '/followInfoShareImg',
    component: followInfoShareImg,
    meta: {
      name: '跟进分享',
      bar: true,
    },
  },
  {
    path: '/assignmentPerson',
    component: assignmentPerson,
    meta: {
      name: '',
      bar: false,
    },
  },
  {
    path: '/sharePerformance',
    component: sharePerformance,
    meta: {
      name: '分摊业绩申请',
      bar: true,
    },
  },
  {
    path: '/channelFees',
    component: channelFees,
    meta: {
      name: '渠道费申请',
      bar: true,
    },
  },
  {
    path: '/completeConstruction',
    component: completeConstruction,
    meta: {
      name: '交付产品业绩',
      bar: true,
    },
  },
  {
    path: '/perfectDispatch',
    component: perfectDispatch,
    meta: {
      name: '完善派单信息',
      bar: true,
    },
  },

  ...authRoute,
  ...constructionRoute,
  ...clueRoute,
  ...targetDataRoute,
];

export default routeMap;
