<!--
 * @Author: gest
 * @Date: 2022-08-20 15:11:00
 * @LastEditTime: 2022-08-31 10:30:53
 * @Description: 任务通知-设计改派
-->
<template>
  <NavBar
    safe-area-inset-top
    title="设计改派"
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase designReassignment">
      <div class="cellInfo">
        <div
          class="item"
          v-for="(val, key) in state.detail.info_list"
          :key="val"
        >
          <div class="left">{{ key }}</div>
          <span class="right">
            {{ val }}
          </span>
        </div>
      </div>
      <Form class="form_main" :disabled="true">
        <CellGroup class="form_cell">
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              改派原因
            </label>
            <Field
              v-model="state.detail.reassignment_remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写..."
              :rules="[
                {
                  required: true,
                  message: '请填写改派原因',
                },
              ]"
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              接单设计中心
            </label>
            <Field
              v-model="state.detail.new_design_center_name"
              readonly
              name="picker"
              placeholder="请选择接单设计中心"
              :rules="[
                {
                  required: true,
                  message: '请选择接单设计中心',
                },
              ]"
            />
          </div>
        </CellGroup>
        <template
          v-if="
            state.detail.contract_progress &&
            state.detail.contract_progress.length > 0
          "
        >
          <div class="design_list">
            <div class="title">改派后设计进度</div>
            <div
              class="list"
              v-for="(item, index) in state.detail.contract_progress"
              :key="index"
            >
              <div class="item">
                <div class="left">合同编号：</div>
                <div class="right">
                  {{ item.contract_no ? item.contract_no : '-' }}
                </div>
              </div>
              <div class="item">
                <div class="left">合同状态：</div>
                <div class="right">
                  {{
                    item.contract_status_name ? item.contract_status_name : '-'
                  }}
                </div>
              </div>
              <div class="item">
                <div class="left">设计编号：</div>
                <div class="right">
                  {{ item.design_no ? item.design_no : '-' }}
                </div>
              </div>
              <div class="item">
                <div class="left">目前进度：</div>
                <div class="right">
                  {{ item.progress_name ? item.progress_name : '-' }}
                </div>
              </div>
              <div v-if="item.design_no">
                <div class="info">改派后阶段调整</div>
                <Field
                  v-model="item.design_reassignment_stage_name"
                  readonly
                  name="picker"
                  placeholder="请选择"
                />
              </div>
            </div>
          </div>
        </template>
      </Form>
    </div>
    <div class="btnSumbit" v-if="state.todo_status != 2">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        知道了
      </Button>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Notify,
  Overlay,
  Loading,
  NavBar,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  todo_id: router.currentRoute.value.query
    ? router.currentRoute.value.query.id
    : '',
  checkId: router.currentRoute.value.query
    ? router.currentRoute.value.query.source_id
    : '',
  todo_status: router.currentRoute.value.query
    ? router.currentRoute.value.query.status
    : 2,
  detail: {},
});

//-获取参数--begin
onBeforeMount(() => {
  getDetail();
});

//-获取参数
const getDetail = async () => {
  state.loadingConfirm = true;
  await $http
    .getTaskApplyInfo({
      id: state.checkId,
    })
    .then((res) => {
      state.loadingConfirm = false;
      if (res.code === 0) {
        state.detail = res.data;
      } else {
        Toast(res.data);
      }
    });
};
//-获取参数--end

//-下一步操作
const btnSumbit = () => {
  state.loadingConfirm = true;
  $http
    .designReassignmentNotice({
      todo_id: state.todo_id,
    })
    .then((res) => {
      const { code, data } = res;
      state.loadingConfirm = false;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            router.back();
          },
        });
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};

//--基本信息表单-----end----------------
//-返回
const onClosePop = () => {
  router.back();
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
@import '@/assets/styles/moreOperate/designReassignment.less';
.page-wrap {
  padding: 0;
}
</style>
