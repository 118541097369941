<!--
 * @Author: NanNan
 * @Date: 2024-05-10 14:24:10
 * @LastEditTime: 2024-05-29 14:14:15
 * @Description: v1.6.0新版本 获客-协议
 * v1.6.1 smart 接口返还目标为“”，前端展示“-”
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <!-- 一、头部 -->
        <div class="data_card_top border_bottom">
          <div class="title">协议</div>
          <!-- 协议目标/当年完成/完成率 -->
          <Row style="margin-bottom: 16px">
            <Col span="8">
              <div class="grey align_center">协议目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info?.target === '' ? '-' : _DATA.info?.target }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">完成</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{ _DATA.info?.actual === '' ? '-' : _DATA.info?.actual }}
                </span>
              </div>
              <div
                class="flex_line flex_line_center"
                v-if="_DATA.info?.actual > _DATA.info?.target"
              >
                <span class="bold green">{{ _DATA.info?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/up.png')"
                  alt="down"
                />
              </div>
              <div
                class="flex_line flex_line_center"
                v-else-if="_DATA.info?.actual < _DATA.info?.target"
              >
                <span class="bold orange">{{ _DATA.info?.gap }}</span>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/down.png')"
                  alt="down"
                />
              </div>
              <div class="flex_line flex_line_center" v-else>
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">见面率</div>
              <!-- 差值 -->
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.meet_rate === ''
                      ? '-'
                      : _DATA.info?.meet_rate + '%'
                  }}
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span="8">
              <div class="grey align_center">成功率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.sign_rate === ''
                      ? '-'
                      : _DATA.info?.sign_rate + '%'
                  }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">线-签率</div>
              <div class="flex_line_center">
                <span class="size17 bold">
                  {{
                    _DATA.info?.clue_sign_rate === ''
                      ? '-'
                      : _DATA.info?.clue_sign_rate + '%'
                  }}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <!-- 二、内容 -->
        <div class="data_card_table_bottom p0" v-if="_DATA.open">
          <!-- 1标题  -->
          <Row class="mb_15 p_l15_r10">
            <Col span="6">
              <div class="grey pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="10" style="position: relative">
              <div class="chart_pos">
                <!-- 左侧文案位置-不一定存在 -->
                <div class="chart_left" v-if="props.data.depId"></div>
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 1,
                    num: _DATA.middle_value,
                    list: _DATA.line_values,
                  }"
                ></chartCoverTop>
                <!-- 右侧总数值-不一定存在 -->
                <div class="chart_right"></div>
              </div>
            </Col>
            <!-- 进度图标题 end-->
            <Col span="4">
              <div class="grey align_center">见面率</div>
            </Col>
            <Col span="4">
              <div class="grey align_center">成功率/</div>
              <div class="grey align_center">线-签率</div>
            </Col>
          </Row>
          <!-- 2循环list -->
          <div
            class="data_card_thred_sub_list"
            v-for="(parentItem, parentIndex) in _DATA.list"
            :key="parentIndex"
          >
            <Row
              class="p_l15_r10"
              style="align-items: center; height: 100%"
              @click.stop="toggleAccordion(parentIndex, _DATA.activeIndex)"
            >
              <Col span="6">
                <div class="size13" style="display: flex; align-items: center">
                  <template v-if="parentItem?.sub?.length > 0">
                    <Icon
                      name="play"
                      :style="
                        parentItem?.expanded
                          ? 'transform: rotate(90deg)'
                          : 'transform: rotate(0deg)'
                      "
                      class="arrow"
                    />
                  </template>
                  <p>
                    {{ parentItem.short_name }}
                  </p>
                </div>
              </Col>
              <Col span="10" class="chart_item">
                <!-- 进度图内容 -->
                <div style="display: flex">
                  <chartJD
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      actual: parentItem.actual,
                      target: parentItem.target
                        ? parentItem.target
                        : parentItem.actual,
                    }"
                  ></chartJD>
                  <div class="chart_right">
                    {{ parentItem.target === '' ? '-' : parentItem?.target }}
                  </div>
                </div>

                <!-- 竖线 begin-->
                <div class="chart_pos">
                  <div class="chart_left" v-if="props.data.depId"></div>
                  <chartCoverLine
                    :data="{
                      type: 1,
                      list: _DATA.line_values,
                      num: _DATA.middle_value,
                    }"
                  ></chartCoverLine>
                  <div class="chart_right"></div>
                </div>
                <!-- 竖线 end-->
              </Col>

              <!-- 见面率 -->
              <Col span="4">
                <div>
                  <span class="lineheight16 align_center">
                    {{
                      parentItem.meet_rate === ''
                        ? '-'
                        : parentItem.meet_rate + '%'
                    }}
                  </span>
                </div>
              </Col>
              <!-- 成功率/线-签率 -->
              <Col span="4">
                <div>
                  <span class="lineheight16 align_center">
                    {{
                      parentItem.sign_rate === ''
                        ? '-'
                        : parentItem.sign_rate + '%'
                    }}
                  </span>
                </div>
                <div>
                  <span class="lineheight16 align_center">
                    {{
                      parentItem.clue_sign_rate === ''
                        ? '-'
                        : parentItem.clue_sign_rate + '%'
                    }}
                  </span>
                </div>
              </Col>
            </Row>
            <Transition name="thred-fade">
              <TempAgreement
                v-if="parentItem.sub && parentItem?.expanded"
                :data="parentItem"
                :index="parentIndex"
              />
            </Transition>
          </div>
          <!-- 图例 -->
          <div class="p_15 flex_center">
            <p class="box_8 grey_bg"></p>
            <p class="ml_5">目标</p>
            <p class="box_8 red_bg ml_30"></p>
            <p class="ml_5">达标</p>
            <p class="box_8 green_bg ml_30"></p>
            <p class="ml_5">未达标</p>
            <p class="box_8 red2_bg ml_30"></p>
            <p class="ml_5">超额</p>
          </div>
        </div>
      </Skeleton>
    </div>
    <!-- 模块：见面-协议时间 -->
    <MeetAgreementTime v-if="_DATA.open" :data="props.data" />
    <!-- 展开收起 -->
    <div
      v-if="!_DATA.loading"
      class="expend-new"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
      :class="_DATA.open ? 'grey' : 'white'"
    >
      <span>{{ _DATA.open ? '收起' : '展开查看详情' }}</span>
      <Icon
        name="play"
        style="transform: rotate(90deg)"
        size="14"
        :style="
          !_DATA.open ? 'transform: rotate(90deg)' : 'transform: rotate(-90deg)'
        "
      />
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import {
  Row,
  Col,
  // Overlay,
  // Loading,
  Icon,
  Notify,
  Skeleton,
} from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';

import MeetAgreementTime from '@/components/targetData/thredData/meetAgreementTime';
import TempAgreement from '@/components/targetData/thredData/collapseItem/TempAgreement';

const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: props.data.depId ? 1 : 1, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  maxCount: 1000, //超过则显示展开收起
  open: false,
  line_values: [],
  middle_value: '',

  activeIndex: [],
  activeChildIndex: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depClueContract;
  let params = {
    year: props.data.yearId,
  };
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};

/**
 * 操作
 */
// 展开收起-操作
const toggleAccordion = (subIndex) => {
  // 切换当前点击子项的展开状态
  _DATA.list[subIndex].expanded = !_DATA.list[subIndex].expanded;
  console.log(_DATA.list[subIndex]);
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
