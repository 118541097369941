<!--
 * @Author: Jessica
 * @Date: 2023-06-17 16:36:06
 * @LastEditTime: 2024-05-25 15:55:33
 * @Description: 目标数据-线索数
 * v1.4.3 smart 数据更改
-->
<template>
  <!-- 线索数 -->
  <div class="main-data" @click.stop="redictFun">
    <div class="warpper">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading || props.loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_1.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">线索数 {{ detail.total?.actual }}</div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>
                  <em class="pl-3">{{
                    detail.total?.target === '' ? '-' : detail.total?.target
                  }}</em>
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap" v-if="detail.total?.gap !== ''">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail.total?.target ?? 0) <
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c1">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail.total?.target ?? 0) >
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c2">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">完成率</span>
                <span class="font-size12 pl-3">
                  {{
                    detail.total?.rate === '' ? '-' : detail.total?.rate + '%'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 集团 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">集团</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.group?.target === '' ? '-' : detail.group?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="7">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.group?.actual ?? '-' }}</em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.group?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.group?.target ?? 0) <
                          parseFloat(detail.group?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.group?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.group?.target ?? 0) >
                          parseFloat(detail.group?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.group?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">
                        {{
                          detail.group?.rate === ''
                            ? '-'
                            : detail.group?.rate + '%'
                        }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 市场 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="7">
                <div class="col">
                  <p class="t">市场</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.market?.target === ''
                          ? '-'
                          : detail.market?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="7">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.market?.actual ?? '-' }}</em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.market?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.market?.target ?? 0) <
                          parseFloat(detail.market?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.market?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.market?.target ?? 0) >
                          parseFloat(detail.market?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.market?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">
                        {{
                          detail.market?.rate === ''
                            ? '-'
                            : detail.market?.rate + '%'
                        }}
                      </em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, watch, defineProps } from 'vue';
import { Notify, Skeleton, Row, Col } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http.targetStatistic_homeClue({ year: props.data.yearId }).then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      detail.value = data;
    } else {
      loading.value = false;
      detail.value = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 1, pos: 1 },
  });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
