<!--
 * @Author: NanNan
 * @Date: 2022-04-23 11:02:57
 * @LastEditTime: 2023-07-22 16:27:22
 * @Description: 首页 - 输入客户手机号、添加客户信息
-->
<template>
  <div class="index-page">
    <svg class="icon-small1" aria-hidden="true">
      <use xlink:href="#icon-a-Group63"></use>
    </svg>
    <div class="input-box" v-if="active == 1">
      <h1>请输入客户的手机号</h1>
      <h2>填写客户手机号，获取客户在红珊瑚的基础信息及装修意愿</h2>
      <p class="label-name">手机号</p>
      <Form>
        <Field
          v-model.trim="phone"
          placeholder="填写手机号码"
          maxlength="11"
          name="validator"
          :rules="[
            {
              required: false,
              validator: validatorPhone,
              message: '请填写正确的手机号码',
              trigger: 'onChange',
            },
          ]"
        />
      </Form>
      <Button
        type="primary"
        size="large"
        :disabled="phone && !validatorPhone(phone)"
        @click="changePhone"
      >
        提交
      </Button>
    </div>
    <div class="input-box" v-if="active == 2">
      <Image
        width="236px"
        height="130px"
        fit="cover"
        lazy-load
        :src="require('@/assets/images/welcome-page2.png')"
      />
      <p class="text">该用户还不是红珊瑚系统内客户</p>
      <Button type="primary" size="large" @click="addCuntomer">
        添加客户信息
      </Button>
    </div>
    <!-- 切换客户 -->
    <ActionSheet
      v-model:show="_DATA.cus_show"
      cancel-text="取消"
      close-on-click-action
      @cancel="handleMore(false)"
      class="change-cus-box"
      title="切换客户"
    >
      <ul class="content">
        <li
          v-for="(item, index) in _DATA.switch_list"
          :key="item.id"
          @click="changeCus(item.id)"
          :class="{ noborder: index + 1 === _DATA.switch_list.length }"
        >
          <div class="left">
            <p class="avder">{{ item.name && item.name[0] }}</p>
            <div class="mess-box">
              <p class="no">{{ 'NO.' + item.no }}</p>
              <p class="post">
                {{ '客户经理：' + item.customer_manager_name }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </ActionSheet>
  </div>
</template>
<script setup>
import { ref, inject, reactive } from 'vue';
import { Field, Button, Image, Form, Notify, ActionSheet } from 'vant';
import { useRouter } from 'vue-router';

const $http = inject('$http');
const router = useRouter();
const _DATA = reactive({
  //--@切换客户--begin
  cus_show: false,
  switch_list: [],
  //--@切换客户--end
});
const active = router.currentRoute.value.query.active || ref(1);
const phone = ref('');
const phoneReg = /^(1\d{10})$/;
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
/**
 * 操作
 */
// @修改客户手机号
const changePhone = () => {
  const userId = localStorage.getItem('USERID_ID');
  const external_userid = localStorage.getItem('WX_EXTERNAL_ID');
  $http
    .getWechatPhoneApi({
      phone: phone.value,
      userid: userId,
      external_userid: external_userid,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        onSubmit(userId);
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//-@切换客户
const changeCus = (id) => {
  _DATA.cus_show = false;
  window.location.href = '/sale?id=' + id;
};
//-@ 切换客户更改
const handleMore = (type) => {
  _DATA.cus_show = type;
};
// @提交
const onSubmit = (userId) => {
  $http
    .getInfoApi({
      phone: phone.value,
      userid: userId,
    })
    .then((res) => {
      const { code, data } = res;
      if (code === 0) {
        if (data.status === 0) {
          active.value = 2;
        } else {
          if (data.list.length) {
            if (data.list.length > 1) {
              _DATA.cus_show = true;
              _DATA.switch_list = data.list;
            } else {
              router.push('/sale?id=' + data.list[0].id);
            }
          } else {
            Notify({
              type: 'warning',
              message: '客户信息不存在，请联系管理员',
            });
          }
        }
      } else {
        Notify({ type: 'warning', message: data });
      }
    });
};
//-添加客户信息
const addCuntomer = () => {
  router.push('/customerNewInfo?source=index');
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/welcome.less';
</style>
