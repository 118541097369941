<!--
 * @Author: gest
 * @Date: 2023-06-17 16:07:08
 * @LastEditTime: 2024-05-31 21:08:12
 * @Description:V1.4.0-非自主见面-协议时间
 * @Description:V1.4.3-设计：见面-协议时间        
 * @Description:V1.4.4-见面-协议时间 
-->
<template>
  <div class="data_item extend chart_jd mt_15" style="padding-bottom: 0px">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title flex_line_between">
            见面-协议时间
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDangerHtml">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!-- 目标/当年完成/差值 -->
          <Row>
            <Col span="8">
              <div class="grey align_center">目标</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info.total?.target + '天' }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <div class="grey align_center">当年完成</div>
              <div>
                <span class="size17 bold align_center">
                  {{ _DATA.info.total?.actual + '天' }}
                </span>
              </div>
            </Col>
            <Col span="8">
              <!-- 差值 -->
              <div class="grey align_center">差值</div>
              <div>
                <div
                  class="flex_line_center"
                  v-if="_DATA.info.total?.actual < _DATA.info.total?.target"
                >
                  <span class="bold green pr_6 size17">
                    {{ _DATA.info.total?.gap + '天' }}
                  </span>
                  <img
                    class="icon_img_18"
                    :src="require('@/assets/images/target-data/up_total.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line_center"
                  v-else-if="
                    _DATA.info.total?.actual > _DATA.info.total?.target
                  "
                >
                  <span class="bold orange pr_6 size17">
                    {{ _DATA.info.total?.gap + '天' }}
                  </span>
                  <img
                    class="icon_img_18"
                    :src="require('@/assets/images/target-data/down_total.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line_center mh_25" v-else>
                  <img
                    class="icon_img_18"
                    :src="
                      require('@/assets/images/target-data/equal_total.png')
                    "
                    alt="down"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div class="data_card_table_bottom border_top">
          <Row class="mb_15">
            <Col span="6">
              <div class="grey">{{ props.data.depId ? '人员' : '部门' }}</div>
            </Col>
            <!-- 进度图标题 begin-->
            <Col span="18" style="position: relative">
              <div class="chart_pos pr_30 pl_20">
                <!-- 坐标数量，4-5个 -->
                <chartCoverTop
                  :data="{
                    type: 2,
                    unit: '天',
                    list: _DATA.line_values,
                    num: _DATA.middle_value,
                  }"
                ></chartCoverTop>
              </div>
            </Col>
            <!-- 进度图标题 end-->
          </Row>

          <Collapse
            v-model="_DATA.collapse_active"
            ref="collapseGroupRef"
            :accordion="false"
            :border="false"
          >
            <div
              class="sub_list"
              v-for="(item, index) in _DATA.list"
              :key="index"
            >
              <CollapseItem
                :name="index"
                :is-link="false"
                v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              >
                <template #title>
                  <Row class="mb_20" style="align-items: center; height: 100%">
                    <Col span="6">
                      <div
                        class="size15"
                        style="display: flex; align-items: center"
                      >
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) == -1"
                          style="transform: rotate(0deg)"
                          class="arrow"
                        />
                        <Icon
                          name="play"
                          v-if="_DATA.collapse_active.indexOf(index) > -1"
                          style="transform: rotate(90deg)"
                          class="arrow"
                        />
                        <p>{{ item.short_name }}</p>
                      </div>
                    </Col>
                    <!-- 进度图内容 begin-->
                    <Col span="18" class="chart_item">
                      <div style="display: flex" class="pr_30 pl_20">
                        <chartJDZW
                          :data="{
                            type: 1,
                            list: _DATA.line_values,
                            actual: item.now.actual,
                            target: item.now.target,
                          }"
                        ></chartJDZW>
                      </div>

                      <!-- 竖线 begin-->
                      <div class="chart_pos pr_30 pl_20">
                        <chartCoverLine
                          :data="{
                            type: 2,
                            list: _DATA.line_values,
                            num: _DATA.middle_value,
                          }"
                        ></chartCoverLine>
                      </div>
                      <!-- 竖线 end-->
                    </Col>
                    <!-- 进度图内容 end-->
                  </Row>
                </template>
                <Row
                  class="mb_10 mt_10"
                  v-show="item.sub_list && item.sub_list.length > 0"
                >
                  <Col span="6">
                    <div class="grey pr_10">
                      {{ props.data.depId ? '人员' : '部门' }}
                    </div>
                  </Col>

                  <Col span="6"><div class="grey align_center">目标</div></Col>
                  <Col span="6">
                    <div class="grey align_center">当年完成</div>
                  </Col>
                  <Col span="6">
                    <div class="grey align_center">差值</div>
                  </Col>
                </Row>
                <div
                  class="sub_item"
                  v-for="(sub, index) in item.sub_list"
                  :key="index"
                >
                  <Row
                    class="mb_20"
                    style="align-items: center; height: 100%"
                    @click="goDetail(sub)"
                  >
                    <Col span="6">
                      <div class="size13">
                        {{ sub.short_name }}
                      </div>
                    </Col>
                    <Col span="6">
                      <div>
                        <span class="align_center">
                          {{ sub.now.target + '天' }}
                        </span>
                      </div>
                    </Col>
                    <Col span="6">
                      <div>
                        <span class="align_center">
                          {{ sub.now.actual + '天' }}
                        </span>
                      </div>
                    </Col>
                    <Col span="6">
                      <div
                        class="flex_line align_center2"
                        v-if="sub.now.actual < sub.now.target"
                      >
                        <span class="green">{{ sub.now.gap }}</span>
                        <img
                          class="icon_img"
                          :src="require('@/assets/images/target-data/up.png')"
                          alt="down"
                        />
                      </div>
                      <div
                        class="flex_line align_center2"
                        v-else-if="sub.now.actual > sub.now.target"
                      >
                        <span class="orange">{{ sub.now.gap }}</span>
                        <img
                          class="icon_img"
                          :src="require('@/assets/images/target-data/down.png')"
                          alt="down"
                        />
                      </div>
                      <div class="flex_line align_center2" v-else>
                        <img
                          class="icon_img"
                          :src="
                            require('@/assets/images/target-data/equal.png')
                          "
                          alt="down"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </CollapseItem>
            </div>
          </Collapse>
        </div>
        <div class="p_15 flex_center">
          <p class="box_8 red_bg ml_30"></p>
          <p class="ml_5">达标</p>
          <p class="box_8 green_bg ml_30"></p>
          <p class="ml_5">未达标</p>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  defineExpose,
} from 'vue';
import {
  Row,
  Col,
  // Overlay,
  // Loading,
  Icon,
  Notify,
  Skeleton,
  Collapse,
  CollapseItem,
} from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJDZW from '@/components/targetData/chatTemp/chartJDZW';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDangerHtml']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  index: props.data.depId ? 3 : 5, //处在页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 3, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  open: false,
  collapse_active: [], //锁定
  line_values: [],
  middle_value: '',
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;

  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_clueFaceContract;
  let params = { year: props.data.yearId };
  if (props.data.depId) {
    url = $http.targetStatistic_depClueFaceContract;
    params = { department_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      _DATA.line_values = data.line_values;
      _DATA.middle_value = data.middle_value;
    } else {
      _DATA.info = {};
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */

const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depDesginData',
    query: { depId: item.id, depName: item.short_name, pos: _DATA.depIndex },
  });
};

//-规则说明--begin
const htmlTitle = '见面-协议时间_规则';
const htmlContent = `<p>1、当年非自主协议时间=当年非自主协议签约日期-当年见面时间（首见）；</p>
  <p>2、当年完成=累计当年协议时间/累计当年非自主协议数量（天/保留1位小数）；</p>
  `;
const createDangerHtml = () => {
  emit('createDangerHtml', 'noneMeetingTimeRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
