<!--
 * @Author: gest
 * @Date:  2023-03-21 11:03:54
 * @LastEditTime: 2024-05-23 16:52:27
 * @Description: v1.3.4 - 全部工地列表
 * v1.4.0 紧急需求 调整状态名称 Jessica
-->
<template>
  <div class="top_box">
    <div class="top_content">
      <!-- 头部搜索 -->
      <div class="header-box">
        <form action="/">
          <Search
            placeholder="编号/姓名/楼盘/手机号/服务人员"
            background="#f5f5f5"
            class="search-field"
            v-model="_DATA.kw"
            @search="onSearch"
            @clear="onClear"
            show-action
          >
            <template #action>
              <div @click="onSearch" style="color: #000">搜索</div>
            </template>
          </Search>
        </form>
      </div>
      <!-- end -->
      <div class="tabBar-box">
        <div class="tabBar" :class="_DATA.searchType !== 1 ? 'lose' : ''">
          <Tabs
            v-model:active="_DATA.tabIndex"
            title-active-color="#0256FF"
            @click-tab="clickTab"
          >
            <Tab
              :title="item.name"
              v-for="item in _DATA.tabList"
              :key="item.id"
              :name="item.id"
            ></Tab>
          </Tabs>
        </div>
        <div class="select_btn" @click.stop="onFilter()">
          <div class="item" :class="_DATA.searchType == 3 ? 'select' : ''">
            筛选
            <Icon name="arrow-down" size="14" />
          </div>
        </div>
      </div>
      <div class="total_tip">
        <div>
          共计数据<span class="pl_5">{{ _DATA.page.total || '0' }}</span>
        </div>
        <div v-if="_DATA.searchType == 1">
          {{
            _DATA.tabIndex == 1
              ? '交付派单时间倒序排列'
              : _DATA.tabIndex == 2 || _DATA.tabIndex == 6
              ? '开工日期倒序排列'
              : _DATA.tabIndex == 3
              ? '完工日期倒序排列'
              : ''
          }}
        </div>
      </div>
    </div>
  </div>

  <div class="page-box">
    <!-- 内容开始 -->
    <List
      v-model:loading="loading"
      :finished="finished"
      @load="onLoad"
      class="list-box"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="cell-same"
        @click="linkTo(item)"
      >
        <div class="head_box">
          <div class="left">
            <span class="img_head" v-if="item.customer_name">
              {{ item.customer_name[0] }}
            </span>
            <div class="con">
              <span class="head_name">{{ item.customer_name }}</span>
            </div>
          </div>
          <!-- 精装/优选整装 construction_type---1：精装工地，3：优选整装-->
          <div class="right flex">
            <em
              class="tag blue"
              v-if="item.construction_type == 1 || item.construction_type == 3"
            >
              {{ item.construction_type == 1 ? '精装工地' : '优选整装' }}
            </em>
          </div>
        </div>

        <div class="desc_box">
          <div class="cell_info clue_no">
            {{ item.customer_no }}
          </div>
          <div class="cell_info more_message">
            {{ item.estate_str }}
          </div>
          <div class="tag_box">
            <em
              class="tag blue"
              v-if="item.status == 2 && item.construction_progress"
            >
              {{ item.construction_progress }}
            </em>
            <em
              class="tag red"
              v-if="item.delay_day > 0 && item.delay_status == 1"
            >
              延期{{ item.delay_day }}天
            </em>
            <em
              class="tag green"
              v-if="item.delay_day > 0 && item.delay_status == 2"
            >
              提前{{ item.delay_day }}天
            </em>
            <!-- to_assign 1 待指派 2 指派工地 -->
            <em class="tag orange" v-if="item.to_assign == 1">待指派</em>
            <em class="tag orange" v-if="item.un_pay_first == 1"
              >待收施工首期款</em
            >
            <em class="tag orange" v-if="item.deliver_label">{{
              item.deliver_label
            }}</em>
            <em class="tag green" v-if="item.re_constrution_name">工地返单</em>
            <em class="tag green" v-if="item.to_assign == 2">指派工地</em>
            <!-- 新增完工工期展示 -->
            <em class="tag blue" v-if="item.status == 3">
              {{ item.finish_schedule || '0' }}天完工
            </em>
          </div>
        </div>
      </div>
      <template v-if="list.length > 0" #finished>没有更多了</template>
    </List>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无数据"
      v-if="!loading && list.length === 0"
    />
    <!-- end -->
    <!-- list -->
    <!--V1.4.0 菜单 悬浮按钮 -->
    <ol class="fixed-ol-box">
      <li class="icon-box" @click="_DATA.showMenu = true">
        <img
          class="icon-fixed"
          :src="require('@/assets/images/icon/icon_menu.png')"
        />
      </li>
    </ol>
    <sideMenu
      :data="{ showMenu: _DATA.showMenu }"
      @closed="
        () => {
          _DATA.showMenu = false;
        }
      "
    ></sideMenu>
    <!-- end -->
  </div>
  <!-- 筛选 -->
  <ActionSheet
    v-model:show="_DATA.showAction"
    :closeable="false"
    close-on-click-action
    title="筛选"
    class="action_box"
  >
    <div class="action_content">
      <div class="select_box">
        <div class="select_item">
          <p>工地状态</p>
          <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
            <GridItem
              v-for="item in _DATA.construction_status"
              :key="item.id"
              @click="changeSelect('工地状态', item)"
            >
              <Button :class="_DATA.action_status == item.id ? 'selected' : ''">
                {{ item.name }}
              </Button>
            </GridItem>
          </Grid>
        </div>
        <div class="select_item">
          <p>施工阶段</p>

          <Grid class="btn_box" :border="false" :column-num="2" :gutter="12">
            <GridItem
              v-for="item in _DATA.construction_stage"
              :key="item.id"
              @click="changeSelect('施工阶段', item)"
            >
              <Button
                :class="
                  _DATA.stages.some((v) => v == item.id) ? 'selected' : ''
                "
              >
                {{ item.name }}
              </Button>
            </GridItem>
          </Grid>
        </div>
        <div class="select_item">
          <p>项目状况</p>
          <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
            <GridItem
              v-for="item in _DATA.construction_delay_list"
              :key="item.id"
              @click="changeSelect('项目状况', item)"
            >
              <Button :class="_DATA.delay_status == item.id ? 'selected' : ''">
                {{ item.name }}
              </Button>
            </GridItem>
          </Grid>
        </div>
        <div class="select_item">
          <p>工地类型</p>
          <Grid class="btn_box" :border="false" :column-num="3" :gutter="12">
            <GridItem
              v-for="item in _DATA.construction_type_labels"
              :key="item.id"
              @click="changeSelect('工地类型', item)"
            >
              <Button
                :class="_DATA.construction_type == item.id ? 'selected' : ''"
              >
                {{ item.name }}
              </Button>
            </GridItem>
          </Grid>
        </div>
      </div>
      <div class="line"></div>
      <Row class="bottom_btn" gutter="12">
        <Col span="12">
          <Button class="reset" @click="btnReset">重置</Button>
        </Col>
        <Col span="12">
          <Button @click="btnConfirm">确定</Button>
        </Col>
      </Row>
    </div>
  </ActionSheet>
  <!-- end -->
</template>
<script setup>
import { ref, onMounted, inject, reactive } from 'vue';
import {
  Search,
  List,
  Notify,
  Icon,
  Tabs,
  Tab,
  Button,
  ActionSheet,
  Grid,
  GridItem,
  Row,
  Col,
  Empty,
} from 'vant';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import sideMenu from '@/components/sideMenu';
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const $http = inject('$http');
const router = useRouter();
const _DATA = reactive({
  kw: '', //假数据
  parmas: {
    kw: '',
  },
  page: {
    count_page: 1,
    page: 1,
    pages: 10,
    total: 0,
  },
  showAction: false,
  tabIndex: 2,
  searchType: 1, //1默认tabar 2模糊搜索 3筛选项
  action_status: '',
  delay_status: '',
  construction_type: '',
  stages: [],
  construction_delay_list: [],
  construction_stage: [],
  construction_status: [],
  construction_type_labels: [], //V1.3.5gest 新增工地类型筛选
  tabList: [
    {
      id: 1,
      name: '准备开工',
    },
    {
      id: 2,
      name: '在建工地',
    },
    {
      id: 6,
      name: '停工工地',
    },
    {
      id: 3,
      name: '已完工',
    },
  ],
});
onMounted(() => {
  initSearch();
  refreshFunc();
});
// 初始化查询
const initSearch = () => {
  _DATA.searchType = Number(router.currentRoute.value.query.searchType) || 1;
  _DATA.tabIndex = Number(router.currentRoute.value.query.tabIndex) || 2;
  _DATA.action_status = router.currentRoute.value.query.action_status || '';
  _DATA.delay_status = router.currentRoute.value.query.delay_status || '';
  _DATA.construction_type =
    router.currentRoute.value.query.construction_type || '';
  _DATA.stages = router.currentRoute.value.query.stages
    ? router.currentRoute.value.query.stages.split(',')
    : [];
};
/**
 * 接口
 */
//-@ 加载
const refreshFunc = () => {
  loading.value = true;
  $http.customerSearchParameter().then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      _DATA.construction_status = data.construction_status;
      _DATA.construction_status.unshift({
        id: '0',
        name: '不限',
      });

      _DATA.construction_stage = data.construction_stage;
      _DATA.construction_delay_list = data.construction_delay_list;
      _DATA.construction_type_labels = data.construction_type_labels;
      setTimeout(() => {
        if (refreshing.value) {
          list.value = [];
          refreshing.value = false;
        }
        // onLoad();
      }, 1000);
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};
const onLoad = () => {
  loading.value = true;
  $http
    .constructionList({
      kw: _DATA.parmas.kw,
      status:
        _DATA.searchType == 1
          ? _DATA.tabIndex
          : _DATA.searchType == 2
          ? ''
          : _DATA.action_status == 0
          ? ''
          : _DATA.action_status,
      stages: _DATA.stages,
      delay_status: _DATA.delay_status,
      construction_type: _DATA.construction_type,
      page: _DATA.page.page,
      pages: _DATA.page.pages,
    })
    .then((res) => {
      const { code, data } = res;
      loading.value = false;
      if (code === 0) {
        _DATA.page = data.page;
        list.value = list.value.concat(data.list);
        if (data.page.page <= data.page.count_page) {
          _DATA.page.page++;
        } else {
          finished.value = true;
        }
      } else {
        loading.value = false;
        finished.value = true;
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
};
const changeRouter = () => {
  // 动态修改路由参数
  const query = _.cloneDeep(router.currentRoute.value.query);

  query.action_status = _DATA.action_status || '';
  query.tabIndex = _DATA.tabIndex || 2;
  query.delay_status = _DATA.delay_status || '';
  query.construction_type = _DATA.construction_type || '';
  if (_DATA.stages && _DATA.stages.length > 0) {
    query.stages = _DATA.stages.join(',');
  } else {
    query.stages = '';
  }
  if (_DATA.searchType) {
    if (_DATA.searchType == 2) {
      //搜索项不存
      query.searchType = 1;
      query.tabIndex = 2;
    } else {
      query.searchType = _DATA.searchType;
    }
  }
  router.replace({ query });
};
/*
 * 操作
 */
//-@ 搜索
const onSearch = () => {
  _DATA.parmas.kw = JSON.parse(JSON.stringify(_DATA.kw));

  if (_DATA.parmas.kw) {
    _DATA.searchType = 2;
    _DATA.action_status = '';
  } else {
    _DATA.tabIndex = 2;
    _DATA.searchType = 1;
  }

  _DATA.stages = [];
  _DATA.delay_status = '';
  _DATA.construction_type = '';
  _DATA.page.page = 1;

  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
  changeRouter();
};
const clickTab = (val) => {
  _DATA.searchType = 1;

  _DATA.tabIndex = val.name;

  _DATA.action_status = '';
  _DATA.stages = [];
  _DATA.delay_status = '';
  _DATA.construction_type = '';
  _DATA.parmas.kw = '';
  _DATA.kw = '';
  list.value = [];
  refreshing.value = false;
  finished.value = false;

  _DATA.page.page = 1;
  onLoad();
  changeRouter();
};

const onFilter = () => {
  _DATA.showAction = true;
};
//-@ 清除
const onClear = () => {
  _DATA.tabIndex = 2;
  _DATA.searchType = 1;
  _DATA.page.page = 1;
  _DATA.parmas.kw = '';
  _DATA.kw = '';
  list.value = [];
  refreshing.value = false;
  finished.value = false;
  onLoad();
};

//-@ 上拉刷新
// const onRefresh = () => {
//   // 清空列表数据
//   finished.value = false;
//   loading.value = false;
//   _DATA.parmas.page = 1;
//   _DATA.page.page = 1;
//   // 重新加载数据
//   // 将 loading 设置为 true，表示处于加载状态
//   loading.value = true;
//   refreshFunc();
// };

//-@ 跳转
const linkTo = (item) => {
  router.push({ path: '/sale', query: { id: item.customer_id } });
};
const changeSelect = (type, item) => {
  switch (type) {
    case '工地状态':
      if (_DATA.action_status == item.id) {
        _DATA.action_status = '';
      } else {
        _DATA.action_status = item.id;
      }

      break;
    case '施工阶段':
      if (_DATA.stages.some((v) => v == item.id)) {
        _DATA.stages = _DATA.stages.filter((v) => v !== item.id);
      } else {
        _DATA.stages.push(item.id);
      }

      break;
    case '项目状况':
      if (_DATA.delay_status == item.id) {
        _DATA.delay_status = '';
      } else {
        _DATA.delay_status = item.id;
      }

      break;
    case '工地类型':
      if (_DATA.construction_type == item.id) {
        _DATA.construction_type = '';
      } else {
        _DATA.construction_type = item.id;
      }

      break;
    default:
      break;
  }
};
const btnReset = () => {
  _DATA.stages = [];
  _DATA.delay_status = '';
  _DATA.construction_type = '';
  _DATA.action_status = '';
};
const btnConfirm = () => {
  _DATA.page.page = 1;
  _DATA.parmas.kw = '';
  _DATA.kw = '';
  list.value = [];
  refreshing.value = false;
  finished.value = false;

  _DATA.showAction = false;
  if (
    _DATA.action_status ||
    _DATA.stages.length > 0 ||
    _DATA.delay_status ||
    _DATA.construction_type
  ) {
    _DATA.searchType = 3;
  } else {
    _DATA.tabIndex = 2;
    _DATA.searchType = 1;
  }
  onLoad();
  changeRouter();
};
</script>
<style lang="less" scoped>
.top_box {
  height: 145px;
  .top_content {
    position: fixed;
    width: 100%;
    z-index: 1;

    .tabBar-box {
      height: 45px;
      display: flex;
      background: #ffffff;

      border-bottom: 1px solid #efefef;
      .tabBar {
        flex: 1;
        height: 44px;

        :deep(.van-tab__text) {
          font-size: 14px;
          font-weight: bold;
        }
        :deep(.van-tabs__line) {
          display: block;
          width: 10%;
          background: #0256ff;
        }
        &.lose {
          :deep(.van-tab--active) {
            color: #646566 !important;
          }
          :deep(.van-tabs__line) {
            background: transparent;
          }
        }
      }
    }
  }

  .select_btn {
    font-size: 14px;
    color: #86909c;
    height: 44px;

    display: flex;
    align-items: center;

    .item {
      padding: 0 6px;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      &.select {
        color: rgb(2, 86, 255);
      }
    }
  }
}
.total_tip {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: @grey-color;
  background: rgb(245, 245, 245);
  padding: 0 15px;
}
.page-box {
  padding: 0;
  height: calc(100% - 192px);
  .list-box {
    .cell-same {
      background: #ffffff;
      border: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 20px 16px;
      border-bottom: 1px solid #f5f5f5;

      .head_box {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 15px 0;
        width: 100%;

        .left {
          max-width: calc(100vw - 160px);
          display: flex;
          align-items: center;
          justify-content: left;
          flex-wrap: nowrap;

          .img_head {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: linear-gradient(135deg, #ace2da 0%, #bdd5ff 100%);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            flex-shrink: 0;
            flex-grow: 0;
          }

          .con {
            flex-grow: 1;
            flex-shrink: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            /*     增加属性flex: 1; */
            flex: 1;

            .head_name {
              font-size: 15px;
              font-weight: bold;
              color: #1a1a1a;
              margin-right: 6px;
              flex-wrap: nowrap;
            }
          }
        }

        .right {
          flex-shrink: 0;
          flex-grow: 0;
          &.flex {
            display: flex;
          }
          .tag {
            padding: 0px 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border-radius: 2px;
            margin-left: 4px;
            line-height: 20px;
            font-weight: bold;

            &.red {
              color: @red-color;
              background: @red-bg-color;
            }
            &.orange {
              color: @orange-color;
              background: @orange-bg-color;
            }
            &.yellow {
              color: @yellow-color;
              background: @yellow-bg-color;
            }
            &.green {
              color: @green-color;
              background: @green-bg-color;
            }
            &.blue {
              color: @blue-color;
              background: @blue-bg-color;
            }
            &.grey {
              color: @grey-color;
              background: @grey-bg-color;
            }
          }
        }
      }

      .desc_box {
        .cell_info {
          display: flex;
          align-items: center;
          justify-content: left;

          &.clue_no {
            line-height: 22px;
            color: #767676;
            font-size: 13px;

            .lunbox {
              font-size: 13px;
              color: #ea0000;
              margin-right: 4px;
            }
          }

          &.more_message {
            color: #1a1a1a;
            font-size: 13px;
            line-height: 22px;
          }
        }
      }

      .tag_box {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 5px;

        & > em {
          padding: 0 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          border-radius: 2px;
          line-height: 19px;
          margin-right: 4px;
          font-weight: bold;
          &.red {
            color: @red-color;
            background: @red-bg-color;
          }
          &.orange {
            color: @orange-color;
            background: @orange-bg-color;
          }
          &.yellow {
            color: @yellow-color;
            background: @yellow-bg-color;
          }
          &.green {
            color: @green-color;
            background: @green-bg-color;
          }
          &.blue {
            color: @blue-color;
            background: @blue-bg-color;
          }
          &.grey {
            color: @grey-color;
            background: @grey-bg-color;
          }
        }
      }
    }
  }
}

.action_box {
  .action_content {
    background: #ffffff;
    margin-bottom: 84px;

    h3 {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
      padding: 16px 0;
      text-align: center;
    }
    .select_box {
      .select_item {
        padding: 0 4px;
        p {
          font-size: 15px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 12px;
          padding: 0 12px;
        }
        .btn_box {
          padding-bottom: 20px;
          :deep(.van-grid-item__content) {
            padding: 0;
          }
          :deep(.van-button) {
            width: 100%;
            border-radius: 2px;
            border: 1px solid #f2f3f5;
            color: #4e5969;
            &.selected {
              background: #e8f3ff;
              color: #165dff;
              border: 1px solid #bedaff;
            }
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 8px;
      background: #f2f3f5;
    }
  }

  .bottom_btn {
    padding: 20px;
    background: #ffffff;

    position: fixed;
    bottom: 0;
    bottom: calc(constant(safe-area-inset-bottom));
    bottom: calc(env(safe-area-inset-bottom));
    width: 100%;
    :deep(.van-button) {
      width: 100%;
      font-size: 16px;
      border-radius: 2px;
      background: #0256ff;
      color: #ffffff;
      border: none;
      &.reset {
        background: #e8f3ff;
        color: #0256ff;
      }
    }
  }
}
</style>
