<!--
 * @Author: Jessica
 * @Date: 2022-06-21 15:07:31
 * @LastEditTime: 2022-11-04 14:06:46
 * @Description: 
-->
<template>
  <!-- 分配市拓专员 -->
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              市拓专员
            </label>
            <Field
              v-model="form.list.marker_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择市拓专员"
              :rules="[
                {
                  required: true,
                  message: '请选择市拓专员',
                },
              ]"
              @click="state.showPicker_marker = true"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remarks"
              rows="5"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="添加备注..."
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!--picker-市拓专员 --->
    <Popup v-model:show="state.showPicker_marker" position="bottom">
      <Picker
        :columns="state.markerList"
        :columns-field-names="customFieldName"
        @confirm="(v) => onConfirm(v, 'marker')"
        @cancel="state.showPicker_marker = false"
        :default-index="
          state.markerList.findIndex((v) => {
            return v.id == form.list.marker;
          })
        "
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import { useRouter } from 'vue-router';
const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  //-市拓专员-picker
  showPicker_marker: false,
  markerList: [],
});

//-picker-选择项字段
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value, type) => {
  if (value) {
    form['list'][type] = value.id;
    form['list'][type + '_name'] = value.name;
  }
  state['showPicker_' + type] = false;
};

//-获取参数--begin
onBeforeMount(() => {
  getPublicParameter();
});

//-获取参数
const getPublicParameter = async () => {
  state.loadingConfirm = true;
  await $http.customerAssignServicePersonParam().then((res) => {
    if (res.code === 0) {
      state.markerList = res.data.marketing_commissioner;
      changeChecker();
    } else {
      state.loadingConfirm = false;
      Toast(res.data);
    }
  });
};
//-获取参数--end

//-数据容错处理
const changeChecker = () => {
  let marker = state.markerList;
  const markerList =
    marker.length > 0 && !!router.currentRoute.value.query.suid
      ? marker.filter((item) => item.id == router.currentRoute.value.query.suid)
      : [];
  if (markerList.length === 0) {
    form.list.marker = '';
    form.list.marker_name = '';
  } else {
    form.list.marker = markerList[0]['id'];
    form.list.marker_name = markerList[0]['name'];
  }
  state.loadingConfirm = false;
};

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    customer_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    type: 9,
    marker: '',
    marker_name: '',
    remarks: '',
  },
});

//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      state.loadingConfirm = true;
      $http
        .customerAssignServicePerson({
          customer_id: form.list.customer_id,
          type: form.list.type,
          service_person_id: form.list.marker,
          remarks: form.list.remarks,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (state.taskDate) {
                  router.push('/taskDay?date=' + state.taskDate);
                } else {
                  router.push('/sale?id=' + form.list.customer_id);
                }
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
