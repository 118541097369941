<!--
 * @Author: gest
 * @Date:2024-01-24 11:33:29
 * @LastEditTime: 2024-05-18 14:45:08
 * @Description: V1.5.4 目标数据-简单获客数据
-->
<template>
  <!-- 线索数 -->
  <div class="main-data" @click.stop="redictFun">
    <div class="warpper_simple">
      <Skeleton
        title
        avatar
        :row="5"
        :loading="loading || props.loading"
        style="padding: 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_s_1.png')"
                alt="icon"
              />
            </div>
            <div class="font_size_17 font_bold">获客数据</div>
          </div>
        </div>

        <div class="pb_15 flex_evenly align_center font_size_13 grey_color">
          <div class="flex_column align_center simple_item">
            <div>线索数</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.clue?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.clue.today }}
                </span>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.clue?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.clue.today }}
                </span>
              </div>

              <div class="icon_box ml_2" v-if="detail.clue?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.clue?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.clue.month }}
                </span>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.clue?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.clue.month }}
                </span>
              </div>

              <div class="icon_box ml_2" v-if="detail.clue?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div class="font_size_20 black_color font_bold">
              {{ detail.clue?.total }}
            </div>
            <div class="mt_10">
              集团：
              <span class="black_color font_bold">
                {{ detail.clue?.group }}
              </span>
            </div>
            <div class="mt_2">
              市场：
              <span class="black_color font_bold">
                {{ detail.clue?.market }}
              </span>
            </div>
          </div>
          <div class="line"></div>
          <div class="flex_column align_center simple_item">
            <div>见面数</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.meet?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.meet.today }}
                </span>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.meet?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.meet.today }}
                </span>
              </div>

              <div class="icon_box ml_2" v-if="detail.meet?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.meet?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.meet.month }}
                </span>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.meet?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.meet.month }}
                </span>
              </div>

              <div class="icon_box ml_2" v-if="detail.meet?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>

            <div class="font_size_20 black_color font_bold">
              {{ detail.meet?.total }}
            </div>
            <div class="mt_10">
              见面率：
              <span class="black_color font_bold">
                {{ detail.meet?.meet_rate }}%
              </span>
            </div>
            <div class="mt_2">
              主场率：
              <span class="black_color font_bold">
                {{ detail.meet?.major_rate }}%
              </span>
            </div>
          </div>
          <div class="line"></div>
          <div class="flex_column align_center simple_item">
            <div>协议数</div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">今</div>
              <div
                class="display_flex align_center"
                v-if="detail.contract?.today > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.contract.today }}
                </span>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.contract?.today < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.contract.today }}
                </span>
              </div>

              <div class="icon_box ml_2" v-if="detail.contract?.today == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div
              class="display_flex mt_2 align_center"
              v-if="props.data.now_year == props.data.yearId"
            >
              <div class="font_bold font_size_12">月</div>
              <div
                class="display_flex align_center"
                v-if="detail.contract?.month > 0"
              >
                <span class="green font_bold font_size_12 ml_5">
                  +{{ detail.contract.month }}
                </span>
              </div>
              <div
                class="display_flex align_center"
                v-if="detail.contract?.month < 0"
              >
                <span class="orange font_bold font_size_12 ml_5">
                  {{ detail.contract.month }}
                </span>
              </div>

              <div class="icon_box ml_2" v-if="detail.contract?.month == 0">
                <img
                  class="icon_img"
                  :src="require('@/assets/images/target-data/equal.png')"
                  alt="down"
                />
              </div>
            </div>
            <div class="font_size_20 black_color font_bold">
              {{ detail.contract?.total }}
            </div>
            <div class="mt_10">成功率</div>
            <div class="black_color font_bold font_size_15 line_h20">
              {{ detail.contract?.rate }}%
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, watch, defineProps } from 'vue';
import { Notify, Skeleton } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http.targetStatistic_simpleClue({ year: props.data.yearId }).then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      detail.value = data;
    } else {
      loading.value = false;
      detail.value = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 1, pos: 1 },
  });
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
