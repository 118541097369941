<!--
 * @Author: NanNan
 * @Date: 2022-04-16 13:59:17
 * @LastEditTime: 2023-11-30 16:45:02
 * @Description: v0.2.2 设计节点 - 列表 - 确认验收
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 节点名称 -->
    <div class="node-name-box">
      <em>节点名称：</em>
      {{ node_name || '-' }}
    </div>
    <!-- 提交表单 -->
    <Form class="form-box" ref="formRef">
      <p class="label-name">
        <em>*</em>
        验收结果
      </p>
      <Field
        v-model="form_info.type_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择验收结果"
        @click="_DATA.type_show = true"
        :rules="[
          {
            required: true,
            message: '请选择验收结果',
            trigger: 'onChange',
          },
        ]"
      />
      <p class="label-name">
        <em>*</em>
        备注
      </p>
      <Field
        v-model.trim="form_info.remarks"
        rows="5"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加备注..."
        :rules="[
          {
            required: true,
            message: '请添加备注',
            trigger: 'onChange',
          },
        ]"
      ></Field>
      <p class="label-name" v-if="form_info.type == 2">
        <em>*</em>
        退回阶段
      </p>
      <Field
        v-if="form_info.type == 2"
        v-model="form_info.backStage_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择退回阶段"
        @click="_DATA.backStage_show = true"
        :rules="[
          {
            required: true,
            message: '请选择退回阶段',
            trigger: 'onChange',
          },
        ]"
      />
      <p class="label-name" v-if="form_info.type == 2">
        <em>*</em>
        退回节点
      </p>
      <Field
        v-if="form_info.type == 2"
        v-model="form_info.backNode_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择退回节点"
        @click="_DATA.backNode_show = true"
        :rules="[
          {
            required: true,
            message: '请选择退回节点',
            trigger: 'onChange',
          },
        ]"
      />
      <!-- end -->
      <div class="stick-box">
        <Row>
          <Col span="11">
            <Button
              type="default"
              size="large"
              class="button-danger"
              @click="onCancel()"
            >
              取消
            </Button>
          </Col>
          <Col span="2"></Col>
          <Col span="11">
            <Button
              type="primary"
              size="large"
              class="button-state"
              @click="onSubmit()"
            >
              确认
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
    <!-- 见证件 -->
    <div class="witnessFile">
      <p class="mb_15">见证件</p>
      <ul
        class="wintness"
        v-if="_DATA.witnessList && _DATA.witnessList.length > 0"
      >
        <li
          class="item"
          v-for="item in _DATA.witnessList"
          :key="item.id"
          @click.stop="wintnessFun(item)"
        >
          <span>{{ item.name || '-' }}</span>
          <Icon name="arrow" class="icon" />
        </li>
      </ul>
      <p v-else>-</p>
    </div>
    <!-- 附件 -->
    <div class="fileGrid">
      <p class="mb_15">附件</p>
      <!-- 附件 -->
      <div
        class="fileList"
        v-if="_DATA.filesList && _DATA.filesList.length > 0"
      >
        <FileGrid :data="_DATA.filesList"></FileGrid>
      </div>
      <p v-else>-</p>
    </div>
    <!-- 验收结果 -->
    <Popup v-model:show="_DATA.type_show" position="bottom">
      <Picker
        :columns="_DATA.type_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="change_type"
        @cancel="_DATA.type_show = false"
      />
    </Popup>

    <Popup v-model:show="_DATA.backStage_show" position="bottom">
      <Picker
        :columns="_DATA.backStage_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="change_backStage"
        @cancel="_DATA.backStage_show = false"
      />
    </Popup>
    <Popup v-model:show="_DATA.backNode_show" position="bottom">
      <Picker
        :columns="_DATA.backNode_list"
        :columns-field-names="{
          text: 'node_name',
          value: 'node_id',
        }"
        @confirm="change_backNode"
        @cancel="_DATA.backNode_show = false"
      />
    </Popup>
    <!-- 见证件展示 -->
    <Popup
      v-model:show="_DATA.witnessOptions.show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <witnessPropsTemp
        v-if="_DATA.witnessOptions.show"
        :data="_DATA.witnessOptions"
        @reviewOk="
          () => {
            _DATA.witnessOptions.show = false;
          }
        "
      />
    </Popup>
  </div>
</template>
<script setup>
import { reactive, ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import {
  Form,
  Field,
  Overlay,
  Loading,
  Button,
  Notify,
  Row,
  Col,
  Picker,
  Popup,
  Icon,
} from 'vant';
//-见证件展示
import witnessPropsTemp from '@/components/witness/witnessPropsTemp';
//-附件展示
import FileGrid from '@/components/common/FileGrid';
const $http = inject('$http');
const router = useRouter();
const design_id = ref(router.currentRoute.value.query.did); //设计节点列表 过来携带 设计id
const design_node_id = ref(router.currentRoute.value.query.nid); // 节点id node_id
const node_name = ref(router.currentRoute.value.query.nname); //携带 节点名称

const _DATA = reactive({
  type_show: false, //组件-验收结果
  type_list: [
    { id: 1, name: '通过' },
    { id: 2, name: '拒绝' },
  ],
  backStage_show: false,
  backStage_list: [],
  backNode_show: false,
  backNode_list: [],
  node_list: [],
  //-附件
  filesList: [],
  //-见证件
  witnessList: [],
  witnessOptions: {
    show: false,
    type: 1, //1设计 2施工
    node_id: '',
    witness_id: '',
  },
});
const form_default = reactive({
  design_node_id: design_node_id.value, //*设计节点id
  type: 1, //1 通过 2 拒绝
  remarks: '', //*备注
  backStage: '',
  backStage_name: '',
  back_node_id: '',
  backNode_name: '',
});
const form_info = reactive({});
const loading = ref(false);

/**
 * 初始化
 */
onMounted(() => {
  loading.value = true;
  Object.assign(form_info, form_default);
  //请求参数
  $http
    .getDesignConfirmParamsApi({
      design_id: Number(design_id.value),
      node_id: Number(design_node_id.value),
    })
    .then((res) => {
      loading.value = false;
      const { data, code } = res;

      if (code === 0) {
        //-附件
        _DATA.filesList = data.node_info.files;
        //-见证件
        _DATA.witnessList = data.node_info.witness_list;
        _DATA.witnessOptions.node_id = data.node_info.id;
        //默认节点id
        _DATA.node_list = data.list;
        let defaultItem = {};
        let list = data.list;
        for (let i in list) {
          let item = list[i];
          if (item.children.find((v) => v.node_id == design_node_id.value)) {
            defaultItem = item;
          }
        }
        form_info.backStage = defaultItem.type_sort;
        form_info.backStage_name = defaultItem.type_name;
        _DATA.backStage_list = list.map((item) => {
          return { id: item.type_sort, name: item.type_name };
        });
        //-V1.4.1【紧急需求】非内控/外控验收节点，退回节点默认为当前验收阶段的内控节点----需要修改PC、企微两端 接口/rs/design/confirmParams  加了is_choice字段  默认选择为1的
        _DATA.backNode_list = defaultItem.children;
        let default_backNode_list = _DATA.backNode_list.filter((v) => {
          return v.is_choice == 1;
        });
        form_info.back_node_id = default_backNode_list?.length
          ? default_backNode_list[0].node_id
          : '';
        form_info.backNode_name = default_backNode_list?.length
          ? default_backNode_list[0].node_name
          : '';
      } else {
        Notify({
          type: 'warning',
          message: data,
        });
      }
    });
});

/**
 * 操作
 */
const change_type = (value) => {
  _DATA.type_show = false;
  form_info.type = value.id;
  form_info.type_name = value.name;
};
const change_backStage = (value) => {
  _DATA.backStage_show = false;
  form_info.backStage = value.id;
  form_info.backStage_name = value.name;
  let changeItem = _DATA.node_list.find((v) => v.type_sort == value.id);
  _DATA.backNode_list = changeItem.children;
  form_info.back_node_id = _DATA.backNode_list[0].node_id;
  form_info.backNode_name = _DATA.backNode_list[0].node_name;
};
const change_backNode = (value) => {
  _DATA.backNode_show = false;
  form_info.back_node_id = value.node_id;
  form_info.backNode_name = value.node_name;
};
//-@打开见证件
const wintnessFun = (data) => {
  _DATA.witnessOptions.show = true;
  _DATA.witnessOptions.witness_id = data.id;
};

//-@ 提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http.getDesignSureApi(form_info).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功！',
          duration: 1000,
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        Notify({
          type: 'warning',
          message: data,
          duration: 2000,
          onClose: () => {
            loading.value = false;
          },
        });
      }
    });
  });
};
const onCancel = () => {
  router.back();
};
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
  .label-name {
    font-size: 15px;
    line-height: 20px;
    margin: 15px 0;
    font-weight: 700;

    &.white {
      color: #fff;
    }

    em {
      color: #ea3d2f;
    }
  }
  .node-name-box {
    padding: 20px;
    font-size: 15px;
    line-height: 18px;
    background: #f5f5f5;
    text-align: center;
    em {
      color: #999;
    }
  }
  .form-box {
    padding: 20px;
  }
  .witnessFile {
    padding: 10px 20px;
    > p {
      font-weight: 700;
      font-size: 15px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(228, 228, 228, 0.6);
      padding: 10px 0px;
      &:first-child {
        padding: 0px 0px 10px;
      }
      > span {
        color: #576b95;
      }
      .icon {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .fileGrid {
    padding: 0px 20px 95px;
    > p {
      font-weight: 700;
      font-size: 15px;
    }
  }
  .stick-box {
    z-index: 9;
  }
}
</style>
