<!--
 * @Author: NanNan
 * @Date: 2022-06-16 09:43:55
 * @LastEditTime: 2023-11-30 16:42:08
 * @Description: 公共备注弹窗
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    :title="title"
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-box formBase">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <Form class="form-box" ref="formRef" style="padding: 20px 0 0 0">
      <Field
        v-model.trim="form_info.remark"
        rows="10"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加备注..."
        style="border: none; background: #f0f0f0"
      ></Field>
      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>
  </div>
</template>
<script setup>
import { reactive, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import {
  NavBar,
  Field,
  Form,
  Button,
  Toast,
  Overlay,
  Loading,
  Notify,
} from 'vant';
const router = useRouter();
const $http = inject('$http');
// const customer_id = ref(router.currentRoute.value.query.id); //客户id
const title = ref(router.currentRoute.value.query.title); //客户id
const paramsID = ref(router.currentRoute.value.query.paramsID); //客户id
const loading = ref(false);

const form_info = reactive({
  remark: '',
});

/**
 * 操作
 */
//-@ 提交
const formRef = ref(null);
const onSubmit = () => {
  if (!form_info.remark) {
    Toast('请填写备注');
    return false;
  }
  formRef.value?.validate().then(() => {
    loading.value = true;

    if (title.value === '取消见面') {
      $http
        .customerFailFace({
          invite_id: paramsID.value,
          remark: form_info.remark,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                loading.value = false;
                router.back();
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: data,
              duration: 2000,
              onClose: () => {
                loading.value = false;
              },
            });
          }
        });
    } else if (title.value === '取消见工地') {
      $http
        .customerFailLookSite({
          source_id: paramsID.value,
          remark: form_info.remark,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功！',
              duration: 1000,
              onClose: () => {
                loading.value = false;
                router.back();
              },
            });
          } else {
            Notify({
              type: 'warning',
              message: data,
              duration: 2000,
              onClose: () => {
                loading.value = false;
              },
            });
          }
        });
    }
  });
};

//-@ 返回
const onBack = () => {
  router.back();
};
</script>
<style lang="less" scoped></style>
