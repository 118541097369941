/*
 * @Author: NanNan
 * @Date: 2022-04-14 17:00:10
 * @LastEditTime: 2023-07-21 16:47:26
 * @Description: 客户信息 - 展示相关
 */
import Service from '@/utils/request';

export default {
  /**
   * 设计板块相关
   */
  // @设计详情 {id:''}
  getDesignInfo(parmas) {
    return Service.post(`/rs/design/info`, parmas);
  },
  // @设计节点列表 {design_id:''}
  getDesignListApi(parmas) {
    return Service.post(`/rs/design/nodeList`, parmas);
  },
  // @设计节点列表 - 标记完成 {node_id:'',remark: ''}
  getDesignOverApi(parmas) {
    return Service.post(`/rs/design/tagOver`, parmas);
  },
  // @设计节点列表 -通知验收 {design_node_id:'',customer_id:'',remarks: ''}
  getDesignNoticeApi(parmas) {
    return Service.post(`/rs/design/noticeInspected`, parmas);
  },
  // @设计节点列表 - 确认验收 {design_node_id:'',type,'',remarks: ''}
  getDesignSureApi(parmas) {
    return Service.post(`/rs/design/confirmInspected`, parmas);
  },
  // @设计节点列表 - 确认验收参数
  getDesignConfirmParamsApi(parmas) {
    return Service.post(`/rs/design/confirmParams`, parmas);
  },
  // @客户设计列表 - 切换
  getDesignCustomerListApi(parmas) {
    return Service.post(`/rs/design/listCustomerDesign`, parmas);
  },
  // @设计节点列表 - 提前开始 {node_id:''}
  getDesignStartNowApi(parmas) {
    return Service.post(`/rs/design/startNow`, parmas);
  },
  // @设计节点列表 - 上传附件{node_id: '', files: []}
  getDesignUploadFileApi(parmas) {
    return Service.post(`/rs/design/uploadFile`, parmas);
  },

  // @设计启动
  getDesignStartDesign(params) {
    return Service.post(`/rs/design/startDesign`, params);
  },
  // @申请设计启动
  getApplyDesignStart(params) {
    return Service.post(`/rs/contract/applyDesignStart`, params);
  },
  // @获取模板列表
  getDesignGetTemplateList(params) {
    return Service.post(`/rs/design/getTemplateList`, params);
  },
  //节点操作记录
  getDesignNodeLogs(params) {
    return Service.post(`/rs/design/nodeLogs`, params);
  },
  /**
   * v1.2.1 延期单、报备单、洽谈备忘录 相关
   */
  // 延期单、报备单、洽谈备忘录 公共参数
  getDesignDelayOrderParams(params) {
    return Service.post(`/rs/design/delayOrderParams`, params);
  },
  // 延期单顺延周期计算
  getDesignPostponeDesignCycle(params) {
    return Service.post(`/rs/design/postponeDesignCycle`, params);
  },
  // 延期单创建
  getDesignDelayOrder(params) {
    return Service.post(`/rs/design/delayOrder`, params);
  },

  // 报备单创建
  getDesignFilingOrder(params) {
    return Service.post(`/rs/design/filingOrder`, params);
  },
  // 获取报备时间/下次洽谈时间
  getDesignEndCycle(params) {
    return Service.post(`/rs/design/endCycle`, params);
  },
  // 洽谈备忘录创建
  getDesignMemorandumOrder(params) {
    return Service.post(`/rs/design/memorandumOrder`, params);
  },

  // 施工报价列表
  getCustomerQuoteOrder(params) {
    return Service.post(`/rs/customerQuoteOrder/info`, params);
  },
  // 设备电报价单/升级选项报价单-详情
  getChooseQuoteInfo(params) {
    return Service.post(`/rs/customerQuoteOrder/chooseQuoteInfo`, params);
  },
  // 选报必报公共参数
  getCustomerQuoteOrderParams(params) {
    return Service.post(`/rs/customerQuoteOrder/getParams`, params);
  },
  // 已配置-设备电报价/升级选项报价列表
  getChooseQuoteList(params) {
    return Service.post(`/rs/customerQuoteOrder/getChooseQuote`, params);
  },
  // 提交/编辑-设备电报价/升级选项报价
  getChooseQuoteSave(params) {
    return Service.post(`/rs/customerQuoteOrder/chooseQuoteSave`, params);
  },
  // 移除
  getChooseQuoteRemove(params) {
    return Service.post(`/rs/customerQuoteOrder/remove`, params);
  },
  //设计变更单参数
  getChangeOrderParams(params) {
    return Service.post(`/rs/design/changeOrderParams`, params);
  },
  // 设计变更单创建
  getChangeOrder(params) {
    return Service.post(`/rs/design/changeOrder`, params);
  },
};
