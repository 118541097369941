<!--
 * @Author: Jessica
 * @Date: 2022-04-19 09:37:45
 * @LastEditTime: 2023-10-19 10:40:26
 * @Description: 
-->
<template>
  <!-- 加载状态 -->
  <Overlay :show="state.loading" class="overlay-fff">
    <Loading color="#1989fa" type="spinner"></Loading>
  </Overlay>
  <!-- end -->
  <!-- 楼盘组件 -->
  <div class="page-wrap-list">
    <NavBar
      safe-area-inset-top
      title="选择楼盘"
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
    />
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="state.kw"
          placeholder="搜索"
          show-action
          @search="searchFun()"
          @clear="searchFun()"
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff; font-size: 15px">
              搜索
            </div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup v-model="form.checked" v-if="form.list.length > 0">
        <Cell v-for="item in form.list" :key="item.id">
          <div class="data_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="data_item clearfix">
                <p class="name">
                  {{ item.name }}
                </p>
                <p class="extra">
                  {{ item.nick_name ? '别名：' + item.nick_name : '别名：-' }}
                </p>
              </div>
            </Radio>
          </div>
        </Cell>
      </RadioGroup>

      <Divider class="divider-bottom" v-if="form.list.length > 0">
        我也是有底线的
      </Divider>
      <Empty
        v-if="!state.loading && !state.kw && form.list.length == 0"
        :image="require('@/assets/images/empty_floor.png')"
        description="输入楼盘名称搜索..."
      />
      <Empty
        v-if="!state.loading && state.kw && form.list.length == 0"
        :image="require('@/assets/images/empty_floor.png')"
        description="未找到请重新搜索..."
      />
    </div>

    <!-- <div class="footer clearfix">
      <Button
        type="primary"
        size="normal"
        style="width: 100%; margin: 15px 0px; border-radius: 5px"
        @click="btnSumbit()"
      >
        确定
      </Button>
    </div> -->
  </div>
</template>
<script setup>
import { reactive, inject, onMounted, defineProps, defineEmits } from 'vue';
import {
  RadioGroup,
  Radio,
  Search,
  Cell,
  Empty,
  NavBar,
  Notify,
  Overlay,
  Loading,
  Divider,
} from 'vant';
const props = defineProps(['id', 'name']);
const emit = defineEmits(['reviewClose', 'reviewOk']);
const $http = inject('$http');
const state = reactive({
  loading: true,
  kw: props.name ?? '',
});
const form = reactive({
  checked: props.id ? props.id : '',
  checkedList: {},
  //-楼盘列表
  list: [],
  //-单元栋号+size
  all_detail_list: [],
  detail: {
    floor_detail_list: [],
    size: '',
  },
});
onMounted(() => {
  searchFun();
});
//-搜索
const searchFun = () => {
  form.list = [];
  onLoad();
};

//-获取列表
const onLoad = () => {
  state.loading = true;
  $http
    .getFloorsApi({
      kw: state.kw,
    })
    .then((res) => {
      state.loading = false;
      if (res.code === 0) {
        form.list = res.data.floor_list ?? [];
        form.all_detail_list = res.data.floor_detail_list ?? [];
      } else {
        form.list = [];
        Notify({
          type: 'warning',
          message: res.msg || res.data,
        });
      }
    });
};

const getClick = (item) => {
  form.checkedList = item;
  form.checked = item.id;
  changeHouse(item.id);
  emit('reviewOk', form.checkedList, form.detail);
};

//-@ 单元栋号 选择
const changeHouse = (val) => {
  const objArr = val
    ? form.all_detail_list.filter((item) => item.floor_id == val)
    : undefined;
  form.detail.floor_detail_list = objArr?.length ? objArr : [];
  form.detail.size = objArr?.length ? objArr[0].size : '';
};

//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//--基本信息end单---------------------
</script>

<style lang="less" scoped>
@import '@/assets/styles/sale/floorList.less';
</style>
