<!--
 * @Author: gest
 * @Date: 2023-09-08 16:55:55
 * @LastEditTime: 2023-10-27 14:58:51
 * @Description: v1.4.5 阶段验收单详情 （11个见证件）
-->
<template>
  <div class="formBase">
    <!-- v-for -->
    <div
      class="content mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="white_bg p_15 flex_between">
        <div class="flex_between" style="align-items: baseline">
          <div
            class="status_tag mr_5"
            :class="item.tag == 1 ? 'blue' : 'green'"
            v-if="item.tag_name && props.sub_type != 7"
          >
            {{ item.tag_name }}
          </div>
          <div class="font_size_17 font_bold" style="width: 180px">
            {{ item.name }}
          </div>
        </div>
        <div class="grey_color" v-if="props.sub_type != 7">
          {{ item.value == 1 ? '有' : '无' }}
        </div>
      </div>

      <div
        class="flex_between align_center line_info"
        @click="arrowClick(item)"
      >
        <div class="grey_color font_size_13">
          共{{ item.children.length }}项
          <span
            v-show="
              ((item.value == 1 && props.sub_type != 7) ||
                props.sub_type == 7) &&
              getTotalFun(item.children, '空值') != 0
            "
            >，<span class="red_color">{{
              getTotalFun(item.children, '空值')
            }}</span
            >个未填</span
          >
        </div>
        <div class="right">
          <div
            class="r_item"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <div class="green_color font_size_17">
              {{ getTotalFun(item.children, '合格') }}
            </div>
            <div class="grey_color font_size_12">合格</div>
          </div>
          <div
            class="vline"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          ></div>
          <div
            class="r_item"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <div class="red_color font_size_17">
              {{ getTotalFun(item.children, '不合格') }}
            </div>
            <div class="grey_color font_size_12">不合格</div>
          </div>
          <div
            class="vline"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          ></div>
          <div
            class="r_item"
            v-if="
              (item.value == 1 && props.sub_type != 7) || props.sub_type == 7
            "
          >
            <div class="grey_color font_size_17">
              {{ getTotalFun(item.children, '无此项') }}
            </div>
            <div class="grey_color font_size_12">无此项</div>
          </div>
          <div class="ml_5" style="height: 40px; line-height: 40px">
            <Icon name="arrow-up" class="grey_color" v-if="item.arrow == 1" />
            <Icon name="arrow-down" class="grey_color" v-else />
          </div>
        </div>
      </div>

      <div class="white_bg" v-show="item.arrow == 1">
        <div
          class="cell_border flex_between"
          v-for="(v, i) in item.children"
          :key="i"
        >
          <div class="font_size_15 flex1 mr_20">{{ v.name }}</div>
          <div
            class="green_color font_size_15"
            v-if="
              ((item.value == 1 && props.sub_type != 7) ||
                props.sub_type == 7) &&
              v.value == 1
            "
          >
            合格
          </div>
          <div
            class="red_color font_size_15"
            v-if="
              ((item.value == 1 && props.sub_type != 7) ||
                props.sub_type == 7) &&
              v.value == 2
            "
          >
            不合格
          </div>
          <div
            class="grey_color font_size_15"
            v-if="
              ((item.value == 1 && props.sub_type != 7) ||
                props.sub_type == 7) &&
              v.value == 3
            "
          >
            无此项
          </div>
        </div>
      </div>
    </div>
    <!-- v-for end-->
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div class="grey_color">验收时间</div>
            <div class="">{{ form.info.accept_time || '-' }}</div>
          </div>
        </div>
        <div
          class="field_item"
          v-if="props.sub_type != 8 && props.sub_type != 7"
        >
          <div class="flex_between">
            <div class="grey_color">特定项验收</div>
            <div>
              {{
                form.info.special_item == 1
                  ? '有'
                  : form.info.special_item == 2
                  ? '无'
                  : '-'
              }}
            </div>
          </div>
        </div>
        <div
          class="field_item"
          v-if="
            props.sub_type != 8 &&
            props.sub_type != 7 &&
            form.info.special_item_remark
          "
        >
          <div>
            {{ form.info.special_item_remark }}
          </div>
        </div>
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>
            {{ form.info.remark || '-' }}
          </div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted } from 'vue';
import { Icon } from 'vant';
import FileGrid from '@/components/common/FileGrid';

const props = defineProps(['data', 'sub_type']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
});

//-计算 未填、合格、不合格数
const getTotalFun = (data, name) => {
  let list = [];
  if (name == '空值') {
    list = data.filter((v) => {
      return !v.value;
    });
  } else if (name == '合格') {
    list = data.filter((v) => {
      return v.value == 1;
    });
  } else if (name == '不合格') {
    list = data.filter((v) => {
      return v.value == 2;
    });
  } else if (name == '无此项') {
    list = data.filter((v) => {
      return v.value == 3;
    });
  }
  return list.length;
};
const arrowClick = (item) => {
  if (item.arrow == 1) {
    item.arrow = '';
  } else {
    item.arrow = 1;
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
