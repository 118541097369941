<!--
 * @Author: Jessica
 * @Date: 2023-06-17 16:36:06
 * @LastEditTime: 2024-05-28 16:27:08
 * @Description: 目标数据-转化数
-->
<template>
  <!-- 转化数 -->
  <div class="main-data">
    <div class="warpper" @click.stop="redictFun">
      <Skeleton
        title
        avatar
        :row="3"
        :loading="loading || props.loading"
        style="padding: 5px 0px 15px"
      >
        <div class="top">
          <div class="flex">
            <div class="icon">
              <img
                :src="require('@/assets/images/target-data/t_11.png')"
                alt="icon"
              />
            </div>
            <div class="number">
              <div class="temp1">
                转化数
                <em>{{ detail.total?.actual ?? '-' }}</em>
                <span class="font-size15">{{
                  ' /' + detail.total?.all ?? '-'
                }}</span>
              </div>
              <div class="temp2">
                <!-- 目标值 -->
                <div class="target">
                  <em>目标</em>
                  <em class="pl-3">{{
                    detail.total?.target === '' ? '-' : detail.total?.target
                  }}</em>
                  <!-- 规则入口 -->
                  <div
                    class="icon_info_box_small pl-3"
                    @click.stop="createDanger"
                  >
                    <img
                      class="icon_img"
                      :src="
                        require('@/assets/images/target-data/icon_info.png')
                      "
                      alt="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="number">
              <div class="temp2 flex_right">
                <!-- 差值 -->
                <div class="gap" v-if="detail.total?.gap !== ''">
                  <!-- 超目标 -->
                  <p
                    v-if="
                      parseFloat(detail.total?.target ?? 0) <
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c1">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="require('@/assets/images/target-data/up_total.png')"
                      alt="down"
                    />
                  </p>
                  <!-- 距目标 -->
                  <p
                    v-else-if="
                      parseFloat(detail.total?.target ?? 0) >
                      parseFloat(detail.total?.actual ?? 0)
                    "
                  >
                    <em class="c2">{{ detail.total?.gap ?? '-' }}</em>
                    <img
                      :src="
                        require('@/assets/images/target-data/down_total.png')
                      "
                      alt="down"
                    />
                  </p>
                  <!-- 已达标 -->
                  <p v-else>
                    <img
                      :src="
                        require('@/assets/images/target-data/equal_total.png')
                      "
                      alt="down"
                    />
                  </p>
                </div>
              </div>
              <div class="temp1 text_right">
                <span class="font-size12">完成率</span>
                <span class="font-size12 pl-3">
                  {{
                    detail.total?.done_rate === ''
                      ? '-'
                      : detail.total?.done_rate + '%'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <!-- 当年 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">当年</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.now?.target === '' ? '-' : detail.now?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.now?.actual ?? '-' }}</em>
                    <span>{{ ' /' + detail.now?.all ?? '-' }}</span>
                  </p>
                  <p class="temp3">转化率{{ detail.now?.rate + '%' ?? '-' }}</p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.now?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.now?.target ?? 0) <
                          parseFloat(detail.now?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.now?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.now?.target ?? 0) >
                          parseFloat(detail.now?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.now?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">{{
                        detail.now?.done_rate === ''
                          ? '-'
                          : detail.now?.done_rate + '%'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 存量 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">存量</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.passed?.target === ''
                          ? '-'
                          : detail.passed?.target
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.passed?.actual ?? '-' }}</em>
                    <span>{{ ' /' + detail.passed?.all ?? '-' }}</span>
                  </p>
                  <p class="temp3">
                    转化率{{ detail.passed?.rate + '%' ?? '-' }}
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.passed?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.passed?.target ?? 0) <
                          parseFloat(detail.passed?.actual ?? 0)
                        "
                      >
                        <em class="c1">{{ detail.passed?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.passed?.target ?? 0) >
                          parseFloat(detail.passed?.actual ?? 0)
                        "
                      >
                        <em class="c2">{{ detail.passed?.gap ?? '-' }}</em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                    <!-- 完成率 -->
                    <div class="rate">
                      <em>完成率</em>
                      <em class="pl-3">{{
                        detail.passed?.done_rate === ''
                          ? '-'
                          : detail.passed?.done_rate + '%'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 履约率 -->
          <div class="item b_b clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">履约率</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.reach_rate?.target_rate === ''
                          ? '-'
                          : detail.reach_rate?.target_rate + '%'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.reach_rate?.actual_rate + '%' ?? '-' }}</em>
                  </p>
                  <p class="temp3">
                    <span>{{ detail.reach_rate?.reach_num ?? '-' }}</span>
                    <span>{{ '/' + detail.reach_rate?.all_num ?? '-' }}</span>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.reach_rate?.gap_rate !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.reach_rate?.target_rate ?? 0) <
                          parseFloat(detail.reach_rate?.actual_rate ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.reach_rate?.gap_rate + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.reach_rate?.target_rate ?? 0) >
                          parseFloat(detail.reach_rate?.actual_rate ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.reach_rate?.gap_rate + '%' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <!-- 当年转化时间 -->
          <div class="item clearfix">
            <Row>
              <Col class="flex_left" span="6">
                <div class="col">
                  <p class="t">转化时间</p>
                  <div class="temp2">
                    <!-- 目标值 -->
                    <div class="target">
                      <em>目标</em>
                      <em class="pl-3">{{
                        detail.now_time?.target === ''
                          ? '-'
                          : detail.now_time?.target + '天'
                      }}</em>
                    </div>
                  </div>
                </div>
              </Col>
              <Col class="flex_right" span="8">
                <div class="col">
                  <p class="temp1">
                    <em>{{ detail.now_time?.actual + '天' ?? '-' }}</em>
                  </p>
                </div>
              </Col>
              <Col class="flex_right" span="10">
                <div class="col">
                  <div class="temp2">
                    <!-- 差值 -->
                    <div class="gap" v-if="detail.now_time?.gap !== ''">
                      <!-- 超目标 -->
                      <p
                        v-if="
                          parseFloat(detail.now_time?.target ?? 0) >
                          parseFloat(detail.now_time?.actual ?? 0)
                        "
                      >
                        <em class="c1">
                          {{ detail.now_time?.gap + '天' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/up_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 距目标 -->
                      <p
                        v-else-if="
                          parseFloat(detail.now_time?.target ?? 0) <
                          parseFloat(detail.now_time?.actual ?? 0)
                        "
                      >
                        <em class="c2">
                          {{ detail.now_time?.gap + '天' ?? '-' }}
                        </em>
                        <img
                          :src="
                            require('@/assets/images/target-data/down_total.png')
                          "
                          alt="down"
                        />
                      </p>
                      <!-- 已达标 -->
                      <p v-else>
                        <img
                          :src="
                            require('@/assets/images/target-data/equal_total.png')
                          "
                          alt="down"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  defineProps,
  watch,
  defineEmits,
  defineExpose,
} from 'vue';
import { Notify, Skeleton, Row, Col } from 'vant';
import { useRouter } from 'vue-router';
const emit = defineEmits(['createDanger']);
const router = useRouter();
const detail = ref([]);
const loading = ref(false);
const $http = inject('$http');
const props = defineProps(['data', 'loading']);
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.yearId != cur.yearId) {
      //切换年份
      onLoad();
    }
  }
);

/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http.targetStatistic_homeDesign({ year: props.data.yearId }).then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      detail.value = data;
    } else {
      loading.value = false;
      detail.value = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

//-跳转路由
const redictFun = () => {
  // subtype-1:获客，2:转化，3：交付
  router.push({
    path: '/allTargetData',
    query: { subtype: 2, pos: 2 },
  });
};

//-规则说明--begin
const htmlTitle = '转化数_规则';
const htmlContent = `<p>1、【尚层装饰室内设计协议】已签约【家庭居室装饰装修工程施工合同】【优选整装工程施工合同】，【已签约】状态且【财务确认收首款】或产品款+产品预收款+产品返款金额≥500000的项目，转化计数1；</p>
<p>2、【尚层装饰配饰设计协议】产品款+产品预收款+产品返款金额≥200000，计数0.5；</p>
<p>3、当年：当年签约协议当年转化，存量：非当年签约协议当年转化；</p><p>4 、履约：转化周期≤设计协议开始到结束周期；</p>`;
const createDanger = () => {
  emit('createDanger', 'transformRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/targetIndex.less';
</style>
