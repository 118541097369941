<!--
 * @Author: Jessica
 * @Date: 2023-06-06 10:03:37
 * @LastEditTime: 2023-06-15 09:03:53
 * @Description: v1.3.8 自购-安装计划
-->
<template>
  <div class="table_box">
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 内容开始 -->
    <div class="list-box">
      <div v-for="(item, index) in list" :key="index" class="cell-same">
        <!-- 产品内容 -->
        <div class="info_box">
          <div
            class="left"
            v-if="item.img && item.img.length > 0"
            @click.stop="fileDetail(item.img[0], item.img)"
          >
            <Image
              class="avader_img"
              :src="
                item.img.length
                  ? $host + item.img[0]['path']
                  : require('@/assets/images/supplier-avder-empty.png')
              "
            />
          </div>
          <div v-else class="left icon_name">自购</div>
          <div class="right">
            <div class="name_box">
              <p class="name" style="flex: 1">{{ item.name }}</p>
              <!-- <div class="status_tag red">备货时间紧张</div> -->
            </div>
            <p class="info">
              <span class="t1">{{ item.space_name || '-' }}</span>
              <span class="t1">｜{{ item.category_sub_name || '-' }}</span>
              <span class="t1">｜{{ item.brand || '-' }}</span>
              <span class="t1">｜{{ item.model || '-' }}</span>
              <span class="t1">｜{{ item.norms || '-' }}</span>
              <span class="t1">｜{{ item.material || '-' }}</span>
              <span class="t1">｜{{ item.colour || '-' }}</span>
            </p>
          </div>
        </div>
        <!-- 各类节点时间 -->
        <div class="time_box">
          <div class="line clearfix">
            <p class="c1 t1"></p>
            <p class="c1 t2">节点</p>
            <p class="c1 t3">预计时间</p>
            <p class="c1 t4">实际完成时间</p>
          </div>
          <div class="line clearfix">
            <p class="c1 t1">下单</p>
            <p class="c2 t2">{{ item.order_att_name || '-' }}</p>
            <p class="c2 t3">{{ item.plan_order_time || '-' }}</p>
            <p class="c2 t4">{{ item.actual_order_time || '-' }}</p>
          </div>
          <div class="line clearfix">
            <p class="c1 t1">测量</p>
            <p class="c2 t2">{{ item.measure_att_name || '-' }}</p>
            <p class="c2 t3">{{ item.plan_measure_time || '-' }}</p>
            <p class="c2 t4">{{ item.actual_measure_time || '-' }}</p>
          </div>
          <div class="line clearfix">
            <p class="c1 t1">收货</p>
            <p class="c2 t2">{{ item.receive_att_name || '-' }}</p>
            <p class="c2 t3">{{ item.plan_receive_time || '-' }}</p>
            <p class="c2 t4">{{ item.onsite_receipt_time || '-' }}</p>
          </div>
          <div class="line clearfix">
            <p class="c1 t1">安装</p>
            <p class="c2 t2">{{ item.install_att_name || '-' }}</p>
            <p class="c2 t3">{{ item.plan_install_time || '-' }}</p>
            <p class="c2 t4">{{ item.complete_install_time || '-' }}</p>
          </div>
          <div class="line clearfix">
            <p class="c1 t1">验收</p>
            <p class="c2 t2">{{ item.acceptance_att_name || '-' }}</p>
            <p class="c2 t3">{{ item.plan_accept_time || '-' }}</p>
            <p class="c2 t4">{{ item.install_acceptance_time || '-' }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 空状态 -->
    <Empty
      class="empty-fff"
      description="暂无数据"
      v-if="!loading && list.length === 0"
    />
  </div>
</template>
<script setup>
import { ref, inject, defineProps, onMounted } from 'vue';
import { Notify, Empty, Image, Overlay, Loading } from 'vant';
import { fileDetail } from '@/utils/fileUpload';
const list = ref([]);
const loading = ref(false);
const $http = inject('$http');
const $host = inject('$host');
const props = defineProps(['id']);

onMounted(() => {
  onLoad();
});
/**
 * 接口
 */
//-@ 加载

const onLoad = () => {
  loading.value = true;
  $http.mainSelfOrder_installTimeList({ id: props.id ?? '' }).then((res) => {
    const { code, data } = res;
    loading.value = false;
    if (code === 0) {
      list.value = data.list;
    } else {
      loading.value = false;
      list.value = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

/*
 * 操作
 */
</script>
<style lang="less" scoped>
.table_box {
  .list-box {
    .cell-same {
      border: none;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid rgba(228, 228, 228, 0.3);
      position: relative;
      padding: 0 16px 0px;
      margin-bottom: 10px;
      background: #fff;
      .info_box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 10px 0px 0;
        .left {
          .avader_img {
            width: 40px;
            height: 40px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .right {
          flex: 1;
          padding-left: 12px;

          .name_box {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .name {
              font-size: 17px;
              font-weight: bold;
              line-height: 20px;
            }
          }
          // .info {
          //   .t1 {
          //     font-size: 13px;
          //     color: #767676;
          //     line-height: 20px;
          //     border-right: 1px solid #767676;
          //     padding: 0 5px;
          //     &.blue {
          //       color: #576b95;
          //     }
          //     &:last-child {
          //       border-right: none;
          //     }
          //     &:first-child {
          //       padding-left: 0;
          //     }
          //   }
          // }
          .t1 {
            font-size: 14px;
            color: #888888;
          }
        }
      }
      .time_box {
        padding: 10px 0px;
        .line {
          background: #fbfbfb;
          border-bottom: 1px solid #ffffff;
          .c1,
          .c2 {
            border-right: 1px solid #ffffff;
            float: left;
            font-size: 12px;
            padding: 5px 5px;
          }
          .c1 {
            color: #8d9091;
            &.t1 {
              text-align: center;
            }
          }
          .c2 {
            color: #000000;
          }
          .t1 {
            width: 45px;
          }

          .t2,
          .t3,
          .t4 {
            width: calc(33.3% - 15px);
          }
          .t4 {
            border-right: none;
          }
        }
      }
    }
  }
}
.icon_name {
  background: rgba(0, 137, 17, 0.1);
  color: rgba(0, 137, 17, 0.4);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
</style>
