/*
 * @Author: gest
 * @Date:2022-07-21 09:15:16
 * @LastEditTime: 2022-09-09 17:21:49
 * @Description:权限
 */

import Service from '@/utils/request';

const BpmStore = {
  namespaced: true,
  state() {
    return {
      //操作权限
      BPM: null,
    };
  },
  actions: {
    //操作权限
    getBpmAction({ commit }, customer_id) {
      return new Promise((resolve) => {
        Service.post(`/rs/company/getBpm`, { customer_id }).then((res) => {
          const { code, data } = res;
          if (code === 0) {
            commit('handleBPM', data.bpm_id);
            resolve(data.bpm_id);
          } else {
            commit('handleBPM', null);
          }
        });
      });
    },
  },
  mutations: {
    handleBPM: (state, val) => {
      state.BPM = val;
    },
  },
};

export default BpmStore;
