<!--
 * @Author:gest
 * @Date: 2022-11-08 18:44:53
 * @LastEditTime: 2023-08-24 09:24:23
 * @Description: 获客确认见面
 * v1.4.4 见面结果选择“已见面-客户满意”增加必填项 “回访客户反馈”
-->
<template>
  <div class="page-wrap">
    <Overlay :show="state.loadingConfirm" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- 表单操作 -->
    <div class="formBase">
      <Form ref="ruleFormRef" :disabled="state.showType == 'look'">
        <CellGroup>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              见面结果
            </label>
            <Field
              v-model="form.list.meeting_result_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择见面结果',
                },
              ]"
              placeholder="请选择见面结果"
              @click="
                () => {
                  if (state.showType != 'look') {
                    state.showPicker = true;
                  }
                }
              "
            />
          </div>
          <div
            class="cell"
            v-if="
              form.list.meeting_result == 363 ||
              form.list.meeting_result == 367 ||
              form.list.meeting_result == 369 ||
              form.list.meeting_result == 482
            "
          >
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              实际见面日期
            </label>
            <Field
              v-model="form.list.reality_meeting_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择实际见面日期"
              :rules="[
                {
                  required: true,
                  message: '请选择实际见面日期',
                },
              ]"
              @click="
                () => {
                  if (state.showType != 'look') {
                    openDateType('reality_meeting_time');
                  }
                }
              "
            />
          </div>
          <div
            class="cell"
            v-if="
              form.list.meeting_result == 364 ||
              form.list.meeting_result == 365 ||
              form.list.meeting_result == 368
            "
          >
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              下次预计见面时间
            </label>
            <Field
              v-model="form.list.reality_meeting_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="请选择下次预计见面时间"
              :rules="[
                {
                  required: true,
                  message: '请选择下次预计见面时间',
                },
              ]"
              @click="
                () => {
                  if (state.showType != 'look') {
                    openDateType('reality_meeting_time');
                  }
                }
              "
            />
          </div>
          <div class="cell" v-if="form.list.meeting_result == 363">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              回访客户反馈
            </label>
            <Field
              v-model="form.list.customer_feedback"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="请填写回访客户反馈"
              show-word-limit
              :rules="[
                {
                  required: true,
                  message: '请填写回访客户反馈',
                },
              ]"
            />
          </div>
          <div class="cell">
            <label class="form_title">备注</label>
            <Field
              v-model="form.list.remark"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              placeholder="此备注下个节点操作人可见"
              show-word-limit
            />
          </div>
        </CellGroup>
      </Form>
    </div>
    <div class="btnSumbit" v-if="state.showType != 'look'">
      <Button class="btn" type="primary" size="large" @click.stop="btnSumbit">
        完成
      </Button>
    </div>
    <!-- popup集合 -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        type="datetime"
        title="选择日期"
        @confirm="onConfirmDate"
        cancel-button-text="清除"
        @cancel="
          () => {
            state.showTimePicker = false;
            form.list.reality_meeting_time = '';
          }
        "
      />
    </Popup>
    <!--picker -见面结果-->
    <Popup v-model:show="state.showPicker" position="bottom">
      <Picker
        :columns="state.meeting_result_List"
        :columns-field-names="customFieldName"
        @confirm="onConfirm"
        @cancel="state.showPicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { inject, reactive, ref, onBeforeMount } from 'vue';
import {
  Form,
  CellGroup,
  Field,
  Button,
  Popup,
  DatetimePicker,
  Picker,
  Toast,
  Notify,
  Overlay,
  Loading,
} from 'vant';
import moment from 'moment';
import { useRouter } from 'vue-router';

const router = useRouter();
const $http = inject('$http');
const state = reactive({
  loadingConfirm: false,
  //-待办时间
  taskDate: router.currentRoute.value.query
    ? router.currentRoute.value.query.date
    : '',
  showType: router.currentRoute.value.query
    ? router.currentRoute.value.query.showType
    : '',
  //-时间控件
  showTimePicker: false,
  timeType: '',
  currentDate: new Date(),
  //-picker
  showPicker: false,
  meeting_result_List: [], //见面结果
});

//--基本信息表单---------------------
const ruleFormRef = ref(null);
const form = reactive({
  list: {
    todo_id: router.currentRoute.value.query
      ? router.currentRoute.value.query.id
      : '',
    meeting_result: '',
    meeting_result_name: '',
    reality_meeting_time: '',
    remark: '',
    customer_feedback: '', //v1.4.4 回访客户反馈
  },
});
//-获取参数--begin
onBeforeMount(() => {
  getParmarsList();
});

//-获取参数
const getParmarsList = async () => {
  state.loadingConfirm = true;
  await $http.getDispatchParams().then((res) => {
    if (res.code === 0) {
      state.meeting_result_List = res.data.meeting_result;
      if (state.showType == 'look') {
        getConfirmMeetingInfo();
      } else {
        state.loadingConfirm = false;
      }
    } else {
      state.loadingConfirm = false;
      Toast(res.data);
    }
  });
};
const getConfirmMeetingInfo = async () => {
  await $http
    .getConfirmMeetingInfo({
      id: form.list.todo_id,
      type: 1,
    })
    .then((res) => {
      state.loadingConfirm = false;
      if (res.code === 0) {
        form.list.meeting_result = res.data.meeting_result;
        form.list.meeting_result_name = res.data.meeting_result_name;
        form.list.reality_meeting_time = res.data.face_time;
        form.list.remark = res.data.remark;
        form.list.customer_feedback = res.data.customer_feedback;
      } else {
        Toast(res.data);
      }
    });
};
//-获取参数--end
//-下一步操作
const btnSumbit = () => {
  if (!ruleFormRef.value) return;
  ruleFormRef.value
    .validate()
    .then(() => {
      if (form.list.meeting_result == 381 && !form.list.remark) {
        Notify({ type: 'warning', message: '请在备注填写具体原因！' });
        return;
      }
      state.loadingConfirm = true;
      $http
        .confirmMeetingForNetwork({
          ...form.list,
        })
        .then((res) => {
          const { code, data } = res;
          state.loadingConfirm = false;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                router.back();
              },
            });
          } else {
            Notify({ type: 'warning', message: data });
          }
        });
    })
    .catch(() => {
      //验证失败
      Toast('完善信息');
    });
};

//--基本信息表单-----end----------------

//-时间控件--begin
const openDateType = (type) => {
  state.showTimePicker = true;
  state.timeType = type;
  if (form['list'][type]) {
    state.currentDate = new Date(form['list'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value) => {
  form['list'][state.timeType] = moment(value).format('YYYY-MM-DD HH:mm');
  state.showTimePicker = false;
};

//-时间控件--end

//-picker-选择项字段--begin
const customFieldName = {
  text: 'name',
  value: 'id',
};
const onConfirm = (value) => {
  if (value) {
    form.list.meeting_result = value.id;
    form.list.meeting_result_name = value.name;
  }
  state.showPicker = false;
};

//-picker-选择项字段--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/moreOperate/index.less';
</style>
