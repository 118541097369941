<!--
 * @Author: NanNan
 * @Date: 2022-04-21 14:12:11
 * @LastEditTime: 2023-09-26 11:53:38
 * @Description: 补充执行人信息
-->
<template>
  <div class="page-box formBase">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <NavBar
      safe-area-inset-top
      title="补充执行人信息"
      left-arrow
      @click-left="onClosePop"
      :fixed="true"
      v-if="edit_type"
    />
    <!-- 表单填写 -->
    <Form class="form-box" :class="{ mt58: edit_type }" ref="formRef">
      <p class="label-name">
        <em>*</em>
        执行人姓名
      </p>
      <Field
        v-model="form_info.info.operator_name"
        placeholder="填写执行人姓名"
        maxlength="20"
        :rules="[
          { required: true, trigger: 'onChange', message: '请填写执行人姓名' },
        ]"
      />

      <p class="label-name">手机号码</p>
      <Field
        v-model.trim="form_info.info.operator_phone"
        placeholder="填写手机号码"
        maxlength="11"
        name="validator"
        :rules="[
          {
            validator: validatorPhone,
            message: '请填写正确的手机号码',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">联系地址</p>
      <Field
        v-model="form_info.info.operator_address"
        placeholder="填写联系地址"
        maxlength="40"
      />

      <p class="label-name">电子邮箱</p>
      <Field
        v-model="form_info.info.operator_email"
        placeholder="填写电子邮箱"
        maxlength="40"
        name="validator"
        :rules="[
          {
            validator: validatorEmail,
            trigger: 'onChange',
            message: '请填写正确的电子邮箱',
          },
        ]"
      />

      <p class="label-name">微信号</p>
      <Field
        v-model="form_info.info.operator_wechat"
        placeholder="填写微信号"
        maxlength="20"
        name="validator"
        :rules="[
          {
            validator: validatorWechat,
            message: '请填写正确的微信账号',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">QQ号</p>
      <Field
        v-model="form_info.info.operator_qq"
        placeholder="填写QQ号"
        maxlength="20"
        type="digit"
        name="validator"
        :rules="[
          {
            validator: validatorQq,
            message: '请填写正确的QQ号',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">收货地址</p>
      <Field
        v-model="form_info.info.operator_delivery_address"
        placeholder="填写收货地址"
        maxlength="40"
      />

      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>
    <!-- end -->
  </div>
</template>
<script setup>
import {
  inject,
  onMounted,
  reactive,
  ref,
  defineProps,
  defineEmits,
} from 'vue';
import {
  Form,
  Field,
  Button,
  Notify,
  NavBar,
  Loading,
  Overlay,
  Toast,
} from 'vant';
import patterns from '@/utils/pattern';
import { useRouter } from 'vue-router';

const $http = inject('$http');
const formRef = ref(null);
const router = useRouter();
const form_info = reactive({
  info: {},
});
const loading = ref(false);
onMounted(() => {
  loadFunc();
});

const props = defineProps(['data']);
const emit = defineEmits(['reviewClose', 'reviewOk']);
//-判断是否从合同入口-补充客户信息
const edit_type = props.data ? props.data.complete_card_Type : '';
const customer_id = props.data
  ? props.data.customer_id
  : router.currentRoute.value.query
  ? router.currentRoute.value.query.id
  : '';
/**
 * 接口调用
 */
const loadFunc = () => {
  loading.value = true;
  $http.getSaleInfoExectorApi({ id: customer_id }).then((res) => {
    const { code, data } = res;
    if (code === 0) {
      form_info.info = data;
    } else {
      Notify({ type: 'warning', message: data });
    }
    loading.value = false;
  });
};

/**
 * 校验方式
 */
const { emailReg, wechatReg, qqReg, phoneReg } = patterns;
const validatorEmail = (val) => (val ? emailReg.test(val) : true);
const validatorWechat = (val) => (val ? wechatReg.test(val) : true);
const validatorQq = (val) => (val ? qqReg.test(val) : true);
const validatorPhone = (val) => (val ? phoneReg.test(val) : true);
/**
 * 表单操作
 */
//-取消
const onClosePop = () => {
  emit('reviewClose');
};
//@提交
const onSubmit = () => {
  formRef.value
    ?.validate()
    .then(() => {
      loading.value = true;
      $http
        .getSaleInfoExectorEditApi(form_info.info)
        .then((res) => {
          const { data, code } = res;
          if (code === 0) {
            Notify({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                if (edit_type) {
                  emit('reviewOk');
                } else {
                  router.push('/saleDetail?id=' + customer_id);
                }
                loading.value = false;
              },
            });
          } else {
            loading.value = false;
            Notify({ type: 'warning', message: data });
          }
        })
        .catch(() => {
          loading.value = false;
          //验证失败
        });
    })
    .catch(() => {
      Toast('请先完善信息');
    });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/sale/addExecutor.less';
</style>
