<!--
 * @Author: gest
 * @Date:2023-09-12 14:24:24
 * @LastEditTime: 2023-10-27 11:22:46
 * @Description: v1.4.5 交底记录单 （预交底记录单，二次交底单）
-->
<template>
  <div class="formBase">
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div
          class="field_item"
          v-for="(item, index) in form.info.users"
          :key="index"
        >
          <div class="flex_between">
            <div class="grey_color">{{ item.role_name }}</div>
            <div class="">{{ item.name || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="label-name">
            <span class="red_color">*</span>记录单内容
          </div>
          <Field
            v-model="form.info.content"
            rows="5"
            autosize
            type="textarea"
            maxlength="3000"
            placeholder="请填写清楚预交底的具体内容与客户需求..."
            show-word-limit
            :rules="[
              {
                required: true,
                message: '请填写记录单内容',
              },
            ]"
          ></Field>
        </div>
        <div class="field_item">
          <div class="label-name">附件</div>
          <FileUpload
            :type="4"
            :data="form.info.files"
            @update:func="(list) => getFileList(list)"
          ></FileUpload>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item right">
          <div class="flex_between">
            <div><span class="red_color">*</span>交底日期</div>
            <Field
              style="text-align: right"
              v-model="form.info.accept_time"
              right-icon="arrow"
              name="picker"
              placeholder="选择"
              readonly
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
              @click="openDateType('accept_time')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content mb_15" v-if="props.sub_type == 26">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="flex_between">
            <div>特定项验收项</div>
            <Field
              name="radio"
              label=""
              :rules="[
                {
                  required: true,
                  message: '请选择',
                },
              ]"
            >
              <template #input>
                <RadioGroup
                  v-model="form.info.special_item"
                  direction="horizontal"
                  checked-color="#0256ff"
                >
                  <Radio :name="1">有</Radio>
                  <Radio :name="2">无</Radio>
                </RadioGroup>
              </template>
            </Field>
          </div>
        </div>
        <div class="field_item" v-if="form.info.special_item == 1">
          <Field
            v-model="form.info.special_item_remark"
            rows="5"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="若有特定的验收项，请填写特定事项验收情况"
            show-word-limit
            :rules="[
              {
                required: true,
                message: '请填写',
              },
            ]"
          ></Field>
        </div>
      </div>
    </div>

    <!-- popup -->
    <!-- 时间选择控件 -->
    <Popup v-model:show="state.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="state.currentDate"
        :max-date="state.maxDate"
        type="date"
        title="选择日期"
        @confirm="(v) => onConfirmDate(v, 'accept_time')"
        @cancel="state.showTimePicker = false"
      />
    </Popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Field, RadioGroup, Radio, Popup, DatetimePicker } from 'vant';
import FileUpload from '@/components/common/FileUpload';
import moment from 'moment';
const emit = defineEmits(['update:data']);

const props = defineProps(['data', 'sub_type']);
const state = reactive({
  //-时间控件
  maxDate: new Date(),
  showTimePicker: false,
  currentDate: new Date(),
});
const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

// @-时间控件
const openDateType = (type) => {
  state.showTimePicker = true;
  if (form['info'][type]) {
    state.currentDate = new Date(form['info'][type]);
  } else {
    state.currentDate = new Date();
  }
};

const onConfirmDate = (value, type) => {
  state.showTimePicker = false;
  form['info'][type] = moment(value).format('YYYY-MM-DD');
};
const getFileList = (files) => {
  form.info.files = files;
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
