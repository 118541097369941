/*
 * @Author: gest
 * @Date: 2023-09-19 18:37:15
 * @LastEditTime: 2023-09-19 18:44:46
 * @Description:V1.4.6 html转图片
 */
import { ref } from 'vue';
import html2Canvas from 'html2canvas';
const htmlImage = ref(null);
// const htmlImage = new Image();
const htmlToImg = (dom) => {
  var imageDom = document.querySelector(dom);
  html2Canvas(imageDom, {
    allowTaint: true,
    useCORS: true,
    scrollY: 0,
    scrollX: 0,
  }).then((canvas) => {
    // 将生成的 canvas 元素转换为图片 URL
    const imageDataURL = canvas.toDataURL('image/png');
    // imageElement.src = imageDataURL;
    htmlImage.value = imageDataURL;
  });
};
export { htmlToImg, htmlImage };
