<!--
 * @Author: NanNan
 * @Date: 2022-05-25 10:26:13
 * @LastEditTime: 2023-02-21 16:49:41
 * @Description: v0.3.0 定期回访
 * v1.3.2 全部待办更改，router.back哪里来回哪里去
-->
<template>
  <div class="page-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>
    <!-- end -->
    <Form class="form-box" ref="formRef">
      <p class="label-name">
        <em>*</em>
        回访类型
      </p>
      <Field
        v-model="form_info.type_id_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择回访类型"
        @click="_DATA.type_show = true"
        :rules="[
          {
            required: true,
            message: '请选择回访类型',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        回访结果
      </p>
      <Field
        v-model="form_info.result_id_name"
        right-icon="arrow-down"
        readonly
        placeholder="选择回访结果"
        @click="_DATA.result_show = true"
        :rules="[
          {
            required: true,
            message: '请选择回访结果',
            trigger: 'onChange',
          },
        ]"
      />

      <p class="label-name">
        <em>*</em>
        回访日期
      </p>
      <Field
        v-model="form_info.visit_time"
        name="picker"
        readonly
        placeholder="选择回访日期"
        right-icon="underway-o"
        @click="_DATA.time_show = true"
        :rules="[
          { required: true, trigger: 'onChange', message: '请选择回访日期' },
        ]"
      />

      <p class="label-name">回访内容</p>
      <Field
        v-model="form_info.remarks"
        rows="3"
        autosize
        type="textarea"
        maxlength="1000"
        placeholder="添加回访内容..."
        show-word-limit
      ></Field>

      <!-- 附件组件 -->
      <p class="label-name">附件</p>
      <FileUpload
        :data="form_info.files"
        @update:func="(list) => getFileList(list)"
      ></FileUpload>
      <!-- end -->

      <div class="stick-box">
        <Button type="primary" size="large" @click="onSubmit">确定</Button>
      </div>
    </Form>

    <!-- 年月日 组件 -->
    <Popup v-model:show="_DATA.time_show" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择回访日期"
        @confirm="changePicker"
        @cancel="_DATA.time_show = false"
      />
    </Popup>
    <!-- 回访类型 -->
    <Popup v-model:show="_DATA.type_show" position="bottom">
      <Picker
        :columns="_DATA.visit_type_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="changeType"
        @cancel="_DATA.type_show = false"
      />
    </Popup>
    <!-- 回访结果 -->
    <Popup v-model:show="_DATA.result_show" position="bottom">
      <Picker
        :columns="_DATA.visit_result_list"
        :columns-field-names="{
          text: 'name',
          value: 'id',
        }"
        @confirm="changeResult"
        @cancel="_DATA.result_show = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { onMounted, reactive, inject, ref } from 'vue';
import {
  Form,
  Field,
  Notify,
  Loading,
  Overlay,
  Button,
  Popup,
  DatetimePicker,
  Picker,
} from 'vant';
import { useRouter } from 'vue-router';

import moment from 'moment';

const $http = inject('$http');
const loading = ref(false);
const router = useRouter();

const _DATA = reactive({
  customer_id: router.currentRoute.value.query.id, //携带客户id
  date: router.currentRoute.value.query.date, //携带 待办日期
  sid: router.currentRoute.value.query.sid, //回访数据source_id
  dtype: router.currentRoute.value.query.dtype, //全部待办 带来的生命周期阶段

  visit_type_list: [], //公共参数-回访类型
  visit_result_list: [], //公共参数-回访结果
  type_show: false, //组件-回访类型
  result_show: false, //组件-回访结果
  time_show: false, //组件-回访日期
});

const form_info = reactive({
  id: router.currentRoute.value.query.sid, //回访数据id
  type_id: '', //回访类型id
  type_id_name: '',
  result_id: '', //回访结果id
  result_id_name: '',
  visit_time: '', //回访日期
  files: [], //附件 name path
  remarks: '', //备注
});

onMounted(() => {
  loadOptions();
});

/**
 * 接口获取
 */
//-@ 客服管理参数
const loadOptions = () => {
  // loading.value = false;
  $http.getCustomerServiceParameterApi().then((res) => {
    const { data, code } = res;
    if (code === 0) {
      _DATA.visit_type_list = data.visit_type;
      _DATA.visit_result_list = data.visit_result;
    } else {
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

/**
 * 操作
 */
//-@回访类型
const changeType = (value) => {
  _DATA.type_show = false;
  form_info.type_id = value.id;
  form_info.type_id_name = value.name;
};

//-@回访结果
const changeResult = (value) => {
  _DATA.result_show = false;
  form_info.result_id = value.id;
  form_info.result_id_name = value.name;
};

//-@回访日期
const currentDate = ref(new Date());
const changePicker = (value) => {
  _DATA.time_show = false;
  form_info.visit_time = moment(value).format('YYYY-MM-DD');
};

//-@附件
const getFileList = (file_list) => {
  form_info.files = file_list;
};

//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    loading.value = true;
    $http
      .getCustomerServiceRegularVisitApi({
        ...form_info,
      })
      .then((res) => {
        const { code, data } = res;
        if (code === 0) {
          Notify({
            type: 'success',
            message: '操作成功',
            onClose: () => {
              loading.value = false;
              // 哪里来回哪里去
              router.back();
            },
          });
        } else {
          loading.value = false;
          Notify({
            type: 'warning',
            message: data,
          });
        }
      });
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/construction/TempVisit.less';
</style>
