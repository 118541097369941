<!--
 * @Author: NanNan
 * @Date: 2022-04-19 15:26:46
 * @LastEditTime: 2023-10-14 10:17:46
 * @Description: 客户 - [合同增减项] 主页面
 V1.4.7 新增tab切换
-->
<template>
  <div>
    <Tabs class="tabList" v-model:active="_DATA.tabIndex" @change="changeTab">
      <Tab title="变更确认单" :name="1"> </Tab>
      <!-- <Tab title="五步 + 三部曲" :name="2"></Tab> -->
      <Tab title="变更意向单" :name="2"></Tab>
    </Tabs>
    <div class="info_box">
      <contractChangeList v-if="_DATA.tabIndex == 1" :type="'all'" />

      <contractChangePurposeList v-if="_DATA.tabIndex == 2" :type="'all'" />
    </div>
  </div>
</template>
<script setup>
import _ from 'lodash';
import { reactive } from 'vue';
import { Tabs, Tab } from 'vant';
import contractChangeList from '@/components/sale/contractChangeList.vue';
import contractChangePurposeList from '@/components/sale/contractChangePurposeList.vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const _DATA = reactive({
  tabIndex: router.currentRoute.value.query.tabIndex
    ? Number(router.currentRoute.value.query.tabIndex)
    : 1,
});

const changeTab = (val) => {
  changeRouter(val);
  _DATA.tabIndex = val;
};
// 动态修改路由参数
const changeRouter = (val) => {
  const query = _.cloneDeep(router.currentRoute.value.query);
  query.tabIndex = val;
  router.replace({ query });
};
</script>
<style lang="less" scoped>
.tabList {
  position: fixed;
  top: 46px;
  background: #fff;
  z-index: 99;
  width: 100vw;
  left: 0px;
}
.info_box {
  margin-top: 46px;
  background-color: #f5f5f5;
}
</style>
