<!--
 * @Author: smart
 * @Date:2022-03-21 11:03:35
 * @LastEditTime: 2023-04-07 10:38:07
 * @Description: 单元栋号组件 - 优化
 * v1.3.5 搜索框交互调整
-->
<template>
  <div class="pop_search">
    <h1 class="clearfix">
      <span class="c1 pull-left">选择单元栋号</span>
      <span class="c2 pull-right" @click="clear">清除</span>
    </h1>
    <div class="search_filed noBorder">
      <form action="/">
        <Search
          v-model="_DATA.kw"
          placeholder="搜索"
          @search="searchFun()"
          @clear="searchFun()"
          show-action
        >
          <template #action>
            <div @click="searchFun()" style="color: #0256ff">搜索</div>
          </template>
        </Search>
      </form>
    </div>

    <div class="checkedList noBorder">
      <RadioGroup v-model="form.checked" v-if="form.list.length">
        <cell v-for="item in form.list" :key="item.id">
          <div class="member_list">
            <Radio :name="item.id" @click.stop="getClick(item)">
              <div class="member_item">
                <p class="name">{{ item.detail_name }}</p>
              </div>
            </Radio>
          </div>
        </cell>
      </RadioGroup>
      <Empty
        v-if="!_DATA.loading && !form.list.length"
        image="search"
        description="没有合适的数据"
      />
    </div>
    <div class="stick-box">
      <Button type="primary" size="large" @click="btnSumbit">确定</Button>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineEmits, defineProps, onMounted } from 'vue';
import { Button, Search, Cell, Empty, Radio, RadioGroup } from 'vant';

import _ from 'lodash';
const emit = defineEmits(['reviewOk', 'reviewClose']);
const props = defineProps(['data', 'check']);

const _DATA = reactive({
  loading: false,
  kw: '',
  list: props.data ? props.data : [],
});
const form = reactive({
  checked: props.check ? Number(props.check) : '',
  checkedItem: {},
  list: props.data ? props.data : [],
});
onMounted(() => {
  searchFun();
});
//-搜索
const searchFun = () => {
  if (_DATA.kw) {
    form.list = _DATA.list.filter((i) => i.detail_name.includes(_DATA.kw));
  } else {
    form.list = _.cloneDeep(_DATA.list);
  }
  if (props?.check) {
    form.checkedItem = form?.list.filter(
      (item) => item.id == props?.check
    )?.[0];
  } else {
    form.checkedItem = {};
  }
};

const getClick = (item) => {
  form.checked = item.id;
  form.checkedItem = item;
};
//-确认操作
const btnSumbit = () => {
  emit('reviewOk', form.checkedItem);
};

//-取消
const clear = () => {
  form.checkedItem = {};
  emit('reviewOk', form.checkedItem);
};
//--基本信息end单---------------------
</script>
<style lang="less" scoped>
@import '@/assets/styles/clue/TempUserFloor.less';
</style>
