<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-03-29 17:38:03
 * @Description: 家居装饰施工合同
 * @Description: V1.5.7紧急需求：合同总价【向上进】，施工折扣【向下舍】
-->
<template>
  <!-- 合同收款 -->
  <div class="temp">
    <h1>合同收款</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              必报项工程造价
            </label>
            <van-field
              v-model="form.required_amount"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的必报项工程造价',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写必报项工程造价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              设备电工程造价
            </label>
            <van-field
              v-model="form.device_amount"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的设备电工程造价',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写设备电工程造价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              升级选项工程造价
            </label>
            <van-field
              v-model="form.upgrade_amount"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的升级选项工程造价',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写升级选项工程造价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              远程费
            </label>
            <van-field
              v-model="form.remote_fee"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的远程费',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写远程费"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              管理费
            </label>
            <van-field
              v-model="form.manage_amount"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的管理费',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写管理费"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              税金
            </label>
            <van-field
              v-model="form.tax_amount"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的税金',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写税金"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              签订合同价
            </label>
            <van-field
              v-model="form.contract_amount"
              type="number"
              @blur="getRatioFun"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的签订合同价',
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写签订合同价"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工折扣(不打折填10)
            </label>
            <van-field
              v-model="form.discount_ratio"
              type="number"
              @blur="getAmountNum('ratio')"
              :rules="[
                {
                  required: true,
                  message: '请填写正确的施工折扣',
                  trigger: 'onChange',
                  validator: validatorDiscount,
                },
              ]"
              placeholder="填写施工折扣（1-10）"
            >
            </van-field>
            <p class="info_tip" v-if="is_discount">
              <van-icon name="warning" color="#eaac41" />
              <span>
                你输入的折扣低于9.5折，低于折扣部分（{{
                  extra_discount
                }}元）由项目组成员承担
              </span>
            </p>
          </div>

          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              收费比例
            </label>
            <van-field
              v-model="form.directory_attr_name"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请选择收费比例',
                },
              ]"
              placeholder="收费比例"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">优惠活动</label>
            <van-field
              v-model="form.discount_activity_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              placeholder="请选择优惠活动"
              @click="showActivePicker = true"
            />
            <van-popup v-model:show="showActivePicker" position="bottom">
              <van-picker
                :columns="activeList"
                :columns-field-names="customFieldName"
                @confirm="onConfirm"
                cancel-button-text="清除"
                @cancel="onRemoveActive"
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">优惠金额</label>
            <van-field
              v-model="form.discount_amount"
              type="number"
              placeholder="0.00"
              :rules="[
                {
                  required: false,
                  message: '请填写正确的优惠金额',
                  validator: validatorAmount,
                  trigger: 'onChange',
                },
              ]"
            >
              <template #button>
                <van-text>元</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell2">
            <label class="form_title">收款期数</label>
            <div
              class="collect_list"
              v-for="(item, index) in form.charge_proportion_details"
              :key="index"
            >
              <div class="line1 clearfix">
                <div class="pull-left header">
                  {{ item.payment_period }}
                </div>
                <div class="pull-left">
                  <p class="title">收款比例</p>
                  <p class="num">{{ item.proportion + '%' }}</p>
                </div>
                <div class="pull-left">
                  <p class="title">应收金额</p>
                  <p class="num">{{ item.receivable_money }}</p>
                </div>
              </div>
              <div class="line2">
                <van-field
                  v-model="item.payment_description"
                  placeholder="输入款项说明"
                  maxlength="100"
                />
              </div>
            </div>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted } from 'vue';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
} from 'vant';
import { Toast } from 'vant';
import patterns from '@/utils/pattern';
import * as _ from 'lodash';
import NP from 'number-precision';
export default {
  props: [
    'type',
    'contractType',
    'data',
    'parameterList',
    'charge_proportions',
    'contractTypeName',
    'parameterListForQuoteOrder',
  ],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      type: props.type,
      contractType: props.contractType,
      //-活动
      showActivePicker: false,
      activeList: props.parameterList
        ? props.parameterList.contract_activity
        : [],
      //-是否展示折扣低于9.5提示
      is_discount: false,
      //-低于9.5折 额外补充的金额
      extra_discount: 0,
      //-上一次施工折扣
      prev_ratio: props.data.discount_ratio || '',
    });

    onMounted(() => {
      //-创建合同-需要计算合同签订价以及款期
      if (!props.data.contract_amount) {
        getAmountNum();
      } else {
        //-重新生成需要判断折扣金额
        getDiscountFun();
      }
    });

    const { discountReg } = patterns;
    //--表单---------------------
    //-贷款/签订合同价/优惠金额
    const validatorAmount = (val) =>
      val ? (parseFloat(val) <= 99999999.99 ? true : false) : true;

    //-施工折扣验证
    const validatorDiscount = (val) =>
      val
        ? parseFloat(val) <= 10 && parseFloat(val) > 0 && discountReg.test(val)
          ? true
          : false
        : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      contract_amount: props.data.contract_amount
        ? props.data.contract_amount
        : '',
      directory_attr_id: props.data.directory_attr_id
        ? props.data.directory_attr_id
        : props.contractType,
      directory_attr_name: props.data.directory_attr_name
        ? props.data.directory_attr_name
        : props.contractTypeName,
      discount_activity_id: props.data.discount_activity_id
        ? props.data.discount_activity_id
        : '',
      discount_activity_name: props.data.discount_activity_name
        ? props.data.discount_activity_name
        : '',
      discount_amount: props.data.discount_amount
        ? props.data.discount_amount
        : '',
      charge_proportion_details: props.data.charge_proportion_details
        ? props.data.charge_proportion_details
        : props.charge_proportions
        ? props.charge_proportions
        : [],
      required_amount:
        props.parameterListForQuoteOrder.required_quote ||
        props.parameterListForQuoteOrder.required_quote === 0
          ? props.parameterListForQuoteOrder.required_quote
          : '',
      device_amount:
        props.parameterListForQuoteOrder.device_total_cost ||
        props.parameterListForQuoteOrder.device_total_cost === 0
          ? props.parameterListForQuoteOrder.device_total_cost
          : '',
      upgrade_amount:
        props.parameterListForQuoteOrder.upgrade_total_cost ||
        props.parameterListForQuoteOrder.upgrade_total_cost === 0
          ? props.parameterListForQuoteOrder.upgrade_total_cost
          : '',
      remote_fee:
        props.parameterListForQuoteOrder.remote_fee_total ||
        props.parameterListForQuoteOrder.remote_fee_total === 0
          ? props.parameterListForQuoteOrder.remote_fee_total
          : '',
      manage_amount:
        props.parameterListForQuoteOrder.manage_amount_total ||
        props.parameterListForQuoteOrder.manage_amount_total === 0
          ? props.parameterListForQuoteOrder.manage_amount_total
          : '',
      tax_amount:
        props.parameterListForQuoteOrder.tax_amount_total ||
        props.parameterListForQuoteOrder.tax_amount_total === 0
          ? props.parameterListForQuoteOrder.tax_amount_total
          : '',
      discount_ratio: props.data.discount_ratio || '',
      remote_fee_ratio:
        props.parameterListForQuoteOrder.remote_fee_ratio ||
        props.parameterListForQuoteOrder.remote_fee_ratio === 0
          ? props.parameterListForQuoteOrder.remote_fee_ratio
          : '',
      dis_contract_amount:
        props.data.dis_contract_amount || props.data.dis_contract_amount === 0
          ? props.data.dis_contract_amount
          : '',
    });
    //-picker-活动选择项字段
    const customFieldName = {
      text: 'name',
      value: 'id',
    };
    const onConfirm = (value) => {
      if (value) {
        form.discount_activity_name = value.name;
        form.discount_activity_id = value.id;
      }
      state.showActivePicker = false;
    };
    const onRemoveActive = () => {
      form.discount_activity_name = '';
      form.discount_activity_id = '';
      state.showActivePicker = false;
    };

    //-获取款期
    const floatNum1 = /^[0-9]+([.]{1}[0-9]{1})?$/;
    const floatNum2 = /^[0-9]+([.]{1}[0-9][0-9]{1})?$/;

    // 计算合同总价以及款期
    const getAmountNum = (type) => {
      // 签订合同价=（必报项工程造价+设备电工程造价+升级选项工程造价+远程费+管理费+税金）*折扣
      let total = NP.plus(
        parseFloat(form.required_amount) ?? 0,
        parseFloat(form.device_amount) ?? 0,
        parseFloat(form.upgrade_amount) ?? 0,
        parseFloat(form.remote_fee) ?? 0,
        parseFloat(form.manage_amount) ?? 0,
        parseFloat(form.tax_amount) ?? 0
      );
      //-实时取最新的折前合同价
      form.dis_contract_amount = total ? total.toString() : '';

      //-@判断 施工折扣失去焦点 需要判断值是否有变化，有变化重新计算施工折扣
      if (type) {
        if (parseFloat(state.prev_ratio) !== parseFloat(form.discount_ratio)) {
          //-合同总价
          form.contract_amount = form.discount_ratio
            ? _.ceil(
                NP.times(
                  total,
                  NP.divide(parseFloat(form.discount_ratio ?? 0), 10)
                ),
                2
              )
            : total; //施工折扣为空默认给全部
          state.prev_ratio = form.discount_ratio;
        }
      } else {
        //-合同总价
        form.contract_amount = form.discount_ratio
          ? _.ceil(
              NP.times(
                total,
                NP.divide(parseFloat(form.discount_ratio ?? 0), 10)
              ),
              2
            )
          : total; //默认给全部
      }
      //-判读折扣金额
      getDiscountFun();
      //-计算款期
      getChargeFun();
    };
    //-end

    //-@根据签订合同价反推折扣
    const getRatioFun = () => {
      //-签订合同价=（必报项工程造价+设备电工程造价+升级选项工程造价+远程费+管理费+税金）*折扣
      let total = NP.plus(
        parseFloat(form.required_amount) ?? 0,
        parseFloat(form.device_amount) ?? 0,
        parseFloat(form.upgrade_amount) ?? 0,
        parseFloat(form.remote_fee) ?? 0,
        parseFloat(form.manage_amount) ?? 0,
        parseFloat(form.tax_amount) ?? 0
      );
      //-施工折扣
      form.discount_ratio = form.contract_amount
        ? _.floor(
            NP.times(
              NP.divide(
                parseFloat(form.contract_amount ?? 0),
                parseFloat(total ?? 1)
              ),
              10
            ),
            2
          )
        : '';
      state.prev_ratio = form.discount_ratio;
      //-判读折扣金额
      getDiscountFun();
      //-计算款期
      getChargeFun();
    };
    //-end

    //-@计算款期
    const getChargeFun = () => {
      if (form.charge_proportion_details.length) {
        let total_num = 0;
        return form.charge_proportion_details.map((v, index) => {
          if (
            index == form.charge_proportion_details.length - 1 &&
            form.charge_proportion_details.length > 1
          ) {
            v.receivable_money = (
              parseFloat(form.contract_amount ? form.contract_amount : 0) -
              parseFloat(total_num)
            ).toFixed(2);
          } else {
            var currentMoney =
              parseFloat(form.contract_amount ? form.contract_amount : 0) *
              (parseFloat(v.proportion) / 100);
            var currentMoneySpecial =
              Math.ceil(
                parseFloat(form.contract_amount ? form.contract_amount : 0) *
                  (parseFloat(v.proportion) / 100) *
                  100
              ) / 100;
            v.receivable_money =
              Number.isInteger(currentMoney) || floatNum1.test(currentMoney)
                ? currentMoney.toFixed(2)
                : floatNum2.test(currentMoney)
                ? currentMoney
                : floatNum1.test(currentMoneySpecial)
                ? currentMoneySpecial.toFixed(2)
                : currentMoneySpecial;

            total_num += parseFloat(v.receivable_money);
          }

          v.payment_period;
          v.proportion;
          v.payment_period;
        });
      }
    };
    //-end

    //-@判读折扣金额
    const getDiscountFun = () => {
      // 签订合同价=（必报项工程造价+设备电工程造价+升级选项工程造价+远程费+管理费+税金）*折扣
      let total = NP.plus(
        parseFloat(form.required_amount) ?? 0,
        parseFloat(form.device_amount) ?? 0,
        parseFloat(form.upgrade_amount) ?? 0,
        parseFloat(form.remote_fee) ?? 0,
        parseFloat(form.manage_amount) ?? 0,
        parseFloat(form.tax_amount) ?? 0
      );
      //-实时取最新的折前合同价
      form.dis_contract_amount = total ? total.toString() : '';

      //-判读折扣金额
      if (parseFloat(form.discount_ratio) < 9.5) {
        state.is_discount = true;
        state.extra_discount = _.floor(
          NP.times(
            total,
            NP.divide(NP.minus(9.5, parseFloat(form.discount_ratio ?? 0)), 10)
          ),
          2
        );
      } else {
        state.is_discount = false;
        state.extra_discount = 0;
      }
    };
    //-end

    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      onConfirm,
      btnSumbit,
      customFieldName,
      getAmountNum,
      validatorAmount,
      onRemoveActive,
      discountReg,
      validatorDiscount,
      getRatioFun,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
