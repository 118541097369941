<!--
 * @Author: Jessica
 * @Date: 2022-04-14 17:00:31
 * @LastEditTime: 2024-03-23 11:40:34
 * @Description: 优选整装施工合同
-->
<template>
  <!-- 房屋信息 -->
  <div class="temp">
    <h1>房屋信息</h1>
    <div class="formBase">
      <van-form ref="ruleFormRef">
        <van-cell-group>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              房屋面积（施工面积）
            </label>
            <van-field
              v-model="form.house_size"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的房屋面积',
                  validator: validatorSize,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写房屋面积"
              @blur="getCountAuto()"
            >
              <template #button>
                <van-text>㎡</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              工期
            </label>
            <van-field
              v-model="form.schedule"
              type="number"
              disabled
              :rules="[
                {
                  required: true,
                  message: '请填写正确的工期',
                  validator: validatorDay,
                  trigger: 'onChange',
                },
              ]"
              placeholder="填写工期"
            >
              <template #button>
                <van-text>天</van-text>
              </template>
            </van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计开工时间
            </label>
            <van-field
              v-model="form.estimated_commencement_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计开工时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计开工时间',
                },
              ]"
              @click="openDateType('estimated_commencement_time')"
            />
            <van-popup v-model:show="showTimePicker" position="bottom">
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择日期"
                :min-date="minDate"
                @confirm="onConfirmDate"
                cancel-button-text="清除"
                @cancel="onRemoveDate"
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">预计派单时间</label>
            <van-field
              v-model="form.expected_dispatch_date"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计派单时间"
              disabled
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              预计完工时间
            </label>
            <van-field
              v-model="form.expected_completion_time"
              right-icon="underway-o"
              readonly
              name="picker"
              placeholder="预计完工时间"
              :rules="[
                {
                  required: true,
                  message: '请选择预计完工时间',
                },
              ]"
              disabled
            />
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              施工图纸提供方式
            </label>
            <van-field
              v-model="form.construction_drawings_mode_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择施工图纸提供方式',
                },
              ]"
              placeholder="请选择施工图纸提供方式"
              @click="showTypePicker = true"
            />
            <van-popup v-model:show="showTypePicker" position="bottom">
              <van-picker
                :columns="typeList"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type')"
                @cancel="showTypePicker = false"
              />
            </van-popup>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同执行人
            </label>
            <van-field
              class="noBorder"
              v-model="form.executor_type"
              :rules="[
                {
                  required: true,
                  message: '请选择合同执行人',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in personList"
                    :key="item.id"
                    :class="{ checked: form.executor_type == item.id }"
                    class="pull-left"
                    @click="getChecked(item.id)"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="extra_operate" v-if="form.executor_type">
            <div class="extra_item" @click.stop="getHandelFun('base')">
              <p class="t1">完善客户基础信息</p>
              <p class="t2" v-if="form.customer_other_num > 0">
                <span>{{ form.customer_other_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
            <div
              class="extra_item"
              @click.stop="getHandelFun('helper')"
              v-if="form.executor_type == 2"
            >
              <p class="t1">完善委托人信息</p>
              <p class="t2" v-if="form.customer_entrust_num > 0">
                <span>{{ form.customer_entrust_num }}</span>
                项内容待完善
              </p>
              <van-icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              合同签署方
            </label>
            <van-field
              class="noBorder"
              v-model="form.signatory"
              :rules="[
                {
                  required: true,
                  message: '请选择合同签署方',
                },
              ]"
            >
              <template #input>
                <div class="clearfix person">
                  <span
                    v-for="item in signatoryList"
                    :key="item.id"
                    :class="{ checked: form.signatory == item.id }"
                    class="pull-left"
                    @click.stop="
                      () => {
                        if (form.signatory != item.id) {
                          form.company_name = '';
                        }
                        form.signatory = item.id;
                      }
                    "
                  >
                    {{ item.name }}
                  </span>
                </div>
              </template>
            </van-field>
          </div>
          <div class="cell" v-if="form.signatory == 2">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              企业名称
            </label>
            <van-field
              v-model="form.company_name"
              :rules="[
                {
                  required: true,
                  message: '请填写企业名称',
                },
              ]"
              placeholder="填写企业名称"
            ></van-field>
          </div>
          <div class="cell">
            <label class="form_title">
              <span style="color: #ea3d2f">*</span>
              整装优选产品
            </label>
            <van-field
              v-model="form.product_name"
              right-icon="arrow-down"
              readonly
              name="picker"
              :rules="[
                {
                  required: true,
                  message: '请选择整装优选产品',
                },
              ]"
              placeholder="请选择整装优选产品"
              @click="showTypePicker1 = true"
            />
            <van-popup v-model:show="showTypePicker1" position="bottom">
              <van-picker
                :columns="preferred_packaged_products"
                :columns-field-names="customFieldName"
                @confirm="(v) => onConfirmType(v, 'type1')"
                @cancel="showTypePicker1 = false"
              />
            </van-popup>
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <div class="btnSumbit">
      <van-button class="btn" type="primary" size="large" @click="btnSumbit">
        下一步
      </van-button>
    </div>
    <!-- 完善客户基础信息/完善执行人信息 -->
    <van-popup
      v-model:show="complete_card_show"
      :close-on-popstate="true"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <SaleAddMorePage
        v-if="complete_card.complete_card_Type == 'base'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
      <SaleAddExecutorPage
        v-if="complete_card.complete_card_Type == 'helper'"
        :data="complete_card"
        @reviewClose="
          () => {
            complete_card_show = false;
            complete_card.complete_card_Type = '';
          }
        "
        @reviewOk="reviewOk"
      />
    </van-popup>
  </div>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted } from 'vue';
import moment from 'moment';
import {
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Icon,
  DatetimePicker,
  Notify,
} from 'vant';
import SaleAddMorePage from '@/components/sale/addMore';
import SaleAddExecutorPage from '@/components/sale/addExecutor';
import patterns from '@/utils/pattern';
import { Toast } from 'vant';
export default {
  props: [
    'type',
    'data',
    'customer_id',
    'parameterList',
    'parameterListForCustomer',
    'contractType',
    'parameterListForQuoteOrder',
  ],
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    [Notify.name]: Notify,
    SaleAddMorePage,
    SaleAddExecutorPage,
  },
  setup(props, { emit }) {
    const $http = inject('$http');
    const $host = inject('$host');
    const state = reactive({
      contractType: props.contractType,
      type: props.type,
      personList: [
        { id: 1, name: '本人' },
        { id: 2, name: '委托人' },
      ],
      typeList: [
        { id: 1, name: '甲方（客户）提供' },
        { id: 2, name: '乙方（尚层）提供' },
      ],
      showTypePicker: false,
      //-合同签署方
      signatoryList: [
        { id: 1, name: '个体' },
        { id: 2, name: '企业' },
      ],
      //-时间控件
      showTimePicker: false,
      timeType: '',
      minDate: new Date(),
      currentDate: new Date(),
      //-整装优选产品参数
      preferred_packaged_products: props.parameterList
        ? props.parameterList.preferred_packaged_products
        : [],
      showTypePicker1: false,
      //-完善信息
      complete_card: {
        //-客户Id
        customer_id: props.customer_id ? props.customer_id : '',
        complete_card_Type: '',
      },
      complete_card_show: false,
    });

    onMounted(() => {
      getCustomerParameter(props.customer_id);
      // 工期自动计算,重新生成数据不变，失去焦点重新计算
      if (props.data.estimated_commencement_time) {
        getCountAuto();
      }
    });

    //--表单---------------------
    /**
     * 校验方式
     */
    const { houseSizeReg } = patterns;
    //-房屋面积
    const validatorSize = (val) =>
      val
        ? parseFloat(val) <= 9999.99 && houseSizeReg.test(val)
          ? true
          : false
        : true;
    //-工期
    const validatorDay = (val) =>
      val ? (parseFloat(val) <= 999 ? true : false) : true;

    const ruleFormRef = ref(null);
    const form = reactive({
      schedule:
        props.parameterListForQuoteOrder.contract_schedule ||
        props.parameterListForQuoteOrder.contract_schedule === 0
          ? props.parameterListForQuoteOrder.contract_schedule
          : '',
      house_size:
        props.parameterListForQuoteOrder.house_size ||
        props.parameterListForQuoteOrder.house_size === 0
          ? props.parameterListForQuoteOrder.house_size
          : '',
      estimated_commencement_time: props.data.estimated_commencement_time
        ? props.data.estimated_commencement_time
        : '',
      expected_completion_time: props.data.expected_completion_time
        ? props.data.expected_completion_time
        : '',
      construction_drawings_mode: props.data.construction_drawings_mode
        ? props.data.construction_drawings_mode
        : '',
      construction_drawings_mode_name: props.data
        .construction_drawings_mode_name
        ? props.data.construction_drawings_mode_name
        : '',
      executor_type: props.data.executor_type ? props.data.executor_type : '',
      product_id: props.data.product_id ? props.data.product_id : '',
      product_name: props.data.product_name ? props.data.product_name : '',
      signatory: props.data.signatory ? props.data.signatory : '',
      company_name: props.data.company_name ? props.data.company_name : '',
      //-待完善条数-本人
      customer_other_num: 0,
      //-待完善条数-委托人
      customer_entrust_num: 0,
      //-预计派单时间
      expected_dispatch_date: props.data.expected_dispatch_date
        ? props.data.expected_dispatch_date
        : '',
    });
    //-时间控件
    const openDateType = (type) => {
      state.showTimePicker = true;
      state.timeType = type;
      if (form[type]) {
        state.currentDate = new Date(form[type]);
      } else {
        state.currentDate = new Date();
      }
    };

    const onConfirmDate = (value) => {
      form[state.timeType] = moment(value).format('YYYY-MM-DD');
      state.showTimePicker = false;
      //-自动匹配结束时间
      if (state.timeType == 'estimated_commencement_time') {
        getCountAuto();
      }
    };

    const onRemoveDate = () => {
      form[state.timeType] = '';
      state.showTimePicker = false;
      //-自动匹配结束时间
      if (state.timeType == 'estimated_commencement_time') {
        form.expected_completion_time = '';
      }
    };
    //-@选择合同执行人---begin
    const getChecked = (id) => {
      form.executor_type = id;
    };

    //-完善合同执行人信息
    const getHandelFun = (type) => {
      state.complete_card_show = true;
      state.complete_card.complete_card_Type = type;
    };
    //-完善合同执行人、委托人信息操作
    const reviewOk = () => {
      if (props.customer_id) {
        getCustomerParameter(props.customer_id);
      }
    };
    //-获取客户参数
    const getCustomerParameter = (id) => {
      state.complete_card_show = false;
      state.complete_card.complete_card_Type = '';
      $http.getContractCustomerParameter({ customer_id: id }).then((res) => {
        if (res.code === 0) {
          form.customer_other_num = res.data.customer_other_num;
          form.customer_entrust_num = res.data.customer_entrust_num;
        } else {
          Toast(res.data);
        }
      });
    };
    //-@选择合同执行人---end

    //-picker-类型选择
    const customFieldName = {
      text: 'name',
      value: 'id',
    };

    const onConfirmType = (value, type) => {
      if (type === 'type') {
        form.construction_drawings_mode_name = value.name;
        form.construction_drawings_mode = value.id;
        state.showTypePicker = false;
      } else if (type === 'type1') {
        form.product_name = value.name;
        form.product_id = value.id;
        state.showTypePicker1 = false;
      }
    };
    //-下一步操作
    const btnSumbit = () => {
      if (!ruleFormRef.value) return;
      ruleFormRef.value
        .validate()
        .then(() => {
          emit('reviewFun', state.type, form);
        })
        .catch(() => {
          //验证失败
          Toast('请先完善信息');
        });
    };

    //--基本信息end单---------------------

    //-工期自动计算----begin
    const getCountAuto = () => {
      $http
        .getContractScheduleBySize({
          customer_id: props.customer_id ?? '',
          contract_type: state.contractType,
          size: form.house_size,
          start_date: form['estimated_commencement_time'],
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            form['expected_completion_time'] = data.end_date;
            form['expected_dispatch_date'] = data.expected_dispatch_date;
          } else {
            form['expected_completion_time'] = '';
            form['expected_dispatch_date'] = '';
            Notify({ type: 'warning', message: data });
          }
        });
    };
    //-工期自动计算-----end

    return {
      ...toRefs(state),
      $http,
      $host,
      form,
      ruleFormRef,
      btnSumbit,
      getChecked,
      getHandelFun,
      customFieldName,
      onConfirmType,
      openDateType,
      onConfirmDate,
      reviewOk,
      validatorSize,
      validatorDay,
      onRemoveDate,
      getCountAuto,
    };
  },
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/contract/temp.less';
</style>
