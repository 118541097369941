<!--
 * @Author: NanNan
 * @Date: 2022-08-04 09:11:24
 * @LastEditTime: 2022-08-04 09:23:23
 * @Description: pdf 查看电子合同
-->
<template>
  <div class="page-box">
    <iframe
      :src="_DATA.src"
      frameborder="0"
      align="middle"
      height="100%"
      width="100%"
    ></iframe>
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const _DATA = reactive({
  src: router.currentRoute.value.query.url,
});
console.log('////');
</script>
<style lang="less" scoped>
.page-box {
  padding: 0;
  max-width: 1200px;
  min-width: 100%;
  height: calc(100vh - 46px);
  margin: 0 auto;
}
</style>
