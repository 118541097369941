<!--
 * @Author: Jessica
 * @Date: 2023-06-20 15:34:11
 * @LastEditTime: 2024-05-25 11:59:00
 * @Description:V1.6.0-二级页设计阶段数据-重构
-->
<template>
  <div class="data_item extend chart_jd">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title flex_line_between">
            设计阶段数据
            <!-- 规则入口 -->
            <div class="icon_info_box" @click.stop="createDanger">
              <img
                class="icon_img"
                :src="require('@/assets/images/target-data/icon_info.png')"
                alt="info"
              />
            </div>
          </div>
          <!-- 图例 -->
          <Row v-if="props.data?.now_year == props.data?.yearId">
            <Col span="4"></Col>
            <Col span="14">
              <div class="lenged">
                <p class="len1">
                  <span class="sq blue"></span>
                  <span class="t">存量</span>
                </p>
                <p class="len2">
                  <span class="sq green"></span>
                  <span class="t">延期</span>
                </p>
              </div>
            </Col>
            <Col span="6"></Col>
          </Row>

          <div class="chart_ld" ref="chartsRef">
            <!-- 当年 -->
            <Row v-if="props.data?.now_year == props.data?.yearId">
              <Col span="4">
                <p class="ld_title" v-for="i in _DATA.typeList" :key="i.id">
                  {{ i.name }}
                </p>
              </Col>
              <Col span="14">
                <!-- 对比漏斗图 -->
                <div class="main">
                  <div class="chart" id="container_1"></div>
                  <div class="chart" id="container_2"></div>
                </div>
              </Col>
              <Col span="3">
                <p
                  class="num_dot"
                  v-for="(i, index) in _DATA.passed_list"
                  :key="i.id"
                >
                  <span :class="`dot dotp` + index"></span>
                  <span class="it">{{ i.value }}</span>
                </p>
              </Col>
              <Col span="3">
                <p
                  class="num_dot"
                  v-for="(i, index) in _DATA.delay_list"
                  :key="i.id"
                >
                  <span :class="`dot dotd` + index"></span>
                  <span class="it">{{ i.value }}</span>
                </p>
              </Col>
            </Row>
            <!-- 往年 -->
            <Row v-else>
              <Col span="4">
                <p
                  class="ld_title pl_10"
                  v-for="i in _DATA.typeList"
                  :key="i.id"
                >
                  {{ i.name }}
                </p>
              </Col>
              <Col span="16">
                <!-- 对比漏斗图 -->
                <div class="main">
                  <div class="chart" id="container_3"></div>
                </div>
              </Col>
              <Col span="4">
                <p
                  class="num_dot"
                  v-for="(i, index) in _DATA.done_list"
                  :key="i.id"
                >
                  <span :class="`dot dotp` + index"></span>
                  <span class="it">{{ i.value }}</span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Skeleton>
      <!-- 概/施/产/效 按钮切换-->
      <div class="change_box">
        <div class="type_box">
          <div
            class="type_item"
            :class="{ check: _DATA.tabActive == i.id }"
            v-for="i in _DATA.typeList"
            :key="i.id"
            @click="changeStage(i)"
          >
            {{ i.name }}
          </div>
        </div>
      </div>

      <!-- 按部门-->
      <div class="data_card_table_bottom">
        <!-- 标题 -->
        <Row class="mb_10">
          <Col span="5">
            <div class="grey size13">
              {{ props.data.depId ? '人员' : '部门' }}
            </div>
          </Col>
          <Col
            :span="props.data?.now_year == props.data?.yearId ? 15 : 19"
            style="position: relative"
          >
            <!-- 进度图标题 -->
            <div class="chart_pos pl_10">
              <!-- 坐标数量，4-5个 -->
              <chartCoverTop
                :data="{
                  list: _DATA.line_values,
                }"
              ></chartCoverTop>
              <!-- 右侧总数值-不一定存在 -->
              <div class="chart_right"></div>
            </div>
          </Col>
          <Col span="4" v-if="props.data?.now_year == props.data?.yearId">
            <div class="grey size13 align_center">存量</div>
          </Col>
        </Row>
        <Collapse
          v-model="_DATA.collapse_active"
          ref="collapseGroupRef"
          :accordion="false"
          :border="false"
        >
          <div
            class="sub_list"
            v-for="(item, index) in _DATA.listStage"
            :key="index"
          >
            <CollapseItem
              :name="index"
              :is-link="false"
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
            >
              <template #title>
                <Row class="mb_20">
                  <Col span="5" class="flex_line">
                    <div class="size13 flex_line_between">
                      <Icon
                        name="play"
                        class="arrow"
                        v-if="_DATA.collapse_active.indexOf(index) == -1"
                        style="transform: rotate(0deg)"
                      />
                      <Icon
                        name="play"
                        class="arrow"
                        v-if="_DATA.collapse_active.indexOf(index) > -1"
                        style="transform: rotate(90deg)"
                      />
                      <p>{{ item.short_name }}</p>
                    </div>
                  </Col>
                  <!-- 当年 -->
                  <Col
                    span="15"
                    class="chart_item"
                    v-if="props.data?.now_year == props.data?.yearId"
                  >
                    <!-- 进度图内容 -->
                    <div style="display: flex" class="pl_10">
                      <chartJD
                        :data="{
                          actual: item.delay_num,
                          target: item.passed_num,
                          list: _DATA.line_values,
                          type: 2,
                        }"
                      ></chartJD>

                      <div class="chart_right"></div>
                    </div>
                    <div style="display: flex" class="pl_10 mt_10">
                      <chartJD
                        :data="{
                          actual: item.done_num,
                          list: _DATA.line_values,
                          type: 4,
                        }"
                      ></chartJD>
                      <div class="chart_right"></div>
                    </div>
                    <!-- 竖线 begin-->
                    <div class="chart_pos pl_10">
                      <chartCoverLine
                        :data="{
                          list: _DATA.line_values,
                        }"
                      ></chartCoverLine>
                      <div class="chart_right"></div>
                    </div>
                    <!-- 竖线 end-->
                  </Col>
                  <!-- 往年 -->
                  <Col span="19" class="chart_item" v-else>
                    <!-- 进度图内容 -->
                    <div style="display: flex" class="pl_10">
                      <chartJD
                        :data="{
                          actual: item.done_num,
                          list: _DATA.line_values,
                          type: 3,
                        }"
                      ></chartJD>

                      <div class="chart_right"></div>
                    </div>
                    <!-- 竖线 begin-->
                    <div class="chart_pos pl_10">
                      <chartCoverLine
                        :data="{
                          list: _DATA.line_values,
                        }"
                      ></chartCoverLine>
                      <div class="chart_right"></div>
                    </div>
                    <!-- 竖线 end-->
                  </Col>
                  <Col
                    span="4"
                    class="flex_line_center"
                    v-if="props.data?.now_year == props.data?.yearId"
                  >
                    <div>
                      {{ item.passed_num }}
                    </div>
                  </Col>
                </Row>
              </template>

              <!-- 运营中心-下级部门数据 begin  -->
              <!-- @标题  -->
              <Row class="mt_10" v-if="item.sub_list?.length">
                <Col
                  :span="props.data?.now_year == props.data?.yearId ? 6 : 12"
                >
                  <div class="grey size13">
                    {{ props.data.depId ? '人员' : '部门' }}
                  </div>
                </Col>
                <Col span="6" v-if="props.data?.now_year == props.data?.yearId">
                  <div class="grey size13 align_center">存量</div>
                </Col>
                <Col span="6" v-if="props.data?.now_year == props.data?.yearId">
                  <div class="grey size13 align_center">延期</div>
                </Col>
                <Col
                  :span="props.data?.now_year == props.data?.yearId ? 6 : 12"
                >
                  <div class="grey size13 align_center">完成</div>
                </Col>
              </Row>
              <div
                class="sub_item"
                v-for="(sub, index) in item.sub_list"
                :key="index"
              >
                <!-- @下级部门数据 -->
                <Row class="mb_20" @click="goDetail(sub)">
                  <Col
                    :span="props.data?.now_year == props.data?.yearId ? 6 : 12"
                  >
                    <div class="size13">
                      {{ sub.short_name }}
                    </div>
                  </Col>
                  <Col
                    span="6"
                    v-if="props.data?.now_year == props.data?.yearId"
                  >
                    <div>
                      <span class="size13 align_center">
                        {{ sub.passed_num }}
                      </span>
                    </div>
                  </Col>
                  <Col
                    span="6"
                    v-if="props.data?.now_year == props.data?.yearId"
                  >
                    <div>
                      <span class="size13 align_center">
                        {{ sub.delay_num }}
                      </span>
                    </div>
                  </Col>
                  <Col
                    :span="props.data?.now_year == props.data?.yearId ? 6 : 12"
                  >
                    <div>
                      <span class="size13 align_center">
                        {{ sub.done_num }}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <!-- 运营中心-下级部门数据 end -->
            </CollapseItem>
          </div>
        </Collapse>

        <div
          class="expand"
          v-if="_DATA.listStage.length > _DATA.maxCount"
          @click="
            () => {
              _DATA.open = !_DATA.open;
            }
          "
        >
          <span>{{ _DATA.open ? '收起' : '展开' }}</span>

          <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
          <Icon name="arrow-up" size="14" v-else />
        </div>
      </div>
      <!-- 图例 -->
      <div
        class="p_15 flex_center"
        v-if="props.data?.now_year == props.data?.yearId"
      >
        <p class="box_8 grey_bg"></p>
        <p class="ml_5">存量</p>
        <p class="box_8 green_bg ml_30"></p>
        <p class="ml_5">延期</p>
        <p class="box_8 red_bg ml_30"></p>
        <p class="ml_5">完成</p>
      </div>
      <div class="p_15 flex_center" v-else>
        <p class="box_8 blue_bg"></p>
        <p class="ml_5">完成</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { renderFunnelChart } from '@/utils/chartLD';
// 进度条相关
import chartCoverTop from '@/components/targetData/chatTemp/chartCoverTop';
import chartCoverLine from '@/components/targetData/chatTemp/chartCoverLine';
import chartJD from '@/components/targetData/chatTemp/chartJD';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
  nextTick,
  ref,
  defineExpose,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify, Collapse, CollapseItem } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd', 'createDanger']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  listStage: [],
  index: props.data.depId ? 6 : 3, //处在当前页面的第几个位置(在全部数据页面和部门数据页面的位置如果不一样，则需要判断)
  depIndex: 7, //部门数据页面所处位置
  maxCount: 1000, //超过则显示展开收起
  open: false,
  typeList: [],
  loadingStage: false,
  tabActive: props.data.tabActive ? props.data.tabActive : 1,
  collapse_active: [], //锁定
  //-对比漏斗图数据
  //-@存量List
  passed_list: [],
  //-@延期List
  delay_list: [],
  //-@已完成
  done_list: [],
  //-进度条相关
  line_values: [],
});
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      _DATA.tabActive = 1;
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_stageDesignNum;
  let params = { year: props.data.yearId };
  if (props.data.depId) {
    params = { department_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      getListFun(data.list);
      getParams();
      getStage();
    } else {
      _DATA.list = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
//-获取阶段参数
const getParams = () => {
  $http.targetStatistic_getParams().then((res) => {
    const { code, data } = res;
    if (code === 0) {
      _DATA.typeList = data.design_stage;
    } else {
      _DATA.typeList = [];
    }
  });
};
//-获取阶段数据
const getStage = () => {
  _DATA.loadingStage = true;
  let url = $http.targetStatistic_getDesignNumByStage;
  let params = {
    type_sort: _DATA.tabActive,
    year: props.data.yearId,
    column_num: 3,
  };
  if (props.data.depId) {
    params = {
      department_id: props.data.depId,
      type_sort: _DATA.tabActive,
      year: props.data.yearId,
      column_num: 3,
    };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loadingStage = false;
    if (code === 0) {
      _DATA.listStage = data.list;
      _DATA.line_values = data.line_values;
    } else {
      _DATA.listStage = [];
      Notify({
        type: 'warning',
        message: data,
      });
    }
  });
};

/**
 * 操作
 */
//-@切换阶段
const changeStage = (i) => {
  _DATA.tabActive = i.id;
  _DATA.open = false;
  getStage();
};
//-@跳转三级页面
const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depDesginData',
    query: {
      depId: item.id,
      depName: item.short_name,
      pos: _DATA.depIndex,
      tabActive: _DATA.tabActive,
    },
  });
};

//-对比漏斗图--begin
const chartsRef = ref();
//-@-获取对比漏斗图数据/加载--begin
const getListFun = (list) => {
  var height = 100;
  const padding = [0, 10];
  const color_1 = ['#0256FF', '#165DFF', '#4080FF', '#94BFFF', '#d1e3ff'];
  const color_2 = ['#008911', '#4DAD59', '#A6D6AC', '#E5F3E7', '#edfaef'];
  //-判断是否为当年
  if (props.data?.now_year == props.data?.yearId) {
    _DATA.passed_list = list.map((v) => {
      return { name: v.name, id: v.id, value: v.passed_num };
    });
    _DATA.delay_list = list.map((v) => {
      return { name: v.name, id: v.id, value: v.delay_num };
    });
    const passed_list = _DATA.passed_list;
    const delay_list = _DATA.delay_list;
    const maxValue1 = Math.max(...passed_list.map((d) => d.value));
    const maxValue2 = Math.max(...delay_list.map((d) => d.value));
    nextTick(() => {
      height = chartsRef?.value?.clientHeight ?? 100;
      renderFunnelChart(
        'container_1',
        height,
        padding,
        _DATA.passed_list,
        color_1,
        maxValue1,
        1
      );
      renderFunnelChart(
        'container_2',
        height,
        padding,
        _DATA.delay_list,
        color_2,
        maxValue2,
        maxValue2 / maxValue1
      );
    });
  } else {
    //-往年
    _DATA.done_list = list.map((v) => {
      return { name: v.name, id: v.id, value: Number(v.done_num) };
    });
    const done_list = _DATA.done_list;
    const maxValue3 = Math.max(...done_list.map((d) => d.value));
    nextTick(() => {
      height = chartsRef?.value?.clientHeight ?? 100;
      renderFunnelChart(
        'container_3',
        height,
        padding,
        _DATA.done_list,
        color_1,
        maxValue3,
        1
      );
    });
  }
};
//-@-获取对比漏斗图数据/加载--end

//-对比漏斗图--end

//-规则说明--begin
const htmlTitle = '设计阶段数据_规则';
const htmlContent = `<p>存量：所有有效【尚层装饰室内设计协议】下对应设计为待启动和进行中项目；</p>
<p>延期：当前阶段延期数=当前时间-当前阶段必须完成时间（外控必须完成时间）；</p>
<p>完成：当年完成的各阶段数据；</p>`;
const createDanger = () => {
  emit('createDanger', 'transformPeriodRef');
};
defineExpose({ htmlContent, htmlTitle });
//-规则说明--end
</script>

<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
