<!--
 * @Author: gest
 * @Date: 2023-09-08 14:59:54
 * @LastEditTime: 2024-03-07 14:59:15
 * @Description: v1.4.5见证件pop模板，只展示相关详情或记录，无任何操作权限
 * @Description: props.data.type==1设计 2施工
-->
<template>
  <NavBar
    safe-area-inset-top
    title="见证件"
    left-arrow
    @click-left="onClosePop"
    :fixed="true"
  />
  <div class="page-box prop">
    <!-- 通用附件 begin-->
    <commonWitness
      v-if="state.detail.sub_type == 1"
      :data="form.form_info"
      :can_edit="state.detail.can_edit"
    ></commonWitness>
    <!-- 通用附件 end-->

    <!-- 概念方案预演会详情 begin-->
    <WitnessDetailGNFAYY
      v-if="state.detail.sub_type == 2"
      :data="form.form_info"
    />

    <!-- 效果方案预演会表单详情 begin-->
    <WitnessDetailXGFAYY
      v-if="state.detail.sub_type == 3"
      :data="form.form_info"
    />

    <!-- 产品方案预演会表单详情 begin-->
    <WitnessDetailCPFAYY
      v-if="state.detail.sub_type == 4"
      :data="form.form_info"
    />

    <!-- 施工方案预演会表单详情 begin-->
    <WitnessDetailSGFAYY
      v-if="state.detail.sub_type == 5"
      :data="form.form_info"
    />
    <!--  end-->

    <!-- 开工交底验收单 详情 begin-->
    <jdysdWitnessDetail
      :data="form.form_info"
      :sub_type="state.detail.sub_type"
      v-if="state.detail.sub_type > 5 && state.detail.sub_type < 17"
    />
    <!--  end-->

    <!-- 进场材料记录单 详情 begin-->
    <jccljyWitnessDetail
      :data="form.form_info"
      v-if="state.detail.sub_type == 25"
    />
    <!--  end-->

    <!-- 交底单 详情 begin-->
    <jdjldWitnessDetail
      :data="form.form_info"
      :sub_type="state.detail.sub_type"
      v-if="state.detail.sub_type == 26 || state.detail.sub_type == 27"
    />
    <!--  end-->

    <!-- 场地交接单 详情 begin-->
    <cdjjdWitnessDetail
      :data="form.form_info"
      v-if="state.detail.sub_type == 28"
    />
    <!--  end-->

    <!-- 防水验收记录表 详情 begin-->
    <fsysWitnessDetail
      :data="form.form_info"
      v-if="state.detail.sub_type == 29"
    />
    <!--  end-->

    <!-- 产品二次交底单 详情 begin-->
    <cpecjddWitnessDetail
      :data="form.form_info"
      v-if="state.detail.sub_type == 30"
    />
    <!--  end-->

    <!-- 工期确认单 详情 begin-->
    <gqqrdWitnessDetail
      :data="form.form_info"
      :info="state.detail"
      v-if="state.detail.sub_type == 31"
    />
    <!--  end-->

    <!-- 产品安装计划表 详情 begin-->
    <cpazjhbWitnessDetail
      :data="form.form_info"
      v-if="state.detail.sub_type == 32"
    />
    <!--  end-->

    <!-- 产品下单记录表 详情 begin-->
    <cpxdjlbWitnessDetail
      :data="form.form_info"
      :sub_type="state.detail.sub_type"
      v-if="state.detail.sub_type > 16 && state.detail.sub_type < 20"
    />
    <!--  end-->

    <!-- 进场产品验收记录单 详情 begin-->
    <jccpysWitnessDetail
      :data="form.form_info"
      :sub_type="state.detail.sub_type"
      v-if="state.detail.sub_type > 19 && state.detail.sub_type < 22"
    />
    <!--  end-->

    <!-- 家庭居室/优选整装阶段结算单 详情 begin-->
    <jtjsjdjsdWitnessDetail
      :data="form.form_info"
      :sub_type="state.detail.sub_type"
      :witness_status="state.detail.witness_status"
      v-if="state.detail.sub_type > 21 && state.detail.sub_type < 25"
    />
    <!--  end-->
    <!-- 三级安全教育承诺书 详情 begin-->
    <sjaqjyWitnessDetail
      :data="form.form_info"
      :sub_type="state.detail.sub_type"
      v-if="state.detail.sub_type ==33"
    />
    <!--  end-->
  </div>
</template>

<script setup>
import { defineEmits, defineProps, reactive, onMounted, inject } from 'vue';
import { NavBar, Toast } from 'vant';
import commonWitness from '@/components/witness/commonWitness'; //-通用附件
import WitnessDetailCPFAYY from '@/components/witness/desgin/WitnessDetailCPFAYY'; //产品方案预演会表单详情
import WitnessDetailXGFAYY from '@/components/witness/desgin/WitnessDetailXGFAYY'; //效果方案预演会表单详情
import WitnessDetailGNFAYY from '@/components/witness/desgin/WitnessDetailGNFAYY'; //概念方案预演会表单详情
import WitnessDetailSGFAYY from '@/components/witness/desgin/WitnessDetailSGFAYY'; //施工方案预演会表单详情

import jdysdWitnessDetail from '@/components/witness/site/jdysdWitnessDetail';

import jccljyWitnessDetail from '@/components/witness/site/jccljyWitnessDetail';

import jdjldWitnessDetail from '@/components/witness/site/jdjldWitnessDetail';

import cdjjdWitnessDetail from '@/components/witness/site/cdjjdWitnessDetail';

import fsysWitnessDetail from '@/components/witness/site/fsysWitnessDetail';

import cpecjddWitnessDetail from '@/components/witness/site/cpecjddWitnessDetail';

import gqqrdWitnessDetail from '@/components/witness/site/gqqrdWitnessDetail';

import cpazjhbWitnessDetail from '@/components/witness/site/cpazjhbWitnessDetail';

import cpxdjlbWitnessDetail from '@/components/witness/site/cpxdjlbWitnessDetail';

import jccpysWitnessDetail from '@/components/witness/site/jccpysWitnessDetail';

import jtjsjdjsdWitnessDetail from '@/components/witness/site/jtjsjdjsdWitnessDetail';
import sjaqjyWitnessDetail from '@/components/witness/site/sjaqjyWitnessDetail';
const props = defineProps(['data']);
const emit = defineEmits(['reviewOk']);
const $http = inject('$http');
const state = reactive({
  detail: {},
});
const form = reactive({
  form_info: null,
  remark: '',
});
onMounted(() => {
  getDetail();
});
//-获取详情数据
const getDetail = () => {
  state.loading = true;
  let url = $http.witness_nodeInfo;
  let obj = {
    witness_id: props.data.witness_id,
    type: props.data.type,
    node_id: props.data.node_id,
  };
  if (props.data.log) {
    url = $http.witness_getNodeForm;
    obj = {
      witness_id: props.data.witness_id,
      type: props.data.type,
      node_id: props.data.node_id,
      form_version: props.data.form_version ?? '',
    };
  }
  url(obj).then((res) => {
    state.loading = false;
    if (res.code === 0) {
      state.detail = res.data;
      form.form_info = res.data.form_info;
    } else {
      Toast(res.data);
    }
  });
};
//-关闭popup 返回确认验收
const onClosePop = () => {
  emit('reviewOk');
};
</script>

<style lang="less" scoped>
.page-box {
  padding: 0;
  min-height: calc(100% - 46px);
  background: #f5f5f5;
  box-sizing: border-box;
  &.prop {
    min-height: 100%;
    padding: 46px 0px 0px;
  }
}
</style>
