<!--
 * @Author: gest
 * @Date: 2023-05-11 20:04:33
 * @LastEditTime: 2023-06-13 11:46:33
 * @Description: V1.3.8 自购产品操作
    //464跟单/465客户下单/466完成测量/469作废
-->
<template>
  <NavBar
    :left-arrow="true"
    safe-area-inset-top
    :title="getTitle()"
    @click-left="onBack"
    :fixed="true"
    :placeholder="true"
  />
  <div class="page-box middle-box">
    <!-- 加载状态 -->
    <Overlay :show="loading" class="overlay-fff">
      <Loading color="#1989fa" type="spinner"></Loading>
    </Overlay>

    <!-- end -->
    <Form class="form-box" ref="formRef">
      <div class="tip_box" v-if="_DATA.type == 466">
        若测量数据与订单明细不一致，请联系客户自购厂家修改产品方案并修改订单明细！
      </div>
      <div class="detail-box">
        <p class="label-name" v-if="_DATA.type == 465">下单日期</p>
        <Field
          v-if="_DATA.type == 465"
          v-model="form_info.place_order_time"
          name="picker"
          readonly
          placeholder="请选择时间"
          right-icon="underway-o"
          @click="clickFun(1)"
        />
        <p class="label-name" v-if="_DATA.type == 465">预计送货日期</p>
        <Field
          v-if="_DATA.type == 465"
          v-model="form_info.custom_time"
          name="picker"
          readonly
          placeholder="请选择时间"
          right-icon="underway-o"
          @click="clickFun(2)"
        />
        <p class="label-name" v-if="_DATA.type == 466">
          <em>*</em>
          测量完成日期
        </p>
        <Field
          v-if="_DATA.type == 466"
          v-model="form_info.custom_time"
          name="picker"
          readonly
          placeholder="请选择日期"
          right-icon="underway-o"
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请选择日期',
            },
          ]"
          @click="clickFun(3)"
        />

        <p class="label-name">
          <em>*</em>
          备注
        </p>
        <Field
          v-model="form_info.remark"
          rows="3"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请填写"
          show-word-limit
          :rules="[
            {
              required: true,
              trigger: 'onChange',
              message: '请填写备注',
            },
          ]"
        ></Field>

        <!-- 附件组件 -->
        <p class="label-name">
          <em v-if="_DATA.type == 466">*</em>
          {{ _DATA.type == 466 ? '现场测量图' : '附件' }}
        </p>

        <FileUpload
          :data="form_info.files"
          @update:func="(list) => getFileList(list)"
        ></FileUpload>
        <!-- end -->

        <div class="stick-box">
          <Button type="primary" size="large" @click="onSubmit">确定</Button>
        </div>
      </div>
    </Form>

    <!-- 年月日 组件 -->
    <!-- 下单日期；测量完成日期  -->
    <Popup v-model:show="_DATA.showTimePicker" position="bottom">
      <DatetimePicker
        v-model="_DATA.currentDate"
        type="date"
        title="选择日期"
        @confirm="changePicker"
        @cancel="_DATA.showTimePicker = false"
      />
    </Popup>
    <!-- 预计送货日期 -->
    <Popup v-model:show="_DATA.showTimePicker2" position="bottom">
      <DatetimePicker
        v-model="_DATA.currentDate2"
        type="date"
        title="选择日期"
        :min-date="_DATA.minDate"
        @confirm="changePicker2"
        @cancel="_DATA.showTimePicker2 = false"
      />
    </Popup>
  </div>
</template>
<script setup>
import FileUpload from '@/components/common/FileUpload';
import { onMounted, reactive, inject, ref } from 'vue';
import {
  Form,
  Field,
  Notify,
  Loading,
  Overlay,
  Button,
  Popup,
  DatetimePicker,
  NavBar,
} from 'vant';
import { useRouter } from 'vue-router';

import moment from 'moment';

const $http = inject('$http');
const loading = ref(false);
const router = useRouter();

const _DATA = reactive({
  id: router.currentRoute.value.query.id,

  type: router.currentRoute.value.query.type,
  showTimePicker: false,
  currentDate: new Date(),
  minDate: new Date(),
  timeType: 1,
  showTimePicker2: false,
  currentDate2: new Date(),
});

const form_info = reactive({
  id: router.currentRoute.value.query.id,
  custom_time: '', //日期
  place_order_time: '',
  files: [], //附件 name path
  remark: '', //备注
  req_type: '',
});
const typeList = [
  { type: 464, name: '跟单', url: $http.mainSelfOrder_trackOrder, remark: '' },
  {
    type: 465,
    name: '客户下单',
    url: $http.mainSelfOrder_formallyPlaceOrder,
    req_type: 2,
    remark: '客户已下单，等待收货！',
  },
  {
    type: 466,
    name: '完成测量',
    url: $http.mainSelfOrder_measureComplete,
    remark: '已于以上日期完成测量',
  },
  {
    type: 469,
    name: '作废',
    url: $http.mainSelfOrder_invalidOrder,
    remark: '',
  },
];

onMounted(() => {
  form_info.remark = typeList.filter(
    (item) => item.type == _DATA.type
  )[0].remark;
});

const getTitle = () => {
  return typeList.filter((item) => item.type == _DATA.type)[0].name;
};

/**
 * 接口获取
 */

/**
 * 操作
 */

//-@日期

const changePicker = (value) => {
  _DATA.showTimePicker = false;
  if (_DATA.timeType == 1) {
    form_info.place_order_time = moment(value).format('YYYY-MM-DD');
  } else {
    form_info.custom_time = moment(value).format('YYYY-MM-DD');
  }
};
const changePicker2 = (value) => {
  _DATA.showTimePicker2 = false;
  form_info.custom_time = moment(value).format('YYYY-MM-DD');
};
//-@附件
const getFileList = (file_list) => {
  form_info.files = file_list;
};

const clickFun = (type) => {
  if (type == 2) {
    _DATA.showTimePicker2 = true;
  } else {
    _DATA.showTimePicker = true;
  }

  _DATA.timeType = type;
};
//-@提交
const formRef = ref(null);
const onSubmit = () => {
  formRef.value?.validate().then(() => {
    if (!form_info.files || form_info.files.length == 0) {
      if (_DATA.type == 466) {
        Notify({ type: 'warning', message: '请上传附件' });
        return;
      }
    }
    loading.value = true;
    let url = typeList.filter((item) => item.type == _DATA.type)[0].url;
    url({ ...form_info }).then((res) => {
      const { code, data } = res;
      if (code === 0) {
        Notify({
          type: 'success',
          message: '操作成功',
          onClose: () => {
            loading.value = false;
            // 哪里来回哪里去
            router.back();
          },
        });
      } else {
        loading.value = false;
        Notify({ type: 'warning', message: data });
      }
    });
  });
};

//-@ 返回
const onBack = () => {
  router.back();
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/commonForm.less';
.tip_box {
  background: #ffef98;
  font-size: 14px;
  line-height: 20px;
  color: #3d3d3d;
  padding: 18px 10px;
  text-align: center;
}
</style>
