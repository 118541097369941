<!--
 * @Author: NanNan
 * @Date: 2023-06-25 10:22:25
 * @LastEditTime: 2023-12-22 16:27:38
 * @Description: v1.4.0 全部数据 - 见面数量
 * v1.4.3 部门数据 - 模块1：见面数量
-->
<template>
  <div class="data_item extend">
    <div class="data_card_box">
      <Skeleton title :row="5" :loading="_DATA.loading" style="padding: 20px">
        <div class="data_card_top">
          <div class="title">见面数量</div>
          <!--存量/当年 -->
          <Row>
            <Col span="12">
              <div class="grey">存量</div>
              <div class="mb_5">
                <span class="size24 bold">{{ _DATA.info.passed?.actual }}</span>
                <span class="grey size13">
                  /{{ _DATA.info.passed?.clue_num }}
                </span>
              </div>
              <div class="mb_5">
                <span></span>
                <span class="bold ml_5"></span>
              </div>
              <div class="flex_line mb_2">
                <div class="grey">
                  <em>目标</em>
                  <em class="bold ml_5">
                    {{ _DATA.info.passed?.target ?? '-' }}
                  </em>
                </div>
                <div class="ml_5">
                  <!-- 超目标 -->
                  <div
                    class="flex_line"
                    v-if="_DATA.info.passed?.actual > _DATA.info.passed?.target"
                  >
                    <span class="bold green">{{ _DATA.info.passed?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 距目标 -->
                  <div
                    class="flex_line"
                    v-else-if="
                      _DATA.info.passed?.actual < _DATA.info.passed?.target
                    "
                  >
                    <span class="bold orange">
                      {{ _DATA.info.passed?.gap }}
                    </span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 已达标 -->
                  <div class="flex_line" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </div>
              <div class="flex_line grey mb_2">
                <em>完成率</em>
                <em class="ml_5 bold">
                  {{ _DATA.info.passed?.rate + '%' ?? '-' }}
                </em>
              </div>
            </Col>
            <Col span="12">
              <div class="grey">当年</div>
              <div class="mb_5">
                <span class="size24 bold">
                  {{ _DATA.info.now?.actual }}
                </span>
                <span class="grey size13">/{{ _DATA.info.now?.clue_num }}</span>
              </div>
              <div class="mb_5">
                <span>见面率</span>
                <span class="bold ml_5">{{ _DATA.info.now?.meet_rate }}%</span>
              </div>
              <div class="flex_line mb_2">
                <div class="grey">
                  <em>目标</em>
                  <em class="bold ml_5">{{ _DATA.info.now?.target ?? '-' }}</em>
                </div>
                <div class="ml_5">
                  <!-- 超目标 -->
                  <div
                    class="flex_line"
                    v-if="_DATA.info.now?.actual > _DATA.info.now?.target"
                  >
                    <span class="bold green">{{ _DATA.info.now?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/up.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 距目标 -->
                  <div
                    class="flex_line"
                    v-else-if="_DATA.info.now?.actual < _DATA.info.now?.target"
                  >
                    <span class="bold orange">{{ _DATA.info.now?.gap }}</span>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/down.png')"
                      alt="down"
                    />
                  </div>
                  <!-- 已达标 -->
                  <div class="flex_line" v-else>
                    <img
                      class="icon_img"
                      :src="require('@/assets/images/target-data/equal.png')"
                      alt="down"
                    />
                  </div>
                </div>
              </div>
              <div class="flex_line grey mb_2">
                <em>完成率</em>
                <em class="ml_5 bold">
                  {{ _DATA.info.now?.rate + '%' ?? '-' }}
                </em>
              </div>
            </Col>
          </Row>
        </div>
        <div class="data_card_table border_top">
          <Row class="mb_10">
            <Col span="6">
              <div class="grey size13 pr_10">
                {{ props.data.depId ? '人员' : '部门' }}
              </div>
            </Col>
            <Col span="7">
              <div class="grey size13">存量见面</div>
            </Col>
            <Col span="7">
              <div class="grey size13">当年见面</div>
            </Col>
            <Col span="4">
              <div class="grey size13">见面率</div>
            </Col>
          </Row>
          <template v-for="(item, index) in _DATA.list" :key="index">
            <Row
              class="mb_20"
              v-if="index + 1 <= _DATA.maxCount || _DATA.open"
              @click.stop="goDetail(item)"
            >
              <Col span="6">
                <div class="size13 pr_10">
                  {{ props.data.depId ? item.name : item.short_name }}
                </div>
              </Col>
              <Col span="7">
                <div>
                  <span class="bold size13">{{ item?.passed?.actual }}</span>
                  <span class="ml_3 grey bold size13">
                    /{{ item?.passed?.clue_num }}
                  </span>
                </div>

                <div
                  class="flex_line"
                  v-if="item?.passed?.actual > item?.passed?.target"
                >
                  <span class="bold green size13">{{ item?.passed?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line"
                  v-else-if="item?.passed?.actual < item?.passed?.target"
                >
                  <span class="bold orange size13">{{ item.passed?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </Col>

              <Col span="7">
                <div>
                  <span class="bold size13">{{ item.now?.actual }}</span>
                  <span class="ml_3 grey bold size13">
                    /{{ item.now?.clue_num }}
                  </span>
                </div>

                <div
                  class="flex_line"
                  v-if="item.now?.actual > item.now?.target"
                >
                  <span class="bold green size13">{{ item.now?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/up.png')"
                    alt="down"
                  />
                </div>
                <div
                  class="flex_line"
                  v-else-if="item.now?.actual < item.now?.target"
                >
                  <span class="bold orange size13">{{ item.now?.gap }}</span>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/down.png')"
                    alt="down"
                  />
                </div>
                <div class="flex_line" v-else>
                  <img
                    class="icon_img"
                    :src="require('@/assets/images/target-data/equal.png')"
                    alt="down"
                  />
                </div>
              </Col>
              <Col span="4">
                <div class="bold size13">{{ item.now?.rate }}%</div>
              </Col>
            </Row>
          </template>
        </div>
      </Skeleton>
    </div>
    <div
      class="expand"
      v-if="_DATA.list.length > _DATA.maxCount"
      @click="
        () => {
          _DATA.open = !_DATA.open;
        }
      "
    >
      <span>{{ _DATA.open ? '收起' : '展开' }}</span>

      <Icon name="arrow-down" size="14" v-if="!_DATA.open" />
      <Icon name="arrow-up" size="14" v-else />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import {
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  inject,
  watch,
} from 'vue';
import { Row, Col, Skeleton, Icon, Notify } from 'vant';
import { getDelayTime } from '@/components/targetData/targetData';
const router = useRouter();
const $http = inject('$http');
const props = defineProps(['data']);
const emit = defineEmits(['loadEnd']);
const _DATA = reactive({
  loading: false,
  info: {},
  list: [],
  //-在全部数据||部门数据不同页面所处的位置
  index: 1,
  depIndex: 1, //部门数据页面所处位置
  maxCount: 5, //超过则显示展开收起
  open: false,
});
//-监听 切换部门-切换路由
watch(
  () => props.data,
  (cur, pre) => {
    if (pre.depId && pre.depId != cur.depId) {
      //切换部门
      _DATA.loading = true;
      onLoad();
    }
    if (pre.yearId != cur.yearId) {
      //切换年份
      _DATA.loading = true;
      onLoad();
    }
  }
);
onMounted(() => {
  _DATA.loading = true;
  if (props.data.yearId) {
    setTimeout(() => {
      onLoad();
    }, getDelayTime(props.data.pos, _DATA.index));
  }
});
//-@ 加载

const onLoad = () => {
  let url = $http.targetStatistic_depMeetData;
  let params = { year: props.data.yearId };
  if (props.data.depId) {
    url = $http.targetStatistic_personMeetData;
    params = { dep_id: props.data.depId, year: props.data.yearId };
  }
  url(params).then((res) => {
    const { code, data } = res;
    _DATA.loading = false;
    if (code === 0) {
      _DATA.info = data.info;
      _DATA.list = data.list;
      console.log(data.list);
    } else {
      _DATA.info = {};
      Notify({
        type: 'warning',
        message: data,
      });
    }
    emit('loadEnd');
  });
};
/**
 * 操作
 */
const goDetail = (item) => {
  if (props.data.depId) {
    return;
  }
  router.push({
    path: '/depCustomerData',
    query: {
      depId: item.id,
      depName: item.short_name,
      pos: _DATA.depIndex,
    },
  });
};
</script>
<style lang="less" scoped>
@import '@/assets/styles/targetData/dataCard.less';
</style>
