<!--
 * @Author: gest
 * @Date: 2023-09-14 16:07:37
 * @LastEditTime: 2023-10-27 11:18:01
 * @Description: v1.4.5 产品二次交底单详情 
-->
<template>
  <div class="formBase">
    <div
      class="content white_bg mb_15"
      v-for="(item, index) in form.info.basic_info"
      :key="index"
    >
      <div class="p_15 flex_between">
        <div class="flex_column">
          <div class="font_size_17 font_bold">{{ item.name }}</div>
          <div class="grey_color font_size_13">
            共{{ item.children.length }}项<span
              v-show="getTotalFun(item.children, '空值') != 0"
              >，<span class="red_color">{{
                getTotalFun(item.children, '空值')
              }}</span
              >项待确认</span
            >
          </div>
        </div>

        <div
          class="flex_between line_info"
          style="background: #ffffff; padding: 0 0 0 10px"
        >
          <div class="right">
            <div class="r_item">
              <div class="green_color font_size_17">
                {{ getTotalFun(item.children, '已交底') }}
              </div>
              <div class="grey_color font_size_12">已交底</div>
            </div>
            <div class="vline"></div>
            <div class="r_item">
              <div class="red_color font_size_17">
                {{ getTotalFun(item.children, '未交底') }}
              </div>
              <div class="grey_color font_size_12">未交底</div>
            </div>
            <div class="vline"></div>
            <div class="r_item">
              <div class="grey_color font_size_17">
                {{ getTotalFun(item.children, '无此项') }}
              </div>
              <div class="grey_color font_size_12">无此项</div>
            </div>
            <div class="ml_5" @click="arrowClick(item)">
              <Icon name="arrow-up" class="grey_color" v-if="item.arrow == 1" />
              <Icon name="arrow-down" class="grey_color" v-else />
            </div>
          </div>
        </div>
      </div>
      <div v-show="item.arrow == 1">
        <div
          class="cell_border flex_between"
          v-for="(v, i) in item.children"
          :key="i"
        >
          <div class="font_size_14 mb_15 flex1 mr_15">
            {{ v.name }}
          </div>

          <div
            class="ml_10"
            :class="
              v.value
                ? v.value == 1
                  ? 'green_color'
                  : v.value == 2
                  ? 'red_color'
                  : 'grey_color'
                : ''
            "
          >
            {{
              v.value
                ? v.value == 1
                  ? '已交底'
                  : v.value == 2
                  ? '未交底'
                  : '无此项'
                : '-'
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="content mb_15">
      <div class="list_field white_bg">
        <div class="field_item">
          <div class="flex_between">
            <div class="grey_color">交底时间</div>
            <div class="">{{ form.info.accept_time || '-' }}</div>
          </div>
        </div>
        <div class="field_item">
          <div class="label-name grey_color">备注说明</div>
          <div>
            {{ form.info.remark }}
          </div>
        </div>
        <div
          class="field_item"
          v-if="form.info.files && form.info.files.length > 0"
        >
          <div class="label-name grey_color">附件</div>
          <FileGrid :data="form.info.files"></FileGrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, onMounted, defineEmits } from 'vue';
import { Icon } from 'vant';
import FileGrid from '@/components/common/FileGrid';
const emit = defineEmits(['update:data']);

const props = defineProps(['data']);

const form = reactive({
  info: {},
  loading: false,
});

// 参数--调用接口
onMounted(() => {
  form.info = props.data;
  emit('update:data', form.info);
});

//-计算
const getTotalFun = (data, name) => {
  let list = [];
  if (name == '空值') {
    list = data.filter((v) => {
      return !v.value;
    });
  } else if (name == '已交底') {
    list = data.filter((v) => {
      return v.value == 1;
    });
  } else if (name == '未交底') {
    list = data.filter((v) => {
      return v.value == 2;
    });
  } else if (name == '无此项') {
    list = data.filter((v) => {
      return v.value == 3;
    });
  }
  console.log(list);
  return list.length;
};
const arrowClick = (item) => {
  if (item.arrow == 1) {
    item.arrow = '';
  } else {
    item.arrow = 1;
  }
};
</script>

<style lang="less" scoped>
@import '@/assets/styles/witness/witnessSiteCell.less';
</style>
